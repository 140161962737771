// React / ReactDOM / React-router
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Redux
import {
    storeWarningAlert,
    storeNotificationAlert    
} from "actions";

// Functions
import {
    getUserLocation
} from "components/Map";

// CSS
import "./SearchResultsLocation.scss";


class SearchResultsLocation extends Component {
    render() {
        return(
            <div id ="search-results-container-location"></div>
        );
    }

    componentDidMount() {
        // Turn on progress alert
        this.props.storeNotificationAlert({
            message: "Browser Detecting Location.",
            on: true
        });

        const successCallback = (userLocation) => {
            //console.log("SearchResultsLocation / componentDidMount - userLocation = ", userLocation);

            // Turn off progress alert
            this.props.storeNotificationAlert({
                message: null,
                on: false
            });

            // Search results props
            const searchResultsStates = {
                keywords: null,
                userLocation: userLocation
            };

            // Redirect to search page
            this.props.history.push(
                {
                    pathname: "/search/results/",
                    state: searchResultsStates
                }
            );
        };

        const failureCallback = () => {          
            // Turn off progress alert
            this.props.storeNotificationAlert({
                message: null,
                on: false
            });

            // Turn on warning
            this.props.storeWarningAlert({
                message: "Browser Does Not Support Location Detection.",
                on: true
            });

            // Turn off warning
            setTimeout(
                () => {
                    this.props.storeWarningAlert({
                        message: null,
                        on: false
                    });

                    // Navigate to home
                    this.props.history.push(
                        "/"
                    );
                },
                1500
            );
        };

        const blockCallback = () => {          
            // Turn off progress alert
            this.props.storeNotificationAlert({
                message: null,
                on: false
            });

            // Turn on warning
            this.props.storeWarningAlert({
                message: "Browser Has Been Blocked from Detecting Location.",
                on: true
            });

            // Turn off warning
            setTimeout(
                () => {
                    this.props.storeWarningAlert({
                        message: null,
                        on: false
                    });

                    // Navigate to home
                    this.props.history.push(
                        "/"
                    );
                },
                1500
            );
        };

        getUserLocation(
            successCallback,
            failureCallback, 
            blockCallback
        );
    }

    componentDidUpdate() {

    }
}

function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        google: state.map.google
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeWarningAlert,
            storeNotificationAlert
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsLocation);
