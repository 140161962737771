/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    DayInfo.js
    - Get weather / sun times / moon phase for a given day
--------------------------------------------------------------------------------------------
    Content
    - DayInfo
    - fetchWeather
    - hideWeather
    - showWeather
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";

// Modules
import moment from "moment-timezone";
import ReactTooltip from "thedots-tooltip";

// Functions
import { getStaticPath, pad } from "js/Functions";

// Axios
import { 
    getWeather
} from "requests";

// CSS
import "./DayInfo.scss";


// stateless React component
function DayInfo(props) {
    const sunriseIcon = (props.colorMode === "day")?
        getStaticPath("/images/common/sunrise-black.png") :
        getStaticPath("/images/common/sunrise-white.png");

    const sunsetIcon = (props.colorMode === "day")?
        getStaticPath("/images/common/sunset-black.png") :
        getStaticPath("/images/common/sunset-white.png");

    const sunriseTime = props.sunTimes.sunrise.format("h:mm a");
    const sunsetTime = props.sunTimes.sunset.format("h:mm a");
    const weatherDisplay = (props.weatherOn)? "inline-block" : "none";
    const precipDisplay = (props.precipOn)? "inline-block" : "none";
    const sunMoonDisplay = (props.sunMoonOn)? "inline-block" : "none";
    //console.log("DayInfo / render - props = ", props);
    //console.log("DayInfo / render - sunriseTime = ", sunriseTime);
    //console.log("DayInfo / render - sunsetTime = ", sunsetTime);

    const moonIcon = (props.colorMode === "day")?
        getStaticPath("/images/moon/day/" + pad(props.moonPhaseDigits * 125, 4, 0) + ".png") :
        getStaticPath("/images/moon/night/" + pad(props.moonPhaseDigits * 125, 4, 0) + ".png");

    // Weather icon
    const weatherIcon = (props.weatherIcon === null)? null : 
    (
        (props.colorMode === "day")?
            getStaticPath("/images/weather/day/" + props.weatherIcon) :
            getStaticPath("/images/weather/night/" + props.weatherIcon)
    );

    // render DayInfo component
    return(
		<div className = "dayinfo">

			<div className = "dayinfo-weather" 
                style = {{ display: weatherDisplay }}
            >
				<div className = "dayinfo-weather-icon image-contain"
                    style = {{ backgroundImage: weatherIcon }}
                    data-tip data-for = "dayinfo-weather-icon"
                >
					<ReactTooltip id = "dayinfo-weather-icon" 
                        className = "tooltip-s2"
                        type = "dark"
                    >
						<span>Weather Forecast</span>
					</ReactTooltip>
				</div>
				<div className = "dayinfo-weather-precip"
                    style = {{ display: precipDisplay }}
                >
					<div className = {(props.colorMode === "day")? 
                        "dayinfo-weather-precip-type dg4" : "dayinfo-weather-precip-type g4"}
                        data-tip data-for = "dayinfo-weather-precip-type"
                    >
                        {props.precipType}
						<ReactTooltip id = "dayinfo-weather-precip-type"
                            className = "tooltip-s2"
                            type = "dark"
                        >
							<span>Type of Precipitation</span>
						</ReactTooltip>
					</div>
					<div className = {(props.colorMode === "day")? 
                        "dayinfo-weather-precip-prob dg4" : "dayinfo-weather-precip-prob g4"}
                        data-tip data-for = "dayinfo-weather-precip-prob"
                    >
                        {props.precipProb}
						<ReactTooltip id = "dayinfo-weather-precip-prob"
                            className = "tooltip-s2"
                            type = "dark"
                        >
							<span>Probability of Precipitation</span>
						</ReactTooltip>
					</div>
				</div>
				<div className = "dayinfo-weather-temp">
					<div className = {(props.colorMode === "day")? 
                        "dayinfo-weather-temp-high dg4" : "dayinfo-weather-temp-high g4"} 
                        data-tip data-for = "dayinfo-weather-temp-high"
                    >
                        {props.tempHigh}
						<ReactTooltip id = "dayinfo-weather-temp-high" 
                            className = "tooltip-s2"
                            type = "dark"
                        >
							<span>High Temperature</span>
						</ReactTooltip>
					</div>
					<div className = {(props.colorMode === "day")? 
                        "dayinfo-weather-temp-low dg4" : "dayinfo-weather-temp-low g4"}
                        data-tip data-for = "dayinfo-weather-temp-low"
                    >
                        {props.tempLow}
						<ReactTooltip id = "dayinfo-weather-temp-low"
                            className = "tooltip-s2"
                            type = "dark"
                        >
							<span>Low Temperature</span>
						</ReactTooltip>
					</div>
				</div>
			</div>

			<div className = "dayinfo-sun"  
                style = {{ display: sunMoonDisplay }}
            >
				<div className = "dayinfo-sunrise" data-tip data-for = "dayinfo-sunrise">
					<div className = "dayinfo-sunrise-icon image-contain" 
                        style = {{ backgroundImage: sunriseIcon }}
                    >
					</div>
					<span className = {(props.colorMode === "day")? "dg4" : "g4"}>
            			{sunriseTime}
            		</span>
					<ReactTooltip id = "dayinfo-sunrise"
                        className = "tooltip-s2"
                        type = "dark"
                    >
						<span>Sunrise Time</span>
					</ReactTooltip>
				</div>
				<div className = "dayinfo-sunset" data-tip data-for = "dayinfo-sunset">
					<div className = "dayinfo-sunset-icon  image-contain"
                        style = {{ backgroundImage: sunsetIcon }}
                    >
					</div>
					<span className = {(props.colorMode === "day")? "dg4" : "g4"}>
            			{sunsetTime}
            		</span>
					<ReactTooltip id = "dayinfo-sunset"
                        className = "tooltip-s2"
                        type = "dark"
                    >
						<span>Sunset Time</span>
					</ReactTooltip>
				</div>
			</div>

			<div className = "dayinfo-moon" 
                style = {{ display: sunMoonDisplay }} data-tip data-for = "dayinfo-moon"
            >
				<div className = "dayinfo-moon-icon image-contain"
                    style = {{ backgroundImage: moonIcon }}
                >
				</div>
				<span className = {(props.colorMode === "day")? "dg4" : "g4"}>
            		{props.moonPhase}
            	</span>
				<ReactTooltip id = "dayinfo-moon"
                    className = "tooltip-s2"
                    type = "dark"
                >
					<span>Moon Illumination</span>
				</ReactTooltip>
			</div>
		</div>
    )
}


/* 
============================================================================================
    fetchWeather
--------------------------------------------------------------------------------------------
    - Retrieve weather information through API
    - Data is in json format
============================================================================================
*/

function fetchWeather(callback) {
    //console.log("DayInfo / fetchWeather - this = ", this);
    //console.log("DayInfo / fetchWeather - callback = ", callback);

    // Round off the location
    const digits = 1000000;
    const locationLat = Math.round(this.state.location.latitude * digits) / digits;
    const locationLng = Math.round(this.state.location.longitude * digits) / digits;
    
    // Get the day index
    const today = moment().tz(this.state.timezone);
    //console.log("DayInfo / fetchWeather - today (before setting time) = ", today.format());
    today.set("hour", 0);
    today.set("minute", 0);
    today.set("second", 0);
    today.set("millisecond", 0);
    //console.log("DayInfo / fetchWeather - today (after setting time) = ", today.format());
    //console.log("DayInfo / fetchWeather - hour00Moment = ", this.state.hour00Moment.format());

    const dayIndex = Math.round((this.state.hour00Moment - today) / 24 / 3600 / 1000);
    //console.log("DayInfo / fetchWeather - this.state.hour00Moment - today = ", (this.state.hour00Moment - today) / 24 / 3600 / 1000);
    //console.log("DayInfo / fetchWeather - dayIndex = ", dayIndex);
    //console.log("DayInfo / fetchWeather - this.state.weather = ", this.state.weather);
    //console.log("DayInfo / fetchWeather - this.showWeather = ", this.showWeather);

    // If more than 8 days away
    if ((dayIndex >= 8) || (dayIndex < 0)) {
        //console.log("DayInfo / fetchWeather - weather out of range");
        if (dayIndex >= 8) {
            //if (this.showWeather !== false) {
                this.props.storeWarningAlert({
                    message: "Weather forecast does not exist for the selected date.",
                    on: true
                });
            //}

            //console.log("DayInfo / fetchWeather - weather more than 8 days away");
        }
        else {
            //if (this.showWeather !== false) {
                this.props.storeWarningAlert({
                    message: "A past date is selected. Choose a day in the future",
                    on: true
                });
            //}
            
            //console.log("DayInfo / fetchWeather - weather in the past");
        }

        // Hide weather
        const hideWeatherBound = hideWeather.bind(this, callback);
        hideWeatherBound();
    }
    // Get the weather (jsonp) if forecast available
    else {
        if (this.state.weather) {
            //console.log("DayInfo / fetchWeather - show weather with existing data");
            //console.log("DayInfo / fetchWeather - this.state.weather = ", this.state.weather);

            const showWeatherBound = showWeather.bind(
                this, dayIndex, this.state.weather, this.props.colorMode, callback
            );

            showWeatherBound();
        }
        else {
            //console.log("DayInfo / fetchWeather - show weather with a new query");

            // Axios callback : execute when the server returns a response
            const axiosCallback = (response) => {
                //console.log("DayInfo / fetchWeather - response.data.content = ", response.data.content);

                // Update weather state
                this.setState(
                    {
                        weather: response.data.content
                    },
                    showWeather.bind(
                        this, dayIndex, response.data.content, this.props.colorMode, callback
                    )
                );
            };

            // Send get weather request using axios with CSRF token
            getWeather(
                {
                    "location" : { "latitude" : locationLat, "longitude" : locationLng }
                }
            )
            .then(axiosCallback)
            .catch((response) => {console.log("DayInfo / fetchWeather - Axios error", response);});
        }
    }
}


function hideWeather(callback) {
    //console.log("DayInfo / hideWeather - this = ", this);

    // Show the weather window and execute callback
    this.setState(
        {
            weatherOn: false,
            weatherIcon: null,
            tempHigh: null,
            tempLow: null,
            precipOn: false,
            precipType: null,
            precipProb: null
        },
        callback
    );
}


function showWeather(dayIndex, weatherJSON, colorMode, callback) {
    //console.log("DayInfo / showWeather - dayIndex = ", dayIndex);
    //console.log("DayInfo / showWeather - colorMode = ", colorMode);
    //console.log("DayInfo / showWeather - weatherJSON = ", weatherJSON);

    // Icon list (Dark Sky Net forecast keywords)
    const weatherIconList = {
        "clear-day": "sun.png",
        "clear-night": "sun.png",
        "rain": "rain.png",
        "snow": "snow.png",
        "sleet": "cloud_snow.png", 
        "wind": "wind.png", 
        "fog": "fog.png", 
        "cloudy": "cloud.png", 
        "partly-cloudy-day": "sun_cloud.png", 
        "partly-cloudy-night": "sun_cloud.png"
    };

    /*
    // Icon list (Weather Underground forecast keywords)
    const weatherIconList = {
        "chanceflurries" : "sun_cloud_snow.png",
        "chancerain" : "sun_cloud_rain.png",
        "chancesleet" : "sun_cloud_hail.png",
        "chancesnow" : "sun_cloud_snow.png",
        "chancetstorms" : "sun_cloud_rain_thunder.png",
        "clear" :  "sun.png",
        "flurries" : "cloud_snow.png",
        "fog" : "fog.png",
        "hazy" : "sun_fog.png",
        "mostlycloudy" : "cloud.png",
        "mostlysunny" : "sun.png",
        "partlycloudy" : "sun_cloud.png",
        "partlysunny" : "sun_cloud.png",
        "rain" : "rain.png",
        "sleet" : "cloud_hail.png",
        "snow" : "snow.png",
        "sunny" : "sun.png",
        "tstorms" : "cloud_rain_thunder.png",
        "unknown" : "sun.png",
        "cloudy" : "cloud.png",
        "partlycloud" : "sun_cloud.png"
    };
    */

    // Parse json file
    const tempHigh = Math.round(Number(weatherJSON.daily.data[dayIndex].temperatureHigh));
    const tempLow = Math.round(Number(weatherJSON.daily.data[dayIndex].temperatureLow));
    const precipProb = Math.round(Number(weatherJSON.daily.data[dayIndex].precipProbability) * 100);
    const precipType = (weatherJSON.daily.data[dayIndex].precipType)? weatherJSON.daily.data[dayIndex].precipType.charAt(0).toUpperCase() + weatherJSON.daily.data[dayIndex].precipType.slice(1).toLowerCase() : "";
    const condIcon = weatherIconList[weatherJSON.daily.data[dayIndex].icon];
    //console.log("DayInfo / showWeather - weatherJSON.daily.data[dayIndex].icon = ", weatherJSON.daily.data[dayIndex].icon);

    // If there is significant precipitation predicted
    let precipOn;
    if (precipProb > 20) {
        // Show the precipitation window
        precipOn = true;
    }
    // No precipitation
    else {
        // Show the precipitation window
        precipOn = false;
    }

    /*
    Weather Underground (Deprecated)
    //const tempHigh = weatherJSON.forecast.simpleforecast.forecastday[dayIndex].high.fahrenheit;
    //const tempLow = weatherJSON.forecast.simpleforecast.forecastday[dayIndex].low.fahrenheit;
    const precipProb = weatherJSON.forecast.simpleforecast.forecastday[dayIndex].pop;
    //const rainPrecip = weatherJSON.forecast.simpleforecast.forecastday[dayIndex].qpf_allday.in;
    const snowPrecip = weatherJSON.forecast.simpleforecast.forecastday[dayIndex].snow_allday.in;
    const condIcon = weatherIconList[weatherJSON.forecast.simpleforecast.forecastday[dayIndex].icon];
    // const condText = weatherJSON.forecast.txt_forecast.forecastday[dayIndex*2].fcttext;

    // If there is precipitation
    let precipType, precipOn;
    if (precipProb > 20) {
        // show the precipitation window
        precipOn = true;

        // if snow
        if(snowPrecip !== 0) {
            precipType = "Snow";
        }
        // for everything else
        else {
            precipType = "Rain";
        }
    }
    // No precipitation
    else {
        // show the precipitation window
        precipOn = false;
    }
    */

    // Add high and low temperature if clear
    const tempHighText = tempHigh + "°F";
    const tempLowText = tempLow + "°F";

    // Print out weather information
    //const warningIcon = getFilePath("images/common/weather/" + condIcon, true);
    //displayWarning(warningIcon, "Weather Forecast", condText, 6500, null);

    // Show the weather window and execute callback
    this.setState(
        {
            weatherOn: true,
            weatherIcon: condIcon,
            tempHigh: tempHighText,
            tempLow: tempLowText,
            precipOn: precipOn,
            precipType: precipType,
            precipProb: precipProb + "%"
        }, 
        callback
    );
}


export {
    DayInfo,
    fetchWeather,
    hideWeather,
    showWeather
};