// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import { getStaticPath } from "js/Functions";

// Redux 
import { storeShare } from "actions"; 

// External package
import {CopyToClipboard} from "react-copy-to-clipboard";

// CSS
import "./Share.scss";


class Share extends Component {
    constructor(props){
        super(props);

        // Modal DOM
        this.modalRef = React.createRef();

        this.state = { 
            linkCopied: false
        };

        this.escFunction = this.escFunction.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onFBShare = this.onFBShare.bind(this);
        this.onCopyLink = this.onCopyLink.bind(this);
    }
    
    render() {
        if (this.props.share.modalOn === true) {
            //console.log("Share / render - process.env.REACT_APP_ROOTURL = ", process.env.REACT_APP_ROOTURL);
            //console.log("Share / render - this.props.share.type = ", this.props.share.type);

            const url = (this.props.share.type === "itinerary")?
                (`${process.env.REACT_APP_WEB_ROOT_URL}${this.props.share.type}/${this.props.share.info.id}`) : 
                (`${process.env.REACT_APP_WEB_ROOT_URL}${this.props.share.type}/${this.props.share.info.slug}`);

            let shareText = "";
            let shareContent = "";

            if (this.props.share.type === "dot") {
                shareText = "Check out this dot on The Dots";
                shareContent = "Check out this dot on The Dots";
            } 
            
            const twUrl = `https://twitter.com/intent/tweet?source=tweetbutton&text=${shareText}&url=${url}`;

            return (
                <div className = "share-modal">
                    <div className = {(this.props.colorMode === "day")? 
                                "share-modal-content modal-day" : 
                                "share-modal-content modal-night"} 
                        ref = {this.modalRef}
                    >
                        <div className = "share-modal-content__cancel image-button-weaker-s3"
                            style = {{
                                backgroundImage:  (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/cancel-black.png") :
                                    getStaticPath("/images/common/cancel-white.png")
                            }}
                            onClick = {this.closeModal}
                        >
                        </div>

                        <div className = {(this.props.colorMode === "day")? 
                                    "share-modal-content__title k2" : 
                                    "share-modal-content__title w2"}
                        >
                            Share 
                        </div>

                        <div className = "share-modal-content__item" > 
                            <div className = "share-modal-content__item-image image-contain"
                                onClick = {this.onFBShare.bind(this, url)}
                                style = {{
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/share/facebook-light-blue.png") :
                                        getStaticPath("/images/share/facebook-blue.png")
                                }}
                            >
                            </div> 
                            <span 
                                className = {(this.props.colorMode === "day")?
                                        "share-modal-content__item-text k4" :
                                        "share-modal-content__item-text w4"}
                                onClick = {this.onFBShare.bind(this, url)}
                            >
                                Facebook
                            </span> 
                        </div>
                        
                        <div className = "share-modal-content__item"> 
                            <div className = "share-modal-content__item-image image-contain"
                                style = {{
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/share/twitter-light-blue.png") :
                                        getStaticPath("/images/share/twitter-blue.png")
                                }}
                            >
                            </div> 
                            <a href = {twUrl} style = {{ textDecoration: "none" }}> 
                                <span 
                                    className = {(this.props.colorMode === "day")? 
                                        "share-modal-content__item-text k4" :
                                        "share-modal-content__item-text w4"}
                                >
                                    Twitter
                                </span>
                            </a>
                        </div>
                        
                        <div className = "share-modal-content__item"> 
                            <div className = "share-modal-content__item-image image-contain"
                                style = {{
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/share/email-light-blue.png") :
                                        getStaticPath("/images/share/email-blue.png")
                                }}
                            >
                            </div> 
                            <a href = {`mailto:?subject=${shareText}&body=${shareContent} ${url}`}>
                                <span
                                    className = {(this.props.colorMode === "day")?
                                        "share-modal-content__item-text k4" :
                                        "share-modal-content__item-text w4"}
                                >
                                    Email
                                </span> 
                            </a>
                        </div>
                        
                        <div className = "share-modal-content__item"> 
                            <div className = "share-modal-content__item-image image-contain"
                                style = {{
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/share/copy-light-blue.png") :
                                        getStaticPath("/images/share/copy-blue.png")
                                }}
                            >
                            </div> 
                                {this.state.linkCopied? (
                                    <span
                                        className = {(this.props.colorMode === "day")?
                                            "share-modal-content__item-text k4" :
                                            "share-modal-content__item-text w4"}
                                    >
                                        Link Copied 
                                    </span>
                                ) : (
                                    <CopyToClipboard text = {url}>
                                        <span
                                            className = {(this.props.colorMode === "day")?
                                                "share-modal-content__item-text k4" :
                                                "share-modal-content__item-text w4"}
                                            onClick = {this.onCopyLink}
                                        >
                                            Copy Link
                                        </span>
                                    </CopyToClipboard>
                                )}
                        </div>
                    </div>
                </div>
            )
        } 
        else {
            return null;
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        document.addEventListener("mousedown", this.clickOutside);
    }
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        document.removeEventListener("mousedown", this.clickOutside);
    }

    escFunction(event) {
        // Disappear share modal on pressing esc
        if(this.props.share.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        this.props.storeShare({ modalOn: false, type: null, info: null })
    }

    onFBShare(url) {
        window.FB.ui(
            {
                method: "share",
                mobile_iframe: true,
                href: url,
            },
            function() {}
        );
    }

    onCopyLink() {
        this.setState({ linkCopied: !this.state.linkCopied });
        setTimeout(
            () => {
                this.setState({ linkCopied: false });
            }, 
            3000
        );
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        share: state.share.share
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeShare }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(Share); 
