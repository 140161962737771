/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    EditUserInfo.js
--------------------------------------------------------------------------------------------
    Content
    - EditUserInfo
============================================================================================
*/


// React / ReactDOM / React-router / Redux
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Modules
import ReactTooltip from "thedots-tooltip";
import InputRange from "thedots-input-range";

// Components
import { DropDownMenu } from "js/Common";
import UploadIndicator from "components/UploadIndicator";

// Functions
//import { loginFacebook, loginGoogle } from "js/Auth";
import {
    url,
    getStaticPath,
    getMediaProperty
} from "js/Functions";

// Constants
import { FITNESS_LEVELS } from "../../components/User/LevelDictionary";

// Axios
import {
    postImage,
    deleteImage,
    patchUser,
    postEmailCheck,
    postUsernameCheck
} from "requests";

// Redux
import { storeUser, storeEditUserInfoOn } from "actions";

// CSS
import "./EditUserInfo.scss";

// Response codes
import { responseResultCodes } from "ResponseCodes";


class EditUserInfo extends Component {
    constructor(props){
        super(props);

        // Transition times
        this.delayTime = 500;

        // DOM nodes
        this.usernameNode = null;
        this.emailNode = null;
        this.passwordOnceNode = null;
        this.passwordTwiceNode = null;
        this.firstNameNode = null;
        this.lastNameNode = null;
        this.areaNode = null;
        this.profileImageNode = null;

        // Focused states
        this.usernameFocused = false;
        this.emailFocused = false;
        this.passwordOnceFocused = false;
        this.passwordTwiceFocused = false;
        this.firstNameFocused = false;
        this.lastNameFocused = false;
        this.areaFocused = false;

        // Area object [lat, lng]
        this.area = null;
        this.areaName = "";

        // Input change timers
        this.usernameChanged = null;
        this.emailChanged = null;
        this.passwordOnceChanged = null;
        this.passwordTwiceChanged = null;
        this.firstNameChanged = null;
        this.lastNameChanged = null;
        this.areaChanged = null;
        this.profileImageChanged = null;

        // Input warning timers
        this.usernameWarning = null;
        this.emailWarning = null;
        this.passwordOnceWarning = null;
        this.passwordTwiceWarning = null;
        this.firstNameWarning = null;
        this.lastNameWarning = null;
        this.areaWarning = null;
        this.profileImageWarning = null;

        // Upload process
        this.uploadProcessTime = 2000;
        this.uploadProcessIntervalTime = 100;
        this.uploadProcessInterval = null;

        // Bucket modes
        this.bucketModeLabels = [ "Bucketed", "Authored", "Everyday" ];
        this.bucketModes = [ "save", "contribute", "everyday" ];
        this.initialBucketMode = [ this.bucketModes.indexOf(this.props.userInfo.bucket_mode) ];

        // Initialize state
        this.state = {
            // Fitness and profile pic
            fitnessLevel: props.userInfo? props.userInfo.fitness_level : props.userFitnessLevelDefault,

            bucketMode: this.initialBucketMode,

            // Profile pic
            profileImage: null,
            profileImageID: null,
            profileImageURL: null,

            // Change update flag
            fitnessLevelChanged: false,
            profileImageChanged: false,
            usernameChanged: false,
            emailChanged: false,
            passwordChanged: false,
            firstNameChanged: false,
            lastNameChanged: false,
            areaChanged: false,
            bucketModeChanged: false,

            // Border color
            profileImageBorderColor: this.unfocusColor,
            emailBorderColor: this.unfocusColor,
            passwordOnceBorderColor: this.unfocusColor,
            passwordTwiceBorderColor: this.unfocusColor,
            firstNameBorderColor: this.unfocusColor,
            lastNameBorderColor: this.unfocusColor,
            usernameBorderColor: this.unfocusColor,
            areaBorderColor: this.unfocusColor,

            // Warning display
            profileImageWarningOn: false,
            usernameWarningOn: false,
            emailWarningOn: false,
            passwordOnceWarningOn: false,
            passwordTwiceWarningOn: false,
            firstNameWarningOn: false,
            lastNameWarningOn: false,
            areaWarningOn: false,

            profileImageWarningMessage: null,
            usernameWarningMessage: null,
            emailWarningMessage: null,
            passwordOnceMessage: null,
            passwordTwiceWarningMessage: null,
            firstNameWarningMessage: null,
            lastNameWarningMessage: null,
            areaWarningMessage: null,

            // Upload indicator
            uploadPercentage: 100,
            uploadComplete: true,
            uploadProcessPercentage: 100,
            uploadProcessComplete: true,
            uploadError: false
        }

        // Bind functions
        this.inputBorderColors = this.inputBorderColors.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.areaResize = this.areaResize.bind(this);
        this.areaResizeDelayed = this.areaResizeDelayed.bind(this);

        this.usernameOnChange = this.usernameOnChange.bind(this);
        this.emailOnChange = this.emailOnChange.bind(this);
        this.passwordOnceOnChange = this.passwordOnceOnChange.bind(this);
        this.passwordTwiceOnChange = this.passwordTwiceOnChange.bind(this);
        this.firstNameOnChange = this.firstNameOnChange.bind(this);
        this.lastNameOnChange = this.lastNameOnChange.bind(this);
        this.areaOnChange = this.areaOnChange.bind(this);

        this.usernameShowWarning = this.usernameShowWarning.bind(this);
        this.emailShowWarning = this.emailShowWarning.bind(this);
        this.passwordOnceShowWarning = this.passwordOnceShowWarning.bind(this);
        this.passwordTwiceShowWarning = this.passwordTwiceShowWarning.bind(this);
        this.firstNameShowWarning = this.firstNameShowWarning.bind(this);
        this.lastNameShowWarning = this.lastNameShowWarning.bind(this);

        this.usernameClearWarning = this.usernameClearWarning.bind(this);
        this.emailClearWarning = this.emailClearWarning.bind(this);
        this.passwordOnceClearWarning = this.passwordOnceClearWarning.bind(this);
        this.passwordTwiceClearWarning = this.passwordTwiceClearWarning.bind(this);
        this.firstNameClearWarning = this.firstNameClearWarning.bind(this);
        this.lastNameClearWarning = this.lastNameClearWarning.bind(this);

        // Submit / success / clear / cancel callbacks
        this.addProfileImage = this.addProfileImage.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.submitEdit = this.submitEdit.bind(this);
        this.editSuccess = this.editSuccess.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.loadUserInfo = this.loadUserInfo.bind(this);
    }

    // General edit success callback
    editSuccess(response, callback) {
        //console.log("EditUserInfo / editSuccess - response = ", response);

        // Get new user info
        const userInfo = response.data.content;
        //console.log("EditUserInfo / editSuccess - userInfo = ", userInfo);

        // Reset all update flags
        this.setState({
            profileImageChanged: false,
            emailChanged: false,
            usernameChanged: false,
            passwordChanged: false,
            firstNameChanged: false,
            lastNameChanged: false,
            areaChanged: false,
            bucketModeChanged: false
        });

        // Store new user info
        this.props.storeUser(userInfo);
        //this.props.history.push(`/`);

        // Close edit user info window
        this.props.storeEditUserInfoOn(false);

        // Execute callback
        if ((callback !== null) && (typeof callback === "function")) {
            callback();
        }
    }

    // Cancel edit
    cancelEdit(cancelEditCallback) {
        // Close edit info window
        this.props.storeEditUserInfoOn(false);

        // Reset all update flags
        this.setState({
            profileImageChanged: false,
            emailChanged: false,
            usernameChanged: false,
            passwordChanged: false,
            firstNameChanged: false,
            lastNameChanged: false,
            areaChanged: false
        });

        // Delete profile picture if uploaded
        if (this.state.profileImageID) {
            // Send delete request
            deleteImage(this.state.profileImageID)
            .then((response) => {
                this.setState({
                    profileImage: null,
                    profileImageID: null
                });
                //console.log("EditUserInfo / cancelEdit - delete profile pic response = ", response);
            })
            .catch((response) => {console.log("[WARNING] EditUserInfo / cancelEdit - delete profile pic error = ", response);});
        }

        // Execute callback
        if ((cancelEditCallback !== null) && (typeof cancelEditCallback === "function")) {
            cancelEditCallback();
        }
    }

    resetForm() {
        // Clear all inputs
        this.usernameNode.value = "";
        this.emailNode.value = "";
        this.passwordOnceNode.value = "";
        this.passwordTwiceNode.value = "";
        this.firstNameNode.value = "";
        this.lastNameNode.value = "";
        this.areaNode.value = "";

        // Area object [lat, lng]
        this.area = null;
        this.areaName = "";

        // Focused states
        this.usernameFocused = false;
        this.emailFocused = false;
        this.passwordOnceFocused = false;
        this.passwordTwiceFocused = false;
        this.firstNameFocused = false;
        this.lastNameFocused = false;
        this.areaFocused = false;

        // Update state
        this.setState({
            fitnessLevelChanged: false,
            profileImageChanged: false,
            usernameChanged: false,
            emailChanged: false,
            passwordChanged: false,
            firstNameChanged: false,
            lastNameChanged: false,
            areaChanged: false,
            profileImageBorderColor: this.unfocusColor,
            usernameBorderColor: this.unfocusColor,
            emailBorderColor: this.unfocusColor,
            passwordOnceBorderColor: this.unfocusColor,
            passwordTwiceBorderColor: this.unfocusColor,
            firstNameBorderColor: this.unfocusColor,
            lastNameBorderColor: this.unfocusColor,
            areaBorderColor: this.unfocusColor,
            profileImageWarningOn: false,
            usernameWarningOn: false,
            emailWarningOn: false,
            passwordOnceWarningOn: false,
            passwordTwiceWarningOn: false,
            firstNameWarningOn: false,
            lastNameWarningOn: false,
            areaWarningOn: false,
            profileImageWarningMessage: null,
            emailWarningMessage: null,
            passwordOnceWarningMessage: null,
            passwordTwiceWarningMessage: null,
            firstNameWarningMessage: null,
            lastNameWarningMessage: null,
            usernameWarningMessage: null,
            areaWarningMessage: null,
            profileImage: null,
            profileImageID: null,
            fitnessLevel: (this.props.userInfo !== null)? this.props.userInfo.fitness_level : this.props.userFitnessLevelDefault
        });
    }

    checkWarnings() {
        // Initialize pass check flag
        let passCheckWarnings = true;

        // Check all warnings
        if (this.state.profileWarningOn) {
            passCheckWarnings = false;
        }
        if (this.state.emailWarningOn) {
            passCheckWarnings = false;
        }
        if (this.state.passwordOnceWarningOn) {
            passCheckWarnings = false;
        }
        if (this.state.passwordTwiceWarningOn) {
            passCheckWarnings = false;
        }
        if (this.state.firstNameWarningOn) {
            passCheckWarnings = false;
        }
        if (this.state.lastNameWarningOn) {
            passCheckWarnings = false;
        }
        if (this.state.usernameWarningOn) {
            passCheckWarnings = false;
        }
        if (this.state.areaWarningOn) {
            passCheckWarnings = false;
        }

        return passCheckWarnings;
    }

    submitEdit(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Construct json data
        let dataJSON = {};

        if (this.state.fitnessLevelChanged) {
            dataJSON.fitness_level = this.state.fitnessLevel;
        }
        if (this.state.profileImageChanged) {
            dataJSON.profile_pic_id = this.state.profileImageID;
        }
        if (this.state.usernameChanged) {
            dataJSON.username = this.usernameNode.value;
        }
        if (this.state.emailChanged) {
            dataJSON.email = this.emailNode.value;
        }
        if (this.state.passwordChanged) {
            //console.log("EditUserInfo / submitEdit - this.state.passwordChanged = ", this.state.passwordOnceNode);
            //console.log("EditUserInfo / submitEdit - this.passwordOnceNode.value = ", this.passwordOnceNode.value);
            //console.log("EditUserInfo / submitEdit - this.passwordTwiceNode.value = ", this.passwordTwiceNode.value);
            dataJSON.password = this.passwordTwiceNode.value;
        }
        if (this.state.firstNameChanged) {
            dataJSON.first_name = this.firstNameNode.value;
        }
        if (this.state.lastNameChanged) {
            dataJSON.last_name = this.lastNameNode.value;
        }
        if (this.state.areaChanged) {
            dataJSON.area = {
                "latitude": this.area[0],
                "longitude": this.area[1]
            };

            dataJSON.area_name = this.areaName;
        }
        if (this.state.bucketModeChanged) {
            dataJSON.bucket_mode = this.bucketModes[this.state.bucketMode[0]];
        }

        //console.log("EditUserInfo / submitEdit - dataJSON = ", dataJSON);

        // Axios callback
        // Execute when the server returns a response
        const axiosCallback = (response) => {
            console.log("EditUserInfo / submitEdit - axios response = ", response);

            // If edit was successful
            if (response.data.status.code === responseResultCodes.OK) {
                // Execute edit success callback
                this.editSuccess(response, this.resetForm);
            }
            // If edit was unsuccessful
            else {
                // Execute edit cancel callback
                //this.cancelEdit(this.resetForm);
            }
        };

        // Send edit request using axios post with CSRF token
        patchUser(this.props.userInfo.username, dataJSON)
        .then(axiosCallback)
        .catch((response) => {
            console.log("[WARNING] EditUserInfo / submitEdit - axios error = ", response);
        });
    }

    // Change border colors
    inputBorderColors () {
        let profileImageBorderColor,
            emailBorderColor,
            passwordOnceBorderColor,
            passwordTwiceBorderColor,
            firstNameBorderColor,
            lastNameBorderColor,
            usernameBorderColor,
            areaBorderColor;

        if (this.state.profileImageWarningOn) {
            profileImageBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }

        if (this.state.emailWarningOn) {
            emailBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            emailBorderColor = (this.emailFocused)?
                this.colorGray :
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }

        if (this.state.passwordOnceWarningOn) {
            passwordOnceBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            passwordOnceBorderColor = (this.passwordOnceFocused)?
                this.colorGray :
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }
        if (this.state.passwordTwiceWarningOn) {
            passwordTwiceBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            passwordTwiceBorderColor = (this.passwordTwiceFocused)?
                this.colorGray :
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }
        if (this.state.firstNameWarningOn) {
            firstNameBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            firstNameBorderColor = (this.firstNameFocused)?
                this.colorGray :
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }
        if (this.state.lastNameWarningOn) {
            lastNameBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            lastNameBorderColor = (this.lastNameFocused)?
                this.colorGray :
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }
        if (this.state.usernameWarningOn) {
            usernameBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            usernameBorderColor = (this.usernameFocused)?
                this.colorGray :
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }
        if (this.state.areaWarningOn) {
            areaBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            areaBorderColor = (this.areaFocused)?
                this.colorGray :
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }

        // Update states
        this.setState(
            {
                profileImageBorderColor: profileImageBorderColor,
                emailBorderColor: emailBorderColor,
                passwordOnceBorderColor: passwordOnceBorderColor,
                passwordTwiceBorderColor: passwordTwiceBorderColor,
                firstNameBorderColor: firstNameBorderColor,
                lastNameBorderColor: lastNameBorderColor,
                usernameBorderColor: usernameBorderColor,
                areaBorderColor: areaBorderColor
            }
        );
    }

    inputOnFocus(event) {
        //console.log("EditUserInfo / inputOnFocus - event.target = ", event.target);

        // Get the target id
        const id = event.target.id;

        // Update focus flag
        if (id === "edit-user-info-username") {
            this.usernameFocused = true;
        }
        else if (id === "edit-user-info-email") {
            this.emailFocused = true;
        }
        else if (id === "edit-user-info-password-once") {
            this.passwordOnceFocused = true;
        }
        else if (id === "edit-user-info-password-twice") {
            this.passwordTwiceFocused = true;
        }
        else if (id === "edit-user-info-first-name") {
            this.firstNameFocused = true;
        }
        else if (id === "edit-user-info-last-name") {
            this.lastNameFocused = true;
        }
        else if (id === "edit-user-info-area") {
            this.areaFocused = true;
        }
        else {
            console.log("[WARNING] EditUserInfo / inputOnFocus - unknown input ID");
        }

        // Update border colors
        this.inputBorderColors();
    }

    inputOnBlur(event) {
        //console.log("EditUserInfo / inputOnBlur - event.target = ", event.target);

        // Get the target id
        const id = event.target.id;

        // Update focus flag and placeholder
        if (id === "edit-user-info-username") {
            this.usernameFocused = false;
        }
        else if (id === "edit-user-info-email") {
            this.emailFocused = false;
        }
        else if (id === "edit-user-info-password-once") {
            this.passwordOnceFocused = false;
        }
        else if (id === "edit-user-info-password-twice") {
            this.passwordTwiceFocused = false;
        }
        else if (id === "edit-user-info-first-name") {
            this.firstNameFocused = false;
        }
        else if (id === "edit-user-info-last-name") {
            this.lastNameFocused = false;
        }
        else if (id === "edit-user-info-area") {
            this.areaFocused = false;
        }
        else {
            console.log("[WARNING] EditUserInfo / inputOnBlur - unknown input ID");
        }

        // Update border colors
        this.inputBorderColors();
    }

    areaResize() {
        this.areaNode.style.height = "auto";
        this.areaNode.style.height = this.areaNode.scrollHeight + "px";
    }

    areaResizeDelayed() {
        //console.log("EditUserInfo / areaResizeDelayed");
        window.setTimeout(
            this.areaResize,
            0
        );
    }


    profileImageClearWarning(callback) {
        // Clear previous timer
        clearTimeout(this.profileImageWarning);

        const borderColor = this.state.profileImageWarningOn? this.focusColor : this.unfocusColor;

        // Update state
        this.setState(
            {
                profileImageBorderColor: borderColor,
                profileImageWarningOn: false,
                profileImageWarningMessage: null
            },
            callback
        );
    }

    profileImageShowWarning(warningMessage, callback) {
        // Clear previous timer
        clearTimeout(this.profileImageWarning);

        // State to update
        const stateToUpdate = {
            profileImageBorderColor: this.warningColor,
            profileImageWarningOn: true,
            profileImageWarningMessage: warningMessage
        };

        // Delay time
        const delayTime = 0;

        // Update state
        this.profileImageWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                callback
            ),
            delayTime
        );
    }

    addProfileImage() {
        //console.log("EditUserInfo / addProfileImage ");

        // Check out attached file
        const file = this.profileImageNode.files[0];
        //console.log("EditUserInfo / addProfileImage - file = ", file);

        // If attached file is a blob
        if (file instanceof Blob) {
            // Get local URL and image properties to enable image preview
            const reader = new FileReader();
            reader.onload = (event) => {
                const profileImageURL = reader.result;
                //console.log("EditUserInfo / addProfileImage - profileImageURL = ", profileImageURL);

                // Create a new image element to get image properties
                const image = new Image();
                image.src = event.target.result;
                image.onload = () => {
                    // Media dimensions
                    const mediaWidth = image.width;
                    const mediaHeight = image.height;

                    // Clear profile image warning
                    this.profileImageClearWarning(this.inputBorderColors);

                    // Reset profile image state
                    this.setState(
                        {
                            // Profile image
                            profileImage: null,
                            profileImageID: null,
                            profileImageURL: profileImageURL,
                            profileImageChanged: false,

                            // Upload process
                            uploadPercentage: 0,
                            uploadComplete: false,
                            uploadProcessPercentage: 0,
                            uploadProcessComplete: false,
                            uploadError: false
                        },

                        () => {
                            // Axios callback
                            const axiosCallback = (response) => {
                                //console.log("EditUserInfo / addProfileImage - axios response.data = ", response.data);

                                // Clear profile image warning
                                this.profileImageClearWarning(this.inputBorderColors);

                                // Clear process interval
                                clearInterval(this.uploadProcessInterval);
                                this.uploadProcessInterval = 0;

                                // Update upload process complete
                                this.setState({
                                    uploadProcessComplete: true,
                                    profileImage: response.data.content,
                                    profileImageID: response.data.content.id,
                                    profileImageChanged: true
                                });
                            };


                            // Attach the file as a part of formdata
                            const formData = new FormData();
                            const uploadPath = "profile";
                            formData.append("uploader_id", this.props.userInfo.id);
                            formData.append("image_file", file);
                            formData.append("width", mediaWidth);
                            formData.append("height", mediaHeight);
                            formData.append("create_mode", "file");
                            formData.append("directory", uploadPath);
                            formData.append("min_required_size", 300);


                            // Upload progress
                            const onUploadProgress = (progressEvent) => {
                                //console.log("EditUserInfo / addProfileImage / onUploadProgress");
                                const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                //console.log("EditUserInfo / addProfileImage / onUploadProgress - uploadPercentage = ", uploadPercentage);

                                // If upload is complete
                                if (uploadPercentage >= 100 && this.state.uploadComplete === false) {
                                    // Update state
                                    this.setState(
                                        {
                                            uploadComplete: true,
                                            uploadPercentage: 100
                                        },
                                        () => {
                                            //console.log("EditUserInfo / addProfileImage - UPLOAD COMPLETE");

                                            this.uploadProcessInterval = setInterval(
                                                () => {
                                                    //console.log("EditUserInfo / addImage - uploadProcessPercentage");

                                                    // Update percentage
                                                    const uploadProcessPercentage = this.state.uploadProcessPercentage +
                                                        Math.round((this.uploadProcessIntervalTime * 100) / this.uploadProcessTime);
                                                    //console.log("EditUserInfo / addImage - uploadProcessPercentage = ", uploadProcessPercentage);

                                                    // If reached 100%
                                                    if (uploadProcessPercentage >= 100) {
                                                        //console.log("EditUserInfo / addImage - PROCESS COMPLETE");

                                                        // Clear interval
                                                        clearInterval(this.uploadProcessInterval);
                                                        //console.log("EditUserInfo / addImage - this.uploadProcessInterval (after clear) - ", this.uploadProcessInterval);
                                                        this.uploadProcessInterval = 0;
                                                        //console.log("EditUserInfo / addImage - this.uploadProcessInterval (after setting to zero) - ", this.uploadProcessInterval);

                                                        this.setState({
                                                            uploadProcessPercentage: 100
                                                        });
                                                    }
                                                    else {
                                                        this.setState({
                                                            uploadProcessPercentage: uploadProcessPercentage
                                                        });
                                                    }
                                                },
                                                this.uploadProcessIntervalTime
                                            );
                                        }
                                    )
                                }
                                else if (uploadPercentage >= 100 && this.state.complete === true) {

                                }
                                else {
                                    this.setState({
                                        uploadPercentage: uploadPercentage
                                    });
                                }
                            };

                            const axiosConfig = {
                                onUploadProgress: onUploadProgress
                            };

                            // Send create image request using axios post with CSRF token
                            postImage(formData, axiosConfig)
                            .then(axiosCallback)
                            .catch(
                                (response) => {
                                    console.log("[WARNING] EditUserInfo / addProfileImage - axios error = ", response);

                                    // Error handling
                                    const errorMessage = "Image Upload Error";
                                    this.profileImageShowWarning(errorMessage, this.inputBorderColors);

                                    // Update state
                                    this.setState(
                                        {
                                            uploadError: true
                                        }
                                    );
                                }
                            );
                        }
                    );
                }
            };

            // Read file
            reader.readAsDataURL(file);
        }
    }

    usernameClearWarning() {
        // Clear previous timer
        clearTimeout(this.usernameWarning);

        // Border color
        const borderColor = this.usernameFocused? this.focusColor : this.unfocusColor;

        // Update state
        this.setState({
            usernameBorderColor: borderColor,
            usernameWarningOn: false,
            usernameWarningMessage: null
        });
    }

    usernameShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.usernameWarning);

        // State to update
        const stateToUpdate = {
            usernameBorderColor: this.warningColor,
            usernameWarningOn: true,
            usernameWarningMessage: warningMessage
        };

        // Update state
        this.usernameWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    usernameOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.usernameClearWarning();

        // Clear previous timer
        clearTimeout(this.usernameWarning);
        clearTimeout(this.usernameChanged);

        // Initialize change flag
        let usernameChanged = false;

        // Get username
        const username = "" + this.usernameNode.value;
        //console.log("EditUserInfo / usernameOnChange - username = ", username);

        // Not enough letters
        if (username.length < 6) {
            // If blank
            if (username.length === 0) {
            }
            // If less than 6 letters
            else if ((username.length > 0) && (username.length < 6)) {
                this.usernameShowWarning("Username must be longer than 6 letters.");
            }

            // Update change flag
            this.usernameChanged = setTimeout(
                this.setState.bind(
                    this,
                    { usernameChanged: false },
                    null
                ),
                this.delayTime
            );
        }
        else {
            // Construct json data
            const dataJSON = {
                username: username
            };

            // Axios callback to execute when the server returns a response
            const axiosCallback = (response) => {
                //console.log("EditUserInfo / usernameOnChange - axios response = ", response);

                // If the username is unique
                if (response.data.status.code === responseResultCodes.OK) {
                    //this.usernameClearWarning();

                    // Update change flag
                    if ((this.usernameNode.value !== "") && (this.usernameNode.value !== this.usernameNode.placeholder)) {
                        usernameChanged = true;
                    }
                }
                // If username error
                else {
                    this.usernameShowWarning(response.data.status.message);
                }


                // Update change flag
                this.usernameChanged = setTimeout(
                    this.setState.bind(
                        this,
                        { usernameChanged: usernameChanged },
                        null
                    ),
                    this.delayTime
                );
           };

            // Send email check request using axios post with CSRF token
            //console.log("EditUserInfo / usernameOnChange - dataJSON = ", dataJSON);
            postUsernameCheck(dataJSON)
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] EditUserInfo / usernameOnChange - axios error = ", response);});
        }
    }

    emailClearWarning() {
        // Clear previous timer
        clearTimeout(this.emailWarning);

        // Border color
        const borderColor = this.emailFocused? this.focusColor : this.unfocusColor;

        // Update state
        this.setState({
            emailBorderColor: borderColor,
            emailWarningOn: false,
            emailWarningMessage: null
        });
    }

    emailShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.emailWarning);

        // State to update
        const stateToUpdate = {
            emailBorderColor: "#950000",
            emailWarningOn: true,
            emailWarningMessage: warningMessage
        };

        // Update state
        this.emailWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    emailOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.emailClearWarning();

        // Clear previous timer
        clearTimeout(this.emailWarning);
        clearTimeout(this.emailChanged);

        // Initialize change flag
        let emailChanged = false;

        const email = "" + this.emailNode.value;
        //console.log("EditUserInfo / emailOnChange - email = ", email);


        if ((email.length === 0) || (!email.includes("@") || !email.includes("."))) {
            if (email.length === 0) {
            }
            else {
                this.emailShowWarning("Please enter a valid email.");
            }

            // Update change flag
            this.emailChanged = setTimeout(
                this.setState.bind(
                    this,
                    { emailChanged: false },
                    null
                ),
                this.delayTime
            );
        }
        else {
            // Construct json data
            const dataJSON = {
                email: email
            };

            // Axios callback to execute when the server returns a response
            const axiosCallback = (response) => {
                //console.log("EditUserInfo / emailOnChange - axios response.data = ", response.data);

                // If the email is unique
                if (response.data.status.code === responseResultCodes.OK) {
                    //this.emailClearWarning();

                    // Update change flag
                    if ((this.emailNode.value !== "") && (this.emailNode.value !== this.emailNode.placeholder)) {
                        emailChanged = true;
                    }
                }
                // If email error
                else {
                    this.emailShowWarning(response.data.status.message);
                }

                // Update change flag
                this.emailChanged = setTimeout(
                    this.setState.bind(
                        this,
                        { emailChanged: emailChanged },
                        null
                    ),
                    this.delayTime
                );
            };

            // Send email check request using axios post with CSRF token
            //console.log("EditUserInfo / emailOnChange - dataJSON = ", dataJSON);
            postEmailCheck(dataJSON)
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] EditUserInfo / emailOnChange - axios error = ",response);});
        }
    }


    passwordOnceClearWarning() {
        // Clear previous timer
        clearTimeout(this.passwordOnceWarning);

        // Border color
        const borderColor = this.passwordOnceFocused? this.focusColor : this.unfocusColor;

        // Update state
        this.setState({
            passwordOnceBorderColor: borderColor,
            passwordOnceWarningOn: false,
            passwordOnceWarningMessage: null
        });
    }

    passwordOnceShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.passwordOnceWarning);

        // State to update
        const stateToUpdate = {
            passwordOnceBorderColor: this.warningColor,
            passwordOnceWarningOn: true,
            passwordOnceWarningMessage: warningMessage
        };

        // Update state
        this.passwordOnceWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    passwordOnceOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.passwordOnceClearWarning();

        // Clear previous timer
        clearTimeout(this.passwordOnceWarning);
        clearTimeout(this.passwordOnceChanged);

        // Initialize change flag
        let passwordChanged = false;

        // Read passwords
        const passwordOnce = "" + this.passwordOnceNode.value;
        const passwordTwice = "" + this.passwordTwiceNode.value;

        if (passwordOnce.length === 0) {
        }
        else if ((passwordOnce.length > 0) && (passwordOnce.length < 8)) {
            this.passwordOnceShowWarning("Password must be at least 8 characters.");
        }
        else {
            if (passwordTwice) {
                if (passwordOnce === passwordTwice) {
                    this.passwordTwiceClearWarning();

                    // Update the flag
                    passwordChanged = true;
                }
                else {
                    this.passwordTwiceShowWarning("The password does not match.");
                }
            }

            // Check the password
            const errorMessage = this.passwordCheck(passwordOnce);

            // If there is an error message
            if (errorMessage) {
                this.passwordOnceShowWarning(errorMessage);
            }
        }

        // Update change flag
        this.passwordOnceChanged = setTimeout(
            this.setState.bind(
                this,
                { passwordChanged: passwordChanged },
                null
            ),
            this.delayTime
        );
    }

    passwordCheck(password) {
        let re,
            count,
            errorMessage,
            errorMessageParts,
            errorMessageSigns;

        errorMessageParts = [];
        errorMessageSigns = [];

        // Set count to 0
        count = 0;

        // Lowercase letter
        re = /[a-z]/;

        if (!re.test(password)) {
            errorMessageParts[0] =  "one lowercase letter";
            errorMessageSigns[0] = 1;
            count += 1;
        }
        else {
            errorMessageParts[0] =  "";
            errorMessageSigns[0] = 0;
        }

        // Uppercase letter
        re = /[A-Z]/;

        if (!re.test(password)) {
            errorMessageParts[1] =  "one uppercase letter";
            errorMessageSigns[1] = 1;
            count += 1;
        }
        else {
            errorMessageParts[1] =  "";
            errorMessageSigns[1] = 0;
        }

        // Number
        re = /[0-9]/;
        if (!re.test(password)) {
            errorMessageParts[2] =  "one number";
            errorMessageSigns[2] = 1;
            count += 1;
        }
        else {
            errorMessageParts[2] =  "";
            errorMessageSigns[2] = 0;
        }

        // Special character
        re = /[!@#$%^&*-+=_.,/?~]/;
        if (!re.test(password)) {
            errorMessageParts[3] =  "one special character";
            errorMessageSigns[3] = 1;
            count += 1;
        }
        else {
            errorMessageParts[3] =  "";
            errorMessageSigns[3] = 0;
        }

        // Construct error message
        errorMessage = "Password must include at least ";

        const firstIndex = errorMessageSigns.indexOf(1);
        const lastIndex = errorMessageSigns.lastIndexOf(1);
        for (let i = 0; i < 4; i++) {
            if (count > 2) {
                if (errorMessageSigns[i] === 1) {
                    if (i === lastIndex) {
                        errorMessage +=  "and " + errorMessageParts[i] + ".";
                    }
                    else {
                        errorMessage += errorMessageParts[i] + ", ";
                    }
                }
            }
            else if (count === 2) {
                if (errorMessageSigns[i] === 1) {
                    if (i === firstIndex) {
                        errorMessage += errorMessageParts[i] + " and ";
                    }
                    else if (i === lastIndex) {
                        errorMessage += errorMessageParts[i] + ".";
                    }
                    else {

                    }
                }
            }
            else {
                if (errorMessageSigns[i] === 1) {
                    errorMessage += errorMessageParts[i] + ".";
                }
            }
        }

        if (count === 0) {
            return false;
        }
        else {
            return errorMessage;
        }
    }


    passwordTwiceClearWarning() {
        // Clear previous timer
        clearTimeout(this.passwordTwiceWarning);

        // Border color
        const borderColor = this.passwordTwiceFocused? this.focusColor : this.unfocusColor;

        // Update state
        this.setState({
            passwordTwiceBorderColor: borderColor,
            passwordTwiceWarningOn: false,
            passwordTwiceWarningMessage: null
        });
    }

    passwordTwiceShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.passwordTwiceWarning);

        // State to update
        const stateToUpdate = {
            passwordTwiceBorderColor: this.warningColor,
            passwordTwiceWarningOn: true,
            passwordTwiceWarningMessage: warningMessage
        };

        // Update state
        this.passwordTwiceWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    passwordTwiceOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.passwordTwiceClearWarning();

        // Clear previous timer
        clearTimeout(this.passwordTwiceWarning);
        clearTimeout(this.passwordTwiceChanged);

        // Initialize change flag
        let passwordChanged = false;

        // Read passwords
        const passwordOnce = "" + this.passwordOnceNode.value;
        const passwordTwice = "" + this.passwordTwiceNode.value;

        if (passwordTwice.length !== 0) {
            if (passwordOnce === passwordTwice) {
                // Update change flag
                passwordChanged = true;
            }
            else {
                this.passwordTwiceShowWarning("The password does not match.");
            }
        }

        // Update change flag
        this.passwordTwiceChanged = setTimeout(
            this.setState.bind(
                this,
                { passwordChanged: passwordChanged },
                null
            ),
            this.delayTime
        );
    }


    firstNameClearWarning() {
        // Clear previous timer
        clearTimeout(this.firstNameWarning);

        // Border color
        const borderColor = (this.firstNameFocused)? this.focusColor : this.unfocusColor;

        // Update state
        this.setState({
            firstNameBorderColor: borderColor,
            firstNameWarningOn: false,
            firstNameWarningMessage: null
        });
    }

    firstNameShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.firstNameWarning);

        // State to update
        const stateToUpdate = {
            firstNameBorderColor: this.warningColor,
            firstNameWarningOn: true,
            firstNameWarningMessage: warningMessage
        };

        // Update state
        this.firstNameWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    firstNameOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.firstNameClearWarning();

        // Clear previous timer
        clearTimeout(this.firstNameWarning);
        clearTimeout(this.firstNameChanged);

        // Read first name
        const firstName = "" + this.firstNameNode.value;

        // Not enough letters
        if (firstName.length < 2) {
            if (firstName.length !== 0) {
                this.firstNameShowWarning("Enter a valid first name.");
            }

            // Update change flag
            this.firstNameChanged = setTimeout(
                this.setState.bind(
                    this,
                    { firstNameChanged: false },
                    null
                ),
                this.delayTime
            );
        }
        else {
            const firstNameChanged = ((this.firstNameNode.value !== "") && (this.firstNameNode.value !== this.firstNameNode.placeholder))? true : false;

            // Update change flag
            this.firstNameChanged = setTimeout(
                this.setState.bind(
                    this,
                    { firstNameChanged: firstNameChanged },
                    null
                ),
                this.delayTime
            );
        }
    }


    lastNameClearWarning() {
        // Clear previous timer
        clearTimeout(this.lastNameWarning);

        // Border color
        const borderColor = (this.lastNameFocused)? this.focusColor : this.unfocusColor;

        // Update state
        this.setState({
            lastNameBorderColor: borderColor,
            lastNameWarningOn: false,
            lastNameWarningMessage: null
        });
    }

    lastNameShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.lastNameWarning);

        // State to update
        const stateToUpdate = {
            lastNameBorderColor: this.warningColor,
            lastNameWarningOn: true,
            lastNameWarningMessage: warningMessage
        };

        // Update state
        this.lastNameWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    lastNameOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.lastNameClearWarning();

        // Clear previous timer
        clearTimeout(this.lastNameWarning);
        clearTimeout(this.lastNameChanged);

        // Get last name
        const lastName = "" + this.lastNameNode.value;

        // Not enough letters
        if (lastName.length < 2) {
            if (lastName.length !== 0) {
                this.lastNameShowWarning("Enter a valid last name.");
            }

            // Update change flag
            this.lastNameChanged = setTimeout(
                this.setState.bind(
                    this,
                    { lastNameChanged: false },
                    null
                ),
                this.delayTime
            );
        }
        else {
            const lastNameChanged = ((this.lastNameNode.value !== "") && (this.lastNameNode.value !== this.lastNameNode.placeholder))? true : false;

            // Update change flag
            this.lastNameChanged = setTimeout(
                this.setState.bind(
                    this,
                    { lastNameChanged: lastNameChanged },
                    null
                ),
                this.delayTime
            );
        }
    }


    areaClearWarning() {
        // Clear previous timer
        clearTimeout(this.areaWarning);

        // Border color
        const borderColor = (this.areaFocused)? this.focusColor : this.unfocusColor;

        // Update state
        this.setState({
            areaBorderColor: borderColor,
            areaWarningOn: false,
            areaWarningMessage: null
        });
    }

    areaShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.areaWarning);

        // State to update
        const stateToUpdate = {
            areaBorderColor: this.warningColor,
            areaWarningOn: true,
            areaWarningMessage: warningMessage
        };

        // Update state
        this.areaWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    areaOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.areaClearWarning();

        // Clear previous timer
        clearTimeout(this.areaWarning);
        clearTimeout(this.areaChanged);

        // Initialize area
        let areaChanged = false;

        // Read area
        const area = "" + this.areaNode.value;

        if (area.length === 0) {
            this.area = null;
        }
        else {
            if (this.area === null) {
                this.areaShowWarning("Choose the closest area from the drop-down menu.");
            }
            else {
                // Update change flag
                areaChanged = true;
            }
        }

        // Update change flag
        this.areaChanged = setTimeout(
            this.setState.bind(
                this,
                { areaChanged: areaChanged },
                null
            ),
            this.delayTime
        );
    }


    render () {
        //console.log("EditUserInfo / render - this.props = ", this.props);
        //console.log("EditUserInfo / render - this.state = ", this.state);
        //console.log("EditUserInfo / render - this.props.userInfo = ", this.props.userInfo);
        //console.log("EditUserInfo / render - this.state.areaChanged = ", this.state.areaChanged);
        //console.log("EditUserInfo / render - this.state.profileImageChanged = ", this.state.profileImageChanged);
        //console.log("EditUserInfo / render - this.state.profileImage = ", this.state.profileImage);

        // Display properties
        const editUserInfoStyle = { display : this.props.editUserInfoOn? "block" : "none" };

        // Button images
        //const facebookImage = getStaticPath("/images/common/facebook.png");
        //const googleImage = getStaticPath("/images/common/google.png");

        const cancelButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") :
            getStaticPath("/images/common/cancel-white.png");

        const imageUploadButtonImage =
            getStaticPath("/images/upload/upload-profile-picture-white.png");

        const noProfileImage = (
            (this.props.colorMode === "day")?
                getStaticPath("/images/common/no-profile-picture-day.png") :
                getStaticPath("/images/common/no-profile-picture-night.png")
        );

        // Profile image
        let profileImage = null;

        // If no picture has been uploaded
        if (this.state.profileImage === null) {
            if (this.state.profileImageURL === null) {
                if (this.props.userInfo !==  null) {
                    if (this.props.userInfo.profile_pic !== null) {
                        profileImage = (this.props.userInfo.profile_pic.external_url === null)?
                            getMediaProperty(this.props.userInfo.profile_pic, "xs", "url", true):
                            url(this.props.userInfo.profile_pic.external_url)
                    }
                    else {
                        profileImage = noProfileImage;
                    }
                }
                else {
                    profileImage = noProfileImage;
                }
            }
            else {
                profileImage = url(this.state.profileImageURL);
            }
        }
        else{
            profileImage = url(this.state.profileImageURL);
        }

        // Submit button
        const passed = (this.checkWarnings() && (
            this.state.fitnessLevelChanged || this.state.profileImageChanged ||
            this.state.emailChanged || this.state.passwordChanged ||
            this.state.firstNameChanged || this.state.lastNameChanged ||
            this.state.usernameChanged || this.state.areaChanged || this.state.bucketModeChanged)
        )? true : false;

        const submitButtonID = (passed)?
        (
            (this.props.colorMode === "day")?
                "edit-user-info-submit-button-active-day" :
                "edit-user-info-submit-button-active-night"
        ) : (
            (this.props.colorMode === "day")?
                "edit-user-info-submit-button-inactive-day" :
                "edit-user-info-submit-button-inactive-night"
        );

        const submitButtonOnClick = (passed)?
            this.submitEdit : null;

        let submitButtonTooltipText;
        let submitButtonTooltipType;
        if (this.checkWarnings()) {
            if (this.state.fitnessLevelChanged || this.state.profileImageChanged ||
            this.state.emailChanged || this.state.passwordChanged ||
            this.state.firstNameChanged || this.state.lastNameChanged ||
            this.state.usernameChanged || this.state.areaChanged) {
                submitButtonTooltipText = "Update Profile";
                submitButtonTooltipType = "info";
            }
            else {
                submitButtonTooltipText = "Make Necessary Changes";
                submitButtonTooltipType = "dark";
            }
        }
        else {
            submitButtonTooltipText = "Fix Errors";
            submitButtonTooltipType = "dark";
        }

        // Slider
        const sliderClassNames = (this.props.colorMode === "day")?
        {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-day",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-day input-range__track--background",
            activeTrack: "input-range-track-day input-range-track-active-day",

            labelContainer: "input-range-label-container-day",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        } : {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-night",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-night input-range__track--background",
            activeTrack: "input-range-track-night input-range-track-active-night",

            labelContainer: "input-range-label-container-night",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        };

        // Get the loader image
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif");


        // Construct drop down menu
        const updateItems = (bucketMode) => {
            this.setState(
                {
                    bucketMode: bucketMode,
                    bucketModeChanged: (this.initialBucketMode[0] !== bucketMode[0])
                },
                () => {
                    //console.log("UploadGallery / render - newMediaCategories = ", newMediaCategories);
                }
            );
        };

        const dropDownMenuProps = {
            colorMode: this.props.colorMode,
            objectIndex: 0,
            selectedItemIndex : this.state.bucketMode[0],
            items: this.state.bucketMode,
            itemLabels: this.bucketModeLabels,
            updateItems: updateItems,
            showSelectedLabel: true,
            width: 70,
            height: 18,
            top: 0,
            left: 0
        };

        const dropDownMenu = (
            <DropDownMenu {...dropDownMenuProps} />
        );

        // Render content
        return (
            <div id = "edit-user-info-modal-container"
                style = { editUserInfoStyle }
            >
                <div id = {(this.props.browserWidth <= 6)?
                        "edit-user-info-modal-small" : "edit-user-info-modal"}
                    className = {(this.props.colorMode === "day")?
                        "modal-day" :
                        "modal-night"}
                >

                    <div id = "edit-user-info-modal__cancel"
                        className = "image-button-weaker-s3"
                        data-tip data-for = "edit-user-info-cancel-button-tooltip"
                        style = {{ backgroundImage : cancelButtonImage }}
                        onClick = {() => { this.cancelEdit(this.resetForm); }}
                    >
                    </div>

                    <div id = "edit-user-info-modal__title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        Update Information
                    </div>

                    <div id = "edit-user-info-profile">
                        <div id = "edit-user-info-profile-level-text"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        >
                            Choose Your Pace
                        </div>
                        <div id = "edit-user-info-profile-level">
                            <InputRange
                                classNames = {sliderClassNames}
                                maxValue = {5}
                                minValue = {1}
                                value = {this.state.fitnessLevel}
                                onChange = {
                                    fitnessLevel => {
                                        if (fitnessLevel === this.props.userInfo.fitness_level) {
                                            this.setState({
                                                fitnessLevel: fitnessLevel,
                                                fitnessLevelChanged: false
                                            });
                                        }
                                        else {
                                            this.setState({
                                                fitnessLevel: fitnessLevel,
                                                fitnessLevelChanged: true
                                            });
                                        }
                                    }
                                }
                                formatLabel = {
                                    fitnessLevel => {
                                        //console.log("EditUserInfo / fitnessLevel = ", fitnessLevel);
                                        return FITNESS_LEVELS[fitnessLevel];
                                    }
                                }
                                showEndLabels = {false}
                                step = {1}
                            />
                        </div>

                        <UploadIndicator
                            index = {0}
                            mediaKey = {0}
                            uploadPercentage = {this.state.uploadPercentage}
                            uploadComplete = {this.state.uploadComplete}
                            uploadProcessPercentage = {this.state.uploadProcessPercentage}
                            uploadProcessComplete = {this.state.uploadProcessComplete}
                            uploadError = {this.state.uploadError}
                            uploadUpdate = {false}
                        />


                        <div id = "edit-user-info-profile-image-loader"
                            className = "image-loader-s4"
                            style = {{ backgroundImage: loaderImage }}
                        >
                            <div id = "edit-user-info-profile-image"
                                className = {(this.props.colorMode === "day")?
                                    "image-cover border-day" : "image-cover border-night"
                                }
                                style = {{
                                    borderColor: this.state.profileImageBorderColor,
                                    backgroundImage: profileImage
                                }}
                            >
                                <div id = "edit-user-info-profile-image-upload-button-container"
                                    className = "image-button-s4"
                                    style = {{
                                        display: ((this.state.uploadComplete && this.state.uploadProcessComplete) || this.state.uploadError)?
                                        "inline-block" : "none",
                                        backgroundImage : imageUploadButtonImage
                                    }}
                                >
                                    <input
                                        id = "edit-user-info-profile-image-upload-button"
                                        type = "file"
                                        name = "profile-image"
                                        accept = "image/*"
                                    />
                                </div>
                            </div>
                        </div>

                        <div id = "edit-user-info-profile-image-warning"
                            className = {(this.props.colorMode === "day")?
                                "warning-day-s2" : "warning-night-s2"}
                            style = {{ display: (this.state.profileImageWarningOn)? "block" : "none" }}>
                            {this.state.profileImageWarningMessage}
                        </div>
                    </div>

                    <form id = {(this.props.browserWidth <= 6)?
                        "edit-user-info-form-small" : "edit-user-info-form"}
                    >
                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-text k4" : "edit-user-info-text w4"}
                            >
                                Username
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-input-small" : "edit-user-info-input"}
                            >
                                <input
                                    type = "text"
                                    id = "edit-user-info-username"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    autoComplete="new-password"
                                    placeholder = {this.usernameFocused? "" : ((this.props.userInfo !== null)? this.props.userInfo.username : "Username")}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.usernameOnChange}
                                    style = {{ borderColor: this.state.usernameBorderColor }}
                                    required
                                />
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-warning warning-day-s2" :
                                "edit-user-info-warning warning-night-s2"}
                            style = {{ display: (this.state.usernameWarningOn)? "block" : "none" }}>
                            {this.state.usernameWarningMessage}
                        </div>

                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-text k4" : "edit-user-info-text w4"}
                            >
                                Email
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-input-small" : "edit-user-info-input"}
                            >
                                <input
                                    type = "text"
                                    id = "edit-user-info-email"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    autoComplete="new-password"
                                    placeholder = {this.emailFocused? "" : ((this.props.userInfo !== null)? this.props.userInfo.email : "Email")}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.emailOnChange}
                                    style = {{ borderColor: this.state.emailBorderColor }}
                                    required
                                />
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-warning warning-day-s2" :
                                "edit-user-info-warning warning-night-s2"}
                            style = {{ display: (this.state.emailWarningOn)? "block" : "none" }}>
                            {this.state.emailWarningMessage}
                        </div>

                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-text k4" : "edit-user-info-text w4"}
                            >
                                Password
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-input-small" : "edit-user-info-input"}
                            >
                                <input
                                    type = "password"
                                    id = "edit-user-info-password-once"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    autoComplete="new-password"
                                    placeholder = {this.passwordOnceFocused? "" : "New password"}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.passwordOnceOnChange}
                                    style = {{ borderColor: this.state.passwordOnceBorderColor }}
                                    required
                                />
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-warning warning-day-s2" :
                                "edit-user-info-warning warning-night-s2"}
                            style = {{ display: (this.state.passwordOnceWarningOn)? "block" : "none" }}>
                            {this.state.passwordOnceWarningMessage}
                        </div>

                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-text k4" : "edit-user-info-text w4"}
                            >
                                Re-enter Password
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-input-small" : "edit-user-info-input"}
                            >
                                <input
                                    type = "password"
                                    id = "edit-user-info-password-twice"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    autoComplete="new-password"
                                    placeholder = {this.passwordTwiceFocused? "" : "Confirm password"}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.passwordTwiceOnChange}
                                    style = {{ borderColor: this.state.passwordTwiceBorderColor }}
                                    required
                                />
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-warning warning-day-s2" :
                                "edit-user-info-warning warning-night-s2"}
                            style = {{ display: (this.state.passwordTwiceWarningOn)? "block" : "none" }}>
                            {this.state.passwordTwiceWarningMessage}
                        </div>

                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-text k4" : "edit-user-info-text w4"}
                            >
                                First Name
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-input-small" : "edit-user-info-input"}
                            >
                                <input
                                    type = "text"
                                    id = "edit-user-info-first-name"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    autoComplete="new-password"
                                    placeholder = {this.firstNameFocused? "" : ((this.props.userInfo !== null)? this.props.userInfo.first_name : "First Name")}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.firstNameOnChange}
                                    style = {{ borderColor: this.state.firstNameBorderColor }}
                                    required
                                />
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-warning warning-day-s2" :
                                "edit-user-info-warning warning-night-s2"}
                            style = {{ display: (this.state.firstNameWarningOn)? "block" : "none" }}>
                            {this.state.firstNameWarningMessage}
                        </div>

                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-text k4" : "edit-user-info-text w4"}
                            >
                                Last Name
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-input-small" : "edit-user-info-input"}
                            >
                                <input
                                    type = "text"
                                    id = "edit-user-info-last-name"
                                    autoComplete="new-password"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    placeholder = {this.lastNameFocused? "" : ((this.props.userInfo !== null)? this.props.userInfo.last_name : "Last Name")}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.lastNameOnChange}
                                    style = {{ borderColor: this.state.lastNameBorderColor }}
                                    required
                                />
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-warning warning-day-s2" :
                                "edit-user-info-warning warning-night-s2"}
                            style = {{ display: (this.state.lastNameWarningOn)? "block" : "none" }}>
                            {this.state.lastNameWarningMessage}
                        </div>

                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-text k4" : "edit-user-info-text w4"}
                            >
                                Area
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-input-small" : "edit-user-info-input"}
                            >
                                <input
                                    type = "text"
                                    id = "edit-user-info-area"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    autoComplete="new-password"
                                    placeholder = {this.areaFocused?
                                        "" : ((this.props.userInfo !== null)?
                                            ((this.props.userInfo.area_name == null)? "" : this.props.userInfo.area_name) : "Area")}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.areaOnChange}
                                    style = {{ borderColor: this.state.areaBorderColor }}
                                    required
                                >
                                </input>
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-warning warning-day-s2" :
                                "edit-user-info-warning warning-night-s2"}
                            style = {{ display: (this.state.areaWarningOn)? "block" : "none" }}>
                            {this.state.areaWarningMessage}
                        </div>

                        <div className = "edit-user-info-row">
                            <div className = {(this.props.colorMode === "day")?
                                "edit-user-info-bucket-mode-text k4" : "edit-user-info-bucket-mode-text w4"}
                            >
                                Home Default Mode
                            </div>
                            <div className = {(this.props.browserWidth <= 6)?
                                "edit-user-info-bucket-mode-menu-small" : "edit-user-info-bucket-mode-menu"}
                            >
                                {dropDownMenu}
                            </div>
                        </div>
                    </form>
                    <div id = "edit-user-info-submit-button-container">
                        <div
                            id = "edit-user-info-submit-button-form"
                            onClick = {submitButtonOnClick}
                        >
                            <input
                                type = "submit"
                                id = {submitButtonID}
                                className = "button-blue-s2"
                                value = "Submit"
                                data-tip data-for = "edit-user-info-submit-button-tooltip"
                            />
                        </div>
                        <ReactTooltip
                            id = "edit-user-info-submit-button-tooltip"
                            className = "tooltip-s2"
                            type = {submitButtonTooltipType}
                            place = "bottom"
                        >
                            <span>{submitButtonTooltipText}</span>
                        </ReactTooltip>
                    </div>
                </div>
            </div>
        );
    }

    componentWllMount() {
        console.log("asdf")
    }

    componentDidMount(){
        // Grab the DOM nodes of inputs
        this.emailNode = document.getElementById("edit-user-info-email");
        this.passwordOnceNode = document.getElementById("edit-user-info-password-once");
        this.passwordTwiceNode = document.getElementById("edit-user-info-password-twice");
        this.firstNameNode = document.getElementById("edit-user-info-first-name");
        this.lastNameNode = document.getElementById("edit-user-info-last-name");
        this.usernameNode = document.getElementById("edit-user-info-username");
        this.areaNode = document.getElementById("edit-user-info-area");
        this.profileImageNode = document.getElementById("edit-user-info-profile-image-upload-button");

        // Set up event listeners for the address input
        this.areaNode.addEventListener("change", this.areaResize, false);
        this.areaNode.addEventListener("cut", this.areaResizeDelayed, false);
        this.areaNode.addEventListener("paste", this.areaResizeDelayed, false);
        this.areaNode.addEventListener("drop", this.areaResizeDelayed, false);
        this.areaNode.addEventListener("keydown", this.areaResizeDelayed, false);
        this.profileImageNode.addEventListener("change", this.addProfileImage, false);

        // Set up google autocomplete
        // const areaAutocomplete = new this.props.google.maps.places.Autocomplete(this.areaNode);
        if (this.props.google !== null) {
            //console.log("EditUserInfo / componentDidMount - this.props.google = ", this.props.google);

            const areaAutocomplete = new this.props.google.maps.places.Autocomplete(this.areaNode);

            // Save scope
            const that = this;

            // Add a Listener to the start point input window
            this.props.google.maps.event.addListener(
                areaAutocomplete,
                "place_changed",
                function() {
                    // Get place from the input address
                    const place = areaAutocomplete.getPlace();
                    //console.log("EditUserInfo / componentDidMount - areaAutocomplete - place = ", place);

                    if (!place.geometry) {
                        //window.alert("Autocomplete"s returned place contains no geometry");
                        return;
                    }

                    // Set start location
                    that.area = [ place.geometry.location.lat(), place.geometry.location.lng() ];
                    const numAddressItems = place.address_components.length;
                    that.areaName = place.address_components[numAddressItems - 3].long_name + ", " + place.address_components[numAddressItems - 2].long_name;
                    //console.log("EditUserInfo / componentDidMount - this.area = ", that.area);
                    //console.log("EditUserInfo / componentDidMount - this.areaName = ", that.areaName);

                    // Change input size
                    that.areaResizeDelayed();

                    // Clear warning
                    that.areaClearWarning();

                    // Update change flag
                    that.setState({
                        areaChanged: true
                    });
                }
            );
        }
        // Set input placeholders
        //console.log("EditUserInfo / componentDidMount - this.props.userinfo = ", this.props.userInfo);

        if (this.props.userInfo !== null) {

            // Fill out the initial values
            this.emailNode.placeholder = this.props.userInfo.email;
            this.firstNameNode.placeholder = this.props.userInfo.first_name;
            this.lastNameNode.placeholder = this.props.userInfo.last_name;
            //this.passwordOnceNode.placeholder = "New password";
            //this.passwordTwiceNode.placeholder = "Confirm password";
            this.usernameNode.placeholder = this.props.userInfo.username;
            if (this.props.userInfo.area_name !== null) {
                this.areaNode.placeholder = this.props.userInfo.area_name;
            }
            else {
                this.areaNode.placeholder = "";
                //this.areaNode.placeholder = "Set Area";
            }
        }
    }

    loadUserInfo(userInfo) {
        // Reset initial bucket mode
        this.initialBucketMode = [ this.bucketModes.indexOf(userInfo.bucket_mode) ];

        // Fill out the initial values
        this.emailNode.placeholder = userInfo.email;
        this.firstNameNode.placeholder = userInfo.first_name;
        this.lastNameNode.placeholder = userInfo.last_name;
        this.usernameNode.placeholder = userInfo.username;
        if (this.props.userInfo.area_name !== null) {
            this.areaNode.placeholder = userInfo.area_name;
        }
        //else {
        //    this.areaNode.placeholder = "Set Area";
        //}
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("EditUserInfo / componentDidUpdate - this.props.userinfo = ", this.props.userInfo);

        // If need an update
        if ((this.props.userInfo !== null) &&
            (JSON.stringify(this.props.userInfo) !== JSON.stringify(prevProps.userInfo))) {

            // Load them into inputs
            this.loadUserInfo(this.props.userInfo);

            // Update state
            this.setState({
                fitnessLevel: this.props.userInfo.fitness_level,
                bucketMode: [ this.bucketModes.indexOf(this.props.userInfo.bucket_mode) ]
            });
        }
        // If logged out
        else if ((prevProps.userInfo !== null) && (this.props.userInfo === null)) {
            // Reset form
            this.resetForm();
        }
    }
}


function mapStateToProps(state) {
    //console.log("mapStateToProps - state.users = ", state.users);
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        editUserInfoOn: state.user.editUserInfoOn,
        google: state.map.google
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeUser, storeEditUserInfoOn }, dispatch);
}

// Export component
export default connect(mapStateToProps, mapDispatchToProps)(EditUserInfo);