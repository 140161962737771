/* 
/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    UserComponents/LevelDictionary.js
--------------------------------------------------------------------------------------------
    Content
    - FITNESS_LEVELS
    - USER_LEVELS
    - CREATOR_LEVELS
============================================================================================
*/


// User physicality (pace) levels
const FITNESS_LEVELS =
{
    "1": "Sleepyhead",
    "2": "Lazyhead",
    "3": "Human",
    "4": "Fasthead",
    "5": "Crazyhead"
};

// User experience levels
const EXPERIENCE_LEVELS = {
    "1": "Tenderfoot",
    "2": "Wanderer",
    "3": "Adventurer",
    "4": "Explorer",
    "5": "Guru"
};

// User creator level
const CREATOR_LEVELS = {
    "1": "User",
    "2": "Helper",
    "3": "Contributor",
    "4": "Creator",
    "5": "Staff"
};


export { FITNESS_LEVELS, EXPERIENCE_LEVELS, CREATOR_LEVELS };