// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Functions
import {
    url,
    getStaticPath,
    getMediaProperty,
    freezeBody,
    unfreezeBody
} from "js/Functions";

// Redux
import {
    storeUserTag,
    storeNewUserTag,
    storeUser,
    storeNewSave
} from "actions";

// Axios
import {
    postUserTag,
    deleteUserTag,
    getUserTags,
    getFriends
} from "requests";

// CSS
import "./UserTag.scss";


class UserTag extends Component {
    constructor(props){
        super(props);

        // Modal DOM node
        this.modalRef = React.createRef();

        // Input DOM node
        this.inputRef = React.createRef();

        // Placeholder for input
        this.inputPlaceholder = "Search friends";

        // Initialize state
        this.state = {
            searchInitiated: false,
            userTagsInfo: [],
            searchedFriendsInfo: []
        };

        // Bind functions
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.inputOnSubmit = this.inputOnSubmit.bind(this);
        this.searchFriends = this.searchFriends.bind(this);

        this.tagFriend = this.tagFriend.bind(this);
        this.untagFriend = this.untagFriend.bind(this);

        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        //console.log("UserTag / componentDidUpdate - remount");

        // Freeze of unfreeze background
        if (this.props.userTag.modalOn === true) {
            freezeBody();
        }
        if (this.props.userTag.modalOn === false) {
            unfreezeBody();
        }

        // Fetch user tags
        if ((this.props.userInfo) && (this.props.userTag.dot !== null)) {
            this.fetchUserTags();
        }

        // Set up event listeners
        this.inputRef.current.addEventListener("keydown", this.inputOnSubmit);
        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);
    }

    componentWillUnmount() {
         // Remove event listeners
        this.inputRef.current.removeEventListener("keydown", this.inputOnSubmit);
        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);
    }

    inputOnFocus(event) {
        event.target.placeholder = "";
    }

    inputOnBlur(event) {
        event.target.placeholder = this.inputPlaceholder;
    }

    inputOnSubmit(event) {
        // KeyCode
        const keyCode = event.which || event.keyCode;

        // If enter was pressed
        if (keyCode === 13) {

            if (this.inputRef.current.value.length > 0) {
                // Search friends
                this.searchFriends(this.inputRef.current.value);
            }
            else {
                // Search friends
                this.searchFriends();
            }

            // Clear input
            //this.inputRef.current.value = "";
        }
    }

    searchFriends(keywords) {
        //console.log("UserTag / searchFriends");

        const axiosCallback = (response) => {
            //console.log("UserTag / searchFriends - response = ", response);

            this.setState({
                searchInitiated: true,
                searchedFriendsInfo: response.data.content.results
            });
        };

        // Send data to server to search friends
        getFriends(this.props.userInfo.username, keywords)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] UserTag / searchFriends - ", response);
            }
        );
    }

    tagFriend(event, friendID) {
        const axiosCallback = (response) => {
            //console.log("UserTag / tagFriend - response = ", response);

            const userTagsInfo = this.state.userTagsInfo.slice();
            userTagsInfo.push(response.data.content);

            this.setState({
                userTagsInfo: userTagsInfo
            });

            // Redux update
            this.props.storeNewUserTag(
                {
                    operation: "add",
                    mode: this.props.userTag.mode,
                    id: this.props.userTag.id,
                    tag: response.data.content
                }
            );

            // Save dot if needed
            if (this.props.userTag.mode === "save" && !this.props.userTag.saved) {
                const userInfo = Object.assign({}, this.props.userInfo);
                userInfo.saved_dots_recent.unshift(this.props.userTag.dot);
                userInfo.saved_dot_count = userInfo.saved_dot_count + 1;

                // Redux update
                this.props.storeUser(userInfo);
                this.props.storeNewSave({
                    id: this.props.userTag.id,
                    saved: true,
                    count: (this.props.userTag.dot.saved_user_count + 1)
                });
            }
        };

        // Data to send
        const dataJSON = {
            tag_type: this.props.userTag.mode,
            taggee_id: friendID,
            dot_id: this.props.userTag.id
        };

        // Send data to server to create a new user tag
        postUserTag(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] UserTag / tagFriend- ", response);
            }
        );
    }

    untagFriend(event, userTagID, index) {
        const axiosCallback = (response) => {
            //console.log("UserTag / untagFriend - response = ", response);

            // Remove corresponding userTagInfo
            const userTagsInfo = this.state.userTagsInfo.slice();
            userTagsInfo.splice(index, 1);

            // Update state
            this.setState({
                userTagsInfo: userTagsInfo
            });

            // Send data to server to create a new user tag
            this.props.storeNewUserTag(
                {
                    operation: "remove",
                    mode: this.props.userTag.mode,
                    id: this.props.userTag.id,
                    tag: response.data.content
                }
            );

            // Re-search friends
            if (this.state.searchInitiated) {
                if (this.inputRef.current.value.length > 0) {
                    // Search friends
                    this.searchFriends(this.inputRef.current.value);
                }
                else {
                    // Search friends
                    this.searchFriends();
                }
            }
        };

        // Send data to server to delete a user tag
        deleteUserTag(userTagID)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] UserTag / untagFriend - ", response);
            }
        );
    }

    fetchUserTags() {
        // Callback
        const axiosCallback = (response) => {
            //console.log("UserTag / fetchUserTags - response = ", response);

            // Update state
            this.setState({
                userTagsInfo: response.data.content
            });
        };

        // Get user tags
        getUserTags(this.props.userInfo.id, this.props.userTag.id, this.props.userTag.mode)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] UserTag / fetchUserTags - ", response);
            }
        );
    }

    escClick(event) {
        // Disappear user tag modal on pressing esc
        if (this.props.userTag.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        this.props.storeUserTag({ modalOn: false, mode: null, id: null, dot: null, saved: null });

        this.setState({
            expanded: false
        });
    }

    render() {
        //console.log("UserTag / render - this.props = ", this.props);
        //console.log("UserTag / render - this.props.userTag = ", this.props.userTag);

        // Tagged friend ids
        const taggedFriendIDs = [];

        const taggedUsers = this.state.userTagsInfo.map(
            (userTagInfo, index) => {
                // Add tagged friend id to the list
                taggedFriendIDs.push(userTagInfo.taggee.id);

                // Tagged friend image
                const taggedFriendImage = (userTagInfo.taggee.profile_pic)?
                    (
                        (userTagInfo.taggee.profile_pic.external_url === null)?
                            getMediaProperty(userTagInfo.taggee.profile_pic, "xs", "url", true):
                            url(userTagInfo.taggee.profile_pic.external_url)
                    ) : (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/no-profile-picture-day.png") :
                            getStaticPath("/images/common/no-profile-picture-night.png")
                    );

                // Return JSX
                return(
                    <div key = {"user-tag-item-" + index.toString()}
                        className = "user-tag-modal-content__item"
                    >
                        <img className= {(this.props.colorMode === "day")?
                                "user-tag-modal-content__item-image image-contain border-day" :
                                "user-tag-modal-content__item-image image-contain border-night"}
                            src = {taggedFriendImage}
                            alt = {""}
                        />
                        <span
                            className = {(this.props.colorMode === "day")?
                                    "user-tag-modal-content__item-name k4" :
                                    "user-tag-modal-content__item-name w4"}
                        >
                            {userTagInfo.taggee.name}
                        </span>
                        <div className = "user-tag-modal-content__item-remove image-contain"
                            style = {{
                                backgroundImage: (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/decrease-solid-light-blue.png") :
                                    getStaticPath("/images/common/decrease-solid-blue.png")
                            }}
                            onClick = {(event) => { this.untagFriend(event, userTagInfo.id, index); }}
                        >
                        </div>
                    </div>
                );
            }
        );

        // Searched friends list
        const searchedFriends = this.state.searchedFriendsInfo.map(
            (searchedFriendInfo, index) => {
                // Is tagged
                const isTagged = (taggedFriendIDs.indexOf(searchedFriendInfo.id) >= 0);

                if (isTagged) {
                    return null;
                }
                else {
                    // Searched friend image
                    const searchedFriendImage = (searchedFriendInfo.profile_pic)?
                    (
                        (searchedFriendInfo.profile_pic.external_url === null)?
                            getMediaProperty(searchedFriendInfo.profile_pic, "xs", "url", true):
                            url(searchedFriendInfo.profile_pic.external_url)
                    ) : (
                            (this.props.colorMode === "day")?
                                getStaticPath("/images/common/no-profile-picture-day.png") :
                                getStaticPath("/images/common/no-profile-picture-night.png")
                        );

                    // Tag friend button
                    const tagFriendButton = (isTagged)? null : (
                        <div className = "user-tag-modal-content__item-add image-contain"
                            style = {{
                                backgroundImage: (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/increase-solid-light-blue.png") :
                                    getStaticPath("/images/common/increase-solid-blue.png")
                            }}
                            onClick = { (event) => { this.tagFriend(event, searchedFriendInfo.id)} }
                        >
                        </div>
                    );

                    return(
                        <div key = {"user-tag-friend-" + index.toString()}
                            className = "user-tag-modal-content__item"
                        >
                            <img className= {(this.props.colorMode === "day")?
                                    "user-tag-modal-content__item-image image-contain border-day" :
                                    "user-tag-modal-content__item-image image-contain border-night"}
                                src = {searchedFriendImage}
                                alt = {""}
                                style = {(isTagged)? { filter: "grayscale(100%)" } : null}
                            />
                            <span
                                className = {(this.props.colorMode === "day")?
                                        "user-tag-modal-content__item-name k4" :
                                        "user-tag-modal-content__item-name w4"}
                            >
                                {searchedFriendInfo.name}
                            </span>
                            {tagFriendButton}
                        </div>
                    );
                }
            }
        );

        // Dot media
        let mediaURL = null;
        let mediaClass = null;
        if (this.props.userTag.dot !== null) {
            // Media info
            const mediaInfo = this.props.userTag.dot.media[0];

            if (mediaInfo.type === "video") {
                if ("xs" in mediaInfo.files) {
                    mediaURL = getMediaProperty(mediaInfo, "xs", "url", true);
                    mediaClass = (this.props.colorMode === "day")?
                        "user-tag-modal-content__image image-cover" :
                        "user-tag-modal-content__image image-cover";
                }
                else {
                    mediaURL = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/video-black.png", true) :
                        getStaticPath("/images/common/video-white.png", true);

                    mediaClass = (this.props.colorMode === "day")?
                        "user-tag-modal-content__video image-actual" :
                        "user-tag-modal-content__video image-actual";
                }
            // }
            // else {
            //     mediaURL = getMediaProperty(mediaInfo, "xs", 'url', true);
            }
            else {
                mediaURL = getMediaProperty(mediaInfo, "s", "url", true);
                mediaClass = (this.props.colorMode === "day")?
                    "user-tag-modal-content__image image-cover" :
                    "user-tag-modal-content__image image-cover";
            }
        }

        // Shadow image
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette-weak.png");

        return (
            <div className = "user-tag-modal"
                style = {{
                    display: (this.props.userTag.modalOn)? "block" : "none"

                }}
            >
                <div className = {(this.props.colorMode === "day")?
                        "user-tag-modal-content modal-day" :
                        "user-tag-modal-content modal-night"}
                    ref = {this.modalRef}
                >
                    <div className = "user-tag-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage:  (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                        onClick = {this.closeModal}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "user-tag-modal-content__title k4" :
                            "user-tag-modal-content__title w4"}
                    >
                        {(this.props.userTag.dot !== null)? this.props.userTag.dot.name : null}
                    </div>

                    <div className = {mediaClass}
                        style = {{ backgroundImage: mediaURL }}
                    >
                        <div className = "user-tag-modal-content__shadow"
                            style = {{ backgroundImage: shadowImage }}
                        >
                        </div>
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "user-tag-modal-content__sub-title k4" :
                            "user-tag-modal-content__sub-title w4"}
                    >
                        {(this.state.userTagsInfo.length === 0)? "Tag a Friend" : "Tagged Friends"}
                    </div>

                    <div className = "user-tag-modal-content__items">
                        {taggedUsers}
                    </div>

                    <input ref = {this.inputRef}
                        id = "user-tag-modal-input"
                        className = {(this.props.colorMode === "day")?
                            "user-tag-modal-content__input input-s3 input-day" :
                            "user-tag-modal-content__input input-s3 input-night"}
                        placeholder = {"Search friends"}
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                    />

                    <div className = "user-tag-modal-content__items">
                        {searchedFriends}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        userTag: state.tag.userTag
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUserTag,
            storeNewUserTag,
            storeUser,
            storeNewSave
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTag);
