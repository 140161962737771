import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import { getStaticPath } from "js/Functions";

// Redux
import { storeResetPasswordTokenExpiredAlert } from "actions";

// CSS
import "./ResetPasswordAlert.scss";


class ResetPasswordTokenExpiredAlert extends Component {
    constructor(props) {
        super(props);

        this.clearAlert = this.clearAlert.bind(this);
    }

    clearAlert() {
        this.props.storeResetPasswordTokenExpiredAlert(false);
    }

    render() {
        if (this.props.resetPasswordTokenExpired === true) {
            return (
                <div className = {(this.props.colorMode === "day")?
                    "alert-reset-password-token-expired alert-reset-password-day" :
                    "alert-reset-password-token-expired alert-reset-password-night"}
                >
                    <div className = "alert-reset-password-token-expired__message">
                        <span className = {(this.props.colorMode === "day")? "k4" : "k4"}
                        >
                            Password Rest link has expired. Please request it again.
                        </span>
                    </div>
                    <div className = "alert-reset-password-token-expired__cancel">
                        <div className = "alert-reset-password-token-expired__cancel-image image-button-s6"
                            style = {{ backgroundImage:  (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cross-white.png") :
                                getStaticPath("/images/common/cross-white.png")
                            }}
                            onClick = { this.clearAlert }
                        >
                        </div>
                    </div>
                </div> 
            );
        } else {
            return null;
        }        
    }
}

function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        resetPasswordTokenExpired: state.alert.resetPasswordTokenExpired
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeResetPasswordTokenExpiredAlert
        },
        dispatch
    ); 
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordTokenExpiredAlert); 