// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import {
    url,
    getStaticPath,
    getMediaProperty,
    freezeBody,
    unfreezeBody
} from "js/Functions";

// Redux 
import { storeFollow, storeWarningAlert } from "actions";

// Axios
import { postFollowRequest, getFollows } from "requests";

// CSS
import "./Follow.scss";


class Follow extends Component {
    constructor(props){
        super(props);

        // Initialize model DOM reference
        this.modalRef = React.createRef();

        // Follow modal container DOM
        this.followContentRef = React.createRef();

        // Initialize state
        this.state = {
            followPage: 1,
            follows: {}
        };

        // Bind functions
        this.follow = this.follow.bind(this);
        this.unfollow = this.unfollow.bind(this);
        this.block = this.block.bind(this);
        this.unblock = this.unblock.bind(this);
        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.followContentOnScroll = this.followContentOnScroll.bind(this);
    }

    unfollow(event, followeeID, page, index) {
        //console.log("Follow / unfollow - this.props = ", this.props);

        // Update states
        const axiosCallback = (response) => {
            //console.log("Follow / unfollow - response.data = ", response.data);

            // Make a copy of loaded dots
            const follows = Object.assign({}, this.state.follows);
            follows[page.toString()][index].action_taken = true;

            // Update state
            this.setState({
                follows: follows
            });
        };

        // Send request
        postFollowRequest("destroy", this.props.follow.id, followeeID)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Follow / unfollow - error = ", response);})
    }

    follow(event, followeeID, page, index) {
        //console.log("Follow / follow - this.props = ", this.props);

        // Update states
        const axiosCallback = (response) => {
            //console.log("Follow / follow - response.data = ", response.data);

            // Make a copy of loaded dots
            const follows = Object.assign({}, this.state.follows);
            follows[page.toString()][index].action_taken = false;

            // Update state
            this.setState({
                follows: follows
            });
        };

        // Send request
        postFollowRequest("create", this.props.follow.id, followeeID)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Follow / follow - error = ", response);})
    }

    block(event, followerID, page, index) {
        //console.log("Follow / block - this.props = ", this.props);

        // Update states
        //const axiosCallback = (response) => {
        //};
    }

    unblock(event, followerID, page, index) {
        //console.log("Follow / unblock - this.props = ", this.props);

        // Update states
        //const axiosCallback = (response) => {
        //};
    }

    escClick(event) {
        // Disappear follow modal on pressing esc
        if (this.props.follow.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        this.props.storeFollow({ modalOn: false, mode: null, id: null, myself: null });
    }

    render() {
        //console.log("Follow / render - this.props = ", this.props);
        //console.log("Follow / render - this.state = ", this.state);
        //console.log("Follow / render - this.props.follows = ", this.props.follow.follows);
        let follows = [];

        // For all pages
        for (let page = 1; page <= this.state.followPage; page++) {
            const followItems = (
                (this.state.follows[page.toString()] !== undefined)?
                    (
                        this.state.follows[page.toString()].map(
                            (follow, index) => {
                                //console.log("Follow / render - follow = ", follow);

                                // Action button
                                const actionButton = (this.props.follow.myself)? (
                                    <div className = {
                                            (this.props.colorMode === "day")?
                                                "follow-modal-content__item-action button-light-blue-gray-s3" :
                                                "follow-modal-content__item-action button-blue-gray-s3"
                                        }
                                        onClick = { 
                                            (this.props.follow.mode === "followers")?
                                                (event) => {
                                                    if (follow.action_taken) {
                                                       this.unblock(
                                                            event,
                                                            userInfo.id,
                                                            page, 
                                                            index
                                                        );
                                                    }
                                                    else {
                                                       this.block(
                                                            event,
                                                            userInfo.id,
                                                            page, 
                                                            index
                                                        );
                                                    }
                                                } : (event) => {
                                                    if (follow.action_taken) {
                                                       this.follow(
                                                            event,
                                                            userInfo.id,
                                                            page, 
                                                            index
                                                        );
                                                    }
                                                    else {
                                                       this.unfollow(
                                                            event,
                                                            userInfo.id,
                                                            page, 
                                                            index
                                                        );
                                                    }
                                                }
                                        }
                                    >
                                        {
                                            (this.props.follow.mode === "followers")?
                                                (
                                                    (follow.action_taken)?
                                                        "Unblock" : "Block"
                                                ) : (
                                                    (follow.action_taken)?
                                                        "Follow" : "Unfollow"
                                                )
                                        }
                                    </div>
                                ) : null;


                                // User info
                                const userInfo = (this.props.follow.mode === "followers")?
                                    follow.follower :
                                    follow.followee;

                                const userImage = (userInfo.profile_pic)?
                                    (
                                        (userInfo.profile_pic.external_url === null)?
                                            getMediaProperty(userInfo.profile_pic, "t", "url", true) :
                                            url(userInfo.profile_pic.external_url)
                                    ) : (
                                        (this.props.colorMode === "day")?
                                            getStaticPath("/images/common/no-profile-picture-day.png") :
                                            getStaticPath("/images/common/no-profile-picture-night.png")
                                    );

                                // JSX
                                return(
                                    <div key = {"follow-modal-item-" + index.toString()}
                                        className = "follow-modal-content__item"
                                    >
                                        <Link to = {`/user/${userInfo.id}`}
                                            onClick = {this.closeModal}
                                        >
                                            <img className= {(this.props.colorMode === "day")?
                                                    "follow-modal-content__item-image image-contain border-day" :
                                                    "follow-modal-content__item-image image-contain border-night"}
                                                src = {userImage}
                                                alt = {""}
                                                style = {(follow.action_taken)? { filter: "grayscale(100%)" } : null}
                                            />
                                        </Link>
                                        <Link to = {`/user/${userInfo.id}`}
                                            onClick = {this.closeModal}
                                        >
                                            <div className = {
                                                    (this.props.colorMode === "day")?
                                                        (
                                                            (follow.action_taken)?
                                                                (
                                                                    (this.props.follow.myself)?
                                                                        "follow-modal-content__item-text follow-modal-content__user-myself g4" :
                                                                        "follow-modal-content__item-text follow-modal-content__user g4"
                                                                ) : (
                                                                    (this.props.follow.myself)?
                                                                        "follow-modal-content__item-text follow-modal-content__user-myself k4" :
                                                                        "follow-modal-content__item-text follow-modal-content__user k4"
                                                                )
                                                        ) : (
                                                            (follow.action_taken)?
                                                                (
                                                                    (this.props.follow.myself)?
                                                                        "follow-modal-content__item-text follow-modal-content__user-myself g4" :
                                                                        "follow-modal-content__item-text follow-modal-content__user g4"
                                                                ) : (
                                                                    (this.props.follow.myself)?
                                                                        "follow-modal-content__item-text follow-modal-content__user-myself w4" :
                                                                        "follow-modal-content__item-text follow-modal-content__user w4"
                                                                )
                                                        )
                                                }
                                            >
                                                {userInfo.name}
                                            </div>
                                        </Link>
                                        {actionButton}
                                    </div>
                                );
                            }
                        )
                    ) : null
            );

            follows.push(followItems);
        }

        return (
            <div className = "follow-modal" 
                style = {{
                    display: (this.props.follow.modalOn)? "block" : "none"
                }}
            >
                <div className = {(this.props.colorMode === "day")? 
                            "follow-modal-content modal-day" : 
                            "follow-modal-content modal-night"} 
                    ref = {this.modalRef}
                >
                    <div className = "follow-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage:  (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                        onClick = {this.closeModal}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")? 
                                "follow-modal-content__title k2" : 
                                "follow-modal-content__title w2"}
                    >
                        {(this.props.follow.mode === "followers")? "Followers" : "Following"}
                    </div>

                    <div ref = {this.followContentRef}
                        id = "follow-modal-content-items"
                        className = "follow-modal-content__items">
                        {follows}
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        //console.log("Follow / componentDidMount - mount");

        // Freeze of unfreeze background
        if (this.props.follow.modalOn === true) {
            freezeBody();
        }
        if (this.props.follow.modalOn === false) {
            unfreezeBody();
        }

        // Fetch the first page
        this.fetchFollows(1);

        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);

        // Add event listeners
        this.followContentRef.current.addEventListener("scroll", this.followContentOnScroll);
    }
    
    componentWillUnmount() {
        //console.log("Follow / componentWillUnmount - unmount");

        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);
        this.followContentRef.current.removeEventListener("scroll", this.followContentOnScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("Follow / componentDidUpdate - update");
    }

    followContentOnScroll() {
        // If end of scroll is reached
        if (Math.floor(this.followContentRef.current.scrollHeight - this.followContentRef.current.scrollTop) === 
                Math.floor(this.followContentRef.current.clientHeight)) {
            //console.log("Follow - followContentOnScroll - end of scroll");

            // Fetch more notifications
            if (this.state.followPage < this.state.followMaxPage) {
                //console.log("Follow - followContentOnScroll - fetching more follows");

                this.fetchFollows(this.state.followPage + 1);
            }
        }

    }

    fetchFollows(page) {
        // Callback
        const axiosCallback = (response) => {
            //console.log("Follow / fetchFollows - response.data = ", response.data);
            
            // Make a copy of loaded dots
            const follows = (page === 1)?
                {} : Object.assign({}, this.state.follows);

            for (let i = 0; i < follows.length; i++) {
                follows[i].action_taken = false;
            }

            // Save newly loaded dots
            follows[page.toString()] = response.data.content.results;

            // Update state
            this.setState({
                follows: follows,
                followPage: page,
                followCount: response.data.content.total_count,
                followMaxPage: response.data.content.total_pages
            });
        }

        if (this.props.follow.mode === "followers") {
            getFollows(null, this.props.follow.id, page)
            .then(axiosCallback)
            .catch(
                (response) => {
                    // console.log("[WARNING] Follow / fetchFollows - ", response);
                }
            );            
        }
        else {
            getFollows(this.props.follow.id, null, page)
            .then(axiosCallback)
            .catch(
                (response) => {
                    // console.log("[WARNING] Follow / fetchFollows - ", response);
                }
            );
        }
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        follow: state.follow.follow
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeFollow, storeWarningAlert }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(Follow); 
