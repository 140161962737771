export const webSocketUrl = () => {
	// Print out websocket mode
	//console.log("webSocket / webSocketUrl - process.env = ", process.env);
	//console.log("webSocket / webSocketUrl - process.env.REACT_APP_WEBSOCKET_MODE = ", process.env.REACT_APP_WEBSOCKET_MODE);

	let endPoint = "";

	if (process.env.REACT_APP_WEBSOCKET_MODE === "local") {
		endPoint = "ws://localhost:8000/";
	}
	else if(process.env.REACT_APP_WEBSOCKET_MODE === "staging") {
		// Browser location
        const loc = window.location;

        // Server only accepts "wss"
	    // const protocol = (loc.protocol === "https://") ? "wss://" : "ws://";
        const protocol = "wss://";

        // Server url is different from client"s loc.host
        let serverUrl = loc.host;

        // Remove www
        serverUrl = serverUrl.replace("www.", "");

        // End point URL
		endPoint = protocol + serverUrl + "/";
	}
	else if(process.env.REACT_APP_WEBSOCKET_MODE === "production") {
		endPoint = "wss://api.thedots.co/"
	}

	// Print out websocket URL
	//console.log("webSocket / webSocketUrl - endPoint = ", endPoint);

	return endPoint
}