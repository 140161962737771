/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    LogIn.js
    - Log in page with three different log in modes
        (1) Regular email / password combination
        (2) Facebook authentication
        (3) Google authentication
--------------------------------------------------------------------------------------------
    Content
    LogIn
============================================================================================
 */


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux"; 

// Redux
import {
    //storeUnseenChatCount,
    //storeUnseenNotificationCount,
    storeColorMode,
    storeUser,
    storeMainMode,
    storeLogInOn,
    storeSignUpOn,
    storeResetPasswordEmailSentAlert,
    storeItinerary,
    storeSaveItinerary
} from "actions";

// Modules
import ReactTooltip from "thedots-tooltip";

// Functions
import {
    logIn,
    logInFacebook,
    logInGoogle
} from "js/AuthFunctions";

import {
    getStaticPath
    //freezeBody,
    //unfreezeBody
} from "js/Functions";

// Axios 
import {
    postEmailCheck,
    postResetEmail,
    postSaveItinerary
} from "requests";

// CSS
import "./LogIn.scss"; 

// Response codes
import {
    responseResultCodes
} from "ResponseCodes";


// Log in component
class LogIn extends Component {
    constructor (props) {
        super(props);

        // Transition and delay times
        this.transitionTime = 500;
        this.delayTime = 1000;

        // Placeholders
        this.emailPlaceholder = "Email";
        this.passwordPlaceholder = "Password";
        this.resetPasswordEmailPlaceholder = "Email Address";

        // DOM nodes
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.resetPasswordEmailRef = React.createRef();

        // Input focused
        this.emailFocused = false;
        this.passwordFocused = false;

        // Input warning timers
        this.warning = null;
        this.facebookGoogleWarning = null;

        // Initialize state
        this.state = {
            // Warning display
            warningOn: false,
            warningMessage: null,

            // Warning display
            facebookGoogleWarningOn: false,
            facebookGoogleWarningMessage: null,

            // Email and password borders
            emailBorderColor: (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray,
            passwordBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,

            // show reset password on click
            resetPassword: false,
            emailNotExist: false,
            emailNotVaild: false,
            userEmail: "",
            emailSendSuccess: null
        };

        // Bind callbacks
        this.logInFacebookSuccess = this.logInFacebookSuccess.bind(this);
        this.logInFacebookFailure = this.logInFacebookFailure.bind(this);
        this.logInGoogleSuccess = this.logInGoogleSuccess.bind(this);
        this.logInGoogleFailure = this.logInGoogleFailure.bind(this);
        this.cancelLogIn = this.cancelLogIn.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.inputBorderColors = this.inputBorderColors.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.escClick = this.escClick.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.forgotPasswordClick = this.forgotPasswordClick.bind(this);
        this.sendEmailClick = this.sendEmailClick.bind(this);
        this.resetPasswordEmailOnChange = this.resetPasswordEmailOnChange.bind(this);
        this.resetPasswordEmailOnFocus = this.resetPasswordEmailOnFocus.bind(this);
        this.resetPasswordEmailOnBlur = this.resetPasswordEmailOnBlur.bind(this);
        this.resetPasswordEmailOnKeyPress = this.resetPasswordEmailOnKeyPress.bind(this);
        this.saveItinerary = this.saveItinerary.bind(this);
        this.logInSuccess = this.logInSuccess.bind(this);
    }

    shouldComponentUpdate (nextProps, nextState) {
        // Prevent re-rendering on exiting resetPassword 
        return ((this.state.resetPassword === true) && (nextState.resetPassword === null))? false : true;
    }

    logInGoogleFailure(error) {
        //console.log("[WARNING] LogIn / logInGoogleFailure - error = ", error);

        // Show Facebook Google log in warning
        this.facebookGoogleShowWarning("Provided Google credentials do not match an existing account.");
    }

    logInGoogleSuccess(authResponse, profileData) {
        //console.log("LogIn / logInGoogleSuccess - authResponse = ", authResponse);
        //console.log("LogIn / logInGoogleSuccess - profileData = ", profileData);

        // Authorize user
        logIn(
            profileData.email,
            null,
            authResponse.idToken,
            "google",
            profileData.profile_pic,
            (loggedIn, userInfo) => {
                //console.log("LogIn / logInGoogleSuccess - loggedIn = ", loggedIn);
                //console.log("LogIn / logInGoogleSuccess - userInfo = ", userInfo);

                // If log in was successful
                if (loggedIn === true && userInfo !== null) {
                    //console.log("LogIn / logInGoogleSuccess - Success");

                    // If temporary itinerary exists
                    if (this.props.saveItinerary !== null) {
                        this.saveItinerary(userInfo);
                    }
                    else {
                        // Log in success
                        this.logInSuccess(userInfo);
                    }
                }
                // If unsuccessful
                else {
                    //console.log("LogIn / logInGoogleSuccess - Failure");

                    // Clear warning
                    this.clearWarning(false);

                    // Show google warning
                    this.facebookGoogleShowWarning("Provided Google credentials do not match an existing account.");
                }
            }
        );
    }

    logInFacebookFailure() {
        //console.log("[WARING] LogIn / logInFacebookFailure");

        // Show Facebook Google log in warning
        this.facebookGoogleShowWarning("Provided Facebook credentials do not match an existing account.");
    }

    logInFacebookSuccess(authResponse, profileData) {
        //console.log("[WARING] LogIn / logInFacebookSuccess");
        //console.log("[WARING] LogIn / logInFacebookSuccess - authResponse = ", authResponse);
        //console.log("[WARING] LogIn / logInFacebookSuccess - profileData = ", profileData);
        
        // Save contenxt
        const that = this;

        // Authorize user
        logIn(
            profileData.email,
            null,
            authResponse.accessToken,
            "facebook",
            profileData.picture.data.url,
            (loggedIn, userInfo) => {
                //console.log("LogIn / logInFacebookSuccess - loggedIn = ", loggedIn);
                //console.log("LogIn / logInFacebookSuccess - userInfo = ", userInfo);

                // If log in was successful
                if (loggedIn === true && userInfo !== null) {
                    //console.log("LogIn / logInFacebookSuccess - Success");

                    // If temporary itinerary exists
                    if (this.props.saveItinerary !== null) {
                        this.saveItinerary(userInfo);
                    }
                    else {
                        // Log in success
                        this.logInSuccess(userInfo);
                    }
                }
                // If unsuccessful
                else {
                    //console.log("LogIn / logInFacebookSuccess - Failure");

                    // Clear log in warning
                    that.clearWarning(false);

                    // Show log in warning
                    that.facebookGoogleShowWarning("Provided Facebook credentials do not match an existing account.");
                }
            }
        );
    }

    // Cancel and close log in menu
    cancelLogIn(callback) {
        //console.log("LogIn / cancelLogIn");

        // Dispatch state to Redux store
        this.props.storeLogInOn(false);

        // Reset the warnings / input values / styles
        window.setTimeout(
            () => {
                // Reset the focus flags
                this.emailFocused = false;
                this.passwordFocused = false;

                // Clear input values
                this.emailRef.current.value = "";
                this.passwordRef.current.value = "";

                // Reset warnings and input styles
                this.resetForm();              
            },
            this.transitionTime
        );
    }

    resetForm() {
        //console.log("LogIn / resetForm");

        // Clear input values
        this.emailRef.current.value = "";
        this.passwordRef.current.value = "";

        // Input focused
        this.emailFocused = false;
        this.passwordFocused = false;

        // Input warning timers
        this.warning = null;
        this.facebookGoogleWarning = null;

        // Update state and input borders
        this.setState(
            {
                warningOn: false,
                warningMessage: null,
                facebookGoogleWarningOn: false,
                facebookGoogleWarningMessage: null
            },
            this.inputBorderColors
        );
    }

    escClick(event) {
        // Close log in on pressed esc key
        if (this.props.logInOn === true && event.keyCode === 27 && !!localStorage.token === false) 
        {
            this.cancelLogIn();
        }
    }

    formSubmit(event) {
        // Disable default form function
        event.preventDefault();

        // Stop event propagation
        event.stopPropagation();

        // Read email and password
        const email = this.emailRef.current.value;
        const password = this.passwordRef.current.value;
        //console.log("LogIn / formSubmit - email = ",this.emailRef.current.value);
        //console.log("LogIn / formSubmit - password = ",this.passwordRef.current.value);

        // Authorize user
        logIn(
            email,
            password,
            null,
            "normal",
            null,
            (loggedIn, userInfo) => {
                // if log in was successful
                if (loggedIn === true && userInfo !==null) {
                    //console.log("LogIn / formSubmit - Success");

                    // If temporary itinerary exists
                    if (this.props.saveItinerary !== null) {
                        this.saveItinerary(userInfo);
                    }
                    else {
                        // Log in success
                        this.logInSuccess(userInfo);
                    }
                }
                // if unsuccessful
                else {
                    // Hide Facebook Google warning
                    this.facebookGoogleClearWarning(false);

                    // Show warning
                    this.showWarning("Provided email and address do not match an existing account.");
                }
            }
        );
    }

    inputBorderColors(callback) {
        //console.log("LogIn / inputBorderColors");

        let emailBorderColor,
            passwordBorderColor;

        // Figure out input border colors
        if (this.state.warningOn) {
            emailBorderColor = (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed;

            passwordBorderColor = (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed;
        }
        else {
            emailBorderColor = (this.emailFocused)? window.colorGray : 
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );

            passwordBorderColor = (this.passwordFocused)? window.colorGray : 
                (
                    (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
                );
        }

        // Update state
        this.setState(
            {
                emailBorderColor: emailBorderColor,
                passwordBorderColor: passwordBorderColor
            }, 
            callback
        );
    }

    inputOnFocus(event) {
        //console.log("LogIn / inputOnFocus - event.target = ", event.target);

        // Remove placeholder
        event.target.placeholder = "";

        // Get the target id
        const id = event.target.id;

        // Update focus flag
        if (id === "log-in-email") {
            this.emailFocused = true;
        }
        else if (id === "log-in-password") {
            this.passwordFocused = true;
        }
        else {
            console.log("[WARNING] LogIn / inputOnFocus - unknown input ID");
        }

        // Update border colors
        this.inputBorderColors();
    }

    inputOnBlur(event) {
        //console.log("LogIn / inputOnBlur - event.target = ", event.target);

        // Get the target id
        const id = event.target.id;

        // Update focus flag and placeholder
        if (id === "log-in-email") {
            this.emailFocused = false;
            event.target.placeholder = this.emailPlaceholder;
        }
        else if (id === "log-in-password") {
            this.passwordFocused = false;
            event.target.placeholder = this.passwordPlaceholder;
        }
        else {
            console.log("[WARNING] LogIn / inputOnBlur - unknown input ID");
        }

        // Update border colors
        this.inputBorderColors();
    }

    inputOnChange() {
        //console.log("LogIn / inputOnChange");

        // Clear Facebook or Google log in warning
        this.facebookGoogleClearWarning(false);

        // Clear log in warning
        this.clearWarning(false);
    }

    resetPasswordEmailOnChange(event) {
        //console.log("LogIn / formResetPasswordEmailOnChange");

        if(event.target.value.length < 1) {
            this.setState({
                emailNotExist: false,
                emailNotVaild: false,
                userEmail: event.target.value
            });
        } else {
            this.setState({ 
                userEmail: event.target.value 
            });
        }
    }

    resetPasswordEmailOnFocus() {
        //console.log("LogIn / resetPasswordEmailOnFocus");

        this.resetPasswordEmailRef.current.placeholder = "";
    }

    resetPasswordEmailOnBlur() {
        //console.log("LogIn / resetPasswordEmailOnBlur");

        this.resetPasswordEmailRef.current.placeholder = this.resetPasswordEmailPlaceholder;
    }

    resetPasswordEmailOnKeyPress(event) {
        //console.log("LogIn / resetPasswordEmailOnKeyPress");

        if (event.key === "Enter" && this.state.userEmail.length > 5) {
            this.sendEmailClick();
        } 
    }

    showWarning(warningMessage) {
        //console.log("LogIn / showWarning");

        // Clear warning timer
        clearTimeout(this.warning);

        // Update state
        this.warning = setTimeout(
            this.setState.bind(
                this,
                {
                    emailBorderColor: (this.props.colorMode === "day")?
                        window.colorLightRed : window.colorRed,
                    passwordBorderColor: (this.props.colorMode === "day")?
                        window.colorLightRed : window.colorRed,
                    warningOn: true,
                    warningMessage: warningMessage
                },
                null
            ),
            this.delayTime
        );
    }

    facebookGoogleShowWarning(warningMessage) {
        //console.log("LogIn / facebookGoogleShowWarning");

        // Clear Facebook or Google warning timer
        clearTimeout(this.facebookGoogleWarning);

        // Update state
        this.facebookGoogleWarning = setTimeout(
            this.setState.bind(
                this,
                {
                    facebookGoogleWarningOn: true,
                    facebookGoogleWarningMessage: warningMessage
                },
                null
            ),
            this.delayTime
        );
    }

    clearWarning(delay) {
        // Only when the warning is on
        if (this.state.warningMessage !== null) {
            //console.log("LogIn / clearWarning");

            // Clear log in warning
            clearTimeout(this.warning);

            // Delay time
            const delayTime = delay? this.delayTime : 0;

            // Update state
            this.warning = setTimeout(
                this.setState.bind(
                    this,
                    {
                        warningOn: false,
                        warningMessage: null
                    },
                    this.inputBorderColors
                ),
                delayTime
            );
        }
    }

    facebookGoogleClearWarning(delay) {
        // Only when the warning is on
        if (this.state.facebookGoogleWarningMessage !== null) {
            //console.log("LogIn / facebookGoogleClearWarning");

            // Clear Facebook or Google log in warning timer
            clearTimeout(this.facebookGoogleWarning);

            // Delay time
            const delayTime = delay? this.delayTime : 0;

            // Update state
            this.facebookGoogleWarning = setTimeout(
                this.setState.bind(
                    this,
                    {
                        facebookGoogleWarningOn: false,
                        facebookGoogleWarningMessage: null
                    },
                    null
                ),
                delayTime
            );
        }
    }

    forgotPasswordClick() {
        //console.log("LogIn / forgotPassword");

        // Update state and reset form
        this.setState(
            {
                resetPassword: true,
            },
            this.resetForm
        );
    }

    sendEmailClick(event) {
        //console.log("LogIn / sendEmailClick");

        if (!this.state.userEmail.includes("@") || !this.state.userEmail.includes(".") ) {
            this.setState({ 
                emailNotVaild: true,
                userEmail: "" 
            })
        } 
        else {
            // Construct json data
            const dataJSON = {
                email: this.state.userEmail
            };

            postEmailCheck(dataJSON)
            .then(response => {
                if(response.data.content.checkSuccess) {
                    this.setState({
                        resetPassword: true,
                        emailNotExist: true,
                        emailNotVaild: false,
                        userEmail: ""
                    });
                } 
                else {
                    postResetEmail(this.state.userEmail)
                    .then(response => {
                        //console.log("Login / sendEmailClick - response = ", response);

                        // Display a success message on top
                        if (response.data.status.code === responseResultCodes.OK) {
                            this.props.storeResetPasswordEmailSentAlert(
                                {
                                    email: this.state.userEmail,
                                    success: true
                                }
                            );

                            this.setState({
                                resetPassword: false,
                                emailNotExist: false,
                                emailNotVaild: false,
                                userEmail: "",
                                emailSendSuccess: null
                            });
                        } else {
                            this.setState({
                                resetPassword: true,
                                emailNotExist: false,
                                emailNotVaild: false,
                                userEmail: "",
                                emailSendSuccess: false
                            });
                        }
                    })
                    .catch(res => {console.log("[WARNING] LogIn / Fail to send email - error = ", res);});

                    // Cancel log in
                    this.cancelLogIn();
                }
            })
            .catch(res => {console.log("[WARNING] LogIn / Fail to check email - error = ", res);});
        }
    }

    saveItinerary(userInfo) {
        if (this.props.saveItinerary !== null) {
            //console.log("LogIn / saveItinerary - this.props.saveItinerary = ", this.props.saveItinerary);

            // Get user info and update states
            const axiosCallback = (response) => {
                //console.log("LogIn / saveItinerary - response.data.content = ", response.data.content);

                // Clear save itinerary
                this.props.storeSaveItinerary(null);

                // Dispatch real itinerary to the redux store
                this.props.storeItinerary(response.data.content);

                // Navigate to the saved itinerary
                this.props.history.push(`/itinerary/${response.data.content.id}`);

                // Log in success
                this.logInSuccess(userInfo);
            };

            // Temporary authentication to go through permissions
            window.axios.defaults.headers.common["Authorization"] = " Token " + localStorage.token;
            window.axiosCSRF.defaults.headers.common["Authorization"] = " Token " + localStorage.token;

            // Request data
            const dataJSON = {
                "itinerary_id": this.props.saveItinerary.id
            };
            //console.log("LogIn / saveItinerary - dataJSON = ", dataJSON);

            // Send find request
            postSaveItinerary(dataJSON)
            .then(axiosCallback)
            .catch(
                (response) => {
                    console.log("[WARNING] LogIn / saveItinerary - error = ", response);
                }
            );
        }
    }

    logInSuccess(userInfo) {
        // Dispatch user info to the Redux store
        this.props.storeUser(userInfo);

        // Store color mode
        this.props.storeColorMode(userInfo.color_mode);

        // Change background color
        if (userInfo.color_mode === "day") {
            // Change body style
            document.body.style.backgroundColor = window.colorAlmostWhite;
        }
        else {
            // Change body style
            document.body.style.backgroundColor = window.colorBlack;
        }

        // Update unseen counts
        //this.props.storeUnseenChatCount(userInfo.unseen_chat_count);
        //this.props.storeUnseenNotificationCount(userInfo.unseen_notification_count);

        // Reset form and cancel log in
        this.cancelLogIn();

        // Clear Facebook Google log in warning
        this.facebookGoogleClearWarning(false);

        // Clear warning
        this.clearWarning(false);        
    }

    render () {
        //console.log("LogIn / render - this.props = ", this.props);
        //console.log("LogIn / render - this.props.saveItinerary = ", this.props.saveItinerary);

        // Log in display properties
        const logInStyle = {
            display : ((this.props.logInOn === true) && (this.state.resetPassword !== true))? "block" : "none",
        };

        // Reset password display properties
        const resetPasswordStyle = {
            display : ((this.props.logInOn === true) && (this.state.resetPassword === true))? "block" : "none",
        };

        // Button images
        const facebookImage = getStaticPath("/images/common/facebook.png");
        const googleImage =  getStaticPath("/images/common/google.png");
        
        const cancelButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") : 
            getStaticPath("/images/common/cancel-white.png");

        const formSubmitImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/log-in-black.png") : 
            getStaticPath("/images/common/log-in-white.png");

        const emailProps = {
            type: "text",
            placeholder: this.emailPlaceholder,
            onFocus: this.inputOnFocus,
            onBlur: this.inputOnBlur,
            onChange: this.inputOnChange,
            autoComplete: "off"
        };

        const passwordProps = {
            type: "password",
            placeholder: this.passwordPlaceholder,
            onFocus: this.inputOnFocus,
            onBlur: this.inputOnBlur,
            onChange: this.inputOnChange,
            autoComplete: "off"
        };

        const resetPasswordEmailProps = {
            type: "text",
            value: this.state.userEmail,
            placeholder: this.resetPasswordEmailPlaceholder,
            autoComplete: "off",
            onFocus: this.resetPasswordEmailOnFocus,
            onBlur: this.resetPasswordEmailOnBlur,
            onChange: this.resetPasswordEmailOnChange,
            onKeyPress: this.resetPasswordEmailOnKeyPress
        };

        return (
            <div>
                <div id = "log-in-modal-container"
                    style = { logInStyle }
                >
                    <div id = {(this.props.browserWidth <= 6)?
                            "log-in-modal-mobile" : "log-in-modal"}
                        className = {(this.props.colorMode === "day")? 
                            "modal-day" : "modal-night"}
                    >
                        <div id = "log-in-modal__cancel"
                            className = {(this.props.colorMode === "day")? 
                                "image-button-weak-s3" : "image-button-s3"}
                            style = {{ backgroundImage : cancelButtonImage }}
                            onClick = {this.cancelLogIn}
                        >
                        </div>

                        <div id = "log-in-modal__title"
                            className = {(this.props.colorMode === "day")? 
                            "k2" : "w2"}
                        >
                            Member Log In
                        </div>

                        <div className = "log-in-row">
                            <div id = "log-in-facebook-container">
                                <div id = "log-in-facebook-button">
                                    <div
                                        data-tip data-for = "log-in-facebook-image"
                                        id = "log-in-facebook-image"
                                        className = {(this.props.colorMode === "day")?
                                            "image-button-strong-reverse-base" : "image-button-base"}
                                        style = {{ backgroundImage : facebookImage }}
                                        onClick = {logInFacebook.bind(null, this.logInFacebookSuccess, this.logInFacebookFailure, this.props.userProfilePicSize)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <ReactTooltip 
                                id = "log-in-facebook-image" 
                                className = "log-in-facebook-image-tooltip tooltip-s2"
                                type = "dark" 
                                place = "right"
                            >
                                <span>Log In Using Facebook</span>
                            </ReactTooltip>
                            <div id = "log-in-google-container">
                                <div
                                    data-tip data-for = "log-in-google-image"
                                    id = "log-in-google-image"
                                    className = {(this.props.colorMode === "day")?
                                        "image-button-strong-reverse-base" : "image-button-base"}
                                    style = {{ backgroundImage : googleImage }}
                                    onClick = {logInGoogle.bind(null, this.logInGoogleSuccess, this.logInGoogleFailure, this.props.userProfilePicSize)}
                                >
                                </div>
                            </div>
                            <ReactTooltip 
                                id = "log-in-google-image" 
                                className = "log-in-google-image-tooltip tooltip-s2"
                                type = "dark" 
                                place = "right"
                            >
                                <span>Log In Using Google</span>
                            </ReactTooltip>
                        </div>

                        <div id = "log-in-facebook-google-warning"
                            className = {(this.props.colorMode === "day")?
                                "warning-day-s2" : "warning-night-s2"}
                            style = {{ display: (this.state.facebookGoogleWarningOn)? "block" : "none" }}
                        >
                            {this.state.facebookGoogleWarningMessage}
                        </div>

                        <div id = "log-in-or"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            or
                        </div>
                        <div className = "log-in-row">
                            <form
                                onSubmit = {this.formSubmit}
                                id = "log-in-form"
                            >
                                <div id = {(this.props.browserWidth <= 6)?
                                    "log-in-inputs-mobile" : "log-in-inputs"}
                                >
                                    <input
                                        ref = {this.emailRef}
                                        type = "text"
                                        id = "log-in-email" {...emailProps}
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s2 input-day" : "input-s2 input-night"}
                                        required
                                        style = {{ borderColor: this.state.emailBorderColor }}
                                    />
                                    <input
                                        ref = {this.passwordRef}
                                        type = "password"
                                        id = "log-in-password" {...passwordProps}
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s2 input-day" : "input-s2 input-night"}
                                        required
                                        style = {{ borderColor: this.state.passwordBorderColor }}
                                    />
                                </div>
                                <input
                                    type = "submit"
                                    value = ""
                                    data-tip data-for = "log-in-submit-button-tooltip"
                                    id = "log-in-submit-button"
                                    className = {(this.props.colorMode === "day")? 
                                        "image-button-weak-s3" : "image-button-s3"}
                                    style = {{ backgroundImage : formSubmitImage }}
                                />
                            </form>
                            <ReactTooltip 
                                id = "log-in-submit-button-tooltip" 
                                className = "log-in-submit-button-tooltip tooltip-s2"
                                type = "dark" 
                                place = "bottom"
                            >
                                <span>Submit</span>
                            </ReactTooltip>
                        </div>
                        <div id = "log-in-warning"
                            className = {(this.props.colorMode === "day")?
                                "warning-day-s2" : "warning-night-s2"}
                            style = {{ 
                                display: (this.state.warningOn)? "block" : "none",
                            }}
                        >
                            {this.state.warningMessage}
                        </div>

                        <div id = "log-in-forgot-password"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"} 
                            onClick = {this.forgotPasswordClick}
                        >
                            Reset Password
                        </div>
                    </div>
                </div>

                <div id = "reset-password-modal-container" 
                    style = { resetPasswordStyle }
                >
                    <div id = {(this.props.browserWidth <= 4)?
                            "reset-password-modal-mobile" : "reset-password-modal"}
                        className = {(this.props.colorMode === "day")? 
                            "modal-day" : "modal-night"}
                    >
                        <div id = "reset-password-modal__cancel"
                            className = "image-button-s3"
                            style = {{ backgroundImage : cancelButtonImage }}
                            onClick = {
                                () => {
                                    this.setState(
                                        {
                                            resetPassword: null,
                                            emailNotExist: false,
                                            emailNotVaild: false
                                        },
                                        () => { this.cancelLogIn(this.clearInput); }
                                    );
                                }
                            }
                        >
                        </div>
                            
                        <div className = "log-in-row">
                            <div id = "reset-password-modal__title"
                                className = {(this.props.colorMode === "day")? "k2" : "w2"}
                            > 
                                Reset Password 
                            </div> 
                            <div id = "reset-password-description"
                                className = {(this.props.colorMode === "day")? "dg4" : "lg4"}
                            > 
                                Enter the email address associated with your account. We’ll email you a link to reset your password.
                            </div>
                            <div id = "reset-password-email"> 
                                <input
                                    ref = {this.resetPasswordEmailRef}
                                    id = "reset-password-email-input"
                                    className = {
                                        (this.props.browserWidth <= 2)?
                                            (
                                                (this.props.colorMode === "day")?
                                                    "reset-password-email-input-mobile input-s2 input-day" :
                                                    "reset-password-email-input-mobile input-s2 input-night"
                                            ) : (
                                                (this.props.colorMode === "day")?
                                                    "reset-password-email-input input-s2 input-day" :
                                                    "reset-password-email-input input-s2 input-night"
                                            )
                                    }
                                    {...resetPasswordEmailProps}
                                />
                                <div id = "reset-password-send-link-button"
                                    className = {(this.props.colorMode === "day")? 
                                        "button-light-blue-s2" : "button-blue-s2"}
                                    onClick = { this.sendEmailClick } >
                                    Send Link 
                                </div> 
                            </div>
                            { this.state.emailNotExist? (
                                <div className = {(this.props.colorMode === "day")?
                                        "reset-password-warning warning-day-s2" :
                                        "reset-password-warning warning-night-s2"}
                                >
                                    No Account Found with the Provided Email.
                                </div> 
                            ): null}
                            { this.state.emailNotVaild? (
                                <div className = {(this.props.colorMode === "day")?
                                        "reset-password-warning warning-day-s2" :
                                        "reset-password-warning warning-night-s2"}
                                >
                                    Please Enter a Valid Email.
                                </div> 
                            ): null}
                            <div id = "reset-password-back">
                                <div id = "reset-password-back-button"
                                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                                    onClick = {() => {
                                        this.setState({
                                            resetPassword: false, 
                                            emailNotExist: false, 
                                            emailNotVaild: false 
                                        });
                                    }}
                                > 
                                    Back to Log In
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escClick, false);
    }

    componentDidMount() {
        // grab the DOM nodes of inputs
        document.addEventListener("keydown", this.escClick, false);
    }
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        logInOn: state.log_in.logInOn,
        userInfo: state.user.userInfo,
        emailPassword: state.alert.emailPassword,
        saveItinerary: state.itinerary.saveItinerary
    };
}

// Dispatch to Redux store
function mapDispatchToProps (dispatch) {
    return bindActionCreators(
        {
            //storeUnseenChatCount,
            //storeUnseenNotificationCount,
            storeUser,
            storeColorMode,
            storeMainMode,
            storeLogInOn,
            storeSignUpOn,
            storeResetPasswordEmailSentAlert,
            storeItinerary,
            storeSaveItinerary
        },
        dispatch
    );
}
// Export component
export default connect(mapStateToProps, mapDispatchToProps)(LogIn); 