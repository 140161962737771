import { STORE_CREATE } from "actions"; 

const INITIAL_STATE = {
    create: {
        modalOn: false,
        mode: null,
        setSlug: null,
        location: null,
        info: null
    }
};

export default function(state=INITIAL_STATE, action) {
    switch(action.type) {
        case STORE_CREATE:
            return { ...state, create: action.payload }; 
        default:
            return state;
    }
};