const dotsAPI = "api/dots/";
const dotAPI = "api/dot/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;


export const getHomeDots = (type, startID, endID) => {
	let URL = (type === "everyday")?
		rootURL + dotsAPI + "home/?type=EV":
		rootURL + dotsAPI + "home/?type=AU";

	if (startID !== null) {
		URL += "&start_id=" + String(startID);
	}

	if (endID !== null) {
		URL += "&end_id=" + String(endID);
	}

	return window.axiosCSRF.get(URL);
};

export const getSetHomeDots = (dotSlug, mode, startID, endID, offsetRows) => {
	let URL = rootURL + dotAPI + dotSlug + "/sethome/?mode=" + mode;

	if (startID !== null) {
		URL += "&start_id=" + String(startID);
	}

	if (endID !== null) {
		URL += "&end_id=" + String(endID);
	}

	if (offsetRows !== null) {
		URL += "&offset_rows=" + String(offsetRows);
	}

	return window.axiosCSRF.get(URL);
};

export const postFetchDots = (dataJSON) => {
	const URL = rootURL + dotsAPI + "fetch/";

	return window.axiosCSRF.post(URL, dataJSON);
};

export const postDot = (dataJSON) => {
	const URL = rootURL + dotsAPI;

	return window.axiosCSRF.post(URL, dataJSON);
};

export const getDot = (dotSlug) => {
	const URL = rootURL + dotAPI + dotSlug + "/";

	return window.axiosCSRF.get(URL)
};

export const patchDot = (dotSlug, dataJSON) => {
	const URL = rootURL + dotAPI + dotSlug + "/";

	return window.axiosCSRF.patch(URL, dataJSON);
};

export const deleteDot = (dotSlug) =>  {
	const URL = rootURL + dotAPI + dotSlug + "/";

	return window.axiosCSRF.delete(URL);
};

export const postSetChildDot = (dotSlug, dataJSON) => {
	const URL = rootURL + dotAPI + dotSlug + "/contributions/";

	return window.axiosCSRF.post(URL, dataJSON);
}

export const getEverydayDots = (username, page, tag) => {
	const URL = (tag !== null)?
		(rootURL + dotsAPI + "?editor__username=" + username + "&type=EV&page=" + page + "&tag=" + tag) :
		(rootURL + dotsAPI + "?editor__username=" + username + "&type=EV&page=" + page);

	return window.axiosCSRF.get(URL);
};

export const postDotLike = (dotSlug, dataJSON) => {
	let URL = rootURL + dotAPI + dotSlug + "/likes/";

	return window.axiosCSRF.post(URL, dataJSON);
};

export const postDotSave = (dotSlug, dataJSON) => {
	let URL = rootURL + dotAPI + dotSlug + "/saves/";

	return window.axiosCSRF.post(URL, dataJSON);
};

export const patchDotSave = (dotSaveID, dataJSON) => {
	let URL = rootURL + dotsAPI + "saves/" + dotSaveID + "/";

	return window.axiosCSRF.patch(URL, dataJSON);
};

export const postCheckLike = (dataJSON) => {
	let URL = rootURL + dotsAPI + "check/like/";

	return window.axiosCSRF.post(URL, dataJSON);
};

export const postCheckSave = (dataJSON) => {
	let URL = rootURL + dotsAPI + "check/save/";

	return window.axiosCSRF.post(URL, dataJSON);
};

export const getDotSaves = (username, page, tag, downloaded) => {
	//console.log("requests / getDotSaves - username = ", username);
	//console.log("requests / getDotSaves - page = ", page);
	//console.log("requests / getDotSaves - tag = ", tag);
	//console.log("requests / getDotSaves - downloaded = ", downloaded);

	let URL = null;

	if (downloaded === null) {
		URL = (tag !== null)?
			(rootURL + dotsAPI + "saves/?user__username=" + username + "&page=" + page + "&tag=" + tag) :
			(rootURL + dotsAPI + "saves/?user__username=" + username + "&page=" + page);
	}
	else {
		URL = (tag !== null)?
			(rootURL + dotsAPI + "saves/?user__username=" + username + "&page=" + page + "&tag=" + tag + "&downloaded=" + downloaded) :
			(rootURL + dotsAPI + "saves/?user__username=" + username + "&page=" + page + "&downloaded=" + downloaded);
	}
	//console.log("requests / getDotSaves - URL = ", URL);

	return window.axiosCSRF.get(URL);
};