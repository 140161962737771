import { STORE_POSTING } from "actions"; 

/*
============================================================================================
	Parameters
--------------------------------------------------------------------------------------------
	posting
	- modalOn: true | false
	- myself: true | false
============================================================================================
*/

const INITIAL_STATE = {
	posting: { modalOn: false, info: null, myself: null }
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_POSTING:
			return { ...state, posting: action.payload }; 
		default:
			return state;
	}
};