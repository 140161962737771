// React / ReactDOM / React-router / Redux
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import { getStaticPath } from "js/Functions";

// Axios
import { getCheckValidToken, putResetPassword } from "requests";

// Redux
import { storeUser, storeResetPasswordTokenExpiredAlert } from "actions";

// CSS
import "./ResetPassword.scss";

// Response codes
import { responseResultCodes } from "ResponseCodes";


class ResetPassword extends Component {
    constructor(props){
        super(props);

        // DOM nodes of input
        this.passwordNewNode = null;
        this.passwordConfirmNode = null;

        // Initialize state
        this.state = { 
            passwordNew: "", 
            passwordConfirm: "", 
            tooShort: false,     // 8 characters 
            oneNum: false,       // one digit
            oneUpper: false,     // one upper character
            oneLower: false,     // one lower character 
            oneSpecial: false,   // one special character
            validPassword: false,
            passwordMatch: null, 
            findToken: false, 
            validToken: false
        };

        // Bind callbacks
        this.setState = this.setState.bind(this);
        this.resetPasswordNewOnFocus = this.resetPasswordNewOnFocus.bind(this);
        this.resetPasswordNewOnBlur = this.resetPasswordNewOnBlur.bind(this);
        this.resetPasswordNewOnChange = this.resetPasswordNewOnChange.bind(this);
        this.resetPasswordConfirmOnFocus = this.resetPasswordConfirmOnFocus.bind(this);
        this.resetPasswordConfirmOnBlur = this.resetPasswordConfirmOnBlur.bind(this);
        this.resetPasswordConfirmOnChange = this.resetPasswordConfirmOnChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.logInSuccess = this.logInSuccess.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // Grab DOM nodes when input components are rendered
        if (prevState.validToken === false && this.state.validToken === true) {
            // Grab the DOM nodes of inputs
            this.passwordNewNode = document.getElementById("reset-password-new");
            this.passwordConfirmNode = document.getElementById("reset-password-confirm");
            //console.log("SetPassword / componentDidMount - this.passwordNewNode = ", this.passwordNewNode);
            //console.log("SetPassword / componentDidMount - this.passwordConfirmNode = ", this.passwordConfirmNode);
        }
    }

    componentDidMount() {
        // Grab the DOM nodes of inputs
        this.passwordNewNode = document.getElementById("reset-password-new");
        this.passwordConfirmNode = document.getElementById("reset-password-confirm");
        //console.log("SetPassword / componentDidMount - this.passwordNewNode = ", this.passwordNewNode);
        //console.log("SetPassword / componentDidMount - this.passwordConfirmNode = ", this.passwordConfirmNode);

        // Check token and expiration 
        getCheckValidToken(this.props.userID)
        .then((response) => {
            console.log("ResetPassword / componentDidMount - response = ", response);
            console.log("ResetPassword / componentDidMount - responseResultCodes = ", responseResultCodes);

            if (response.data.status.code === responseResultCodes.OK) {
                this.setState({
                    validToken: true
                });
            } 
            else if (response.data.status.code === responseResultCodes.TOKEN_INVALID) {
                this.props.storeResetPasswordTokenExpiredAlert(true);
                this.setState({
                    validToken: false
                });
            }
        })
    }

    resetPasswordNewOnFocus(event) {
        event.target.placeholder = "";
    }

    resetPasswordNewOnBlur(event) {
        event.target.placeholder = "New Password";
    }

    resetPasswordNewOnChange(event) {
        event.preventDefault();

        let tooShort = false, oneNum = false, oneUpper = false,     
            oneLower = false, oneSpecial= false;
        // validPassword = false;

        let re; 
        if (event.target.value.length > 7) { tooShort = true; }

        re = /[!@#$%^&*-+=_.,/?~]/;
        if (re.test(event.target.value)) { oneSpecial = true; }

        re = /[A-Z]/;
        if(re.test(event.target.value)) { oneUpper = true; }

        re = /[a-z]/;
        if(re.test(event.target.value)) { oneLower = true; } 

        re = /[0-9]/;
        if (re.test(event.target.value)) { oneNum = true; }
        
        this.setState({ 
            tooShort: tooShort,
            oneNum: oneNum,
            oneUpper: oneUpper,
            oneLower: oneLower,
            oneSpecial: oneSpecial
        });
    }

    resetPasswordConfirmOnFocus(event) {
        event.target.placeholder = "";
    }

    resetPasswordConfirmOnBlur(event) {
        event.target.placeholder = "Confirm Password";
    } 

    resetPasswordConfirmOnChange(event) {
        event.preventDefault();

        //console.log("this.passwordNewNode.value = ", this.passwordNewNode.value);
        //console.log("event.target.value = ", event.target.value);
        let passwordMatch = null;

        if (event.target.value.length > 6) {
            if (this.passwordNewNode.value === event.target.value) {
                passwordMatch = true;
            }
            else {
                passwordMatch = false;
            }
        }

        this.setState({ 
            passwordMatch: passwordMatch
        });
    }

    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    }

    handleSubmit(event) {
        if (this.passwordNewNode.value !== this.passwordConfirmNode.value) {
            this.setState({ passwordMatch: false })
        } 
        else {
            if (this.checkPasswordWarnings() && this.state.passwordMatch === true) {
                putResetPassword(this.passwordNewNode.value, this.props.userID)
                .then((response) => {
                    if (response.data.status.code !== responseResultCodes.OK) {
                        this.props.storeResetPasswordTokenExpiredAlert(true);
                        this.setState({
                            validToken: false
                        });
                    } 
                    else {
                        // Update user info and execute login success callback
                        localStorage.token = response.data.content.token;
                        this.props.storeUser(response.data.content.user);
                        this.props.history.push(`/`);
                        this.logInSuccess();
                    }
                })
            }
        }
    }
    
    checkPasswordWarnings() {
        let warningsCleared = false;

        if ((this.state.tooShort) && 
            (this.state.oneNum) &&
            (this.state.oneUpper) &&
            (this.state.oneLower) &&
            (this.state.oneSpecial)) {
            warningsCleared = true;
        }

        return warningsCleared;
    }

    logInSuccess(successCallback) {
        console.log("SetPassword / logInSuccess");

    }

    render() {        
        if(this.state.validToken === false) {
            return null;
        }
        
        const crossOutImage = (
            <div className = "reset-password-box__cross image-contain"
                style = {{ backgroundImage:  getStaticPath("/images/icons/cross-out-red.png") }}
            ></div>
        );

        return (
            <div id = "reset-password-container">
                <div className = "reset-password-box"> 
                    <div className = "reset-password-box__title w2"> 
                        Reset Password 
                    </div>
                    <div className = "reset-password-box__desc g4">
                        Your password must have at lease 8 characters and include a number, a lowercase letter, an uppercase letter, and a special character.
                    </div>
                    <form>
                        <div className = "reset-password-box__wrap">
                            <input id = "reset-password-new"
                                type = "password"
                                className = "reset-password-box__input input-s2"
                                placeholder = "New Password"
                                onFocus = {this.resetPasswordNewOnFocus}
                                onBlur = {this.resetPasswordNewOnBlur}
                                onChange = {this.resetPasswordNewOnChange}
                            />
                        </div>
                        <div className = "reset-password-box__wrap">
                            <input id = "reset-password-confirm"
                                type = "password"
                                className = "reset-password-box__input input-s2"
                                placeholder = "Confirm Password"
                                onFocus = {this.resetPasswordConfirmOnFocus}
                                onBlur = {this.resetPasswordConfirmOnBlur}
                                onChange = {this.resetPasswordConfirmOnChange}
                             />
                        </div>

                        {this.state.passwordMatch === false ? (
                            <div className = "reset-password-box__warning-password-match warning-s2"> Passwords do mot match </div> 
                        ) : null}

                        {this.checkPasswordWarnings() === false ? (
                            <div className = "reset-password-box__subtitle w4"> Password Must Include</div>
                        ) : null}

                        {this.state.tooShort === false ? (
                            <React.Fragment>
                                {crossOutImage}
                                <div className = "reset-password-box__warning r4"> 8 characters </div> <br/>
                            </React.Fragment>
                        ) : null}
                        {this.state.oneNum === false ? (
                            <React.Fragment>
                                {crossOutImage}
                                <div className = "reset-password-box__warning r4"> A number </div> <br/>
                            </React.Fragment>
                        ) : null}
                        {this.state.oneUpper === false ? (
                            <React.Fragment>
                                {crossOutImage}
                                <div className = "reset-password-box__warning r4"> An uppercase letter </div> <br/>
                            </React.Fragment>
                        ) : null}
                        {this.state.oneLower === false ? (
                            <React.Fragment>
                                {crossOutImage}
                                <div className = "reset-password-box__warning r4"> A lowercase letter </div> <br/>
                            </React.Fragment>
                        ) : null}
                        {this.state.oneSpecial === false ? (
                            <React.Fragment>
                                {crossOutImage}
                                <div className = "reset-password-box__warning r4"> A special character </div> <br/>
                            </React.Fragment>
                        ) : null}
                        
                        {
                            (this.checkPasswordWarnings() && this.state.passwordMatch === true)?
                            (<div className = "reset-password-box__button-container">
                                <div className = "reset-password-box__button font-cabin-medium"
                                    onClick = {this.handleSubmit}
                                >
                                    Submit
                                </div>
                            </div>) : null
                        }
                    </form>
                </div> 
            </div> 
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeResetPasswordTokenExpiredAlert
        },
        dispatch
    ); 
}

// Export component
export default connect(null, mapDispatchToProps)(ResetPassword); 