// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";

// Modules
import polyline from "@mapbox/polyline";

// Functions
import { getStaticPath, arraysOrderEqual } from "js/Functions";

import {
    getMapStyles,
    pointToLatLng,
    latLngToPoint,
    pointsEqual,
    getAverageLocation
} from "./MapFunc";

// CSS
import "./GoogleMap.scss";


class GoogleMap extends Component {
    constructor (props) {
        super (props);

        // Initialize icons
        //this.initMarkerIcons = this.initMarkerIcons.bind(this);
        //var icons = this.initMarkerIcons(props.google);

        // Zoom in level for "overview" or "itinerary" mode
        // - Different from this.props.mapZoom <-- for "dot" mode
        // - zoomOutLevel is not necessary because the map sets its bounds accordingly
        this.zoomInLevel = 15;

        // DOM node of the polygon (for create click events)
        this.rectangleNode = null;

        // Initialize states
        this.state = {
            // Map object (to be initialized)
            map: null,

            // Map mode ( "dots-home" / "create" / "trip-overview" / "itinerary" )
            mapMode: props.mapMode,

            // Map height
            // - Width is specified by this.props.mapWidth
            mapHeight: props.mapHeight,
            mapMinHeight: props.mapMinHeight,
            mapMaxHeight: props.mapMaxHeight,
            mapHeightIncrement: props.mapHeightIncrement,

            // Start and end locations
            startLocation: props.startLocation,
            endLocation: props.endLocation,

            // Markers
            markers: null,
            childMarkers: null,
            startMarker: null,
            endMarker: null,
            tempHoverMarker: null,
            tempClickMarker: null,

            // Hike mode
            displayChildren: null

            /*
            // Marker icons
            markerIcons: icons.markerIcons,
            childMarkerIcons: icons.childMarkerIcons,
            startMarkerIcon: icons.startMarkerIcon,
            endMarkerIcon: icons.endMarkerIcon,
            markerIconRed: icons.markerIconRed,
            markerIconGrey: icons.markerIconGrey,
            markerIconBlack: icons.markerIconBlack,
            */
        };

        // Bind initialize map function
        this.initMap = this.initMap.bind(this);

        // Bind set map height function
        this.setMapHeight = this.setMapHeight.bind(this);

        // Bind reset bounds and refresh funcitons
        this.resetMapBounds = this.resetMapBounds.bind(this);
        this.refreshMap = this.refreshMap.bind(this);

        // Bind marker functions
        this.setMarkers = this.setMarkers.bind(this);
        this.setEndMarkers = this.setEndMarkers.bind(this);

        // Bind zoom in and out functions
        this.zoomInMap = this.zoomInMap.bind(this);
        this.zoomOutMap = this.zoomOutMap.bind(this);

        // Path related functions
        this.addPath = this.addPath.bind(this);
        this.removePath = this.removePath.bind(this);
        this.zoomToFitPath = this.zoomToFitPath.bind(this);
    }


    render() {
        //console.log("Map / render - this.props = ", this.props);
        const mapStyle = {
            height: this.state.mapHeight
        };

        return (
			<div id = {this.props.mapContainerNodeID} className = "map-conatiner">
				<div id = {this.props.mapNodeID}
                    className = "map"
                    style = {mapStyle}
                >
                </div>
			</div>
        )
    }


    componentDidMount() {
        //console.log("Map / componentDidMount - this.props = ", this.props);
        //console.log("Map / componentDidMount - this.state = ", this.state);
        //console.log("Map / componentDidMount - this.props.dotsInfo = ", this.props.dotsInfo);

        // Initialize custom marker prototype
        initCustomMarker(this.props.google);

        // Initialize map
        this.initMap();
    }


    componentDidUpdate(prevProps, prevState) {
        //console.log("Map / componentDidUpdate - this.props = ", this.props);
        //console.log("Map / componentDidUpdate - this.props.itinerary = ", this.props.itinerary);

        // Trigger reset markers
        let resetMarkerSwitch = false;
        let resetEndMarkerSwitch = false;

        // Markers
        // Color mode changed
        if (this.props.colorMode !== prevProps.colorMode) {
            resetMarkerSwitch = true;
            resetEndMarkerSwitch = true;
        }

        // For dot-related modes
        if ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create")) {
            // Markers
            if (((this.props.dotsInfo == null) && (prevProps.dotsInfo != null)) || 
                    ((this.props.dots != null) && (prevProps.dots == null))) {
                resetMarkerSwitch = true;
            }
            else if ((this.props.dotsInfo != null) && (prevProps.dotsInfo != null)) {
                if (!pointsEqual(this.props.dotsInfo[0].location, prevProps.dotsInfo[0].location)) {
                    resetMarkerSwitch = true;
                }
            }
        }
        // For trip-related modes
        else if ((this.props.mapMode === "trip-overview") || (this.props.mapMode === "itinerary")) {
            // Compare itineraries (trips)
            if (!arraysOrderEqual(this.props.itinerary, prevProps.itinerary)) {
                //console.log("Map / componentDidUpdate - itinerary changed");
                resetMarkerSwitch = true;
            }
        }
        else {
            console.log("[WARNING] Map / componentDidUpdate - wrong mapMode - ", this.props.mapMode);
        }

        // End markers
        // For dot-related modes
        if ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create")) {
            // Render end markers when locations are provided or changed
            // Dot mode
            if (this.props.createMode === "dot") {
                // When got a new start location (parking location changed)
                if (this.props.parkingLocation != null) {
                    if ((prevProps.parkingLocation == null)
                        || (!pointsEqual(this.props.parkingLocation, prevProps.parkingLocation))) {
                        resetEndMarkerSwitch = true;
                    }
                }
                // When the start location is deleted (parking location deleted)
                else  {
                    if (prevProps.parkingLocation !== null) {
                        resetEndMarkerSwitch = true;
                    }
                }
            }
            // Trip mode
            else if ((this.props.createMode === "trip") || (this.props.createMode === "route")) {
                // Got a new start location (first map opened / start location changed)
                if ((prevProps.startLocation == null)
                    || (!pointsEqual(this.props.startLocation, prevProps.startLocation))) {
                        resetEndMarkerSwitch = true;
                }
                // Got a new end location (end location clicked or changed)
                if ((this.props.startLocation !== null) && (this.props.endLocation !== null)) {
                    if ((prevProps.endLocation === null)
                        || (!pointsEqual(this.props.endLocation, prevProps.endLocation))) {
                        resetEndMarkerSwitch = true;
                    }
                }

                // Toggled between drivable and undrivable
                if ((prevProps.locationType !== this.props.locationType) ||
                    (prevProps.drivable !== this.props.drivable) ||
                    (prevProps.roundtrip !== this.props.roundtrip) ||
                    (prevProps.loop !== this.props.loop) ||
                    (prevProps.createMode !== this.props.createMode)) {
                    resetEndMarkerSwitch = true;
                }
            }
        }
        /*
        // For trip-related modes ==> Taken care in the Autocomplete event listeners
        else if ((this.props.mapMode == "trip-overview") || (this.props.mapMode == "itinerary")) {
            // Render end markers when locations are provided or changed
            if ((this.props.startLocation != null) && (this.props.endLocation != null)) {
                if (((prevProps.startLocation == null) || (prevProps.endLocation == null))
                    || ((!pointsEqual(this.props.startLocation, prevProps.startLocation)) || (!pointsEqual(this.props.endLocation, prevProps.endLocation)))) {
                    //resetEndMarkerSwitch = true;
                }
            }
        }
        */

        // Reset map bounds mode
        let resetMapBoundsMode = null;
        if ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create")) {
            resetMapBoundsMode = "dot";
        }
        else {
            resetMapBoundsMode = (this.state.displayChildren != null)? "route" : "trip";
        }

        // If dots or itineraries have changed
        if ((resetMarkerSwitch) && (resetEndMarkerSwitch)) {
            this.setMarkers(
                this.props.mapMode,
                false,
                this.setEndMarkers.bind(
                    this,
                    this.state.map,
                    ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create"))?
                        this.props.parkingLocation : this.props.startLocation,
                    this.props.endLocation,
                    this.resetMapBounds.bind(
                        this,
                        resetMapBoundsMode,
                        null
                    )
                )
            );
        }
        else if ((resetMarkerSwitch) && (!resetEndMarkerSwitch)) {
            this.setMarkers(
                this.props.mapMode,
                false,
                this.resetMapBounds.bind(
                    this,
                    resetMapBoundsMode,
                    null
                )
            );
        }
        else if ((!resetMarkerSwitch) && (resetEndMarkerSwitch)) {
            this.setEndMarkers(
                this.state.map,
                ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create"))?
                    this.props.parkingLocation : this.props.startLocation,
                this.props.endLocation,
                this.resetMapBounds.bind(
                    this,
                    resetMapBoundsMode,
                    null
                )
            );
        }
        else {
            if ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create")) {
                if (this.props.locationType !== prevProps.locationType) {
                    this.resetMapBounds(resetMapBoundsMode, null);
                }
            }
        }

        // If the previously selected dot was in the itinerary / selection
        let prevSelectedInItinerary = null;
        if (prevProps.selected !== null) {
            prevSelectedInItinerary = (prevProps.itinerary.indexOf(prevProps.selected) === -1)? false: true;
        }
        //console.log("Map / componentDidUpdate - prevProps.itinerary = ", prevProps.itinerary);
        //console.log("Map / componentDidUpdate - prevProps.selected = ", prevProps.selected);
        //console.log("Map / componentDidUpdate - prevSelectedInItinerary = ", prevSelectedInItinerary);

        // Trigger animations according to the trigger switches
        if (this.props.mapRefreshAnimation) {
            //console.log("Map - mapRefreshAnimation Triggered");
            this.props.resetAnimations(this.refreshMap)
        }
        if (this.props.mapZoomInAnimation) {
            //console.log("Map - mapZoomInAnimation Triggered");
            this.props.resetAnimations(this.zoomInMap.bind(this, prevProps.selected, prevSelectedInItinerary));
        }
        if (this.props.mapZoomOutAnimation) {
            //console.log("Map - mapZoomOutAnimation Triggered");
            this.props.resetAnimations(this.zoomOutMap.bind(this, prevProps.selected, prevSelectedInItinerary));
        }
        if (this.props.mapZoomHikeAnimation) {
            //console.log("Map - mapZoomHikeAnimation Triggered");
            this.props.resetAnimations(
                this.resetMapBounds.bind(
                    this,
                    "child",
                    null
                )
            );
        }

        // Path is added or removed
        if (this.props.path !== null && prevProps.path === null) {
            this.addPath(this.props.path);
        }
        else if (this.props.path === null && prevProps.path !== null) {
            this.removePath();
            if (this.props.selected !== null) {
                this.zoomInMap(this.props.selected);
            }
        }
        else if ((this.props.path !== null && prevProps.path !== null) && (this.props.path !== prevProps.path)) {
            this.removePath();
            this.addPath(this.props.path);
        }
    }


    /*
    ============================================================================================
        setMapHeight
    --------------------------------------------------------------------------------------------
        - Adjust map height
    ============================================================================================
    */

    setMapHeight(heightIncrement) {
        // Calculate new height
        let newMapHeight = this.state.mapHeight + heightIncrement;

        // Limit the range
        if (newMapHeight < this.state.mapMinHeight) {
            newMapHeight = this.state.mapMinHeight;
        }
        if (newMapHeight > this.state.mapMaxHeight) {
            newMapHeight = this.state.mapMaxHeight;
        }

        // Set state and reflect changes
        this.setState(
            {
                mapHeight: newMapHeight
            },
            this.refreshMap
        );

        if (this.props.mapHeightUpdate) {
            this.props.setState({
                mapHeight: newMapHeight
            });
        }
    }


    /*
    ============================================================================================
        initMap
    --------------------------------------------------------------------------------------------
        - Initialize google map
        - Set up end markers if in trip or itinerary modes
        - google : google object (initialized in index.html script tag)
        - mapNodeID : DOM id of the map div
    ============================================================================================
    */

    initMap() {
        //console.log("Map / initMap - this.state = ", this.state);
        //console.log("Map / initMap - this.props = ", this.props);

        // Map DOM node
        const mapNode = document.getElementById(this.props.mapNodeID);
        //console.log("Map / initMap - mapNode = ",mapNode);

        // Get the average location
        let map = null;
        if ((this.props.createMode === "trip") || (this.props.createMode === "route")) {
            // Map settings
            const mapSettings = {
                center: pointToLatLng(this.props.startLocation),
                scrollwheel: false,
                mapTypeId: this.props.google.maps.MapTypeId.TERRAIN,
                clickableIcons: false
            };
            //console.log("Map / initMap - mapSettings = ", mapSettings);

            // Create a map object
            map = new this.props.google.maps.Map(mapNode, mapSettings);
        }
        else {
            const avgLocation = getAverageLocation(this.props.itinerary, this.props.dotsInfo);
            //console.log("Map / initMap - avgLocation = ", avgLocation);
            //console.log("Map / initMap - this.props.itinerary = ", this.props.itinerary);
            //console.log("Map / initMap - this.props.dotsInfo = ", this.props.dotsInfo);

            // Map settings
            const mapSettings = {
                center: pointToLatLng(avgLocation),
                scrollwheel: false,
                mapTypeId: this.props.google.maps.MapTypeId.TERRAIN,
                clickableIcons: false
            };
            //console.log("Map / initMap - mapSettings = ", mapSettings);

            // Create a map object
            map = new this.props.google.maps.Map(mapNode, mapSettings);
        }

        // Save map handle for the parent component
        if ((this.props.setMap) && (this.props.setMap instanceof Function)) {
            this.props.setMap(map);
        }

        // Apply styles
        const mapStyles = getMapStyles();
        map.setOptions({ styles: mapStyles });

        // Set map type
        const mapType = (this.props.mapType == null)? "terrain" : this.props.mapType;
        map.setMapTypeId(mapType);

        // Construct buttons
        const mapButtons = document.createElement("div");
        mapButtons.id = this.props.buttonsNodeID;
        mapButtons.className = "map-buttons";

        // Initialize buttons
        this.initMapButtons(map, mapButtons);

        // Set control attribute of the map
        mapButtons.index = 1;
        map.controls[this.props.google.maps.ControlPosition.LEFT_CENTER].push(mapButtons);

        // When the map is in dots-home or create mode
        if ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create")) {
            // Set start and end markers
            const endMarkers = this.setEndMarkers(
                map, 
                ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create"))?
                    this.props.parkingLocation : this.props.startLocation,
                this.props.endLocation,
                null
            );

            if (this.props.mapMode === "create") {
                // Save context
                const that = this;

                // Set up event listener for map click
                this.props.google.maps.event.addListener(map, "bounds_changed", function() {
                    // Check the rectangle polygon node
                    //console.log("Map / initMap - that.rectangleNode = ", that.rectangleNode);

                    // Remove the existing polygon
                    if (that.rectangleNode != null) {
                        that.rectangleNode.setMap(null);
                    }

                    // Get the map bounds
                    const bounds = map.getBounds();
                    const keysFirst = Object.keys(bounds);
                    const keysSecond = Object.keys(bounds[keysFirst[0]]);
                    /*
                    console.log("Map / initMap - current map bounds = ", bounds);
                    console.log("Map / initMap - current map keysFirst = ", keysFirst);
                    console.log("Map / initMap - current map keysSecond = ", keysSecond);
                    console.log("Map / initMap - 1st point = ", bounds[keysFirst[0]][keysSecond[0]], bounds[keysFirst[1]][keysSecond[1]]);
                    console.log("Map / initMap - 2st point = ", bounds[keysFirst[0]][keysSecond[1]], bounds[keysFirst[1]][keysSecond[1]]);
                    console.log("Map / initMap - 3rd point = ", bounds[keysFirst[0]][keysSecond[1]], bounds[keysFirst[1]][keysSecond[0]]);
                    console.log("Map / initMap - 4th point = ", bounds[keysFirst[0]][keysSecond[0]], bounds[keysFirst[1]][keysSecond[0]]);
                    */

                    // Set up the rectangular polygon
                    that.rectangleNode = new that.props.google.maps.Polygon({
                        paths : [
                            new that.props.google.maps.LatLng(bounds[keysFirst[0]][keysSecond[0]], bounds[keysFirst[1]][keysSecond[1]]),
                            new that.props.google.maps.LatLng(bounds[keysFirst[0]][keysSecond[1]], bounds[keysFirst[1]][keysSecond[1]]),
                            new that.props.google.maps.LatLng(bounds[keysFirst[0]][keysSecond[1]], bounds[keysFirst[1]][keysSecond[0]]),
                            new that.props.google.maps.LatLng(bounds[keysFirst[0]][keysSecond[0]], bounds[keysFirst[1]][keysSecond[0]])
                        ],
                        strokeOpacity: 0,
                        fillOpacity : 0,
                        map : map
                    });
                    //console.log("Map / initMap - that.rectangleNode = ", that.rectangleNode);

                    // Add event listener
                    that.props.google.maps.event.addListener(that.rectangleNode, "click", function(args) {
                        //console.log("Map / initMap - clicked latlng = ", args.latLng.lat(), args.latLng.lng());
                        //console.log("Map / initMap - that.props.locationType  = ", that.props.locationType);

                        // Clear location search input
                        that.props.clearLocationSearch();

                        // Clear GPS inputs (only in create mode)
                        that.props.clearLocationGPS();

                        // Change the location state of the parent component
                        if (that.props.locationType === "start") {
                            that.props.setStartLocation(latLngToPoint(args.latLng));
                        }
                        else if (that.props.locationType === "end") {
                            that.props.setEndLocation(latLngToPoint(args.latLng));
                        }
                        else if (that.props.locationType === "parking") {
                            that.props.setParkingLocation(latLngToPoint(args.latLng));
                        }
                        else {
                            that.props.setLocation(latLngToPoint(args.latLng));
                        }
                    });
                });
            }

            // Set state
            this.setState(
                {
                    map: map,
                    startMarker: endMarkers.startMarker,
                    endMarker: endMarkers.endMarker
                },
                this.setMarkers.bind(
                    this,
                    this.props.mapMode,
                    false,
                    this.resetMapBounds.bind(this, "dot", null)
                )
            );
        }
        // When the map is in trip-overview or itinerary modes
        else if ((this.props.mapMode === "trip-overview") || (this.props.mapMode === "itinerary")) {
            // Set start and end markers
            const endMarkers = this.setEndMarkers(map, this.props.startLocation, this.props.endLocation, null);

            if (typeof this.props.startInputNodeID == "string") {
                // Get the start point input element
                const startInput = document.getElementById(this.props.startInputNodeID);
                const startAutocomplete = new this.props.google.maps.places.Autocomplete(startInput);

                // Save context
                const that = this;

                // Add a Listener to the start point input window
                startAutocomplete.addListener("place_changed", function() {
                    // Get place from the input address
                    const place = startAutocomplete.getPlace();
                    if (!place.geometry) {
                        window.alert("Autocomplete returned place contains no geometry");
                        return;
                    }

                    // Zoom in and center
                    map.setCenter(place.geometry.location);
                    map.setZoom(that.zoomInLevel);

                    // Set start location
                    const startLocation = latLngToPoint(place.geometry.location);
                    const startLocationName = place.name;
                    //console.log("Map / initMap - place = ", place);
                    //console.log("Map / initMap - startLocation = ", startLocation);
                    //console.log("Map / initMap - startLocationName = ", startLocationName);

                    // Update state and update times
                    that.props.setStartLocation(startLocation, startLocationName);

                    // Set start marker position
                    endMarkers.startMarker.setPosition(place.geometry.location);

                    // Determine resetMapBoundsMode
                    const resetMapBoundsMode = (that.props.displayChildren != null)? "route" : "trip";

                    // Return to the overview
                    setTimeout(
                        () => {that.resetMapBounds(resetMapBoundsMode, null);},
                        3000
                    );

                    /*
                    // If the place has a geometry, present it the map.
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    }

                    // Fetch address
                    const startLocationAddress = (place.address_components)? [
                        (place.address_components[0] && place.address_components[0].short_name || ""),
                        (place.address_components[1] && place.address_components[1].short_name || ""),
                        (place.address_components[2] && place.address_components[2].short_name || "")
                    ].join(" ") : null;
                    console.log("Map / initMap - startLocationAddress = ", startLocationAddress);
                    */
                });
            }

            if (typeof this.props.endInputNodeID == "string") {
                // Get the end point input
                const endInput = document.getElementById(this.props.endInputNodeID);
                const endAutocomplete = new this.props.google.maps.places.Autocomplete(endInput);

                // Save context
                const that = this;

                // Add a Listener to the end point input window
                endAutocomplete.addListener("place_changed", function() {
                    // Get place from the input address
                    const place = endAutocomplete.getPlace();
                    if (!place.geometry) {
                        window.alert("Autocomplete returned place contains no geometry");
                        return;
                    }

                    // Zoom in and center
                    map.setCenter(place.geometry.location);
                    map.setZoom(that.zoomInLevel);

                    // Set start location
                    const endLocation = latLngToPoint(place.geometry.location);
                    const endLocationName = place.name;
                    //console.log("Map / initMap - place = ", place);
                    //console.log("Map / initMap - endLocation = ", endLocation);
                    //console.log("Map / initMap - endLocationName = ", endLocationName);

                    // Update state and update times
                    that.props.setEndLocation(endLocation, endLocationName);

                    // Set start marker position
                    endMarkers.endMarker.setPosition(place.geometry.location);

                    // Determine resetMapBoundsMode
                    const resetMapBoundsMode = (that.props.displayChildren != null)? "route" : "trip";

                    // Return to the overview
                    setTimeout(
                        () => {that.resetMapBounds(resetMapBoundsMode, null);},
                        3000
                    );
                });
            }

            // Set state
            this.setState(
                {
                    map: map,
                    startMarker: endMarkers.startMarker,
                    endMarker: endMarkers.endMarker
                },
                this.setMarkers.bind(
                    this,
                    this.props.mapMode,
                    false,
                    this.resetMapBounds.bind(this, "trip", null)
                )
            );
        }
        else {
            //console.log("[WARNING] Map / initMap - wrong mapMode - ", this.props.mapMode);
        }
    }


    /*
    ============================================================================================
        initMapButtons
    --------------------------------------------------------------------------------------------
        - Initialize map buttons and set up event listeners
        - map : DOM handle of the map object
        - mapButtons : DOM handles of the map zoom button objects
        - mapHeight : a state variable passed down from the parent
    ============================================================================================
    */

    initMapButtons(map, mapButtons) {
        // Adjust map height callback
        const setMapHeight = this.setMapHeight;

        // Map height increment size
        const mapHeightIncrement = this.state.mapHeightIncrement;

        // Create a new div
        const divIncrease = document.createElement("div");

        // Set id and attributes
        const increaseButtonID = this.props.mapNodeID + "-button-increase";
        divIncrease.id = increaseButtonID;
        divIncrease.className = "map-button-increase";
        divIncrease.setAttribute("title", "Increase Map Size");
        divIncrease.style.backgroundImage = getStaticPath("/images/map/map-size-up.png");

        // Insert into container
        mapButtons.appendChild(divIncrease);

        // Click event
        divIncrease.addEventListener("click", function(){
            // Execute callback to adjust height and update state
            setMapHeight(mapHeightIncrement);
        });
      
        // Create a new div
        const divDecrease = document.createElement("div");

        // Set id and attributes
        const decreaseButtonID = this.props.mapNodeID + "-button-decrease";
        divDecrease.id = decreaseButtonID;
        divDecrease.className = "map-button-decrease";
        divDecrease.setAttribute("title", "Decrease Map Size");
        divDecrease.style.backgroundImage = getStaticPath("/images/map/map-size-down.png");

        // Insert into container
        mapButtons.appendChild(divDecrease);

        // Add a click event listener
        divDecrease.addEventListener("click", function(){
            // Execute callback to adjust height and update state
            setMapHeight(-mapHeightIncrement);
        });
    }



    /*
    ============================================================================================
        setEndMarkers
    --------------------------------------------------------------------------------------------
        - Set end point markers
        - Set up hover event listeners
    ============================================================================================
    */

    setEndMarkers(map, startLocation, endLocation, callback) {
        //console.log("Map / setEndMarkers");

        // Remove previous markers if any
        if (this.state.startMarker) {
            this.state.startMarker.remove();
        }
        if (this.state.endMarker) {
            this.state.endMarker.remove();
        }

        let startMarker, endMarker = null;
        if ((typeof startLocation === "object") && (startLocation != null)) {
            // Convert location to a LatLng object
            const startLatLng = pointToLatLng(startLocation);
            //console.log("Map / setEndMarkers - startLatLng = ", startLatLng);

            // Set marker icons
            let startMarkerIcon = null;
            let startMarkerName = null;
            if ((this.props.mapMode === "dots-home") ||
                (this.props.createMode === "dot") ||
                (((this.props.createMode === "trip") || (this.props.createMode === "route"))
                    && (this.props.roundtrip || this.props.loop) && (this.props.drivable))) {

                startMarkerIcon = (this.props.colorMode === "day")?
                    getStaticPath("/images/number/single_white_P.png") :
                    getStaticPath("/images/number/single_black_P.png");
                startMarkerName = "Parking";
            }
            else {
                startMarkerIcon = (this.props.colorMode === "day")?
                    getStaticPath("/images/number/single_black_S.png") :
                    getStaticPath("/images/number/single_white_S.png");
                startMarkerName = "Start Point";
            }

            // Set up start marker
            startMarker = new customMarker(
                startLatLng,
                map,
                {
                    id: "marker-start",
                    type: "start",
                    name: startMarkerName,
                    icon: startMarkerIcon,
                    selected: false,
                    endHoverOn: this.props.endHoverOn,
                    endHoverOff: this.props.endHoverOff
                }
            );
        }

        if ((typeof endLocation == "object") && (endLocation != null)) {
            // Convert location to a LatLng object
            const endLatLng = pointToLatLng(endLocation);
            //console.log("Map / setEndMarkers - endLatLng = ", endLatLng);

            // Set marker icons
            const endMarkerIcon = (this.props.colorMode === "day")?
                getStaticPath("/images/number/single_black_E.png") :
                getStaticPath("/images/number/single_white_E.png");

            // Set up start marker
            endMarker = new customMarker(
                endLatLng,
                map,
                {
                    id: "marker-end",
                    type: "end",
                    name: "End Point",
                    icon: endMarkerIcon,
                    selected: false,
                    endHoverOn: this.props.endHoverOn,
                    endHoverOff: this.props.endHoverOff
                }
            );
        }

        // Update states
        this.setState(
            {
                startMarker: startMarker,
                endMarker: endMarker
            }, callback
        );

        // Return marker objects to set up event listeners
        return({
            startMarker: startMarker,
            endMarker: endMarker
        })
    }


    /*
    ============================================================================================
        setMarkers
    --------------------------------------------------------------------------------------------
        - Clear old markers and set new markers
    ============================================================================================
    */

    setMarkers(mapMode, keepSelected, callback) {
        //console.log("Map / setMarkers");
        //console.log("Map / setMarkers - this.props = ", this.props);
        //console.log("Map / setMarkers - this.state = ", this.state);

        // Clear previous markers
        if (this.state.markers != null) {
            //console.log("Map / setMarkers - removing markers = ");
            for (let i = 0; i < this.state.markers.length; i++){
                //console.log("Map / setMarkers - removing marker ", i);
                this.state.markers[i].remove();
            }
            //console.log("Map / setMarkers - after removal - this.state = ", this.state);
        }
        if (this.state.childMarkers != null) {
            for (let i = 0; i < this.state.childMarkers.length; i++){
                this.state.childMarkers[i].remove();
            }
        }

        // Initialize marker arrays
        const markers = [];
        const childMarkers = [];

        // Set up counter
        let count = 0;
        let countChildren = 0;

        //console.log("Map / setMarkers - this.props.dotsInfo = ", this.props.dotsInfo);
        //console.log("Map / setMarkers - this.props.itinerary = ", this.props.itinerary);

        // For all dots
        for (let i = 0; i < this.props.itinerary.length; i++){

            // Get element dot index
            const dotIndex = this.props.itinerary[i];

            // If child dots of the dot are displayed
            if (this.props.displayChildren === dotIndex) {
                // For all child dots
                for (let j = 0; j < this.props.dotsInfo[dotIndex].children.length; j++){
                    // Get child dot index
                    const childIndex = j;

                    // Create a new child marker
                    const selected = (j === this.props.selectedChild)? true : false;

                    // Get DOM IDs
                    const markerNodeID = "child-marker-" + childIndex;
                    const childNodeID = "child-dot-" + dotIndex + "-" + childIndex;
                    const curationNodeID = "child-curation-" + dotIndex + "-" + childIndex;
                    const parentNodeID = "dot-" + dotIndex;
                    const parentCurationNodeID = "curation-" + dotIndex;

                    // Get info
                    const name = this.props.dotsInfo[dotIndex].children[childIndex].name;
                    const location = this.props.dotsInfo[dotIndex].children[childIndex].location;
                    const latlng = pointToLatLng(location);
                    const markerIcon = (mapMode === "trip-overview")? (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/number/single_white_" + (countChildren + 1) + ".png") :
                            getStaticPath("/images/number/single_black_" + (countChildren + 1) + ".png")
                    ) : (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/number/single_red_" + (countChildren + 1) + ".png") :
                            getStaticPath("/images/number/single_red_" + (countChildren + 1) + ".png")
                    );

                    // Set up a child marker
                    const marker = new customMarker(
                        latlng,
                        this.state.map,
                        {
                            id: markerNodeID,
                            type: "child",
                            dotInfo: this.props.dotsInfo[dotIndex],
                            name: name,
                            icon: markerIcon,
                            selected: selected,
                            childIndex: childIndex,
                            childDotID: childNodeID,
                            curationID: curationNodeID,
                            parentDotIndex: dotIndex,
                            parentDotID: parentNodeID,
                            parentCurationID: parentCurationNodeID,
                            dotClick: this.props.dotClick,
                            dotHoverOn: this.props.dotHoverOn,
                            dotHoverOff: this.props.dotHoverOff,
                            mapMode: this.props.mapMode
                        }
                    );

                    // Add the marker and window to the arrays
                    childMarkers.push(marker);

                    // Increase counter
                    countChildren += 1;
                }
            }

            // If the dot is selected
            const selected = (this.props.itinerary[i] === this.props.selected)? true : false;

            // Get DOM IDs
            const markerNodeID = "marker-" + dotIndex;
            const dotNodeID = "dot-" + dotIndex;
            const curationNodeID = "curation-" + dotIndex;

            // Get info
            const name =  this.props.dotsInfo[dotIndex].name;
            const location = this.props.dotsInfo[dotIndex].location;
            const latlng = new pointToLatLng(location);

            //console.log("Map / setMarkers - dotIndex = ", dotIndex);
            // Get icon
            let markerIcon;
            if (mapMode === "dots-home") {
                markerIcon = getStaticPath("/images/map/dot-marker-black.png");
            }
            else if (mapMode === "create") {
                markerIcon = getStaticPath("/images/map/dot-marker-black.png");
            }
            else if (mapMode === "trip-overview") {
                markerIcon = (this.props.colorMode === "day")?
                    getStaticPath("/images/number/single_white_" + (count + 1) + ".png") :
                    getStaticPath("/images/number/single_black_" + (count + 1) + ".png");
            }
            else if (mapMode === "itinerary") {
                markerIcon = getStaticPath("/images/number/single_red_" + (count + 1) + ".png");
            }
            else {
                console.log("[WARNING] Map / setMarkers - wrong map mode - ", mapMode);
            }
            //console.log("Map / setMarkers - markerIcon = ", markerIcon);

            // Set up a marker
            const marker = new customMarker(
                latlng,
                this.state.map,
                {
                    id: markerNodeID,
                    type: "selection",
                    dotInfo: this.props.dotsInfo[dotIndex],
                    name: name,
                    icon: markerIcon,
                    selected: selected,
                    dotIndex: dotIndex,
                    dotID: dotNodeID,
                    curationID: curationNodeID,
                    dotClick: this.props.dotClick,
                    dotHoverOn: this.props.dotHoverOn,
                    dotHoverOff: this.props.dotHoverOff,
                    mapMode: this.props.mapMode
                }
            );

            // Add the marker and window to the arrays
            markers.push(marker);

            // Increase counter
            count += 1;

            //console.log("Map / setMarkers - created ", count, " markers");
            //console.log("Map / setMarkers - markers = ", markers);
        }

        // If selected dot is in the optional group
        //if (this.props.itinerary.indexOf(this.props.selected) == -1) {
            //getTempMarker(props.selected);
        //}
        //console.log("Map / setMarkers - markers = ", markers);
        //console.log("Map / setMarkers - childMarkers = ", childMarkers);

        // Set state and refresh map
        this.setState(
            {
                markers: markers,
                childMarkers: childMarkers
            },
            callback
        );
    }


    /*
    ============================================================================================
        resetMapBounds
    --------------------------------------------------------------------------------------------
        - Three different modes "dot" / "trip" / "route"
        - Reset the maps bounds
        - Replaces old functions
    ============================================================================================
    */

    resetMapBounds(mode, callback) {
        //console.log("Map / resetMapBounds");
        //console.log("Map / resetMapBounds - mode = ", mode);
        //console.log("Map / resetMapBounds - this.state.markers = ", this.state.markers);

        // Dot mode (display one dot / fixed scale / recenter only)
        if (mode === "dot") {
            //console.log("Map / resetMapBounds - dot mode");
            //console.log("Map / resetMapBounds - this.state.map.getZoom() = ", this.state.map.getZoom());

            let mapZoom = null;
            if (typeof this.state.map.getZoom() === "undefined") {
                //console.log("Map / resetMapBounds - mapZoom undefined");
                mapZoom = (this.props.mapZoom == null)? 14 : this.props.mapZoom;
            }
            else {
                //console.log("Map / resetMapBounds - mapZoom getZoom");
                mapZoom = this.state.map.getZoom();
            }

            let mapCenter = null;

            // Create trip mode
            if ((this.props.createMode === "trip") || (this.props.createMode === "route")) {
                if (this.props.endLocation == null) {
                    mapCenter = pointToLatLng(this.props.startLocation)
                    this.state.map.setZoom(mapZoom);
                    this.state.map.setCenter(mapCenter);
                }
                else {
                    if (this.props.locationType === "start") {
                        mapCenter = pointToLatLng(this.props.startLocation)
                        this.state.map.setZoom(mapZoom);
                        this.state.map.setCenter(mapCenter);
                    }
                    if (this.props.locationType === "end") {
                        mapCenter = pointToLatLng(this.props.endLocation)
                        this.state.map.setZoom(mapZoom);
                        this.state.map.setCenter(mapCenter);
                    }
                }
            }
            else if (this.props.createMode === "dot") {
                if (this.props.locationType === "parking" && this.props.parkingLocation !== null) {
                    mapCenter = pointToLatLng(this.props.parkingLocation);
                    this.state.map.setZoom(mapZoom);
                    this.state.map.setCenter(mapCenter);
                }
                else {
                    mapCenter = pointToLatLng(this.props.dotsInfo[0].location);
                    this.state.map.setZoom(mapZoom);
                    this.state.map.setCenter(mapCenter);
                }
                /*
                // Not used now
                else {
                    // Define new bounds
                    const bounds = new this.props.google.maps.LatLngBounds();

                    // Include dot and parking locations in th map bounds
                    bounds.extend(pointToLatLng(this.props.dotsInfo[0].location));
                    bounds.extend(pointToLatLng(this.props.parkingLocation));

                    // Reset bounds
                    this.state.map.fitBounds(bounds);
                }
                */
            }
            else {
                mapCenter = (this.props.mapCenter == null)? pointToLatLng(this.props.dotsInfo[0].location) : pointToLatLng(this.props.dotsInfo[0].map_center);
                this.state.map.setZoom(mapZoom);
                this.state.map.setCenter(mapCenter);
            }
        }
        else {
            // Define new bounds
            const bounds = new this.props.google.maps.LatLngBounds();
            // Trail mode (only considers child dots and their parent dot)
            if (mode === "route") {
                //console.log("Map / resetMapBounds - route mode");

                // For all child dots
                for (let i = 0; i < this.state.childMarkers.length; i++) {
                    bounds.extend(this.state.childMarkers[i].getPosition());
                }

                // Location of the parent dot
                const parentLocation = this.props.dotsInfo[this.props.displayChildren].location;

                // Include the parent dot
                bounds.extend(pointToLatLng(parentLocation));
            }
            // Trip mode (considers all dots and child dots)
            else if (mode === "trip") {
                //console.log("Map / resetMapBounds - trip mode");

                // For all dots
                for (let i = 0; i < this.state.markers.length; i++) {
                    bounds.extend(this.state.markers[i].getPosition());
                    //console.log("Map / resetMapBounds - marker ",i," = ",this.state.markers[i].getPosition());
                }
            }
            else {
                console.log("[WARNING] Map / resetMapBounds - wrong resetMapBoundsMode - ", mode);
            }

            // Reset bounds
            this.state.map.fitBounds(bounds);
        }

        // Execute callback
        if ((callback) && (callback instanceof Function )) {
            callback();
            //setTimeout(callback,1000);
        }
    }


    /*
    ============================================================================================
        zoomInMap
    --------------------------------------------------------------------------------------------
        - Zoom in and center at a dot
    --------------------------------------------------------------------------------------------
        Props
        - prevSelected: previously selected dot index
        - prevSelectedInItinerary : if the previously selected dot was in the itinerary / selection
    ============================================================================================
    */

    zoomInMap(prevSelected, prevSelectedInItinerary) {
        //console.log("Map / zoomInMap - prevSelected = ", prevSelected);
        //console.log("Map / zoomInMap - this.props.selected = ", this.props.selected);
        // Pulsation animation classname
        const pulseClassName = ((this.props.mapMode === "trip-overview") || (this.props.mapMode === "dots-home"))?
            "pulse-overview" : "pulse";

        // If the selected dot was in the selection / itinerary
        if (prevSelected !== null) {
            if (prevSelectedInItinerary) {
                // Get the previously selected marker
                const markerID = "marker-" + prevSelected;
                const marker = document.getElementById(markerID);

                // Remove the pulsation effect
                marker.classList.remove(pulseClassName);
            }
            else {

            }
        }

        // If newly selected dot is in the itinerary / selection
        const selectedInItinerary = (this.props.itinerary.indexOf(this.props.selected) === -1)? false: true;

        if (selectedInItinerary) {
            // Get the newly selected marker
            const markerID = "marker-" + this.props.selected;
            const marker = document.getElementById(markerID);

            // Add the pulsation effect
            marker.classList.add(pulseClassName);
        }
        else {

        }

        // Get the dot
        const dotInfo = this.props.dotsInfo[this.props.selected];

        // Position of the dot
        const dotLocation = dotInfo.location;
        const dotLocationLatLng = pointToLatLng(dotLocation);

        // Zoom in
        this.state.map.setZoom(this.zoomInLevel);

        // Center map
        this.state.map.panTo(dotLocationLatLng);
    }


    /*
    ============================================================================================
        zoomOutMap method
    --------------------------------------------------------------------------------------------
        - Zoom out and reset map bounds
    --------------------------------------------------------------------------------------------
        Props
        - prevSelected: previously selected dot index
        - prevSelectedInItinerary : if the previously selected dot was in the itinerary / selection
    ============================================================================================
    */

    zoomOutMap(prevSelected, prevSelectedInItinerary) {
        //console.log("GoogleMap / zoomOutMap - prevSelected = ", prevSelected);
        //console.log("GoogleMap / zoomOutMap - prevSelectedInItinerary = ", prevSelectedInItinerary);

        // Pulsation animation classname
        const pulseClassName = ((this.props.mapMode === "trip-overview") || (this.props.mapMode === "dots-home"))?
            "pulse-overview" : "pulse";

        // If the selected dot was in the selection / itinerary
        if (prevSelected != null) {
            if (prevSelectedInItinerary) {
                // Get the previously selected marker
                const markerID = "marker-" + prevSelected;
                const marker = document.getElementById(markerID);
                //console.log("GoogleMap / zoomOutMap - markerID = ", markerID);
                //console.log("GoogleMap / zoomOutMap - marker = ", marker);

                // Remove the pulsation effect
                marker.classList.remove(pulseClassName);
            }
            else{

            }
        }

        // Determine resetMapBoundsMode
        const resetMapBoundsMode = (this.state.displayChildren !== null)? "route" : "trip";

        // Reset map bounds
        this.resetMapBounds(
            resetMapBoundsMode,
            this.refreshMap
        );
    }


    /*
    ============================================================================================
        path
    --------------------------------------------------------------------------------------------
        - Refresh map after changes
    ============================================================================================
    */

    addPath(pathString) {
        //console.log("GoogleMap / addPath - pathString = ", pathString);

        // Construct geoJson
        const geoJson = {
            "type": "Feature",
            "properties": {
                "name": "path"
            },
            "geometry": polyline.toGeoJSON(pathString)
        };
        //console.log("GoogleMap / addPath - geoJson = ", geoJson);
 
        // Add path to the map
        const path = this.state.map.data.addGeoJson(geoJson);
        //console.log("GoogleMap / addPath - path = ", path);

        // Set style
        this.state.map.data.setStyle({
            //strokeColor: "#B30000",
            strokeColor: "#4EA3D0",
            //strokeColor: "#3284B0",
            strokeOpacity: 0.9,
            strokeWeight: 12
        });

        // Update state
        this.setState(
            {
                path: path
            },
            this.zoomToFitPath
        );
    }

    removePath() {
        // Remove path from the map
        this.state.map.data.forEach(
            (feature) => {
                this.state.map.data.remove(feature);
            }
        );
    }

    zoomToFitPath() {
        // Create empty bounds object
        const bounds = new this.props.google.maps.LatLngBounds();

        // Loop through features
        this.state.map.data.forEach(
            (feature) => {
                const geometry = feature.getGeometry();
                geometry.forEachLatLng(
                    (latLng) => {
                        bounds.extend(latLng);
                        //console.log("GoogleMap / zoomToFitPath - latLng = ", latLng);
                    }
                );
            }
        );

        // Fit bounds
        this.state.map.fitBounds(bounds);
        //this.state.map.setZoom(this.state.map.getZoom() + 1);
    }


    /*
    ============================================================================================
        refreshMap method
    --------------------------------------------------------------------------------------------
        - Refresh map after changes
    ============================================================================================
    */

    refreshMap() {
        // Refresh map
        const center = this.state.map.getCenter();
        this.props.google.maps.event.trigger(this.state.map,"resize");
        this.state.map.setCenter(center);
    }
}


/*
============================================================================================
    customMarker object
--------------------------------------------------------------------------------------------
    - Define custom marker prototype and related functions
============================================================================================
*/

function customMarker(latlng, map, args) {
    this.latlng = latlng;
    this.args = args;
    this.setMap(map);
}


function initCustomMarker(google) {
    //console.log("Map / initCustomMarker - this = ",this);
    //console.log("Map / initCustomMarker - customMarker = ", customMarker);
    // Using OverlayView object of google map to define customMarker
    customMarker.prototype = new google.maps.OverlayView();

    // Draw method
    customMarker.prototype.draw = function() {
        // Save customMarker context as self
        const self = this;
        //console.log("Map / initCustomMarker (inside draw method) - this = ", this);

        // Content of the marker
        let div = this.div;

        // If empty
        if (!div) {
            // create new div
            div = this.div = document.createElement("div");

            // set attributes
            if (self.args.type === "child") {
                if (self.args.selected === true) {
                    div.className = "child-marker child-pulse";
                }
                else {
                    div.className = "child-marker";
                }
            }
            else if (self.args.type === "selection") {
                if (self.args.selected === true) {
                    if (((self.args.mapMode === "dots-home") || (self.args.mapMode === "trip-overview"))
                        || (self.args.mapMode === "create")) {
                        div.className = "marker pulse-overview";
                    }
                    else {
                        div.className = "marker pulse";
                    }
                }
                else {
                    div.className = "marker";
                }
            }
            else if (self.args.type === "options") {
                if (self.args.selected === true) {
                    div.className = "temp-marker temp-pulse";
                }
                else {
                    div.className = "temp-marker";
                }
            }
            else if (self.args.type === "hover") {
                div.className = "temp-marker temp-hover-pulse";
            }

            // Set properties of the marker
            div.style.position = "absolute";
            div.style.cursor = "pointer";
            div.style.width = "32px";
            div.style.height = "32px";
            div.style.background = self.args.icon;
            div.style.backgroundSize = "38px 38px";
            div.style.backgroundPosition = "center";
            div.style.opacity = 1.0;

            // ID should come from args
            if (typeof(self.args.id) !== "undefined") {
                div.id = self.args.id;
            }

            // Insert nametag if a hover marker
            if (self.args.type === "hover") {
                // Nametag
                const name = document.createElement("div");
                name.className = "temp-marker-name";
                name.textContent = self.args.name;
                div.appendChild(name);

                //console.log("Map / initCustomMarker - self.args = ", self.args);

                if (1) {
                    if (self.args.dot.type === "SC") {
                        //console.log("Map / initCustomMarker - setting factors");

                        // Width
                        const factorWidth = 70;
                        const scenicWidth = Math.round(Math.max(self.args.dot.rating, 5) / 5 * factorWidth);
                        const difficultyWidth = Math.round(Math.max(Math.ceil(self.args.dot.difficulty), 5) / 5 * factorWidth);
                        //console.log("Map / initCustomMarker - scenicWidth = ",scenicWidth);
                        //console.log("Map / initCustomMarker - difficultyWidth = ",difficultyWidth);

                        // Create
                        const markerFactors = document.createElement("div");
                        const markerScenicWrapper = document.createElement("div");
                        const markerScenic = document.createElement("div");
                        const markerDifficultyWrapper = document.createElement("div");
                        const markerDifficulty = document.createElement("div");

                        // Insert
                        div.appendChild(markerFactors);
                        markerFactors.appendChild(markerScenicWrapper);
                        markerFactors.appendChild(markerDifficultyWrapper);
                        markerScenicWrapper.appendChild(markerScenic);
                        markerDifficultyWrapper.appendChild(markerDifficulty);

                        // Classes
                        markerFactors.className = "marker-factors";
                        markerScenicWrapper.className = "marker-scenic-wrapper";
                        markerScenic.className = "marker-scenic";
                        markerDifficultyWrapper.className = "marker-difficulty-wrapper";
                        markerDifficulty.className = "marker-difficulty";

                        // Set widths
                        markerScenic.style.width = "" + scenicWidth + "px";
                        markerDifficulty.style.width = "" + difficultyWidth + "px";
                    }
                }
            }

            // When the map mode is trip related
            if ((self.args.mapMode === "trip-overview") || (self.args.mapMode === "itinerary")) {
                // Click event
                google.maps.event.addDomListener(div, "click", function(event) {
                    // Hike dot callback
                    if (self.args.type === "child") {
                        self.args.dotClick(
                            self.args.dotIndex,
                            self.args.childIndex
                        );
                    }
                    // Regular dot callback
                    else if (self.args.type === "selection" || self.args.type === "options") {
                        self.args.dotClick(
                            self.args.dotIndex,
                            null
                        );
                    }
                    else if (self.args.type === "start" || self.args.type === "end") {
                        self.args.dotClick(
                            self.args.dotIndex,
                            null
                        );
                    }
                });

                // Hover on event
                google.maps.event.addDomListener(div, "mouseover", function(event) {
                    // Child dot callback
                    if (self.args.type === "child") {
                        // Fill out later
                    }
                    // Regular dot callback
                    else if (self.args.type === "selection" || self.args.type === "options") {
                        const inItinerary = (self.args.type === "selection")? true : false;
                        self.args.dotHoverOn(
                            self.args.dotIndex,
                            self.args.dotInfo,
                            self.args.selected,
                            inItinerary
                        );
                    }
                    else if (self.args.type === "start" || self.args.type === "end") {
                        self.args.endHoverOn(self.args.type);
                    }
                });

                // Hover off event
                google.maps.event.addDomListener(div, "mouseout", function(event) {
                    // Child dot callback
                    if (self.args.type === "child") {
                        // Fill out later
                    }
                    // Regular dot callback
                    else if (self.args.type === "selection" || self.args.type === "options") {
                        const inItinerary = (self.args.type === "selection")? true : false;
                        self.args.dotHoverOff(
                            self.args.dotIndex,
                            self.args.selected,
                            inItinerary
                        );
                    }
                    else if (self.args.type === "start" || self.args.type === "end") {
                        self.args.endHoverOff(self.args.type);
                    }
                });
            }

            // Set panes and append div
            const panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        // Position marker
        //console.log("Map - this.latlng = ",this.latlng);
        const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
        if (point) {
            div.style.left = (point.x - 14) + "px";
            div.style.top = (point.y - 14) + "px";
        }
    };

    customMarker.prototype.remove = function() {
        if (this.div != null) {
            this.div.parentNode.removeChild(this.div);
        }
        this.div = null;
        this.setMap(null);
    };

    customMarker.prototype.getPosition = function() {
        return this.latlng;
    };

    customMarker.prototype.setPosition = function(latlng) {
        this.latlng = latlng;
    };
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode
    };
}

export default connect(mapStateToProps, null)(GoogleMap);
