/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Common.js
    - Commonly used components and functions
--------------------------------------------------------------------------------------------
    Content
    - UserProfilePicList
    - UserListShort
    - OpenTripList
    - DropDownMenu
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";
import { Link } from "react-router-dom";

// Modules
import ReactTooltip from "thedots-tooltip";

// Functions
import { 
    getStaticPath,
    getMediaProperty
} from "js/Functions";


/*
============================================================================================
    UserProfilePicList
--------------------------------------------------------------------------------------------
	props
	- [list] usersInfo : list of detailed user info objects
	- [number] userCount : how many users in total (even the ones whose details were not fetched )
    - [number] numProfilePics : how many profile pics will be displayed
	- [string] userTypeLabel : label for the header - e.g. "Contributors" / "Helpers" / "Follower" / "Following"
    - [boolean] userTypeLabelOn : will display label or not
    - [boolean] moreBlankOn: will display spacer instead of more ... icons
	- [string] classNamePrefix: prefix for class name - e.g. "trip-preview"
	- [string] classNameUserType: user type name - e.g. "contributor"
    - [number] index: unique index of the list (when you have multipile lists)
============================================================================================
*/

function UserProfilePicList (props) {
    // Dots image to show more items
    const moreItemsImage = (props.colorMode === "day")?
        getStaticPath("/images/common/more-items-black.png") :
        getStaticPath("/images/common/more-items-white.png");
        
    // List of user profile pics
    const userProfilePicList = (props.userCount > 0)?
    props.usersInfo.map(
        (user, index) => {
            //console.log("Common / UserProfilePicList  - index = ", index);
            if (index < props.numProfilePics) {
                const userProfilePic = (user.profile_pic)?
                    getMediaProperty(user.profile_pic, "t", 'url', true) :
                    (
                        (props.colorMode === "day")?
                            getStaticPath("/images/common/no-profile-picture-day.png") :
                            getStaticPath("/images/common/no-profile-picture-night.png")
                    );

                const tooltipID = props.classNamePrefix + "-" + props.index + "-" + props.classNameUserType + "-" + index;
                const tooltipContent = user.name;

                return(
                    <Link key = {props.classNamePrefix + "-" + props.classNameUserType + "-" + index.toString()}
                        className = {(props.colorMode === "day")?
                            props.classNamePrefix + "-" + props.classNameUserType + "-profile-pic profile-image-s5 border-day" :
                            props.classNamePrefix + "-" + props.classNameUserType + "-profile-pic profile-image-s5 border-night"}
                        style = {{ backgroundImage: userProfilePic, cursor: "pointer" }}
                        data-tip = {tooltipContent} 
                        data-for = {tooltipID}
                        to = {`/user/${user.username}`}
                    >
                        <ReactTooltip 
                        	id = {tooltipID}
                        	className = {props.classNamePrefix + "-tooltip tooltip-s2"}
                        	type = "dark"
                        	place = "bottom"
                        	html = {true} 
                        />
                    </Link>
                )
            }
            else {
                return null;
            }
        }
    ) : null;

    // Text to show the number of users
    let usersText = null;
    if (props.userCount === 0) {
        usersText = null;
    }
    else if (props.userCount === 1) {
        usersText = "1 " + props.userTypeLabel;
    } else {
        usersText = "" + props.userCount + " " + props.userTypeLabel + "s";
    }

    // Tooltip text for more users
    let usersMoreText = "";
    if (props.userCount > props.numProfilePics) {
        if (props.usersInfo.length > props.numProfilePics) {
            usersMoreText += "with <br />"
            for (let i = props.numProfilePics; i < props.usersInfo.length; i++) {
                usersMoreText += ("" + props.usersInfo[i].name + " <br />");
            }
        }
        if (props.userCount > props.usersInfo.length ) {
            usersMoreText += "and <br />";
            usersMoreText += (props.userCount - props.usersInfo.length) + " more";
        }
    }

    // Setup tooltip and more items button
    const usersMoreTooltipID = props.classNamePrefix + "-" + props.classNameUserType + "s-more-tooltip-" + props.index;
    const usersMoreBlank = props.moreBlankOn? (
        <div className = {props.classNamePrefix + "-" + props.classNameUserType + "s-more"}>
        </div>
    ): null;

    const usersMore = (props.userCount > props.numProfilePics)?
    (
        <div className = {props.classNamePrefix + "-" + props.classNameUserType + "s-more"}
            style = {{ backgroundImage: moreItemsImage }}
            data-tip = {usersMoreText} 
            data-for = {usersMoreTooltipID}>
            <ReactTooltip 
            	id = {usersMoreTooltipID} 
            	className = {props.classNamePrefix + "-tooltip tooltip-s2"}
            	type = "dark" 
            	place = "bottom" 
            	html = {true} 
            />
        </div>
    ) : usersMoreBlank;

    const userLabel = (props.userTypeLabelOn)? 
        (
            <div className = {(props.colorMode === "day")?
                props.classNamePrefix + "-" + props.classNameUserType + "s-count k4" :
                props.classNamePrefix + "-" + props.classNameUserType + "s-count w4"}
            >
                {usersText}
            </div>
        ): null;

    // Final users unit
    const users = (props.userCount > 0)? 
    (
        <div className = {props.classNamePrefix + "-" + props.classNameUserType + "s"}>
            {userLabel}
            <div className = {props.classNamePrefix + "-" + props.classNameUserType + "-profile-pics"}>
                {userProfilePicList}
                {usersMore}
            </div>
        </div>
    ) : null;
    
	return users
}


/*
============================================================================================
    UserListShort
--------------------------------------------------------------------------------------------
    props
    - [list] usersInfo :
    - [number] userCount :
    - [string] userTypeLabel :
    - [boolean] andOn : to show "and" before the user label
    - [string] classNamePrefix: prefix for class name e.g. "trip-preview"
    - [string] classNameUserType: user type name e.g. "contributor"
    - [number] index: unique index of the list (when you have multipile lists)
============================================================================================
*/

function UserSimpleList (props) {
    //console.log("UserSimpleList - props: ", props);
    // Tooltip text for a shorter version
    let userSimpleListText = "";

    if (props.userCount > 0) {
        for (let i = 0; i < props.usersInfo.length; i++) {
            userSimpleListText += ("" + props.usersInfo[i].name + " <br />");
        }

        const extraCount = props.userCount - props.usersInfo.length;
        if (extraCount > 0) {
            userSimpleListText += "with <br />" + extraCount + " more";
        }
    }

    const andText = props.andOn? "and " : "";
    const userSimpleListContent = (props.userCount === 1)?
        (andText + "1 " + props.userTypeLabel) : (andText + props.userCount + " " + props.userTypeLabel + "s");

    // Setup tooltip and more items button
    const userSimpleListTooltipID = props.classNamePrefix + "-" + props.classNameUserType + "-simple-list-tooltip-" + props.index;
    const userSimpleList = (props.userCount > 0)?
    (
        <div className = {props.classNamePrefix + "-" + props.classNameUserType + "-simple-list"}
            data-tip = {userSimpleListText}
            data-for = {userSimpleListTooltipID}>
            <ReactTooltip 
                id = {userSimpleListTooltipID} 
                className = {props.classNamePrefix + "-tooltip tooltip-s2"}
                type = "dark" 
                place = "bottom" 
                html = {true} 
            />
            {userSimpleListContent}
        </div>
    ) : null;

    return userSimpleList
}


/*
============================================================================================
    OpenItineraryList
--------------------------------------------------------------------------------------------
    props
    - tripSlug : trip slug
    - setState : update state of the parent component (that keeps the states)
    - loadOpenItineraries : fetch more data

    - firstItineraryIndex : index of the first itinerary on the current page (state)
    - lastItineraryIndex : index of the last itinerary on the current page (state)

    - openItinerariesInfo : list of itineraries fetched from the server (accumulated)
    - openItineraryFetchedCount: number of fetched itineraries
    - openItineraryTotalCount: total number of itineraries
    - openItineraryIndices : which itinerary is shown for a day that has multiple trips
                         this is as long as the openItinerariesInfo 

    - numItineraries : number of itineraries displayed on a page
    - numProfilePics : number of profile pics displayed on one trip
============================================================================================
*/

function OpenItineraryList (props) {
    //console.log("OpenItineraryList - props = ", props);

    // Get the displayed part of the list
    const openItinerariesInfo = props.openItinerariesInfo.slice(props.firstItineraryIndex, props.lastItineraryIndex + 1);

    // List of user profile pics
    const openItineraryList = openItinerariesInfo.map(
        (openItineraries, partialIndex) => {
            // Actual index
            const index = props.firstItineraryIndex + partialIndex;

            // Sub-index for the same day itineraries
            const subIndex = props.openItineraryIndices[index];

            // Select the itinerary
            const openItinerary = openItineraries[subIndex];

            // Sub button images
            const subPrevButtonImage = (props.colorMode === "day")?
                getStaticPath("/images/common/triangle-narrow-left-black.png") :
                getStaticPath("/images/common/triangle-narrow-left-white.png");
            const subNextButtonImage = (props.colorMode === "day")?
                getStaticPath("/images/common/triangle-narrow-right-black.png") :
                getStaticPath("/images/common/triangle-narrow-right-white.png");

            // Get the sub-index pagination buttons
            let subPrevButton = null;
            if (openItineraries.length > 1) {
                subPrevButton = (subIndex > 0)?
                (
                    <div key = {"open-itinerary-item-prev-button-" + index.toString()}
                        className = "open-itinerary-sub-prev-button"
                        style = {{backgroundImage: subPrevButtonImage}}
                        onClick = {
                            () => {
                                // Current sub-index
                                const newOpenItinerayIndices = props.openItineraryIndices.slice();
                                newOpenItinerayIndices[index] = subIndex - 1;

                                props.setState({
                                    openItineraryIndices: newOpenItinerayIndices
                                });

                                /*
                                console.log("OpenItineraryList - subPrevButton clicked");
                                console.log("OpenItineraryList - index = ", index);
                                console.log("OpenItineraryList - subIndex = ", subIndex);
                                console.log("OpenItineraryList - props.openItineraryIndices = ", props.openItineraryIndices);
                                console.log("OpenItineraryList - newOpenItinerayIndices = ", newOpenItinerayIndices);
                                */
                            }
                        }
                    >
                    </div>
                ):(
                    <div className = "open-itinerary-sub-button-spacer">
                    </div>
                );
            }


            let subNextButton = null;
            if (openItineraries.length > 1) {
                subNextButton = (subIndex < (openItineraries.length - 1))?
                (
                    <div key = {"open-itinerary-item-next-button-" + index.toString()}
                        className = "open-itinerary-sub-next-button"
                        style = {{backgroundImage: subNextButtonImage}}
                        onClick = {
                            () => {

                                // Current sub-index
                                const newOpenItinerayIndices = props.openItineraryIndices.slice();
                                newOpenItinerayIndices[index] = subIndex + 1;

                                props.setState({
                                    openItineraryIndices: newOpenItinerayIndices
                                });

                                /*
                                console.log("OpenItineraryList - subNextButton clicked");
                                console.log("OpenItineraryList - index = ", index);
                                console.log("OpenItineraryList - subIndex = ", subIndex);
                                console.log("OpenItineraryList - props.openItineraryIndices = ", props.openItineraryIndices);
                                console.log("OpenItineraryList - newOpenItinerayIndices = ", newOpenItinerayIndices);
                                */
                            }
                        }
                    >
                    </div>
                ):(
                    <div className = "open-itinerary-sub-button-spacer">
                    </div>
                );
            }

            // Time text
            const openItineraryTimeText = openItinerary.start_time_local_formatted +
                " - " + openItinerary.end_time_local_formatted;

            // Combine the host and guests
            const participantsInfo = [];
            const isHost = (props.userInfo.id === openItinerary.user.id);
            let requested = false;
            let approved = false;
            participantsInfo.push(openItinerary.user);
            if (openItinerary.guests) {
                for (let i = 0; i < openItinerary.guests.length; i++) {
                    if (openItinerary.guests[i].is_approved) {
                        participantsInfo.push(openItinerary.guests[i]);
                    }

                    if (openItinerary.guests[i].id === props.userInfo.id) {
                        requested = true;

                        if (openItinerary.guests[i].is_approved) {
                            approved = true;
                        }
                    }
                }
            }

            // Get the props for the profile pic list
            const guestProfilePicListProps = {
                numProfilePics : props.numProfilePics,
                usersInfo : participantsInfo,
                userCount : participantsInfo.length,
                userTypeLabel : "",
                userTypeLabelOn : false,
                moreBlankOn: false,
                classNamePrefix: "open-itinerary",
                classNameUserType: "participant",
                index: 0
            };

            // Get the classname (different for the end item because of the border)
            const itemClassName = ((partialIndex === (props.numItineraries - 1)) || 
                (index === (props.openItinerariesInfo.length - 1)))?
                "open-itinerary-item-end" : "open-itinerary-item";

            return (
                <div key = {"open-itinerary-item-" + index.toString()} 
                    className = {itemClassName} 
                >
                    <Link 
                        to = {`/itinerary/${openItinerary.id}`}
                    >
                        <div className = {(props.colorMode === "day")?
                            "open-itinerary-item-date lb3" :
                            "open-itinerary-item-date b3"}
                        >
                            {openItinerary.date_formatted}
                        </div>
                        <div className = {(props.colorMode === "day")?
                            "open-itinerary-item-time g4" :
                            "open-itinerary-item-time g4"}
                        >
                            {openItineraryTimeText}
                        </div>
                    </Link>
                    <UserProfilePicList {...guestProfilePicListProps} />

                    <div className = "open-itinerary-item-buttons clear-fix">
                        {subPrevButton}
                        {
                            (isHost)?
                            (
                                <div className = {(props.colorMode === "day")?
                                        "open-itinerary-item-join-button button-light-gray-s3 bold" :
                                        "open-itinerary-item-join-button button-gray-s3 bold"}
                                    style = {{ opacity: 0.3 }}
                                >
                                     Hosting
                                </div>
                            ) : (
                                (approved)?
                                    (
                                        <div className = {(props.colorMode === "day")?
                                                "open-itinerary-item-join-button button-light-gray-s3 bold" :
                                                "open-itinerary-item-join-button button-gray-s3 bold"}
                                            style = {{ opacity: 0.3 }}
                                        >
                                             Joined
                                        </div>
                                    ) : (
                                        <div className = {(props.colorMode === "day")?
                                                "open-itinerary-item-join-button button-light-blue-s3 bold" :
                                                "open-itinerary-item-join-button button-blue-s3 bold"}
                                            onClick = {
                                                (requested)?
                                                    () => { props.cancelOpenItinerary(index, subIndex); } :
                                                    () => { props.joinOpenItinerary(index, subIndex); }
                                            }
                                        >
                                            {
                                                (requested)?
                                                    "Cancel" : "Join"
                                            }
                                        </div>
                                    )
                            )
                        }
                        {subNextButton}
                    </div>
                </div>
            );
        }
    );

    // Images of the arrows to show more items
    const prevButtonImage = (props.colorMode === "day")?
        getStaticPath("/images/common/arrow-left-black.png") :
        getStaticPath("/images/common/arrow-left-white.png");

    const nextButtonImage = (props.colorMode === "day")?
        getStaticPath("/images/common/arrow-right-black.png") :
        getStaticPath("/images/common/arrow-right-white.png");

    const prevFirstItineraryIndex = (props.firstItineraryIndex - props.numItineraries >= 0)?
        (props.firstItineraryIndex - props.numItineraries) : null;

    const prevLastItineraryIndex = (props.firstItineraryIndex - props.numItineraries >= 0)?
        (prevFirstItineraryIndex + props.numItineraries - 1) : null;

    // Previous page button
    let prevButton = null;
    if (props.firstItineraryIndex - props.numItineraries >= 0) {
        const prevButtonClick = () => {
            props.setState({
                openItineraryFirstIndex: prevFirstItineraryIndex,
                openItineraryLastIndex: prevLastItineraryIndex
            });
        };

        prevButton = (
            <div id = "open-itinerary-prev-button"
                style = {{backgroundImage: prevButtonImage}}
                onClick = {prevButtonClick}
            >
            </div>
        );
    }


    // Next button
    let nextFirstItineraryIndex, nextLastItineraryIndex, nextButton, nextButtonClick;

    // Not all itineraries are fetched yet
    if (props.openItineraryFetchedCount < props.openItineraryTotalCount) {
        // Calculate the next indices
        const nextProjectedFirstItineraryIndex = props.firstItineraryIndex + props.numItineraries;
        const nextProjectedLastItineraryIndex = props.lastItineraryIndex + props.numItineraries;
        /*
        console.log("Common / OpenItineraryList - props.openItineraryFetchedCount = ", props.openItineraryFetchedCount);
        console.log("Common / OpenItineraryList - props.openItineraryTotalCount = ", props.openItineraryTotalCount);
        console.log("Common / OpenItineraryList - props.openItinerariesInfo.length = ", props.openItinerariesInfo.length);
        console.log("Common / OpenItineraryList - props.openItineraryTotalCount = ", props.openItineraryTotalCount);
        console.log("Common / OpenItineraryList - props.firstItineraryIndex = ", props.firstItineraryIndex);
        console.log("Common / OpenItineraryList - props.lastItineraryIndex = ", props.lastItineraryIndex);
        console.log("Common / OpenItineraryList - nextProjectedFirstItineraryIndex = ", nextProjectedFirstItineraryIndex);
        console.log("Common / OpenItineraryList - nextProjectedLastItineraryIndex = ", nextProjectedLastItineraryIndex);
        */

        const nextButtonClickCallback = () => {
            props.setState({
                openItineraryFirstIndex: nextProjectedFirstItineraryIndex,
                openItineraryLastIndex: nextProjectedLastItineraryIndex
            });
        };

        // If the next page is still within the already fetched list
        if (nextProjectedLastItineraryIndex <= props.openItinerariesInfo.length - 1) {
            nextButtonClick = nextButtonClickCallback;
        }
        // If more itineraries need to be fetched from server to go to the next page
        else {
            nextButtonClick = () => {
                props.loadOpenItineraries(
                    props.tripSlug,
                    nextButtonClickCallback
                );
            };
        }

        // Construct the next page button
        nextButton = (
            <div id = "open-itinerary-next-button"
                style = {{ backgroundImage: nextButtonImage }}
                onClick = {nextButtonClick}
            >
            </div>
        );
    }
    // If all itineraries are fetched
    else {
        /*
        console.log("Common / OpenItineraryList [all fetched] - props.openItinerariesInfo.length = ", props.openItinerariesInfo.length);
        console.log("Common / OpenItineraryList [all fetched] - props.openItineraryTotalCount = ", props.openItineraryTotalCount);
        console.log("Common / OpenItineraryList [all fetched] - props.openItineraryFetchedCount = ", props.openItineraryFetchedCount);
        console.log("Common / OpenItineraryList [all fetched] - props.firstItineraryIndex = ", props.firstItineraryIndex);
        console.log("Common / OpenItineraryList [all fetched] - props.lastItineraryIndex = ", props.lastItineraryIndex);
        */

        // If the next page is still within the already fetched list 
        // as long as the first item of the next page is still within the fetched list
        if (props.firstItineraryIndex + props.numItineraries <= props.openItinerariesInfo.length - 1) {
            // Calculate the next indices
            nextFirstItineraryIndex = Math.min(
                props.firstItineraryIndex + props.numItineraries,
                props.openItinerariesInfo.length - 1
            );

            nextLastItineraryIndex = Math.min(
                props.lastItineraryIndex + props.numItineraries,
                props.openItinerariesInfo.length - 1
            );

            // Click callback
            nextButtonClick = () => {
                props.setState({
                    openItineraryFirstIndex: nextFirstItineraryIndex,
                    openItineraryLastIndex: nextLastItineraryIndex
                });
            };

            // Construct the next page button
            nextButton = (
                <div id = "open-itinerary-next-button"
                    style = {{ backgroundImage: nextButtonImage }}
                    onClick = {nextButtonClick}
                >
                </div>
            );            
        }
        // If already the last page
        else {
            nextButton = null;
        }
    }

    // Construct open itinerary list
    return(
        <div id = "open-itinerary-list">
            {prevButton}
            {openItineraryList}
            {nextButton}
        </div>
    );
}

/*
============================================================================================
    DropDownMenu
--------------------------------------------------------------------------------------------
    props
    - [number] objectIndex : the index of the current object
    - [number] selectedItemIndex : what item (group) is the object currently assigned to
    - [list of strings] itemLabels: labels of the items (groups) to be displayed
    - [list of numbers] items: list of object item(group) indices
    - [function] updateItems : update object item (group) list of the parent component
    - [boolean] showSelectedLabel : display the currenly selected label or not
    - [number] height: height of the placeholder and selected label
============================================================================================
*/

function DropDownMenu(props) {
    //console.log("props = ", props);
    const dropDownItemList = props.itemLabels.map(
        (itemLabel, itemIndex) => {
            //console.log("itemLabel = ", itemLabel);
            //console.log("itemIndex = ", itemIndex);
            let dropDownItemClassName = null;
            if (itemIndex === 0) {
                dropDownItemClassName = (props.colorMode === "day")? 
                    "drop-down-item-top drop-down-item-day dg6" :
                    "drop-down-item-top drop-down-item-night g6";
            }
            else if (itemIndex === (props.itemLabels.length - 1)) {
                dropDownItemClassName = (props.colorMode === "day")? 
                    "drop-down-item-bottom drop-down-item-day dg6" :
                    "drop-down-item-bottom drop-down-item-night g6";
            }
            else {
                dropDownItemClassName = (props.colorMode === "day")? 
                    "drop-down-item drop-down-item-day dg6" :
                    "drop-down-item drop-down-item-night g6";
            }

            if (itemIndex === props.selectedItemIndex) {
                dropDownItemClassName += (props.colorMode === "day")? 
                    " drop-down-item-selected-day lb6" :
                    " drop-down-item-selected-night b6";
            }

            //console.log("dropDownItemClassName = ", dropDownItemClassName);
            return (
                <div key = {"drop-down-menu-object-" + props.objectIndex.toString() + "-item-" + itemIndex.toString()} 
                    className = {dropDownItemClassName}
                    style = {{ width: props.width }}
                    onClick = {() => {
                        // Construct new object items list
                        const newItems = props.items.slice(0, props.objectIndex).concat([itemIndex], props.items.slice(props.objectIndex + 1));

                        // Update items list for the parent component
                        props.updateItems(newItems);
                    }}
                >
                    {itemLabel}
                </div>
            );
        }
    );

    const selectedLabel = (props.showSelectedLabel)? props.itemLabels[props.selectedItemIndex] : null;
    const selectedClassname = (props.showSelectedLabel)? 
        (
            (props.colorMode === "day")? 
                "drop-down-selected-day w6" :
                "drop-down-selected-night k6"
        ) :
        "drop-down-selected-spacer";

    return (
        <div className = "drop-down-placeholder"
            style = {{
                height: props.height
            }}
        >
            <div className = "drop-down">
                <div className = {selectedClassname}
                    style = {{ 
                        width: props.width,
                        height: props.height
                    }}
                >
                    {selectedLabel}
                </div>
                <div className = "drop-down-items-container"
                    style = {{
                        top: props.top,
                        left: props.left
                    }}
                >
                    {dropDownItemList}
                </div>
            </div>
        </div>
    );
}




/*
============================================================================================
    BottleProfilePicList
--------------------------------------------------------------------------------------------
    props
    - [list] usersInfo : list of detailed user info objects
    - [number] userCount : how many users in total (even the ones whose details were not fetched )
    - [number] numProfilePics : how many profile pics will be displayed
    - [number] minBottleSize : how many bottle sections will be displayed by default
    - [string] classNamePrefix: prefix for class name - e.g. "trip-preview"
    - [number] index: unique index of the list (when you have multipile lists)
============================================================================================
*/

function BottleProfilePicList (props) {
    // Dots image to show more items
    //const moreItemsImage =  (props.colorMode === "day")?
    //    getStaticPath("/images/common/more-items-black.png") :
    //    getStaticPath("/images/common/more-items-white.png");

    //console.log("BottleProfilePicList - props.minBottleSize = ", props.minBottleSize);
    //console.log("BottleProfilePicList - props.maxBottleSize = ", props.maxBottleSize);

    // List of user profile pics
    let userProfilePicList = null;
    if (props.userCount > 0) {
        const indices = [];
        let bottleSize = null;
        if ((props.usersInfo.length >= props.minBottleSize) && (props.usersInfo.length < props.maxBottleSize)) {
            bottleSize = props.usersInfo.length;
        }
        else if (props.usersInfo.length < props.minBottleSize) {
            bottleSize = props.minBottleSize;
        }
        else 
        {
            bottleSize = props.maxBottleSize;            
        }
        const emptySize = bottleSize - props.usersInfo.length;
        //console.log("BottleProfilePicList - bottleSize = ", bottleSize);
        //console.log("BottleProfilePicList - emptySize = ", emptySize);

        for (let i = 0; i < bottleSize; i++) {
            if (i < emptySize) {
                indices.push(-1);
            }
            else {
                indices.push(i - emptySize);
            }
        }
        //console.log("BottleProfilePicList - indices = ", indices);

        userProfilePicList = indices.map(
            (userIndex, index) => {
                //console.log("Common / UserProfilePicList  - index = ", index);
                let bottleImage = null;
                let className = null;
                let speakerPhone = null;
                let speakerPhoneTooltip = null;
                if (index === 0) {
                    bottleImage = (props.colorMode === "day")?
                        getStaticPath("/images/bottle/bottle-left-light-gray.png") :
                        getStaticPath("/images/bottle/bottle-left-darkest-gray.png");
                    className = props.classNamePrefix + "-bottle-user-left";
                }
                else if (index === (bottleSize - 1)) {
                    bottleImage = (props.colorMode === "day")?
                        getStaticPath("/images/bottle/bottle-right-light-gray.png") :
                        getStaticPath("/images/bottle/bottle-right-darkest-gray.png");
                    className = props.classNamePrefix + "-bottle-user-right";
                }
                else if ((index > 0) && (index < (bottleSize - 1))) {
                    bottleImage = (props.colorMode === "day")?
                        getStaticPath("/images/bottle/bottle-middle-light-gray.png") :
                        getStaticPath("/images/bottle/bottle-middle-darkest-gray.png");
                    className = props.classNamePrefix + "-bottle-user-middle";
                }

                // Only when it is not empty
                if (indices[index] !== -1) {
                    // Get the public icon
                    if (props.usersPublic.indexOf(props.usersInfo[userIndex].id) !== -1) {
                        const speakerPhoneImage = (props.colorMode === "day")?
                            getStaticPath("/images/bottle/speakerphone.png") :
                            getStaticPath("/images/bottle/speakerphone.png");
                        const speakerPhoneTooltipID = props.classNamePrefix + "-bottle-" + props.dotID + "-user-" + userIndex + "-speakerphone";

                        speakerPhone = (
                            <div className = {props.classNamePrefix + "-bottle-speakerphone image-cover"}
                                style = {{ backgroundImage: speakerPhoneImage }}
                                data-tip = "Public Message"
                                data-for = {speakerPhoneTooltipID}
                            >
                            </div>
                        );

                        speakerPhoneTooltip = (
                            <ReactTooltip 
                                id = {speakerPhoneTooltipID} 
                                className = {props.classNamePrefix + "-tooltip tooltip-s2"} 
                                type = "info"
                                place = "top"
                                html = {true} 
                            />
                        );
                    }

                    // Render part of bottle
                    const user = props.usersInfo[userIndex];
                    const userProfilePic = (user.profile_pic)?
                        getMediaProperty(user.profile_pic, "t", 'url', true) :
                            (
                                (props.colorMode === "day")?
                                    getStaticPath("/images/common/no-profile-picture-day.png") :
                                    getStaticPath("/images/common/no-profile-picture-night.png")
                            );
                    
                    const tooltipID = props.classNamePrefix + "-bottle-" + props.dotID + "-user-" + userIndex;
                    const tooltipContent = user.name;
                    const userClassName = (props.colorMode === "day")?
                        props.classNamePrefix + "-bottle-user-profile-pic profile-image-s4 border-day buzz" :
                        props.classNamePrefix + "-bottle-user-profile-pic profile-image-s4 border-night buzz";
                    return(
                        <div key = {props.classNamePrefix + "-" + index.toString()}
                            className = {className}
                            style = {{ backgroundImage: bottleImage, cursor: "pointer" }}
                        >
                            <Link className = {userClassName}
                                style = {{ backgroundImage: userProfilePic, cursor: "pointer" }}
                                data-tip = {tooltipContent} 
                                data-for = {tooltipID}
                                to = {`/user/${user.username}`}
                            >
                                {speakerPhone}
                            </Link>
                            <ReactTooltip 
                                id = {tooltipID} 
                                className = {props.classNamePrefix + "-tooltip tooltip-s2"} 
                                type = "dark" 
                                place = "bottom" 
                                html = {true} 
                            />
                            {speakerPhoneTooltip}
                        </div>
                    );
                }
                else {
                    return (
                        <div key = {props.classNamePrefix + "-" + index.toString()}
                            className = {className}
                            style = {{ backgroundImage: bottleImage }}
                        >
                        </div>
                    );
                }
            }
        );
    }

    // Text to show the number of users
    let userCount = null;
    if (props.userCount === 0) {
        userCount = null;
    }
    else if (props.userCount === 1) {
        userCount = "1 Message";
    } else {
        userCount = "" + props.userCount + " Messages";
    }

    // Title className
    const titleClassName = (props.colorMode === "day")?
        props.classNamePrefix + "-bottle-user-count k4" :
        props.classNamePrefix + "-bottle-user-count w4";

    // Final users unit
    const bottle = (props.userCount > 0)? 
    (
        <div className = {props.classNamePrefix + "-bottle"}>
            <div className = {titleClassName}>
                {userCount}
            </div>
            <div className = {props.classNamePrefix + "-bottle-user-profile-pics"}>
                {userProfilePicList}
            </div>
        </div>
    ) : null;
    
    return bottle
}


export {
    UserProfilePicList, 
    UserSimpleList,
    OpenItineraryList,
    DropDownMenu, 
    BottleProfilePicList
};