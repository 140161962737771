import { STORE_NEW_NOTIFICATION } from "actions"; 

const INITIAL_STATE = {
    newNotification: null
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
        case STORE_NEW_NOTIFICATION:
            return { ...state, newNotification: action.payload};
		default:
			return state;
	}
};