// React / ReactDOM / React-router
import React, { Component } from "react";
//import { bindActionCreators } from "redux"; 
import { connect } from "react-redux"; 

// Modules
//import * as d3 from "d3";

// Components
import Drag from "js/Drag";

// Functions
import {
    getStaticPath
} from "js/Functions";

// Scss
import "./Globe.scss";


class Globe extends Component {

    constructor(props) {
        super(props);

        // Rotation speed
        this.rotationSpeed = 1.0;
        this.autoRotationSpeed = 0.4;

        // Settings
        this.markerSize = 4;

        // Nodes
        this.projection = null;
        this.svg = null;

        // Max rotation
        const rotationX = this.maxRotation(this.props.locationsInfo);
        const rotationXShift = -30;
        const rotationY = -25;

        // Initialize state
        this.state = {
            dragOn: (window.touchOn)? false : true,
            rotationX: rotationX + rotationXShift,
            rotationY: rotationY,
            translateX: 0,
            translateY: 0
        };

        // Draggable
        this.onDrag = this.onDrag.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);

        // Drag on and off 
        this.dragOn = this.dragOn.bind(this);
        this.dragOff = this.dragOff.bind(this);

        // Rotate interval 
        this.rotateInterval = null;

        // Bind rotate globe function
        this.rotateGlobe = this.rotateGlobe.bind(this);
    }
  

    render() {
        //console.log("Globe / render - this.props.locationsInfo = ", this.props.locationsInfo);
        
        // Render globe
        this.projection = window.d3.geoOrthographic()
            .fitSize([this.props.size, this.props.size], window.geoJson)
            .rotate([ this.state.rotationX, this.state.rotationY ]);
        const geoGenerator = window.d3.geoPath().projection(this.projection);
        const pathString = geoGenerator(window.geoJson);

        /*
        // Animation
        window.requestAnimationFrame(
            this.rotateGlobe
        );
        */

        // SVG 
        this.svg = window.d3.select('svg').attr('width', this.props.size).attr('height', this.props.size);

        // Clear previous markers
        this.clearMarkers();

        // Set markers
        this.setMarkers();

        // Drag image
        const dragImage = (this.state.dragOn)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/unlock-light-blue.png") :
                    getStaticPath("/images/common/unlock-blue.png")
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/lock-light-blue.png") :
                    getStaticPath("/images/common/lock-blue.png")
            );

        // Globe mode
        let globeMode = null;
        if (this.props.globeMode === "all") {
            if (this.props.bucketMode === "save") {
                globeMode = (this.props.selectedDotTag !== null)?
                    this.props.collectionName : "Bucketed";
            }
            if (this.props.bucketMode === "contribute") {
                globeMode = (this.props.selectedDotTag !== null)?
                    this.props.collectionName : "Authored";
            }
            if (this.props.bucketMode === "everyday") {
                globeMode = (this.props.selectedDotTag !== null)?
                    this.props.collectionName : "Everyday";
            }
        }
        else {
            globeMode = "Current Page";
        }

        return (
            <div className = {(this.props.colorMode === "day")?
                "globe-wrapper-day" : "globe-wrapper-night"}
            >
                {
                    //(this.props.globeModeButtonOn)?
                    (
                        <div className = "globe-mode-container">
                            <div className = {(this.props.colorMode === "day")?
                                    "globe-mode-day w5" :
                                    "globe-mode-night k5"}
                                onClick = {(this.props.globeModeButtonOn)? this.props.toggleGlobeMode : null}
                            >
                                {globeMode}
                            </div>
                        </div>
                    )
                    //: null
                }


                <div className = "globe-wrapper">
                    {
                        (window.touchOn)?
                        (
                            <div className = "globe-drag-button image-button-s1"
                                style = {{ backgroundImage: dragImage }}
                                onClick = {
                                    (this.state.dragOn)?
                                        this.dragOff : this.dragOn
                                }
                            >
                            </div>
                        ) : null
                    }

                    <div className = {(this.props.colorMode === "day")?
                            "globe-count font-cabin light-blue" :
                            "globe-count font-cabin blue"}
                    >
                        {this.props.locationsInfo.length}
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                        "globe-background-day" : "globe-background-night"}
                    >                
                        <Drag
                            onDrag = {this.onDrag}
                            onDragStart = {this.onDragStart}
                            onDragEnd = {this.onDragEnd}
                            dragOn = {this.state.dragOn}
                        >
                            <svg width={this.props.size} height={this.props.size}>
                                <path className = {(this.props.colorMode === "day")?
                                        "globe-path-day" : "globe-path-night"}
                                    d = {pathString}
                                />
                            </svg>
                        </Drag>
                    </div>
                </div>
            </div>
        );
    }

    maxRotation(locationsInfo) {
        const maxNumLocations = 10;
        const range = 11.25;
        let counts = [];
        let longitudes = [];
        const sectionWidth = 22.5;

        // For a small cluster
        if (locationsInfo.length <= maxNumLocations) {
            // Initialize counts
            for (let i = 0;  i < locationsInfo.length; i++) {
                counts.push(0);
            }

            // For all interactions
            for (let i = 0; i < locationsInfo.length; i++) {
                for (let j = i + 1; j < locationsInfo.length; j++) {
                    const dx = Math.abs(locationsInfo[i].longitude - locationsInfo[j].longitude);
                    const dr = Math.abs(dx - Math.round(dx / 360) * 360);
                    if (dr < range) {
                        counts[i] += 1;
                        counts[j] += 1;
                    }
                }

                longitudes.push(locationsInfo[i].longitude);
            }
        }
        // For a large cluster
        else {
            // Initialize longitude sections
            longitudes = [ -180 ];
            for (let i = 1; i < Math.floor(360 / sectionWidth); i++) {
                longitudes.push(longitudes[i - 1] + sectionWidth);
            }

            // Initialize counts
            for (let i = 0; i < longitudes.length; i++) {
                counts.push(0);
            }

            // For all locations
            for (let i = 0; i < locationsInfo.length; i++) {

                const index = Math.floor((locationsInfo[i].longitude + 180) / sectionWidth);
                const periodicIndex = index + Math.floor((longitudes.length - index) / longitudes.length) * longitudes.length;

                if (index === longitudes.length) {
                    counts[0] += 1;
                }
                else {
                    counts[periodicIndex] += 1;
                }
            }
        }

        // Get the max longitude
        let maxIndex = 0;
        for (let i = 0;  i < counts.length; i++) {
            if (counts[i] > maxIndex) {
                maxIndex = i;
            }
        }

        // Set the max rotation
        let maxLongitude = longitudes[maxIndex];
        if (locationsInfo.length > maxNumLocations) {
            maxLongitude += sectionWidth / 2;
        }

        //console.log("Globe / maxRotation - maxIndex = ", maxIndex);
        //console.log("Globe / maxRotation - longitudes = ", longitudes);
        //console.log("Globe / maxRotation - counts = ", counts);

        return -maxLongitude;
    }

    dragOn() {
        //console.log("Globe / dragOn");

        this.setState({ dragOn: true });
    }

    dragOff() {
        //console.log("Globe / dragOff");

        this.setState({ dragOn: false });
    }

    onDrag(translation) {
        //console.log("Globe / onDrag - translation = ", translation);

        this.setState({
            rotationX: this.state.rotationX + this.rotationSpeed * (translation.translateX - this.state.translateX),
            rotationY: this.state.rotationY - this.rotationSpeed * (translation.translateY - this.state.translateY),
            translateX: translation.translateX,
            translateY: translation.translateY
        });
    }

    onDragStart() {
        //console.log("Globe / onDragStart");
    }

    onDragEnd() {
        //console.log("Globe / onDragEnd");
    }        

    clearMarkers() {
        // Remove all circles
        this.svg.selectAll("circle").remove();
    }

    setMarkers() {
        // Create a marker set
        const markerSet = this.svg.append("g");

        // Center point
        const center = [ this.props.size / 2, this.props.size / 2 ];

        // Get all locations
        const locations = [];
        for (let i = 0; i < this.props.locationsInfo.length; i++) {
            locations.push(this.props.locationsInfo[i]);
        }

        // Initialize markers
        const markers = markerSet.selectAll("circle").data(locations);

        // Draw markers
        markers
            .enter()
            .append("circle")
            .merge(markers)
            .attr("cx", d => this.projection([d.longitude, d.latitude])[0])
            .attr("cy", d => this.projection([d.longitude, d.latitude])[1])
            .attr("fill", d => {
                const coordinate = [d.longitude, d.latitude];
                const gdistance = window.d3.geoDistance(coordinate, this.projection.invert(center));
                return gdistance > 1.57 ? "none" : 
                    (this.props.colorMode === "day")?
                        window.colorLightBlue : window.colorDarkBlue;
            })
            .attr("r", this.markerSize);
    }

    rotateGlobe() {
        this.setState({
            rotationX: this.state.rotationX + this.autoRotationSpeed
        });
    }

    componentDidMount() {
        //console.log("Globe / componentDidMount");

        // Set rotate interval
        this.rotateInterval = setInterval(
            this.rotateGlobe,
            100
        );
    }

    componentWillUnmount() {
        //console.log("Globe / componentWillUnmount");

        // Clear rotate interval
        clearInterval(this.rotateInterval);

        /*
        window.cancelAnimationFrame(
            this.rotateGlobe
        );
        */
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode
    };
}

// Export component
export default connect(mapStateToProps, null)(Globe);
