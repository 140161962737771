/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Curation.js
    - Stylize content curations
    - Overview / todos / history / stories
--------------------------------------------------------------------------------------------
    Content
    - updateCurationStyle
    - curationCapitalize
    - curationPartition
    - removeFirstSpace
    - regularCuration
    - regularCurationStrong
    - truncateCuration
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";


// Need to be bound
function updateCurationStyle(dotInfo, callback) {
    // Common dot flag
    const commonDot = (dotInfo.type === "EV" || dotInfo.type === "AU");

    // Capitalize the first letters of paragraphs
    if ((this.curationOverviewRef) && (this.curationOverviewLetterRef)) {
        if (removeFirstSpace(dotInfo.overview) !== "") {
            curationCapitalize(this.curationOverviewRef, this.curationOverviewLetterRef);
        }
    }
    if (!commonDot) {
        if ((this.curationTodosRef) && (this.curationTodosLetterRef)) {
            if (removeFirstSpace(dotInfo.dot_extension.todos) !== "") {
                curationCapitalize(this.curationTodosRef, this.curationTodosLetterRef);
            }
        }
        if ((this.curationHistoryRef) && (this.curationHistoryLetterRef)) {
            if (removeFirstSpace(dotInfo.dot_extension.history) !== "") {
                curationCapitalize(this.curationHistoryRef, this.curationHistoryLetterRef);
            }
        }
        if ((this.curationStoriesRef) && (this.curationStoriesLetterRef)) {
            if (removeFirstSpace(dotInfo.dot_extension.stories) !== "") {
                curationCapitalize(this.curationStoriesRef, this.curationStoriesLetterRef);
            }
        }
    }

    if (callback && typeof callback === "function") {
        callback();
    }
}


// Need to be bound
function curationPartition(props) {
    // Common dot flag
    const commonDot = (props.dotInfo.type === "EV" || props.dotInfo.type === "AU");

    // Get the reference
    let getRef = null;
    let getRefLetter = null;
    let firstLetterLate = false;
    let string = null;

    if (props.type === "overview") {
        string = removeFirstSpace(props.dotInfo.overview);
        firstLetterLate = (string.slice(0, 1) === "*")? true: false;
        getRef = (element) => {this.curationOverviewRef = element;};
        getRefLetter = (element) => {this.curationOverviewLetterRef = element;};
    }
    else if (!commonDot && props.type === "todos") {
        string = removeFirstSpace(props.dotInfo.dot_extension.todos);
        firstLetterLate = (string.slice(0, 1) === "*")? true: false;
        getRef = (element) => {this.curationTodosRef = element;};
        getRefLetter = (element) => {this.curationTodosLetterRef = element;};
    }
    else if (!commonDot && props.type === "history") {
        string = removeFirstSpace(props.dotInfo.dot_extension.history);
        firstLetterLate = (string.slice(0, 1) === "*")? true: false;            
        getRef = (element) => {this.curationHistoryRef = element;};
        getRefLetter = (element) => {this.curationHistoryLetterRef = element;};
    }
    else if (!commonDot && props.type === "stories") {
        string = removeFirstSpace(props.dotInfo.dot_extension.stories);
        firstLetterLate = (string.slice(0, 1) === "*")? true: false;
        getRef = (element) => {this.curationStoriesRef = element;};
        getRefLetter = (element) => {this.curationStoriesLetterRef = element;};
    }
    else {
        console.log("[Warning] Curation / curationPartition - invalid item type");
    }
    //console.log("Curation / curationPartition - firstLetterLate = ", firstLetterLate);

    // Partition the raw string into a number of substrings (paragrahs)
    const strings = [];
    const lineBreakIndices = [];

    // First line break index
    let lineBreakIndex = string.indexOf("\n");

    // If no line break
    if (lineBreakIndex < 0) {
        strings.push(string);
    }
    else {
        while (lineBreakIndex >= 0) {

            // Add string
            if (lineBreakIndices.length === 0) {
                strings.push(string.slice(0, lineBreakIndex));
            }
            else {
                strings.push(string.slice(lineBreakIndices[lineBreakIndices.length - 1] + 1, lineBreakIndex));
            }

            // Add line break index to index lists
            lineBreakIndices.push(lineBreakIndex);

            // Find next line break index
            lineBreakIndex = string.indexOf("\n", lineBreakIndex + 1);

            // Last section
            if (lineBreakIndex < 0) {
                strings.push(string.slice(lineBreakIndices[lineBreakIndices.length - 1] + 1));
            }
        }
    }
    //console.log("Curation / curationPartition - string = ", string);
    //console.log("Curation / curationPartition - strings = ", strings);
    //console.log("Curation / curationPartition - lineBreakIndices = ", lineBreakIndices);

    // Initialize JSX expression
    let jsx = [];
    let stringParagraph;
    let highlightIndices, highlightIndex, highlightStartIndex;
    let subStringRaw, subString;
    let component;
    let componentSpace;

    // For all paragraphs
    for (let i = 0; i < strings.length; i++) {
        //console.log("Curation / curationPartition - i = ", i);

        // Get the current paragraph
        stringParagraph = strings[i];
        //console.log("Curation / curationPartition - stringParagraph = ", stringParagraph);

        // Get the indices of *
        highlightIndices = [];
        highlightIndex = -1;
        while ((highlightIndex = stringParagraph.indexOf("*", highlightIndex + 1)) >= 0) {
            highlightIndices.push(highlightIndex);
        }
        //console.log("Curation / curationPartition - highlightIndices = ", highlightIndices);

        // Reset highlight counter
        highlightStartIndex = 0;

        // For all highlight sections
        for (let j = 0; j < (highlightIndices.length + 1); j++) {
            subStringRaw = (j === (highlightIndices.length))?
                stringParagraph.slice(highlightStartIndex) : stringParagraph.slice(highlightStartIndex, highlightIndices[j]);
            subString = removeFirstSpace(subStringRaw);
            component = null;
            //console.log("Curation / curationPartition - subString = ", subString);

            // Only when the sub sring contains actual letters
            if (subString.length > 0) {
                if (i === 0 && j === 0 && !firstLetterLate) {
                    //console.log("Curation / curationPartition - Case 1");

                    component = (
                        <div ref = {getRef} key = {props.keyHeader + "-part-" + i.toString() + "-" + j.toString()}
                            className = {props.classPrefix + "-curation-paragraph"}
                            >
                            <span ref = {getRefLetter}
                                className = {(props.colorMode === "day")?
                                    props.classPrefix + "-curation-capitalize capitalize-day" :
                                    props.classPrefix + "-curation-capitalize capitalize-night"}
                            >
                                {subString.slice(0, 1).toUpperCase()}
                            </span>
                            <span className = {(props.colorMode === "day")?
                                props.classPrefix + "-curation-text text dark-gray" : 
                                props.classPrefix + "-curation-text text gray"
                            }>
                                {removeFirstSpace(subString.slice(1))}
                            </span>
                        </div>
                    );
                }
                else {
                    if (isOdd(j)) {
                        //console.log("Curation / curationPartition - Case 2");
                        
                        component = (
                            <div key = {props.keyHeader + "-part-" + i.toString() + "-" + j.toString()}
                                className = {(props.colorMode === "day")?
                                    props.classPrefix + "-curation-highlight highlight-lb3" :
                                    props.classPrefix + "-curation-highlight highlight-b3"}
                            >
                                {'"' + subString.slice(0, 1).toUpperCase() + subString.slice(1) + '"'}
                            </div>
                        );
                    }
                    else {
                        //console.log("Curation / curationPartition - Case 3");
                        
                        component = (
                            <div key = {props.keyHeader + "-part-" + i.toString() + "-" + j.toString()}
                                className = {props.classPrefix + "-curation-paragraph"}
                            >
                                <span className = {(props.colorMode === "day")?
                                    props.classPrefix + "-curation-text text dark-gray" : 
                                    props.classPrefix + "-curation-text text gray"}
                                >
                                    {subString}
                                </span>
                            </div>
                        );
                    }
                }

                // Component space
                componentSpace = (
                    <div key = {props.keyHeader + "-space-" + i.toString() + "-" + j.toString()}
                        className = {props.classPrefix + "-space-paragraph"}
                    >
                    </div>
                );
            }
            else {
                componentSpace = null;
            }

            // Update highlight index
            highlightStartIndex = highlightIndices[j] + 1;

            // Add JSX component
            jsx.push(component);

            // Add space
            if (i !== strings.length - 1) {
                jsx.push(componentSpace);
            }
        }
    }

    return jsx;
}


function curationCapitalize(curationRef, curationLetterRef) {
    //console.log("Curation / curationCapitalize - curationRef = ", curationRef);
    //console.log("Curation / curationCapitalize - curationRef.clientHeight = ", curationRef.clientHeight);
    const paddingWidth = 12; // Empirically determined
    const fontSize = 20; // Empirically determined
    const lineHeight = 19.9; // This has to match with the curation-text class
    const maxNumLines = 3;
    const numLines = Math.min(
        Math.round((curationRef.clientHeight - paddingWidth) / lineHeight),
        maxNumLines
    );
    //console.log("Curation / curationCapitalize - Number of lines = ", numLines);
    
    if (numLines === 1 || numLines === 0) {
        curationLetterRef.style.fontSize = fontSize.toString() + "px";
        curationLetterRef.style.lineHeight = lineHeight.toString() + "px";
        curationLetterRef.style.paddingRight = "1px";
    }
    else {
        curationLetterRef.style.fontSize  = (numLines * fontSize).toString() + "px";
        curationLetterRef.style.lineHeight = (numLines * lineHeight).toString() + "px";
        curationLetterRef.style.paddingRight = ((numLines - 1) * 2).toString() + "px";
    }
}


function isOdd (number) { 
    return number % 2;
}


function removeFirstSpace(string) {
    let index = 0;
    while ((index === string.indexOf(" ", index)) && (string.indexOf(" ", index) !== -1)) {
        index += 1;
    }
    return string.slice(index);
}


/*
============================================================================================
    Regular Curation
============================================================================================
*/

function regularCuration(string, classPrefix, keyHeader, colorMode) {
    // Find indices of *
    const indices = [];
    let index = -1;
    while ((index = string.indexOf("*", index + 1)) >= 0) {
        indices.push(index);
    }
    
    // Odd number function
    const isOdd = (num) => { return num % 2;};

    // Initialize JSX expression
    let jsx = [];

    // Get the number of highlighted sections
    let startIndex = 0;
    for (let i = 0; i < (indices.length + 1); i++) {
        let subString = (i === (indices.length))?
            string.slice(startIndex) : string.slice(startIndex, indices[i]);
        let component;

        // First part
        if (subString.length > 0) {
            if (isOdd(i)) {
                component = (
                    <span key = {keyHeader + "-" + i.toString()} 
                        className = {(colorMode === "day")?
                            classPrefix + "-curation-highlight text light-blue" :
                            classPrefix + "-curation-highlight text blue"}
                    >
                        {subString}
                    </span>
                );
            }
            else {
                component = (
                    <span key = {keyHeader + "-" + i.toString()} 
                        className = {(colorMode === "day")?
                            classPrefix + "-curation-regular text dark-gray" :
                            classPrefix + "-curation-regular text gray"}
                    >
                        {subString}
                    </span>
                );
            }
            
        }

        // Update index
        startIndex = indices[i] + 1;

        // Add JSX
        jsx.push(component);
    }

    // Return modified string
    return jsx;
} 


/*
============================================================================================
    Regular Curation Strong (for fainter backgrounds)
============================================================================================
*/

function regularCurationStrong(string, classPrefix, keyHeader, colorMode) {
    // Find indices of *
    const indices = [];
    let index = -1;
    while ((index = string.indexOf("*", index + 1)) >= 0) {
        indices.push(index);
    }
    
    // Odd number function
    const isOdd = (num) => { return num % 2;};

    // Initialize JSX expression
    let jsx = [];

    // Get the number of highlighted sections
    let startIndex = 0;
    for (let i = 0; i < (indices.length + 1); i++) {
        let subString = (i === (indices.length))?
            string.slice(startIndex) : string.slice(startIndex, indices[i]);
        let component;

        // First part
        if (subString.length > 0) {
            if (isOdd(i)) {
                component = (
                    <span key = {keyHeader + "-" + i.toString()} 
                        className = {(colorMode === "day")?
                            classPrefix + "-curation-highlight text blue" :
                            classPrefix + "-curation-highlight text light-blue"}
                    >
                        {subString}
                    </span>
                );
            }
            else {
                component = (
                    <span key = {keyHeader + "-" + i.toString()} 
                        className = {(colorMode === "day")?
                            classPrefix + "-curation-regular text dark-gray" :
                            classPrefix + "-curation-regular text light-gray"}
                    >
                        {subString}
                    </span>
                );
            }
            
        }

        // Update index
        startIndex = indices[i] + 1;

        // Add JSX
        jsx.push(component);
    }

    // Return modified string
    return jsx;
}


export {
    updateCurationStyle,
    curationCapitalize,
    curationPartition,
    removeFirstSpace,
    regularCuration,
    regularCurationStrong
}