/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Search.js
    - search input
--------------------------------------------------------------------------------------------
    Content
    - SearchInput
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
//import { bindActionCreators } from "redux";

// Functions
import { getStaticPath } from "js/Functions";

// CSS
import "./Search.scss";


class SearchInput extends Component {
    constructor(props){
        super(props);

        this.searchInputRef = React.createRef();

        this.state = {
            placeholder: "Search",
        }

        // function bind
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleFocus() {
        this.setState({
            placeholder: ""
        })
    }

    handleBlur() {
        this.setState({
            placeholder: "Search"
        })
    }

    render() {
        // console.log("SearchInput / render - this.state = ", this.state)

        // Properties for the input element
        // TODO: minLength attribute does not work.
        const inputElementProps = {
            type: "text",
            placeholder: this.state.placeholder,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            autoComplete: "off",
            minLength: 2,
            required: "required",
            value: this.state.keywords
        };

        // Submit image
        const submitImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/search-input-black.png") :
            getStaticPath("/images/common/search-input-white.png");

        const nearbySubmitImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/nearby-search-black.png") :
            getStaticPath("/images/common/nearby-search-white.png");

        // Search input class name
        const searchInputClassName = (this.props.searchOff)?
            (
                (this.props.colorMode === "day")?
                    "search-bar-input-menu input-s2 input-day" :
                    "search-bar-input-menu input-s2 input-night"
            ) : (
                (this.props.searchSmall)?
                    (
                        (this.props.colorMode === "day")?
                            "search-bar-input-small input-s2 input-day" :
                            "search-bar-input-small input-s2 input-night"
                    ) : (
                        (this.props.colorMode === "day")?
                            "search-bar-input input-s2 input-day" :
                            "search-bar-input input-s2 input-night"
                    )
            );

        // Display flags
        const nearbySearchOn = !this.props.inMenu;
        const searchInputOn = ((this.props.inMenu && this.props.searchOff) || (!this.props.inMenu && !this.props.searchOff));

        // JSX part to render html elements
        return (
            <div className = "search-bar-form">
                <div
                    className = "search-bar-icon image-button-s5"
                    style = {{
                        backgroundImage : nearbySubmitImage,
                        display: nearbySearchOn? "inline-block" : "none"
                    }}
                    onClick = {
                        () => {
                            // Turn off main menu
                            if (this.props.mainMenuOff && (typeof this.props.mainMenuOff === "function")) {
                                this.props.mainMenuOff();
                            }

                            // Get keywords
                            const keywords = this.searchInputRef.current.value;
                            //console.log("Search / render - keywords = ", keywords);

                            if (keywords === "" || keywords === null || keywords === undefined) {
                                //console.log("Search / render - nearby search");

                                // Redirect to plan page
                                this.props.history.push("/search/location/");
                            }
                            else {
                                //console.log("Search / render - keyword search");

                                const searchResultsStates = {
                                    keywords: keywords,
                                    userLocation: null
                                };

                                // Redirect to result page
                                this.props.history.push(
                                    {
                                        pathname: "/search/results/",
                                        state: searchResultsStates
                                    }
                                );
                            }
                        }
                    }
                >
                </div>
                <input
                    ref = {this.searchInputRef}
                    id = "search-bar-input"
                    className = {searchInputClassName}
                    style = {{
                        display: searchInputOn? "inline-block" : "none"
                    }}
                    {...inputElementProps}
                />
                <div
                    className = "search-bar-icon image-button-s8"
                    style = {{
                        backgroundImage : submitImage,
                        display: searchInputOn? "inline-block" : "none"
                    }}
                    onClick = { () => {
                        // Turn off main menu
                        if (this.props.mainMenuOff && (typeof this.props.mainMenuOff === "function")) {
                            this.props.mainMenuOff();
                        }

                        // Get keywords
                        const keywords = this.searchInputRef.current.value;
                        //console.log("Search / render - keywords = ", keywords);

                        if (keywords === "" || keywords === null || keywords === undefined) {
                            //console.log("Search / render - nearby search");

                            // Redirect to plan page
                            this.props.history.push("/search/location/");
                        }
                        else {
                            //console.log("Search / render - keyword search");

                            const searchResultsStates = {
                                keywords: keywords,
                                userLocation: null
                            };

                            // Redirect to result page
                            this.props.history.push(
                                {
                                    pathname: "/search/results/",
                                    state: searchResultsStates
                                }
                            );
                        }
                    }}
                >
                </div>
            </div>
        );
    }
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode
    };
}


export default connect(mapStateToProps, null)(SearchInput);