/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Bucket.js
--------------------------------------------------------------------------------------------
    Content
    - Bucket
============================================================================================
*/


// React / ReactDOM / React-router / Redux
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    storeDotTag,
    storeNewDotTag,
    storeUserTag,
    storeNewUserTag,
    storeNewMemo,
    storeGallery
} from "actions";

// Modules
import moment from "moment-timezone";
import ReactTooltip from "thedots-tooltip";

// Components
import { Gallery, mediaDimensions } from "components/Gallery";
import Globe from "components/Globe";
import { UserProfilePicList } from "js/Common";

// Functions
import {
    getStaticPath,
    getMediaProperty,
    sortMedia,
    roundMoment,
    debounce
} from "js/Functions";

import {
    regularCuration
} from "js/Curation";

// Axios
import {
    postDotSave,
    getDotSaves,
    getContributions,
    //getCompletions,
    getLocations
} from "requests";

// CSS
import "./Bucket.scss";


/*
===============================================================
    UnconnectedBucket
===============================================================
*/

class UnconnectedBucket extends Component {
    constructor(props) {
        super(props);

        // DOM ref
        this.bucketRef = React.createRef();

        // Top margin for scrolling
        this.topMargin = 68;
        this.topMarginSmall = 58;
        this.menuHeight = 35;
        this.bottomMenuShift = -100;
        this.bottomMenuOnPosition = -800;

        // Bottom menu change in progress
        this.bottomMenuChanging = false;

        // Pages per group
        this.numPagesPerGroup = 10;

        //  Min dots for time bar
        this.minDotsForTimeBar = 2;

        //  Min dots for tags
        this.minDotsForDotTags = 5;
        this.minDotsForCount = 1;

        // Globe size
        this.globeSize = 300;

        // Get the initial bucket mode
        const bucketMode = (this.props.bucketMode === null)?
        (
            (this.props.userInfo.bucket_mode === null)?
                (
                    (this.props.userInfo.editing_dot_count > this.props.userInfo.saved_dot_count)?
                        "everyday" : "save"
                ) : this.props.userInfo.bucket_mode
        ) : this.props.bucketMode;

        // Get the initial display mode
        const displayMode = (this.props.displayMode === null)?
            "bucket" : this.props.displayMode;

        // Initialize state
        this.state = {
            displayMode: displayMode,
            bucketMode: bucketMode,
            globeMode: "all",
            selectedDotTag: null,
            savedDotTags: this.props.userInfo.dot_tags_saved,
            contributedDotTags: this.props.userInfo.dot_tags_contributed,
            everydayDotTags: this.props.userInfo.dot_tags_everyday,
            savedCount: 0,
            savedPage: 1,
            savedMaxPage: null,
            savedDots: {},
            contributedCount: 0,
            contributedPage: 1,
            contributedMaxPage: null,
            contributedDots: {},
            everydayCount: 0,
            everydayPage: 1,
            everydayMaxPage: null,
            everydayDots: {},
            savedLocations: [],
            everydayLocations: [],
            contributedLocations: [],
            collectionMenuOn: null,
            bottomMenuOn: false,

            // Timeline scroll
            scrollToItem: 0
        };

        // Bind functions
        this.setState = this.setState.bind(this);
        this.switchToBucketedMode = this.switchToBucketedMode.bind(this);
        this.switchToEverydayMode = this.switchToEverydayMode.bind(this);
        this.switchToContributedMode = this.switchToContributedMode.bind(this);
        this.fetchDots = this.fetchDots.bind(this);
        this.previousGroup = this.previousGroup.bind(this);
        this.nextGroup = this.nextGroup.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.unsaveClick = unsaveClick.bind(this);

        // Collection menu functions
        this.menuBucketClick = this.menuBucketClick.bind(this);
        this.menuModeClick = this.menuModeClick.bind(this);
        this.menuModeHoverOn = this.menuModeHoverOn.bind(this);
        this.menuModeHoverOff = this.menuModeHoverOff.bind(this);
        this.menuDisplayClick = this.menuDisplayClick.bind(this);

        // Bind globe toggle
        this.toggleGlobeMode = this.toggleGlobeMode.bind(this);

        // Scroll functions
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 10);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    render() {
        //console.log("Bucket / render - this.state = ", this.state);
        //console.log("Bucket / render - this.props.userInfo = ", this.props.userInfo);

        /*
        ============================================================================================
            Dot List
        ============================================================================================
        */

        // List of saved or contributed dots for the current page
        const dotsInfo = (this.state.bucketMode === "save")?
            this.state.savedDots[this.state.savedPage.toString()] :
            (this.state.bucketMode === "everyday")?
                this.state.everydayDots[this.state.everydayPage.toString()] :
                this.state.contributedDots[this.state.contributedPage.toString()];
        //console.log("Bucket / render - this.state.savedDots = ", this.state.savedDots);
        //console.log("Bucket / render - this.state.savedPage = ", this.state.savedPage);
        //console.log("Bucket / render - dotsInfo = ", dotsInfo);

        // Dot list
        const bucketList = ((typeof dotsInfo !== "undefined") && (this.state.renderContent === true))? (
            dotsInfo.map((dotInfo, index) => {
                return (
                    <BucketItem
                        key = {"user-info-bucket-mode-" + this.state.bucketMode + "-item-" + index}
                        index = {index}
                        dotInfo = {dotInfo}
                        displayMode = {this.state.displayMode}
                        colorMode = {this.props.colorMode}
                        browserWidth = {this.props.browserWidth}
                        isMyself = {this.props.isMyself}
                        bucketMode = {this.state.bucketMode}
                        storeDotTag = {this.props.storeDotTag}
                        storeUserTag = {this.props.storeUserTag}
                        storeGallery = {this.props.storeGallery}
                        unsaveClick = {this.unsaveClick}
                        setState = {this.setState}
                    />
                );
            })
        ) : null;

        // Contributed and saved counts and click callbacks
        //const contributedCount = (this.props.userInfo.editing_dot_count > 0)? this.props.userInfo.editing_dot_count : null;
        //const savedCount = (this.props.userInfo.saved_dot_count > 0)? this.props.userInfo.saved_dot_count : null;
        //const contributedClick = (this.props.userInfo.editing_dot_count > 0)? this.switchToContributedMode : null;
        //const savedClick = (this.props.userInfo.saved_dot_count > 0)? this.switchToBucketedMode : null;


        /*
        ============================================================================================
            Pagination
        ============================================================================================
        */

        // Get the page
        const page = (this.state.bucketMode === "save")? 
            this.state.savedPage : 
            (this.state.bucketMode === "everyday")?
                this.state.everydayPage : this.state.contributedPage;

        // Get the max page
        const maxPage = (this.state.bucketMode === "save")?
            this.state.savedMaxPage : 
            (this.state.bucketMode === "everyday")?
                this.state.everydayMaxPage : this.state.contributedMaxPage;

        let previous = null;
        let next = null;
        let pageItems = null;
        let pages = null;
        //console.log("maxPage = ", maxPage);
        //console.log("this.state.savedMaxPage = ", this.state.savedMaxPage);
        //console.log("this.state.everydayMaxPage = ", this.state.everydayMaxPage);
        //console.log("this.state.contributedMaxPage = ", this.state.contributedMaxPage);

        if (((typeof dotsInfo !== "undefined") && (maxPage > 1)) && (this.state.renderContent === true)) {

            const maxPageGroup = Math.floor((maxPage - 1) / this.numPagesPerGroup); // indexed from 0
            const pageGroup = Math.floor((page - 1) / this.numPagesPerGroup); // indexed from 0

            // Get the navigation arrows
            if (pageGroup > 0) {
                previous = (
                    <div className = "user-info-bucket-previous"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/arrow-left-black.png") :
                                getStaticPath("/images/common/arrow-left-white.png")
                        }}
                        onClick = {this.previousGroup}
                    >
                    </div>
                );
            }

            if (pageGroup < maxPageGroup) {
                next = (
                    <div className = "user-info-bucket-next"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/arrow-right-black.png") :
                                getStaticPath("/images/common/arrow-right-white.png")
                        }}
                        onClick = {this.nextGroup}
                    >
                    </div>
                );
            }

            // Page groups
            const pageGroupStart = pageGroup * this.numPagesPerGroup + 1;
            const pageGroupEnd = (maxPage > (pageGroup + 1) * this.numPagesPerGroup)?
                (pageGroup + 1) * this.numPagesPerGroup : maxPage;

            // Pages
            const pageNumbers = [];
            for (let pageNumber = pageGroupStart; pageNumber <= pageGroupEnd; pageNumber++) {
                pageNumbers.push(pageNumber);
            }

            // For all pages
            pageItems = pageNumbers.map((pageNumber, index) => {
                const pageClassName = (pageNumber === page)?
                    (
                        (this.props.colorMode === "day")?
                            "user-info-bucket-page page-selected-day-s2 " :
                            "user-info-bucket-page page-selected-night-s2"
                    ) : (
                        (this.props.colorMode === "day")?
                            "user-info-bucket-page page-day-s2 " :
                            "user-info-bucket-page page-night-s2"
                    );

                return (
                    <div key = {"user-info-bucket-pagination-mode-" + this.state.bucketMode +
                        "-tag-" + this.state.selectedDotTag + "-page-" + pageNumber.toString()}
                        className = {pageClassName}
                        onClick = {() => {this.goToPage(pageNumber);}}
                    >
                        {pageNumber}
                    </div>
                );
            });

            // JSX
            pages = (
                <div className = "user-info-bucket-pages-container">
                    {previous}
                    <div className = "user-info-bucket-pages">
                        {pageItems}
                    </div>
                    {next}
                </div>
            );
        }


        /*
        ============================================================================================
            Dot Timeline
        ============================================================================================
        */

        const timeline = (((typeof dotsInfo !== "undefined") &&
            (this.state.renderContent === true)))?
        (
            <Timeline
                key = {"user-info-timeline-mode-" + this.state.bucketMode +
                    "-page-" + {page} + "-tag-" + this.state.selectedDotTag}
                displayMode = {this.state.displayMode}
                colorMode = {this.props.colorMode}
                selectedDotTag = {this.state.selectedDotTag}
                browserWidth = {this.props.browserWidth}
                browserWidthPixels = {this.props.browserWidthPixels}
                isMyself = {this.props.isMyself}
                dotsInfo = {dotsInfo}
                bucketMode = {this.state.bucketMode}
                storeDotTag = {this.props.storeDotTag}
                storeUserTag = {this.props.storeUserTag}
                scrollToItem = {this.state.scrollToItem}
                topMargin = {this.topMargin}
                topMarginSmall = {this.topMarginSmall}
                menuHeight = {this.menuHeight}
                userInfo = {this.props.userInfo}
            />
        ) : null;


        /*
        ============================================================================================
            Bucket Header
        ============================================================================================
        */

        // Bucket image
        const bucketImage = (this.props.colorMode === "day")?
            getStaticPath("/images/user/bucket-black.png") :
            getStaticPath("/images/user/bucket-white.png");

        const bucketHeader = (true)? null : (
            <div id = "user-info-bucket-header">
                <div id = "user-info-bucket-contributed-count"
                    className = {
                        (this.state.bucketMode === "contributed")?
                        (
                            (this.props.colorMode === "day")? "number-lb2" : "number-b2"
                        ) : (
                            (this.props.colorMode === "day")? "number-lg2" : "number-g2"
                        )
                    }
                    onClick = { this.switchToContributedMode }
                    data-tip = "Authored Dots"
                    data-for = "user-info-bucket-contributed-count-tooltip"
                >
                    {this.props.userInfo.editing_dot_count }
                </div>
                <div id = "user-info-bucket-image"
                    className = "image-cover"
                    style = {{ backgroundImage: bucketImage }}
                >
                </div>
                <div id = "user-info-bucket-saved-count"
                    className = {
                        (this.state.bucketMode === "save")?
                        (
                            (this.props.colorMode === "day")? "number-lb2" : "number-b2"
                        ) : (
                            (this.props.colorMode === "day")? "number-lg2" : "number-g2"
                        )
                    }
                    onClick = { this.switchToBucketedMode }
                    data-tip = "Bucketed Dots"
                    data-for = "user-info-bucket-saved-count-tooltip"
                 >
                    {this.props.userInfo.saved_dot_count }
                </div>
                <ReactTooltip
                    id = "user-info-bucket-contributed-count-tooltip"
                    className = "user-info-bucket-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
                <ReactTooltip
                    id = "user-info-bucket-saved-count-tooltip"
                    className = "user-info-bucket-tooltip tooltip-s2"
                    type = "dark"
                    place = "right"
                    html = {true}
                />
            </div>
        );

        // Get the count
        const count = (this.state.bucketMode === "save")?
            this.state.savedCount :
            (
                (this.state.bucketMode === "contribute")?
                    this.state.contributedCount :
                    this.state.everydayCount
            );


        /*
        ============================================================================================
            Dot Tags
        ============================================================================================
        */

        // DotTags to display
        let dotTags = null;
        if (this.state.bucketMode === "save") {
            dotTags = this.state.savedDotTags;
        }
        else if (this.state.bucketMode === "contribute") {
            dotTags = this.state.contributedDotTags;
        }
        else if (this.state.bucketMode === "everyday") {
            dotTags = this.state.everydayDotTags;
        }
        else {
            console.log("[WARNING] Bucket / render - wrong bucket mode");
        }


        /*
        ============================================================================================
            Collection Name
        ============================================================================================
        */

        // For all current tags
        let collectionName = null;
        if (this.state.selectedDotTag !== null) {
            for (let i = 0; i < dotTags.length; i++) {
                if (this.state.selectedDotTag === dotTags[i].id) {
                    collectionName = dotTags[i].tag_name[0].toUpperCase() + dotTags[i].tag_name.slice(1);
                }
            }
        }


        /*
        ============================================================================================
            Collection
        ============================================================================================
        */

        let topCollection = null;
        let bottomCollection = null;
        if (this.state.selectedDotTag !== null) {
            topCollection = (
                <div id = "user-info-top-collection"
                    style = {{ display: (this.state.renderContent)? "block" : "none" }}
                >
                    <div id = "user-info-top-collection-label"
                        className = {
                            (this.props.colorMode === "day")?
                                "g4" : "g4"
                        }
                    >
                        Collection
                    </div>
                    <div id = "user-info-top-collection-name"
                        className = {
                            (this.props.colorMode === "day")?
                                "k2" : "w2"
                        }
                    >
                        {collectionName}
                    </div>
                    <div id = "user-info-top-collection-count"
                        className = {
                            (this.props.colorMode === "day")?
                                "light-blue font-cabin" :
                                "blue font-cabin"
                        }
                    >
                        {count}
                    </div>
                </div>
            );

            bottomCollection = (
                <div id = "user-info-bottom-collection"
                    style = {{ display: (this.state.renderContent)? "block" : "none" }}
                >
                    <div id = "user-info-bottom-collection-label"
                        className = {
                            (this.props.colorMode === "day")?
                                "g4" : "g4"
                        }
                    >
                        Collection
                    </div>
                    <div id = "user-info-bottom-collection-name"
                        className = {
                            (this.props.colorMode === "day")?
                                "k2" : "w2"
                        }
                    >
                        {collectionName}
                    </div>
                    <div id = "user-info-bottom-collection-count"
                        className = {
                            (this.props.colorMode === "day")?
                                "light-blue font-cabin" :
                                "blue font-cabin"
                        }
                    >
                        {count}
                    </div>
                </div>
            );
        }


        /*
        ============================================================================================
            Collection Menus
        ============================================================================================
        */

        const savedCollectionTopMenu = (this.state.savedDotTags.length > 0  && this.state.collectionMenuOn === "save-top")?
        (
            <CollectionMenu
                mode = {"save"}
                position = {"top"}
                browserWidth = {this.props.browserWidth}
                isMyself = {this.props.isMyself}
                colorMode = {this.props.colorMode}
                bucketMode = {this.state.bucketMode}
                selectedDotTag = {this.state.selectedDotTag}
                dotTags = {this.state.savedDotTags}
                storeDotTag = {this.props.storeDotTag}
                userInfo = {this.props.userInfo}
                setState = {this.setState}
                minDotsForDotTags = {this.minDotsForDotTags}
                minDotsForCount = {this.minDotsForCount}
                scrollToTop = {this.scrollToTop}
            />
        ) : null;

        const contributedCollectionTopMenu = (this.state.contributedDotTags.length > 0 && this.state.collectionMenuOn === "contribute-top")?
        (
            <CollectionMenu
                mode = {"contribute"}
                position = {"top"}
                browserWidth = {this.props.browserWidth}
                isMyself = {this.props.isMyself}
                colorMode = {this.props.colorMode}
                bucketMode = {this.state.bucketMode}
                selectedDotTag = {this.state.selectedDotTag}
                dotTags = {this.state.contributedDotTags}
                storeDotTag = {this.props.storeDotTag}
                userInfo = {this.props.userInfo}
                setState = {this.setState}
                minDotsForDotTags = {this.minDotsForDotTags}
                minDotsForCount = {this.minDotsForCount}
                scrollToTop = {this.scrollToTop}
            />
        ) : null;

        const everydayCollectionTopMenu = (this.state.everydayDotTags.length > 0 && this.state.collectionMenuOn === "everyday-top")?
        (
            <CollectionMenu
                mode = {"everyday"}
                position = {"top"}
                browserWidth = {this.props.browserWidth}
                isMyself = {this.props.isMyself}
                colorMode = {this.props.colorMode}
                bucketMode = {this.state.bucketMode}
                selectedDotTag = {this.state.selectedDotTag}
                dotTags = {this.state.everydayDotTags}
                storeDotTag = {this.props.storeDotTag}
                userInfo = {this.props.userInfo}
                setState = {this.setState}
                minDotsForDotTags = {this.minDotsForDotTags}
                minDotsForCount = {this.minDotsForCount}
                scrollToTop = {this.scrollToTop}
            />
        ) : null;

        const savedCollectionBottomMenu = (this.state.savedDotTags.length > 0  && this.state.collectionMenuOn === "save-bottom")?
        (
            <CollectionMenu
                mode = {"save"}
                position = {"bottom"}
                browserWidth = {this.props.browserWidth}
                isMyself = {this.props.isMyself}
                colorMode = {this.props.colorMode}
                bucketMode = {this.state.bucketMode}
                selectedDotTag = {this.state.selectedDotTag}
                dotTags = {this.state.savedDotTags}
                storeDotTag = {this.props.storeDotTag}
                userInfo = {this.props.userInfo}
                setState = {this.setState}
                minDotsForDotTags = {this.minDotsForDotTags}
                minDotsForCount = {this.minDotsForCount}
                scrollToTop = {this.scrollToTop}
            />
        ) : null;

        const contributedCollectionBottomMenu = (this.state.contributedDotTags.length > 0 && this.state.collectionMenuOn === "contribute-bottom")?
        (
            <CollectionMenu
                mode = {"contribute"}
                position = {"bottom"}
                browserWidth = {this.props.browserWidth}
                isMyself = {this.props.isMyself}
                colorMode = {this.props.colorMode}
                bucketMode = {this.state.bucketMode}
                selectedDotTag = {this.state.selectedDotTag}
                dotTags = {this.state.contributedDotTags}
                storeDotTag = {this.props.storeDotTag}
                userInfo = {this.props.userInfo}
                setState = {this.setState}
                minDotsForDotTags = {this.minDotsForDotTags}
                minDotsForCount = {this.minDotsForCount}
                scrollToTop = {this.scrollToTop}
            />
        ) : null;

        const everydayCollectionBottomMenu = (this.state.everydayDotTags.length > 0 && this.state.collectionMenuOn === "everyday-bottom")?
        (
            <CollectionMenu
                mode = {"everyday"}
                position = {"bottom"}
                browserWidth = {this.props.browserWidth}
                isMyself = {this.props.isMyself}
                colorMode = {this.props.colorMode}
                bucketMode = {this.state.bucketMode}
                selectedDotTag = {this.state.selectedDotTag}
                dotTags = {this.state.everydayDotTags}
                storeDotTag = {this.props.storeDotTag}
                userInfo = {this.props.userInfo}
                setState = {this.setState}
                minDotsForDotTags = {this.minDotsForDotTags}
                minDotsForCount = {this.minDotsForCount}
                scrollToTop = {this.scrollToTop}
            />
        ) : null;


        /*
        ============================================================================================
            Top Menu
        ============================================================================================
        */

        const displayModeImage = (this.state.displayMode === "bucket")?
        (
            (this.props.colorMode === "day")?
                getStaticPath("/images/user/display-timeline-black.png") :
                getStaticPath("/images/user/display-timeline-white.png")
        ) : (
            (this.props.colorMode === "day")?
                getStaticPath("/images/user/display-bucket-black.png") :
                getStaticPath("/images/user/display-bucket-white.png")
        );

        const textClassName = (this.props.browserWidth <= 2)?
            " font-century user-info-top-menu-mode-small" :
            " font-century user-info-top-menu-mode";

        const topMenu = (
            <div id = {(this.props.browserWidth <= 10)?
                   "user-info-top-menu-container-small" : "user-info-top-menu-container"}
                //style = {{ height: (this.state.bottomMenuOn)? 0 : "auto" }}
            >
                <div id = "user-info-top-menu-modes">
                    <div id = {
                            (this.props.browserWidth <= 2)?
                                "user-info-top-menu-bucket-image-small" :
                                "user-info-top-menu-bucket-image"
                        }
                        className = "image-cover"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/user/bucket-black.png") :
                                getStaticPath("/images/user/bucket-white.png")
                        }}
                        onClick = {
                            (event) => {
                                this.menuBucketClick(event, dotTags);
                            }
                        }
                    >
                    </div>
                    <div id = "user-info-top-menu-mode-saved"
                        className = {
                            (this.state.bucketMode === "save")?
                            (
                                (this.props.colorMode === "day")?
                                    "user-info-top-menu-mode-selected-day light-blue" + textClassName :
                                    "user-info-top-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName :
                                    "gray-blue" + textClassName
                            )
                        }
                        //onTouchStart = {() => { console.log("Parent - onTouchStart"); }}
                        //onTouchEnd = {() => { console.log("Parent - onTouchEnd"); }}
                        onMouseEnter = {(event) => { this.menuModeHoverOn(event, "save-top"); }}
                        onMouseLeave = {this.menuModeHoverOff}
                        onClick = {(event) => { this.menuModeClick(event, "save", "top"); }}
                    >
                        Bucketed
                        {savedCollectionTopMenu}
                    </div>

                    <div id = "user-info-top-menu-mode-contributed"
                        className = {
                            (this.state.bucketMode === "contribute")?
                            (
                                (this.props.colorMode === "day")?
                                    "user-info-top-menu-mode-selected-day light-blue" + textClassName :
                                    "user-info-top-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName:
                                    "gray-blue" + textClassName
                            )
                        }
                        onMouseEnter = {(event) => { this.menuModeHoverOn(event, "contribute-top"); }}
                        onMouseLeave = {this.menuModeHoverOff}
                        onClick = {(event) => { this.menuModeClick(event, "contribute", "top"); }}
                    >
                        Authored
                        {contributedCollectionTopMenu}
                    </div>

                    <div id = "user-info-top-menu-mode-everyday"
                        className = {
                            (this.state.bucketMode === "everyday")?
                            (
                                (this.props.colorMode === "day")?
                                    "user-info-top-menu-mode-selected-day light-blue" + textClassName :
                                    "user-info-top-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName :
                                    "gray-blue" + textClassName
                            )
                        }
                        onMouseEnter = {(event) => { this.menuModeHoverOn(event, "everyday-top"); }}
                        onMouseLeave = {this.menuModeHoverOff}
                        onClick = {(event) => { this.menuModeClick(event, "everyday", "top"); }}
                    >
                        Everyday
                        {everydayCollectionTopMenu}
                    </div>

                    <div id = {
                            (this.props.browserWidth <= 2)?
                                "user-info-top-menu-mode-display-small" :
                                "user-info-top-menu-mode-display"
                        }
                        className = "image-cover"
                        style = {{
                            backgroundImage: displayModeImage
                        }}
                        onClick = {this.menuDisplayClick}
                    >
                    </div>
                </div>
                {topCollection}
            </div>
        );


        /*
        ============================================================================================
            Bottom Menu
        ============================================================================================
        */

        const bottomMenu = (
            <div id = {(this.props.browserWidth <= 10)?
                    "user-info-bottom-menu-container-small" : "user-info-bottom-menu-container"}
                className = {(this.props.colorMode === "day")?
                    "modal-day-no-shadow" : "modal-night"}
                style = {{ bottom: (this.state.bottomMenuOn)? 0 : this.bottomMenuShift }}
            >
                {bottomCollection}
                <div id = "user-info-bottom-menu-modes">
                    <div id = {
                            (this.props.browserWidth <= 2)?
                                "user-info-bottom-menu-bucket-image-small" :
                                "user-info-bottom-menu-bucket-image"
                        }
                        className = "image-cover"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/user/bucket-black.png") :
                                getStaticPath("/images/user/bucket-white.png")
                        }}
                        onClick = {
                            (event) => {
                                this.menuBucketClick(event, dotTags);
                            }
                        }
                    >
                    </div>
                    <div id = "user-info-bottom-menu-mode-saved"
                        className = {
                            (this.state.bucketMode === "save")?
                            (
                                (this.props.colorMode === "day")?
                                    "user-info-bottom-menu-mode-selected-day light-blue" + textClassName :
                                    "user-info-bottom-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName :
                                    "gray-blue" + textClassName
                            )
                        }
                        onMouseEnter = {(event) => { this.menuModeHoverOn(event, "save-bottom"); }}
                        onMouseLeave = {this.menuModeHoverOff}
                        onClick = {(event) => { this.menuModeClick(event, "save", "bottom"); }}
                    >
                        Bucketed
                        {savedCollectionBottomMenu}
                    </div>

                    <div id = "user-info-bottom-menu-mode-contributed"
                        className = {
                            (this.state.bucketMode === "contribute")?
                            (
                                (this.props.colorMode === "day")?
                                    "user-info-bottom-menu-mode-selected-day light-blue" + textClassName :
                                    "user-info-bottom-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName:
                                    "gray-blue" + textClassName
                            )
                        }
                        onMouseEnter = {(event) => { this.menuModeHoverOn(event, "contribute-bottom"); }}
                        onMouseLeave = {this.menuModeHoverOff}
                        onClick = {(event) => { this.menuModeClick(event, "contribute", "bottom"); }}
                    >
                        Authored
                        {contributedCollectionBottomMenu}
                    </div>

                    <div id = "user-info-bottom-menu-mode-everyday"
                        className = {
                            (this.state.bucketMode === "everyday")?
                            (
                                (this.props.colorMode === "day")?
                                    "user-info-bottom-menu-mode-selected-day light-blue" + textClassName :
                                    "user-info-bottom-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName :
                                    "gray-blue" + textClassName
                            )
                        }
                        onMouseEnter = {(event) => { this.menuModeHoverOn(event, "everyday-bottom"); }}
                        onMouseLeave = {this.menuModeHoverOff}
                        onClick = {(event) => { this.menuModeClick(event, "everyday", "bottom"); }}
                    >
                        Everyday
                        {everydayCollectionBottomMenu}
                    </div>

                    <div id = {
                            (this.props.browserWidth <= 2)?
                                "user-info-bottom-menu-mode-display-small" :
                                "user-info-bottom-menu-mode-display"
                        }
                        className = "image-cover"
                        style = {{
                            backgroundImage: displayModeImage
                        }}
                        onClick = {this.menuDisplayClick}
                    >
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Time Bar
        ============================================================================================
        */

        let timeBar = null;
        //console.log("Bucket / render - dotsInfo = ", dotsInfo);
        if ((typeof dotsInfo !== "undefined") && (this.state.renderContent === true)) {
            if ((typeof dotsInfo === "object") && (dotsInfo.length > 0)) {
                //console.log("Bucket / render - first dot = ", dotsInfo[0]);
                //console.log("Bucket / render - last dot = ", dotsInfo[dotsInfo.length - 1]);

                const startTime = (this.state.bucketMode === "save")?
                    moment(dotsInfo[0].saved_time) :
                    (
                        (this.state.bucketMode === "everyday")?
                            moment(dotsInfo[0].everyday_time) :
                            moment(dotsInfo[0].contributed_time)
                    );

                const endTime = (this.state.bucketMode === "save")?
                    moment(dotsInfo[dotsInfo.length - 1].saved_time) :
                    (
                        (this.state.bucketMode === "everyday")?
                            moment(dotsInfo[dotsInfo.length - 1].everyday_time) :
                            moment(dotsInfo[dotsInfo.length - 1].contributed_time)
                    );
                //console.log("Bucket / render - startTime = ", startTime);
                //console.log("Bucket / render - endTime = ", endTime);

                timeBar = (dotsInfo.length >= this.minDotsForTimeBar)? (
                    <TimeBar
                        key = {"user-info-time-bar-mode-" + this.state.bucketMode + "-page-" + page + "-tag-" + this.state.selectedDotTag}
                        browserWidth = {this.props.browserWidth}
                        colorMode = {this.props.colorMode}
                        dotsInfo = {dotsInfo}
                        mode = {this.state.bucketMode}
                        startTime = {startTime}
                        endTime = {endTime}
                    />
                ) : null;
            }
        }




        /*
        ============================================================================================
            Globe
        ============================================================================================
        */

        let globe = null;

        if ((typeof dotsInfo !== "undefined") && (this.state.renderContent === true)) {
            if ((typeof dotsInfo === "object") && (dotsInfo.length >= 4)) {

                let globeLocationsInfo = null;
                if (this.state.globeMode === "all") {
                    if (this.state.bucketMode === "save") {
                        globeLocationsInfo = this.state.savedLocations;
                    }
                    else if (this.state.bucketMode === "everyday") {
                        globeLocationsInfo = this.state.everydayLocations;
                    }
                    else if (this.state.bucketMode === "contribute") {
                        globeLocationsInfo = this.state.contributedLocations;
                    }
                }
                else {
                    const locationsInfo = [];
                    for (let i = 0; i < dotsInfo.length; i++) {
                        locationsInfo.push(dotsInfo[i].location);
                    }
                    globeLocationsInfo = locationsInfo;
                }

                let globeModeButtonOn = null;
                if (this.state.bucketMode === "save") {
                    globeModeButtonOn = (this.state.savedMaxPage > 1);
                }
                else if (this.state.bucketMode === "everyday") {
                    globeModeButtonOn = (this.state.contributedMaxPage > 1);
                }
                else if (this.state.bucketMode === "contribute") {
                    globeModeButtonOn = (this.state.everydayMaxPage > 1);
                }
                
                globe = (
                    <Globe
                        key = {"globe-mode-" + this.state.bucketMode}
                        size = {this.globeSize}
                        locationsInfo = {globeLocationsInfo}
                        bucketMode = {this.state.bucketMode}
                        selectedDotTag = {this.state.selectedDotTag}
                        collectionName = {collectionName}
                        globeMode = {this.state.globeMode}
                        globeModeButtonOn = {globeModeButtonOn}
                        toggleGlobeMode = {this.toggleGlobeMode}
                    />
                );
            }
        }



        /*
        ============================================================================================
            JSX
        ============================================================================================
        */

        return (
            <div id = "user-info-bucket"
                ref = {this.bucketRef}
            >
                {bucketHeader}

                {topMenu}

                <div id = "user-info-bucket-content"
                    style = {{ display: (this.state.displayMode === "bucket")? "block" : "none" }}
                >
                    <div id = {
                        (this.props.browserWidth <= 2)?
                            "user-info-bucket-container-small" :
                                (
                                    (this.props.browserWidth <= 4)?
                                        "user-info-bucket-container-small" :
                                            (
                                                (this.props.browserWidth <= 7)?
                                                    "user-info-bucket-container-medium" :
                                                    "user-info-bucket-container"
                                            )
                                )
                        }
                    >

                        {bucketList}

                    </div>
                </div>

                {timeline}

                {pages}

                {timeBar}

                {globe}

                {bottomMenu}

            </div>
        );
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollListener);
    }

    componentDidMount() {
        //console.log("Bucket / componentDidMount - this.props.userInfo = ", this.props.userInfo);

        // Scroll listener
        window.addEventListener("scroll", this.scrollListener);

        // Fetch dots
        this.fetchDots(1, this.state.bucketMode, true, null, null);

        // Fetch locations for Globe
        this.fetchLocations();
    }

    componentDidUpdate(prevProps, prevState) {
        // If recently saved or contributed dots have changed
        if (JSON.stringify(this.props.userInfo) !== JSON.stringify(prevProps.userInfo)) {
            //console.log("Bucket / componentDidUpdate - saved or contributed");

            if (this.state.bucketMode === "save") {
                this.fetchDots(this.state.savedPage, "save", true, this.state.selectedDotTag, null);
            }
            else if (this.state.bucketMode === "contribute") {
                this.fetchDots(this.state.contributedPage, "contribute", true, this.state.selectedDotTag, null);
            }
            else if (this.state.bucketMode === "everyday") {
                this.fetchDots(this.state.everydayPage, "everyday", true, this.state.selectedDotTag, null);
            }
        }

        // If mode or tag has changed
        if ((this.state.bucketMode !== prevState.bucketMode) || (this.state.selectedDotTag !== prevState.selectedDotTag)) {
            // Fetch dots
            this.fetchDots(1, this.state.bucketMode, true, this.state.selectedDotTag, this.scrollToTop);

            // Fetch locations
            this.fetchLocations();

            // Reset globe mode
            this.setState({ globeMode: "all" });
        }

        // When a new dot tag is added
        if (prevProps.newDotTag.id === null && this.props.newDotTag.id !== null) {
            //console.log("Bucket / componentDidUpdate - newDotTag = ", this.props.newDotTag);

            if (this.props.newDotTag.type === "filter") {
                let newDotTags = null;
                if (this.props.newDotTag.mode === "save") {
                    newDotTags = this.state.savedDotTags.slice();
                }
                else if (this.props.newDotTag.mode === "contribute") {
                    newDotTags = this.state.contributedDotTags.slice();
                }
                else if (this.props.newDotTag.mode === "everyday") {
                    newDotTags = this.state.everydayDotTags.slice();
                }
                //console.log("Bucket / componentDidUpdate - dotTags = ", newDotTags);

                if (this.props.newDotTag.operation === "add") {
                    newDotTags.push(this.props.newDotTag.tag);
                }
                else {
                    let indexToRemove = null;
                    for (let i = 0; i < newDotTags.length; i++) {
                        if (newDotTags[i].id === this.props.newDotTag.tag.id) {
                            indexToRemove = i;
                        }
                    }
                    newDotTags.splice(indexToRemove, 1);
                }
                //console.log("Bucket / componentDidUpdate - newDotTags = ", newDotTags);

                if (this.props.newDotTag.mode === "save") {
                    this.setState({
                        savedDotTags: newDotTags
                    });
                }
                else if (this.props.newDotTag.mode === "contribute") {
                    this.setState({
                        contributedDotTags: newDotTags
                    });
                }
                else if (this.props.newDotTag.mode === "everyday") {
                    this.setState({
                        everydayDotTags: newDotTags
                    });
                }
            }
            else if (this.props.newDotTag.type === "tag") {
                if (this.props.newDotTag.mode === "save") {
                    // Copy saved dots objects
                    const savedDots = Object.assign({}, this.state.savedDots);

                    // For the current page dots
                    for (let i = 0; i < savedDots[this.state.savedPage].length; i++) {
                        // If found a match
                        if (savedDots[this.state.savedPage][i].id === this.props.newDotTag.id) {
                            // Add a dot tag
                            if (this.props.newDotTag.operation === "add") {
                                savedDots[this.state.savedPage][i].dot_tags.push(this.props.newDotTag.tag);
                            }
                            // Remove a dot tag
                            else if (this.props.newDotTag.operation === "remove") {
                                let indexToRemove = null;
                                for (let j = 0; j < savedDots[this.state.savedPage][i].dot_tags.length; j++) {
                                    if (savedDots[this.state.savedPage][i].dot_tags[j].id === this.props.newDotTag.tag.id) {
                                        indexToRemove = j;
                                    }
                                }
                                savedDots[this.state.savedPage][i].dot_tags.splice(indexToRemove, 1);
                            }
                            else {
                                console.log("[WARNING] Bucket / componentDidUpdate - wrong operation");
                            }
                        }
                    }

                    // Update saved dots
                    this.setState({
                        savedDots: savedDots
                    });

                    //console.log("Bucket / componentDidUpdate - savedDots = ", savedDots);
                    //console.log("Bucket / componentDidUpdate - this.props.newDotTag = ", this.props.newDotTag);
                }
                else if (this.props.newDotTag.mode === "contribute") {
                    // Copy contributed dots objects
                    const contributedDots = Object.assign({}, this.state.contributedDots);

                    // For the current page dots
                    for (let i = 0; i < contributedDots[this.state.contributedPage].length; i++) {
                        // If found a match
                        if (contributedDots[this.state.contributedPage][i].id === this.props.newDotTag.id) {
                            // Add a dot tag
                            if (this.props.newDotTag.operation === "add") {
                                contributedDots[this.state.contributedPage][i].dot_tags.push(this.props.newDotTag.tag);
                            }
                            // Remove a dot tag
                            else if (this.props.newDotTag.operation === "remove") {
                                let indexToRemove = null;
                                for (let j = 0; j < contributedDots[this.state.contributedPage][i].dot_tags.length; j++) {
                                    if (contributedDots[this.state.contributedPage][i].dot_tags[j].id === this.props.newDotTag.tag.id) {
                                        indexToRemove = j;
                                    }
                                }
                                contributedDots[this.state.contributedPage][i].dot_tags.splice(indexToRemove, 1);
                            }
                            else {
                                console.log("[WARNING] Bucket / componentDidUpdate - wrong operation");
                            }
                        }
                    }

                    // Update contributed dots
                    this.setState({
                        contributedDots: contributedDots
                    });
                }                
                else if (this.props.newDotTag.mode === "everyday") {
                    // Copy everyday dots objects
                    const everydayDots = Object.assign({}, this.state.everydayDots);

                    // For the current page dots
                    for (let i = 0; i < everydayDots[this.state.everydayPage].length; i++) {
                        // If found a match
                        if (everydayDots[this.state.everydayPage][i].id === this.props.newDotTag.id) {
                            // Add a dot tag
                            if (this.props.newDotTag.operation === "add") {
                                everydayDots[this.state.everydayPage][i].dot_tags.push(this.props.newDotTag.tag);
                            }
                            // Remove a dot tag
                            else if (this.props.newDotTag.operation === "remove") {
                                let indexToRemove = null;
                                for (let j = 0; j < everydayDots[this.state.everydayPage][i].dot_tags.length; j++) {
                                    if (everydayDots[this.state.everydayPage][i].dot_tags[j].id === this.props.newDotTag.tag.id) {
                                        indexToRemove = j;
                                    }
                                }
                                everydayDots[this.state.everydayPage][i].dot_tags.splice(indexToRemove, 1);
                            }
                            else {
                                console.log("[WARNING] Bucket / componentDidUpdate - wrong operation");
                            }
                        }
                    }

                    // Update everyday dots
                    this.setState({
                        everydayDots: everydayDots
                    });
                }
            }

            // Reset new dot tag
            this.props.storeNewDotTag({
                operation: null,
                type: null,
                mode: null,
                id: null,
                tag: null
            });
        }

        // When a new dot tag is added
        if (prevProps.newUserTag.id === null && this.props.newUserTag.id !== null) {
            //console.log("Bucket / componentDidUpdate - newUserTag = ", this.props.newUserTag);

            if (this.props.newUserTag.mode === "save") {
                // Copy saved dots objects
                const savedDots = Object.assign({}, this.state.savedDots);

                // For the current page dots
                for (let i = 0; i < savedDots[this.state.savedPage].length; i++) {
                    // If found a match
                    if (savedDots[this.state.savedPage][i].id === this.props.newUserTag.id) {
                        // Add a user tag
                        if (this.props.newUserTag.operation === "add") {
                            savedDots[this.state.savedPage][i].user_tags.push(this.props.newUserTag.tag);
                        }
                        // Remove a user tag
                        else if (this.props.newUserTag.operation === "remove") {
                            let indexToRemove = null;
                            for (let j = 0; j < savedDots[this.state.savedPage][i].user_tags.length; j++) {
                                if (savedDots[this.state.savedPage][i].user_tags[j].id === this.props.newUserTag.tag.id) {
                                    indexToRemove = j;
                                }
                            }
                            savedDots[this.state.savedPage][i].user_tags.splice(indexToRemove, 1);
                        }
                        else {
                            console.log("[WARNING] Bucket / componentDidUpdate - wrong operation");
                        }
                    }
                }

                // Update saved dots
                this.setState({
                    savedDots: savedDots
                });

                //console.log("Bucket / componentDidUpdate - savedDots = ", savedDots);
                //console.log("Bucket / componentDidUpdate - this.props.newDotTag = ", this.props.newDotTag);
            }
            else if (this.props.newUserTag.mode === "contribute") {
                // Copy contributed dots objects
                const contributedDots = Object.assign({}, this.state.contributedDots);

                // For the current page dots
                for (let i = 0; i < contributedDots[this.state.contributedPage].length; i++) {
                    // If found a match
                    if (contributedDots[this.state.contributedPage][i].id === this.props.newUserTag.id) {
                        // Add a user tag
                        if (this.props.newUserTag.operation === "add") {
                            contributedDots[this.state.contributedPage][i].user_tags.push(this.props.newUserTag.tag);
                        }
                        // Remove a user tag
                        else if (this.props.newUserTag.operation === "remove") {
                            let indexToRemove = null;
                            for (let j = 0; j < contributedDots[this.state.contributedPage][i].user_tags.length; j++) {
                                if (contributedDots[this.state.contributedPage][i].user_tags[j].id === this.props.newUserTag.tag.id) {
                                    indexToRemove = j;
                                }
                            }
                            contributedDots[this.state.contributedPage][i].user_tags.splice(indexToRemove, 1);
                        }
                        else {
                            console.log("[WARNING] Bucket / componentDidUpdate - wrong operation");
                        }
                    }
                }

                // Update contributed dots
                this.setState({
                    contributedDots: contributedDots
                });
            }
            else if (this.props.newUserTag.mode === "everyday") {
                // Copy everyday dots objects
                const everydayDots = Object.assign({}, this.state.everydayDots);

                // For the current page dots
                for (let i = 0; i < everydayDots[this.state.everydayPage].length; i++) {
                    // If found a match
                    if (everydayDots[this.state.everydayPage][i].id === this.props.newUserTag.id) {
                        // Add a user tag
                        if (this.props.newUserTag.operation === "add") {
                            everydayDots[this.state.everydayPage][i].user_tags.push(this.props.newUserTag.tag);
                        }
                        // Remove a user tag
                        else if (this.props.newUserTag.operation === "remove") {
                            let indexToRemove = null;
                            for (let j = 0; j < everydayDots[this.state.everydayPage][i].user_tags.length; j++) {
                                if (everydayDots[this.state.everydayPage][i].user_tags[j].id === this.props.newUserTag.tag.id) {
                                    indexToRemove = j;
                                }
                            }
                            everydayDots[this.state.everydayPage][i].user_tags.splice(indexToRemove, 1);
                        }
                        else {
                            console.log("[WARNING] Bucket / componentDidUpdate - wrong operation");
                        }
                    }
                }

                // Update everyday dots
                this.setState({
                    everydayDots: everydayDots
                });
            }

            // Reset new dot tag
            this.props.storeNewUserTag({
                operation: null,
                mode: null,
                id: null,
                tag: null
            });
        }

        // When memo is updated
        if (prevProps.newMemo.id === null && this.props.newMemo.id !== null) {
            //console.log("Bucket / componentDidUpdate - prevProps.newMemo = ", prevProps.newMemo);
            //console.log("Bucket / componentDidUpdate - this.props.newMemo = ", this.props.newMemo);

            if (this.props.newMemo.mode === "save") {
                // Copy saved dots objects
                const savedDots = Object.assign({}, this.state.savedDots);

                // For the current page dots
                for (let i = 0; i < savedDots[this.state.savedPage].length; i++) {

                    // If found a match
                    if (savedDots[this.state.savedPage][i].interaction_id === this.props.newMemo.id) {
                        savedDots[this.state.savedPage][i].memo = this.props.newMemo.memo;
                    }
                }

                this.setState(
                    {
                        savedDots: savedDots
                    },
                    () => { this.props.storeNewMemo({ mode: null, id: null, memo: null }); }
                );
            }
            else if (this.props.newMemo.mode === "contribute") {
                // Copy contributed dots objects
                const contributedDots = Object.assign({}, this.state.contributedDots);

                // For the current page dots
                for (let i = 0; i < contributedDots[this.state.contributedPage].length; i++) {

                    // If found a match
                    if (contributedDots[this.state.contributedPage][i].interaction_id === this.props.newMemo.id) {
                        contributedDots[this.state.contributedPage][i].memo = this.props.newMemo.memo;
                    }
                }

                this.setState(
                    {
                        contributedDots: contributedDots
                    },
                    () => { this.props.storeNewMemo({ mode: null, id: null, memo: null }); }
                );
            }
            else if (this.props.newMemo.mode === "everyday") {
                // Copy everyday dots objects
                const everydayDots = Object.assign({}, this.state.everydayDots);

                // For the current page dots
                for (let i = 0; i < everydayDots[this.state.everydayPage].length; i++) {

                    // If found a match
                    if (everydayDots[this.state.everydayPage][i].interaction_id === this.props.newMemo.id) {
                        everydayDots[this.state.everydayPage][i].memo = this.props.newMemo.memo;
                    }
                }

                this.setState(
                    {
                        everydayDots: everydayDots
                    },
                    () => { this.props.storeNewMemo({ mode: null, id: null, memo: null }); }
                );
            }
        }

        // Update globe locations
        if (prevState.savedCount !== this.state.savedCount) {
            this.fetchLocations();
        }
        else if (prevState.contributedCount !== this.state.contributedCount) {
            this.fetchLocations();
        }
        else if (prevState.everydayCount !== this.state.everydayCount) {
            this.fetchLocations();
        }
    }


    /*
    ============================================================================================
        Collection Menu Actions
    ============================================================================================
    */

    menuBucketClick(event, dotTags) {
        console.log("Bucket / menuBucketClick");

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Only when myself
        if (this.props.isMyself) {
            this.props.storeDotTag(
                {
                    modalOn: true,
                    type: "filter",
                    mode: this.state.bucketMode,
                    id: this.props.userInfo.id,
                    dot: null,
                    tags: dotTags
                }
            );
        }
    }

    menuModeClick(event, bucketMode, position) {
        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // For touch devices
        if (window.touchOn) {

            if (this.state.collectionMenuOn === null) {
                //console.log("Bucket / menuModeClick - first touch");
                this.setState({
                    collectionMenuOn: bucketMode + "-" + position
                });
            }
            else {
                //console.log("Bucket / menuModeClick - second touch");
                if (this.state.bucketMode === bucketMode) {
                    //console.log("Bucket / menuModeClick - close menu");

                    // Update state
                    this.setState({
                        collectionMenuOn: null,
                    });
                }
                else {
                    //console.log("Bucket / menuModeClick - close menu and switch mode");

                    // Update state
                    this.setState({
                        bucketMode: bucketMode,
                        selectedDotTag: null,
                        collectionMenuOn: null,
                        savedPage: 1,
                        contributedPage: 1,
                        everydayPage: 1,
                        renderContent: false
                    });
                }
            }
        }
        // For non-touch devices
        else {
            // False if refetch
            const renderContent = (this.state.selectedDotTag === null && this.state.bucketMode === bucketMode)?
                true : false;

            // Update state
            this.setState({
                bucketMode: bucketMode,
                selectedDotTag: null,
                collectionMenuOn: null,
                savedPage: 1,
                contributedPage: 1,
                everydayPage: 1,
                renderContent: renderContent
            });
        }
    }

    menuModeHoverOn(event, mode) {
        //console.log("Bucket / menuModeHoverOn");

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Only for non-touch devices
        if (!window.touchOn) {
            this.setState({
                collectionMenuOn: mode
            });
        }
    }

    menuModeHoverOff(event) {
        //console.log("Bucket / menuModeHoverOff");

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Only for non-touch devices
        if (!window.touchOn) {
            this.setState({
                collectionMenuOn: null
            });
        }
    }

    menuDisplayClick(event) {
        //console.log("Bucket / menuDisplayClick");

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Display mode change
        if (this.state.displayMode === "bucket") {
            this.setState({ displayMode: "timeline" });
        }
        else {
            this.setState({ displayMode: "bucket" });
        }
    }


    switchToBucketedMode () {
        this.setState({
            bucketMode: "save",
            selectedDotTag: null
        });
    }

    switchToEverydayMode () {
        this.setState({
            bucketMode: "everyday",
            selectedDotTag: null
        });
    }

    switchToContributedMode () {
        this.setState({
            bucketMode: "contribute",
            selectedDotTag: null
        });
    }


    /*
    ============================================================================================
        Fetch dots
    ============================================================================================
    */

    fetchDots(page, mode, resetData, dotTag, callback) {
        // Callback
        const axiosCallback = (response) => {
            //console.log("Bucket / fetchDots - response.data.content = ", response.data.content);

            if (mode === "save") {
                // Make a copy of loaded dots
                const dots = (resetData)?
                    {} : Object.assign({}, this.state.savedDots);

                // Compile newly loaded dots
                const newDots = [];
                for (let j = 0; j < response.data.content.results.length; j++) {
                    response.data.content.results[j].dot["interaction_id"] = response.data.content.results[j].id;
                    response.data.content.results[j].dot["dot_tags"] = response.data.content.results[j].dot_tags;
                    response.data.content.results[j].dot["user_tags"] = response.data.content.results[j].user_tags;
                    response.data.content.results[j].dot["memo"] = response.data.content.results[j].memo;
                    response.data.content.results[j].dot["saved_time"] = response.data.content.results[j].created_time;
                    newDots.push(response.data.content.results[j].dot);
                }
                //console.log("Bucket / fetchDots - newDots = ", newDots);

                // Save newly loaded dots
                dots[page.toString()] = newDots;
                //console.log("Bucket / fetchDots - dots = ", dots);

                // Update state
                this.setState(
                    {
                        savedCount: response.data.content.total_count,
                        savedDots: dots,
                        savedPage: page,
                        savedMaxPage: response.data.content.total_pages,
                        renderContent: true
                    },
                    callback
                );
            }
            else if (mode === "contribute") {
                // Make a copy of loaded dots
                const dots = (resetData)?
                    {} : Object.assign({}, this.state.contributedDots);

                // Compile newly loaded dots
                const newDots = [];
                for (let j = 0; j < response.data.content.results.length; j++) {
                    response.data.content.results[j].dot["interaction_id"] = response.data.content.results[j].id;
                    response.data.content.results[j].dot["dot_tags"] = response.data.content.results[j].dot_tags;
                    response.data.content.results[j].dot["user_tags"] = response.data.content.results[j].user_tags;
                    response.data.content.results[j].dot["memo"] = response.data.content.results[j].memo;
                    response.data.content.results[j].dot["contributed_time"] = response.data.content.results[j].created_time;
                    newDots.push(response.data.content.results[j].dot);
                }
                //console.log("Bucket / fetchDots - newDots = ", newDots);

                // Save newly loaded dots
                dots[page.toString()] = newDots;

                // Update state
                this.setState(
                    {
                        contributedCount: response.data.content.total_count,
                        contributedDots: dots,
                        contributedPage: page,
                        contributedMaxPage: response.data.content.total_pages,
                        renderContent: true
                    },
                    callback
                );
            }
            else if (mode === "everyday") {
                // Make a copy of loaded dots
                const dots = (resetData)? 
                    {} : Object.assign({}, this.state.everydayDots);

                // Compile newly loaded dots
                const newDots = [];
                for (let j = 0; j < response.data.content.results.length; j++) {
                    response.data.content.results[j].dot["interaction_id"] = response.data.content.results[j].id;
                    response.data.content.results[j].dot["dot_tags"] = response.data.content.results[j].dot_tags;
                    response.data.content.results[j].dot["user_tags"] = response.data.content.results[j].user_tags;
                    response.data.content.results[j].dot["memo"] = response.data.content.results[j].memo;
                    response.data.content.results[j].dot["everyday_time"] = response.data.content.results[j].created_time;
                    newDots.push(response.data.content.results[j].dot);
                }
                //console.log("Bucket / fetchDots - newDots = ", newDots);

                // Save newly loaded dots
                dots[page.toString()] = newDots;

                // Update state
                this.setState(
                    {
                        everydayCount: response.data.content.total_count,
                        everydayDots: dots,
                        everydayPage: page,
                        everydayMaxPage: response.data.content.total_pages,
                        renderContent: true
                    },
                    callback
                );
            }
            else {
                console.log("[WARNING] Bucket / fetchDots - wrong mode");
            }
        };

        if (mode === "save") {
            getDotSaves(this.props.userInfo.username, page, dotTag, null)
            .then(axiosCallback)
            .catch(
                (response) => {
                    // console.log("[WARNING] Bucket / fetchDots - ", response);
                }
            );
        }
        else if (mode === "contribute") {
            getContributions(this.props.userInfo.username, "EV", "not", page, dotTag)
            .then(axiosCallback)
            .catch(
                (response) => {
                    // console.log("[WARNING] Bucket / fetchDots - ", response);
                }
            );
        }
        else if (mode === "everyday") {
            getContributions(this.props.userInfo.username, "EV", "is", page, dotTag)
            .then(axiosCallback)
            .catch(
                (response) => {
                    // console.log("[WARNING] Bucket / fetchDots - ", response);
                }
            );
        }
        else {
            console.log("[WARNING] Bucket / fetchDots - wrong mode");
        }
    }


    fetchLocations() {
        //console.log("Bucket / fetchLocations");

        // Callback
        const axiosCallback = (response) => {
            //console.log("Bucket / fetchLocations - response.data.content = ", response.data.content);

            if (this.state.bucketMode === "save") {
                this.setState({
                    savedLocations: response.data.content.locations
                });
            }
            else if (this.state.bucketMode === "contribute") {
                this.setState({
                    contributedLocations: response.data.content.locations
                });
            }
            else if (this.state.bucketMode === "everyday") {
                this.setState({
                    everydayLocations: response.data.content.locations
                });
            }
        };

        getLocations(this.props.userInfo.username, this.state.bucketMode, this.state.selectedDotTag)
        .then(axiosCallback)
        .catch(
            (response) => {
                // console.log("[WARNING] Bucket / fetchLocations - ", response);
            }
        );
    }

    previousGroup() {
        const previousPage = (this.state.bucketMode === "contribute")?
            ((Math.floor((this.state.contributedPage - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1 ) :
            ((Math.floor((this.state.savedPage - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1 );

        this.goToPage(previousPage);
    }

    nextGroup() {
        const nextPage = (this.state.bucketMode === "contribute")?
            ((Math.floor((this.state.contributedPage - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1 ) :
            ((Math.floor((this.state.savedPage - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1 );

        this.goToPage(nextPage);
    }

    goToPage(page) {
        // Scroll to the top after switching page
        const callback = this.scrollToTop;

        if (this.state.bucketMode === "save") {
            if (page.toString() in this.state.savedDots) {
                this.setState(
                    {
                        savedPage: page,
                    },
                    callback
                );
            }
            else {
                this.fetchDots(page, "save", false, this.state.selectedDotTag, callback);
            }
        }
        else if (this.state.bucketMode === "everyday") {
            if (page.toString() in this.state.everydayDots) {
                this.setState(
                    {
                        everydayPage: page,
                    },
                    callback
                );
            }
            else {
                this.fetchDots(page, "everyday", false, this.state.selectedDotTag, callback);
            }
        }
        else if (this.state.bucketMode === "contribute") {
            if (page.toString() in this.state.contributedDots) {
                this.setState(
                    {
                        contributedPage: page,
                    },
                    callback
                );
            }
            else {
                this.fetchDots(page, "contribute", false, this.state.selectedDotTag, callback);
            }
        }
    }

    leftMenuDotTagClick(event, userID) {

    }


    /*
    ============================================================================================
        Globe mode
    ============================================================================================
    */

    toggleGlobeMode() {
        const globeMode = (this.state.globeMode === "all")?
            "page" : "all";

        // Update
        this.setState({
            globeMode: globeMode
        })
    }


    /*
    ============================================================================================
        Scroll
    ============================================================================================
    */

    onScroll(event) {
        //console.log("Bucket / onScroll - this.bucketRef.current.offsetTop = ", this.bucketRef.current.offsetTop);
        //console.log("Bucket / onScroll - this.bucketRef.current.offsetTop + this.menuHeight = ", this.bucketRef.current.offsetTop + this.menuHeight);

        //const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        // Only when the ref has a corresponding element
        if (this.bucketRef.current !== null) {
            // Judge if this is within viewport
            const bucketContentBoundingBox = this.bucketRef.current.getBoundingClientRect();
            //console.log("Bucket / onScroll - this.bucketRef.current.getBoundingClientRect().top = ", this.bucketRef.current.getBoundingClientRect().top);

            if (bucketContentBoundingBox.top < this.bottomMenuOnPosition) {
                if (!this.state.bottomMenuOn && !this.bottomMenuChanging) {
                    this.bottomMenuChanging = true;

                    this.setState(
                        {
                            bottomMenuOn : true
                        },
                        () => { this.bottomMenuChanging = false; }
                    );
                }
            }
            else {
                if (this.state.bottomMenuOn && !this.bottomMenuChanging) {
                    this.bottomMenuChanging = true;

                    this.setState(
                        {
                            bottomMenuOn : false
                        },
                        () => { this.bottomMenuChanging = false; }
                    );
                }
            }
        }
    }

    scrollToTop() {
        // Scroll to the top
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}


/*
============================================================================================
    BucketList
--------------------------------------------------------------------------------------------
    - List to render contributed or saved dots
============================================================================================
*/

class BucketItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverOn: false
        };

        // Bind functions
        this.itemHoverOn = this.itemHoverOn.bind(this);
        this.itemHoverOff = this.itemHoverOff.bind(this);
        this.itemClick = this.itemClick.bind(this);
    }

    render() {
        //console.log("BucketItem / render - this.props.dotInfo = ", this.props.dotInfo);

        // Sort media
        const mediaSorted = sortMedia(this.props.dotInfo);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Get media info
        const mediaInfo = media[0];
        let mediaURL = null;
        let bucketDotClass = null;
        if (mediaInfo.type === "video") {
            if ("t" in mediaInfo.files) {
                mediaURL = getMediaProperty(mediaInfo, "t", 'url', true);
                bucketDotClass = (this.props.colorMode === "day")?
                    "user-info-bucket-item-image image-cover border-day" :
                    "user-info-bucket-item-image image-cover border-night";
            }
            else {
                mediaURL = (this.props.colorMode === "day")?
                    getStaticPath("/images/common/video-black.png", true) :
                    getStaticPath("/images/common/video-white.png", true);

                bucketDotClass = (this.props.colorMode === "day")?
                    "user-info-bucket-item-video image-actual border-day" :
                    "user-info-bucket-item-video image-actual border-night";
            }
        // }
        // else {
        //     mediaURL = getMediaProperty(mediaInfo, "xs", 'url', true);
        }
        else {
            mediaURL = getMediaProperty(mediaInfo, "t", 'url', true);
            bucketDotClass = (this.props.colorMode === "day")?
                "user-info-bucket-item-image image-cover border-day" :
                "user-info-bucket-item-image image-cover border-night";
        }

        /*
        // Get the link URL
        let linkURL = null;
        if (this.props.dotInfo.type === "TR") {
            linkURL = "/trip/" + String(this.props.dotInfo.slug);
        }
        else if (this.props.dotInfo.type === "RT") {
            linkURL = "/route/" + String(this.props.dotInfo.slug);
        }
        else {
            linkURL = "/dot/" + String(this.props.dotInfo.slug);
        }
        */

        // Dot tag button
        const dotTagImage = getStaticPath("/images/common/tag-white.png");
        const dotTagClick = (event, dotID) => {
            //console.log("BucketItem / render / dotTagClick - event = ", event);
            //console.log("BucketItem / render / dotTagClick - dotID = ", dotID);

            // Prevent default and stop event propagation to parent
            event.preventDefault();
            event.stopPropagation();

            // Display dot tag modal
            this.props.storeDotTag(
                {
                    modalOn: true,
                    type: "tag",
                    mode: this.props.bucketMode,
                    id: dotID,
                    dot: this.props.dotInfo,
                    tags: this.props.dotInfo.dot_tags
                }
            );
        };
        const dotTagButton = (this.props.isMyself)? (
            <div className = "user-info-bucket-item-dot-tag image-button-s3"
                style = {{ backgroundImage: dotTagImage }}
                onClick = {(event) => { dotTagClick(event, this.props.dotInfo.id); }}
            >
            </div>
        ) : null;


        // User tag button
        const userTagImage = getStaticPath("/images/common/user-tag-white.png");
        const userTagClick = (event, dotID) => {
            //console.log("BucketItem / render / userTagClick - event = ", event);
            //console.log("BucketItem / render / userTagClick - dotID = ", dotID);

            // Prevent default and stop event propagation to parent
            event.preventDefault();
            event.stopPropagation();

            // Display user tag modal
            this.props.storeUserTag(
                {
                    modalOn: true,
                    mode: this.props.bucketMode,
                    id: this.props.dotInfo.id,
                    dot: this.props.dotInfo
                }
            );
        };
        const userTagButton = (this.props.isMyself)? (
            <div className = "user-info-bucket-item-user-tag image-button-s3"
                style = {{ backgroundImage: userTagImage }}
                onClick = {(event) => { userTagClick(event, this.props.dotInfo.id); }}
            >
            </div>
        ) : (
            (this.props.dotInfo.user_tags.length > 0)? 
                (
                    <div className = "user-info-bucket-item-user-tagged image-button-s3"
                        style = {{ backgroundImage: userTagImage }}
                    >
                    </div>
                ) : null
        );

        const userTagCount = (this.props.dotInfo.user_tags.length > 0)? 
        (
            <div className = "user-info-bucket-item-user-tagged-count font-cabin white">
                {this.props.dotInfo.user_tags.length}
            </div>
        ) : null;

        // Unsave dot button
        const unsaveImage = getStaticPath("/images/user/bucket-remove-white.png");
        const unsaveButton = (this.props.isMyself && this.props.bucketMode === "save")? (
            <div className = "user-info-bucket-item-unsave image-button-s3"
                style = {{ backgroundImage: unsaveImage }}
                onClick = {(event) => {this.props.unsaveClick(event, this.props.dotInfo);}}
            >
            </div>
        ) : null;

        // Media enlarge button
        const enlargeButtonImage = getStaticPath("/images/common/enlarge-white.png");
        const enlargeButton = (
            <div className = "user-info-bucket-item-enlarge image-button-s3"
                style = {{ backgroundImage: enlargeButtonImage }}
                onClick = {
                    (event) => {
                        // Stop propagation
                        event.stopPropagation();

                        // Open gallery modal
                        this.props.storeGallery({
                            modalOn: true,
                            info: this.props.dotInfo
                        });
                    }
                }
            >
            </div>
        );

        // Get name and area
        let nameArea = null;
        if (this.props.dotInfo.name === null && this.props.dotInfo.area !== null) {
            nameArea = (
                <div className = "user-info-bucket-item-name-area w5">
                    {this.props.dotInfo.area}
                </div>
            );
        }
        else if (this.props.dotInfo.name !== null && this.props.dotInfo.area === null) {
            nameArea = (
                <div className = "user-info-bucket-item-name-area w5">
                    {this.props.dotInfo.name}
                </div>
            );
        }
        else {
            nameArea = (
                <div className = "user-info-bucket-item-name-container">
                    <div className = "user-info-bucket-item-name w5">
                        {this.props.dotInfo.name}
                    </div>
                    <div className = "user-info-bucket-item-area lg5">
                        {this.props.dotInfo.area}
                    </div>
                </div>
            );
        }

        // Shadow image
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette-weak.png");

        // Loader image
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif");

        // Return an individual item
        return (
            <div key = {"user-info-bucket-item-" + this.props.index.toString()}
                className = {(this.props.browserWidth <= 2)?
                    "user-info-bucket-item-loader-small image-loader-s4" :
                    "user-info-bucket-item-loader image-loader-s4"}
                style = {{ backgroundImage: loaderImage }}
            >
                <div
                    className = {bucketDotClass}
                    //to = {linkURL}
                    style = {{ backgroundImage: mediaURL }}
                >
                    <div className = "user-info-bucket-item-shadow"
                        style = {{ backgroundImage: shadowImage }}
                    >
                        <div className = {
                                (this.state.hoverOn)?
                                    (
                                        (this.props.colorMode === "day")?
                                            "user-info-bucket-item-shadow-hover-day" :
                                            "user-info-bucket-item-shadow-hover-night"
                                    ) : (
                                        (this.props.colorMode === "day")?
                                            "user-info-bucket-item-shadow-day" :
                                            "user-info-bucket-item-shadow-night"
                                    )
                            }
                            onMouseEnter = {this.itemHoverOn}
                            onMouseLeave = {this.itemHoverOff}
                            onClick = {this.itemClick}
                        >
                            <div className = "user-info-bucket-item-left-buttons">
                                {enlargeButton}
                                {userTagButton}
                                {userTagCount}
                                {dotTagButton}
                            </div>

                            <div className = "user-info-bucket-item-right-buttons">
                                {unsaveButton}
                            </div>
                            <div className = "user-info-bucket-item-title">
                                {nameArea}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    itemHoverOn(event) {
        //console.log("BucketItem / itemHoverOn");

        // Prevent event actinos
        event.stopPropagation();
        event.preventDefault();

        // Update state
        if (!window.touchOn) {
            this.setState({
                hoverOn: true
            });
        }
    }

    itemHoverOff(event) {
        //console.log("BucketItem / itemHoverOff");

        // Prevent event actinos
        event.stopPropagation();
        event.preventDefault();

        // Update state
        this.setState({
            hoverOn: false
        });
    }

    itemClick(event) {
        //console.log("BucketItem / itemClick");

        // Prevent event actinos
        event.stopPropagation();
        event.preventDefault();

        if (window.touchOn && !this.state.hoverOn) {
            //console.log("BucketItem / itemClick - touch");

            // Update state
            this.setState({
                hoverOn: true
            });
        }
        else {
            //console.log("BucketItem / itemClick - click");

            // Update state
            this.props.setState({
                displayMode: "timeline",
                scrollToItem: this.props.index
            });
        }

    }
}


/*
============================================================================================
    Timeline
--------------------------------------------------------------------------------------------
    - List to render timeline style list of dots
============================================================================================
*/

class Timeline extends Component {
    constructor(props) {
        super(props);

        // Header height
        this.headerHeight = 30;

        // Max overview height 
        this.maxOverviewHeight = 120;

        // Minimum / scale / maximum content widths
        this.minContentWidth = 300;
        this.scaleContentWidth = 600;
        this.maxContentWidth = 600;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media area
        this.mediaArea = 360000;

        // Media side margin width
        this.marginWidth = 10;

        // Taggee profile pics
        this.numTaggeeProfilePicsMobile = 3;
        this.numTaggeeProfilePics = 10;

        // Initialize selected media indices
        const selectedMediaIndices = [];
        const expanded = [];
        for (let i = 0; i < this.props.dotsInfo.length; i++) {
            selectedMediaIndices.push(0);
            expanded.push(null);
        }

        // Create references
        this.itemRefs = [];
        this.itemOverviewRefs = [];
        for (let i = 0; i < this.props.dotsInfo.length; i++) {
            this.itemRefs.push(React.createRef());
            this.itemOverviewRefs.push(React.createRef());
        }

        // Intialize state
        this.state = {
            // Selected media
            selectedMediaIndices: selectedMediaIndices,

            // Expanded
            expanded: expanded,

            // Scroll
            checkScroll: false
        };

        // Bind setState and onScroll functions
        this.setState = this.setState.bind(this);

        // Scroll item
        this.scrollToItem = this.scrollToItem.bind(this);

        // Scroll listener
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);

        // Media navigation functions
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);

        // Expand click
        this.expandClick = this.expandClick.bind(this);
        this.resetExpanded = this.resetExpanded.bind(this);
    }

    render() {
        //console.log("Timeline / render - this.props = ", this.props);

        /*
        ============================================================================================
            Effective browser width
        ============================================================================================
        */

        const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.marginWidth;
        //console.log("Timeline / render - this.props.browserWidthPixels = ", this.props.browserWidthPixels);
        //console.log("Timeline / render - this.marginWidth = ", this.marginWidth);
        //console.log("Timeline / render - effectiveBrowserWidth = ", effectiveBrowserWidth);


        /*
        ============================================================================================
            Timeline List
        ============================================================================================
        */

        // This year
        const thisYear = moment().year();

        // For all dots in the list
        const timelineList = this.props.dotsInfo.map((dotInfo, dotIndex) => {
            //console.log("Timeline / render - dotInfo = ", dotInfo);

            // Is author
            const isAuthor = (this.props.userInfo === null)?
                false : (dotInfo.editor.id === this.props.userInfo.id);

            // Dot time
            const dotTime = (this.props.bucketMode === "save")?
                moment(dotInfo.saved_time) :
                (
                    (this.props.bucketMode === "contribute")?
                        moment(dotInfo.contributed_time) :
                        moment(dotInfo.everyday_time)
                );

            // Dot time
            const previousDotTime = (dotIndex === 0)?
                null : (
                    (this.props.bucketMode === "save")?
                        moment(this.props.dotsInfo[dotIndex - 1].saved_time) :
                        (
                            (this.props.bucketMode === "contribute")?
                                moment(this.props.dotsInfo[dotIndex - 1].contributed_time) :
                                moment(this.props.dotsInfo[dotIndex - 1].everyday_time)
                        )
                );

            // Get the link URL
            let linkURL = null;
            if (dotInfo.type === "TR") {
                linkURL = "/trip/" + String(dotInfo.slug);
            }
            else if (dotInfo.type === "RT") {
                linkURL = "/route/" + String(dotInfo.slug);
            }
            else {
                linkURL = "/dot/" + String(dotInfo.slug);
            }


            /*
            ============================================================================================
                Item gallery
            ============================================================================================
            */
            // Sort media
            const mediaSorted = sortMedia(dotInfo);

            // Merge all media categories
            const media = [
                ...mediaSorted.overview,
                ...mediaSorted.todos,
                ...mediaSorted.history,
                ...mediaSorted.stories
            ];
            //console.log("Timeline / render - media = ", media);

            /*
            ============================================================================================
                Item gallery
            ============================================================================================
            */

            const selectedMediaIndex = this.state.selectedMediaIndices[dotIndex];

            let gallery = null;
            let galleryDimensions = null;
            if (media.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = media[selectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (media[selectedMediaIndex].type === "video");
                //console.log("Timeline / render - isVideo = ", isVideo);

                // Gallery dimensions
                galleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo,
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth,
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: this.maxContentWidth
                });

                // Gallery props
                const galleryProps = Object.assign(
                    galleryDimensions,
                    {
                        // General
                        id: "-" + dotIndex,
                        idPrefix: "user-info-timeline-item",
                        classPrefix: "user-info-timeline-item",
                        selectedMediaIndex: selectedMediaIndex,
                        media: media,
                        size: "s",
                        startPlaying: false,
                        checkScroll: (this.props.displayMode === "timeline")?
                            this.state.checkScroll : null,
                        nextMediaClick: this.nextMediaClick,
                        prevMediaClick: this.prevMediaClick,
                        navDotClick: this.navDotClick,
                        index: dotIndex,
                        square: false,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        dotTagOn: this.props.isMyself,
                        memoOn: (this.props.isMyself && !isAuthor && this.props.bucketMode !== "everyday"),
                        unsaveOn: (this.props.isMyself && this.props.bucketMode === "save"),
                        enlargeOn: true,
                        dotInfo: dotInfo,
                        tagMode: this.props.bucketMode
                    }
                );

                gallery = (
                    <div className = "user-info-timeline-item-gallery">
                        <Gallery {...galleryProps} />
                    </div>
                );
            }

            // Time
            let timeOn = null;
            const yearOn = (dotTime.year() !== thisYear);

            if (previousDotTime === null) {
                timeOn = true;
            }
            else {
                timeOn = !(dotTime.month() === previousDotTime.month() && dotTime.date() === previousDotTime.date());
            }

            // Line image
            const lineImage = (this.props.colorMode === "day")?
                getStaticPath("/images/line/horizontal/dotted-middle-lightest-gray.png"):
                getStaticPath("/images/line/horizontal/dotted-middle-darkest-gray.png");

            const time = (timeOn)?
            (
                <div className = "user-info-timeline-item-time">
                    <div className = "user-info-timeline-item-time-line"
                        style = {{ backgroundImage: lineImage }}
                    >
                        <div className = {
                            (this.props.colorMode === "day")?
                                "user-info-timeline-item-time-value-day font-cabin light-blue" :
                                "user-info-timeline-item-time-value-night font-cabin blue"
                            }
                        >
                            {(yearOn)? dotTime.format("MMMM Do YYYY") : dotTime.format("MMMM Do")}
                        </div>
                    </div>
                </div>
            ) : (
                <div className = "user-info-timeline-item-divider">
                    <div className = "user-info-timeline-item-divider-line"
                        style = {{ backgroundImage: lineImage }}
                    >
                    </div>
                </div>
            )
            //null;


            // Tagged users
            // Set parameters for taggedList using UserProfilePicList
            const taggeesInfo = [];
            for (let i = 0; i < dotInfo.user_tags.length; i++) {
                taggeesInfo.push(dotInfo.user_tags[i].taggee);
            }
            //console.log("Timeline / render - taggeesInfo = ", taggeesInfo);

            const taggeeListProps = {
                colorMode: this.props.colorMode,
                numProfilePics : (this.props.browserWidth <= 2)?
                    this.numTaggeeProfilePicsMobile : this.numTaggeeProfilePics,
                usersInfo: taggeesInfo,
                userCount: taggeesInfo.length,
                userTypeLabel: "Tagged Friend",
                userTypeLabelOn: true,
                classNamePrefix: "user-info-timeline-item",
                classNameUserType: "taggee"
            };

            const taggeeList = (
                <div className = "user-info-timeline-item-taggees-container">
                    <UserProfilePicList {...taggeeListProps} />
                </div>
            )

            // Memo
            const memoImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/memo-light-blue.png") :
                getStaticPath("/images/common/memo-blue.png");

            const memoTextClassName = (this.props.browserWidth <= 2)?
                "user-info-timeline-item-memo-text-small" :
                (
                    (this.props.browserWidth <= 6)?
                        "user-info-timeline-item-memo-text-medium" :
                        "user-info-timeline-item-memo-text"
                );

            const memo = (dotInfo.memo === null || dotInfo.memo === undefined)? null : (
                <div className = "user-info-timeline-item-memo">
                    <div className = "user-info-timeline-item-memo-image image-contain"
                        style = {{ backgroundImage: memoImage }}
                    >
                    </div>
                    <div className = {
                        (this.props.colorMode === "day")?
                            "k4 " + memoTextClassName :
                            "w4 " + memoTextClassName
                        }
                    >
                        {dotInfo.memo}
                    </div>
                </div>
            );

            // Content expand button
            const expandButtonImage = (this.state.expanded[dotIndex] === false)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                ) : (
                   (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                );

            // Expand button
            const expandButton = (this.state.expanded[dotIndex] === null)? null : (
                <div className = {(this.state.expanded[dotIndex] === false)?
                        "user-info-timeline-item-overview-more" :
                        "user-info-timeline-item-overview-less"
                    }
                    style = {{
                        display: (this.state.expanded[dotIndex] === null)? "none" : "block",
                        backgroundImage: expandButtonImage
                    }}
                    onClick = {this.expandClick.bind(this, dotIndex)}
                >
                </div>
            );

            // Stylize curation
            const curationOverview = regularCuration(
                dotInfo.overview,
                "user-info-timeline-item-overview",
                "user-info-timeline-item-overview",
                this.props.colorMode
            );

            // Return an individual item
            return (
                <div key = {"user-info-timeline-item-" + dotIndex.toString()}
                    ref = {this.itemRefs[dotIndex]}
                    className = "user-info-timeline-item"
                >
                    {time}

                    {gallery}

                    <div className = {
                        (this.props.browserWidth <= 4)?
                            "user-info-timeline-item-header-small" :
                            "user-info-timeline-item-header"
                        }
                    >
                        <Link
                            to = {linkURL}
                        >
                            <div className = {
                                (this.props.colorMode === "day")?
                                    "user-info-timeline-item-title k3" :
                                    "user-info-timeline-item-title w3"
                                }
                            >
                                {dotInfo.title}
                            </div>
                            <div className = {
                                (this.props.colorMode === "day")?
                                    "user-info-timeline-item-name lb4" :
                                    "user-info-timeline-item-name b4"
                                }
                            >
                                {dotInfo.name}
                            </div>
                        </Link>
                    </div>

                    <div className = {
                        (this.props.browserWidth <= 4)?
                            "user-info-timeline-item-content-small" :
                            "user-info-timeline-item-content"
                        }
                    >
                        <div className = "user-info-timeline-item-overview-container">
                            <div ref = {this.itemOverviewRefs[dotIndex]}
                                className = {(this.props.colorMode === "day")?
                                    "user-info-timeline-item-overview text light-gray" :
                                    "user-info-timeline-item-overview text gray"
                                }
                                style = {this.state.expanded[dotIndex] === false? {
                                        maxHeight: this.maxOverviewHeight,
                                        overflow: "hidden"
                                    } : {}
                                }
                            >
                                {curationOverview}
                            </div>
                            {expandButton}
                        </div>

                        {memo}

                        {taggeeList}
                    </div>
                </div>
            );
        });

        return (
            <div className = "user-info-timeline"
                style = {{ display: (this.props.displayMode === "bucket")? "none" : "block" }}
            >
                {timelineList}
            </div>
        );
    }

    resetExpanded() {
        const callback = () => {
            // Calculate new expanded list
            const expanded = [];
            for (let i = 0; i < this.props.dotsInfo.length; i++) {
                if (this.itemOverviewRefs[i].current.clientHeight > this.maxOverviewHeight) {
                    expanded.push(false);
                }
                else {
                    expanded.push(null);
                }
            }

            // Update state
            this.setState({
                expanded: expanded
            });
        };

        // Calculate new expanded list
        const expanded = [];
        for (let i = 0; i < this.props.dotsInfo.length; i++) {
            expanded.push(null);
        }

        // Update state
        this.setState(
            {
                expanded: expanded
            },
            callback
        );

        //console.log("Timeline / componentDidMount - expanded = ", expanded);
    }

    expandClick(dotIndex) {
        // Reverse expanded
        const expanded = this.state.expanded.slice();
        expanded[dotIndex] = !expanded[dotIndex];

        // Update state
        this.setState({
            expanded: expanded
        });
    }

    componentDidMount() {
        //console.log("Timeline / componentDidMount");
        window.addEventListener("scroll", this.scrollListener);

        // Check if in viewport
        this.onScroll();

        // Reset curation expanded
        this.resetExpanded();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollListener);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("Timeline / componentDidUpdate - prevProps = ", prevProps);
        //console.log("Timeline / componentDidUpdate - this.props = ", this.props);

        // Scroll to item
        if (this.props.scrollToItem !== prevProps.scrollToItem) {
            //console.log("Timeline / componentDidUpdate - scroll to different item");
            this.scrollToItem(this.props.scrollToItem);
        }

        // Check if in viewport when display mode has changed
        if (prevProps.displayMode === "bucket" && this.props.displayMode === "timeline") {
            //console.log("Timeline / componentDidUpdate - checking scroll");
            this.onScroll();
            this.resetExpanded();
        }
        else if (prevProps.displayMode === "timeline" && this.props.displayMode === "bucket") {
        }

        if (this.props.browserWidth !== prevProps.browserWidth) {
            this.resetExpanded();
        }
    }


    /*
    ============================================================================================
        Scroll to item
    ============================================================================================
    */

    scrollToItem(itemIndex) {
        //console.log("Timeline / scrollToItem - this.itemRefs[itemIndex].current.offsetTop = ", this.itemRefs[itemIndex].current.offsetTop);
        window.scrollTo(
            0,
            this.itemRefs[itemIndex].current.offsetTop
            + this.headerHeight
            - ((this.props.browserWidth <= window.browserWidthSmall)?
                this.props.topMarginSmall : this.props.topMargin)
        );
    }


    /*
    ============================================================================================
        Gallery Navigation Functions
    ============================================================================================
    */

    nextMediaClick(event, itemIndex) {
        //console.log("Timeline / nextMediaClick - itemIndex = ", itemIndex);

        // Stop propagation
        event.stopPropagation();

        // Update state
        const selectedMediaIndices = this.state.selectedMediaIndices.slice();
        selectedMediaIndices[itemIndex] = selectedMediaIndices[itemIndex] + 1;

        this.setState(
            {
                selectedMediaIndices: selectedMediaIndices
            }
        );
    }

    prevMediaClick(event, itemIndex) {
        //console.log("Timeline / prevMediaClick - itemIndex = ", itemIndex);

        // Stop propagation
        event.stopPropagation();

        // Update state
        const selectedMediaIndices = this.state.selectedMediaIndices.slice();
        selectedMediaIndices[itemIndex] = selectedMediaIndices[itemIndex] - 1;

        this.setState(
            {
                selectedMediaIndices: selectedMediaIndices
            }
        );
    }

    navDotClick(event, mediaIndex, itemIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        const selectedMediaIndices = this.state.selectedMediaIndices.slice();
        selectedMediaIndices[itemIndex] = mediaIndex;

        this.setState(
            {
                selectedMediaIndices: selectedMediaIndices
            }
        );
    }

    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }
}


/*
============================================================================================
    Collection Menu
============================================================================================
*/

class CollectionMenu extends Component {
    constructor(props) {
        super(props);

        // Initialize state
        this.state = {};

        // Bind functions
        this.menuItemClick = this.menuItemClick.bind(this);
    }

    render() {
        const dotTags = this.props.dotTags.map(
            (dotTag, index) => {
                //console.log("CollectionMenu / render - dotTag = ", dotTag);
                //console.log("CollectionMenu / render - this.props.selectedDotTag = ", this.props.selectedDotTag);

                return (
                    <div key = {"user-info-collection-menu-tags-item-" + index + 1}
                        className = "user-info-collection-menu-tags-row"
                    >
                        <div
                            id = {"user-info-collection-menu-tags-item-" + index + 1}
                            className = {(dotTag.id === this.props.selectedDotTag)?
                                (
                                    (this.props.colorMode === "day")?
                                        "user-info-collection-menu-tags-selected-item k5" :
                                        "user-info-collection-menu-tags-selected-item w5"
                                ) : (
                                    (this.props.colorMode === "day")?
                                        "user-info-collection-menu-tags-item k5" :
                                        "user-info-collection-menu-tags-item w5"
                                )
                            }
                            onClick = {(event) => { this.menuItemClick(event, dotTag); }}
                        >
                            {dotTag.tag_name[0].toUpperCase() + dotTag.tag_name.slice(1)}
                        </div>
                    </div>
                );
            }
        );

        return (
            <div className = {(this.props.browserWidth <= 10)?
                "user-info-collection-" + this.props.position + "-menu-container-small" :
                "user-info-collection-" + this.props.position + "-menu-container"}
            >
                <div className = {
                        (this.props.colorMode === "day")?
                            "user-info-collection-" + this.props.position + "-menu menu-day" :
                            "user-info-collection-" + this.props.position + "-menu menu-night"
                    }
                >
                    {dotTags}
                </div>
            </div>
        );
    }

    componentDidMount() {}


    /*
    ============================================================================================
        Click action
    ============================================================================================
    */

    menuItemClick(event, dotTag) {
        //console.log("CollectionMenu / menuItemClick");

        // Stop propagation
        event.stopPropagation();
        event.preventDefault();

        // New dot tag
        const selectedDotTag = (this.props.bucketMode === this.props.mode && this.props.selectedDotTag === dotTag.id)?
            null : dotTag.id;

        // Callback after fetch
        this.props.setState(
            {
                bucketMode: this.props.mode,
                selectedDotTag: selectedDotTag,
                collectionMenuOn: null,
                savedPage: 1,
                contributedPage: 1,
                everydayPage: 1,
                renderContent: false
            }
        );
    }
}


/*
============================================================================================
    TimeBar
============================================================================================
*/

class TimeBar extends Component {
    constructor(props) {
        super(props);

        this.groupCount = 0;
        this.numberGroups = (props.browserWidth <= 5)? 5 :
            (props.browserWidth <= 7)? 8 : 10;

        this.state = {
            startTime: props.startTime,
            endTime: props.endTime,
            containerPaddingTop: 0,
            containerPaddingBottom: 0
        };

        this.setState = this.setState.bind(this);
        this.updateContainerSize = this.updateContainerSize.bind(this);
    }

    render() {
        // Group dots
        const timeBarLength = this.state.startTime - this.state.endTime;
        const timeBarPadding = timeBarLength * 0.1;
        const groupMinLength = (timeBarLength) / (this.numberGroups + 1);
        //console.log("TimeBar / render - this.state.startTime = ", this.state.startTime);
        //console.log("TimeBar / render - this.state.endTime = ", this.state.endTime);
        //console.log("TimeBar / render - timeBarLength = ", timeBarLength);
        //console.log("TimeBar / render - timeBarPadding = ", timeBarPadding);
        //console.log("TimeBar / render - groupMinLength = ", groupMinLength);

        // Get title
        const title = (this.props.mode === "save")?
            "Bucketed" :
            (
                (this.props.mode === "contribute")?
                    "Authored" : "Everyday"
            );

        // Sort into groups
        const groups = [];
        let groupCount = 0;
        for (let i = 0; i < this.props.dotsInfo.length; i++) {
            // Dot name
            const dotName = (this.props.dotsInfo[i].name !== null)?
                this.props.dotsInfo[i].name : this.props.dotsInfo[i].area;

            // Dot time
            const dotTime = (this.props.mode === "save")?
                moment(this.props.dotsInfo[i].saved_time) :
                (
                    (this.props.mode === "contribute")?
                        moment(this.props.dotsInfo[i].contributed_time) :
                        moment(this.props.dotsInfo[i].everyday_time)
                );
            //console.log("TimeBar / render - dotTime = ", dotTime);


            if (i === 0) {
                const newGroup = {
                    names: [ dotName ],
                    time: null,
                    firstTime: dotTime,
                    lastTime: dotTime,
                    count: 1,
                    displayTime: false
                };

                groups.push(
                    newGroup
                );

                groupCount += 1;
            }
            else {
                // If the new dot belongs to the previous group
                if (groups[groupCount - 1].lastTime - dotTime < groupMinLength) {
                    // Add name
                    const newNames = groups[groupCount - 1].names.slice();
                    newNames.push(dotName);

                    // Update previous group
                    groups[groupCount - 1].names = newNames;
                    groups[groupCount - 1].lastTime = dotTime;
                    groups[groupCount - 1].count += 1;
                }
                else {
                    const newGroup = {
                        names: [ dotName ],
                        time: null,
                        firstTime: dotTime,
                        lastTime: dotTime,
                        count: 1,
                        displayTime: false
                    };

                    groups.push(
                        newGroup
                    );

                    groupCount += 1;
                }
            }
        }
        this.groupCount = groupCount;
        //console.log("TimeBar / render - groups = ", groups);

        // Get the mid point times
        for (let i = 0; i < groups.length; i++) {
            groups[i].time = moment.unix((groups[i].firstTime.unix() + groups[i].lastTime.unix()) / 2);
        }

        // Check the dates of the groups
        let minGroupDistance = timeBarLength;
        for (let i = 0; i < groups.length; i++) {
            for (let j = i + 1; j < groups.length; j++) {
                if ((groups[i].time.date() === groups[j].time.date()) &&
                    (groups[i].time.month() === groups[j].time.month())) {
                    //console.log("TimeBar / render - groups[i].time.date() = ", groups[i].time.date());
                    //console.log("TimeBar / render - groups[i].time.month() = ", groups[i].time.month());
                    //console.log("group.firstTime = ", group.firstTime.format("MMM Do h:mm a"));
                    //console.log("group.lastTime = ", group.lastTime.format("MMM Do h:mm a"));

                    groups[i].displayTime = true;
                    groups[j].displayTime = true;

                    // Update the min group time distance
                    if (minGroupDistance > Math.abs(groups[i].time - groups[j].time)) {
                        minGroupDistance = Math.abs(groups[i].time - groups[j].time);
                    }
                }
            }
        }

        // Get the dots
        const timeBarDots = groups.map((group, index) => {
                // Bucketed time
                const time = moment.unix((group.firstTime.unix() + group.lastTime.unix()) / 2);
                //console.log("time = ", time.format("MMM Do h:mm a"));
                //console.log("minGroupDistance = ", minGroupDistance);

                let timeFormatted = null;
                if (Math.floor(minGroupDistance / 1000 / 60 / 60) >= 1) {
                    timeFormatted = (group.displayTime)?
                        roundMoment(time, moment.duration(60, "minutes"), "round").format("MMM Do h a") :
                        time.format("MMM Do");
                }
                else if (Math.round(minGroupDistance / 1000 / 60 / 30) >= 1) {
                    timeFormatted = (group.displayTime)?
                        roundMoment(time, moment.duration(30, "minutes"), "round").format("MMM Do h:mm a") :
                        time.format("MMM Do");
                }
                else if (Math.round(minGroupDistance / 1000 / 60 / 15) >= 1) {
                    timeFormatted = (group.displayTime)?
                        roundMoment(time, moment.duration(15, "minutes"), "round").format("MMM Do h:mm a") :
                        time.format("MMM Do");
                }
                else if (Math.round(minGroupDistance / 1000 / 60 / 10) >= 1) {
                    timeFormatted = (group.displayTime)?
                        roundMoment(time, moment.duration(10, "minutes"), "round").format("MMM Do h:mm a") :
                        time.format("MMM Do");
                }
                else if (Math.round(minGroupDistance / 1000 / 60 / 5) >= 1) {
                    timeFormatted = (group.displayTime)?
                        roundMoment(time, moment.duration(5, "minutes"), "round").format("MMM Do h:mm a") :
                        time.format("MMM Do");
                }
                else {
                    timeFormatted = (group.displayTime)?
                        time.format("MMM Do h:mm a") :
                        time.format("MMM Do");
                }
                //console.log("time = ", time.format("MMM Do h:mm a"));
                //console.log("time.add(30, 'minutes').startOf('hour') = ", time.add(30, "minutes").startOf("hour").format("MMM Do h:mm a"));

                // Placement
                const placement = (index % 2 === 0)? "bottom" : "top";

                // Position
                const position = (groups.length === 1)? "50%" :
                    ("" + (((this.props.startTime - time) + timeBarPadding) * 100) /
                    (timeBarLength + 2 * timeBarPadding) + "%");

                // Create a dot and a label
                if (placement === "top") {
                    return (
                        <div key = {"user-time-bar-dot-" + (index + 1)}
                            id = {"user-time-bar-dot-" + (index + 1)}
                            className = "user-time-bar-dot-top"
                            style = {{ left: position }}
                        >
                            <div className = {(this.props.colorMode === "day")?
                                "user-time-bar-dot-name k5" :
                                "user-time-bar-dot-name w5"}
                            >
                                {
                                    (group.count > 1)?
                                        (group.names[0] + " and " + (group.count - 1) + " more") :
                                        group.names[0]
                                }
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                "user-time-bar-dot-time b5" :
                                "user-time-bar-dot-time b5"}
                            >
                                {timeFormatted}
                            </div>
                            <div className = "user-time-bar-dot-image image-contain"
                                style = {{
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/common/user-time-bar-dot-light.png") :
                                        getStaticPath("/images/common/user-time-bar-dot-dark.png")
                                }}
                            >
                            </div>
                        </div>
                    );
                }
                else {
                    return (
                        <div key = {"user-time-bar-dot-" + (index + 1)}
                            id = {"user-time-bar-dot-" + (index + 1)}
                            className = "user-time-bar-dot-bottom"
                            style = {{ left: position }}
                        >
                            <div className = "user-time-bar-dot-image image-contain"
                                style = {{
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/common/user-time-bar-dot-light.png") :
                                        getStaticPath("/images/common/user-time-bar-dot-dark.png")
                                }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                "user-time-bar-dot-time b5" :
                                "user-time-bar-dot-time b5"}
                            >
                                {timeFormatted}
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                "user-time-bar-dot-name k5" :
                                "user-time-bar-dot-name w5"}
                            >
                                {
                                    (group.count > 1)?
                                        (group.names[0] + " and " + (group.count - 1) + " more") :
                                        group.names[0]
                                }
                            </div>
                        </div>
                    );
                }
            }
        );

        // Get the title
        const timeBarTitle = (false)? (
            <div id = "user-time-bar-title-container">
                <div id = "user-time-bar-title-image"
                    className = "image-contain"
                    style = {{
                        backgroundImage: (this.props.colorMode === "day")?
                            getStaticPath("/images/common/history-black.png") :
                            getStaticPath("/images/common/history-white.png")
                    }}
                >
                </div>
                <div id = "user-time-bar-title"
                    className = {(this.props.colorMode === "day")?
                        "k4" : "w4"}
                >
                    {title}
                </div>
            </div>
        ) : null;


        // Get the time bar icon
        const timeBarImage = (false)? (
            <div id = "user-time-bar-image"
                className = "image-contain"
                style = {{
                    backgroundImage: (this.props.colorMode === "day")?
                        getStaticPath("/images/common/history-black.png") :
                        getStaticPath("/images/common/history-white.png")
                }}
            >
            </div>
        ) : null;

        return(
            <div id = {(this.props.browserWidth <= 7)?
                "user-time-bar-container-small" : "user-time-bar-container"}
                style = {{
                    paddingTop: this.state.containerPaddingTop,
                    paddingBottom: this.state.containerPaddingBottom
                }}
            >
                <div id = "user-time-bar-line"
                    style = {{
                        backgroundImage: (this.props.colorMode === "day")?
                            getStaticPath("/images/line/horizontal/solid-middle-light.png") :
                            getStaticPath("/images/line/horizontal/solid-middle-dark.png")
                    }}
                >
                    {timeBarImage}
                    {timeBarDots}
                    {timeBarTitle}
                </div>
            </div>
        );
    }

    static getDerivedStateFromProps() {
        return {

        };
    }

    componentDidMount() {
        //console.log("TimeBar / componentDidMount");

        // Update the time bar container size
        this.updateContainerSize();
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("TimeBar / componentDidUpdate");
    }

    updateContainerSize() {
        // Grab the line node and get four corners
        const lineNode = document.getElementById("user-time-bar-line");
        const lineRectangle = lineNode.getBoundingClientRect();
        //console.log("TimeBar / updateContainerSize - lineRectangle = ", lineRectangle);

        // Determine the top and bottom of the container
        let top = lineRectangle.top;
        let bottom = lineRectangle.bottom;

        // For all groups
        for (let i = 0; i < this.groupCount; i++) {
            // Get the dot node and four corners
            const dotNode = document.getElementById("user-time-bar-dot-" + (i + 1));
            const dotRectangle = dotNode.getBoundingClientRect();
            //console.log("TimeBar / updateContainerSize - dotRectangle = ", dotRectangle);

            // Update the container top and bottom
            if (dotRectangle.top < top) {
                top = dotRectangle.top;
            }
            if (dotRectangle.bottom > bottom) {
                bottom = dotRectangle.bottom;
            }
        }
        //console.log("TimeBar / updateContainerSize - top = ", top);
        //console.log("TimeBar / updateContainerSize - bottom = ", bottom);

        // Update container padding
        this.setState({
            containerPaddingTop: lineRectangle.top - top,
            containerPaddingBottom: bottom - lineRectangle.bottom
        });
    }
}


function unsaveClick(event, dotInfo) {
    //console.log("Bucket / unsaveClick - event = ", event);

    // Prevent default and stop event propagation to parent
    event.preventDefault();
    event.stopPropagation();

    // Update saveCount and savedByMe
    const axiosCallback = (response) => {
        //console.log("Bucket / unsaveClick - this.props.userInfo = ", this.props.userInfo);
        //console.log("Bucket / unsaveClick - response.data.content = ", response.data.content);

        // Construct a shallow copy of the userInfo
        const userInfo = Object.assign({}, this.props.userInfo);

        // Update user info
        userInfo.saved_dots_recent = response.data.content.saved_dots_recent;
        userInfo.saved_dot_count = response.data.content.saved_dot_count;

        // Dispatch to Redux store
        this.props.storeUser(userInfo);
    };

    // Request data
    let dataJSON = {
        dot_id: dotInfo.id,
        user_id: this.props.userInfo.id,
        mode: "destroy"
    };
    //console.log("Bucket / unsaveClick - dataJSON = ", dataJSON);

    // send save or unsave request
    postDotSave(dotInfo.slug, dataJSON)
    .then(axiosCallback)
    .catch((response) => {console.log("[WARNING] Bucket / unsaveClick - error = ", response);})
}


// Import necessary global state from the store as props
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        dotTag: state.tag.dotTag,
        newDotTag: state.tag.newDotTag,
        userTag: state.tag.userTag,
        newUserTag: state.tag.newUserTag,
        newMemo: state.memo.newMemo
    };
}

// Dispatch to Redux store
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeDotTag,
            storeNewDotTag,
            storeUserTag,
            storeNewUserTag,
            storeNewMemo,
            storeGallery
        },
        dispatch
    );
}


// Connect
const Bucket = connect(mapStateToProps, mapDispatchToProps)(UnconnectedBucket);

// Export component
export { Bucket, unsaveClick };
