// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Axios
import { 
    getDot,
} from "requests";

// Redux 
import { 
    storeUser,
} from "actions"; 

import DotEditInfo from "./DotEditInfo";

// CSS
import "./DotEdit.scss";


class DotEdit extends Component {
    constructor (props) {
        super(props);
        // Initialize states
        this.state = {
            // Information ready indicators
            dotInfo: null,

            // Editor information window
            editorInfoOn: false,
            editorInfoOpacity: 0.0
        };
        
        // Image settings
        this.imageArea = 300000;
        this.thumbnailArea = 20000;
    }

    /**
     *  Retrieve dot info from database 
     */
    loadDotInfo(dotSlug) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Dot / getDotInfo - response = ", response);

            // In case the editor info does not match
            if (this.props.userInfo.id !== response.data.content.editor.id) {
                this.props.history.push(`/dot/${this.props.dotSlug}`)
            }

            // Check if the logged in user follows the editor
            const userFollowingEditor = localStorage.token? response.data.content.editor.followed_by_me : null;
            //console.log("Dot / getDotInfo - userFollowingEditor = ", userFollowingEditor);

            // Update state
            this.setState({
                dotInfo: response.data.content,
                userFollowingEditor: userFollowingEditor,
                editorFollowersCount: response.data.content.editor.follower_count,
                area: response.data.content.area
            });
        };

        getDot(dotSlug)
        .then(axiosCallback)
        .catch((response) => {console.log("Dot / getDotInfo - Axios error ", response);});
    }

    componentDidMount() {
        this.loadDotInfo(this.props.dotSlug);
    }

    render () {
        if (this.state.dotInfo !== null) {
            return (
                <DotEditInfo
                    key = {"dot-edit-info-" + String(this.props.dotSlug)}
                    history = {this.props.history}
                    {...this.state}
                />
            );
        } else return null; 
    }
}

function mapStateToProps(state) {
    return {
        google: state.map.google,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeUser }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(DotEdit);