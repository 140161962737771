/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Profile.js
--------------------------------------------------------------------------------------------
    Content
    - Profile
============================================================================================
*/


// React / ReactDOM / React-router / Redux
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import { UserProfilePicList } from "js/Common";

// Functions
import {
    url,
    getStaticPath,
    getMediaProperty,
    formatNumbers
} from "js/Functions";

// Constants
import {
    EXPERIENCE_LEVELS,
    CREATOR_LEVELS
} from "./LevelDictionary";

// Axios
import { postPosting } from "requests/axios_users";

// Redux
import {
    storeUser,
    storePosting,
    storeFollow,
    storeEditUserInfoOn
} from "actions";

// CSS
import "./Profile.scss";


/*
===============================================================
    Profile
===============================================================
*/

class Profile extends Component {
    constructor (props) {
        super(props);

        // Post input
        this.postingInputRef = React.createRef();
        this.postingInputID = "user-profile-posting-input";
        this.postingInputFocused = false;
        this.postingInputPlaceholder = "Create a Post.";

        // Follow
        this.numFollowerProfilePics = 4;
        this.numFollowerProfilePicsMobile = 3;
        this.numFollowingProfilePics = 4;
        this.numFollowingProfilePicsMobile = 3;

        // Initialize state
        this.state = {
            editOn: false,
            editHoverOn: false,
            editButtonHoverOn: false,
            historyButtonHoverOn: false,
            posting: this.props.userInfo.posting,
            postingInputBorderColor: (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkGray
        };

        // Bind callbacks
        this.setState = this.setState.bind(this);
        this.editHoverOn = this.editHoverOn.bind(this);
        this.editHoverOff = this.editHoverOff.bind(this);
        this.editButtonHoverOn = this.editButtonHoverOn.bind(this);
        this.editButtonHoverOff = this.editButtonHoverOff.bind(this);
        this.historyButtonHoverOn = this.historyButtonHoverOn.bind(this);
        this.historyButtonHoverOff = this.historyButtonHoverOff.bind(this);
        this.postingHistoryOn = this.postingHistoryOn.bind(this);
        this.postingInputOnFocus = this.postingInputOnFocus.bind(this);
        this.postingInputOnBlur = this.postingInputOnBlur.bind(this);
        this.postingInputOnChange = this.postingInputOnChange.bind(this);
        this.postingInputEditOn = this.postingInputEditOn.bind(this);
        this.postingInputEditOff = this.postingInputEditOff.bind(this);
        this.postingInputResize = this.postingInputResize.bind(this);
        this.createPosting = this.createPosting.bind(this);
        this.openEditUserInfo = this.openEditUserInfo.bind(this);
    }

    render () {
        //console.log("Profile / render - this.props = ", this.props);
        //console.log("Profile / render - this.state = ", this.state);
        //console.log("Profile / render - this.props.userInfo = ", this.props.userInfo);
        //console.log("Profile / render - this.props.userInfo.posting = ", this.props.userInfo.posting);

        // Set profile pic
        const profilePic  = (this.props.userInfo.profile_pic)? 
            (
                (this.props.userInfo.profile_pic.external_url === null)?
                    getMediaProperty(this.props.userInfo.profile_pic, "xs", "url", true):
                    url(this.props.userInfo.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );


        /*
        ===============================================================
            Posting Buttons
        ===============================================================
        */

        // Post button images
        const postingEditOnImage = (this.props.colorMode === "day")?
            getStaticPath("/images/user/posting-edit-bare-light-blue.png") :
            getStaticPath("/images/user/posting-edit-bare-blue.png");

        const postingEditOffImage = (this.props.colorMode === "day")?
            getStaticPath("/images/user/posting-edit-bare-lightest-gray.png") :
            getStaticPath("/images/user/posting-edit-bare-darkest-gray.png");

        const confirmOnImage = (this.props.colorMode === "day")?
            getStaticPath("/images/user/posting-confirm-bare-light-blue.png") :
            getStaticPath("/images/user/posting-confirm-bare-blue.png");

        const confirmOffImage = (this.props.colorMode === "day")?
            getStaticPath("/images/user/posting-confirm-bare-lightest-gray.png") :
            getStaticPath("/images/user/posting-confirm-bare-darkest-gray.png");

        // Choose the right image for the button
        let postingEditButtonImage = null;
        if (this.state.editOn) {
            postingEditButtonImage = (this.state.editButtonHoverOn)?
                confirmOnImage : confirmOffImage;
        }
        else {
            postingEditButtonImage = (this.state.editHoverOn)?
                postingEditOnImage : postingEditOffImage;
        }

        const postingHistoryButtonImage = (this.props.colorMode === "day")?
            (
                (this.state.historyButtonHoverOn)?
                    getStaticPath("/images/common/history-light-blue.png") :
                    getStaticPath("/images/common/history-lightest-gray.png")
            ) : (
                (this.state.historyButtonHoverOn)?
                    getStaticPath("/images/common/history-blue.png") :
                    getStaticPath("/images/common/history-darkest-gray.png")
            );

        const postingHistoryButton = (
            <div id = {(this.props.browserWidth <= 6)?
                    "user-profile-posting-history-button-small" :
                    "user-profile-posting-history-button"}
                className = "image-button-s2"
                style = {{ backgroundImage : postingHistoryButtonImage }}
                onMouseEnter = {this.historyButtonHoverOn}
                onMouseLeave = {this.historyButtonHoverOff}
                onClick = {this.postingHistoryOn}
            >
            </div>
        );

        const postingEditButton = (this.props.isMyself)? (
            <div id = "user-profile-posting-edit-button"
                className = "image-button-s6"
                style = {{ backgroundImage : postingEditButtonImage }}
                onMouseEnter = {this.editHoverOn}
                onMouseLeave = {this.editHoverOff}
                onClick = {this.postingInputEditOn}
            >
            </div>
        ) : null;

        const postingDisplay = (this.state.editOn)? null : (
            <div className = "user-profile-posting"
                style = {{ display: (this.state.editOn)? "none" : "block" }}
                onMouseEnter = {this.props.isMyself? this.editHoverOn : null}
                onMouseLeave = {this.props.isMyself? this.editHoverOff : null}
            >
                <div id =  "user-profile-posting-text"
                    onClick = {this.props.isMyself? this.postingInputEditOn : null}
                    className = {(this.props.colorMode === "day")?
                        "input-s3 input-day" : "input-s3 input-night"}
                    style = {{ borderColor: (this.state.editOn || this.state.editHoverOn)?
                        (
                            (this.props.colorMode === "day")?
                                window.colorLightBlue : window.colorBlue
                        ) : (
                            (this.props.colorMode === "day")?
                                window.colorLightGray : window.colorDarkGray
                        )
                    }}
                >
                    {
                        (this.state.posting === null)?
                            "" : this.state.posting.content
                    }
                </div>
                {postingEditButton}
            </div>
        );

        const postingConfirmButton = (this.props.isMyself)? (
            <div id = "user-profile-posting-confirm-button"
                className = "image-button-s6"
                style = {{ backgroundImage : postingEditButtonImage }}
                onMouseEnter = {this.editButtonHoverOn}
                onMouseLeave = {this.editButtonHoverOff}
                onClick = {this.postingInputEditOff}
            >
            </div>
        ) : null;

        const postingEdit = (
            <div className = "user-profile-posting"
                style = {{ display: (this.state.editOn) ? "block" : "none" }}
            >
                <textarea
                    ref = {this.postingInputRef}
                    type = "text"
                    id = {this.postingInputID}
                    className = {(this.props.colorMode === "day")?
                        "input-s3 input-day" : "input-s3 input-night"}
                    rows = {1}
                    placeholder = {this.postingInputPlaceholder}
                    onFocus = {this.postingInputOnFocus}
                    onBlur = {this.postingInputOnBlur}
                    onChange = {this.postingInputOnChange}
                    style = {{
                        borderColor: (this.state.editOn)?
                        (
                            (this.props.colorMode === "day")?
                                window.colorLightBlue : window.colorBlue
                        ) : (
                            (this.props.colorMode === "day")?
                                window.colorLightGray : window.colorDarkGray
                        )
                    }}
                />
                {postingConfirmButton}
            </div>
        );


        /*
        ===============================================================
            Profile Edit Button
        ===============================================================
        */

        const profileEditButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/user/profile-edit-light-blue.png") :
            getStaticPath("/images/user/profile-edit-blue.png");

        const profileEditText = (this.props.browserWidth <= 1)?
        null : (
                <div id = "user-profile-edit-text"
                    className = {(this.props.colorMode === "day")?
                        "lb5" : "b5"}
                >
                    {(this.props.browserWidth <= 2)? "Edit" : "Edit Profile"}
                </div>
        );

        const profileEdit = this.props.isMyself? (
            <div id = "user-profile-edit-button-container"
                onClick = {this.openEditUserInfo}
            >
                <div id = "user-profile-edit-button"
                    className = "image-cover"
                    style = {{ backgroundImage : profileEditButtonImage }}
                    onMouseEnter = {this.updateHoverOn}
                    onMouseLeave = {this.updateHoverOff}
                >
                </div>
                {profileEditText}
            </div>
        ) : null;


        /*
        ===============================================================
            Create Level
        ===============================================================
        */

        const createLevel = (this.props.userInfo.create_level > 1)? (
            <div id = "user-profile-level-creation"
                className = {(this.props.colorMode === "day")?
                "font-century user-profile-level-creation-day" :
                "font-century user-profile-level-creation-night"}
            >
                {CREATOR_LEVELS[this.props.userInfo.create_level]}
            </div>
        ) : null;


        /*
        ===============================================================
            Follow Button / Followers / Following
        ===============================================================
        */

        // Follow button
        // console.log("this.props.userInfo = ", this.props.userInfo);
        // console.log("this.props.userInfo.followed_by_me = ", this.props.userInfo.followed_by_me);
        const followButtonID = (this.props.userInfo.followed_by_me)?
            "user-info-follow-button-on" : "user-info-follow-button-off";
        const followButtonText = (this.props.userInfo.followed_by_me)?
            "Following" : "Follow";
        const followButtonClick = (this.props.userInfo.followed_by_me)?
            this.props.unfollowRequest : this.props.followRequest;

        const followButton = this.props.isMyself? null : (
            <div id = {followButtonID}
                className = {(this.props.colorMode === "day")?
                    "button-light-blue-s3" : "button-blue-s3"}
                onClick = {followButtonClick}
            >
                {followButtonText}
            </div>
        );


        // set parameters for follwerList and followingList using UserProfilePicList
        const followerListProps = {
            colorMode: this.props.colorMode,
            numProfilePics : (this.props.browserWidth <= 2)?
                this.numFollowerProfilePicsMobile : this.numFollowerProfilePics,
            usersInfo: this.props.userInfo.followers_recent,
            userCount: this.props.userInfo.follower_count,
            userTypeLabel: "Follower",
            userTypeLabelOn: false,
            classNamePrefix: "user-info",
            classNameUserType: "follow"
        };

        const followingListProps = {
            colorMode: this.props.colorMode,
            numProfilePics: (this.props.browserWidth <= 2)?
                this.numFollowingProfilePicsMobile : this.numFollowingProfilePics,
            usersInfo: this.props.userInfo.following_recent,
            userCount: this.props.userInfo.following_count,
            userTypeLabel: "Following",
            userTypeLabelOn: false,
            classNamePrefix: "user-info",
            classNameUserType: "follow"
        };

        // Followers
        const followerList = this.props.userInfo.follower_count > 0? (
            <UserProfilePicList {...followerListProps}/>
        ) : null;

        const followers = (this.props.userInfo.follower_count > 0)? (
            <div id = {(this.props.browserWidth <= 6)?
                "user-info-followers-small" : "user-info-followers"}
            >
                <div className = "user-info-follow-title">
                    <div className = {(this.props.colorMode === "day")?
                            "user-info-follow-text k4" : "user-info-follow-text w4"}
                        onClick = {
                            () => {
                                this.props.storeFollow(
                                    {
                                        modalOn: true,
                                        mode: "followers",
                                        id: this.props.userInfo.id,
                                        myself: this.props.isMyself
                                    }
                                );
                            }
                        }
                    >
                        Followers
                    </div>
                    <div className = {(this.props.colorMode === "day")?
                        "user-info-follow-number number-lb2" : "user-info-follow-number number-b2"}
                    >
                        {formatNumbers(this.props.userInfo.follower_count)}
                    </div>
                </div>
                {followerList}
            </div>
        ) : (
            <div id = {(this.props.browserWidth <= 6)?
                "user-info-followers-small" : "user-info-followers"}
            >
            </div>
        );

        // Following
        const followingList = this.props.userInfo.following_count > 0? (
            <UserProfilePicList {...followingListProps}/>
        ) : null;

        const following = (this.props.userInfo.following_count > 0)? (
            <div id = {(this.props.browserWidth <= 6)?
                "user-info-following-small" : "user-info-following"}
            >
                <div className = "user-info-follow-title">
                    <div className = {(this.props.colorMode === "day")?
                            "user-info-follow-text k4" : "user-info-follow-text w4"}
                        onClick = {
                            () => {
                                this.props.storeFollow(
                                    {
                                        modalOn: true,
                                        mode: "following",
                                        id: this.props.userInfo.id,
                                        myself: this.props.isMyself
                                    }
                                );
                            }
                        }
                    >
                        Following
                    </div>
                    <div className = {(this.props.colorMode === "day")?
                        "user-info-follow-number number-lb2" : "user-info-follow-number number-b2"}
                    >
                        {formatNumbers(this.props.userInfo.following_count)}
                    </div>
                </div>
                {followingList}
            </div>
        ) : (
            <div id = {(this.props.browserWidth <= 6)?
                "user-info-following-small" : "user-info-following"}
            >
            </div>
        );

        // Spacer
        const spacer = ((followers != null) && (following != null))? (
            <div id = "user-info-follow-spacer">
            </div>
        ) : null;

        const loggedIn = !!localStorage.token;

        // Loader image
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif");


        // Follow block
        const followBlock = (
            <div id = {(this.props.browserWidth <= 6)?
                "user-info-follow-small" : "user-info-follow"}
            >
                {followers}
                {spacer}
                {following}
            </div>
        );

        return(
            <div id = "user-profile">
                <div id = "user-profile-left">
                    <div id = {
                            (this.props.browserWidth <= 2)?
                                "user-profile-pic-loader-small" :
                                (
                                    (this.props.browserWidth <= 6)?
                                        "user-profile-pic-loader-medium":
                                        "user-profile-pic-loader"
                                )
                        }
                        className = "image-loader-s4"
                        style = {{ backgroundImage: loaderImage }}
                    >
                        <div id = "user-profile-pic"
                            className =  {(this.props.colorMode === "day")?
                                "profile-image-base border-day" :
                                "profile-image-base border-night"}
                            style = {{ backgroundImage: profilePic }}
                            onClick = {(this.props.isMyself)? this.openEditUserInfo : null}
                        >
                        </div>
                    </div>
                    {loggedIn ? (
                        <div id = "user-info-follow-button">
                            {followButton}
                        </div>
                    ) : null }
                </div>
                <div id = {(this.props.browserWidth <= 2)?
                        "user-profile-right-small" :
                        (
                            (this.props.browserWidth <= 6)?
                                "user-profile-right-medium" : "user-profile-right"
                        )
                    }
                >
                    <div id = "user-profile-basic">
                        <div id = "user-profile-name"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            {this.props.userInfo.name}
                        </div>
                        <div id = "user-profile-username"
                            className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                        >
                            {this.props.userInfo.username}
                        </div>
                        {postingHistoryButton}
                    </div>
                    {postingDisplay}
                    {postingEdit}
                    <div id = "user-profile-level-container">
                        <div id = "user-profile-level-experience"
                            className = {(this.props.colorMode === "day")?
                                "font-century user-profile-level-experience-day" :
                                "font-century user-profile-level-experience-night"}
                        >
                            {EXPERIENCE_LEVELS[this.props.userInfo.experience_level]}
                        </div>
                        {createLevel}
                        {profileEdit}
                    </div>

                    {
                        (this.props.browserWidth <= 6)?
                            null : followBlock
                    }
                </div>

                {
                    (this.props.browserWidth <= 6)?
                        followBlock : null
                }
            </div>
        );
    }


    componentDidMount () {
        //console.log("Profile / componentDidMount - mount");

        // Set up event listeners for the posting input
        this.postingInputRef.current.addEventListener("input", this.postingInputResize);
        this.postingInputRef.current.value = "";

        // this.postingInputRef.current.addEventListener("change", this.postingInputResize);
        // this.postingInputRef.current.addEventListener("cut", this.postingInputResize);
        // this.postingInputRef.current.addEventListener("paste", this.postingInputResize);
        // this.postingInputRef.current.addEventListener("drop", this.postingInputResize);
        this.postingInputRef.current.addEventListener("keydown", this.postingInputResize);
        // this.postingInputRef.current.addEventListener("keyup", this.postingInputResize);
        // this.postingInputRef.current.addEventListener("enter", this.postingInputResize);
    }

    componentWillUnmount() {
        // Remove event listeners for the posting input
        this.postingInputRef.current.removeEventListener("input", this.postingInputResize);
        // this.postingInputRef.current.removeEventListener("change", this.postingInputResize);
        // this.postingInputRef.current.removeEventListener("cut", this.postingInputResize);
        // this.postingInputRef.current.removeEventListener("paste", this.postingInputResize);
        // this.postingInputRef.current.removeEventListener("drop", this.postingInputResize);
        this.postingInputRef.current.removeEventListener("keydown", this.postingInputResize);
        // this.postingInputRef.current.removeEventListener("keyup", this.postingInputResize);
        // this.postingInputRef.current.removeEventListener("enter", this.postingInputResize);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.userInfo.posting) !== JSON.stringify(this.props.userInfo.posting)) {
            //console.log("Profile / componentDidUpdate - this.props.userInfo.posting = ", this.props.userInfo.posting);
            //console.log("Profile / componentDidUpdate - prevProps.userInfo.posting = ", prevProps.userInfo.posting);

            // Update state
            this.setState({
                posting: this.props.userInfo.posting
            });
        }
    }


    /*
    ============================================================================================
         Edit user info
    ============================================================================================
    */

    postingHistoryOn() {
        this.props.storePosting(
            {
                modalOn: true,
                info: this.props.userInfo,
                myself: this.props.isMyself
            }
        );
    }


    /*
    ============================================================================================
         Edit user info
    ============================================================================================
    */

    openEditUserInfo() {
        this.props.storeEditUserInfoOn(true);
    }


    /*
    ============================================================================================
         Posting input
    ============================================================================================
    */

    historyButtonHoverOn() {
        this.setState({
            historyButtonHoverOn: true
        });
    }

    historyButtonHoverOff() {
        this.setState({
            historyButtonHoverOn: false
        });
    }

    editButtonHoverOn() {
        //console.log("Profile / editButtonHoverOn");

        this.setState({
            editButtonHoverOn: true
        });
    }

    editButtonHoverOff() {
        //console.log("Profile / editButtonHoverOff");

        this.setState({
            editButtonHoverOn: false
        });
    }

    editHoverOn() {
        this.setState({
            editHoverOn: true
        });
    }

    editHoverOff() {
        this.setState({
            editHoverOn: false
        });
    }

    postingInputEditOn() {
        this.setState(
            {
                editOn: true
            },
            this.postingInputResize
        );
    }

    postingInputEditOff() {
        if (window.event) {
            // Stop propagation to parents
            window.event.stopPropagation();
        }

        if (String(this.postingInputRef.current.value).replace(/\s/g, "").length > 0) {
            // Callback chain
            this.setState(
                {
                    editOn: false
                },
                this.createPosting()
            );
        }
        else {
            // Clear input value
            this.postingInputRef.current.value = "";
            this.postingInputFocused = false;
            this.postingInputRef.current.placeholder = this.postingInputPlaceholder;
            this.postingInputRef.current.style.textAlign = "center";

            this.setState(
                {
                    editOn: false
                },
            );

            //console.log("Profile / postingInputEditOff - only space");
        }
    }

    postingInputResize() {
        this.postingInputRef.current.style.height = "auto";
        this.postingInputRef.current.style.height = this.postingInputRef.current.scrollHeight + "px";
    }

    postingInputOnChange(event) {
        // console.log("Profile / postingInputOnChange - event.target.value = ", event.target.value);

        // Stop event propagation
        event.stopPropagation();
    }

    postingInputOnFocus (event) {
        //console.log("Profile / inputOnFocus - event.target.value = ", event.target.value);

        this.postingInputFocused = true;
        event.target.placeholder = "";
        event.target.style.textAlign = "left";
    }


    postingInputOnBlur (event) {
        //console.log("Profile / postingInputOnBlur - event.target.value = ", event.target.value);
        this.postingInputFocused = false;

        if (this.postingInputRef.current.value.length > 0) {
            event.target.style.textAlign = "left";
        }
        else {
            event.target.placeholder = this.postingInputPlaceholder;
            event.target.style.textAlign = "center";
        }

        // Update border color
        this.setState({
            postingInputBorderColor: (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkGray
        });
    }


    createPosting (callback) {
        const axiosCallback = (response) => {
            console.log("Profile / createPosting - response.data = ", response.data);

            // Copy user info
            const userInfo = Object.assign({}, this.props.userInfo);
            userInfo.posting = response.data.content;

            // Clear input value
            this.postingInputRef.current.value = "";
            this.postingInputFocused = false;
            this.postingInputRef.current.placeholder = this.postingInputPlaceholder;
            this.postingInputRef.current.style.textAlign = "center";

            // Update state
            this.setState(
                {
                    postingInputBorderColor: (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkGray,
                    posting: response.data.content
                },
                () => {
                    // Update global userInfo
                    this.props.storeUser(userInfo);

                    // Execute callback
                    if ((callback) && (typeof callback === "function")) {
                        callback();
                    }
                }
            );
        }

        const dataJson = {
            "content": String(this.postingInputRef.current.value)
        };

        // Send edit request using axios posting with CSRF token
        postPosting(dataJson)
        .then(axiosCallback)
        .catch((response) => {
            console.log("[WARNING] Profile / createPosting - axios error = ", response);
        });
    }
}


// Import necessary global state from the store as props
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        follow: state.follow.follow,
        editUserInfoOn: state.user.editUserInfoOn
    };
}

// Update global state in the store
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeUser, storeFollow, storePosting, storeEditUserInfoOn }, dispatch);
}

// Export component
export default connect(mapStateToProps, mapDispatchToProps)(Profile);