import {
	STORE_WARNING_ALERT,
	STORE_NOTIFICATION_ALERT,
	STORE_RESET_PASSWORD_EMAIL_SENT_ALERT,
	STORE_RESET_PASSWORD_TOKEN_EXPIRED_ALERT
} from "actions";

const INITIAL_STATE = { 
	warningAlertInfo: { message: null, on: false },
	notificationAlertInfo: { message: null, on: false },
	resetPasswordEmailPasswordInfo: { email:"", success: null },
	resetPasswordTokenExpired: false, 
	force: null 
};

export default function(state = INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_WARNING_ALERT:
			return { ...state, warningAlertInfo: action.payload };
		case STORE_NOTIFICATION_ALERT:
			return { ...state, notificationAlertInfo: action.payload };
		case STORE_RESET_PASSWORD_EMAIL_SENT_ALERT: 
			return { ...state, resetPasswordEmailPasswordInfo: action.payload }; 
		case STORE_RESET_PASSWORD_TOKEN_EXPIRED_ALERT: 
			return { ...state, resetPasswordTokenExpired: action.payload }; 
		default:
		return state;
	}
};