// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import { getStaticPath } from "js/Functions";

// Redux 
import { storeMemo, storeNewMemo } from "actions";

// Axios
import { patchDotSave, patchContribution, patchCompletion } from "requests";

// CSS
import "./Memo.scss";


class Memo extends Component {
    constructor(props){
        super(props);

        // Min memo length
        this.minMemoLength = 4;

        // DOM nodes
        this.modalRef = React.createRef();
        this.inputRef = React.createRef();

        // Placeholder for input
        this.inputPlaceholder = "Write a Memo";

        // Initialize state
        this.state = {
            submitButtonOn: false
        };

        // Bind functions
        this.resetMemo = this.resetMemo.bind(this);
        this.updateMemo = this.updateMemo.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.inputResize = this.inputResize.bind(this);
        this.inputResizeDelayed = this.inputResizeDelayed.bind(this);
        //this.inputOnSubmit = this.inputOnSubmit.bind(this);
        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        //console.log("Memo / componentDidMount - mount");

        // Initialize input vallue
        this.inputRef.current.value = this.props.memo.memo;

        // Add event listeners
        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);
        this.inputRef.current.addEventListener("keydown", this.inputResizeDelayed);
        this.inputRef.current.addEventListener("paste", this.inputResizeDelayed);
    }
    
    componentWillUnmount() {
        // Remove event listeners
        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);
        this.inputRef.current.removeEventListener("keydown", this.inputResizeDelayed);
        this.inputRef.current.removeEventListener("paste", this.inputResizeDelayed);
    }

    inputOnFocus(event) {
        //console.log("Memo / inputOnFocus - event = ", event);

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Style change
        event.target.style.textAlign = "left";
        event.target.placeholder = "";
    }

    inputOnBlur(event) {
        //console.log("Memo / inputOnBlur - event = ", event);

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Style change
        event.target.style.textAlign = "center";
        event.target.placeholder = this.inputPlaceholder;
    }

    inputOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState({
            submitButtonOn: (this.inputRef.current.value.length > this.minMemoLength)
        });
    }

    inputResize() {
        this.inputRef.current.style.height = "auto";
        this.inputRef.current.style.height = this.inputRef.current.scrollHeight + "px";
    }


    inputResizeDelayed() {
        window.setTimeout( this.inputResize, 0 );
    }


    updateMemo(event) {
        //console.log("Memo / inputOnSubmit");

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();
        
        // Callback
        const axiosCallback = (response) => {
            //console.log("Memo / updateMemo - response = ", response);

            // Update global new memo object
            this.props.storeNewMemo(
                {
                    mode: this.props.memo.mode,
                    id: this.props.memo.id,
                    memo: this.inputRef.current.value
                }
            );

            // Clear input
            this.inputRef.current.value = "";

            // Update global memo object
            this.props.storeMemo(
                { 
                    modalOn: false,
                    mode: null,
                    id: null,
                    dot: null,
                    memo: null
                }
            );
        };

        // Data to send
        const dataJSON = {
            memo: this.inputRef.current.value
        };

        // Send request
        if (this.props.memo.mode === "save") {
            // Send data to server to create a new tag
            patchDotSave(this.props.memo.id, dataJSON)
            .then(axiosCallback)
            .catch(
                (response) => {
                    console.log("[WARNING] Memo / updateMemo - ", response);
                }
            );
        }
        else if (this.props.memo.mode === "contribute") {
            // Send data to server to create a new tag
            patchContribution(this.props.memo.id, dataJSON)
            .then(axiosCallback)
            .catch(
                (response) => {
                    console.log("[WARNING] Memo / updateMemo - ", response);
                }
            );
        }
        else if (this.props.memo.mode === "everyday") {
            // Send data to server to create a new tag
            patchContribution(this.props.memo.id, dataJSON)
            .then(axiosCallback)
            .catch(
                (response) => {
                    console.log("[WARNING] Memo / updateMemo - ", response);
                }
            );
        }
        else if (this.props.memo.mode === "complete") {
            // Send data to server to create a new tag
            patchCompletion(this.props.memo.id, dataJSON)
            .then(axiosCallback)
            .catch(
                (response) => {
                    console.log("[WARNING] Memo / updateMemo - ", response);
                }
            );
        }
        else {
            console.log("Memo / updateMemo - wrong tag mode");
        }
    }

    resetMemo() {
        this.props.storeMemo(
            { 
                modalOn: false,
                mode: null,
                id: null,
                dot: null,
                memo: null
            }
        );
    }

    escClick(event) {
        //console.log("Memo / escClick");

        if (this.props.memo.modalOn === true && event.keyCode === 27) {
            this.resetMemo();
        }
    }

    clickOutside(event) {
        //console.log("Memo / clickOutside");

        // If clicked outside
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.resetMemo();
        }
    }

    closeModal(event) {
        //console.log("Memo / closeModal");

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Reset memo
        this.resetMemo();
    }

    // Main JSX
    render() {
        // Submit button
        const submitButton = (this.state.submitButtonOn)?
        (
            <div className = "memo-modal-content__row-center">
                <div className = {
                        (this.props.colorMode === "day")?
                            "memo-modal-content__submit button-light-blue-gray-s3" :
                            "memo-modal-content__submit button-blue-gray-s3"
                    }
                    onClick = {this.updateMemo}
                >
                    {(this.props.memo.memo === null)? "Submit" : "Update"}
                </div>
            </div>
        ) : null;


        // Name and area
        let area = null;
        if (this.props.memo.id !== null) {
            if (this.props.memo.dot.area) {
                area = (
                    <div className = {(this.props.colorMode === "day")?
                            "memo-modal-content__area lb4" :
                            "memo-modal-content__area b4"
                        }
                    >
                        {(this.props.memo.dot.area)? this.props.memo.dot.area : "Area"}
                    </div>
                );
            }
        }

        const name = (this.props.memo.id === null)? null : (
            <div className = {(this.props.colorMode === "day")?
                    "memo-modal-content__name k3" :
                    "memo-modal-content__name w3"
                }
            >
                {this.props.memo.dot.name}
            </div>
        );

        //console.log("Memo / render - this.props = ", this.props);
        return (
            <div className = "memo-modal" 
                style = {{
                    display: (this.props.memo.modalOn)? "block" : "none"

                }}
            >
                <div ref = {this.modalRef}
                    className = {(this.props.colorMode === "day")? 
                        "memo-modal-content modal-day" : 
                        "memo-modal-content modal-night"} 
                >
                    <div className = "memo-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage:  (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                        onClick = {this.closeModal}
                    >
                    </div>

                    <div className = "memo-modal-content__title">
                        {name}
                        {area}
                    </div>

                    <div className = "memo-modal-content__row-center">
                        <textarea
                            ref = {this.inputRef}
                            id = "memo-modal-input"
                            className = {(this.props.colorMode === "day")? 
                                "memo-modal-content__input text-input input-day" : 
                                "memo-modal-content__input text-input input-night"}
                            rows = {3}
                            placeholder = {this.inputPlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.inputOnChange}
                            //style = {{ borderColor: this.state.overviewBorderColor }}
                            //required
                        />
                    </div>

                    {submitButton}
                </div>
            </div>
        );
    } 
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        memo: state.memo.memo,
        newMemo: state.memo.newMemo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeMemo, storeNewMemo }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(Memo);
