/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    DotsHomeControl.js
    - Control dots home
--------------------------------------------------------------------------------------------
    Content
    - DotsHomeControl
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 
import { storeDotsHomeOn } from "actions";


class DotsHomeControl extends Component {

    // Component constructor
    constructor(props) {
        //console.log("DotsHome / constructor - props = ", props);
        super(props);

        // Initial state
        this.state = {};
    }


    // Main render function
    render() {
        return (
            <div id = "dots-home-control-container">
            </div>
        );
    }


    componentDidMount() {
        //this._isMounted = true;
        //console.log("DotsHomeControl / componentDidMount");

        this.props.storeDotsHomeOn(true);
    }


    componentWillUnmount() {
        //this._isMounted = false;
        //console.log("DotsHomeControl / componentWillUnmount");

        this.props.storeDotsHomeOn(false);
    }

    componentDidUpdate(prevProps, prevState) {}
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({storeDotsHomeOn}, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(DotsHomeControl); 
