import {
	STORE_USER,
	STORE_EDIT_USER_INFO_ON
} from "actions"; 

const INITIAL_STATE = {
	userInfo: null,
	editUserInfoOn: false
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_USER:
			return { ...state, userInfo: action.payload };
		case STORE_EDIT_USER_INFO_ON:
			return { ...state, editUserInfoOn: action.payload};
		default:
			return state;
	}
};