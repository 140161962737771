/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    TripsHome.js
    - Main home feed that showcases trips
--------------------------------------------------------------------------------------------
    Content
    - TripsHome
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

// Modules
import Slider from "thedots-slick";

// Functions
import { getStaticPath, pad, permutate } from "js/Functions";

// Redux
import { storeMainMode } from "actions";

// CSS
import "./TripsHome.scss";


// Home component
class TripsHome extends Component {
    constructor() {
        super();

        // Number of images to show
        const tripsToShow = Math.round(window.innerWidth/ 250);

        // Initialize states
        this.state = {
            randomizedTripList: true,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            tripImageWidth: 250,
            tripsToShow: tripsToShow
        }

        // Bind functions
        this.updateWindowSize = this.updateWindowSize.bind(this);
    }


    // Set up an event listener for window size change
    componentDidMount() {
        window.addEventListener("resize", this.updateWindowSize);
    }


    // Cancel the event listener for window size change
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowSize);
    }

    render() {
        // Settings for slider
        const tripSettings = {
            colorMode: this.props.colorMode,
            screenWidth: this.state.screenWidth,
            screenHeight: this.state.screenHeight,
            tripImageWidth: this.state.tripImageWidth,
            tripsToShow: this.state.tripsToShow,
            storeMainMode: this.props.storeMainMode
        };

        return (
            <div id = "trips-home-container"
                className = {(this.props.browserWidth <= window.browserWidthSmall)?
                    "trips-home-container-small" : "trips-home-container"}
            >
                <div className = "body-narrow">
                <div className = "row">
                    <FeaturedTrips
                        browserWidth = {this.props.browserWidth}
                        colorMode = {this.props.colorMode}
                        storeMainMode = {this.props.storeMainMode}
                    />
                </div>
                </div>
                <div className = "body-wide trips-home-slider-wrapper">
                    <div className = "row">
                        <TripList {...tripSettings} title = "Popular" indices = {[0,1,3,7,8,10]}/>
                    </div>
                    <div className = "row">
                        <TripList {...tripSettings} title = "Trending" indices = {[2,4,5,9,11,12]}/>
                    </div>
                    <div className = "row">
                        <TripList {...tripSettings} title = "Fresh" indices = {[0,3,6,7,9,10]}/>
                    </div>
                </div>
            </div>
        );
    }


    // Window size update callback (used by eventlistner set up in componenetDidMount)
    updateWindowSize() {
        // Get the new window size
        const newScreenWidth = window.innerWidth;
        const newScreenHeight = window.innerHeight;
        const newTripsToShow = Math.round(newScreenWidth / this.state.tripImageWidth);

        // Set states
        this.setState({
            screenWidth: newScreenWidth,
            screenHeight: newScreenHeight,
            tripsToShow: newTripsToShow
        });
    }
}




//============================================================================================
//    Featured Trips
//============================================================================================

// FeaturedTrips Object
class FeaturedTrips extends Component {
    constructor() {
        super();

        this.state = {
            tripIndices: null,
            tripIDs: null,
            ratings: null,
            titles: null,
            descriptions: null
        };
    }

    componentWillMount(){
        // Trip indices and ratings
        const indices = [0, 1, 2, 3, 4, 5];
        const ratings = [4.5, 3.7, 4.3, 4.2, 3.3, 3.5];
        const titles = [
            "A Weekend at the Alpine Paradise",
            "A Golden Day in the Sierras",
            "Witness the Greatest Work of Time",
            "The Search for Alien Life",
            "Lost World of the Southwest",
            "Winter at the Redrock Temple"
        ];
        const descriptions = [
            "A Walk through John Muir's Favorite Alpine Garden",
            "Feel the Cool Breeze of Aspen Leaves",
            "Hidden Gems of Grand Canyon - A Tour of the South Rim Like No Other",
            "A Dawn and a Dusk at an Exotic Salt Lake",
            "Hear the Voices of the Canyons Buried Under Water",
            "The Unforgettable Views of the Winter Canyon"
        ];

        // Generate permutated lists
        const permutatedIndices = permutate(indices);
        const permutatedRatings = [];
        const permutatedTitles = [];
        const permutatedDescriptions = [];
        for (let i = 0; i < indices.length; i++) {
            permutatedRatings[i] = ratings[permutatedIndices[i]];
            permutatedTitles[i] = titles[permutatedIndices[i]];
            permutatedDescriptions[i] = descriptions[permutatedIndices[i]];
        }

        // Update states
        this.setState({
            tripIndices: indices,
            tripIDs: permutatedIndices,
            ratings: permutatedRatings,
            titles: permutatedTitles,
            descriptions: permutatedDescriptions
        });
    }

    render() {
        // Featured trip slider settings
        const sliderSettings = {
            accessibility: true,
            arrows: false,
            className: (this.props.browserWidth <= 6)?
                "trips-home-featured-slider-small" :
                (
                    (this.props.browserWidth <= 10)?
                        "trips-home-featured-slider-medium" :
                        "trips-home-featured-slider"
                ),
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            hoverZoom: false,
            hoverZoomScale: 1.0
        };

        // Trip indices
        const indices = this.state.tripIndices;

        // If not rendered yet
        let slider = null;
        if (indices != null) {
            // Generate array of featured trips
            // trip index : 0 ~ N-1 | trip number : 1 ~ N
            const listFeaturedTrips = indices.map((index) =>
                <div key = {"trips-home-featured-item-container-" + index.toString()}>
                    <FeaturedTripItem
                        colorMode = {this.props.colorMode}
                        key = {"trips-home-featured-item-" + index.toString()}
                        number = {index + 1}
                        id = {this.state.tripIDs[index]}
                        rating = {this.state.ratings[index]}
                        title = {this.state.titles[index]}
                        description = {this.state.descriptions[index]}
                        storeMainMode = {this.props.storeMainMode}
                    />
                </div>
            );

            slider = (
                <Slider {...sliderSettings}>
                    {listFeaturedTrips}
                </Slider>
            );
        }

        // Render the list of featured trips
        return (
            <div>
                <div>{this.props.title}</div>
                {slider}
            </div>
        );
    }
}

// Individual FeaturedTrip Object
class FeaturedTripItem extends Component {
    constructor(props) {
        super(props);

        // Ref
        this.imageRef = React.createRef();

        // State
        this.state = {
            imageLoaded: false
        };

        // Bind functions
        this.handleImageLoad = this.handleImageLoad.bind(this);
    }

    render() {
        // Image loader
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif");

        // Stars
        const starWidth = Math.round(this.props.rating / 5.0 * 100) + "%";
        const starTopImage = getStaticPath("/images/common/star-full-white.png");
        const starBottomImage = getStaticPath("/images/common/star-empty-white.png");

        // Image
        const dotImageName = pad(this.props.id + 1, 4, 0);
        const dotImage = getStaticPath(`/images/featured-trip/${dotImageName}.jpg`, false);

        // Turn off trips mode
        const clearMainMode = () => {
            this.props.storeMainMode(null)
        };

        const description = (this.state.imageLoaded)? (
            <div className = "trips-home-featured-item-gradient">
                <div className = "trips-home-featured-item-text">
                    <p className = "trips-home-featured-item-text-title w0">
                        {this.props.title}
                    </p>
                    <p className = "trips-home-featured-item-text-description lg4">
                        {this.props.description}
                    </p>
                    <div className = "trips-home-featured-item-text-rating number font-cabin-medium">
                        {this.props.rating.toFixed(1)}
                    </div>
                    <div className = "trips-home-featured-item-star-bottom"
                        style = {{ backgroundImage: starBottomImage }}>
                        <div className = "trips-home-featured-item-star-top"
                            style = {{
                                width: starWidth,
                                backgroundImage: starTopImage
                            }}
                        >
                        </div>
                    </div>
                </div>
                <div className="trips-home-featured-item-spacer">
                </div>
            </div>
        ) : null;

        // Render a featured trip
        return (
            <Link to = {`/trip/yosemite-valley`}>
                <div className = {(this.props.colorMode === "day")?
                        "trips-home-featured-item-loader image-loader-s0 border-day" :
                        "trips-home-featured-item-loader image-loader-s0 border-night"
                    }
                    style = {{ backgroundImage: loaderImage }}
                    onClick = {clearMainMode}
                >
                    <img ref = {this.imageRef}
                        alt = "featured trips"
                        className = "trips-home-featured-item"
                        src = {dotImage}
                        onLoad = {this.handleImageLoad}
                    >
                    </img>
                    {description}
                </div>
            </Link>
        );
    }

    componenetDidMount() {
        const image = this.imageRef.current;
        if (image) {
            if (image.complete) {
                this.handleImageLoad();
            }
            else {
                this.imageRef.addEventListener()
                image.addEventListener('load', this.handleImageLoad);
            }
        }
    }

    componentWillUnmount() {
        const image = this.imageRef.current;
        image.removeEventListener('load', this.handleImageLoad);
    }

    handleImageLoad() {
        this.setState({ imageLoaded: true });
    }
}


//============================================================================================
//    Trip Lists
//============================================================================================

class TripList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputTripIndices: props.indices,
            tripIndices: null,
            tripIDs: null,
            ratings: null,
            titles: null,
            descriptions: null,
            levels: null,
            areas: null,
            offroads: null
        }
    }

    componentWillMount() {
        // Input indices
        const indices = this.state.inputTripIndices;

        // Trip indices and ratings
        const indicesCut = [0, 1, 2, 3, 4, 5];
        // const indicesData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const ratingsData = [4.5, 3.7, 4.3, 4.2, 3.3, 3.5, 4.0, 3.9, 3.8, 4.1, 3.6, 4.1, 4.2];
        const levelsData = [2,3,1,1,1,1,2,3,2,4,1,3,2];
        const areasData = ["UT","UT","AZ","CA","CA","NV","CA","WA","AZ","UT","CA","WY","CA"];
        const offroadsData = [false,true,false,false,false,false,false,true,false,true,false,false,false];
        const titlesData = [
            "Kingdom of Hoodoos",
            "100 Miles from Nowhere",
            "Find Your Guardian Tree",
            "An Unconventional View of the Bay",
            "The Lake of Felons",
            "Keep It Blue",
            "Meditate through the High Land",
            "The Unknown",
            "Stormchasing in Grand Canyon",
            "Hidden Canyons of the Needles District",
            "Pathway to Light",
            "Mountains of a Fairy Tale",
            "Water Down the Mosses"
        ];
        const descriptionsData = [
            "Description 1",
            "Description 2",
            "Description 3",
            "Description 4",
            "Description 5",
            "Description 6",
            "Description 7",
            "Description 8",
            "Description 9",
            "Description 10",
            "Description 11",
            "Description 12",
            "Description 13"
        ];

        // Generate permutated lists
        const permutatedIndices = permutate(indices);
        const permutatedRatings = [];
        const permutatedTitles = [];
        const permutatedDescriptions = [];
        const permutatedLevels = [];
        const permutatedAreas = [];
        const permutatedOffroads = [];

        for (let i = 0; i < indices.length; i++) {
            permutatedRatings[i] = ratingsData[permutatedIndices[i]];
            permutatedTitles[i] = titlesData[permutatedIndices[i]];
            permutatedDescriptions[i] = descriptionsData[permutatedIndices[i]];
            permutatedLevels[i] = levelsData[permutatedIndices[i]];
            permutatedAreas[i] = areasData[permutatedIndices[i]];
            permutatedOffroads[i] = offroadsData[permutatedIndices[i]];
        }

        // Set states
        this.setState({
            tripIndices: indicesCut,
            tripIDs: permutatedIndices,
            ratings: permutatedRatings,
            titles: permutatedTitles,
            descriptions: permutatedDescriptions,
            levels: permutatedLevels,
            areas: permutatedAreas,
            offroads: permutatedOffroads
        });
    }


    render() {
        // Regular trip slider settings
        const sliderSettings = {
            accessibility: true,
            arrows: true,
            className: "trips-home-slider",
            autoplay: false,
            autoplaySpeed: 4000,
            infinite: true,
            pauseOnHover: true,
            focusOnSelect: true,
            slidesToShow: this.props.tripsToShow,
            slidesToScroll: 2,
            hoverZoom: true,
            hoverZoomScale: 1.25,
            overFlow: true
        };

        // Trip indices
        const indices = this.state.tripIndices;

        // If not rendered yet
        let slider = null;
        if (indices != null) {
            // Generate array of featured trips
            // trip index : 0 ~ N-1 | trip number : 1 ~ N
            const listTrips = indices.map((index) =>
                <div key = {"trips-home-item-container-" + index.toString()} >
                    <TripItem
                        colorMode = {this.props.colorMode}
                        key = {"trips-home-item-" + index.toString()}
                        number = {index + 1}
                        id = {this.state.tripIDs[index]}
                        rating = {this.state.ratings[index]}
                        title = {this.state.titles[index]}
                        description = {this.state.descriptions[index]}
                        level = {this.state.levels[index]}
                        area = {this.state.areas[index]}
                        offroad = {this.state.offroads[index]}
                        storeMainMode = {this.props.storeMainMode}
                    />
                </div>);

            slider = (
                <Slider {...sliderSettings}>
                    {listTrips}
                </Slider>);
        }

        // Render the list of trips
        return (
            <div>
                <div className="trips-home-slider-title font-century">
                    {this.props.title}
                </div>
                {slider}
            </div>
        );
    }
}


// Individual Trip Object
class TripItem extends Component {
    constructor(props) {
        super(props);

        // Ref
        this.imageRef = React.createRef();

        // State
        this.state = {
            imageLoaded: false
        };

        // Bind functions
        this.handleImageLoad = this.handleImageLoad.bind(this);
    }

    render() {
        // Image loader
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif");

        // Stars
        const starWidth = Math.round(this.props.rating / 5.0 * 100) + "%";
        const starTopImage = getStaticPath("/images/common/star-full-white.png");
        const starBottomImage = getStaticPath("/images/common/star-empty-white.png");

        // Image
        const dotImageName = pad(this.props.id + 1, 4, 0);
        const dotImage = getStaticPath(`/images/trip/${dotImageName}.jpg`, false);

        // Turn off trips mode
        const clearMainMode = () => {
            this.props.storeMainMode(null)
        };

        const offroadText = (this.props.offroad)?
            (
                <div className = "trips-home-item-detail-offroad font-cabin-medium">
                    4WD
                </div>
            ) : null;

        const description = (this.state.imageLoaded)? (
            <div className = "trips-home-item-gradient">
                <div className = "trips-home-item-info">
                    <p className = "trips-home-item-info-title w4">
                        {this.props.title}
                    </p>
                    <div className = "trips-home-item-info-rating number-w4">
                        {this.props.rating.toFixed(1)}
                    </div>
                    <div className = "trips-home-item-star-bottom"
                        style = {{
                            backgroundImage: starBottomImage
                        }}
                    >
                        <div className = "trips-home-item-star-top"
                            style = {{
                                width: starWidth,
                                backgroundImage: starTopImage
                            }}
                        >
                        </div>
                    </div>
                </div>
                <div className = "trips-home-item-detail-switch">
                    {offroadText}
                    <div className = "trips-home-item-detail">
                        <span className = "trips-home-item-detail-area font-cabin-medium">
                            {this.props.area}
                        </span>
                        <span className = "trips-home-item-detail-level font-cabin-medium">
                            {this.props.level}
                        </span>
                    </div>
                </div>
            </div>
        ) : null;

        return (

            <Link to = {`/trip/yosemite-valley`}>
                <div className = {(this.props.colorMode === "day")?
                        "trips-home-item-loader image-loader-s3 border-day" :
                        "trips-home-item-loader image-loader-s3 border-night"
                    }
                    style = {{ backgroundImage: loaderImage }}
                    onClick = {clearMainMode}
                >
                    <img ref = {this.imageRef}
                        alt = "recommended trips"
                        className = "trips-home-item"
                        src = {dotImage}
                        onLoad = {this.handleImageLoad}
                    >
                    </img>
                    {description}
                </div>
            </Link>
        );
    }

    componenetDidMount() {
        const image = this.imageRef.current;
        if (image) {
            if (image.complete) {
                this.handleImageLoad();
            }
            else {
                this.imageRef.addEventListener()
                image.addEventListener('load', this.handleImageLoad);
            }
        }
    }

    componentWillUnmount() {
        const image = this.imageRef.current;
        image.removeEventListener('load', this.handleImageLoad);
    }

    handleImageLoad() {
        this.setState({ imageLoaded: true });
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        google: state.map.google,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({storeMainMode}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TripsHome);
