// Mixpanel
import mixpanel from 'mixpanel-browser';
//import {MixpanelProvider, MixpanelConsumer} from "react-mixpanel"; // https://www.npmjs.com/package/react-mixpanel


mixpanel.init('0cec558bce5506211dedcea6a7a8a65c');


let env_check = process.env.NODE_ENV === 'production';
export let Mixpanel = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
};


// Google Analytics - gtags
export const GA_TRACKING_ID = 'G-K0RSDFE20P';

export const pageview = url => {
    window.gtag('config', GA_TRACKING_ID, {
        page_location: url
    });
};


export const event = ({action, category, label, value}) => {
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
    });
};
