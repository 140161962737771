/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Trip.js
    - Home page of a trip
    - Use Dayinfo / Map / TripOverview components
--------------------------------------------------------------------------------------------
    Content
    - Trip
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Redux
import {
    storeUser,
    storeMore,
    storeNewLike,
    storeNewSave,
    storeShare,
    storeWarningAlert
} from "actions";

// Modules
import DatePicker from "react-datepicker";
import InputRange from "thedots-input-range";
import ReactTooltip from "thedots-tooltip";
import { MetaTags } from "react-meta-tags";

// Components
import { initTime } from "js/Time";
import TripOverview from "./TripOverview";
import { DayInfo } from "components/DayInfo";
import SaveBucket from "components/SaveBucket";
import { OpenMap, GoogleMap, getAverageLocation } from "components/Map";
import { Gallery, mediaDimensions } from "components/Gallery";
import Board from "components/Board";

// Axios
import { 
    getDot,
    postParticipant,
    postOpenItineraries,
    postFollowRequest,
    getSingleBoardComments
} from "requests";

// Websocket
import {
    addWebSocketGroups,
    removeWebSocketGroups
} from "js/WebSocketFunctions";

// Functions
import {
    UserProfilePicList,
    UserSimpleList,
    OpenItineraryList,
    BottleProfilePicList
} from "js/Common";

import {
    likeButtonClick,
    saveButtonClick
} from "js/Interaction";

import {
    updateCurationStyle,
    curationCapitalize,
    curationPartition,
    removeFirstSpace
}
from "js/Curation";

import {
    dotIDsToIndices,
    updateTimesSetState, 
    updateTimes,
    updateDate,
    updateDayInfo,
    startInputOnFocus,
    startInputOnBlur,
    endInputOnFocus,
    endInputOnBlur,
    setStartLocation,
    setEndLocation,
    setStartTimezone,
    setEndTimezone,
    resetAnimations,
    dotClick,
    dotHoverOn,
    dotHoverOff,
    endHoverOn,
    endHoverOff,
    timeSliderOnChange,
    timeSliderOnChangeComplete,
    timeSliderFormatLabel,
    showItineraryTimeWarning    
} from "js/TripFunctions";

import { fetchWeather } from "components/DayInfo";

import {
    url,
    getStaticPath,
    getMediaProperty,
    sortMedia,
    formatNumbers,
    debounce
} from "js/Functions";

// CSS
import "./Trip.scss";

// Response codes
import { responseResultCodes } from "ResponseCodes";


// Trip component
class Trip extends Component {
    // Constructor receives tripSlug as the prop
    constructor (props) {
        super (props);
        //console.log("Trip / constructor - props = ", props);

        // Map Mode (google / open / static)
        this.mapSource = "open";
        this.mapMode = "trip-overview";

        // Name Mobile Max Length
        this.nameMobileMaxLength = 8;

        // Margin bottom
        this.marginBottom = 120;
        this.marginBottomOpenItineraries = 220;
        this.marginBottomOpenItinerariesStacked = 280;

        // Side column widths
        this.editorColumnWidth = 160;
        this.statsColumnWidth = 160;

        // Minimum / scale / maximum content widths
        this.minContentWidth = 320;
        this.scaleContentWidth = 600;
        this.maxContentWidth = 700;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media area
        this.mediaArea = 360000;

        // Media side margin width
        this.marginWidth = 10;

        // Gallery spacing
        this.gallerySpacing = 32;

        // Map settings
        this.mapZoom = 10;
        this.mapHeight = 280;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // Open trips settings
        this.numOpenItinerariesRegular = 3;
        this.numOpenItinerariesMedium = 2;
        this.numOpenItinerariesSmall = 1;
        this.numOpenItineraries = (this.props.browserWidth <= 4)?
            this.numOpenItinerariesSmall : (this.props.browserWidth <= 7)?
                this.numOpenItinerariesMedium : this.numOpenItinerariesRegular;
        this.numOpenItineraryUsers = 3;

        // User ratings / difficulties settings
        this.numThresholdRatings = 5;
        this.numThresholdDifficulties = 5;
        this.numCompletedUserProfilePics = 3;

        // Staff section settings
        this.numStaffProfilePics = 3;

        // Bottle size
        this.minBottleSize = 3;
        this.maxBottleSize = 5;

        // Curation ref and height
        this.curationHeight = 100;
        this.curationLineHeight = 20;
        this.curationMargin = 2;
        this.overviewRef = React.createRef();
        this.todosRef = React.createRef();
        this.historyRef = React.createRef();
        this.storiesRef = React.createRef();

        // Map DOM IDs
        this.mapNodeID = "trip-map";
        this.mapContainerNodeID = "trip-map-wrapper";
        this.mapButtonsNodeID = "trip-map-buttons";

        // Location input DOM IDs
        this.startInputNodeID = "trip-start-location-input";
        this.endInputNodeID = "trip-end-location-input";

        // WebSocket Prefix
        this.webSocketGroupPrefix = "dot_board";

        // Weather on
        //this.showWeather = true;

        // Initialize states
        this.state = {
            // Render Itinerary component
            renderItinerary: false,
            
            // Trip information and states to be passed to Itinerary
            tripInfo: null,
            tripStates: null, 

            // Comments info
            commentsInfo: null,

            // Locations and timezones
            location: null,
            timezone: null,
            startLocation: null,
            endLocation: null,
            startLocationName: null,
            endLocationName: null,
            startTimezone: null,
            endTimezone: null,

            // Highlight dots (indices of highlights in the dotsInfo list)
            highlightIndices: null,

            // Selected / hovered / selectedChild / hoveredChild
            selected: null,
            hovered: null,
            selectedChild: null,
            hoveredChild: null,
            displayChildren: null,

            // Media galleries
            mediaOverview: null,
            mediaTodos: null,
            mediaHistory: null,
            mediaStories: null,
            overviewSelectedMediaIndex: 0,
            todosSelectedMediaIndex: 0,
            historySelectedMediaIndex: 0,
            storiesSelectedMediaIndex: 0,

            // Curation
            overviewExpanded: false,
            overviewVariableHeight: false,
            todosExpanded: false,
            todosVariableHeight: false,
            historyExpanded: false,
            historyVariableHeight: false,
            storiesExpanded: false,
            storiesVariableHeight: false,

            // Go button
            goReady: false,
            goInfoOn: false,

            // Times
            startMoment: null,
            endMoment: null,
            sunTimes : null,
            moonTimes : null,
            dayTime : null,
            offsetTime: null,
            totalTime: null,
            startEndTimeValues: null,
            hour00Moment: null,
            hour24Moment: null,

            // Astronomy
            sunMoonOn: false,
            moonPhase: null,
            moonPhaseDigits: null,

            // Weather
            weather: null,
            weatherOn: false,
            weatherIcon: null,
            tempHigh: null,
            tempLow: null,
            precipOn: null,
            precipType: null,
            precipProb: null,

            // Like / save
            likeCount: null,
            likedByMe: null,
            saveCount: null,
            savedByMe: null,

            // Editor buttons
            editorChatButtonOn: false,
            editorCalendarButtonOn: false,
            editorInfoButtonOn: false,

            // Editor information window
            editorInfoOn: false,

            // If the editor is followed by the logged in user
            followedByMe: null,
            followerCount: null,

            // Preview switches and opacities
            previewOn: false,
            previewContentOn: false,

            // Map switch 
            mapOn: true,

            // Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false,

            // Open itineraries
            openItineraryFirstIndex: null,
            openItineraryLastIndex: null,
            openItinerariesInfo: [],
            openItineraryFetchedCount: 0,
            openItineraryTotalCount: 0,
            openItineraryIndices: null,

            // Processed batch (Need to update the above)
            processedBatchCount: 0,

            // Window width
            windowWidth: window.innerWidth,

            // Websocket Group
            webSocketGroup: null,

            // Scroll
            checkScroll: false
        };

        // Bind setState and onScroll functions
        this.setState = this.setState.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);

        // Bind open itineraries functions
        this.loadOpenItineraries = this.loadOpenItineraries.bind(this);
        this.resetOpenItineraryIndices = this.resetOpenItineraryIndices.bind(this);

        // Bind editor follow / unfollow / editor info functions
        this.followRequest = this.followRequest.bind(this);
        this.unfollowRequest = this.unfollowRequest.bind(this);
        this.clickEditorInfo = this.clickEditorInfo.bind(this);

        // Bind gallery click functions
        this.overviewNextMediaClick = this.overviewNextMediaClick.bind(this);
        this.overviewPrevMediaClick = this.overviewPrevMediaClick.bind(this);
        this.overviewNavDotClick = this.overviewNavDotClick.bind(this);

        // Bind curation expand functions
        this.toggleOverviewExpanded = this.toggleOverviewExpanded.bind(this);
        this.toggleTodosExpanded = this.toggleTodosExpanded.bind(this);
        this.toggleHistoryExpanded = this.toggleHistoryExpanded.bind(this);
        this.toggleStoriesExpanded = this.toggleStoriesExpanded.bind(this);

        // Bind go button functions
        this.goCancelButtonClick = this.goCancelButtonClick.bind(this);
        this.goButtonFirstClick = this.goButtonFirstClick.bind(this);
        this.goButtonSecondClick = this.goButtonSecondClick.bind(this);

        // Bind dot click and hover function
        this.dotClick = dotClick.bind(this);
        this.dotHoverOn = dotHoverOn.bind(this);
        this.dotHoverOff = dotHoverOff.bind(this);
        this.endHoverOn = endHoverOn.bind(this);
        this.endHoverOff = endHoverOff.bind(this);

        // Bind date and time change related functions
        this.updateTimes = updateTimes.bind(this);
        this.updateTimesSetState = updateTimesSetState.bind(this);
        this.updateDate = updateDate.bind(this);
        this.updateDayInfo = updateDayInfo.bind(this);
        this.fetchWeather = fetchWeather.bind(this);

        // Bind time slider functions
        this.timeSliderOnChange = timeSliderOnChange.bind(this);
        this.timeSliderOnChangeComplete = timeSliderOnChangeComplete.bind(this);
        this.timeSliderFormatLabel = timeSliderFormatLabel.bind(this);

        // Bind itinerary time warning function
        this.showItineraryTimeWarning = showItineraryTimeWarning.bind(this);

        // Bind location input focus and blur functions
        this.startInputOnFocus = startInputOnFocus.bind(this);
        this.startInputOnBlur = startInputOnBlur.bind(this);
        this.endInputOnFocus = endInputOnFocus.bind(this);
        this.endInputOnBlur = endInputOnBlur.bind(this);

        // Bind set location functions
        this.setStartLocation = setStartLocation.bind(this);
        this.setEndLocation = setEndLocation.bind(this);
        this.setStartTimezone = setStartTimezone.bind(this);
        this.setEndTimezone = setEndTimezone.bind(this);

        // Bind map animation function
        this.resetAnimations = resetAnimations.bind(this);

        // Bind like / save / share / more button functions
        this.likeButtonClick = likeButtonClick.bind(this);
        this.saveButtonClick = saveButtonClick.bind(this);
        this.shareButtonClick = this.shareButtonClick.bind(this);
        this.moreButtonClick = this.moreButtonClick.bind(this);

        // Bind curation stylize functions
        this.updateCurationStyle = updateCurationStyle.bind(this);
        this.updateCurationLayout = this.updateCurationLayout.bind(this);
        this.curationCapitalize = curationCapitalize.bind(this);
        this.curationPartition = curationPartition.bind(this);
        this.removeFirstSpace = removeFirstSpace.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        // Open itinerary functions
        this.joinOpenItinerary = this.joinOpenItinerary.bind(this);
        this.cancelOpenItinerary = this.cancelOpenItinerary.bind(this);
    }


    /*
    ============================================================================================
        Main Render Function
    ============================================================================================
    */

    render() {
        // Only when trip and dot info are ready
        if (this.state.tripInfo !== null) {
            // Determine if user is logged in
            const loggedIn = (!!localStorage.token && (this.props.userInfo !== null));
            const isAuthor = (loggedIn && this.props.userInfo.id === this.state.tripInfo.editor.id);

            // Small layout
            const smallLayout = this.props.browserWidth <= 3;

            // Save limit
            const bucketedOut = (this.state.tripInfo.save_limit === null)? false : 
                (
                    (this.state.tripInfo.saved_user_count >= this.state.tripInfo.save_limit)?
                        true : false
                );

            // Open itinerary on
            const openItineraryOn = (this.state.openItinerariesInfo.length > 0);
            const openItineraryStacked = (openItineraryOn && this.props.browserWidth <= 2);


            /*
            ============================================================================================
                Trip Stats
            ============================================================================================
            */

            // Rating
            const ratingTitle = (this.state.tripInfo.rated_user_count >= this.numThresholdRatings)? 
                "Rating" :
                (
                    (this.props.browserWidth <= 6)? 
                        "Rating" : "Editor Rating"
                );
            let rating = null;
            let ratingCount = null;

            if ((this.state.tripInfo.rated_user_count >= this.numThresholdRatings) && (this.state.tripInfo.user_rating != null)) {
                ratingCount= formatNumbers(this.state.tripInfo.rated_user_count).toString() + " Users";
                rating = (
                    <div className = "trip-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "trip-preview-numbers-rating-mobile" :
                                    "trip-preview-numbers-rating"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-blue" : "font-cabin blue"}
                        >
                            {Number(this.state.tripInfo.user_rating).toFixed(1)}
                        </div>
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "trip-preview-numbers-rating-count" :
                                    "trip-preview-numbers-rating-count"
                            }
                            className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                        >
                            {ratingCount}
                        </div>
                    </div>
                );
            }
            else {
                rating = (
                    <div className = "trip-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "trip-preview-numbers-rating-mobile" :
                                    "trip-preview-numbers-rating"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-blue" : "font-cabin blue"}
                        >
                            {Number(this.state.tripInfo.dot_extension.rating).toFixed(1)}
                        </div>
                    </div>
                );
            }

            // Difficulty
            const difficultyTitle = (this.state.tripInfo.difficultied_user_count >= this.numThresholdDifficulties)? 
                    "Difficulty" : 
                    (
                        (this.props.browserWidth <= 6)? 
                            "Difficulty" : "Editor Difficulty"
                    );

            let difficulty = null;
            let difficultyCount = null;

            if ((this.state.tripInfo.difficultied_user_count >= this.numThresholdDifficulties) && (this.state.tripInfo.user_difficulty != null)) {
                difficultyCount= formatNumbers(this.state.tripInfo.difficultied_user_count).toString() + " Users";
                difficulty = (
                    <div className = "trip-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "trip-preview-numbers-difficulty-mobile" :
                                    "trip-preview-numbers-difficulty"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-red" : "font-cabin red"}
                        >
                            {Number(this.state.tripInfo.user_difficulty).toFixed(1)}
                        </div>
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "trip-preview-numbers-difficulty-count" :
                                    "trip-preview-numbers-difficulty-count"
                            }
                            className = {(this.props.colorMode === "day")? "lr4" : "r4"}
                        >
                            {difficultyCount}
                        </div>
                    </div>
                );
            }
            else {
                difficulty = (
                    <div className = "trip-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "trip-preview-numbers-difficulty-tbd-mobile" :
                                    "trip-preview-numbers-difficulty-tbd"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-red" : "font-cabin red"}
                        >
                            TBD
                        </div>
                    </div>
                );
            }

            /*
            // Completed users
            let completedUsers = null;
            let completedUsersTitle = null;
            if (this.state.tripInfo.completed_user_count > 0) {
                const completedUserProfilePicListProps = {
                    numProfilePics : this.numCompletedUserProfilePics,
                    usersInfo : this.state.tripInfo.completed_users_recent,
                    userCount : this.state.tripInfo.completed_user_count,
                    userTypeLabel : "Recently Visited",
                    userTypeLabelOn: false,
                    moreBlankOn: false,
                    classNamePrefix: "trip-preview",
                    classNameUserType: "completed-user",
                    index: 0
                };

                completedUsersTitle = (
                    <div className = "trip-preview-completed-users-title"
                        data-tip = "Test tooltip"
                        data-for = "trip-preview-completed-users-tooltip">
                        Recently Visited
                    </div>
                );

                completedUsers = (
                    <UserProfilePicList {...completedUserProfilePicListProps} />
                );
            }
            */


            /*
            ============================================================================================
                Like and Save Buttons
            ============================================================================================
            */

            let likeButton = null;
            let likeButtonTooltip = null;
            let saveButton = null;
            let saveButtonText = null;
            let saveButtonTooltip = null;
            let shareButton = null;
            let shareButtonTooltip = null;
            let moreButton = null;
            let moreButtonTooltip = null;

            if (loggedIn) {
                const likeButtonTooltipText = this.state.likedByMe? "Unlike" : "Like";
                const likeButtonTooltipID = "trip-like-button-tooltip";
                likeButtonTooltip = (window.touchOn)? null : (
                    <ReactTooltip 
                        id = {likeButtonTooltipID}
                        className = "tooltip-s2"
                        type = "dark"
                        place = "bottom" 
                        html={true} 
                    />
                );

                const likeButtonClickProps = {
                    setState: this.setState,
                    likedByMe: this.state.likedByMe,
                    userInfo: this.props.userInfo,
                    dotInfo: this.state.tripInfo,
                    storeNewLike: this.props.storeNewLike,
                    storeUser: this.props.storeUser
                };

                const likeButtonImage = this.state.likedByMe?
                    (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/like-on-red.png") :
                            getStaticPath("/images/common/like-on-red.png")
                    ) : (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/common/like-off-black.png") :
                            getStaticPath("/images/common/like-off-white.png")
                    );

                const likeButtonID = this.state.likedByMe?
                    "trip-like-button-on" : "trip-like-button-off";

                //console.log("this.state.tripInfo = ", this.state.tripInfo);
                likeButton = (isAuthor)? null : (
                    <div id = {likeButtonID}
                        className = "image-button-weak-s3"
                        style = {{ backgroundImage: likeButtonImage }}
                        onClick = {likeButtonClick.bind(this, likeButtonClickProps)}
                        data-tip = {likeButtonTooltipText}
                        data-for = {likeButtonTooltipID}
                    >
                    </div>
                );

                // Save button
                const saveButtonTooltipText = this.state.savedByMe? "Unbucket" : "Bucket This Dot";
                const saveButtonTooltipID = "trip-save-button-tooltip";
                saveButtonTooltip = (window.touchOn)? null : (
                    <ReactTooltip 
                        id = "trip-save-button-tooltip"
                        className = "tooltip-s2"
                        type = "dark"
                        place = "bottom" 
                        html = {true} 
                    />
                );

                const saveButtonClickProps = {
                    setState: this.setState,
                    savedByMe: this.state.savedByMe,
                    userInfo: this.props.userInfo,
                    dotInfo: this.state.tripInfo,
                    storeNewSave: this.props.storeNewSave,
                    storeUser: this.props.storeUser
                };

                const saveButtonImage = this.state.savedByMe? 
                    (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/common/bucket-on-black.png") :
                            getStaticPath("/images/common/bucket-on-white.png")
                    ) : (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/common/bucket-off-black.png") :
                            getStaticPath("/images/common/bucket-off-white.png")
                    );

                const saveButtonID = this.state.savedByMe?
                    "trip-save-button-on" : "trip-save-button-off";

                saveButton = (isAuthor || (!this.state.savedByMe && bucketedOut))? null : (
                    <div id = {saveButtonID}
                        className = "image-button-weak-s2"
                        style = {{ backgroundImage: saveButtonImage }}
                        onClick = {this.saveButtonClick.bind(this, saveButtonClickProps)}
                        data-tip = {saveButtonTooltipText}
                        data-for = {saveButtonTooltipID}
                    >
                    </div>
                );

                if (!isAuthor && (this.state.savedByMe || !bucketedOut)) {
                    saveButtonText = this.state.savedByMe? (
                        <div id = "trip-save-button-text"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        >
                            Bucketed
                        </div>
                    ) : null;
                }
            }


            /*
            ============================================================================================
                Like and Save Counts
            ============================================================================================
            */

            // Counts
            const likeText = (this.state.likeCount === 1)? "Like" : "Likes";
            const likeCount = (this.state.likeCount > 0)?
            (
                <div id = "trip-like-count"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    {formatNumbers(this.state.likeCount).toString()} {likeText}
                </div>
            ) : null;

            /*
            const saveText = (this.state.saveCount === 1)? "Save" : "Saves";
            const saveCount = (this.state.saveCount > 0)?
            (
                <div id = "trip-save-count"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    {formatNumbers(this.state.saveCount).toString()} {saveText}
                </div>
            ) : null;
            */


            /*
            ============================================================================================
                Share Button
            ============================================================================================
            */

            const shareButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/share-off-black.png") :
                getStaticPath("/images/common/share-off-white.png");

            const shareButtonTooltipText = "Share";
            const shareButtonTooltipID = "trip-share-button-tooltip";

            shareButtonTooltip = (window.touchOn)? null : (
                <ReactTooltip
                    id = {shareButtonTooltipID}
                    className = "tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html = {true}
                />
            );

            const shareButtonID = "trip-share-button";

            shareButton = (isAuthor)? null : (
                <div id = {shareButtonID}
                    className = "image-button-weak-s2"
                    onClick = {this.shareButtonClick}
                    style = {{ backgroundImage:  shareButtonImage }}
                    data-tip = {shareButtonTooltipText}
                    data-for = {shareButtonTooltipID}
                >
                </div>
            );


            /*
            ============================================================================================
                More Button
            ============================================================================================
            */

            const moreButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/more-black.png") :
                getStaticPath("/images/common/more-white.png");

            const moreButtonTooltipText = "Options";
            const moreButtonTooltipID = "trip-more-button-tooltip";

            moreButtonTooltip = (window.touchOn)? null : (
                <ReactTooltip 
                    id = {moreButtonTooltipID}
                    className = "tooltip-s2"
                    type = "dark"
                    place = "bottom" 
                    html = {true} 
                />
            );

            moreButton = (isAuthor)?
            (
                <div id = "trip-more-button"
                    className = "image-button-base"
                    onClick = {() => { this.moreButtonClick(isAuthor); }}
                    style = {{ backgroundImage:  moreButtonImage }}
                    data-tip = {moreButtonTooltipText}
                    data-for = {moreButtonTooltipID}
                >
                </div>
            ) : null;


            /*
            ============================================================================================
                Save Bucket
            ============================================================================================
            */

            const saveBucket = (loggedIn)?
            ( 
                <SaveBucket 
                    userInfo = {this.props.userInfo} 
                    setState = {this.setState}
                />
            ) : null;


            /*
            ============================================================================================
                Open Itineraries
            ============================================================================================
            */

            const openItineraryProps = (openItineraryOn)?
            {
                colorMode: this.props.colorMode,
                setState: this.setState,
                loadOpenItineraries: this.loadOpenItineraries,

                userInfo: this.props.userInfo,

                tripSlug: this.props.tripSlug,

                firstItineraryIndex: this.state.openItineraryFirstIndex,
                lastItineraryIndex: this.state.openItineraryLastIndex,

                openItinerariesInfo: this.state.openItinerariesInfo,
                openItineraryFetchedCount: this.state.openItineraryFetchedCount,
                openItineraryTotalCount: this.state.openItineraryTotalCount,
                openItineraryIndices: this.state.openItineraryIndices,

                numItineraries: this.numOpenItineraries,
                numProfilePics: this.numOpenItineraryUsers,

                cancelOpenItinerary: this.cancelOpenItinerary,
                joinOpenItinerary: this.joinOpenItinerary
            }: null;

            const openItineraries = (openItineraryOn)?
            (
                <div id = "open-itineraries"
                    style = {{ display: (openItineraryStacked)? "block" : "table-cell" }}
                >
                    <div id = "open-itineraries-title"
                        className = {(this.props.colorMode === "day")?
                            "k2" : "w2"
                        }
                    >
                        Upcoming Open Trips
                    </div>
                    <OpenItineraryList {...openItineraryProps} />
                </div>
            ): null;


            /*
            ============================================================================================
                Go Button
            ============================================================================================
            */

            // Go button
            const goButtonImage = (this.state.goReady)? 
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/go-light-blue.png") :
                        getStaticPath("/images/common/go-blue.png")
                ) : getStaticPath("/images/common/go-gray.png");

            const goCancelButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/cancel-black.png") : 
                getStaticPath("/images/common/cancel-white.png");

            const goButtonTooltipText = (this.state.goReady)?
                "Let's Go Already!" : "I am Sold!";

            const goButtonTooltipType = (this.state.goReady)?
                "info" : "dark";

            const goButtonText = (openItineraryOn)?
                "Plan Your Own" : "Plan a Trip";

            const goButtonID = (openItineraryOn)?
                "trip-go-button-groups" : "trip-go-button";

            const goButtonTextID = (!openItineraryOn || openItineraryStacked)?
                "trip-go-button-title" : "trip-go-button-title-groups";

            const goButtonContent = (this.state.goReady)? (
                <div id = {goButtonID}
                    className = {(this.props.colorMode === "day")? 
                        "image-button-strong-base" : 
                        "image-button-strong-base"}
                    style = {{ backgroundImage: goButtonImage }}
                    data-tip = {goButtonTooltipText}
                    data-for = "trip-go-button-tooltip"
                    onClick = {this.goButtonSecondClick}
                >
                </div>
            ):(
                <div id = {goButtonID}
                    className = {(this.props.colorMode === "day")? 
                        "image-button-strong-base" :
                        "image-button-strong-base"}
                    style = {{ backgroundImage: goButtonImage }}
                    data-tip = {goButtonTooltipText}
                    data-for = "trip-go-button-tooltip"
                    onClick = {this.goButtonFirstClick}
                >
                </div>
            );

            const goButton = (
                <div id = "trip-go-button-container">
                    <div id = {goButtonTextID}
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        {goButtonText}
                    </div>
                    {goButtonContent}
                </div>
            );


            /*
            ============================================================================================
                Calendar
            ============================================================================================
            */

            // Calendar icon
            const calendarIconImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/calendar-black.png") :
                getStaticPath("/images/common/calendar-white.png");

            // Calendar
            let calendar = null;
            if ((this.state.startMoment) && (this.state.endMoment)) {
                //console.log("Trip / render - this.state.startMoment = ", this.state.startMoment.format());
                //console.log("Trip / render - this.state.endMoment = ", this.state.endMoment.format());

                // calendar object
                calendar = (
                    <div id = "trip-calendar-container">
                        <div id = "trip-calendar-icon"
                            className = "image-contain"
                            data-tip = "Travel Date"
                            data-for = "trip-calendar-icon-tooltip"
                            style = {{ backgroundImage: calendarIconImage }}
                        >
                            {
                                (window.touchOn)? null :
                                (
                                    <ReactTooltip
                                        id = "trip-calendar-icon-tooltip"
                                        className = "tooltip-s2"
                                        type = "dark"
                                        place = "bottom"
                                    />
                                )
                            }
                        </div>
                        <div id = "trip-calendar"
                            data-tip = "Click to Change Date"
                            data-for = "trip-calendar-input-tooltip">
                            <DatePicker
                                dateFormat = "MMM D YYYY"
                                className = {(this.props.colorMode === "day")?
                                    "trip-calendar-input-day font-cabin" :
                                    "trip-calendar-input-night font-cabin"}
                                selected = {this.state.startMoment}
                                onChange = {this.updateDate}
                                readOnly = {true}
                            />
                            {
                                (window.touchOn)? null :
                                (
                                    <ReactTooltip
                                        id = "trip-calendar-input-tooltip"
                                        className = "tooltip-s2"
                                        type = "dark"
                                        place = "bottom"
                                    />
                                )
                            }
                        </div>
                    </div>
                );
                //console.log("Trip / render - calendar = ", calendar);                
            }
            else {
                calendar = null;
            }
  

            /*
            ============================================================================================
                DayInfo (weather / sun / moon)
            ============================================================================================
            */

            // Only when the sunTimes and moonPhase are ready ro render
            let dayInfo = null;
            if ((this.state.sunTimes) && (this.state.moonPhase)) {
                const dayInfoProps = {
                    colorMode: this.props.colorMode,
                    weatherOn: this.state.weatherOn,
                    weatherIcon: this.state.weatherIcon,
                    precipOn: this.state.precipOn,
                    precipType: this.state.precipType,
                    precipProb: this.state.precipProb,
                    tempHigh: this.state.tempHigh,
                    tempLow: this.state.tempLow,
                    sunTimes: this.state.sunTimes,
                    sunMoonOn: this.state.sunMoonOn,
                    moonPhase: this.state.moonPhase,
                    moonPhaseDigits: this.state.moonPhaseDigits,
                }
                dayInfo = DayInfo(dayInfoProps);
                //console.log("Trip / render - dayInfo = ", dayInfo);
            }
            else{
                dayInfo = null;
            }


            /*
            ============================================================================================
                Location Inputs
            ============================================================================================
            */

            // Set props for start and end location inputs
            const startInputProps = {
                type: "text",
                placeholder: this.state.tripInfo.trip_extension.start_location_name,
                onFocus: this.startInputOnFocus,
                onBlur: this.startInputOnBlur,
                autoComplete: "off"
            }

            const endInputProps = {
                type: "text",
                placeholder: this.state.tripInfo.trip_extension.end_location_name,
                onFocus: this.endInputOnFocus,
                onBlur: this.endInputOnBlur,
                autoComplete: "off"
            }

            // Location input
            const locationsImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/location-black.png") :
                getStaticPath("/images/common/location-white.png");

            const locationsIcon = (
                <div
                    data-tip = "Start and End Locations"
                    data-for = "trip-locations-icon-tooltip"
                    id = "trip-locations-icon"
                    className = "image-contain"
                    style = {{ backgroundImage: locationsImage }}
                >
                    {
                        (window.touchOn)? null :
                        (
                            <ReactTooltip 
                                id = "trip-locations-icon-tooltip" 
                                className = "tooltip-s2"
                                type = "dark" 
                                place = "right"
                            />
                        )
                    }
                </div>
            );


            /*
            ============================================================================================
                Time Slider
            ============================================================================================
            */

            let timeSlider;
            if ((this.state.sunTimes) && (this.state.startEndTimeValues)) {

                const sliderClassNames = (this.props.colorMode === "day")? 
                {
                    inputRange: "input-range",
                    disabledInputRange: "input-range input-range--disabled",

                    slider: "input-range-slider-day",
                    sliderContainer: "input-range__slider-container",

                    track: "input-range-track-day input-range__track--background",
                    activeTrack: "input-range-track-day input-range-track-active-day",

                    labelContainer: "input-range-label-container-day",
                    maxLabel: "input-range__label input-range__label--max",
                    minLabel: "input-range__label input-range__label--min",
                    valueLabel: "input-range__label input-range__label--value"
                } : {
                    inputRange: "input-range",
                    disabledInputRange: "input-range input-range--disabled",

                    slider: "input-range-slider-night",
                    sliderContainer: "input-range__slider-container",

                    track: "input-range-track-night input-range__track--background",
                    activeTrack: "input-range-track-night input-range-track-active-night",

                    labelContainer: "input-range-label-container-night",
                    maxLabel: "input-range__label input-range__label--max",
                    minLabel: "input-range__label input-range__label--min",
                    valueLabel: "input-range__label input-range__label--value"            
                };

                //console.log("this.state.startEndTimeValues = ", this.state.startEndTimeValues);
                // Convert solar and reference times to millisecs (since 1970/01/01)
                const sunrise = this.state.sunTimes.sunrise.unix();
                const sunset = this.state.sunTimes.sunset.unix();
                const solarNoon = this.state.sunTimes.solarNoon.unix();
                const hour00 = this.state.hour00Moment.unix();
                const hour24 = this.state.hour24Moment.unix();

                // Calculate the position of the solar markers on the slider
                const sunriseWidth = (sunrise - hour00) / (hour24 - hour00) * 100 + "%";
                const sunsetWidth = (sunset - hour00) / (hour24 - hour00) * 100 + "%";
                const solarNoonWidth = (solarNoon - hour00) / (hour24 - hour00) * 100 + "%";

                // Icon image
                const timeSliderImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/common/time-black.png") :
                    getStaticPath("/images/common/time-white.png");

                // Set up time slider
                timeSlider = (
                    <div id = "trip-time-slider-container">
                        <div id = "trip-time-slider-icon" 
                            className = "image-contain"
                            data-tip = "Trip Duration"
                            data-for = "trip-time-slider-icon-tooltip"
                            style = {{ backgroundImage: timeSliderImage }}
                        >
                            {
                                (window.touchOn)? null :
                                (
                                    <ReactTooltip
                                        id = "trip-time-slider-icon-tooltip"
                                        className = "tooltip-s2"
                                        type = "dark"
                                        place = "bottom"
                                    />
                                )
                            }
                        </div>
                        <div id = "trip-time-slider">
                            <div className = {(this.props.colorMode === "day")? 
                                    "trip-time-slider-dot-day" :
                                    "trip-time-slider-dot-night"}
                                style = {{ left: sunriseWidth }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "trip-time-slider-text-day" :
                                    "trip-time-slider-text-night"}
                                style = {{ left: sunriseWidth }}
                            >
                                {"Sunrise"}
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "trip-time-slider-dot-day" :
                                    "trip-time-slider-dot-night"}
                                style = {{ left: solarNoonWidth }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "trip-time-slider-text-day" :
                                    "trip-time-slider-text-night"}
                                style = {{ left: solarNoonWidth }}
                            >
                                {"Solar Noon"}
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "trip-time-slider-dot-day" :
                                    "trip-time-slider-dot-night"}
                                style = {{ left: sunsetWidth }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "trip-time-slider-text-day" :
                                    "trip-time-slider-text-night"}
                                style = {{ left: sunsetWidth }}
                            >
                                {"Sunset"}
                            </div>
                            <InputRange
                                classNames = {sliderClassNames}
                                maxValue = {this.state.hour24Moment.unix()}
                                minValue = {this.state.hour00Moment.unix()}
                                value = {this.state.startEndTimeValues}
                                onChange = {this.timeSliderOnChange}
                                onChangeComplete = {(values) => { this.timeSliderOnChangeComplete(values, false); }}
                                formatLabel = {this.timeSliderFormatLabel}
                                showEndLabels = {false}
                                step = {300}
                            />
                        </div>
                    </div>
                );
            }
            else {
                timeSlider = null;
            }


            /*
            ============================================================================================
                Map
            ============================================================================================
            */

            let map = null;

            if (this.state.savedByMe || !bucketedOut) {
                // Using Google maps
                if (this.mapSource === "google") {
                    // Only when google object and locations are ready
                    if ((this.props.google) && (this.state.tripInfo != null)) {
                        // Set the props for Map component
                        const mapProps = {
                            // Google
                            google: this.props.google,

                            // Map mode
                            mapMode: this.mapMode,

                            // DOM node IDs
                            mapNodeID: this.mapNodeID,
                            mapContainerNodeID : this.mapContainerNodeID,
                            buttonsNodeID: this.mapButtonsNodeID,
                            startInputNodeID: this.startInputNodeID,
                            endInputNodeID: this.endInputNodeID,

                            // Map height
                            mapHeight: this.mapHeight,
                            mapMinHeight: this.mapMinHeight,
                            mapMaxHeight: this.mapMaxHeight,
                            mapHeightIncrement: this.mapHeightIncrement,

                            // Dots
                            dotsInfo: this.state.tripInfo.trip_extension.children,

                            // Itinerary and selected settings
                            itinerary: this.state.highlightIndices,

                            // Selected / hovered / selectedChild / hoveredChild / displayChildren
                            selected: this.state.selected,
                            hovered: this.state.hovered,
                            selectedChild: this.state.selectedChild,
                            hoveredChild: this.state.hoveredChild,
                            displayChildren: null,

                            // Parking / start / end locations
                            parkingLocation: null,
                            startLocation: this.state.startLocation,
                            endLocation: this.state.endLocation,

                            // Action triggers 
                            mapZoomInAnimation: this.state.mapZoomInAnimation,
                            mapZoomOutAnimation: this.state.mapZoomOutAnimation,
                            mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
                            mapRefreshAnimation: this.state.mapRefreshAnimation,
                            resetAnimations: this.resetAnimations,

                            // Dot click and hover callback
                            dotClick: this.dotClick,
                            dotHoverOn: this.dotHoverOn,
                            dotHoverOff: this.dotHoverOff,
                            endHoverOn: this.endHoverOn,
                            endHoverOff: this.endHoverOff,

                            // Parse callbacks (modify top states)
                            setParkingLocation: null,
                            setStartLocation: this.setStartLocation,
                            setEndLocation: this.setEndLocation,

                            // Map type
                            mapType: (this.state.tripInfo.map_type === null)?
                                "hybrid" : this.state.tripInfo.map_type,

                            // SetState
                            setState: this.setState
                        }
                        //console.log("Trip / render - mapProps = ", mapProps);

                        // Get the Map component
                        map = (<GoogleMap {...mapProps}/>);
                    }
                }
                // Using Open Street Maps
                else if (this.mapSource === "open") {
                    if (this.state.tripInfo != null) {
                        const averageLocation = getAverageLocation(
                            this.state.highlightIndices,
                            this.state.tripInfo.trip_extension.children
                        );
     
                        // Set the props for Map component
                        const mapProps = {
                            // Google
                            google: this.props.google,

                            // Map mode
                            mapMode: this.mapMode,

                            // DOM node IDs
                            mapNodeID: this.mapNodeID,
                            startInputNodeID: this.startInputNodeID,
                            endInputNodeID: this.endInputNodeID,

                            // Map height
                            mapHeight: this.mapHeight,
                            mapMinHeight: this.mapMinHeight,
                            mapMaxHeight: this.mapMaxHeight,
                            mapHeightIncrement: this.mapHeightIncrement,

                            // Dots
                            dotsInfo: this.state.tripInfo.trip_extension.children,

                            // Itinerary
                            itinerary: this.state.highlightIndices,

                            // Selected / hovered / selectedChild / hoveredChild / displayChildren
                            selected: this.state.selected,
                            hovered: this.state.hovered,
                            selectedChild: this.state.selectedChild,
                            hoveredChild: this.state.hoveredChild,
                            displayChildren: null,

                            // Parking / start / end locations
                            parkingLocation: null,
                            startLocation: this.state.startLocation,
                            endLocation: this.state.endLocation,

                            // Dot click and hover callback
                            dotClick: this.dotClick,
                            //dotHoverOn: this.dotHoverOn,
                            //dotHoverOff: this.dotHoverOff,
                            //endHoverOn: this.endHoverOn,
                            //endHoverOff: this.endHoverOff,

                            // Parse callbacks (modify top states)
                            setParkingLocation: null,
                            setStartLocation: this.setStartLocation,
                            setEndLocation: this.setEndLocation,

                            // Map center / zoom / type
                            mapCenter: averageLocation,
                            mapZoom: this.mapZoom,
                            mapType: (this.state.tripInfo.map_type === null)?
                                "hybrid" : this.state.tripInfo.map_type,

                            // SetState
                            setState: this.setState
                        }
                        //console.log("Trip / render - mapProps = ", mapProps);

                        // Get the Map component
                        map = (<OpenMap {...mapProps}/>);
                    }
                }
            }


            /*
            ============================================================================================
                Trip Overview
            ============================================================================================
            */

            // Set props for TripOverview component
            const overviewProps = {
                // Highlights
                highlights: this.state.tripInfo.trip_extension.highlights,

                // Selected and hovered
                selected: this.state.selected,
                hovered: this.state.hovered,
                selectedChild: this.state.selectedChild,
                hoveredChild: this.state.hoveredChild,
                displayChildren: this.state.displayChildren,

                // Dots information
                dotsInfo: this.state.tripInfo.trip_extension.children,

                // User info
                userInfo: this.props.userInfo, 

                // Map source
                mapSource: this.mapSource,

                // Click and hover callbacks
                dotClick: this.dotClick,
                dotHoverOn: this.dotHoverOn, 
                dotHoverOff: this.dotHoverOff,

                // Set state callback
                setState: this.setState,

                // Redux fetch user info 
                storeUser: this.props.storeUser
            };


            /*
            ============================================================================================
                Effective browser width
            ============================================================================================
            */

            const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.marginWidth;


            /*
            ============================================================================================
                Overview Gallery
            ============================================================================================
            */

            let overviewGallery = null;
            let overviewGalleryDimensions = null;
            if (this.state.mediaOverview.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaOverview[this.state.overviewSelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaOverview[this.state.overviewSelectedMediaIndex].type === "video");

                // Gallery dimensions
                overviewGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo, 
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth, 
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: this.maxContentWidth
                });

                // Gallery props
                const overviewGalleryProps = Object.assign(
                    overviewGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "trip-overview",
                        classPrefix: "trip-overview",
                        selectedMediaIndex: this.state.overviewSelectedMediaIndex,
                        media: this.state.mediaOverview,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.overviewNextMediaClick,
                        prevMediaClick: this.overviewPrevMediaClick,
                        navDotClick: this.overviewNavDotClick,
                        index: null,
                        square: false,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.tripInfo,
                        tagMode: "save"                      
                    }
                );

                overviewGallery = (
                    <Gallery {...overviewGalleryProps} />
                )
            }


            /*
            ============================================================================================
                Todos Gallery
            ============================================================================================
            */

            let todosGallery = null;
            if (this.state.mediaTodos.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaTodos[this.state.todosSelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaTodos[this.state.todosSelectedMediaIndex].type === "video");

                // Gallery dimensions
                const todosGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo, 
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth, 
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: null
                });

                // Gallery props
                const todosGalleryProps = Object.assign(
                    todosGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "trip-todos",
                        classPrefix: "trip-todos",
                        selectedMediaIndex: this.state.todosSelectedMediaIndex,
                        media: this.state.mediaTodos,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.todosNextMediaClick,
                        prevMediaClick: this.todosPrevMediaClick,
                        navDotClick: this.todosNavDotClick,
                        index: null,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.tripInfo,
                        tagMode: "save" 
                    }
                );

                todosGallery = (
                    <Gallery {...todosGalleryProps} />
                )
            }


            /*
            ============================================================================================
                History Gallery
            ============================================================================================
            */

            let historyGallery = null;
            if (this.state.mediaHistory.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaHistory[this.state.historySelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaHistory[this.state.historySelectedMediaIndex].type === "video");

                // Gallery dimensions
                const historyGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo, 
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth, 
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: null
                });

                // Gallery props
                const historyGalleryProps = Object.assign(
                    historyGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "trip-history",
                        classPrefix: "trip-history",
                        selectedMediaIndex: this.state.historySelectedMediaIndex,
                        media: this.state.mediaHistory,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.historyNextMediaClick,
                        prevMediaClick: this.historyPrevMediaClick,
                        navDotClick: this.historyNavDotClick,
                        index: null,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.tripInfo,
                        tagMode: "save"
                    }
                );

                historyGallery = (
                    <Gallery {...historyGalleryProps} />
                )
            }


            /*
            ============================================================================================
                Stories Gallery
            ============================================================================================
            */

            let storiesGallery = null;
            if (this.state.mediaStories.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaStories[this.state.storiesSelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaStories[this.state.storiesSelectedMediaIndex].type === "video");

                // Gallery dimensions
                const storiesGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo, 
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth, 
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: null
                });

                // Gallery props
                const storiesGalleryProps = Object.assign(
                    storiesGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "trip-stories",
                        classPrefix: "trip-stories",
                        selectedMediaIndex: this.state.storiesSelectedMediaIndex,
                        media: this.state.mediaStories,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.storiesNextMediaClick,
                        prevMediaClick: this.storiesPrevMediaClick,
                        navDotClick: this.storiesNavDotClick,
                        index: null,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.tripInfo,
                        tagMode: "save"
                    }
                );

                storiesGallery = (
                    <Gallery {...storiesGalleryProps} />
                )
            }


            /*
            ============================================================================================
                Layout
            ============================================================================================
            */

            const narrowLayout = (this.props.browserWidthPixels < overviewGalleryDimensions.finalMediaWidth + this.editorColumnWidth + this.statsColumnWidth + 2 * this.marginWidth);


            /*
            ============================================================================================
                Editor
            ============================================================================================
            */

            const editorImage = (this.state.tripInfo.editor.profile_pic)?
                (
                    (this.state.tripInfo.editor.profile_pic.external_url === null)?
                        getMediaProperty(this.state.tripInfo.editor.profile_pic, "t", "url", true) :
                        url(this.state.tripInfo.editor.profile_pic.external_url)
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/no-profile-picture-day.png") :
                        getStaticPath("/images/common/no-profile-picture-night.png")
                );

            const chatButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/user-chat-black.png") :
                getStaticPath("/images/common/user-chat-white.png"); 

            const calendarButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/user-calendar-black.png") :
                getStaticPath("/images/common/user-calendar-white.png"); 

            const infoButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/user-info-black.png") :
                getStaticPath("/images/common/user-info-white.png");

            // Follow button / editor buttons
            let followButton = null;
            let editorButtons = null;

            if (loggedIn) {
                // Logged in user is same as the editor
                //console.log("token = ", localStorage.token);

                if (this.props.userInfo.id === this.state.tripInfo.editor.id) {
                    //console.log("Logged in user is the same as the editor");
                }
                // Logged in user is different than the editor
                else {
                    const editorFollowButtonID = this.state.followedByMe? 
                        "trip-preview-editor-follow-button-on" :
                        "trip-preview-editor-follow-button-off";

                    const editorFollowButtonText = this.state.followedByMe?
                        "Following" : "Follow";

                    const editorFollowButtonClick = this.state.followedByMe?
                        this.unfollowRequest : this.followRequest;

                    followButton = (
                        <div id = {editorFollowButtonID}
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}
                            onClick = {editorFollowButtonClick}
                        >
                            {editorFollowButtonText}
                        </div>
                    );


                    /*
                    ============================================================================================
                        Editor's Buttons
                    ============================================================================================
                    */

                    const editorInfoTriangleImage = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/user-info-triangle-day.png") : 
                        getStaticPath("/images/common/user-info-triangle-night.png"); 
                        
                    const editorChatButtonID = this.state.editorChatButtonOn? 
                        "trip-preview-editor-chat-button-on" :
                        "trip-preview-editor-chat-button-off";

                    const editorCalendarButtonID = this.state.editorCalendarButtonOn? 
                        "trip-preview-editor-calendar-button-on" :
                        "trip-preview-editor-calendar-button-off";

                    const editorInfoButtonID = this.state.editorInfoButtonOn? 
                        "trip-preview-editor-info-button-on" :
                        "trip-preview-editor-info-button-off";

                    editorButtons = (
                        <div id = {(narrowLayout)?
                            "trip-preview-editor-buttons-container-mobile" : 
                            "trip-preview-editor-buttons-container"}
                        >
                            <div id = "trip-preview-editor-info-triangle" 
                                style = {{ 
                                    display: this.state.editorInfoOn? "inline-block" : "none",
                                    backgroundImage: editorInfoTriangleImage
                                }}
                            >
                            </div>
                            <div id = "trip-preview-editor-buttons">
                                <div id = {editorChatButtonID}
                                    className = "image-button-weak-base"
                                    style = {{ backgroundImage: chatButtonImage }}
                                    data-tip = "Send a Message"
                                    data-for = "trip-preview-editor-chat-button-tooltip"
                                >
                                </div>
                                {
                                    (window.touchOn)? null :
                                    (
                                        <ReactTooltip
                                            id = "trip-preview-editor-chat-button-tooltip"
                                            className = "tooltip-s2"
                                            type = "dark"
                                            place = "bottom"
                                        />
                                    )
                                }
                                <div id = {editorCalendarButtonID}
                                    className = "image-button-weak-base"
                                    style = {{ backgroundImage: calendarButtonImage }}
                                    data-tip = "Book a Tour"
                                    data-for = "trip-preview-editor-calendar-button-tooltip"
                                >
                                </div>
                                {
                                    (window.touchOn)? null :
                                    (
                                        <ReactTooltip
                                            id = "trip-preview-editor-calendar-button-tooltip"
                                            className = "tooltip-s2"
                                            type = "dark"
                                            place = "bottom"
                                        />
                                    )
                                }
                                <div id = {editorInfoButtonID}
                                    className = "image-button-weak-base"
                                    style = {{ backgroundImage: infoButtonImage }}
                                    data-tip = "Curator Information"
                                    data-for = "trip-preview-editor-info-button-tooltip"
                                    onClick = {this.clickEditorInfo}
                                >
                                </div>
                                {
                                    (window.touchOn)? null :
                                    (
                                        <ReactTooltip
                                            id = "trip-preview-editor-info-button-tooltip"
                                            className = "tooltip-s2"
                                            type = "dark"
                                            place = "bottom"
                                        />
                                    )
                                }
                            </div>
                        </div>
                    );
                }
            }


            /*
            ============================================================================================
                Editor Information
            ============================================================================================
            */

            const editorInfo = (this.state.editorInfoOn)?
            (
                <div id = "trip-preview-editor-info"
                    className = {(this.props.colorMode === "day")? 
                        "trip-preview-editor-info-day" :
                        "trip-preview-editor-info-night"}
                >
                    <div id = "trip-preview-editor-info-published"
                        className = {(this.props.colorMode === "day")? 
                            "trip-preview-editor-info-published-day k4" : 
                            "trip-preview-editor-info-published-night w4"}
                    >
                        Published
                    </div>
                    <div id = "trip-preview-editor-info-published-dots">
                        <span className = {(this.props.colorMode === "day")?
                            "trip-preview-editor-info-published-number number-lb0" :
                            "trip-preview-editor-info-published-number number-b0"}
                        >
                            {this.state.tripInfo.editor.editing_dot_count}
                        </span>
                        <span className = {(this.props.colorMode === "day")?
                            "trip-preview-editor-info-published-title dg4" :
                            "trip-preview-editor-info-published-title g4"}
                        >
                            {" dots"}
                         </span>
                    </div>
                    <div id = "trip-preview-editor-info-published"
                        className = {(this.props.colorMode === "day")?
                            "trip-preview-editor-info-published-day k4" : 
                            "trip-preview-editor-info-published-night w4"}
                    >
                        Guided
                    </div>
                </div>
            ) : null;


            /*
            ============================================================================================
                Contributors and Helpers
            ============================================================================================
            */

            let staff = null;
            if (this.props.browserWidth > 4) {
                if ((this.state.tripInfo.contributor_count - 1) > 0) {
                    const contributorProfilePicListProps = {
                        colorMode: this.props.colorMode,
                        numProfilePics : this.numStaffProfilePics,
                        usersInfo : this.state.tripInfo.contributors_best,
                        userCount : this.state.tripInfo.contributor_count - 1,
                        userTypeLabel : "Contributor",
                        userTypeLabelOn: true,
                        moreBlankOn: false,
                        classNamePrefix: "trip-preview",
                        classNameUserType: "contributor",
                        index: 0
                    };

                    if (this.state.tripInfo.helper_count > 0 && !narrowLayout) {
                        const helperListProps = {
                            colorMode: this.props.colorMode,
                            numProfilePics : this.numStaffProfilePics,
                            usersInfo : this.state.tripInfo.helpers_best,
                            userCount : this.state.tripInfo.helper_count,
                            userTypeLabel : "Helper",
                            andOn: true,
                            classNamePrefix: "trip-preview",
                            classNameUserType: "helper",
                            index: 0
                        };

                        staff = (
                            <div id = "trip-preview-staff">
                                <UserProfilePicList {...contributorProfilePicListProps} />
                                <div id = "trip-preview-staff-spacer"></div>
                                <UserSimpleList {...helperListProps} />
                            </div>
                        );
                    }
                    else {
                        staff = (
                            <div id = "trip-preview-staff"> 
                                <UserProfilePicList {...contributorProfilePicListProps} />
                            </div>
                        );
                    }
                }
                else {
                    if (this.state.tripInfo.helper_count > 0) {
                        const helperProfilePicListProps = {
                            colorMode: this.props.colorMode,
                            numProfilePics : this.numStaffProfilePics,
                            usersInfo : this.state.tripInfo.helpers_best,
                            userCount : this.state.tripInfo.helper_count,
                            userTypeLabel : "Helper",
                            userTypeLabelOn: true,
                            moreBlankOn: false,
                            classNamePrefix: "trip-preview",
                            classNameUserType: "helper",
                            index: 0
                        };

                        staff = (
                            <div id = "trip-preview-staff"> 
                                <UserProfilePicList {...helperProfilePicListProps} />
                            </div>
                        );
                    }
                }
            }


            /*
            ============================================================================================
                Curation Layouts
            ============================================================================================
            */

            const overviewClassName = (this.state.overviewVariableHeight)?
            (
                (this.state.overviewExpanded)?
                    "trip-preview-overview-container-expanded" :
                    "trip-preview-overview-container-folded"
            ) : (
                "trip-preview-overview-container"
            );

            const overviewStyle = (this.state.overviewVariableHeight && !this.state.overviewExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}

            const todosClassName = (this.state.todosVariableHeight)?
            (
                (this.state.todosExpanded)?
                    "trip-preview-todos-container-expanded" :
                    "trip-preview-todos-container-folded"
            ) : (
                "trip-preview-todos-container"
            );

            const todosStyle = (this.state.todosVariableHeight && !this.state.todosExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}

            const historyClassName = (this.state.historyVariableHeight)?
            (
                (this.state.historyExpanded)?
                    "trip-preview-history-container-expanded" :
                    "trip-preview-history-container-folded"
            ) : (
                "trip-preview-history-container"
            );

            const historyStyle = (this.state.historyVariableHeight && !this.state.historyExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}

            const storiesClassName = (this.state.storiesVariableHeight)?
            (
                (this.state.storiesExpanded)?
                    "trip-preview-stories-container-expanded" :
                    "trip-preview-stories-container-folded"
            ) : (
                "trip-preview-stories-container"
            );

            const storiesStyle = (this.state.storiesVariableHeight && !this.state.storiesExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}


            /*
            ============================================================================================
                Curation Expand Buttons
            ============================================================================================
            */

            const overviewExpandButtonImage = (this.state.overviewExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const overviewExpandButton = (this.state.overviewVariableHeight)? (
                <div className = {(this.state.overviewExpanded)?
                        "trip-preview-overview-container-more image-button-base" : 
                        "trip-preview-overview-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: overviewExpandButtonImage
                    }}
                    onClick = {this.toggleOverviewExpanded}
                >
                </div>
            ) : null;

            const todosExpandButtonImage = (this.state.todosExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const todosExpandButton = (this.state.todosVariableHeight)? (
                <div className = {(this.state.todosExpanded)?
                        "trip-preview-todos-container-more image-button-base" : 
                        "trip-preview-todos-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: todosExpandButtonImage
                    }}
                    onClick = {this.toggleTodosExpanded}
                >
                </div>
            ) : null;


            const historyExpandButtonImage = (this.state.historyExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const historyExpandButton = (this.state.historyVariableHeight)? (
                <div className = {(this.state.historyExpanded)?
                        "trip-preview-history-container-more image-button-base" : 
                        "trip-preview-history-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: historyExpandButtonImage
                    }}
                    onClick = {this.toggleHistoryExpanded}
                >
                </div>
            ) : null;

            const storiesExpandButtonImage = (this.state.storiesExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const storiesExpandButton = (this.state.storiesVariableHeight)? (
                <div className = {(this.state.storiesExpanded)?
                        "trip-preview-stories-container-more image-button-base" : 
                        "trip-preview-stories-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: storiesExpandButtonImage
                    }}
                    onClick = {this.toggleStoriesExpanded}
                >
                </div>
            ) : null;


            /*
            ============================================================================================
                Curation
            ============================================================================================
            */
            // Title
            const titleContent = this.state.tripInfo.title == null?
                null : this.state.tripInfo.title.replace(/[*]/g, "");

            // Curation flags
            const overviewOn = (this.state.tripInfo.overview !== null);
            const todosOn = (this.state.tripInfo.dot_extension.todos !== null);
            const historyOn = (this.state.tripInfo.dot_extension.history !== null);
            const storiesOn = (this.state.tripInfo.dot_extension.stories !== null);

            const overviewContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.tripInfo,
                keyHeader: "trip-overview",
                type: "overview",
                classPrefix: "trip"
            }
            const todosContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.tripInfo,
                keyHeader: "trip-todos",
                type: "todos",
                classPrefix: "trip"
            }
            const historyContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.tripInfo,
                keyHeader: "trip-history",
                type: "history",
                classPrefix: "trip"
            }
            const storiesContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.tripInfo,
                keyHeader: "trip-stories",
                type: "stories",
                classPrefix: "trip"
            }
            
            const overviewContent = (overviewOn)?
                this.curationPartition(overviewContentProps) : null;

            const todosContent = (todosOn)?
                this.curationPartition(todosContentProps) : null;

            const historyContent = (historyOn)?
                this.curationPartition(historyContentProps) : null;

            const storiesContent = (storiesOn)?
                this.curationPartition(storiesContentProps) : null;

            let todos = null;
            if (!todosOn) {
                todos = (this.state.mediaTodos.length > 0)? (
                    <div className = "body-wide"
                        style = {{ paddingTop: this.gallerySpacing }}
                    >
                        {todosGallery}
                    </div>
                ) : null;
            }
            else {
                todos = (
                    <div className = "body-wide">
                        <div id = "trip-todos-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Todos
                        </div>
                        <div id = "trip-todos"
                            ref = {this.todosRef}
                            className = {todosClassName}
                            style = {todosStyle}
                        >
                            {todosContent}
                        </div>
                        {todosExpandButton}
                        {todosGallery}
                    </div>
                );
            }

            let history = null;
            if (!historyOn) {
                history = (this.state.mediaHistory.length > 0)? (
                    <div className = "body-wide"
                        style = {{ paddingTop: this.gallerySpacing }}
                    >
                        {historyGallery}
                    </div>
                ) : null;
            }
            else {
                history = (
                    <div className = "body-wide">
                        <div id = "trip-history-title" 
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            History
                        </div>
                        <div id = "trip-history"
                            ref = {this.historyRef}
                            className = {historyClassName}
                            style = {historyStyle}
                        >
                            {historyContent}
                        </div>
                        {historyExpandButton}
                        {historyGallery}
                    </div>
                );
            }

            let stories = null;
            if (!storiesOn) {
                stories = (this.state.mediaStories.length > 0)? (
                    <div className = "body-wide"
                        style = {{ paddingTop: this.gallerySpacing }}
                    >
                        {storiesGallery}
                    </div>
                ) : null;
            }
            else {
                stories = (
                    <div className = "body-wide">
                        <div id = "trip-stories-title" 
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Stories
                        </div>
                        <div id = "trip-stories"
                            ref = {this.storiesRef}
                            className = {storiesClassName}
                            style = {storiesStyle}
                        >
                            {storiesContent}
                        </div>
                        {storiesExpandButton}
                        {storiesGallery}
                    </div>
                );
            }


            /*
            ============================================================================================
                Bottle
            ============================================================================================
            */
            const bottleProps = {
                colorMode: this.props.colorMode,
                dotID: this.state.tripInfo.id,
                usersInfo: this.state.tripInfo.noted_users_recent,
                usersPublic: this.state.tripInfo.noted_users_recent_public,
                userCount: this.state.tripInfo.noted_user_count,
                minBottleSize: this.minBottleSize,
                maxBottleSize: this.maxBottleSize,
                classNamePrefix: "trip"
            };

            const bottle = (this.state.tripInfo.noted_user_count > 0)? (
                <BottleProfilePicList {...bottleProps} />
            ) : null;


            /*
            ============================================================================================
                Board
            ============================================================================================
            */

            const board = (this.state.tripInfo !== null && this.state.commentsInfo !== null)?
            (
                <div id = "trip-board-container">
                    {
                        ((this.state.tripInfo.board.comment_count > 0) || (this.props.userInfo !== null))?
                        (
                            <div id = "trip-board-title"
                                className = {(this.props.colorMode === "day")? "k2" : "w2"}
                            >
                                Comments
                            </div>
                        ) : null
                    }
                    <Board
                        key = {"trip-" + this.state.tripInfo.id + "-board-" + this.state.tripInfo.board.id}
                        typePrefix = {"trip"}
                        boardID = {this.state.tripInfo.board.id}
                        userInfo = {this.props.userInfo}
                        loggedIn = {loggedIn}
                        commentCount = {this.state.tripInfo.board.comment_count}
                        commentsInfo = {this.state.commentsInfo}
                        webSocketGroupPrefix = {this.webSocketGroupPrefix}
                    />
                </div>
            ) : null;


            /*
            ============================================================================================
                Loader image
            ============================================================================================
            */

            // Loader image
            const loaderImage = (this.props.colorMode === "day")?
                getStaticPath("/images/loader/loader-white.gif") :
                getStaticPath("/images/loader/loader-black.gif");


            /*
            ============================================================================================
                Editor column
            ============================================================================================
            */

            const editorColumn = (narrowLayout)? 
            (
                <div id = "trip-preview-left-mobile">
                    {editorInfo}
                    <div className = "trip-preview-left-mobile-column">
                        <Link key = {"trip-editor-profile-pic"}
                            to = {`/user/${this.state.tripInfo.editor.id}`}
                        >
                            <div id = {(this.props.browserWidth <= 4)?
                                    "trip-preview-editor-profile-pic-loader-small" :
                                    "trip-preview-editor-profile-pic-loader-medium"}
                                className = "image-loader-s4"
                                style = {{ backgroundImage: loaderImage }}
                            >
                                <div id = "trip-preview-editor-profile-pic"
                                    className = {(this.props.colorMode === "day")? 
                                        "profile-image-base border-day" : 
                                        "profile-image-base border-night"}
                                    style = {{ backgroundImage: editorImage }}>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className = "trip-preview-left-mobile-column">
                        <div id = "trip-preview-editor-name"
                            className = {
                                (this.props.browserWidth <= 4)?
                                    (
                                        (this.props.colorMode === "day")? "k5" : "w5"
                                    ) : (
                                        (this.props.colorMode === "day")? "k4" : "w4"
                                    )
                            }
                        >
                            {
                                ((this.state.tripInfo.editor.name.length > this.nameMobileMaxLength) &&
                                (this.props.browserWidth <= 4))?
                                    this.state.tripInfo.editor.first_name :
                                    this.state.tripInfo.editor.name
                            }
                        </div>
                        <div id = "trip-preview-editor-follow-button-container">
                            {followButton}
                        </div>
                        {editorButtons}
                    </div>
                    <div className = "trip-preview-left-mobile-column">
                        {staff}
                    </div>
                </div>
            ) : (
                <div id = "trip-preview-left">
                    {editorInfo}
                    <div className = "trip-preview-editor">
                        <div id = "trip-preview-editor-title"
                            className = {(this.props.colorMode === "day")? "k3" : "w3"}
                        >
                            Editor
                        </div>
                        <Link key = {"trip-editor-profile-pic"}
                            to = {`/user/${this.state.tripInfo.editor.id}`}
                        >
                            <div id = "trip-preview-editor-profile-pic-loader"
                                className = "image-loader-s4"
                                style = {{ backgroundImage: loaderImage }}
                            >
                                <div id = "trip-preview-editor-profile-pic"
                                    className = {(this.props.colorMode === "day")? 
                                        "profile-image-base border-day" : 
                                        "profile-image-base border-night"}
                                    style = {{ backgroundImage:  editorImage }}>
                                </div>
                            </div>
                        </Link>
                        <div id = "trip-preview-editor-name"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        >
                            {this.state.tripInfo.editor.name}
                        </div>
                        <div id = "trip-preview-editor-follow-button-container">
                            {followButton}
                        </div>
                        {editorButtons}
                    </div>
                    {staff}
                </div>
            );


            /*
            ============================================================================================
                Bucketed out
            ============================================================================================
            */

            let bucketedOutSign = null;

            if (!this.state.savedByMe && bucketedOut) {
                const bucketedOutImage = (smallLayout)?
                    (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/saved-out-day.png") :
                            getStaticPath("/images/common/saved-out-night.png")
                    ) : (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/bucketed-out-day.png") :
                            getStaticPath("/images/common/bucketed-out-night.png")
                    );

                bucketedOutSign = (
                    <div className = {(smallLayout)?
                            "trip-bucketed-out-small image-contain" :
                            "trip-bucketed-out image-contain"}
                        style = {{ backgroundImage: bucketedOutImage }}
                    >
                    </div>
                );
            }


            /*
            ============================================================================================
                Stats column
            ============================================================================================
            */

            const statsColumn = (narrowLayout)? 
            (
                <div id = "trip-preview-right-mobile">
                    <div className = "trip-preview-right-mobile-column">
                        <div className = {
                            (this.props.browserWidth <= 4)?
                                (
                                    (this.props.colorMode === "day")? 
                                        "trip-preview-numbers-title k4" :
                                        "trip-preview-numbers-title w4"
                                ) : (
                                    (this.props.colorMode === "day")? 
                                        "trip-preview-numbers-title k3" :
                                        "trip-preview-numbers-title w3"

                                )
                            }
                            data-tip = "Rating"
                            data-for = "trip-preview-numbers-rating-tooltip"
                        >
                            {ratingTitle}
                        </div>
                        {rating}
                    </div>
                    <div className = "trip-preview-right-mobile-column">
                        <div className = {
                            (this.props.browserWidth <= 4)?
                                (
                                    (this.props.colorMode === "day")? 
                                        "trip-preview-numbers-title k4" :
                                        "trip-preview-numbers-title w4"
                                ) : (
                                    (this.props.colorMode === "day")? 
                                        "trip-preview-numbers-title k3" :
                                        "trip-preview-numbers-title w3"

                                )
                            }
                            data-tip = "Difficulty"
                            data-for = "trip-preview-numbers-difficulty-tooltip"
                        >
                            {difficultyTitle}
                        </div>
                        {difficulty}
                    </div>
                </div>
            ) : (
                <div id = "trip-preview-right">
                    <div className = {(this.props.colorMode === "day")? 
                            "trip-preview-numbers-title k3" :
                            "trip-preview-numbers-title w3"}
                        data-tip = "Rating"
                        data-for = "trip-preview-numbers-rating-tooltip"
                    >
                        {ratingTitle}
                    </div>
                    {rating}
                    <div className = {(this.props.colorMode === "day")? 
                            "trip-preview-numbers-title k3" :
                            "trip-preview-numbers-title w3"}
                        data-tip = "Difficulty"
                        data-for = "trip-preview-numbers-difficulty-tooltip"
                    >
                        {difficultyTitle}
                    </div>
                    {difficulty}
                </div>
            );


            /*
            ============================================================================================
                Interaction Box
            ============================================================================================
            */

            const interactionBox = (likeCount || likeButton || shareButton || saveButton || bucketedOutSign)? (
                <div id = "trip-interaction-box" className = "clear-fix">
                    <div id = "trip-like-share-container">
                        {likeCount}
                        {likeButton}
                        {likeButtonTooltip}
                        {shareButton}
                        {shareButtonTooltip}
                    </div>
                    <div id = "trip-save-container">
                        {saveButtonText}
                        {saveButton}
                        {saveButtonTooltip}
                        {bucketedOutSign}
                    </div>
                </div>
            ) : null;


            /*
            ============================================================================================
                Body
            ============================================================================================
            */

            return (
                <div id = "trip-container"
                    className = {(this.props.browserWidth <= window.browserWidthSmall)? 
                        "trip-container-small" : "trip-container"}
                    style = {{ marginBottom: (!openItineraryOn)?
                        this.marginBottom : (
                            (openItineraryStacked)?
                            this.marginBottomOpenItinerariesStacked : this.marginBottomOpenItineraries
                        )
                    }}
                >
                    <MetaTags>
                        <title>{this.state.tripInfo.title}</title>
                        <meta name="title" content={this.state.tripInfo.title} />
                        <meta name="description" content={this.state.tripInfo.overview} />
                        <meta property="fb:app_id" content="1387370438053401" />
                        <meta property="og:title" content={this.state.tripInfo.title} />
                        <meta property="og:type" content="place" />
                        <meta property="og:description" content={this.state.tripInfo.overview} />
                        <meta property="og:image" content={getMediaProperty(this.state.tripInfo.media[0], 'xs', 'url', true)} />
                        <meta property="og:image:width" content={getMediaProperty(this.state.tripInfo.media[0], 'xs', 'width', false)} />
                        <meta property="og:image:height" content={getMediaProperty(this.state.tripInfo.media[0], 'xs', 'height', false)} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="twitter:title" content={this.state.tripInfo.title} />
                        <meta property="twitter:description" content={this.state.tripInfo.overview} />
                        <meta property="twitter:image:src" content={getMediaProperty(this.state.tripInfo.media[0], 'xs', 'url', true)} />
                    </MetaTags>
                    <div className = "body-wide" style = {{ overflow: "visible" }}>
                        {saveBucket}

                        <div id = "trip-preview">

                            {(narrowLayout)? null : editorColumn}

                            <div id = "trip-preview-middle"
                                style = {{ width: overviewGalleryDimensions.finalMediaWidth }}
                            >
                                <div id = "trip-preview-intro">
                                    <div id = "trip-preview-title-container">
                                        <div id = "trip-preview-title"
                                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                                        >
                                            {titleContent}
                                        </div>

                                        <div id ="trip-more-cotainer">
                                            {moreButton}
                                            {moreButtonTooltip}
                                        </div>

                                        <div id = "trip-preview-name"
                                            className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                                        >
                                            {this.state.tripInfo.name}
                                        </div> 
                                    </div>
                                    <div id = "trip-preview-overview"
                                        ref = {this.overviewRef}
                                        className = {overviewClassName}
                                        style = {overviewStyle}
                                    >
                                        {overviewContent}
                                    </div>
                                    {overviewExpandButton}
                                </div>

                                {interactionBox}
                                {bottle}
                                {overviewGallery}
                            </div>
                            {
                                (narrowLayout)? (
                                    <div id = "trip-preview-narrow">
                                        {editorColumn}
                                        {statsColumn}
                                    </div>
                                    ) : statsColumn
                            }
                        </div>
                    </div>
                    <div id = "trip-go-container"
                        className = {(this.props.colorMode === "day")? 
                            "trip-go-container-day" :
                            "trip-go-container-night"}
                    >
                        <div id = "trip-go-info-container"
                            style = {{ display: this.state.goInfoOn? "block" : "none" }}
                        >
                            <div id = "trip-go-info"
                            >
                                <div id = "trip-go-cancel-button"
                                    className = {(this.props.colorMode === "day")? 
                                        "image-button-weaker-s3" :
                                        "image-button-weaker-s3"}
                                    style = {{ backgroundImage : goCancelButtonImage }}
                                    onClick = {this.goCancelButtonClick}
                                >
                                </div>
                                <div id = "trip-calendar-dayinfo-container">
                                    <div id = "trip-calendar-dayinfo">
                                        <div id = "trip-calendar-column">
                                            <div id = "trip-calendar-title"
                                                className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                            >
                                                Travel Date
                                            </div>
                                            {calendar}
                                        </div>
                                        <div id = "trip-dayinfo-column">
                                            <div id = "trip-dayinfo">
                                                <div id = "trip-dayinfo-title"
                                                    className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                                >
                                                    Weather and Astronomy
                                                </div>
                                                {dayInfo}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id = "trip-time">
                                    <div id = "trip-time-slider-title"
                                        className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                    >
                                        Trip Duration
                                    </div>
                                    {timeSlider}
                                </div>

                                <div id = "trip-locations-container"
                                    style = {{
                                        display: (this.state.tripInfo.type === "TR")? "block" : "none"
                                    }}
                                >
                                    <div id = "trip-locations-title"
                                        className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                    >
                                        Start and End Locations
                                    </div>
                                    <div id = "trip-locations">
                                        {locationsIcon}
                                        <div id = {
                                            (this.props.browserWidth <= 5)?
                                                "trip-locations-inputs-mobile" : "trip-locations-inputs"}
                                        >
                                            <input {...startInputProps}
                                                id = {this.startInputNodeID}
                                                className = {(this.props.colorMode === "day")?
                                                    "input-s3 input-day" : "input-s3 input-night"}
                                                ref = {(node) => {this.startInputNode = node}}
                                                data-tip = "Start Location"
                                                data-for = {this.startInputNodeID + "-tooltip"}
                                            />
                                            <input {...endInputProps}
                                                id = {this.endInputNodeID}
                                                className = {(this.props.colorMode === "day")?
                                                    "input-s3 input-day" : "input-s3 input-night"}
                                                ref = {(node) => {this.endInputNode = node}}
                                                data-tip = "End Location"
                                                data-for = {this.endInputNodeID + "-tooltip"}
                                            />
                                        </div>
                                    </div>

                                    {
                                        (window.touchOn)? null : (
                                            <ReactTooltip
                                                id = {this.startInputNodeID + "-tooltip"}
                                                className = "tooltip-s2"
                                                type = "dark"
                                                place = "bottom"
                                            />
                                        )
                                    }
                                    {
                                        (window.touchOn)? null : (
                                            <ReactTooltip
                                                id = {this.endInputNodeID + "-tooltip"}
                                                className = "tooltip-s2"
                                                type = "dark"
                                                place = "bottom"
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                        {
                            (!this.state.savedByMe && bucketedOut)?
                                null : (
                                    <div id = "trip-go-button-container"
                                        style = {{ display: (openItineraryStacked)? "block" : "table" }}
                                    >
                                        {openItineraries}
                                        <div id = "trip-go"
                                            style = {{ display: (openItineraryStacked)? "block" : "table-cell" }}
                                        >
                                            {goButton}
                                        </div>
                                    </div>
                                )
                        }

                        {
                            (window.touchOn)? null : (
                                <ReactTooltip
                                    id = "trip-go-button-tooltip"
                                    className = "tooltip-s2"
                                    type = {goButtonTooltipType}
                                    place = "bottom"
                                />
                            )
                        }
                    </div>

                    {todos}
                    {history}
                    {stories}

                    {
                        (!this.state.savedByMe && bucketedOut)? null : (
                            <div className="body-narrow">
                                <div id = "trip-map-container">
                                    <div id = "trip-map-title"
                                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                                    >
                                        Map
                                    </div>
                                    <div id = "trip-map-wrapper"
                                        className = {(this.props.colorMode === "day")?
                                            "border-day" : "border-night"}
                                    >
                                        {map}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className = "body-narrow">
                        <div id = "trip-overview-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Highlights
                        </div>
                        <TripOverview {...overviewProps} />
                    </div>

                    <div className = "body-narrow">
                        {board}
                    </div>
                </div>
            )
        }
        else{
            return null
        }
    }


    updateWindowDimensions() {
        this.setState(
            { 
                windowWidth: window.innerWidth
            },
            this.resetOpenItineraryIndices
        );
    }

    componentWillUnmount() {
        // Remove event listeners
        window.removeEventListener("resize", this.updateWindowDimensions);
        window.removeEventListener("scroll", this.scrollListener);

        // Remove relevant webSocket group
        removeWebSocketGroups(this.state.webSocketGroup);
    }

    componentDidMount() {
        //console.log("Trip / componentDidMount - tripSlug = ", this.props.tripSlug);

        // Get trip info from the server
        this.loadTripInfo(this.props.tripSlug);

        // Get open trips info from the server
        this.loadOpenItineraries(this.props.tripSlug, null);

        // Update window dimensions
        this.updateWindowDimensions();

        // Add event listeners
        window.addEventListener("resize", this.updateWindowDimensions);
        window.addEventListener("scroll", this.scrollListener);
    }


    componentDidUpdate(prevProps, prevState) {
        //if ((this.props.tripSlug !== prevProps.tripSlug) || (JSON.stringify(this.props.userInfo) !== JSON.stringify(prevProps.userInfo))) {
        //console.log("Trip / componentDidUpdate - update required");

        // If need an update
        if (this.props.tripSlug !== prevProps.tripSlug) {
            // Remove relevant webSocket group
            removeWebSocketGroups(this.state.webSocketGroup);

            // Load new trip
            this.loadTripInfo(this.props.tripSlug);
        }

        // Update curation style when window resized
        if ((this.props.browserWidthPixels !== prevProps.browserWidthPixels) && (this.state.tripInfo !== null)) {
            this.updateCurationStyle(this.state.tripInfo, this.updateCurationLayout);
        }

        // Update save
        if ((this.props.newSave.id !== prevProps.newSave.id) ||
            ((this.props.newSave.id === prevProps.newSave.id) && (this.props.newSave.saved !== prevProps.newSave.saved))) {
            if (this.state.tripInfo.id === this.props.newSave.id) {
                this.setState({
                    savedByMe: this.props.newSave.saved,
                    saveCount: this.props.newSave.count
                });
            }
        }
    }


    /*
    ============================================================================================
        Update curation layout
    ============================================================================================
    */

    updateCurationLayout() {
        // Curation
        if (this.overviewRef.current !== null) {
            const overviewBoundingBox = this.overviewRef.current.getBoundingClientRect();
            //console.log("Dot / updateCurationLayout - overviewBoundingBox.height = ", overviewBoundingBox.height);
            //console.log("Dot / updateCurationLayout - this.curationHeight = ", this.curationHeight);

            if (overviewBoundingBox.height > this.curationHeight) {
                this.setState({
                    overviewVariableHeight: true
                });
            }
        }

        if (this.todosRef.current !== null) {
            const todosBoundingBox = this.todosRef.current.getBoundingClientRect();

            if (todosBoundingBox.height > this.curationHeight) {
                this.setState({
                    todosVariableHeight: true
                });
            }
        }

        if (this.historyRef.current !== null) {
            const historyBoundingBox = this.historyRef.current.getBoundingClientRect();

            if (historyBoundingBox.height > this.curationHeight) {
                this.setState({
                    historyVariableHeight: true
                });
            }
        }

        if (this.storiesRef.current !== null) {
            const storiesBoundingBox = this.storiesRef.current.getBoundingClientRect();

            if (storiesBoundingBox.height > this.curationHeight) {
                this.setState({
                    storiesVariableHeight: true
                });
            }
        }        
    }


    /*
    ============================================================================================
        Toggle curation
    ============================================================================================
    */

    toggleOverviewExpanded() {
        this.setState({
            overviewExpanded: !this.state.overviewExpanded
        });
    }

    toggleTodosExpanded() {
        this.setState({
            todosExpanded: !this.state.todosExpanded
        });
    }

    toggleHistoryExpanded() {
        this.setState({
            historyExpanded: !this.state.historyExpanded
        });
    }

    toggleStoriesExpanded() {
        this.setState({
            storiesExpanded: !this.state.storiesExpanded
        });
    }


    /*
    ============================================================================================
        Share and More Button Click Functions
    ============================================================================================
    */

    shareButtonClick() {
        this.props.storeShare({
            modalOn: true,
            type: "trip",
            info: this.state.tripInfo
        });
    }

    moreButtonClick(isAuthor) {
        this.props.storeMore({
            modalOn: true,
            type: "trip",
            info: this.state.tripInfo,
            isAuthor: isAuthor
        });
    }


    /* 
    ============================================================================================
        Overview Gallery Navigation Arrow Click
    ============================================================================================
    */

    overviewNextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                overviewSelectedMediaIndex: (this.state.overviewSelectedMediaIndex + 1),
            }
        );
    }

    overviewPrevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                overviewSelectedMediaIndex: (this.state.overviewSelectedMediaIndex - 1),
            }
        );
    }

    overviewNavDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            overviewSelectedMediaIndex: mediaIndex,
        });
    }


    /* 
    ============================================================================================
        Go Cancel Button Click
    ============================================================================================
    */

    goCancelButtonClick() {
        //console.log("Trip / goButtonCancelClick");
        //console.log("Trip / goButtonCancelClick - this.state = ", this.state);

        this.setState(
            {
                goReady: false,
                goInfoOn: false
            }, 
            () => {
                this.props.storeWarningAlert({
                    message: null,
                    on: false
                });
            }
        );
    }


    /* 
    ============================================================================================
        Go Button Click
    ============================================================================================
    */

    goButtonFirstClick() {
        //console.log("Trip / goButtonFirstClick");
        //console.log("Trip / goButtonFirstClick - this.state = ", this.state);

        this.setState(
            {
                goReady: true,
                goInfoOn: true
            },
            this.showItineraryTimeWarning.bind(this, false)
        );
    }


    /* 
    ============================================================================================
        Go Button Second Click
    ============================================================================================
    */

    goButtonSecondClick() {
        //console.log("Trip / goButtonSecondClick");
        /*
        console.log("Trip / goButtonSecondClick - startMoment = ", this.state.startMoment);
        console.log("Trip / goButtonSecondClick - endMoment = ", this.state.endMoment);
        console.log("Trip / goButtonSecondClick - sunTimes = ", this.state.sunTimes);
        console.log("Trip / goButtonSecondClick - moonTimes = ", this.state.moonTimes);
        console.log("Trip / goButtonSecondClick - dayTime = ", this.state.dayTime);
        console.log("Trip / goButtonSecondClick - offsetTime = ", this.state.offsetTime);
        console.log("Trip / goButtonSecondClick - totalTime = ", this.state.totalTime);
        console.log("Trip / goButtonSecondClick - startEndTimeValues = ", this.state.startEndTimeValues);
        console.log("Trip / goButtonSecondClick - hour00Moment = ", this.state.hour00Moment);
        console.log("Trip / goButtonSecondClick - hour24Moment = ", this.state.hour24Moment);
        */
        
        // Convert moments
        const startMoment = this.state.startMoment.format();
        const endMoment = this.state.endMoment.format();
        const sunTimes = {
            sunrise: (this.state.sunTimes.sunrise === null)? null : this.state.sunTimes.sunrise.format(),
            sunset: (this.state.sunTimes.sunset === null)? null : this.state.sunTimes.sunset.format(),
            solarNoon: (this.state.sunTimes.solarNoon === null)? null : this.state.sunTimes.solarNoon.format()
        };
        const moonTimes = {
            moonrise: (this.state.moonTimes.moonrise === null)? null: this.state.moonTimes.moonrise.format(),
            moonset: (this.state.moonTimes.moonset === null)? null : this.state.moonTimes.moonset.format()
        };
        const dayTime = this.state.dayTime;
        const offsetTime = this.state.offsetTime;
        const totalTime = this.state.totalTime;
        const startEndTimeValues = this.state.startEndTimeValues;
        const hour00Moment = this.state.hour00Moment.format();
        const hour24Moment = this.state.hour24Moment.format();

        // States to be passed to plan page
        const planStates = {
            // Trip info
            tripInfo: this.state.tripInfo,

            // ItineraryInfo
            itineraryInfo: null,

            // Locations
            startLocation: this.state.startLocation,
            endLocation: this.state.endLocation,            
            startLocationName: this.state.startLocationName,
            endLocationName: this.state.endLocationName,            
            location: this.state.location,
            startTimezone: this.state.startTimezone,
            endTimezone: this.state.endTimezone,
            timezone: this.state.timezone,

            // Start and end times
            startMoment: startMoment,
            endMoment: endMoment,

            // Day times
            sunTimes: sunTimes,
            moonTimes: moonTimes,
            dayTime: dayTime,
            offsetTime: offsetTime,
            totalTime: totalTime,
            startEndTimeValues: startEndTimeValues,
            hour00Moment: hour00Moment,
            hour24Moment: hour24Moment,

            // Astronomy
            sunMoonOn: this.state.sunMoonOn,
            moonPhase: this.state.moonPhase,
            moonPhaseDigits: this.state.moonPhaseDigits,

            // Weather
            weather: this.state.weather,
            weatherOn: this.state.weatherOn,
            weatherIcon: this.state.weatherIcon,
            tempHigh: this.state.tempHigh,
            tempLow: this.state.tempLow,
            precipOn: this.state.precipOn,
            precipType: this.state.precipType,
            precipProb: this.state.precipProb
        };

        // Clear alert
        this.props.storeWarningAlert({
            message: null,
            on: false
        });

        // Redirect to plan page
        this.props.history.push(
            {
                pathname: "/plan/" + this.state.tripInfo.id,
                state: planStates
            }
        );
    }


    /*
    ============================================================================================
        Load Trip Info
    ============================================================================================
    */

    loadTripInfo (tripSlug) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Trip / loadTripInfo - response.data.content = ", response.data.content);

            // Logged in
            const loggedIn = (!!localStorage.token && (this.props.userInfo !== null));

            // Construct dot index list from highlights (order preserved)
            const highlightIndices = dotIDsToIndices(
                response.data.content.trip_extension.highlights,
                response.data.content.trip_extension.children
            );

            // Check if the logged in user follows the editor
            const followedByMe = loggedIn? response.data.content.editor.followed_by_me : null;

            // Check if the the trip is liked or saved by the user
            const likedByMe = loggedIn? response.data.content.liked_by_me : null;
            const savedByMe = loggedIn? response.data.content.saved_by_me : null;

            // Get the like and save counts
            const likeCount = response.data.content.liked_user_count;
            const saveCount = response.data.content.saved_user_count;

            // Sort media
            const mediaSorted = sortMedia(response.data.content);

            // Callback after retrieving the trip info
            const callback = () => {
                //console.log("Trip / loadTripInfo - this.state = ", this.state);

                // Update curation style
                this.updateCurationStyle(response.data.content, this.updateCurationLayout);

                // Initialize Time object
                initTime(response.data.content.dot_extension.time_value);

                // Update day times / get weather
                this.updateDayInfo(
                    true,
                    true,
                    null
                );
            };

            // Fetch board comments
            this.loadCommentsInfo(response.data.content.board.id);

            // Add relevant webSocket group
            let webSocketGroup = [];
            webSocketGroup.push(this.webSocketGroupPrefix + "_" + response.data.content.board.id);
            addWebSocketGroups(webSocketGroup);

            // Set states and check if the logged in user follows the editor
            this.setState(
                {
                    // Render Itinerary component
                    renderItinerary: false,
                    
                    // Trip information
                    tripInfo: response.data.content,

                    // Locations and timezones
                    location: response.data.content.location,
                    timezone: response.data.content.timezone,
                    startLocation: response.data.content.trip_extension.start_location,
                    startLocationName: response.data.content.trip_extension.start_location_name,
                    startTimezone: response.data.content.trip_extension.start_timezone,
                    endLocation: response.data.content.trip_extension.end_location,
                    endLocationName: response.data.content.trip_extension.end_location_name,
                    endTimezone: response.data.content.trip_extension.end_timezone,

                    // Highlight dots (indices of highlights in the dotsInfo list)
                    highlightIndices: highlightIndices,

                    // Selected / hovered / selectedChild / hoveredChild
                    selected: null,
                    hovered: null,
                    selectedChild: null,
                    hoveredChild: null,
                    displayChildren: null,

                    // Media galleries
                    mediaOverview: mediaSorted.overview,
                    mediaTodos: mediaSorted.todos,
                    mediaHistory: mediaSorted.history,
                    mediaStories: mediaSorted.stories,
                    overviewSelectedMediaIndex: 0,
                    todosSelectedMediaIndex: 0,
                    historySelectedMediaIndex: 0,
                    storiesSelectedMediaIndex: 0,

                    // Go button
                    goReady: false,
                    goInfoOn: false,

                    // Times
                    startMoment: null,
                    endMoment: null,
                    sunTimes : null,
                    moonTimes : null,
                    dayTime : null,
                    offsetTime: null,
                    totalTime: null,
                    startEndTimeValues: null,
                    hour00Moment: null,
                    hour24Moment: null,

                    // Astronomy
                    sunMoonOn: false,
                    moonPhase: null,
                    moonPhaseDigits: null,

                    // Weather
                    weather: null,
                    weatherOn: false,
                    weatherIcon: null,
                    tempHigh: null,
                    tempLow: null,
                    precipOn: null,
                    precipType: null,
                    precipProb: null,

                    // Like / save
                    likedByMe: likedByMe,
                    savedByMe: savedByMe,
                    likeCount: likeCount,
                    saveCount: saveCount,

                    // Editor buttons
                    editorChatButtonOn: false,
                    editorCalendarButtonOn: false,
                    editorInfoButtonOn: false,

                    // Editor information window
                    editorInfoOn: false,

                    // Editor follow information
                    followedByMe: followedByMe,
                    followerCount: response.data.content.editor.follower_count, 

                    // Websocket
                    webSocketGroup: webSocketGroup
                },
                callback
            );
        };

        // Send request with CSRF token
        getDot(tripSlug)
        .then(axiosCallback)
        .catch((response) => { console.log("Trip / loadTripInfo - Axios error ", response); });
    }


    /*
    ============================================================================================
        loadCommentsInfo
    ============================================================================================
    */

    loadCommentsInfo(boardID) {
        // console.log("Trip / loadCommentsInfo - boardID = ", boardID);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (commentsResponse) => {
            //console.log("Trip / loadCommentsInfo - commentsResponse.data = ", commentsResponse.data);
            this.setState(
                {
                    commentsInfo: commentsResponse.data.content
                }
            );
        };

        // Send post request using axios with CSRF token
        getSingleBoardComments(boardID, 0)
        .then(axiosCallback)
        .catch((response) => {console.log("Trip / loadCommentsInfo - Axios error ", response);});
    }


    /*
    ============================================================================================
        Get Open Itineraries
    ============================================================================================
    */
    
    loadOpenItineraries(tripSlug, callback) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Trip / loadOpenItineraries - response = ", response);

            // If request was unsuccessful
            if (response.data.status.code !== responseResultCodes.OK) {
                console.log("Trip / loadOpenItineraries - request error");
            }
            // If request was successful
            else {
                // Patch up the open itineraries info list
                let openItineraryFirstIndex, openItineraryLastIndex, statesToUpdate;

                // If patching up the already existing list
                if (this.state.openItineraryFetchedCount > 0) {
                    let openItinerariesInfo = this.state.openItinerariesInfo.slice();

                    openItinerariesInfo = openItinerariesInfo.concat(response.data.content.itineraries);

                    const newOpenItineraryIndices = [];
                    for (let i = 0; i < response.data.content.itineraries.length; i++) {
                        newOpenItineraryIndices.push(0);
                    }

                    let openItineraryIndices = this.state.openItineraryIndices.slice();
                    openItineraryIndices = openItineraryIndices.concat(newOpenItineraryIndices);

                    statesToUpdate = {
                        openItineraryTotalCount: response.data.content.itinerary_total_count,
                        openItineraryFetchedCount: (this.state.openItineraryFetchedCount + response.data.content.itinerary_fetched_count),
                        openItinerariesInfo: openItinerariesInfo,
                        openItineraryIndices: openItineraryIndices
                    };
                    //console.log("Trip / loadOpenItineraries [patching up] - statesToUpdate = ", statesToUpdate);
                    //console.log("Trip / loadOpenItineraries [patching up] - openItinerariesInfo = ", openItinerariesInfo);
                    //console.log("Trip / loadOpenItineraries [patching up] - response.data.content.itineraries = ", response.data.content.itineraries);
                }
                // If loaded open itineraries for the first time
                else {
                    // Initialize the indices
                    openItineraryFirstIndex = 0;
                    openItineraryLastIndex = Math.min(
                        (this.numOpenItineraries - 1),
                        (response.data.content.itineraries.length - 1)
                    );

                    // Initialize the sub indices
                    const openItineraryIndices = [];
                    for (let i = 0; i < response.data.content.itineraries.length; i++) {
                        openItineraryIndices.push(0);
                    }

                    statesToUpdate = {
                        openItineraryTotalCount: response.data.content.itinerary_total_count,
                        openItineraryFetchedCount: response.data.content.itinerary_fetched_count,
                        openItinerariesInfo: response.data.content.itineraries,
                        openItineraryIndices: openItineraryIndices,
                        openItineraryFirstIndex: openItineraryFirstIndex,
                        openItineraryLastIndex: openItineraryLastIndex
                    };
                    //console.log("Trip / loadOpenItineraries - statesToUpdate = ", statesToUpdate);
                }

                // Update open trips and relevant states
                this.setState(
                    statesToUpdate,
                    callback
                );
            }
        };

        // Send post request using axios with CSRF token
        postOpenItineraries(tripSlug, this.state.openItineraryFetchedCount)
        .then(axiosCallback)
        .catch((response) => {console.log("Trip / loadOpenItineraries - Axios error ", response);});
    }


    resetOpenItineraryIndices() {
        this.numOpenItineraries = (this.props.browserWidth <= 4)?
            this.numOpenItinerariesSmall : (this.props.browserWidth <= 7)?
                this.numOpenItinerariesMedium : this.numOpenItinerariesRegular;

        const openItineraryFirstIndex = this.state.openItineraryFirstIndex;
        const openItineraryLastIndex = Math.min(
            this.state.openItineraryFetchedCount,
            (this.state.openItineraryFirstIndex + this.numOpenItineraries - 1)
        );

        this.setState({
            openItineraryFirstIndex: openItineraryFirstIndex,
            openItineraryLastIndex: openItineraryLastIndex
        });
    }


    /*
    ============================================================================================
        Follow Request
    ============================================================================================
    */

    followRequest() {
        //console.log("Trip / followRequest - this.props = ", this.props);

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("Trip / followRequest - response.data = ", response.data);

            // Update local and global state
            this.setState(
                {
                    followedByMe: response.data.content.followed_by_me,
                    followerCount: response.data.content.follower_count
                },
                () => {
                    // Construct a shallow copy of the userInfo
                    const userInfo = Object.assign({}, this.props.userInfo);

                    // Update user info
                    userInfo.following_recent = response.data.content.following_recent; 
                    userInfo.following_count = response.data.content.following_count;

                    // Dispatch to Redux store
                    this.props.storeUser(userInfo);
                }
            );
        };

        // Send request
        postFollowRequest("create", this.state.tripInfo.editor.id)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Trip / followRequest - error = ", response);})       
    }


    /*
    ============================================================================================
        Unfollow Request
    ============================================================================================
    */

    unfollowRequest() {
        //console.log("Trip / unfollowRequest - this.props = ", this.props);

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("Trip / unfollowRequest - response.data = ", response.data);

            // Update local and global state
            this.setState(
                {
                    followedByMe: response.data.content.followed_by_me,
                    followerCount: response.data.content.follower_count
                },
                () => {
                    // Construct a shallow copy of the userInfo
                    const userInfo = Object.assign({}, this.props.userInfo);

                    // Update user info
                    userInfo.following_recent = response.data.content.following_recent; 
                    userInfo.following_count = response.data.content.following_count;

                    // Dispatch to Redux store
                    this.props.storeUser(userInfo);
                }
            );
        };

        // Send request
        postFollowRequest("destroy", this.state.tripInfo.editor.id)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Trip / unfollowRequest - error = ", response);})       
    }


    /*
    ============================================================================================
        Editor Information Window Click
    ============================================================================================
    */

    clickEditorInfo() {
        if (this.state.editorInfoOn) {
            this.setState({
                editorInfoButtonOn: false,
                editorInfoOn: false
            });
        }
        else {
            this.setState({
                editorInfoButtonOn: true,
                editorInfoOn: true
            });
        }
    }


    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }


    /*
    ============================================================================================
        Open itineraries
    ============================================================================================
    */

    joinOpenItinerary(index, subIndex) {
        // Make a copy of open itineraries
        const openItinerariesInfo = this.state.openItinerariesInfo.slice();
        //console.log("Trip / joinOpenItinerary - openItinerariesInfo = ", openItinerariesInfo);

        // Itinerary info
        const itineraryInfo = openItinerariesInfo[index][subIndex];
        console.log("Trip / joinOpenItinerary - itineraryInfo = ", itineraryInfo);

        // Get guests info
        const guestsInfo = itineraryInfo.guests;
        console.log("Trip / joinOpenItinerary - guestsInfo = ", guestsInfo);

        // Callback
        const axiosCallback = (response) => {
            console.log("Trip / joinOpenItinerary - response.data.content = ", response.data.content);

            // Add guest
            guestsInfo.push(response.data.content);

            // Update guests info
            openItinerariesInfo[index][subIndex].guests = guestsInfo;

            this.setState({
                openItinerariesInfo: openItinerariesInfo
            });
        };

        // Data to send
        const dataJSON = {
            operation: "add",
            user_id: this.props.userInfo.id,
            itinerary_id: itineraryInfo.id,
            is_invited: false
        };

        // Send request to add participant
        postParticipant(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Trip / joinOpenItinerary - ", response);
            }
        );
    }

    cancelOpenItinerary(index, subIndex) {
        // Make a copy of open itineraries
        const openItinerariesInfo = this.state.openItinerariesInfo.slice();
        //console.log("Trip / cancelOpenItinerary - openItinerariesInfo = ", openItinerariesInfo);

        // Itinerary info
        const itineraryInfo = openItinerariesInfo[index][subIndex];
        console.log("Trip / cancelOpenItinerary - itineraryInfo = ", itineraryInfo);

        // Get guests info
        const guestsInfo = itineraryInfo.guests;
        console.log("Trip / cancelOpenItinerary - guestsInfo = ", guestsInfo);

        // Callback
        const axiosCallback = (response) => {
            console.log("Trip / cancelOpenItinerary - response.data.content = ", response.data.content);

            // Remove guest
            let indexToRemove = null;
            for (let i = 0; i < guestsInfo.length; i++) {
                if (guestsInfo[i].id === this.props.userInfo.id) {
                    indexToRemove = i;
                }
            }
            guestsInfo.splice(indexToRemove, 1);

            // Update guests info
            openItinerariesInfo[index][subIndex].guests = guestsInfo;

            this.setState({
                openItinerariesInfo: openItinerariesInfo
            });
        };

        // Data to send
        const dataJSON = {
            operation: "remove",
            user_id: this.props.userInfo.id,
            itinerary_id: itineraryInfo.id,
            is_invited: false
        };

        // Send request to add participant
        postParticipant(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Trip / cancelOpenItinerary - ", response);
            }
        );
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        google: state.map.google,
        userInfo: state.user.userInfo,
        newSave: state.interaction.newSave
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeMore,
            storeNewLike,
            storeNewSave,
            storeShare,
            storeWarningAlert
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Trip);