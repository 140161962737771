import { STORE_GALLERY } from "actions"; 

const INITIAL_STATE = {
    gallery: { modalOn: false, info: null }
};

export default function(state=INITIAL_STATE, action) {
    switch(action.type) {
        case STORE_GALLERY:
            return { ...state, gallery: action.payload }; 
        default:
            return state;
    }
};