import { STORE_REFRESH } from "actions";
import { getTimestamp } from "js/Functions";

const INITIAL_STATE = {
    refresh: {
        timestamp: getTimestamp()
    }
};

export default function(state=INITIAL_STATE, action) {
    switch(action.type) {
        case STORE_REFRESH:
            return { ...state, refresh: action.payload }; 
        default:
            return state;
    }
};