/*
============================================================================================
    projectionect Dots
--------------------------------------------------------------------------------------------
    NationalParkMap.js
    - Open Street Map Based Components and Functions
--------------------------------------------------------------------------------------------
    Content
    - NationalParkMap
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Redux
import {
    storeLogInOn,
    storeLogInSimpleOn,
    storeWarningAlert,
    storeNotificationAlert,
    storeCreate
} from "actions";

// Modules
//import polyline from "@mapbox/polyline";
//import * as turf from "@turf/turf";
//import * as GeoJSONLib from "geojson";

// Map and open layers
import "ol/ol.css";
import { Map } from "ol";
import {
    //defaults as DefaultControls,
    Control
} from "ol/control";

// View
import { View } from "ol";

// projectionection
import * as projection from "ol/proj";
import { fromLonLat } from "ol/proj";
import { toLonLat } from "ol/proj";

// get vector context
import {getVectorContext} from "ol/render";

// Extent
import {
    getBottomLeft,
    getTopRight
} from 'ol/extent';

// Open street map and bing map tiles
import { OSM } from "ol/source";
import { BingMaps } from "ol/source";

// Tile and tile grid
import { Tile } from "ol/layer";
import { TileJSON } from "ol/source";
import { XYZ } from "ol/source";

// Geometry
//import * as geom from "ol/geom";
import {
    Point,
    //MultiPolygon
} from "ol/geom";

// Vector layer and source
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";

// Overlay and Feature
import { Overlay } from "ol";
import { Feature } from "ol";

// Geo json
import { GeoJSON } from "ol/format";

// Style
import { Style } from "ol/style";
//import { Icon as IconStyle} from "ol/style";
import { Circle as CircleStyle } from "ol/style";
import { Fill as FillStyle } from "ol/style";
import { Stroke as StrokeStyle } from "ol/style";

// Vector tiles from mapbox
//import MVT from "ol/format/MVT";
//import VectorTileLayer from "ol/layer/VectorTile";
//import VectorTileSource from "ol/source/VectorTile";

// Open layers mapbox styles
//import { register } from "ol/proj/proj4";
//import proj4 from "proj4";
//import olms from 'ol-mapbox-style';
import apply from 'ol-mapbox-style';
import FullScreen from 'ol/control/FullScreen';
//import { defaultResolutions } from 'ol-mapbox-style/dist/util';
//import { applyBackground, applyStyle } from "ol-mapbox-style";
//import stylefunction from 'ol-mapbox-style/dist/stylefunction';

// Functions
import {
    getStaticPath,
    getMediaProperty,
    sortMedia
} from "js/Functions";

// Map functions
import {
    //latLngToPoint,
    getDistanceFromLatLonInKm,
    //degToRad,
    //getUserLocation
} from "components/Map";

// Axios
import {
    getNearbySearch,
    getGeographies
} from "requests";

// CSS
import "./NationalParkMap.css";


class NationalParkMap extends Component {
    constructor(props) {
        super(props);

        // DOM node
        this.openMapRef = React.createRef();

        // Use cloud tiles
        this.cloudTiles = true;

        // Boundary center
        this.boundaryCenter = this.props.mapCenter;

        // Zoom to fit switch
        this.zoomToFitOn = false;

        // Zoom in level
        this.zoomInLevel = this.props.mapZoom;
        this.zoomOutLevel = this.props.mapZoom;

        // Zoom limits for cloud tiles
        this.maxZoomCloudDict = {
            "roadmap": 18,
            "terrain": 18,
            "hybrid": 18,
            "satellite": 18
        };

        // Zoom limits for self-hosted tiles
        this.maxZoomDict = {
            "roadmap": 18,
            "terrain": 18,
            "hybrid": 13,
            "satellite": 13
        };

        // New nearby search criteria
        this.zoomTolerance = 2.0;
        this.radiusTolerance = 2.0;

        // Handles to store map related objects
        this.openMap = null;
        this.markersLayer = null;
        this.markersSource = null;
        this.childMarkersLayer = null;
        this.childMarkersSource = null;
        this.boundaryLayer = null;
        this.boundarySource = null;

        // Marker information window
        this.markerInformationOverlay = null;
        this.hoveredMarkerInformationOverlay = null;

        // Marker pulse animation
        this.markerPulseOverlay = null;

        // On-map action flag
        this.unselectZoomOut = null;
        this.nearbySearchOn = null;

        // Map action listener keys
        this.zoomPanListenerKey = null;
        this.markerClickListenerKey = null;

        // Map zoom and center
        this.prevZoom = null;
        this.prevCenter = null;

        // Long Press
        this.longPressTimer = null;
        this.longPressCancelRadius = 15;
        this.longPressPosition = null;

        // Create Mode
        this.createMenuOn = false;

        // Create location
        this.location = null;

        // Initialize states
        this.state = {
            // Map height
            mapHeight: props.mapHeight,
            mapMinHeight: props.mapMinHeight,
            mapMaxHeight: props.mapMaxHeight,
            mapHeightIncrement: props.mapHeightIncrement,

            // Markers
            markers: [],
            childMarkers: [],

            // User Current Location
            userCurrentLocation: null,
        };

        // Initialize map
        this.initializeMap = this.initializeMap.bind(this);
        this.initializeLayout = this.initializeLayout.bind(this);

        // Bind marker functions
        this.setMarkers = this.setMarkers.bind(this);
        this.removeMarkers = this.removeMarkers.bind(this);

        // Bind map height functions
        this.addMapHeightButtons = this.addMapHeightButtons.bind(this);
        this.mapHeightIncrease = this.mapHeightIncrease.bind(this);
        this.mapHeightDecrease = this.mapHeightDecrease.bind(this);

        // Bind map location initialize functions
        this.mapBoundaryFitButton = this.mapBoundaryFitButton.bind(this);
        this.mapDotFitButton = this.mapDotFitButton.bind(this);
        // Bind map button function
        this.nearbySearch = this.nearbySearch.bind(this);

        // Near by Search updater
        this.nearbySearchPopUp = this.nearbySearchPopUp.bind(this);
        this.removeNearbySearchPopUp = this.removeNearbySearchPopUp.bind(this);
        this.yesNearbySearchButton = this.yesNearbySearchButton.bind(this);
        this.noNearbySearchButton = this.noNearbySearchButton.bind(this);

        // Create Button state updater
        this.createPopUp = this.createPopUp.bind(this);
        this.removeCreatePopUp = this.removeCreatePopUp.bind(this);
        this.yesCreateButton = this.yesCreateButton.bind(this);
        this.noCreateButton = this.noCreateButton.bind(this);

        // Marker information
        this.setMarkerInformation = this.setMarkerInformation.bind(this);

        // Marker pulse
        this.setMarkerPulse = this.setMarkerPulse.bind(this);
        this.markerPulseOn = this.markerPulseOn.bind(this);

        // Setup map action listeners
        this.markerClickListener = this.markerClickListener.bind(this);
        this.zoomPanListener = this.zoomPanListener.bind(this);

        // Zoom functions
        this.zoomToFit = this.zoomToFit.bind(this);
        this.getZoom = this.getZoom.bind(this);

        // Bind mouse action
        this.panToDot = this.panToDot.bind(this);
        this.panToCenter = this.panToCenter.bind(this);

        // Mouse action state updaters
        this.markerHoverOn = this.markerHoverOn.bind(this);
        this.markerHoverOff = this.markerHoverOff.bind(this);
        this.hoverOn = this.hoverOn.bind(this);

        // Load Park Boundary
        this.loadBoundary = this.loadBoundary.bind(this);

        // Render Park Boundary
        this.renderBoundary = this.renderBoundary.bind(this);
        this.getFeatureStyle = this.getFeatureStyle.bind(this);
    }

    render() {
        // JSX
        return (
            <div id = "national-park-map">
                <div ref = {this.openMapRef}
                    id = "national-park-set-map"
                    style = {{
                        width: this.props.mapWidth,
                        height: this.state.mapHeight,
                        zIndex: 100
                    }}
                    onTouchStart = {(event) => {
                        if (window.touchOn) {
                            //console.log("NationalParkMap / render / onTouchStart");
                            //console.log("NationalParkMap / render / onTouchStart - event.touches = ", event.touches);
                            //console.log("NationalParkMap / render / onTouchStart - event.touches.length = ", event.touches.length);
                            //console.log("NationalParkMap / render / onTouchStart - this.longPressTimer = ", this.longPressTimer);

                            // Select first touch point
                            const touch = event.touches[0];
                            //console.log("onTouchStart - touch = ", touch);
                            this.longPressPosition = [ touch.clientX, touch.clientY ];
                            //console.log("NationalParkMap / render / onTouchStart - this.longPressPosition = ", this.longPressPosition);

                            // Get bounding box
                            const boundingBox = this.openMapRef.current.getBoundingClientRect();
                            //console.log("NationalParkMap / render / onTouchStart - boundingBox = ", boundingBox);
                            //console.log("NationalParkMap / render / onTouchStart - touch.clientX = ", touch.clientX);
                            //console.log("NationalParkMap / render / onTouchStart - touch.clientY = ", touch.clientY);

                            // Convert to pixels and coordinates
                            const pixels = [ touch.clientX - boundingBox.left, touch.clientY - boundingBox.top ];
                            const coordinates = this.openMap.getCoordinateFromPixel(pixels);
                            //console.log("NationalParkMap / render / onTouchStart - pixels = ", pixels);
                            //console.log("NationalParkMap / render / onTouchStart - coordinates = ", coordinates);

                            // Location
                            const lnglat = projection.transform(coordinates, "EPSG:3857", "EPSG:4326");
                            const location = {
                                latitude: lnglat[1],
                                longitude: lnglat[0]
                            };
                            //console.log("NationalParkMap / render / onTouchStart - location = ", location);

                            // Cancel long press timer (Multi-Touches)
                            if (event.touches.length > 1) {
                                clearTimeout(this.longPressTimer);
                                this.longPressTimer = null;
                                }

                            // Install long press timer
                            this.longPressTimer = setTimeout(
                                () => {
                                    this.createPopUp(location);
                                },
                                1000
                            );

                        }
                    }}
                    onTouchEnd = {(event) => {
                        if (window.touchOn) {
                            //console.log("NationalParkMap / render / onTouchEnd");

                            // Cancel long press timer
                            if (this.longPressTimer !== null) {
                                clearTimeout(this.longPressTimer);
                                this.longPressTimer = null;
                            }
                        }
                    }}
                    onTouchMove = {(event) => {
                        if (window.touchOn) {
                            //console.log("NationalParkMap / render / onTouchMove");

                            // Cancel long press timer
                            if (this.longPressTimer !== null) {
                                const touch = event.touches[0];
                                const touchDisplacement = Math.sqrt(Math.pow(this.longPressPosition[0] - touch.clientX, 2) + Math.pow(this.longPressPosition[1] - touch.clientY, 2));
                                //console.log("NationalParkMap / render / onTouchMove - this.longPressTimer = ", this.longPressTimer);
                                //console.log("NationalParkMap / render / onTouchMove - touchDisplacement = ", touchDisplacement);

                                if (touchDisplacement > this.longPressCancelRadius) {
                                    clearTimeout(this.longPressTimer);
                                    this.longPressTimer = null;
                                }
                            }
                        }
                    }}
                    onMouseMove = {(event) => {
                        if (!window.touchOn) {
                            //console.log("NationalParkMap / render / onMouseMove");

                            // Cancel long press timer
                            if (this.longPressTimer !== null) {
                                const clickDisplacement = Math.sqrt(Math.pow(this.longPressPosition[0] - event.clientX, 2) + Math.pow(this.longPressPosition[1] - event.clientY, 2));
                                //console.log("NationalParkMap / render / onMouseMove - clickDisplacement = ", clickDisplacement);

                                if (clickDisplacement > this.longPressCancelRadius) {
                                    clearTimeout(this.longPressTimer);
                                    this.longPressTimer = null;
                                }
                            }
                        }
                    }}
                    onMouseDown = {(event) => {
                        if (!window.touchOn) {
                            //console.log("NationalParkMap / render / onMouseDown");

                            // Get bounding box
                            const boundingBox = this.openMapRef.current.getBoundingClientRect();
                            //console.log("NationalParkMap / render / onMouseDown - boundingBox = ", boundingBox);

                            // Select first click point
                            this.longPressPosition = [ event.clientX, event.clientY ];
                            //console.log("NationalParkMap / render / onMouseDown - this.longPressPosition = ", this.longPressPosition);

                            //console.log("NationalParkMap / render / onMouseDown - event = ", event);
                            //console.log("NationalParkMap / render / onMouseDown - event.target = ", event.target);
                            //console.log("NationalParkMap / render / onMouseDown - event.clientX = ", event.clientX);
                            //console.log("NationalParkMap / render / onMouseDown - event.clientY = ", event.clientY);

                            // Convert to pixels and coordinates
                            const pixels = [ event.clientX - boundingBox.left, event.clientY - boundingBox.top ];
                            const coordinates = this.openMap.getCoordinateFromPixel(pixels);
                            //console.log("NationalParkMap / render / onMouseDown - pixels = ", pixels);
                            //console.log("NationalParkMap / render / onMouseDown - coordinates = ", coordinates);

                            // Location
                            const lnglat = projection.transform(coordinates, "EPSG:3857", "EPSG:4326");
                            const location = {
                                latitude: lnglat[1],
                                longitude: lnglat[0]
                            };
                            //console.log("NationalParkMap / render / onMouseDown - location = ", location);

                            // Install long press timer
                            this.longPressTimer = setTimeout(
                                () => {
                                    this.createPopUp(location);
                                },
                                1000
                            );
                        }
                    }}
                    onMouseUp = {(event) => {
                        if (!window.touchOn) {
                            //console.log("NationalParkMap / render / onMouseUp");

                            // Cancel long press timer
                            if (this.longPressTimer !== null) {
                                clearTimeout(this.longPressTimer);
                                this.longPressTimer = null;
                            }
                        }
                    }}
                >
                </div>
            </div>
        );
    }

    initializeMap() {
        let baseLayerSelected = null;
        let terrainLayerSelected = null;
        let hybridLayerSelected = null;
        let satelliteLayerSelected = null;

        // OSM Base map layer
        const developLayer = new Tile(
            {
                source: new OSM()
            }
        );

        /*
        ============================================================================================
            Tile Server
        ============================================================================================
        */

        // Base raster layer
        const baseLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/klokantech-basic/{z}/{x}/{y}.png"
            })
        });

        // Terrain raster layer
        const terrainLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/klokantech-terrain/{z}/{x}/{y}.png"
            })
        });

        // Hybrid raster layer
        const hybridLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/hybrid/{z}/{x}/{y}.png"
            })
        });

        // Satellite raster layer
        const satelliteLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/satellite/{z}/{x}/{y}.png"
            })
        });


        /*
        ============================================================================================
            V-world Layers
        ============================================================================================
        */

        // V-world layers
        const vWorldBaseLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Base/{z}/{y}/{x}.png"
            })
        });

        const vWorldTerrainLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Terrain/{z}/{y}/{x}.png"
            })
        });

        const vWorldHybridLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Hybrid/{z}/{y}/{x}.png"
            })
        });

        const vWorldSatelliteLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Satellite/{z}/{y}/{x}.jpeg"
            })
        });


        /*
        ============================================================================================
            Bing Layers
        ============================================================================================
        */

        // Bing Arial with roads
        const bingSatelliteLayer = new Tile({
            visible: true,
            preload: Infinity,
            source: new BingMaps({
                key: "Ag6EnD5xB98riaGAyNCa-kpBtogfQYVAYprgoBefcywRC-4tjNDA0yK_HVma1gm0",
                imagerySet: "AerialWithLabels",
                maxZoom: 19
            })
        });


        /*
        ============================================================================================
            MapTiler Layers
        ============================================================================================
        */

        // MapTiler Satellite
        const mapTilerSatelliteLayer = new Tile(
            {
                source: new TileJSON(
                    {
                        url: "https://api.maptiler.com/maps/hybrid/tiles.json?key=zh1EW7kV5q0kzABuQrMS",
                        crossOrigin: "anonymous"
                    }
                )
            }
        );


        /*
        ============================================================================================
            MapBox Layers
        ============================================================================================
        */

        // Mapbox cloud API key
        const mapBoxKey = "pk.eyJ1IjoiaGFya2xlZSIsImEiOiJja2FvaXoxbHEwMnZ5MnBxaW5oNGhlcHljIn0.sVCVavi9TlJMgTFuC25dMw";

        // Mapbox cloud satellite
        const mapBoxSatelliteLayer = new Tile({
            source: new XYZ({
                url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=" + mapBoxKey
            })
        });


        /*
        ============================================================================================
            Tile Layers
        ============================================================================================
        */

        if (this.props.mapSource === "vworld") {
            baseLayerSelected = vWorldBaseLayer;
            terrainLayerSelected = vWorldTerrainLayer;
            hybridLayerSelected = vWorldSatelliteLayer;
            satelliteLayerSelected = vWorldSatelliteLayer;
        }
        else {
            baseLayerSelected = developLayer;
            terrainLayerSelected = terrainLayer;
            hybridLayerSelected = bingSatelliteLayer;
            satelliteLayerSelected = bingSatelliteLayer;
        }
        // Tile layers
        const tileLayersDict = {
            "roadmap": baseLayerSelected,
            "terrain": terrainLayerSelected,
            "hybrid": hybridLayerSelected,
            "satellite": satelliteLayerSelected
        };

        // Get the max zoom
        const maxZoom = this.getZoom(20);

        // Layers
        const tileLayers = (this.cloudTiles)?
             [tileLayersDict[String(this.props.mapType)]]  : [];

        // text Map Extent
        //const mapExtent = [-12466588.826405548, 5408945.929699543, -12154725.751002029, 5653544.420212108]
        //const mapExtent = [-12459862.36791645, 5436463.259882205, -12147999.29251293, 5681061.75039477]

        // Set up the map
        this.openMap = new Map({
            layers: tileLayers,
            target: "national-park-set-map",
            control: new FullScreen(),
            view: new View({
                center: fromLonLat([
                    this.boundaryCenter.longitude,
                    this.boundaryCenter.latitude
                ]),
                zoom: this.getZoom(this.props.mapZoom),
                maxZoom: maxZoom,
                projection: "EPSG:3857",
                //extent: mapExtent
            })
        });

        // For self-hosted tiles
        // Enable vector layers
        if (!this.cloudTiles) {
            // GL Style jsons
            const styleJson = {
                "roadmap": "http://35.239.243.183/styles/klokantech-basic/style.json",
                "terrain": "http://35.239.243.183/styles/klokantech-terrain/style.json",
                "hybrid": "http://35.239.243.183/styles/hybrid/style.json",
                "satellite": "http://35.239.243.183/styles/klokantech-terrain/style.json"
            };

            // Apply mapbox style
            apply(this.openMap, styleJson[String(this.props.mapType)]).then(
                (openMap) => {
                    this.initializeLayout();
                }
            );
        }
        // For cloud tiles
        else {
            this.initializeLayout();
        }

    }


    /*
    ============================================================================================
        Initialize layout
    ============================================================================================
    */

    initializeLayout() {
        // Set markers
        this.setMarkers(null);

        // Add height control buttons
        this.addMapHeightButtons();

        // Add map fit button
        this.mapBoundaryFitButton();
        this.mapDotFitButton();

        // Setup Listeners
        this.zoomPanListener();
        this.markerClickListener();
    }

    componentDidMount() {
        //console.log("NationalParkMap / componentDidMount - this.state = ", this.state);
        //console.log("NationalParkMap / componentDidMount - this.props = ", this.props);

        // Initialize map
        this.initializeMap();

        // Load National Park Boundary
        this.loadBoundary();
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("NationalParkMap / componentDidUpdate - this.state = ", this.state);
        //console.log("NationalParkMap / componentDidUpdate - this.props = ", this.props);

        // Dots info changed
        if (JSON.stringify(prevProps.dotsInfo) !== JSON.stringify(this.props.dotsInfo)) {
            // Reset marker information
            this.removeMarkerInformation();

            // Reset markers
            this.setMarkers();
        }

        // Hover on
        if ((this.props.hovered !== null) && (prevProps.hovered !== this.props.hovered)) {
            if (prevProps.hovered !== null) {
                this.markerHoverOff();
            }
            this.hoverOn(this.props.hovered);
        }

        // Hover off
        if ((this.props.hovered === null) && (prevProps.hovered !== null)) {
            this.markerHoverOff();
            //this.panToCenter();
        }

        // Select on
        if ((this.props.selected !== null) && (prevProps.selected !== this.props.selected)) {
            // Disable select in progress and nearby search
            this.unselectZoomOut = null;
            this.nearbySearchOn = null;

            // Remove nearbySearch pop up overlay
            this.removeNearbySearchPopUp();

            // Remove create pop up overlay
            this.removeCreatePopUp();

            // Pan to dot
            this.panToDot(
                this.props.selected,
                () => {
                    //console.log("Select on / panToDot callback");

                    // Re-activate select in progress
                    this.unselectZoomOut = true;

                    // Reset nearby search flag
                    this.nearbySearchOn = null;

                    //console.log("Select on / panToDot callback - this.unselectZoomOut = ", this.unselectZoomOut);
                    //console.log("Select on / panToDot callback - this.nearbySearchOn = ", this.nearbySearchOn);
                },
                this.props.selectZoomIn
            );
        }

        // Select off
        if ((this.props.selected === null) && (prevProps.selected !== null)) {
            //console.log("NationalParkMap / componentDidUpdate - this.unselectZoomOut = ", this.unselectZoomOut);
            //console.log("NationalParkMap / componentDidUpdate - this.nearbySearchOn = ", this.nearbySearchOn);
            //console.log("NationalParkMap / componentDidUpdate - this.props.selected = ", this.props.selected);

            if (this.unselectZoomOut === true && this.nearbySearchOn === true) {
                // Disable select in progress and nearby search
                this.unselectZoomOut = null;
                this.nearbySearchOn = null;

                const extentSource = (this.markersSource === null) ? true : false;   // true:boundarySource, false:markersSource

                // Zoom to fit
                this.zoomToFit(
                    false,
                    false,
                    extentSource,
                    () => {
                        //console.log("Select off / zoomToFit callback");

                        // Reset select in progress and nearby search flag
                        this.unselectZoomOut = null;
                        this.nearbySearchOn = null;

                        // Remove nearbySearch pop up overlay
                        this.removeNearbySearchPopUp();

                        // Remove create pop up overlay
                        this.removeCreatePopUp();

                        //console.log("Select off / zoomToFit callback - this.unselectZoomOut = ", this.unselectZoomOut);
                        //console.log("Select off / zoomToFit callback - this.nearbySearchOn = ", this.nearbySearchOn);
                    }
                );

                /*
                this.panToCenter(
                    () => {
                        //console.log("Select off / panToCenter callback");

                        // Reset select in progress and nearby search flag
                        this.unselectZoomOut = null;
                        this.nearbySearchOn = null;

                        // Remove nearbySearch pop up overlay
                        this.removeNearbySearchPopUp();

                        // Remove create pop up overlay
                        this.removeCreatePopUp();

                        //console.log("Select off / panToCenter callback - this.unselectZoomOut = ", this.unselectZoomOut);
                        //console.log("Select off / panToCenter callback - this.nearbySearchOn = ", this.nearbySearchOn);
                    }
                );
                */
            }
            else {
                // Reset select in progress and nearby search
                this.unselectZoomOut = null;
                this.nearbySearchOn = null;

                // Remove nearbySearch pop up overlay
                this.removeNearbySearchPopUp();

                // Remove create pop up overlay
                this.removeCreatePopUp();
            }
        }

        // Sense user log in
        //console.log("NationalParkMap / componentDidUpdate - this.location = ", this.location);
        //console.log("NationalParkMap / componentDidUpdate - prevProps.userInfo = ", prevProps.userInfo);
        //console.log("NationalParkMap / componentDidUpdate - this.props.userInfo = ", this.props.userInfo );
        if (this.location !== null && prevProps.userInfo === null && this.props.userInfo !== null) {
            // Open create modal
            this.props.storeCreate({
                modalOn: true,
                mode: "create",
                setSlug: this.props.setSlug,
                location: this.location,
                info: null
            });

            // Reset location
            this.location = null;
        }

        // Detect window width change
        if (this.props.browserWidthPixels !== prevProps.browserWidthPixels) {
            //console.log("NationalParkMap / componentDidUpdate - this.props.browserWidthPixels = ", this.props.browserWidthPixels);
            //console.log("NationalParkMap / componentDidUpdate - prevProps.browserWidthPixels = ", prevProps.browserWidthPixels);

            // Zoom to fit
            this.zoomToFit(
                true,
                true,
                false,//true,
                null
            );
        }
    }


    /*
    ============================================================================================
        Marker Actions
    ============================================================================================
    */

    setMarkers(callback) {
        // Clear previous markers
        this.removeMarkers(false);

        // Initialize marker arrays
        let markers = [];

        // Set up counter
        let markerCount = 0;

        // For all dots
        for (let i = 0; i < this.props.dotsInfo.length; i++) {
            // Location
            const location = this.props.dotsInfo[i].location;

            // Create a marker
            const marker = this.createMarker(
                location,
                i,
                null
            );

            // Setup marker information
            this.setMarkerInformation(
                i,
                null,
                false
            );

            // Add marker to the array
            markers.push(marker);

            // Increase counter
            markerCount += 1;
        }

        if (markerCount > 0) {

            // Markers vector
            const markersSource = new VectorSource({
                features: markers,
                wrapX: false
            });

            const markersLayer = new VectorLayer({
                className: "national-park-map-marker-layer",
                source: markersSource,
            });
            markersLayer.setZIndex(104);

            // Set name
            markersLayer.set("name", "marker", true);

            // Save the vector source
            this.markersSource = markersSource;

            // Save the layer handle
            this.markersLayer = markersLayer;

            // Add vector layer
            this.openMap.addLayer(markersLayer);
        }
        else {
            markers = null;
        }

        // Set state and refresh map
        this.setState(
            {
                markers: markers,
            },
            callback
        );
    }

    createMarker(location, dotIndex, childIndex) {
        // Set up a marker
        const marker = new Feature({
            geometry: new Point(
                fromLonLat([
                    location.longitude,
                    location.latitude
                ])
            ),
            dotIndex: dotIndex,
            childIndex: childIndex
        });

        const fill = new FillStyle({
            color: "rgba(255,255,255,0.4)"
        });

        const stroke = new StrokeStyle({
            color: "rgb(255,255,255)",
            width: 2
        });

        // Marker circle
        marker.setStyle(new Style({
            image: new CircleStyle({
               fill: fill,
               stroke: stroke,
               radius: 25
             }),
            zIndex: 102
        }));

        return marker;
    }

    removeMarkers(updateState) {
        if ((this.state.markers !== null) && (this.markersLayer !== null)) {
            this.markersLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    markers: null
                });
            }
        }
    }

    removeChildMarkers(updateState) {
        if ((this.state.childMarkers !== null) && (this.childMarkersLayer !== null)) {
            this.childMarkersLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    childMarkers: null
                });
            }

        }
    }


    /*
    ============================================================================================
        Marker Pulse
    ============================================================================================
    */

    setMarkerPulse(location) {
        const userMarkerImage = getStaticPath("/images/map/dot-marker-black.png", true);
        //(this.props.colorMode === "day")?
        //    getStaticPath("/images/number/single_white_O.png", true) :
        //    getStaticPath("/images/number/single_black_O.png", true)
        const userMarker = document.createElement("div");
        userMarker.className = "search-open-map-user-marker image-cover";
        userMarker.style.backgroundImage = userMarkerImage;

        const userMarkerPulse = document.createElement("div");
        userMarkerPulse.className = "search-open-map-marker-pulse-black";
        userMarkerPulse.appendChild(userMarker);

        // Set up a new overlay
        this.markerPulseOverlay = new Overlay({
            position: fromLonLat([ location.longitude, location.latitude ]),
            positioning: "center-center",
            element: userMarkerPulse,
            stopEvent: false
        });

        this.openMap.addOverlay(this.markerPulseOverlay);
    }

    markerPulseOn(location) {
        const coordinates = fromLonLat([
            location.longitude,
            location.latitude
        ]);

        // Move the marker information to the hovered location
        this.markerPulseOverlay.setPosition(coordinates);
    }


    /*
    ============================================================================================
        Marker Information
    ============================================================================================
    */

    setMarkerInformation(dotIndex, childIndex, hovered) {
        // Dot info
        const dotInfo = (childIndex === null)?
            this.props.dotsInfo[dotIndex] :
            this.props.dotsInfo[dotIndex].trip_extension.children[childIndex];

        // Get location
        const location = dotInfo.location;

        // Media
        const mediaSorted = sortMedia(dotInfo);
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Is video
        const isVideo = (media[0].type === "video");

        // Dot media
        const dotMedia = (isVideo)?
            (
                ("t" in media[0].files)?
                    getMediaProperty(media[0], "t", "url", false) :
                    getStaticPath("/images/common/video-white.png", true)
            ) : (
                getMediaProperty(media[0], "t", "url", false)
            );

        // Create new markerInformation
        let markerInformation = (hovered)?
            document.createElement("national-park-map-marker-information-hover-on") :
            document.createElement("national-park-map-marker-information-hover-off");

        // Media
        const mediaElement = (hovered)?
            document.createElement("national-park-map-hovered-marker-media") :
            document.createElement("national-park-map-marker-media");
        mediaElement.className = (hovered)?
            "national-park-map-marker-media national-park-map-marker-media-on" :
            "national-park-map-marker-media national-park-map-marker-media-off";
        mediaElement.style.background = "url(" + dotMedia + ")";
        mediaElement.style.backgroundSize = "cover";

        // Name
        const nameElement = (hovered)?
            document.createElement("national-park-map-hovered-marker-name") :
            document.createElement("national-park-map-marker-name");
        nameElement.className = (hovered)?
            "national-park-map-marker-name national-park-map-marker-name-on w5" :
            "national-park-map-marker-name national-park-map-marker-name-off w5";
        nameElement.textContent = dotInfo.name;

        // Insert name and media into marker
        markerInformation.appendChild(mediaElement);
        markerInformation.appendChild(nameElement);

        // Set up a new overlay
        if (hovered) {
            // Set up a new hovered overlay
            this.hoveredMarkerInformationOverlay = new Overlay({
                position: fromLonLat([ location.longitude, location.latitude ]),
                positioning: "center-center",
                element: markerInformation,
                stopEvent: false,
                className: "national-park-map-marker-information-hover-on",
            });

            this.openMap.addOverlay(this.hoveredMarkerInformationOverlay);
        }
        else {
            this.markerInformationOverlay = new Overlay({
                position: fromLonLat([ location.longitude, location.latitude ]),
                positioning: "center-center",
                element: markerInformation,
                stopEvent: false,
                className: (hovered)?
                    "national-park-map-marker-information-hover-on" :
                    "national-park-map-marker-information"
            });

            this.openMap.addOverlay(this.markerInformationOverlay);
        }
    }

    removeMarkerInformation() {
        this.openMap.getOverlays().clear()
    }

    /*
    ============================================================================================
        Map Height Button
    ============================================================================================
    */

    addMapHeightButtons() {
        const heightDecreaseButton = document.createElement("button");
        heightDecreaseButton.innerHTML = "∧";
        heightDecreaseButton.addEventListener("click", this.mapHeightDecrease, false);

        const heightIncreaseButton = document.createElement("button");
        heightIncreaseButton.innerHTML = "∨";
        heightIncreaseButton.addEventListener("click", this.mapHeightIncrease, false);

        const heightButtons = document.createElement("div");
        heightButtons.className = "national-park-map-height-buttons ol-unselectable ol-control";
        heightButtons.appendChild(heightDecreaseButton);
        heightButtons.appendChild(heightIncreaseButton);

        const heightControl = new Control({
            element: heightButtons
        });

        this.openMap.addControl(heightControl);
    }


    mapHeightIncrease() {
        const mapHeight = (this.state.mapHeight < this.props.mapMaxHeight)?
            this.state.mapHeight + this.props.mapHeightIncrement : this.state.mapHeight;

        this.setState({
                mapHeight: mapHeight
            },
            () => {this.openMap.updateSize();
        });

        if (this.props.mapHeightUpdate) {
            this.props.setState({
                mapHeight: mapHeight
            });
        }
    }


    mapHeightDecrease() {
        const mapHeight = (this.state.mapHeight > this.props.mapMinHeight)?
            this.state.mapHeight - this.props.mapHeightIncrement : this.state.mapHeight;

        this.setState({
                mapHeight: mapHeight
            },
            () => {this.openMap.updateSize();
        });

        if (this.props.mapHeightUpdate) {
            this.props.setState({
                mapHeight: mapHeight
            });
        }
    }

    /*
    ============================================================================================
        Map Initialize Button
    ============================================================================================
    */

    mapBoundaryFitButton() {
        const initializeIcon = getStaticPath("/images/common/expand-white.png");
        const boundaryFitIcon = document.createElement("button");
        boundaryFitIcon.className = "image-contain";
        boundaryFitIcon.style.backgroundImage = initializeIcon;

        boundaryFitIcon.addEventListener(
            "click",
            (event) => {
                // Stop event propagation
                event.stopPropagation();

                // Disable select in progress and nearby search
                this.unselectZoomOut = null;
                this.nearbySearchOn = null;

                // Zoom to fit
                this.zoomToFit(
                    false,
                    false,
                    true,
                    () => {
                        //console.log("NationalParkMap / mapBoundaryFitButton - this.unselectZoomOut = ", this.unselectZoomOut);
                        //console.log("NationalParkMap / mapBoundaryFitButton - this.nearbySearchOn = ", this.nearbySearchOn);

                        // Re-activate select in progress
                        this.unselectZoomOut = null;

                        // Reset nearby search flag
                        this.nearbySearchOn = null;
                    }
                );
            },
            false
        );

        const boundaryFitButton = document.createElement("div");
        boundaryFitButton.className = "national-park-map-boundary-fit-buttons ol-unselectable ol-control";
        boundaryFitButton.appendChild(boundaryFitIcon);

        const mapBoundaryFitButton = new Control({
            element: boundaryFitButton
        });

        this.openMap.addControl(mapBoundaryFitButton);
    }

    /*
    ============================================================================================
        Map Initialize Button
    ============================================================================================
    */

    mapDotFitButton() {
    const dotIcon = getStaticPath("/images/common/type-dot-white.png");
    const dotFitIcon = document.createElement("button");
    dotFitIcon.className = "image-contain";
    dotFitIcon.style.backgroundImage = dotIcon;

    dotFitIcon.addEventListener(
        "click",
        (event) => {
            // Stop event propagation
            event.stopPropagation();

            // Disable select in progress and nearby search
            this.unselectZoomOut = null;
            this.nearbySearchOn = null;

            const extentSource = (this.markersSource === null) ? true : false;   // true:boundarySource, false:markersSource
            // Zoom to fit
            this.zoomToFit(
                false,
                false,
                extentSource,
                () => {
                    //console.log("NationalParkMap / mapDotFitButton - this.unselectZoomOut = ", this.unselectZoomOut);
                    //console.log("NationalParkMap / mapDotFitButton - this.nearbySearchOn = ", this.nearbySearchOn);

                    // Re-activate select in progress
                    this.unselectZoomOut = null;

                    // Reset nearby search flag
                    this.nearbySearchOn = null;
                }
            );
        },
        false
    );

    const dotFitButton = document.createElement("div");
    dotFitButton.className = "national-park-map-dot-fit-buttons ol-unselectable ol-control";
    dotFitButton.appendChild(dotFitIcon);

    const mapDotFitButton = new Control({
        element: dotFitButton
    });

    this.openMap.addControl(mapDotFitButton);
}


    /*
    ============================================================================================
        Create Button
    ============================================================================================
    */
    createPopUp(location) {
        //console.log("NationalParkMap / createPopUp - location = ", location);

        // Update location
        this.location = location;

        // Remove create pop up overlay
        this.removeCreatePopUp();

        const createPopUp = document.createElement("div");
        createPopUp.className = "national-park-map-create-box";
        const content = document.createElement("div");
        content.className = "national-park-map-create-box-text w4";
        content.textContent = "Create a dot";

        const answerContainer = document.createElement("div");
        answerContainer.className = "national-park-map-create-answer-container";
        const yesButton = document.createElement("button");
        yesButton.className = "national-park-map-create-answer-button w4";
        yesButton.textContent = "Yes";
        yesButton.addEventListener("click", this.yesCreateButton, false);

        const noButton = document.createElement("button");
        noButton.className = "national-park-map-create-answer-button w4";
        noButton.textContent = "No";
        noButton.addEventListener("click", this.noCreateButton, false);

        // Insert button into container
        answerContainer.appendChild(yesButton);
        answerContainer.appendChild(noButton);

        // Insert content into container
        createPopUp.appendChild(content);
        createPopUp.appendChild(answerContainer);

        // Set up a new overlay
        this.createPopUpOverlay = new Overlay({
            position: fromLonLat([ location.longitude, location.latitude ]),
            positioning: "bottom-center",
            element: createPopUp,
            autoPan: true,
            //stopEvent: false,
        });

        this.openMap.addOverlay(this.createPopUpOverlay);

        // add temp marker
        this.setMarkerPulse(location);

    }


    removeCreatePopUp() {
        this.openMap.removeOverlay(this.createPopUpOverlay);
        this.openMap.removeOverlay(this.markerPulseOverlay);
    }


    yesCreateButton() {
        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && this.props.userInfo !== null);
        //console.log("NationalParkMap / yesCreateButton - loggedIn = ", loggedIn);
        //console.log("NationalParkMap / yesCreateButton - this.location = ", this.location);

        if (loggedIn) {
            // Open create modal
            this.props.storeCreate({
                modalOn: true,
                mode: "create",
                setSlug: this.props.setSlug,
                location: this.location,
                info: null
            });

            // Reset create locatipn
            this.location = null;
        }
        else {
            // Open log in
            this.props.storeLogInSimpleOn(true);
        }

        // remove create pop up overlay
        this.removeCreatePopUp();
    }

    noCreateButton() {
        // Reset create locatipn
        this.location = null;

        // remove create pop up overlay
        this.removeCreatePopUp();
    }


    /*
    ============================================================================================
        Zoom Actions
    ============================================================================================
    */

    zoomToFit(updateZoomOutLevel, updateCenter, fitBoundary, callback) {
        //console.log("NationalParkMap / zoomToFit - updateZoomOutLevel = ", updateZoomOutLevel);
        //console.log("NationalParkMap / zoomToFit - fitBoundary = ", fitBoundary);
        if (
            (this.boundarySource !== null && fitBoundary) ||
            (this.markersSource !== null && !fitBoundary)
        ) {
            //console.log("NationalParkMap / zoomToFit");

            // Turn on zoom to fit progress status
            this.zoomToFitOn = true;

            // Get current view
            const view = this.openMap.getView();

            // Get the extent
            let extent = null;
            if (fitBoundary) {
                extent = this.boundarySource.getExtent();
            }
            else {
                extent = this.markersSource.getExtent();
            }

            view.fit(
                extent,
                {
                    duration: 0,
                    padding: [ 50, 50, 50, 50 ],
                    constrainResolution: false,
                    callback: () => {
                        // Update map size
                        this.openMap.updateSize();

                        // Update map zoom out level
                        if (updateZoomOutLevel) {
                            this.zoomOutLevel = this.openMap.getView().getZoom();
                            //console.log("NationalParkMap / zoomToFit - this.zoomOutLevel = ", this.zoomOutLevel);
                        }

                        // Update map center
                        if (updateCenter) {
                            // Map center
                            const centerCoordinates = this.openMap.getView().getCenter();

                            // Convert LonLat
                            const centerLonLat = projection.transform(centerCoordinates, 'EPSG:3857', 'EPSG:4326');

                            // Change Data form
                            const centerLocation = {
                                latitude: centerLonLat[1],
                                longitude: centerLonLat[0]
                            };
                            //console.log("NationalParkMap /  zoomToFit - centerLocation = ", centerLocation);

                            // Update boundary center
                            this.boundaryCenter = centerLocation;
                        }

                        // Turn off zoom to fit progress status
                        this.zoomToFitOn = false;

                        // Execute callback
                        if (callback && (typeof callback === "function")) {
                            callback();
                        }
                    }
                }
            );
        }
    }


    /*
    ============================================================================================
        Get max zoom
    ============================================================================================
    */

    getZoom(zoom) {
        let adjustedZoom = null;
        if (this.cloudTiles) {
            adjustedZoom = Math.min(this.maxZoomCloudDict[String(this.props.mapType)], zoom);
        }
        else {
            adjustedZoom = Math.min(this.maxZoomDict[String(this.props.mapType)], zoom);
        }

        return adjustedZoom;
    }


    /*
    ============================================================================================
        Mouse Actions State Updaters
    ============================================================================================
    */

    panToDot(dotIndex, callback, zoomIn) {
        console.log("NationalParkMap / panToDot - this.zoomInLevel = ", this.zoomInLevel);
        console.log("NationalParkMap / panToDot - this.props.mapZoom = ", this.props.mapZoom);

        // Get the view of the map
        const view = this.openMap.getView();
        const dotLocation = this.props.dotsInfo[dotIndex].location;
        const dotCoordinates = fromLonLat([ dotLocation.longitude, dotLocation.latitude ]);

        if (zoomIn) {
            // Pan to and zoom in on a dot
            view.animate(
                {
                    duration: this.props.scrollTime,
                    center: dotCoordinates,
                    zoom: this.getZoom(this.zoomInLevel)
                },
                callback
            );
        }
        else {
            // Pan to a dot
            view.animate(
                {
                    duration: this.props.scrollTime,
                    center: dotCoordinates
                },
                callback
            );
        }
    }

    panToCenter(callback) {
        // Get the view of the map
        const view = this.openMap.getView();
        const boundaryCenterCoordinates = fromLonLat(
            [ this.boundaryCenter.longitude, this.boundaryCenter.latitude ]
        );

        // Zoom in and center the map
        view.animate(
            {
                duration: this.props.scrollTime,
                center: boundaryCenterCoordinates,
                zoom: this.getZoom(this.zoomOutLevel)
            },
            callback
        );
    }

    hoverOn(dotIndex) {
        // Pan to hovered dot
        //this.panToDot(this.props.hovered, null, false);

        // remove hovered marker information
        this.markerHoverOff();

        // hovered marker information
        this.setMarkerInformation(
            this.props.hovered,
            null,
            true
        );
    }

    markerHoverOn(dotIndex, childIndex) {
        // remove hovered marker information
        this.markerHoverOff();

        // hovered marker information
        this.setMarkerInformation(
            dotIndex,
            childIndex,
            true
        );
    }

    markerHoverOff() {
        // remove hovered marker information
        this.openMap.removeOverlay(this.hoveredMarkerInformationOverlay);
    }


    /*
    ============================================================================================
        Nearby Search
    ============================================================================================
    */

    nearbySearch(updatePrevMapSettings) {
        //console.log("NationalParkMap - nearbySearch")
        // Map Center
        const extentCenter = this.openMap.getView().getCenter();

        // Convert LonLat
        const extentCenterLonLat = projection.transform(extentCenter, 'EPSG:3857', 'EPSG:4326');

        // Change Data form
        const centerLocation = {
            latitude: extentCenterLonLat[1],
            longitude: extentCenterLonLat[0]
        };

        // Detect Map Extent
        const extent = this.openMap.getView().calculateExtent(this.openMap.getSize());
        const bottomLeft = toLonLat(getBottomLeft(extent));
        const topRight = toLonLat(getTopRight(extent));

        // Get coordinates of map corners
        const lat1 = bottomLeft[1];
        const lon1 = bottomLeft[0];
        const lat2 = topRight[1];
        const lon2 = topRight[0];

        // Get distance
        const distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);   // km   1km = 0.621371 mile

        // Search resorce for server requset
        const searchRadius = Number(distance / 2 * 0.621371).toFixed(4); // covert km to mile

        // Convert search location to string
        const centerLocationString = "{'latitude':"
            + centerLocation.latitude
            + ",'longitude':"
            + centerLocation.longitude
            + "}";

        // Callback
        const axiosCallback = (response) => {
            console.log("NationalParkMap / nearbySearch - response = ", response);

            // Disable unselect zoom out
            this.unselectZoomOut = null;

            // Update parent state
            this.props.setState(
                {
                    searchMode: "nearby",
                    searchCenterLocation : centerLocation,
                    searchRadius : searchRadius,
                    hovered: 0,
                    selected: null,
                    mapInitialized: true
                }
            );

            // Update previous map settings
            if (updatePrevMapSettings) {
                this.prevZoom = this.openMap.getView().getZoom();
                this.prevCenter = extentCenter;
            }
        };

        // Send request to server
        getNearbySearch(
            "nearby",
            centerLocationString,
            searchRadius,
            'mi',   // unit
            null,   // keywords
            1
        )
        .then(axiosCallback)
        .catch(
            (response) =>{
                console.log("[WARNING] Search / nearbySearch - error = ", response);
            }
        );

    }


    /*
    ============================================================================================
        Near By Search Popup
    ============================================================================================
    */

    nearbySearchPopUp(extentCenter) {
        // Remove nearbySearch pop up overlay
        this.removeNearbySearchPopUp();

        const nearbySearchPopUp = document.createElement("div");
        nearbySearchPopUp.className = "national-park-map-near-by-search-box";
        const content = document.createElement("div");
        content.className = "national-park-map-near-by-search-box-text w4";
        content.textContent = "Search area";

        const answerContainer = document.createElement("div");
        answerContainer.className = "national-park-map-near-by-search-answer-container";
        const yesButton = document.createElement("button");
        yesButton.className = "national-park-map-near-by-search-answer-button w4";
        yesButton.textContent = "Update";
        yesButton.addEventListener("click",
            (event) => {
                // Initiate nearby search
                this.yesNearbySearchButton(event, extentCenter);
            },
            false
        );

        /*
        const noButton = document.createElement("button");
        noButton.className = "national-park-map-near-by-search-answer-button w4";
        noButton.textContent = "No";
        noButton.addEventListener("click", this.noNearbySearchButton, false);
        */

        // Insert button into container
        answerContainer.appendChild(yesButton);
        //answerContainer.appendChild(noButton);

        // Insert content into container
        nearbySearchPopUp.appendChild(content);
        nearbySearchPopUp.appendChild(answerContainer);

        // Set up a new overlay
        this.nearbySearchPopUpOverlay = new Overlay({
            position: [ extentCenter[0], extentCenter[1] ],
            positioning: "bottom-center",
            element: nearbySearchPopUp,
            autoPan: true,
            offset: [0, this.state.mapHeight / 2]
            //stopEvent: false,
        });

        this.openMap.addOverlay(this.nearbySearchPopUpOverlay);
    }


    removeNearbySearchPopUp() {
        this.openMap.removeOverlay(this.nearbySearchPopUpOverlay);
    }


    yesNearbySearchButton(event, extentCenter) {

        // Stop event propagation
        event.stopPropagation();

        // Near By Search
        this.nearbySearch(true);

        // Remove nearby search pop up overlay
        this.removeNearbySearchPopUp();
    }


    noNearbySearchButton(event) {
        // Stop event propagation
        event.stopPropagation();

        // Remove nearby search pop up overlay
        this.removeNearbySearchPopUp();
    }


    /*
    ============================================================================================
        Map Listeners
    ============================================================================================
    */

    zoomPanListener() {
        // Set up zoom event listener and layer
        this.zoomPanListenerKey = this.openMap.on(
            "postrender",//"moveend",
            (event) => {
                //console.log("NationalParkMap / zoomPanListener - this.unselectZoomOut = ", this.unselectZoomOut);
                //console.log("NationalParkMap / zoomPanListener - this.nearbySearchOn = ", this.nearbySearchOn);

                // Remove nearbySearch pop up overlay
                this.removeNearbySearchPopUp();

                // Remove create pop up overlay
                this.removeCreatePopUp();

                // Nearby search
                if (this.nearbySearchOn === null) {
                    this.nearbySearchOn = true;
                }
                else {
                    //console.log("NationalParkMap / zoomPanListener - this.prevZoom = ", this.prevZoom);
                    //console.log("NationalParkMap / zoomPanListener - this.prevCenter = ", this.prevCenter);
                    //console.log("NationalParkMap / zoomPanListener - this.props.selected = ", this.props.selected);
                    if (this.prevZoom !== null && this.prevCenter !== null) {
                        //console.log("NationalParkMap / zoomPanListener - this = ", this);
                        //console.log("NationalParkMap / zoomPanListener - this.prevZoom = ", this.prevZoom);
                        //console.log("NationalParkMap / zoomPanListener - this.prevCenter = ", this.prevCenter);
                        //console.log("NationalParkMap / zoomPanListener - this.props.selected = ", this.props.selected);

                        // Current zoom and center
                        const currentZoom = this.openMap.getView().getZoom();
                        const currentCenter = this.openMap.getView().getCenter();
                        //console.log("NationalParkMap / zoomPanListener - currentCenter = ", currentCenter);

                        // Detect map extent (bottom / left / top / right)
                        const extent = this.openMap.getView().calculateExtent(this.openMap.getSize());
                        const extentWidth = Math.abs(extent[3] - extent[1]);
                        const extentHeight = Math.abs(extent[2] - extent[0]);
                        const extentRadius = Math.sqrt(
                            Math.pow(extentWidth / 2, 2) +
                            Math.pow(extentHeight / 2, 2)
                        );

                        // Calculate radius
                        const zoomDiff = Math.abs(currentZoom - this.prevZoom);
                        const centerDiff = Math.sqrt(
                            Math.pow(this.prevCenter[0] - currentCenter[0], 2) +
                            Math.pow(this.prevCenter[1] - currentCenter[1], 2)
                        );

                        // Determine if new search is necessary
                        if (zoomDiff >= this.zoomTolerance || (centerDiff / extentRadius) >= this.radiusTolerance) {
                            // Near By Search PopUp
                            this.nearbySearchPopUp(currentCenter);
                        }
                    }
                }
            }
        );
    }

    markerClickListener() {
        // Set up click event listener
        this.markerClickListenerKey = this.openMap.on(
            "click",
            (event) => {
                //console.log("NationalParkMap / markerClickListener - click");

                // Initialize clicked feature and layer
                let clickedFeature = null;
                let clickedLayer = null;

                // Detect feature
                this.openMap.forEachFeatureAtPixel(
                    event.pixel,
                    (detectedFeature, detectedLayer) => {
                        clickedFeature = detectedFeature;
                        clickedLayer = detectedLayer;
                    },
                    {
                        hitTolerance: 0,
                        layerFilter: (detectedLayer) => {
                            return (detectedLayer === this.markersLayer) ||
                            (detectedLayer === this.childMarkersLayer);
                        }
                    }
                );

                if (clickedFeature && ((clickedLayer.get("name") === "marker") || (clickedLayer.get("name") === "child"))) {
                    // Move feed to the corresponding item
                    const dotIndex = clickedFeature.get("dotIndex");
                    if (dotIndex !== null && dotIndex !== undefined) {

                        // Select item with no zoom action
                        this.props.itemClick(event, dotIndex, null);

                        /*
                        // Update hovered first
                        this.props.setState({
                            hovered: dotIndex,
                            selected: dotIndex
                        });

                        // Scroll to item
                        this.props.scrollToItem(dotIndex);
                        */
                    }
                }
            }
        );
    }


    /*
    ============================================================================================
        National Park Boundary
    ============================================================================================
    */

    loadBoundary() {
        //console.log("NationalParkMap / loadBoundary");

        const axiosCallback = (response) => {
            //console.log("NationalParkMap / loadBoundary - response.data.content = ", response.data.content);

            // Choose the park boundary
            const geoJson = JSON.parse(response.data.content);
            //console.log("NationalParkMap / loadBoundary - geoJson = ", geoJson);

            // Render boundary
            this.renderBoundary(geoJson);
        };

        getGeographies(this.props.nationalParkCode)
        .then(axiosCallback)
        .catch((response) => {
            console.log("NationalParkMap / loadBoundary - Axios error ", response)
        });
    }

    renderBoundary(geoJson) {
        // Create features
        const boundaryFeatures = new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(geoJson);

        // Vector source
        const boundarySource = new VectorSource({
            features: boundaryFeatures
        });

        // Vector layer
        const boundaryLayer = new VectorLayer({
            className: "national-park-map-boundary-layer",
            updateWhileInteracting: true,
            source: boundarySource,
            style: new Style({
                stroke: new StrokeStyle({
                    color: "#216C95",
                    width: 6
                })
            }),
            zIndex: 130,
            opacity: 0.55
        });

        // Shadow Layer
        const shadowGeoJson = {
            "type": "Feature",
            "properties": {
                "name": "Shadow"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates":
                [[
                    fromLonLat([ -180, 90 ]),
                    fromLonLat([ -180, -90 ]),
                    fromLonLat([ 180, -90 ]),
                    fromLonLat([ 180, 90 ]),
                    fromLonLat([ -180, 90 ])
                ]]
            }
        };

        // Create features
        const shadowFeature = (
            new GeoJSON({})
        ).readFeatures(shadowGeoJson);

        // Vector source
        const shadowSource = new VectorSource({
            features: shadowFeature
        });

        // Vector layer
        const shadowLayer = new VectorLayer({
            className: "national-park-map-shadow-layer",
            updateWhileInteracting: true,
            source: shadowSource,
            style: new Style({
                fill: new FillStyle({
                    color: "#000000"
                })
            }),
            zIndex: 130,
            opacity: 0.55
        });

        // Set name
        boundaryLayer.set("name", "Park Boundary", true);
        shadowLayer.set("name", "Map Shadow", true);

        // Remove overlap layer
        shadowLayer.on(
            "postrender",
            (event) => {
                const vectorContext = getVectorContext(event);
                event.context.globalCompositeOperation = 'destination-out';

                boundaryLayer.getSource().forEachFeature(
                    function (feature) {
                    vectorContext.drawFeature(
                        feature,
                        new Style({
                            fill: new FillStyle({
                                color: "#000000"
                            })
                        })
                    );
                });

                event.context.globalCompositeOperation = 'source-over';
            }
        );

        // Add polygon to the map
        this.openMap.addLayer(boundaryLayer);
        this.openMap.addLayer(shadowLayer);

        // Store sources and layers
        this.boundarySource = boundarySource;
        this.boundaryLayer = boundaryLayer;
        this.shadowSource = shadowSource;
        this.shadowLayer = shadowLayer;

        // Zoom to fit boundary
        this.zoomToFit(
            true,
            true,
            (this.state.markers === null),
            ()=> {
                // Re-initialize nearby search flag
                this.nearbySearchOn = null;

                // Record map zoom and center
                this.prevZoom = this.openMap.getView().getZoom();
                this.prevCenter = this.openMap.getView().getCenter();
            }
        );
    }

    getFeatureStyle(feature) {
        const image = getStaticPath("/images/map/open-map-point.png", false);

        const styles = {
            "Point": new Style({
                image: image
            }),
            "LineString": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 10
                })
            }),
            "MultiLineString": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 12
                })
            }),
            "MultiPoint": new Style({
                image: image
            }),
            "MultiPolygon": new Style({
                stroke: new StrokeStyle({
                    color: "#216C95",
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            }),
            "Polygon": new Style({
                stroke: new StrokeStyle({
                    color: "#216C95",
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#000000"
                })
            }),
            "GeometryCollection": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                }),
                image: new CircleStyle({
                    radius: 10,
                    fill: null,
                    stroke: new StrokeStyle({
                        color: "#4EA3D0"
                    })
                })
            }),
            "Circle": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 10
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            })
        };

        return styles[feature.getGeometry().getType()];
    };
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        userInfo: state.user.userInfo,
        create: state.create.create
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeLogInOn,
            storeLogInSimpleOn,
            storeWarningAlert,
            storeNotificationAlert,
            storeCreate
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(NationalParkMap);