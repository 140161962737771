/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Interaction.js
    - User interaction components and fuctions
--------------------------------------------------------------------------------------------
    Content
    - InteractionStatBox
    - InteractionButtonBox
    - checkLike
    - checkSave
    - OpenStatusBox
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";

// Functions
import { 
    getStaticPath, 
    formatNumbers
} from "js/Functions";

// Axios
import { 
    postDotLike,
    postDotSave, 
    postCheckLike, 
    postCheckSave, 
} from "requests";

// CSS
import "css/Interaction.scss";


/*
============================================================================================
    InteractionStatBox
--------------------------------------------------------------------------------------------
    - display user likes / saves / completes / favorites
    - props
    {
        setState: update states of the parent component
        likeCount: like count state of the parent component
        saveCount: save count state of the parent component
        info: tripInfo / dotsInfo / hikeDotsInfo
        infoType: "trip" / "dot" / "hikedot"
        size: "small" / "medium" / "large"
    }
============================================================================================
*/

function InteractionStatBox (props) {
    // button images
    const likeImage = (props.colorMode === "day")?
        getStaticPath("/images/common/like-on-red.png") :
        getStaticPath("/images/common/like-on-white.png");

    const saveImage = (props.colorMode === "day")?
        getStaticPath("/images/common/bucket-on-black.png") :
        getStaticPath("/images/common/bucket-on-white.png");

    //const completeImage = (props.colorMode === "day")?
    //    getStaticPath("/images/common/complete-on-black.png") :
    //    getStaticPath("/images/common/complete-on-white.png");

    //const favoriteImage = (props.colorMode === "day")?
    //    getStaticPath("/images/common/favorite-on-black.png") :
    //    getStaticPath("/images/common/favorite-on-white.png");

    // if user is logged in and need the responsive like count
    const likeCount = (props.likeCount != null)? 
        props.likeCount : props.info.liked_user_count;
    const like = (likeCount > 0)? (
        <div className = "interaction-stat-box-float">
            <div className = {"interaction-stat-box-like-" + props.size}
                style = {{backgroundImage: likeImage}}
            >
            </div>
            <div className = {"interaction-stat-box-number-" + props.size}>
                {formatNumbers(likeCount)}
            </div>
        </div>
    ): null;

    // if user is logged in and need the responsive save count
    const saveCount = (props.saveCount != null)? 
        props.saveCount : props.info.saved_user_count;
    const save = (saveCount > 0)? (
        <div className = "interaction-stat-box-float">
            <div className = {"interaction-stat-box-save-" + props.size}
                style = {{backgroundImage: saveImage}}
            >
            </div>
            <div className = {"interaction-stat-box-number-" + props.size}>
                {formatNumbers(saveCount)}
            </div>
        </div>
    ): null;

    /*
    const complete = (props.info.completed_user_count > 0) ? (
        <div className = "interaction-stat-box-float">
            <div className = {"interaction-stat-box-complete-" + props.size}
                style = {{backgroundImage: completeImage}}
            >
            </div>
            <div className = {"interaction-stat-box-number-" + props.size}>
                {formatNumbers(props.info.completed_user_count)}
            </div>
        </div>
    ): null;

    const favorite = (props.info.favorited_user_count > 0)? (
        <div className = "interaction-stat-box-float">
            <div className = {"interaction-stat-box-favorite-" + props.size}
                style = {{backgroundImage: favoriteImage}}
            >
            </div>
            <div className = {"interaction-stat-box-number-" + props.size}>
                {formatNumbers(props.info.favorited_user_count)}
            </div>
        </div>
    ): null;
    */

    return(
        <div className = "interaction-stat-box">
            {like}
            {save}
        </div>
    );

    //{complete}
    //{favorite}
}


/*
============================================================================================
    InteractionButtonBox
--------------------------------------------------------------------------------------------
    - display user like / save / help buttons
    - props
    {
        setState: update states of the parent component
        likedByMe: if the logged in user already liked this object
        savedByMe: if the logged in user already saved this object
        likeButtonOnOpacity: opacity of like button when liked
        likeButtonOffOpacity: opacity of like button when not liked
        saveButtonOnOpacity: opacity of save button when saved
        saveButtonOffOpacity: opacity of save button when not saved
        info: tripInfo / dotsInfo / hikeDotsInfo
        infoType: "trip" / "dot" / "hikedot"
    }    
============================================================================================
*/

function InteractionButtonBox (props) {
    // if user is logged in
    const loggedIn = (localStorage.token)? true: false;

    // like button
    const likeButtonOpacity = (props.likedByMe)? props.likeButtonOffOpacity : props.likeButtonOnOpacity;
    const likeButtonText = (props.likedByMe)? "Liked" : "Like";
    const likeButtonStyle = {
        opacity: likeButtonOpacity
    };

    const likeButtonClickProps = {
        setState: props.setState, 
        likedByMe: props.likedByMe,
        info: props.info,
        infoType: props.infoType
    };

    const likeButtonMouseEnterProps = {
        setState: props.setState,
        likeButtonOnOpacity: 0.5
    };

    const likeButtonMouseLeaveProps = {
        setState: props.setState,
        likeButtonOffOpacity: 0.3
    };

    const likeButton = (loggedIn)? (
            <div className = {"interaction-button-box-like-" + props.size}
                style = {likeButtonStyle}
                onClick = {likeButtonClick.bind(null, likeButtonClickProps)}
                onMouseEnter = {likeButtonMouseEnter.bind(null, likeButtonMouseEnterProps)}
                onMouseLeave = {likeButtonMouseLeave.bind(null, likeButtonMouseLeaveProps)}
            >
                {likeButtonText}
            </div>
    ):null;

    // save button
    const saveButtonOpacity = (props.savedByMe)? props.saveButtonOffOpacity : props.saveButtonOnOpacity;
    const saveButtonText = (props.savedByMe)? "Saved" : "Save";
    const saveButtonStyle = {
        opacity: saveButtonOpacity
    };

    const saveButtonClickProps = {
        setState: props.setState,
        savedByMe: props.savedByMe,
        info: props.info,
        infoType: props.infoType
    };

    const saveButtonMouseEnterProps = {
        setState: props.setState,
        saveButtonOnOpacity: 0.5
    };

    const saveButtonMouseLeaveProps = {
        setState: props.setState,
        saveButtonOffOpacity: 0.3
    };

    const saveButton = (loggedIn)? (
            <div className = {"interaction-button-box-save-" + props.size}
                style = {saveButtonStyle}
                onClick = {saveButtonClick.bind(null, saveButtonClickProps)}
                onMouseEnter = {saveButtonMouseEnter.bind(null, saveButtonMouseEnterProps)}
                onMouseLeave = {saveButtonMouseLeave.bind(null, saveButtonMouseLeaveProps)}
            >
                {saveButtonText}
            </div>
    ):null;

    // help button
    const helpButton = (loggedIn)? (
            <div className = {"interaction-button-box-help-" + props.size}
                onClick = {helpButtonClick.bind(null)}
            >
                Help
            </div>
    ):null;

    // construct button box
    return(
        <div className = "interaction-button-box">    
            {helpButton}
            {saveButton}
            {likeButton}
        </div>
    )
}


/*
============================================================================================
    likeButtonClick
--------------------------------------------------------------------------------------------
    - send like request to server and update
    - props
    {
        setState: update states of the parent component
        likedByMe: if the logged in user liked the object
        info: tripInfo / dotsInfo / hikeDotsInfo
        infoType: "trip" / "dot" / "hikedot"
        likeButtonOnOpacity: opacity of like button when liked
        likeButtonOffOpacity: opacity of like button when not liked        
    }  
============================================================================================
*/

function likeButtonClick(props, event) {
    //console.log("Interaction / likeButtonClick - props = ", props);
    //console.log("Interaction / likeButtonClick - event = ", event);
    
    // Stop propagation to parent
    event.stopPropagation();

    // Update likeCount and likedByMe
    const axiosCallback = (response) => {
        //console.log("Interaction / likeButtonClick - response.data.content = ", response.data.content);

        // Update local and global state
        props.setState(
            {
                likeCount: response.data.content.liked_user_count,
                likedByMe: response.data.content.liked_by_me
            },
            () => {
                // Construct a shallow copy of the userInfo
                const userInfo = Object.assign({}, props.userInfo);

                // Update user info
                userInfo.liked_dots_recent = response.data.content.liked_dots_recent; 
                userInfo.liked_dot_count = response.data.content.liked_dot_count;

                // Dispatch to Redux store
                props.storeUser(userInfo);
                //console.log("Interaction / likeButtonClick - props.storeNewLike = ", props.storeNewLike);

                if (props.storeNewLike && typeof props.storeNewLike === "function") {
                    props.storeNewLike({
                        id: props.dotInfo.id,
                        liked: response.data.content.liked_by_me,
                        count: response.data.content.liked_user_count
                    });
                }
            }            
        );
    };

    // Determine the request type
    const mode = (props.likedByMe)? "destroy" : "create";

    // Request data
    let dataJSON = {
        dot_id: props.dotInfo.id,
        user_id: props.userInfo.id,
        mode: mode
    };

    // Send like or unlike request
    postDotLike(props.dotInfo.slug, dataJSON)
    .then(axiosCallback)
    .catch((response) => {console.log("[WARNING] Interaction / likeButtonClick - error = ", response);})
}

function likeButtonMouseEnter(props) {
    props.setState({
        likeButtonOpacity: props.likeButtonOnOpacity
    });
}

function likeButtonMouseLeave(props) {
    props.setState({
        likeButtonOpacity: props.likeButtonOffOpacity
    });
}


/*
============================================================================================
    saveButton
--------------------------------------------------------------------------------------------
    - send save request to server and update
    - props
    {
        setState: update states of the parent component
        info: tripInfo / dotsInfo / hikeDotsInfo
        infoType: "trip" / "dot" / "hikedot"
        saveButtonOnOpacity: opacity of save button when saved
        saveButtonOffOpacity: opacity of save button when not saved
    }  
============================================================================================
*/

function saveButtonClick(props, event) {
    //console.log("Interaction / saveButtonClick - props = ", props);
    //console.log("Interaction / saveButtonClick - event = ", event);
    
    // Stop propagation to parent
    event.stopPropagation();

    // Update saveCount and savedByMe
    const axiosCallback = (response) => {
        //console.log("Interaction / saveButtonClick / props = ", props);
        //console.log("Interaction / saveButtonClick - response = ", response);
        //console.log("Interaction / saveButtonClick - response.data.content = ", response.data.content);

        // Update local and global state
        props.setState(
            {
                saveCount: response.data.content.saved_dot_count,
                savedByMe: response.data.content.saved_by_me
            }, 
            () => {
                // Construct a shallow copy of the userInfo
                const userInfo = Object.assign({}, props.userInfo);

                // Update user info
                userInfo.saved_dots_recent = response.data.content.saved_dots_recent; 
                userInfo.saved_dot_count = response.data.content.saved_dot_count;

                // Dispatch to Redux store
                props.storeUser(userInfo);
                if (props.storeNewSave && typeof props.storeNewSave === "function") {
                    props.storeNewSave({
                        id: props.dotInfo.id,
                        saved: response.data.content.saved_by_me,
                        count: response.data.content.saved_user_count
                    });
                }
            }
        );
    };

    // Determine the request type
    const mode = (props.savedByMe)? "destroy" : "create";

    // Request data
    let dataJSON = {
        dot_id: props.dotInfo.id,
        user_id: props.userInfo.id,
        mode: mode
    };

    // send save or unsave request
    postDotSave(props.dotInfo.slug, dataJSON)
    .then(axiosCallback)
    .catch((response) => {console.log("[WARNING] Interaction / saveButtonClick - error = ", response);})
}

function saveButtonMouseEnter(props) {
    props.setState({
        saveButtonOpacity: props.saveButtonOnOpacity
    });
}

function saveButtonMouseLeave(props) {
    props.setState({
        saveButtonOpacity: props.saveButtonOffOpacity
    });
}


/*
============================================================================================
    helpButton
--------------------------------------------------------------------------------------------
    - make help contributions
    - props
    {

    }  
============================================================================================
*/

function helpButtonClick(props, event) {
    //console.log("Interaction / helpButtonClick - props = ", props);
    //console.log("Interaction / helpButtonClick - event = ", event);
    
    // Stop propagation to parent
    event.stopPropagation();

    console.log("Interaction / helpButton - clicked");
}


/*
============================================================================================
    checkLike
--------------------------------------------------------------------------------------------
    - check if the object is liked by the logged in user
    - props
    {
        setState: update states of the parent component
        info: tripInfo / dotInfo / hikeDotInfo
        infoType: "trip" / "dot" / "hikedot"
    }  
============================================================================================
*/

function checkLike(setState, info, infoType) {
    // check if the object is liked by the logged in user
    const axiosCallback = (response) => {
        //console.log("Interaction / checkLike - response.data.content = ", response.data.content);
        setState(
            {
                likeCount: response.data.content.like_count,
                likedByMe: response.data.content.liked_by_me,
            }
        );
    };

    let dataJSON = {
        object_id: info.id,
        object_type: infoType
    }
    postCheckLike(dataJSON)
    .then(axiosCallback)
    .catch((response) => {console.log("[WARNING] Interaction / checkLike - error = ", response);})
}


/*
============================================================================================
    checkSave
--------------------------------------------------------------------------------------------
    - check if the object is saved by the logged in user
    - props
    {
        setState: update states of the parent component
        info: tripInfo / dotsInfo / hikeDotsInfo
        infoType: "trip" / "dot" / "hikedot"
    }  
============================================================================================
*/

function checkSave(setState, info, infoType) {
    // check if the object is saved by the logged in user
    const axiosCallback = (response) => {
        //console.log("Interaction / checkSave - response.data.content = ", response.data.content);
        setState(
            {
                saveCount: response.data.content.save_count,
                savedByMe: response.data.content.saved_by_me,
            }
        );
    };

    // send delete request
    let dataJSON = {
        object_id: info.id,
        object_type: infoType
    }
    postCheckSave(dataJSON)
    .then(axiosCallback)
    .catch((response) => {console.log("[WARNING] Interaction / checkSave - error = ", response);})
   }


/*
============================================================================================
    OpenStatusBox
--------------------------------------------------------------------------------------------
    - shows open and closure status
    - props
    {
        is_open : true or false - shows if trip / dot / hikedot is open or closed
        seasonal_access : true or false - shows if the item is seasonally affected
    }  
============================================================================================
*/

function OpenStatusBox (props) {
    const statusOpen = (props.is_open)? (
            <span className = "open-status-box-open">Currently Open</span>
        ):(
            <span className = "open-status-box-closed">Currently Closed</span>
        );

    const statusSeasonal = (props.seasonal_access)? (
            <span className = "open-status-box-seasonal">Seasonal Access</span>
        ): null;

    return(
        <div className = "open-status-box-container">
            {statusOpen} {statusSeasonal}
        </div>
    )
}

export {
    InteractionStatBox,
    InteractionButtonBox,
    likeButtonClick,
    likeButtonMouseEnter,
    likeButtonMouseLeave,
    saveButtonClick,
    saveButtonMouseEnter,
    saveButtonMouseLeave,
    checkLike,
    checkSave, 
    OpenStatusBox
};
