/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    PlanGallery.js
    - Gallery module developed to show finalized itinerary
--------------------------------------------------------------------------------------------
    Content
    - PlanGallery
    - PlanGalleryItem
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 

// Components
import TimeBar from "./TimeBar";

// Functions
import {
    getMediaProperty,
    getStaticPath, 
    getTransitImage
} from "js/Functions";

// CSS
import "./PlanGallery.scss";


// PlanGallery component
class PlanGallery extends Component {
    constructor (props){
        super(props);

        // Track settings
        this.trackMargin = 24;

        // Media size settings (conserve media area)
        this.mediaMargin = 3;
        this.mediaBorder = 1;
        this.unfocusedMediaArea = 60000;
        this.focusedMediaArea = 300000;
        this.unfocusedMediaOpacity = 0.9;
        this.focusedMediaOpacity = 1.0;
        this.unfocusedTimeOpacity = 0.75;
        this.focusedTimeOpacity = 0.9;
        this.unfocusedDurationOpacity = 0.9;
        this.focusedDurationOpacity = 1.0;

        // Width and height for title and description box
        // Specify minimum and leave it to auto at times for height
        this.textBoxWidth = 600;
        this.textBoxMinHeight = 20;

        // Text box transition time
        this.textBoxTransitionTime = 250;

        // Media Arrays
        this.unfocusedMediaWidths = [];
        this.unfocusedMediaHeights = [];
        this.focusedMediaWidths = [];
        this.focusedMediaHeights = [];

        // Initialize states
        this.state = {
            // Props passed from plan class
            itinerary: this.props.itinerary,

            // Focused dot index 
            // (indexing with respect to the dotsInfo)
            focusedDotIndex: null,
            hoveredDotIndex: null,

            // Window size
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,

            // Track width / position and media positions along the track
            trackWidth: null,
            trackPosition: null,

            // Media size arrays
            mediaWidths: [],
            mediaHeights: [],
            mediaOldWidths: [],

            // Opacity arrays for tansition animation
            textBoxOpacities: [],

            // Text box height
            textBoxHeight: 20,

            // Switch for text box animation
            textBoxFadeSwitch: true,
            textBoxScaleSwitch: true,

            // Display properties for arrows
            prevArrowDisplay: "none",
            nextArrowDisplay: "none",

            // Randomize media sets
            randomizeMediaSets: null
        }

        // Bind functions
        this.setState = this.setState.bind(this);
        this.initializeTrack = this.initializeTrack.bind(this);
        this.mediaClick = this.mediaClick.bind(this);
        this.mediaHoverOn = this.mediaHoverOn.bind(this);
        this.mediaHoverOff = this.mediaHoverOff.bind(this);
        this.updateTrackProperties = this.updateTrackProperties.bind(this);
        this.prevArrowClick = this.prevArrowClick.bind(this);
        this.nextArrowClick = this.nextArrowClick.bind(this);
        this.updateWindowSize = this.updateWindowSize.bind(this);
    }

    render(){
        if ((this.state.mediaWidths) && (this.state.mediaHeights) && (this.state.trackWidth)) {
            /*
            planGalleryItem props
            - mode
            - focusedDotIndex
            - itinerary
            - dotIndex
            - dotsInfo
            - dotTimes
            - mediaWidths
            - mediaHeights
            - mediaOldWidths
            - textBoxOpacities
            - textBoxWidth
            - textBoxHeight
            - textBoxFadeSwitch
            - textBoxScaleSwitch
            - mediaClick
            */
            
            // initialize states
            const planGalleryItemProps = {
                // Color mode
                colorMode: this.props.colorMode,

                // Gallery mode
                mode: this.props.mode,

                // Focused dot
                focusedDotIndex: this.state.focusedDotIndex,

                // Focused dot
                hoveredDotIndex: this.state.hoveredDotIndex,

                // Itinerary
                itinerary: this.props.itinerary,

                // Dot info
                dotsInfo: this.props.dotsInfo,

                // Time
                dotTimes: this.props.dotTimes,

                // Time
                transitTimes: this.props.transitTimes,

                // Time
                transitModes: this.props.transitModes,

                // Media size arrays
                mediaWidths: this.state.mediaWidths,
                mediaHeights: this.state.mediaHeights,
                mediaOldWidths: this.state.mediaOldWidths,

                // Opacity arrays for tansition animation
                textBoxOpacities: this.state.textBoxOpacities,

                // Using fixed box width for improved animation
                textBoxWidth: this.textBoxWidth,

                // Height for title and description box
                textBoxHeight: this.state.textBoxHeight,

                // Switch for text box animation
                textBoxScaleSwitch: this.state.textBoxScaleSwitch,
                textBoxFadeSwitch: this.state.textBoxFadeSwitch,

                // Media focus and hover opacities
                unfocusedMediaOpacity: this.unfocusedMediaOpacity,
                focusedMediaOpacity: this.focusedMediaOpacity,
                unfocusedTimeOpacity: this.unfocusedTimeOpacity,
                focusedTimeOpacity: this.focusedTimeOpacity,
                unfocusedDurationOpacity: this.unfocusedDurationOpacity,
                focusedDurationOpacity: this.focusedDurationOpacity,

                // Event handlers
                mediaClick: this.mediaClick,
                mediaHoverOn: this.mediaHoverOn,
                mediaHoverOff: this.mediaHoverOff
            }

            // Only if list of media became properties
            const planGalleryItemList = this.props.itinerary.map((dotIndex, index) => {
                // Link media sizes with width style of DOM columns
                const itemColumnStyle = (this.state.mediaWidths != null)?
                    {
                        width: this.state.mediaWidths[index] + 2 * (this.mediaBorder + this.mediaMargin),
                    }: null;

                // Return an individual item
                return(
                    <div key = {"plan-gallery-item-container-" + dotIndex.toString()} 
                        className = "plan-gallery-item-column" 
                        style = {itemColumnStyle}
                    >
                        <PlanGalleryItem 
                            key = {"plan-gallery-item-" + dotIndex.toString()}
                            dotIndex = {dotIndex}
                            {...planGalleryItemProps} 
                        />
                    </div>
                )
            });

            // Previous and next navigation arrows
            const prevArrowProps = {
                className : "plan-gallery-prev-arrow",
                onClick : this.prevArrowClick,
                style : { display: this.state.prevArrowDisplay }
            }
            const nextArrowProps = {
                className : "plan-gallery-next-arrow",
                onClick : this.nextArrowClick,
                style : { display: this.state.nextArrowDisplay }
            }

            // Text for navigation arrows
            const prevArrowText = "<";
            const nextArrowText = ">";

            /*
            ============================================================================================
                Time Bar
            ============================================================================================
            */

            // set the props for TimeBar component
            const timeBarProps = {
                itinerary: this.props.itinerary,
                startMoment: this.props.startMoment,
                endMoment: this.props.endMoment,
                dotsInfo: this.props.dotsInfo,
                transitTimes: this.props.transitTimes,
                transitTimeValues: this.props.transitTimeValues,
                transitModes: this.props.transitModes,
                focusedDotIndex: this.state.focusedDotIndex,
                hoveredDotIndex: this.state.hoveredDotIndex,
                screenWidth: this.state.screenWidth,
                //displayHike: this.state.displayHike
                setState: this.setState,
                mediaClick: this.mediaClick
            }
            //console.log("PlanGallery / render - timeBarProps = ", timeBarProps);
            const timeBar = (<TimeBar {...timeBarProps} />);

            // Render the list of items
            return(
                <div className="plan-gallery-wrapper">
                    {timeBar}
                    <div key = "plan-gallery-prev-arrow"
                        {...prevArrowProps}
                    >
                        {prevArrowText}
                    </div>
                    <div key = "plan-gallery-next-arrow"
                        {...nextArrowProps}
                    >
                        {nextArrowText}
                    </div>
                    <div className = "plan-gallery-track" 
                        style = {{ left: this.state.trackPosition }}
                    >
                        {planGalleryItemList}
                    </div>
                </div>
            );
        }
        else{
            return null;
        }
    }

    // Set up an event listener for window size change
    componentDidMount() {
        // Set up the event listener
        window.addEventListener("resize", this.updateWindowSize);

        // TMP - Update media and track properties (to avoid getDerivedStateFromProps)
        this.updateStatesFromProps(this.props, this.initializeTrack);
    }


    // Cancel the event listener for window size change
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowSize);
    }


    // Update trackWidth / trackPosition / mediaPositions
    componentDidUpdate (prevProps, prevState) {
        // Temporary - Update media and track properties (to avoid getDerivedStateFromProps)
        if (prevProps.itinerary !== this.props.itinerary) {
            this.updateStatesFromProps(this.props, this.initializeTrack);
        }
    }


    // Update basic states from props (to avoid getDerivedStateFromProps)
    updateStatesFromProps(nextProps, callback) {
        //console.log("PlanGallery / updateStatesFromProps - nextProps = ", nextProps);
        // Reset all media size arrays
        this.unfocusedMediaWidths = [];
        this.unfocusedMediaHeights = [];
        this.focusedMediaWidths = [];
        this.focusedMediaHeights = [];

        // For all itinerary dots
        for (let index = 0; index < nextProps.itinerary.length; index++) {
            // Get the dot index (with respect to dotsInfo)
            const dotIndex = nextProps.itinerary[index];

            // Get the media dimensions for the right size (medium size: "m")
            // Choosing only the first media for now
            const fileMediaInfo = nextProps.dotsInfo[dotIndex].media[0];

            // Pick the properties of the right size
            const fileMediaWidth = getMediaProperty(fileMediaInfo, "o", 'width', false);
            const fileMediaHeight = getMediaProperty(fileMediaInfo, "o", 'height', false);

            // Calculate the new sizes
            // Resized to fit the specified regular and focused media sizes
            const resizedUnfocusedRatio = Math.sqrt(this.unfocusedMediaArea / (fileMediaWidth * fileMediaHeight));
            const resizedUnfocusedMediaWidth = Math.round(fileMediaWidth * resizedUnfocusedRatio);
            const resizedUnfocusedMediaHeight = Math.round(fileMediaHeight * resizedUnfocusedRatio);

            const resizedFocusedRatio = Math.sqrt(this.focusedMediaArea / (fileMediaWidth * fileMediaHeight));
            const resizedFocusedMediaWidth = Math.round(fileMediaWidth * resizedFocusedRatio);
            const resizedFocusedMediaHeight = Math.round(fileMediaHeight * resizedFocusedRatio);

            // Add the sizes to the lists
            this.unfocusedMediaWidths.push(resizedUnfocusedMediaWidth);
            this.unfocusedMediaHeights.push(resizedUnfocusedMediaHeight);
            this.focusedMediaWidths.push(resizedFocusedMediaWidth);
            this.focusedMediaHeights.push(resizedFocusedMediaHeight);
        }

        // Initialize title and description opacities
        const textBoxOpacities = [];
        for (let i = 0; i < nextProps.itinerary.length; i++){
            textBoxOpacities.push(0.0);
        }

        // Return new states
        this.setState(
            {
                itinerary: nextProps.itinerary,
                dotsInfo: nextProps.dotsInfo,
                focusedDotIndex: null,
                mediaWidths: this.unfocusedMediaWidths,
                mediaHeights: this.unfocusedMediaHeights,
                oldMediaWidths: null,
                textBoxOpacities: textBoxOpacities,
                randomizeMediaSets: nextProps.randomizeMediaSets
            },
            callback
        );
    }


    initializeTrack() {
        // Update total track width and media positions
        let trackWidth = 0;
        const mediaPositions = [];

        // For all media
        for (let index = 0; index < this.state.itinerary.length; index++) {
            mediaPositions[index] = trackWidth + this.state.mediaWidths[index] / 2;
            trackWidth += this.state.mediaWidths[index] + 2 * (this.mediaBorder + this.mediaMargin);
        }

        // Set up arrows
        let prevArrowDisplay,
            nextArrowDisplay,
            trackPosition;

        if (trackWidth > window.innerWidth) {
            prevArrowDisplay = "none";
            nextArrowDisplay = "block";
            trackPosition = this.trackMargin;
        }
        else {
            nextArrowDisplay = "none";
            prevArrowDisplay = "none";
            trackPosition = window.innerWidth / 2 - trackWidth / 2;
        }

        // update track variables
        this.setState({
            trackWidth: trackWidth,
            trackPosition: trackPosition,
            mediaPositions: mediaPositions,
            prevArrowDisplay: prevArrowDisplay,
            nextArrowDisplay: nextArrowDisplay
        });
    }


    // Click event handler
    mediaClick(e, clickedDotIndex) {
        // Limit the target of click event
        e.stopPropagation();

        // Get the clicked and focused indices
        const clickedIndex = this.state.itinerary.indexOf(clickedDotIndex);
        const focusedIndex = (this.state.focusedDotIndex != null)? this.state.itinerary.indexOf(this.state.focusedDotIndex) : null;

        // New property arrays
        const mediaWidths = this.state.mediaWidths.slice();
        const mediaHeights = this.state.mediaHeights.slice();
        const textBoxOpacities = this.state.textBoxOpacities.slice();

        // Save old media widths
        const mediaOldWidths = this.state.mediaWidths.slice();
        //const mediaOldHeights = this.state.mediaHeights.slice();

        // Parameters
        let focusedDotIndex,
            zoomedDotIndex,
            callbackCase,
            shiftParams,
            fadeParams,
            halfwayMediaWidths,
            halfwayMediaHeights,
            halfwayTextBoxOpacities,
            halfwayFadeParams,
            halfwayShiftParams;

        // Zoom the clicked media and make it selected if no other is selected
        if (this.state.focusedDotIndex == null) {
            // Zoom in the clicked media
            mediaWidths[clickedIndex] = this.focusedMediaWidths[clickedIndex];
            mediaHeights[clickedIndex] = this.focusedMediaHeights[clickedIndex];

            // Turn on title and description
            textBoxOpacities[clickedIndex] = 1.0;

            // Update the focused dot index
            focusedDotIndex = clickedDotIndex;

            // Callback case 1 : zoom in the clicked media when nothing was zoomed
            callbackCase = 1;

            // Set the parameters for 1st callback
            shiftParams = {
                textBoxScaleSwitch: true,
                textBoxFadeSwitch: false,
                mediaOldWidths: mediaOldWidths,
            };

            // Set the parameters for 2nd callback
            fadeParams = {
                textBoxFadeSwitch: true,
                textBoxOpacities: textBoxOpacities,
                textBoxHeight: "auto"
            };
        }
        // If an media was already selected
        else {
            // Zoom out the media and make none selected if clicked media is already selected
            if (focusedIndex === clickedIndex) {
                // Zoom out the clicked media (use pre-calculated values)
                mediaWidths[clickedIndex] = this.unfocusedMediaWidths[clickedIndex];
                mediaHeights[clickedIndex] = this.unfocusedMediaHeights[clickedIndex];

                // Turn off title and description
                textBoxOpacities[clickedIndex] = 0.0;

                // Mark the zoomed out media
                zoomedDotIndex = clickedDotIndex;

                // Set the focused dot to none
                focusedDotIndex = null;

                // Callback case 2 : zoom out the already zoomed media
                callbackCase = 2;

                // Set the parameters for 1st callback
                fadeParams = {
                    textBoxFadeSwitch: true,
                    textBoxScaleSwitch: false,
                    mediaOldWidths: mediaOldWidths,
                    textBoxOpacities: textBoxOpacities,
                    textBoxHeight: this.textBoxMinHeight
                };

                // Set the parameters for 2nd callback
                shiftParams = {
                    textBoxScaleSwitch: true,
                };
            }
            // If other media is selected
            else {
                // Zoom out the already selected media (use pre-calculated values)
                // Zoomed out media is different from the clicked media
                // Make a halfway point for transition
                halfwayMediaWidths = mediaWidths.slice();
                halfwayMediaHeights = mediaHeights.slice();
                halfwayTextBoxOpacities = textBoxOpacities.slice();

                // Zoom out the first media
                mediaWidths[focusedIndex] = this.unfocusedMediaWidths[focusedIndex];
                mediaHeights[focusedIndex] = this.unfocusedMediaHeights[focusedIndex];
                halfwayMediaWidths[focusedIndex] = this.unfocusedMediaWidths[focusedIndex];
                halfwayMediaHeights[focusedIndex] = this.unfocusedMediaHeights[focusedIndex];

                // Zoom in the clicked media
                mediaWidths[clickedIndex] = this.focusedMediaWidths[clickedIndex];
                mediaHeights[clickedIndex] = this.focusedMediaHeights[clickedIndex];

                // Turn off title and description of the previously selected
                textBoxOpacities[focusedIndex] = 0.0;
                halfwayTextBoxOpacities[focusedIndex] = 0.0;

                // Turn on title and description of the newly selected
                textBoxOpacities[clickedIndex] = 1.0;

                // Set the focused dot to clicked dot
                focusedDotIndex = clickedDotIndex;

                // Callback case 3 : moving from previously the zoomed media to another
                callbackCase = 3;

                // Set the parameters for callback
                halfwayFadeParams = {
                    textBoxFadeSwitch: true,
                    textBoxScaleSwitch: false,
                    mediaOldWidths: mediaOldWidths,
                    textBoxOpacities: halfwayTextBoxOpacities,
                    textBoxHeight: "auto"
                };
                halfwayShiftParams = {
                    textBoxScaleSwitch: true,
                };

                shiftParams = {
                    textBoxScaleSwitch: true,
                    textBoxFadeSwitch: false,
                    mediaOldWidths: halfwayMediaWidths,
                };

                fadeParams = {
                    textBoxFadeSwitch: true,
                    textBoxOpacities: textBoxOpacities,
                    textBoxHeight: "auto"
                };
            }
        }

        // Update the parent (Itinerary) selected dot
        const updateItinerary = () => {
            const itineraryState = (callbackCase === 2)?
            {
                timeBarFocused: null
            }:{
                timeBarFocused: clickedDotIndex
            };

            this.props.setState(itineraryState);
        };

        // Callback from track move
        const callbackTrack = this.updateTrackProperties.bind(this, mediaWidths, zoomedDotIndex, focusedDotIndex, textBoxOpacities, updateItinerary);

        // Save the scope
        const that = this;

        // Construct the callback chain for animation
        // If none was selected
        if (callbackCase === 1) {
            //=======================================================================
            // 4-step callback chain (zooming in an media when nothing was selected)
            //=======================================================================
            this.setState(
                {
                    mediaWidths: mediaWidths,
                    mediaHeights: mediaHeights,
                    focusedDotIndex: focusedDotIndex
                },
                that.setState.bind(
                    that,
                    shiftParams,
                    setTimeout.bind(
                        null,
                        that.setState.bind(
                            that,
                            fadeParams,
                            callbackTrack
                        ),
                        that.textBoxTransitionTime
                    )
                )
            );
        }
        else {
            if (callbackCase === 2) {
                //=======================================================================
                // 4-step callback chain (zooming out the previously clicked media)
                //=======================================================================

                this.setState(
                    fadeParams,
                    setTimeout.bind(
                        null,
                        that.setState.bind(
                            that,
                            shiftParams,
                            that.setState.bind(
                                that,
                                {
                                    mediaWidths: mediaWidths,
                                    mediaHeights: mediaHeights,
                                    focusedDotIndex: focusedDotIndex
                                },
                                callbackTrack
                            )
                        ),
                        that.textBoxTransitionTime
                    )
                );
                //=======================================================================
            }

            else {
                //=======================================================================
                // 7-step callback chain (moving from one media to another)
                //=======================================================================

                this.setState(
                    halfwayFadeParams,
                    setTimeout.bind(
                        null,
                        that.setState.bind(
                            that,
                            halfwayShiftParams,
                            that.setState.bind(
                                that,
                                {
                                    mediaWidths: halfwayMediaWidths,
                                    mediaHeights: halfwayMediaHeights,
                                    focusedDotIndex: null
                                },
                                that.setState.bind(
                                    that,
                                    {
                                        mediaWidths: mediaWidths,
                                        mediaHeights: mediaHeights,
                                        focusedDotIndex: focusedDotIndex
                                    },
                                    that.setState.bind(
                                        that,
                                        shiftParams,
                                        setTimeout.bind(
                                            null,
                                            that.setState.bind(
                                                that,
                                                fadeParams,
                                                callbackTrack
                                            ),
                                            that.textBoxTransitionTime
                                        )
                                    )
                                )
                            )
                        ),
                        that.textBoxTransitionTime
                    )
                );
                //=======================================================================
            }
        }
    }


    // Hover on event handler
    mediaHoverOn(e, hoveredDotIndex){
        // Limit the target of hover on event
        e.stopPropagation();

        // Get the hovered on dot index
        //const hoveredIndex = this.state.itinerary.indexOf(hoveredDotIndex);

        // Update the Itinerary state
        this.props.setState({
            timeBarHovered: hoveredDotIndex
        });

        // Update hovered dot index
        this.setState({
            hoveredDotIndex: hoveredDotIndex
        });
    }


    // Hover off event handler
    mediaHoverOff(e, hoveredDotIndex){
        // Limit the target of hover off event
        e.stopPropagation();

        // Get the hovered off dot index
        //const hoveredIndex = this.state.itinerary.indexOf(hoveredDotIndex);

        // Update the Itinerary state
        this.props.setState({
            timeBarHovered: null
        });      

        // Update hovered dot index
        this.setState({
            hoveredDotIndex: null
        });
    }


    // Update track width and position
    updateTrackProperties(mediaWidths, zoomedDotIndex, focusedDotIndex, textBoxOpacities, callback) {
        // Window width
        const screenWidth = window.innerWidth;

        // Update total track width and media positions
        let trackWidth = 0;
        const mediaPositions = [];
        for (let index = 0; index < this.state.itinerary.length; index++) {
            mediaPositions[index] = Math.round(trackWidth + mediaWidths[index] / 2 + (this.mediaBorder + this.mediaMargin));
            trackWidth += mediaWidths[index] + 2 * (this.mediaBorder + this.mediaMargin);
        }

        // Calculate the shift position
        // If no media is selected => only possible when the clicked media was zoomed-in before
        let trackPosition;
        if (focusedDotIndex == null) {
            // Go back to the middle of the track
            // trackPosition = Math.round(screenWidth / 2 - trackWidth / 2);
            // Go back to the clicked element
            if ((trackWidth < screenWidth) || (this.state.trackWidth < screenWidth)) {
                trackPosition = (screenWidth - trackWidth) / 2;
            }
            else {
                const zoomedIndex = this.state.itinerary.indexOf(zoomedDotIndex);

                if (mediaPositions[zoomedIndex] < (screenWidth / 2)) {
                    trackPosition = this.trackMargin;
                }
                else {
                    if (mediaPositions[zoomedIndex] > (trackWidth - screenWidth / 2)) {
                        trackPosition = -(trackWidth - screenWidth + this.trackMargin);
                    }
                    else {
                        trackPosition = Math.round((screenWidth / 2) - mediaPositions[zoomedIndex]);
                    }
                }
            }
        }
        // If an media is selected
        else {
            // If the whole track is smaller than the screen
            if (trackWidth < screenWidth) {
                trackPosition = (screenWidth - trackWidth) / 2;
            }
            // If the whole track is bigger than the screen
            else {
                const focusedIndex = this.state.itinerary.indexOf(focusedDotIndex);
                if (mediaPositions[focusedIndex] < (screenWidth / 2)){
                    trackPosition = this.trackMargin;
                }
                else {
                    if (mediaPositions[focusedIndex] > (trackWidth - screenWidth / 2)){
                        trackPosition = -(trackWidth - screenWidth + this.trackMargin);
                    }
                    else {
                        trackPosition = Math.round((screenWidth / 2) - mediaPositions[focusedIndex]);
                    }
                }
            }
        }

        // Page navigation arrows
        let prevArrowDisplay,
            nextArrowDisplay;

        if (trackWidth < window.innerWidth) {
            prevArrowDisplay = "none";
            nextArrowDisplay = "none";
        }
        else {
            if ((window.innerWidth - trackPosition) < window.innerWidth) {
                prevArrowDisplay = "none";
            }
            else {
                prevArrowDisplay = "block";
            }
            if ((trackWidth + trackPosition) < window.innerWidth) {
                nextArrowDisplay = "none";
            }
            else {
                nextArrowDisplay = "block";
            }
        }

        if ((trackWidth !== this.state.trackWidth)||(mediaPositions !== this.state.mediaPositions)) {
            // update variables
            this.setState(
                {
                    trackWidth: trackWidth,
                    trackPosition: trackPosition,
                    mediaPositions: mediaPositions,
                    textBoxOpacities: textBoxOpacities,
                    prevArrowDisplay: prevArrowDisplay,
                    nextArrowDisplay: nextArrowDisplay
                },
                callback
            );
        }
    }


    // Prev page navigation
    prevArrowClick() {
        let trackPosition,
            prevArrowDisplay,
            nextArrowDisplay;

        // Screen width
        const screenWidth = window.innerWidth;

        // If an media was selected
        if(this.state.focusedDotIndex != null){
            // Get the focused index
            const focusedIndex = this.state.itinerary.indexOf(this.state.focusedDotIndex);

            // New property arrays
            const mediaWidths = this.state.mediaWidths.slice();
            const mediaHeights = this.state.mediaHeights.slice();
            const textBoxOpacities = this.state.textBoxOpacities.slice();

            // Save old widths
            const mediaOldWidths = this.state.mediaWidths.slice();

            // Zoom out the selected media
            mediaWidths[focusedIndex] = this.unfocusedMediaWidths[focusedIndex];
            mediaHeights[focusedIndex] = this.unfocusedMediaHeights[focusedIndex];

            // Turn off title and description
            textBoxOpacities[focusedIndex] = 0.0;

            // Update the selected dot index
            const focusedDotIndex = null;

            // Set the parameters for 1st callback
            const fadeParams = {
                textBoxFadeSwitch: true,
                textBoxScaleSwitch: false,
                mediaOldWidths: mediaOldWidths,
                textBoxOpacities: textBoxOpacities,
                textBoxHeight: this.textBoxMinHeight
            };

            // Set the parameters for 2nd callback
            const shiftParams = {
                textBoxScaleSwitch: true,
            };

            // Recalculate track width and set position
            const trackWidth = this.state.trackWidth - (mediaOldWidths[focusedIndex] - mediaWidths[focusedIndex]);
            const trackOldPosition = this.state.trackPosition;

            // Only when the new track width is larger than the screen size
            if (trackWidth > screenWidth) {
                // Calculate the new track position
                if (-trackOldPosition > screenWidth) {
                    trackPosition = -(-trackOldPosition - screenWidth);
                    prevArrowDisplay = "block";
                    nextArrowDisplay = "block";
                }
                else {
                    trackPosition = this.trackMargin;
                    prevArrowDisplay = "none";
                    nextArrowDisplay = "block";
                }
            }
            else {
                // return the track to the center
                trackPosition = (screenWidth - trackWidth) / 2;
                prevArrowDisplay = "none";
                nextArrowDisplay = "none";
            }

            const trackParams = {
                trackWidth: trackWidth,
                trackPosition: trackPosition,
                prevArrowDisplay: prevArrowDisplay,
                nextArrowDisplay: nextArrowDisplay
            }

            //=======================================================================
            // 4-step callback chain (zooming out the previously clicked media)
            //=======================================================================

            // Save scope
            const that = this;

            this.setState(
                fadeParams,
                setTimeout.bind(
                    null,
                    that.setState.bind(
                        that,
                        shiftParams,
                        that.setState.bind(
                            that,
                            {
                                mediaWidths: mediaWidths,
                                mediaHeights: mediaHeights,
                                focusedDotIndex: focusedDotIndex
                            },
                            that.setState.bind(
                                that,
                                trackParams
                            )
                        )
                    ),
                    that.textBoxTransitionTime
                )
            );
        }
        // If no media is selected
        else {
            // Calculate the new track position
            if (-this.state.trackPosition > screenWidth) {
                trackPosition = -(-this.state.trackPosition - screenWidth);
                prevArrowDisplay = "block";
                nextArrowDisplay = "block";
            }
            else {
                trackPosition = this.trackMargin;
                prevArrowDisplay = "none";
                nextArrowDisplay = "block";
            }

            // Update variables
            this.setState(
                {
                    trackPosition: trackPosition,
                    prevArrowDisplay: prevArrowDisplay,
                    nextArrowDisplay: nextArrowDisplay
                }
            );
        }
    }


    // Next page navigation
    nextArrowClick() {
        let trackPosition,
            prevArrowDisplay,
            nextArrowDisplay;

        // Get the window width
        const screenWidth = window.innerWidth;

        // If an media was selected
        if (this.state.focusedDotIndex != null) {
            // Get the media index
            const focusedIndex = this.state.itinerary.indexOf(this.state.focusedDotIndex);

            // New property arrays
            const mediaWidths = this.state.mediaWidths.slice();
            const mediaHeights = this.state.mediaHeights.slice();
            const textBoxOpacities = this.state.textBoxOpacities.slice();

            // Save old widths
            const mediaOldWidths = this.state.mediaWidths.slice();

            // Zoom out the selected media
            mediaWidths[focusedIndex] = this.unfocusedMediaWidths[focusedIndex];
            mediaHeights[focusedIndex] = this.unfocusedMediaHeights[focusedIndex];

            // Turn off title and description
            textBoxOpacities[focusedIndex] = 0.0;

            // Update the selected media ID
            const focusedDotIndex = null;

            // Set the parameters for 1st callback
            const fadeParams = {
                textBoxFadeSwitch: true,
                textBoxScaleSwitch: false,
                mediaOldWidths: mediaOldWidths,
                textBoxOpacities: textBoxOpacities,
                textBoxHeight: this.textBoxMinHeight
            };

            // Set the parameters for 2nd callback
            const shiftParams = {
                textBoxScaleSwitch: true,
            };

            // Recalculate track width and set position
            const trackWidth = this.state.trackWidth - (mediaOldWidths[focusedIndex] - mediaWidths[focusedIndex]);
            const trackOldPosition = this.state.trackPosition + (mediaOldWidths[focusedIndex] - mediaWidths[focusedIndex]);

            // Only when the new track width is larger than the screen size
            if (trackWidth > screenWidth) {
                // If more than one screenwidth is left to scroll
                if ((trackWidth + trackOldPosition) - screenWidth > screenWidth) {
                    trackPosition = trackOldPosition - screenWidth;
                    prevArrowDisplay = "block";
                    nextArrowDisplay = "block";
                }
                else {
                    trackPosition = -(trackWidth - screenWidth + this.trackMargin);
                    prevArrowDisplay = "block";
                    nextArrowDisplay = "none";
                }
            }
            else {
                // Return the track to the center
                trackPosition = (screenWidth - trackWidth) / 2;
                prevArrowDisplay = "none";
                nextArrowDisplay = "none";
            }

            const trackParams = {
                trackWidth: trackWidth,
                trackPosition: trackPosition,
                prevArrowDisplay: prevArrowDisplay,
                nextArrowDisplay: nextArrowDisplay
            }

            //=======================================================================
            // 4-step callback chain (zooming out the previously clicked media)
            //=======================================================================

            // Save scope
            const that = this;

            this.setState(
                fadeParams,
                setTimeout.bind(
                    null,
                    that.setState.bind(
                        that,
                        shiftParams,
                        that.setState.bind(
                            that,
                            {
                                mediaWidths: mediaWidths,
                                mediaHeights: mediaHeights,
                                focusedDotIndex: focusedDotIndex
                            },
                            that.setState.bind(
                                that,
                                trackParams
                            )
                        )
                    ),
                    that.textBoxTransitionTime
                )
            );
            //=======================================================================
        }
        // If no media is selected
        else {
            // If more than one screenwidth is left to scroll
            if ((this.state.trackWidth + this.state.trackPosition) - screenWidth > screenWidth) {
                trackPosition = this.state.trackPosition - screenWidth;
                prevArrowDisplay = "block";
                nextArrowDisplay = "block";
            }
            else {
                trackPosition = -(this.state.trackWidth - screenWidth + this.trackMargin);
                prevArrowDisplay = "block";
                nextArrowDisplay = "none";
            }

            // Update variables
            this.setState(
                {
                    trackPosition: trackPosition,
                    prevArrowDisplay: prevArrowDisplay,
                    nextArrowDisplay: nextArrowDisplay
                }
            );
        }
    }


    // Window size update callback (used by eventlistner set up in componenetDidMount)
    updateWindowSize() {
        //console.log("updateWindowSize");

        // Get the new window size
        const newScreenWidth = window.innerWidth;
        const newScreenHeight = window.innerHeight;

        // Change the arrows
        let prevArrowDisplay,
            nextArrowDisplay,
            newTrackPosition;

        // If track width is larger than screen width
        if (this.state.trackWidth > newScreenWidth) {
            prevArrowDisplay = "none";
            nextArrowDisplay = "block";
            newTrackPosition = this.trackMargin;
        }
        else {
            nextArrowDisplay = "none";
            prevArrowDisplay = "none";
            newTrackPosition = newScreenWidth / 2 - this.state.trackWidth / 2;
        }

        // Initialize title and description opacities
        const textBoxOpacities = [];
        for (let i = 0; i < this.state.itinerary.length; i++){
            textBoxOpacities.push(0.0);
        }

        // Update states
        this.setState(
            {
                focusedDotIndex: null,
                hoveredDotIndex: null,
                mediaWidths: this.unfocusedMediaWidths,
                mediaHeights: this.unfocusedMediaHeights,
                oldMediaWidths: null,
                textBoxOpacities: textBoxOpacities,
                screenWidth: newScreenWidth,
                screenHeight: newScreenHeight,
                trackPosition: newTrackPosition,
                prevArrowDisplay: prevArrowDisplay,
                nextArrowDisplay: nextArrowDisplay
            }
        );
    }
}


/*
Props
- mode
- focusedDotIndex
- dotIndex
- dotsInfo
- itinerary
- times
- mediaWidths
- mediaHeights
- mediaOldWidths
- textBoxFadeSwitch
- textBoxOpacities
- textBoxScaleSwitch
- textBoxWidth
- textBoxHeight
- mediaClick
*/

// Individual items
class PlanGalleryItem extends Component{
    /*
    constructor (props){
        super(props);
    } */
    
    render () {
        //console.log(" PlanGalleryItem / render - this.props = ", this.props);

        // Get the dot
        const dot = this.props.dotsInfo[this.props.dotIndex];

        // Get the media url
        const media = getMediaProperty(dot.media[0], "s", 'url', true);

        // Get the loader media
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/media/loader/loader-white.gif") :
            getStaticPath("/media/loader/loader-black.gif");

        // Get the index
        const index = this.props.itinerary.indexOf(this.props.dotIndex);

        // Line media
        let lineImageRight = getStaticPath("/images/line/horizontal/dotted-middle.png");
        let lineImageLeft = getStaticPath("/images/line/horizontal/dotted-middle.png");

        // If it is the first media
        if (index === 0) {
            // If only one media is left
            if (this.props.itinerary.length === 1) {
                lineImageRight = null;
            }

            lineImageLeft = null;
        }
        else {
            // If it is the last media
            if (index === (this.props.itinerary.length - 1)) {
                lineImageRight = null;
            }
        }

        // Opacity
        let mediaOpacity = this.props.unfocusedMediaOpacity;
        let timeOpacity = this.props.unfocusedTimeOpacity;
        let durationOpacity = this.props.unfocusedDurationOpacity;
        if (this.props.dotIndex === this.props.focusedDotIndex) {
            mediaOpacity = this.props.focusedMediaOpacity;
            timeOpacity = this.props.focusedTimeOpacity;
            durationOpacity = this.props.focusedDurationOpacity;
        }
        else {
            if (this.props.dotIndex === this.props.hoveredDotIndex) {
                mediaOpacity = this.props.focusedMediaOpacity;
                timeOpacity = this.props.focusedTimeOpacity;
                durationOpacity = this.props.focusedDurationOpacity;
            }
        }

        // If there are loaded media
        let mediaStyle, textBoxStyle, textBoxWrapperStyle;
        if (this.props.mediaWidths != null) {
            // Get media style
            const mediaBaseStyle = {
                backgroundImage: media,
                width: this.props.mediaWidths[index],
                height: this.props.mediaHeights[index]
            };

            const focusedStyle = ((this.props.dotIndex === this.props.hoveredDotIndex) || (this.props.dotIndex === this.props.focusedDotIndex))?
            {
                border: ("1px solid " + ((this.props.colorMode === "day")?
                    window.colorLightBlue : window.colorDarkBlue)),
                boxShadow: ("0px 0px 4px 0px " + ((this.props.colorMode === "day")?
                    window.colorLightBlue : window.colorBlue))
           } : {
                border: ("1px solid " + ((this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray))
            };

            mediaStyle = Object.assign({}, mediaBaseStyle, focusedStyle);

            // Text box fade animation
            if (this.props.textBoxFadeSwitch) {
                textBoxStyle = {opacity: this.props.textBoxOpacities[index]};
            }
            else {
                textBoxStyle = {};
            }

            // Text box scale animation
            if (this.props.textBoxScaleSwitch) {
                // Calculate the text box shift
                const textBoxShift = (this.props.mediaWidths[index] - this.props.textBoxWidth) / 2;

                // Get the height
                const textBoxHeight = this.props.textBoxHeight;

                // Set text box style
                textBoxWrapperStyle = {
                    width: this.props.textBoxWidth,
                    height: textBoxHeight,
                    left: textBoxShift,
                    position: "relative"
                };
            }
            else {
                // Calculate the text box shift
                const textBoxOldShift = (this.props.mediaOldWidths[index] - this.props.textBoxWidth) / 2;
                textBoxWrapperStyle = {
                    width: this.props.textBoxWidth,
                    left: textBoxOldShift,
                    position: "relative"
                }
            }
        }
        // If no media
        else {
            mediaStyle = {};
            textBoxWrapperStyle = {};
            textBoxStyle = {};
        }

        // Transit mode
        const transitMode = (index !== this.props.itinerary.length - 1)?
        (
            <div className = "plan-gallery-item-transit-mode image-contain" 
                style = {{
                    backgroundImage: getTransitImage(this.props.transitModes[index + 1], this.props.colorMode, true) 
                }}
            >
            </div>
        ) : null;

        const transitTime = (index === this.props.itinerary.length - 1)? 
        null: (
            <div className = "plan-gallery-item-transit-time number-g4">
                {this.props.transitTimes[index + 1]}
            </div>
        );

        // Marker media
        const markerImage = (this.props.colorMode === "day")?
            getStaticPath("/images/number/double_red_day_" + (index + 1) +".png") :
            getStaticPath("/images/number/double_red_night_" + (index + 1) +".png");

        // Get the header for itinerary mode
        let itineraryMode = null;

        if (this.props.mode === "itinerary") {
            // Selected media
            let dotMedia;
            if (this.props.focusedDotIndex === this.props.dotIndex) {
                // Get the dot media and pulse effect
                dotMedia = (
                    <div className = "plan-gallery-item-marker"
                        style = {{ backgroundImage: markerImage }}>

                        <div className = "plan-gallery-item-pulse plan-gallery-pulse">
                        </div>
                    </div>
                );
            }
            else {
                // Get the dot media
                dotMedia = (
                    <div className = "plan-gallery-item-marker" 
                        style = {{ backgroundImage: markerImage }}>
                        <div className = "plan-gallery-item-pulse">
                        </div>
                    </div>
                );
            }

            itineraryMode =
                (
                    <div>
                        <div className = "plan-gallery-item-line">
                            <div className = "plan-gallery-item-left" 
                                style = {{ backgroundImage: lineImageLeft }}>
                                {dotMedia}
                            </div>
                            <div className = "plan-gallery-item-right" 
                                style = {{ backgroundImage: lineImageRight }}>
                                {transitMode}
                                {transitTime}
                            </div>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                            "plan-gallery-item-activity-time number-k4" :
                            "plan-gallery-item-activity-time number-w4"}
                            style = {{ opacity: durationOpacity }}>
                            {dot.time}
                        </div>
                    </div>
                );
        }

        // Render JSX
        return (
            <div key = {"plan-gallery-item-content-" + this.props.dotIndex.toString()}
                 onClick = {(e) => this.props.mediaClick(e, this.props.dotIndex)}
                 onMouseEnter = {(e) => this.props.mediaHoverOn(e, this.props.dotIndex)}
                 onMouseLeave = {(e) => this.props.mediaHoverOff(e, this.props.dotIndex)}
                 className = "plan-gallery-item-content">
                <div className = "plan-gallery-item-location-wrapper">
                    <div className = "plan-gallery-item-location">
                        <div className = "plan-gallery-item-time">
                            <span className = {(this.props.colorMode === "day")?
                                "plan-gallery-item-time-value-day font-cabin-medium" :
                                "plan-gallery-item-time-value-night font-cabin-medium"}
                                style = {{ opacity: timeOpacity }}>
                                {this.props.dotTimes[index]}
                            </span>
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                            "plan-gallery-item-location-text k4" :
                            "plan-gallery-item-location-text w4"}
                        >
                            {dot.name}
                        </div>
                    </div>
                </div>
                {itineraryMode}
                <div className = "plan-gallery-media-loader image-loader-s3" 
                    style = {{ 
                            backgroundImage: loaderImage,
                            opacity: mediaOpacity
                        }}
                    >
                    <div className = {(this.props.colorMode === "day")? 
                            "plan-gallery-media border-day" : "plan-gallery-media border-night"}
                        style = {mediaStyle}
                    >
                    </div>
                </div>
                <div className = "plan-gallery-textbox-wrapper" style = {textBoxWrapperStyle}>
                    <div className = "plan-gallery-item-textbox" style = {textBoxStyle}>
                        <div className = {(this.props.colorMode === "day")?
                            "plan-gallery-item-title k4" :
                            "plan-gallery-item-title w4"}
                        >
                            {dot.title}
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                            "plan-gallery-item-description" :
                            "plan-gallery-item-description"}
                        >
                            {this.curationPartition(
                                dot.overview, 
                                this.props.colorMode, 
                                "plan-gallery-item-curation-"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    curationPartition(string, colorMode, keyHeader) {
        // Find indices of *
        const indices = [];
        let index = -1;
        while ((index = string.indexOf("*", index + 1)) >= 0) {
            indices.push(index);
        }

        // Odd number function
        const isOdd = (num) => { return num % 2;};

        // Initialize JSX expression
        let jsx = [];

        // Get the number of highlighted sections
        let startIndex = 0;
        for (let i = 0; i < (indices.length + 1); i++) {
            let subString = (i === (indices.length))?
                string.slice(startIndex) : string.slice(startIndex, indices[i]);
            let component;

            // First part
            if (subString.length > 0) {
                if (isOdd(i)) {
                    component = (
                        <span key = {keyHeader + i.toString()}
                            className = {(this.props.colorMode === "day")?
                            "plan-gallery-item-description-highlight light-blue" :
                            "plan-gallery-item-description-highlight blue"}
                        >
                            {subString}
                        </span>
                    );
                }
                else {
                    component = (
                        <span key = {keyHeader + i.toString()} 
                            className = {(this.props.colorMode === "day")?
                            "plan-gallery-item-description-highlight dark-gray" :
                            "plan-gallery-item-description-highlight gray"}
                        >
                            {subString}
                        </span>
                    );
                }
                
            }

            // Update index
            startIndex = indices[i] + 1;

            // Add JSX
            jsx.push(component);
        }

        // Return modified string
        return jsx;
    }    
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode
    };
}

export default connect(mapStateToProps, null)(PlanGallery);
