/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    TimeBar.js
    - Analyze scheduel and display timebar for TripGallery
    - Must be used in conjuction with TripGallery
--------------------------------------------------------------------------------------------
    Content
    - TimeBar
============================================================================================
*/


// React / ReactDOM / React-router
import React, {Component} from "react";
//import { findDOMNode } from "react-dom";
import { connect } from "react-redux"; 

// Modules - ReactTooltip / moment
import moment from "moment-timezone";
import ReactTooltip from "thedots-tooltip";

// Load functions
import { 
    getStaticPath,
    getTransitImage
} from "js/Functions";

// CSS
import "./TimeBar.scss";


// TimeBar component
class TimeBar extends Component {
    constructor (props) {
        super(props);
        //console.log("TimeBar / render - props = ", props);
        //console.log("TimeBar / render - itinerary = ", this.props.itinerary);
        //console.log("TimeBar / render - selected = ", this.props.selected);
        //console.log("TimeBar / render - displayHike = ", this.props.displayHike);

        // Ref
        this.timeBarRef = React.createRef();

        // Initialize state
        this.state = {
            timeBarHovered: false,
            timeBarHoveredDot: null,
            timeBarHoveredTransit: false
        };

        this.remainColorDay = window.colorDarkestGray;
        this.transitColorDay = window.colorDarkGray;
        this.dotColorDay = window.colorLightGray;
        this.selectedColorDay = window.colorLightBlue;
        this.hoveredColorDay = window.colorCloseToWhite;

        this.remainColorNight = window.colorAlmostBlack;
        this.transitColorNight = window.colorDarkGray;
        this.dotColorNight = window.colorGray;
        this.selectedColorNight = window.colorBlue;
        this.hoveredColorNight = window.colorLightGray;

        // TimeBar positions
        this.leftPosition = null;
        this.rightPosition = null;

        // Accumulated Times
        this.dotTimeSum = null;
        this.transitTimeSum = null;
        this.totalTime = null;

        // Hover interval
        this.hoverInterval = null;
        this.refreshHoverTime = 100;

        // Bind get tooltip content
        this.getTargetDot = this.getTargetDot.bind(this);
        this.getTooltipContent = this.getTooltipContent.bind(this);

        // Bind hover callbacks
        this.targetX = null;
        this.targetY = null;
        this.timeBarHoverOn = this.timeBarHoverOn.bind(this);
        this.timeBarHoverOff = this.timeBarHoverOff.bind(this);
        this.timeBarMove = this.timeBarMove.bind(this);
        this.timeBarClick = this.timeBarClick.bind(this);
    }


    render() {
        // Number of dots
        const numDots = this.props.itinerary.length;

        // Get dot activity times
        const dotTimes = [];
        for (let i = 0; i < numDots; i++) {
            dotTimes.push(this.props.dotsInfo[this.props.itinerary[i]].dot_extension.time_value);
        }

        // Get dot colors
        const selectedIndex = (this.props.selected !== null)? 
            this.props.itinerary.indexOf(this.props.selected) : null;
        const hoveredIndex = (this.props.hovered !== null)? 
            this.props.itinerary.indexOf(this.props.hovered) : null;

        const dotColors = [];
        for (let i = 0; i < numDots; i++) {
            if (i === selectedIndex) {
                dotColors[i] = (this.props.colorMode === "day")?
                    this.selectedColorDay : this.selectedColorNight;
            }
            else {
                if (i === hoveredIndex) {
                    dotColors[i] = (this.props.colorMode === "day")?
                    this.hoveredColorDay : this.hoveredColorNight;
                }
                else {
                    dotColors[i] = (this.props.colorMode === "day")?
                    this.dotColorDay : this.dotColorNight;
                }
            }
        }
        //console.log("Timebar / render - this.props.selected = ", this.props.selected);
        //console.log("Timebar / render - this.props.hovered = ", this.props.hovered);
        //console.log("Timebar / render - this.props.displayChildren = ", this.props.displayChildren);
        //console.log("Timebar / render - dotColors = ", dotColors);

        // Initialize accumulative sum
        this.transitTimeSum = [];
        this.dotTimeSum = [];

        // For all transit sections and dots
        for (let i = 0; i < numDots; i++) {
            this.transitTimeSum.push(0);
            this.dotTimeSum.push(0);
        }
        this.transitTimeSum.push(0);

        // Initialize sum
        let timeSum = 0;

        // For all transit sections and dots
        for (let i = 0; i < numDots; i++) {
            // Time of dot activity start
            this.transitTimeSum[i] += (timeSum + this.props.transitTimeValues[i]);

            // Time of dot activity end
            this.dotTimeSum[i] += (this.transitTimeSum[i] + dotTimes[i]);

            // Update the sum
            timeSum += (this.props.transitTimeValues[i] + dotTimes[i]);
        }

        // Add the last transit section
        this.transitTimeSum[numDots] += (timeSum + this.props.transitTimeValues[numDots]);

        // Total time
        const totalTime = this.transitTimeSum[numDots];

        // Save time sum and total time
        this.totalTime = totalTime;

        // Print out information
        //console.log("TimeBar / render - dotTimes = ", dotTimes);
        //console.log("TimeBar / render - this.props.transitTimeValues = ", this.props.transitTimeValues);
        //console.log("Timebar / render - dotTimeSum = ", dotTimeSum);
        //console.log("Timebar / render - transitTimeSum = ", transitTimeSum);
        //console.log("Timebar / render - totalTime = ", totalTime);


        // Initialize background properties
        let background = "";

        // Transit color
        const transitColor = (this.props.colorMode === "day")?
            this.transitColorDay : this.transitColorNight;

        // Remain color
        const remainColor = (this.props.colorMode === "day")?
            this.remainColorDay : this.remainColorNight;

        for (let i = 0; i < numDots; i++) {
            // First dot
            if(i === 0) {
                // if the first transit begins at sunrise
                background += ("linear-gradient(90deg," + transitColor + ",");
                background += (" " + transitColor + " " + (this.transitTimeSum[i] / totalTime * 100) + "%, " + dotColors[i] + " " + (this.transitTimeSum[i] / totalTime * 100) + "%, ");
                background += (" " + dotColors[i] + " " + (this.dotTimeSum[i] / totalTime * 100) + "%, " + transitColor + " " + (this.dotTimeSum[i] / totalTime * 100) + "%, ");
            }
            // Last dot
            else if (i === (numDots - 1)) {
                background += (" " + transitColor + " " + (this.transitTimeSum[i] / totalTime * 100) + "%, " + dotColors[i] + " " + (this.transitTimeSum[i] / totalTime * 100) + "%, ");
                background += (" " + dotColors[i] + " " + (this.dotTimeSum[i] / totalTime * 100) + "%, " + transitColor + " " + (this.dotTimeSum[i] / totalTime * 100) + "%, ");
                background += (" " + transitColor + " " + (this.transitTimeSum[i + 1] / totalTime * 100) + "%, " + remainColor + " " + (this.transitTimeSum[i + 1] / totalTime * 100) + "%, ");
                background += (" " + remainColor + " )");
            }
            // Mid section
            else {
                background += (" " + transitColor + " " + (this.transitTimeSum[i] / totalTime * 100) + "%, " + dotColors[i] + " " + (this.transitTimeSum[i] / totalTime * 100) + "%, ");
                background += (" " + dotColors[i] + " " + (this.dotTimeSum[i] / totalTime * 100) + "%, " + transitColor + " " + (this.dotTimeSum[i] / totalTime * 100) + "%, ");
            }
        }
        //console.log("Timebar / render - background = ", background);


        // Cursor type
        const cursorType = (this.state.timeBarHoveredDot != null)? "pointer" : "default";

        // Style
        const timeBarStyle = {
            background: background,
            backgroundSize: "auto",
            cursor: cursorType
        };

        // Tooltip
        const tooltip = (
            <ReactTooltip 
                //ref = {this.props.classPrefix + "-time-bar-tooltip"}
                id = {this.props.classPrefix + "-time-bar-tooltip"}
                className = {this.props.classPrefix + "-time-bar-tooltip"}
                type = "dark"
                place = "top"
                html = {true}
                getContent = {[this.getTooltipContent, 1]}
            />
        );
        //}

        return (
    		<div id = {this.props.classPrefix + "-time-bar-container"}
                className = {this.props.classPrefix + "-time-bar-container"}
            >
    			<div ref = {this.timeBarRef}
                    id = {this.props.classPrefix + "-time-bar"}
                    className = {this.props.classPrefix + "-time-bar"}
                    style = {timeBarStyle}
                    onMouseEnter = {this.timeBarHoverOn}
                    onMouseLeave = {this.timeBarHoverOff}
                    onMouseMove = {this.timeBarMove}
                    onClick = {this.timeBarClick}
                    data-tip 
                    data-for = {this.props.classPrefix + "-time-bar-tooltip"}
                    data-iscapture = {true}
                >
    			</div>
                {tooltip}
    		</div>
        );
    }


    componentDidMount() {
        // Show tooltip
        //ReactTooltip.show(findDOMNode(this.refs.timebartooltip));
    }


    componentDidUpdate (prevProps, prevState) {
    }

    updateTimeBarPosition () {
        // Fetch end positions
        const timeBarPosition = this.timeBarRef.current.getBoundingClientRect();
        this.leftPosition = timeBarPosition.left;
        this.rightPosition = timeBarPosition.right;
        //console.log("TimeBar / updateTimeBarPosition - timeBarPosition", timeBarPosition);
        //console.log("TimeBar / updateTimeBarPosition - this.leftPosition", this.leftPosition);
        //console.log("TimeBar / updateTimeBarPosition - this.rightPosition", this.rightPosition);
    }

    timeBarHoverOn() {
        // Show tooltip
        //ReactTooltip.show(findDOMNode(this.refs.timebartooltip));

        // Update parent
        this.props.dotHoverOn(
            this.state.timeBarHoveredDot,
            this.props.dotsInfo[this.state.timeBarHoveredDot],
            (this.state.timeBarHoveredDot === this.props.selected),
            true
        );
 
        // Update state
        this.setState({
            timeBarHovered: true
        });
    }


    timeBarHoverOff() {
        // Hide tooltip
        //ReactTooltip.hide(findDOMNode(this.refs.timebartooltip));

        // Update parent
        this.props.dotHoverOff(
            this.state.timeBarHoveredDot,
            (this.state.timeBarHoveredDot === this.props.selected),
            true
        );

        // Update state
        this.setState({
            timeBarHovered: false,
            timeBarHoveredDot: null,
            timeBarHoveredTransit: null
        });
    }


    timeBarMove(e) {
        // Update cursor position
        if (this.state.timeBarHovered) {
            //console.log("TimeBar / timeBarMove - e.target = ", e.target);
            //console.log("TimeBar / timeBarMove - e.target.screenX = ", e.target.screenX);
            //console.log("TimeBar / timeBarMove - e = ", e);

            // Update time bar position
            this.updateTimeBarPosition();

            // Record mouse position
            this.mouseX = e.clientX;
            this.mouseY = e.clientY;
        }
    }

    timeBarClick(e) {
        // Get the target dot info
        let targetIndexDot;
        [targetIndexDot] = this.getTargetDot();

        // Get the dot index
        const targetDotIndex = this.props.itinerary[targetIndexDot];

        // Update parent
        if (targetDotIndex !== null && targetDotIndex >= 0) {
            this.props.dotClick(targetDotIndex, null);
        }
    }

    getTooltipContent() {
        // Get the target dot info
        let targetIndexDot, targetIndexTransit, targetSeconds;
        [targetIndexDot, targetIndexTransit, targetSeconds] = this.getTargetDot();
        //console.log("TimeBar / getTooltipContent - targetIndexDot = ", targetIndexDot);
        //console.log("TimeBar / getTooltipContent - targetIndexTransit = ", targetIndexTransit);
        //console.log("TimeBar / getTooltipContent - targetSeconds = ", targetSeconds);

        if (targetIndexDot !== null) {
            // Dot index
            const targetDotIndex = this.props.itinerary[targetIndexDot];
            //console.log("TimeBar / getTooltipContent - targetDotIndex = ", targetDotIndex);

            // Timezone
            const targetDotTimezone = this.props.dotsInfo[targetDotIndex].timezone;
            //console.log("TimeBar / getTooltipContent - timezone = ", targetDotTimezone);

            // Time text
            const targetTime = moment.unix((this.props.startMoment.unix() + targetSeconds)).tz(targetDotTimezone).format("h:mm a");
            //console.log("TimeBar / getTooltipContent - targetTime = ", targetTime);
            //console.log("TimeBar / getTooltipContent - this.props.startMoment.unix() = ", this.props.startMoment.unix());

            // Tooltip image
            const tooltipNumberImage = (this.props.colorMode === "day")?
                getStaticPath("/images/number/single_red_" + (targetIndexDot + 1) + ".png") :
                getStaticPath("/images/number/single_red_" + (targetIndexDot + 1) + ".png");

            // Tooltip content    
            const tooltipContent = "<div class = '" + this.props.classPrefix + 
                "-time-bar-tooltip-content'><div class = '" + this.props.classPrefix + 
                "-time-bar-tooltip-number' style = 'background-image:" + tooltipNumberImage + 
                "'></div><div class = '" + this.props.classPrefix + "-time-bar-tooltip-text'>" + 
                targetTime + "</div></div>";

            // Update state to change the tooltip style
            if (this.props.hovered !== targetDotIndex) {
                // Update parent
                this.props.dotHoverOn(
                    targetDotIndex,
                    this.props.dotsInfo[targetDotIndex],
                    (targetDotIndex === this.props.selected),
                    true
                );

                // Update tooltip
                this.setState({
                    timeBarHoveredDot: targetDotIndex,
                    timeBarHoveredTransit: false
                });
            }

            return tooltipContent;
        }
        else {
            // Transit image
            const transitImage = getTransitImage(this.props.transitModes[targetIndexTransit], this.props.colorMode, true);

            // Tooltip content    
            const tooltipContent = "<div id = 'time-bar-tooltip-content'><div id = 'time-bar-tooltip-transit' style = 'background-image:"
            + transitImage + "'></div><div id = 'time-bar-tooltip-text'>" + this.props.transitTimes[targetIndexTransit] + "</div></div>"

            // Update state to change the tooltip style
            if (this.state.hoveredIndexTransit !== targetIndexTransit) {
                // Update parent
                this.props.dotHoverOff(
                    this.state.timeBarHoveredDot,
                    (this.state.timeBarHoveredDot === this.props.selected),
                    true
                );

                // Update tooltip
                this.setState({
                    timeBarHoveredDot: null,
                    timeBarHoveredTransit: true
                });
            }

            return tooltipContent;
        }
    }

    getTargetDot() {
        // Calculate seconds of target time
        const totalLength = (this.rightPosition - this.leftPosition);
        const targetLength = Math.max(this.mouseX - this.leftPosition, 0);
        const targetSeconds = (this.totalTime * targetLength) / totalLength;

        // Find hovered dot
        let targetIndexDot = null;
        let targetIndexTransit = null;
        if ((targetSeconds >= 0) && (targetSeconds < this.transitTimeSum[0])) {
            targetIndexTransit = 0;
        }
        else {
            for (let i = 0; i < this.props.itinerary.length; i++) {
                if ((targetSeconds >= this.transitTimeSum[i]) && (targetSeconds <= this.dotTimeSum[i])) {
                    targetIndexDot = i;
                }
                else {
                    if ((targetSeconds > this.transitTimeSum[i]) && (targetSeconds < this.transitTimeSum[i + 1])) {
                        targetIndexTransit = i + 1;
                    }
                }
            }
        }

        /*
        console.log("==================================================================");
        console.log("TimeBar / getTargetDot - this.rightPosition = ", this.rightPosition);
        console.log("TimeBar / getTargetDot - this.leftPosition = ", this.leftPosition);
        console.log("TimeBar / getTargetDot - totalLength = ", totalLength);
        console.log("TimeBar / getTargetDot - this.targetX = ", this.targetX);
        console.log("TimeBar / getTargetDot - targetLength = ", targetLength);
        console.log("------------------------------------------------------------------");
        console.log("TimeBar / getTargetDot - this.totalTime = ", this.totalTime);
        console.log("TimeBar / getTargetDot - targetSeconds = ", targetSeconds);
        console.log("TimeBar / getTargetDot - this.transitTimeSum = ", this.transitTimeSum);
        console.log("TimeBar / getTargetDot - this.dotTimeSum = ", this.dotTimeSum);
        console.log("------------------------------------------------------------------");        
        console.log("TimeBar / getTargetDot - targetIndexDot = ", targetIndexDot);
        console.log("TimeBar / getTargetDot - targetIndexTransit = ", targetIndexTransit);
        console.log("==================================================================");
        */

        return [targetIndexDot, targetIndexTransit, targetSeconds];
    }

}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

export default connect(mapStateToProps, null)(TimeBar); 
