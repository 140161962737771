/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    About.js
    - Introduction of The Dots Team
--------------------------------------------------------------------------------------------
    Content
    - About
============================================================================================
*/

// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
//import { bindActionCreators } from "redux";


class About extends Component {
    constructor (props) {
        super(props);
        this.state = {}
    }
    
    render () {
        return (
            <div id = "about-container"
                className = {(this.props.browserWidth <= window.browserWidthSmall)? 
                    "about-container-small" : "about-container"}
            >
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

/*
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    ); 
}
*/

export default connect(mapStateToProps, null)(About);
