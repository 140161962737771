/*
============================================================================================
    projectionect Dots
--------------------------------------------------------------------------------------------
    SearchOpenMap.js
    - Open Street Map Based Components and Functions
--------------------------------------------------------------------------------------------
    Content
    - SearchOpenMap
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Redux
import {
    storeLogInOn,
    storeSignUpOn,
    storeWarningAlert,
    storeNotificationAlert,
    storeCreate
} from "actions";

// Modules
//import polyline from "@mapbox/polyline";

// Map and open layers
import "ol/ol.css";
import { Map } from "ol";
import {
    //defaults as DefaultControls,
    Control
} from "ol/control";

// View
import { View } from "ol";

// projectionection
import * as projection from "ol/proj";
import { fromLonLat } from "ol/proj";
import { toLonLat } from "ol/proj";

// Extent
import {
    getBottomLeft,
    getTopRight
} from 'ol/extent';

// Open street map and bing map tiles
import { OSM } from "ol/source";
import { BingMaps } from "ol/source";

// Tile and tile grid
import { Tile } from "ol/layer";
import { TileJSON } from "ol/source";
import { XYZ } from "ol/source";

// Geometry
//import * as geom from "ol/geom";
import { Point } from "ol/geom";
//import { LineString } from "ol/geom";
//import { Circle } from "ol/geom";

// Vector layer and source
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";

// Overlay and Feature
import { Overlay } from "ol";
import { Feature } from "ol";

// Geo json
import { GeoJSON } from "ol/format";

// Style
import { Style } from "ol/style";
//import { Icon as IconStyle} from "ol/style";
import { Circle as CircleStyle } from "ol/style";
import { Fill as FillStyle } from "ol/style";
import { Stroke as StrokeStyle } from "ol/style";

// Vector tiles from mapbox
//import MVT from "ol/format/MVT";
//import VectorTileLayer from "ol/layer/VectorTile";
//import VectorTileSource from "ol/source/VectorTile";

// Open layers mapbox styles
//import { register } from "ol/proj/proj4";
//import proj4 from "proj4";
//import olms from 'ol-mapbox-style';
import apply from 'ol-mapbox-style';
import FullScreen from 'ol/control/FullScreen';
//import { defaultResolutions } from 'ol-mapbox-style/dist/util';
//import { applyBackground, applyStyle } from "ol-mapbox-style";
//import stylefunction from 'ol-mapbox-style/dist/stylefunction';

// Functions
import {
    getStaticPath,
    getMediaProperty,
    sortMedia
} from "js/Functions";

// Map functions
import {
    //latLngToPoint,
    getDistanceFromLatLonInKm,
    //degToRad,
    getUserLocation
} from "components/Map";

// Axios
import {
    getNearbySearch
} from "requests";

// CSS
import "./SearchOpenMap.css";


class SearchOpenMap extends Component {
    constructor(props) {
        super(props);

        // DOM node
        this.openMapRef = React.createRef();
        this.mapSearchInputRef = React.createRef();

        // Use cloud tiles
        this.cloudTiles = true;

        // Zoom in level
        this.zoomInLevel = 16;
        this.zoomOutLevel = this.props.mapZoom;
        this.zoomLevelDifference = 2;

        // Zoom limits for cloud tiles
        this.maxZoomCloudDict = {
            "roadmap": 18,
            "terrain": 18,
            "hybrid": 18,
            "satellite": 18
        };

        // Zoom limits for self-hosted tiles
        this.maxZoomDict = {
            "roadmap": 18,
            "terrain": 18,
            "hybrid": 13,
            "satellite": 13
        };

        // Zoom to fit switch
        this.zoomToFitOn = false;

        // Search placeholder
        this.searchInputPlaceholder = "Search Within Map";

        // Handles to store map related objects
        this.openMap = null;
        this.markersLayer = null;
        this.markersSource = null;
        this.childMarkersLayer = null;
        this.childMarkersSource = null;
        this.polygonLayer = null;
        this.polygonSource = null;

        // Map action listener keys
        this.setMapClickListenerKey = null;

        // Marker action listener keys
        this.markerHoverListenerKey = null;

        // Marker information window
        this.markerInformationOverlay = null;
        this.hoveredMarkerInformationOverlay = null;

        // Marker pulse animation
        this.markerPulseOverlay = null;

        // First touch
        this.firstTouchedDotIndex = null;
        this.firstTouchedChildIndex = null;
        this.longPressTimer = null;
        this.longPressCancelRadius = 15;
        this.longPressPosition = null;

        // Create Mode
        this.createMenuOn = false;

        // Create location
        this.location = null;

        // Initialize states
        this.state = {
            // Map height
            mapHeight: props.mapHeight,
            mapMinHeight: props.mapMinHeight,
            mapMaxHeight: props.mapMaxHeight,
            mapHeightIncrement: props.mapHeightIncrement,

            // Markers
            markers: [],
            childMarkers: [],

            // Search Button state
            searchButtonOn: false,

            // User Current Location
            userCurrentLocation: null,

            // Search Box
            searchBoxWidth: 230,
            searchBoxHeight: 40,

            // Search input focused
            searchInputFocused: false,

        };

        // Initialize map
        this.initializeMap = this.initializeMap.bind(this);
        this.initializeLayout = this.initializeLayout.bind(this);

        // Bind marker functions
        this.setMarkers = this.setMarkers.bind(this);
        this.removeMarkers = this.removeMarkers.bind(this);
        this.setChildMarkers = this.setChildMarkers.bind(this);
        this.removeChildMarkers = this.removeChildMarkers.bind(this);

        // Bind mouse action
        this.panToDot = this.panToDot.bind(this);
        this.panToUser = this.panToUser.bind(this);

        // Mouse action listeners
        this.setHoverListener = this.setHoverListener.bind(this);
        this.setMapClickListener = this.setMapClickListener.bind(this);

        // Mouse action state updaters
        this.markerHoverOn = this.markerHoverOn.bind(this);
        this.markerHoverOff = this.markerHoverOff.bind(this);
        this.hoverOn = this.hoverOn.bind(this);
        //this.clickOn = this.clickOn.bind(this);
        //this.clickOff = this.clickOff.bind(this);

        // Marker information
        this.setMarkerInformation = this.setMarkerInformation.bind(this);

        // Marker pulse
        this.setMarkerPulse = this.setMarkerPulse.bind(this);
        this.markerPulseOn = this.markerPulseOn.bind(this);

        // Bind map height functions
        this.addMapHeightButtons = this.addMapHeightButtons.bind(this);
        this.mapHeightIncrease = this.mapHeightIncrease.bind(this);
        this.mapHeightDecrease = this.mapHeightDecrease.bind(this);

        // Zoom functions
        this.zoomToFit = this.zoomToFit.bind(this);
        this.zoomToFitChildren = this.zoomToFitChildren.bind(this);
        this.getZoom = this.getZoom.bind(this);

        // Bind map button function
        this.addMapFunctionButtons = this.addMapFunctionButtons.bind(this);
        this.nearbySearch = this.nearbySearch.bind(this);

        // Polygon
        this.addPolygon = this.addPolygon.bind(this);
        this.removePolygon = this.removePolygon.bind(this);
        this.zoomToFitPolygon = this.zoomToFitPolygon.bind(this);
        this.getFeatureStyle = this.getFeatureStyle.bind(this);

        // Function bind
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);

        // Create Button state updater
        this.createPopUp = this.createPopUp.bind(this);
        this.removeCreatePopUp = this.removeCreatePopUp.bind(this);
        this.yesCreateButton = this.yesCreateButton.bind(this);
        this.noCreateButton = this.noCreateButton.bind(this);

    }

    render() {

        const searchInputProps = {
            type: "text",
            placeholder: (this.state.searchInputFocused)? "" : this.searchInputPlaceholder,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            autoComplete: "off",
        };

        // JSX
        return (
             <div id = "search-open-map-container"
                style = {{
                    width: this.props.mapContainerWidth
                }}
            >
                <div>
                    <div id = "search-open-map-left-column"
                    style = {{
                        width: this.props.leftColumnWidth,
                    }}
                    >
                    </div>
                    <div id = {(this.props.browserWidth <= 6)?
                        "search-open-map-small" : "search-open-map"}
                    >
                        <div id = "search-open-map-search-box"
                            style = {{
                                width: this.state.searchBoxWidth,
                                height: this.state.searchBoxHeight,
                                zIndex: 101
                            }}
                        >
                            <input
                                ref = {this.mapSearchInputRef}
                                id = "search-open-map-search-input"
                                className = {(this.props.colorMode === "day")?
                                    "input-s2 input-day" : "input-s2 input-night"}
                                {...searchInputProps}
                            />
                            <div
                                id = "search-open-map-search-button"
                                className = {(this.state.searchButtonOn === false)?
                                    "search-open-map-search-button-off w5" :
                                    "search-open-map-search-button-on w5"}
                                onClick = {this.nearbySearch}
                            >
                                Search
                            </div>
                        </div>
                        <div id = {(this.props.browserWidth <= 6)?
                                    null : "search-open-set-map-border"}
                        >
                            <div ref = {this.openMapRef}
                                id = "search-open-set-map"
                                style = {{
                                    width: this.props.mapWidth,
                                    height: this.state.mapHeight,
                                    zIndex: 100
                                }}
                                onTouchStart = {(event) => {
                                    if (window.touchOn) {
                                        //console.log("SearchOpenMap / render / onTouchStart");
                                        //console.log("SearchOpenMap / render / onTouchStart - event.touches = ", event.touches);
                                        //console.log("SearchOpenMap / render / onTouchStart - event.touches.length = ", event.touches.length);
                                        //console.log("SearchOpenMap / render / onTouchStart - this.longPressTimer = ", this.longPressTimer);

                                        // Select first touch point
                                        const touch = event.touches[0];
                                        //console.log("onTouchStart - touch = ", touch);
                                        this.longPressPosition = [ touch.clientX, touch.clientY ];
                                        //console.log("SearchOpenMap / render / onTouchStart - this.longPressPosition = ", this.longPressPosition);

                                        // Get bounding box
                                        const boundingBox = this.openMapRef.current.getBoundingClientRect();
                                        //console.log("SearchOpenMap / render / onTouchStart - boundingBox = ", boundingBox);
                                        //console.log("SearchOpenMap / render / onTouchStart - touch.clientX = ", touch.clientX);
                                        //console.log("SearchOpenMap / render / onTouchStart - touch.clientY = ", touch.clientY);

                                        // Convert to pixels and coordinates
                                        const pixels = [ touch.clientX - boundingBox.left, touch.clientY - boundingBox.top ];
                                        const coordinates = this.openMap.getCoordinateFromPixel(pixels);
                                        //console.log("SearchOpenMap / render / onTouchStart - pixels = ", pixels);
                                        //console.log("SearchOpenMap / render / onTouchStart - coordinates = ", coordinates);

                                        // Location
                                        const lnglat = projection.transform(coordinates, "EPSG:3857", "EPSG:4326");
                                        const location = {
                                            latitude: lnglat[1],
                                            longitude: lnglat[0]
                                        };
                                        //console.log("SearchOpenMap / render / onTouchStart - location = ", location);

                                        // Cancel long press timer (Multi-Touches)
                                        if (event.touches.length > 1) {
                                         clearTimeout(this.longPressTimer);
                                         this.longPressTimer = null;
                                         }

                                        // Install long press timer
                                        this.longPressTimer = setTimeout(
                                            () => {
                                                this.createPopUp(location);
                                            },
                                            1000
                                        );

                                    }
                                }}
                                onTouchEnd = {(event) => {
                                    if (window.touchOn) {
                                        //console.log("SearchOpenMap / render / onTouchEnd");

                                        // Cancel long press timer
                                        if (this.longPressTimer !== null) {
                                            clearTimeout(this.longPressTimer);
                                            this.longPressTimer = null;
                                        }
                                    }
                                }}
                                onTouchMove = {(event) => {
                                    if (window.touchOn) {
                                        //console.log("SearchOpenMap / render / onTouchMove");

                                        // Cancel long press timer
                                        if (this.longPressTimer !== null) {
                                            const touch = event.touches[0];
                                            const touchDisplacement = Math.sqrt(Math.pow(this.longPressPosition[0] - touch.clientX, 2) + Math.pow(this.longPressPosition[1] - touch.clientY, 2));
                                            //console.log("SearchOpenMap / render / onTouchMove - this.longPressTimer = ", this.longPressTimer);
                                            //console.log("SearchOpenMap / render / onTouchMove - touchDisplacement = ", touchDisplacement);

                                            if (touchDisplacement > this.longPressCancelRadius) {
                                                clearTimeout(this.longPressTimer);
                                                this.longPressTimer = null;
                                            }
                                        }
                                    }
                                }}
                                onMouseMove = {(event) => {
                                    if (!window.touchOn) {
                                        //console.log("SearchOpenMap / render / onMouseMove");

                                        // Cancel long press timer
                                        if (this.longPressTimer !== null) {
                                            const clickDisplacement = Math.sqrt(Math.pow(this.longPressPosition[0] - event.clientX, 2) + Math.pow(this.longPressPosition[1] - event.clientY, 2));
                                            //console.log("SearchOpenMap / render / onMouseMove - clickDisplacement = ", clickDisplacement);

                                            if (clickDisplacement > this.longPressCancelRadius) {
                                                clearTimeout(this.longPressTimer);
                                                this.longPressTimer = null;
                                            }
                                        }
                                    }
                                }}
                                onMouseDown = {(event) => {
                                    if (!window.touchOn) {
                                        //console.log("SearchOpenMap / render / onMouseDown");

                                        // Get bounding box
                                        const boundingBox = this.openMapRef.current.getBoundingClientRect();
                                        //console.log("SearchOpenMap / render / onMouseDown - boundingBox = ", boundingBox);

                                        // Select first click point
                                        this.longPressPosition = [ event.clientX, event.clientY ];
                                        //console.log("SearchOpenMap / render / onMouseDown - this.longPressPosition = ", this.longPressPosition);

                                        //console.log("SearchOpenMap / render / onMouseDown - event = ", event);
                                        //console.log("SearchOpenMap / render / onMouseDown - event.target = ", event.target);
                                        //console.log("SearchOpenMap / render / onMouseDown - event.clientX = ", event.clientX);
                                        //console.log("SearchOpenMap / render / onMouseDown - event.clientY = ", event.clientY);

                                        // Convert to pixels and coordinates
                                        const pixels = [ event.clientX - boundingBox.left, event.clientY - boundingBox.top ];
                                        const coordinates = this.openMap.getCoordinateFromPixel(pixels);
                                        //console.log("SearchOpenMap / render / onMouseDown - pixels = ", pixels);
                                        //console.log("SearchOpenMap / render / onMouseDown - coordinates = ", coordinates);

                                        // Location
                                        const lnglat = projection.transform(coordinates, "EPSG:3857", "EPSG:4326");
                                        const location = {
                                            latitude: lnglat[1],
                                            longitude: lnglat[0]
                                        };
                                        //console.log("SearchOpenMap / render / onMouseDown - location = ", location);

                                        // Install long press timer
                                        this.longPressTimer = setTimeout(
                                            () => {
                                                this.createPopUp(location);
                                            },
                                            1000
                                        );
                                    }
                                }}
                                onMouseUp = {(event) => {
                                    if (!window.touchOn) {
                                        //console.log("SearchOpenMap / render / onMouseUp");

                                        // Cancel long press timer
                                        if (this.longPressTimer !== null) {
                                            clearTimeout(this.longPressTimer);
                                            this.longPressTimer = null;
                                        }
                                    }
                                }}
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    initializeMap() {

        // OSM Base map layer
        const developLayer = new Tile(
            {
                source: new OSM()
            }
        );

        // Base raster layer
        const baseLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/klokantech-basic/{z}/{x}/{y}.png"
            })
        });

        // Terrain raster layer
        const terrainLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/klokantech-terrain/{z}/{x}/{y}.png"
            })
        });

        // Hybrid raster layer
        const hybridLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/hybrid/{z}/{x}/{y}.png"
            })
        });

        // Satellite raster layer
        const satelliteLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/satellite/{z}/{x}/{y}.png"
            })
        });

        // Bing Arial with roads
        const bingSatelliteLayer = new Tile({
            visible: true,
            preload: Infinity,
            source: new BingMaps({
                key: "Ag6EnD5xB98riaGAyNCa-kpBtogfQYVAYprgoBefcywRC-4tjNDA0yK_HVma1gm0",
                imagerySet: "AerialWithLabels",
                maxZoom: 19
            })
        });

        // MapTiler Satellite
        const mapTilerSatelliteLayer = new Tile(
            {
                source: new TileJSON(
                    {
                        url: "https://api.maptiler.com/maps/hybrid/tiles.json?key=zh1EW7kV5q0kzABuQrMS",
                        crossOrigin: "anonymous"
                    }
                )
            }
        );

        // Mapbox cloud API key
        const mapBoxKey = "pk.eyJ1IjoiaGFya2xlZSIsImEiOiJja2FvaXoxbHEwMnZ5MnBxaW5oNGhlcHljIn0.sVCVavi9TlJMgTFuC25dMw";

        // Mapbox cloud satellite
        const mapBoxSatelliteLayer = new Tile({
            source: new XYZ({
                url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=" + mapBoxKey
            })
        });

        // Tile layers
        const tileLayersDict = {
            "roadmap": baseLayer,
            "terrain": terrainLayer,
            "hybrid": bingSatelliteLayer,
            "satellite": bingSatelliteLayer
        };

        // Get the max zoom
        const maxZoom = this.getZoom(20);

        // Layers
        const tileLayers = (this.cloudTiles)?
            [ tileLayersDict[String(this.props.mapType)] ] : [];

        // Set up the map
        this.openMap = new Map({
            layers: tileLayers,
            target: "search-open-set-map",
            control: new FullScreen(),
            view: new View({
                center: fromLonLat([
                    this.props.mapCenter.longitude,
                    this.props.mapCenter.latitude
                ]),
                zoom: this.getZoom(this.props.mapZoom),
                maxZoom: maxZoom,
                projection: "EPSG:3857",
            })
        });

        // Add zoom level change listener
        this.openMap.getView().on('change:resolution',
            (event) => {
                if (!this.zoomToFitOn) {
                    this.setState({
                        searchButtonOn: true
                    });
                }
            }
        );

        // For self-hosted tiles
        // Enable vector layers
        if (!this.cloudTiles) {
            // GL Style jsons
            const styleJson = {
                "roadmap": "http://35.239.243.183/styles/klokantech-basic/style.json",
                "terrain": "http://35.239.243.183/styles/klokantech-terrain/style.json",
                "hybrid": "http://35.239.243.183/styles/hybrid/style.json",
                "satellite": "http://35.239.243.183/styles/klokantech-terrain/style.json"
            };

            // Apply mapbox style
            apply(this.openMap, styleJson[String(this.props.mapType)]).then(
                (openMap) => {
                    this.initializeLayout();
                }
            );
        }
        // For cloud tiles
        else {
            this.initializeLayout();
        }
    }

    /*
    ============================================================================================
        Initialize layout
    ============================================================================================
    */

    initializeLayout() {
        // Set markers
        this.setMarkers(null);

        // Add height control buttons
        this.addMapHeightButtons();

        // Add Map Function buttons
        this.addMapFunctionButtons();

        // Setup marker pulse
        if (this.state.userCurrentLocation !== null) {
            this.setMarkerPulse(this.state.userCurrentLocation);
        }

        // Setup mouse action listeners
        this.setHoverListener();
        this.setMapClickListener();

        // Zoom to fit markers
        this.zoomToFit(true);
    }

    componentDidMount() {

        // Initialize map
        this.initializeMap();

    }

    componentDidUpdate(prevProps, prevState) {

        // Dots info changed
        if (JSON.stringify(prevProps.dotsInfo) !== JSON.stringify(this.props.dotsInfo)) {
            // Reset marker information
            this.removeMarkerInformation();

            // Reset markers
            this.setMarkers(
                () => {
                    this.zoomToFit(true);
                }
            );
        }

        // Hover on
        if ((this.props.hovered !== null) && (prevProps.hovered !== this.props.hovered)) {
            if (prevProps.hovered !== null) {
                this.markerHoverOff();
            }
            this.hoverOn(this.props.hovered);
        }

        // Hover off
        if ((this.props.hovered === null) && (prevProps.hovered !== null)) {
            this.markerHoverOff();

            if(this.props.selected !== null) {
                this.zoomToFitChildren();
            } else {
                this.zoomToFit();
            }
        }

        // Select on
        if ((this.props.selected !== null) && (prevProps.selected !== this.props.selected)) {
            if (prevProps.selected !== null) {
                // Reset marker information
                this.removeMarkerInformation();
            }
            this.setMarkers(null);
            this.setChildMarkers(null);
        }

        // Select off (trip result -> dot result)
        if ((this.props.selected === null) && (prevProps.selected !== null)) {

            this.removeChildMarkers(false);
            this.removePolygon();
        }

    }


    /*
    ============================================================================================
        Marker Actions
    ============================================================================================
    */

    setMarkers(callback) {
        // Clear previous markers
        this.removeMarkers(false);

        // Initialize marker arrays
        let markers = [];

        // Set up counter
        let markerCount = 0;

        // For all dots
        for (let i = 0; i < this.props.dotsInfo.length; i++) {
            // Location
            const location = this.props.dotsInfo[i].location;

            // Create a marker
            const marker = this.createMarker(
                location,
                i,
                null
            );

            // Setup marker information
            this.setMarkerInformation(
                i,
                null,
                false
            );

            // Add marker to the array
            markers.push(marker);

            // Increase counter
            markerCount += 1;
        }

        if (markerCount > 0) {

            // Markers vector
            const markersSource = new VectorSource({
                features: markers,
                wrapX: false
            });

            const markersLayer = new VectorLayer({
                className: "open-map-marker-layer",
                source: markersSource,
            });
            markersLayer.setZIndex(104);

            // Set name
            markersLayer.set("name", "marker", true);

            // Save the vector source
            this.markersSource = markersSource;

            // Save the layer handle
            this.markersLayer = markersLayer;

            // Add vector layer
            this.openMap.addLayer(markersLayer);
        }
        else {
            markers = null;
        }

        // Set state and refresh map
        this.setState(
            {
                markers: markers,
            },
            callback
        );
    }

    setChildMarkers(callback) {
        // Clear previous markers
        this.removeChildMarkers(false);

        // Clear previous Polygon
        this.removePolygon();

        // Initialize marker arrays
        let childMarkers = [];

        // Set up counter
        let childMarkerCount = 0;
        //console.log("SearchOpenMap / setChildMarkers - this.props = ", this.props);

        // Child dots
        const childrenInfo = this.props.dotsInfo[this.props.selected].trip_extension.children;

        // For all dots
        for (let i = 0; i < childrenInfo.length; i++) {
            // Get location
            const location = childrenInfo[i].location;

            // Create a marker
            const childMarker = this.createMarker(
                location,
                this.props.selected,
                i
            );

            // Setup marker information
            this.setMarkerInformation(
                this.props.selected,
                i,
                false
            );

            // Add marker to the array
            childMarkers.push(childMarker);

            // Increase counter
            childMarkerCount += 1;
        }

        if (childMarkerCount > 0) {
            // Markers vector
            const childMarkersSource = new VectorSource({
                features: childMarkers,
                wrapX: false
            });

            const childMarkersLayer = new VectorLayer({
                className: "open-map-child-layer",
                source: childMarkersSource,
                zIndex: 103
            });

            // Set name
            childMarkersLayer.set("name", "child", true);

            // Save the vector source
            this.childMarkersSource = childMarkersSource;

            // Save the layer handle
            this.childMarkersLayer = childMarkersLayer;

            // Add vector layer
            this.openMap.addLayer(childMarkersLayer);
        }
        else {
            childMarkers = null;
        }

        // Set state and refresh map
        this.setState(
            {
                childMarkers: childMarkers,
            },
            callback,
        );

        //add polygon
        this.addPolygon();
    }

    createMarker(location, dotIndex, childIndex) {
        // Set up a marker
        const marker = new Feature({
            geometry: new Point(
                fromLonLat([
                    location.longitude,
                    location.latitude
                ])
            ),
            dotIndex: dotIndex,
            childIndex: childIndex
        });

        const fill = new FillStyle({
            color: "rgba(255,255,255,0.4)"
        });

        const stroke = new StrokeStyle({
            color: "rgb(255,255,255)",
            width: 2
        });

        // Marker circle
        marker.setStyle(new Style({
            image: new CircleStyle({
               fill: fill,
               stroke: stroke,
               radius: 30
             }),
            zIndex: 102
        }));

        return marker;
    }

    removeMarkers(updateState) {
        if ((this.state.markers !== null) && (this.markersLayer !== null)) {
            this.markersLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    markers: null
                });
            }
        }
    }

    removeChildMarkers(updateState) {
        if ((this.state.childMarkers !== null) && (this.childMarkersLayer !== null)) {
            this.childMarkersLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    childMarkers: null
                });
            }

        }
    }

    /*
    ============================================================================================
        Marker Pulse
    ============================================================================================
    */

   setMarkerPulse(location) {
        const userMarkerImage = getStaticPath("/images/map/dot-marker-black.png", true);
        //(this.props.colorMode === "day")?
        //    getStaticPath("/images/number/single_white_O.png", true) :
        //    getStaticPath("/images/number/single_black_O.png", true)
        const userMarker = document.createElement("div");
        userMarker.className = "search-open-map-user-marker image-cover";
        userMarker.style.backgroundImage = userMarkerImage;

        const userMarkerPulse = document.createElement("div");
        userMarkerPulse.className = "search-open-map-marker-pulse-black";
        userMarkerPulse.appendChild(userMarker);

        // Set up a new overlay
        this.markerPulseOverlay = new Overlay({
            position: fromLonLat([ location.longitude, location.latitude ]),
            positioning: "center-center",
            element: userMarkerPulse,
            stopEvent: false
        });

        this.openMap.addOverlay(this.markerPulseOverlay);
    }

    markerPulseOn(location) {
        const coordinates = fromLonLat([
            location.longitude,
            location.latitude
        ]);

        // Move the marker information to the hovered location
        this.markerPulseOverlay.setPosition(coordinates);
    }

    /*
    ============================================================================================
        Map Height
    ============================================================================================
    */

    addMapHeightButtons() {
        const heightDecreaseButton = document.createElement("button");
        heightDecreaseButton.innerHTML = "∧";
        heightDecreaseButton.addEventListener("click", this.mapHeightDecrease, false);

        const heightIncreaseButton = document.createElement("button");
        heightIncreaseButton.innerHTML = "∨";
        heightIncreaseButton.addEventListener("click", this.mapHeightIncrease, false);

        const heightButtons = document.createElement("div");
        heightButtons.className = "search-open-map-height-buttons ol-unselectable ol-control";
        heightButtons.appendChild(heightDecreaseButton);
        heightButtons.appendChild(heightIncreaseButton);

        const heightControl = new Control({
            element: heightButtons
        });

        this.openMap.addControl(heightControl);
    }


    mapHeightIncrease() {
        const mapHeight = (this.state.mapHeight < this.props.mapMaxHeight)?
            this.state.mapHeight + this.props.mapHeightIncrement : this.state.mapHeight;

        this.setState({
                mapHeight: mapHeight
            },
            () => {this.openMap.updateSize();
        });
    }


    mapHeightDecrease() {
        const mapHeight = (this.state.mapHeight > this.props.mapMinHeight)?
            this.state.mapHeight - this.props.mapHeightIncrement : this.state.mapHeight;

        this.setState({
                mapHeight: mapHeight
            },
            () => {this.openMap.updateSize();
        });
    }

    /*
    ============================================================================================
        Map Function Buttons
    ============================================================================================
    */

    addMapFunctionButtons() {
        // User Current Location
        const userLocationButton = document.createElement("button");
        userLocationButton.innerHTML = "O";
        userLocationButton.addEventListener("click", this.panToUser, false);

        const functionButtons = document.createElement("div");
        functionButtons.className = "search-open-map-function-buttons ol-unselectable ol-control";
        functionButtons.appendChild(userLocationButton);

        const functionControl = new Control({
            element: functionButtons
        });

        this.openMap.addControl(functionControl);
    }


    /*
    ============================================================================================
        Nearby Search
    ============================================================================================
    */

    nearbySearch() {
        // Map Center
        const extentCenter = this.openMap.getView().getCenter();

        // Convert LonLat
        const extentCenterLonLat = projection.transform(extentCenter, 'EPSG:3857', 'EPSG:4326');

        // Change Data form
        const centerLocation = {
            latitude: extentCenterLonLat[1],
            longitude: extentCenterLonLat[0]
        };

        // Detect Map Extent
        const extent = this.openMap.getView().calculateExtent(this.openMap.getSize());
        const bottomLeft = toLonLat(getBottomLeft(extent));
        const topRight = toLonLat(getTopRight(extent));

        // Get coordinates of map corners
        const lat1 = bottomLeft[1];
        const lon1 = bottomLeft[0];
        const lat2 = topRight[1];
        const lon2 = topRight[0];

        // Get distance
        const distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);   // km   1km = 0.621371 mile

        // Search resorce for server requset
        const searchRadius = Number(distance / 2 * 0.621371).toFixed(4); // covert km to mile

        // Convert search location to string
        const centerLocationString = "{'latitude':"
            + centerLocation.latitude
            + ",'longitude':"
            + centerLocation.longitude
            + "}";

        // Get keywords
        const keywords = (this.mapSearchInputRef.current.value.length === 0)?
            null : this.mapSearchInputRef.current.value;

        // Callback
        const axiosCallback = (response) => {
            //console.log("SearchOpenMap / nearbySearch - response = ", response);

            const dotsInfo = response.data.content.dots;
            const tripsInfo = response.data.content.trips;

            let resultMode = "dot";
            if (dotsInfo === null && tripsInfo !== null) {
                resultMode = "trip";
            }

            // Update parent state
            this.props.setState(
                {
                    resultMode: resultMode,
                    keywords: keywords,
                    searchMode: "nearby",
                    searchCenterLocation : centerLocation,
                    searchRadius : searchRadius,
                    dotPage: 1,
                    dotMaxPage: (dotsInfo === null)? null :
                        response.data.content.dot_results_total_pages,
                    dotsInfo: (dotsInfo === null)? null : { "1": dotsInfo },
                    tripPage: 1,
                    tripMaxPage: (tripsInfo === null)? null :
                        response.data.content.trip_results_total_pages,
                    tripsInfo: (tripsInfo === null)? null : { "1": tripsInfo },
                    hovered: null,
                    selected: null,
                    hoveredChildID: null,
                    mapInitialized: true
                }
            );
        };

        // Send request to server
        getNearbySearch(
            "nearby",
            centerLocationString,
            searchRadius,
            this.props.searchRadiusUnit,
            keywords,
            1
        )
        .then(axiosCallback)
        .catch(
            (response) =>{
                console.log("[WARNING] Search / nearbySearch - error = ", response);
            }
        );

        //add setState to change color of search button
        this.setState({
            searchButtonOn: false
        });
    }


    /*
    ============================================================================================
        Nearby Search Input Functions
    ============================================================================================
    */

    handleFocus() {
        this.setState({
            searchInputFocused: true
        });
    }

    handleBlur() {
        this.setState({
            searchInputFocused: false
        });
    }

    handleChange() {
    }


    /*
    ============================================================================================
        Mouse Actions Listeners
    ============================================================================================
    */

    setHoverListener() {
        // Save context
        const that = this;

        // Set up hover event listener and layer
        this.markerHoverListenerKey = this.openMap.on(
            "pointermove",
            (event) => {
                //console.log("SearchOpenMap / setHoverListener - pointermove");
                //console.log("SearchOpenMap / setHoverListener - event = ", event);

                // Initialize hovered feature
                let hoveredFeature = null;
                let hoveredLayer = null;

                // Detect feature
                that.openMap.forEachFeatureAtPixel(
                    event.pixel,
                    (detectedFeature, detectedLayer) => {
                        hoveredFeature = detectedFeature;
                        hoveredLayer = detectedLayer;
                    },
                    {
                        hitTolerance: 0,
                        layerFilter: (detectedLayer) => {
                            return (detectedLayer === that.markersLayer) ||
                            (detectedLayer === that.childMarkersLayer);
                        }
                    }
                );

                if (hoveredFeature && ((hoveredLayer.get("name") === "marker") || (hoveredLayer.get("name") === "child"))) {

                    // Mouse cursor style
                    that.openMap.getTargetElement().style.cursor = "pointer";

                    // Fetch properties
                    const hoveredFeatureProperties = hoveredFeature.getProperties();
                    const dotIndex = hoveredFeatureProperties.dotIndex;
                    const childIndex = hoveredFeatureProperties.childIndex;

                    // Update marker hover effect
                    that.markerHoverOn(dotIndex, childIndex);
                }
                else {
                    // Mouse cursor style
                    that.openMap.getTargetElement().style.cursor = "default";

                    // Update marker hover effect
                    that.markerHoverOff();
                }
            }
        );
    }

    setMapClickListener() {
        // Save context
        const that = this;

        // Set up click event listener
        this.markerClickListenerKey = this.openMap.on(
            "click",
            (event) => {
                //console.log("SearchOpenMap / setMapClickListener - click");

                // Initialize clicked feature and layer
                let clickedFeature = null;
                let clickedLayer = null;

                // Detect feature
                that.openMap.forEachFeatureAtPixel(
                    event.pixel,
                    (detectedFeature, detectedLayer) => {
                        clickedFeature = detectedFeature;
                        clickedLayer = detectedLayer;
                    },
                    {
                        hitTolerance: 0,
                        layerFilter: (detectedLayer) => {
                            return (detectedLayer === that.markersLayer) ||
                            (detectedLayer === that.childMarkersLayer);
                        }
                    }
                );

                if (clickedFeature && ((clickedLayer.get("name") === "marker") || (clickedLayer.get("name") === "child"))) {

                    // Fetch properties
                    const clickedFeatureProperties = clickedFeature.getProperties();
                    const dotIndex = clickedFeatureProperties.dotIndex;
                    const childIndex = clickedFeatureProperties.childIndex;
                    const type = (that.props.resultMode === "dot")?
                        "dot" : (
                            (childIndex === null)? "trip" : "dot"
                        );

                    const dotInfo = (childIndex === null)?
                        that.props.dotsInfo[dotIndex] :
                        that.props.dotsInfo[dotIndex].trip_extension.children[childIndex];

                    if (window.touchOn) {
                        // Second touched
                        const secondTouched = (childIndex === null)?
                            (that.firstTouchedDotIndex !== null && that.firstTouchedDotIndex === dotIndex) :
                            ((that.firstTouchedDotIndex !== null && that.firstTouchedDotIndex === dotIndex) &&
                            (that.firstTouchedChildIndex !== null && that.firstTouchedChildIndex === childIndex));

                        // Detect touch marker
                        if (secondTouched) {
                            // Navigate to second touched dot
                            setTimeout(
                                () => {
                                    that.props.history.push(`/${type}/${dotInfo.slug}`);
                                },
                                300
                            )
                        }
                        else {
                            // Update marker hover effect
                            that.markerHoverOn(dotIndex, childIndex);

                            // Record first touched index
                            that.firstTouchedDotIndex = dotIndex;
                            that.firstTouchedChildIndex = childIndex;
                        }
                    }
                    else {
                        // Navigate to second touched dot
                        setTimeout(
                            () => {
                                // Navigate to second touched dot
                               that.props.history.push(`/${type}/${dotInfo.slug}`);
                            },
                            300
                        );
                    }
                }
                else {
                    // For touch devices
                    if (window.touchOn) {
                        // Mouse cursor style
                        that.openMap.getTargetElement().style.cursor = "default";

                        // Update marker hover effect
                        that.markerHoverOff();

                        // First touched
                        that.firstTouchedDotIndex = null;
                        that.firstTouchedChildIndex = null;
                    }
                }
            }
        );
    }


    /*
    ============================================================================================
        Create Button
    ============================================================================================
    */
    createPopUp(location) {

        // Update location
        this.location = location;

        // Remove create pop up overlay
        this.removeCreatePopUp()

        const createPopUp = document.createElement("div");
        createPopUp.className = "search-open-map-create-box";
        const content = document.createElement("div");
        content.className = "search-open-map-create-box-text w3";
        content.textContent = "Do you want to create a dot?";

        const answerContainer = document.createElement("div");
        answerContainer.className = "search-open-map-create-answer-container";
        const yesButton = document.createElement("button");
        yesButton.className = "search-open-map-create-answer-button w4";
        yesButton.textContent = "Yes";
        yesButton.addEventListener("click", this.yesCreateButton, false);

        const noButton = document.createElement("button");
        noButton.className = "search-open-map-create-answer-button w4";
        noButton.textContent = "No";
        noButton.addEventListener("click", this.noCreateButton, false);

        // Insert button into container
        answerContainer.appendChild(yesButton);
        answerContainer.appendChild(noButton);

        // Insert content into container
        createPopUp.appendChild(content);
        createPopUp.appendChild(answerContainer);

        // Set up a new overlay
        this.createPopUpOverlay = new Overlay({
            position: fromLonLat([ location.longitude, location.latitude ]),
            positioning: "bottom-center",
            element: createPopUp,
            autoPan: true,
            //stopEvent: false,
        });

        this.openMap.addOverlay(this.createPopUpOverlay);

        // add temp marker
        this.setMarkerPulse(location);

    }

    removeCreatePopUp() {
        this.openMap.removeOverlay(this.createPopUpOverlay);
        this.openMap.removeOverlay(this.markerPulseOverlay);
    }

    yesCreateButton() {
        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && this.props.userInfo !== null);
        //console.log("NationalParkMap / yesCreateButton - loggedIn = ", loggedIn);
        //console.log("NationalParkMap / yesCreateButton - this.location = ", this.location);

        if (loggedIn) {
            // Open create modal
            this.props.storeCreate({
                modalOn: true,
                mode: "create",
                setSlug: null,
                location: this.location,
                info: null
            });

            // Reset create locatipn
            this.location = null;
        }
        else {
            // Open log in
            this.props.storeLogInOn(true);
        }

        // remove create pop up overlay
        this.removeCreatePopUp()
    }

    noCreateButton() {
        // Reset create locatipn
        this.location = null;

        // remove create pop up overlay
        this.removeCreatePopUp()
    }

    /*
    ============================================================================================
        Mouse Actions State Updaters
    ============================================================================================
    */

    panToDot(dotIndex) {
        // Get the view of the map
        const view = this.openMap.getView();
        const dotLocation = this.props.dotsInfo[dotIndex].location;
        const dotCoordinates = fromLonLat([ dotLocation.longitude, dotLocation.latitude ]);
        //console.log("SearchOpenMap / panToDot - this.zoomOutLevel = ", this.zoomOutLevel);
        //console.log("SearchOpenMap / panToDot - this.zoomLevelDifference = ", this.zoomLevelDifference);

        // Zoom in and center the map
        view.animate(
            {
                duration: 600,
                center: dotCoordinates,
                zoom: this.getZoom(this.zoomOutLevel + this.zoomLevelDifference)
            }
        );
    }

    panToUser() {
        // Turn on progress alert
        this.props.storeNotificationAlert({
            message: "Browser Detecting Location.",
            on: true
        });

        const successCallback = (userLocation) => {
            //console.log("SearchOpenMap / panToUser - userLocation = ", userLocation);

            // Turn off progress alert
            this.props.storeNotificationAlert({
                message: null,
                on: false
            });

            // Get the view of the map
            const view = this.openMap.getView();

            // Convert to user coordinates
            const userCoordinates = fromLonLat([
                userLocation.longitude,
                userLocation.latitude
            ]);

            this.setMarkerPulse(userLocation);
            this.markerPulseOn(userLocation);

            // Zoom in and center the map
            view.animate(
                {
                    duration: 600,
                    center: userCoordinates,
                    zoom: this.getZoom(this.zoomInLevel)
                }
            );

            // Update parent state
            this.props.setState({
                userLocation: userLocation
            });
        };

        const failureCallback = () => {
            // Turn off progress alert
            this.props.storeNotificationAlert({
                message: null,
                on: false
            });

            // Turn on warning
            this.props.storeWarningAlert({
                message: "Browser Does Not Support Location Detection.",
                on: true
            });

            // Turn off warning
            setTimeout(
                () => {
                    this.props.storeWarningAlert({
                        message: null,
                        on: false
                    });
                },
                1500
            );
        };

        const blockCallback = () => {
            // Turn off progress alert
            this.props.storeNotificationAlert({
                message: null,
                on: false
            });

            // Turn on warning
            this.props.storeWarningAlert({
                message: "Browser Has Been Blocked from Detecting Location.",
                on: true
            });

            // Turn off warning
            setTimeout(
                () => {
                    this.props.storeWarningAlert({
                        message: null,
                        on: false
                    });
                },
                1500
            );
        };

        // Execute get user location
        getUserLocation(
            successCallback,
            failureCallback,
            blockCallback
        );
    }

    hoverOn(dotIndex) {
        // Pan to hovered dot
        this.panToDot(this.props.hovered);

        // remove hovered marker information
        this.markerHoverOff();

        // hovered marker information
        this.setMarkerInformation(
            this.props.hovered,
            null,
            true
        );
    }

    markerHoverOn(dotIndex, childIndex) {
        //console.log("SearchOpenMap / markerHoverOn - dotIndex = ", dotIndex);

        // remove hovered marker information
        this.markerHoverOff();

        // hovered marker information
        this.setMarkerInformation(
            dotIndex,
            childIndex,
            true
        );

        this.props.setState({
            hoveredMarker: dotIndex
        });
    }

    markerHoverOff() {
        // remove hovered marker information
        this.openMap.removeOverlay(this.hoveredMarkerInformationOverlay);

        this.props.setState({
            hoveredMarker: null
        });
    }

    /*
    ============================================================================================
        Marker Information
    ============================================================================================
    */

    setMarkerInformation(dotIndex, childIndex, hovered) {
        // Dot info
        const dotInfo = (childIndex === null)?
            this.props.dotsInfo[dotIndex] :
            this.props.dotsInfo[dotIndex].trip_extension.children[childIndex];

        // Get location
        const location = dotInfo.location;

        // Media
        const mediaSorted = sortMedia(dotInfo);
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Is video
        const isVideo = (media[0].type === "video");

        // Dot media
        const dotMedia = (isVideo)?
            (
                ("t" in media[0].files)?
                    getMediaProperty(media[0], "t", "url", false) :
                    getStaticPath("/images/common/search-open-map-video.png", false)
            ) : (
                getMediaProperty(media[0], "t", "url", false)
            );

        // Create new markerInformation
        let markerInformation = (hovered)?
            document.createElement("search-open-map-marker-information-hover-on") :
            document.createElement("search-open-map-marker-information-hover-off");

        // Media
        const mediaElement = (hovered)?
            document.createElement("search-open-map-hovered-marker-media") :
            document.createElement("search-open-map-marker-media");
        mediaElement.className = (hovered)?
            "search-open-map-marker-media search-open-map-marker-media-on" :
            "search-open-map-marker-media search-open-map-marker-media-off";
        mediaElement.style.background = "url(" + dotMedia + ")";
        mediaElement.style.backgroundSize = "cover";

        // Name
        const nameElement = (hovered)?
            document.createElement("search-open-map-hovered-marker-name") :
            document.createElement("search-open-map-marker-name");
        nameElement.className = (hovered)?
            "search-open-map-marker-name search-open-map-marker-name-on w5" :
            "search-open-map-marker-name search-open-map-marker-name-off w5";
        nameElement.textContent = dotInfo.name;

        // Insert name and media into marker
        markerInformation.appendChild(mediaElement);
        markerInformation.appendChild(nameElement);

        // Set up a new overlay
        if (hovered) {
            // Set up a new hovered overlay
            this.hoveredMarkerInformationOverlay = new Overlay({
                position: fromLonLat([ location.longitude, location.latitude ]),
                positioning: "center-center",
                element: markerInformation,
                stopEvent: false,
                className: "search-open-map-marker-information-hover-on",
            });

            this.openMap.addOverlay(this.hoveredMarkerInformationOverlay);
        }
        else {
            this.markerInformationOverlay = new Overlay({
                position: fromLonLat([ location.longitude, location.latitude ]),
                positioning: "center-center",
                element: markerInformation,
                stopEvent: false,
                className: (hovered)?
                    "search-open-map-marker-information-hover-on" :
                    "search-open-map-marker-information"
            });

            this.openMap.addOverlay(this.markerInformationOverlay);
        }
    }

    removeMarkerInformation() {
        this.openMap.getOverlays().clear()
    }

    /*
    ============================================================================================
        Zoom Actions
    ============================================================================================
    */

    zoomToFit(updateZoomOutLevel) {
        if (this.props.dotsInfo.length > 0) {
            // Turn on zoom to fit progress status
            this.zoomToFitOn = true;

            // Get current view
            const view = this.openMap.getView();

            view.fit(
                this.markersSource.getExtent(),
                {
                    duration: 600,
                    padding: [100, 100, 100, 100],
                    constrainResolution: false,
                    callback: () => {
                        this.openMap.updateSize();
                        if (updateZoomOutLevel) {
                            this.zoomOutLevel = view.getZoom();
                        }

                        // Turn off zoom to fit progress status
                        this.zoomToFitOn = false;
                        //console.log("SearchOpenMap / zoomToFit - this.zoomOutLevel = ", this.zoomOutLevel);
                    }
                }
            );
        }
    }

    zoomToFitChildren(updateZoomOutLevel) {
        if (this.props.dotsInfo.length > 0) {
            // Turn on zoom to fit progress status
            this.zoomToFitOn = true;

            // Get current view
            const view = this.openMap.getView();

            view.fit(
                this.childMarkersSource.getExtent(),
                {
                    duration: 600,
                    padding: [100, 100, 100, 100],
                    constrainResolution: false,
                    callback: () => {
                        this.openMap.updateSize();
                        if (updateZoomOutLevel) {
                            this.zoomOutLevel = view.getZoom();
                        }

                        // Turn off zoom to fit progress status
                        this.zoomToFitOn = false;
                        //console.log("SearchOpenMap / zoomToFit - this.zoomOutLevel = ", this.zoomOutLevel);
                    }
                }
            );
        }
    }


    /*
    ============================================================================================
        Get max zoom
    ============================================================================================
    */

    getZoom(zoom) {
        let adjustedZoom = null;
        if (this.cloudTiles) {
            adjustedZoom = Math.min(this.maxZoomCloudDict[String(this.props.mapType)], zoom);
        }
        else {
            adjustedZoom = Math.min(this.maxZoomDict[String(this.props.mapType)], zoom);
        }

        return adjustedZoom;
    }


    /*
    ============================================================================================
        Polygon
    ============================================================================================
    */
    addPolygon() {
        // Remove the vector layer
        this.removePolygon();

        // Construct geoJson
        const geoJson = {
            "type": "Feature",
            "properties": {
                "name": "polygon"
            },
            "geometry": {
                "type":"Polygon",
                "coordinates": []
            }
        };

        // Coordinate conversion
        const coordinates = [];

        // Coordinate child dots
        const childrenInfo = this.props.dotsInfo[this.props.selected].trip_extension.children;

        for (let i = 0; i < childrenInfo.length; i++) {
            coordinates.push(
                fromLonLat([
                    childrenInfo[i].location.longitude,
                    childrenInfo[i].location.latitude
                ])
            );
        }

        // Convex hull function (clockwise)
        function cross(a, b, o) {
           return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0])
        }
        // @param points An array of [X, Y] coordinates
        function convexHull(points) {
            points.sort(function(a, b) {
                return a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];
            });
            // Compute the lower hull
            const lower = [];
            for (let i = 0; i < points.length; i++) {
                while (lower.length >= 2 && cross(lower[lower.length - 2], lower[lower.length - 1], points[i]) <= 0) {
                    lower.pop();
                }
                lower.push(points[i]);
            }
            // Compute the upper hull
            const upper = [];
            for (let i = points.length - 1; i >= 0; i--) {
                while (upper.length >= 2 && cross(upper[upper.length - 2], upper[upper.length - 1], points[i]) <= 0) {
                    upper.pop();
                }
                upper.push(points[i]);
            }
            upper.pop();
            lower.pop();
            return lower.concat(upper);
        }

        let convexHullCoordinates = [];
        convexHullCoordinates = convexHull(coordinates);

        let ringConvexHullCoordinates = [];
        for (let i = 0; i < convexHullCoordinates.length; i++) {
            ringConvexHullCoordinates.push([
                convexHullCoordinates[i][0],
                convexHullCoordinates[i][1]
            ]);
        }

        // Replace coordinates
        geoJson.geometry.coordinates = [ringConvexHullCoordinates];

        // Get features
        const features = (
            new GeoJSON({})
        ).readFeatures(geoJson);

        // Vector source
        const polygonSource = new VectorSource({
            features: features
        });

        // Vector layer
        const polygonLayer = new VectorLayer({
            className: "open-map-polygon-layer",
            source: polygonSource,
            style: this.getFeatureStyle,
            zIndex: 101,
            opacity: 0.6
        });

        // Set name
        polygonLayer.set("name", "polygon", true);

        // Add polygon to the map
        this.openMap.addLayer(polygonLayer);

        // Store
        this.polygonSource = polygonSource;
        this.polygonLayer = polygonLayer;

        // Zoom to fit polygon
        this.zoomToFitPolygon();
    }

    removePolygon() {
        if (this.polygonLayer != null) {
            // Remove the vector layer
            this.polygonLayer.getSource().clear();
        }
    }

    zoomToFitPolygon() {
        // Turn on zoom to fit progress status
        this.zoomToFitOn = true;

        this.openMap.getView().fit(
            this.polygonSource.getExtent(),
            {
                duration: 600,
                padding: [100, 100, 100, 100],
                constrainResolution: false,
                callback: () => {
                    this.openMap.updateSize();

                    // Turn off zoom to fit progress status
                    this.zoomToFitOn = false;
                }
            }
        );
    }

    getFeatureStyle(feature) {
        const image = getStaticPath("/images/map/open-map-point.png", false);

        const styles = {
            "Point": new Style({
                image: image
            }),
            "LineString": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 10
                })
            }),
            "MultiLineString": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 12
                })
            }),
            "MultiPoint": new Style({
                image: image
            }),
            "MultiPolygon": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            }),
            "Polygon": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    lineDash: [ 10 ],
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            }),
            "GeometryCollection": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                }),
                image: new CircleStyle({
                    radius: 10,
                    fill: null,
                    stroke: new StrokeStyle({
                        color: "#4EA3D0"
                    })
                })
            }),
            "Circle": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 10
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            })
        };

        return styles[feature.getGeometry().getType()];
    };

}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        create: state.create.create
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeLogInOn,
            storeSignUpOn,
            storeWarningAlert,
            storeNotificationAlert,
            storeCreate
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchOpenMap);