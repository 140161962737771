/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Create.js
    - Create page for content creation
--------------------------------------------------------------------------------------------
    Content
    - Create
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux"; 

// Modules
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import InputRange from "thedots-input-range";
import TimeKeeper from "thedots-timekeeper";
import ReactTooltip from "thedots-tooltip";

// Axios
import {
    postDot,
    patchDot
} from "requests";

// Open Source Map and Open Layers
import * as openLayersProj from "ol/proj";

// Components
import {
    OpenMap,
    GoogleMap,
    pointToLatLng,
    latLngToPoint,
    calculateDisplacement
} from "components/Map";

import { 
    UploadGallery,
    addImage,
    addVideo,
    removeMedia,
    addSearchedMedia,
    clearSearchedMedia,
    selectMedia,
    moveMediaLeft,
    moveMediaRight,
    thumbnailPrevPage,
    thumbnailNextPage,
    updateIndices
} from "components/UploadGallery";
import CreateConnect from "./CreateConnect";
//import UploadIndicator from "components/UploadIndicator";

// Functions
import {
    getStaticPath,
    updateStateChain2,
    pad,
    formatDuration
} from "js/Functions";

// CSS
import "./Create.scss";


/*
============================================================================================
    Create
============================================================================================
*/

// Create component
class Create extends Component {
    constructor(props){
        super(props);

        // Map Mode (google / open)
        this.mapSource = "open";
        this.mapMode = "create";

        // Font weight
        this.fontWeightRegular = 100;
        this.fontWeightBold = 600;

        // Map settings
        this.mapZoom = 15;
        this.mapWidth = 360;
        this.mapHeight = 300;
        this.mapHeightSmall = 260;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // Focus and unfocus opacities
        this.focusOpacity = 1.0;
        this.unfocusOpacity = 0.75;

        // Margin width
        this.marginWidth = 10;

        // Media upload input IDs
        this.imageUploadID = "upload-gallery-add-image";
        this.videoUploadID = "upload-gallery-add-video";

        // Aspect ratios
        this.minAspectRatio = 0.5;
        this.maxAspectRatio = 2.0;

        // Content widths
        this.minContentWidth = 300;
        this.scaleContentWidth = 600;
        this.maxContentWidth = 1000;

        // Media settings
        this.mediaArea = 200000;

        // Thumbnails
        this.thumbnailArea = 9000;

        // Video thumbnails
        this.videoThumbnailWidth = 100;
        this.videoThumbnailHeight = 100;

        // Number of thumbnails
        this.numThumbnailsSmall = 2;
        this.numThumbnailsMedium = 3;
        this.numThumbnailsLarge = 3;

        // Number of thumbnails
        const numThumbnails = (this.props.browserWidth <= 4)?
            this.numThumbnailsSmall :
            (
                (this.props.browserWidth <= 8)?
                    this.numThumbnailsMedium : this.numThumbnailsLarge
            );

        // Slider labels and constant presets
        this.bestTimeLabels = [ "Sunrise", "Morning", "Noon", "Afternoon", "Sunset" ];
        this.bestTimeTypes = [ "SR", "MO", "NO", "AF", "SS" ];
        this.physicalityLabels = ["0 - No Effort", "0.5", "1 - Easy / Stroll", "1.5", "2 - Slow Walk", "2.5 - Moderate / Regular Walk", "3 - Fast Walk", "3.5", "4 - Streneous / Jogging", "4.5", "5 - Extreme / Running" ];
        //this.physicalityLabels = [ "0 - Idle", "0.5", "1 - Stroll", "1.5", "2 - Walk", "2.5", "3 - Hike Up", "3.5", "4 - Backpack Up", "4.5", "5 - Running" ];
        this.durationScales = [ 3600, 7200, 14400, 28800, 57600, 115200 ];
        this.distanceScales = [ 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 100000 ];
        this.defaultPhysicalities = [
            0, // drive
            2.5, // walk
            0, // bus
            0, // subway
            0, // train
            0, // flight
            0, // cruise
            4, // backpack
            4, // swim
            3, // crosscounry
            2, // downhill
            4, // climb
            4, // scramble
            4  // kayak
        ];
        this.defaultSpeeds = [
            60 / 3600, // drive
            2.5 / 3600, // walk
            30 / 3600, // bus
            40 / 3600, // subway
            40 / 3600, // train
            500 / 3600, // flight
            20/3600, // cruise
            2 / 3600, // backpack
            3.5 / 3600, // swim
            4 / 3600, // crosscounry
            10 / 3600, // downhill
            0.02 / 3600, // climb
            0.2 / 3600, // scramble
            5 / 3600  // kayak
        ];

        // Map DOM IDs
        this.mapNodeID = "map";
        this.mapContainerNodeID = "map-container";
        this.mapButtonsNodeID = "map-buttons";
        this.mapStartNodeID = "map-start";
        this.mapStartContainerNodeID = "map-start-container";
        this.mapStartButtonsNodeID = "map-start-buttons";
        this.mapEndNodeID = "map-end";
        this.mapEndContainerNodeID = "map-end-container";
        this.mapEndButtonsNodeID = "map-end-buttons";

        // Placeholders
        this.placeholderLocation = "Search area and click on map";
        this.placeholderName = "Name the place if necessary";
        this.placeholderArea = "Recognizable name of the area";
        this.placeholderTitle = "Attention grabbing title";
        this.placeholderOverviewDot = "Brief introduction of the place";
        this.placeholderOverviewTrip = "Overview of the trip";
        this.placeholderTodos = "What-to-do ideas to experience the place";
        this.placeholderTodosDine = "What-to-order ideas for best experience";
        this.placeholderHistory = "Some history that gives a perspective";
        this.placeholderStories = "Other interesting stories or anecdotes";

        // General user mode placeholder
        this.placeholderLocationSimple = "Search area and click on map";
        this.placeholderNameSimple = "Name the place if necessary";
        this.placeholderTitleSimple = "Attention grabbing title";
        this.placeholderOverviewSimple = "Interesting story about the place";

        // Media arning Messages
        this.mediaWarningMessage = "Upload at least one media file.";
        this.mediaWarningUploadMessage = "Wait until upload is complete or remove media with errors.";
        this.mediaWarningOverviewMessage = "At least one media file must be for overview.";

        // Contributor mode warning messages
        this.locationWarningMessage = "Search location or provide coordinates.";
        this.locationParkingWarningMessage = "Set the parking location.";
        this.locationStartWarningMessage = "Choose a good place to start the trip \n(airport / hotel / park entrance / trailhead).";
        this.locationEndWarningMessage = "Choose a good place to end the trip \n(Airport / hotel / park entrance / trailhead).";
        this.locationStartEndWarningMessage = "Choose good places to start and end the trip \n(Airport / hotel / park entrance / trailhead).";
        this.nameWarningMessage = "A dot must have a name.";
        this.areaWarningMessage = "Write what area this place belongs in.";
        this.titleWarningMessage = "Give it a title.";
        this.overviewWarningMessage = "An overview is required.";
        this.connectDotWarningMessage = "Connect the trip with at least one dot.";
        this.connectHighlightWarningMessage = "Highlight dots that represent the trip.";
        this.connectStartLocationWarningMessage = "Set start location before adding a dot";
        this.connectEndLocationWarningMessage = "Set end location before adding a dot";
        this.connectStartEndLocationWarningMessage = "Set start and end locations before adding a dot";

        // General user mode warning messages 
        this.locationWarningMessageSimple = "Search location.";
        this.locationParkingWarningMessageSimple = "Set the parking location.";
        this.nameWarningMessageSimple = "A dot must have a name.";
        this.titleWarningMessageSimple = "Give it a title.";
        this.overviewWarningMessageSimple = "Write something about the place.";

        // Default closure dates
        const closureStartDate = moment().month(11).date(1);
        const closureEndDate = moment().year(moment().year() + 1).month(5).date(15);
        
        // Transit defaults
        this.defaultTransitMode = 0;
        this.defaultTransitDuration = 600;
        this.defaultTransitPhysicality = 0.0;
        this.defaultTransitDistance = 10.0;
        //this.defaultTransitMode = 1;
        //this.defaultTransitDuration = 1800;
        //this.defaultTransitPhysicality = 2.5;
        //this.defaultTransitDistance = 1.25;
        this.metersToMiles = 0.000621371;

        // Media file count
        this.mediaCount = 0;

        // Media objects using file upload order as key
        this.media = {};
        
        // Upload indicator objects using file upload order as key
        this.uploadProgress = {};

        // Upload process interval and time
        this.uploadProcessTime = 2000;
        this.uploadProcessIntervalTime = 100;

        // Upload update interval
        this.uploadUpdateIntervalTime = 200;
        this.uploadUpdateInterval = null;

        // Save limit
        this.saveLimitLabels = [ "10", "100", "1000", "10k", "Unlimited" ];
        this.saveLimits = [ 10, 100, 1000, 10000, null ];

        // Initialize states
        this.state = {
            /*
            ============================================================================================
                User Create Level
            ============================================================================================
            */
            userIsContributor: (this.props.userInfo.create_level > 3)? true : false,

            /*
            ============================================================================================
                Write Mode
            ============================================================================================
            */
            everydayModeOn: true,
            authorModeOn: false,
            contributorModeOn: false,

            /*
            ============================================================================================
                Dot Type Menu
            ============================================================================================
            */
            dotType: "SC",

            /*
            ============================================================================================
                Create Progress
            ============================================================================================
            */
            createStage: 1,

            /*
            ============================================================================================
                Media
            ============================================================================================
            */
            selectedMediaIndex: null,
            startMediaIndex: null,
            endMediaIndex: null,
            numThumbnails: numThumbnails,

            // Media keys and categories
            mediaKeys: [],
            mediaCategories: [],
            mediaIDs: [],

            // Media warning
            mediaWarningOn: false,
            mediaWarningMessage: null,

            /*
            ============================================================================================
                Overview
                - name
                - area
                - rating
                - physicality
                - location
            ============================================================================================
            */
            // Name
            nameOn: false,
            nameBorderColor: "#464646",
            nameeWarningOn: false,
            nameWarningMessage: null,

            // Area
            areaBorderColor: "#464646",
            areaWarningOn: false,
            areaWarningMessage: null,

            /*
            ============================================================================================
                Sliders
            ============================================================================================
            */

            // Rating and physicality
            rating: 3,
            physicality: 2,

            // Duration
            duration: 1800, // 30 mins as default
            durationScaleIndex: 0,

            // Best time
            bestTimeOn: true,
            bestTimeMode: "single", // allday | single | double,
            bestTimeFirst: 0,
            bestTimeSecond: 4,

            /*
            ============================================================================================
                Location
            ============================================================================================
            */

            // Location detected on
            locationDetectedOn: false,
            locationDetected: null,

            // Accessibility
            accessibilityMode: "drivable",

            // Preferred travel method
            preferredMode: "drive",

            // Location type
            locationType: "location",

            // Location object
            location: null,
            parkingLocation: null,
            startLocation: null,
            startLocationName: null,
            endLocation: null,
            endLocationName: null,

            // Location and warning
            locationBorderColor: "#464646",
            locationLatBorderColor: "#464646",
            locationLngBorderColor: "#464646",
            locationWarningOn: false,
            locationWarningMessage: null,

            // Roundtrip
            roundtrip: true,

            // Loop
            loop: false,

            // Reversible
            reversible: true,

            // State
            state: null,
            stateStart: null,
            stateEnd: null,

            /*
            ============================================================================================
                Map
            ============================================================================================
            */

            // Map objects
            map: null,
            mapConnet: null,

            // Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false, 

            /*
            ============================================================================================
                Business Hours
            ============================================================================================
            */

            hoursOn: true,
            startHour: {
                hour: 6,
                minute: 0,
                meridiem: "am",
                formatted: "6:00am"
            },
            endHour: {
                hour: 9,
                minute: 0,
                meridiem: "pm",
                formatted: "9:00pm"
            },

            /*
            ============================================================================================
                Season Closure Dates
            ============================================================================================
            */

            seasonClosureType: "estimate",
            closureStartDate: closureStartDate,
            closureEndDate: closureEndDate, 

            /*
            ============================================================================================
                Dining Options
            ============================================================================================
            */
            diningOptions: false,

            /*
            ============================================================================================
                Stories
                - title
                - overview 
                - todos
                - history
                - stories
            ============================================================================================
            */
            // Title / overview warnings
            titleBorderColor: "#464646",
            titleWarningOn: false,
            titleWarningMessage: null,

            overviewBorderColor: "#464646",
            overviewWarningOn: false,
            overviewMessage: null,

            // Todos / history / stories warnings
            todosBorderColor: "#464646",
            todosWarningOn: false,
            todosWarningMessage: null,

            historyBorderColor: "#464646",
            historyWarningOn: false,
            historyWarningMessage: null,

            storiesBorderColor: "#464646",
            storiesWarningOn: false,
            storiesWarningMessage: null,


            /*
            ============================================================================================
                Curation Mode
            ============================================================================================
            */
            curationMode: "todos",


            /*
            ============================================================================================
                Connect
            ============================================================================================
            */
            // Dots
            dots : null,

            // Connect warning
            connectBorderColor: "#464646",
            connectWarningOn: false,
            connectWarningMessage: null,

            // Selected object IDs
            selectedIDs: [],
            highlightIDs: [],
            highlightCount: 0,

            // Refresh search results signal
            refreshResults: false,


            /*
            ============================================================================================
                Connect Route
            ============================================================================================
            */
            transitModes: [],
            transitDurations: [],
            transitPhysicalities: [],
            transitDistances: [],
            durationScaleIndices: [],
            distanceScaleIndices: [],


            /*
            ============================================================================================
                Progress Warnings
            ============================================================================================
            */
            progressFirstWarningOn: false,
            progressFirstWarningMessage: null,
            progressSecondWarningOn: false,
            progressSecondWarningMessage: null,
            progressThirdWarningOn: false,
            progressThirdWarningMessage: null,


            /*
            ============================================================================================
                Publish Button
            ============================================================================================
            */
            progressPublishButtonHoverOn: false,


            /*
            ============================================================================================
                Publish Button
            ============================================================================================
            */
            completeModalOn: false,


            /*
            ============================================================================================
                Created Object ID
            ============================================================================================
            */
            objectSlug: null,


            /*
            ============================================================================================
                Upload Indicator
            ============================================================================================
            */
            uploadUpdate: false,


            /*
            ============================================================================================
                Complete
            ============================================================================================
            */
            isPublic: true,
            saveLimit: 4,
            completeButtonHoverOn: false
        };

        // Location object [lat, lng]
        this.location = null;
        
        // DOM nodes of inputs
        this.imageNode = null;
        this.videoNode = null;
        this.locationRef = React.createRef();
        this.locationLatRef = React.createRef();
        this.locationLngRef = React.createRef();
        this.nameRef = React.createRef();
        this.areaRef = React.createRef();
        this.titleRef = React.createRef();
        this.overviewRef = React.createRef();
        this.todosRef = React.createRef();
        this.historyRef = React.createRef();
        this.storiesRef = React.createRef();
        this.connectRef = React.createRef();

        // Form input focused states
        this.mediaFocused = false;
        this.locationFocused = false;
        this.locationLatFocused = false;
        this.locationLngFocused = false;
        this.nameFocused = false;
        this.areaFocused = false;
        this.titleFocused = false;
        this.overviewFocused = false;
        this.todosFocused = false;
        this.historyFocused = false;
        this.storiesFocused = false;
        this.connectFocused = false;

        // Media warning timer
        this.mediaWarningTimer = null;
        this.locationWarningTimer = null;
        this.nameWarningTimer = null;
        this.areaWarningTimer = null;
        this.titleWarningTimer = null;
        this.overviewWarningTimer = null;
        this.todosWarningTimer = null;
        this.historyWarningTimer = null;
        this.storiesWarningTimer = null;
        this.connectWarningTimer = null;

        // Transition times
        this.transitionTime = 300;
        this.delayTime = 1000;

        // Next button
        this.nextButton = null;

        // Bind setState
        this.setState = this.setState.bind(this);

        // Bind location callbacks
        this.locationDetectConfirm = this.locationDetectConfirm.bind(this);
        this.locationDetectCancel = this.locationDetectCancel.bind(this);

        // Bind the object type callbacks
        this.dotModeClick = this.dotModeClick.bind(this);
        this.tripModeClick = this.tripModeClick.bind(this);

        // Bind write mode callbacks
        this.everydayModeClick = this.everydayModeClick.bind(this);
        this.authorModeClick = this.authorModeClick.bind(this);
        this.contributorModeClick = this.contributorModeClick.bind(this);

        // Bind the dot type callbacks
        this.scenicTypeClick = this.scenicTypeClick.bind(this);
        this.experienceTypeClick = this.experienceTypeClick.bind(this);
        this.dineTypeClick = this.dineTypeClick.bind(this);

        // Bind the trip type callbacks
        this.tripTypeClick = this.tripTypeClick.bind(this);
        this.routeTypeClick = this.routeTypeClick.bind(this);

        // Input callbacks
        this.colorInputBorders = this.colorInputBorders.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        //this.clearInput = this.clearInput.bind(this);

        // Bind media callbacks
        this.addImage = addImage.bind(this);
        this.addVideo = addVideo.bind(this);
        this.removeMedia = removeMedia.bind(this);
        this.addSearchedMedia = addSearchedMedia.bind(this);
        this.clearSearchedMedia = clearSearchedMedia.bind(this);
        this.selectMedia = selectMedia.bind(this);
        this.moveMediaLeft = moveMediaLeft.bind(this);
        this.moveMediaRight = moveMediaRight.bind(this);
        this.thumbnailPrevPage = thumbnailPrevPage.bind(this);
        this.thumbnailNextPage = thumbnailNextPage.bind(this);
        this.updateIndices = updateIndices.bind(this);

        // Bind map callbacks
        this.resetMapAnimations = this.resetMapAnimations.bind(this);

        // Location
        this.clearLocationSearch = this.clearLocationSearch.bind(this);
        this.clearLocationGPS = this.clearLocationGPS.bind(this);
        this.dotLocationDrivableClick = this.dotLocationDrivableClick.bind(this);
        this.dotLocationParkAndWalkClick = this.dotLocationParkAndWalkClick.bind(this);
        this.dotLocationUndrivableClick = this.dotLocationUndrivableClick.bind(this);
        this.tripLocationDrivableClick = this.tripLocationDrivableClick.bind(this);
        this.tripLocationUndrivableClick = this.tripLocationUndrivableClick.bind(this);
        this.locationGPSClick = this.locationGPSClick.bind(this);
        this.locationOnChange = this.locationOnChange.bind(this);
        this.locationLatOnChange = this.locationLatOnChange.bind(this);
        this.locationLngOnChange = this.locationLngOnChange.bind(this);
        this.locationResize = this.locationResize.bind(this);
        this.locationResizeDelayed = this.locationResizeDelayed.bind(this);
        
        // Duration callback
        this.formatDuration = formatDuration.bind(this);

        // Bind the season closure type callbacks
        this.updateSeasonClosureStartDate = this.updateSeasonClosureStartDate.bind(this);
        this.updateSeasonClosureEndDate = this.updateSeasonClosureEndDate.bind(this);        

        // Name
        this.nameOnChange = this.nameOnChange.bind(this);

        // Area
        this.areaOnChange = this.areaOnChange.bind(this);

        // Title
        this.titleOnChange = this.titleOnChange.bind(this);

        // Overview
        this.overviewOnChange = this.overviewOnChange.bind(this);
        this.overviewResize = this.overviewResize.bind(this);
        this.overviewResizeDelayed = this.overviewResizeDelayed.bind(this);

        // Curation
        this.todosOnChange = this.todosOnChange.bind(this);
        this.historyOnChange = this.historyOnChange.bind(this);
        this.storiesOnChange = this.storiesOnChange.bind(this);
        this.todosResize = this.todosResize.bind(this);
        this.historyResize = this.historyResize.bind(this);
        this.storiesResize = this.storiesResize.bind(this);
        this.todosResizeDelayed = this.todosResizeDelayed.bind(this);
        this.historyResizeDelayed = this.historyResizeDelayed.bind(this);
        this.storiesResizeDelayed = this.storiesResizeDelayed.bind(this);

        // Connect
        this.updateTransitDurations = this.updateTransitDurations.bind(this);
        this.updateTransitPhysicalities = this.updateTransitPhysicalities.bind(this);
        this.updateTransitDistances = this.updateTransitDistances.bind(this);
        this.updateDurationScaleIndices = this.updateDurationScaleIndices.bind(this);
        this.updateDistanceScaleIndices = this.updateDistanceScaleIndices.bind(this);

        // Bind warning callbacks
        this.clearLocationWarning = this.clearLocationWarning.bind(this);
        this.clearNameWarning = this.clearNameWarning.bind(this);
        this.clearTitleWarning = this.clearTitleWarning.bind(this);
        this.clearOverviewWarning = this.clearOverviewWarning.bind(this);
        this.clearTodosWarning = this.clearTodosWarning.bind(this);
        this.clearHistoryWarning = this.clearHistoryWarning.bind(this);
        this.clearStoriesWarning = this.clearStoriesWarning.bind(this);

        /*
        this.showLocationWarning = this.showLocationWarning.bind(this);
        this.showTitleWarning = this.showTitleWarning.bind(this);
        this.showOverviewWarning = this.showOverviewWarning.bind(this);
        this.showTodosWarning = this.showTodosWarning.bind(this);
        this.showHistoryWarning = this.showHistoryWarning.bind(this);
        this.showStoriesWarning = this.showStoriesWarning.bind(this);
        this.clearConnectWarning = this.clearConnectWarning.bind(this);
        this.showConnectWarning = this.showConnectWarning.bind(this);
        */
        this.checkProgressFirstWarning = this.checkProgressFirstWarning.bind(this);
        this.checkProgressSecondWarning = this.checkProgressSecondWarning.bind(this);
        this.checkProgressThirdWarning = this.checkProgressThirdWarning.bind(this);

        // Bind the progress button callbacks
        this.progressPublishClick = this.progressPublishClick.bind(this);

        // Get state
        this.getState = this.getState.bind(this);

        // Complete
        this.completeButtonClick = this.completeButtonClick.bind(this);

        // Scroll to top
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    
    render() {

        /*
        ============================================================================================
            Object Type
        ============================================================================================
        */
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";


        /*
        ============================================================================================
            Object Type Menu
        ============================================================================================
        */

        // Object type menu icons
        const dotButtonImage = (this.props.colorMode === "day")? 
            getStaticPath("/images/common/type-dot-black.png") : 
            getStaticPath("/images/common/type-dot-white.png");

        const tripButtonImage = (this.props.colorMode === "day")? 
            getStaticPath("/images/common/type-trip-black.png") : 
            getStaticPath("/images/common/type-trip-white.png");

        const objectTypeMenu = (
            <div className = "body-narrow"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "create-object-type-container"
                    className = "clear-fix"
                >
                    <div className = "create-object-type-float"
                        style = {
                            (objectType === "dot")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "create-object-type-dot"
                            style = {{ backgroundImage: dotButtonImage }}
                            onClick = {this.dotModeClick}
                            data-tip = "Share A Memorable Place or Activity"
                            data-for = "create-object-type-dot-tooltip"
                        >
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "create-object-type-title k4" :
                                "create-object-type-title w4"}
                        >
                            Dot
                        </div>
                    </div>
                    <div className = "create-object-type-float"
                        style = { (objectType === "trip")? { opacity: 1.0 } : null }
                    >
                        <div id = "create-object-type-trip"
                            style = {{
                                backgroundImage: tripButtonImage,
                            }}
                            onClick = {this.tripModeClick}
                            data-tip = "Trip as a Collection of Dots"
                            data-for = "create-object-type-trip-tooltip"
                        >
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "create-object-type-title k4" :
                                "create-object-type-title w4"}
                        >
                            Trip
                        </div>
                    </div>
                    <ReactTooltip 
                        id = "create-object-type-dot-tooltip"
                        className = "create-object-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "bottom" 
                        html = {true} 
                    />
                    <ReactTooltip 
                        id = "create-object-type-trip-tooltip"
                        className = "create-object-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "bottom" 
                        html = {true} 
                    />
                </div>
            </div>
        );


        /*
        ============================================================================================
            Everyday Mode
        ============================================================================================
        */
        const everydayMode = (this.state.everydayModeOn)?
        (
            <div id = "create-everyday-mode"
                onClick = {this.everydayModeClick}
            >
                <div id = "create-everyday-mode-icon"
                    className = "create-everyday-mode-icon"
                    style = {{ 
                        backgroundImage: (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/everyday-light-blue.png") :
                            getStaticPath("/images/create/everyday-blue.png")
                    }}
                >
                </div>
                <div id = "create-everyday-mode-text"
                    className = {(this.props.colorMode === "day")?
                        "create-everyday-mode-text k4" :
                        "create-everyday-mode-text w4" }
                >
                    Everyday Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Author Mode
        ============================================================================================
        */
        const authorMode = (this.state.authorModeOn)? 
        (
            <div id = "create-author-mode"
                onClick = {this.authorModeClick}
            >
                <div id = "create-author-mode-icon"
                    className = "create-author-mode-icon"
                    style = {{ 
                        backgroundImage: (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/fountain-pen-light-blue.png") :
                            getStaticPath("/images/create/fountain-pen-blue.png")
                    }}
                >
                </div>
                <div id = "create-author-mode-text"
                    className = {(this.props.colorMode === "day")?
                        "create-author-mode-text k4" :
                        "create-author-mode-text w4" }
                >
                    Author Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Contributor Mode
        ============================================================================================
        */
        const contributorMode = (this.state.userIsContributor && this.state.contributorModeOn)?
        (
            <div id = "create-contributor-mode"
                style = {{ display: (this.state.userIsContributor)?  "inline-block" : "none" }}
                onClick = {this.contributorModeClick}
            >
                <div id = "create-contributor-mode-icon"
                    className = "create-contributor-mode-icon"
                    style = {{
                        backgroundImage: (this.props.colorMode === "day")?
                            getStaticPath("/images/create/contributor-light-blue.png") : 
                            getStaticPath("/images/create/contributor-blue.png")
                    }}
                >
                </div>
                <div id = "create-contributor-mode-text"
                    className = { 
                        (this.props.colorMode === "day")?
                            "create-contributor-mode-text k4" :
                            "create-contributor-mode-text w4"}
                >
                    Contributor Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Map and Location
        ============================================================================================
        */

        // Location search title
        const locationSearchTitle = (this.state.contributorModeOn)? 
        (
            <div id = "create-location-search-title">
                <div className = {(this.props.colorMode === "day")?
                        "create-location-search-title-text-single k4" :
                        "create-location-search-title-text-single w4"}
                >
                    Search
                </div>
            </div>
        ) : (
            <div id = "create-location-search-title">
                <div className = {(this.props.colorMode === "day")?
                        "create-location-search-title-text-single k4" :
                        "create-location-search-title-text-single w4"}
                >
                    Search
                </div>
            </div>
        );

        /*
            <div id = "create-location-search-title">
                <div className = {(this.props.colorMode === "day")?
                        "create-location-search-title-text-double k4" :
                        "create-location-search-title-text-double w4"}
                >
                    Location
                </div>
                 <div className = {(this.props.colorMode === "day")?
                        "create-location-search-title-text-double k4" :
                        "create-location-search-title-text-double w4"}
                >
                    Search
                </div>
            </div>
        */

        // Location placeholder
        const locationPlaceholder = (this.state.contributorModeOn)? 
            this.placeholderLocation : this.placeholderLocationSimple;

        // TODO - Add name and other things?
        let map = null;
        let locationType = null;

        // Construct a temporary dot
        const dotInfo = {
            name: "location",
            location: this.state.location
        };

        const dotsInfo = [
            dotInfo
        ];

        // Create mode
        let createMode = null;
        if ((this.state.dotType !== "TR") && (this.state.dotType !== "RT")) {
            createMode = "dot";
        }
        else {
            createMode = (this.state.dotType === "TR")? "trip" : "route";
        }

        // Only when google object and locations are ready
        if ((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null)) {
            if ((this.mapSource === "google") && (this.props.google)) {
                // Set the props for Map component
                const mapProps = {
                    // Google
                    google: this.props.google,

                    // Map mode
                    mapMode: this.mapMode,

                    // Create mode
                    createMode: createMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    mapContainerNodeID : this.mapContainerNodeID,
                    buttonsNodeID: this.mapButtonsNodeID,
                    startInputNodeID: null,
                    endInputNodeID: null,

                    // Dots
                    dotsInfo: (objectType === "dot")? dotsInfo : null,

                    // Itinerary and selected settings
                    itinerary: (objectType === "dot")? [0] : [],
                    selected: 0,
                    hovered: null,
                    selectedChild: null,
                    hoveredChild: null,
                    displayChildren: null,

                    // Roundtrip
                    roundtrip: this.state.roundtrip,

                    // Loop
                    loop: this.state.loop,

                    // Drivable
                    drivable: (this.state.accessibilityMode === "undrivable")? false : true,

                    // Location mode
                    locationType: this.state.locationType,

                    // Locations
                    parkingLocation: this.state.parkingLocation,
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Map height
                    mapWidth: this.mapWidth,
                    mapHeight: (this.props.browserWidth <= 4)? 
                        this.mapHeightSmall : this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Action triggers
                    mapResetMarkers: this.state.mapResetMarkers,
                    mapZoomInAnimation: this.state.mapZoomInAnimation,
                    mapZoomOutAnimation: this.state.mapZoomOutAnimation,
                    mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
                    mapRefreshAnimation: this.state.mapRefreshAnimation,
                    resetAnimations: this.resetMapAnimations,

                    // Dot click and hover callback
                    dotClick: null,
                    dotHoverOn: null,
                    dotHoverOff: null,
                    endHoverOn: null,
                    endHoverOff: null,

                    // Default zoom level
                    mapZoom: this.mapZoom,
                    mapType: "hybrid",

                    // Set location
                    setLocation: (location) => {
                        if (this.state.accessibilityMode !== "parkandwalk") {
                            this.setState(
                                {
                                    location: location,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    location: location,
                                }
                            );
                        }
                    },

                    // Set parking location
                    setParkingLocation: (parkingLocation) => {
                        if (this.state.location !== null) {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                }
                            );
                        }
                    },

                    // Set start location
                    setStartLocation: (startLocation) => {
                        if (this.state.loop || this.state.roundtrip || this.state.endLocation !== null) {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set end location
                    setEndLocation: (endLocation) => {
                        if (this.state.startLocation !== null) {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set map
                    setMap: (map) => {this.setState({ map: map });},

                    // Clear location input
                    clearLocationSearch: this.clearLocationSearch,
                    clearLocationGPS: this.clearLocationGPS
                };

                // Create map
                map = (
                    <GoogleMap {...mapProps}/>
                );
            }
            else if (this.mapSource === "open") {
                // Set the props for Map component
                const openMapProps = {
                    // Map mode
                    mapMode: this.mapMode,

                    // Create mode
                    createMode: createMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,

                    // Map width and height
                    mapWidth: this.mapWidth,
                    mapHeight: (this.props.browserWidth <= 4)? 
                        this.mapHeightSmall : this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: dotsInfo,

                    // Itinerary and selected settings
                    itinerary: (objectType === "dot")? [0] : [],
                    selected: (objectType === "dot")? 0 : null,
                    selectedChild: null,
                    displayChildren: null,

                    // Roundtrip
                    roundtrip: this.state.roundtrip,

                    // Loop
                    loop: this.state.loop,

                    // Drivable
                    drivable: (this.state.accessibilityMode === "undrivable")? false : true,

                    // Location mode
                    locationType: this.state.locationType,

                    // Parking / start / end locations
                    parkingLocation: this.state.parkingLocation,
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Map zoom / center / type
                    mapZoom: this.mapZoom,
                    mapCenter: (objectType === "dot")? 
                        (
                            (this.state.locationType === "parking")?
                                this.state.parkingLocation : this.state.location
                        ) : (
                            (this.state.locationType === "start")?
                                this.state.startLocation : this.state.endLocation
                        ),
                    mapType: "hybrid",

                    // SetState
                    setState: this.setState,

                     // Set location
                    setLocation: (location) => {
                        if (this.state.accessibilityMode !== "parkandwalk") {
                            this.setState(
                                {
                                    location: location,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    location: location,
                                }
                            );
                        }
                    },

                    // Set parking location
                    setParkingLocation: (parkingLocation) => {
                        if (this.state.location !== null) {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                }
                            );
                        }
                    },

                    // Set start location
                    setStartLocation: (startLocation) => {
                        if (this.state.loop || this.state.roundtrip || this.state.endLocation !== null) {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set end location
                    setEndLocation: (endLocation) => {
                        if (this.state.startLocation !== null) {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set map
                    setMap: (map) => {this.setState({ map: map });},

                    // Clear location input
                    clearLocationSearch: this.clearLocationSearch,
                    clearLocationGPS: this.clearLocationGPS                    
                };

                // Get the Map component
                map = (
                    <OpenMap {...openMapProps}/>
                );
            }

            // Location type buttons
            if (objectType === "dot") {
                locationType = (this.state.accessibilityMode === "parkandwalk")? (
                    <div id = "create-location-type">
                        <div id = "create-location-type-actual"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}
                            style = {
                                (this.state.locationType === "location")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => { this.setState({ locationType: "location" }); }}
                        >
                            Location
                        </div>
                        <div id = "create-location-type-parking"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}
                            style = {
                                (this.state.locationType === "parking")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => { this.setState({ locationType: "parking" }); }}
                        >
                            Parking
                        </div>
                    </div>
                ) : null;
            }
            else {
                // End location
                const endLocationButton = (!this.state.roundtrip && !this.state.loop)? (
                    <div id = "create-location-type-end"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.locationType === "end")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => { this.setState({ locationType: "end" }); }}
                    >
                        End Location
                    </div>
                ) : null;

                // Start location
                const startLocationButtonText = (this.state.roundtrip || this.state.loop)?
                    "Parking Location" : "Start Location";

                locationType = ((this.state.roundtrip || this.state.loop) && (this.state.accessibilityMode === "undrivable"))? null : (
                    <div id = "create-location-type">
                        <div id = "create-location-type-start"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}

                            style = {
                                (this.state.locationType === "start")?
                                    {
                                        width: (this.state.roundtrip || this.state.loop)? "100%" : "50%",
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        width: (this.state.roundtrip || this.state.loop)? "100%" : "50%",
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {(this.state.roundtrip || this.state.loop)?
                                null: () => { this.setState({ locationType: "start" }); }}
                        >
                            {startLocationButtonText}
                        </div>
                        {endLocationButton}
                    </div>
                );
            }
        }

        // Location values
        //console.log("this.state.location = ", this.state.location);
        let locationLat = null;
        let locationLng = null;
        if (this.state.locationType === "location") {
            locationLat = (this.state.location === null)? "Latitude" : this.state.location.latitude.toFixed(6);
            locationLng = (this.state.location === null)? "Longitude" : this.state.location.longitude.toFixed(6);
        }
        else if (this.state.locationType === "parking") {
            locationLat = (this.state.parkingLocation === null)? "Latitude" : this.state.parkingLocation.latitude.toFixed(6);
            locationLng = (this.state.parkingLocation === null)? "Longitude" : this.state.parkingLocation.longitude.toFixed(6);
        }
        else if (this.state.locationType === "start") {
            locationLat = (this.state.startLocation === null)? "Latitude" : this.state.startLocation.latitude.toFixed(6);
            locationLng = (this.state.startLocation === null)? "Longitude" : this.state.startLocation.longitude.toFixed(6);
        }
        else if (this.state.locationType === "end") {
            locationLat = (this.state.endLocation === null)? "Latitude" : this.state.endLocation.latitude.toFixed(6);
            locationLng = (this.state.endLocation === null)? "Longitude" : this.state.endLocation.longitude.toFixed(6);
        }


        /*
        ============================================================================================
            Name
        ============================================================================================
        */
        const namePlaceholder = (this.state.contributorModeOn)? this.placeholderName : this.placeholderNameSimple;

        const name = (
            <div style = {{ display: (objectType === "dot" && this.state.nameOn)? "block" : "none" }}>
                <div className = "create-basic-row">
                    <div className = {(this.props.colorMode === "day")?
                            "create-basic-title k4" :
                            "create-basic-title w4"}
                    >
                        Name
                    </div>
                    <div className = "create-basic-input">
                        <input
                            ref = {this.nameRef}
                            type = "text"
                            id = "create-name"
                            className = {(this.props.colorMode === "day")? 
                                "input-s3 input-day" : "input-s3 input-night"}
                            placeholder = {namePlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.nameOnChange}
                            style = {{ borderColor: this.state.nameBorderColor }}
                            required
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-warning warning-day-s2" :
                        "create-warning warning-night-s2"}
                    style = {{ display: this.state.nameWarningOn? "block" : "none" }}
                >
                    {this.state.nameWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Area
        ============================================================================================
        */
        const area = (
            <div style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}>
                <div className = "create-basic-row">
                    <div className = {(this.props.colorMode === "day")?
                            "create-basic-title k4" :
                            "create-basic-title w4"}
                    >
                        Area
                    </div>
                    <div className = "create-basic-input">
                        <input
                            ref = {this.areaRef}
                            type = "text"
                            id = "create-area"
                            className = {(this.props.colorMode === "day")? 
                                "input-s3 input-day" : "input-s3 input-night"}
                            placeholder = {this.placeholderArea}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.areaOnChange}
                            style = {{ borderColor: this.state.areaBorderColor }}
                            required
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-warning warning-day-s2" :
                        "create-warning warning-night-s2"}
                    style = {{ display: this.state.areaWarningOn? "block" : "none" }}>
                    {this.state.areaWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Sliders
        ============================================================================================
        */

        /*
        inputRange: "input-range",
        disabledInputRange: "input-range input-range--disabled",

        slider: "input-range__slider",
        sliderContainer: "input-range__slider-container",

        track: "input-range__track input-range__track--background",
        activeTrack: "input-range__track input-range__track--active",

        labelContainer: "input-range__label-container",
        maxLabel: "input-range__label input-range__label--max",
        minLabel: "input-range__label input-range__label--min",
        valueLabel: "input-range__label input-range__label--value"
        */

        const sliderClassNames = (this.props.colorMode === "day")? 
        {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-day",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-day input-range__track--background",
            activeTrack: "input-range-track-day input-range-track-active-day",

            labelContainer: "input-range-label-container-day",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        } : {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-night",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-night input-range__track--background",
            activeTrack: "input-range-track-night input-range-track-active-night",

            labelContainer: "input-range-label-container-night",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"            
        };

        const ratingTooltipText = (this.state.dotType === "RT" || this.state.dotType === "TR")?
            "<p>Overall Satisfaction</p><p>Rating of the Trip</p>"
            : "<p>Overall Satisfaction</p><p>Rating of the Place</p><p>or Experience</p>";
        const ratingSlider = (
            <div className = "create-detail-row"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "create-rating-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = {ratingTooltipText}
                    data-for = "create-rating-title-tooltip"
                >
                    Rating
                </div>
                <div id = "create-rating">
                    <InputRange
                        classNames = {sliderClassNames}
                        maxValue = {5}
                        minValue = {0}
                        value = {this.state.rating}
                        onChange = {rating => this.setState({ rating: rating })}
                        formatLabel = {rating => {return rating}}
                        showEndLabels = {false}
                        step = {0.5}
                    />
                </div>
                <ReactTooltip
                    id = "create-rating-title-tooltip"
                    className = "create-rating-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );

        const physicalitySlider = (
            <div className = "create-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && ((objectType === "dot") && ((this.state.dotType === "SC") || (this.state.dotType === "EX"))))? "block" : "none" }}
            >
                <div id = "create-physicality-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Physical Intensity of</p><p>the Stay or Experience</p>"
                    data-for = "create-physicality-title-tooltip"
                >
                    Physicality
                </div>
                <div id = "create-physicality">
                    <InputRange
                        classNames = {sliderClassNames}
                        maxValue = {5}
                        minValue = {0}
                        value = {this.state.physicality}
                        onChange = {physicality => this.setState({ physicality: physicality })}
                        formatLabel = {physicality => {return this.physicalityLabels[String(Math.round(physicality / 0.5))]}}
                        showEndLabels = {false}
                        step = {0.5}
                    />
                </div>
                <ReactTooltip
                    id = "create-physicality-title-tooltip"
                    className = "create-physicality-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );

        const sliderIncreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/increase-black.png") :
            getStaticPath("/images/common/increase-white.png");

        const sliderDecreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/decrease-black.png") :
            getStaticPath("/images/common/decrease-white.png");

        let durationTitle = null;
        if (this.state.dotType === "SC") {
            durationTitle = "Stay Time";
        } 
        else if (this.state.dotType === "EX" || this.state.dotType === "DE") {
            durationTitle = "Activity Time";
        }
        else if (this.state.dotType === "DI") {
            durationTitle = "Dine Time";
        }

        const durationSlider = (
            <div className = "create-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (objectType === "dot"))? "block" : "none" }}
            >
                <div id = "create-duration-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Needed Time to</p><p>Enjoy the Place</p>"
                    data-for = "create-duration-title-tooltip"
                >
                    {durationTitle}
                </div>
                <div id = "create-duration">
                    <div id = "create-duration-slider-increase"
                        data-tip = "Increase Time Scale"
                        data-for = "create-duration-slider-increase-tooltip"
                        style = {{
                            backgroundImage: sliderIncreaseImage,
                            display: (this.state.durationScaleIndex === (this.durationScales.length - 1))? "none" : "block"
                        }}
                        onClick = {
                            ()=> {
                                this.setState({ durationScaleIndex: this.state.durationScaleIndex + 1 });
                            }
                        }
                    >
                    </div>
                    <div id = "create-duration-slider-decrease"
                        data-tip = "Decrease Time Scale"
                        data-for = "create-duration-slider-decrease-tooltip"
                        style = {{
                            backgroundImage: sliderDecreaseImage,
                            display: (this.state.durationScaleIndex === 0)? "none" : "block"
                        }}
                        onClick = {
                            ()=> {
                                this.setState({
                                    durationScaleIndex: this.state.durationScaleIndex - 1,
                                    duration: Math.min(this.state.duration, this.durationScales[this.state.durationScaleIndex - 1])
                                })
                            }
                        }
                    >
                    </div>
                    <InputRange
                        classNames = {sliderClassNames}
                        maxValue = {this.durationScales[this.state.durationScaleIndex]}
                        minValue = {300}
                        value = {this.state.duration}
                        onChange = {duration => this.setState({ duration: duration })}
                        formatLabel = {duration => this.formatDuration(duration)}
                        showEndLabels = {false}
                        step = {300}
                    />
                </div>
                <ReactTooltip
                    id = "create-duration-title-tooltip"
                    className = "create-duration-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
                <ReactTooltip
                    id = "create-duration-slider-decrease-tooltip"
                    className = "create-duration-slider-decrease-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                />
                <ReactTooltip
                    id = "create-duration-slider-increase-tooltip"
                    className = "create-duration-slider-increase-tooltip tooltip-s2"
                    type = "dark"
                    place = "right"
                />
            </div>
        );

        const bestTimeSlider = (
            <div className = "create-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (this.state.bestTimeOn))? "block" : "none" }}
            >
                <div id = "create-best-time-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Best Time of the Day</p><p>to Experience the Place</p>"
                    data-for = "create-best-time-title-tooltip"
                >
                    Best Time
                </div>

                <div id = "create-best-time">
                    <div id = "create-best-time-first"
                        style = {{ display: (this.state.bestTimeMode !== "allday")? "block" : "none" }}
                    >
                        <InputRange
                            classNames = {sliderClassNames}
                            maxValue = {4}
                            minValue = {0}
                            value = {this.state.bestTimeFirst}
                            onChange = {bestTime => this.setState({ bestTimeFirst: bestTime })}
                            formatLabel = {bestTime => this.bestTimeLabels[bestTime]}
                            showEndLabels = {false}
                            step = {1}
                        />
                    </div>

                    <div id = "create-best-time-second"
                        style = {{ display: (this.state.bestTimeMode === "double")? "block" : "none" }}
                    >
                        <InputRange
                            classNames = {sliderClassNames}
                            maxValue = {4}
                            minValue = {0}
                            value = {this.state.bestTimeSecond}
                            onChange = {bestTime => this.setState({ bestTimeSecond: bestTime })}
                            formatLabel = {bestTime => this.bestTimeLabels[bestTime]}
                            showEndLabels = {false}
                            step = {1}
                        />
                    </div>

                    <div id = "create-best-time-buttons">
                        <div id = "create-best-time-single"
                            className = {(this.props.colorMode === "day")?
                                "create-best-time-button button-light-blue-s3" :
                                "create-best-time-button button-blue-s3"}
                            style = {
                                (this.state.bestTimeMode === "single")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ bestTimeMode: "single" });}}
                        >
                            Single
                        </div>
                        <div id = "create-best-time-double"
                            className = {(this.props.colorMode === "day")?
                                "create-best-time-button button-light-blue-s3" :
                                "create-best-time-button button-blue-s3"}
                            style = {
                                (this.state.bestTimeMode === "double")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ bestTimeMode: "double" });}}
                        >
                            Double
                        </div>
                        <div id = "create-best-time-all-day"
                            className = {(this.props.colorMode === "day")?
                                "create-best-time-button button-light-blue-s3" :
                                "create-best-time-button button-blue-s3"}
                            style = {
                                (this.state.bestTimeMode === "allday")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ bestTimeMode: "allday" });}}
                        >
                            All Day
                        </div>
                    </div>
                </div>

                <ReactTooltip
                    id = "create-best-time-title-tooltip"
                    className = "create-best-time-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );

        
        /*
        ============================================================================================
            Dot Type Menu
        ============================================================================================
        */

        // Images
        const scenicDotImage = (this.state.dotType === "SC")? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-scenic-light-blue.png") :
                    getStaticPath("/images/common/category-scenic-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-scenic-black.png") :
                    getStaticPath("/images/common/category-scenic-white.png")
            );

        const experienceDotImage = (this.state.dotType === "EX")? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-experience-light-blue.png") :
                    getStaticPath("/images/common/category-experience-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-experience-black.png") :
                    getStaticPath("/images/common/category-experience-white.png")
            );

        const dineDotImage = (this.state.dotType === "DI")? 
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/category-dine-light-blue.png") :
                    getStaticPath("/images/common/category-dine-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-dine-black.png") :
                    getStaticPath("/images/common/category-dine-white.png")
            );

        const dotTypeMenu = (
            <div className = "create-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (objectType === "dot"))? "block" : "none" }}
            >
                <div className = {(this.props.colorMode === "day")? 
                        "create-dot-type-title k4" : 
                        "create-dot-type-title w4"}
                    data-tip = "<p>The Type of Dot</p><p>You Want to Create</p>"
                    data-for = "create-dot-type-title-tooltip"
                >
                    Category
                </div>
                <div id = "create-dot-type-container"
                    className = "clear-fix"
                >
                    <div className = "create-dot-type-float"
                        style = {
                            (this.state.dotType === "SC")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "create-dot-type-scenic"
                            className = "image-contain"
                            style = {{ backgroundImage: scenicDotImage }}
                            onClick = {this.scenicTypeClick}
                            data-tip = "Scenic Dot"
                            data-for = "create-dot-type-scenic-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-dot-type-scenic-tooltip"
                        className = "create-dot-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />

                    <div className = "create-dot-type-float"
                        style = {
                            (this.state.dotType === "EX")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "create-dot-type-experience"
                            className = "image-contain"
                            style = {{ backgroundImage: experienceDotImage }}
                            onClick = {this.experienceTypeClick}
                            data-tip = "Experiences"
                            data-for = "create-dot-type-experience-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-dot-type-experience-tooltip"
                        className = "create-dot-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />

                    <div className = "create-dot-type-float"
                        style = {
                            (this.state.dotType === "DI")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "create-dot-type-dine"
                            className = "image-contain"
                            style = {{ backgroundImage: dineDotImage }}
                            onClick = {this.dineTypeClick}
                            data-tip = "A Restaurant or Diner"
                            data-for = "create-dot-type-dine-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-dot-type-dine-tooltip"
                        className = "create-dot-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />
                </div>
                <ReactTooltip
                    id = "create-dot-type-title-tooltip"
                    className = "create-dot-type-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Trip Type Menu
        ============================================================================================
        */

        // Images
        const smartTripImage = (this.state.dotType === "TR")? 
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/create/trip-light-blue.png") :
                    getStaticPath("/images/create/trip-blue.png")
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/create/trip-black.png") :
                    getStaticPath("/images/create/trip-white.png")
            );
        const routeTripImage = (this.state.dotType === "RT")? 
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/create/route-light-blue.png") :
                    getStaticPath("/images/create/route-blue.png")
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/create/route-black.png") :
                    getStaticPath("/images/create/route-white.png")
            );

        const tripTypeMenu = (
            <div className = "create-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (objectType === "trip"))? "block" : "none" }}
            >
                <div className = {(this.props.colorMode === "day")? 
                        "create-trip-type-title k4" : 
                        "create-trip-type-title w4"}
                    data-tip = "<p>The Type of Trip</p><p>You Want to Create</p>"
                    data-for = "create-trip-type-title-tooltip"
                >
                    Category
                </div>
                <div id = "create-trip-type-container"
                    className = "clear-fix"
                >
                    <div className = "create-trip-type-float"
                        style = {(this.state.dotType === "TR")?
                            { opacity: 1.0 } : null}
                    >
                        <div id = "create-trip-type-smart"
                            className = "image-contain"
                            style = {{ backgroundImage: smartTripImage }}
                            onClick = {this.tripTypeClick}
                            data-tip = "Smart Plannable Trip"
                            data-for = "create-trip-type-smart-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-trip-type-smart-tooltip"
                        className = "create-trip-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />

                    <div className = "create-trip-type-float"
                        style = {(this.state.dotType === "RT")?
                            { opacity: 1.0 } : null}
                    >
                        <div id = "create-trip-type-route"
                            className = "image-contain"
                            style = {{ backgroundImage: routeTripImage }}
                            onClick = {this.routeTypeClick}
                            data-tip = "Fixed Route Trip"
                            data-for = "create-trip-type-route-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-trip-type-route-tooltip"
                        className = "create-trip-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />
                </div>

                <ReactTooltip
                    id = "create-trip-type-title-tooltip"
                    className = "create-trip-type-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Hours
        ============================================================================================
        */
        const hoursArrow = (this.props.colorMode === "day")?
            getStaticPath("/images/create/hours-arrow-black.png") :
            getStaticPath("/images/create/hours-arrow-white.png");

        const hourPickers = (
            <div id = "create-hours-picker-container"
                style = {{ display: (this.state.hoursOn)? "block" : "none" }}
            >
                <div id = "create-hours-arrow"
                    style = {{ backgroundImage: hoursArrow }}
                >
                </div>
                <div id = "create-start-hour">
                    <TimeKeeper 
                        time = {this.state.startHour.formatted}
                        onChange = {(setTime) => {
                            this.setState(
                                {
                                    startHour: setTime
                                }
                            );
                        }}
                    />
                </div>
                <div id = "create-end-hour">
                    <TimeKeeper 
                        time = {this.state.endHour.formatted}
                        onChange = {(setTime) => {
                            this.setState(
                                {
                                    endHour: setTime
                                }
                            );
                        }}
                    />
                </div>
            </div>
        );

        const hours = (
            <div className = "create-detail-row"
                style = {{ 
                    display: ((this.state.contributorModeOn) && ((this.state.dotType === "DI") || (this.state.dotType === "EX") || (this.state.dotType === "RT")))? "block" : "none",
                    paddingTop: (this.state.hoursOn)? 0: 10
                }}
            >
                <div id = "create-hours-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Hours of Business</p><p>or Operation</p>"
                    data-for = "create-hours-title-tooltip"
                >
                    Hours
                </div>
                <div id = "create-hours-container">
                    {hourPickers}
                    <div id = "create-hours-type">
                        <div id = "create-hours-fixed-button"
                            className = {(this.props.colorMode === "day")?
                                "create-hours-button button-light-blue-s3" :
                                "create-hours-button button-blue-s3"}
                            style = {
                                (this.state.hoursOn)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ hoursOn: true });}}
                        >
                            Fixed Hours
                        </div>
                        <div id = "create-hours-allday-button"
                            className = {(this.props.colorMode === "day")?
                                "create-hours-button button-light-blue-s3" :
                                "create-hours-button button-blue-s3"}
                            style = {
                                (!this.state.hoursOn)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ hoursOn: false });}}
                        >
                            Open All Day
                        </div>
                    </div>
                </div>

                <ReactTooltip
                    id = "create-hours-title-tooltip"
                    className = "create-hours-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Season Closure
        ============================================================================================
        */
        const createClosureDateArrow = (this.props.colorMode === "day")?
            getStaticPath("/images/create/dates-arrow-black.png") :
            getStaticPath("/images/create/dates-arrow-white.png");

        const closureDates = (
            <div id = "create-season-closure-dates"
                style = {{ display: ((this.state.contributorModeOn) && ((this.state.seasonClosureType === "estimate") || (this.state.seasonClosureType === "exact")))? "block" : "none" }}
            >
                <div id = "create-season-closure-start-date"
                    data-tip = {(this.state.seasonClosureType === "estimate")? "Start Date (Estimated)" : "Start Date (Exact)"}
                    data-for = "create-closure-start-date-tooltip">
                    <DatePicker
                        readOnly = {true}
                        dateFormat = "MMM D"
                        className = "create-season-closure-date-input font-cabin"
                        selected = {this.state.closureStartDate}
                        onChange = {this.updateSeasonClosureStartDate}
                    />
                </div>
                <div id = "create-season-closure-date-arrow" 
                    className = "image-contain"
                    style = {{ backgroundImage:  createClosureDateArrow }}
                >
                </div>
                <div id = "create-season-closure-end-date"
                    data-tip = {(this.state.seasonClosureType === "estimate")? "End Date (Estimated)" : "End Date (Exact)"}
                    data-for = "create-closure-end-date-tooltip">
                    <DatePicker
                        readOnly = {true}
                        dateFormat = "MMM D"
                        className = "create-season-closure-date-input font-cabin"
                        selected = {this.state.closureEndDate}
                        onChange = {this.updateSeasonClosureEndDate}
                    />
                    <ReactTooltip
                        id = "create-closure-end-date-tooltip"
                        className = "create-closure-date-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />
                </div>
                <ReactTooltip
                    id = "create-closure-start-date-tooltip"
                    className = "create-closure-date-tooltip tooltip-s2"
                    type = "dark"
                    place = "top"
                />                
            </div>
        );

        const seasonClosure = (
            <div className = "create-detail-row"
                style = {{ 
                    display: (this.state.contributorModeOn)? "block" : "none",
                    addingBottom: (this.state.seasonClosureType === "estimate")? 26 : 16 
                }}
            >
                <div id = "create-season-closure-date-title"
                    data-tip = "<p>Estimated Dates of</p><p>Seasonal Closure</p>"
                    data-for = "create-season-closure-title-tooltip"
                >
                    <div className = {(this.props.colorMode === "day")? 
                            "create-season-closure-date-title-text k4" : 
                            "create-season-closure-date-title-text w4"}
                    >
                        Season
                    </div>
                    <div className = {(this.props.colorMode === "day")? 
                            "create-season-closure-date-title-text k4" : 
                            "create-season-closure-date-title-text w4"}
                    >
                        Closure
                    </div>
                </div>
                <ReactTooltip
                    id = "create-season-closure-title-tooltip"
                    className = "create-season-closure-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />

                <div id = "create-season-closure-dates-container">
                    {closureDates}
                    <div id = "create-season-closure-date-type">
                        <div id = "create-season-closure-date-estimate"
                            className = {(this.props.colorMode === "day")?
                                "create-season-closure-date-button button-light-blue-s3" :
                                "create-season-closure-date-button button-blue-s3"}
                            style = {
                                (this.state.seasonClosureType === "estimate")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {
                                () => {
                                    this.setState({ seasonClosureType: "estimate" });
                                }
                            }
                        >
                            Estimated
                        </div>
                        <div id = "create-season-closure-date-open"
                            className = {(this.props.colorMode === "day")?
                                "create-season-closure-date-button button-light-blue-s3" :
                                "create-season-closure-date-button button-blue-s3"}
                            style = {
                                (this.state.seasonClosureType === "open")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {
                                () => {
                                    this.setState({ seasonClosureType: "open" });
                                }
                            }
                        >
                            Open All Year
                        </div>
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Dining Options
        ============================================================================================
        */

        const diningOptions = (
            <div className = "create-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (this.state.dotType === "EX"))? "block" : "none" }}
            >
                <div id = "create-dining-options-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>If the Place Offers</p><p>Dining Options</p>"
                    data-for = "create-dining-options-title-tooltip"
                >
                    Dining Options
                </div>
                <div id = "create-dining-options-type">
                    <div id = "create-dining-options-yes-button"
                        className = {(this.props.colorMode === "day")?
                            "create-dining-options-button button-light-blue-s3" :
                            "create-dining-options-button button-blue-s3"}
                        style = {
                            (this.state.diningOptions)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ diningOptions: true })}}
                    >
                        Yes
                    </div>
                    <div id = "create-dining-options-no-button"
                        className = {(this.props.colorMode === "day")?
                            "create-dining-options-button button-light-blue-s3" :
                            "create-dining-options-button button-blue-s3"}
                        style = {
                            (!this.state.diningOptions)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ diningOptions: false })}}
                    >
                        No
                    </div>
                </div>

                <ReactTooltip
                    id = "create-dining-options-title-tooltip"
                    className = "create-dining-options-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Roundtrip
        ============================================================================================
        */

        const roundtrip = (
            <div className = "create-detail-row" 
                style = {{ 
                    display: ((this.state.userIsContributor) && (this.state.dotType === "RT"))? "block" : "none",
                    paddingTop: 10 
                }}
            >
                <div id = "create-travel-type-title"
                    data-tip = "<p>Trave Type of</p><p>the Route</p>"
                    data-for = "create-trip-type-title-tooltip"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    Route Type
                </div>
                <div id = "create-travel-type">
                    <div id = "create-roundtrip-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.roundtrip && !this.state.loop)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                roundtrip: true,
                                loop: false,
                                locationType: "start",
                                endLocation: null,
                                endLocationName: null
                            });
                        }}
                    >
                        Roundtrip
                    </div>
                    <div id = "create-oneway-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (!this.state.roundtrip && !this.state.loop)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                roundtrip: false,
                                loop: false
                            });
                        }}
                    >
                        One Way
                    </div>
                    <div id = "create-loop-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (!this.state.roundtrip && this.state.loop)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                roundtrip: false,
                                loop: true,
                                locationType: "start",
                                endLocation: null,
                                endLocationName: null
                            });
                        }}
                    >
                        Loop
                    </div>
                </div>

                <ReactTooltip
                    id = "create-travel-type-title-tooltip"
                    className = "create-travel-type-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />                
            </div>
        );


        /*
        ============================================================================================
            Reversible
        ============================================================================================
        */

        const reversible = (
            <div className = "create-detail-row" 
                style = {{ 
                    display: ((this.state.userIsContributor) && (this.state.dotType === "RT") && (!this.state.roundtrip))? "display" : "none",
                    paddingTop: 10 
                }}
            >
                <div id = "create-location-reversible-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    Route Reversible
                </div>
                <div id = "create-location-reversible">
                    <div id = "create-reversible-yes-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.reversible)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                reversible: true
                            });
                        }}
                    >
                        Yes
                    </div>
                    <div id = "create-reversible-no-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (!this.state.reversible)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                reversible: false
                            });
                        }}
                    >
                        No
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Accessibility
        ============================================================================================
        */

        const parkAndWalk = (
            <div id = "create-park-and-walk-button"
                className = {(this.props.colorMode === "day")?
                    "button-light-blue-s3" : "button-blue-s3"}
                style = {
                    (this.state.accessibilityMode === "parkandwalk")?
                        {
                            display: (objectType === "dot")? "inline-block" : "none",
                            background: (this.props.colorMode === "day")?
                                window.colorLightBlue : window.colorBlue,
                            opacity: 1.0
                        } : {
                            display: (objectType === "dot")? "inline-block" : "none",
                            background: (this.props.colorMode === "day")?
                                window.colorLightGray : window.colorGray
                        }
                }
                onClick = {this.dotLocationParkAndWalkClick}
            >
                Park and Walk
            </div>
        );

        const accessibilityContainerID = (objectType === "dot")? 
        "create-location-access-wide" : "create-location-access-narrow";

        const accessibilityDrivableLabel = (objectType === "dot")? 
        "Drivable" : "Drivable";

        const accessibilityTitle = (objectType === "dot")? 
        "Access" : "Access";

        const accessibility = (this.state.everydayModeOn)? null : (
            <div className = "create-basic-row"
                style = {{ display: ((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null))? "block" : "none" }}
            >
                <div id = "create-location-access-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    {accessibilityTitle}
                </div>
                <div id = {accessibilityContainerID}>
                    <div id = "create-drivable-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.accessibilityMode === "drivable")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {(objectType === "dot")? this.dotLocationDrivableClick : this.tripLocationDrivableClick}
                    >
                        {accessibilityDrivableLabel}
                    </div>
                    <div id = "create-undrivable-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.accessibilityMode === "undrivable")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {(objectType === "dot")? this.dotLocationUndrivableClick : this.tripLocationUndrivableClick}
                    >
                        Undrivable
                    </div>
                    {parkAndWalk}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Preferred 
        ============================================================================================
        */

        const preferred = (this.state.everydayModeOn)? null : (
            <div className = "create-basic-row"
                style = {{ display: (((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null)) && ((this.state.accessibilityMode !== "undrivable")))? "block" : "none" }}
            >
                <div id = "create-location-preferred-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    Preferred
                </div>
                <div id = "create-location-preferred">
                    <div id = "create-drive-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.preferredMode === "drive")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ preferredMode: "drive" });}}
                    >
                        Drive
                    </div>
                    <div id = "create-public-transportation-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.preferredMode === "public")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ preferredMode: "public" });}}
                    >
                        Public Transportation
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Location Detect Modal
        ============================================================================================
        */

        const locationDetect = (this.state.locationDetectOn)? (
            <div className = "create-location-detect-modal">
                <div className =  {(this.props.colorMode === "day")?
                        "create-location-detect-modal-content modal-day" :
                        "create-location-detect-modal-content modal-night"}
                >
                    <div className = "create-location-detect-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                       onClick = {this.locationDetectCancel}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "create-location-detect-modal-content__title k2" :
                            "create-location-detect-modal-content__title w2"}
                    >
                        Location Detected
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "create-location-detect-modal-content__text dg4" :
                            "create-location-detect-modal-content__text g4"}
                    >
                        <p>Use the location extracted</p>
                        <p>from the media?</p>
                    </div>

                    <div className = "create-location-detect-delete-modal-content__confirm">
                        <div className = "create-location-detect-delete-modal-content__confirm-select">
                            <div className =  {(this.props.colorMode === "day")?
                                    "create-location-detect-modal-content__confirm-select-yes button-light-blue-gray-s2" :
                                    "create-location-detect-modal-content__confirm-select-yes button-blue-gray-s2"}
                                onClick = {this.locationDetectConfirm}
                            >
                                Yes
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                    "create-location-detect-modal-content__confirm-select-no button-light-blue-gray-s2" :
                                    "create-location-detect-modal-content__confirm-select-no button-blue-gray-s2"}
                                onClick = {this.locationDetectCancel}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Basic Curation - Title / Overview
        ============================================================================================
        */
        const titlePlaceholder = (this.state.contributorModeOn)? this.placeholderTitle : this.placeholderTitleSimple;

        const title = (
            <div>
                <div className = "create-curation-row">
                    <div className = {(this.props.browserWidth <= 8)?
                            "create-curation-title-small" : "create-curation-title"}
                    >
                        <div className = {(this.props.colorMode === "day")? 
                                "create-curation-title-text k4" : 
                                "create-curation-title-text w4"}
                        >
                            Title
                        </div>
                    </div>
                    <div className = "create-curation-input">
                        <input
                            ref = {this.titleRef}
                            type = "text"
                            id = "create-title"
                            className = {(this.props.colorMode === "day")? 
                                "input-s3 input-day" : "input-s3 input-night"}
                            placeholder = {titlePlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.titleOnChange}
                            style = {{ borderColor: this.state.titleBorderColor }}
                            required
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-warning warning-day-s2" :
                        "create-warning warning-night-s2"}
                    style = {{ display: this.state.titleWarningOn? "block" : "none" }}>
                    {this.state.titleWarningMessage}
                </div>
            </div>
        );


        let overviewPlaceholder = null;

        if (this.state.contributorModeOn) {
            if (objectType === "trip") {
                overviewPlaceholder = this.placeholderOverviewTrip;
            }
            else {
                overviewPlaceholder = this.placeholderOverviewDot;
            }
        }
        else {
            overviewPlaceholder = this.placeholderOverviewSimple;
        }

        const overviewTitle = (this.state.contributorModeOn)? "Overview" : "Story";

        const overview = (
            <div>
                <div className = "create-curation-row">
                    <div className = {(this.props.browserWidth <= 8)?
                            "create-curation-title-small" : "create-curation-title"}
                    >
                        <div className = {(this.props.colorMode === "day")? 
                                "create-curation-title-text k4" : 
                                "create-curation-title-text w4"}
                        >
                            {overviewTitle}
                        </div>
                    </div>
                    <div className = "create-curation-input">
                        <textarea
                            ref = {this.overviewRef}
                            id = "create-overview"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            rows = {4}
                            placeholder = {overviewPlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.overviewOnChange}
                            style = {{ borderColor: this.state.overviewBorderColor }}
                            required
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-warning warning-day-s2" :
                        "create-warning warning-night-s2"}
                    style = {{ display: this.state.overviewWarningOn? "block" : "none" }}>
                    {this.state.overviewWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Advanced Curation Type
        ============================================================================================
        */

        const curationType = (
            <div id = "create-curation-type"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "create-curation-type-todos"
                    className = {(this.props.colorMode === "day")?
                        "button-light-blue-s3" : "button-blue-s3"}
                    style = {
                        (this.state.curationMode === "todos")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => {this.setState({ curationMode: "todos" });}}
                >
                    Todos
                </div>
                <div id = "create-curation-type-history"
                    className = {(this.props.colorMode === "day")?
                        "button-light-blue-s3" : "button-blue-s3"}
                    style = {
                        (this.state.curationMode === "history")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => {this.setState({ curationMode: "history" });}}
                >
                    History
                </div>
                <div id = "create-curation-type-stories"
                    className = {(this.props.colorMode === "day")?
                        "button-light-blue-s3" : "button-blue-s3"}
                    style = {
                        (this.state.curationMode === "stories")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => {this.setState({ curationMode: "stories" });}}
                >
                    Stories
                </div>
            </div>
        );


        /*
        ============================================================================================
            Advanced Curation - Todos / History / Stories
        ============================================================================================
        */

        let todosPlaceholder = null;

        if (this.state.dotType === "DI") {
            todosPlaceholder = this.placeholderTodosDine;
        }
        else {
            todosPlaceholder = this.placeholderTodos;
        }

        const curation = (
            <div style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}>
                <div className = "create-curation-row">
                    <div className = {(this.props.browserWidth <= 8)?
                            "create-curation-title-small" : "create-curation-optional-title"}
                    >
                        <div className = {(this.props.colorMode === "day")? 
                                "create-curation-title-text k4" : 
                                "create-curation-title-text w4"}
                        >
                            Optional
                        </div>
                    </div>
                    
                    <div className = "create-curation-input">
                        {curationType}
                        <textarea
                            ref = {this.todosRef}
                            id = "create-todos"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            rows = {4}
                            placeholder = {todosPlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.todosOnChange}
                            style = {{
                                display: (this.state.curationMode === "todos")? "block" : "none",
                                borderColor: this.state.todosBorderColor
                            }}
                        />

                        <textarea
                            ref = {this.historyRef}
                            id = "create-history"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            rows = {4}
                            placeholder = {this.placeholderHistory}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.historyOnChange}
                            style = {{
                                display: (this.state.curationMode === "history")? "block" : "none",
                                borderColor: this.state.historyBorderColor
                            }}
                        />

                        <textarea
                            ref = {this.storiesRef}
                            id = "create-stories"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            rows = {4}
                            placeholder = {this.placeholderStories}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.storiesOnChange}
                            style = {{
                                display: (this.state.curationMode === "stories")? "block" : "none",
                                borderColor: this.state.storiesBorderColor
                            }}
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-warning warning-day-s2" :
                        "create-warning warning-night-s2"}
                    style = {{ display: (this.state.todosWarningOn)? "block" : "none" }}>
                    {this.state.todosWarningMessage}
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-warning warning-day-s2" :
                        "create-warning warning-night-s2"}
                    style = {{ display: (this.state.historyWarningOn)? "block" : "none" }}>
                    {this.state.historyWarningMessage}
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-warning warning-day-s2" :
                        "create-warning warning-night-s2"}
                    style = {{ display: (this.state.storiesWarningOn)? "block" : "none" }}>
                    {this.state.storiesWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Progress First and Second Button Titles
        ============================================================================================
        */

        const progressFirstButtonTitle = (this.state.contributorModeOn)? "Basics" : "Location";
        const progressSecondButtonTitle = (this.state.contributorModeOn)? "Stories" : "Story";


        /*
        ============================================================================================
            Progress Buttons and Sections
        ============================================================================================
        */
        const createConnect = (objectType === "trip")? 
        (
            <CreateConnect
                colorMode = {this.props.colorMode}
                sliderClassNames = {sliderClassNames}

                dotType = {this.state.dotType}
                roundtrip = {this.state.roundtrip}
                loop = {this.state.loop}
                selectedIDs = {this.state.selectedIDs}
                highlightIDs = {this.state.highlightIDs}
                highlightCount = {this.state.highlightCount}
                setState = {this.setState}
                refreshResults = {this.state.refreshResults}
                google = {this.props.google}
                startLocation = {this.state.startLocation}
                endLocation = {this.state.endLocation}
                transitModes = {this.state.transitModes}
                transitDurations = {this.state.transitDurations}
                transitPhysicalities = {this.state.transitPhysicalities}
                transitDistances = {this.state.transitDistances}
                durationScaleIndices = {this.state.durationScaleIndices}
                distanceScaleIndices = {this.state.distanceScaleIndices}
                durationScales = {this.durationScales}
                distanceScales = {this.distanceScales}
                physicalityLabels = {this.physicalityLabels}
                updateTransitDurations = {this.updateTransitDurations}
                updateTransitPhysicalities = {this.updateTransitPhysicalities}
                updateTransitDistances = {this.updateTransitDistances}
                updateDurationScaleIndices = {this.updateDurationScaleIndices}
                updateDistanceScaleIndices = {this.updateDistanceScaleIndices}
                defaultPhysicalities = {this.defaultPhysicalities}
                defaultSpeeds = {this.defaultSpeeds}

                defaultTransitMode = {this.defaultTransitMode}
                defaultTransitDuration = {this.defaultTransitDuration}
                defaultTransitPhysicality = {this.defaultTransitPhysicality}
                defaultTransitDistance = {this.defaultTransitDistance}
                metersToMiles = {this.metersToMiles}

                locationStartWarningMessage = {this.locationStartWarningMessage}
                locationEndWarningMessage = {this.locationEndWarningMessage}
                locationStartEndWarningMessage = {this.locationStartEndWarningMessage}
                connectStartLocationWarningMessage = {this.connectStartLocationWarningMessage}
                connectEndLocationWarningMessage = {this.connectEndLocationWarningMessage}
                connectStartEndLocationWarningMessage = {this.connectStartEndLocationWarningMessage}

                colorInputBorders = {this.colorInputBorders}
                scrollToTop = {this.scrollToTop}
            />
        ) : null;


        /*
        ============================================================================================
            Progress Buttons and Sections
        ============================================================================================
        */
        const progressLineImage = (this.props.colorMode === "day")? 
            getStaticPath("/images/create/progress-line-day.png") :
            getStaticPath("/images/create/progress-line-night.png");

        let progressFirstButtonNumber, progressSecondButtonNumber, progressThirdButtonNumber = null;
        let progressFirstButtonTitleColor, progressSecondButtonTitleColor, progressThirdButtonTitleColor;
        let progressFirstButtonTitleWeight, progressSecondButtonTitleWeight, progressThirdButtonTitleWeight;

        if (this.checkProgressFirstWarning()) {
            progressFirstButtonNumber = 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_red_day_1.png") :
                        getStaticPath("/images/number/double_red_night_1.png")
                );

            progressFirstButtonTitleColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;

            progressFirstButtonTitleWeight = this.fontWeightBold;
        }
        else {
            progressFirstButtonNumber = (this.state.createStage === 1)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_light_blue_day_1.png") :
                        getStaticPath("/images/number/double_blue_night_1.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_gray_day_1.png") :
                        getStaticPath("/images/number/double_gray_night_1.png")
                );

            progressFirstButtonTitleColor = (this.props.colorMode === "day")?
                window.colorBlack : window.colorWhite;

            progressFirstButtonTitleWeight = this.fontWeightRegular;
        }
        if (this.checkProgressSecondWarning()) {
            progressSecondButtonNumber = 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_red_day_2.png") :
                        getStaticPath("/images/number/double_red_night_2.png")
                );

            progressSecondButtonTitleColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;

            progressSecondButtonTitleWeight = this.fontWeightBold;
        }
        else {
            progressSecondButtonNumber = (this.state.createStage === 2)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_light_blue_day_2.png") :
                        getStaticPath("/images/number/double_blue_night_2.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_gray_day_2.png") :
                        getStaticPath("/images/number/double_gray_night_2.png")
                );

            progressSecondButtonTitleColor = (this.props.colorMode === "day")?
                window.colorBlack : window.colorWhite;

            progressSecondButtonTitleWeight = this.fontWeightRegular;
        }
        if (this.checkProgressThirdWarning()) {
            progressThirdButtonNumber = 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_red_day_3.png") :
                        getStaticPath("/images/number/double_red_night_3.png")
                );

            progressThirdButtonTitleColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;

            progressThirdButtonTitleWeight = this.fontWeightBold;
        }
        else {
            progressThirdButtonNumber = (this.state.createStage === 3)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_light_blue_day_3.png") :
                        getStaticPath("/images/number/double_blue_night_3.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_gray_day_3.png") :
                        getStaticPath("/images/number/double_gray_night_3.png")
                );

            progressThirdButtonTitleColor = (this.props.colorMode === "day")?
                window.colorBlack : window.colorWhite;

            progressThirdButtonTitleWeight = this.fontWeightRegular;
        }
        const progressSecondSection = (objectType === "trip")?
        (
            <div id = "create-progress-second-section"
                style = {{
                    backgroundImage: progressLineImage
                }}
            >
                <div id = "create-progress-third"
                    className = "image-contain"
                    style = {
                        (this.state.createStage === 3)?
                        {
                            opacity: 1.0
                        } : {
                        }
                    }
                >
                    <div id = "create-progress-third-number"
                        className = "image-contain"
                        style = {{ backgroundImage: progressThirdButtonNumber }}
                        onClick = {() => {this.setState({ createStage: 3 });}}
                    >
                    </div>
                    <div id = "create-progress-third-title"
                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        style = {{
                            color: progressThirdButtonTitleColor,
                            fontWeight: progressThirdButtonTitleWeight
                        }}
                    >
                        Connect
                    </div>
                </div>
            </div>
        ): null;
        //console.log("progressSecondSection = ", progressSecondSection);


        /*
        ============================================================================================
            Publish Button
        ============================================================================================
        */

        let publishButton = null;
        let publishButtonImage = null;

        if (this.checkProgressFirstWarning() || this.checkProgressSecondWarning() || this.checkProgressThirdWarning()) {
            publishButtonImage = (this.state.progressPublishButtonHoverOn)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/create/publish-red-day.png") : 
                        getStaticPath("/images/create/publish-red-night.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/create/publish-gray-day.png") : 
                        getStaticPath("/images/create/publish-gray-night.png")
                );
        }
        else {
            publishButtonImage = (this.state.progressPublishButtonHoverOn)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/create/publish-blue-day.png") : 
                        getStaticPath("/images/create/publish-blue-night.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/create/publish-gray-day.png") : 
                        getStaticPath("/images/create/publish-gray-night.png")
                );
        }

        if (objectType === "trip") {
            publishButton = (this.state.createStage === 3)?
            (
                <div id = "create-progress-publish-container">
                    <div id = "create-progress-publish"
                        className = "image-contain"
                        style = {{ backgroundImage: publishButtonImage }}
                        onClick = {this.getState.bind(this, this.progressPublishClick)}
                        onMouseEnter = {() => {this.setState({ progressPublishButtonHoverOn : true });}}
                        onMouseLeave = {() => {this.setState({ progressPublishButtonHoverOn : false });}}
                    >
                    </div>
                </div>
            ) : null;
        }
        else {
            publishButton = (this.state.createStage === 2)?
            (
                <div id = "create-progress-publish-container">
                    <div id = "create-progress-publish"
                        className = "image-contain"
                        style = {{ backgroundImage: publishButtonImage }}
                        onClick = {this.getState.bind(this, this.progressPublishClick)}
                        onMouseEnter = {() => {this.setState({ progressPublishButtonHoverOn : true });}}
                        onMouseLeave = {() => {this.setState({ progressPublishButtonHoverOn : false });}}
                    >
                    </div>
                </div>
            ) : null;
        }

        // Publish message
        let publishMessage = null;
        if (objectType === "dot") {
            publishMessage = "You just published a dot."
        }
        else if ((objectType === "trip") && (this.state.dotType === "TR")) {
            publishMessage = "You just published a trip."
        }
        else if ((objectType === "trip") && (this.state.dotType === "RT")) {
            publishMessage = "You just published a route."
        }
        else {
            console.log("[WARNING] Create / render - wrong objectType");
        }

        // Contributor only part (left section)
        const contributorOnly = (this.state.contributorModeOn)? (
            <div id = "create-left">
                <div id = "create-detail-container">
                    {dotTypeMenu}

                    {tripTypeMenu}

                    {roundtrip}

                    {reversible}

                    {ratingSlider}

                    {durationSlider}

                    {physicalitySlider}

                    {bestTimeSlider}

                    {hours}

                    {seasonClosure}

                    {diningOptions}
                </div>
            </div>
        ) : null;

        // Complete button image
        const completeButtonImage = (this.state.completeButtonHoverOn)? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/create/confirm-light-blue.png") : 
                    getStaticPath("/images/create/confirm-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/create/confirm-light-gray.png") : 
                    getStaticPath("/images/create/confirm-gray.png")
            );

        // JSX part to render html elements
        return (
            <div id = "create-container"
                className = {(this.props.browserWidth <= window.browserWidthSmall)? 
                    "create-container-small" : "create-container"}
            >
                {locationDetect}

                <div id = "create-complete-modal" 
                    style = {{ display: (this.state.completeModalOn)? "block" : "none" }}
                >
                    <div id = "create-complete-publish"
                        className = {(this.props.colorMode === "day")? 
                            "modal-day" :
                            "modal-night"} 
                    >
                        <div id = "create-complete-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Congratulations
                        </div>
                        <div id = "create-complete-message"
                            className ={(this.props.colorMode === "day")? "dg4" : "g4"}
                        >
                            {publishMessage}
                        </div>
                        <div id = "create-complete-public"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s2" : "button-blue-s2"}
                            style = {
                                (this.state.isPublic)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => { this.setState({ isPublic: true }); }}
                            data-tip = "Submit for a Review and Get Published in the Magazine"
                            data-for = "create-complete-public-tooltip"
                        >
                            Public
                        </div>
                        <div id = "create-complete-personal"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s2" : "button-blue-s2"}
                            style = {
                                (this.state.isPublic)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    }
                            }
                            onClick = {() => { this.setState({ isPublic: false }); }}
                            data-tip = "Keep It To Yourself and Your Followers"
                            data-for = "create-complete-personal-tooltip"
                        >
                            Private
                        </div>

                        {
                            (this.state.isPublic)?
                                (
                                    <div id = "create-complete-slider-container">
                                        <div id = "create-complete-slider-title"
                                            className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                            data-tip = "Reduce the Number of Visitors for Conservation Purposes."
                                            data-for = "create-complete-slider-tooltip"
                                        >
                                            Bucketing Limit
                                        </div>
                                        <div id = "create-complete-slider">
                                            <InputRange
                                                classNames = {sliderClassNames}
                                                maxValue = {4}
                                                minValue = {0}
                                                value = {this.state.saveLimit}
                                                onChange = {saveLimit => this.setState({ saveLimit: saveLimit })}
                                                formatLabel = {saveLimit => this.saveLimitLabels[saveLimit]}
                                                showEndLabels = {false}
                                                step = {1}
                                            />
                                        </div>
                                    </div>
                                ) : null
                        }
                        <div id = "create-complete-button-container"
                            onMouseEnter = {() => {this.setState({ completeButtonHoverOn : true });}}
                            onMouseLeave = {() => {this.setState({ completeButtonHoverOn : false });}}
                            onClick = {this.completeButtonClick}
                        >
                            <Link to = {`/`}>
                                <div id = "create-complete-button"
                                    className = "image-contain"
                                    style = {{ backgroundImage: completeButtonImage }}
                                    data-tip = "Submit"
                                    data-for = "create-complete-button-tooltip"
                                >
                                </div>
                                <div id = "create-complete-text"
                                    className = {(this.props.colorMode === "day")? "k2" : "w2"}
                                    style = {{ opacity: (this.state.completeButtonHoverOn)? 1.0 : 0.6 }}
                                >
                                    Finish
                                </div>
                            </Link>
                        </div>
                        <ReactTooltip
                            id = "create-complete-public-tooltip"
                            className = "create-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                        <ReactTooltip
                            id = "create-complete-personal-tooltip"
                            className = "create-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                        <ReactTooltip
                            id = "create-complete-slider-tooltip"
                            className = "create-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                        <ReactTooltip
                            id = "create-complete-button-tooltip"
                            className = "create-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                    </div>
                </div>

                {objectTypeMenu}

                <div className = "body-narrow" 
                    style = {{ display: (this.state.createStage !== 3)? "block" : "none" }}
                >
                    <UploadGallery
                        imageUploadID = {this.imageUploadID}
                        videoUploadID = {this.videoUploadID}

                        colorMode = {this.props.colorMode}

                        searchOn = {!this.state.everydayModeOn}

                        mediaArea = {this.mediaArea}
                        thumbnailArea = {this.thumbnailArea}
                        marginWidth = {this.marginWidth}
                        minAspectRatio = {this.minAspectRatio}
                        maxAspectRatio = {this.maxAspectRatio}
                        minContentWidth = {this.minContentWidth}
                        scaleContentWidth = {this.scaleContentWidth}
                        maxContentWidth = {this.maxContentWidth}

                        objectType = {objectType}
                        userIsContributor = {this.state.userIsContributor}
                        contributorModeOn = {this.state.contributorModeOn}

                        selectedMediaIndex = {this.state.selectedMediaIndex}
                        startMediaIndex = {this.state.startMediaIndex}
                        endMediaIndex = {this.state.endMediaIndex}
                        numThumbnails = {this.state.numThumbnails}

                        media = {this.media}
                        mediaKeys = {this.state.mediaKeys}
                        mediaIDs = {this.state.mediaIDs}
                        mediaCategories = {this.state.mediaCategories}
                        
                        setState = {this.setState}
                        addImage = {this.addImage}
                        addVideo = {this.addVideo}
                        removeMedia = {this.removeMedia}
                        addSearchedMedia = {this.addSearchedMedia}
                        clearSearchedMedia = {this.clearSearchedMedia}

                        selectMedia = {this.selectMedia}
                        thumbnailPrevPage = {this.thumbnailPrevPage}
                        thumbnailNextPage = {this.thumbnailNextPage}
                        moveMediaRight = {this.moveMediaRight}
                        moveMediaLeft = {this.moveMediaLeft}

                        focusOpacity = {this.focusOpacity}
                        unfocusOpacity = {this.unfocusOpacity}

                        mediaWarningOn = {this.state.mediaWarningOn}
                        mediaWarningMessage = {this.state.mediaWarningMessage}

                        uploadProgress = {this.uploadProgress}
                        uploadUpdate = {this.state.uploadUpdate}
                    />
                </div>

                <div id = "create-progress-container">
                    <div id = "create-progress-first-section"
                        style = {{
                            backgroundImage: progressLineImage,
                        }}
                    >
                        <div id = "create-progress-first"
                            className = "image-contain"
                            style = {
                                (this.state.createStage === 1)?
                                {
                                    opacity: 1.0
                                } : {
                                }
                            }
                        >
                            <div id = "create-progress-first-number"
                                className = "image-contain"
                                style = {{ backgroundImage: progressFirstButtonNumber }}
                                onClick = {() => {this.setState({ createStage: 1 });}}
                            >
                            </div>
                            <div id = "create-progress-first-title"
                                className = {(this.props.colorMode === "day")? "k4" : "w4"}
                                style = {{
                                    color: progressFirstButtonTitleColor,
                                    fontWeight: progressFirstButtonTitleWeight
                                }}
                            >
                                {progressFirstButtonTitle}
                            </div>
                        </div>
                        <div id = "create-progress-second"
                            className = "image-contain"
                            style = {
                                (this.state.createStage === 2)?
                                {
                                    opacity: 1.0
                                } : {
                                }
                            }
                        >
                            <div id = "create-progress-second-number"
                                className = "image-contain"
                                style = {{ backgroundImage: progressSecondButtonNumber }}
                                onClick = {() => {this.setState({ createStage: 2 });}}
                            >
                            </div>
                            <div id = "create-progress-second-title"
                                className = {(this.props.colorMode === "day")? "k4" : "w4"}
                                style = {{
                                    color: progressSecondButtonTitleColor,
                                    fontWeight: progressSecondButtonTitleWeight                                    
                                }}
                            >
                                {progressSecondButtonTitle}
                            </div>
                        </div>
                    </div>
                    
                    {progressSecondSection}

                    {publishButton}
                </div>

                <div className = "body-wide">
                    <div id = "create-connect-container"
                        style = {{ display: (this.state.createStage === 3)? "block" : "none" }}
                    >
                        <div className = {(this.props.colorMode === "day")?
                                "create-warning warning-day-s2" :
                                "create-warning warning-night-s2"}
                            style = {{ display: (this.state.connectWarningOn)? "block" : "none" }}>
                            {this.state.connectWarningMessage}
                        </div>
                        {createConnect}
                    </div>
                </div>

                <div className = "body-narrow"
                    style = {{ display: (this.state.createStage === 1)? "block" : "none" }}
                >
                    {(this.props.browserWidth <= 7)? null : contributorOnly}

                    <div id = {((this.state.contributorModeOn) && (this.props.browserWidth > 6))?
                            "create-right" : "create-right-small"}
                    >
                        <div id = "create-basic-container">

                            {name}

                            {area}

                            {accessibility}

                            <div className = "create-basic-row"
                                style = {{
                                    display: ((this.state.location === null) && (this.state.startLocation === null))? "none" : "block"
                                }}
                            >
                                <div id = "create-map-container" 
                                    className = {(this.props.colorMode === "day")?
                                        "border-day" : "border-night"}
                                >
                                    {locationType}
                                    {map}
                                </div>
                            </div>

                            {preferred}

                            <div className = "create-basic-row">
                                {locationSearchTitle}
                                <div className = "create-basic-input">
                                    <input
                                        ref = {this.locationRef}
                                        type = "text"
                                        id = "create-location"
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s3 input-day" : "input-s3 input-night"}
                                        placeholder = {locationPlaceholder}
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.locationOnChange}
                                        style = {{ borderColor: this.state.locationBorderColor }}
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className = "create-basic-row"
                                style = {{
                                    display: ((this.state.contributorModeOn) && ((this.state.location !== null) || 
                                        (this.state.startLocation !== null) || (this.state.endLocation !== null)))? "block": "none"
                                }}
                            >
                                <div id = "create-location-gps">
                                    <div id = "create-location-gps-title"
                                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                                    >
                                        GPS
                                    </div>
                                    <div className = "create-basic-input">
                                        <input
                                            ref = {this.locationLatRef}
                                            type = "text"
                                            id = "create-location-lat"
                                            className = {(this.props.colorMode === "day")? 
                                                "input-s3 input-day" : "input-s3 input-night"}
                                            placeholder = {locationLat}
                                            onFocus = {this.inputOnFocus}
                                            onBlur = {this.inputOnBlur}
                                            onChange = {this.locationLatOnChange}
                                            style = {{ borderColor: this.state.locationLatBorderColor }}
                                        />
                                        <input
                                            ref = {this.locationLngRef}
                                            type = "text"
                                            id = "create-location-lng"
                                            className = {(this.props.colorMode === "day")? 
                                                "input-s3 input-day" : "input-s3 input-night"}
                                            placeholder = {locationLng}
                                            onFocus = {this.inputOnFocus}
                                            onBlur = {this.inputOnBlur}
                                            onChange = {this.locationLngOnChange}
                                            style = {{ borderColor: this.state.locationLngBorderColor }}
                                        />
                                        <div id = "create-location-gps-button"
                                            className = {(this.props.colorMode === "day")?
                                                "button-light-blue-s3" : "button-blue-s3"}
                                            onClick = {this.locationGPSClick}
                                        > 
                                            Set
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className = {(this.props.colorMode === "day")?
                                    "create-warning warning-day-s2" :
                                    "create-warning warning-night-s2"}
                                style = {{ display: (this.state.locationWarningOn)? "block" : "none" }}>
                                {this.state.locationWarningMessage}
                            </div>
                        </div>
                    </div>
                </div>

                {
                    ((this.props.browserWidth <= 7) && (this.state.contributorModeOn))? 
                    (
                        <div className = "body-narrow"
                            style = {{ display: (this.state.createStage === 1)? "block" : "none" }}
                        >
                            <div id = "create-contributor-only">
                                {contributorOnly}
                            </div>
                        </div>
                    ) : null
                }

                <div className = "body-narrow">
                    <div id = "create-curation-container"
                        style = {{ display: (this.state.createStage === 2)? "block" : "none" }}
                    >
                        {title}

                        {overview}

                        {curation}
                    </div>
                </div>

                <div className = "body-narrow">
                    <div id = "create-write-mode-container">
                        {everydayMode}

                        {authorMode}

                        {contributorMode}
                    </div>
                </div>
            </div>
        );
    }


    componentDidMount() {
        // Grab the DOM nodes of inputs
        this.imageNode = document.getElementById(this.imageUploadID);
        this.videoNode = document.getElementById(this.videoUploadID);

        // Set up an event listener for the media upload
        this.imageNode.addEventListener("change", this.addImage);
        this.videoNode.addEventListener("change", this.addVideo);

        // Set up event listeners for the location search input
        this.locationRef.current.addEventListener("paste", this.locationResizeDelayed);
        this.locationRef.current.addEventListener("keydown", this.locationResizeDelayed);

        // Set up event listeners for the overview input
        this.overviewRef.current.addEventListener("paste", this.overviewResizeDelayed);
        this.overviewRef.current.addEventListener("keydown", this.overviewResizeDelayed);

        // Set up event listeners for the todos input
        this.todosRef.current.addEventListener("paste", this.todosResizeDelayed);
        this.todosRef.current.addEventListener("keydown", this.todosResizeDelayed);

        // Set up event listeners for the history input
        this.historyRef.current.addEventListener("paste", this.historyResizeDelayed);
        this.historyRef.current.addEventListener("keydown", this.historyResizeDelayed);

        // Set up event listeners for the stories input
        this.storiesRef.current.addEventListener("paste", this.storiesResizeDelayed);
        this.storiesRef.current.addEventListener("keydown", this.storiesResizeDelayed);

        // console.log(this.props.google)
        // Set up google autocomplete for location
        const locationAutocomplete = new this.props.google.maps.places.Autocomplete(this.locationRef.current);
        //console.log("this.locationRef.current = ", this.locationRef.current);

        // Save scope
        const that = this;

        // Add a Listener to the location input
        this.props.google.maps.event.addListener(
            locationAutocomplete,
            "place_changed",
            function() {
                // State
                let state = null;

                // Get place from the input address
                const place = locationAutocomplete.getPlace();
                //console.log("Create / componentDidMount - locationAutocomplete - place = ", place);

                if (!place.geometry) {
                    console.log("[WARNING] Create / componentDidMount - Autocomplete returned a place with no geometry");
                }
                else {
                    for (let i=0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types[0] === "administrative_area_level_1") {
                            state = place.address_components[i].short_name;
                            console.log("Create / componentDidMount - locationAutocomplete - place = ", place);
                            console.log("Create / componentDidMount - locationAutocomplete - place.address_components[i].short_name = ", place.address_components[i].short_name);
                         }
                    }

                    if (state === null) {
                        state = place.address_components[0].short_name;
                    }
                }
                //console.log("Create / componentDidMount - locationAutocomplete - that.props = ", that.props);
                //console.log("Create / componentDidMount - locationAutocomplete - state = ", state);
                //console.log("Create / componentDidMount - locationAutocomplete - place.geometry.location = ", place.geometry.location);
                //console.log("Create / componentDidMount - locationAutocomplete - latitude = ", place.geometry.location.lat());
                //console.log("Create / componentDidMount - locationAutocomplete - longitude = ", place.geometry.location.lng());

                // Set start location
                that.location = latLngToPoint(place.geometry.location);
                //console.log("Create / componentDidMount - locationAutocomplete - latLngToPoint(place.geometry.location) = ", latLngToPoint(place.geometry.location));
                //console.log("Create / componentDidMount - locationAutocomplete - that.location = ", that.location);

                // Set states
                if ((that.state.dotType !== "TR") && (that.state.dotType !== "RT")) {
                    if (that.state.locationType === "parking") {
                        that.setState(
                            {
                                parkingLocation: that.location,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                    else {
                        if ((that.nameRef.current.value === null) || (that.nameRef.current.value === "")) {
                            that.nameRef.current.value = place.name;

                            that.setState(
                                {
                                    location: that.location,
                                    state: state,
                                    nameOn: true,
                                    nameWarningOn: false,
                                    nameWarningMessage: null,
                                    mapResetMarkers: false,
                                    mapZoomInAnimation: false,
                                    mapZoomOutAnimation: false,
                                    mapZoomHikeAnimation: false,
                                    mapRefreshAnimation: true
                                },
                                that.colorInputBorders
                            );
                        }
                        else {
                            that.setState(
                                {
                                    location: that.location,
                                    state: state,
                                    mapResetMarkers: false,
                                    mapZoomInAnimation: true,
                                    mapZoomOutAnimation: false,
                                    mapZoomHikeAnimation: false,
                                    mapRefreshAnimation: true
                                }
                            );
                        }
                    }
                }
                else {
                    if (that.state.locationType === "start") {
                        that.setState(
                            {
                                startLocation: that.location,
                                startLocationName: (place.vicinity)? place.vicinity : place.formatted_address,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                    else {
                        that.setState(
                            {
                                endLocation: that.location,
                                endLocationName: (place.vicinity)? place.vicinity : place.formatted_address,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                }

                // Change input size
                that.locationResizeDelayed();

                // Clear GPS inputs (only in create-dot mode)
                that.clearLocationGPS();
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.browserWidth !== prevProps.browserWidth) {
            this.updateIndices();            
        }
    }

    componentWillUnmount() {
        // Remove an event listener for the media upload
        this.imageNode.removeEventListener("change", this.addImage);
        this.videoNode.removeEventListener("change", this.addVideo);

        // Remove event listeners for the location search input
        this.locationRef.current.removeEventListener("paste", this.locationResizeDelayed);
        this.locationRef.current.removeEventListener("keydown", this.locationResizeDelayed);

        // Remove event listeners for the overview input
        this.overviewRef.current.removeEventListener("paste", this.overviewResizeDelayed);
        this.overviewRef.current.removeEventListener("keydown", this.overviewResizeDelayed);

        // Remove event listeners for the todos input
        this.todosRef.current.removeEventListener("paste", this.todosResizeDelayed);
        this.todosRef.current.removeEventListener("keydown", this.todosResizeDelayed);

        // Remove event listeners for the history input
        this.historyRef.current.removeEventListener("paste", this.historyResizeDelayed);
        this.historyRef.current.removeEventListener("keydown", this.historyResizeDelayed);

        // Remove event listeners for the stories input
        this.storiesRef.current.removeEventListener("paste", this.storiesResizeDelayed);
        this.storiesRef.current.removeEventListener("keydown", this.storiesResizeDelayed);
    }


    /*
    ============================================================================================
        Location Detect Click
    ============================================================================================
    */

    locationDetectConfirm(event) {
        // Stop propagation
        event.stopPropagation();

        // Set detected location
        if ((this.state.dotType !== "TR") && (this.state.dotType !== "RT")) {
            this.setState({
                location: this.state.locationDetected,
                locationDetectOn: false,
                locationDetected: null
            });
        }
        else {
            this.setState({
                startLocation: this.state.locationDetected,
                locationDetectOn: false,
                locationDetected: null
            });
        }
    }

    locationDetectCancel(event) {
        // Stop propagation
        event.stopPropagation();

        // Reset location detect
        this.setState({
            locationDetectOn: false,
            locationDetected: null
        });
    }


    /*
    ============================================================================================
        Object Type Click Callbacks
    ============================================================================================
    */

    dotModeClick() {
        this.clearLocationSearch();
        this.nameRef.current.value = "";

        this.setState({
            dotType: "SC",
            roundtrip: null,
            reversible: null,
            bestTimeOn: true,
            bestTimeMode: "single",
            hoursOn: false,
            seasonClosureType: "estimate",
            diningOptions: false,

            accessibilityMode: "drivable",
            locationType: "location",
            location: null,
            parkingLocation: null,
            startLocation: null,
            endLocation: null,
            preferredMode: "drive",
            
            selectedIDs: [],
            highlightIDs: [],
            highlightCount: 0,
            refreshResults: true,
            
            mapRefreshAnimation: true,

            createStage: 1
        });
    }

    tripModeClick() {
        // Clear locations
        this.clearLocationSearch();
        this.nameRef.current.value = "";

        this.setState({
            dotType: "TR",
            roundtrip: false,
            reversible: true,
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: false,
            seasonClosureType: "open",
            diningOptions: false,

            accessibilityMode: "drivable",
            locationType: "start",
            location: null,
            parkingLocation: null,
            startLocation: null,
            endLocation: null,
            preferredMode: "drive",

            selectedIDs: [],
            highlightIDs: [],
            highlightCount: 0,
            refreshResults: true,

            mapRefreshAnimation: true,

            createStage: 1,            
        });
    }


    /*
    ============================================================================================
        Everyday Mode Click
    ============================================================================================
    */

    everydayModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            this.dotModeClick();
        }

        // Turn on author mode
        this.setState({
            everydayModeOn: false,
            authorModeOn: true,
            contributorModeOn: false,
            nameOn: true
        });
    }


    /*
    ============================================================================================
        Author Mode Click
    ============================================================================================
    */

    authorModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            this.dotModeClick();
        }

        if (this.state.userIsContributor) {
            // Turn on contributor mode
            this.setState({
                everydayModeOn: false,
                authorModeOn: false,
                contributorModeOn: true
            });
        }
        else {
            // Turn on everyday mode
            this.setState({
                everydayModeOn: true,
                authorModeOn: false,
                contributorModeOn: false,
                nameOn: ((this.nameRef.current.value === null) || (this.nameRef.current.value === ""))? false : true
            });
        }
    }


    /*
    ============================================================================================
        Contribute Click Callback
    ============================================================================================
    */

    contributorModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            this.dotModeClick();
        }

        // Turn on author mode
        this.setState({
            everydayModeOn: true,
            authorModeOn: false,
            contributorModeOn: false,
            nameOn: ((this.nameRef.current.value === null) || (this.nameRef.current.value === ""))? false : true
        });
    }


    /*
    ============================================================================================
        Dot Type Click Callbacks
    ============================================================================================
    */

    scenicTypeClick() {
        this.setState({
            dotType: "SC",
            roundtrip: null,
            reversible: null,
            bestTimeOn: true,
            bestTimeMode: "single",
            hoursOn: false,
            seasonClosureType: "estimate",
            diningOptions: false
        });
    }

    experienceTypeClick() {
        //console.log("Create / experienceTypeClick");
        this.setState({
            dotType: "EX",
            roundtrip: null,
            reversible: null,
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: true,
            seasonClosureType: "open",
            diningOptions: false
        });
    }

    dineTypeClick() {
        this.setState({
            dotType: "DI",
            roundtrip: null,
            reversible: null,
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: true,
            seasonClosureType: "open",
            diningOptions: false
        });
    }


    /*
    ============================================================================================
         Trip Type Click Callbacks
    ============================================================================================
    */

    tripTypeClick() {
        this.setState({
            dotType: "TR",
            roundtrip: false,
            reversible: true,
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: false,
            seasonClosureType: "open",
            diningOptions: false,

            //accessibilityMode: "drivable",
            //locationType: "start",
            //location: null,
            //startLocation: null,
            //endLocation: null,
            //preferredMode: "drive",

            //selectedIDs: [],
            //highlightIDs: [],
            //highlightCount: 0,
            //refreshResults: true,

            //mapRefreshAnimation: true,

            //createStage: 1,            
        });
    }

    routeTypeClick() {
        // Create copies of the transit lists
        const transitModes = this.state.transitModes.slice();
        const transitDurations = this.state.transitDurations.slice();
        const transitPhysicalities = this.state.transitPhysicalities.slice();
        const transitDistances = this.state.transitDistances.slice();
        const durationScaleIndices = this.state.durationScaleIndices.slice();
        const distanceScaleIndices = this.state.distanceScaleIndices.slice();
        //console.log("Create / routeTypeClick - transitModes = ", transitModes);
        //console.log("Create / routeTypeClick - this.state.selectedIDs = ", this.state.selectedIDs);

        // Fill the missing elements of the transit lists
        const numMissing = this.state.selectedIDs.length + 1 - transitModes.length;
        if (numMissing > 0) {
            for (let i = 0; i < numMissing; i++) {
                transitModes.push(this.defaultTransitMode);
                transitDurations.push(this.defaultTransitDuration);
                transitPhysicalities.push(this.defaultTransitPhysicality);
                transitDistances.push(this.defaultTransitDistance);
                durationScaleIndices.push(0);
                distanceScaleIndices.push(0);
            }
        }
        //console.log("Create / routeTypeClick - transitModes = ", transitModes);

        this.setState({
            dotType: "RT",
            roundtrip: false,
            reversible: true,
            bestTimeOn: true,
            bestTimeMode: "allday",
            hoursOn: false,
            seasonClosureType: "open",
            diningOptions: false,

            //accessibilityMode: "drivable",
            //locationType: "start",
            //location: null,
            //startLocation: null,
            //endLocation: null,
            //preferredMode: "drive",

            transitModes: transitModes,
            transitDurations: transitDurations,
            transitPhysicalities: transitPhysicalities,
            transitDistances: transitDistances,
            durationScaleIndices: durationScaleIndices,
            distanceScaleIndices: distanceScaleIndices

            //selectedIDs: [],
            //highlightIDs: [],
            //highlightCount: 0,
            //refreshResults: true,

            //mapRefreshAnimation: true,

            //createStage: 1,            
        });
    }

    /*
    ============================================================================================
        Change Border Colors
    ============================================================================================
    */

    colorInputBorders() {
        let locationBorderColor,
            locationLatBorderColor, 
            locationLngBorderColor,
            nameBorderColor,
            areaBorderColor,
            titleBorderColor,
            overviewBorderColor,
            todosBorderColor,
            storiesBorderColor,
            historyBorderColor,
            connectBorderColor;

        if (this.state.locationWarningOn) {
            locationBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
            locationLatBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
            locationLngBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            locationBorderColor = (this.locationFocused)? 
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );

            locationLatBorderColor = (this.locationFocused)? 
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );
            locationLngBorderColor = (this.locationFocused)? 
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );
        }

        if (this.state.nameWarningOn) {
            nameBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            nameBorderColor = (this.nameFocused)? 
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.areaWarningOn) {
            areaBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            areaBorderColor = (this.areaFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.titleWarningOn) {
            titleBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            titleBorderColor = (this.titleFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.overviewWarningOn) {
            overviewBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            overviewBorderColor = (this.overviewFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.todosWarningOn) {
            todosBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            todosBorderColor = (this.todosFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.historyWarningOn) {
            historyBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            historyBorderColor = (this.historyFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.storiesWarningOn) {
            storiesBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            storiesBorderColor = (this.storiesFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.connectWarningOn) {
            connectBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            connectBorderColor =  (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray
        }

        // Update states
        this.setState({
            locationBorderColor: locationBorderColor,
            locationLatBorderColor: locationLatBorderColor,
            locationLngBorderColor: locationLngBorderColor,
            nameBorderColor: nameBorderColor,
            areaBorderColor: areaBorderColor,
            titleBorderColor: titleBorderColor,
            overviewBorderColor: overviewBorderColor,
            todosBorderColor: todosBorderColor,
            historyBorderColor: historyBorderColor,
            storiesBorderColor: storiesBorderColor,
            connectBorderColor: connectBorderColor,
        });
    }


    inputOnFocus(event) {
        //console.log("Create / inputOnFocus - event.target.value = ", event.target.value);
        event.target.placeholder = "";

        // get the target id
        const id = event.target.id;

        if (id === "create-location") {
            this.locationFocused = true;
        }
        else if (id === "create-location-lat") {
            this.locationLatFocused = true;
            event.target.value = (this.state.location === null)?
                "" : this.state.location.latitude.toFixed(6)
        }
        else if (id === "create-location-lng") {
            this.locationLngFocused = true;
            event.target.value = (this.state.location === null)?
                "" : this.state.location.longitude.toFixed(6)
        }
        else if (id === "create-name") {
            this.nameFocused = true;
        }
        else if (id === "create-area") {
            this.areaFocused = true;
        }
        else if (id === "create-title") {
            this.titleFocused = true;
        }
        else if (id === "create-overview") {
            this.overviewFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "create-todos") {
            this.todosFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "create-history") {
            this.historyFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "create-stories") {
            this.storiesFocused = true;
            event.target.style.textAlign = "left";
        }
        else {
            console.log("[WARNING] Create / inputOnFocus - unknown input ID = ", id);
        }

        // Update the border colors
        this.colorInputBorders();
    }


    inputOnBlur (event) {
        //console.log("Create / inputOnBlur - event.target.value = ", event.target.value);

        // Get the target id
        const id = event.target.id;

        if (id === "create-location") {
            this.locationFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderLocation : this.placeholderLocationSimple;
        }
        else if (id === "create-location-lat") {
            this.locationLatFocused = false;
            event.target.placeholder = (this.state.location === null)? "Latitude" : this.state.location.latitude.toFixed(6);
        }
        else if (id === "create-location-lng") {
            this.locationLngFocused = false;
            event.target.placeholder = (this.state.location === null)? "Longitude" : this.state.location.longitude.toFixed(6);
        }
        else if (id === "create-name") {
            this.nameFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderName : this.placeholderNameSimple;
        }
        else if (id === "create-area") {
            this.areaFocused = false;
            event.target.placeholder = this.placeholderArea;
        }
        else if (id === "create-title") {
            this.titleFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderTitle : this.placeholderTitleSimple;
        }
        else if (id === "create-overview") {
            this.overviewFocused = false;

            let placeholder = null;
            if (this.state.contributorModeOn) {
                if ((this.state.dotType === "TR") || (this.state.dotType === "RT")) {
                    placeholder = this.placeholderOverviewTrip;
                }
                else {
                    placeholder = this.placeholderOverviewDot;
                }
            }
            else {
                placeholder = this.placeholderOverviewSimple;
            }

            event.target.placeholder = placeholder;

            if (this.overviewRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "create-todos") {
            this.todosFocused = false;

            let placeholder = null;

            if (this.state.dotType === "DI") {
                placeholder = this.placeholderTodosDine;
            }
            else {
                placeholder = this.placeholderTodos;
            }

            event.target.placeholder = placeholder;

            if (this.todosRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "create-history") {
            this.historyFocused = false;

            event.target.placeholder = this.placeholderHistory;

            if (this.historyRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "create-stories") {
            this.storiesFocused = false;

            event.target.placeholder = this.placeholderStories;

            if (this.storiesRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else {
            console.log("[WARNING] Create / inputOnBlur - unknown input ID = ", id);
        }

        // Update the border colors
        this.colorInputBorders();
    }


    /*
    ============================================================================================
        Clear Location Search and GPS
    ============================================================================================
    */

    clearLocationSearch() {
        this.locationRef.current.value = "";
    }

    clearLocationGPS() {
        this.locationLatRef.current.value = "";
        this.locationLngRef.current.value = "";
    }


    /*
    ============================================================================================
        Location Drivable Click
    ============================================================================================
    */

    dotLocationDrivableClick() {
        this.setState({
            accessibilityMode: "drivable",
            locationType: "location",
            parkingLocation: null,
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Park and Walk Click
    ============================================================================================
    */

    dotLocationParkAndWalkClick() {
        this.setState({
            accessibilityMode: "parkandwalk",
            locationType: "location",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Undrivable Click
    ============================================================================================
    */

    dotLocationUndrivableClick() {
        this.setState({
            accessibilityMode: "undrivable",
            locationType: "location",
            parkingLocation: null,
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Drivable Click
    ============================================================================================
    */

    tripLocationDrivableClick() {
        this.setState({
            accessibilityMode: "drivable",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Undrivable Click
    ============================================================================================
    */

    tripLocationUndrivableClick() {
        this.setState({
            accessibilityMode: "undrivable",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location GPS Click
    ============================================================================================
    */

    locationGPSClick() {
        if ((this.locationLatRef.current.value !== null) && (this.locationLngRef.current.value !== null)) {
            // Read latitude and longitude from input values
            const latitude = parseFloat(this.locationLatRef.current.value);
            const longitude = parseFloat(this.locationLngRef.current.value);

            // Check the ranges
            if (((latitude >= -90) && (latitude <= 90)) && ((longitude >= -180) && (longitude <= 180))) {
                // Clear location
                this.clearLocationSearch();

                const location = { "latitude": latitude, "longitude": longitude };

                if (this.state.locationType === "location") {
                    this.setState({
                        location: location
                    });
                }
                else if (this.state.locationType === "parking") {
                    this.setState({
                        parkingLocation: location
                    });
                }
                else if (this.state.locationType === "start") {
                    this.setState({
                        startLocation: location
                    });
                }
                else if (this.state.locationType === "end") {
                    this.setState({
                        endLocation: location
                    });
                }
            }
            else {
                // Issue warning

            }
        }
    }


    /*
    ============================================================================================
        Location
    ============================================================================================
    */

    locationOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const location = "" + this.locationRef.current.value;

        if (location.length > 0) {
            this.clearLocationWarning(false);
        }
    }


    locationLatOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const locationLat = "" + this.locationLatRef.current.value;

        if (locationLat.length > 0) {
            this.clearLocationWarning(false);
        }
    }


    locationLngOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const locationLng = "" + this.locationLngRef.current.value;

        if (locationLng.length > 0) {
            this.clearLocationWarning(false);
        }
    }


    locationResize() {
        this.locationRef.current.style.height = "auto";
        this.locationRef.current.style.height = this.locationRef.current.scrollHeight + "px";
    }


    locationResizeDelayed() {
        window.setTimeout(this.locationResize, 0);
    }


    clearLocationWarning(delay) {
        clearTimeout(this.locationWarningTimer);

        const locationBorderColor = (this.locationFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const locationLatBorderColor = (this.locationLatFocused)? 
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const locationLngBorderColor = (this.locationLngFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            locationBorderColor: locationBorderColor,
            locationLatBorderColor: locationLatBorderColor,
            locationLngBorderColor: locationLngBorderColor,
            locationWarningOn: false,
            locationWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.locationWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }



    /* 
    ============================================================================================
        Map Reset Animations
    ============================================================================================
    */

    resetMapAnimations(callback) {
        //console.log("Create / resetAnimations - executed");
        this.setState(
            {
                mapZoomInAnimation: false,
                mapZoomOutAnimation: false,
                mapZoomHikeAnimation: false,
                mapRefreshAnimation: false
            }, 
            callback
        );
    }


    /*
    ============================================================================================
        Name Warning
    ============================================================================================
    */

    nameOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const name = "" + this.nameRef.current.value;

        if (name.length > 0) {
            this.clearNameWarning(false);
        }
    }


    clearNameWarning(delay) {
        clearTimeout(this.nameWarningTimer);

        const borderColor = (this.nameFocused)? 
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            nameBorderColor: borderColor,
            nameWarningOn: false,
            nameWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.nameWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Area Warning
    ============================================================================================
    */

    areaOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const area = "" + this.areaRef.current.value;

        if (area.length > 0) {
            this.clearAreaWarning(false);
        }
    }


    clearAreaWarning(delay) {
        clearTimeout(this.areaWarningTimer);

        const borderColor = (this.areaFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            areaBorderColor: borderColor,
            areaWarningOn: false,
            areaWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.areaWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Title Warning
    ============================================================================================
    */

    titleOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const title = "" + this.titleRef.current.value;

        if (title.length > 0) {
            this.clearTitleWarning(false);
        }
    }


    clearTitleWarning(delay) {
        clearTimeout(this.titleWarningTimer);

        const borderColor = (this.titleFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            titleBorderColor: borderColor,
            titleWarningOn: false,
            titleWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.titleWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Overview
    ============================================================================================
    */

    overviewOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const overview = "" + this.overviewRef.current.value;

        if (overview.length > 0) {
            this.clearOverviewWarning(false);
        }
    }


    overviewResize() {
        this.overviewRef.current.style.height = "auto";
        this.overviewRef.current.style.height = this.overviewRef.current.scrollHeight + "px";
    }


    overviewResizeDelayed() {
        window.setTimeout( this.overviewResize, 0 );
    }

    clearOverviewWarning(delay) {
        clearTimeout(this.overviewWarningTimer);

        const borderColor = (this.overviewFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            overviewBorderColor: borderColor,
            overviewWarningOn: false,
            overviewWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.overviewWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Todos Warning
    ============================================================================================
    */

    todosOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const todos = "" + this.todosRef.current.value;

        if (todos.length > 0) {
            this.clearTodosWarning(false);
        }
    }


    todosResize() {
        this.todosRef.current.style.height = "auto";
        this.todosRef.current.style.height = this.todosRef.current.scrollHeight + "px";
    }


    todosResizeDelayed() {
        window.setTimeout( this.todosResize, 0 );
    }


    clearTodosWarning(delay) {
        clearTimeout(this.todosWarningTimer);

        const borderColor = (this.todosFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            todosBorderColor: borderColor,
            todosWarningOn: false,
            todosWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.todosWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        History Warning
    ============================================================================================
    */

    historyOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const history = "" + this.historyRef.current.value;

        if (history.length > 0) {
            this.clearHistoryWarning(false);
        }
    }


    historyResize() {
        this.historyRef.current.style.height = "auto";
        this.historyRef.current.style.height = this.historyRef.current.scrollHeight + "px";
    }


    historyResizeDelayed() {
        window.setTimeout( this.historyResize, 0 );
    }


    clearHistoryWarning(delay) {
        clearTimeout(this.historyWarningTimer);

        const borderColor = (this.historyFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            historyBorderColor: borderColor,
            historyWarningOn: false,
            historyWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.historyWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Stories Warning
    ============================================================================================
    */

    storiesOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const stories = "" + this.storiesRef.current.value;

        if (stories.length > 0) {
            this.clearStoriesWarning(false);
        }
    }


    storiesResize() {
        this.storiesRef.current.style.height = "auto";
        this.storiesRef.current.style.height = this.storiesRef.current.scrollHeight + "px";
    }


    storiesResizeDelayed() {
        window.setTimeout( this.storiesResize, 0 );
    }


    clearStoriesWarning(delay) {
        clearTimeout(this.storiesWarningTimer);

        const borderColor = (this.storiesFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            storiesBorderColor: borderColor,
            storiesWarningOn: false,
            storiesWarningMessage: null
        };

        const delayTime = delay? this.delayTime : 0;

        this.storiesWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /* 
    ============================================================================================
        Update Season Closure Dates
    ============================================================================================
    */

    updateSeasonClosureStartDate(date) {
        const closureStartMoment = moment(date);

        // Set state
        this.setState(
            {
                closureStartDate: closureStartMoment
            }
        );
    }


    updateSeasonClosureEndDate(date) {
        const closureEndMoment = moment(date);

        // Set state
        this.setState(
            {
                closureEndDate: closureEndMoment
            }
        );
    }


    updateTransitPhysicalities(transitIndex, physicality) {
        // Construct new transit physicalities
        const newTransitPhysicalities = this.state.transitPhysicalities.slice(0, transitIndex).concat([ physicality ], this.state.transitPhysicalities.slice(transitIndex + 1));

        // Update state
        this.setState({
            transitPhysicalities: newTransitPhysicalities
        });
    }


    updateTransitDurations(transitIndex, duration) {
        // Construct new transit durations
        const newTransitDurations = this.state.transitDurations.slice(0, transitIndex).concat([ duration ], this.state.transitDurations.slice(transitIndex + 1));

        // Update state
        this.setState({
            transitDurations: newTransitDurations
        });
    }


    updateTransitDistances(transitIndex, distance) {
        // Construct new transit distances
        const newTransitDistances = this.state.transitDistances.slice(0, transitIndex).concat([ distance ], this.state.transitDistances.slice(transitIndex + 1));

        // Update state
        this.setState({
            transitDistances: newTransitDistances
        });
    }

    updateDurationScaleIndices(transitIndex, durationScaleIndex) {
        // Construct new duration scale indices
        const newDurationScaleIndices = this.state.durationScaleIndices.slice(0, transitIndex).concat([ durationScaleIndex ], this.state.durationScaleIndices.slice(transitIndex + 1));

        // Update state
        this.setState({
            durationScaleIndices: newDurationScaleIndices
        });
    }

    updateDistanceScaleIndices(transitIndex, distanceScaleIndex) {
        // Construct new distance scale indices
        const newDistanceScaleIndices = this.state.distanceScaleIndices.slice(0, transitIndex).concat([ distanceScaleIndex ], this.state.distanceScaleIndices.slice(transitIndex + 1));

        // Update state
        this.setState({
            distanceScaleIndices: newDistanceScaleIndices
        });
    }


    /* 
    ============================================================================================
        Progress Warnings
    ============================================================================================
    */

    checkProgressFirstWarning() {
        if ((this.state.dotType !== "TR") && (this.state.dotType !== "RT")) {
            if ((this.state.mediaWarningOn) || (this.state.locationWarningOn) || (this.state.nameWarningOn) || (this.state.areaWarningOn)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if ((this.state.mediaWarningOn) || (this.state.areaWarningOn) || (this.state.locationWarningOn)) {
                return true;
            }
            else {
                return false;
            }
        }
    }


    checkProgressSecondWarning() {
            if ((this.state.titleWarningOn) || (this.state.overviewWarningOn) || (this.state.todosWarningOn) || (this.state.historyWarningOn) || (this.state.storiesWarningOn)) {
                return true;
            }
            else {
                return false;
            }
    }


    checkProgressThirdWarning() {
            if ((this.state.connectWarningOn)) {
                return true;
            }
            else {
                return false;
            }
    }


    /* 
    ============================================================================================
        Progress Callbacks
    ============================================================================================
    */

    progressPublishClick(state) {
        //console.log("Create / progressPublishClick");
        //console.log("Create / progressPublishClick - state = ", state);
        //console.log("Create / progressPublishClick - this.state = ", this.state);
        //console.log("Create / progressPublishClick - this.state.location = ", this.state.location);

        // Data warning
        let dataWarning = false;

        // Initialize warnings
        let mediaWarningOn = false;
        let locationWarningOn = false;
        let nameWarningOn = false;
        let areaWarningOn = false;
        let titleWarningOn = false;
        let overviewWarningOn = false;
        let connectWarningOn = false;
        let mediaWarningMessage = null;
        let locationWarningMessage = null;
        let nameWarningMessage = null;
        let areaWarningMessage = null;
        let titleWarningMessage = null;
        let overviewWarningMessage = null;
        let connectWarningMessage = null;

        // Construct request data
        let dataJSON = null;

        // General dot
        if ((this.state.contributorModeOn === false) && ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))) {
            // Media
            const mediaIDs = [];
            for (let i = 0; i < this.state.mediaKeys.length; i++) {
                const mediaKey = this.state.mediaKeys[i];
                const mediaID = this.state.mediaIDs[mediaKey];

                // Media IDs
                mediaIDs.push(mediaID)
            }
            //console.log("Create / progressPublishClick - this.state.mediaKeys = ", this.state.mediaKeys);
            //console.log("Create / progressPublishClick - this.state.mediaIDs = ", this.state.mediaIDs);

            // Check if all upload processes are complete
            let allUploadComplete = true;
            const keys = Object.keys(this.uploadProgress);
            for (let i = 0; i < keys.length; i++) {
                const key = Number(keys[i]);
                if (this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) {
                    allUploadComplete = false;
                }
            }

            // Check media
            if (!allUploadComplete) {
                dataWarning = true;
                mediaWarningOn = true;
                mediaWarningMessage = this.mediaWarningUploadMessage;
            }
            else { 
                if (this.state.mediaKeys.length === 0) {
                    dataWarning = true;
                    mediaWarningOn = true;
                    mediaWarningMessage = this.mediaWarningMessage;
                }
            }

            // Check location
            if ((this.state.accessibilityMode === "parkandwalk") && (this.state.parkingLocation === null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationParkingWarningMessageSimple;
            }
            if (this.state.location === null) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationWarningMessage;
            }

            // Check name
            if ((this.nameRef.current.value === null) || (this.nameRef.current.value === "")) {
                dataWarning = true;
                nameWarningOn = true;
                nameWarningMessage = this.nameWarningMessageSimple;
            }
            // Check title
            if ((this.titleRef.current.value === null) || (this.titleRef.current.value === "")) {
                dataWarning = true;
                titleWarningOn = true;
                titleWarningMessage = this.titleWarningMessageSimple;
            }
            // Check overview
            if ((this.overviewRef.current.value === null) || (this.overviewRef.current.value === "")) {
                dataWarning = true;
                overviewWarningOn = true;
                overviewWarningMessage = this.overviewWarningMessageSimple;
            }

            // Issue warnings
            const stateToUpdate = {
                mediaWarningOn: mediaWarningOn,
                mediaWarningMessage: mediaWarningMessage,
                locationWarningOn: locationWarningOn,
                locationWarningMessage: locationWarningMessage,
                nameWarningOn: nameWarningOn,
                nameWarningMessage: nameWarningMessage,
                //areaWarningOn: areaWarningOn,
                //areaWarningMessage: areaWarningMessage,
                titleWarningOn: titleWarningOn,
                titleWarningMessage: titleWarningMessage,
                overviewWarningOn: overviewWarningOn,
                overviewWarningMessage: overviewWarningMessage,
                //todosWarningOn: todosWarningOn,
                //todosWarningMessage: todosWarningMessage,
                //historyWarningOn: historyWarningOn,
                //historyWarningMessage: historyWarningMessage,
                //storiesWarningOn: storiesWarningOn,
                //storiesWarningMessage: storiesWarningMessage,
                //connectWarningOn: connectWarningOn,
                //connectWarningMessage: connectWarningMessage
            };

            // Update state
            this.setState(stateToUpdate, this.colorInputBorders);

            // Send request if no errors
            if (dataWarning === false) {
                //console.log("Create / progressPublishClick (dot) - state = ", state)

                // Parking location
                const parkingLocation = (this.state.everydayModeOn)? null : 
                    ((this.state.accessibilityMode === "parkandwalk")? this.state.parkingLocation : null);

                // Drivable
                const drivable = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "undrivable")? false : true);

                // Public Transportation
                const publicTransportation = (this.state.everydayModeOn)? null :
                    (
                        (this.state.accessibilityMode === "undrivable")?
                            null :
                            (
                                (this.state.preferredMode === "public")? true : false
                            )
                    );

                // Map zoom level / center / type / displacement
                let mapZoom = null;
                let mapCenter = null;
                let mapType = null;

                if (this.mapSource === "google") {
                    mapZoom = this.state.map.getZoom()
                    mapCenter = latLngToPoint(this.state.map.getCenter());
                    mapType = this.state.map.getMapTypeId();
                }
                else {
                    const view = this.state.map.getView();
                    mapZoom = Math.round(view.getZoom());
                    const lnglat = openLayersProj.transform(view.getCenter(), "EPSG:3857", "EPSG:4326");
                    //console.log("Create / progressPublishClick - lnglat = ", lnglat);
                    mapCenter = {
                        latitude: lnglat[1],
                        longitude: lnglat[0]
                    };
                    mapType = "hybrid";
                }

                // Displacement and create map media switch
                const displacement = (this.mapSource === "google")?
                    calculateDisplacement(this.state.location, mapCenter, this.state.map) : [ 0.0, 0.0 ];
                const createMapMedia = (this.mapSource === "google");

                //console.log("Create / progressPublishClick - mapZoom = ", mapZoom);
                //console.log("Create / progressPublishClick - mapCenter = ", mapCenter);
                //console.log("Create / progressPublishClick - mapType = ", mapType);
                //console.log("Create / progressPublishClick - displacement = ", displacement);

                // Request data
                dataJSON = {
                    // Editor
                    editor_id: this.props.userInfo.id,
                    // Media
                    media_ids: mediaIDs,
                    media_overview: mediaIDs,
                    // Dot type
                    type: (this.state.everydayModeOn)? "EV" : "AU",
                    // Name
                    name: this.nameRef.current.value,
                    // Area
                    area: null,
                    // Rating and physicality 
                    //rating: this.state.rating,
                    //physicality: this.state.physicality,
                    // Duration
                    //duration: this.state.duration,
                    // Best time
                    //time_type: timeType,
                    //time_type_optional: timeTypeOptional,
                    // Hours
                    //start_hour: startHour,
                    //end_hour: endHour,
                    // Season closure
                    //closure_estimate: seasonClosureEstimate,
                    //closure_start_date: seasonClosureStart,
                    //closure_end_date: seasonClosureEnd,
                    // Locations
                    location: this.state.location,
                    parking_location: parkingLocation,
                    //start_location: startLocation,
                    //end_location: (this.state.loop)? null : endLocation,
                    //start_location_name: startLocationName,
                    //end_location_name: (this.state.loop)? null : endLocationName,
                    // Drivable
                    drivable: drivable,
                    // Prefered transportation
                    public_transportation: publicTransportation,
                    // Roundtrip
                    //roundtrip: this.state.roundtrip,
                    // Loop
                    //loop: this.state.loop,
                    // Reversible
                    //reversible: this.state.reversible,
                    // State
                    state: this.state.state,
                    // Map zoom level and center
                    map_zoom: mapZoom,
                    map_center: mapCenter,
                    map_type: mapType,
                    map_source: this.mapSource,
                    map_displacement: displacement,
                    create_map_media: createMapMedia,
                    // Curation
                    title: this.titleRef.current.value,
                    overview: this.overviewRef.current.value,
                    //todos: (this.todosRef.current.value.length === 0)? null: this.todosRef.current.value,
                    //history: (this.historyRef.current.value.length === 0)? null: this.historyRef.current.value,
                    //stories: (this.storiesRef.current.value.length === 0)? null: this.storiesRef.current.value,
                    // Connect
                    //child_ids: null,
                    //highlights: null
                    // Automatically approved
                    is_approved: (this.props.userInfo.create_level === 5)? true : false
                    // Public and save limit
                    //is_public: this.state.is_public,
                    //save_limit: this.saveLimits[this.state.saveLimit]
                };
            }
        }

        // Contributor dot
        else if ((this.state.contributorModeOn === true) && ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))) {
            // Media
            const mediaIDs = [];
            const mediaOverview = [];
            const mediaTodos = [];
            const mediaHistory = [];
            const mediaStories = [];
            for (let i = 0; i < this.state.mediaKeys.length; i++) {
                const mediaKey = this.state.mediaKeys[i];
                const mediaID = this.state.mediaIDs[mediaKey];
                const mediaCategory = this.state.mediaCategories[this.state.mediaKeys[i]];

                // Media IDs
                mediaIDs.push(mediaID)

                // Categorize media IDs
                if (this.state.mediaCategories[mediaKey] === 0) {
                    mediaOverview.push(mediaID);
                }
                else if (mediaCategory === 1) {
                    mediaTodos.push(mediaID);
                }
                else if (mediaCategory === 2) {
                    mediaHistory.push(mediaID);
                }
                else if (mediaCategory === 3) {
                    mediaStories.push(mediaID);
                }
                else {
                    console.log("[WARNING] Create / progressPublishClick - wrong media category");
                }
            }

            // Check if all upload processes are complete
            let allUploadComplete = true;
            const keys = Object.keys(this.uploadProgress);
            for (let i = 0; i < keys.length; i++) {
                const key = Number(keys[i]);
                if (this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) {
                    allUploadComplete = false;
                }
            }

            // Check media
            if (allUploadComplete) {
                if (this.state.mediaKeys.length === 0) {
                    dataWarning = true;
                    mediaWarningOn = true;
                    mediaWarningMessage = this.mediaWarningMessage;
                }
                else {
                    if (mediaOverview.length === 0) {
                        dataWarning = true;
                        mediaWarningOn = true;
                        mediaWarningMessage = this.mediaWarningOverviewMessage;
                    }
                }
            }
            else {
                dataWarning = true;
                mediaWarningOn = true;
                mediaWarningMessage = this.mediaWarningUploadMessage;
            }
  
            // Check location
            if ((this.state.accessibilityMode === "parkandwalk") && (this.state.parkingLocation === null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationParkingWarningMessage;
            }
            if (this.state.location === null) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationWarningMessage;
            }

            // Check name
            if ((this.nameRef.current.value === null) || (this.nameRef.current.value === "")) {
                dataWarning = true;
                nameWarningOn = true;
                nameWarningMessage = this.nameWarningMessage;
            }
            // Check area
            if ((this.areaRef.current.value === null) || (this.areaRef.current.value === "")) {
                dataWarning = true;
                areaWarningOn = true;
                areaWarningMessage = this.areaWarningMessage;
            }
            // Check title
            if ((this.titleRef.current.value === null) || (this.titleRef.current.value === "")) {
                dataWarning = true;
                titleWarningOn = true;
                titleWarningMessage = this.titleWarningMessage;
            }
            // Check overview
            if ((this.overviewRef.current.value === null) || (this.overviewRef.current.value === "")) {
                dataWarning = true;
                overviewWarningOn = true;
                overviewWarningMessage = this.overviewWarningMessage;
            }
            /*
            // Check todos
            if ((this.todosRef.current.value === null) || (this.todosRef.current.value === "")) {
                dataWarning = true;
                todosWarningOn = true;
                todosWarningMessage = "What-to-do Ideas to Fully Enjoy the Place";
            }
            // Check history
            if ((this.historyRef.current.value === null) || (this.historyRef.current.value === "")) {
                dataWarning = true;
                historyWarningOn = true;
                historyWarningMessage = "A Little History That Gives a Different Perspective";
            }
            // Check stories
            if ((this.storiesRef.current.value === null) || (this.storiesRef.current.value === "")) {
                dataWarning = true;
                storiesWarningOn = true;
                storiesWarningMessage = "Other Interesting Stories or Anecdotes";
            }
            */

            // Issue warnings
            const stateToUpdate = {
                mediaWarningOn: mediaWarningOn,
                mediaWarningMessage: mediaWarningMessage,
                locationWarningOn: locationWarningOn,
                locationWarningMessage: locationWarningMessage,
                nameWarningOn: nameWarningOn,
                nameWarningMessage: nameWarningMessage,
                areaWarningOn: areaWarningOn,
                areaWarningMessage: areaWarningMessage,
                titleWarningOn: titleWarningOn,
                titleWarningMessage: titleWarningMessage,
                overviewWarningOn: overviewWarningOn,
                overviewWarningMessage: overviewWarningMessage,
                //todosWarningOn: todosWarningOn,
                //todosWarningMessage: todosWarningMessage,
                //historyWarningOn: historyWarningOn,
                //historyWarningMessage: historyWarningMessage,
                //storiesWarningOn: storiesWarningOn,
                //storiesWarningMessage: storiesWarningMessage,
                //connectWarningOn: connectWarningOn,
                //connectWarningMessage: connectWarningMessage
            };

            // Update state
            this.setState(stateToUpdate, this.colorInputBorders);

            // Send request if no errors
            if (dataWarning === false) {
                // console.log("Create / progressPublishClick (dot) - state = ", state)

                // Dot Type
                let dotType = null;
                if (this.state.everydayModeOn) {
                    dotType = "EV";
                }
                else if (this.state.authorModeOn) {
                    dotType = "AU";
                }
                else {
                    dotType = ((this.state.dotType === "EX") && (this.state.diningOptions))? "DE" : this.state.dotType;
                }

                // Parking location
                const parkingLocation = (this.state.everydayModeOn)? null : 
                    ((this.state.accessibilityMode === "parkandwalk")? this.state.parkingLocation : null);

                // Drivable
                const drivable = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "undrivable")? false : true);

                // Public Transportation
                const publicTransportation = (this.state.everydayModeOn)? null :
                    (
                        (this.state.accessibilityMode === "undrivable")?
                            null :
                            (
                                (this.state.preferredMode === "public")? true : false
                            )
                    );

                // Hours
                let startHour = null;
                let endHour = null;
                if (this.state.hoursOn) {
                    //console.log("Create / progressPublishClick - this.state.startHour.meridiem = ", this.state.startHour.meridiem)
                    let startAddHour, endAddHour;

                    if ((this.state.startHour.meridiem === "pm") && (this.state.startHour.hour < 12)) {
                        startAddHour = 12;
                    }
                    else if ((this.state.startHour.meridiem === "am") && (this.state.startHour.hour === 12)) {
                        startAddHour = -12;
                    }
                    else {
                        startAddHour = 0;
                    }

                    if ((this.state.endHour.meridiem === "pm") && (this.state.endHour.hour < 12)) {
                        endAddHour = 12;
                    }
                    else if ((this.state.endHour.meridiem=== "am") && (this.state.endHour.hour === 12)) {
                        endAddHour = -12;
                    }
                    else {
                        endAddHour = 0;
                    }

                    startHour = "" + pad(this.state.startHour.hour + startAddHour, 2, 0) + ":" + pad(this.state.startHour.minute, 2, 0);
                    endHour = "" + pad(this.state.endHour.hour + endAddHour, 2, 0) + ":" + pad(this.state.endHour.minute, 2, 0);
                }


                // Time types
                let timeType = null;
                let timeTypeOptional = null;
                if (this.state.bestTimeOn) {
                    if (this.state.bestTimeMode === "double") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                        timeTypeOptional = this.bestTimeTypes[this.state.bestTimeSecond];
                    }
                    else if (this.state.bestTimeMode === "single") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                    }
                    else if (this.state.bestTimeMode === "allday") {
                        timeType = "AD";
                    }
                    else {
                        console.log("[WARNING] Create / progressPublishClick - wrong best time mode");
                    }
                }

                // Seasonal information
                let seasonClosureEstimate = null;
                if (this.state.seasonClosureType === "open") {
                    seasonClosureEstimate = null;
                }
                else if (this.state.seasonClosureType === "estimate") {
                    seasonClosureEstimate = true;
                }
                else {
                    seasonClosureEstimate = false;
                }
                let seasonClosureStart = null;
                let seasonClosureEnd = null;
                if (this.state.seasonClosureType !== "open") {
                    seasonClosureStart = "" + pad(this.state.closureStartDate.year(), 4, 0) + "-" + pad(this.state.closureStartDate.month() + 1, 2, 0) + "-" + pad(this.state.closureStartDate.date(), 2, 0);
                }
                if (this.state.seasonClosureType !== "open") {
                    seasonClosureEnd = "" + pad(this.state.closureEndDate.year(), 4, 0) + "-" + pad(this.state.closureEndDate.month() + 1, 2, 0) + "-" + pad(this.state.closureEndDate.date(), 2, 0);
                }

                // Map zoom level / center / type
                let mapZoom = null;
                let mapCenter = null;
                let mapType = null;

                if (this.mapSource === "google") {
                    mapZoom = this.state.map.getZoom()
                    mapCenter = latLngToPoint(this.state.map.getCenter());
                    mapType = this.state.map.getMapTypeId();
                }
                else {
                    const view = this.state.map.getView();
                    mapZoom = Math.round(view.getZoom());
                    const lnglat = openLayersProj.transform(view.getCenter(), "EPSG:3857", "EPSG:4326");
                    //console.log("Create / progressPublishClick - lnglat = ", lnglat);
                    mapCenter = {
                        latitude: lnglat[1],
                        longitude: lnglat[0]
                    };
                    mapType = "hybrid";
                }

                // Displacement and create map media switch
                const displacement = (this.mapSource === "google")?
                    calculateDisplacement(this.state.location, mapCenter, this.state.map) : [ 0.0, 0.0 ];
                const createMapMedia = (this.mapSource === "google");

                //console.log("Create / progressPublishClick - mapZoom = ", mapZoom);
                //console.log("Create / progressPublishClick - mapCenter = ", mapCenter);
                //console.log("Create / progressPublishClick - mapType = ", mapType);

                // Request data
                dataJSON = {
                    // Editor
                    editor_id: this.props.userInfo.id,
                    // Media
                    media_ids: mediaIDs,
                    media_overview: mediaOverview,
                    media_todos: mediaTodos,
                    media_history: mediaHistory,
                    media_stories: mediaStories,
                    // Object and dot type
                    type: dotType,
                     // Name
                    name: this.nameRef.current.value,
                    // Area
                    area: this.areaRef.current.value,
                    // Rating and physicality 
                    rating: this.state.rating,
                    physicality: (this.state.dotType === "DI")? null : this.state.physicality,
                    // Duration
                    duration: this.state.duration,
                    // Best time
                    time_type: timeType,
                    time_type_optional: timeTypeOptional,
                    // Hours
                    start_hour: startHour,
                    end_hour: endHour,
                    // Season closure
                    closure_estimate: seasonClosureEstimate,
                    closure_start_date: seasonClosureStart,
                    closure_end_date: seasonClosureEnd,
                    // Locations
                    location: this.state.location,
                    parking_location: parkingLocation,
                    //start_location: startLocation,
                    //end_location: (this.state.loop)? null : endLocation,
                    //start_location_name: startLocationName,
                    //end_location_name: (this.state.loop)? null : endLocationName,
                    // Drivable
                    drivable: drivable,
                    // Prefered transportation
                    public_transportation: publicTransportation,
                    // Roundtrip
                    //roundtrip: this.state.roundtrip,
                    // Loop
                    //loop: this.state.loop,
                    // Reversible
                    //reversible: this.state.reversible,
                    // State
                    state: this.state.state,
                    // Map zoom level and center
                    map_zoom: mapZoom,
                    map_center: mapCenter,
                    map_type: mapType,
                    map_source: this.mapSource,
                    map_displacement: displacement,
                    create_map_media: createMapMedia,
                    // Curation
                    title: this.titleRef.current.value,
                    overview: this.overviewRef.current.value,
                    todos: (this.todosRef.current.value.length === 0)? null : this.todosRef.current.value,
                    history: (this.historyRef.current.value.length === 0)? null : this.historyRef.current.value,
                    stories: (this.storiesRef.current.value.length === 0)? null : this.storiesRef.current.value,
                    // Connect
                    //child_ids: null,
                    //highlights: null
                    // Automatically approved
                    is_approved: (this.props.userInfo.create_level === 5)? true : false
                    // Public and save limit
                    //is_public: this.state.is_public,
                    //save_limit: this.saveLimits[this.state.saveLimit]
                };
            }
        }

        // Trip or route
        else if ((this.state.contributorModeOn === true) && ((this.state.dotType === "TR") || (this.state.dotType === "RT") || (this.state.dotType === "ST"))) {
            // Media
            const mediaIDs = [];
            const mediaOverview = [];
            const mediaTodos = [];
            const mediaHistory = [];
            const mediaStories = [];
            for (let i = 0; i < this.state.mediaKeys.length; i++) {
                const mediaKey = this.state.mediaKeys[i];
                const mediaID = this.state.mediaIDs[mediaKey];
                const mediaCategory = this.state.mediaCategories[this.state.mediaKeys[i]];

                // Media IDs
                mediaIDs.push(mediaID)

                // Categorize media IDs
                if (this.state.mediaCategories[mediaKey] === 0) {
                    mediaOverview.push(mediaID);
                }
                else if (mediaCategory === 1) {
                    mediaTodos.push(mediaID);
                }
                else if (mediaCategory === 2) {
                    mediaHistory.push(mediaID);
                }
                else if (mediaCategory === 3) {
                    mediaStories.push(mediaID);
                }
                else {
                    console.log("[WARNING] Create / progressPublishClick - wrong media category");
                }
            }

            // Check if all upload processes are complete
            let allUploadComplete = true;
            const keys = Object.keys(this.uploadProgress);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) {
                    allUploadComplete = false;
                }
            }

            // Check media
            if (allUploadComplete) {
                if (this.state.mediaKeys.length === 0) {
                    dataWarning = true;
                    mediaWarningOn = true;
                    mediaWarningMessage = this.mediaWarningMessage;
                }
                else {
                    if (mediaOverview.length === 0) {
                        dataWarning = true;
                        mediaWarningOn = true;
                        mediaWarningMessage = this.mediaWarningOverviewMessage;
                    }
                }
            }
            else {
                dataWarning = true;
                mediaWarningOn = true;
                mediaWarningMessage = this.mediaWarningUploadMessage;
            }

            // Check locations
            if ((this.state.startLocation === null) && ((!this.state.roundtrip && !this.state.loop) && (this.state.endLocation === null))) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationStartEndWarningMessage;
            }
            else if ((this.state.startLocation !== null) && (!this.state.roundtrip && !this.state.loop) && (this.state.endLocation === null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationEndWarningMessage;
            }
            else if ((this.state.startLocation === null) && (this.state.roundtrip || this.state.loop) && (this.state.endLocation !== null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationStartWarningMessage;
            }

            // Check area
            if ((this.areaRef.current.value === null) || (this.areaRef.current.value === "")) {
                dataWarning = true;
                areaWarningOn = true;
                areaWarningMessage = this.areaWarningMessage;
            }
            // Check title
            if ((this.titleRef.current.value === null) || (this.titleRef.current.value === "")) {
                dataWarning = true;
                titleWarningOn = true;
                titleWarningMessage = this.titleWarningMessage;
            }
            // Check overview
            if ((this.overviewRef.current.value === null) || (this.overviewRef.current.value === "")) {
                dataWarning = true;
                overviewWarningOn = true;
                overviewWarningMessage = this.overviewWarningMessage;
            }
            /*
            // Check todos
            if ((this.todosRef.current.value === null) || (this.todosRef.current.value === "")) {
                dataWarning = true;
                todosWarningOn = true;
                todosWarningMessage = "What-to-do Ideas to Fully Enjoy the Place";
            }
            // Check history
            if ((this.historyRef.current.value === null) || (this.historyRef.current.value === "")) {
                dataWarning = true;
                historyWarningOn = true;
                historyWarningMessage = "A Little History That Gives a Different Perspective";
            }
            // Check stories
            if ((this.storiesRef.current.value === null) || (this.storiesRef.current.value === "")) {
                dataWarning = true;
                storiesWarningOn = true;
                storiesWarningMessage = "Other Interesting Stories or Anecdotes";
            }
            */
            // Check connect
            if (this.state.selectedIDs.length === 0) {
                dataWarning = true;
                connectWarningOn = true;
                connectWarningMessage = this.connectDotWarningMessage;
            }
            else {
                if (this.state.highlightIDs.length === 0) {
                    dataWarning = true;
                    connectWarningOn = true;
                    connectWarningMessage = this.connectHighlightWarningMessage;
                }
            }

            // Issue warnings
            const stateToUpdate = {
                mediaWarningOn: mediaWarningOn,
                mediaWarningMessage: mediaWarningMessage,
                locationWarningOn: locationWarningOn,
                locationWarningMessage: locationWarningMessage,
                nameWarningOn: nameWarningOn,
                nameWarningMessage: nameWarningMessage,
                areaWarningOn: areaWarningOn,
                areaWarningMessage: areaWarningMessage,
                titleWarningOn: titleWarningOn,
                titleWarningMessage: titleWarningMessage,
                overviewWarningOn: overviewWarningOn,
                overviewWarningMessage: overviewWarningMessage,
                //todosWarningOn: todosWarningOn,
                //todosWarningMessage: todosWarningMessage,
                //historyWarningOn: historyWarningOn,
                //historyWarningMessage: historyWarningMessage,
                //storiesWarningOn: storiesWarningOn,
                //storiesWarningMessage: storiesWarningMessage, 
                connectWarningOn: connectWarningOn,
                connectWarningMessage: connectWarningMessage
            };

            // Update state
            this.setState(stateToUpdate, this.colorInputBorders);

            // Send request if no errors
            if (dataWarning === false) {
                //console.log("Create / progressPublishClick (trip) - state = ", state)

                // Drivable
                const drivable = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "undrivable")? false : true);

                // Public Transportation
                const publicTransportation = (this.state.everydayModeOn)? null :
                    (
                        (this.state.accessibilityMode === "undrivable")?
                            null :
                            (
                                (this.state.preferredMode === "public")? true : false
                            )
                    );

                // Hours
                let startHour = null;
                let endHour = null;
                if ((this.state.dotType === "RT") && (this.state.hoursOn)) {
                    //console.log("Create / progressPublishClick - this.state.startHour.meridiem = ", this.state.startHour.meridiem)
                    let startAddHour, endAddHour;

                    if ((this.state.startHour.meridiem === "pm") && (this.state.startHour.hour < 12)) {
                        startAddHour = 12;
                    }
                    else if ((this.state.startHour.meridiem === "am") && (this.state.startHour.hour === 12)) {
                        startAddHour = -12;
                    }
                    else {
                        startAddHour = 0;
                    }

                    if ((this.state.endHour.meridiem === "pm") && (this.state.endHour.hour < 12)) {
                        endAddHour = 12;
                    }
                    else if ((this.state.endHour.meridiem === "am") && (this.state.endHour.hour === 12)) {
                        endAddHour = -12;
                    }
                    else {
                        endAddHour = 0;
                    }

                    startHour = "" + pad(this.state.startHour.hour + startAddHour, 2, 0) + ":" + pad(this.state.startHour.minute, 2, 0);
                    endHour = "" + pad(this.state.endHour.hour + endAddHour, 2, 0) + ":" + pad(this.state.endHour.minute, 2, 0);
                }

                // Time types
                let timeType = null;
                let timeTypeOptional = null;
                if (this.state.bestTimeOn) {
                    if (this.state.bestTimeMode === "double") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                        timeTypeOptional = this.bestTimeTypes[this.state.bestTimeSecond];
                    }
                    else if (this.state.bestTimeMode === "single") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                    }
                    else if (this.state.bestTimeMode === "allday") {
                        timeType = "AD";
                    }
                    else {
                        console.log("[WARNING] Create / progressPublishClick - wrong best time mode");
                    }
                }

                // Seasonal information
                let seasonClosureEstimate = null;
                if (this.state.seasonClosureType === "open") {
                    seasonClosureEstimate = null;
                }
                else if (this.state.seasonClosureType === "estimate") {
                    seasonClosureEstimate = true;
                }
                else {
                    seasonClosureEstimate = false;
                }
                let seasonClosureStart = null;
                let seasonClosureEnd = null;
                if (this.state.seasonClosureType !== "open") {
                    seasonClosureStart = "" + pad(this.state.closureStartDate.year(), 4, 0) + "-" + pad(this.state.closureStartDate.month() + 1, 2, 0) + "-" + pad(this.state.closureStartDate.date(), 2, 0);
                    seasonClosureEnd = "" + pad(this.state.closureEndDate.year(), 4, 0) + "-" + pad(this.state.closureEndDate.month() + 1, 2, 0) + "-" + pad(this.state.closureEndDate.date(), 2, 0);
                }

                // Location
                const startLocation = this.state.startLocation;
                const startLocationName = this.state.startLocationName;
                let endLocation = null;
                let endLocationName = null;
                if (this.state.dotType === "RT" && (this.state.roundtrip || this.state.loop)) {
                    endLocation = null;
                    endLocationName = null;
                }
                else {
                    endLocation = this.state.endLocation;
                    endLocationName = this.state.endLocationName;
                }

                // Roundtrip / loop / reversible
                const roundtrip = (this.state.dotType === "RT")? this.state.roundtrip : null;
                const loop = (this.state.dotType === "RT")? this.state.loop : null;
                const reversible = (this.state.dotType === "RT")? this.state.reversible : null;

                // Route
                const route = (this.state.dotType === "RT")? this.state.selectedIDs : [];

                // Transit information
                const transitModes = (this.state.dotType === "RT")? this.state.transitModes : [];
                const transitTimes = (this.state.dotType === "RT")? this.state.transitDurations : [];
                const transitPhysicalities = (this.state.dotType === "RT")? this.state.transitPhysicalities : [];
                let transitDistances = [];
                if (this.state.dotType === "RT") {
                    for (let i = 0; i < this.state.transitDistances.length; i++) {
                        // Convert to meters
                        transitDistances.push(this.state.transitDistances[i] * 1.60934 * 1000);
                    }
                }
                else {
                    transitDistances = [];
                }

                // Truncate the last section if roundtrip
                if (this.state.roundtrip) {
                    transitModes.pop();
                    transitTimes.pop();
                    transitPhysicalities.pop();
                    transitDistances.pop();
                }

                // Map type
                let mapType = null;
                if (this.mapSource === "google") {
                    mapType = this.state.map.getMapTypeId();
                }
                else {
                    mapType = "hybrid";
                }

                // Request data
                dataJSON = {
                    // Editor
                    editor_id: this.props.userInfo.id,
                    // Images
                    media_ids: mediaIDs,
                    media_overview: mediaOverview,
                    media_todos: mediaTodos,
                    media_history: mediaHistory,
                    media_stories: mediaStories,
                    // Object and dot type
                    type: this.state.dotType,
                    // Name
                    //name: this.nameRef.current.value,
                    // Area
                    area: this.areaRef.current.value,
                    // Rating and physicality 
                    rating: this.state.rating,
                    //physicality: this.state.physicality,
                    // Duration
                    duration: null,
                    // Best time
                    time_type: timeType,
                    time_type_optional: timeTypeOptional,
                    // Hours
                    start_hour: startHour,
                    end_hour: endHour,
                    // Season closure
                    closure_estimate: seasonClosureEstimate,
                    closure_start_date: seasonClosureStart,
                    closure_end_date: seasonClosureEnd,
                    // Locations
                    //location: location,
                    //parking_location: this.state.parkingLocation,
                    start_location: startLocation,
                    end_location: endLocation,
                    start_location_name: startLocationName,
                    end_location_name: endLocationName,
                    // Drivable
                    drivable: drivable,
                    // Prefered transportation
                    public_transportation: publicTransportation,
                    // Roundtrip
                    roundtrip: roundtrip,
                    // Loop
                    loop: loop,
                    // Reversible
                    reversible: reversible,
                    // State
                    //state: this.state.state,
                    // Map zoom level and center
                    //map_zoom: mapZoom,
                    //map_center: mapCenter,
                    map_type: mapType,
                    map_source: this.mapSource,
                    //map_displacement: displacement,
                    create_map_media: false,
                    // Curation
                    title: this.titleRef.current.value,
                    overview: this.overviewRef.current.value,
                    todos: (this.todosRef.current.value.length === 0)? null : this.todosRef.current.value,
                    history: (this.historyRef.current.value.length === 0)? null : this.historyRef.current.value,
                    stories: (this.storiesRef.current.value.length === 0)? null : this.storiesRef.current.value,
                    // Connect
                    child_ids: this.state.selectedIDs,
                    highlights: this.state.highlightIDs,
                    route: route,
                    // Route
                    transit_time: transitTimes,
                    transit_distance: transitDistances,
                    transit_physicality: transitPhysicalities,
                    transit_mode: transitModes,
                    // Automatically approved
                    is_approved: (this.props.userInfo.create_level === 5)? true : false
                    // Public and save limit
                    //is_public: this.state.is_public,
                    //save_limit: this.saveLimits[this.state.saveLimit]
                };
                //console.log("Create / progressPublishClick - dataJSON = ", dataJSON);
            }
        }
        else {
            console.log("[WARNING] Create / progressPublishClick - wrong object type");
        }
        //console.log("Create / progressPublishClick - dataJSON = ", dataJSON);

        // Send create request
        if (dataWarning === false) {
            // Get response and update states
            const axiosCallback = (response) => {
                // console.log("Create / progressPublishClick - response.data.content = ", response.data.content);
                const firstState = {
                    completeModalOn: true,
                    objectSlug: response.data.content.slug
                };

                const secondState = {
                    completeModalOpacity: 1.0
                };

                // Set state chain
                updateStateChain2(this.setState, firstState, secondState, null, this.transitionTime);
            };

            // Send create request
            postDot(dataJSON)
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] Create / progressPublishClick - error = ", response);});
        }
    }


    getState(progressPublishClick) {
        // If location is set
        if (this.state.location !== null) {
            // Geocode
            const geocoder = new this.props.google.maps.Geocoder();

            geocoder.geocode({"location": pointToLatLng(this.state.location)}, function(results, status) {
                if (status === "OK") {

                    let state = null;

                    for (let i=0; i < results[0].address_components.length; i++) {
                        if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
                            state = results[0].address_components[i].short_name;
                            //console.log("Create / getState - state = ", state);
                        }
                    }

                    if (state === null) {
                        state = results[0].address_components[0].short_name;
                    }

                    if ((progressPublishClick) && (typeof progressPublishClick === "function")) {
                        progressPublishClick(state);
                    }
                }
            });
        }
        // When location is not set
        else {
            progressPublishClick(null);
        }
    }

    completeButtonClick() {
        if (!this.state.isPublic || 
            (this.state.isPublic && this.state.saveLimit < (this.saveLimits.length - 1))) {
            // Submit the content to editorial team
            const axiosCallback = (response) => {
                //console.log("Create / completeButtonClick - response.data.content = ", response.data.content);
            };

            const dataJSON = {
                is_public: this.state.isPublic,
                save_limit: this.saveLimits[this.state.saveLimit]
            };
            //console.log("Create / completeButtonClick - dataJSON = ", dataJSON);

            patchDot(this.state.objectSlug, dataJSON)
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] Create / completeButtonClick - error = ", response);});
        }
    }

    scrollToTop() {
        // Scroll to the top
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }    
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        google: state.map.google
    };
}

export default connect(mapStateToProps, null)(Create);