/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    WebSocketFunctions.js
    - WebSocket related functions
--------------------------------------------------------------------------------------------
    Content
    - openWebSocket
    - closeWebSocket
    - addWebSocketGroups
    - webSocketOnMessage 
============================================================================================
*/

/*
============================================================================================
    openWebSocket 
    - Needs to be bound to App.js
============================================================================================
*/    

// Websocket
import { webSocketUrl } from "requests";


function openWebSocket(onMessageCallback) {
    // Define API endpoints for WebSocket to access
    let webSocket = new WebSocket(webSocketUrl());

    // Console message during connection
    if (webSocket.readyState === WebSocket.CONNECTING) {
        //console.log("WebsocketFunctions / openWebSocket - connecting websocket");
    }
    // WebSocket connection is asynchrnous and the manual recommends creating handlers
    // before sending messages to make sure connections is established 
    // (https://developer.mozilla.org/en-US/docs/Web/API/WebSockets_API/Writing_WebSocket_client_applications#Sending_data_to_the_server)

    // Handler for opening connection
    webSocket.onopen = (e) => {
        console.log("webSocket / openWebSocket[open] - e = ", e);

        if (webSocket.readyState === WebSocket.OPEN) {
            this.setState(
                {
                    webSocketReady: true
                },
                () => { 
                    console.log("webSocket / openWebSocket - webSocketReady"); 
                }
            );
        }
    }

    // Handler for closing connection
    webSocket.onclose = (e) => {
        console.log("webSocket / openWebSocket[close] - e = ", e);
    }

    // Handler for an error
    webSocket.onerror = (e) => {
        console.log("webSocket / openWebSocket[error] - e = ", e);
    }

    // Handler for a new message
    webSocket.onmessage = onMessageCallback;

    // return webSocket object
    return webSocket;
}


/*
============================================================================================
    closeWebSocket
============================================================================================
*/

function closeWebSocket() {
    if (window.webSocket != null) {
        window.webSocket.close();
    }
}


/*
============================================================================================
    addWebSocketGroups
============================================================================================
*/

function addWebSocketGroups(webSocketGroups) {
    // Create add groups request
    const message = JSON.stringify({
        request_type: "add_groups",
        groups: webSocketGroups
    });

    //console.log("WebSocketFunctions / addWebSocketGroups - message = ", message);
    window.webSocket.send(message);
}


function removeWebSocketGroups(webSocketGroups) {
    // Create add groups request
    const message = JSON.stringify({
        request_type: "remove_groups",
        groups: webSocketGroups
    });

    //console.log("WebSocketFunctions / removeWebSocketGroups - message = ", message);
    window.webSocket.send(message);
}


/*
============================================================================================
    webSocketOnMessage 
    - Needs to be bound to App.js
============================================================================================
*/

function webSocketOnMessage (e) {
    //console.log("webSocket / webSocketOnMessage - data = ", e.data);

    // Get the new message
    const receivedMessage = JSON.parse(e.data);
    
    // If the message is for chats
    if (receivedMessage.message.type === "chat") {
        //console.log("WebsocketFunctions / webSocketOnMessage - message = ", receivedMessage.message);
        const newChat = {
            type: receivedMessage.message.chat_type,
            operation: receivedMessage.message.operation,
            info: receivedMessage.message.chat_info
        };

        // Store into Redux state
        this.props.storeNewChat(newChat);
    }
    else if (receivedMessage.message.type === "chat_message") {
        //console.log("WebsocketFunctions / webSocketOnMessage - message = ", receivedMessage.message);
        const userID = receivedMessage.message.chat_message_info.user;
        const content = receivedMessage.message.chat_message_info.content;

        // Update only when there is content
        if ((userID !== undefined) && (userID !== null) && (content !== undefined)) {
            console.log("WebsocketFunctions / sending new chat message = ", receivedMessage.message.chat_message_info);
    
            // Store into Redux state
            this.props.storeNewChatMessage(receivedMessage.message.chat_message_info);
        }
    }
    // If the message is for notifications
    else if (receivedMessage.message.type === "notification") {
        //console.log("WebsocketFunctions / webSocketOnMessage - message = ", receivedMessage.message);
        const userID = receivedMessage.message.notification_info.user.id;
        const code = receivedMessage.message.notification_info.code;

        // Update only when there is content
        if ((userID !== undefined) && (userID !== null) && (code !== undefined)) {
            // Store into Redux state
            this.props.storeNewNotification(receivedMessage.message.notification_info);
        }
    }
    // If the message is a board comment
    else if (receivedMessage.message.type === "dot_board_comment") {
        //console.log("WebsocketFunctions / webSocketOnMessage - message = ", receivedMessage.message);
        const userID = receivedMessage.message.board_comment_info.user.id;
        const content = receivedMessage.message.board_comment_info.content;

        // Update only when there is content
        if ((userID !== undefined) && (userID !== null) && (content !== undefined)) {
            // Store into Redux state
            this.props.storeNewComment(receivedMessage.message.board_comment_info);
        }
    }    
    // If the message is a board reply
    else if (receivedMessage.message.type === "dot_board_reply") {
        //console.log("WebsocketFunctions / webSocketOnMessage - message = ", receivedMessage.message);
        const userID = receivedMessage.message.board_reply_info.user.id;
        const content = receivedMessage.message.board_reply_info.content;

        // Update only when there is content
        if ((userID !== undefined) && (userID !== null) && (content !== undefined)) {
            // Store into Redux state
            this.props.storeNewReply(receivedMessage.message.board_reply_info);
        }
    }
    // If the message is an itinerary guest
    else if (receivedMessage.message.type === "itinerary_guest") {
        //console.log("WebsocketFunctions / webSocketOnMessage - message = ", receivedMessage.message);
        const itineraryInfo = receivedMessage.message.itinerary_info;
        const operation = receivedMessage.message.operation;
        const userInfo = receivedMessage.message.user_info;

        // Update only when there is content
        if ((itineraryInfo !== undefined) && (itineraryInfo !== null) && (userInfo !== undefined)) {
            // Create a new participant object
            const newParticipant= {};
            newParticipant["itineraryID"] = itineraryInfo.id;
            newParticipant["operation"] = operation;
            newParticipant["user"] = userInfo;

            // Store into Redux state
            this.props.storeNewParticipant(newParticipant);
        }
    }
    // If the message is a new itinerary
    else if (receivedMessage.message.type === "calendar") {
        //console.log("WebsocketFunctions / webSocketOnMessage - message = ", receivedMessage.message);
        const itineraryInfo = receivedMessage.message.itinerary_info;
        const operation = receivedMessage.message.operation;

        // Update only when there is content
        if ((itineraryInfo !== undefined) && (itineraryInfo !== null)) {
            // Store into Redux state
            this.props.storeNewItinerary({ operation: operation, info: itineraryInfo });
        }
    }
}


export {
    openWebSocket,
    closeWebSocket,
    addWebSocketGroups,
    removeWebSocketGroups,
    webSocketOnMessage
};