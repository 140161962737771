import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import { getStaticPath } from "js/Functions";

// Redux
import { storeNotificationAlert } from "actions";

// CSS
import "./NotificationAlert.scss";


class NotificationAlert extends Component {
    constructor(props) {
        super(props);

        this.clearAlert = this.clearAlert.bind(this);
    }

    clearAlert() {
        this.props.storeNotificationAlert({ message: null, on: false });
    }

    render() {
        if (this.props.notificationAlertInfo.on) {
            return (
                <div className = {(this.props.colorMode === "day")?
                    "alert-notification alert-notification-day" :
                    "alert-notification alert-notification-night"}
                >
                    <div className = "alert-notification__message">
                        <span className = {(this.props.colorMode === "day")?
                            "w4" : "w4"}>
                            {this.props.notificationAlertInfo.message}
                        </span>
                    </div>
                    <div className = "alert-notification__cancel">
                        <div className = "alert-notification__cancel-image image-button-weak-s7"
                            style = {{ backgroundImage:
                                (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/cross-white.png") :
                                    getStaticPath("/images/common/cross-white.png")
                            }}
                            onClick = { this.clearAlert }
                        >
                        </div>
                    </div>
                </div> 
            );
        } 
        else {
            return null;
        }        
    }
}

function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        notificationAlertInfo: state.alert.notificationAlertInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeNotificationAlert }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAlert); 