/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    LogInSimple.js
    - Log in page with two different log in modes
        (1) Facebook authentication
        (2) Google authentication
--------------------------------------------------------------------------------------------
    Content
    LogInSimple
============================================================================================
 */


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux"; 

// Redux
import {
    storeColorMode,
    storeUser,
    storeLogInSimpleOn,
    storeItinerary,
    storeSaveItinerary
} from "actions";

// Modules
import ReactTooltip from "thedots-tooltip";

// Functions
import {
    logIn,
    logInFacebook,
    logInGoogle
} from "js/AuthFunctions";

import {
    getStaticPath
} from "js/Functions";

// Axios 
import {
    getUserLogin,
    postUser,
    postSaveItinerary
} from "requests";

// CSS
import "./LogInSimple.scss"; 

// Response codes
import {
    responseResultCodes
} from "ResponseCodes";


// Log in component
class LogIn extends Component {
    constructor (props) {
        super(props);

        // Transition and delay times
        this.transitionTime = 500;
        this.delayTime = 1000;

        // Input warning timers
        this.warning = null;
        this.facebookGoogleWarning = null;

        // Initialize state
        this.state = {
            // Warning display
            warningOn: false,
            warningMessage: null,

            // Warning display
            facebookGoogleWarningOn: false,
            facebookGoogleWarningMessage: null,
        };

        // Temporary profile data and sign up mode
        this.email = null;
        this.firstName = null;
        this.lastName = null;
        this.profilePicURL = null;
        this.signUpMode = null;

        // Bind callbacks
        this.logInFacebookSuccess = this.logInFacebookSuccess.bind(this);
        this.logInFacebookFailure = this.logInFacebookFailure.bind(this);
        this.logInGoogleSuccess = this.logInGoogleSuccess.bind(this);
        this.logInGoogleFailure = this.logInGoogleFailure.bind(this);
        this.signUpClick = this.signUpClick.bind(this);
        this.submitFacebookGoogle = this.submitFacebookGoogle.bind(this);
        this.signUpSuccess = this.signUpSuccess.bind(this);
        this.cancelLogIn = this.cancelLogIn.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.escClick = this.escClick.bind(this);
        this.saveItinerary = this.saveItinerary.bind(this);
        this.logInSuccess = this.logInSuccess.bind(this);
    }

    logInGoogleFailure(error) {
        //console.log("[WARNING] LogIn / logInGoogleFailure - error = ", error);

        // Show Facebook Google log in warning
        this.facebookGoogleShowWarning("Provided Google credentials do not match an existing account.");
    }

    logInGoogleSuccess(authResponse, profileData) {
        //console.log("LogInSimple / logInGoogleSuccess - authResponse = ", authResponse);
        //console.log("LogInSimple / logInGoogleSuccess - profileData = ", profileData);

        // Authorize user
        logIn(
            profileData.email,
            null,
            authResponse.idToken,
            "google",
            profileData.profile_pic,
            (loggedIn, userInfo) => {
                //console.log("LogInSimple / logInGoogleSuccess - profileData = ", profileData);
                //console.log("LogInSimple / logInGoogleSuccess - loggedIn = ", loggedIn);
                //console.log("LogInSimple / logInGoogleSuccess - userInfo = ", userInfo);

                // If log in was successful
                if (loggedIn === true && userInfo !== null) {
                    //console.log("LogInSimple / logInGoogleSuccess - Success");

                    // If temporary itinerary exists
                    if (this.props.saveItinerary !== null) {
                        this.saveItinerary(userInfo);
                    }
                    else {
                        // Log in success
                        this.logInSuccess(userInfo);
                    }
                }
                // If unsuccessful
                else {
                    //console.log("LogInSimple / logInGoogleSuccess - Failure");

                    // Save profile data
                    this.email = profileData.email;
                    this.firstName = profileData.first_name;
                    this.lastName = profileData.last_name
                    this.profilePicURL = profileData.profile_pic;
                    this.signUpMode = "google";

                    // Clear warning
                    this.clearWarning(false);

                    // Show google warning
                    this.facebookGoogleShowWarning("Provided Google credentials do not match an existing account.");
                }
            }
        );
    }

    logInFacebookFailure() {
        //console.log("[WARING] LogIn / logInFacebookFailure");

        // Show Facebook Google log in warning
        this.facebookGoogleShowWarning("Provided Facebook credentials do not match an existing account.");
    }

    logInFacebookSuccess(authResponse, profileData) {
        //console.log("[WARING] LogIn / logInFacebookSuccess");
        //console.log("[WARING] LogIn / logInFacebookSuccess - authResponse = ", authResponse);
        //console.log("[WARING] LogIn / logInFacebookSuccess - profileData = ", profileData);
        
        // Save contenxt
        const that = this;

        // Authorize user
        logIn(
            profileData.email,
            null,
            authResponse.accessToken,
            "facebook",
            profileData.picture.data.url,
            (loggedIn, userInfo) => {
                //console.log("LogInSimple / logInFacebookSuccess - loggedIn = ", loggedIn);
                //console.log("LogInSimple / logInFacebookSuccess - userInfo = ", userInfo);

                // If log in was successful
                if (loggedIn === true && userInfo !== null) {
                    //console.log("LogInSimple / logInFacebookSuccess - Success");

                    // If temporary itinerary exists
                    if (this.props.saveItinerary !== null) {
                        this.saveItinerary(userInfo);
                    }
                    else {
                        // Log in success
                        this.logInSuccess(userInfo);
                    }
                }
                // If unsuccessful
                else {
                    //console.log("LogInSimple / logInFacebookSuccess - Failure");

                    // Save profile data
                    console.log("LogInSimple / logInFacebookSuccess - profileData = ", profileData);
                    this.email = profileData.email;
                    this.firstName = profileData.first_name;
                    this.lastName = profileData.last_name
                    this.profilePicURL = profileData.picture.data.url;
                    this.signUpMode = "facebook";

                    // Clear log in warning
                    that.clearWarning(false);

                    // Show log in warning
                    that.facebookGoogleShowWarning("Provided Facebook credentials do not match an existing account.");
                }
            }
        );
    }

    // Cancel and close log in menu
    cancelLogIn(callback) {
        //console.log("LogInSimple / cancelLogIn");

        // Dispatch state to Redux store
        this.props.storeLogInSimpleOn(false);

        // Reset the warnings / input values / styles
        window.setTimeout(
            () => {
                // Reset warnings and input styles
                this.resetForm();              
            },
            this.transitionTime
        );
    }

    resetForm() {
        //console.log("LogInSimple / resetForm");

        // Input warning timers
        this.warning = null;
        this.facebookGoogleWarning = null;

        // Update state and input borders
        this.setState(
            {
                warningOn: false,
                warningMessage: null,
                facebookGoogleWarningOn: false,
                facebookGoogleWarningMessage: null
            }
        );
    }

    escClick(event) {
        // Close log in on pressed esc key
        if (this.props.logInSimpleOn === true && event.keyCode === 27 && !!localStorage.token === false) 
        {
            this.cancelLogIn();
        }
    }

    facebookGoogleShowWarning(warningMessage) {
        //console.log("LogInSimple / facebookGoogleShowWarning");

        // Clear Facebook or Google warning timer
        clearTimeout(this.facebookGoogleWarning);

        // Update state
        this.facebookGoogleWarning = setTimeout(
            this.setState.bind(
                this,
                {
                    facebookGoogleWarningOn: true,
                    facebookGoogleWarningMessage: warningMessage
                },
                null
            ),
            this.delayTime
        );
    }

    clearWarning(delay) {
        // Only when the warning is on
        if (this.state.warningMessage !== null) {
            //console.log("LogInSimple / clearWarning");

            // Clear log in warning
            clearTimeout(this.warning);

            // Delay time
            const delayTime = delay? this.delayTime : 0;

            // Update state
            this.warning = setTimeout(
                this.setState.bind(
                    this,
                    {
                        warningOn: false,
                        warningMessage: null
                    }
                ),
                delayTime
            );
        }
    }

    facebookGoogleClearWarning(delay) {
        // Only when the warning is on
        if (this.state.facebookGoogleWarningMessage !== null) {
            //console.log("LogInSimple / facebookGoogleClearWarning");

            // Clear Facebook or Google log in warning timer
            clearTimeout(this.facebookGoogleWarning);

            // Delay time
            const delayTime = delay? this.delayTime : 0;

            // Update state
            this.facebookGoogleWarning = setTimeout(
                this.setState.bind(
                    this,
                    {
                        facebookGoogleWarningOn: false,
                        facebookGoogleWarningMessage: null
                    },
                    null
                ),
                delayTime
            );
        }
    }

    saveItinerary(userInfo) {
        if (this.props.saveItinerary !== null) {
            //console.log("LogInSimple / saveItinerary - this.props.saveItinerary = ", this.props.saveItinerary);

            // Get user info and update states
            const axiosCallback = (response) => {
                //console.log("LogInSimple / saveItinerary - response.data.content = ", response.data.content);

                // Clear save itinerary
                this.props.storeSaveItinerary(null);

                // Dispatch real itinerary to the redux store
                this.props.storeItinerary(response.data.content);

                // Navigate to the saved itinerary
                this.props.history.push(`/itinerary/${response.data.content.id}`);

                // Log in success
                this.logInSuccess(userInfo);
            };

            // Temporary authentication to go through permissions
            window.axios.defaults.headers.common["Authorization"] = " Token " + localStorage.token;
            window.axiosCSRF.defaults.headers.common["Authorization"] = " Token " + localStorage.token;

            // Request data
            const dataJSON = {
                "itinerary_id": this.props.saveItinerary.id
            };
            //console.log("LogInSimple / saveItinerary - dataJSON = ", dataJSON);

            // Send find request
            postSaveItinerary(dataJSON)
            .then(axiosCallback)
            .catch(
                (response) => {
                    console.log("[WARNING] LogInSimple / saveItinerary - error = ", response);
                }
            );
        }
    }

    logInSuccess(userInfo) {
        // Dispatch user info to the Redux store
        this.props.storeUser(userInfo);

        // Store color mode
        this.props.storeColorMode(userInfo.color_mode);

        // Change background color
        if (userInfo.color_mode === "day") {
            // Change body style
            document.body.style.backgroundColor = window.colorAlmostWhite;
        }
        else {
            // Change body style
            document.body.style.backgroundColor = window.colorBlack;
        }

        // Update unseen counts
        //this.props.storeUnseenChatCount(userInfo.unseen_chat_count);
        //this.props.storeUnseenNotificationCount(userInfo.unseen_notification_count);

        // Reset form and cancel log in
        this.cancelLogIn();

        // Clear Facebook Google log in warning
        this.facebookGoogleClearWarning(false);

        // Clear warning
        this.clearWarning(false);        
    }

    render () {
        //console.log("LogInSimple / render - this.props = ", this.props);
        //console.log("LogInSimple / render - this.props.saveItinerary = ", this.props.saveItinerary);

        // Log in display properties
        const logInStyle = {
            display : (this.props.logInSimpleOn === true)? "block" : "none",
        };

        // Button images
        const facebookImage = getStaticPath("/images/common/facebook.png");
        const googleImage =  getStaticPath("/images/common/google.png");
        
        const cancelButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") : 
            getStaticPath("/images/common/cancel-white.png");

        return (
            <div>
                <div id = "log-in-simple-modal-container"
                    style = { logInStyle }
                >
                    <div id = {(this.props.browserWidth <= 6)?
                            "log-in-simple-modal-mobile" : "log-in-simple-modal"}
                        className = {(this.props.colorMode === "day")? 
                            "modal-day" : "modal-night"}
                    >
                        <div id = "log-in-simple-modal__cancel"
                            className = {(this.props.colorMode === "day")? 
                                "image-button-weak-s3" : "image-button-s3"}
                            style = {{ backgroundImage : cancelButtonImage }}
                            onClick = {this.cancelLogIn}
                        >
                        </div>

                        <div id = "log-in-simple-modal__title"
                            className = {(this.props.colorMode === "day")? 
                            "k2" : "w2"}
                        >
                            Member Log In
                        </div>

                        <div className = "log-in-simple-row">
                            <div id = "log-in-simple-facebook-container">
                                <div id = "log-in-simple-facebook-button">
                                    <div
                                        data-tip data-for = "log-in-simple-facebook-image"
                                        id = "log-in-simple-facebook-image"
                                        className = {(this.props.colorMode === "day")?
                                            "image-button-strong-reverse-base" : "image-button-base"}
                                        style = {{ backgroundImage : facebookImage }}
                                        onClick = {logInFacebook.bind(null, this.logInFacebookSuccess, this.logInFacebookFailure, this.props.userProfilePicSize)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <ReactTooltip 
                                id = "log-in-simple-facebook-image" 
                                className = "log-in-simple-facebook-image-tooltip tooltip-s2"
                                type = "dark" 
                                place = "right"
                            >
                                <span>Log In Using Facebook</span>
                            </ReactTooltip>
                            <div id = "log-in-simple-google-container">
                                <div
                                    data-tip data-for = "log-in-simple-google-image"
                                    id = "log-in-simple-google-image"
                                    className = {(this.props.colorMode === "day")?
                                        "image-button-strong-reverse-base" : "image-button-base"}
                                    style = {{ backgroundImage : googleImage }}
                                    onClick = {logInGoogle.bind(null, this.logInGoogleSuccess, this.logInGoogleFailure, this.props.userProfilePicSize)}
                                >
                                </div>
                            </div>
                            <ReactTooltip 
                                id = "log-in-simple-google-image" 
                                className = "log-in-simple-google-image-tooltip tooltip-s2"
                                type = "dark" 
                                place = "right"
                            >
                                <span>Log In Using Google</span>
                            </ReactTooltip>
                        </div>

                        <div id = "log-in-simple-facebook-google-warning"
                            className = {(this.props.colorMode === "day")?
                                "warning-day-s2" : "warning-night-s2"}
                            style = {{ display: (this.state.facebookGoogleWarningOn)? "block" : "none" }}
                        >
                            {this.state.facebookGoogleWarningMessage}
                        </div>

                        <div id = "log-in-simple-warning"
                            className = {(this.props.colorMode === "day")?
                                "warning-day-s2" : "warning-night-s2"}
                            style = {{ 
                                display: (this.state.warningOn)? "block" : "none",
                            }}
                        >
                            {this.state.warningMessage}
                        </div>

                        {
                            (this.state.facebookGoogleWarningOn)? (
                                <div id = "log-in-simple-sign-up"
                                    className = {(this.props.colorMode === "day")? "k4" : "w4"} 
                                    onClick = {this.signUpClick}
                                >
                                    Sign Up
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escClick, false);
    }

    componentDidMount() {
        // grab the DOM nodes of inputs
        document.addEventListener("keydown", this.escClick, false);
    }

    signUpClick() {
        this.submitFacebookGoogle(
            this.email,
            this.firstName,
            this.lastName,
            this.profilePicURL,
            this.signUpMode
        );
    }

    submitFacebookGoogle(email, firstName, lastName, profilePicURL, signUpMode) {
        // Construct json data
        const dataJSON = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            profile_pic_external_url: profilePicURL
        };
        //console.log("SignUp / submitFacebookGoogle - dataJSON = ", dataJSON);

        // Axios callback
        // Execute when the server returns a response
        const axiosCallback = (response) => {
            console.log("SignUp / submitFacebookGoogle - response = ", response);

            // If sign up was successful
            if (response.data.status.code === responseResultCodes.OK) {
                // execute signup callback
                this.signUpSuccess(response, this.resetForm);
            }
            // If sign up was unsuccessful
            // TODO: need to make it more intelligent
            else {
                if (signUpMode === "facebook") {
                    // Show warning
                    this.facebookGoogleShowWarning("An account already exists with the provided Facebook account.")
                }
                else {
                    // Show warning
                    this.facebookGoogleShowWarning("An account already exists with the provided Google account.")                    
                }
            }
        };

        const axiosErrorCallback = (response) => {
            //console.log("SignUp / submitFacebookGoogle - error response = ", response);

            // Show warning
            this.facebookGoogleShowWarning("An account already exists with the provided email address.")
        };

        // Send sign up request using axios post with CSRF token
        postUser(dataJSON)
        .then(axiosCallback)
        .catch(axiosErrorCallback);
    }

    // General signup success callback
    signUpSuccess(response, cancelCallback) {
        //console.log("SignUp / signUpSuccess - response = ", response);

        // Save token to localStorage
        const token = response.data.content.token;
        localStorage.token = token;

        // Set the axios headers with the access token
        window.axios.defaults.headers.common["Authorization"] = " Token " + token;
        window.axiosCSRF.defaults.headers.common["Authorization"] = " Token " + token;

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("SignUp / signUpSuccess - login response = ", response);
            const userInfo = response.data.content;
            //console.log("SignUp / SignUpSuccess - userInfo = ", userInfo);

            // If temporary itinerary exists
            if (this.props.saveItinerary !== null) {
                this.saveItinerary(userInfo);
            }
            else {
                // Dispatch to Redux store
                this.props.storeUser(userInfo);
                this.props.storeLogInSimpleOn(false);

                // Reset form
                this.resetForm();
            }
        };

        // Send user info request using axios post with CSRF token
        getUserLogin()
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] SignUp / SignUpSuccess - axios error = ", response);});
    }
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        logInSimpleOn: state.log_in.logInSimpleOn,
        userInfo: state.user.userInfo,
        saveItinerary: state.itinerary.saveItinerary
    };
}

// Dispatch to Redux store
function mapDispatchToProps (dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeColorMode,
            storeLogInSimpleOn,
            storeItinerary,
            storeSaveItinerary
        },
        dispatch
    );
}
// Export component
export default connect(mapStateToProps, mapDispatchToProps)(LogIn); 