// React / ReactDOM / React-router
import React from "react";
import { connect } from "react-redux"; 

// CSS
import "./UploadIndicator.scss";


function UploadIndicator (props) {
    //console.log("UploadIndicator / render - props = ", props);

    const uploadIndicatorTitle = (props.uploadError)? 
        "Upload Error" :
        (
            (props.uploadComplete === true)? 
                "Processing" : "Uploading"
        );

    return (
        <div key = {"upload-indicator-upload-" + props.uploadPercentage + "-process-" + props.uploadProcessPercentage}
            className = "upload-indicator"
            style = {{
                display: (props.uploadComplete === true && props.uploadProcessComplete === true)? 
                "none" : "block"
            }}
        >
            <div className = {(props.uploadError)? 
                (
                    (props.colorMode === "day")?
                        "upload-indicator-title lr5" :
                        "upload-indicator-title r5"
                ) : (
                    (props.colorMode === "day")?
                        "upload-indicator-title k5" :
                        "upload-indicator-title w5"
                )}
            >
                {uploadIndicatorTitle}
            </div>
            <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                "upload-indicator-progress-slider-container-mobile-day" :
                                "upload-indicator-progress-slider-container-mobile-night"
                        ) : (
                            (props.colorMode === "day")?
                                "upload-indicator-progress-slider-container-day" :
                                "upload-indicator-progress-slider-container-night"
                        )
                }
            >
                <div className = {(props.uploadError)? 
                    (
                        (props.colorMode === "day")?
                            "upload-indicator-progress-slider-error-day" : 
                            "upload-indicator-progress-slider-error-night"
                    ) : (
                        (props.colorMode === "day")?
                            "upload-indicator-progress-slider-day" : 
                            "upload-indicator-progress-slider-night"
                    )}
                    style = {{
                        width : (props.uploadComplete === true)?
                            Math.min(100, props.uploadProcessPercentage) + "%" :
                            Math.min(100, props.uploadPercentage) + "%"
                    }}
                >
                </div>
            </div>
            <div className = {(props.uploadError)? 
                (
                    (props.colorMode === "day")?
                        "upload-indicator-progress-percentage number-lr5" :
                        "upload-indicator-progress-percentage number-r5"
                ) : (
                    (props.colorMode === "day")?
                        "upload-indicator-progress-percentage number-lb5" :
                        "upload-indicator-progress-percentage number-b5"
                )}
            >
                {
                    (props.uploadComplete === true)?
                    Math.min(100, props.uploadProcessPercentage) + "%" : 
                    Math.min(100, props.uploadPercentage) + "%"
                }
            </div>
        </div>
    );
}



function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode
    };
}

export default connect(mapStateToProps, null)(UploadIndicator); 
