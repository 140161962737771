// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import {
    initCustomMarker,
    customMarker
} from "./CustomMarker";

// Functions
import {
    getStaticPath,
    getMediaProperty,
    sortMedia
} from "js/Functions";

import {
    getMapStyles,
    pointToLatLng,
    //latLngToPoint,
    //pointsEqual,
    //getAverageLocation
} from "./MapFunc";

// CSS
import "./SearchMap.scss";


class SearchMap extends Component {
    constructor(props) {
        super(props);

        // Initialize map object
        this.map = null;

        // DOM node
        this.mapRef = React.createRef();


        // Initialize state
        this.state = {
            // Map height
            mapHeight: props.mapHeight,
            mapMinHeight: props.mapMinHeight,
            mapMaxHeight: props.mapMaxHeight,
            mapHeightIncrement: props.mapHeightIncrement,

            // User location
            userLocation: null,

            // Markers
            markers: [],
            childMarkers: [],

            // Polygon
            polygon: null
        };

        // Bind initialize map function
        this.setState = this.setState.bind(this);
        this.initMap = this.initMap.bind(this);
        this.panToCenter = this.panToCenter.bind(this);
        this.panToDot = this.panToDot.bind(this);
        this.setMapHeight = this.setMapHeight.bind(this);
        this.zoomToFit = this.zoomToFit.bind(this);
        this.zoomToFitChildren = this.zoomToFitChildren.bind(this);
        this.resetMarkers = this.resetMarkers.bind(this);
        this.clearMarkers = this.clearMarkers.bind(this);
        this.resetChildMarkers = this.resetChildMarkers.bind(this);
        this.clearChildMarkers = this.clearChildMarkers.bind(this);
        this.resetPolygon = this.resetPolygon.bind(this);
        this.clearPolygon = this.clearPolygon.bind(this);
    }

    render() {
        //console.log("SearchMap / render - this.props = ", this.props);
        //console.log("SearchMap / render - this.state = ", this.state);

        return (
            <div id = "search-map-container"
                style = {{
                    width: this.props.mapContainerWidth
                }}
            >
                <div id = "search-map-left-column"
                    style = {{
                        width: this.props.leftColumnWidth,
                    }}
                >
                </div>
                <div ref = {this.mapRef}
                    id = {(this.props.browserWidth <= 6)?
                        "search-map-small" : "search-map"}
                    className = "search-map"
                    style = {{
                        width: this.props.mapWidth,
                        height: this.state.mapHeight
                    }}
                >
                </div>
            </div>
        )
    }

    componentDidMount() {
        //console.log("SearchMap / componentDidMount");

        // Initialize custom marker prototype
        initCustomMarker(this.props.google);

        // Initialize map
        this.initMap();
    }

    componentDidUpdate(prevProps, prevState) {
        // Center location changed
        if (JSON.stringify(prevProps.mapCenter) !== JSON.stringify(this.props.mapCenter)) {
            //console.log("SearchMap / componentDidUpdate - mapCenter changed");
            //console.log("SearchMap / componentDidUpdate - prevProps.mapCenter = ", prevProps.mapCenter);
            //console.log("SearchMap / componentDidUpdate - this.props.mapCenter = ", this.props.mapCenter);

            // Center map
            this.panToCenter();
        }

        // Dots info changed
        if (JSON.stringify(prevProps.dotsInfo) !== JSON.stringify(this.props.dotsInfo)) {
            //console.log("SearchMap / componentDidUpdate - dotsInfo changed = ", JSON.stringify(prevProps.dotsInfo));
            //console.log("SearchMap / componentDidUpdate - dotsInfo changed = ", JSON.stringify(this.props.dotsInfo));

            // Reset markers
            this.resetMarkers();
        }

        // Hover on
        if ((this.props.hovered !== null) && (prevProps.hovered !== this.props.hovered)) {
            this.hoverOn();

            if (prevProps.hovered !== null) {
                this.hoverOff(prevProps.hovered);
            }

            // Pan to hovered dot
            this.panToDot(this.props.hovered);
        }
        // Hover off
        if ((this.props.hovered === null) && (prevProps.hovered !== null)) {
            this.hoverOff(prevProps.hovered);

            // Pan to hovered dot
            this.panToCenter();
        }

        // Select on
        if ((this.props.selected !== null) && (prevProps.selected !== this.props.selected)) {
            this.resetChildMarkers();
            this.resetPolygon();
        }

        // Select off
        if ((this.props.selected === null) && (prevProps.selected !== null)) {
            this.clearChildMarkers();
            this.clearPolygon();

            this.zoomToFit();
        }
    }


    /*
    ============================================================================================
        setMapHeight
    --------------------------------------------------------------------------------------------
        - Adjust map height
    ============================================================================================
    */

    setMapHeight(heightIncrement) {
        // Calculate new height
        let newMapHeight = this.state.mapHeight + heightIncrement;

        // Limit the range
        if (newMapHeight < this.state.mapMinHeight) {
            newMapHeight = this.state.mapMinHeight;
        }
        if (newMapHeight > this.state.mapMaxHeight) {
            newMapHeight = this.state.mapMaxHeight;
        }

        // Set state and reflect changes
        this.setState(
            {
                mapHeight: newMapHeight
            },
            this.refreshMap
        );
    }

    initMap() {
        // Store current context
        const that = this;

        /*
        ============================================================================================
            Initialize Map
        ============================================================================================
        */

        // Map settings
        const mapSettings = {
            center: (this.props.mapCenter !== null)?
                (pointToLatLng(this.props.mapCenter)) : (pointToLatLng(this.state.userLocation)),
            scrollwheel: false,
            mapTypeId: this.props.google.maps.MapTypeId.TERRAIN,
            zoom:8
        };

        this.map = new this.props.google.maps.Map(this.mapRef.current, mapSettings);

        // Apply styles
        const mapStyles = getMapStyles();
        this.map.setOptions({ styles: mapStyles });

        // Construct buttons
        const mapButtons = document.createElement("div");
        mapButtons.id = this.props.buttonsNodeID;
        mapButtons.className = "map-buttons";

        // Initialize buttons
        this.initMapButtons(this.map, mapButtons);

        // Set control attribute of the map
        mapButtons.index = 1;
        this.map.controls[this.props.google.maps.ControlPosition.LEFT_CENTER].push(mapButtons);

        // Initialize markers
        this.resetMarkers();

        /*
        ============================================================================================
            Extract user location
        ============================================================================================
        */

        let userLocation = null;

        navigator.geolocation.getCurrentPosition(
            (location) => {
                const locationLatitude = location.coords.latitude;
                const locationLongitude = location.coords.longitude;
                userLocation = { latitude: locationLatitude, longitude: locationLongitude };
                that.setState({
                    userLocation : userLocation
                })
            }
        );

    }

    panToCenter() {
        // Pan to center location
        if (this.props.mapCenter !== null) {
            this.map.panTo(pointToLatLng(this.props.mapCenter));
        }
        else {
            this.map.panTo(pointToLatLng(this.state.userLocation));
        }

    }

    panToDot(dotIndex) {
        // Center map
        this.map.panTo(pointToLatLng(this.props.dotsInfo[dotIndex].location));
    }

    hoverOn() {
        // Get marker
        const marker = this.state.markers[this.props.hovered];

        // Get name and media
        const mediaID = marker.args.mediaID;
        const mediaNode = document.getElementById(mediaID);
        const nameID = marker.args.nameID;
        const nameNode = document.getElementById(nameID);

        //console.log("SearchMap / hoverOn - mediaNode = ", mediaNode);
        //console.log("SearchMap / hoverOn - nameNode = ", nameNode);

        // Change marker style
        if (nameNode !== null || mediaNode !== null) {
            nameNode.className = "search-map-marker-name search-map-marker-name-on w5";
            mediaNode.className = "search-map-marker-media search-map-marker-media-on";
        }
    }


    hoverOff(dotIndex) {
        // Get marker
        const marker = this.state.markers[dotIndex];

        // Get name and media
        const mediaID = marker.args.mediaID;
        const mediaNode = document.getElementById(mediaID);
        const nameID = marker.args.nameID;
        const nameNode = document.getElementById(nameID);

        // Change marker style
        if (nameNode !== null || mediaNode !== null) {
            nameNode.className = "search-map-marker-name search-map-marker-name-off w5";
            mediaNode.className = "search-map-marker-media search-map-marker-media-off";
        }
    }

    /*
    ============================================================================================
        initMapButtons
    --------------------------------------------------------------------------------------------
        - Initialize map buttons and set up event listeners
        - map : DOM handle of the map object
        - mapButtons : DOM handles of the map zoom button objects
        - mapHeight : a state variable passed down from the parent
    ============================================================================================
    */

    initMapButtons(map, mapButtons) {
        // Adjust map height callback
        const setMapHeight = this.setMapHeight;

        // Map height increment size
        const mapHeightIncrement = this.state.mapHeightIncrement;

        // Create a new div
        const divIncrease = document.createElement("div");

        // Set id and attributes
        const increaseButtonID = this.props.mapNodeID + "-button-increase";
        divIncrease.id = increaseButtonID;
        divIncrease.className = "map-button-increase";
        divIncrease.setAttribute("title", "Increase Map Size");
        divIncrease.style.backgroundImage = getStaticPath("/images/map/map-size-up.png");

        // Insert into container
        mapButtons.appendChild(divIncrease);

        // Click event
        divIncrease.addEventListener("click", function(){
            // Execute callback to adjust height and update state
            setMapHeight(mapHeightIncrement);
        });

        // Create a new div
        const divDecrease = document.createElement("div");

        // Set id and attributes
        const decreaseButtonID = this.props.mapNodeID + "-button-decrease";
        divDecrease.id = decreaseButtonID;
        divDecrease.className = "map-button-decrease";
        divDecrease.setAttribute("title", "Decrease Map Size");
        divDecrease.style.backgroundImage = getStaticPath("/images/map/map-size-down.png");

        // Insert into container
        mapButtons.appendChild(divDecrease);

        // Add a click event listener
        divDecrease.addEventListener("click", function(){
            // Execute callback to adjust height and update state
            setMapHeight(-mapHeightIncrement);
        });
    }

    resetMarkers() {
        //console.log("SearchMap / resetMarkers - this.props.dotsInfo = ", this.props.dotsInfo)

        // Clear previous markers
        this.clearMarkers();

        // Initialize marker arrays
        const markers = [];

        // For all dots
        for (let i = 0; i < this.props.dotsInfo.length; i++) {

            // Media
            const mediaSorted = sortMedia(this.props.dotsInfo[i]);
            const media = [
                ...mediaSorted.overview,
                ...mediaSorted.todos,
                ...mediaSorted.history,
                ...mediaSorted.stories
            ];

            // Is video
            const isVideo = (media[0].type === "video");

            // Dot media
            const dotMedia = (isVideo)?
                (
                    ("xs" in media[0].files)?
                        getMediaProperty(media[0], "xs", 'url', true) :
                        getStaticPath("/images/common/video-white.png", true)
                ) : (
                    getMediaProperty(media[0], "xs", 'url', true)
                );

            // Marker icons
            //const markerIcon = getStaticPath("/images/number/single_white_" + (i + 1) + ".png", true);

            // Set up start marker
            const marker = new customMarker(
                pointToLatLng(this.props.dotsInfo[i].location),
                this.map,
                {
                    type: "dot",
                    icon: null,
                    id: "search-map-dot-marker-" + i,
                    name: this.props.dotsInfo[i].name,
                    nameID:"search-map-dot-marker-name-" + i,
                    media: dotMedia,
                    mediaID: "search-map-dot-marker-media-" + i,
                    hovered: (this.props.hovered === i)
                    //hoverOn: this.hoverOn,
                    //hoverOff: this.hoverOff
                }
            );
            //console.log("SearchMap / resetMarkers - marker = ", marker);

            // Add marker to the list
            markers.push(marker);
        }

        // Update state and zoom to fit
        this.setState(
            {
                markers: markers
            },
            this.zoomToFit
        );
    }

    clearMarkers() {
        // Clear previous markers
        for (let i = 0; i < this.state.markers.length; i++) {
            this.state.markers[i].setMap(null);
        }

        // Update markers
        this.setState({
            markers: []
        });
    }

    resetChildMarkers() {
        //console.log("SearchMap / resetChildMarkers - this.props.dotsInfo = ", this.props.dotsInfo);
        //console.log("SearchMap / resetChildMarkers - this.props.dotsInfo = ", this.props.dotsInfo);
        //console.log("SearchMap / resetChildMarkers - this.props.selected = ", this.props.selected);

        // Clear previous child markers
        this.clearChildMarkers();

        // Initialize marker arrays
        const childMarkers = [];

        // Child dots
        const childrenInfo = this.props.dotsInfo[this.props.selected].trip_extension.children;

        // For all dots
        for (let i = 0; i < childrenInfo.length; i++) {

            // Media
            const mediaSorted = sortMedia(childrenInfo[i]);
            const media = [
                ...mediaSorted.overview,
                ...mediaSorted.todos,
                ...mediaSorted.history,
                ...mediaSorted.stories
            ];

            // Is video
            const isVideo = (media[0].type === "video");

            // Dot media
            const dotMedia = (isVideo)?
                (
                    (media[0].sizes.indexOf("xs") === -1)?
                        getStaticPath("/images/common/search-map-video.png", true) :
                        getMediaProperty(media[0], "xs", 'url', true)
                ) : (
                    getMediaProperty(media[0], "xs", 'url', true)
                );

            // Marker icons
            //const markerIcon = getStaticPath("/images/number/single_white_" + (i + 1) + ".png", true);

            // Set up start marker
            const childID = childrenInfo[i].id;
            const setHoveredChildID = () => { this.setState({ hoveredChildID: childID }); };
            const clearHoveredChildID = () => { this.setState({ hoveredChildID: null }); };
            const childMarker = new customMarker(
                pointToLatLng(childrenInfo[i].location),
                this.map,
                {
                    type: "child",
                    icon: null,
                    id: "search-map-child-marker-" + i,
                    name: childrenInfo[i].name,
                    nameID:"search-map-child-marker-name-" + i,
                    media: dotMedia,
                    mediaID: "search-map-child-marker-media-" + i,
                    hovered: (this.props.hoveredChildID === childID),
                    setHoveredChildID: setHoveredChildID,
                    clearHoveredChildID: clearHoveredChildID
                    //hoverOn: this.hoverOn,
                    //hoverOff: this.hoverOff
                }
            );
            //console.log("SearchMap / resetChildMarkers - childMarker = ", childMarker);

            // Add child marker to the list
            childMarkers.push(childMarker);
        }

        // Update state and zoom to fit
        this.setState(
            {
                childMarkers: childMarkers
            },
            this.zoomToFitChildren
        );
    }

    clearChildMarkers() {
        // Clear previous child markers
        for (let i = 0; i < this.state.childMarkers.length; i++) {
            this.state.childMarkers[i].setMap(null);
        }

        // Update markers
        this.setState({
            childMarkers: []
        });
    }

    zoomToFit() {
        // Initialize bounds
        const bounds = new this.props.google.maps.LatLngBounds();

        // Extend bounds for all dots
        for (let i = 0; i < this.props.dotsInfo.length; i++) {
            bounds.extend(pointToLatLng(this.props.dotsInfo[i].location));
        }

        // Fit map bounds
        this.map.fitBounds(bounds);
    }

    zoomToFitChildren() {
        //console.log("SearchMap / zoomToFitChildren");

        // Initialize bounds
        const bounds = new this.props.google.maps.LatLngBounds();

        // Extend bounds for all dots
        const childrenInfo = this.props.dotsInfo[this.props.selected].trip_extension.children;
        //console.log("SearchMap / childrenInfo = ", childrenInfo);

        for (let i = 0; i < childrenInfo.length; i++) {
            bounds.extend(pointToLatLng(childrenInfo[i].location));
        }

        // Fit map bounds
        this.map.fitBounds(bounds);
    }

    resetPolygon() {
        /*
        ============================================================================================
            Clear polygon
        ============================================================================================
        */

        this.clearPolygon();


        /*
        ============================================================================================
            Contruct polygon
        ============================================================================================
        */

        const polylines = [];

        // Dots data
        const dotsInfo = this.props.dotsInfo[this.props.selected].trip_extension.children;

        // For all dots
        for (let i = 0; i < dotsInfo.length; i++) {
            const polyline = pointToLatLng(dotsInfo[i].location)
            polylines.push(polyline);
        }
        polylines.sort(sortPointX);
        polylines.sort(sortPointY);

        // Convex hull
        const hullPoints = [];
        chainHull_2D(polylines, polylines.length, hullPoints);

        //function
        function sortPointX(a,b) {
            return a.lng() - b.lng();
        }

        function sortPointY(a,b) {
            return  a.lat() - b.lat();
        }

        function isLeft(P0, P1, P2) {
            return (P1.lng() - P0.lng()) * (P2.lat() - P0.lat()) - (P2.lng() - P0.lng()) * (P1.lat() - P0.lat());
        }

        function chainHull_2D(P, n, H) {
            // the output array H[] will be used as the stack
            let bot = 0,
                top = (-1); // indices for bottom and top of the stack
            let i; // array scan index
            // Get the indices of points with min x-coord and min|max y-coord
            let minmin = 0, minmax;
            let xmin = P[0].lng();
            for (i = 1; i < n; i++) {
                if (P[i].lng() !== xmin) {
                    break;
                }
            }

            minmax = i - 1;
            if (minmax === n - 1) { // degenerate case: all x-coords == xmin
                H[++top] = P[minmin];
                if (P[minmax].lat() !== P[minmin].lat()) // a nontrivial segment
                H[++top] = P[minmax];
                H[++top] = P[minmin]; // add polygon endpoint
                return top + 1;
            }

            // Get the indices of points with max x-coord and min|max y-coord
            let maxmin, maxmax = n - 1;
            let xmax = P[n - 1].lng();
            for (i = n - 2; i >= 0; i--) {
                if (P[i].lng() !== xmax) {
                    break;
                }
            }
            maxmin = i + 1;

            // Compute the lower hull on the stack H
            H[++top] = P[minmin]; // push minmin point onto stack
            i = minmax;
            while (++i <= maxmin) {
                // the lower line joins P[minmin] with P[maxmin]
                if (isLeft(P[minmin], P[maxmin], P[i]) >= 0 && i < maxmin) {
                    continue; // ignore P[i] above or on the lower line
                }

                while (top > 0) { // there are at least 2 points on the stack
                    // test if P[i] is left of the line at the stack top
                    if (isLeft(H[top - 1], H[top], P[i]) > 0) {
                        break; // P[i] is a new hull vertex
                    } else {
                        top--; // pop top point off stack
                    }
                }

                H[++top] = P[i]; // push P[i] onto stack
            }

            // Next, compute the upper hull on the stack H above the bottom hull
            if (maxmax !== maxmin) { // if distinct xmax points
                H[++top] = P[maxmax]; // push maxmax point onto stack
            }

            bot = top; // the bottom point of the upper hull stack
            i = maxmin;
            while (--i >= minmax) {
                // the upper line joins P[maxmax] with P[minmax]
                if (isLeft(P[maxmax], P[minmax], P[i]) >= 0 && i > minmax) {
                    continue; // ignore P[i] below or on the upper line
                }

                while (top > bot) { // at least 2 points on the upper stack
                    // test if P[i] is left of the line at the stack top
                    if (isLeft(H[top - 1], H[top], P[i]) > 0) {
                        break; // P[i] is a new hull vertex
                    } else {
                        top--; // pop top point off stack
                    }
                }

                // breaks algorithm
                //        if (P[i].lng() == H[0].lng() && P[i].lat() == H[0].lat()) {
                //            return top + 1; // special case (mgomes)
                //        }

                H[++top] = P[i]; // push P[i] onto stack
            }

            if (minmax !== minmin) {
                H[++top] = P[minmin]; // push joining endpoint onto stack
            }

            return top + 1;
        }

        // Polygon
        const polygon = new this.props.google.maps.Polygon({
            paths: hullPoints,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
        });

        // Set map
        polygon.setMap(this.map);

        // Update state
        this.setState(
            {
                polygon: polygon
            }
        );


        /*
        ============================================================================================
            Path
        ============================================================================================
        */

        /*
        // Define a symbol using SVG path notation, with an opacity of 1.
        const lineSymbol = {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          scale: 4
        };
        */

        /*
        // Connecting Dots
        const polyPath = new this.props.google.maps.Polyline({
            path: polylines,
            strokeColor: '#4EA3D0',
            strokeOpacity: 0,
            icons: [{
                icon: lineSymbol,
                offset: '0',
                repeat: '20px'
            }],
            map: this.map
        });
        */

        //polyPath.setMap(this.map);
        /*
        for (let i = 0; i < dotsInfo.length; i++) {
            // directionsService
            const directionsService = new this.props.google.maps.DirectionsService();

            const directionsRenderer = new this.props.google.maps.DirectionsRenderer({
                origin:this.state.polylines[i],
                destination:this.state.polylines[i + 1],
                travelMode: this.props.google.maps.DirectionsTravelMode.DRIVING,
                map: this.map
            });
                console.log("SearchMap / render - this.state.polylines[] = ",this.state.polylines[i]);
                console.log("SearchMap / render - this.state.polylines = ",this.state.polylines);
        }
        */
    }

    clearPolygon() {
        // Clear polygon
        const polygon = this.state.polygon;
        if (polygon !== null) {
            polygon.setMap(null);
        }

        // Update state
        this.setState({
            polygon: null
        });
    }
}

function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode
    };
}

export default connect(mapStateToProps, null)(SearchMap);