import { STORE_LOG_IN_ON, STORE_LOG_IN_SIMPLE_ON } from "actions"; 

const INITIAL_STATE = {
    logInOn: false,
    logInSimpleOn: false
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_LOG_IN_ON:
			return { ...state, logInOn: action.payload };
        case STORE_LOG_IN_SIMPLE_ON:
            return { ...state, logInSimpleOn: action.payload };
		default:
			return state;
	}
};