// React / ReactDOM / React-router / Redux / history
import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history"

// Modules
import axios from "axios";
import Cookies from "universal-cookie";
import firebase from "firebase/app";
import "firebase/firestore";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    storeColorMode,
    storeBrowserWidth,
    storeBrowserWidthPixels,
    storeUser,
    storeMap,
    storeNewComment,
    storeNewReply,
    storeNewChat,
    storeNewChatMessage,
    storeNewNotification,
    storeItinerary,
    storeItineraryParams,
    storeNewItinerary,
    storeNewParticipant,
    storeDotsHomeOn
} from "actions";

// Modal components
import SignUp from "components/SignUp";
import LogIn from "components/LogIn";
import LogInSimple from "components/LogInSimple";
import Share from "components/Share";
import DotTag from "components/DotTag";
import UserTag from "components/UserTag";
import Memo from "components/Memo";
import Follow from "components/Follow";
import Posting from "components/Posting";
import More from "components/More";
import Schedule from "components/Schedule";
import CreateSimple from "components/CreateSimple";
import GallerySimple from "components/GallerySimple";

// Components
import Nav from "components/Nav";
import {
    WarningAlert,
    NotificationAlert,
    ResetPasswordEmailSentAlert,
    ResetPasswordTokenExpiredAlert
} from "components/Alert";
import { DotsHome, DotsHomeControl } from "containers/DotsView";
import DotView from "containers/DotView";
import { DotEdit } from "containers/DotView";
import TripsView from "containers/TripsView";
import TripView from "containers/TripView";
import PlanView from "containers/PlanView";
import RouteView from "containers/RouteView";
import ItineraryView from "containers/ItineraryView";
import ItinerariesView from "containers/ItinerariesView";
import UserView from "containers/UserView";
import { ResetPassword, EditUserInfo } from "containers/UserView";
import CreateView from "containers/CreateView";
import { SearchResults, SearchResultsLocation } from "containers/SearchResultView";
import AboutView from "containers/AboutView";
import NationalParkView from "containers/NationalParkContainer";

// Axios
import { getUserLogin } from "requests";

// Websocket
import {
    openWebSocket,
    closeWebSocket,
    webSocketOnMessage
} from "js/WebSocketFunctions";

// Functions
import { detectTouch } from "js/Functions";

// CSS
import "./css/Font.scss";
import "./css/Body.scss";
import "./css/Common.scss";
import "./css/InputRange.scss";
import "./css/DatePicker.scss";

// Main component of the app
class App extends Component {
    constructor(props){
        super(props);

        // Reset redux variables
        this.resetRedux();

        // Touch device
        window.browserWidthSmall = 4;
        window.touchOn = detectTouch();


        // Medium width
        window.browserWidthMedium = 8;

        // Set basic colors
        window.colorWhite = "#FFFFFF";
        window.colorAlmostWhite = "#F8F8F8";
        window.colorCloseToWhite = "#DDDDDD";
        window.colorBlack = "#000000";
        window.colorAlmostBlack = "#101010";
        window.colorCloseToBlack = "#222222";
        window.colorCloserToBlack = "#181818";
        window.colorGray = "#888888";
        window.colorLightGray = "#AAAAAA";
        window.colorDarkGray = "#555555";
        window.colorDarkestGray = "#333333";
        window.colorBlue = "#3284B0";
        window.colorLightBlue = "#4EA3D0";
        window.colorDarkBlue = "#256486";
        window.colorRed = "#950000";
        window.colorLightRed = "#B30000";
        window.colorDarkRed = "#840000";

        // Set browser width levels
        window.browserWidths = {
            1: 350,
            2: 400,
            3: 450,
            4: 500,
            5: 550,
            6: 600,
            7: 700,
            8: 800,
            9: 900,
            10: 1000,
            11: 1100,
            12: 1200
        };

        // Scroll bar width
        window.scrollBarWidth = 8;

        // Transit modes
        window.transitModes = [
            "drive",
            "walk",
            "bus",
            "subway",
            "train",
            "flight",
            "cruise",
            "backpack",
            "swim",
            "crosscountry",
            "downhill",
            "climb",
            "scramble",
            "kayak"
        ];

        // Set cookies
        window.cookies = new Cookies();
        //window.cookies.set("platform", "TheDots", { path: "/", sameSite: "None", secure: true });
        //console.log("App / constructor - window.cookies= ", window.cookies);

        // CSRF token
        const csrfToken = window.cookies.get("csrftoken");
        //console.log("App / constructor - csrfToken = ", csrfToken);

        // Create regular axios and CSRF token enabled axios instances
        // and set as global variables
        window.axios = axios;
        window.axiosCSRF = axios.create({
            headers: { "X-CSRFToken": csrfToken }
        });

        // Create a no-cache option just in case
        window.axiosCSRFnoCache = axios.create({
            headers: {
                "X-CSRFToken": csrfToken,
                "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0",
                "Pragma": "no-cache",
                "Expires": 0
            }
        });

        // Bind web socket functions
        window.webSocket = null;
        this.openWebSocket = openWebSocket.bind(this);
        this.closeWebSocket = closeWebSocket.bind(this);
        this.webSocketOnMessage = webSocketOnMessage.bind(this);

        // Check if user is already logged in and token exists
        //const loggedIn = (localStorage.token)? true: false;

        // User default settings
        this.userProfilePicSize = 800;
        this.userFitnessLevelDefault = 3;

          // Initialize state
        this.state = {
            // Modules ready
            webSocketReady: false,
            facebookLogInReady: false,
            googleLogInReady: false,

            // If log in is checked
            logInChecked: false,

            // Print map window
            printMapOn: false,
            printMapOpacity: 0.0,

            // Google map
            googleMap: null
        };

        // Bind initGoogle callback with the App scope
        this.initGoogle = this.initGoogle.bind(this);
        this.initFB = this.initFB.bind(this);
        this.initGAPI = this.initGAPI.bind(this);
        this.initFirestore = this.initFirestore.bind(this);

        // Bind browser width update function
        this.updateBrowserWidth = this.updateBrowserWidth.bind(this);

        // Make initGoogle global object (will create window.google after loading API scripts)
        window.initGoogle = this.initGoogle;
        window.initFB = this.initFB;
        window.initGAPI = this.initGAPI;

        // Bind reset redux callback
        this.resetRedux = this.resetRedux.bind(this);
    }

    // Callback to initialize Google API
    initGoogle() {
        //console.log("App / initGoogle");
        this.props.storeMap(window.google)
        window.geocoder = new window.google.maps.Geocoder();
        //console.log("App / initGoogle - window.google = ", window.google);
        //console.log("App / initGoogle - window.geocoder = ", window.geocoder);

    }

    initFB () {
        // Self invoking function to load Facebook script
        ( function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/all.js#xfbml=1&appId=" + 1387370438053401;
            fjs.parentNode.insertBefore(js, fjs);
        } (document, "script", "facebook-jssdk"));

        // Initialize FB object
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: "1387370438053401",
                cookie: true,
                status: true,
                xfbml: false
            });
        };

        // Update the state to trigger rerender
        this.setState(
            {
                facebookLogInReady: true
            },
        );
    }

    initGAPI () {
        //console.log("App / initGAPI");

        // Self invoking function to load Google script
        (
            function (d, s, id, cb) {
                const element = d.getElementsByTagName(s)[0];
                const fjs = element;
                let js = element;

                js = d.createElement(s);
                js.id = id;
                js.src = "//apis.google.com/js/client:platform.js";
                fjs.parentNode.insertBefore(js, fjs);
                js.onload = cb;
            }
            (
                document, "script", "google-login", () => {
                    const params = {
                        client_id: "750909309521-f811ir820v2p45g9uqhpqdp30cimi0o7.apps.googleusercontent.com",
                        cookiepolicy: "none",
                        login_hint: null,
                        hosted_domain: null,
                        fetch_basic_profile: true,
                        discoveryDocs: "https://developers.google.com/discovery/v1/using",
                        ux_mode: "popup",
                        redirect_uri: null,
                    };

                    window.gapi.load(
                        "auth2",
                        () => {
                            // Initialize auth2 if not ready
                            window.gapi.auth2.init(params).then(
                                () =>
                                {
                                    console.log("App / initGAPI - googleAuth initialized");

                                    // Get authentication instance and store
                                    window.googleAuth = window.gapi.auth2.getAuthInstance()
                                    //console.log("App / initGAPI - window.gapi.auth2 = ", window.gapi.auth2);
                                    //console.log("App / initGAPI - window.googleAuth = ", window.googleAuth);

                                    this.setState({
                                        googleLogInReady: true,
                                    });
                                },
                                (error) => { console.log("[WARNING] App / initGAPI - googleAuth error = ", error); }
                            );
                        }
                    );

                    /*
                    this.setState({
                        googleLogInReady: true,
                    });
                    */
                }
            )
        );
    }

    initFirestore() {
        // Initialize Cloud Firestore through Firebase
        // Your web app's Firebase configuration

        const firebaseConfig = {
            apiKey: "AIzaSyAa12uKUEtLYyd8OD7F3pUw7VFD2sRk3qc",
            authDomain: "thedots-277314.firebaseapp.com",
            databaseURL: "https://thedots-277314.firebaseio.com",
            projectId: "thedots-277314",
            storageBucket: "thedots-277314.appspot.com",
            messagingSenderId: "750909309521",
            appId: "1:750909309521:web:0d8b132734dece7670ae70",
            measurementId: "G-LP2CTFZLTF"
        };

        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);

        // Save firestore instance as a global variable
        window.firestore = firebase.firestore();
    }

    updateBrowserWidth() {
        // Print out current values
        //console.log("App / updateBrowserWidth = ", document.body.clientWidth);
        //console.log("App / updateBrowserHeight = ", document.body.clientHeight);

        // Browser width without scroll bar
        const browserWidthPixels = (window.touchOn)?
            document.body.clientWidth : document.body.clientWidth - window.scrollBarWidth;

        // Determine the browser width level
        let browserWidth = null;
        const keys = Object.keys(window.browserWidths);
        for (let i = 0; i < keys.length; i++) {
            if (i === 0) {
                if (browserWidthPixels <= window.browserWidths[keys[0]]) {
                    browserWidth = Number(keys[0]);
                }
            }
            else if (i < keys.length) {
                if ((browserWidthPixels > window.browserWidths[keys[i - 1]]) &&
                    (browserWidthPixels <= window.browserWidths[keys[i]])) {
                    browserWidth = Number(keys[i]);
                }
            }
        }
        if (browserWidth === null) {
            browserWidth = keys.length + 1;
        }
        //console.log("App / browserWidth = ", browserWidth);
        //console.log("App / browserWidthPixels = ", browserWidthPixels);

        // Update redux state
        this.props.storeBrowserWidth(browserWidth);
        this.props.storeBrowserWidthPixels(browserWidthPixels);
    }

    componentDidMount() {
        // Update browser width
        this.updateBrowserWidth();

        // Add update browser width listener
        window.addEventListener("resize", this.updateBrowserWidth);
        window.addEventListener("contextmenu", (e) => { e.preventDefault(); });

        // Clear redux variables
        //window.addEventListener("unload", this.resetRedux);

        // Set background color settings
        document.body.style.backgroundColor = (this.props.colorMode === "day")?
            window.colorAlmostWhite : window.colorBlack;

        // Set slider style
        if (this.props.colorMode === "day") {
            document.body.style.scrollbarFaceColor = window.colorRed;
            document.body.style.scrollbarArrowColor = window.colorRed;
            document.body.style.scrollbarTrackColor = window.colorRed;
            document.body.style.scrollbarShadowColor = window.colorRed;
            document.body.style.scrollbarHighlightColor = window.colorRed;
            document.body.style.scrollbar3dlightColor = window.colorRed;
            document.body.style.scrollbarDarkshadowColor = window.colorRed;
        }
        else {
            document.body.style.scrollbarFaceColor = window.colorBlue;
            document.body.style.scrollbarArrowColor = window.colorBlue;
            document.body.style.scrollbarTrackColor = window.colorBlue;
            document.body.style.scrollbarShadowColor = window.colorBlue;
            document.body.style.scrollbarHighlightColor = window.colorBlue;
            document.body.style.scrollbar3dlightColor = window.colorBlue;
            document.body.style.scrollbarDarkshadowColor = window.colorBlue;
        }
        //console.log("App / componentDidMount - document.body.style = ", document.body.style);

        // Initialize Google and Facebook
        window.initGoogle = this.initGoogle;
        this.initGAPI();
        this.initFB();
        this.initFirestore();

        // Asynchronously load the Google Maps script, passing in the callback reference
        loadJS("https://maps.googleapis.com/maps/api/js?key=AIzaSyAa12uKUEtLYyd8OD7F3pUw7VFD2sRk3qc&callback=initGoogle&libraries=places")

        // Check if user is already logged in and token exists
        const loggedIn = !!(localStorage.token);
        //console.log("App / componentDidMount - loggedIn = ", loggedIn);
        //console.log("App / componentDidMount - localStorage.token = ", localStorage.token);

        // Set axios headers with the access token
        if (loggedIn) {
            window.axios.defaults.headers.common["Authorization"] = " Token " + localStorage.token;
            window.axiosCSRF.defaults.headers.common["Authorization"] = " Token " + localStorage.token;

            // Callback
            const axiosCallback = (response) => {
                //console.log("App / componentDidMount - response.data.content = ", response.data.content);

                // Update global user info
                this.props.storeUser(response.data.content);

                // Store color mode
                this.props.storeColorMode(response.data.content.color_mode);

                // Change background color
                if (response.data.content.color_mode === "day") {
                    // Change body style
                    document.body.style.backgroundColor = window.colorAlmostWhite;
                }
                else {
                    // Change body style
                    document.body.style.backgroundColor = window.colorBlack;
                }

                // Update log in check flag
                this.setState({
                    logInChecked: true
                });
            };

            // Send request
            getUserLogin()
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] App / componentDidMount - error = ", response);})

        }
        else {
            // Nulify global user info
            this.props.storeUser(null);

            // Update log in check flag
            this.setState({
                logInChecked: true
            });
        }
    }

    componentWillMount () {
        window.openWebSocket = () => {
            window.webSocket = this.openWebSocket(this.webSocketOnMessage);
        };

        window.webSocket = this.openWebSocket(this.webSocketOnMessage);
    }

    componentWillUnmount () {
        // Clear redux variables
        this.props.storeDotsHomeOn(false);

        // Close websocket
        this.closeWebSocket();

        // Remove update browser width listener
        window.removeEventListener("resize", this.updateBrowserWidth);
        window.removeEventListener("contextmenu", (e) => { e.preventDefault(); });

        // Remove itinerary props
        this.props.storeItinerary(null);
        this.props.storeItineraryParams(null);
    }

    render() {
        //console.log("App / render - this.props = ", this.props);
        //console.log("App / render - this.state = ", this.state);
        //console.log("App / render - this.props.userInfo = ", this.props.userInfo);
        //console.log("App / render - this.state.logInChecked = ", this.state.logInChecked);
        //console.log("App / render - this.state.webSocketReady = ", this.state.webSocketReady);
        //console.log("App / render - this.webSocket.readyState = ", this.webSocket.readyState);
        //console.log("App / render - this.props.colorMode = ", this.props.colorMode);
        //console.log("App / render - this.props.mainMode = ", this.props.mainMode);

        // Render only after checking the log in status and web socket connection
        if (this.state.logInChecked && this.state.webSocketReady) {
            const colorStyle = (this.props.colorMode === "day")? {
                backgroundColor: window.colorAlmostWhite,
                color: window.colorBlack
            } : {
                backgroundColor: window.colorBlack,
                color: window.colorWhite
            };

            const TripsHomeContainer = () => {
                return (
                    <TripsView />
                );
            };

            const TripContainer = (props) => {
                const tripSlug = props.match.params.tripSlug;

                return (
                    <TripView
                        key = {"trip-page-" + tripSlug}
                        tripSlug = {tripSlug}
                        history = {props.history}
                    />
                );
            };

            const PlanContainer = (props) => {
                const planID = props.match.params.planID;
                const username = (this.props.userInfo !== null)?
                    this.props.userInfo.username : "guest";

                return (
                    <PlanView
                        key = {"plan-page-" + planID + "-user-" + username}
                        planID = {planID}
                        history = {props.history}
                    />
                );
            };

            const DotContainer = (props) => {
                const dotSlug = props.match.params.dotSlug;

                return (
                    <DotView
                        key = {"dot-page-" + dotSlug}
                        dotSlug = {dotSlug}
                        history = {props.history}
                    />
                );
            };

            const DotEditContainer = (props) => {
                const dotSlug = props.match.params.dotSlug;

                return (
                    <DotEdit
                        key = {"dot-edit-page-" + dotSlug}
                        dotSlug = {dotSlug}
                        history = {props.history}
                    />
                );
            }

            const RouteContainer = (props) => {
                const routeSlug = props.match.params.routeSlug;

                return (
                    <RouteView
                        key = {"route-page-" + routeSlug}
                        routeSlug = {routeSlug}
                        history = {props.history}
                    />
                );
            };

            const ItinerariesHomeContainer = () => {
                return (
                    <ItinerariesView />
                );
            };

            const ItineraryContainer = (props) => {
                const itineraryID = Number(props.match.params.itineraryID);

                return (
                    <ItineraryView
                        key = {"itinerary-page-" + itineraryID}
                        itineraryID = {itineraryID}
                        history = {props.history}
                    />
                );
            };

            const UserContainer = (props) =>  {
                const username = props.match.params.username;

                return (
                    <UserView
                        key = {"user-page-" + username}
                        username = {username}
                        history = {props.history}
                    />
                );
            }

            const ResetPasswordContainer = (props) => {
                const username = props.match.params.username;

                return (
                    <ResetPassword
                        key = {"reset-password-" + username}
                        username = {username}
                        history = {props.history}
                    />
                )
            }

            const SearchResultsContainer = (props) =>  {
                return (
                    <SearchResults
                        key = {"search-results"}
                        history = {props.history}
                    />
                );
            }

            const SearchResultsLocationContainer = (props) =>  {
                return (
                    <SearchResultsLocation
                        key = {"search-results-location"}
                        history = {props.history}
                    />
                );
            }

            const NationalParkContainer = (props) => {
                const setSlug = props.match.params.setSlug;

                return (
                    <NationalParkView
                        key = {"national-park-" + setSlug + "-" + this.props.refresh.timestamp}
                        setSlug = {setSlug}
                        history = {props.history}
                    />
                );
            }

            return(
                <Router history={history}>
                    <div className = "website"
                        style = {colorStyle}
                    >
                        {/* ============================================================ */}
                        {/* Alert Modals */}
                        {/* ============================================================ */}

                        <WarningAlert />

                        <NotificationAlert />

                        <ResetPasswordEmailSentAlert />

                        <ResetPasswordTokenExpiredAlert />

                        {/* ============================================================ */}
                        {/* Authentication Modals */}
                        {/* ============================================================ */}

                        <SignUp
                            userProfilePicSize = {this.userProfilePicSize}
                            history = {history}
                        />

                        <LogIn
                            userProfilePicSize = {this.userProfilePicSize}
                            history = {history}
                        />

                        <LogInSimple
                            userProfilePicSize = {this.userProfilePicSize}
                            history = {history}
                        />

                        {/* ============================================================ */}
                        {/* Dot Page Modals */}
                        {/* ============================================================ */}

                        <Share component = {Share} />

                        <More component = {More}
                            history = {history}
                        />

                        {/* ============================================================ */}
                        {/* User Page Modals */}
                        {/* ============================================================ */}

                        {(!!window.google && this.props.userInfo !== null)? (
                            <EditUserInfo
                                key = {"edit-user-info-" + this.props.userInfo.id}
                                userProfilePicSize = {this.userProfilePicSize}
                                userFitnessLevelDefault = {this.userFitnessLevelDefault}
                                history = {history}
                            />
                        ) : null}

                        <Follow
                            key = {"follow-" + this.props.follow.id + "-" +
                                this.props.follow.type}
                            component = {Follow}
                        />

                        <Posting
                            key = {"posting-" + this.props.posting.modalOn}
                            component = {Posting}
                        />


                        {/* ============================================================ */}
                        {/* Bucket or Timeline Modals */}
                        {/* ============================================================ */}

                        <DotTag
                            key = {"dot-tag-" + this.props.dotTag.id + "-" +
                                this.props.dotTag.type + "-" + this.props.dotTag.mode}
                            component = {DotTag}
                        />

                        <UserTag
                            key = {"user-tag-" + this.props.userTag.id}
                            component = {UserTag}
                        />

                        <Memo
                            key = {"memo-" + this.props.memo.id}
                            component = {Memo}
                        />


                        {/* ============================================================ */}
                        {/* Itinerary Modals */}
                        {/* ============================================================ */}

                        {
                            (this.props.schedule.modalOn === true)?
                            (
                                <Schedule
                                    component = {Schedule}
                                    history = {history}
                                />
                            ) : null
                        }


                        {/* ============================================================ */}
                        {/* Create Simple Modal */}
                        {/* ============================================================ */}

                        {
                            (this.props.userInfo && this.props.google && this.props.create.modalOn)?
                                (
                                    (this.props.create.mode === "create")?
                                        (
                                            <CreateSimple
                                                key = {"create-simple-" + this.props.userInfo.id + "-" + this.props.create.location}
                                                history = {history}
                                            />
                                        ) : (
                                            <CreateSimple
                                                key = {"create-simple-" + this.props.userInfo.id + "-" + this.props.create.info.slug}
                                                history = {history}
                                            />
                                        )
                                ) : null
                        }

                        {/* ============================================================ */}
                        {/* Gallery Simple Modal */}
                        {/* ============================================================ */}

                        {
                            (this.props.gallery.modalOn === true && this.props.gallery.info !== null)?
                            (
                                <GallerySimple
                                    key = {"gallery-simple-" + this.props.gallery.info.id}
                                    history = {history}
                                />
                            ) : null
                        }

                        {/* ============================================================ */}
                        {/* Navigation */}
                        {/* ============================================================ */}
                        <Nav history = {history} />

                        {/* ============================================================ */}
                        {/* Dots Home */}
                        {/* ============================================================ */}

                        <DotsHome
                            key = {"dots-home-" + this.props.refresh.timestamp}
                            history = {history}
                         />

                        {/* ============================================================ */}
                        {/* Main */}
                        {/* ============================================================ */}

                        <Switch>
                            <Route exact path = "/" render = {TripsHomeContainer} />
                            <Route path = "/health">
                                <h3>ok</h3>
                            </Route>
                            <Route path = "/user/:username" render = {UserContainer} />
                            <Route path = "/trips" component = {TripsView} />
                            <Route path = "/dots" component = {DotsHomeControl} />
                            <Route path = "/trip/:tripSlug" render = {TripContainer} />
                            <Route path = "/dot/:dotSlug" render = {DotContainer} />
                            <Route path = "/dot_edit/:dotSlug" render = {DotEditContainer} />
                            <Route path = "/route/:routeSlug" render = {RouteContainer} />
                            <Route path = "/plan/:planID" render = {PlanContainer} />
                            <Route path = "/itinerary/:itineraryID" render = {ItineraryContainer} />
                            <Route path = "/itineraries" render = {ItinerariesHomeContainer} />
                            <Route path = "/reset_password/:username" render = {ResetPasswordContainer} />
                            <Route path = "/create" component = {CreateView} />
                            <Route path = "/search/results/" render = {SearchResultsContainer} />
                            <Route path = "/search/location/" render = {SearchResultsLocationContainer} />
                            <Route path = "/about" component = {AboutView} />
                            <Route path = "/nationalpark/:setSlug" render = {NationalParkContainer} />
                        </Switch>
                    </div>
                </Router>
            );
        } else {
            return null
        }
    }

    resetRedux() {
        this.props.storeDotsHomeOn(false);
    }
}


function loadJS(src) {
    var ref = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        refresh: state.refresh.refresh,
        colorMode: state.nav.colorMode,
        mainMode: state.nav.mainMode,
        logInOn: state.log_in.logInOn,
        signUpOn: state.sign_up.signUpOn,
        userInfo: state.user.userInfo,
        editUserInfoOn: state.user.editUserInfoOn,
        newComment: state.board.newComment,
        newReply: state.board.newReply,
        follow: state.follow.follow,
        posting: state.posting.posting,
        dotTag: state.tag.dotTag,
        userTag: state.tag.userTag,
        memo: state.memo.memo,
        schedule: state.schedule.schedule,
        newChatMessage: state.chat.newChatMessage,
        newNotification: state.notification.newNotification,
        create: state.create.create,
        gallery: state.gallery.gallery,
        google: state.map.google
    };
}

// Dispatch to Redux store
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeColorMode,
            storeBrowserWidth,
            storeBrowserWidthPixels,
            storeUser,
            storeMap,
            storeNewComment,
            storeNewReply,
            storeNewChat,
            storeNewChatMessage,
            storeNewNotification,
            storeItinerary,
            storeItineraryParams,
            storeNewItinerary,
            storeNewParticipant,
            storeDotsHomeOn
        },
        dispatch
    );
}

// Export component
export default connect(mapStateToProps, mapDispatchToProps)(App);
