import { combineReducers } from "redux";
import refreshReducers from "./reducer_refresh";
import logInReducers from "./reducer_log_in";
import signUpReducers from "./reducer_sign_up";
import navReducers from "./reducer_nav";
import userReducers from "./reducer_user";
import alertReducers from "./reducer_alert";
import mapReducers from "./reducer_map";
import shareReducers from "./reducer_share";
import tagReducers from "./reducer_tag";
import memoReducers from "./reducer_memo";
import followReducers from "./reducer_follow";
import postingReducers from "./reducer_posting";
import moreReducers from "./reducer_more";
import boardReducers from "./reducer_board";
import notificationReducers from "./reducer_notification";
import chatReducers from "./reducer_chat";
import interactionReducers from "./reducer_interaction";
import itineraryReducers from "./reducer_itinerary";
import scheduleReducers from "./reducer_schedule";
import createReducers from "./reducer_create";
import galleryReducers from "./reducer_gallery";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const userPersistConfig = {
	key: "user",
	storage: storage,
	whitelist: ["user"]
};

const rootReducer = combineReducers({
	refresh: refreshReducers,
	log_in: logInReducers,
	sign_up: signUpReducers,
	nav: navReducers,
	user: persistReducer(userPersistConfig, userReducers),
	alert: alertReducers,
	map: mapReducers,
	share: shareReducers,
	tag: tagReducers,
	memo: memoReducers,
	follow: followReducers,
	posting: postingReducers,
	more: moreReducers,
	board: boardReducers,
	notification: notificationReducers,
	chat: chatReducers,
	interaction: interactionReducers,
	itinerary: itineraryReducers,
	schedule: scheduleReducers,
	create: createReducers,
	gallery: galleryReducers
}); 

export default rootReducer;