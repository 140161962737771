import {
    STORE_NEW_LIKE,
    STORE_NEW_SAVE
} from "actions";

const INITIAL_STATE = { 
    newLike: { id: null, liked: null, count: null },
    newSave: { id: null, saved: null, count: null }
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case STORE_NEW_LIKE:
            return { ...state, newLike: action.payload };
        case STORE_NEW_SAVE:
            return { ...state, newSave: action.payload };
        default:
        return state;
    }
};