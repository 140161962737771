/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Nav.js
    - Top menu and navigation
    - Main logo / home button
    - Navigation menu
    - Search
    - User log in / log out / sign up / create buttons
--------------------------------------------------------------------------------------------
    Content
    - Nav
    - NavUser
    - Auth
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

// Modules
import ReactTooltip from "thedots-tooltip";

// Components
import SearchInput from "components/Search";
import Chat, { ChatWindow } from "components/Chat";
import Notification from "components/Notification";
import Itinerary from "components/Itinerary";

// Axios
import {
    patchUnseenCount,
    getChats,
    getChatMessages,
    getSingleChatMessage,
    postAddChat,
    deleteChatRoom,
    getItineraries,
    getNotifications,
    patchUser
} from "requests";

// Functions
import { 
    url,
    getStaticPath,
    getMediaProperty
} from "js/Functions";

// Redux
import {
    storeUser,
    storeColorMode,
    storeMainMode,
    storeItineraryAnimation,
    storeChatMenuOn,
    storeItineraryMenuOn,
    storeNotificationMenuOn,
    storeLogInOn,
    storeSignUpOn,
    storeNewNotification,
    storeNewChat,
    storeNewChatMessage,
    storeNewItinerary,
    storeItinerariesFetchComplete,
    storeSchedule
} from "actions";

import {
    addWebSocketGroups,
    removeWebSocketGroups
} from "js/WebSocketFunctions";

// CSS
import "./Nav.scss";


// Nav component
class Nav extends Component {
    constructor(props) {
        super(props);

        // Menu button DOM node
        this.mainMenuRef = React.createRef();

        this.state = {
            mainModeDotsHovered: false,
            mainModeTripsHovered: false,
            mainMenuOn: false
        };

        // Bind functions
        this.signUpButtonClick = this.signUpButtonClick.bind(this);
        this.logInButtonClick = this.logInButtonClick.bind(this);
        this.logOutButtonClick = this.logOutButtonClick.bind(this);
        this.colorModeButtonClick = this.colorModeButtonClick.bind(this);
        this.mainModeDotsClick = this.mainModeDotsClick.bind(this);
        this.mainModeTripsClick = this.mainModeTripsClick.bind(this);
        this.mainModeDotsHoverOn = this.mainModeDotsHoverOn.bind(this);
        this.mainModeDotsHoverOff = this.mainModeDotsHoverOff.bind(this);
        this.mainModeTripsHoverOn = this.mainModeTripsHoverOn.bind(this);
        this.mainModeTripsHoverOff = this.mainModeTripsHoverOff.bind(this);
        this.mainMenuOff = this.mainMenuOff.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
    }

    render() {
        //console.log("Nav / this.props = ", this.props);

        // Check if user is logged in
        const loggedIn = (!!localStorage.token) && (this.props.userInfo !== null);

        // Layout switches
        let logoSmall = false;
        let profileSmall = false;
        let animationSmall = false;
        let animationMedium = false;
        let searchOff = false;
        let searchSmall = false;
        let nameOff = false;
        let buttonsOff = false;
        let authOff = false;

        if (loggedIn) {
            logoSmall = (this.props.browserWidth <= window.browserWidthSmall);
            profileSmall = (this.props.browserWidth <= window.browserWidthSmall);
            animationSmall = (this.props.browserWidth <= window.browserWidthSmall);
            animationMedium = (this.props.browserWidth <= 6);
            searchOff = (this.props.browserWidth <= 10);
            searchSmall = (this.props.browserWidth <= 11);
            nameOff = (this.props.browserWidth <= 7);
            buttonsOff = (this.props.browserWidth <= 8);
            authOff = (this.props.browserWidth <= 6);
        }
        else {
            logoSmall = (this.props.browserWidth <= window.browserWidthSmall);
            animationSmall = (this.props.browserWidth <= window.browserWidthSmall);
            animationMedium = (this.props.browserWidth <= 6);
            searchOff = (this.props.browserWidth <= 8);
            searchSmall = (this.props.browserWidth <= 9);
            authOff = (this.props.browserWidth <= 3);
        }

        // Get the main mode icon opacities
        const mainModeDotsOpacity = ((this.props.mainMode === "dots") || (this.state.mainModeDotsHovered))?
            0.8 : ((this.props.colorMode === "day")? 0.6 : 0.4);
        const mainModeTripsOpacity = ((this.props.mainMode === "trips") || (this.state.mainModeTripsHovered))?
            0.8 : ((this.props.colorMode === "day")? 0.6 : 0.4);

        // Main mode images
        const logoImage = (this.props.colorMode === "day")?
            getStaticPath("/images/logo/logo-black.png") : getStaticPath("/images/logo/logo-white.png");

        const mainModeDotsImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/type-dot-black.png") :
            getStaticPath("/images/common/type-dot-white.png");

        const mainModeTripsImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/type-trip-black.png") :
            getStaticPath("/images/common/type-trip-white.png");

        // Animation image and text
        const itineraryAnimationImage = (this.props.itineraryAnimation === null)? null :
        ((this.props.itineraryAnimation === "find")?
            ((this.props.colorMode === "day")?
                    getStaticPath("/images/common/find-animation-black.gif") :
                    getStaticPath("/images/common/find-animation-white.gif"))
                :
            ((this.props.colorMode === "day")?
                getStaticPath("/images/common/connect-animation-black.gif") :
                getStaticPath("/images/common/connect-animation-white.gif"))
        );
        const itineraryAnimationText = (this.props.itineraryAnimation === null)? null :
        ((this.props.itineraryAnimation === "find")? "Finding Dots" : "Connecting Dots");

        // User related components
        const user = (loggedIn)? (
            <NavUser
                nameOff = {nameOff}
                buttonsOff = {buttonsOff}
                profileSmall = {profileSmall}

                colorMode = {this.props.colorMode}

                chatMenuOn = {this.props.chatMenuOn}
                itineraryMenuOn = {this.props.itineraryMenuOn}
                notificationMenuOn = {this.props.notificationMenuOn}

                logInOn = {this.props.logInOn}
                signUpOn = {this.props.signUpOn}
                userInfo = {this.props.userInfo}
                editUserInfoOn = {this.props.editUserInfoOn}

                itinerariesFetchComplete = {this.props.itinerariesFetchComplete}

                storeChatMenuOn = {this.props.storeChatMenuOn}
                storeItineraryMenuOn = {this.props.storeItineraryMenuOn}
                storeNotificationMenuOn = {this.props.storeNotificationMenuOn}
                storeLogInOn = {this.props.storeLogInOn}
                storeSignUpOn = {this.props.storeSignUpOn}
                storeUser = {this.props.storeUser}
                storeEditUserInfoOn = {this.props.storeEditUserInfoOn}
                storeNewNotification = {this.props.storeNewNotification}
                storeNewChat = {this.props.storeNewChat}
                storeNewChatMessage = {this.props.storeNewChatMessage}
                storeNewItinerary = {this.props.storeNewItinerary}
                storeItinerariesFetchComplete = {this.props.storeItinerariesFetchComplete}
                storeSchedule = {this.props.storeSchedule}

                newNotification = {this.props.newNotification}
                newChat = {this.props.newChat}
                newChatMessage = {this.props.newChatMessage}
                newItinerary = {this.props.newItinerary}
            />
        ) : null;

        // Main menu button
        const mainMenuButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/main-menu-black.png") :
            getStaticPath("/images/common/main-menu-white.png");

        const mainMenuButton = (
            <div id = "nav-main-menu-button"
                className = "image-button-weak-s5"
                style = {{ backgroundImage: mainMenuButtonImage }}
                onClick = {
                    (event) => {
                        //console.log("onClick - event = ", event);
                        if (!window.isMobile) {
                            this.setState(
                                {
                                    mainMenuOn: !this.state.mainMenuOn
                                }
                                //() => { console.log("onClick - this.state.mainMenuOn (after update) = ", this.state.mainMenuOn); }
                            );
                        }
                    }
                }
            >
            </div>
        );

        const mainMenuTextClassName = (this.props.colorMode === "day")?
            "nav-main-menu-text k5" : "nav-main-menu-text w5";

/*

                <div className = {(this.props.colorMode === "day")?
                        "nav-main-menu-line-day" :
                        "nav-main-menu-line-night"}
                >
                </div>


*/
        // Common Items
        const mainMenuCommonItems = (
            <div id = "nav-main-menu-common-items">
                <div className = "nav-main-menu-item"
                    onClick = {(event) => {this.mainMenuOff(event);}}
                >
                    <Link to = {`/about`}>
                        <div className = {mainMenuTextClassName}>
                           About
                        </div>
                    </Link>
                </div>

                <div className = "nav-main-menu-item"
                   onClick = {(event) => {this.mainMenuOff(event);}}
                >
                    <div className = {mainMenuTextClassName}>
                       Help
                    </div>
                </div>

                <div className = {(this.props.colorMode === "day")?
                        "nav-main-menu-line-day" :
                        "nav-main-menu-line-night"}
                >
                </div>

                <div className = "nav-main-menu-item"
                    onClick = {this.colorModeButtonClick}
                >
                    <div className = {mainMenuTextClassName}>
                        {(this.props.colorMode === "day")? "Night Mode" : "Day Mode"}
                    </div>
                </div>
            </div>
        );


        // Main menu
        const mainMenuLoggedOutItems = (loggedIn)?
            null:
            (
                <div className = "nav-main-menu-items">
                    <div className = "nav-main-menu-item-search"
                        style = {{ display: (searchOff)? "block" : "none" }}
                    >
                        <SearchInput
                            loggedIn = {loggedIn}
                            inMenu = {true}
                            searchOff = {searchOff}
                            searchSmall = {searchSmall}
                            history = {this.props.history}
                            mainMenuOff = {this.mainMenuOff}
                        />
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "nav-main-menu-line-day" :
                            "nav-main-menu-line-night"}
                        style = {{ display: (searchOff)? "block": "none"}}
                    >
                    </div>

                    <div className = "nav-main-menu-item"
                        style = {{ display: (authOff)? "block": "none"}}
                        onClick = {(event) => {this.signUpButtonClick(event);}}
                    >
                        <div className = {mainMenuTextClassName}>
                            Sign Up
                        </div>
                    </div>

                    <div className = "nav-main-menu-item"
                        style = {{ display: (authOff)? "block": "none"}}
                        onClick = {(event) => {this.logInButtonClick(event);}}
                    >
                        <div className = {mainMenuTextClassName}>
                            Log In
                        </div>
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "nav-main-menu-line-day" :
                            "nav-main-menu-line-night"}
                        style = {{ display: (authOff)? "block": "none"}}
                    >
                    </div>

                    {mainMenuCommonItems}
                </div>
            );

        const mainMenuLoggedInItems = (loggedIn)?
            (
                <div className = "nav-main-menu-items">
                    <div className = "nav-main-menu-item"
                        style = {{ display: (nameOff)? "block": "none"}}
                    >
                        <Link to = {`/user/${this.props.userInfo.username}`}>
                            <div id = "nav-user-info-name"
                                className = {mainMenuTextClassName}
                                onClick = {(event) => {this.mainMenuOff(event);}}
                            >
                                {this.props.userInfo.name}
                            </div>
                        </Link>
                    </div>
                    <div className = {(this.props.colorMode === "day")?
                            "nav-main-menu-line-day" :
                            "nav-main-menu-line-night"}
                        style = {{ display: (nameOff)? "block": "none"}}
                    >
                    </div>
                    <div className = "nav-main-menu-item-search"
                        style = {{ display: (searchOff)? "block": "none"}}
                    >
                        <SearchInput
                            loggedIn = {loggedIn}
                            inMenu = {true}
                            searchOff = {searchOff}
                            searchSmall = {searchSmall}
                            history = {this.props.history}
                            mainMenuOff = {this.mainMenuOff}
                        />
                    </div>
                    <div className = {(this.props.colorMode === "day")?
                            "nav-main-menu-line-day" :
                            "nav-main-menu-line-night"}
                        style = {{ display: (searchOff)? "block": "none"}}
                    >
                    </div>

                    <div id = "nav-main-menu-scroll-container">
                        <div className = "nav-main-menu-item"
                            style = {{ display: (authOff)? "block": "none"}}
                        >
                            <Link to = {`/create`}>
                                <div className = {mainMenuTextClassName}
                                    onClick = {(event) => {this.mainMenuOff(event);}}
                                >
                                    Create
                                </div>
                            </Link>
                        </div>

                        <div className = "nav-main-menu-item"
                            style = {{ display: (authOff)? "block": "none"}}
                            onClick = {(event) => {this.logOutButtonClick(event);}}
                        >
                            <div className = {mainMenuTextClassName}>
                                Log Out
                            </div>
                        </div>

                        <div className = {(this.props.colorMode === "day")?
                                "nav-main-menu-line-day" :
                                "nav-main-menu-line-night"}
                            style = {{ display: (authOff)? "block": "none"}}
                        >
                        </div>

                        <div className = "nav-main-menu-item"
                            style = {{ display: (buttonsOff)? "block": "none"}}
                            onClick = {
                                (event) => {
                                    this.props.storeChatMenuOn(!this.props.chatMenuOn);
                                    this.mainMenuOff(event);
                                }
                            }
                        >
                            <div className = {mainMenuTextClassName}>
                                Chats
                            </div>
                        </div>

                        <div className = "nav-main-menu-item"
                            style = {{
                                display: (buttonsOff)? "block": "none"
                            }}
                            onClick = {
                                (event) => {
                                    this.props.storeItineraryMenuOn(!this.props.itineraryMenuOn);
                                    this.mainMenuOff(event);
                                }
                            }
                        >
                            <div className = {mainMenuTextClassName}>
                                Itineraries
                            </div>
                        </div>

                        <div className = "nav-main-menu-item"
                            style = {{ display: (buttonsOff)? "block": "none" }}
                            onClick = {
                                (event) => {
                                    this.props.storeNotificationMenuOn(!this.props.notificationMenuOn);
                                    this.mainMenuOff(event);
                                }
                            }
                        >
                            <div className = {mainMenuTextClassName}>
                                Notifications
                            </div>
                        </div>

                        <div className = {(this.props.colorMode === "day")?
                                "nav-main-menu-line-day" :
                                "nav-main-menu-line-night"}
                            style = {{ display: (buttonsOff)? "block": "none"}}
                        >
                        </div>
                        {mainMenuCommonItems}
                    </div>
                </div>
            ) : null;

        const menuTriangleImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/top-menu-triangle-day.png") :
            getStaticPath("/images/common/top-menu-triangle-night.png");

        const mainMenu = (this.state.mainMenuOn)?
            (
                <div id = "nav-main-menu">
                    <div id = "nav-main-menu-triangle"
                        style = {{ backgroundImage: menuTriangleImage }}
                    >
                    </div>
                    <div id = "nav-main-menu-shadow">
                        <div id = "nav-main-menu-header"
                            className = {(this.props.colorMode === "day")?
                            "nav-main-menu-header-day k5" :
                            "nav-main-menu-header-night w5"}
                        >
                        </div>
                        <div id = "nav-main-menu-container"
                            className = {(this.props.colorMode === "day")?
                                "nav-main-menu-container-day" :
                                "nav-main-menu-container-night"}
                        >
                        {mainMenuLoggedOutItems}
                        {mainMenuLoggedInItems}
                        </div>
                        <div id = "nav-main-menu-footer"
                            className = {(this.props.colorMode === "day")?
                            "nav-main-menu-footer-day" :
                            "nav-main-menu-footer-night"}
                        >
                        </div>
                    </div>
                </div>
            ) : null;
        //console.log("Nav / render - this.state.mainMenuOn = ", this.state.mainMenuOn);
        //console.log("Nav / render - mainMenu = ", mainMenu);

        return (
            <div id = "nav-container"
                className = {(this.props.colorMode === "day")?
                    "nav-container-day" : "nav-container-night"}
            >
                <div className = "body-narrow">
                    <div id = {(logoSmall)? "nav-small" : "nav"}>
                        <div id = "nav-logo">
                            <Link to = {`/dots`}>
                                <div id = {(logoSmall)? "main-mode-dots-small" : "main-mode-dots"}
                                    className = "image-button-base"
                                    data-tip = "Discover Dots"
                                    data-for = "main-mode-dots-tooltip"
                                    style = {{ backgroundImage: mainModeDotsImage, opacity: mainModeDotsOpacity }}
                                    onClick = {this.mainModeDotsClick}
                                    onMouseEnter = {this.mainModeDotsHoverOn}
                                    onMouseLeave = {this.mainModeDotsHoverOff}
                                >
                                </div>
                            </Link>
                            <ReactTooltip
                                id = "main-mode-dots-tooltip"
                                className = "nav-tooltip tooltip-s2"
                                type = "dark"
                                place = "top"
                            />
                            <Link to = {`/dots`}>
                                <div id = {(logoSmall)? "logo-small" : "logo"}
                                    className = "image-cover"
                                    style = {{ backgroundImage:  logoImage }}>
                                </div>
                            </Link>
                            <Link to = {`/trips`}>
                                <div id = {(logoSmall)? "main-mode-trips-small" : "main-mode-trips"}
                                    className = "image-button-base"
                                    data-tip = "Experience Trips"
                                    data-for = "main-mode-trips-tooltip"
                                    style = {{ backgroundImage: mainModeTripsImage, opacity: mainModeTripsOpacity }}
                                    onClick = {this.mainModeTripsClick}
                                    onMouseEnter = {this.mainModeTripsHoverOn}
                                    onMouseLeave = {this.mainModeTripsHoverOff}
                                >
                                </div>
                            </Link>
                            <ReactTooltip
                                id = "main-mode-trips-tooltip"
                                className = "nav-tooltip tooltip-s2"
                                type = "dark"
                                place = "top"
                            />
                        </div>
                        <div id = "nav-status">
                            <div id = "nav-status-content"
                                className = {
                                    (animationMedium)?
                                        (
                                            (animationSmall)?
                                                (
                                                    (this.props.colorMode === "day")?
                                                        "nav-status-small-day" :
                                                        "nav-status-small-night"
                                                ) : (
                                                    (this.props.colorMode === "day")?
                                                        "nav-status-medium-day" :
                                                        "nav-status-medium-night"
                                                )
                                        ) : "nav-status"
                                }
                                style = {{ display: (this.props.itineraryAnimation === null)?
                                    "none" : "inline-block"
                                }}
                            >
                                <div id = "itinerary-animation"
                                    className = "clear-fix"
                                >
                                    <div id = {(animationMedium)?
                                            "itinerary-animation-image-medium" :
                                            "itinerary-animation-image"
                                        }
                                        className = "image-contain"
                                        style = {{ backgroundImage: itineraryAnimationImage }}>
                                    </div>
                                    <div id = "itinerary-animation-title"
                                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                                    >
                                        {itineraryAnimationText}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div id = "nav-search">
                            <SearchInput
                                loggedIn = {loggedIn}
                                inMenu = {false}
                                searchOff = {searchOff}
                                searchSmall = {searchSmall}
                                history = {this.props.history}
                            />
                        </div>

                        <div id = "nav-user"
                            style = {{ display: (user === null)? "none" : "table-cell"}}
                        >
                            {user}
                        </div>

                        <div id = "nav-auth"
                            style = {{ display: (authOff)? "none" : "table-cell" }}
                        >
                            <Auth
                                loggedIn = {loggedIn}
                                colorMode = {this.props.colorMode}
                                logInOn = {this.props.logInOn}
                                signUpOn = {this.props.signUpOn}
                                userInfo = {this.props.userInfo}
                                editUserInfoOn = {this.props.editUserInfoOn}
                                storeLogInOn = {this.props.storeLogInOn}
                                storeSignUpOn = {this.props.storeSignUpOn}
                                storeUser = {this.props.storeUser}
                                storeEditUserInfoOn = {this.props.storeEditUserInfoOn}
                                logInButtonClick = {this.logInButtonClick}
                                logOutButtonClick = {this.logOutButtonClick}
                                signUpButtonClick = {this.signUpButtonClick}
                            />
                        </div>
                        <div id = "nav-menu"
                            ref = {this.mainMenuRef}
                        >
                            {mainMenuButton}
                            {mainMenu}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        //document.addEventListener("keydown", this.escFunction, false);
        document.addEventListener("mousedown", this.clickOutside);
    }

    componentWillUnmount() {
        //document.removeEventListener("keydown", this.escFunction, false);
        document.removeEventListener("mousedown", this.clickOutside);
    }

    escFunction = (event) => {
        // Close modal on pressing esc
        if (this.state.mainMenuOn === true && event.keyCode === 27) {
            this.setState(
                {
                    mainMenuOn: false
                }
            );
        }
    }

    // Close main menu clicking ouside
    clickOutside = (event) => {
        if (this.mainMenuRef.current && !this.mainMenuRef.current.contains(event.target)) {
            this.setState(
                {
                    mainMenuOn: false
                }
            );
        }
    }

    mainMenuOff(event) {
        //event.stopPropagation();
        //event.preventDefault();

        this.setState({
            mainMenuOn: false
        });
    }

    colorModeButtonClick(event) {
        if (this.props.colorMode === "day") {
            // Store color mode
            this.props.storeColorMode("night");

            // Change body style
            document.body.style.backgroundColor = window.colorBlack;

            // Change user default color mode
            if (!!localStorage.token && this.props.userInfo !== null) {
                const axiosCallback = (response) => {
                    //console.log("[WARNING] Nav / colorModeButtonClick - response = ", response);

                    // Get new user info
                    const userInfo = response.data.content;

                    // Store new user info
                    this.props.storeUser(userInfo);
                };

                const dataJSON = {
                    color_mode: "night"
                };

                // Patch user
                patchUser(this.props.userInfo.username, dataJSON)
                .then(axiosCallback)
                .catch((response) => {
                    console.log("[WARNING] Nav / colorModeButtonClick - axios error = ", response);
                });
            }
        }
        else {
            // Store color mode
            this.props.storeColorMode("day");

            // Change body style
            document.body.style.backgroundColor = window.colorAlmostWhite;

            // Change user default color mode
            if (!!localStorage.token && this.props.userInfo !== null) {
                const axiosCallback = (response) => {
                    //console.log("[WARNING] Nav / colorModeButtonClick - response = ", response);

                    // Get new user info
                    const userInfo = response.data.content;

                    // Store new user info
                    this.props.storeUser(userInfo);
                };

                const dataJSON = {
                    color_mode: "day"
                };

                // Patch user
                patchUser(this.props.userInfo.username, dataJSON)
                .then(axiosCallback)
                .catch((response) => {
                    console.log("[WARNING] Nav / colorModeButtonClick - axios error = ", response);
                });
            }
        }

        this.setState(
            {
                mainMenuOn: false
            }
        );
    }

    logInButtonClick(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Dispatch to Redux store
        this.props.storeLogInOn(true);

        this.setState(
            {
                mainMenuOn: false
            }
        );
    }

    signUpButtonClick(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Dispatch to Redux store
        this.props.storeSignUpOn(true);

        this.setState(
            {
                mainMenuOn: false
            }
        );
    }

    // Log out button click
    logOutButtonClick(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Dispatch to Redux store
        this.props.storeUser(null);

        // delete the token
        delete localStorage.token;

        // Log out callback
        const logOutCallback = () => {
            // Log out of Facebook if logged in
            window.FB.getLoginStatus(function(response) {
                //console.log("Nav / logOutButtonClick - Facebook log out attempt");
                if (response.status === "connected") {
                    //console.log("Nav / logOutButtonClick - Facebook connected");

                    // Log out of Facebook
                    window.FB.logout();
                    //console.log("Nav / logOutButtonClick - Facebook signed out");
                }
                else if (response.status === "not_authorized") {
                    //console.log("Nav / logOutButtonClick - Facebook not authorized");
                }
                else {
                    //console.log("Nav / logOutButtonClick - Facebook status unknown");
                }
            });

            // Sign out of Google if logged in
            if (window.googleAuth.isSignedIn.get()) {
                //console.log("Nav / logOutButtonClick - Google log out attempt");

                window.googleAuth.signOut().then(
                    () => {
                        //console.log("Nav / logOutButtonClick - Google signed out");
                    }
                );
            }

            this.setState(
                {
                    mainMenuOn: false
                }
            );
        };

        // Execute log out callback
        logOutCallback();

        // Re-direct to the root
        this.props.history.push("/");
    }

    mainModeDotsClick() {
        this.props.storeMainMode("dots")
    }

    mainModeDotsHoverOn() {
        this.setState({
            mainModeDotsHovered: true
        });
    }

    mainModeDotsHoverOff() {
        this.setState({
            mainModeDotsHovered: false
        });
    }

    mainModeTripsClick() {
        this.props.storeMainMode("trips")
    }

    mainModeTripsHoverOn() {
        this.setState({
            mainModeTripsHovered: true
        });
    }

    mainModeTripsHoverOff() {
        this.setState({
            mainModeTripsHovered: false
        });
    }
}


// Stateless component to render user info
class NavUser extends Component {
    constructor(props) {
        super(props);

        // Number of itineraries per query
        this.numItinerariesPerQuery = 10

        // WebSocket prefixs
        this.webSocketChatUserGroupPrefix = "chat_user";
        this.webSocketChatGroupPrefix = "chat";
        this.webSocketCalendarGroupPrefix = "calendar_user";
        this.webSocketNotificationGroupPrefix = "notification_user";

        // WebSocket groups
        this.webSocketChatUserGroups = [];
        this.webSocketChatGroups = [];
        this.webSocketCalendarGroup = [];
        this.webSocketNotificationGroup = [];

        // Chat window size
        this.chatWindowWidth = 300;
        this.chatWindowSpacing = 40;
        this.chatWindowEndMargin = 40;

        // Chat number of profile pics
        this.chatNumProfilePics = 3;

        // DOM nodes
        this.notificationNode = null;

        // Max name length
        this.maxNameLength = 10;

        // Calculate max number of chat windows
        const windowWidth = (window.innerWidth || document.body.clientWidth);
        const numChatWindowsMax = Math.floor(
            (windowWidth - this.chatWindowEndMargin * 2 + this.chatWindowSpacing) / (this.chatWindowWidth + this.chatWindowSpacing)
        );
        this.chatWindowEndSpacing = Math.round((windowWidth - this.chatWindowEndMargin * 2 -
            (numChatWindowsMax * this.chatWindowWidth + (numChatWindowsMax - 1) * this.chatWindowSpacing)) / 2);

        // Initialize state
        this.state = {
            newChatOpen: false,
            newGroupChatOpen: false,
            chatsInfo: [],
            chatsMessagesInfo: [],
            chatsMessagesFetchComplete: [],
            chatsChecked: [],
            chatsFetchComplete: false,
            itinerariesInfo: [],
            notificationsInfo: [],
            notificationsFetchComplete: false,
            numChatWindowsMax: numChatWindowsMax,
            chatWindowsOpen: [],
            unseenChatCount: this.props.userInfo.unseen_chat_count,
            unseenNotificationCount: this.props.userInfo.unseen_notification_count
        };

        // Bind functions
        this.setState = this.setState.bind(this);
        this.getChatsInfo = this.getChatsInfo.bind(this);
        this.getChatsMessagesInfo = this.getChatsMessagesInfo.bind(this);
        this.getChatMessagesInfo = this.getChatMessagesInfo.bind(this);
        this.getItinerariesInfo = this.getItinerariesInfo.bind(this);
        this.getNotificationsInfo = this.getNotificationsInfo.bind(this);
        this.chatClick = this.chatClick.bind(this);
        this.itineraryClick = this.itineraryClick.bind(this);
        this.notificationClick = this.notificationClick.bind(this);
        this.updateUnseenCount = this.updateUnseenCount.bind(this);
        this.updateNumChatWindowsMax = this.updateNumChatWindowsMax.bind(this);
        this.addChat = this.addChat.bind(this);
        this.closeChat = this.closeChat.bind(this);
    }


    render() {
        //console.log("NavUser / render - this.props = ", this.props);
        //console.log("NavUser / render - this.props.userInfo.profile_pic = ", this.props.userInfo.profile_pic);

        // User info
        const profilePic = (this.props.userInfo.profile_pic)?
            (
                (this.props.userInfo.profile_pic.external_url === null)?
                    getMediaProperty(this.props.userInfo.profile_pic, "t", "url", true) :
                    url(this.props.userInfo.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        // Icons
        const userInfoChatIcon = (this.props.colorMode === "day")?
            getStaticPath("/images/common/user-chat-black.png") :
            getStaticPath("/images/common/user-chat-white.png");

        const userInfoItineraryIcon = (this.props.colorMode === "day")?
            getStaticPath("/images/common/user-calendar-black.png") :
            getStaticPath("/images/common/user-calendar-white.png");

        const userInfoNotificationIcon = (this.props.colorMode === "day")?
            getStaticPath("/images/common/user-notification-black.png") :
            getStaticPath("/images/common/user-notification-white.png");

        // Unseen counters
        const unseenChatCounter = (this.state.unseenChatCount > 0)? (
            <div id = "nav-user-info-chat-counter"
                className = {(this.props.colorMode === "day")?
                    "nav-user-info-notification-day" : "nav-user-info-notification-night"}
            >
                {this.state.unseenChatCount}
            </div>
        ) : null;

        const unseenNotificationCounter = (this.state.unseenNotificationCount > 0)? (
            <div id = "nav-user-info-notfication-counter"
                className = {(this.props.colorMode === "day")?
                    "nav-user-info-notification-day" : "nav-user-info-notification-night"}
            >
                {this.state.unseenNotificationCount}
            </div>
        ) : null;

        // Chats
        const chats = (this.props.chatMenuOn)? (
            <Chat
                buttonsOff = {this.props.buttonsOff}
                setState = {this.setState}
                storeChatMenuOn = {this.props.storeChatMenuOn}
                chatOpen = {this.props.chatMenuOn}
                newChatOpen = {this.state.newChatOpen}
                newGroupChatOpen = {this.state.newGroupChatOpen}
                userInfo = {this.props.userInfo}
                chatsInfo = {this.state.chatsInfo}
                chatsMessagesInfo = {this.state.chatsMessagesInfo}
                chatsChecked = {this.state.chatsChecked}
                chatsFetchComplete = {this.state.chatsFetchComplete}
                getChatsInfo = {this.getChatsInfo}
                numChatWindowsMax = {this.state.numChatWindowsMax}
                chatWindowsOpen = {this.state.chatWindowsOpen}
                addChat = {this.addChat}
            />
        ) : null;

        // Chat windows
        const chatWindows = (this.state.chatWindowsOpen.length > 0)?
        this.state.chatWindowsOpen.map((chatID, index) => {
            if (index < this.state.numChatWindowsMax) {
                let chatInfo = null;
                let chatMessagesInfo = null;
                let chatIndex = null;
                for (let i = 0; i < this.state.chatsInfo.length; i++) {
                    if (chatID === this.state.chatsInfo[i].id) {
                        chatInfo = this.state.chatsInfo[i];
                        chatMessagesInfo = this.state.chatsMessagesInfo[i];
                        chatIndex = i;
                    }
                }

                // Figure out the update scroll switch
                const updateScroll = (this.props.newChatMessage === null)? false :
                    ((chatInfo.id === this.props.newChatMessage.chat)? true : false);
                //console.log("NavUser / render - newChatMessage = ", newChatMessage);
                //console.log("NavUser / render - updateScroll = ", updateScroll);

                return (
                    <ChatWindow
                        index = {index}
                        key = {"chat-window-" + chatInfo.id.toString()}
                        userInfo = {this.props.userInfo}
                        chatInfo = {chatInfo}
                        chatMessagesInfo = {chatMessagesInfo}
                        chatIndex = {chatIndex}
                        setState = {this.setState}
                        chatWindowWidth = {this.chatWindowWidth}
                        chatWindowSpacing = {this.chatWindowSpacing}
                        chatWindowEndSpacing = {this.chatWindowEndSpacing}
                        chatWindowEndMargin = {this.chatWindowEndMargin}
                        chatNumProfilePics = {this.chatNumProfilePics}
                        chatWindowsOpen = {this.state.chatWindowsOpen}
                        closeChat = {this.closeChat}
                        chatMessagesFetchComplete = {this.state.chatsMessagesFetchComplete[chatIndex]}
                        getChatMessagesInfo = {this.getChatMessagesInfo}
                        updateScroll = {updateScroll}
                        webSocketChatGroupPrefix = {this.webSocketChatGroupPrefix}
                    />
                );
            }
            else
            {
                return null;
            }
        }) : null;

        // Itineraries
        const itineraries = (this.props.itineraryMenuOn)? (
            <Itinerary
                buttonsOff = {this.props.buttonsOff}
                setState = {this.setState}
                storeItineraryMenuOn = {this.props.storeItineraryMenuOn}
                storeSchedule = {this.props.storeSchedule}
                itineraryOpen = {this.props.itineraryMenuOn}
                itinerariesInfo = {this.state.itinerariesInfo}
                itinerariesFetchComplete = {this.props.itinerariesFetchComplete}
                getItinerariesInfo = {this.getItinerariesInfo}
            />
        ) : null;

        // Notifications
        const notifications = (this.props.notificationMenuOn)? (
            <Notification
                buttonsOff = {this.props.buttonsOff}
                setState = {this.setState}
                storeNotificationMenuOn = {this.props.storeNotificationMenuOn}
                notificationOpen = {this.props.notificationMenuOn}
                notificationsInfo = {this.state.notificationsInfo}
                notificationsFetchComplete = {this.state.notificationsFetchComplete}
                getNotificationsInfo = {this.getNotificationsInfo}
            />
        ) : null;

        // Name
        const name = (this.props.userInfo.name.length > this.maxNameLength)?
            this.props.userInfo.first_name : this.props.userInfo.name;

        // Main JSX
        return (
            <div id = "nav-user-info-container"
                style = {{ opacity: this.props.userInfoOpacity }}
            >
                {chatWindows}
                <div id = "nav-user-info">
                    <Link id = "nav-user-info-picture"
                        to = {`/user/${this.props.userInfo.username}`}
                        className = {
                            (this.props.profileSmall)?
                            (
                                (this.props.colorMode === "day")?
                                    "image-button-strong-reverse-base border-day profile-image-s6" :
                                    "image-button-strong-base border-night profile-image-s6"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "image-button-strong-reverse-base border-day profile-image-s5" :
                                    "image-button-strong-base border-night profile-image-s5"
                            )
                        }
                        style = {{ backgroundImage: profilePic }}
                    >
                    </Link>
                    <div id = "nav-user-info-name"
                        className = "font-century"
                        style = {{
                            display : (this.props.nameOff)? "none" : "inline-block"
                        }}
                    >
                        {name}
                    </div>
                    <div id = "nav-user-info-menus">
                        <div id = "nav-user-info-menus-background"
                            className = "nav-user-info-menus-background"
                            style = {{
                                display: (this.props.buttonsOff &&
                                    (this.props.itineraryMenuOn ||this.props.chatMenuOn || this.props.notificationMenuOn))?
                                    "block" : "none"
                            }}
                        >
                        </div>
                        {chats}
                        {itineraries}
                        {notifications}
                    </div>
                    <div id = "nav-user-info-buttons"
                        style = {{
                            display: (this.props.buttonsOff)? "none" : "inline-block"
                        }}
                    >
                        <div id = "nav-user-info-chat-button">
                            <div id = "nav-user-info-chat-image"
                                className = "image-button-weak-s7"
                                style = {{ backgroundImage: userInfoChatIcon }}
                                onClick = {this.chatClick}
                            >
                            </div>
                            {unseenChatCounter}
                        </div>
                        <div id = "nav-user-info-itinerary-button">
                            <div id = "nav-user-info-itinerary-image"
                                className = "image-button-weak-s7"
                                style = {{ backgroundImage: userInfoItineraryIcon }}
                                onClick = {this.itineraryClick}
                            >
                            </div>
                        </div>

                        <div id = "nav-user-info-notification-button">
                            <div id = "nav-user-info-notification-image"
                                className = "image-button-weak-s7"
                                style = {{ backgroundImage: userInfoNotificationIcon }}
                                onClick = {this.notificationClick}
                            >
                            </div>
                            {unseenNotificationCounter}
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    componentDidMount() {
        // Fetch latest chats
        this.getChatsInfo();

        // Fetch latest itineraries
        //this.props.storeItinerariesFetchComplete(false);
        this.getItinerariesInfo();

        // Fetch latest notifications
        this.getNotificationsInfo();

        // Add webSocket chat user group
        this.webSocketChatUserGroup = [ this.webSocketChatUserGroupPrefix + "_" + this.props.userInfo.id ];
        addWebSocketGroups(this.webSocketChatUserGroup, window.webSocket);

        // Add webSocket calendar group
        this.webSocketCalendarGroup = [ this.webSocketCalendarGroupPrefix + "_" + this.props.userInfo.id ];
        addWebSocketGroups(this.webSocketCalendarGroup, window.webSocket);

        // Add webSocket notification group
        this.webSocketNotificationGroup = [ this.webSocketNotificationGroupPrefix + "_" + this.props.userInfo.id ];
        addWebSocketGroups(this.webSocketNotificationGroup, window.webSocket);

        // Add event listener
        window.addEventListener("resize", this.updateNumChatWindowsMax);
    }


    componentWillUnmount() {
        // Remove webSocket chat user groups
        removeWebSocketGroups(this.webSocketChatUserGroups, window.webSocket);

        // Remove webSocket chat groups
        removeWebSocketGroups(this.webSocketChatGroups, window.webSocket);

        // Remove webSocket notification group
        removeWebSocketGroups(this.webSocketCalendarGroup, window.webSocket);

        // Remove webSocket notification group
        removeWebSocketGroups(this.webSocketNotificationGroup, window.webSocket);

        // Remove event listener
        window.removeEventListener("resize", this.updateNumChatWindowsMax);
    }


    componentDidUpdate(prevProps, prevState) {
        //console.log("NavUser / componentDidUpdate - this.props = ", this.props);
        //console.log("NavUser / componentDidUpdate - this.props.newItinerary", this.props.newItinerary);

        // If a new itinerary
        if (((prevProps.newItinerary === null) && (this.props.newItinerary !== null)) ||
            (((prevProps.newItinerary !== null) && (this.props.newItinerary !== null))
            &&  (prevProps.newItinerary.id !== this.props.newItinerary.id))) {
            //console.log("NavUser / componentDidUpdate - new itinerary");
            //console.log("NavUser / componentDidUpdate - this.props.newItinerary", this.props.newItinerary);

            // Make a copy of currently loaded itineraries
            let itinerariesInfo = this.state.itinerariesInfo.slice();

            // If add operation
            if (this.props.newItinerary.operation === "add") {
                //console.log("NavUser / componentDidUpdate - adding itinerary");

                // Add the new itinerary to the already loaded itineraries
                const newItinerariesInfo = [];

                let itineraryAdded = false;

                // For all previous itineraries
                for (let i = 0; i < this.state.itinerariesInfo.length; i++) {
                    if (!itineraryAdded) {
                        if (this.state.itinerariesInfo[i].id === this.props.newItinerary.info.id) {
                            // Replace the old itinerary
                            newItinerariesInfo.push(this.props.newItinerary.info);
                            itineraryAdded = true;
                        }
                        else {
                            if (this.state.itinerariesInfo[i].start_time > this.props.newItinerary.info.start_time) {
                                // Add new itinerary
                                newItinerariesInfo.push(this.props.newItinerary.info);
                                itineraryAdded = true;
                            }

                            // Add the i-th old itinerary
                            newItinerariesInfo.push(this.state.itinerariesInfo[i]);
                        }
                    }
                    else {
                        // Add the i-th old itinerary
                        newItinerariesInfo.push(this.state.itinerariesInfo[i]);
                    }
                }

                // If the new itinerary is supposed to be added to the end of list
                if ((!itineraryAdded) && (itinerariesInfo.length < this.numItinerariesPerQuery)) {
                    newItinerariesInfo.push(this.props.newItinerary.info);
                }

                // Set the new itineraries
                itinerariesInfo = newItinerariesInfo;
            }
            else if (this.props.newItinerary.operation === "remove") {
                //console.log("NavUser / componentDidUpdate - removing itinerary");

                // Initialize itinerary index
                let indexToRemove = null;

                // For all previous itineraries
                for (let i = 0; i < itinerariesInfo.length; i++) {
                    if (itinerariesInfo[i].id === this.props.newItinerary.info.id) {
                        indexToRemove = i;
                    }
                }

                // Remove itinerary
                if (indexToRemove !== null) {
                    itinerariesInfo.splice(indexToRemove, 1);
                }
            }

            // Update state
            this.setState(
                {
                    itinerariesInfo: itinerariesInfo,
                    //unseenItineraryCount: (this.state.unseenItineraryCount + 1)
                },
                () => {
                    // Reset new itinerary
                    this.props.storeNewItinerary(null);
                }
            );
        }


        // If a new chat
        if ((prevProps.newChat === null) && (this.props.newChat !== null) && (this.props.newChat.type === null)) {
            //console.log("NavUser / componentDidUpdate - this.props.newChat = ", this.props.newChat);

            /*
            // Chat windows open
            const chatWindowsOpen = this.state.chatWindowsOpen.slice();

            // Chat loaded
            let chatLoaded = false;
            for (let i = 0; i < this.state.chatsInfo.length; i++) {
                if (this.props.newChat.info.id === this.state.chatsInfo[i].id) {
                    chatLoaded = true;
                }
            }
            console.log("NavUser / componentDidUpdate - chatLoaded = ", chatLoaded);

            // If the chat already exists and loaded
            if (chatLoaded) {
                // Add open window
                chatWindowsOpen.push(this.props.newChat.info.id);

                // Update state
                this.setState({
                    newChatOpen: false,
                    chatWindowsOpen: chatWindowsOpen
                });
            }
            else {
            */

            // Add new chat
            if (this.props.newChat.operation === "add") {
                // Copy the current arrays
                const chatsInfo = this.state.chatsInfo.slice();
                const chatsMessagesInfo = this.state.chatsMessagesInfo.slice();
                const chatsMessagesFetchComplete = this.state.chatsMessagesFetchComplete.slice();

                // Get chat messages
                const chatMessagesInfo = [];

                // Add to the first row of all arrays
                this.props.newChat.info["newFetched"] = true;
                chatsInfo.unshift(this.props.newChat.info);
                chatsMessagesInfo.unshift(chatMessagesInfo);
                chatsMessagesFetchComplete.unshift(null);
                //chatWindowsOpen.push(this.props.newChat.info.id);

                // Update state
                this.setState(
                    {
                        newChatOpen: false,
                        chatsInfo: chatsInfo,
                        chatsMessagesInfo: chatsMessagesInfo,
                        chatsMessagesFetchComplete: chatsMessagesFetchComplete,
                        //chatWindowsOpen: chatWindowsOpen
                    },
                    () => {
                        // Add websocket group
                        addWebSocketGroups(
                            [ this.webSocketChatGroupPrefix + "_" + this.props.newChat.info.id ],
                            window.webSocket
                        );

                        // Clear redux
                        this.props.storeNewChat(null);
                    }
                );
            }
            // Remove new chat
            else {
                // Copy the current arrays
                const chatsInfo = [];
                const chatsMessagesInfo = [];
                const chatsMessagesFetchComplete = [];

                for (let i = 0; i < this.state.chatsInfo.length; i++) {
                    if (this.state.chatsInfo[i].id !== this.props.newChat.info.id) {
                        chatsInfo.push(this.state.chatsInfo[i]);
                        chatsMessagesInfo.push(this.state.chatsMessagesInfo[i]);
                        chatsMessagesFetchComplete.push(this.state.chatsMessagesFetchComplete[i]);
                    }
                }

                // Chat windows open
                const chatWindowsOpen = this.state.chatWindowsOpen.slice();
                if (chatWindowsOpen.indexOf(this.props.newChat.info.id) >= 0) {
                    chatWindowsOpen.splice(chatWindowsOpen.indexOf(this.props.newChat.info.id), 1);
                }

                // Update state
                this.setState(
                    {
                        chatsInfo: chatsInfo,
                        chatsMessagesInfo: chatsMessagesInfo,
                        chatsMessagesFetchComplete: chatsMessagesFetchComplete,
                        chatWindowsOpen: chatWindowsOpen
                    },
                    () => {
                        // Remove websocket group
                        removeWebSocketGroups(
                            [ this.webSocketChatGroupPrefix + "_" + this.props.newChat.info.id ],
                            window.webSocket
                        );

                        // Clear redux
                        this.props.storeNewChat(null);
                    }
                );
            }
            //}
        }


        // If a new chat message
        if ((prevProps.newChatMessage === null) && (this.props.newChatMessage !== null)) {
            //console.log("NavUser / componentDidUpdate - this.props.newChatMessage = ", this.props.newChatMessage);

            const chatsInfo = [];
            const chatsMessagesInfo = [];
            let updatedChatInfo = null;
            let updatedChatMessagesInfo = null;

            // For all chats
            for (let i = 0; i < this.state.chatsInfo.length; i++) {
                const chatInfo = Object.assign({}, this.state.chatsInfo[i]);
                const chatMessagesInfo = this.state.chatsMessagesInfo[i].slice();

                // For the chat room that received the new message
                if (chatInfo.id === this.props.newChatMessage.chat) {
                    updatedChatInfo = chatInfo;
                    updatedChatMessagesInfo = chatMessagesInfo;

                    // Update the timestamp
                    updatedChatInfo.timestamp = (new Date()).getTime() / 1000;
                    updatedChatInfo.newUpdated = true;

                    // Add the new message to the list
                    chatMessagesInfo.push(this.props.newChatMessage);
                }
                else {
                    chatsInfo.push(chatInfo);
                    chatsMessagesInfo.push(chatMessagesInfo);
                }
            }

            // Slip in the updated infos
            chatsInfo.unshift(updatedChatInfo);
            chatsMessagesInfo.unshift(updatedChatMessagesInfo);

            // Increase the unseen counter if necessary
            const unseenChatCount = (this.props.userInfo.id === this.props.newChatMessage.user)?
                this.state.unseenChatCount : (this.state.unseenChatCount + 1);

            // Chat windows open
            const chatWindowsOpen = this.state.chatWindowsOpen.slice();
            if (chatWindowsOpen.indexOf(updatedChatInfo.id) === -1) {
                chatWindowsOpen.push(updatedChatInfo.id);
            }

            // Update state
            this.setState(
                {
                    chatsInfo: chatsInfo,
                    chatsMessagesInfo: chatsMessagesInfo,
                    unseenChatCount: unseenChatCount,
                    chatWindowsOpen: chatWindowsOpen
                },
                () => {
                    // Reset new chat message
                    this.props.storeNewChatMessage(null);
                }
            );
        }


        // If a new notification
        if ((prevProps.newNotification === null) && (this.props.newNotification !== null)) {
            //console.log("NavUser / componentDidUpdate - New Notification");
            //console.log("NavUser / componentDidUpdate - this.props.newNotification", this.props.newNotification);

            // Add the new notification to the already loaded notifications
            const notificationsInfo = this.state.notificationsInfo.slice();
            notificationsInfo.unshift(this.props.newNotification);

            // Update state
            this.setState(
                {
                    notificationsInfo: notificationsInfo,
                    unseenNotificationCount: (this.state.unseenNotificationCount + 1)
                },
                () => {
                    // Reset new notification
                    this.props.storeNewNotification(null);
                }
            );
        }
    }


    chatClick() {
        if (this.props.chatMenuOn) {
            // Update global state
            this.props.storeChatMenuOn(false);
        }
        else {
            // Update unseen count callback
            const updateUnseenCount = (this.state.unseenChatCount > 0)?
                () => { this.updateUnseenCount("chat"); } : null;

            // Update global state
            this.props.storeChatMenuOn(true);
            this.props.storeItineraryMenuOn(false);
            this.props.storeNotificationMenuOn(false);
            // Update state and fire updateUnseenCount if necessary
            this.setState(
                {
                    unseenChatCount: 0
                },
                updateUnseenCount
            );
        }
    }


    itineraryClick() {
        if (this.props.itineraryMenuOn) {
            // Update global state
            this.props.storeItineraryMenuOn(false);
        }
        else {
            // Update unseen count callback
            const updateUnseenCount = (this.state.unseenItineraryCount > 0)?
                () => {this.updateUnseenCount("itinerary");} : null;

            // Update global state
            this.props.storeChatMenuOn(false);
            this.props.storeItineraryMenuOn(true);
            this.props.storeNotificationMenuOn(false);

            // Update state and fire updateUnseenCount if necessary
            this.setState(
                {
                    unseenItineraryCount: 0
                },
                updateUnseenCount
            );
        }
    }


    notificationClick() {
        if (this.props.notificationMenuOn) {
            // Update global state
            this.props.storeNotificationMenuOn(false);
        }
        else {
            // Update unseen count callback
            const updateUnseenCount = (this.state.unseenNotificationCount > 0)?
                () => {this.updateUnseenCount("notification");} : null;

            // Update global state
            this.props.storeChatMenuOn(false);
            this.props.storeItineraryMenuOn(false);
            this.props.storeNotificationMenuOn(true);

            // Update state and fire updateUnseenCount if necessary
            this.setState(
                {
                    unseenNotificationCount: 0
                },
                updateUnseenCount
            );
        }
    }


    getChatsInfo() {
        //const apiURL = "/api/chats/multiple_chats_messages/";

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NavUser / getChatsInfo - response.data.content = ", response.data.content);

            // Figure out if all notifications are fetched
            const chatsFetchComplete = (response.data.content.length === 0)? true : false;

            if (!chatsFetchComplete) {
                // Chats checked
                const chatsChecked = [];
                for (let i = 0; i < response.data.content.length; i++) {
                    chatsChecked.push(false);
                }

                // Extract chat ids
                const chatIDs = [];
                for (let i = 0; i < response.data.content.length; i++) {
                    chatIDs.push(response.data.content[i].id);
                    this.webSocketChatGroups.push(this.webSocketChatGroupPrefix + "_" + response.data.content[i].id);
                }
                //console.log("NavUser / getChatsInfo - this.webSocketChatGroups = ", this.webSocketChatGroups);

                // Add webSocket groups
                addWebSocketGroups(this.webSocketChatGroups, window.webSocket);

                // Update state
                this.setState(
                    {
                        chatsInfo: this.state.chatsInfo.concat(response.data.content),
                        chatsChecked: this.state.chatsInfo.concat(chatsChecked),
                        chatsFetchComplete: chatsFetchComplete
                    },
                    () => { this.getChatsMessagesInfo(chatIDs); }
                );
            }
        };

        // Send post request using axios with CSRF token
        getChats(this.state.chatsInfo.length)
        .then(axiosCallback)
        .catch((response) => {console.log("Nav / User / getChatsInfo - Axios error ", response);});
    }


    getChatsMessagesInfo(chatIDs) {
        //console.log("Nav / getChatsMessagesInfo - chatIDs = ", chatIDs);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NavUser / getChatsMessagesInfo - response.data.content = ", response.data.content);

            // Chat messages info fetch complete
            const chatsMessagesFetchComplete = [];
            for (let i = 0; i < response.data.content.length; i++) {
                chatsMessagesFetchComplete.push(null);
            }

            // Update state
            this.setState({
                chatsMessagesInfo: this.state.chatsMessagesInfo.concat(response.data.content),
                chatsMessagesFetchComplete:
                    this.state.chatsMessagesFetchComplete.concat(chatsMessagesFetchComplete)
            });
        };

        // Only if when (this.state.chatsInfo.length > 0)
        // Not necessary when there are no chats (causes error at the FetchChatView)
        if (this.state.chatsInfo.length > 0) {
            // Send post request using axios with CSRF token
            getChatMessages(chatIDs)
            .then(axiosCallback)
            .catch((response) => {console.log("Nav / User / getChatsMessagesInfo - Axios error ", response);});
        }
    }


    getChatMessagesInfo(chatID, chatIndex) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NavUser / getChatMessagesInfo - response.data.content = ", response.data.content);

            // If the fetch is complete
            if (response.data.content.length === 0) {
                //console.log("NavUser / getChatMessagesInfo - fetch complete");

                // Initialize chats messages fetch complete
                const chatsMessagesFetchComplete = [];

                // For all chats
                for (let i = 0; i < this.state.chatsInfo.length; i++) {
                    if (i === chatIndex) {
                        chatsMessagesFetchComplete[i] = true;
                    }
                    else {
                        chatsMessagesFetchComplete[i] = this.state.chatsMessagesFetchComplete[i];
                    }
                }

                // Update state
                this.setState(
                    {
                        chatsMessagesFetchComplete: chatsMessagesFetchComplete
                    }
                );
            }
            else {
                // Initialize chats messages
                const chatsMessagesInfo = [];

                // Copy chat messages
                const chatMessagesInfo = this.state.chatsMessagesInfo[chatIndex].slice();

                // For all chats
                for (let i = 0; i < this.state.chatsMessagesInfo.length; i++) {
                    if (i === chatIndex) {
                        chatsMessagesInfo.push(response.data.content.concat(chatMessagesInfo));
                    }
                    else {
                        chatsMessagesInfo.push(this.state.chatsMessagesInfo[i]);
                    }
                }
                //console.log("NavUser / getChatMessagesInfo - this.state.chatsMessagesInfo= ", this.state.chatsMessagesInfo);
                //console.log("NavUser / getChatMessagesInfo - chatsMessagesInfo = ", chatsMessagesInfo);

                // Update state
                this.setState(
                    {
                        chatsMessagesInfo: chatsMessagesInfo
                    }
                );
            }
        };

        // Send post request using axios with CSRF token
        getSingleChatMessage(chatID, this.state.chatsMessagesInfo[chatIndex].length)
        .then(axiosCallback)
        .catch((response) => {console.log("Nav / User / getChatMessagesInfo - Axios error ", response);});
    }


    getItinerariesInfo() {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NavUser / getItinerariesInfo - response = ", response);

            // Figure out if all itineraries are fetched
            const itinerariesFetchComplete = (response.data.content.length === 0)? true : false;

            // Update state
            this.setState(
                {
                    itinerariesInfo: this.state.itinerariesInfo.concat(response.data.content),
                },
                () => {
                    this.props.storeItinerariesFetchComplete(itinerariesFetchComplete);
                }
            );
        };

        // Send post request using axios with CSRF token
        getItineraries(this.state.itinerariesInfo.length)
        .then(axiosCallback)
        .catch((response) => {console.log("Nav / User / getItinerariesInfo - Axios error ", response);});
    }


    getNotificationsInfo() {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NavUser / getNotificationsInfo - response = ", response);

            // Figure out if all notifications are fetched
            const notificationsFetchComplete = (response.data.content.length === 0)? true : false;

            // Update state
            this.setState(
                {
                    notificationsInfo: this.state.notificationsInfo.concat(response.data.content),
                    notificationsFetchComplete : notificationsFetchComplete
                }
            );
        };

        // Send post request using axios with CSRF token
        getNotifications(this.state.notificationsInfo.length)
        .then(axiosCallback)
        .catch((response) => {console.log("Nav / User / getNotificationsInfo - Axios error ", response);});
    }


    updateUnseenCount(type) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            // console.log("NavUser / updateUnseenCount - response = ", response);
            this.props.storeUser(response.data.content);
        };

        // Request data
        let dataJSON = null;
        if (type === "chat") {
            dataJSON = {
                unseen_chat_count: 0
            };
        }
        //else if (type === "itinerary") {
        //    dataJSON = {
        //        unseen_itinerary_count: 0
        //    };
        //}
        else if (type === "notification") {
            dataJSON = {
                unseen_notification_count: 0
            };
        }
        else {
            console.log("Nav / User / updateUnseenCount - type error");
        }

        // Send post request using axios with CSRF token
        patchUnseenCount(this.props.userInfo.username, dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("Nav / User / updateUnseenCount - Axios error ", response);});
    }


    updateNumChatWindowsMax() {
        // Recalculate max number of chat windows and end margin
        const windowWidth = (window.innerWidth || document.body.clientWidth);
        const numChatWindowsMax = Math.floor(
            (windowWidth - this.chatWindowEndMargin * 2 + this.chatWindowSpacing) / (this.chatWindowWidth + this.chatWindowSpacing)
        );
        this.chatWindowEndSpacing = Math.round((windowWidth - this.chatWindowEndMargin * 2 -
            (numChatWindowsMax * this.chatWindowWidth + (numChatWindowsMax - 1) * this.chatWindowSpacing)) / 2);

        // Update the open chat ID array
        const chatWindowsOpen = this.state.chatWindowsOpen.slice();
        if (chatWindowsOpen.length > numChatWindowsMax) {
            chatWindowsOpen.splice(0, (chatWindowsOpen.length - numChatWindowsMax));
        }

        // Update state
        this.setState({
            numChatWindowsMax: numChatWindowsMax,
            chatWindowsOpen: chatWindowsOpen
        });
    }


    addChat(userIDs) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NavUser / addChat - response = ", response);

            // Chat windows open
            const chatWindowsOpen = this.state.chatWindowsOpen.slice();

            // Chat loaded
            let chatLoaded = null;
            for (let i = 0; i < this.state.chatsInfo.length; i++) {
                if (response.data.content.id === this.state.chatsInfo[i].id) {
                    chatLoaded = true;
                }
            }

            // If the chat already exists and loaded
            if ((response.data.content.exist) && (chatLoaded)) {
                // Add open window
                chatWindowsOpen.push(response.data.content.id);

                // Update state
                this.setState({
                    newChatOpen: false,
                    chatWindowsOpen: chatWindowsOpen
                });
            }
            else {
                // Copy the current arrays
                const chatsInfo = this.state.chatsInfo.slice();
                const chatsMessagesInfo = this.state.chatsMessagesInfo.slice();
                const chatsMessagesFetchComplete = this.state.chatsMessagesFetchComplete.slice();

                // Get chat messages
                const chatMessagesInfo = (response.data.content.exist)? response.data.content.messages : [];

                // Add to the first row of all arrays
                response.data.content["newFetched"] = true;
                chatsInfo.unshift(response.data.content);
                chatsMessagesInfo.unshift(chatMessagesInfo);
                chatsMessagesFetchComplete.unshift(null);
                chatWindowsOpen.push(response.data.content.id);

                // Update state
                this.setState(
                    {
                        newChatOpen: false,
                        chatsInfo: chatsInfo,
                        chatsMessagesInfo: chatsMessagesInfo,
                        chatsMessagesFetchComplete: chatsMessagesFetchComplete,
                        chatWindowsOpen: chatWindowsOpen
                    },
                    // Add webSocket group
                    () => {
                        addWebSocketGroups(
                            [ this.webSocketChatGroupPrefix + "_" + response.data.content.id ],
                            window.webSocket
                        );
                    }
                );
            }
        };

        // Request data to send
        const dataJSON = {
            user_ids: userIDs,
            user_count: userIDs.length
        };

        // Send post request using axios with CSRF token
        postAddChat(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("Nav / User / addChat - Axios error ", response);});
    }


    closeChat(windowIndex, chatIndex) {
        // Update the open chat ID array
        const chatWindowsOpen = this.state.chatWindowsOpen.slice();
        chatWindowsOpen.splice(windowIndex, 1);
        //console.log("NavUser / closeChat - chatWindowsOpen = ", chatWindowsOpen);

        // Get the chats
        const chatsInfo = this.state.chatsInfo.slice();
        const chatInfo = chatsInfo.splice(chatIndex, 1)[0];
        //console.log("NavUser / closeChat - chatsInfo = ", chatsInfo);
        //console.log("NavUser / closeChat - chatInfo = ", chatInfo);

        // Get the messages
        const chatsMessagesInfo = this.state.chatsMessagesInfo.slice();
        const chatMessagesInfo = chatsMessagesInfo.splice(chatIndex, 1)[0];
        //console.log("NavUser / closeChat - chatsMessagesInfo = ", chatsMessagesInfo);
        //console.log("NavUser / closeChat - chatMessagesInfo = ", chatMessagesInfo);

        // Get the messages fetch complete
        const chatsMessagesFetchComplete = this.state.chatsMessagesFetchComplete.slice();
        // const chatMessagesFetchComplete = chatsMessagesFetchComplete.splice(chatIndex, 1)[0];

        // If the chat is already loaded
        if (!chatInfo.newFetched) {
            // Update state
            this.setState({
                chatWindowsOpen: chatWindowsOpen
            });
        }
        // If the chat is newly fetched
        else {
            // If the chat received new messages through webSocket since loading
            if (chatInfo.newUpdated) {
                // Update state
                this.setState({
                    chatWindowsOpen: chatWindowsOpen
                });
            }
            // No new messages were added
            else {
                // Update state
                this.setState(
                    {
                        chatWindowsOpen: chatWindowsOpen,
                    },
                    () => {
                        this.setState({
                            chatsInfo: chatsInfo,
                            chatsMessagesInfo: chatsMessagesInfo,
                            chatsMessagesFetchComplete: chatsMessagesFetchComplete
                        });
                    }
                );
            }

            // Remove chat room if no messages were added since creation
            if (chatMessagesInfo.length === 0) {
                // Send post request using axios with CSRF token
                deleteChatRoom(chatInfo.id)
                .catch((response) => { console.log("Nav / User / closeChat - Axios error ", response); });
            }
        }
    }
}


// Stateless component to render authorization buttons
function Auth (props) {
    //console.log("Auth / props = ", props);

    const authTextClassName = (props.colorMode === "day")?
        "nav-auth-text k5" : "nav-auth-text w5";

    return (
        <div id = "nav-auth-buttons">
            <div id = "nav-auth-sign-up"
                style = {{ display : props.loggedIn? "none" : "block" }}
                onClick = {(event) => {props.signUpButtonClick(event);}}
            >
                <div className = {authTextClassName}>
                    Sign Up
                </div>
            </div>
            <div id = "nav-auth-log-in"
                style = {{ display : props.loggedIn? "none" : "block" }}
                onClick = {(event) => {props.logInButtonClick(event);}}
            >
                <div className = {authTextClassName}>
                    Log In
                </div>
            </div>
            <Link to = {`/create`}>
                <div id = "nav-auth-create"
                    style = {{ display : props.loggedIn? "block" : "none" }}
                >
                    <div className = {authTextClassName}>
                        Create
                    </div>
                </div>
            </Link>
            <Link to = {`/`}>
                <div id = "nav-auth-log-out"
                    style = {{ display : props.loggedIn? "block" : "none" }}
                    onClick = {(event) => {props.logOutButtonClick(event);}}
                >
                    <div className = {authTextClassName}>
                        Log Out
                    </div>
                </div>
            </Link>
        </div>
    )
}

// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        mainMode: state.nav.mainMode,
        itineraryAnimation: state.nav.itineraryAnimation,
        chatMenuOn: state.nav.chatMenuOn,
        itineraryMenuOn: state.nav.itineraryMenuOn,
        notificationMenuOn: state.nav.notificationMenuOn,
        logInOn: state.log_in.logInOn,
        signUpOn: state.sign_up.signUpOn,
        userInfo: state.user.userInfo,
        newNotification: state.notification.newNotification,
        newChat: state.chat.newChat,
        newChatMessage: state.chat.newChatMessage,
        newItinerary: state.itinerary.newItinerary,
        itinerariesFetchComplete: state.itinerary.itinerariesFetchComplete
    };
}

// Dispatch to Redux store
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeColorMode,
            storeMainMode,
            storeItineraryAnimation,
            storeChatMenuOn,
            storeItineraryMenuOn,
            storeNotificationMenuOn,
            storeLogInOn,
            storeSignUpOn,
            storeNewNotification,
            storeNewChat,
            storeNewChatMessage,
            storeNewItinerary,
            storeItinerariesFetchComplete,
            storeSchedule
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
