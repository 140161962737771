import { createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "reducers";
import { applyMiddleware, compose } from "redux"; 
import promise from "redux-promise";
import reduxThunk from "redux-thunk";

// Show redux log
// import logger from "redux-logger";  

const persistConfig = {
	key: "root",
	storage,
};
 
const persistedReducer = persistReducer(persistConfig, rootReducer);
 
export const store = createStore(
	persistedReducer,
  	compose (
    	applyMiddleware(
      		reduxThunk, promise
    	)
  	)
);

export const persistor = persistStore(store);
