const mediaAPI = "api/media/";
const imagesAPI = "api/images/";
const videosAPI = "api/videos/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

// Create a new media object
export const postMedia = (formData, axiosConfig) => {
	let URL = rootURL + mediaAPI;

	return window.axiosCSRF.post(URL, formData, axiosConfig);
};

// Send media delete request
export const deleteMedia = (mediaID) => {
	let URL = rootURL + mediaAPI + mediaID + "/";

    return window.axiosCSRF.delete(URL);
};

// Create a new image
export const postImage = (formData, axiosConfig) => {
	let URL = rootURL + imagesAPI;

	return window.axiosCSRF.post(URL, formData, axiosConfig);
};

// Send image delete request
export const deleteImage = (imageID) => {
	let URL = rootURL + imagesAPI + imageID + "/";

    return window.axiosCSRF.delete(URL);
};

// Create a new video
export const postVideo = (formData, axiosConfig) => {
	let URL = rootURL + videosAPI;

	return window.axiosCSRF.post(URL, formData, axiosConfig);
};

// Send delete request
export const deleteVideo = (videoID) => {
	let URL = rootURL + videosAPI + videoID + "/";

    return window.axiosCSRF.delete(URL);
};

export const postFetchMedia = (dataJSON) => {
	let URL = rootURL + mediaAPI + "fetch/";

	return window.axiosCSRF.post(URL, dataJSON);
};
