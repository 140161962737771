/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    RouteOverviewStartEndItem.js
--------------------------------------------------------------------------------------------
    Content
    - Individual item for Start and End Items for RouteOverview
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";

// Modules
//import ReactTooltip from "thedots-tooltip";

// Functions
import {
    getStaticPath,
    getTransitImage
} from "js/Functions";


// Start and end items
function RouteOverviewStartEndItem (props) {
    //console.log("RouteOverviewStartEndItem / render - props = ", props);
    let lineImage = null;
    let bottomLineImage = null;

    // Determine if this is the first or the last dot
    if (props.isStart) {
        lineImage = (props.renderItinerary)?
            (
                (props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/solid-top-light.png") :
                    getStaticPath("/images/line/vertical/solid-top-dark.png")
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/dotted-top-light.png") :
                    getStaticPath("/images/line/vertical/dotted-top-dark.png")
            );

        bottomLineImage = (props.renderItinerary)?
            (
                (props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/solid-middle-light.png") :
                    getStaticPath("/images/line/vertical/solid-middle-dark.png")
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/dotted-middle-light.png") :
                    getStaticPath("/images/line/vertical/dotted-middle-dark.png")
            );
    }
    else {
        lineImage = (props.renderItinerary)?
            (
                (props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/solid-bottom-light.png") :
                    getStaticPath("/images/line/vertical/solid-bottom-dark.png")
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/dotted-bottom-light.png") :
                    getStaticPath("/images/line/vertical/dotted-bottom-dark.png")
            );

        bottomLineImage = null;
    }

    // End image
    const endImage = (props.isStart)?
        (
            (props.colorMode === "day")?
                getStaticPath("/images/number/double_red_day_S.png") :
                getStaticPath("/images/number/double_red_night_S.png")
        ) : (
            (props.colorMode === "day")?
                getStaticPath("/images/number/double_red_day_E.png") :
                getStaticPath("/images/number/double_red_night_E.png")
        );


    // Timestamp
    const timestamp = (props.isStart)? (
        <div id = "route-overview-start-timestamp" 
            className = {(props.colorMode === "day")?
                "route-overview-start-timestamp k4" :
                "route-overview-start-timestamp w4"}
        >
            {props.endTimestamp}
        </div>
    ) : (
        <div id = "route-overview-end-timestamp" 
            className = {(props.colorMode === "day")?
                "route-overview-end-timestamp k4" :
                "route-overview-end-timestamp w4"}
        >
            {props.endTimestamp}
        </div>
    ); 

    // End column
    const endColumn = (
        <div className = "route-overview-end-container">
            <div className = "route-overview-end image-cover"
                style = {{ backgroundImage: endImage }}
            >
                {timestamp}
            </div>
        </div>
    );

    // Transit timestamp and mode
    const transitTimestamp = (props.isStart)? (
        <div className = {(props.colorMode === "day")?
            "route-overview-transit-time number-dg4" :
            "route-overview-transit-time number-g4"}
        >
            {props.transitTimestamp}
        </div>
    ) : null;

    const transitImage = getTransitImage(props.transitMode, props.colorMode, true);


    const transitMode = (props.isStart)? (
        <div className = "route-overview-transit-mode image-contain"
            style = {{ backgroundImage: transitImage }}
        >
        </div>
    ) : null;

    // Render component
    if (props.isStart) {
        return (
            <div key = {"route-overview-item-start"}
                className = "route-overview-item"
            >
                <div className = "route-overview-item-row">
                    <div className = "route-overview-media-left-column" 
                        style = {{ 
                            width: props.leftColumnWidth, 
                            backgroundImage: lineImage 
                        }}
                    >
                        {endColumn}
                    </div>
                    <div className = {(props.colorMode === "day")?
                            "route-overview-media-column k4" :
                            "route-overview-media-column w4"}
                    >
                        {props.children[0]}
                    </div>
                    <div className = "route-overview-media-right-column">
                    </div>
                </div>
                <div className = "route-overview-item-row">
                    <div className = "route-overview-description-left-column" 
                        style = {{ width: props.leftColumnWidth, backgroundImage: bottomLineImage }}
                    >
                        <div className = "route-overview-transit-container">
                            {transitMode}
                            {transitTimestamp}
                        </div>
                    </div>
                    <div className = "route-overview-description-column">
                        {props.children[1]}
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div key = {"route-overview-item-end"}
                className = "route-overview-item"
            >
                <div className = "route-overview-item-row">
                    <div className = "route-overview-media-left-column"
                        style = {{ width: props.leftColumnWidth, backgroundImage: lineImage }}
                    >
                        {endColumn}
                    </div>
                    <div className = "route-overview-media-column">
                    </div>
                    <div className = "route-overview-media-right-column">
                    </div>
                </div>
            </div>
        );
    }
}


export default RouteOverviewStartEndItem;