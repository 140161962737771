const connectAPI = "api/dots/connect/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const postConnectDots = (dataJSON) => {
    let URL = rootURL + connectAPI;

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postConnectDotsSync = (dataJSON) => {
    let URL = rootURL + connectAPI + "sync/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postPartialConnectDots = (dataJSON) => {
    let URL = rootURL + connectAPI + "partial/";

    return window.axiosCSRF.post(URL, dataJSON);
};
