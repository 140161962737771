/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    ItinerariesHome.js
    - Home page of itineraries
--------------------------------------------------------------------------------------------
    Content
    - ItinerariesHome
    - ItinerariesHomeItem
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Redux
import {
    storeUser
} from "actions";

// Modules
import ReactPlayer from "react-player";
//import ReactTooltip from "thedots-tooltip";
import moment from "moment-timezone";

// Components
//import { OpenMap, GoogleMap, getAverageLocation } from "components/Map";
//import { Gallery, mediaDimensions } from "components/Gallery";

// Axios
import {
    getItinerariesPaginated
} from "requests";

// Functions
import {
	url,
    getStaticPath,
    getMediaProperty,
    sortMedia,
    debounce
} from "js/Functions";

// CSS
import "./ItinerariesHome.scss";

// Response codes
//import { responseResultCodes } from "ResponseCodes";


class ItinerariesHome extends Component {
	constructor(props) {
		super(props);

        // Number of pages per group
        this.numPagesPerGroup = 10;

        // Initialize state
		this.state = {
			page: 1,
			itinerariesInfo: null,
			maxPage: null,
			checkScroll: true
		};

		// Bind functions
		this.setState = this.setState.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);
        this.previousGroup = this.previousGroup.bind(this);
        this.nextGroup = this.nextGroup.bind(this);
        this.goToPage = this.goToPage.bind(this);
	}

	render() {
		// Layout
		const layoutSmall = (this.props.browserWidth <= 3);


        /*
        ============================================================================================
            Pagination
        ============================================================================================
        */

        let previous = null;
        let next = null;
        let pageItems = null;
        let pages = null;

        if ((this.state.itinerarisInfo !== null) && (this.state.maxPage > 1)) {

            const maxPageGroup = Math.floor((this.state.maxPage - 1) / this.numPagesPerGroup); // indexed from 0 
            const pageGroup = Math.floor((this.state.page - 1) / this.numPagesPerGroup); // indexed from 0 

            // Get the navigation arrows
            if (pageGroup > 0) {
                previous = (
                    <div className = "itineraries-home-previous"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/arrow-left-black.png") :
                                getStaticPath("/images/common/arrow-left-white.png")
                        }}
                        onClick = {this.previousGroup}
                    >
                    </div>
                );
            }

            if (pageGroup < maxPageGroup) {
                next = (
                    <div className = "itineraries-home-next"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/arrow-right-black.png") :
                                getStaticPath("/images/common/arrow-right-white.png")
                        }}
                        onClick = {this.nextGroup}
                    >
                    </div>
                );
            }

            // Page groups
            const pageGroupStart = pageGroup * this.numPagesPerGroup + 1;
            const pageGroupEnd = (this.state.maxPage > (pageGroup + 1) * this.numPagesPerGroup)?
                (pageGroup + 1) * this.numPagesPerGroup : this.state.maxPage;

            // Pages
            const pageNumbers = [];
            for (let pageNumber = pageGroupStart; pageNumber <= pageGroupEnd; pageNumber++) {
                pageNumbers.push(pageNumber);
            }

            // For all pages
            pageItems = pageNumbers.map((pageNumber, index) => {
                const pageClassName = (pageNumber === this.state.page)?
                    (
                        (this.props.colorMode === "day")?
                            "itineraries-home-page page-selected-day-s2 " :
                            "itineraries-home-page page-selected-night-s2"
                    ) : (
                        (this.props.colorMode === "day")?
                            "itineraries-home-page page-day-s2 " :
                            "itineraries-home-page page-night-s2"
                    );

                return (
                    <div key = {"itineraries-home-pagination-page-" + pageNumber.toString()}
                        className = {pageClassName}
                        onClick = {() => { this.goToPage(pageNumber); }}
                    >
                        {pageNumber}
                    </div>
                );
            });

            // JSX
            pages = (
                <div className = "itineraries-home-pages-container">
                    {previous}
                    <div className = "itineraries-home-pages">
                        {pageItems}
                    </div>
                    {next}
                </div>
            );
        }


        /*
        ============================================================================================
            Itineraries
        ============================================================================================
        */

		const itineraries = (this.state.itinerariesInfo !== null)? 
			this.state.itinerariesInfo[this.state.page.toString()].map(
				(itineraryInfo, index) => {
					return (
						<ItinerariesHomeItem
							browserWidth = {this.props.browserWidth}
							colorMode = {this.props.colorMode}
							layoutSmall = {layoutSmall}
							key = {"itineraries-home-item-" + itineraryInfo.id}
							itineraryInfo = {itineraryInfo}
							checkScroll = {this.state.checkScroll}
						/>
					);
				}
			) : null;


        /*
        ============================================================================================
            Main Render
        ============================================================================================
        */

		return(
            <div id = "itineraries-home-container"
                className = {(this.props.browserWidth <= window.browserWidthSmall)? 
                    "itineraries-home-container-small" : "itineraries-home-container"}
            >
				{itineraries}
                {pages}
			</div>
		);
	}

	componentDidMount() {
		this.loadItineraries(1, true);
	}

	componentDidUpdate() {

	}


    /*
    ============================================================================================
        Load Itineraries
    ============================================================================================
    */

	loadItineraries(page, resetData, callback) {
		const axiosCallback = (response) => {
            //console.log("ItinerariesHome / loadItineraries - response.data.content.results = ", response.data.content.results);

            // Make a copy of loaded itineraries
            const itinerariesInfo = (resetData)? 
                {} : Object.assign({}, this.state.itinerariesInfo);

            // Compile newly loaded itineraries
            const newItinerariesInfo = [];
            for (let j = 0; j < response.data.content.results.length; j++) {
                newItinerariesInfo.push(response.data.content.results[j]);
            }
            //console.log("ItinerariesHome / loadItineraries - newItinerariesInfo = ", newItinerariesInfo);

            // Save newly loaded itineraries
            itinerariesInfo[page.toString()] = newItinerariesInfo;

            // Update state
			this.setState(
                {
    				itinerariesInfo: itinerariesInfo,
    				itineraryCount: response.data.content.total_count,
    				maxPage: response.data.content.total_pages
    			},
                callback
            );
		};

        getItinerariesPaginated(this.props.userInfo.username, page)
        .then(axiosCallback)
        .catch(
            (response) => {
                // console.log("[WARNING] ItinerariesHome / loadItineraries - ", response);
            }
        );
	}


    /*
    ============================================================================================
        Pagination
    ============================================================================================
    */

    previousGroup() {
        const previousPage = (Math.floor((this.state.page - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1;
        this.goToPage(previousPage);
    }

    nextGroup() {
        const nextPage = (Math.floor((this.state.page - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1;
        this.goToPage(nextPage);
    }

    goToPage(page) {
        const callback = () => {
            this.setState(
                {
                    page: page,
                },
                this.scrollToTop
            );
        };

        if (page.toString() in this.state.itinerariesInfo) {
            callback();
        }
        else {
            this.loadItineraries(page, false, callback);
        }
    }


    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }

    scrollToTop() {
        // Scroll to the top
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
    }
}

class ItinerariesHomeItem extends Component {
	constructor(props) {
		super(props);

		// DOM Node
		this.itemRef = React.createRef();

        // Media
        const mediaSorted = sortMedia(this.props.itineraryInfo.dot);
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];
        //console.log("ItinerariesHomeItem / constructor - media = ", media);

		this.state = {
			media: media,
			selectedMediaIndex: 0,
			inViewport: this.props.checkScroll
		};

		// Bind functions
		this.setState = this.setState.bind(this);
		this.prevMediaClick = this.prevMediaClick.bind(this);
		this.nextMediaClick = this.nextMediaClick.bind(this);
		this.navDotClick = this.navDotClick.bind(this);
	}

	render() {
		// Is video
		const isVideo = (this.state.media[this.state.selectedMediaIndex].type === "video");

		// Is square
		const isSquare = false;

		// Item id
		const itemID = this.props.itineraryInfo.id;

        /*
        ============================================================================================
            Shadow image
        ============================================================================================
        */
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette.png");


        /*
        ============================================================================================
            Media
        ============================================================================================
        */
        // Media URL
        const mediaURL = getMediaProperty(
        	this.state.media[this.state.selectedMediaIndex],
        	"s",
        	'url',
        	false
        );


        /*
        ============================================================================================
            Navigation arrows
        ============================================================================================
        */
        // Navigation arrow images
       	const nextArrowImage = getStaticPath("/images/common/arrow-right-white.png");
        const prevArrowImage = getStaticPath("/images/common/arrow-left-white.png");        
 
        // Navigation arrows
        const nextArrow = this.state.selectedMediaIndex < (this.state.media.length - 1)?
        (
            <div id = {"itineraries-home-item-gallery-next-arrow-" + itemID}
                className = "itineraries-home-item-gallery-next-arrow"
                style = {{ backgroundImage: nextArrowImage }}
                onClick = {this.nextMediaClick}
            >
            </div>
        ) : null;

        const prevArrow = this.state.selectedMediaIndex > 0?
        (
            <div id = {"itineraries-home-item-gallery-prev-arrow-" + itemID}
                className = "itineraries-home-item-gallery-prev-arrow"
                style = {{ backgroundImage: prevArrowImage }}
                onClick = {this.prevMediaClick}
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            Navigation Dots
        ============================================================================================
        */
        // Navigation dot images
        const navDotImage = getStaticPath("/images/common/image-dot-white.png");

        // Navigation dots
        let navDots = null;

        if (this.state.media.length > 1) {
            const dots = this.state.media.map((media, index) => {
                // If selected
                const navDotClass = (this.state.selectedMediaIndex === index)? 
                    (this.props.classPrefix + "-gallery-dot-on") : 
                    (this.props.classPrefix + "-gallery-dot-off");

                return(
                    <div key = {"itineraries-home-item-gallery-" + itemID + "-dot-" + index.toString()}
                        className = {navDotClass}
                        style = {{ backgroundImage: navDotImage }}
                        onClick = {this.navDotClick.bind(this, index)}
                    >
                    </div>
                );
            })

            navDots = (
                <div id = {"itineraries-home-gallery-dots-" + itemID}
                    className = "itineraries-home-gallery-dots"
                >
                    {dots}
                </div>
            );
        }


        /*
        ============================================================================================
            Media
        ============================================================================================
        */
        //console.log("ItinerariesHomeItem / render - props);

        const media = (isVideo)? (
            <div id = {"itineraries-home-item-gallery-wrapper-" + itemID}
                className = {
                    (isSquare)? (
                        (this.props.colorMode === "day")? 
                            "itineraries-home-item-gallery-wrapper-square content-box image-loader-s2" :
                            "itineraries-home-item-gallery-wrapper-square content-box image-loader-s2"
                    ) : (
                        (this.props.colorMode === "day")? 
                            "itineraries-home-item-gallery-wrapper content-box border-day image-loader-s2" :
                            "itineraries-home-item-gallery-wrapper content-box border-night image-loader-s2"
                    )
                }
            >
                <ReactPlayer id = {"itineraries-home-item-gallery-video-" + itemID}
                    className = "itineraries-home-item-gallery-video"
                    url = {mediaURL}
                    width = "100%"
                    height = "100%"
                    playing = {this.state.inViewport}
                    controls
                    playsinline
                    muted = {true}
                />
                {nextArrow}
                {prevArrow}
                <div id = {"itineraries-home-item-gallery-shadow-"  + itemID}
                    className = "itineraries-home-item-gallery-shadow image-cover"
                    style = {{ backgroundImage: shadowImage }}
                >
                	<div id = {"itineraries-home-item-gallery-gradient-"  + itemID}
                		className = {(this.props.layoutSmall)?
                			"itineraries-home-item-gallery-gradient-small" :
                			"itineraries-home-item-gallery-gradient"}
                	>

                		<div className = "itineraries-home-item-left">
	                		<div className = {(this.props.layoutSmall)?
	                				"itineraries-home-item-area w4" :
	                				"itineraries-home-item-area w2"}
	                		>
	                			{this.props.itineraryInfo.dot.area}
	                		</div>
							<div className = {
								(this.props.layoutSmall)?
									"itineraries-home-item-date-small font-cabin blue" : 
									"itineraries-home-item-date font-cabin blue"
								}
							>
								{
									(this.props.browserWidth === 1)?
										moment(this.props.itineraryInfo.start_time).format("M / D / YY") :
										moment(this.props.itineraryInfo.start_time).format("MMM Do YYYY")
								}
							</div>
	                	</div>

                		<div className = "itineraries-home-item-right">

                			<div className = {(this.props.layoutSmall)?
        						"itineraries-home-item-column-small" :
        						"itineraries-home-item-column"}
            				>
	            				<div className = {(this.props.layoutSmall)?
	            						"itineraries-home-item-dots-icon-small image-contain" :
	            						"itineraries-home-item-dots-icon image-contain"}
	            					style = {{ backgroundImage: getStaticPath("/images/common/main-mode-dots-white.png") }}
	            				>
	            				</div>
                				<div className = {(this.props.layoutSmall)?
            						"itineraries-home-item-dots-text-small b4" :
            						"itineraries-home-item-dots-text b3"}
                				>
                					{"" + this.props.itineraryInfo.route.length + " dots"}
                				</div>
                			</div>

                			<div className = {(this.props.layoutSmall)?
        						"itineraries-home-item-column-small" :
        						"itineraries-home-item-column"}
            				>
	            				<div className = {(this.props.layoutSmall)?
	            						"itineraries-home-item-time-icon-small image-contain" :
	            						"itineraries-home-item-time-icon image-contain"}
	            					style = {{ backgroundImage: getStaticPath("/images/common/time-white.png") }}
	            				>
	            				</div>
                				<div className = "itineraries-home-item-time">
	                				<div className = {(this.props.layoutSmall)?
                						"itineraries-home-item-time-start-small b5" :
                						"itineraries-home-item-time-start b4"}
	                				>
	                					{moment(this.props.itineraryInfo.start_time).format("h:mm a")}
	                				</div>
	                				<div className = {(this.props.layoutSmall)?
                						"itineraries-home-item-time-line-small" :
                						"itineraries-home-item-time-line"}
		            					style = {{ backgroundImage: getStaticPath("/images/line/vertical/solid-middle-white.png") }}
                					>
	                				</div>
	                				<div className = {(this.props.layoutSmall)?
                						"itineraries-home-item-time-end-small b5" :
                						"itineraries-home-item-time-end b4"}
                					>
	                					{moment(this.props.itineraryInfo.end_time).format("h:mm a")}
	                				</div>
                				</div>
                			</div>
	                	</div>

                	</div>
                </div>
            </div>
        ) : (
            <div id = {"itineraries-home-item-gallery-wrapper-" + itemID}
                className = {
                    (isSquare)? (
                        (this.props.colorMode === "day")? 
                            "itineraries-home-item-gallery-wrapper-square content-box image-loader-s2" :
                            "itineraries-home-item-gallery-wrapper-square content-box image-loader-s2"
                    ) : (
                        (this.props.colorMode === "day")? 
                            "itineraries-home-item-gallery-wrapper content-box border-day image-loader-s2" :
                            "itineraries-home-item-gallery-wrapper content-box border-night image-loader-s2"
                    )
                }
            >
                <div id = {"itineraries-home-item-gallery-image-" + itemID}
                    className = "itineraries-home-item-gallery-image image-gallery"
                    style = {{ backgroundImage: url(mediaURL) }}
                >
                </div>
                {nextArrow}
                {prevArrow}
                <div id = {"itineraries-home-item-gallery-shadow-" + itemID}
                    className = "itineraries-home-item-gallery-shadow image-cover"
                    style = {{ backgroundImage: shadowImage }}
                >
                	<div id = {"itineraries-home-item-gallery-gradient-"  + itemID}
                		className = "itineraries-home-item-gallery-gradient"
                	>

                		<div className = "itineraries-home-item-left">
	                		<div className = {(this.props.layoutSmall)?
	                				"itineraries-home-item-area w4" :
	                				"itineraries-home-item-area w2"}
	                		>
	                			{this.props.itineraryInfo.dot.area}
	                		</div>
							<div className = {
								(this.props.layoutSmall)?
									"itineraries-home-item-date-small font-cabin blue" : 
									"itineraries-home-item-date font-cabin blue"
								}
							>
								{
									(this.props.browserWidth === 1)?
										moment(this.props.itineraryInfo.start_time).format("M / D / YY") :
										moment(this.props.itineraryInfo.start_time).format("MMM Do YYYY")
								}
							</div>
	                	</div>

                		<div className = "itineraries-home-item-right">

                			<div className = {(this.props.layoutSmall)?
        						"itineraries-home-item-column-small" :
        						"itineraries-home-item-column"}
            				>
	            				<div className = {(this.props.layoutSmall)?
	            						"itineraries-home-item-dots-icon-small image-contain" :
	            						"itineraries-home-item-dots-icon image-contain"}
	            					style = {{ backgroundImage: getStaticPath("/images/common/main-mode-dots-white.png") }}
	            				>
	            				</div>
                				<div className = {(this.props.layoutSmall)?
            						"itineraries-home-item-dots-text-small b4" :
            						"itineraries-home-item-dots-text b3"}
                				>
                					{"" + this.props.itineraryInfo.route.length + " dots"}
                				</div>
                			</div>

                			<div className = {(this.props.layoutSmall)?
        						"itineraries-home-item-column-small" :
        						"itineraries-home-item-column"}
            				>
	            				<div className = {(this.props.layoutSmall)?
	            						"itineraries-home-item-time-icon-small image-contain" :
	            						"itineraries-home-item-time-icon image-contain"}
	            					style = {{ backgroundImage: getStaticPath("/images/common/time-white.png") }}
	            				>
	            				</div>
                				<div className = "itineraries-home-item-time">
	                				<div className = {(this.props.layoutSmall)?
                						"itineraries-home-item-time-start-small b5" :
                						"itineraries-home-item-time-start b4"}
	                				>
	                					{moment(this.props.itineraryInfo.start_time).format("h:mm a")}
	                				</div>
	                				<div className = {(this.props.layoutSmall)?
                						"itineraries-home-item-time-line-small" :
                						"itineraries-home-item-time-line"}
		            					style = {{ backgroundImage: getStaticPath("/images/line/vertical/solid-middle-white.png") }}
                					>
	                				</div>
	                				<div className = {(this.props.layoutSmall)?
                						"itineraries-home-item-time-end-small b5" :
                						"itineraries-home-item-time-end b4"}
                					>
	                					{moment(this.props.itineraryInfo.end_time).format("h:mm a")}
	                				</div>
                				</div>
                			</div>
	                	</div>

                	</div>
                </div>
            </div>
        );

		return (
			<Link
                to = {`/itinerary/${this.props.itineraryInfo.id}`}
            >
				<div ref = {this.itemRef}
					id = {"itineraries-home-item-" + itemID}
					className = {(this.props.layoutSmall)?
						"itineraries-home-item-small": "itineraries-home-item"}
				>
		            <div id = {"itineraries-home-item-gallery-container-" + itemID}
		                className = {"itineraries-home-item-gallery-container"}
		            >
		                {navDots}
		                {media}
		            </div>
				</div>
			</Link>
		);
	}

	componentDidMount() {}

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.checkScroll === false || prevProps.checkScroll === null) && (this.props.checkScroll === true)) {

            if (this.itemRef.current !== null) {
                const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                // Judge if this is within viewport
                const itemBoundingBox = this.itemRef.current.getBoundingClientRect();
                const inViewport = (
                    (itemBoundingBox.top >= 0) 
                    && 
                    (itemBoundingBox.bottom <= viewportHeight)
                );

                if (inViewport !== this.state.inViewport) {
                    this.setState({
                        inViewport: inViewport
                    });
                }
            }
        }
        else if (prevProps.checkScroll !== null && this.props.checkScroll === null) {
            this.setState({
                inViewport: false
            });
        }
    }

    nextMediaClick() {
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex + 1),
            }
        );
    }

    prevMediaClick() {
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex - 1),
            }
        );
    }

    navDotClick(mediaIndex) {
        this.setState({
            selectedMediaIndex: mediaIndex,
        });
    }
}


// Import necessary global state from the store as props
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

// Dispatch to Redux store
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
    	{
    		storeUser
    	},
    	dispatch
    );
}


// Export component
export default connect(mapStateToProps, mapDispatchToProps)(ItinerariesHome);