const usersAPI = "api/users/";
const userAPI = "api/user/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;


// Create a user or get lists of users
export const postUser = (dataJSON) => {
    const URL = rootURL + usersAPI;

    return window.axiosCSRF.post(URL, dataJSON);
};

// Get individual user
export const getUser = (username) => {
    const URL = rootURL + userAPI + username + "/";

    return window.axiosCSRF.get(URL);
};

export const postFetchUser = (dataJSON) => {
	const URL = rootURL + usersAPI + "fetch/";

	return window.axiosCSRF.post(URL, dataJSON)
}

// Update individual user
export const patchUser = (username, dataJSON) => {
    const URL = rootURL + userAPI + username + "/";

    return window.axiosCSRF.patch(URL, dataJSON);
};

export const postPosting = (dataJSON) => {
    const URL = rootURL + usersAPI + "postings/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const patchPosting = (postingID, dataJSON) => {
    const URL = rootURL + usersAPI + "postings/" + postingID + "/";

    return window.axiosCSRF.patch(URL, dataJSON);
};

export const deletePosting = (postingID) => {
    const URL = rootURL + usersAPI + "postings/" + postingID + "/";

    return window.axiosCSRF.delete(URL);
};

export const getPostings = (username, page) => {
	const URL = (page !== null)?
		(rootURL + usersAPI + "postings/?user__username=" + username + "&page=" + page) :
		(rootURL + usersAPI + "postings/?user__username=" + username);

    return window.axiosCSRF.get(URL);
};

export const postFollowRequest = (mode, followeeID) => {
    const URL = rootURL + usersAPI + "follows/";
    const dataJSON = {
		mode: mode,
		followee_id: followeeID
    };

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postCheckFollow = (followeeID) => {
	const URL = rootURL + usersAPI + "checkfollow/";
	const dataJSON = {
		followee_id: followeeID
	};

	return window.axiosCSRF.post(URL, dataJSON);
};

export const getFollows = (followerID, followeeID, page) => {
    let URL = rootURL + usersAPI +  "follows/";

    if ((followerID !== null) && (followeeID === null)) {
    	if (page !== null) {
	       	URL += ("?follower__id=" + followerID + "&page=" + page);
    	}
    	else {
	       	URL += ("?follower__id=" + followerID);
       	}
    }
    else if ((followerID === null) && (followeeID !== null)) {
    	if (page !== null) {
	       	URL += ("?followee__id=" + followeeID + "&page=" + page);
	    }
	    else {
	       	URL += ("?followee__id=" + followeeID);
	    }
    }
    else if ((followerID !== null) && (followeeID !== null)) {
    	if (page !== null) {
	       	URL += ("?follower__id=" + followerID + "&followee__id=" + followeeID + "&page=" + page);
    	}
    	else {
	       	URL += ("?follower__id=" + followerID + "&followee__id=" + followeeID);
	    }
    }

    return window.axiosCSRF.get(URL);
};

export const getUserLogin = () => {
	const URL = rootURL + usersAPI + "login-info/";

	return window.axiosCSRF.get(URL);
};

export const patchUnseenCount = (username, dataJSON) => {
	const URL = rootURL + userAPI + username + "/";

	return window.axiosCSRF.patch(URL, dataJSON);
};

export const getCheckValidToken = (id) => {
	const URL = rootURL + usersAPI + "reset-email/?token=" + id;

	return window.axiosCSRF.get(URL);
};

export const postResetEmail = (userEmail) => {
	const URL = rootURL + usersAPI + "reset-email/";

	return window.axiosCSRF.post(URL, { email: userEmail } );
};

export const putResetPassword = (pwd, token) => {
	const URL = rootURL + usersAPI + "reset-email/";

	return window.axiosCSRF.put(URL, { pwd, token } );
};

export const postDotTagManage = (username, dataJSON) => {
	const URL = rootURL + userAPI + username + "/tags/";

	return window.axiosCSRF.post(URL, dataJSON);
};

export const getFriends = (username, keywords, page) => {
	let URL = rootURL + userAPI + username + "/friends/";

	if ((typeof keywords !== "undefined") && (typeof page !== "undefined")) {
		URL += ("?keywords=" + keywords + "&page=" + page);
	}
	else if ((typeof keywords !== "undefined") && (typeof page === "undefined")) {
		URL += ("?keywords=" + keywords);
	}
	else if ((typeof keywords === "undefined") && (typeof page !== "undefined")) {
		URL += ("?page=" + page);
	}

	//console.log("requests / getFriends - URL = ", URL);
	return window.axiosCSRF.get(URL);
};

export const getLocations = (username, mode, tag) => {
	const URL = (tag === null)?
		(rootURL + userAPI + username + "/locations/?mode=" + mode) :
		(rootURL + userAPI + username + "/locations/?mode=" + mode + "/&tag=" + tag);
		
	return window.axiosCSRF.get(URL);
};
