// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Modules
import moment from "moment-timezone";

// Functions
import {
    getStaticPath,
    freezeBody,
    unfreezeBody
} from "js/Functions";

// Redux 
import {
    storePosting,
    storeWarningAlert,
    storeUser
} from "actions";

// Axios
import {
    patchPosting,
    getPostings
} from "requests";

// CSS
import "./Posting.scss";


class Posting extends Component {
    constructor(props){
        super(props);

        // Initialize model DOM reference
        this.modalRef = React.createRef();

        // Post modal container DOM
        this.postingContentRef = React.createRef();

        // Initialize state
        this.state = {
            postingPage: 1,
            postings: {}
        };

        // Bind functions
        this.editPosting = this.editPosting.bind(this);
        this.removePosting = this.removePosting.bind(this);
        this.unremovePosting = this.unremovePosting.bind(this);
        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.postingContentOnScroll = this.postingContentOnScroll.bind(this);
        this.updateLatestPosting = this.updateLatestPosting.bind(this);
    }

    removePosting(event, postingID, page, index) {
        //console.log("Post / removePosting - this.props = ", this.props);

        // Update states
        const axiosCallback = (response) => {
            //console.log("Post / removePosting - response.data = ", response.data);

            // Make a copy of loaded postings
            const postings = Object.assign({}, this.state.postings);
            postings[page.toString()][index].action_taken = true;

            // Update state
            this.setState({
                postings: postings
            });
        };

        // Data
        const dataJSON = {
            is_active: false
        };

        // Send delete request
        patchPosting(postingID, dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Posting / removePosting - error = ", response);})
    }

    unremovePosting(event, postingID, page, index) {
        //console.log("Post / unremovePosting - this.props = ", this.props);

        // Update states
        const axiosCallback = (response) => {
            //console.log("Post / unremovePosting - response.data = ", response.data);

            // Make a copy of loaded postings
            const postings = Object.assign({}, this.state.postings);
            postings[page.toString()][index].action_taken = false;

            // Update state
            this.setState({
                postings: postings
            });
        };

        // Data
        const dataJSON = {
            is_active: true
        };

        // Send undelete request
        patchPosting(postingID, dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Posting / unremovePosting - error = ", response);})
    }

    editPosting(event, postingID, page, index) {
        //console.log("Post / editPosting - this.props = ", this.props);

        // Update states
        const axiosCallback = (response) => {
            //console.log("Post / editPosting - response.data = ", response.data);

            // Make a copy of loaded postings
            const postings = Object.assign({}, this.state.postings);
            postings[page.toString()][index].action_taken = false;

            // Update state
            this.setState({
                postings: postings
            });
        };

        const dataJSON = {
            content: ""
        };

        // Send request
        patchPosting(postingID, dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Posting / editPosting - error = ", response);})
    }

    escClick(event) {
        // Disappear posting modal on pressing esc
        if (this.props.posting.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.props.posting.modalOn === true && this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        if (this.props.posting.myself) {
            this.updateLatestPosting();
        }
        this.props.storePosting({ modalOn: false, info: null, myself: null });
    }
    
    updateLatestPosting() {
        const keys = Object.keys(this.state.postings);
        //console.log("Posting / updateLatestPosting - keys = ", keys);

        // For all pages
        let latestPosting = null;
        for (let i = 0; i < keys.length; i++){
            for (let j = 0; j < this.state.postings[keys[i]].length; j++) {
                //console.log("Posting / updateLatestPosting - this.state.postings[keys[i]] = ", this.state.postings[keys[i]]);

                if (latestPosting === null &&
                    (
                        this.state.postings[keys[i]][j].action_taken === undefined ||
                        this.state.postings[keys[i]][j].action_taken === false 
                    )
                ) {
                    latestPosting = this.state.postings[keys[i]][j];
                    //console.log("Posting / updateLatestPosting - latestPosting = ", latestPosting);
                }
            }
        }

        // User info
        const userInfo = Object.assign({}, this.props.userInfo);
        userInfo.posting = latestPosting;
        //console.log("Posting / updateLatestPosting - userInfo = ", userInfo);

        // Update user info
        this.props.storeUser(userInfo);
    }

    render() {
        //console.log("Posting / render - this.props = ", this.props);
        //console.log("Posting / render - this.state = ", this.state);
        //console.log("Posting / render - this.props.posting = ", this.props.posting);
        let postings = [];

        // For all pages
        for (let page = 1; page <= this.state.postingPage; page++) {

            // Get the current time
            const now = moment();
            
            const postingItems = (
                (this.state.postings[page.toString()] !== undefined)?
                    (
                        this.state.postings[page.toString()].map(
                            (posting, index) => {
                                //console.log("Posting / render - posting = ", posting);

                                // Timestamp formatted
                                const time = moment(posting.created_time);
                                const timeFormatted = (now.year === time.year)?
                                    moment(posting.created_time).format("MMM Do h:mm a") :
                                    moment(posting.created_time).format("MMM Do YYYY h:mm a");

                                // Action button
                                const actionButton = (this.props.posting.myself)?
                                (
                                    <div className = {
                                            (this.props.colorMode === "day")?
                                                "posting-modal-content__item-action-button button-light-blue-gray-s3" :
                                                "posting-modal-content__item-action-button button-blue-gray-s3"
                                        }
                                        onClick = { 
                                            (event) => {
                                                if (posting.action_taken) {
                                                   this.unremovePosting(
                                                        event,
                                                        posting.id,
                                                        page, 
                                                        index
                                                    );
                                                }
                                                else {
                                                   this.removePosting(
                                                        event,
                                                        posting.id,
                                                        page, 
                                                        index
                                                    );
                                                }
                                            }
                                        }
                                    >
                                        {
                                            (posting.action_taken)?
                                                "Undo" : "Remove"
                                        }
                                    </div>
                                ) : null;

                                return(
                                    <div key = {"PostItem-" + index.toString()}
                                        className = "posting-modal-content__item"
                                    > 
                                        <div className = "posting-modal-content__item-time-container">
                                            <span
                                                className = {
                                                    (this.props.colorMode === "day")?
                                                        (
                                                            (posting.action_taken)?
                                                                "posting-modal-content__item-time lg4" :
                                                                "posting-modal-content__item-time lb4"
                                                        ) : (
                                                            (posting.action_taken)?
                                                                "posting-modal-content__item-time dg4" :
                                                                "posting-modal-content__item-time b4"
                                                        )
                                                }
                                            >
                                                {timeFormatted}
                                            </span>

                                            {actionButton}

                                        </div>

                                        <span
                                            className = {
                                                (this.props.colorMode === "day")?
                                                    (
                                                        (posting.action_taken)?
                                                            "posting-modal-content__item-text lg4" :
                                                            "posting-modal-content__item-text k4"
                                                    ) : (
                                                        (posting.action_taken)?
                                                            "posting-modal-content__item-text dg4" :
                                                            "posting-modal-content__item-text w4"
                                                    )
                                            }
                                        >
                                            {posting.content}
                                        </span>
                                    </div>
                                );
                            }
                        )
                    ) : null
            );

            postings.push(postingItems);
        }

        return (
            <div className = "posting-modal" 
                style = {{
                    display: (this.props.posting.modalOn)? "block" : "none"

                }}
            >
                <div className = {(this.props.colorMode === "day")? 
                            "posting-modal-content modal-day" : 
                            "posting-modal-content modal-night"} 
                    ref = {this.modalRef}
                >
                    <div className = "posting-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage:  (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                        onClick = {this.closeModal}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")? 
                                "posting-modal-content__title k2" : 
                                "posting-modal-content__title w2"}
                    >
                        Posts
                    </div>

                    <div ref = {this.postingContentRef}
                        id = "posting-modal-content-items"
                        className = "posting-modal-content__items">
                        {postings}
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        //console.log("Posting / componentDidMount - mount");

        // Freeze of unfreeze background
        if (this.props.posting.modalOn === true) {
            freezeBody();
        }
        if (this.props.posting.modalOn === false) {
            unfreezeBody();
        }

        // Fetch the first page
        if (this.props.posting.modalOn) {
            this.fetchPostings(1);
        }

        // Add event listeners
        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);
        this.postingContentRef.current.addEventListener("scroll", this.postingContentOnScroll);
    }
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);
        this.postingContentRef.current.removeEventListener("scroll", this.postingContentOnScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("Posting / componentDidUpdate - update");
    }

    postingContentOnScroll() {
        // If end of scroll is reached
        if (Math.floor(this.postingContentRef.current.scrollHeight - this.postingContentRef.current.scrollTop) === 
            Math.floor(this.postingContentRef.current.clientHeight)) {
            //console.log("Posting - postingContentOnScroll - end of scroll");

            // Fetch more notifications
            if (this.state.postingPage < this.state.postingMaxPage) {
                //console.log("Posting - postingContentOnScroll - fetching more postings");

                this.fetchPostings(this.state.postingPage + 1);
            }
        }

    }

    fetchPostings(page) {
        // Callback
        const axiosCallback = (response) => {
            //console.log("Posting / fetchPostings - response.data = ", response.data);
            
            // Make a copy of loaded postings
            const postings = (page === 1)?
                {} : Object.assign({}, this.state.postings);

            for (let i = 0; i < postings.length; i++) {
                postings[i].action_taken = false;
            }

            // Save newly loaded dots
            postings[page.toString()] = response.data.content.results;

            // Update state
            this.setState({
                postings: postings,
                postingPage: page,
                postingCount: response.data.content.total_count,
                postingMaxPage: response.data.content.total_pages
            });
        }

        getPostings(this.props.posting.info.username, page)
        .then(axiosCallback)
        .catch(
            (response) => {
                // console.log("[WARNING] Posting / fetchPostings - ", response);
            }
        );
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        posting: state.posting.posting,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storePosting, storeUser, storeWarningAlert }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(Posting); 
