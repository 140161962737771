import { STORE_FOLLOW } from "actions"; 

/*
============================================================================================
	Parameters
--------------------------------------------------------------------------------------------
	follow
	- modalOn: true | false
	- mode: followers | following
	- id: followee id (followers) | follower id (following)
	- myself: true | false
============================================================================================
*/

const INITIAL_STATE = {
	follow: { modalOn: false, mode: null, id: null, myself: null }
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_FOLLOW:
			return { ...state, follow: action.payload }; 
		default:
			return state;
	}
};