export const responseResultCodes = {
    "OK": 0,
    "UNAUTHENTICATED": 401,
    "FORBIDDEN": 403,
    "UNKNOWN": 500,

    "EMAIL_VALIDATOR": 100001,
    "EMAIL_EXIST_ERROR": 100002,

    "USER_NOT_EXIST_ERROR": 100101,
    "USERNAME_EXIST_ERROR": 100102,

    "PASSWORD_INCORRECT": 100201,

    "TASK_COMPLETED": 200000,
    "TASK_IN_PROGRESS": 200001,
    "TASK_FAILED": 200002,

    "EMAIL_EXCEPTION": 500001,
    "EMAIL_SEND_EXCEPTION": 500002,
    "EMAIL_REQUEST_TIME_OVER": 500003,
    "EMAIL_VALID_TIME_OVER": 500004,
    "EMAIL_NOT_SEND": 500005,
    "EMAIL_AUTH_FAIL": 500006,
    "EMAIL_ALREADY_AUTH": 500007,
    "EMAIL_USER_SAVE_FAIL": 500008,

    "TOKEN_EXPIRED": 500101,
    "TOKEN_INVALID": 500102,

    "REQUEST_FORMAT_ERROR": 900001,


    "DEVICE_LOGIN_FAIL": 300401,
    "DEVICE_AUTH_FAIL": 300402,
    "DEVICE_USER_NOT_EXIST": 300403,
    
    "MY_AIM_NOT_EXIST": 300501,
    
    "ON_BOARDING_SIGN_UP_ERROR": 400001,
    
    "AUTH_ID_NOT_EXIST": 600101,
    "AUTH_USER_NOT_EXIST": 600102,
    "AUTH_DB_EXCEPTION": 600103,
    "AUTH_USER_PASSWORD_ERROR": 600104,
    
    "APPLICATION_DISMISS": 800301,
    };
