const completionsAPI = "api/completions/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const getCompletions = (username, page, tag) => {
	const URL = (tag !== null)?
		(rootURL + completionsAPI + "?user__username=" + username + "&page=" + page + "&tag=" + tag) :
		(rootURL + completionsAPI + "?user__username=" + username + "&page=" + page);

	return window.axiosCSRF.get(URL);
};


export const patchCompletion = (completionID, dataJSON) => {
	let URL = rootURL + completionsAPI + completionID + "/";

	return window.axiosCSRF.patch(URL, dataJSON);
};
