/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Itinerary.js
--------------------------------------------------------------------------------------------
    Content
    - Itinerary
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Modules
import ReactTooltip from "thedots-tooltip";

// Functions
import {
	getStaticPath,
    getMediaProperty,
    sortMedia,
    formatDatetime,
    formatTimeElapsed,
} from "js/Functions";

// CSS
import "./Itinerary.scss";


class Itinerary extends Component {
	constructor(props) {
		super(props);

        // Initialize state
        this.state = {
            scrollBarOn: false
        }

		// DOM nodes
		this.itinerariesNode = null;

		// Bind functions
		this.itinerariesOnScroll = this.itinerariesOnScroll.bind(this);
        this.detectScrollBar = this.detectScrollBar.bind(this);
	}

	render() {
        const menuTriangleImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/top-menu-triangle-day.png") :
            getStaticPath("/images/common/top-menu-triangle-night.png");

		const itinerariesList = (this.props.itinerariesInfo.length === 0)?
		(
			<div id = "itinerary-item-blank"
                className = "g5"
            >
				No Upcomming Trips
			</div>
		) : (
    		this.props.itinerariesInfo.map((itineraryInfo, index) => {
    	        // Return an individual item
    	        return(
    	        	<ItineraryItem
                        buttonsOff = {this.props.buttonsOff}
                        setState = {this.props.setState}
                        storeItineraryMenuOn = {this.props.storeItineraryMenuOn}
                        storeSchedule = {this.props.storeSchedule}
                        scrollBarOn = {this.state.scrollBarOn}
                        colorMode = {this.props.colorMode}
    		        	key = {"itinerary-item-" + index.toString()}
    	        		index = {index}
    	        		itineraryInfo = {itineraryInfo}
    	        	/>
    	        );
    	    })
        );

        const closeButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") :
            getStaticPath("/images/common/cancel-white.png");

		return (
			<div id = "itineraries"
                className = {(this.props.buttonsOff)?
                    "itineraries-small" : "itineraries"}
            >
                {
                    (this.props.buttonsOff)?
                        null : (
                            <div id = "itineraries-triangle"
                                style = {{ backgroundImage: menuTriangleImage }}
                            >
                            </div>
                        )
                }
				<div id = "itineraries-shadow">
					<div id = "itineraries-header"
                        className = {(this.props.colorMode === "day")?
                            "itineraries-header-day k5" :
                            "itineraries-header-night w5"}
                    >
						Itineraries

                        <Link to = {`/itineraries/`}
                            onClick = {() => { this.props.storeItineraryMenuOn(false); }}
                        >
                            <div id = {(this.props.buttonsOff)?
                                "itineraries-all-button-small" :
                                "itineraries-all-button"}
                                className = {(this.props.colorMode === "day")?
                                    "itineraries-header-day k5" :
                                    "itineraries-header-night w5"}
                            >
                                See All
                            </div>
                        </Link>
                        {
                            (this.props.buttonsOff)?
                            (
                                <div id = "itineraries-close-button"
                                    className = "image-button-s8"
                                    style = {{ backgroundImage: closeButtonImage }}
                                    onClick = {() => { this.props.storeItineraryMenuOn(false); }}
                                >
                                </div>
                            ) : null
                        }
					</div>
					<div id = "itineraries-container"
                        className = {
                            (this.props.buttonsOff)?
                                (
                                    (this.props.colorMode === "day")?
                                    "itineraries-container-long itineraries-container-day" :
                                    "itineraries-container-long itineraries-container-night"
                                ) : (
                                    (this.props.colorMode === "day")?
                                    "itineraries-container-short itineraries-container-day" :
                                    "itineraries-container-short itineraries-container-night"
                                )
                        }
                    >
						{itinerariesList}
					</div>
					<div id = "itineraries-footer"
                        className = {(this.props.colorMode === "day")?
                            "itineraries-footer-day" :
                            "itineraries-footer-night"}
                    >
					</div>
				</div>
			</div>
		)
	}

	componentDidMount() {
        // DOM Node
        this.itinerariesNode = document.getElementById("itineraries-container");

        // Add event listeners
       	this.itinerariesNode.addEventListener("scroll", this.itinerariesOnScroll);
        //this.itinerariesNode.addEventListener("resize", this.detectScrollBar);

        // Detect scroll bar
        this.detectScrollBar();
	}

    componentWillUnmount() {
        // Remove event listeners
        this.itinerariesNode.removeEventListener("scroll", this.itinerariesOnScroll);
        //this.itinerariesNode.removeEventListener("resize", this.detectScrollBar);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.itinerariesInfo !== null) &&
                (prevProps.itinerariesInfo.length !== this.props.itinerariesInfo.length)) {
            this.detectScrollBar();
        }
    }

    detectScrollBar() {
        const scrollBarOn = this.itinerariesNode.scrollHeight > this.itinerariesNode.clientHeight;

        // Update state
        this.setState({
            scrollBarOn: scrollBarOn
        });
    }

    itinerariesOnScroll(e) {
        //console.log("Itinerary / itinerariesOnScroll - e = ", e);

        // Stop propagation to parents
        //e.stopPropagation();

    	// If end of scroll is reached
		if (Math.floor(this.itinerariesNode.scrollHeight - this.itinerariesNode.scrollTop) === Math.floor(this.itinerariesNode.clientHeight)) {
			//console.log("Itinerary - itinerariesOnScroll - end of scroll");

			// Fetch more itineraries
			if (!this.props.itinerariesFetchComplete) {
				//console.log("Itinerary - itinerariesOnScroll - fetching more itineraries");
				this.props.getItinerariesInfo();
			}
		}
    }
}


function ItineraryItem(props) {
    //console.log("ItineraryItem / render - itineraryInfo = ", props.itineraryInfo);

	// Class name
	const itineraryItemClassName = (props.colorMode === "day")?
        "itinerary-item-day" : "itinerary-item-night";

	// Timestamp
    const timeElapsed = formatTimeElapsed(props.itineraryInfo.timestamp);
    const timestampText = formatDatetime(props.itineraryInfo.timestamp, true, false, " ");
    const timestampTooltipID = "itinerary-item-" + props.index + "-timestamp-tooltip";

    const timestamp = (
        <span className = "itinerary-item-timestamp b6"
            data-for = {timestampTooltipID}
            data-tip = {timestampText}
        >
            {timeElapsed}
        </span>
    );
    const timestampTooltip = (
        <ReactTooltip
            id = {timestampTooltipID}
            className = {"itinerary-item-timestamp-tooltip tooltip-s2"}
            type = "info"
            place = "right"
            html = {true}
        />
    );

    // Sort media
    const mediaSorted = sortMedia(props.itineraryInfo.dot);

    // Merge all media categories
    const media = [
        ...mediaSorted.overview,
        ...mediaSorted.todos,
        ...mediaSorted.history,
        ...mediaSorted.stories
    ];

    // Pick the first item
    const mediaInfo = media[0];

    // Media
    let dotImage = null;
    if (mediaInfo.type === "video") {
        if ("xs" in mediaInfo.files) {
            dotImage = getMediaProperty(mediaInfo, "xs", "url", true);
        }
        else {
            dotImage = getStaticPath("/images/common/video-white.png", true);
        }
    }
    else {
        dotImage = getMediaProperty(mediaInfo, "s", "url", true);
    }

    // Get dot URL
    let dotURL = null;
    if (props.itineraryInfo.dot.type === "TR") {
        dotURL = `/trip/${props.itineraryInfo.dot.slug}`
    }
    else if (props.itineraryInfo.dot.type === "RT") {
        dotURL = `/route/${props.itineraryInfo.dot.slug}`
    }
    else {
        dotURL = `/dot/${props.itineraryInfo.dot.slug}`
    }

    // Click callback
    const clickItem = (e) => {
        //e.stopPropagation();
        //e.preventDefault();

        // Close menu
        props.storeItineraryMenuOn(false);

        /*
        // Open schedule
        props.storeSchedule({
            modalOn: true,
            mode: null,
            id : props.itineraryInfo.id
        });
        */

        //console.log("ItineraryItem / clickItem - props = ", props);
    };

    return (
        <div key = {"itinerary-item-content-" + props.index.toString()}
            className = {itineraryItemClassName}
        >

            <Link className = {(props.colorMode === "day")?
                    "itinerary-item-date bold lb5" :
                    "itinerary-item-date bold b5"}
                onClick = {clickItem}
                to = {`/itinerary/${props.itineraryInfo.id}`}
            >
                {props.itineraryInfo.date_formatted}
            </Link>

            <Link className = {(props.scrollBarOn)?
                    "itinerary-item-description-short-scroll" :
                    "itinerary-item-description-short"}
                onClick = {clickItem}
                to = {`/itinerary/${props.itineraryInfo.id}`}
            >
                <span className = {(props.colorMode === "day")?
                    "itinerary-item-text comment-dg4" :
                    "itinerary-item-text comment-g4"}
                >
                    {"Your trip to "}
                </span>

                <span className = {(props.colorMode === "day")?
                    "itinerary-item-bold k5" :
                    "itinerary-item-bold w5"}
                >
                    {props.itineraryInfo.dot.area}
                </span>

                <span className = {(props.colorMode === "day")?
                    "itinerary-item-text comment-dg4" :
                    "itinerary-item-text comment-g4"}
                >
                    {" starts at "}
                </span>


                <span className = {(props.colorMode === "day")?
                    "itinerary-item-bold k5" :
                    "itinerary-item-bold w5"}
                >
                    {props.itineraryInfo.start_time_local_formatted}
                </span>

                <span className = {(props.colorMode === "day")?
                    "itinerary-item-text comment-dg4" :
                    "itinerary-item-text comment-g4"}
                >
                    {"."}
                </span>

                {timestamp}
                {timestampTooltip}
            </Link>

            <Link className = {(props.colorMode === "day")?
                    "itinerary-item-dot image-cover border-day" :
                    "itinerary-item-dot image-cover border-night"}
                style = {{ backgroundImage: dotImage }}
                onClick = {() => { props.setState({ itineraryOpen: false }); }}
                to = {dotURL}
            >
            </Link>

        </div>
    );

    /*
        <Link className = {"itinerary-item-user profile-image-s6"}
            style = {{ backgroundImage: userImage }}
            to = {`/user/${props.itineraryInfo.user.id}`}
        >
        </Link>
    */
}

// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
    };
}

export default connect(mapStateToProps, null)(Itinerary);
