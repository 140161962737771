const searchAPI = "api/search/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const getSearch = (searchType, keywords) => {
    const URL = rootURL + searchAPI + "?search_type=" + searchType + "&keywords=" + keywords;

    return window.axiosCSRF.get(URL);
};

export const getUserSearch = (keywords) => {
    const URL = rootURL + searchAPI + "users/?keywords=" + keywords;

    return window.axiosCSRF.get(URL);
};

export const getDotSearch = (searchType, keywords) => {
    const URL = rootURL + searchAPI + "dots/?search_type=" + searchType + "&keywords=" + keywords;

    return window.axiosCSRF.get(URL);
};

export const getTripSearch = (searchType, keywords) => {
    const URL = rootURL + searchAPI + "trips/?search_type=" + searchType + "&keywords=" + keywords;

    return window.axiosCSRF.get(URL);
};

export const getNearbySearch = (searchType, location, radius, unit, keywords) => {
    const searchNearbyAPI = searchAPI + "nearby/";

    const URL = (keywords === null) ?
        (rootURL + searchNearbyAPI + "?search_type=" + searchType + "&location=" + location + "&radius=" + radius + "&unit=" + unit) :
        (rootURL + searchNearbyAPI + "?search_type=" + searchType + "&location=" + location + "&radius=" + radius + "&unit=" + unit + "&keywords=" + keywords);

    return window.axiosCSRF.get(URL);
};

export const getMediaSearch = (searchType, keywords) => {
    const URL = rootURL + searchAPI + "media/?search_type=" + searchType + "&keywords=" + keywords;

    return window.axiosCSRF.get(URL);
};

export const postSearch = (searchType, keywords) => {
    const URL = rootURL + searchAPI + "?search_type=" + searchType + "&keywords=" + keywords;

    return window.axiosCSRF.get(URL);
};
