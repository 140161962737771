import {
    STORE_SIGN_UP_ON
} from "actions";

const INITIAL_STATE = {
    signUpOn: false
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_SIGN_UP_ON:
			return { ...state, signUpOn: action.payload };
		default:
			return state;
	}
};