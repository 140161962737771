// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Components
import DotEditContributor from "./DotEditContributor";
import {
    OpenMap,
    GoogleMap,
    pointToLatLng,
    latLngToPoint,
    calculateDisplacement
} from "components/Map";

import {
    UploadGallery,
    addImage,
    addVideo,
    removeMedia,
    addSearchedMedia,
    clearSearchedMedia,
    selectMedia,
    moveMediaLeft,
    moveMediaRight,
    thumbnailPrevPage,
    thumbnailNextPage,
    updateIndices
}

from "components/UploadGallery";

import TextareaAutosize from "react-autosize-textarea";

import {
    pad,
    url,
    updateStateChain2,
    getStaticPath,
    getMediaProperty,
    formatNumbers,
    IntervalToSeconds,
    getDurationScaleIndex,
    getClockTime,
    getCloseDate,
    getBestTimeIndex
} from "js/Functions";

// Modules
import moment from "moment-timezone";

// Axios
import {
    patchDot
} from "requests";

// Open Source Map and Open Layers
import * as openLayersProj from "ol/proj";


class DotEditInfo extends Component {
    // Constructor receives dotID as the prop
    constructor (props) {
        super (props);
        //console.log("DotEditInfo - constructor / this.props = ", this.props);
        //console.log("DotEditInfo - constructor / this.props.dotInfo = ", this.props.dotInfo);
        //console.log("DotEditInfo - constructor / this.props.dotInfo.dot_extension = ", this.props.dotInfo.dot_extension);

        /*
        ============================================================================================
            Name Mobile Max Length
        ============================================================================================
        */

        this.nameMobileMaxLength = 8;


        /*
        ============================================================================================
            DOM Nodes
        ============================================================================================
        */

        // Preview middle
        this.previewMiddle = React.createRef();

        // DOM nodes of inputs
        this.imageNode = null;
        this.videoNode = null;
        this.locationRef = React.createRef();
        this.locationLatRef = React.createRef();
        this.locationLngRef = React.createRef();
        this.nameRef = React.createRef();
        this.areaRef = React.createRef();
        this.titleRef = React.createRef();
        this.overviewRef = React.createRef();
        this.todosRef = React.createRef();
        this.historyRef = React.createRef();
        this.storiesRef = React.createRef();


        /*
        ============================================================================================
           Input Focused
        ============================================================================================
        */

        // Form input focused states
        this.mediaFocused = false;
        this.locationFocused = false;
        this.locationLatFocused = false;
        this.locationLngFocused = false;
        this.nameFocused = false;
        this.areaFocused = false;
        this.titleFocused = false;
        this.overviewFocused = false;
        this.todosFocused = false;
        this.historyFocused = false;
        this.storiesFocused = false;


        /*
        ============================================================================================
            Warnings
        ============================================================================================
        */

        // Media warning timer
        this.mediaWarningTimer = null;

        // Transition times
        this.transitionTime = 300;
        this.delayTime = 1000;


        /*
        ============================================================================================
            Side Columns
        ============================================================================================
        */

        // Side column widths
        this.editorColumnWidth = 160;
        this.statsColumnWidth = 160;

        // User ratings / difficulties settings
        this.numThresholdRatings = 5;
        this.numThresholdDifficulties = 5;
        this.numCompletedUserProfilePics = 3;


        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        // Location object [lat, lng]
        this.location = null;

        // Map DOM IDs
        this.mapNodeID = "map";
        this.mapContainerNodeID = "map-container";
        this.mapButtonsNodeID = "map-buttons";

        // Map Mode
        this.map = null;
        this.mapSource = "google";
        this.mapMode = "create";

        // Map settings
        this.mapZoom = this.props.dotInfo.map_zoom;
        this.mapWidth = 360;
        this.mapHeight = 300;
        this.mapHeightSmall = 260;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;


        /*
        ============================================================================================
            Media Settings
        ============================================================================================
        */

        // Focus and unfocus opacities
        this.focusOpacity = 1.0;
        this.unfocusOpacity = 0.75;

        // Margin width
        this.marginWidth = 10;

        // Media upload input IDs
        this.imageUploadID = "dot-edit-upload-gallery-add-image";
        this.videoUploadID = "dot-edit-upload-gallery-add-video";

        // Aspect ratios
        this.minAspectRatio = 0.5;
        this.maxAspectRatio = 2.0;

        // Content widths
        this.minContentWidth = 300;
        this.scaleContentWidth = 600;
        this.maxContentWidth = 900;

        // Media settings
        this.mediaArea = 320000;

        // Thumbnails
        this.thumbnailArea = 12000;

        // Video thumbnails
        this.videoThumbnailWidth = 100;
        this.videoThumbnailHeight = 100;

        // Number of thumbnails
        this.numThumbnailsSmall = 2;
        this.numThumbnailsMedium = 3;
        this.numThumbnailsLarge = 3;

        // Number of thumbnails
        const numThumbnails = (this.props.browserWidth <= 4)?
            this.numThumbnailsSmall :
            (
                (this.props.browserWidth <= 8)?
                    this.numThumbnailsMedium : this.numThumbnailsLarge
            );


        /*
        ============================================================================================
            Initialize Dot Information
        ============================================================================================
        */

        // Slider labels and constant presets
        this.bestTimeLabels = [ "Sunrise", "Morning", "Noon", "Afternoon", "Sunset" ];
        this.bestTimeTypes = [ "SR", "MO", "NO", "AF", "SS" ];
        this.physicalityLabels = ["0 - No Effort", "0.5", "1 - Easy / Stroll", "1.5", "2 - Slow Walk", "2.5 - Moderate / Regular Walk", "3 - Fast Walk", "3.5", "4 - Streneous / Jogging", "4.5", "5 - Extreme / Running" ];
        //this.physicalityLabels = [ "0 - Idle", "0.5", "1 - Stroll", "1.5", "2 - Walk", "2.5", "3 - Hike Up", "3.5", "4 - Backpack Up", "4.5", "5 - Running" ];
        this.durationScales = [ 3600, 7200, 14400, 28800, 57600 ];
        this.defaultPhysicalities = [
            0, // drive
            2.5, // walk
            0, // bus
            0, // subway
            0, // train
            0, // flight
            0, // cruise
            4, // backpack
            4, // swim
            3, // crosscounry
            2, // downhill
            4, // climb
            4, // scramble
            4  // kayak
        ];
        this.defaultSpeeds = [
            60 / 3600, // drive
            2.5 / 3600, // walk
            30 / 3600, // bus
            40 / 3600, // subway
            40 / 3600, // train
            500 / 3600, // flight
            20/3600, // cruise
            2 / 3600, // backpack
            3.5 / 3600, // swim
            4 / 3600, // crosscounry
            10 / 3600, // downhill
            0.02 / 3600, // climb
            0.2 / 3600, // scramble
            5 / 3600  // kayak
        ];


        /*
        ============================================================================================
            Initialize Dot Information
        ============================================================================================
        */

        /*
        --------------------------------------------------------------------------------------------
            User Is Contributor and Write Mode
        --------------------------------------------------------------------------------------------
        */

        // Is contributor
        const userIsContributor = (this.props.userInfo.create_level > 3)? true : false;

        // Everyday / author / contributor mode on
        const everydayModeOn = (this.props.dotInfo.type === "EV");
        const authorModeOn =  (this.props.dotInfo.type === "AU");
        const contributorModeOn = (this.props.dotInfo.type !== "AU" && this.props.dotInfo.type !== "EV");

        /*
        --------------------------------------------------------------------------------------------
            Dot Type
        --------------------------------------------------------------------------------------------
        */

        const dotTypeDefault = "SC";
        const dotType = (this.props.dotInfo.type === "DE")?
            "EX" :
            (
                (this.props.dotInfo.type === "EV" || this.props.dotInfo.type === "AU")?
                    dotTypeDefault  : this.props.dotInfo.type
            );

        /*
        --------------------------------------------------------------------------------------------
            Curation
        --------------------------------------------------------------------------------------------
        */

        const history = (contributorModeOn)?
            this.props.dotInfo.dot_extension.history : null;
        const stories = (contributorModeOn)?
            this.props.dotInfo.dot_extension.stories : null;
        const todos = (contributorModeOn)?
            this.props.dotInfo.dot_extension.todos : null;


        /*
        --------------------------------------------------------------------------------------------
            Rating
        --------------------------------------------------------------------------------------------
        */
        const ratingDefault = 3;
        const rating = (contributorModeOn)?
            (
                (this.props.dotInfo.dot_extension.rating !== null)?
                    Number(this.props.dotInfo.dot_extension.rating) : ratingDefault
            ) : ratingDefault;


        /*
        --------------------------------------------------------------------------------------------
            Physicality
        --------------------------------------------------------------------------------------------
        */

        // Physicality
        const physicalityDefault = 2;
        const physicality = (contributorModeOn)?
            (
                (this.props.dotInfo.dot_extension.physicality !== null)?
                    Number(this.props.dotInfo.dot_extension.physicality) : physicalityDefault
            ) : physicalityDefault;


        /*
        --------------------------------------------------------------------------------------------
            Duration
        --------------------------------------------------------------------------------------------
        */

        // Duration
        let duration = null;
        let durationScaleIndex = 0;

        const durationDefault = 1800; // 30 mins as default
        const durationScaleIndexDefault = 0;

        if (contributorModeOn) {
            if (this.props.dotInfo.dot_extension.duration !== null) {
                duration = IntervalToSeconds(this.props.dotInfo.dot_extension.duration);
                durationScaleIndex = getDurationScaleIndex(duration);
            }
            else {
                duration = durationDefault;
                durationScaleIndex = durationScaleIndexDefault;
            }
        }
        else {
            duration = durationDefault;
            durationScaleIndex = durationScaleIndexDefault;
        }


        /*
        --------------------------------------------------------------------------------------------
            Start and End hours
        --------------------------------------------------------------------------------------------
        */

        // Hours
        let startHour = null;
        let endHour = null;
        let hoursOn = false;

        const startHourDefault = {
            hour: 6,
            minute: 0,
            meridiem: "am",
            formatted: "6:00am"
        };
        const endHourDefault = {
            hour: 9,
            minute: 0,
            meridiem: "pm",
            formatted: "9:00pm"
        };
        const hoursOnDefault = false;

        if (contributorModeOn) {
            if (this.props.dotInfo.dot_extension.start_hour === null &&
                this.props.dotInfo.dot_extension.end_hour === null) {
                startHour = startHourDefault;
                endHour = endHourDefault;
                hoursOn = false;
            }
            else {
                startHour = getClockTime(this.props.dotInfo.dot_extension.start_hour);
                endHour = getClockTime(this.props.dotInfo.dot_extension.end_hour);
                hoursOn = true;
            }
        }
        else {
            startHour = startHourDefault;
            endHour = endHourDefault;
            hoursOn = hoursOnDefault;
        }


        /*
        --------------------------------------------------------------------------------------------
            Time Type
        --------------------------------------------------------------------------------------------
        */

        // Best time
        let bestTimeOn = null;
        let bestTimeType = null;
        let bestTimeFirst = null;
        let bestTimeSecond = null;

        const bestTimeOnDefault = true;
        const bestTimeTypeDefault = "single";
        const bestTimeFirstDefault = 0;
        const bestTimeSecondDefault = 4;

        if (contributorModeOn) {
            if (this.props.dotInfo.dot_extension.time_type === null) {
                bestTimeOn = false;
                bestTimeType = bestTimeTypeDefault;
                bestTimeFirst = bestTimeFirstDefault;
                bestTimeSecond = bestTimeSecondDefault;
            }
            else if (this.props.dotInfo.dot_extension.time_type === "AD") {
                bestTimeOn = true;
                bestTimeType = "allday";
                bestTimeFirst = bestTimeFirstDefault;
                bestTimeSecond = bestTimeSecondDefault;
            }
            else {
                bestTimeOn = true;
                if (this.props.dotInfo.dot_extension.time_type_optional === null) {
                    bestTimeType = "single";
                    bestTimeFirst = getBestTimeIndex(this.bestTimeTypes, this.props.dotInfo.dot_extension.time_type);
                    bestTimeSecond = bestTimeSecondDefault;
                }
                else {
                    bestTimeType = "double";
                    bestTimeFirst = getBestTimeIndex(this.bestTimeTypes, this.props.dotInfo.dot_extension.time_type);
                    bestTimeSecond = getBestTimeIndex(this.bestTimeTypes, this.props.dotInfo.dot_extension.time_type_optional);
                }
            }
        }
        else {
            bestTimeOn = bestTimeOnDefault;
            bestTimeType = bestTimeTypeDefault;
            bestTimeFirst = bestTimeFirstDefault;
            bestTimeSecond = bestTimeSecondDefault;
        }


        /*
        --------------------------------------------------------------------------------------------
            Seasonal Closure
        --------------------------------------------------------------------------------------------
        */

        let seasonClosureType = null;
        let closureStartDate = null;
        let closureEndDate = null;

        const seasonClosureTypeDefault = "estimate";
        const closureStartDateDefault = moment().month(11).date(1);
        const closureEndDateDefault = moment().year(moment().year() + 1).month(5).date(15);

        if (contributorModeOn) {
            if (this.props.dotInfo.dot_extension.closure_estimate === true) {
                seasonClosureType = "estimate";
                closureStartDate = getCloseDate(moment, this.props.dotInfo.dot_extension.closure_start_date);
                closureEndDate = getCloseDate(moment, this.props.dotInfo.dot_extension.closure_end_date);
            }
            else if (this.props.dotInfo.dot_extension.closure_estimate === false) {
                seasonClosureType = "exact";
                closureStartDate = getCloseDate(moment, this.props.dotInfo.dot_extension.closure_start_date);
                closureEndDate = getCloseDate(moment, this.props.dotInfo.dot_extension.closure_end_date);
            }
            else if (this.props.dotInfo.dot_extension.closure_estimate === null) {
                seasonClosureType = "open";
                closureStartDate = closureStartDateDefault;
                closureEndDate = closureEndDateDefault;
            }
        }
        else {
            seasonClosureType = seasonClosureTypeDefault;
            closureStartDate = closureStartDateDefault;
            closureEndDate = closureEndDateDefault;
        }


        /*
        --------------------------------------------------------------------------------------------
            Accessibility and Preferred Mode
        --------------------------------------------------------------------------------------------
        */

        let accessibilityMode = null;
        let parkingLocation = null;

        const accessibilityModeDefault = "drivable";

        if (this.props.dotInfo.drivable === null) {
            accessibilityMode = accessibilityModeDefault;
        }
        else {
            if (this.props.dotInfo.drivable) {
                if (this.props.dotInfo.parking_location === null) {
                    accessibilityMode = "drivable";
                }
                else {
                    accessibilityMode = "parkandwalk";
                    parkingLocation = this.props.dotInfo.parking_location;
                }
            }
            else {
                accessibilityMode = "undrivable";
            }
        }

        let preferredMode = null;
        const preferredModeDefault = "drive";

        if (this.props.dotInfo.public_transportation !== null) {
            preferredMode = (this.props.dotInfo.public_transportation)?
                "public" : "drive";
        }
        else {
            preferredMode = preferredModeDefault;
        }


        /*
        --------------------------------------------------------------------------------------------
            Locations
        --------------------------------------------------------------------------------------------
        */

        // Location (default location for debugging purposes)
        const location = this.props.dotInfo.location;

        // For trips
        const startLocation = null;
        const startLocationName = null;
        const endLocation = null;
        const endLocationName = null;

        /*
        // Start and end locations (for trips)
        startLocation = (this.props.dotInfo.trip_extension.start_location !== null)?
            this.props.dotInfo.trip_extension.start_location : null;

        startLocationName = (this.props.dotInfo.trip_extension.start_location_name !== null)?
            this.props.dotInfo.trip_extension.start_location_name : null;

        endLocation = (this.props.dotInfo.trip_extension.end_location !== null)?
            this.props.dotInfo.trip_extension.end_location : null;

        endLocationName = (this.props.dotInfo.trip_extension.end_location_name !== null)?
            this.props.dotInfo.trip_extension.end_location_name : null;
        */


        /*
        --------------------------------------------------------------------------------------------
            Dining Option
        --------------------------------------------------------------------------------------------
        */

        // Dining options
        let diningOptions = null;
        const diningOptionsDefault = false;

        if (this.props.dotInfo.type === "DE") {
            diningOptions = true;
        }
        else {
            diningOptions = diningOptionsDefault;
        }


        /*
        ============================================================================================
            Load Media Information
        ============================================================================================
        */

        // Media file count
        this.mediaCount = this.props.dotInfo.media.length;

        // Media objects using file upload order as key
        this.media = {};

        // Upload indicator objects using file upload order as key
        this.uploadProgress = {};

        // Construct media states
        const mediaKeys = [];
        const mediaCategories = [];
        const mediaIDs = [];

        // Construct files
        let mediaCount = 0;
        for (let i = 0; i < this.props.dotInfo.media_overview.length; i++) {
            mediaKeys.push(mediaCount);
            mediaCategories.push(0);
            mediaIDs.push(this.props.dotInfo.media_overview[i]);

            // Media info
            let mediaInfo = null;
            for (let j = 0; j < this.props.dotInfo.media.length; j++) {
                if (this.props.dotInfo.media_overview[i] === this.props.dotInfo.media[j].id) {
                    mediaInfo = this.props.dotInfo.media[j];
                }
            }

            // Calculate thumbnail width and height
            const mediaWidth = getMediaProperty(mediaInfo, "o", 'width', false);
            const mediaHeight = getMediaProperty(mediaInfo, "o", 'height', false);
            const mediaURL = getMediaProperty(mediaInfo, "o", "url", false);
            // Calculate the resized width and height of media and thumbnail
            const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
            const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
            const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
            const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
            const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
            const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

            // Figure out media source
            let mediaSource = "search";
            //console.log("DotEditInfo / constructor - mediaInfo = ", mediaInfo);
            if (mediaInfo.editor !== null && mediaInfo.editor !== undefined) {
                if (mediaInfo.editor.username === this.props.userInfo.username) {
                    mediaSource = "upload";
                }
            }

            // Initialize media object
            this.media[mediaCount] = {
                mediaType: mediaInfo.type,
                mediaURL: mediaURL,
                mediaWidth: resizedMediaWidth,
                mediaHeight: resizedMediaHeight,
                mediaCategory: 0,
                thumbnailWidth: (mediaInfo.type === "video")?
                    this.videoThumbnailWidth : thumbnailWidth,
                thumbnailHeight: (mediaInfo.type === "video")?
                    this.videoThumbnailHeight : thumbnailHeight,
                source: mediaSource
            };

            // Initialize upload progress object
            this.uploadProgress[mediaCount] = {
                uploadPercentage: 100,
                uploadComplete: true,
                uploadProcessPercentage: 100,
                uploadProcessComplete: true,
                uploadProcessInterval: null,
                uploadError: false,
                removed: false
            };

            // Increase count
            mediaCount += 1;
        }

        if (contributorModeOn) {
            for (let i = 0; i < this.props.dotInfo.dot_extension.media_todos.length; i++) {
                mediaKeys.push(mediaCount);
                mediaCategories.push(1);
                mediaIDs.push(this.props.dotInfo.dot_extension.media_todos[i]);

                // Media info
                let mediaInfo = null;
                for (let j = 0; j < this.props.dotInfo.media.length; j++) {
                    if (this.props.dotInfo.dot_extension.media_todos[i] === this.props.dotInfo.media[j].id) {
                        mediaInfo = this.props.dotInfo.media[j];
                    }
                }

                // Calculate thumbnail width and height
                const mediaWidth = getMediaProperty(mediaInfo, "o", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "o", 'height', false);
                const mediaURL = getMediaProperty(mediaInfo, "xs", "url", false);

                // Calculate the resized width and height of media and thumbnail
                const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                // Initialize media object
                this.media[mediaCount] = {
                    mediaType: mediaInfo.type,
                    mediaURL: mediaURL,
                    mediaWidth: resizedMediaWidth,
                    mediaHeight: resizedMediaHeight,
                    mediaCategory: 1,
                    thumbnailWidth: (mediaInfo.type === "video")? this.videoThumbnailWidth : thumbnailWidth,
                    thumbnailHeight: (mediaInfo.type === "video")? this.videoThumbnailHeight : thumbnailHeight
                };

                // Initialize upload progress object
                this.uploadProgress[mediaCount] = {
                    uploadPercentage: 100,
                    uploadComplete: true,
                    uploadProcessPercentage: 100,
                    uploadProcessComplete: true,
                    uploadProcessInterval: null,
                    uploadError: false,
                    removed: false
                };

                // Increase count
                mediaCount += 1;
            }

            for (let i = 0; i < this.props.dotInfo.dot_extension.media_history.length; i++) {
                mediaKeys.push(mediaCount);
                mediaCategories.push(2);
                mediaIDs.push(this.props.dotInfo.dot_extension.media_history[i]);

                // Media info
                let mediaInfo = null;
                for (let j = 0; j < this.props.dotInfo.media.length; j++) {
                    if (this.props.dotInfo.dot_extension.media_history[i] === this.props.dotInfo.media[j].id) {
                        mediaInfo = this.props.dotInfo.media[j];
                    }
                }

                // Calculate thumbnail width and height
                const mediaWidth = getMediaProperty(mediaInfo, "m", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "m", 'height', false);
                const mediaURL = getMediaProperty(mediaInfo, "s", "url", false);

                // Calculate the resized width and height of media and thumbnail
                const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                // Initialize media object
                this.media[mediaCount] = {
                    mediaType: mediaInfo.type,
                    mediaURL: mediaURL,
                    mediaWidth: resizedMediaWidth,
                    mediaHeight: resizedMediaHeight,
                    mediaCategory: 2,
                    thumbnailWidth: (mediaInfo.type === "video")?
                        this.videoThumbnailWidth : thumbnailWidth,
                    thumbnailHeight: (mediaInfo.type === "video")?
                        this.videoThumbnailHeight : thumbnailHeight
                };

                // Initialize upload progress object
                this.uploadProgress[mediaCount] = {
                    uploadPercentage: 100,
                    uploadComplete: true,
                    uploadProcessPercentage: 100,
                    uploadProcessComplete: true,
                    uploadProcessInterval: null,
                    uploadError: false,
                    removed: false
                };

                // Increase count
                mediaCount += 1;
            }

            for (let i = 0; i < this.props.dotInfo.dot_extension.media_stories.length; i++) {
                mediaKeys.push(mediaCount);
                mediaCategories.push(3);
                mediaIDs.push(this.props.dotInfo.dot_extension.media_stories[i]);

                // Media info
                let mediaInfo = null;
                for (let j = 0; j < this.props.dotInfo.media.length; j++) {
                    if (this.props.dotInfo.dot_extension.media_stories[i] === this.props.dotInfo.media[j].id) {
                        mediaInfo = this.props.dotInfo.media[j];
                    }
                }

                // Calculate thumbnail width and height
                const mediaWidth = getMediaProperty(mediaInfo, "m", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "m", 'height', false);
                const mediaURL = getMediaProperty(mediaInfo, "m", "url", false);

                // Calculate the resized width and height of media and thumbnail
                const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                // Initialize media object
                this.media[mediaCount] = {
                    mediaType: mediaInfo.type,
                    mediaURL: mediaURL,
                    mediaWidth: resizedMediaWidth,
                    mediaHeight: resizedMediaHeight,
                    mediaCategory: 3,
                    thumbnailWidth: (mediaInfo.type === "video")? this.videoThumbnailWidth : thumbnailWidth,
                    thumbnailHeight: (mediaInfo.type === "video")? this.videoThumbnailHeight : thumbnailHeight
                };

                // Initialize upload progress object
                this.uploadProgress[mediaCount] = {
                    uploadPercentage: 100,
                    uploadComplete: true,
                    uploadProcessPercentage: 100,
                    uploadProcessComplete: true,
                    uploadProcessInterval: null,
                    uploadError: false,
                    removed: false
                };

                // Increase count
                mediaCount += 1;
            }
        }

        //console.log("DotEditInfo / constructor - mediaKeys = ", mediaKeys);
        //console.log("DotEditInfo / constructor - mediaCategories = ", mediaCategories);
        //console.log("DotEditInfo / constructor - mediaIDs = ", mediaIDs);
        //console.log("DotEditInfo / constructor - this.media = ", this.media);
        //console.log("DotEditInfo / constructor - this.uploadProgress = ", this.uploadProgress);


        /*
        ============================================================================================
            Upload Settings
        ============================================================================================
        */

        // Upload process interval and time
        this.uploadProcessTime = 2000;
        this.uploadProcessIntervalTime = 100;

        // Upload update interval
        this.uploadUpdateIntervalTime = 200;
        this.uploadUpdateInterval = null;


        /*
        ============================================================================================
            Initialize State
        ============================================================================================
        */

        // Set initial state
        this.state = {
            // Dot type
            dotType: dotType,

            // Write mode
            everydayModeOn: everydayModeOn,
            authorModeOn: authorModeOn,
            contributorModeOn: contributorModeOn,

            // Contributor
            userIsContributor: userIsContributor,

            // Map objects
            map: null,

            // Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false,

            // Map height
            mapWidth: this.mapWidth,
            mapHeight: this.mapHeight,
            mapMinHeight: this.mapMinHeight,
            mapMaxHeight: this.mapMaxHeight,
            mapHeightIncrement: this.mapHeightIncrement,

            // Media
            selectedMediaIndex: 0,
            startMediaIndex: 0,
            endMediaIndex: Math.min(numThumbnails - 1, this.mediaCount - 1),
            numThumbnails: numThumbnails,

            // Media keys and categories
            mediaKeys: mediaKeys,
            mediaCategories: mediaCategories,
            mediaIDs: mediaIDs,

            // Media warning
            mediaWarningOn: false,
            mediaWarningMessage: null,

            // Accessibility
            accessibilityMode: accessibilityMode,

            // Preferred travel method
            preferredMode: preferredMode,

            // Location type
            locationType: "location",

            // Location object
            location: location,
            parkingLocation: parkingLocation,
            startLocation: startLocation,
            startLocationName: startLocationName,
            endLocation: endLocation,
            endLocationName: endLocationName,

            // Location and warning
            locationBorderColor: (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray,
            locationLatBorderColor: (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray,
            locationLngBorderColor: (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray,
            locationWarningOn: false,
            locationWarningOpacity: 0.0,
            locationWarningMessage: null,

            // Roundtrip (route)
            //roundtrip: false,

            // Loop (route)
            //loop: false,

            // Reversible (route)
            //reversible: true,

            // State
            state: this.props.dotInfo.state,
            //stateStart: null,
            //stateEnd: null,

            // Rating and physicality
            rating: rating,
            physicality: physicality,

            // Area
            area: this.props.area,

            // Curation
            title: this.props.dotInfo.title,
            overview: this.props.dotInfo.overview,
            todos: todos,
            history: history,
            stories: stories,
            name: (this.props.dotInfo.name !== null)?
                this.props.dotInfo.name : null,
            nameOn: true,

            // Duration
            duration: duration,
            durationScaleIndex: durationScaleIndex,

            // Best time
            bestTimeOn: bestTimeOn,
            bestTimeMode: bestTimeType,
            bestTimeFirst: bestTimeFirst,
            bestTimeSecond: bestTimeSecond,

            // Business hours
            hoursOn: hoursOn,
            startHour: startHour,
            endHour: endHour,

            // Season closure
            seasonClosureType: seasonClosureType,
            closureStartDate: closureStartDate,
            closureEndDate: closureEndDate,

            // Dining options
            diningOptions: diningOptions,
        }
        //console.log("DotEditInfo / contructor - this.state = ", this.state);


        // Bind setState
        this.setState = this.setState.bind(this);

        // Bind write mode callbacks
        this.everydayModeClick = this.everydayModeClick.bind(this);
        this.authorModeClick = this.authorModeClick.bind(this);
        this.contributorModeClick = this.contributorModeClick.bind(this);

        // Bind dot type callbacks
        this.scenicTypeClick = this.scenicTypeClick.bind(this);
        this.experienceTypeClick = this.experienceTypeClick.bind(this);
        this.dineTypeClick = this.dineTypeClick.bind(this);

        // Bind warning callbacks
        this.clearLocationWarning = this.clearLocationWarning.bind(this);

        // Input callbacks
        this.colorInputBorders = this.colorInputBorders.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);

        // Bind media callbacks
        this.addImage = addImage.bind(this);
        this.addVideo = addVideo.bind(this);
        this.removeMedia = removeMedia.bind(this);
        this.addSearchedMedia = addSearchedMedia.bind(this);
        this.clearSearchedMedia = clearSearchedMedia.bind(this);
        this.selectMedia = selectMedia.bind(this);
        this.moveMediaLeft = moveMediaLeft.bind(this);
        this.moveMediaRight = moveMediaRight.bind(this);
        this.thumbnailPrevPage = thumbnailPrevPage.bind(this);
        this.thumbnailNextPage = thumbnailNextPage.bind(this);
        this.updateIndices = updateIndices.bind(this);

        // Bind map callbacks
        this.resetMapAnimations = this.resetMapAnimations.bind(this);

        // Location
        this.clearLocationSearch = this.clearLocationSearch.bind(this);
        this.clearLocationGPS = this.clearLocationGPS.bind(this);
        this.dotLocationDrivableClick = this.dotLocationDrivableClick.bind(this);
        this.dotLocationParkAndWalkClick = this.dotLocationParkAndWalkClick.bind(this);
        this.dotLocationUndrivableClick = this.dotLocationUndrivableClick.bind(this);
        this.tripLocationDrivableClick = this.tripLocationDrivableClick.bind(this);
        this.tripLocationUndrivableClick = this.tripLocationUndrivableClick.bind(this);
        this.locationGPSClick = this.locationGPSClick.bind(this);
        this.locationOnChange = this.locationOnChange.bind(this);
        this.locationLatOnChange = this.locationLatOnChange.bind(this);
        this.locationLngOnChange = this.locationLngOnChange.bind(this);
        this.locationResize = this.locationResize.bind(this);
        this.locationResizeDelayed = this.locationResizeDelayed.bind(this);

        // Edit save
        this.dotEditSaveClick = this.dotEditSaveClick.bind(this);

        // Bind contributor callbacks
        this.titleOnChange = this.titleOnChange.bind(this);
        this.nameOnChange = this.nameOnChange.bind(this);
        this.areaOnChange = this.areaOnChange.bind(this);
        this.overviewOnChange = this.overviewOnChange.bind(this);
        this.todosOnChange = this.todosOnChange.bind(this);
        this.historyOnChange = this.historyOnChange.bind(this);
        this.storiesOnChange = this.storiesOnChange.bind(this);

        this.setContributorMenuOn = this.setContributorMenuOn.bind(this);
        this.setRating = this.setRating.bind(this);
        this.setDurationIncrease = this.setDurationIncrease.bind(this);
        this.setDurationDecrease = this.setDurationDecrease.bind(this);
        this.setDuration = this.setDuration.bind(this);
        this.setPhysicality = this.setPhysicality.bind(this);
        this.setBestTimeFirst = this.setBestTimeFirst.bind(this);
        this.setBestTimeSecond = this.setBestTimeSecond.bind(this);
        this.setBestTimeMode = this.setBestTimeMode.bind(this);
        this.setHoursOn = this.setHoursOn.bind(this);
        this.setStartHour = this.setStartHour.bind(this);
        this.setEndHour = this.setEndHour.bind(this);
        this.setSeasonClosureType = this.setSeasonClosureType.bind(this);
        this.setClosureStartDate = this.setClosureStartDate.bind(this);
        this.setClosureEndDate = this.setClosureEndDate.bind(this);
        this.setDiningOptions = this.setDiningOptions.bind(this);

        // Placeholders
        this.placeholderLocation = "Search area and click on map";
        this.placeholderName = "Name the place if necessary";
        this.placeholderArea = "Recognizable name of the area";
        this.placeholderTitle = "Attention grabbing title";
        this.placeholderOverviewDot = "Brief introduction of the place";
        this.placeholderOverviewTrip = "Overview of the trip";
        this.placeholderTodos = "What-to-do ideas to experience the place";
        this.placeholderTodosDine = "What-to-order ideas for best experience";
        this.placeholderHistory = "Some history that gives a perspective";
        this.placeholderStories = "Other interesting stories or anecdotes";

        // General user mode placeholder
        this.placeholderLocationSimple = "Search area and click on map";
        this.placeholderNameSimple = "Name the place if necessary";
        this.placeholderTitleSimple = "Attention grabbing title";
        this.placeholderOverviewSimple = "Interesting story about the place";

        // Media warning Messages
        this.mediaWarningMessage = "Upload at least one media file.";
        this.mediaWarningUploadMessage = "Wait until upload is complete or remove images with errors";
        this.mediaWarningOverviewMessage = "At least one media file must be for overview.";
        this.mediaWarningImageMessage = "Contributors must upload at least one image.";

        // Contributor mode warning messages
        this.locationWarningMessage = "Search location or provide coordinates.";
        this.locationParkingWarningMessage = "Set the parking location";
        this.locationStartWarningMessage = "Choose a good place to start the trip \n(airport / hotel / park entrance / trailhead).";
        this.locationEndWarningMessage = "Choose a good place to end the trip \n(Airport / hotel / park entrance / trailhead).";
        this.nameWarningMessage = "A dot must have a name.";
        this.areaWarningMessage = "Write what area this place belongs in.";
        this.titleWarningMessage = "Give it a fascinating title.";
        this.overviewWarningMessage = "An overview must be provided.";
        this.connectWarningMessageDot = "Connect the trip with at least one dot.";
        this.connectWarningMessageHighlight = "Highlight dots that represent the trip.";

        // General user mode warning messages
        this.locationWarningMessageSimple = "Search location.";
        this.locationParkingWarningMessageSimple = "Set the parking location.";
        this.nameWarningMessageSimple = "A dot must have a name.";
        this.titleWarningMessageSimple = "Give it a fascinating title.";
        this.overviewWarningMessageSimple = "Write something about this place.";
    }

    componentDidMount() {
        //console.log("DotEditInfo / componentDidMount - this.props = ", this.props);

        // Grab the DOM nodes of inputs
        this.imageNode = document.getElementById(this.imageUploadID);
        this.videoNode = document.getElementById(this.videoUploadID);

        // Set up an event listener for the media upload
        this.imageNode.addEventListener("change", this.addImage);
        this.videoNode.addEventListener("change", this.addVideo);

        // Set up event listeners for the location search input
        this.locationRef.current.addEventListener("change", this.locationResize);
        this.locationRef.current.addEventListener("cut", this.locationResizeDelayed);
        this.locationRef.current.addEventListener("paste", this.locationResizeDelayed);
        this.locationRef.current.addEventListener("drop", this.locationResizeDelayed);
        this.locationRef.current.addEventListener("keydown", this.locationResizeDelayed);

        const locationAutocomplete = new this.props.google.maps.places.Autocomplete(this.locationRef.current);

          // Save scope
        const that = this;

        // Add a Listener to the location input
        this.props.google.maps.event.addListener(
            locationAutocomplete,
            "place_changed",
            function() {
                // State
                let state = null;

                // Get place from the input address
                const place = locationAutocomplete.getPlace();
                //console.log("Create / componentDidMount - locationAutocomplete - place = ", place);

                if (!place.geometry) {
                    console.log("[WARNING] Create / componentDidMount - Autocomplete returned a place with no geometry");
                }
                else {
                    for (let i=0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types[0] === "administrative_area_level_1") {
                            state = place.address_components[i].short_name;
                            //console.log("Create / componentDidMount - locationAutocomplete - place.address_components[i].short_name = ", place.address_components[i].short_name);
                         }
                    }

                    if (state === null) {
                        state = place.address_components[0].short_name;
                    }
                }
                //console.log("Create / componentDidMount - locationAutocomplete - that.props = ", that.props);
                //console.log("Create / componentDidMount - locationAutocomplete - state = ", state);
                //console.log("Create / componentDidMount - locationAutocomplete - place.geometry.location = ", place.geometry.location);
                //console.log("Create / componentDidMount - locationAutocomplete - latitude = ", place.geometry.location.lat());
                //console.log("Create / componentDidMount - locationAutocomplete - longitude = ", place.geometry.location.lng());

                // Set start location
                that.location = latLngToPoint(place.geometry.location);
                //console.log("Create / componentDidMount - locationAutocomplete - latLngToPoint(place.geometry.location) = ", latLngToPoint(place.geometry.location));
                //console.log("Create / componentDidMount - locationAutocomplete - that.location = ", that.location);

                // Set states
                if ((that.state.dotType !== "TR") && (that.state.dotType !== "RT")) {
                    if (that.state.locationType === "parking") {
                        that.setState(
                            {
                                parkingLocation: that.location,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                    else {
                        if ((that.nameRef.current.value === null) || (that.nameRef.current.value === "")) {
                            that.nameRef.current.value = place.name;

                            that.setState(
                                {
                                    location: that.location,
                                    state: state,
                                    nameOn: true,
                                    mapResetMarkers: false,
                                    mapZoomInAnimation: false,
                                    mapZoomOutAnimation: false,
                                    mapZoomHikeAnimation: false,
                                    mapRefreshAnimation: true
                                }
                            );
                        }
                        else {
                            that.setState(
                                {
                                    location: that.location,
                                    state: state,
                                    mapResetMarkers: false,
                                    mapZoomInAnimation: false,
                                    mapZoomOutAnimation: false,
                                    mapZoomHikeAnimation: false,
                                    mapRefreshAnimation: true
                                }
                            );
                        }
                    }
                }
                else {
                    if (that.state.locationType === "parking") {
                        that.setState(
                            {
                                parkingLocation: that.location,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                    else {
                        that.setState(
                            {
                                endLocation: that.location,
                                endLocationName: (place.vicinity)? place.vicinity : place.formatted_address,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                }

                // Change input size
                that.locationResizeDelayed();

                // Clear GPS inputs (only in create-dot mode)
                that.clearLocationGPS();
            }
        );
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.browserWidth !== prevProps.browserWidth) {
            this.updateIndices();
        }

        // Apply map settings
        if (this.mapSource === "google" && prevState.map === null && this.state.map !== null) {
            // Get map center and zoom
            const mapCenter = pointToLatLng(this.props.dotInfo.map_center);
            const mapZoom = this.props.dotInfo.map_zoom;
            //console.log("DotEditInfo / componentDidUpdate - this.props.dotInfo.map_center =", this.props.dotInfo.map_center);
            //console.log("DotEditInfo / componentDidUpdate - this.props.dotInfo.map_zoom =", this.props.dotInfo.map_zoom);

            // Delayed trigger
            setTimeout(
                () => {
                    this.state.map.setCenter(mapCenter);
                    this.state.map.setZoom(mapZoom);
                },
                250
            );
        }
    }

    componentWillUnmount() {
        // Remove an event listener for the media upload
        this.imageNode.removeEventListener("change", this.addImage);
        this.videoNode.removeEventListener("change", this.addVideo);

        // Remove event listeners for the location search input
        this.locationRef.current.removeEventListener("change", this.locationResize);
        this.locationRef.current.removeEventListener("cut", this.locationResizeDelayed);
        this.locationRef.current.removeEventListener("paste", this.locationResizeDelayed);
        this.locationRef.current.removeEventListener("drop", this.locationResizeDelayed);
        this.locationRef.current.removeEventListener("keydown", this.locationResizeDelayed);
    }

    titleOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        this.setState({
            title: event.target.value
        })
    }

    nameOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        this.setState({
            name: event.target.value
        })
    }

    areaOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        this.setState({
            area: event.target.value
        })
    }

    overviewOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        this.setState({
            overview: event.target.value
        })
    }

    todosOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        this.setState({
            todos: event.target.value
        })
    }

    historyOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        this.setState({
            history: event.target.value
        })
    }

    storiesOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        this.setState({
            stories: event.target.value
        })
    }


    /*
    ============================================================================================
        Everyday Mode Click
    ============================================================================================
    */

    everydayModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            //this.dotModeClick();
        }

        // Turn on author mode
        this.setState({
            everydayModeOn: false,
            authorModeOn: true,
            contributorModeOn: false,
            nameOn: true
        });
    }


    /*
    ============================================================================================
        Author Mode Click
    ============================================================================================
    */

    authorModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            //his.dotModeClick();
        }

        if (this.state.userIsContributor) {
            // Turn on contributor mode
            this.setState({
                everydayModeOn: false,
                authorModeOn: false,
                contributorModeOn: true
            });
        }
        else {
            // Turn on everyday mode
            this.setState({
                everydayModeOn: true,
                authorModeOn: false,
                contributorModeOn: false,
                nameOn: ((this.nameRef.current.value === null) || (this.nameRef.current.value === ""))? false : true
            });
        }
    }


    /*
    ============================================================================================
        Contribute Click Callback
    ============================================================================================
    */

    contributorModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            //this.dotModeClick();
        }

        // Turn on author mode
        this.setState({
            everydayModeOn: true,
            authorModeOn: false,
            contributorModeOn: false,
            nameOn: ((this.nameRef.current.value === null) || (this.nameRef.current.value === ""))? false : true
        });
    }


    /*
    ============================================================================================
        Dot type callbacks
    ============================================================================================
    */

    setContributorMenuOn(value) {
        this.setState({
            contributorMenuOn: value
        });
    }


    /*
    ============================================================================================
        Dot Type Click Callbacks
    ============================================================================================
    */

    scenicTypeClick() {
        this.setState({
            dotType: "SC",
            roundtrip: null,
            reversible: null,
            bestTimeOn: true,
            bestTimeMode: "single",
            hoursOn: false,
            seasonClosureType: "estimate",
            diningOptions: false
        });
    }

    experienceTypeClick() {
        console.log("Create / experienceTypeClick");
        this.setState({
            dotType: "EX",
            roundtrip: null,
            reversible: null,
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: true,
            seasonClosureType: "open",
            diningOptions: false
        });
    }

    dineTypeClick() {
        this.setState({
            dotType: "DI",
            roundtrip: null,
            reversible: null,
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: true,
            seasonClosureType: "open",
            diningOptions: false
        });
    }


    /*
    ============================================================================================
        Update callbacks for contributor menu
    ============================================================================================
    */

    setRating(rating) {
        this.setState({
            rating: rating
        });
    }

    setDurationIncrease(durationScaleIndex) {
        this.setState({
            durationScaleIndex: this.state.durationScaleIndex + 1
        });
    }

    setDurationDecrease(durationScaleIndex) {
        this.setState({
            durationScaleIndex: this.state.durationScaleIndex - 1,
            duration: Math.min(this.state.duration, this.durationScales[this.state.durationScaleIndex - 1])
        });
    }

    setDuration(duration) {
        this.setState({
            duration: duration
        });
    }

    setPhysicality(physicality) {
        this.setState({
            physicality: physicality
        });
    }

    setBestTimeFirst(bestTimeFirst) {
        this.setState({
            bestTimeFirst: bestTimeFirst
        });
    }

    setBestTimeSecond(bestTimeSecond) {
        this.setState({
            bestTimeSecond: bestTimeSecond
        });
    }

    setBestTimeMode(bestTimeMode) {
        this.setState({
            bestTimeMode: bestTimeMode
        });
    }

    setHoursOn(hoursOn) {
        this.setState({
            hoursOn: hoursOn
        });
    }

    setStartHour(startHour) {
        this.setState({
            startHour: startHour
        });
    }

    setEndHour(endHour) {
        this.setState({
            endHour: endHour
        });
    }

    setSeasonClosureType(seasonClosureType) {
        this.setState({
            seasonClosureType: seasonClosureType
        });
    }

    setClosureStartDate(date) {
        const closureStartMoment = moment(date);

        // Set state
        this.setState(
            {
                closureStartDate: closureStartMoment
            }
        );
    }

    setClosureEndDate(date) {
        const closureEndMoment = moment(date);

        // Set state
        this.setState(
            {
                closureEndDate: closureEndMoment
            }
        );
    }

    setDiningOptions(diningOptions) {
        this.setState({
            diningOptions: diningOptions
        });
    }


    /*
    ============================================================================================
        Save click
    ============================================================================================
    */

    dotEditSaveClick() {
        // Data warning
        let dataWarning = false;

        // Initialize warnings
        let mediaWarningOn = false;
        let locationWarningOn = false;
        let nameWarningOn = false;
        let areaWarningOn = false;
        let titleWarningOn = false;
        let overviewWarningOn = false;
        //let connectWarningOn = false;
        let mediaWarningMessage = null;
        let locationWarningMessage = null;
        let nameWarningMessage = null;
        let areaWarningMessage = null;
        let titleWarningMessage = null;
        let overviewWarningMessage = null;
        //let connectWarningMessage = null;

        // Construct request data
        let dataJSON = null;

        // General dot
        if ((this.state.contributorModeOn === false) && ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))) {
            // Media
            const mediaIDs = [];
            for (let i = 0; i < this.state.mediaKeys.length; i++) {
                const mediaKey = this.state.mediaKeys[i];
                const mediaID = this.state.mediaIDs[mediaKey];

                // Media IDs
                mediaIDs.push(mediaID)
            }
            //console.log("DotEditInfo / dotEditSaveClick - this.state.mediaKeys = ", this.state.mediaKeys);
            //console.log("DotEditInfo / dotEditSaveClick - this.state.mediaIDs = ", this.state.mediaIDs);

            // Check if all upload processes are complete
            let allUploadComplete = true;
            const keys = Object.keys(this.uploadProgress);
            for (let i = 0; i < keys.length; i++) {
                const key = Number(keys[i]);
                if (this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) {
                    allUploadComplete = false;
                }
            }

            // Check media
            if (!allUploadComplete) {
                dataWarning = true;
                mediaWarningOn = true;
                mediaWarningMessage = this.mediaWarningUploadMessage;
            }
            else {
                if (this.state.mediaKeys.length === 0) {
                    dataWarning = true;
                    mediaWarningOn = true;
                    mediaWarningMessage = this.mediaWarningMessage;
                }
            }

            // Check location
            if ((this.state.accessibilityMode === "parkandwalk") && (this.state.parkingLocation === null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationParkingWarningMessageSimple;
            }
            if (this.state.location === null) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationWarningMessage;
            }

            // Check name
            if ((this.nameRef.current.value === null) || (this.nameRef.current.value === "")) {
                dataWarning = true;
                nameWarningOn = true;
                nameWarningMessage = this.nameWarningMessageSimple;
            }
            // Check title
            if ((this.titleRef.current.value === null) || (this.titleRef.current.value === "")) {
                dataWarning = true;
                titleWarningOn = true;
                titleWarningMessage = this.titleWarningMessageSimple;
            }
            // Check overview
            if ((this.overviewRef.current.value === null) || (this.overviewRef.current.value === "")) {
                dataWarning = true;
                overviewWarningOn = true;
                overviewWarningMessage = this.overviewWarningMessageSimple;
            }

            // Issue warnings
            const stateToUpdate = {
                mediaWarningOn: mediaWarningOn,
                mediaWarningMessage: mediaWarningMessage,
                locationWarningOn: locationWarningOn,
                locationWarningMessage: locationWarningMessage,
                nameWarningOn: nameWarningOn,
                nameWarningMessage: nameWarningMessage,
                //areaWarningOn: areaWarningOn,
                //areaWarningMessage: areaWarningMessage,
                titleWarningOn: titleWarningOn,
                titleWarningMessage: titleWarningMessage,
                overviewWarningOn: overviewWarningOn,
                overviewWarningMessage: overviewWarningMessage,
                //todosWarningOn: todosWarningOn,
                //todosWarningMessage: todosWarningMessage,
                //historyWarningOn: historyWarningOn,
                //historyWarningMessage: historyWarningMessage,
                //storiesWarningOn: storiesWarningOn,
                //storiesWarningMessage: storiesWarningMessage,
                //connectWarningOn: connectWarningOn,
                //connectWarningMessage: connectWarningMessage
            };

            // Update state
            this.setState(stateToUpdate, this.colorInputBorders);

            // Send request if no errors
            if (dataWarning === false) {
                //console.log("DotEditInfo / dotEditSaveClick (dot) - state = ", state)

                // Parking location
                const parkingLocation = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "parkandwalk")? this.state.parkingLocation : null);

                // Drivable
                const drivable = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "undrivable")? false : true);

                // Public Transportation
                const publicTransportation = (this.state.everydayModeOn)? null :
                    (
                        (this.state.accessibilityMode === "undrivable")?
                            null :
                            (
                                (this.state.preferredMode === "public")? true : false
                            )
                    );

                // Map zoom level / center / type
                let mapZoom = null;
                let mapCenter = null;
                let mapType = null;

                if (this.mapSource === "google") {
                    mapZoom = this.state.map.getZoom()
                    mapCenter = latLngToPoint(this.state.map.getCenter());
                    mapType = this.state.map.getMapTypeId();
                }
                else {
                    const view = this.state.map.getView();
                    mapZoom = Math.round(view.getZoom());
                    const lnglat = openLayersProj.transform(view.getCenter(), "EPSG:3857", "EPSG:4326");
                    //console.log("DotEditInfo / dotEditSaveClick - lnglat = ", lnglat);
                    mapCenter = {
                        latitude: lnglat[1],
                        longitude: lnglat[0]
                    };
                    mapType = "hybrid";
                }

                // Displacement and create map media switch
                const displacement = (this.mapSource === "google")?
                    calculateDisplacement(this.state.location, mapCenter, this.state.map) : [ 0.0, 0.0 ];
                const createMapMedia = (this.mapSource === "google");

                //console.log("DotEditInfo / dotEditSaveClick - mapZoom = ", mapZoom);
                //console.log("DotEditInfo / dotEditSaveClick - mapCenter = ", mapCenter);
                //console.log("DotEditInfo / dotEditSaveClick - mapType = ", mapType);
                console.log("DotEditInfo / dotEditSaveClick - displacement = ", displacement);

                // Request data
                dataJSON = {
                    // Editor
                    editor_id: this.props.userInfo.id,
                    // Media
                    media_ids: mediaIDs,
                    media_overview: mediaIDs,
                    // Dot type
                    type: (this.state.everydayModeOn)? "EV" : "AU",
                    // Name
                    name: this.nameRef.current.value,
                    // Area
                    area: null,
                    // Rating and physicality
                    //rating: this.state.rating,
                    //physicality: this.state.physicality,
                    // Duration
                    //duration: this.state.duration,
                    // Best time
                    //time_type: timeType,
                    //time_type_optional: timeTypeOptional,
                    // Hours
                    //start_hour: startHour,
                    //end_hour: endHour,
                    // Season closure
                    //closure_estimate: seasonClosureEstimate,
                    //closure_start_date: seasonClosureStart,
                    //closure_end_date: seasonClosureEnd,
                    // Locations
                    location: this.state.location,
                    parking_location: parkingLocation,
                    //start_location: startLocation,
                    //end_location: (this.state.loop)? null : endLocation,
                    //start_location_name: startLocationName,
                    //end_location_name: (this.state.loop)? null : endLocationName,
                    // Drivable
                    drivable: drivable,
                    // Prefered transportation
                    public_transportation: publicTransportation,
                    // Roundtrip
                    //roundtrip: this.state.roundtrip,
                    // Loop
                    //loop: this.state.loop,
                    // Reversible
                    //reversible: this.state.reversible,
                    // State
                    state: this.state.state,
                    // Map zoom level and center
                    map_zoom: mapZoom,
                    map_center: mapCenter,
                    map_type: mapType,
                    map_displacement: displacement,
                    create_map_media: createMapMedia,
                    // Curation
                    title: this.titleRef.current.value,
                    overview: this.overviewRef.current.value,
                    //todos: (this.todosRef.current.value.length === 0)? null: this.todosRef.current.value,
                    //history: (this.historyRef.current.value.length === 0)? null: this.historyRef.current.value,
                    //stories: (this.storiesRef.current.value.length === 0)? null: this.storiesRef.current.value,
                    // Connect
                    //child_ids: null,
                    //highlights: null
                };
            }
        }

        // Contributor dot
        else if ((this.state.contributorModeOn === true) && ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))) {
            // Media
            const mediaIDs = [];
            const mediaOverview = [];
            const mediaTodos = [];
            const mediaHistory = [];
            const mediaStories = [];
            for (let i = 0; i < this.state.mediaKeys.length; i++) {
                const mediaKey = this.state.mediaKeys[i];
                const mediaID = this.state.mediaIDs[mediaKey];
                const mediaCategory = this.state.mediaCategories[this.state.mediaKeys[i]];

                // Media IDs
                mediaIDs.push(mediaID)

                // Categorize media IDs
                if (this.state.mediaCategories[mediaKey] === 0) {
                    mediaOverview.push(mediaID);
                }
                else if (mediaCategory === 1) {
                    mediaTodos.push(mediaID);
                }
                else if (mediaCategory === 2) {
                    mediaHistory.push(mediaID);
                }
                else if (mediaCategory === 3) {
                    mediaStories.push(mediaID);
                }
                else {
                    console.log("[WARNING] DotEditInfo / dotEditSaveClick - wrong media category");
                }
            }

            // Check if all upload processes are complete
            let allUploadComplete = true;
            const keys = Object.keys(this.uploadProgress);
            for (let i = 0; i < keys.length; i++) {
                const key = Number(keys[i]);
                if (this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) {
                    allUploadComplete = false;
                }
            }

            // Check media
            if (allUploadComplete) {
                if (this.state.mediaKeys.length === 0) {
                    dataWarning = true;
                    mediaWarningOn = true;
                    mediaWarningMessage = this.mediaWarningMessage;
                }
                else {
                    if (mediaOverview.length === 0) {
                        dataWarning = true;
                        mediaWarningOn = true;
                        mediaWarningMessage = this.mediaWarningOverviewMessage;
                    }
                }
            }
            else {
                dataWarning = true;
                mediaWarningOn = true;
                mediaWarningMessage = this.mediaWarningUploadMessage;
            }

            // Check location
            if ((this.state.accessibilityMode === "parkandwalk") && (this.state.parkingLocation === null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationParkingWarningMessage;
            }
            if (this.state.location === null) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationWarningMessage;
            }

            // Check name
            if ((this.nameRef.current.value === null) || (this.nameRef.current.value === "")) {
                dataWarning = true;
                nameWarningOn = true;
                nameWarningMessage = this.nameWarningMessage;
            }
            // Check area
            if ((this.areaRef.current.value === null) || (this.areaRef.current.value === "")) {
                dataWarning = true;
                areaWarningOn = true;
                areaWarningMessage = this.areaWarningMessage;
            }
            // Check title
            if ((this.titleRef.current.value === null) || (this.titleRef.current.value === "")) {
                dataWarning = true;
                titleWarningOn = true;
                titleWarningMessage = this.titleWarningMessage;
            }
            // Check overview
            if ((this.overviewRef.current.value === null) || (this.overviewRef.current.value === "")) {
                dataWarning = true;
                overviewWarningOn = true;
                overviewWarningMessage = this.overviewWarningMessage;
            }
            /*
            // Check todos
            if ((this.todosRef.current.value === null) || (this.todosRef.current.value === "")) {
                dataWarning = true;
                todosWarningOn = true;
                todosWarningMessage = "What-to-do Ideas to Fully Enjoy the Place";
            }
            // Check history
            if ((this.historyRef.current.value === null) || (this.historyRef.current.value === "")) {
                dataWarning = true;
                historyWarningOn = true;
                historyWarningMessage = "A Little History That Gives a Different Perspective";
            }
            // Check stories
            if ((this.storiesRef.current.value === null) || (this.storiesRef.current.value === "")) {
                dataWarning = true;
                storiesWarningOn = true;
                storiesWarningMessage = "Other Interesting Stories or Anecdotes";
            }
            */

            // Issue warnings
            const stateToUpdate = {
                mediaWarningOn: mediaWarningOn,
                mediaWarningMessage: mediaWarningMessage,
                locationWarningOn: locationWarningOn,
                locationWarningMessage: locationWarningMessage,
                nameWarningOn: nameWarningOn,
                nameWarningMessage: nameWarningMessage,
                areaWarningOn: areaWarningOn,
                areaWarningMessage: areaWarningMessage,
                titleWarningOn: titleWarningOn,
                titleWarningMessage: titleWarningMessage,
                overviewWarningOn: overviewWarningOn,
                overviewWarningMessage: overviewWarningMessage,
                //todosWarningOn: todosWarningOn,
                //todosWarningMessage: todosWarningMessage,
                //historyWarningOn: historyWarningOn,
                //historyWarningMessage: historyWarningMessage,
                //storiesWarningOn: storiesWarningOn,
                //storiesWarningMessage: storiesWarningMessage,
                //connectWarningOn: connectWarningOn,
                //connectWarningMessage: connectWarningMessage
            };

            // Update state
            this.setState(stateToUpdate, this.colorInputBorders);

            // Send request if no errors
            if (dataWarning === false) {
                // console.log("DotEditInfo / dotEditSaveClick (dot) - state = ", state)

                // Dot Type
                let dotType = null;
                if (this.state.everydayModeOn) {
                    dotType = "EV";
                }
                else if (this.state.authorModeOn) {
                    dotType = "AU";
                }
                else {
                    dotType = ((this.state.dotType === "EX") && (this.state.diningOptions))? "DE" : this.state.dotType;
                }

                // Parking location
                const parkingLocation = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "parkandwalk")? this.state.parkingLocation : null);

                // Drivable
                const drivable = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "undrivable")? false : true);

                // Public Transportation
                const publicTransportation = (this.state.everydayModeOn)? null :
                    (
                        (this.state.accessibilityMode === "undrivable")?
                            null :
                            (
                                (this.state.preferredMode === "public")? true : false
                            )
                    );

                // Hours
                let startHour = null;
                let endHour = null;
                if ((this.state.dotType !== "SC") && (this.state.hoursOn)) {
                    //console.log("DotEditInfo / dotEditSaveClick - this.state.startHour.meridiem = ", this.state.startHour.meridiem)
                    let startAddHour, endAddHour;

                    if ((this.state.startHour.meridiem === "pm") && (this.state.startHour.hour < 12)) {
                        startAddHour = 12;
                    }
                    else if ((this.state.startHour.meridiem === "am") && (this.state.startHour.hour === 12)) {
                        startAddHour = -12;
                    }
                    else {
                        startAddHour = 0;
                    }

                    if ((this.state.endHour.meridiem === "pm") && (this.state.endHour.hour < 12)) {
                        endAddHour = 12;
                    }
                    else if ((this.state.endHour.meridiem=== "am") && (this.state.endHour.hour === 12)) {
                        endAddHour = -12;
                    }
                    else {
                        endAddHour = 0;
                    }

                    startHour = "" + pad(this.state.startHour.hour + startAddHour, 2, 0) + ":" + pad(this.state.startHour.minute, 2, 0);
                    endHour = "" + pad(this.state.endHour.hour + endAddHour, 2, 0) + ":" + pad(this.state.endHour.minute, 2, 0);
                }

                // Time types
                let timeType = null;
                let timeTypeOptional = null;
                if (this.state.bestTimeOn) {
                    if (this.state.bestTimeMode === "double") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                        timeTypeOptional = this.bestTimeTypes[this.state.bestTimeSecond];
                    }
                    else if (this.state.bestTimeMode === "single") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                    }
                    else if (this.state.bestTimeMode === "allday") {
                        timeType = "AD";
                    }
                    else {
                        console.log("[WARNING] DotEditInfo / dotEditSaveClick - wrong best time mode");
                    }
                }

                // Seasonal information
                let seasonClosureEstimate = null;
                if (this.state.seasonClosureType === "open") {
                    seasonClosureEstimate = null;
                }
                else if (this.state.seasonClosureType === "estimate") {
                    seasonClosureEstimate = true;
                }
                else {
                    seasonClosureEstimate = false;
                }
                let seasonClosureStart = null;
                let seasonClosureEnd = null;
                if (this.state.seasonClosureType !== "open") {
                    seasonClosureStart = "" + pad(this.state.closureStartDate.year(), 4, 0) + "-" + pad(this.state.closureStartDate.month() + 1, 2, 0) + "-" + pad(this.state.closureStartDate.date(), 2, 0);
                }
                if (this.state.seasonClosureType !== "open") {
                    seasonClosureEnd = "" + pad(this.state.closureEndDate.year(), 4, 0) + "-" + pad(this.state.closureEndDate.month() + 1, 2, 0) + "-" + pad(this.state.closureEndDate.date(), 2, 0);
                }

                // Map zoom level / center / type
                let mapZoom = null;
                let mapCenter = null;
                let mapType = null;

                if (this.mapSource === "google") {
                    mapZoom = this.state.map.getZoom()
                    mapCenter = latLngToPoint(this.state.map.getCenter());
                    mapType = this.state.map.getMapTypeId();
                }
                else {
                    const view = this.state.map.getView();
                    mapZoom = Math.round(view.getZoom());
                    const lnglat = openLayersProj.transform(view.getCenter(), "EPSG:3857", "EPSG:4326");
                    //console.log("DotEditInfo / dotEditSaveClick - lnglat = ", lnglat);
                    mapCenter = {
                        latitude: lnglat[1],
                        longitude: lnglat[0]
                    };
                    mapType = "hybrid";
                }

                // Displacement and create map media switch
                const displacement = (this.mapSource === "google")?
                    calculateDisplacement(this.state.location, mapCenter, this.state.map) : [ 0.0, 0.0 ];
                const createMapMedia = (this.mapSource === "google");

                //console.log("DotEditInfo / dotEditSaveClick - mapZoom = ", mapZoom);
                //console.log("DotEditInfo / dotEditSaveClick - mapCenter = ", mapCenter);
                //console.log("DotEditInfo / dotEditSaveClick - mapType = ", mapType);

                // Request data
                dataJSON = {
                    // Editor
                    editor_id: this.props.userInfo.id,
                    // Media
                    media_ids: mediaIDs,
                    media_overview: mediaOverview,
                    media_todos: mediaTodos,
                    media_history: mediaHistory,
                    media_stories: mediaStories,
                    // Object and dot type
                    type: dotType,
                     // Name
                    name: this.nameRef.current.value,
                    // Area
                    area: this.areaRef.current.value,
                    // Rating and physicality
                    rating: this.state.rating,
                    physicality: (this.state.dotType === "DI")?
                        null : this.state.physicality,
                    // Duration
                    duration: this.state.duration,
                    // Best time
                    time_type: timeType,
                    time_type_optional: timeTypeOptional,
                    // Hours
                    start_hour: startHour,
                    end_hour: endHour,
                    // Season closure
                    closure_estimate: seasonClosureEstimate,
                    closure_start_date: seasonClosureStart,
                    closure_end_date: seasonClosureEnd,
                    // Locations
                    location: this.state.location,
                    parking_location: parkingLocation,
                    //start_location: startLocation,
                    //end_location: (this.state.loop)? null : endLocation,
                    //start_location_name: startLocationName,
                    //end_location_name: (this.state.loop)? null : endLocationName,
                    // Drivable
                    drivable: drivable,
                    // Prefered transportation
                    public_transportation: publicTransportation,
                    // Roundtrip
                    //roundtrip: this.state.roundtrip,
                    // Loop
                    //loop: this.state.loop,
                    // Reversible
                    //reversible: this.state.reversible,
                    // State
                    state: this.state.state,
                    // Map zoom level and center
                    map_zoom: mapZoom,
                    map_center: mapCenter,
                    map_type: mapType,
                    map_displacement: displacement,
                    create_map_media: createMapMedia,
                    // Curation
                    title: this.titleRef.current.value,
                    overview: this.overviewRef.current.value,
                    todos: (this.todosRef.current.value.length === 0)? null : this.todosRef.current.value,
                    history: (this.historyRef.current.value.length === 0)? null : this.historyRef.current.value,
                    stories: (this.storiesRef.current.value.length === 0)? null : this.storiesRef.current.value
                    // Connect
                    //child_ids: null,
                    //highlights: null
                };
            }
        }
        //console.log("DotEdit / dotEditSaveClick - dataJSON = ", dataJSON);

        // Callback
        const axiosCallback = (response) => {
            //console.log("DotEditInfo / dotEditSaveClick - response = ", response);
            //console.log("DotEditInfo / dotEditSaveClick - response.data.content.dot_extension = ", response.data.content.dot_extension);

            this.props.history.push(`/dot/${this.props.dotInfo.slug}`)
        }

        // Send request
        patchDot(this.props.dotInfo.slug, dataJSON)
        .then(axiosCallback)
        .catch (response =>  console.log("DotEdit / dotEditSaveClick - Axios error ", response))
    }


    /*
    ============================================================================================
        Location Change
    ============================================================================================
    */

    locationOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        const location = "" + this.locationRef.current.value;

        if (location.length > 0) {
            this.clearLocationWarning(false);
        }
    }

    locationLatOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        const locationLat = "" + this.locationLatRef.current.value;

        if (locationLat.length > 0) {
            this.clearLocationWarning(false);
        }
    }

    locationLngOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        const locationLng = "" + this.locationLngRef.current.value;

        if (locationLng.length > 0) {
            this.clearLocationWarning(false);
        }
    }


    /*
    ============================================================================================
        Location Resize
    ============================================================================================
    */

    locationResize() {
        this.locationRef.current.style.height = "auto";
        this.locationRef.current.style.height = this.locationRef.current.scrollHeight + "px";
    }


    locationResizeDelayed() {
        window.setTimeout(this.locationResize, 0);
    }


    /*
    ============================================================================================
        Location Clear Warning
    ============================================================================================
    */

    clearLocationWarning(delay) {
        clearTimeout(this.locationWarningTimer);

        const locationBorderColor = (this.locationFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const locationLatBorderColor = (this.locationLatFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const locationLngBorderColor = (this.locationLngFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const firstState = {
            locationWarningOpacity: 0.0
        };
        const secondState = {
            locationBorderColor: locationBorderColor,
            locationLatBorderColor: locationLatBorderColor,
            locationLngBorderColor: locationLngBorderColor,
            locationWarningOn: false,
            locationWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.locationWarningTimer = setTimeout(
            updateStateChain2.bind(
                null,
                this.setState,
                firstState,
                secondState,
                this.colorInputBorders,
                this.transitionTime
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Location GPS Click
    ============================================================================================
    */

    locationGPSClick() {
        if ((this.locationLatRef.current.value !== null) && (this.locationLngRef.current.value !== null)) {
            // Read latitude and longitude from input values
            const latitude = parseFloat(this.locationLatRef.current.value);
            const longitude = parseFloat(this.locationLngRef.current.value);

            // Check the ranges
            if (((latitude >= -90) && (latitude <= 90)) && ((longitude >= -180) && (longitude <= 180))) {
                // Clear location
                this.clearLocationSearch();

                const location = { "latitude": latitude, "longitude": longitude };

                if (this.state.locationType === "location") {
                    this.setState({
                        location: location
                    });
                }
                else if (this.state.locationType === "parking") {
                    this.setState({
                        parkingLocation: location
                    });
                }
                else if (this.state.locationType === "start") {
                    this.setState({
                        startLocation: location
                    });
                }
                else if (this.state.locationType === "end") {
                    this.setState({
                        endLocation: location
                    });
                }
            }
            else {
                // Issue warning

            }
        }
    }


    /*
    ============================================================================================
        Location Drivable Click
    ============================================================================================
    */

    dotLocationDrivableClick() {
        this.setState({
            accessibilityMode: "drivable",
            locationType: "location",
            parkingLocation: null,
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Park and Walk Click
    ============================================================================================
    */

    dotLocationParkAndWalkClick() {
        this.setState({
            accessibilityMode: "parkandwalk",
            locationType: "location",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Undrivable Click
    ============================================================================================
    */

    dotLocationUndrivableClick() {
        this.setState({
            accessibilityMode: "undrivable",
            locationType: "location",
            parkingLocation: null,
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Drivable Click
    ============================================================================================
    */

    tripLocationDrivableClick() {
        this.setState({
            accessibilityMode: "drivable",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Undrivable Click
    ============================================================================================
    */

    tripLocationUndrivableClick() {
        this.setState({
            accessibilityMode: "undrivable",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Map Reset Animations
    ============================================================================================
    */

    resetMapAnimations(callback) {
        //console.log("Create / resetAnimations - executed");
        this.setState(
            {
                mapZoomInAnimation: false,
                mapZoomOutAnimation: false,
                mapZoomHikeAnimation: false,
                mapRefreshAnimation: false
            },
            callback
        );
    }


    /*
    ============================================================================================
        Change Border Colors
    ============================================================================================
    */

    colorInputBorders() {
        let locationBorderColor,
            locationLatBorderColor,
            locationLngBorderColor,
            nameBorderColor,
            //areaBorderColor,
            titleBorderColor,
            overviewBorderColor;
            //todosBorderColor,
            //storiesBorderColor,
            //historyBorderColor,
            //connectBorderColor;

        if (this.state.locationWarningOn) {
            locationBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
            locationLatBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
            locationLngBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            locationBorderColor = (this.locationFocused)?
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );

            locationLatBorderColor = (this.locationFocused)?
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );
            locationLngBorderColor = (this.locationFocused)?
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );
        }

        if (this.state.nameWarningOn) {
            nameBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            nameBorderColor = (this.nameFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        /*
        if (this.state.areaWarningOn) {
            areaBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            areaBorderColor = (this.areaFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }
        */

        if (this.state.titleWarningOn) {
            titleBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            titleBorderColor = (this.titleFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.overviewWarningOn) {
            overviewBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            overviewBorderColor = (this.overviewFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        /*
        if (this.state.todosWarningOn) {
            todosBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            todosBorderColor = (this.todosFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.historyWarningOn) {
            historyBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            historyBorderColor = (this.historyFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.storiesWarningOn) {
            storiesBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            storiesBorderColor = (this.storiesFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.connectWarningOn) {
            connectBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            connectBorderColor =  (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray
        }
        */

        // Update states
        this.setState({
            locationBorderColor: locationBorderColor,
            locationLatBorderColor: locationLatBorderColor,
            locationLngBorderColor: locationLngBorderColor,
            nameBorderColor: nameBorderColor,
            //areaBorderColor: areaBorderColor,
            titleBorderColor: titleBorderColor,
            overviewBorderColor: overviewBorderColor,
            //todosBorderColor: todosBorderColor,
            //historyBorderColor: historyBorderColor,
            //storiesBorderColor: storiesBorderColor,
            //connectBorderColor: connectBorderColor,
        });
    }


    inputOnFocus(event) {
        //console.log("Create / inputOnFocus - event.target.value = ", event.target.value);
        event.target.placeholder = "";

        // get the target id
        const id = event.target.id;

        if (id === "dot-edit-location") {
            this.locationFocused = true;
        }
        else if (id === "dot-edit-location-lat") {
            this.locationLatFocused = true;
            event.target.value = (this.state.location === null)?
                "" : this.state.location.latitude.toFixed(6)
        }
        else if (id === "dot-edit-location-lng") {
            this.locationLngFocused = true;
            event.target.value = (this.state.location === null)?
                "" : this.state.location.longitude.toFixed(6)
        }
        else if (id === "dot-edit-title") {
            this.titleFocused = true;
        }
        else if (id === "dot-edit-name") {
            this.nameFocused = true;
        }
        else if (id === "dot-edit-area") {
            this.areaFocused = true;
        }
        else if (id === "dot-edit-overview") {
            this.overviewFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "dot-edit-todos") {
            this.todosFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "dot-edit-history") {
            this.historyFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "dot-edit-stories") {
            this.storiesFocused = true;
            event.target.style.textAlign = "left";
        }
        else {
            console.log("[WARNING] DotEditInfo / inputOnFocus - unknown input ID = ", id);
        }

        // Update the border colors
        this.colorInputBorders();
    }


    inputOnBlur (event) {
        //console.log("Create / inputOnBlur - event.target.value = ", event.target.value);

        // Get the target id
        const id = event.target.id;

        if (id === "dot-edit-location") {
            this.locationFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderLocation : this.placeholderLocationSimple;
        }
        else if (id === "dot-edit-location-lat") {
            this.locationLatFocused = false;
            event.target.placeholder = (this.state.location === null)? "Latitude" : this.state.location.latitude.toFixed(6);
        }
        else if (id === "dot-edit-location-lng") {
            this.locationLngFocused = false;
            event.target.placeholder = (this.state.location === null)? "Longitude" : this.state.location.longitude.toFixed(6);
        }
        else if (id === "dot-edit-name") {
            this.nameFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderName : this.placeholderNameSimple;
        }
        else if (id === "dot-edit-area") {
            this.areaFocused = false;
            event.target.placeholder = this.placeholderArea;
        }
        else if (id === "dot-edit-title") {
            this.titleFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderTitle : this.placeholderTitleSimple;
        }
        else if (id === "dot-edit-overview") {
            this.overviewFocused = false;

            let placeholder = null;
            if (this.state.contributorModeOn) {
                if ((this.state.dotType === "TR") || (this.state.dotType === "RT")) {
                    placeholder = this.placeholderOverviewTrip;
                }
                else {
                    placeholder = this.placeholderOverviewDot;
                }
            }
            else {
                placeholder = this.placeholderOverviewSimple;
            }

            event.target.placeholder = placeholder;

            if (this.overviewRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "dot-edit-todos") {
             this.todosFocused = false;

            let placeholder = null;

            if (this.state.dotType === "DI") {
                placeholder = this.placeholderTodosDine;
            }
            else {
                placeholder = this.placeholderTodos;
            }

            event.target.placeholder = placeholder;

            if (this.todosRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
       }
        else if (id === "dot-edit-history") {
            this.historyFocused = false;
            event.target.placeholder = this.placeholderHistory;
            if (this.historyRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "dot-edit-stories") {
            this.storiesFocused = false;

            event.target.placeholder = this.placeholderStories;

            if (this.storiesRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else {
            console.log("[WARNING] Create / inputOnBlur - unknown input ID = ", id);
        }

        // Update the border colors
        this.colorInputBorders();
    }


    /*
    ============================================================================================
        Location Clear
    ============================================================================================
    */

    clearLocationSearch() {
        this.locationRef.current.value = "";
    }

    clearLocationGPS() {
        this.locationLatRef.current.value = "";
        this.locationLngRef.current.value = "";
    }


    /*
    ============================================================================================
        Main Render Function
    ============================================================================================
    */

    render() {
        //console.log("DotEditInfo / render - this.props.dotInfo = ", this.props.dotInfo);

        /*
        ============================================================================================
            Sliders
        ============================================================================================
        */

        const sliderClassNames = (this.props.colorMode === "day")?
        {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-day",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-day input-range__track--background",
            activeTrack: "input-range-track-day input-range-track-active-day",

            labelContainer: "input-range-label-container-day",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        } : {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-night",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-night input-range__track--background",
            activeTrack: "input-range-track-night input-range-track-active-night",

            labelContainer: "input-range-label-container-night",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        };


        /*
        ============================================================================================
            Object Type
        ============================================================================================
        */
        const objectType = "dot";
        //let objectType = null;
        //if ((this.state.dotType !== "TR") && (this.state.dotType !== "RT")) {
        //    objectType = "dot";
        //}
        //else {
        //    objectType = "trip";
        //}


        /*
        ============================================================================================
            Layout
        ============================================================================================
        */

        const narrowLayout = (this.previewMiddle.current !== null)?
            (this.props.browserWidthPixels < this.previewMiddle.current.offsetWidth + this.editorColumnWidth + this.statsColumnWidth + 2 * this.marginWidth) : false;


        /*
        ============================================================================================
            Editor
        ============================================================================================
        */

        const editorImage = (this.props.dotInfo.editor.profile_pic)?
            (
                (this.props.dotInfo.editor.profile_pic.external_url === null)?
                    getMediaProperty(this.props.dotInfo.editor.profile_pic, "t", "url", true) :
                    url(this.props.dotInfo.editor.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );


        /*
        ============================================================================================
            Dot Stats
        ============================================================================================
        */

        // Rating
        let ratingTitle = null;
        let rating = null;
        let ratingCount = null;

        if (((this.props.dotInfo.rating !== null) && (this.props.dotInfo.rating > 0.0)) ||
            ((this.props.dotInfo.rated_user_count >= this.numThresholdRatings) && (this.props.dotInfo.user_rating != null))) {
            ratingTitle = (this.props.dotInfo.rated_user_count >= this.numThresholdRatings)?
                "Rating" :
                (
                    (this.props.browserWidth <= 6)?
                        "Rating" : "Editor Rating"
                );

            if ((this.props.dotInfo.rated_user_count >= this.numThresholdRatings) && (this.props.dotInfo.user_rating != null)) {
                ratingCount= formatNumbers(this.props.dotInfo.rated_user_count).toString() + " Users";
                rating = (
                    <div className = "dot-edit-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "dot-edit-preview-numbers-rating-small" :
                                    "dot-edit-preview-numbers-rating"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-blue" : "font-cabin blue"}
                        >
                            {Number(this.props.dotInfo.user_rating).toFixed(1)}
                        </div>
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "dot-edit-preview-numbers-rating-count" :
                                    "dot-edit-preview-numbers-rating-count"
                            }
                            className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                        >
                            {ratingCount}
                        </div>
                    </div>
                );
            }
            else {
                rating = (
                    <div className = "dot-edit-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "dot-edit-preview-numbers-rating-small" :
                                    "dot-edit-preview-numbers-rating"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-blue" : "font-cabin blue"}
                        >
                            {Number(this.props.dotInfo.rating).toFixed(1)}
                        </div>
                    </div>
                );
            }
        }

        // Difficulty
        let difficultyTitle = null;
        let difficulty = null;
        let difficultyCount = null;

        if (((this.props.dotInfo.difficulty !== null) && (this.props.dotInfo.difficulty > 0.0)) ||
            ((this.props.dotInfo.difficultied_user_count >= this.numThresholdDifficulties) && (this.props.dotInfo.user_difficulty != null))) {
            difficultyTitle = (this.props.dotInfo.difficultied_user_count >= this.numThresholdDifficulties)?
                "Difficulty" :
                (
                    (this.props.browserWidth <= 6)?
                        "Difficulty" : "Editor Difficulty"
                );

            if ((this.props.dotInfo.difficultied_user_count >= this.numThresholdDifficulties) && (this.props.dotInfo.user_difficulty != null)) {
                difficultyCount= formatNumbers(this.props.dotInfo.difficultied_user_count).toString() + " Users";
                difficulty = (
                    <div className = "dot-edit-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "dot-edit-preview-numbers-difficulty-small" :
                                    "dot-edit-preview-numbers-difficulty"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-red" : "font-cabin red"}
                        >
                            {Number(this.props.dotInfo.user_difficulty).toFixed(1)}
                        </div>
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "dot-edit-preview-numbers-difficulty-count" :
                                    "dot-edit-preview-numbers-difficulty-count"
                            }
                            className = {(this.props.colorMode === "day")? "lr4" : "r4"}
                        >
                            {difficultyCount}
                        </div>
                    </div>
                );
            }
            else {
                difficulty = (
                    <div className = "dot-edit-preview-numbers-container">
                        <div id = {
                                (this.props.browserWidth <= 4)?
                                    "dot-edit-preview-numbers-difficulty-small" :
                                    "dot-edit-preview-numbers-difficulty"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-red" : "font-cabin red"}
                        >
                            {Math.ceil(this.props.dotInfo.difficulty).toFixed(1)}
                        </div>
                    </div>
                );
            }
        }


        /*
        ============================================================================================
            Upload Gallery
        ============================================================================================
        */

        //console.log("DotEditInfo / render - userIsContributor = ", this.state.userIsContributor);
        const uploadGallery = (
            <UploadGallery
                imageUploadID = {this.imageUploadID}
                videoUploadID = {this.videoUploadID}

                colorMode = {this.props.colorMode}

                searchOn = {!this.state.everydayModeOn}

                mediaArea = {this.mediaArea}
                thumbnailArea = {this.thumbnailArea}
                marginWidth = {this.marginWidth}
                minAspectRatio = {this.minAspectRatio}
                maxAspectRatio = {this.maxAspectRatio}
                minContentWidth = {this.minContentWidth}
                scaleContentWidth = {this.scaleContentWidth}
                maxContentWidth = {this.maxContentWidth}

                objectType = {objectType}
                userIsContributor = {this.state.userIsContributor}
                contributorModeOn = {this.state.contributorModeOn}

                selectedMediaIndex = {this.state.selectedMediaIndex}
                startMediaIndex = {this.state.startMediaIndex}
                endMediaIndex = {this.state.endMediaIndex}
                numThumbnails = {this.state.numThumbnails}

                media = {this.media}
                mediaKeys = {this.state.mediaKeys}
                mediaIDs = {this.state.mediaIDs}
                mediaCategories = {this.state.mediaCategories}

                setState = {this.setState}
                addImage = {this.addImage}
                addVideo = {this.addVideo}
                removeMedia = {this.removeMedia}
                addSearchedMedia = {this.addSearchedMedia}
                clearSearchedMedia = {this.clearSearchedMedia}

                selectMedia = {this.selectMedia}
                thumbnailPrevPage = {this.thumbnailPrevPage}
                thumbnailNextPage = {this.thumbnailNextPage}
                moveMediaRight = {this.moveMediaRight}
                moveMediaLeft = {this.moveMediaLeft}

                focusOpacity = {this.focusOpacity}
                unfocusOpacity = {this.unfocusOpacity}

                mediaWarningOn = {this.state.mediaWarningOn}
                mediaWarningMessage = {this.state.mediaWarningMessage}

                uploadProgress = {this.uploadProgress}
                uploadUpdate = {this.state.uploadUpdate}
            />
        )


        /*
        ============================================================================================
            Todos / History / Stories
        ============================================================================================
        */

        // Todos
        const todos = (
            <div className = "body-wide"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "dot-edit-todos-container">
                    <div id = "dot-edit-todos-title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        Todos
                    </div>
                    <TextareaAutosize
                        ref = {this.todosRef}
                        id = "dot-edit-todos"
                        name = "textarea"
                        className = {(this.props.colorMode === "day")?
                            "input-s2 input-day" : "input-s2 input-night"}
                        value = {(this.state.todos === null)? "" : this.state.todos}
                        placeholder = {this.placeholderTodos}
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                        onChange = {this.todosOnChange}
                        innerRef = {ref => this.textarea = ref}
                    />
                </div>
            </div>
        );


        // History
        const history = (
            <div className = "body-wide"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "dot-edit-history-container">
                    <div id = "dot-edit-history-title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        History
                    </div>
                    <TextareaAutosize
                        ref = {this.historyRef}
                        id = "dot-edit-history"
                        name = "textarea"
                        className = {(this.props.colorMode === "day")?
                            "input-s2 input-day" : "input-s2 input-night"}
                        value = {(this.state.history === null)? "" : this.state.history}
                        placeholder = {this.placeholderHistory}
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                        onChange={this.historyOnChange}
                        innerRef={ref => this.textarea = ref}
                    />
                </div>
            </div>
        );


        // Stories
        const stories = (
            <div className = "body-wide"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "dot-edit-stories-container">
                    <div id = "dot-edit-stories-title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        Stories
                    </div>
                    <TextareaAutosize
                        ref = {this.storiesRef}
                        id = "dot-edit-stories"
                        name = "textarea"
                        className = {(this.props.colorMode === "day")?
                            "input-s2 input-day" : "input-s2 input-night"}
                        value = {(this.state.stories === null)? "" : this.state.stories}
                        placeholder = {this.placeholderStories}
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                        onChange={this.storiesOnChange}
                        innerRef={ref => this.textarea = ref}
                    />
                </div>
            </div>
        );


        /*
        ============================================================================================
            Name
        ============================================================================================
        */

        const namePlaceholder = (this.state.contributorModeOn)? this.placeholderName : this.placeholderNameSimple;

        const name =
        (
            <div>
                <div className = "dot-edit-basic-row">
                    <div className = {(this.props.colorMode === "day")?
                            "dot-edit-basic-title k4" : "dot-edit-basic-title w4"}
                    >
                        Name
                    </div>
                    <div className = "dot-edit-basic-input">
                        <input
                            ref = {this.nameRef}
                            type = "text"
                            id = "dot-edit-name"
                            className = {(this.props.colorMode === "day")?
                                "input-s2 input-day" : "input-s2 input-night"}
                            value = {(this.state.name === null)? "" : this.state.name}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.nameOnChange}
                            placeholder = {namePlaceholder}
                            style = {{ borderColor: this.state.nameBorderColor }}
                            required
                        >
                        </input>
                    </div>
                </div>
                <div className = "dot-edit-warning warning-s2"
                    style = {{ display: this.state.nameWarningOn? "block" : "none" }}
                >
                    {this.state.nameWarningMessage}
                </div>
            </div>
        );



        /*
        ============================================================================================
            Area
        ============================================================================================
        */

        const area = (
            <div style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}>
                <div className = "dot-edit-basic-row">
                    <div className = {(this.props.colorMode === "day")?
                            "dot-edit-basic-title k4" : "dot-edit-basic-title w4"}
                    >
                        Area
                    </div>
                    <div className = "dot-edit-basic-input">
                        <input
                            ref = {this.areaRef}
                            type = "text"
                            id = "dot-edit-area"
                            className = {(this.props.colorMode === "day")?
                                "input-s2 input-day" : "input-s2 input-night"}
                            placeholder = {this.placeholderArea}
                            value = {(this.state.area === null)? "" : this.state.area}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.areaOnChange}
                            style = {{ borderColor: this.state.areaBorderColor }}
                            required
                        />
                    </div>
                </div>
                <div className = "dot-edit-warning warning-s2"
                    style = {{ display: this.state.areaWarningOn? "block" : "none" }}>
                    {this.state.areaWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Preferred
        ============================================================================================
        */

        const preferred = (this.state.everydayModeOn)? null : (
            <div className = "dot-edit-basic-row"
                style = {{ display: (((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null)) && ((this.state.accessibilityMode !== "undrivable")))? "block" : "none" }}
            >
                <div id = "dot-edit-location-preferred-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    Preferred
                </div>
                <div id = "dot-edit-location-preferred">
                    <div id = "dot-edit-drive-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.preferredMode === "drive")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ preferredMode: "drive" });}}
                    >
                        Drive
                    </div>
                    <div id = "dot-edit-public-transportation-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.preferredMode === "public")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ preferredMode: "public" });}}
                    >
                        Public Transportation
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Location Search
        ============================================================================================
        */

        const locationSearchTitle = (
            <div id = "dot-edit-location-search-title"
                className = {(this.props.colorMode === "day")? "k4" : "w4"}
            >
                Search
            </div>
        );

        // Location placeholder
        const locationPlaceholder = (this.state.dotType !== null)?
            this.placeholderLocation : this.placeholderLocationSimple;


        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        // Initialize map object
        let map = null;

        // Using Google maps
        if (this.mapSource === "google") {
            // Only when google object and locations are ready
            if ((this.props.google) && (this.props.dotInfo)) {
                // Set the props for Map component
                const mapProps = {
                    // Google
                    google: this.props.google,

                    // Map mode
                    mapMode: this.mapMode,

                    // Create mode
                    createMode: "dot",

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    mapContainerNodeID : this.mapContainerNodeID,
                    buttonsNodeID: this.mapButtonsNodeID,
                    startInputNodeID: null,
                    endInputNodeID: null,

                    // Dots
                    dotsInfo: [ {
                        name: "location",
                        location: this.state.location
                    } ],

                    // Itinerary
                    itinerary: [ 0 ],
                    selected: 0,
                    hovered: null,
                    selectedChild: null,
                    hoveredChild: null,
                    displayChildren: null,

                    // Roundtrip
                    roundtrip: this.state.roundtrip,

                    // Loop
                    loop: this.state.loop,

                    // Drivable
                    drivable: (this.state.accessibilityMode === "undrivable")? false : true,

                    // Location mode
                    locationType: this.state.locationType,

                    // Locations
                    parkingLocation: this.state.parkingLocation,
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Map settings
                    mapWidth: this.mapWidth,
                    mapHeight: (this.props.browserWidth <= 4)?
                        this.mapHeightSmall : this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Action triggers
                    mapResetMarkers: this.state.mapResetMarkers,
                    mapZoomInAnimation: this.state.mapZoomInAnimation,
                    mapZoomOutAnimation: this.state.mapZoomOutAnimation,
                    mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
                    mapRefreshAnimation: this.state.mapRefreshAnimation,
                    resetAnimations: this.resetMapAnimations,

                    // Dot click and hover callback
                    dotClick: null,
                    dotHoverOn: null,
                    dotHoverOff: null,
                    endHoverOn: null,
                    endHoverOff: null,

                    // Map zoom / center / type
                    mapZoom: this.props.dotInfo.map_zoom,
                    mapCenter: this.props.dotInfo.map_center,
                    mapType: this.props.dotInfo.map_type,

                    // SetState
                    setState: this.setState,

                    // Set location
                    setLocation: (location) => {
                        this.setState(
                            {
                                location: location,
                            }
                        );
                    },

                    // Set parking location
                    setParkingLocation: (parkingLocation) => {
                        this.setState(
                            {
                                parkingLocation: parkingLocation
                            }
                        );
                    },

                    // Set start location
                    setStartLocation: (startLocation) => {
                        this.setState(
                            {
                                startLocation: startLocation,
                                startLocationName: (this.locationRef.current.value.length === 0)?
                                "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                            }
                        );
                    },

                    // Set end location
                    setEndLocation: (endLocation) => {
                        this.setState(
                            {
                                endLocation: endLocation,
                                endLocationName: (this.locationRef.current.value.length === 0)?
                                "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value
                            }
                        );
                    },

                    // Set map
                    setMap: (map) => {this.setState({ map: map });},

                    // Clear location input
                    clearLocationSearch: this.clearLocationSearch,
                    clearLocationGPS: this.clearLocationGPS
                }
                //console.log("Dot / render - mapProps = ", mapProps);

                // Get the Map component
                map = (<GoogleMap {...mapProps}/>);
            }
        }
        // Using Open Street Maps
        else if (this.mapSource === "open") {
            // Set the props for Map component
            let openMapProps = {
                // Map mode
                mapMode: this.mapMode,

                // Create mode
                createMode: "dot",

                // DOM node IDs
                mapNodeID: this.mapNodeID,

                // Map settings
                mapWidth: this.mapWidth,
                mapHeight: (this.props.browserWidth <= 4)?
                    this.mapHeightSmall : this.mapHeight,
                mapMinHeight: this.mapMinHeight,
                mapMaxHeight: this.mapMaxHeight,
                mapHeightIncrement: this.mapHeightIncrement,

                // Dots
                dotsInfo: [ {
                    name: "location",
                    location: this.state.location
                } ],

                // Itinerary and selected settings
                itinerary: [ 0 ],
                selected: 0,
                selectedChild: null,
                hoveredChild: null,
                displayChildren: null,

                // Location mode
                locationType: this.state.locationType,

                // Parking / start / end locations
                parkingLocation: this.state.parkingLocation,
                startLocation: this.state.startLocation,
                endLocation: this.state.endLocation,

                // Map zoom / center / type
                mapZoom: this.mapZoom,
                mapCenter: this.state.location,
                mapType: "hybrid",

                // SetState
                setState: this.setState,

                // Set location
                setLocation: (location) => {
                    this.setState(
                        {
                            location: location,
                        }
                    );
                },

                // Set parking location
                setParkingLocation: (parkingLocation) => {
                    this.setState(
                        {
                            parkingLocation: parkingLocation
                        }
                    );
                },

                // Set start location
                setStartLocation: (startLocation) => {
                    this.setState(
                        {
                            startLocation: startLocation,
                            startLocationName: (this.locationRef.current.value.length === 0)?
                            "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                        }
                    );
                },

                // Set end location
                setEndLocation: (endLocation) => {
                    this.setState(
                        {
                            endLocation: endLocation,
                            endLocationName: (this.locationRef.current.value.length === 0)?
                            "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value
                        }
                    );
                },

                // Set map
                setMap: (map) => {this.setState({ map: map });},

                // Clear location input
                clearLocationSearch: this.clearLocationSearch,
                clearLocationGPS: this.clearLocationGPS
            };

            // Get the Map component
            map = (
                <OpenMap {...openMapProps}/>
            );
        }


        /*
        ============================================================================================
            Accessibility
        ============================================================================================
        */

        const parkAndWalk = (
            <div id = "dot-edit-park-and-walk-button"
                className = {(this.props.colorMode === "day")?
                    "button-light-blue-s3" : "button-blue-s3"}
                style = {
                    (this.state.accessibilityMode === "parkandwalk")?
                        {
                            display: (objectType === "dot")? "inline-block" : "none",
                            background: (this.props.colorMode === "day")?
                                window.colorLightBlue : window.colorBlue,
                            opacity: 1.0
                        } : {
                            display: (objectType === "dot")? "inline-block" : "none",
                            background: (this.props.colorMode === "day")?
                                window.colorLightGray : window.colorGray
                        }
                }
                onClick = {this.dotLocationParkAndWalkClick}
            >
                Park and Walk
            </div>
        );

        const accessibilityContainerID = (objectType === "dot")?
        "create-location-access-wide" : "create-location-access-narrow";

        const accessibilityDrivableLabel = (objectType === "dot")?
        "Drivable" : "Drivable";

        const accessibilityTitle = (objectType === "dot")?
        "Access" : "Access";

        const accessibility = (this.state.everydayModeOn)? null : (
            <div className = "dot-edit-basic-row"
                style = {{ display: ((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null))? "block" : "none" }}
            >
                <div id = "dot-edit-location-access-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    {accessibilityTitle}
                </div>
                <div id = {accessibilityContainerID}>
                    <div id = "dot-edit-drivable-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.accessibilityMode === "drivable")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {(objectType === "dot")? this.dotLocationDrivableClick : this.tripLocationDrivableClick}
                    >
                        {accessibilityDrivableLabel}
                    </div>
                    {parkAndWalk}
                    <div id = "dot-edit-undrivable-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.accessibilityMode === "undrivable")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {(objectType === "dot")? this.dotLocationUndrivableClick : this.tripLocationUndrivableClick}
                    >
                        Undrivable
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Location Type
        ============================================================================================
        */

        const locationType = (this.state.accessibilityMode === "parkandwalk")? (
            <div id = "dot-edit-location-type">
                <div id = "dot-edit-location-type-actual"
                    className = {(this.props.colorMode === "day")?
                        "dot-edit-location-type button-light-blue-s3" :
                        "dot-edit-location-type button-blue-s3"}
                    style = {
                        (this.state.locationType === "location")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => { this.setState({ locationType: "location" }); }}
                >
                    Location
                </div>
                <div id = "dot-edit-location-type-parking"
                    className = {(this.props.colorMode === "day")?
                        "dot-edit-location-type button-light-blue-s3" :
                        "dot-edit-location-type button-blue-s3"}
                    style = {
                        (this.state.locationType === "parking")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => { this.setState({ locationType: "parking" }); }}
                >
                    Parking
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Location GPS Input
        ============================================================================================
        */

        let locationLat = null;
        let locationLng = null;
        if (this.state.locationType === "location") {
            locationLat = (this.state.location === null)? "Latitude" : this.state.location.latitude.toFixed(6);
            locationLng = (this.state.location === null)? "Longitude" : this.state.location.longitude.toFixed(6);
        }
        else if (this.state.locationType === "parking") {
            locationLat = (this.state.parkingLocation === null)? "Latitude" : this.state.parkingLocation.latitude.toFixed(6);
            locationLng = (this.state.parkingLocation === null)? "Longitude" : this.state.parkingLocation.longitude.toFixed(6);
        }
        else if (this.state.locationType === "start") {
            locationLat = (this.state.startLocation === null)? "Latitude" : this.state.startLocation.latitude.toFixed(6);
            locationLng = (this.state.startLocation === null)? "Longitude" : this.state.startLocation.longitude.toFixed(6);
        }
        else if (this.state.locationType === "end") {
            locationLat = (this.state.endLocation === null)? "Latitude" : this.state.endLocation.latitude.toFixed(6);
            locationLng = (this.state.endLocation === null)? "Longitude" : this.state.endLocation.longitude.toFixed(6);
        }


        /*
        ============================================================================================
            Everyday Mode
        ============================================================================================
        */
        const everydayMode = (this.state.everydayModeOn)?
        (
            <div id = "dot-edit-everyday-mode"
                onClick = {this.everydayModeClick}
            >
                <div id = "dot-edit-everyday-mode-icon"
                    className = "dot-edit-everyday-mode-icon"
                    style = {{
                        backgroundImage: (this.props.colorMode === "day")?
                            getStaticPath("/images/create/everyday-light-blue.png") :
                            getStaticPath("/images/create/everyday-blue.png")
                    }}
                >
                </div>
                <div id = "dot-edit-everyday-mode-text"
                    className = {(this.props.colorMode === "day")?
                        "dot-edit-everyday-mode-text k4" :
                        "dot-edit-everyday-mode-text w4" }
                >
                    Everyday Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Author Mode
        ============================================================================================
        */
        const authorMode = (this.state.authorModeOn)?
        (
            <div id = "dot-edit-author-mode"
                onClick = {this.authorModeClick}
            >
                <div id = "dot-edit-author-mode-icon"
                    className = "dot-edit-author-mode-icon"
                    style = {{
                        backgroundImage: (this.props.colorMode === "day")?
                            getStaticPath("/images/create/fountain-pen-light-blue.png") :
                            getStaticPath("/images/create/fountain-pen-blue.png")
                    }}
                >
                </div>
                <div id = "dot-edit-author-mode-text"
                    className = {(this.props.colorMode === "day")?
                        "dot-edit-author-mode-text k4" :
                        "dot-edit-author-mode-text w4" }
                >
                    Author Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Contributor Mode
        ============================================================================================
        */
        const contributorMode = (this.state.userIsContributor && this.state.contributorModeOn)?
        (
            <div id = "dot-edit-contributor-mode"
                style = {{ display: (this.state.userIsContributor)?  "inline-block" : "none" }}
                onClick = {this.contributorModeClick}
            >
                <div id = "dot-edit-contributor-mode-icon"
                    className = "dot-edit-contributor-mode-icon"
                    style = {{
                        backgroundImage: (this.props.colorMode === "day")?
                            getStaticPath("/images/create/contributor-light-blue.png") :
                            getStaticPath("/images/create/contributor-blue.png")
                    }}
                >
                </div>
                <div id = "dot-edit-contributor-mode-text"
                    className = {
                        (this.props.colorMode === "day")?
                            "dot-edit-contributor-mode-text k4" :
                            "dot-edit-contributor-mode-text w4"}
                >
                    Contributor Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Save Button Modal
        ============================================================================================
        */

        // Save button image
        const dotEditSaveImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/disk-light-blue.png") :
            getStaticPath("/images/common/disk-blue.png");


        /*
        ============================================================================================
            Loader image
        ============================================================================================
        */

        // Loader image
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif");


        /*
        ============================================================================================
            Editor column
        ============================================================================================
        */

        const editorColumn = (narrowLayout)?
        (
            <div id = "dot-edit-preview-left-small">
                <div className = "dot-edit-preview-left-small-column">
                    <Link key = {"dot-edit-editor-profile-pic"}
                        to = {`/user/${this.props.dotInfo.editor.username}`}
                    >
                        <div id = {(this.props.browserWidth <= 4)?
                                "dot-edit-preview-editor-profile-pic-loader-small" :
                                "dot-edit-preview-editor-profile-pic-loader-medium"}
                            className = "image-loader-s4"
                            style = {{ backgroundImage: loaderImage }}
                        >
                            <div id = "dot-edit-preview-editor-profile-pic"
                                className = {(this.props.colorMode === "day")?
                                    "profile-image-base border-day" :
                                    "profile-image-base border-night"}
                                style = {{ backgroundImage: editorImage }}>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className = "dot-edit-preview-left-small-column">
                    <div id = "dot-edit-preview-editor-name"
                        className = {
                            (this.props.browserWidth <= 4)?
                                (
                                    (this.props.colorMode === "day")? "k5" : "w5"
                                ) : (
                                    (this.props.colorMode === "day")? "k4" : "w4"
                                )
                        }
                    >
                        {
                            ((this.props.dotInfo.editor.name.length > this.nameMobileMaxLength) &&
                            (this.props.browserWidth <= 4))?
                                this.props.dotInfo.editor.first_name :
                                this.props.dotInfo.editor.name
                        }
                    </div>
                </div>
            </div>
        ) : (
            <div id = "dot-edit-preview-left">
                <div className = "dot-edit-preview-editor">
                    <div id = "dot-edit-preview-editor-title"
                        className = {(this.props.colorMode === "day")? "k3" : "w3"}
                    >
                        Editor
                    </div>
                    <Link key = {"dot-edit-editor-profile-pic"}
                        to = {`/user/${this.props.dotInfo.editor.username}`}
                    >
                        <div id = "dot-edit-preview-editor-profile-pic-loader"
                            className = "image-loader-s4"
                            style = {{ backgroundImage: loaderImage }}
                        >
                            <div id = "dot-edit-preview-editor-profile-pic"
                                className = {(this.props.colorMode === "day")?
                                    "profile-image-base border-day" :
                                    "profile-image-base border-night"}
                                style = {{ backgroundImage:  editorImage }}>
                            </div>
                        </div>
                    </Link>
                    <div id = "dot-edit-preview-editor-name"
                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    >
                        {this.props.dotInfo.editor.name}
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Stats column
        ============================================================================================
        */

        const statsColumn = (narrowLayout)?
        (
            <div id = "dot-edit-preview-right-small">
                <div className = "dot-edit-preview-right-small-column">
                    <div className = {
                        (this.props.browserWidth <= 4)?
                            (
                                (this.props.colorMode === "day")?
                                    "dot-edit-preview-numbers-title k4" :
                                    "dot-edit-preview-numbers-title w4"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "dot-edit-preview-numbers-title k3" :
                                    "dot-edit-preview-numbers-title w3"

                            )
                        }
                        data-tip = "Rating"
                        data-for = "dot-edit-preview-numbers-rating-tooltip"
                    >
                        {ratingTitle}
                    </div>
                    {rating}
                </div>
                <div className = "dot-edit-preview-right-small-column">
                    <div className = {
                        (this.props.browserWidth <= 4)?
                            (
                                (this.props.colorMode === "day")?
                                    "dot-edit-preview-numbers-title k4" :
                                    "dot-edit-preview-numbers-title w4"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "dot-edit-preview-numbers-title k3" :
                                    "dot-edit-preview-numbers-title w3"

                            )
                        }
                        data-tip = "Difficulty"
                        data-for = "dot-edit-preview-numbers-difficulty-tooltip"
                    >
                        {difficultyTitle}
                    </div>
                    {difficulty}
                </div>
            </div>
        ) : (
            <div id = "dot-edit-preview-right">
                <div className = {(this.props.colorMode === "day")?
                        "dot-edit-preview-numbers-title k3" :
                        "dot-edit-preview-numbers-title w3"}
                    data-tip = "Rating"
                    data-for = "dot-edit-preview-numbers-rating-tooltip"
                >
                    {ratingTitle}
                </div>
                {rating}
                <div className = {(this.props.colorMode === "day")?
                        "dot-edit-preview-numbers-title k3" :
                        "dot-edit-preview-numbers-title w3"}
                    data-tip = "Difficulty"
                    data-for = "dot-edit-preview-numbers-difficulty-tooltip"
                >
                    {difficultyTitle}
                </div>
                {difficulty}
            </div>
        );


        /*
        ============================================================================================
            Placeholders
        ============================================================================================
        */

        const titlePlaceholder = (this.state.contributorModeOn)? this.placeholderTitle : this.placeholderTitleSimple;

        let overviewPlaceholder = null;

        if (this.state.contributorModeOn) {
            if (objectType === "dot") {
                overviewPlaceholder = this.placeholderOverviewDot;
            }
            else {
                overviewPlaceholder = this.placeholderOverviewTrip;
            }
        }
        else {
            overviewPlaceholder = this.placeholderOverviewSimple;
        }


        /*
        ============================================================================================
            JSX
        ============================================================================================
        */

        return (
            <div id = "dot-edit-container">
                {
                    (this.state.contributorModeOn && this.state.contributorMenuOn)?
                    (
                        <DotEditContributor
                            sliderClassNames = {sliderClassNames}

                            setContributorMenuOn = {this.setContributorMenuOn}

                            scenicTypeClick = {this.scenicTypeClick}
                            experienceTypeClick = {this.experienceTypeClick}
                            dineTypeClick = {this.dineTypeClick}

                            setRating = {this.setRating}
                            setDurationIncrease = {this.setDurationIncrease}
                            setDurationDecrease = {this.setDurationDecrease}
                            setDuration = {this.setDuration}
                            setPhysicality = {this.setPhysicality}
                            setBestTimeFirst = {this.setBestTimeFirst}
                            setBestTimeSecond = {this.setBestTimeSecond}
                            setBestTimeMode = {this.setBestTimeMode}
                            setHoursOn = { this.setHoursOn }
                            setStartHour = {this.setStartHour}
                            setEndHour = {this.setEndHour}
                            setSeasonClosureType = {this.setSeasonClosureType}
                            setClosureStartDate = {this.setClosureStartDate}
                            setClosureEndDate = {this.setClosureEndDate}
                            setDiningOptions = {this.setDiningOptions}

                            userIsContributor = {this.state.userIsContributor}
                            dotInfo = {this.props.dotInfo}
                            dotType = {this.state.dotType}

                            rating = {this.state.rating}
                            durationScaleIndex = {this.state.durationScaleIndex}
                            duration = {this.state.duration}
                            physicality = {this.state.physicality}
                            bestTimeOn = {this.state.bestTimeOn}
                            bestTimeMode = {this.state.bestTimeMode}
                            bestTimeFirst = {this.state.bestTimeFirst}
                            bestTimeSecond = {this.state.bestTimeSecond}
                            hoursOn = {this.state.hoursOn}
                            startHour = {this.state.startHour}
                            endHour = {this.state.endHour}
                            seasonClosureType = {this.state.seasonClosureType}
                            closureStartDate = {this.state.closureStartDate}
                            closureEndDate = {this.state.closureEndDate}
                            diningOptions = {this.state.diningOptions}
                        />
                    ) : null
                }

                <div className = "body-wide"
                    style = {{ overflow: "visible" }}
                >
                    <div className = {(this.props.colorMode === "day")?
                            "dot-edit-save-modal-fixed-bottom dot-edit-save-modal-fixed-bottom-day" :
                            "dot-edit-save-modal-fixed-bottom dot-edit-save-modal-fixed-bottom-night"}
                    >
                        <div className = "dot-edit-save-modal-content-nav">
                            <div id = "dot-edit-save-modal-text"
                                className = {(this.props.colorMode === "day")? "k2" : "w2"}
                            >
                                Save Changes
                            </div>
                            <div id = "dot-edit-save-modal-button"
                                className = "image-button-base"
                                onClick = {this.dotEditSaveClick}
                                style = {{ backgroundImage: dotEditSaveImage }}
                            >
                            </div>
                        </div>
                    </div>
                    <div id = "dot-edit-preview">

                        {(narrowLayout)? null : editorColumn}

                        <div id = "dot-edit-preview-middle"
                            ref = {this.previewMiddle}
                        >
                            <div className= "dot-edit-preview-wrapper" >
                                <input
                                    ref = {this.titleRef}
                                    id = "dot-edit-title"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    value = {this.state.title}
                                    placeholder = {titlePlaceholder}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.titleOnChange}
                                >
                                </input>

                                <TextareaAutosize
                                    ref = {this.overviewRef}
                                    id = "dot-edit-overview"
                                    name = "textarea"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s2 input-day" : "input-s2 input-night"}
                                    value = {this.state.overview}
                                    placeholder = {overviewPlaceholder}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.overviewOnChange}
                                    innerRef = {ref => this.textarea = ref}
                                />
                            </div>

                            <div className = "body-narrow">
                                {uploadGallery}
                            </div>
                        </div>
                        {
                            (narrowLayout)? (
                                <div id = "dot-edit-preview-narrow">
                                    {editorColumn}
                                    {statsColumn}
                                </div>
                                ) : statsColumn
                        }
                    </div>
                </div>

                {todos}

                {history}

                {stories}

                <div className = "body-wide">
                    <div id = "dot-edit-location-container">
                        <div id = "dot-edit-location-text"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Location
                        </div>
                        {name}
                        {area}
                        {accessibility}

                        <div className = "dot-edit-basic-row"
                                style = {{
                                    display: ((this.state.location === null) && (this.state.startLocation === null))?
                                        "none" : "block"
                                }}
                            >
                                <div id = "dot-edit-map-container"
                                    className = {(this.props.colorMode === "day")?
                                        "border-day" : "border-night"}
                                >
                                    {locationType}
                                    {map}
                                </div>
                        </div>

                        {preferred}

                        <div className = "dot-edit-basic-row">
                            {locationSearchTitle}
                            <div className = "dot-edit-basic-input">
                                <input
                                    ref = {this.locationRef}
                                    type = "text"
                                    id = "dot-edit-location"
                                    className = {(this.props.colorMode === "day")?
                                        "input-s3 input-day" : "input-s3 input-night"}
                                    placeholder = {locationPlaceholder}
                                    onFocus = {this.inputOnFocus}
                                    onBlur = {this.inputOnBlur}
                                    onChange = {this.locationOnChange}
                                    style = {{ borderColor: this.state.locationBorderColor }}
                                >
                                </input>
                            </div>
                        </div>

                        <div className = "dot-edit-basic-row"
                            style = {{
                                display: ((this.state.contributorModeOn) && ((this.state.location !== null) ||
                                    (this.state.startLocation !== null) || (this.state.endLocation !== null)))? "block": "none"
                            }}
                        >
                            <div id = "dot-edit-location-gps"
                                style = {{
                                    display: ((this.state.contributorModeOn) && ((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null)))? "block": "none"
                                }}
                            >
                                <div id = "dot-edit-location-gps-title"
                                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                                >
                                    GPS
                                </div>
                                <div className = "dot-edit-basic-input">
                                    <input
                                        ref = {this.locationLatRef}
                                        type = "text"
                                        id = "dot-edit-location-lat"
                                        className = {(this.props.colorMode === "day")?
                                            "input-s3 input-day" : "input-s3 input-night"}
                                        placeholder = {locationLat}
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.locationLatOnChange}
                                        style = {{ borderColor: this.state.locationLatBorderColor }}
                                    />
                                    <input
                                        ref = {this.locationLngRef}
                                        type = "text"
                                        id = "dot-edit-location-lng"
                                        className = {(this.props.colorMode === "day")?
                                            "input-s3 input-day" : "input-s3 input-night"}
                                        placeholder = {locationLng}
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.locationLngOnChange}
                                        style = {{ borderColor: this.state.locationLngBorderColor }}
                                    />
                                    <div id = "dot-edit-location-gps-button"
                                        className = {(this.props.colorMode === "day")?
                                            "button-light-blue-gray-s3" : "button-blue-gray-s3"}
                                        onClick = {this.locationGPSClick}
                                    >
                                        Set
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className = "dot-edit-basic-row">
                            <div id = "dot-edit-write-mode-container">
                                {everydayMode}

                                {authorMode}

                                {contributorMode}
                            </div>

                            {
                                (this.state.contributorModeOn)? (
                                    <div id = "dot-edit-contributor-button"
                                        className = {(this.props.colorMode === "day")?
                                            "button-light-blue-gray-s3" : "button-blue-gray-s3"}
                                        onClick = {() => { this.setContributorMenuOn(true); }}
                                    >
                                        {
                                            (this.props.browserWidth <= 4)?
                                                "Expert Parameters" : "Expert Parameters"

                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        google: state.map.google,
        userInfo: state.user.userInfo,
        dotEditMode: state.more.dotEditMode
    };
}

export default connect(mapStateToProps, null)(DotEditInfo);