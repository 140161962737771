/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    DotStats.js
--------------------------------------------------------------------------------------------
    Content
    - Dot rating / difficulty / hours / time
============================================================================================
*/

// React
import React  from "react";


function DotStats(props) {
    //console.log("DotStats - props = ", props);

    /*
    ============================================================================================
        Difficulty and Rating
    ============================================================================================
    */
    let difficulty = null;
    if (props.dotInfo.dot_extension.difficulty != null) {
        const difficultyValue = Math.min(Math.ceil(props.dotInfo.dot_extension.difficulty), 5);
        const difficultyBar = (difficultyValue < 5)?
        (
            <div className = {(props.colorMode === "day")? 
                props.classPrefix + "-stats-difficulty-bar-front-regular-day" :
                props.classPrefix + "-stats-difficulty-bar-front-regular-night"}
                style = {{ width: Math.round(difficultyValue * props.statsWidth / 5) }}
            >
            </div>
        ) : (
            <div className = {(props.colorMode === "day")? 
                props.classPrefix + "-stats-difficulty-bar-front-round-day" :
                props.classPrefix + "-stats-difficulty-bar-front-round-night"}
                 style = {{ width: Math.round(difficultyValue * props.statsWidth / 5) }}
            >
            </div>
        );
        difficulty = (
            <div className = {props.classPrefix + "-stats-difficulty"}>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-difficulty-text dg5" :
                                props.classPrefix + "-stats-difficulty-text g5"
                        ) : (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-difficulty-text dg4" :
                                props.classPrefix + "-stats-difficulty-text g4"
                        )
                    }
                >
                    Difficulty
                </div>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-difficulty-value-small font-cabin light-red" :
                                props.classPrefix + "-stats-difficulty-value-small font-cabin red"
                        ) : (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-difficulty-value font-cabin light-red" :
                                props.classPrefix + "-stats-difficulty-value font-cabin red"
                        )
                    }
                >
                    {Math.ceil(props.dotInfo.dot_extension.difficulty).toFixed(1)}
                </div>
                {
                    (props.colorMode === "day")?
                    (
                        <div className = {props.classPrefix + "-stats-difficulty-bar-day"}
                            style = {{
                                width: props.statsWidth + 2 * props.statsPaddingWidth,
                                height: props.statsHeight + 2 * props.statsPaddingWidth
                            }}
                        >
                            <div className = {props.classPrefix + "-stats-difficulty-bar-back-day"}>
                                {difficultyBar}
                            </div>
                        </div>
                    ) : (
                        <div className = {props.classPrefix + "-stats-difficulty-bar-night"}
                            style = {{
                                width: props.statsWidth + 2 * props.statsPaddingWidth,
                                height: props.statsHeight + 2 * props.statsPaddingWidth
                            }}
                        >
                            <div className = {props.classPrefix + "-stats-difficulty-bar-back-night"}>
                                {difficultyBar}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    let rating = null;
    if (props.dotInfo.dot_extension.rating != null) {
        const ratingBar = (props.dotInfo.dot_extension.rating < 5)?
        (
            <div className = {(props.colorMode === "day")?
                props.classPrefix + "-stats-rating-bar-front-regular-day" :
                props.classPrefix + "-stats-rating-bar-front-regular-night"}
                style = {{ width: Math.round(props.dotInfo.dot_extension.rating * props.statsWidth / 5) }}
            >
            </div>
        ) : (
            <div className = {(props.colorMode === "day")?
                props.classPrefix + "-stats-rating-bar-front-round-day" :
                props.classPrefix + "-stats-rating-bar-front-round-night"}
                style = {{ width: Math.round(props.dotInfo.dot_extension.rating * props.statsWidth / 5) }}
            >
            </div>
        );

        rating = (
            <div className = {props.classPrefix + "-stats-rating"}>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-rating-text dg5" :
                                props.classPrefix + "-stats-rating-text g5"
                        ) : (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-rating-text dg4" :
                                props.classPrefix + "-stats-rating-text g4"
                        )
                    }
                >
                    Rating
                </div>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-rating-value-small font-cabin light-blue" :
                                props.classPrefix + "-stats-rating-value-small font-cabin blue"
                        ) : (
                            (props.colorMode === "day")?
                                props.classPrefix + "-stats-rating-value font-cabin light-blue" :
                                props.classPrefix + "-stats-rating-value font-cabin blue"
                        )
                    }
                >
                    {Number(props.dotInfo.dot_extension.rating).toFixed(1)}
                </div>
                {
                    (props.colorMode === "day")?
                    (
                        <div className = {props.classPrefix + "-stats-rating-bar-day"}
                            style = {{
                                width: props.statsWidth + 2 * props.statsPaddingWidth,
                                height: props.statsHeight + 2 * props.statsPaddingWidth
                            }}
                        >
                            <div className = {props.classPrefix + "-stats-rating-bar-back-day"}>
                                {ratingBar}
                            </div>
                        </div>
                    ) : (
                        <div className = {props.classPrefix + "-stats-rating-bar-night"}
                            style = {{
                                width: props.statsWidth + 2 * props.statsPaddingWidth,
                                height: props.statsHeight + 2 * props.statsPaddingWidth
                            }}
                        >
                            <div className = {props.classPrefix + "-stats-rating-bar-back-night"}>
                                {ratingBar}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }


    /*
    ============================================================================================
        Hours
    ============================================================================================
    */

    const hours = ((props.dotInfo.dot_extension.start_hour !== null) && (props.dotInfo.dot_extension.end_hour !== null))? (
        <div className = {props.classPrefix + "-stats-hours"}>
            <div className = {
                (props.browserWidth <= 4)?
                    (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-text dg5" :
                            props.classPrefix + "-stats-hours-text g5"
                    ) : (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-text dg4" :
                            props.classPrefix + "-stats-hours-text g4"
                    )
                }
            >
                Hours
            </div>
            <div className = {
                (props.browserWidth <= 4)?
                    (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-value-small font-cabin black" :
                            props.classPrefix + "-stats-hours-value-small font-cabin white"
                    ) : (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-value font-cabin black" :
                            props.classPrefix + "-stats-hours-value font-cabin white"
                    )
                }
            >
                {props.dotInfo.dot_extension.start_hour}
            </div>
            <div className = {
                (props.browserWidth <= 4)?
                    (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-line-small font-cabin black" :
                            props.classPrefix + "-stats-hours-line-small font-cabin white"
                    ) : (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-line font-cabin black" :
                            props.classPrefix + "-stats-hours-line font-cabin white"
                    )
                }
            >
                |
            </div>
            <div className = {
                (props.browserWidth <= 4)?
                    (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-value-small font-cabin black" :
                            props.classPrefix + "-stats-hours-value-small font-cabin white"
                    ) : (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-hours-value font-cabin black" :
                            props.classPrefix + "-stats-hours-value font-cabin white"
                    )
                }
            >
                {props.dotInfo.dot_extension.end_hour}
            </div>
        </div>
    ) : null;


    /*
    ============================================================================================
        Time
    ============================================================================================
    */

    const time = (props.dotInfo.dot_extension.time !== null)? 
    (
        <div className = {props.classPrefix + "-stats-time"}>
            <div className = {
                (props.browserWidth <= 4)?
                    (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-time-text dg5" :
                            props.classPrefix + "-stats-time-text g5"
                    ) : (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-time-text dg4" :
                            props.classPrefix + "-stats-time-text g4"
                    )
                }
            >
                Estimated Time
            </div>
            <div className = {
                (props.browserWidth <= 4)?
                    (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-time-value-small font-cabin black" :
                            props.classPrefix + "-stats-time-value-small font-cabin white"
                    ) : (
                        (props.colorMode === "day")?
                            props.classPrefix + "-stats-time-value font-cabin black" :
                            props.classPrefix + "-stats-time-value font-cabin white"
                    )
                }
            >
                {props.dotInfo.dot_extension.time}
            </div>
        </div>
    ) : null;


    // Render JSX
    return(
        <div className = {props.classPrefix + "-stats"}>
            {rating}
            {difficulty}
            {hours}
            {time}
        </div>
    );
}

export { DotStats };
