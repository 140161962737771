import {
    STORE_ITINERARY,
    STORE_ITINERARY_PARAMS,
    STORE_NEW_ITINERARY,
    STORE_ITINERARIES_FETCH_COMPLETE,
    STORE_PARTICIPANT,
    STORE_NEW_PARTICIPANT,
    STORE_SAVE_ITINERARY
} from "actions";

const INITIAL_STATE = { 
    itineraryInfo: null,
    itineraryParams: null,
    newItinerary: null,
    itinerariesFetchComplete: false,
    participant: { modalOn: false },
    newParticipant: null,
    saveItinerary: null
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
        case STORE_ITINERARY:
            return { ...state, itineraryInfo: action.payload };
        case STORE_ITINERARY_PARAMS:
            return { ...state, itineraryParams: action.payload };
        case STORE_NEW_ITINERARY:
            return { ...state, newItinerary: action.payload };
        case STORE_ITINERARIES_FETCH_COMPLETE:
        	return { ...state, itinerariesFetchComplete: action.payload };
        case STORE_PARTICIPANT:
            return { ...state, participant: action.payload };
        case STORE_NEW_PARTICIPANT:
            return { ...state, newParticipant: action.payload };
        case STORE_SAVE_ITINERARY:
            return { ...state, saveItinerary: action.payload };
		default:
			return state;
	}
};
