import {
	STORE_DOT_TAG,
	STORE_NEW_DOT_TAG,
	STORE_USER_TAG,
	STORE_NEW_USER_TAG
} from "actions"; 

/*
============================================================================================
	Parameters
--------------------------------------------------------------------------------------------
	dotTag
	- modalOn: true | false
	- type: filter | tag
	- mode: save | contribute | everyday | complete
	- id: dot tag id
	- dot: null (filter) | dot object (tag)
	- tags: list of tag objects

	newDotTag
	- operation: add | remove
	- type: filter | tag
	- mode: save | contribute | everyday | complete
	- id: dot tag id
	- tag : new tag object

	userTag
	- modalOn: true | false
	- mode: save | contribute | everyday | complete
	- id: dot id
	- dot: dot object
	- saved: dot is saved by the user

	newUserTag
	- operation: add | remove
	- mode: save | contribute | everyday | complete
	- id: dot id
	- tag : new tag object
============================================================================================
*/

const INITIAL_STATE = {
	dotTag: { 
		modalOn: false,
		type: null,
		mode: null,
		id: null,
		dot: null,
		tags: []
	},
	newDotTag: {
		operation: null,
		type: null,
		mode: null,
		id: null,
		tag: null
	},
	userTag: {
		modalOn: false,
		mode: null,
		id: null,
		dot: null,
		saved: null
	},
	newUserTag: {
		operation: null,
		mode: null,
		id: null,
		tag: null
	}
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_DOT_TAG:
			return { ...state, dotTag: action.payload };
		case STORE_NEW_DOT_TAG:
			return { ...state, newDotTag: action.payload };
		case STORE_USER_TAG:
			return { ...state, userTag: action.payload };
		case STORE_NEW_USER_TAG:
			return { ...state, newUserTag: action.payload };
		default:
			return state;
	}
};