// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import {
    getStaticPath
} from "js/Functions";

// Redux 
import {
    storeShare,
    storeMore,
    storeItinerary,
    storeNewItinerary
} from "actions"; 

// Axios 
import {
    deleteDot,
    postParticipant,
    patchItinerary,
    deleteItinerary
} from "requests"; 

// CSS
import "./More.scss";


class More extends Component {
    constructor(props){
        super(props);

        // Modal DOM
        this.modalRef = React.createRef();

        this.state = {
            deleteConfirmOn: false
        };

        // Bind functions
        this.escFunction = this.escFunction.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSetPrivacy = this.onSetPrivacy.bind(this);
        this.onShare = this.onShare.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onLeave = this.onLeave.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        document.addEventListener("mousedown", this.clickOutside);
    }
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        document.removeEventListener("mousedown", this.clickOutside);
    }

    escFunction(event) {
        // Close modal on pressing esc
        if (this.props.more.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    // Close modal clicking ouside
    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        this.setState(
            {
                deleteConfirmOn: false
            }
        );

        this.props.storeMore(
            {
                modalOn: false,
                type: null,
                info: null,
                isAuthor: false
            }
        );
    }

    onDeleteConfirm() {
        this.setState(
            {
                deleteConfirmOn: true
            }
        );
    }

    onDelete() {
        if (this.props.more.type === "dot") {
            const axiosCallback = () => {
                this.setState(
                    {
                        deleteConfirmOn: false
                    }
                );

                this.props.storeMore(
                    {
                        modalOn: false,
                        type: null,
                        info: null,
                        isAuthor: false
                    }
                );

                this.props.history.push("/dots");
            }

            deleteDot(this.props.more.info.slug)
            .then(axiosCallback)
            .catch(response => console.log("More / onDelete - Axios error ", response));
        }
        else if (this.props.more.type === "itinerary") {
            const axiosCallback = () => {
                this.props.storeNewItinerary({
                    operation: "remove",
                    info: this.props.more.info
                });

                this.setState(
                    {
                        deleteConfirmOn: false
                    }
                );

                this.props.storeMore(
                    {
                        modalOn: false,
                        type: null,
                        info: null,
                        isAuthor: false
                    }
                );

                this.props.history.push("/dots");
            }

            deleteItinerary(this.props.more.info.id)
            .then(axiosCallback)
            .catch(response => console.log("More / onDelete - Axios error ", response));
        }
    }

    onSetPrivacy() {
        const axiosCallback = (response) => {
            //console.log("More / onSetPrivacy - response = ", response);

            // Update itinerary
            this.props.storeItinerary(response.data.content);

            // Close modal
            this.closeModal();
        };

        const dataJSON = {
            is_public: !this.props.more.info.is_public
        };
        //console.log("More / onSetPrivacy - this.props.more.info.is_public = ", this.props.more.info.is_public);
        //console.log("More / onSetPrivacy - dataJSON = ", dataJSON);

        patchItinerary(this.props.more.info.id, dataJSON)
        .then(axiosCallback)
        .catch(response => console.log("More / onSetPrivacy - Axios error ", response));
    }

    onShare() {
        this.props.storeMore(
            {
                modalOn: false,
                type: null,
                info: null,
                isAuthor: false
            }
        );

        this.props.storeShare(
            {
                modalOn: true,
                type: this.props.more.type,
                info: this.props.more.info
            }
        );
    }

    onEdit() {
        if (this.props.more.type === "dot") {
            this.props.storeMore(
                {
                    modalOn: false,
                    type: null,
                    info: null,
                    isAuthor: false
                }
            );

            this.props.history.push(`/dot_edit/${this.props.more.info.slug}`);
        }        
    }

    onLeave() {
        const axiosCallback = (response) => {
            //console.log("response = ", response);

            // Is private
            const isPrivate = !this.props.more.info.is_public;

            // Close modal
            this.closeModal();

            // Reroute to home
            if (isPrivate) {
                this.props.history.push("/");
            }
        };

        // Data to send
        const dataJSON = {
            operation: "remove",
            user_id: this.props.userInfo.id,
            itinerary_id: this.props.more.info.id,
            is_invited: true
        };

        // Send data to server to remove oneself from an itinerary
        postParticipant(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Participant / onLeave - ", response);
            }
        );        
    }

    render() {
        //console.log("More / render - this.props = ", this.props);

        // Is host
        let isHost = false;
        let isGuest = false;
        if (this.props.more.type === "itinerary" && this.props.more.info !== null) {
            for (let i = 0; i < this.props.more.info.guests.length; i++) {
                if (this.props.userInfo.id === this.props.more.info.guests[i].id) {
                    isGuest = true;
                }
            }

            isHost = (this.props.userInfo.id === this.props.more.info.user.id);
        }

        if (this.props.more.modalOn === true) {
            if (this.state.deleteConfirmOn === true) {
                return (
                    <div className = "delete-modal">
                        <div className =  {(this.props.colorMode === "day")? 
                                "delete-modal-content modal-day" : 
                                "delete-modal-content modal-night"}
                        >
                            <div className = "delete-modal-content__cancel image-button-weaker-s3"
                                style = {{ 
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/common/cancel-black.png") :
                                        getStaticPath("/images/common/cancel-white.png") 
                                }}
                               onClick = {this.closeModal} >
                            </div>

                            <div className = {(this.props.colorMode === "day")? 
                                    "delete-modal-content__title k2" : 
                                    "delete-modal-content__title w2"}
                            > 
                                Delete
                            </div>

                            <div className = {(this.props.colorMode === "day")? 
                                    "delete-modal-content__text dg4" : 
                                    "delete-modal-content__text g4"}
                            >
                                Are you sure to delete?
                            </div>

                            <div className = "delete-modal-content__confirm"> 
                                <div className = "delete-modal-content__confirm-select"> 
                                    <div className =  {(this.props.colorMode === "day")?
                                            "delete-modal-content__confirm-select-yes button-light-blue-gray-s2" :
                                            "delete-modal-content__confirm-select-yes button-blue-gray-s2"}
                                        onClick = {this.onDelete}
                                    > 
                                        Yes 
                                    </div>
                                    <div className = {(this.props.colorMode === "day")?
                                            "delete-modal-content__confirm-select-no button-light-blue-gray-s2" :
                                            "delete-modal-content__confirm-select-no button-blue-gray-s2"}
                                        onClick = {this.closeModal}
                                    >
                                        No
                                    </div> 
                                </div> 
                            </div> 
                        </div>
                    </div>    
                );
            }
            else {
                return (
                    <div className = "more-modal">
                        <div className =  {(this.props.colorMode === "day")? 
                                "more-modal-content modal-day" : 
                                "more-modal-content modal-night border-black"}
                            ref = {this.modalRef}
                        >
                            <div className = "more-modal-content__cancel image-button-weaker-s3"
                                style = {{ 
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/common/cancel-black.png") :
                                        getStaticPath("/images/common/cancel-white.png") 
                                }}
                                onClick = {this.closeModal}
                            >
                            </div>

                            <div className = {(this.props.colorMode === "day")? 
                                    "more-modal-content__title k2" : 
                                    "more-modal-content__title w2"}
                            >
                                Options
                            </div>

                            <React.Fragment>
                                {
                                    (this.props.more.isAuthor && this.props.more.type === "dot")?
                                    (
                                        <div className = "more-modal-content__item">
                                            <div className = "more-modal-content__item-image image-cover"
                                                style = {{ 
                                                    backgroundImage: (this.props.colorMode === "day")?
                                                        getStaticPath("/images/common/edit-light-blue.png") :
                                                        getStaticPath("/images/common/edit-blue.png")
                                                }}
                                            >
                                            </div>
                                            <span 
                                                className = {(this.props.colorMode === "day")? 
                                                    "more-modal-content__item-text k4" :
                                                    "more-modal-content__item-text w4"}
                                                onClick = {this.onEdit}
                                            >
                                                Edit
                                            </span>
                                        </div>
                                    ) : null
                                }

                                {
                                    (this.props.more.isAuthor || this.props.more.type === "itinerary")?
                                    (
                                        <div className = "more-modal-content__item">
                                            <div className = "more-modal-content__item-image image-cover"
                                                style = {{ 
                                                    backgroundImage: (this.props.colorMode === "day")?
                                                        getStaticPath("/images/common/share-light-blue.png") :
                                                        getStaticPath("/images/common/share-blue.png")
                                                }}
                                            >
                                            </div>
                                            <span 
                                                className = {(this.props.colorMode === "day")? 
                                                    "more-modal-content__item-text k4" :
                                                    "more-modal-content__item-text w4"}
                                                onClick = {this.onShare}
                                            >
                                                Share
                                            </span>
                                        </div>
                                    ) : null
                                }

                                {
                                    (isGuest && this.props.more.type === "itinerary")?
                                    (
                                        <div className = "more-modal-content__item">
                                            <div className = "more-modal-content__item-image image-cover"
                                                style = {{ 
                                                    backgroundImage: (this.props.colorMode === "day")?
                                                        getStaticPath("/images/common/exit-light-blue.png") :
                                                        getStaticPath("/images/common/exit-blue.png")
                                                }}
                                            >
                                            </div>
                                            <span 
                                                className = {(this.props.colorMode === "day")? 
                                                    "more-modal-content__item-text k4" :
                                                    "more-modal-content__item-text w4"}
                                                onClick = {this.onLeave}
                                            >
                                                Leave Trip
                                            </span>
                                        </div>
                                    ) : null
                                }

                                {
                                    (isHost && this.props.more.type === "itinerary")?
                                    (
                                        <div className = "more-modal-content__item">
                                            <div className = "more-modal-content__item-image image-cover"
                                                style = {{ 
                                                    backgroundImage: (this.props.more.info.is_public)?
                                                        (
                                                            (this.props.colorMode === "day")?
                                                                getStaticPath("/images/common/lock-light-blue.png") :
                                                                getStaticPath("/images/common/lock-blue.png")
                                                        ) : (
                                                            (this.props.colorMode === "day")?
                                                                getStaticPath("/images/common/unlock-light-blue.png") :
                                                                getStaticPath("/images/common/unlock-blue.png")
                                                        )
                                                }}
                                            >
                                            </div>
                                            <span
                                                className = {(this.props.colorMode === "day")?
                                                    "more-modal-content__item-text k4" :
                                                    "more-modal-content__item-text w4"}
                                                onClick = {this.onSetPrivacy}
                                            >
                                                {
                                                    (this.props.more.info.is_public)?
                                                        "Make a Private Trip" : "Make an Open Trip"
                                                }
                                            </span>
                                        </div>
                                    ) : null
                                }

                                {
                                    (this.props.more.isAuthor)?
                                    (
                                        <div className = "more-modal-content__item">
                                            <div className = "more-modal-content__item-image image-cover"
                                                style = {{
                                                    backgroundImage: (this.props.colorMode === "day")?
                                                        getStaticPath("/images/common/trash-can-light-blue.png") :
                                                        getStaticPath("/images/common/trash-can-blue.png")
                                                }}
                                            >
                                            </div>
                                            <span
                                                className = {(this.props.colorMode === "day")? 
                                                    "more-modal-content__item-text k4" :
                                                    "more-modal-content__item-text w4"}
                                                onClick = {this.onDeleteConfirm}
                                            >
                                                Delete
                                            </span>
                                        </div>
                                    ) : null
                                }
                            </React.Fragment>
                        </div> 
                    </div>
                );
            }
        } 
        else {
            return null;
        }
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        more: state.more.more,
        share: state.share.share,
        userInfo: state.user.userInfo,
        itineraryInfo: state.itinerary.itineraryInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeShare,
            storeMore,
            storeItinerary,
            storeNewItinerary
        },
        dispatch
    ); 
}

export default connect(mapStateToProps, mapDispatchToProps)(More); 
