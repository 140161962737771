import { STORE_MAP } from "actions"; 

const INITIAL_STATE = { google: null };
export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_MAP: 
			return { ...state, google: action.payload }; 
		default:
			return state;
	}
};