import React from 'react';
import { debounce } from 'js/Functions';


export default class Drag extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDragging: false,

            originalX: 0,
            originalY: 0,

            translateX: 0,
            translateY: 0,

            lastTranslateX: 0,
            lastTranslateY: 0
        };

        // Bind functions
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMoveDebounced = debounce(this.handleMouseMove, 5);
    }

    componentWillUnmount() {
        if (window.touchOn) {
            window.removeEventListener('touchmove', this.handleMouseMoveDebounced);
            window.removeEventListener('touchend', this.handleMouseUp);
        }
        else {
            window.removeEventListener('mousemove', this.handleMouseMoveDebounced);
            window.removeEventListener('mouseup', this.handleMouseUp);
        }
    }

    handleMouseDown(event) {
        //console.log("Drag - handleMouseDown");

        // Get clientX and clientY
        let clientX = null;
        let clientY = null;

        if (window.touchOn) {
            event.preventDefault();
            const touch = event.touches[0];
            clientX = touch.clientX;
            clientY = touch.clientY;
        }
        else {
            clientX = event.clientX;
            clientY = event.clientY;
        }

        // Add event listeners
        if (window.touchOn) {
            window.addEventListener('touchmove', this.handleMouseMoveDebounced);
            window.addEventListener('touchend', this.handleMouseUp);
        }
        else {
            window.addEventListener('mousemove', this.handleMouseMoveDebounced);
            window.addEventListener('mouseup', this.handleMouseUp);
        }

        if (this.props.onDragStart) {
            this.props.onDragStart();
        }

        // Update state
        this.setState({
            originalX: clientX,
            originalY: clientY,
            isDragging: true
        });
    }

    handleMouseMove(event) {
        //console.log("Drag - handleMouseMove");

        // Get clientX and clientY
        let clientX = null;
        let clientY = null;

        if (window.touchOn) {
            event.preventDefault();
            const touch = event.touches[0];
            clientX = touch.clientX;
            clientY = touch.clientY;
        }
        else {
            clientX = event.clientX;
            clientY = event.clientY;
        }

        const { isDragging } = this.state;
        const { onDrag } = this.props;

        if (!isDragging) {
            return;
        }

        this.setState(prevState => ({
                translateX: clientX - prevState.originalX + prevState.lastTranslateX,
                translateY: clientY - prevState.originalY + prevState.lastTranslateY
            }),
            () => {
                if (onDrag) {
                    onDrag({
                        translateX: this.state.translateX,
                        translateY: this.state.translateY
                    });
                }
            }
        );
    }

    handleMouseUp(event) {
        //console.log("Drag - handleMouseUp");

        if (window.touchOn) {
            event.preventDefault();
        }

        if (window.touchOn) {
            window.removeEventListener('touchmove', this.handleMouseMoveDebounced);
            window.removeEventListener('touchend', this.handleMouseUp);
        }
        else {
            window.removeEventListener('mousemove', this.handleMouseMoveDebounced);
            window.removeEventListener('mouseup', this.handleMouseUp);
        }

        // Update state
        this.setState(
            {
                originalX: 0,
                originalY: 0,
                lastTranslateX: this.state.translateX,
                lastTranslateY: this.state.translateY,

                isDragging: false
            },
            () => {
                if (this.props.onDragEnd) {
                    this.props.onDragEnd();
                }
            }
        );
    }

    render() {
        const { children } = this.props;

        return (
        <div 
            onMouseDown = {
                (this.props.dragOn)?
                    ((window.touchOn)? null : this.handleMouseDown) : null
            }
            onTouchStart = {
                (this.props.dragOn)?
                    ((window.touchOn)? this.handleMouseDown : null) : null
            }
            style = {{ 
                cursor: "pointer",
                touchAction: (this.props.dragOn)? "none" : "auto"
            }}
        >
            {children}
        </div>
        );
    }
}
