/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    TripFunctions.js
    - Trip related components and functions
--------------------------------------------------------------------------------------------
    Content
    - dotIndicesToIDs
    - dotIDsToIndices
    - updateTimesSetState
    - updateTimes
	- updateDate
	- updateDayInfo
	- timeSliderOnChange
	- timeSliderOnChangeComplete
	- timeSliderFormatLabel
	- startInputOnFocus
	- startInputOnBlur
	- endInputOnFocus
	- endInputOnBlur
	- setStartLocation
	- setEndLocation
	- setStartTimezone
	- setEndTimezone
	- resetAnimations
    - dotClick
    - dotHoverOn
    - dotHoverOff
    - endHoverOn
    - endHoverOff
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";
import ReactDOM from "react-dom";

// Modules
import { Time } from "./Time";
import moment from "moment-timezone";

// Functions
import { fetchWeather } from "components/DayInfo";
import { updateStateChain3Short } from "./Functions";


/*
============================================================================================
    dotIndicesToIDs
--------------------------------------------------------------------------------------------
    - Convert dot indices to dot IDs
============================================================================================
*/

function dotIndicesToIDs(dotIndices, dotsInfo) {
    // Initialize ID list
    const dotIDs = [];

    // For all indices
    for (let i = 0; i < dotIndices.length; i++) {
        dotIDs.push(dotsInfo[dotIndices[i]].id);
    }

    return dotIDs;
}


/*
============================================================================================
    dotIDsToIndices
--------------------------------------------------------------------------------------------
    - Convert dot IDs to indices
============================================================================================
*/

function dotIDsToIndices(dotIDs, dotsInfo) {
    // Initialize indices
    const dotIndices = [];

    // For all dots
    for (let i = 0; i < dotIDs.length; i++) {
        for (let j = 0; j < dotsInfo.length; j++) {
            if (dotIDs[i] === dotsInfo[j].id) {
                dotIndices.push(j);
            }
        }
    }

    return dotIndices;
}


/* 
============================================================================================
    updateTimesSetState
--------------------------------------------------------------------------------------------
    - Set calculate times as states
============================================================================================
*/

function updateTimesSetState(time, callback) {
    //console.log("TripFunctions / updateTimesSetState time = ", time);

    // set the time variables
    const firstState = {
        startMoment: time.startMoment,
        endMoment: time.endMoment,
        startEndTimeValues : {min: time.startMoment.unix(), max: time.endMoment.unix()},
        hour00Moment: time.hour00Moment,
        hour24Moment: time.hour24Moment,
        sunTimes: time.sunTimes,
        moonTimes: time.moonTimes,
        dayTime: time.totalDayTime,
        offsetTime: time.totalOffsetTime,
        totalTime: time.totalTime,
        moonPhase: time.moonPhase,
        moonPhaseDigits: time.moonPhaseDigits
    };

    // turn on the sun and moon times windows
    const secondState = {
        sunMoonOn: true
    };

    // fade in the sun and moon times windows
    const thirdState = {
        sunMoonOpacity: 1.0
    };
    //console.log("TripFunctions / updateTimesSetState firstState = ", firstState);

    // three step callback chain
    updateStateChain3Short(this.setState, firstState, secondState, thirdState, callback, this.transitionTime);
}


/* 
============================================================================================
    updateTimes
    - Need to be used bound to either Trip / Itinerary component
============================================================================================
*/

function updateTimes(callback, adjustTimes) {
    //console.log("TripFunctions / updateTimes - this.state.tripInfo = ", this.state.tripInfo);
    //console.log("TripFunctions / updateTimes - startLocation = ", this.state.startLocation);

    // set props
    const timeProps = {
        startMoment: this.state.startMoment,
        endMoment: this.state.endMoment,
        startLocation: this.state.startLocation,
        endLocation: this.state.endLocation,
        location: this.state.location,
        startTimezone: this.state.startTimezone,
        endTimezone: this.state.endTimezone,
        timezone: this.state.timezone,
        adjustTimes: adjustTimes
    };
    //console.log("TripFunctions / updateTimes - timeProps = ", timeProps);

    // Create a Time object
    const time = new Time(timeProps);
    //console.log("TripFunctions / updateTimes - time = ", time);

    // construct a callback chain to set up times
    const setUpTime = time.setStartEndMoments.bind(
        time,
        time.getSunTimes.bind(time,
            time.getMoonTimes.bind(time,
                time.getTimes.bind(time,
                    this.updateTimesSetState.bind(this, time, callback)
                )
            )
        )
    );

    // execute the workflow
    setUpTime();
}


/* 
============================================================================================
    updateDate / updateDayInfo
============================================================================================
*/

function updateDate(date) {
    //console.log("TripFunctions / updateDate - this.state = ", this.state);
    //console.log("TripFunctions / updateDate - date = ", date);

    // Update the start and end dateTime
    const startMoment = moment(date);
    const endMoment = (this.state.endMoment)?
        moment.unix(startMoment.unix() + (this.state.endMoment.unix() - this.state.startMoment.unix())).tz(this.state.endTimezone):
        null;
    //console.log ("TripFunctions / updateDate - date = ", date);
    //console.log ("TripFunctions / updateDate - startMoment = ", startMoment.format());
    //console.log ("TripFunctions / updateDate - endMoment = ", endMoment.format());

    // Set state and trigger updateTimes, updateDayInfo, and displayWarning
    this.setState(
        {
            startMoment: startMoment,
            endMoment: endMoment,
        },
        this.updateDayInfo.bind(this, true, false, this.checkUpdate)
    );
}


function updateDayInfo(updateWeather, adjustTimes, callback) {
    //console.log("TripFunctions / updateDayInfo - this.state = ", this.state);
    //console.log("TripFunctions / updateDayInfo - updateWeather = ", updateWeather);
    //console.log("TripFunctions / updateDayInfo - adjustTimes = ", adjustTimes);
    //console.log("TripFunctions / updateDayInfo - callback = ", callback);

    // Update day times / get weather
    this.updateTimes(
        (updateWeather)?
            fetchWeather.bind(
                this,
                callback
            ) : callback,
        adjustTimes
    );
}


/*
============================================================================================
    timeSliderOnChange / timeSliderOnChangeComplete / timeSliderFormatLabel
--------------------------------------------------------------------------------------------
    - Set and format start and end times
============================================================================================
*/

function timeSliderOnChange(values) {
    //console.log("TripFunctions / timeSliderOnChange - this = ", this);
    //console.log("TripFunctions / timeSliderOnChange - values = ", values);

    this.setState({
        startEndTimeValues: values
    });
}


function timeSliderOnChangeComplete(values, checkUpdate) {
    const startMoment = moment.unix(values.min).tz(this.state.startTimezone);
    const endMoment = moment.unix(values.max).tz(this.state.endTimezone);
    //console.log("TripFunctions / timeSliderOnChangeComplete - this = ", this);
    //console.log("TripFunctions / timeSliderOnChangeComplete - values = ", values);
    //console.log("TripFunctions / timeSliderOnChangeComplete - startMoment = ", startMoment);
    //console.log("TripFunctions / timeSliderOnChangeComplete - endMoment = ", endMoment);
    //console.log("TripFunctions / timeSliderOnChangeComplete - this.state.sunTimes = ", this.state.sunTimes);
    
    // Update start and end moments / update day info / check if update is necessary
    this.setState(
        {
            startMoment: startMoment,
            endMoment: endMoment,
            startEndTimeValues: values,
        },
        this.updateTimes.bind(
            this,
            this.showItineraryTimeWarning.bind(this, checkUpdate),
            false
        )
    );

    // Issue a warning if start or end time is outside of day time

}


function timeSliderFormatLabel(value) {
    //console.log("TripFunctions / timeSliderFormatLabel - value = ",value);
    const time = moment.unix(value);
    if (value === this.state.startEndTimeValues.min) {
        return time.tz(this.state.startTimezone).format("h:mm a z")
    }
    else if (value === this.state.startEndTimeValues.max) {
        return time.tz(this.state.endTimezone).format("h:mm a z")
    }
    else {
        console.log("[WARNING] TripFunctions / timeSliderFormatLabel - invalid value");
        return null
    }
}


/*
============================================================================================
    Show Itinerary Time Warning
============================================================================================
*/

function showItineraryTimeWarning(checkUpdate) {
    const timeMargin = 600;

    if ((this.state.startMoment.unix() < this.state.sunTimes.sunrise.unix() - timeMargin) && (this.state.endMoment.unix() <= this.state.sunTimes.sunset.unix() + timeMargin)) {
        this.props.storeWarningAlert({
            message: "Trip begins before sunrise. Optimzation may not work properly.",
            on: true
        });
    }
    else if ((this.state.startMoment.unix() >= this.state.sunTimes.sunrise.unix() - timeMargin) && (this.state.endMoment.unix() > this.state.sunTimes.sunset.unix() + timeMargin)) {
        this.props.storeWarningAlert({
            message: "Trip ends after sunset. Optimzation may not work properly.",
            on: true
        });
    }
    else if ((this.state.startMoment.unix() < this.state.sunTimes.sunrise.unix() - timeMargin) && (this.state.endMoment.unix() > this.state.sunTimes.sunset.unix() + timeMargin)) {
        this.props.storeWarningAlert({
            message: "Trip is longer than the day time. Optimzation may not work properly.",
            on: true
        });
    }
    else {
        this.props.storeWarningAlert({
            message: null,
            on: false
        });
    }

    if (checkUpdate) {
        this.checkUpdate();
    }
}


/* 
============================================================================================
    Start and end location input actions
============================================================================================
*/

function startInputOnFocus() {
    this.startInputNode.placeholder = "";
}


function startInputOnBlur() {
    this.startInputNode.placeholder = this.state.startLocationName;
    //console.log("TripFunctions / startInputOnBlur - this.startInputNode = ", this.startInputNode);

    /*
    if (this.startInputNode.value) {
        //console.log("TripFunctions / endInputOnBlur - this.startInputNode.value = ", this.startInputNode.value);
    }
    else {
        this.setState({
                startLocation: this.state.tripInfo.trip_extension.start_location,
                startTimezone: this.state.tripInfo.trip_extension.start_timezone
            },
            this.updateTimes.bind(
                this,
                null,
                false
            )
        );
    }
    */
}


function endInputOnFocus() {
    this.endInputNode.placeholder = "";
}


function endInputOnBlur() {
    this.endInputNode.placeholder = this.state.endLocationName;
    //console.log("TripFunctions / endInputOnBlur - this.endInputNode = ", this.endInputNode);

    /*
    if (this.endInputNode.value) {
        //console.log("TripFunctions / endInputOnBlur - this.endInputNode.value = ", this.endInputNode.value);
    }
    else {
        this.setState({
                endLocation: this.state.tripInfo.trip_extension.end_location,
                endTimezone: this.state.tripInfo.trip_extension.end_timezone
            },
            this.updateTimes.bind(
                this,
                null,
                false
            )
        );
    }
    */
}


/* 
============================================================================================
    setStartLocation / setEndLocation (Map and location inputs)
============================================================================================
*/

function setStartLocation(startLocation, startLocationName) {
    //console.log("TripFunctions / setStartLocation - startLocation = ", startLocation);

    // Figure out if check update is required
    const checkUpdate = (typeof this.checkUpdate === "function")?
        this.checkUpdate : () => {};

    // Update state and execute callbacks (setStartTimezone / updateTimes / checkUpdate)
    this.setState(
        {
            startLocation: startLocation,
            startLocationName: startLocationName
        },
        this.setStartTimezone.bind(
            this,
            startLocation,
            () => {
                this.updateTimes(checkUpdate, false);
            }
        )
    );
}


function setEndLocation(endLocation, endLocationName) {
    //console.log("TripFunctions / setEndLocation - newEndLocation = ", newEndLocation);

    // Figure out if check update is required
    const checkUpdate = (typeof this.checkUpdate === "function")?
        this.checkUpdate : () => {};

    // Update state and execute callbacks (setEndTimezone / updateTimes / checkUpdate)
    this.setState(
        {
            endLocation: endLocation,
            endLocationName: endLocationName
        },
        this.setEndTimezone.bind(
            this, 
            endLocation,
            () => {
                this.updateTimes(checkUpdate, false);
            }
        )
    );
}


function setStartTimezone(startLocation, callback) {
    //console.log("TripFunctions / setStartTimezone - startLocation = ", startLocation);
    const axiosCallback = (response) => {
        //console.log("Trip / setStartTimezone - response.data.content = ", response.data.content);
        this.setState(
            {
                startTimezone: response.data.content.timezone
            },
            callback
        );
    };

    window.axiosCSRF({
        method: "post",
        url: "/api/timezone/",
        data: {
            location: startLocation
        }
    })
    .then(axiosCallback)
    .catch((response) => {console.log("[WARNING] TripFunctions / setStartTimezone - error = ", response);})
}


function setEndTimezone(endLocation, callback) {
    //console.log("TripFunctions / setEndTimezone - endLocation = ", endLocation);        
    const axiosCallback = (response) => {
        //console.log("Trip / setEndTimezone - response.data.content = ", response.data.content);
        this.setState(
            {
                endTimezone: response.data.content.timezone
            },
            callback
        );
    };

    window.axiosCSRF({
        method: "post",
        url: "/api/utils/timezone/",
        data: {
            location: endLocation
        }
    })
    .then(axiosCallback)
    .catch((response) => {console.log("[WARNING] TripFunctions / setEndTimezone - error = ", response);})
}


/* 
============================================================================================
    Map resetAnimations
============================================================================================
*/

function resetAnimations(callback) {
    //console.log("TripFunctions / resetAnimations - resetAnimations triggered");
    this.setState(
        {
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false
        }, 
        callback
    );
}


/*
============================================================================================
    Dot click
============================================================================================
*/

function dotClick(dotIndex, childIndex) {
    //console.log("TripFunctions / dotClick - dotIndex = ", dotIndex);

    // When unselecting a dot and zooming out
    if (((dotIndex != null) && (this.state.selected === dotIndex)) || ((childIndex != null) && (this.state.selectedChild === childIndex))) {
        const stateToUpdate = {
            selected: null,
            selectedChild: null,
            //displayChildren: dotIndex,
            selectedImageIndex: 0,
            previewOn: false,
            previewContentOn: false,
            previewOpacity: 0.0,
            mapZoomOutAnimation: true // Controls zooming of Google map
        };

        this.setState(
            stateToUpdate,
            this.resetAnimations
        );
    }
    else {
        // If another dot was already selected
        if ((childIndex != null) && ((this.state.selected != null) || (this.state.selectedChild != null))) {
            this.setState(
                {
                    selected: null,
                    selectedChild: childIndex,
                    //displayChildren: dotIndex,
                    selectedImageIndex: 0,
                    mapZoomInAnimation: true // Controls zooming of Google map
                },
                this.resetAnimations
            );
        }
        else if ((dotIndex != null) && ((this.state.selected != null) || (this.state.selectedChild != null))) {
            this.setState(
                {
                    selected: dotIndex,
                    selectedChild: null,
                    //displayChildren: null,
                    selectedImageIndex: 0,
                    mapZoomInAnimation: true // Controls zooming of Google map
                }, 
                this.resetAnimations
            );
        }
        // If no dot was selected and need to zoom in on the newly selected
        else {
            // If map is not open
            if (!this.state.mapOn) {
                const stateToUpdate = (childIndex != null)? {
                    selected: null,
                    selectedChild: childIndex,
                    //displayChildren: dotIndex,
                    previewOn: true,
                    previewContentOn: true,
                    previewOpacity: 1.0,
                    mapOn: true,
                    mapMounted: true,
                    mapOpacity: 1.0,
                    mapIconOpacity: 1.0,
                    mapZoomInAnimation: true // Controls zooming of Google map
                } : {
                    selected: dotIndex,
                    selectedChild: null,
                    //displayChildren: null,
                    previewOn: true,
                    previewContentOn: true,
                    previewOpacity: 1.0,
                    mapOn: true,
                    mapMounted: true,
                    mapOpacity: 1.0,
                    mapIconOpacity: 1.0,
                    mapZoomInAnimation: true // Controls zooming of Google map
                };

                this.setState(
                    stateToUpdate,
                    this.resetAnimations
                );
            }
            // If map is open
            else {
                const stateToUpdate = (childIndex != null)? {
                    selected: null,
                    selectedChild: childIndex,
                    //displayChildren: dotIndex,
                    previewOn: true,
                    previewContentOn: true,
                    previewOpacity: 1.0,
                    mapZoomInAnimation: true // Controls zooming of Google map
                } : {
                    selected: dotIndex,
                    selectedChild: null,
                    //displayChildren: null,
                    previewOn: true,
                    previewContentOn: true,
                    previewOpacity: 1.0,
                    mapZoomInAnimation: true // Controls zooming of Google map
                };

                this.setState(
                    stateToUpdate,
                    this.resetAnimations
                );
            }
        }
    }
}


/*
============================================================================================
    dotHoverOn
--------------------------------------------------------------------------------------------
    - Create name and factors for a marker whose dot is being hovered over
--------------------------------------------------------------------------------------------
    Props
    - dotIndex
    - dotInfo
    - selected
============================================================================================
*/

function dotHoverOn(dotIndex, dotInfo, selected, inItinerary) {
    //console.log("TripFunctions / dotHoverOn - dotIndex = ", dotIndex);
    //console.log("TripFunctions / dotHoverOn - dotInfo = ", dotInfo);
    //console.log("TripFunctions / dotHoverOn - selected = ", selected);
    //console.log("TripFunctions / dotHoverOn - inItinerary= ", inItinerary);
    
    // Only when the map is on
    if (this.state.mapOn) {
        if (this.mapSource === "google") {
            // If included in itinerary
            if (inItinerary) {
                //console.log("TripFunctions / dotHoverOn - dotIndex = ", dotIndex);
                //console.log("TripFunctions / dotHoverOn - dotInfo = ", dotInfo);
                //console.log("TripFunctions / dotHoverOn - selected = ", selected);
                //console.log("TripFunctions / dotHoverOn - inItinerary = ", inItinerary);

                // Hover classname
                const hoverClassName = (this.mapMode === "trip-overview")?
                    "pulse-overview-hover" : "pulse-hover";

                // Get the marker
                const markerID = "marker-" + dotIndex;
                const marker = document.getElementById(markerID);

                // If marker is not null
                if (marker !== null) {
                    // Add the hover effect
                    if (!selected) {
                        //console.log("TripFunctions / dotHoverOn - marker = ", marker);
                        marker.classList.add(hoverClassName);
                    }

                    // Construct name
                    const name = (
                        <div id="hover-name" className = "marker-name">
                            {dotInfo.name}
                        </div>
                    );

                    // Factors 
                    let factors = null;
                    if (dotInfo.type !== "EV" && dotInfo.type !== "AU") {
                        const ratingValue = dotInfo.dot_extension.rating;
                        const difficultyValue = Math.min(Math.ceil(dotInfo.dot_extension.difficulty), 5);

                        // Construct factors
                        const factorWidth = 70;
                        const ratingWidth = Math.round(ratingValue / 5 * factorWidth);
                        const difficultyWidth = Math.round(difficultyValue / 5 * factorWidth);

                        if ((dotInfo.type === "SC") || (dotInfo.type === "RT")) {
                            const scenicRatingClassName = (ratingValue === 5)? 
                                "marker-scenic-rating-end" : "marker-scenic-rating";
                            const difficultyClassName = (difficultyValue === 5)?
                                "marker-difficulty-end" : "marker-difficulty";

                            factors = (
                                <div id = "hover-factors" className = "marker-factors">
                                    <div className = "marker-scenic-rating-wrapper">
                                        <div className = {scenicRatingClassName} style = {{ width: ratingWidth }}>
                                        </div>
                                    </div>
                                    <div className = "marker-difficulty-wrapper">
                                        <div className = {difficultyClassName} style = {{ width: difficultyWidth }}>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        else {
                            const ratingClassName = (dotInfo.dot_extension.rating === 5)?
                                "marker-rating-end" : "marker-rating";

                            factors = (
                                <div id = "hover-factors" className = "marker-factors">
                                    <div className = "marker-rating-wrapper">
                                        <div className = {ratingClassName} style = {{ width: ratingWidth }}>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    }

                    // Content of the marker
                    const content = (
                        <div>
                            {name}
                            {factors}
                        </div>
                    );

                    // Render into the DOM
                    ReactDOM.render(content, marker);
                }
            }
            // When in the options
            else {

            }
        }
        else {
            // For open map
        }
    }

    // Update state
    this.setState({
        hovered: dotIndex
    });
}


/*
============================================================================================
    dotHoverOff
--------------------------------------------------------------------------------------------
    - Cancel pulse effect and clear name and factors of the marker when not hovered
--------------------------------------------------------------------------------------------
    Props
    - dotIndex
    - selected
============================================================================================
*/

function dotHoverOff(dotIndex, selected, inItinerary) {
    //console.log("TripFunctions / dotHoverOff - dotIndex = ", dotIndex);
    //console.log("TripFunctions / dotHoverOff - selected = ", selected);
    //console.log("TripFunctions / dotHoverOff - inItinerary= ", inItinerary);

    // Only when the map is on
    if (this.state.mapOn) {
        if (this.mapSource === "google") {
            const hoverClassName = (this.mapMode === "trip-overview")?
                "pulse-overview-hover" : "pulse-hover";

            // Get all markers with hover class
            const markers = document.getElementsByClassName(hoverClassName);
            //console.log("TripFunctions / dotHoverOff - markers = ", markers);

            // Get all markers with hover class
            for (let i = 0; i < markers.length; i++) {
                // Get the marker
                const marker = markers.item(i);

                // If marker is not null
                if (marker !== null) {
                    // Remove hover class
                    marker.classList.remove(hoverClassName);

                    // Unmount the content
                    ReactDOM.unmountComponentAtNode(marker);
                }
            }
        }
        else {
            // For open map
        }
    }

    // Update state
    this.setState({
        hovered: null
    });
}


/*
============================================================================================
    endHoverOn
--------------------------------------------------------------------------------------------
    - Add name and pulse effect to start or end marker
--------------------------------------------------------------------------------------------
    Props
    - type : "start" or "end"
============================================================================================
*/

function endHoverOn(type) {
    // Get the marker
    const markerID = "marker-" + type;
    const marker = document.getElementById(markerID);

    // Add the pulsation animation
    marker.classList.add("temp-pulse-hover");

    // Construct name
    const nameText = (type === "start")? "Start Point" : "End Point";
    const name = (
        <div id="hover-name" className = "temp-marker-name">
            {nameText}
        </div>
    );

    // Content of the marker
    const content = (
        <div>
            {name}
        </div>
    );

    // Render into the DOM
    ReactDOM.render(content, marker);
}


/*
============================================================================================
    endHoverOff
--------------------------------------------------------------------------------------------
    - Clear name and cancel pulse effect for start or end marker
--------------------------------------------------------------------------------------------
    Props
    - type: "start" or "end"
============================================================================================
*/

function endHoverOff(type) {
    // Get the marker
    const markerID = "marker-" + type;
    const marker = document.getElementById(markerID);

    // Remove the pulsation animation
    marker.classList.remove("temp-pulse-hover");

    // Unmount the content
    ReactDOM.unmountComponentAtNode(marker);
}


export {
    dotIndicesToIDs,
    dotIDsToIndices,
	updateTimesSetState, 
	updateTimes,
	updateDate,
	updateDayInfo,
	timeSliderOnChange,
	timeSliderOnChangeComplete,
	timeSliderFormatLabel,
    showItineraryTimeWarning,
	startInputOnFocus,
	startInputOnBlur,
	endInputOnFocus,
	endInputOnBlur,
	setStartLocation,
	setEndLocation,
	setStartTimezone,
	setEndTimezone,
	resetAnimations,
    dotClick,
    dotHoverOn,
    dotHoverOff,
    endHoverOn,
    endHoverOff
};