/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    RouteFunctions.js
    - Route specific functions
--------------------------------------------------------------------------------------------
    Content
	- timeSliderOnChange
	- timeSliderOnChangeComplete
	- timeSliderFormatLabel
============================================================================================
*/


// Modules
import moment from "moment-timezone";


/*
============================================================================================
    timeSliderOnChange / timeSliderOnChangeComplete / timeSliderFormatLabel
--------------------------------------------------------------------------------------------
    - Set and format start and end times
============================================================================================
*/

function timeSliderOnChange(values) {
    //console.log("RouteFunctions / timeSliderOnChange - this = ", this);
    //console.log("RouteFunctions / timeSliderOnChange - values = ", values);

    if (this.state.startEndTimeValues.min === values.min) {
        values.min = values.max - this.state.routeInfo.dot_extension.time_value
    }
    else {
        values.max = values.min + this.state.routeInfo.dot_extension.time_value
    }

    this.setState({
        startEndTimeValues: values
    });
}


function timeSliderOnChangeComplete(values) {
    const timeMargin = 600;
    const startMoment = moment.unix(values.min).tz(this.state.startTimezone);
    const endMoment = moment.unix(values.max).tz(this.state.endTimezone);
    //console.log("TripFunctions / timeSliderOnChangeComplete - this = ", this);
    //console.log("TripFunctions / timeSliderOnChangeComplete - values = ", values);
    //console.log("TripFunctions / timeSliderOnChangeComplete - start / end = ", startDateTimeChanged, endDateTimeChanged);

    this.setState(
        {
            startMoment: startMoment,
            endMoment: endMoment,
            startEndTimeValues: values,
        },
        this.updateTimes.bind(
            this,
            () => {
                //console.log("TripFunctions / timeSliderOnChangeComplete - endMoment.unix() = ", endMoment.unix());
                //console.log("TripFunctions / timeSliderOnChangeComplete - this.state.sunTimes.sunset.unix() = ", this.state.sunTimes.sunset.unix());
                //console.log("TripFunctions / timeSliderOnChangeComplete - endMoment > this.state.sunTimes.sunset = ", endMoment > this.state.sunTimes.sunset);

                if ((startMoment.unix() < this.state.sunTimes.sunrise.unix() - timeMargin) && (endMoment.unix() <= this.state.sunTimes.sunset.unix() + timeMargin)) {
                    this.props.storeWarningAlert({
                        message: "Trip begins before sunrise.",
                        on: true
                    });
                }
                else if ((startMoment.unix() >= this.state.sunTimes.sunrise.unix() - timeMargin) && (endMoment.unix() > this.state.sunTimes.sunset.unix() + timeMargin)) {
                    this.props.storeWarningAlert({
                        message: "Trip ends after sunset.",
                        on: true
                    });
                }
                else if ((startMoment.unix() < this.state.sunTimes.sunrise.unix() - timeMargin) && (endMoment.unix() > this.state.sunTimes.sunset.unix() + timeMargin)) {
                    this.props.storeWarningAlert({
                        message: "Trip is longer than the day time.",
                        on: true
                    });
                }
                else {
                    this.props.storeWarningAlert({
                        message: null,
                        on: false
                    });                    
                }
            },
            false
        )
    );
}


function timeSliderFormatLabel(value) {
    //console.log("TripFunctions / timeSliderFormatLabel - value = ",value);
    const time = moment.unix(value);
    if (value === this.state.startEndTimeValues.min) {
        return time.tz(this.state.startTimezone).format("h:mm a z")
    }
    else if (value === this.state.startEndTimeValues.max) {
        return time.tz(this.state.endTimezone).format("h:mm a z")
    }
    else {
        console.log("[WARNING] RouteFunctions / timeSliderFormatLabel - invalid value");
        return null
    }
}


/*
============================================================================================
    Show Itinerary Time Warning
============================================================================================
*/

function showItineraryTimeWarning() {
    const timeMargin = 600;

    if ((this.state.startMoment.unix() < this.state.sunTimes.sunrise.unix() - timeMargin) && (this.state.endMoment.unix() <= this.state.sunTimes.sunset.unix() + timeMargin)) {
        this.props.storeWarningAlert({
            message: "Trip begins before sunrise.",
            on: true
        });
    }
    else if ((this.state.startMoment.unix() >= this.state.sunTimes.sunrise.unix() - timeMargin) && (this.state.endMoment.unix() > this.state.sunTimes.sunset.unix() + timeMargin)) {
        this.props.storeWarningAlert({
            message: "Trip ends after sunset.",
            on: true
        });
    }
    else if ((this.state.startMoment.unix() < this.state.sunTimes.sunrise.unix() - timeMargin) && (this.state.endMoment.unix() > this.state.sunTimes.sunset.unix() + timeMargin)) {
        this.props.storeWarningAlert({
            message: "Trip is longer than the day time.",
            on: true
        });
    }
    else {
        this.props.storeWarningAlert({
            message: null,
            on: false
        });                    
    }
}

export {
	timeSliderOnChange,
	timeSliderOnChangeComplete,
	timeSliderFormatLabel,
    showItineraryTimeWarning
};