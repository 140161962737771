// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import {
    NationalParkMap
} from "components/Map";
//import ReactPlayer from "react-player";
import { Gallery, mediaDimensions } from "components/Gallery";
import Board from "components/Board";

import
    NationalParkUser
from "./NationalParkUser";

// Axios
import {
    getDot,
    getSetHomeDots,
    postFetchDots,
    postCheckLike,
    postCheckSave,
    deleteDot,
    getSingleBoardComments    
} from "requests";

// Websocket
import {
    addWebSocketGroups,
    removeWebSocketGroups
} from "js/WebSocketFunctions";

// Redux
import {
    storeRefresh,
    storeLogInOn,
    storeLogInSimpleOn,
    storeSignUpOn,
    storeWarningAlert,
    storeNotificationAlert,
    storeUser,
    storeMore,
    storeNewLike,
    storeNewSave,
    storeShare,
    storeCreate
} from "actions";

import {
    likeButtonClick,
} from "js/Interaction";

import {
    regularCurationStrong
} from "js/Curation";

import {
    //getTimestamp,
    url,
    getStaticPath,
    getMediaProperty,
    sortMedia,
    formatNumbers,
    debounce
} from "js/Functions";

// CSS
import "./NationalPark.scss";


class NationalPark extends Component {
    constructor (props) {
        super(props);

        // Window / margin / scroll bar widths
        this.windowMaxWidth = 1200;
        this.marginWidth = 16;
        this.scrollBarWidth = 12;
        this.doubleLayoutWidth = 8;

        // Media area
        this.mediaMaxArea = 240000;
        this.mediaArea = this.mediaMaxArea;

        // Minimum / scale / maximum content widths
        this.minContentWidth = 320;
        this.scaleContentWidth = 460;
        this.maxContentWidth = 600;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.8; // Vertical 4:5
        this.maxAspectRatio = 2.0; // Horizontal 3:2

        // Number of dots per batch
        this.numDotsPerBatch = 20;

        // Dimensions
        this.topMargin = 72;
        this.titleHeight = 54;
        this.topHeight = this.topMargin + this.titleHeight;
        this.tabHeight = 36;
        this.dotCountHeight = 37;
        this.usersHeight = 150;

        // Small layout dimensions
        this.smallTopMargin = 55;
        this.smallTitleHeight = 30;
        this.smallTopHeight = this.smallTopMargin + this.smallTitleHeight;
        this.smallTabHeight = 36;
        this.smallDotCountHeight = 24;
        this.smallMapHeight = 260;
        this.smallMapHeightIncrement = 40;

        // Medium layout dimensions
        this.mediumTitleHeight = 40;
        this.mediumMapHeight = 300;

        // Create On
        this.createOn = null;

        // Scroll Flag
        this.onScrollActive = false;

        // Scroll to item
        this.scrollTime = 250;

        // Scroll cancel time - At least two times bigger than the scroll time
        this.scrollCancelTime = 800;

        // Map Mode
        //this.mapSource = "google";
        this.mapSource = "open";

        // Map height
        this.mapHeight = window.innerHeight - this.topHeight - this.tabHeight - this.dotCountHeight - this.usersHeight;
        this.mapMinHeight = 200;
        this.mapMaxHeight = 900;
        this.mapHeightIncrement = 75;

        // Layout switches
        this.doubleLayout = (this.props.browserWidth >= this.doubleLayoutWidth);
        this.smallLayout = (this.props.browserWidth <= window.browserWidthSmall);
        this.doubleColumnHeight = window.innerHeight - this.topHeight;

        // WebSocket Prefix
        this.webSocketGroupPrefix = "dot_board";

        // Initialize state
        this.state = {
            setInfo: null,

            mainMode: "content", // "content" | "community"

            mapInitialized: true,
            selected: null,
            hovered: null,
            moreMenuOn: false,
            deleteSlug: null,
            deleteConfirmOn: false,

            mapHeight: (this.doubleLayout)? this.mapHeight :
                ((this.smallLayout)? this.smallMapHeight : this.mediumMapHeight),

            feedMode: "new",

            newIDs: [],
            newStartID: null,
            newEndID: null,
            newCount: null,
            newDotsInfo: [],
            newDotsLiked: [],
            newDotsSaved: [],
            newDotsEditor: [],
            newCommentsInfo: [],
            newWebSocketGroups: [],
            newFeedComplete: false,
            newFeedLoaderOn: false,

            popularIDs: [],
            popularStartID: null,
            popularEndID: null,
            popularCount: null,
            popularDotsInfo: [],
            popularDotsLiked: [],
            popularDotsSaved: [],
            popularDotsEditor: [],
            popularCommentsInfo: [],
            popularWebSocketGroups: [],
            popularFeedComplete: false,
            popularFeedLoaderOn: false,

            approvedIDs: [],
            approvedStartID: null,
            approvedEndID: null,
            approvedCount: null,
            approvedDotsInfo: [],
            approvedDotsLiked: [],
            approvedDotsSaved: [],
            approvedDotsEditor: [],
            approvedCommentsInfo: [],
            approvedWebSocketGroups: [],
            approvedFeedComplete: false,
            approvedFeedLoaderOn: false,

            windowWidth: null,
            windowHeight: null,

            checkScroll: false,
            scrollToItem: 0,
            scrollMidLine: null,
            newScrollPosition: 0,
            popularScrollPosition: 0,
            approvedScrollPosition: 0,

            commentsInfo: null,
            webSocketGroups: [],            
        };

        // DOM Nodes
        this.newDotsFeedRef = React.createRef();
        this.popularDotsFeedRef = React.createRef();
        this.approvedDotsFeedRef = React.createRef();
        this.newItemRefs = [];
        this.popularItemRefs = [];
        this.approvedItemRefs = [];

        // Bind functions
        this.setState = this.setState.bind(this);

        // Main menu mode function
        this.menuModeClick = this.menuModeClick.bind(this);

        // Bind post tab button functions
        this.switchToNewMode = this.switchToNewMode.bind(this);
        this.switchToPopularMode = this.switchToPopularMode.bind(this);
        this.switchToApprovedMode = this.switchToApprovedMode.bind(this);
        this.switchToBoardMode = this.switchToBoardMode.bind(this);

        // Bind update functions
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        // Load dot ids and info
        this.loadDotIDs = this.loadDotIDs.bind(this);
        this.loadDotsInfo = this.loadDotsInfo.bind(this);
        this.loadSetInfo = this.loadSetInfo.bind(this);
        this.loadCommentsInfo = this.loadCommentsInfo.bind(this);

        // Create / edit / delete dot
        this.editButtonClick = this.editButtonClick.bind(this);
        this.createButtonClick = this.createButtonClick.bind(this);
        this.deleteButtonClick = this.deleteButtonClick.bind(this);
        this.deleteConfirmClick = this.deleteConfirmClick.bind(this);
        this.deleteCancelClick = this.deleteCancelClick.bind(this);

        // Scroll listener
        this.newOnScroll = this.newOnScroll.bind(this);
        this.popularOnScroll = this.popularOnScroll.bind(this);
        this.approvedOnScroll = this.approvedOnScroll.bind(this);
        this.newScrollListener = debounce(this.newOnScroll, 25);
        this.popularScrollListener = debounce(this.popularOnScroll, 25);
        this.approvedScrollListener = debounce(this.approvedOnScroll, 25);

        // Item event
        this.itemHoverOn = this.itemHoverOn.bind(this);
        this.itemHoverOff = this.itemHoverOff.bind(this);
        this.itemClick = this.itemClick.bind(this);

        // Scroll to item
        this.scrollToItem = this.scrollToItem.bind(this);
    }

    render() {
        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && this.props.userInfo !== null);
        //console.log("NationalPark / render - loggedIn = ", loggedIn);


        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        let dotsInfo = null;
        if (this.state.feedMode === "new") {
            dotsInfo = this.state.newDotsInfo;
        }
        else if (this.state.feedMode === "popular" || this.state.feedMode === "board") {
            dotsInfo = this.state.popularDotsInfo;
        }
        else if (this.state.feedMode === "approved") {
            dotsInfo = this.state.approvedDotsInfo;
        }

        let map = null;
        //console.log("NationalPark / render - dotsInfo = ", dotsInfo);
        //console.log("NationalPark / render - this.state.setInfo = ", this.state.setInfo);

        if (this.mapSource === "open") {
            // Only when google object and locations are ready
            if (this.state.mapInitialized && dotsInfo !== null && this.state.setInfo !== null) {
                const mapProps = {
                    // Set slug
                    setSlug: this.props.setSlug,

                    // Map code
                    nationalParkCode: this.state.setInfo.trip_extension.area_code,

                    // History
                    history: this.props.history,

                    // Map width
                    mapWidth: "100%",

                    // Map height
                    mapHeight: this.state.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: (this.doubleLayout)? 
                        this.mapHeightIncrement : this.smallMapHeightIncrement,
                    mapHeightUpdate: true,

                    // Map Center location
                    mapZoom: this.state.setInfo.map_zoom,
                    mapCenter: this.state.setInfo.location,
                    mapType: this.state.setInfo.map_type,
                    mapSource: this.state.setInfo.map_source,

                    // Dots
                    dotsInfo: dotsInfo,

                    // Selected and hovered
                    selected: this.state.selected,
                    hovered: this.state.hovered,

                    // Select zoom action
                    selectZoomIn: this.state.selectZoomIn,

                    // Item click
                    itemClick: this.itemClick,

                    // Scroll time
                    scrollTime: this.scrollTime,

                    // Set state
                    setState: this.setState
                };

                // Get the Map component
                map = (
                    <NationalParkMap
                        {...mapProps}
                    />
                );

            }
        }

        // Number of Dots
        let numberOfDots = null;
        if (this.state.feedMode === "new") {
            numberOfDots = this.state.newCount;
        }
        else if (this.state.feedMode === "popular") {
            numberOfDots = this.state.popularCount;
        }
        else if (this.state.feedMode === "approved") {
            numberOfDots = this.state.approvedCount;
        }
        const numberOfDotsText = (this.state.feedMode === "board")?
            null : ((numberOfDots <= 1)? "Dot" : "Dots");

        // Mapping Dots
        const newDots = this.state.newDotsInfo.map((dotInfo, itemIndex) => {
            //console.log("NationalPark / render - this.state = ", this.state);
            //console.log("NationalPark / render - this.props = ", this.props);
            //console.log("NationalPark / render - dotInfo = ", dotInfo);
            //console.log("NationalPark / render - itemIndex = ", itemIndex);

            return(
                <div key = {"national-park-post-new-" + itemIndex + "-" + dotInfo.slug}
                    ref = {this.newItemRefs[itemIndex]}
                    className = "national-park-post-container"
                >
                    <NationalParkPost
                        itemIndex = {itemIndex}
                        itemHoverOn = {this.itemHoverOn}
                        itemHoverOff = {this.itemHoverOff}
                        itemClick = {this.itemClick}
                        dotInfo = {dotInfo}
                        userInfo = {this.props.userInfo}
                        loggedIn = {loggedIn}
                        likedByMe = {this.state.newDotsLiked[itemIndex]}
                        savedByMe = {this.state.newDotsSaved[itemIndex]}
                        storeNewLike = {this.props.storeNewLike}
                        storeUser = {this.props.storeUser}
                        checkScroll = {this.state.checkScroll}
                        scrollPosition = {this.state.newScrollPosition}
                        newLike = {this.props.newLike}
                        newSave = {this.props.newSave}
                        colorMode = {this.props.colorMode}
                        scrollMidLine = {this.state.scrollMidLine}
                        editButtonClick = {this.editButtonClick}
                        deleteButtonClick = {this.deleteButtonClick}
                        deleteConfirmClick = {this.deleteConfirmClick}
                        deleteCancelClick = {this.deleteCancelClick.bind}
                        storeShare = {this.props.storeShare}
                        storeGallery = {this.props.storeGallery}
                        windowMaxWidth = {this.windowMaxWidth}
                        marginWidth = {this.marginWidth}
                        scrollBarWidth = {this.scrollBarWidth}
                        mediaArea = {this.mediaArea}
                        minContentWidth = {this.minContentWidth}
                        scaleContentWidth = {this.scaleContentWidth}
                        maxContentWidth = {this.maxContentWidth}
                        minAspectRatio = {this.minAspectRatio}
                        maxAspectRatio = {this.maxAspectRatio}
                        browserWidth = {this.props.browserWidth}
                        browserWidthPixels = {this.props.browserWidthPixels}
                        doubleLayout = {this.doubleLayout}
                        setState = {this.setState}
                    />
                </div>
            );

        });

        // Popular ots
        const popularDots = this.state.popularDotsInfo.map((dotInfo, itemIndex) => {
            //console.log("NationalPark / render - this.state = ", this.state);
            //console.log("NationalPark / render - this.props = ", this.props);
            //console.log("NationalPark / render - dotInfo = ", dotInfo);
            //console.log("NationalPark / render - itemIndex = ", itemIndex);

            return(
                <div key = {"national-park-post-popular-" + itemIndex + "-" + dotInfo.slug}
                    ref = {this.popularItemRefs[itemIndex]}
                    className = "national-park-post-container"
                >
                    <NationalParkPost
                        itemIndex = {itemIndex}
                        itemHoverOn = {this.itemHoverOn}
                        itemHoverOff = {this.itemHoverOff}
                        itemClick = {this.itemClick}
                        dotInfo = {dotInfo}
                        userInfo = {this.props.userInfo}
                        loggedIn = {loggedIn}
                        likedByMe = {this.state.newDotsLiked[itemIndex]}
                        savedByMe = {this.state.newDotsSaved[itemIndex]}
                        storeNewLike = {this.props.storeNewLike}
                        storeUser = {this.props.storeUser}
                        checkScroll = {this.state.checkScroll}
                        scrollPosition = {this.state.popularScrollPosition}
                        newLike = {this.props.newLike}
                        newSave = {this.props.newSave}
                        colorMode = {this.props.colorMode}
                        scrollMidLine = {this.state.scrollMidLine}
                        editButtonClick = {this.editButtonClick}
                        deleteButtonClick = {this.deleteButtonClick}
                        deleteConfirmClick = {this.deleteConfirmClick}
                        deleteCancelClick = {this.deleteCancelClick.bind}
                        storeShare = {this.props.storeShare}
                        storeGallery = {this.props.storeGallery}
                        windowMaxWidth = {this.windowMaxWidth}
                        marginWidth = {this.marginWidth}
                        scrollBarWidth = {this.scrollBarWidth}
                        mediaArea = {this.mediaArea}
                        minContentWidth = {this.minContentWidth}
                        scaleContentWidth = {this.scaleContentWidth}
                        maxContentWidth = {this.maxContentWidth}
                        minAspectRatio = {this.minAspectRatio}
                        maxAspectRatio = {this.maxAspectRatio}
                        browserWidth = {this.props.browserWidth}
                        browserWidthPixels = {this.props.browserWidthPixels}
                        doubleLayout = {this.doubleLayout}
                        setState = {this.setState}
                    />
                </div>
            );
        });


        // Approved dots
        const approvedDots = this.state.approvedDotsInfo.map((dotInfo, itemIndex) => {
            //console.log("NationalPark / render - this.state = ", this.state);
            //console.log("NationalPark / render - this.props = ", this.props);
            //console.log("NationalPark / render - dotInfo = ", dotInfo);
            //console.log("NationalPark / render - itemIndex = ", itemIndex);

            return(
                <div key = {"national-park-post-approved-" + itemIndex + "-" + dotInfo.slug}
                    ref = {this.approvedItemRefs[itemIndex]}
                    className = "national-park-post-container"
                >
                    <NationalParkPost
                        itemIndex = {itemIndex}
                        itemHoverOn = {this.itemHoverOn}
                        itemHoverOff = {this.itemHoverOff}
                        itemClick = {this.itemClick}
                        dotInfo = {dotInfo}
                        userInfo = {this.props.userInfo}
                        loggedIn = {loggedIn}
                        likedByMe = {this.state.newDotsLiked[itemIndex]}
                        savedByMe = {this.state.newDotsSaved[itemIndex]}
                        storeNewLike = {this.props.storeNewLike}
                        storeUser = {this.props.storeUser}
                        checkScroll = {this.state.checkScroll}
                        scrollPosition = {this.state.approvedScrollPosition}
                        newLike = {this.props.newLike}
                        newSave = {this.props.newSave}
                        colorMode = {this.props.colorMode}
                        scrollMidLine = {this.state.scrollMidLine}
                        editButtonClick = {this.editButtonClick}
                        deleteButtonClick = {this.deleteButtonClick}
                        deleteConfirmClick = {this.deleteConfirmClick}
                        deleteCancelClick = {this.deleteCancelClick.bind}
                        storeShare = {this.props.storeShare}
                        storeGallery = {this.props.storeGallery}
                        windowMaxWidth = {this.windowMaxWidth}
                        marginWidth = {this.marginWidth}
                        scrollBarWidth = {this.scrollBarWidth}
                        mediaArea = {this.mediaArea}
                        minContentWidth = {this.minContentWidth}
                        scaleContentWidth = {this.scaleContentWidth}
                        maxContentWidth = {this.maxContentWidth}
                        minAspectRatio = {this.minAspectRatio}
                        maxAspectRatio = {this.maxAspectRatio}
                        browserWidth = {this.props.browserWidth}
                        browserWidthPixels = {this.props.browserWidthPixels}
                        doubleLayout = {this.doubleLayout}
                        setState = {this.setState}
                    />
                </div>
            );
        });


        /*
        ============================================================================================
            Loader
        ============================================================================================
        */

        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/feed-loader-black.gif") :
            getStaticPath("/images/loader/feed-loader-white.gif");

        const newFeedLoader = this.state.newFeedLoaderOn? (
            <div className = "national-park-posts-loader"
                style = {{
                    backgroundImage:  loaderImage
                }}
            >
            </div>
        ) : null;

        const popularFeedLoader = this.state.popularFeedLoaderOn? (
            <div className = "national-park-posts-loader"
                style = {{
                    backgroundImage:  loaderImage
                }}
            >
            </div>
        ) : null;

        const approvedFeedLoader = this.state.approvedFeedLoaderOn? (
            <div className = "national-park-posts-loader"
                style = {{
                    backgroundImage:  loaderImage
                }}
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            Genesis
        ============================================================================================
        */

        const genesisImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/genesis-light-blue.png") :
            getStaticPath("/images/common/genesis-blue.png");

        const newGenesis = (this.state.newFeedComplete)? (
            <div className = "body-narrow">
                <div className = "national-park-posts-genesis image-contain"
                    style = {{ 
                        backgroundImage: genesisImage
                    }}
                >
                </div>
                <div className = {(this.props.colorMode === "day")? 
                    "national-park-posts-genesis-title k4" : "national-park-posts-genesis-title w4"}
                >
                    You've Reached the Beginning of Time.
                </div>
            </div>
        ) : null;

        const popularGenesis = (this.state.popularFeedComplete)? (
            <div className = "body-narrow">
                <div className = "national-park-posts-genesis image-contain"
                    style = {{ 
                        backgroundImage: genesisImage
                    }}
                >
                </div>
                <div className = {(this.props.colorMode === "day")? 
                    "national-park-posts-genesis-title k4" : "national-park-posts-genesis-title w4"}
                >
                    You've Reached the Beginning of Space.
                </div>
            </div>
        ) : null;

        const approvedGenesis = (this.state.approvedFeedComplete)? (
            <div className = "body-narrow">
                <div className = "national-park-posts-genesis image-contain"
                    style = {{ 
                        backgroundImage: genesisImage
                    }}
                >
                </div>
                <div className = {(this.props.colorMode === "day")? 
                    "national-park-posts-genesis-title k4" : "national-park-posts-genesis-title w4"}
                >
                    You've Reached the Beginning of Cosmos.
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Board
        ============================================================================================
        */

        const board = (this.state.setInfo !== null && this.state.commentsInfo !== null)?
        (
            <div id = {(this.props.colorMode === "day")?
                    "national-park-board-container-day" :
                    "national-park-board-container-night"}
                style = {{
                    zIndex: (this.state.feedMode === "board")? 2 : 1,
                    overflowY: this.state.feedMode === "board"? "scroll" : "hidden",
                    height: (this.state.windowHeight === null)?
                            "auto" : this.state.windowHeight - this.topHeight - this.tabHeight
                }}
            >
                {
                    ((this.state.setInfo.board.comment_count > 0) || (this.props.userInfo !== null))?
                    (
                        <div id = "national-park-board-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Comments
                        </div>
                    ) : null
                }
                <Board
                    key = {"national-park-" + this.state.setInfo.id + "-board-" + this.state.setInfo.board.id}
                    typePrefix = {"dot"}
                    boardID = {this.state.setInfo.board.id}
                    userInfo = {this.props.userInfo}
                    loggedIn = {loggedIn}
                    commentCount = {this.state.setInfo.board.comment_count}
                    commentsInfo = {this.state.commentsInfo}
                    webSocketGroupPrefix = {this.props.webSocketGroupPrefix}
                />
            </div>
        ) : null;


        /*
        ============================================================================================
            Components
        ============================================================================================
        */

        const mapContainer = (
            <div id = "national-park-map-container">
                {map}
            </div>
        );

        const dotCount = (this.smallLayout)? null : (
            <div id = "national-park-dot-count"
                className = {(this.props.colorMode === "day")? "k0" : "w0"}
            >
                {numberOfDots} {numberOfDotsText}
            </div>
        );

        const content = (
            <div id = "national-park-content">
                <div id = "national-park-posts-container">
                    <div id = "national-park-posts-header">
                        <div className = {
                                (this.state.feedMode === "new")?
                                    (
                                        (this.props.colorMode === "day")?
                                            "national-park-posts-header-tab-hover-day lb4" :
                                            "national-park-posts-header-tab-hover-night lb4"
                                    ) : "national-park-posts-header-tab g4"
                            }
                            onClick = {this.switchToNewMode}
                        >
                            New
                        </div>
                        <div className = {
                                (this.state.feedMode === "popular")?
                                    (
                                        (this.props.colorMode === "day")?
                                            "national-park-posts-header-tab-hover-day lb4" :
                                            "national-park-posts-header-tab-hover-night lb4"
                                    ) : "national-park-posts-header-tab g4"
                            }
                            onClick = {this.switchToPopularMode}
                        >
                            Popular
                        </div>
                        <div className = {
                                (this.state.feedMode === "approved")?
                                    (
                                        (this.props.colorMode === "day")?
                                            "national-park-posts-header-tab-hover-day lb4" :
                                            "national-park-posts-header-tab-hover-night lb4"
                                    ) : "national-park-posts-header-tab g4"
                            }
                            onClick = {this.switchToApprovedMode}
                        >
                            Editorial
                        </div>
                        <div className = {
                                (this.state.feedMode === "board")?
                                    (
                                        (this.props.colorMode === "day")?
                                            "national-park-posts-header-tab-hover-day lb4" :
                                            "national-park-posts-header-tab-hover-night lb4"
                                    ) : "national-park-posts-header-tab g4"
                            }
                            onClick = {this.switchToBoardMode}
                        >
                            Board
                        </div>
                    </div>
                    <div id = "national-park-posts-body"
                        style = {{
                            height: (this.state.windowHeight === null)? 
                                "auto" : this.state.windowHeight - this.topHeight - this.tabHeight 
                        }}
                    >
                        <div className = {(this.props.colorMode === "day")? 
                                "national-park-posts-day" : "national-park-posts-night"}
                            ref = {this.newDotsFeedRef}
                            style = {{
                                zIndex: (this.state.feedMode === "new")? 2 : 1,
                                overflowY: (this.state.feedMode === "new")? "scroll" : "hidden"
                            }}
                        >
                            {newDots}
                            {newFeedLoader}
                            {newGenesis}
                        </div> 
                        <div className = {(this.props.colorMode === "day")? 
                                "national-park-posts-day" : "national-park-posts-night"}
                            ref = {this.popularDotsFeedRef}
                            style = {{
                                zIndex: (this.state.feedMode === "popular")? 2 : 1,
                                overflowY: (this.state.feedMode === "popular")? "scroll" : "hidden"
                            }}
                        >
                            {popularDots}
                            {popularFeedLoader}
                            {popularGenesis}
                        </div>
                        <div className = {(this.props.colorMode === "day")? 
                                "national-park-posts-day" : "national-park-posts-night"}
                            ref = {this.approvedDotsFeedRef}
                            style = {{
                                zIndex: (this.state.feedMode === "approved")? 2 : 1,
                                overflowY: (this.state.feedMode === "approved")? "scroll" : "hidden"
                            }}
                        >
                            {approvedDots}
                            {approvedFeedLoader}
                            {approvedGenesis}
                        </div>                       
                        {board}
                    </div>
                </div>
            </div>
        );

        // Delete confirm modal
        const deleteConfirm = (this.state.deleteConfirmOn)? (
            <div className = "national-park-post-delete-modal">
                <div className =  {(this.props.colorMode === "day")?
                        "national-park-post-delete-modal-content modal-day" :
                        "national-park-post-delete-modal-content modal-night"}
                >
                    <div className = "national-park-post-delete-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                       onClick = {this.deleteCancelClick} >
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "national-park-post-delete-modal-content__title k2" :
                            "national-park-post-delete-modal-content__title w2"}
                    >
                        Delete
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "national-park-post-delete-modal-content__text dg4" :
                            "national-park-post-delete-modal-content__text g4"}
                    >
                        Are you sure to delete?
                    </div>

                    <div className = "national-park-post-delete-modal-content__confirm">
                        <div className = "national-park-post-delete-modal-content__confirm-select">
                            <div className =  {(this.props.colorMode === "day")?
                                    "national-park-post-delete-modal-content__confirm-select-yes button-light-blue-gray-s2" :
                                    "national-park-post-delete-modal-content__confirm-select-yes button-blue-gray-s2"}
                                onClick = {this.deleteConfirmClick}
                            >
                                Yes
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                    "national-park-post-delete-modal-content__confirm-select-no button-light-blue-gray-s2" :
                                    "national-park-post-delete-modal-content__confirm-select-no button-blue-gray-s2"}
                                onClick = {this.deleteCancelClick}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Users
        ============================================================================================
        */
        const usersHeight = (this.state.windowHeight === null)? this.usersHeight :
            (this.state.windowHeight - this.topHeight - this.dotCountHeight - this.tabHeight - this.state.mapHeight);
        //console.log("NationalPark / render - this.state.windowHeight = ", this.state.windowHeight);
        //console.log("NationalPark / render - this.topHeight = ", this.topHeight);
        //console.log("NationalPark / render - this.dotCountHeight = ", this.dotCountHeight);
        //console.log("NationalPark / render - this.state.mapHeight = ", this.state.mapHeight);

        const usersProps = {
            setSlug: this.props.setSlug,
            feedMode: "contributor",
            height: usersHeight,
            doubleLayoutWidth: this.doubleLayoutWidth            
        };

        const users = (
            <NationalParkUser
                {...usersProps}
            />
        );


        /*
        ============================================================================================
            Title
        ============================================================================================
        */

        //console.log("NationalPark / render - this.props.browserWidth = ", this.props.browserWidth);
        //console.log("NationalPark / render - window.browserWidthMedium = ", window.browserWidthMedium);
        let titleClassName = null;
        let titleContainerClassName = null;
        let titleHeight = null;
        if (this.props.browserWidth <= window.browserWidthSmall) {
            titleClassName = (this.props.colorMode === "day")?
                "national-park-title-small black" :
                "national-park-title-small white";
            titleContainerClassName = "national-park-title-container-small"
        }
        else if (this.props.browserWidth >= window.browserWidthMedium) {
            titleClassName = (this.props.colorMode === "day")?
                "national-park-title-large black" : 
                "national-park-title-large white";
            titleContainerClassName = "national-park-title-container"
        }
        else {
            titleClassName = (this.props.colorMode === "day")?
                "national-park-title black" : 
                "national-park-title white";
            titleContainerClassName = "national-park-title-container-large"
        }


        /*
        ============================================================================================
            Bottom Menu (Only for Mobile)
        ============================================================================================
        */

        const contentIcon = (this.state.mainMode === "content")?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/type-dot-light-blue.png") :
                    getStaticPath("/images/common/type-dot-blue.png")
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/type-dot-black.png") :
                    getStaticPath("/images/common/type-dot-white.png")
            );

        const communityIcon = (this.state.mainMode === "community")?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/group-light-blue.png") :
                    getStaticPath("/images/common/group-blue.png")
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/group-black.png") :
                    getStaticPath("/images/common/group-white.png")
            );

        const bottomMenu = (this.doubleLayout)? null : (
            <div id = "national-park-bottom-menu-container">
                <div id = "national-park-bottom-menu-modes"
                    className = {(this.props.colorMode === "day")?
                        "modal-day-no-shadow" : "modal-night"}
                >
                    <div id = {(this.state.mainMode === "content")?
                            "national-park-bottom-menu-mode-content-selected" :
                            "national-park-bottom-menu-mode-content"
                        }
                        onClick = {(event) => { this.menuModeClick(event, "content"); }}
                    >
                        <div id = {(this.smallLayout)?
                                "national-park-bottom-menu-mode-content-icon-small" :
                                "national-park-bottom-menu-mode-content-icon"}
                            className = "image-cover"
                            style = {{ backgroundImage: contentIcon}}
                        >
                        </div>
                        <div id = "national-park-bottom-menu-mode-content-text"
                            className = {
                                (this.state.mainMode === "content")?
                                (
                                    (this.props.colorMode === "day")?
                                        "lb4" : "b4"
                                ) : (
                                    (this.props.colorMode === "day")?
                                        "k4" : "w4"
                                )
                            }
                        >
                            Dots
                        </div>
                    </div>

                    <div id = {(this.state.mainMode === "community")?
                            "national-park-bottom-menu-mode-community-selected" :
                            "national-park-bottom-menu-mode-community"
                        }
                        onClick = {(event) => { this.menuModeClick(event, "community"); }}
                    >
                        <div id = {(this.smallLayout)?
                                "national-park-bottom-menu-mode-community-icon-small" :
                                "national-park-bottom-menu-mode-community-icon"}
                            className = "image-cover"
                            style = {{ backgroundImage: communityIcon}}
                        >
                        </div>
                        <div id = "national-park-bottom-menu-mode-community-text"
                            className = {
                                (this.state.mainMode === "community")?
                                (
                                    (this.props.colorMode === "day")?
                                        "lb4" : "b4"
                                ) : (
                                    (this.props.colorMode === "day")?
                                        "k4" : "w4"
                                )
                            }
                        >
                            Group
                        </div>
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Set add button
        ============================================================================================
        */

        const setAddButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/add-light-blue.png", true) :
            getStaticPath("/images/common/add-blue.png", true);


        /*
        ============================================================================================
            Body
        ============================================================================================
        */

        return(
            <div id ="national-park-container"
                className = {(this.smallLayout)? 
                    "national-park-container-small" : 
                    "national-park-container"
                }
            >
                {deleteConfirm}

                <div id = "national-park-title-container"
                    className = {titleContainerClassName}
                >
                    {
                        (this.state.setInfo !== null)?
                            (
                                <div id = "national-park-title"
                                    className = {titleClassName}
                                    style = {{
                                        height: titleHeight
                                    }}
                                >
                                    {this.state.setInfo.title.toUpperCase()}
                                </div>
                            ) : null
                    }

                    <div id = "national-park-set-add"
                        onClick = {this.createButtonClick}
                    >
                        <div id = "national-park-set-add-button"
                            className = "image-contain"
                            style = {{ backgroundImage: setAddButtonImage }}
                        >
                        </div>
                        {
                            (this.props.browserWidth > 6)?
                            (
                                <div id = "national-park-set-add-text"
                                    className = {(this.props.colorMode === "day")? "lb2" : "b2"}
                                >
                                    Add a dot
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div id = "national-park-left"
                    className = {(this.doubleLayout)?
                        "national-park-left-double" : "national-park-left-single"}
                    style = {{
                        height: (this.doubleLayout)?
                            this.doubleColumnHeight : this.state.mapHeight
                    }}
                >
                    {mapContainer}
                    {dotCount}
                    {(this.doubleLayout)? users : null}
                </div>
                <div id = "national-park-right"
                    className = {(this.doubleLayout)?
                        "national-park-right-double" : 
                        "national-park-right-single"}
                    style = {{
                        height: (this.doubleLayout)?
                            this.doubleColumnHeight : (
                                (this.smallLayout)?
                                    (this.state.windowHeight - this.state.mapHeight - this.smallTopHeight):
                                    (this.state.windowHeight - this.state.mapHeight - this.topMargin - this.mediumTitleHeight)
                            )
                    }}
                >
                    {content}
                </div>
                {bottomMenu}
            </div>
        );
    }

    updateWindowDimensions() {
        const scrollMidLine = this.topHeight + (window.innerHeight - this.topHeight) / 2;

        // Update media area
        if (this.props.browserWidthPixels < this.windowMaxWidth) {
            this.mediaArea = this.mediaMaxArea / this.windowMaxWidth * Math.min(this.props.browserWidthPixels, this.windowMaxWidth);
        }

        // Update window parameters
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            scrollMidLine: scrollMidLine
        });
    }

    componentWillUnmount() {
        // Remove event listeners
        window.removeEventListener("resize", this.updateWindowDimensions);
        if (this.newDotsFeedRef.current !== null) {
            this.newDotsFeedRef.current.removeEventListener("scroll", this.newScrollListener);
        }
        if (this.popularDotsFeedRef.current !== null) {
            this.popularDotsFeedRef.current.removeEventListener("scroll", this.popularScrollListener);
        }
        if (this.approvedDotsFeedRef.current !== null) {
            this.approvedDotsFeedRef.current.removeEventListener("scroll", this.approvedScrollListener);
        }

        // Remove relevant webSocket group
        //removeWebSocketGroups(this.state.newWebSocketGroups);
        removeWebSocketGroups(this.state.webSocketGroups);
    }

    componentDidMount() {
        //console.log("NationalPark / componentDidMount - this.props = ", this.props);
        //console.log("NationalPark / componentDidMount - this.state = ", this.state);

        // Load set and comments
        this.loadSetInfo();

        // Load new dots
        this.loadDotIDs(
            "new",
            () => {
                this.loadDotsInfo(
                    "new",
                    () => {
                        this.setState({
                            hovered: 0
                        });
                    }
                );
            }
        );

        // Load popular dots
        this.loadDotIDs(
            "popular",
            () => {
                this.loadDotsInfo(
                    "popular",
                    null
                );
            }
        );

        // Load approved dots
        this.loadDotIDs(
            "approved",
            () => {
                this.loadDotsInfo(
                    "approved",
                    null
                );
            }
        );

        // Update window dimensions
        this.updateWindowDimensions();

        // Add event listeners
        window.addEventListener("resize", this.updateWindowDimensions);
        if (this.newDotsFeedRef.current !== null) {
            this.newDotsFeedRef.current.addEventListener("scroll", this.newScrollListener);
        }
        if (this.popularDotsFeedRef.current !== null) {
            this.popularDotsFeedRef.current.addEventListener("scroll", this.popularScrollListener);
        }
        if (this.approvedDotsFeedRef.current !== null) {
            this.approvedDotsFeedRef.current.addEventListener("scroll", this.approvedScrollListener);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("NationalPark / componentDidUpdate - this.props = ", this.props);
        //console.log("NationalPark / componentDidUpdate - this.state = ", this.state);

        // When logged in or out
        if ((prevProps.userInfo === null) && (this.props.userInfo !== null)) {
            //console.log("NationalPark / componentDidUpdate - logged in");

            this.checkLike("new");
            this.checkLike("popular");
            this.checkLike("approved");
        }
        else if ((prevProps.userInfo !== null) && (this.props.userInfo === null)) {
            //console.log("NationalPark / componentDidUpdate - logged out");

            this.resetLike();
        }

        // Sense user log in
        //console.log("NationalPark / componentDidUpdate - this.createOn = ", this.createOn);
        //console.log("NationalPark / componentDidUpdate - prevProps.userInfo = ", prevProps.userInfo);
        //console.log("NationalPark / componentDidUpdate - this.props.userInfo = ", this.props.userInfo );
        if (this.createOn !== null && prevProps.userInfo === null && this.props.userInfo !== null) {
            // Open create modal
            this.props.storeCreate({
                modalOn: true,
                mode: "create",
                setSlug: this.props.setSlug,
                location: null,
                info: null
            });

            // Reset location
            this.createOn = null;
        }

        // Scroll to item
        //if (this.state.scrollToItem !== prevState.scrollToItem) {
        //console.log("NationalPark / componentDidUpdate - this.state.scrollToItem = ", this.state.scrollToItem);
        //console.log("NationalPark / componentDidUpdate - prevState.scrollToItem = ", prevState.scrollToItem);
        if ((this.state.scrollToItem !== null && this.state.scrollToItem !== prevState.scrollToItem) && 
            (this.state.feedMode === prevState.feedMode)) {
            //console.log("NationalPark / componentDidUpdate - this.state.scrollToItem = ", this.state.scrollToItem);
            //console.log("NationalPark / componentDidUpdate - prevState.scrollToItem = ", prevState.scrollToItem);

            this.scrollToItem(this.state.scrollToItem);
        }

        // Automatically cancel selected
        if (
            (this.state.selected !== null) &&
            (this.state.selected !== this.state.hovered) &&
            (!this.state.scrollToItemInProgress) &&
            (this.state.checkScroll)
        ) {
            this.setState({
                selected : null
            });
        }
    }


    /*
    ============================================================================================
        menuModeClick
    ============================================================================================
    */

    menuModeClick(event, mainMode) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            mainMode: mainMode,
        });
    }


    /*
    ============================================================================================
        Scroll to item
    ============================================================================================
    */

    scrollToItem(itemIndex) {
        //console.log("NationalPark / scrollToItem - this.newItemRefs[itemIndex].current = ", this.newItemRefs[itemIndex].current);

        if (this.state.feedMode === "new") {
            const containerBox = this.newDotsFeedRef.current.getBoundingClientRect();
            const itemBox = this.newItemRefs[itemIndex].current.getBoundingClientRect();
            //console.log("NationalPark / scrollToItem - containerBox = ", containerBox);
            //console.log("NationalPark / scrollToItem - itemBox = ", itemBox);

            if (this.newDotsFeedRef.current !== null) {
                //console.log("NationalPark / scrollToItem - itemIndex = ", itemIndex);

                this.newDotsFeedRef.current.scrollTo(
                    0,
                    this.newItemRefs[itemIndex].current.offsetTop - this.newDotsFeedRef.current.offsetTop
                    - (containerBox.height - itemBox.height) / 2
                );
            }
        }
        else if (this.state.feedMode === "popular") {
            const containerBox = this.popularDotsFeedRef.current.getBoundingClientRect();
            const itemBox = this.popularItemRefs[itemIndex].current.getBoundingClientRect();
            //console.log("NationalPark / scrollToItem - containerBox = ", containerBox);
            //console.log("NationalPark / scrollToItem - itemBox = ", itemBox);

            if (this.popularDotsFeedRef.current !== null) {
                //console.log("NationalPark / scrollToItem - itemIndex = ", itemIndex);

                this.popularDotsFeedRef.current.scrollTo(
                    0,
                    this.popularItemRefs[itemIndex].current.offsetTop - this.popularDotsFeedRef.current.offsetTop
                    - (containerBox.height - itemBox.height) / 2
                );
            }
        }
        else if (this.state.feedMode === "approved") {
            const containerBox = this.approvedDotsFeedRef.current.getBoundingClientRect();
            const itemBox = this.approvedItemRefs[itemIndex].current.getBoundingClientRect();
            //console.log("NationalPark / scrollToItem - containerBox = ", containerBox);
            //console.log("NationalPark / scrollToItem - itemBox = ", itemBox);

            if (this.approvedDotsFeedRef.current !== null) {
                //console.log("NationalPark / scrollToItem - itemIndex = ", itemIndex);

                this.approvedDotsFeedRef.current.scrollTo(
                    0,
                    this.approvedItemRefs[itemIndex].current.offsetTop - this.approvedDotsFeedRef.current.offsetTop
                    - (containerBox.height - itemBox.height) / 2
                );
            }
        }

        // Reset scroll to item flag
        this.setState({
            scrollToItem: null
        });
    }


    /*
    ============================================================================================
        Switch Post Mode
    ============================================================================================
    */
    switchToNewMode() {
        this.setState({
            feedMode: "new",
            selected: null,
            hovered: null,
            scrollToItem: 0
        });
    };

    switchToPopularMode() {
        this.setState({
            feedMode: "popular",
            selected: null,
            hovered: null,
            scrollToItem: 0            
        });
    };

    switchToApprovedMode() {
        this.setState({
            feedMode: "approved",
            selected: null,
            hovered: null,
            scrollToItem: 0            
        });
    };

    switchToBoardMode() {
        this.setState({
            feedMode: "board",
            selected: null,
            hovered: null
        });
    };

    /*
    ============================================================================================
        Load Dots
    ============================================================================================
    */

    loadDotIDs(feedMode, callback) {
        //console.log("NationalPark / loadDotIDs");
        //console.log("NationalPark / loadDotIDs - feedMode = ", feedMode);

        // Determine start ID and offset rows
        let startID = null;
        let offsetRows = null;
        if (feedMode === "new") {
            startID = this.state.newEndID;
        }
        else if (feedMode === "popular") {
            offsetRows = this.state.popularIDs.length;
        }
        else if (feedMode === "approved") {
            startID = this.state.approvedEndID;
        }
        //console.log("NationalPark / loadDotIDs - startID = ", startID);
        //console.log("NationalPark / loadDotIDs - offsetRows = ", offsetRows);

        // Determine if user is logged in
        //const loggedIn = !!localStorage.token;

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NationalPark / loadDotIDs - response = ", response);
            //console.log("NationalPark / loadDotIDs - response.data.content.dot_ids = ", response.data.content.dot_ids);
            //console.log("NationalPark / loadDotIDs - response.data.content.feed_complete = ", response.data.content.feed_complete);
            //console.log("NationalPark / loadDotIDs - response.data.content.total_count = ", response.data.content.total_count);

            // Patch up the dotIDs array
            let dotIDsPrevious = null;
            if (feedMode === "new") {
                dotIDsPrevious = this.state.newIDs.slice();
                const dotIDs = dotIDsPrevious.concat(response.data.content.dot_ids)

                // Update state
                this.setState(
                    {
                        newIDs: dotIDs,
                        newFeedComplete: response.data.content.feed_complete,
                        newCount: (startID === null)?
                            response.data.content.total_count : this.state.newCount
                    },
                    callback
                );
            }
            else if (feedMode === "popular") {
                dotIDsPrevious = this.state.popularIDs.slice();
                const dotIDs = dotIDsPrevious.concat(response.data.content.dot_ids)

                // Update state
                this.setState(
                    {
                        popularIDs: dotIDs,
                        popularFeedComplete: response.data.content.feed_complete,
                        popularCount: (offsetRows === 0)?
                            response.data.content.total_count : this.state.popularCount
                    },
                    callback
                );
            }
            else if (feedMode === "approved") {
                dotIDsPrevious = this.state.approvedIDs.slice();
                const dotIDs = dotIDsPrevious.concat(response.data.content.dot_ids)

                // Update state
                this.setState(
                    {
                        approvedIDs: dotIDs,
                        approvedFeedComplete: response.data.content.feed_complete,
                        approvedCount: (startID === null)?
                            response.data.content.total_count : this.state.approvedCount
                    },
                    callback
                );
            }
        };

        getSetHomeDots(this.props.setSlug, feedMode, startID, null, offsetRows)
        .then(axiosCallback)
        .catch((response) => {
            console.log("NationalPark / loadDotIDs - Axios error ", response)
        });
    }

    /*
    ============================================================================================
        loadDotsInfo
    ============================================================================================
    */
    loadDotsInfo(feedMode, callback) {
        //console.log("NationalPark / loadDotsInfo");
        //console.log("NationalPark / loadDotsInfo - feedMode = ", feedMode);

        // Determine if user is logged in
        const loggedIn = !!localStorage.token;

        // Determine dot ids
        let dotIDs = null;
        let fetchComplete = false;

        if (feedMode === "new") {
            const startIndex = (this.state.newEndID === null)? 0 :
                this.state.newIDs.indexOf(this.state.newEndID) + 1;
            const endIndex = startIndex + this.numDotsPerBatch;
            const maxIndex = this.state.newIDs.length;
            //console.log("NationalPark / loadDotsInfo - startIndex = ", startIndex);
            //console.log("NationalPark / loadDotsInfo - endIndex = ", endIndex);

            if (startIndex >= maxIndex && endIndex >= maxIndex) {
                fetchComplete = true;
                dotIDs = [];
            }
            else if (startIndex < maxIndex && endIndex >= maxIndex) {
                dotIDs = this.state.newIDs.slice(startIndex, maxIndex);
            }
            else {
                dotIDs = this.state.newIDs.slice(startIndex, endIndex);
            }
            //console.log("NationalPark / loadDotsInfo - dotIDs = ", dotIDs);
        }
        else if (feedMode === "popular") {
            const startIndex = (this.state.popularEndID === null)? 0 :
                this.state.popularIDs.indexOf(this.state.popularEndID) + 1;
            const endIndex = startIndex + this.numDotsPerBatch;
            const maxIndex = this.state.popularIDs.length;
            //console.log("NationalPark / loadDotsInfo - startIndex = ", startIndex);
            //console.log("NationalPark / loadDotsInfo - endIndex = ", endIndex);

            if (startIndex >= maxIndex && endIndex >= maxIndex) {
                fetchComplete = true;
                dotIDs = [];
            }
            else if (startIndex < maxIndex && endIndex >= maxIndex) {
                dotIDs = this.state.popularIDs.slice(startIndex, maxIndex);
            }
            else {
                dotIDs = this.state.popularIDs.slice(startIndex, endIndex);
            }
            //console.log("NationalPark / loadDotsInfo - dotIDs = ", dotIDs);
        }
        else if (feedMode === "approved") {
            const startIndex = (this.state.approvedEndID === null)? 0 :
                this.state.approvedIDs.indexOf(this.state.approvedEndID) + 1;
            const endIndex = startIndex + this.numDotsPerBatch;
            const maxIndex = this.state.approvedIDs.length;
            //console.log("NationalPark / loadDotsInfo - startIndex = ", startIndex);
            //console.log("NationalPark / loadDotsInfo - endIndex = ", endIndex);

            if (startIndex >= maxIndex && endIndex >= maxIndex) {
                fetchComplete = true;
                dotIDs = [];
            }
            else if (startIndex < maxIndex && endIndex >= maxIndex) {
                dotIDs = this.state.approvedIDs.slice(startIndex, maxIndex);
            }
            else {
                dotIDs = this.state.approvedIDs.slice(startIndex, endIndex);
            }
            //console.log("NationalPark / loadDotsInfo - dotIDs = ", dotIDs);
        }

        // Callback after retrieving the dots info
        const fetchDotsCallback = () => {
            // Turn off scroll active flag
            this.onScrollActive = false;

            //console.log("NationalPark / loadDotsInfo - this.state = ", this.state);
            //console.log("NationalPark / loadDotsInfo - this.onScrollActive = ", this.onScrollActive);

            // Execute provided callback
            if (callback && typeof callback === "function") {
                callback();
            }
        };

        if (fetchComplete) {
            if (feedMode === "new") {
                this.setState(
                    {
                        newFeedLoaderOn: false
                    },
                    fetchDotsCallback
                );
            }
            else if (feedMode === "popular") {
                this.setState(
                    {
                        popularFeedLoaderOn: false
                    },
                    fetchDotsCallback
                );
            }
            else if (feedMode === "approved") {
                this.setState(
                    {
                        approvedFeedLoaderOn: false
                    },
                    fetchDotsCallback
                );
            }
        }
        else {
            // Axios callback : execute when the server returns a response
            const axiosCallback = (response) => {
                //console.log("NationalPark / loadDotsInfo - response = ", response);
                //console.log("NationalPark / loadDotsInfo - response.data.content.dots = ", response.data.content.dots);
                //console.log("NationalPark / loadDotsInfo - response.data.content.feed_complete = ", response.data.content.feed_complete);

                if (feedMode === "new") {
                    // Patch up the dotsInfo array
                    const dotsInfoPrevious = this.state.newDotsInfo.slice();
                    const dotsInfo = dotsInfoPrevious.concat(response.data.content.dots)

                    // Fetch dotsLiked arrays
                    const dotsLikedPrevious = this.state.newDotsLiked.slice();
                    const dotsSavedPrevious = this.state.newDotsSaved.slice();

                    const dotsLikedNew = [];
                    const dotsSavedNew = [];
                    for (let i = 0; i < response.data.content.dots.length; i++) {
                        if (loggedIn) {
                            dotsLikedNew.push(response.data.content.dots[i].liked_by_me);
                            dotsSavedNew.push(response.data.content.dots[i].saved_by_me);
                        }
                        else {
                            dotsLikedNew.push(null);
                            dotsSavedNew.push(null);
                        }
                    }
                    const dotsLiked = dotsLikedPrevious.concat(dotsLikedNew);
                    const dotsSaved = dotsSavedPrevious.concat(dotsSavedNew);

                    // Create post references
                    const newItemRefsPrevious = this.newItemRefs.slice();

                    const newItemRefs = [];
                    for (let i = 0; i < response.data.content.dots.length; i++) {
                        newItemRefs.push(React.createRef());
                    }
                    this.newItemRefs = newItemRefsPrevious.concat(newItemRefs);

                    // Update state
                    this.setState(
                        {
                            newEndID: dotIDs[dotIDs.length - 1],
                            newDotsInfo: dotsInfo,
                            newDotsLiked: dotsLiked,
                            newDotsSaved: dotsSaved,
                            newFeedLoaderOn: false,
                             //newWebSocketGroups: newWebSocketGroups                           
                        },
                        () => {
                            // Turn off scroll active flag
                            this.onScrollActive = false;

                            // Execute provided callback
                            if (callback && typeof callback === "function") {
                                callback();
                            }
                        }
                    );
                }
                else if (feedMode === "popular") {
                    // Patch up the dotsInfo array
                    const dotsInfoPrevious = this.state.popularDotsInfo.slice();
                    const dotsInfo = dotsInfoPrevious.concat(response.data.content.dots)

                    // Fetch dotsLiked arrays
                    const dotsLikedPrevious = this.state.popularDotsLiked.slice();
                    const dotsSavedPrevious = this.state.popularDotsSaved.slice();

                    const dotsLikedNew = [];
                    const dotsSavedNew = [];
                    for (let i = 0; i < response.data.content.dots.length; i++) {
                        if (loggedIn) {
                            dotsLikedNew.push(response.data.content.dots[i].liked_by_me);
                            dotsSavedNew.push(response.data.content.dots[i].saved_by_me);
                        }
                        else {
                            dotsLikedNew.push(null);
                            dotsSavedNew.push(null);
                        }
                    }
                    const dotsLiked = dotsLikedPrevious.concat(dotsLikedNew);
                    const dotsSaved = dotsSavedPrevious.concat(dotsSavedNew);

                    // Create post references
                    const popularItemRefsPrevious = this.popularItemRefs.slice();

                    const popularItemRefs = [];
                    for (let i = 0; i < response.data.content.dots.length; i++) {
                        popularItemRefs.push(React.createRef());
                    }
                    this.popularItemRefs = popularItemRefsPrevious.concat(popularItemRefs);

                    // Update state
                    this.setState(
                        {
                            popularEndID: dotIDs[dotIDs.length - 1],
                            popularDotsInfo: dotsInfo,
                            popularDotsLiked: dotsLiked,
                            popularDotsSaved: dotsSaved,
                            popularFeedLoaderOn: false,
                            //popularWebSocketGroups: popularWebSocketGroups                            
                        },
                        () => {
                            // Turn off scroll active flag
                            this.onScrollActive = false;

                            // Execute provided callback
                            if (callback && typeof callback === "function") {
                                callback();
                            }
                        }
                    );
                }
                else if (feedMode === "approved") {
                    // Patch up the dotsInfo array
                    const dotsInfoPrevious = this.state.approvedDotsInfo.slice();
                    const dotsInfo = dotsInfoPrevious.concat(response.data.content.dots)

                    // Fetch dotsLiked arrays
                    const dotsLikedPrevious = this.state.approvedDotsLiked.slice();
                    const dotsSavedPrevious = this.state.approvedDotsSaved.slice();

                    const dotsLikedNew = [];
                    const dotsSavedNew = [];
                    for (let i = 0; i < response.data.content.dots.length; i++) {
                        if (loggedIn) {
                            dotsLikedNew.push(response.data.content.dots[i].liked_by_me);
                            dotsSavedNew.push(response.data.content.dots[i].saved_by_me);
                        }
                        else {
                            dotsLikedNew.push(null);
                            dotsSavedNew.push(null);
                        }
                    }
                    const dotsLiked = dotsLikedPrevious.concat(dotsLikedNew);
                    const dotsSaved = dotsSavedPrevious.concat(dotsSavedNew);

                    // Create post references
                    const approvedItemRefsPrevious = this.approvedItemRefs.slice();

                    const approvedItemRefs = [];
                    for (let i = 0; i < response.data.content.dots.length; i++) {
                        approvedItemRefs.push(React.createRef());
                    }
                    this.approvedItemRefs = approvedItemRefsPrevious.concat(approvedItemRefs);

                    // Update state
                    this.setState(
                        {
                            approvedEndID: dotIDs[dotIDs.length - 1],
                            approvedDotsInfo: dotsInfo,
                            approvedDotsLiked: dotsLiked,
                            approvedDotsSaved: dotsSaved,
                            approvedFeedLoaderOn: false,
                            //approvedWebSocketGroups: approvedWebSocketGroups
                        },
                        () => {
                            // Turn off scroll active flag
                            this.onScrollActive = false;

                            // Execute provided callback
                            if (callback && typeof callback === "function") {
                                callback();
                            }
                        }
                    );
                }

                /*
                // Create board id arrays
                const boardIDs = [];
                for (let i = 0; i < dotsInfo.length; i++) {
                    boardIDs.push(dotsInfo[i].board.id);
                }

                // Add relevant webSocket group
                let webSocketGroups = [];
                for (let i = 0; i < response.data.content.dots.length; i++) {
                    webSocketGroups.push(this.webSocketGroupPrefix + "_" + response.data.content.dots[i].board.id);
                }
                if (webSocketGroups.length > 0) {
                    addWebSocketGroups(webSocketGroups);
                }

                // Update state
                const newWebSocketGroups = this.state.newWebSocketGroups.concat(webSocketGroups);
                */
            };

            const dataJSON = {
                dot_ids: dotIDs
            };

            postFetchDots(dataJSON)
            .then(axiosCallback)
            .catch((response) => {
                console.log("NationalPark / loadDotsInfo - Axios error ", response)
            });
        }
    }


    /*
    ============================================================================================
        Load Set Info
    ============================================================================================
    */

    loadSetInfo(callback) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("NaitonalPark / loadSetInfo - response.data.content = ", response.data.content);

            // Fetch board comments
            this.loadCommentsInfo(response.data.content.board.id);

            // Add relevant webSocket group
            let webSocketGroup = [];
            webSocketGroup.push(this.webSocketGroupPrefix + "_" + response.data.content.board.id);
            addWebSocketGroups(webSocketGroup);

            // Set states and check if the logged in user follows the editor
            this.setState(
                {
                    setInfo: response.data.content,
                    webSocketGroup: webSocketGroup
                },
                callback
            );
        };

        getDot(this.props.setSlug)
        .then(axiosCallback)
        .catch((response) => { console.log("NationalPark / loadSetInfo - Axios error ", response); });
    }


    /*
    ============================================================================================
        loadCommentsInfo
    ============================================================================================
    */

    loadCommentsInfo(boardID) {
        // console.log("NationalPark / loadCommentsInfo - boardID = ", boardID);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (commentsResponse) => {
            //console.log("NationalPark / loadCommentsInfo - commentsResponse.data = ", commentsResponse.data);
            this.setState(
                {
                    commentsInfo: commentsResponse.data.content,
                }
            );
        };

        // Send post request using axios with CSRF token
        getSingleBoardComments(boardID, 0)
        .then(axiosCallback)
        .catch((response) => {console.log("NationalPark / loadCommentsInfo - Axios error ", response);});
    }


    /*
    ============================================================================================
        Check Like
    ============================================================================================
    */
    checkLike(feedMode, callback) {
        // Construct request data
        const dotIDs = [];

        if (feedMode === "new") {
            for (let i = 0; i < this.state.newDotsInfo.length; i++) {
                dotIDs.push(this.state.newDotsInfo[i].id);
            }
        }
        else if (feedMode === "popular") {
            for (let i = 0; i < this.state.popularDotsInfo.length; i++) {
                dotIDs.push(this.state.popularDotsInfo[i].id);
            }
        }
        else if (feedMode === "approved") {
            for (let i = 0; i < this.state.approvedDotsInfo.length; i++) {
                dotIDs.push(this.state.approvedDotsInfo[i].id);
            }
        }

        const dataJSON = {
            "dot_ids" : dotIDs
        };
        //console.log("NationalPark / checkLike - dataJSON = ", dataJSON);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (checkLikeResponse) => {
            //console.log("NationalPark / checkLike - checkLikeResponse = ", checkLikeResponse);
            //console.log("NationalPark / checkLike - checkLikeResponse.data.content.dot_liked = ", checkLikeResponse.data.content.dot_liked);

            if (feedMode === "new") {
                // Set state
                this.setState(
                    {
                        newDotsLiked: checkLikeResponse.data.content.dot_liked
                    },
                    callback
                );
            }
            else if (feedMode === "popular") {
                // Set state
                this.setState(
                    {
                        popularDotsLiked: checkLikeResponse.data.content.dot_liked
                    },
                    callback
                );                
            }
            else if (feedMode === "approved") {
                // Set state
                this.setState(
                    {
                        approvedDotsLiked: checkLikeResponse.data.content.dot_liked
                    },
                    callback
                );                
            }
        };

        postCheckLike(dataJSON)
        .then(axiosCallback)
        .catch((response) => {
            console.log("NationalPark / checkLike - Axios error ", response)
        });
    }


    /*
    ============================================================================================
        Check Save
    ============================================================================================
    */
    checkSave(feedMode, callback) {
        // Construct request data
        const dotIDs = [];
        if (feedMode === "new") {
            for (let i = 0; i < this.state.newDotsInfo.length; i++) {
                dotIDs.push(this.state.newDotsInfo[i].id);
            }
        }
        else if (feedMode === "popular") {
            for (let i = 0; i < this.state.popularDotsInfo.length; i++) {
                dotIDs.push(this.state.popularDotsInfo[i].id);
            }
        }
        else if (feedMode === "approved") {
            for (let i = 0; i < this.state.approvedDotsInfo.length; i++) {
                dotIDs.push(this.state.approvedDotsInfo[i].id);
            }
        }

        const dataJSON = {
            "dot_ids" : dotIDs
        };
        //console.log("NationalPark / checkSave - dataJSON = ", dataJSON);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (checkSaveResponse) => {
            //console.log("NationalPark / checkSave - checkSaveResponse = ", checkSaveResponse);
            //console.log("NationalPark / checkSave - checkSaveResponse.data.content.dot_saved = ", checkSaveResponse.data.content.dot_saved);

            if (feedMode === "new") {
                // Set state
                this.setState(
                    {
                        newDotsSaved: checkSaveResponse.data.content.dot_saved
                    },
                    callback
                );
            }
            else if (feedMode === "popular") {
                // Set state
                this.setState(
                    {
                        popularDotsSaved: checkSaveResponse.data.content.dot_saved
                    },
                    callback
                );                
            }
            else if (feedMode === "approved") {
                // Set state
                this.setState(
                    {
                        approvedDotsSaved: checkSaveResponse.data.content.dot_saved
                    },
                    callback
                );                
            }
        };

        postCheckSave(dataJSON)
        .then(axiosCallback)
        .catch((response) => {
            console.log("NationalPark / checkSave - Axios error ", response)
        });
    }


    /*
    ============================================================================================
        Reset Like
    ============================================================================================
    */
    resetLike() {
        // Construct nullified like
        const newDotsLiked = [];
        const popularDotsLiked = [];
        const approvedDotsLiked = [];
        for (let i = 0; i < this.state.newDotsInfo.length; i++) {
            newDotsLiked.push(null);
        }
        for (let i = 0; i < this.state.popularDotsInfo.length; i++) {
            popularDotsLiked.push(null);
        }
        for (let i = 0; i < this.state.approvedDotsInfo.length; i++) {
            approvedDotsLiked.push(null);
        }

        // Update state
        this.setState(
            {
                newDotsLiked: newDotsLiked,
                popularDotsLiked: popularDotsLiked,
                approvedDotsLiked: approvedDotsLiked
            }
        );
    }


    /*
    ============================================================================================
        Edit dot
    ============================================================================================
    */

    editButtonClick(event, dotInfo) {
        // Stop propagation
        event.stopPropagation();

        // Open create modal in edit mode
        this.props.storeCreate({
            modalOn: true,
            mode: "edit",
            setSlug: this.state.setInfo.slug,
            location: null,
            info: dotInfo
        });
        //this.props.history.push(`/dot_edit/${setSlug}`);
    }


    /*
    ============================================================================================
        Create dot
    ============================================================================================
    */

    createButtonClick(event) {
        // Stop propagation
        event.stopPropagation();

        if (this.props.userInfo !== null) {
            // Open create modal in create mode
            this.props.storeCreate({
                modalOn: true,
                mode: "create",
                setSlug: this.state.setInfo.slug,
                location: null,
                info: null
            });
        }
        else {
            // Turn on the flag
            this.createOn = true;

            // Open log in
            this.props.storeLogInSimpleOn(true);
        }
    }


    /*
    ============================================================================================
        Delete dot
    ============================================================================================
    */

    deleteButtonClick(event, dotSlug) {
        //console.log("NationalPark / deleteButtonClick - dotSlug = ", dotSlug);

        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            deleteSlug: dotSlug,
            deleteConfirmOn: true
        });
    }

    deleteConfirmClick(event) {
        //console.log("NationalPark / deleteConfirmClick");

        // Stop propagation
        event.stopPropagation();

        // Callback
        const axiosCallback = () => {
            if (this.state.feedMode === "new") {
                const newIDs = [];
                const newDotsInfo = [];
                const newDotsLiked = [];
                const newDotsSaved = [];
                const newItemRefs = [];

                for (let i = 0; i < this.state.newDotsInfo.length; i++) {
                    if (this.state.newDotsInfo[i].slug !== this.state.deleteSlug) {
                        newIDs.push(this.state.newIDs[i]);
                        newDotsInfo.push(this.state.newDotsInfo[i]);
                        newDotsLiked.push(this.state.newDotsLiked[i]);
                        newDotsSaved.push(this.state.newDotsSaved[i]);
                        newItemRefs.push(this.newItemRefs[i]);
                    }
                }

                const newStartID = newIDs[0];
                const newEndID = newIDs[newIDs.length - 1];

                // Update state
                this.setState(
                    {
                        newIDs: newIDs,
                        newStartID: newStartID,
                        newEndID: newEndID,
                        newCount: this.state.newCount - 1,
                        newDotsInfo: newDotsInfo,
                        newDotsLiked: newDotsLiked,
                        newDotsSaved: newDotsSaved,
                        deleteSlug: null,
                        deleteConfirmOn: false
                    },
                    () => {
                        // Update item refs
                        this.newItemRefs = newItemRefs;
                    }
                );
            }
            else if (this.state.feedMode === "popular") {
                const popularIDs = [];
                const popularDotsInfo = [];
                const popularDotsLiked = [];
                const popularDotsSaved = [];
                const popularItemRefs = [];

                for (let i = 0; i < this.state.popularDotsInfo.length; i++) {
                    if (this.state.popularDotsInfo[i].slug !== this.state.deleteSlug) {
                        popularIDs.push(this.state.popularIDs[i]);
                        popularDotsInfo.push(this.state.popularDotsInfo[i]);
                        popularDotsLiked.push(this.state.popularDotsLiked[i]);
                        popularDotsSaved.push(this.state.popularDotsSaved[i]);
                        popularItemRefs.push(this.popularItemRefs[i]);
                    }
                }

                const popularStartID = popularIDs[0];
                const popularEndID = popularIDs[popularIDs.length - 1];

                // Update state
                this.setState(
                    {
                        popularIDs: popularIDs,
                        popularStartID: popularStartID,
                        popularEndID: popularEndID,
                        popularCount: this.state.popularCount - 1,
                        popularDotsInfo: popularDotsInfo,
                        popularDotsLiked: popularDotsLiked,
                        popularDotsSaved: popularDotsSaved,
                        deleteSlug: null,
                        deleteConfirmOn: false
                    },
                    () => {
                        // Update item refs
                        this.popularItemRefs = popularItemRefs;
                    }
                );              
            }
            else if (this.state.feedMode === "approved") {
                const approvedIDs = [];
                const approvedDotsInfo = [];
                const approvedDotsLiked = [];
                const approvedDotsSaved = [];
                const approvedItemRefs = [];

                for (let i = 0; i < this.state.approvedDotsInfo.length; i++) {
                    if (this.state.approvedDotsInfo[i].slug !== this.state.deleteSlug) {
                        approvedIDs.push(this.state.approvedIDs[i]);
                        approvedDotsInfo.push(this.state.approvedDotsInfo[i]);
                        approvedDotsLiked.push(this.state.approvedDotsLiked[i]);
                        approvedDotsSaved.push(this.state.approvedDotsSaved[i]);
                        approvedItemRefs.push(this.approvedItemRefs[i]);
                    }
                }

                const approvedStartID = approvedIDs[0];
                const approvedEndID = approvedIDs[approvedIDs.length - 1];

                // Update state
                this.setState(
                    {
                        approvedIDs: approvedIDs,
                        approvedStartID: approvedStartID,
                        approvedEndID: approvedEndID,
                        approvedCount: this.state.approvedCount - 1,
                        approvedDotsInfo: approvedDotsInfo,
                        approvedDotsLiked: approvedDotsLiked,
                        approvedDotsSaved: approvedDotsSaved,
                        deleteSlug: null,
                        deleteConfirmOn: false
                    },
                    () => {
                        // Update item refs
                        this.approvedItemRefs = approvedItemRefs;
                    }
                );                 
            }
        }

        deleteDot(this.state.deleteSlug)
        .then(axiosCallback)
        .catch(response => console.log("More / onDelete - Axios error ", response));
    }


    deleteCancelClick(event) {
        //console.log("NationalPark / deleteCancelClick");

        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            deleteSlug: null,
            deleteConfirmOn: false
        });
    }


    /*
    ============================================================================================
        Reset Save
    ============================================================================================
    */
    resetSave() {
        // Construct nullified save
        const newDotsSaved = [];
        const popularDotsSaved = [];
        const approvedDotsSaved = [];
        for (let i = 0; i < this.state.newDotsInfo.length; i++) {
            newDotsSaved.push(null);
        }
        for (let i = 0; i < this.state.popularDotsInfo.length; i++) {
            popularDotsSaved.push(null);
        }
        for (let i = 0; i < this.state.approvedDotsInfo.length; i++) {
            approvedDotsSaved.push(null);
        }

        // Update state
        this.setState(
            {
                newDotsSaved: newDotsSaved,
                popularDotsSaved: popularDotsSaved,
                approvedDotsSaved: approvedDotsSaved
            }
        );
    }


    /*
    ============================================================================================
        newOnScroll
    ============================================================================================
    */

    newOnScroll(event) {
        //console.log("NationalPark / newOnScroll - event = ", event);

        // When reached the bottom
        if (this.state.feedMode === "new" && this.newDotsFeedRef.current !== null) {
            //console.log("NationalPark / newOnScroll");

            // Send out check scroll signal
            this.setState(
                {
                    checkScroll: true
                },
                () => {
                    this.setState({
                        checkScroll: false,
                        newScrollPosition: window.scrollY
                    });
                }
            );

            //console.log("NationalPark / newOnScroll - last element bottom = ", this.newItemRefs[this.newItemRefs.length - 1].current.getBoundingClientRect().bottom);
            //console.log("NationalPark / newOnScroll - window.innerHeight = ", window.innerHeight);
            //console.log("NationalPark / newOnScroll - this.onScrollActive = ", this.onScrollActive);

            if (
                (this.newItemRefs[this.newItemRefs.length - 1].current.getBoundingClientRect().bottom <= window.innerHeight) &&
                (!this.onScrollActive)
            ) {
                // Activate flag
                this.onScrollActive = true;

                // Update state
                this.setState(
                    {
                        newFeedLoaderOn: true,
                    },
                    () => {
                        // Fetch another page
                        if (!this.state.newFeedComplete) {
                            if (this.state.newIDs.length > this.state.newDotsInfo.length) {
                                //console.log("NationalPark / newOnScroll - fetching new dotsInfo");
                                this.loadDotsInfo("new", null);
                            }
                            else {
                                //console.log("NationalPark / newOnScroll - fetching new dotIDs and dotsInfo");
                                // Load new dots
                                this.loadDotIDs(
                                    "new",
                                    () => { this.loadDotsInfo("new", null); }
                                );
                            }

                        }
                        else {
                            this.setState(
                                {
                                    newFeedLoaderOn: false
                                },
                                () => {
                                    // De-activate flag
                                    this.onScrollActive = false;                                    
                                }
                            );
                        }
                    }
                )
            }
        }
    }


    /*
    ============================================================================================
        popularOnScroll
    ============================================================================================
    */

    popularOnScroll(event) {
        //console.log("NationalPark / popularOnScroll - event = ", event);

        // When reached the bottom
        if (this.state.feedMode === "popular" && this.popularDotsFeedRef.current !== null) {
            //console.log("NationalPark / popularOnScroll");

            // Send out check scroll signal
            this.setState(
                {
                    checkScroll: true
                },
                () => {
                    this.setState({
                        checkScroll: false,
                        popularScrollPosition: window.scrollY
                    });
                }
            );

            //console.log("NationalPark / popularOnScroll - last element bottom = ", this.popularItemRefs[this.popularItemRefs.length - 1].current.getBoundingClientRect().bottom);
            //console.log("NationalPark / popularOnScroll - window.innerHeight = ", window.innerHeight);
            //console.log("NationalPark / popularOnScroll - this.onScrollActive = ", this.onScrollActive);

            if (
                (this.popularItemRefs[this.popularItemRefs.length - 1].current.getBoundingClientRect().bottom <= window.innerHeight) &&
                (!this.onScrollActive)
            ) {
                // Activate flag
                this.onScrollActive = true;

                // Update state
                this.setState(
                    {
                        popularFeedLoaderOn: true,
                    },
                    () => {
                        // Fetch another page
                        if (!this.state.popularFeedComplete) {
                            if (this.state.popularIDs.length > this.state.popularDotsInfo.length) {
                                //console.log("NationalPark / popularOnScroll - fetching popular dotsInfo");
                                this.loadDotsInfo("popular", null);
                            }
                            else {
                                //console.log("NationalPark / popularOnScroll - fetching popular dotIDs and dotsInfo");
                                // Load popular dots
                                this.loadDotIDs(
                                    "popular",
                                    () => { this.loadDotsInfo("popular", null); }
                                );
                            }

                        }
                        else {
                            this.setState(
                                {
                                    popularFeedLoaderOn: false
                                },
                                () => {
                                    // De-activate flag
                                    this.onScrollActive = false;                                    
                                }
                            );
                        }
                    }
                )
            }
        }
    }


    /*
    ============================================================================================
        approvedOnScroll
    ============================================================================================
    */

    approvedOnScroll(event) {
        //console.log("NationalPark / approvedOnScroll - event = ", event);

        // When reached the bottom
        if (this.state.feedMode === "approved" && this.approvedDotsFeedRef.current !== null) {
            //console.log("NationalPark / approvedOnScroll");

            // Send out check scroll signal
            this.setState(
                {
                    checkScroll: true
                },
                () => {
                    this.setState({
                        checkScroll: false,
                        approvedScrollPosition: window.scrollY
                    });
                }
            );

            //console.log("NationalPark / approvedOnScroll - last element bottom = ", this.approvedItemRefs[this.approvedItemRefs.length - 1].current.getBoundingClientRect().bottom);
            //console.log("NationalPark / approvedOnScroll - window.innerHeight = ", window.innerHeight);
            //console.log("NationalPark / approvedOnScroll - this.onScrollActive = ", this.onScrollActive);

            if (
                (this.approvedItemRefs[this.approvedItemRefs.length - 1].current.getBoundingClientRect().bottom <= window.innerHeight) &&
                (!this.onScrollActive)
            ) {
                // Activate flag
                this.onScrollActive = true;

                // Update state
                this.setState(
                    {
                        approvedFeedLoaderOn: true,
                    },
                    () => {
                        // Fetch another page
                        if (!this.state.approvedFeedComplete) {
                            if (this.state.approvedIDs.length > this.state.approvedDotsInfo.length) {
                                //console.log("NationalPark / approvedOnScroll - fetching approved dotsInfo");
                                this.loadDotsInfo("approved", null);
                            }
                            else {
                                //console.log("NationalPark / approvedOnScroll - fetching approved dotIDs and dotsInfo");
                                // Load approved dots
                                this.loadDotIDs(
                                    "approved",
                                    () => { this.loadDotsInfo("approved", null); }
                                );
                            }

                        }
                        else {
                            this.setState(
                                {
                                    approvedFeedLoaderOn: false
                                },
                                () => {
                                    // De-activate flag
                                    this.onScrollActive = false;                                    
                                }
                            );
                        }
                    }
                )
            }
        }
    }


    /*
    ============================================================================================
        Item event
    ============================================================================================
    */

    itemHoverOn(event, itemIndex) {
        //console.log("NationalPark / itemHoverOn - event",event);
        //console.log("NationalPark / itemHoverOn - itemIndex",itemIndex);
        // Prevent event actinos
        //event.stopPropagation();
        //event.preventDefault();

        /*
        if (window.touchOn) {
            // Update state
            this.setState({
                hovered: null,
            });
        }
        else {
            // Update state
            this.setState({
                hovered: itemIndex,
            });
        }
        */
    }

    itemHoverOff(event, itemIndex) {
        //console.log("NationalPark / itemHoverOff - event",event);
        //console.log("NationalPark / itemHoverOff - itemIndex",itemIndex);
        // Prevent event actinos
        //event.stopPropagation();
        //event.preventDefault();

        // Update state
        this.setState({
            selected: null
        });

    }

    itemClick(event, itemIndex, selectZoomIn) {
        //console.log("NationalPark / itemClick - hovered = ", this.state.hovered);
        //console.log("NationalPark / itemClick - selected = ", this.state.selected);
        //console.log("NationalPark / itemClick - itemIndex = ", itemIndex);

        // Unselect if selected
        if (this.state.selected === itemIndex) {
            //console.log("NationalPark / itemClick - unselecting ", itemIndex);

            // Update state
            this.setState(
                {
                    selected: null,
                    selectZoomIn: null,
                    scrollToItemInProgress: null
                }
            );
        }
        else {
            //console.log("NationalPark / itemClick - selecting ", itemIndex);

            // Update state
            this.setState(
                {
                    selected: itemIndex,
                    selectZoomIn: selectZoomIn,
                    scrollToItem: itemIndex,
                    scrollToItemInProgress: true
                },
                () => {
                    setTimeout(
                        () => {
                            this.setState({
                                selectZoomIn: null,
                                scrollToItemInProgress: null
                            });
                        },
                        this.scrollCancelTime
                    );
                }
            );
        }
    }
}


class NationalParkPost extends Component {
    constructor(props) {
        super(props);

        // Post ref
        this.postRef = React.createRef();

        // Overview text box ref
        this.overviewRef = React.createRef();

        // Text height
        this.overviewHeight = 78;

        // Initialize state
        this.state = {
            inViewport: false,
            feedVideoOn: false,
            selectedMediaIndex: 0,
            variableHeight: false,
            expanded: false,
            likedByMe: this.props.dotInfo.liked_by_me,
            likeCount: this.props.dotInfo.liked_user_count,
            savedByMe: this.props.dotInfo.saved_by_me,
            saveCount: this.props.dotInfo.saved_user_count
        };

        // Bind functions
        // Bind like / share / more button functions
        this.setState = this.setState.bind(this);
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);
        this.likeButtonClick = likeButtonClick.bind(this);
        this.shareButtonClick = this.shareButtonClick.bind(this);
        this.moreButtonClick = this.moreButtonClick.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    render() {
        // IsAuthor
        const isAuthor = (this.props.loggedIn && this.props.userInfo.id === this.props.dotInfo.editor.id);
        //console.log("NationalPark / render - isAuthor = ", isAuthor);

        /*
        ============================================================================================
            Media
        ============================================================================================
        */

        // Sort media
        const mediaSorted = sortMedia(this.props.dotInfo);

        // Media categories
        const mediaCombined = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];


        /*
        ============================================================================================
            Media navigation
        ============================================================================================
        */

        /*
        const nextArrowImage = getStaticPath("/images/common/arrow-right-white.png");
        const nextArrow = this.state.selectedMediaIndex < (mediaCombined.length - 1)?
        (
            <div className = "national-park-post-media-next-arrow image-cover"
                style = {{ backgroundImage:  nextArrowImage }}
                onClick = {this.nextMediaClick}
            >
            </div>
        ) : null;

        const prevArrowImage = getStaticPath("/images/common/arrow-left-white.png");
        const prevArrow = this.state.selectedMediaIndex > 0?
        (
            <div className = "national-park-post-media-prev-arrow image-cover"
                style = {{ backgroundImage: prevArrowImage }}
                onClick = {this.prevMediaClick}
            >
            </div>
        ) : null;

        // Navigation dots
        const navDotImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/image-dot-black.png") :
            getStaticPath("/images/common/image-dot-white.png");

        const navDots = (mediaCombined.length > 1)?
        mediaCombined.map((media, index) => {
            // If selected
            const navDotClass = (this.state.selectedMediaIndex === index)?
                "national-park-post-nav-dot-on" : "national-park-post-nav-dot-off";

            return(
                <div key = {"national-park-post-nav-dot-" + index.toString()} className={navDotClass}
                    style = {{ backgroundImage: navDotImage }}
                    onClick = {(event) => {this.navDotClick(event, index);}}
                >
                </div>
            );
        }) : null;
        */


        /*
        ============================================================================================
            Effective browser width
        ============================================================================================
        */

        const effectiveBrowserWidth = (this.props.doubleLayout)?
            ((Math.min(this.props.windowMaxWidth, this.props.browserWidthPixels) - 2 * this.props.marginWidth) / 2 - this.props.scrollBarWidth) :
            (this.props.browserWidthPixels - this.props.marginWidth);
        //console.log("NationalParkPost / render - this.props.doubleLayout = ", this.props.doubleLayout);
        //console.log("NationalParkPost / render - effectiveBrowserWidth = ", effectiveBrowserWidth);
        //console.log("NationalParkPost / render - this.props.browserWidthPixels = ", this.props.browserWidthPixels);
        //console.log("NationalParkPost / render - this.props.marginWidth = ", this.props.marginWidth);
        //console.log("NationalParkPost / render - this.props.scrollBarWidth = ", this.props.scrollBarWidth);
        //const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.props.marginWidth;


        /*
        ============================================================================================
            Overview gallery
        ============================================================================================
        */

        let gallery = null;
        let galleryDimensions = null;
        if (mediaCombined.length > 0) {
            // Get the image dimensions for the right size (small size)
            const mediaInfo = mediaCombined[this.state.selectedMediaIndex];
            const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
            const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

            // Is video
            const isVideo = (mediaCombined[this.state.selectedMediaIndex].type === "video");

            // Gallery dimensions
            galleryDimensions = mediaDimensions({
                colorMode: this.props.colorMode,
                effectiveBrowserWidth: effectiveBrowserWidth,
                isVideo: isVideo,
                mediaArea: this.props.mediaArea,
                mediaWidth: mediaWidth,
                mediaHeight: mediaHeight,
                minAspectRatio: this.props.minAspectRatio,
                maxAspectRatio: this.props.maxAspectRatio,
                minContentWidth: this.props.minContentWidth,
                scaleContentWidth: this.props.scaleContentWidth,
                maxContentWidth: this.props.maxContentWidth
            });
            //console.log("NationalPark / render - galleryDimensions = ", galleryDimensions);

            // Gallery props
            const galleryProps = Object.assign(
                galleryDimensions,
                {
                    // General
                    id: "",
                    idPrefix: "national-park-post-" + this.props.itemIndex,
                    classPrefix: "national-park-post",
                    selectedMediaIndex: this.state.selectedMediaIndex,
                    media: mediaCombined,
                    size: "s",
                    startPlaying: true,
                    checkScroll: this.props.checkScroll,
                    nextMediaClick: this.nextMediaClick,
                    prevMediaClick: this.prevMediaClick,
                    navDotClick: this.navDotClick,
                    index: null,
                    square: false,
                    muted: false,

                    // Interaction buttons
                    userTagOn: false,
                    saved: this.state.savedByMe,
                    dotTagOn: false,
                    unsaveOn: false,
                    enlargeOn: true,
                    dotInfo: this.props.dotInfo,
                    tagMode: "save"
                }
            );

            gallery = (
                <Gallery {...galleryProps} />
            );
        }

        /*
        ============================================================================================
            Media
        ============================================================================================
        */

        /*
        const media = (mediaCombined[this.state.selectedMediaIndex].type === "video")?
        (
            <div className = "national-park-post-media-wrapper image-loader-s2"
                style = {mediaWrapperStyle}
            >
                <ReactPlayer className = "national-park-post-video"
                    url = {mediaURL}
                    width = "100%"
                    height = "100%"
                    volume = {0.05}
                    playing = {this.state.inViewport && this.state.feedVideoOn}
                    controls
                    playsinline
                />
                {nextArrow}
                {prevArrow}
                {enlargeButton}
                <div className = "national-park-post-media-shadow"
                    style = {{ backgroundImage: shadowImage }}
                >
                </div>
            </div>
        ) : (
            <div className = "national-park-post-media-wrapper image-loader-s2"
                style = {mediaWrapperStyle}>
                <div className = "national-park-post-image"
                    style = {{ backgroundImage: url(mediaURL) }}
                >
                </div>
                {nextArrow}
                {prevArrow}
                {enlargeButton}
                <div className = "national-park-post-media-shadow"
                    style = {{ backgroundImage: shadowImage }}
                >
                </div>
            </div>
        );
        */

        /*
        ============================================================================================
            Title / Overview / Expand Button
        ============================================================================================
        */

        // Title
        const title = this.props.dotInfo.title;

        // Overview
        //const overview = this.props.dotInfo.overview.replace( /\*/g, "");
        const overview = regularCurationStrong(
            this.props.dotInfo.overview,
            "national-park-post",
            "national-park-post",
            this.props.colorMode
        );

        // Expand button
        const expandButtonImage = (this.state.expanded)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/less-info-black.png") :
                    getStaticPath("/images/common/less-info-white.png")
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/more-info-black-arrow.png") :
                    getStaticPath("/images/common/more-info-white-arrow.png")
            );

        const expandButton = (this.state.variableHeight)? (
            <div className = "national-park-post-expand-button image-button-base"
                style = {{
                    backgroundImage: expandButtonImage
                }}
                onClick = {this.toggleExpanded}
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            General Interaction
        ============================================================================================
        */

        // Like button
        let likeButton = null;

        if (this.props.loggedIn) {
            const likeButtonClickProps = {
                setState: this.setState,
                likedByMe: this.state.likedByMe,
                userInfo: this.props.userInfo,
                dotInfo: this.props.dotInfo,
                storeNewLike: this.props.storeNewLike,
                storeUser: this.props.storeUser
            };

            const likeButtonImage = this.state.likedByMe?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/like-on-red.png") :
                        getStaticPath("/images/common/like-on-red.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/like-off-black.png") :
                        getStaticPath("/images/common/like-off-white.png")
                );

            likeButton = (isAuthor)? null : (
                <div className = {(this.state.likedByMe)?
                        "national-park-post-like-button-on image-button-weak-s3" :
                        "national-park-post-like-button-off image-button-weak-s3"
                    }
                    style = {{ backgroundImage: likeButtonImage }}
                    onClick = {likeButtonClick.bind(this, likeButtonClickProps)}
                >
                </div>
            );
        }
        // Like count
        const likeText = (this.state.likeCount === 1)?
            "Like" : "Likes";
        let likeCount = (this.state.likeCount > 0)?
        (
            <div className = {(this.props.colorMode === "day")?
                "national-park-post-like-count k5" : "national-park-post-like-count w5"}
            >
                {formatNumbers(this.state.likeCount).toString()} {likeText}
            </div>
        ) : null;

        // Share Button
        let shareButton = null;
        const shareButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/share-off-black.png") :
            getStaticPath("/images/common/share-off-white.png");


        shareButton = (
            <div className = "national-park-post-share-button image-button-weak-s2"
                onClick = {this.shareButtonClick}
                style = {{ backgroundImage: shareButtonImage }}
            >
            </div>
        );


        /*
        ============================================================================================
            Author Menu
        ============================================================================================
        */

        // More Button
        let moreButton = null;
        const moreButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/more-black.png") :
            getStaticPath("/images/common/more-white.png");

        moreButton = (isAuthor)? (
            <div className = "national-park-post-more-button image-button-base"
                onClick = {(event) => { this.moreButtonClick(event); }}
                style = {{ backgroundImage:  moreButtonImage }}
            >
            </div>
        ) : null;

        // Edit Button
        let editButton = null;
        const editButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/edit-black.png") :
            getStaticPath("/images/common/edit-white.png")

        editButton = (isAuthor)?(
            <div className = "national-park-post-edit-button image-button-base"
                onClick = {(event) => { this.props.editButtonClick(event, this.props.dotInfo); }}
                style = {{ backgroundImage:  editButtonImage }}
            >
            </div>
        ) : null;

        // Delete Button
        let deleteButton = null;
        const deleteButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/trash-can-black.png") :
            getStaticPath("/images/common/trash-can-white.png")

        deleteButton = (isAuthor)?(
            <div className = "national-park-post-delete-button image-button-base"
                onClick = {(event) => { this.props.deleteButtonClick(event, this.props.dotInfo.slug); }}
                style = {{ backgroundImage:  deleteButtonImage }}
            >
            </div>
        ) : null;

        //console.log("nationalParkMap / nationalParkDots / dotInfo.editor.profile_pic",dotInfo.editor.profile_pic);


        /*
        ============================================================================================
            Editor
        ============================================================================================
        */

        // Editor
        const editorMedia = (this.props.dotInfo.editor.profile_pic)?
            (
                (this.props.dotInfo.editor.profile_pic.external_url === null)?
                    getMediaProperty(this.props.dotInfo.editor.profile_pic, "t", "url", true) :
                    url(this.props.dotInfo.editor.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
        const editorName = this.props.dotInfo.editor.name;


        /*
        ============================================================================================
            Main JSX
        ============================================================================================
        */

        return(
            <div ref = {this.postRef}
                className = {(this.props.colorMode === "day")?
                    "national-park-post-day" : "national-park-post-night"}
                onMouseEnter = {(event) => { this.props.itemHoverOn(event, this.props.itemIndex); }}
                //onMouseLeave = {(event) => { this.props.itemHoverOff(event, this.props.itemIndex); }}
                onClick = {(event) => {this.props.itemClick(event, this.props.itemIndex, true); }}
                //style = {{ border: this.state.inViewport? "2px dotted #888" : "none" }}
            >
                <div className = "national-park-post-top">
                    {gallery}
                </div>

                <div className = "national-park-post-middle"
                    style = {{ width: galleryDimensions.finalMediaWidth }}
                >
                    <Link to = {`/user/${this.props.dotInfo.editor.username}`}
                        className = "national-park-post-editor"
                    >
                        <div className = "national-park-post-editor-media profile-image-s3"
                            style = {{
                                backgroundImage: editorMedia
                            }}
                        >
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                            "national-park-post-editor-name k4" :
                            "national-park-post-editor-name w4"}
                        >
                            {editorName}
                        </div>
                    </Link>
                    <div className = "national-park-post-interaction">
                        {likeCount}
                        {likeButton}
                        {shareButton}
                        {moreButton}
                        <div className = {(this.props.colorMode === "day")?
                                "national-park-post-more-menu modal-day" :
                                "national-park-post-more-menu modal-night"}
                            style = {{ display:(this.state.moreMenuOn)? "inline-block" : "none"}}
                        >
                            {editButton}
                            {deleteButton}
                        </div>
                    </div>
                </div>

                <div className = "national-park-post-bottom">
                    <div className = {
                        (this.props.colorMode === "day")?
                            "national-park-post-title b3" :
                            "national-park-post-title lb3"}
                    >
                        {title}
                    </div>
                    <div ref = {this.overviewRef}
                        className = {
                            (this.props.colorMode === "day")?
                                (
                                    (this.state.variableHeight)?
                                        (
                                            (this.state.expanded)?
                                                "national-park-post-overview-expanded k4" : "national-park-post-overview-folded k4"
                                        ) : (
                                            "national-park-post-overview k4"
                                        )
                                ) : (
                                    (this.state.variableHeight)?
                                        (
                                            (this.state.expanded)?
                                                "national-park-post-overview-expanded lg4" : "national-park-post-overview-folded lg4"
                                        ) : (
                                            "national-park-post-overview lg4"
                                        )
                                )
                        }
                    >
                        {overview}
                    </div>

                    {expandButton}
                </div>
            </div>

        );
    }

    componentDidMount() {
        if (this.overviewRef.current !== null) {
            // Judge if this is within viewport
            const overviewBoundingBox = this.overviewRef.current.getBoundingClientRect();
            //console.log("componentDidMount - overviewBoundingBox = ", overviewBoundingBox);

            // If the container size is large
            if (overviewBoundingBox.height > this.overviewHeight) {
                this.setState({
                    variableHeight: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.checkScroll === false) && (this.props.checkScroll === true)) {
            if (this.postRef.current !== null) {
                // Judge if this is within viewport
                const postBoundingBox = this.postRef.current.getBoundingClientRect();

                const inViewport = (
                    (postBoundingBox.top < this.props.scrollMidLine)
                    &&
                    (postBoundingBox.bottom > this.props.scrollMidLine)
                );
                //console.log("NationalParkPost / componentDidUpdate - this.props.scrollMidLine = ", this.props.scrollMidLine);

                if (inViewport !== this.state.inViewport) {
                    // Update in viewport
                    this.setState({
                        inViewport: inViewport
                    });

                    // Update hovered if in viewport
                    if (inViewport) {
                        //console.log("NationalParkPost / componentDidUpdate - hovered = ", this.props.itemIndex);
                        this.props.setState({
                            hovered: this.props.itemIndex
                        });
                    }
                    else {
                        if (this.props.selected === this.props.itemIndex) {
                            this.props.setState({
                                selected: null
                            });
                        }
                    }
                }
            }
        }

        /*
        // Feed is on
        if ((prevProps.feedOn === false) && (this.props.feedOn === true)) {
            // Stylize curations
            this.updateCurationStyle(this.props.dotInfo);

            //console.log("DotsHomeItem / componentDidUpdate - turning on video");
            setTimeout(
                () => {
                    this.setState({
                        feedVideoOn: true
                    });
                },
                250
            );
        }

        // Feed is off
        else if ((prevProps.feedOn === true) && (this.props.feedOn === false)) {
            this.setState({
                feedVideoOn: false
            });
        }
        */

        // Update like
        if ((this.props.newLike.id !== prevProps.newLike.id) ||
            ((this.props.newLike.id === prevProps.newLike.id) && (this.props.newLike.liked !== prevProps.newLike.liked))) {
            if (this.props.dotInfo.id === this.props.newLike.id) {
                //console.log("DotsHomeItem / componentDidUpdate - updating new like");
                //console.log("DotsHomeItem / componentDidUpdate - this.props.newLike = ", this.props.newLike);

                this.setState({
                    likedByMe: this.props.newLike.liked,
                    likeCount: this.props.newLike.count
                });
            }
        }

        // Update save
        if ((this.props.newSave.id !== prevProps.newSave.id) ||
            ((this.props.newSave.id === prevProps.newSave.id) && (this.props.newSave.saved !== prevProps.newSave.saved))) {
            if (this.props.dotInfo.id === this.props.newSave.id) {
                //console.log("DotsHomeItem / componentDidUpdate - updating new save");
                //console.log("DotsHomeItem / componentDidUpdate - this.props.newSave = ", this.props.newSave);

                this.setState({
                    savedByMe: this.props.newSave.saved,
                    saveCount: this.props.newSave.count
                });
            }
        }
    }


    /*
    ============================================================================================
        Media Navigation Functions
    ============================================================================================
    */

    nextMediaClick(event) {
        event.stopPropagation();

        const newSelectedMediaIndex = this.state.selectedMediaIndex + 1;

        // Update state
        this.setState(
            {
                selectedMediaIndex: newSelectedMediaIndex
            }
        );
    }

    prevMediaClick(event) {
        event.stopPropagation();

        const newSelectedMediaIndex = this.state.selectedMediaIndex - 1;

        // Update state
        this.setState(
            {
                selectedMediaIndex: newSelectedMediaIndex
            }
        );
    }

    navDotClick(event, mediaIndex) {
        event.stopPropagation();

        // Update state
        this.setState({
            selectedMediaIndex: mediaIndex
        });
    }


    /*
    ============================================================================================
        More and Share Button Click Functions
    ============================================================================================
    */

    moreButtonClick(event) {
        //console.log("moreButtonClick / this.state.moreMenuOn", this.state.moreMenuOn);

        // Stop propagation
        event.stopPropagation();

        if(this.state.moreMenuOn) {
            this.setState({
                moreMenuOn: false
            });
        }
        else {
            this.setState({
                moreMenuOn: true
            });
        }
    }

    shareButtonClick(event) {
        // Stop propagation
        event.stopPropagation();

        this.props.storeShare({
            modalOn: true,
            type: "dot",
            info: this.props.dotInfo
        });
    }

    toggleExpanded(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            expanded: !this.state.expanded
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log("NationalParkPost / getDeriveStateFromProps - nextProps = ", nextProps);
        //console.log("NationalParkPost / getDeriveStateFromProps - prevState = ", prevState);

        let likedByMe, savedByMe;

        // User logged out
        if ((prevState.likedByMe !== null) && (nextProps.dotLiked === null)) {
            likedByMe = null;
        }
        // User logged in
        else if ((prevState.likedByMe === null) && (nextProps.dotLiked !== null)) {
            likedByMe = nextProps.dotLiked;
        }
        // Else
        else {
            likedByMe = prevState.likedByMe;
        }

        // User logged out
        if ((prevState.savedByMe !== null) && (nextProps.dotSaved === null)) {
            savedByMe = null;
        }
        // User logged in
        else if ((prevState.savedByMe === null) && (nextProps.dotSaved !== null)) {
            savedByMe = nextProps.dotSaved;
        }
        // Else
        else {
            savedByMe = prevState.savedByMe;
        }

        return {
            likedByMe: likedByMe,
            savedByMe: savedByMe
        }
    }

}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        newLike: state.interaction.newLike,
        newSave: state.interaction.newSave,
        create: state.create.create
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeRefresh,
            storeLogInOn,
            storeLogInSimpleOn,
            storeSignUpOn,
            storeWarningAlert,
            storeNotificationAlert,
            storeUser,
            storeMore,
            storeNewLike,
            storeNewSave,
            storeShare,
            storeCreate
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NationalPark);