/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Itinerary.js
    - Home page of an itinerary
    - Use Dayinfo / Map / ItineraryOverview components
--------------------------------------------------------------------------------------------
    Content
    - Itinerary
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Modules
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import InputRange from "thedots-input-range";
import ReactTooltip from "thedots-tooltip";

// Components
import { initTime } from "js/Time";
import ItineraryOverview from "./ItineraryOverview";
import { DayInfo } from "components/DayInfo";
import SaveBucket from "components/SaveBucket";
//import { OpenMap, GoogleMap, getAverageLocation } from "components/Map";
import { UserProfilePicList } from "js/Common";
import Participant from "components/Participant";
//import Board from "components/Board";

// Axios
import { 
    getItinerary,
    postParticipant,
    //postRouteItinerary
    patchRouteItinerary,
    //getSingleBoardComments
} from "requests";

// Websocket
import {
    addWebSocketGroups,
    removeWebSocketGroups
} from "js/WebSocketFunctions";

// Redux
import {
    storeItinerary,
    storeItineraryParams,
    storeWarningAlert,
    storeUser,
    storeSchedule,
    storeParticipant,
    storeNewParticipant,
    storeMore
} from "actions";

import {
    dotIDsToIndices,
    updateTimes,
    updateTimesSetState, 
    updateDate,
    updateDayInfo,
    resetAnimations,    
    dotClick,
    dotHoverOn,
    dotHoverOff,
    endHoverOn,
    endHoverOff
} from "js/TripFunctions";

import { fetchWeather } from "components/DayInfo";

import {
    timeSliderOnChange,
    timeSliderOnChangeComplete,
    timeSliderFormatLabel,
    //showItineraryTimeWarning
} from "js/RouteFunctions";

import { 
    getStaticPath,
    getDateShortText,
    //getMediaProperty,
    formatRouteDuration,
    formatNumbers,
    debounce
} from "js/Functions";

// CSS
import "./Itinerary.scss";

// Response codes
//import { responseResultCodes } from "ResponseCodes";


// Itinerary component
class Itinerary extends Component {
    // Constructor receives itineraryID as the prop
    constructor (props) {
        super (props);
        //console.log("Itinerary / constructor - props = ", props);
        //console.log("Itinerary / constructor - this.props.itineraryInfo = ", this.props.itineraryInfo);
        //console.log("Itinerary / constructor - this.props.itineraryParams = ", this.props.itineraryParams);

        // Map Mode
        this.mapSource = "google";
        this.mapMode = "itinerary";

        // Guests
        this.numGuestProfilePicsMobile = 5;
        this.numGuestProfilePics = 5;

        // Minimum and maximum container width
        //this.minContentWidth = 600;
        //this.maxContentWidth = 900;

        // Map settings
        this.mapZoom = 10;
        this.mapHeight = 280;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // User ratings / difficulties settings
        this.numThresholdRatings = 5;
        this.numThresholdDifficulties = 5;

        // Map DOM IDs
        this.mapNodeID = "map";
        this.mapContainerNodeID = "map-container";
        this.mapButtonsNodeID = "map-buttons";

        // Weather on
        //this.showWeather = false;

        // Day info on
        const dayInfoOn = (this.props.browserWidth <= 5)? false : true;

        // WebSocket Prefix
        this.webSocketGroupPrefix = "itinerary";

        // Load from global
        this.preloaded = (this.props.itineraryInfo !== null && this.props.itineraryInfo !== undefined)? (
            (this.props.itineraryInfo.id === this.props.itineraryID)? true : false) : false;
        //console.log("Itinerary / constructor - this.preloaded = ", this.preloaded);

        // Timezones and moments
        let routeInfo = null;
        let timezone = null;
        let startTimezone = null;
        let endTimezone = null;
        let startMoment = null;
        let endMoment = null;
        let actualEndMoment = null;
        if (this.preloaded) {
            routeInfo = (this.props.itineraryInfo.dot.type === "RT")?
                this.props.itineraryInfo.dot : null;
            timezone = this.props.itineraryParams.timezone;
            startTimezone = this.props.itineraryParams.startTimezone;
            endTimezone = this.props.itineraryParams.endTimezone;
            startMoment = moment(this.props.itineraryInfo.start_time).tz(startTimezone);
            endMoment = moment(this.props.itineraryInfo.planned_end_time).tz(endTimezone);
            actualEndMoment = moment(this.props.itineraryInfo.end_time).tz(endTimezone);
        }
        //console.log("Itinerary / constructor - routeInfo = ", routeInfo);

        // Initialize states
        this.state = {
            // Route info
            routeInfo: routeInfo,

            // Route plan
            routePlanOn: false,

            // Day info on
            dayInfoOn: dayInfoOn,

            // Itinerary information
            //itineraryInfo: null,
            itinerary: (this.preloaded)? dotIDsToIndices(
                this.props.itineraryInfo.route,
                this.props.itineraryInfo.dot.trip_extension.children
            ) : null,

            // Comments info
            //commentsInfo: null,

            // Locations and timezones
            location: (this.preloaded)? this.props.itineraryParams.location : null,
            timezone: timezone,
            startLocation: (this.preloaded)? this.props.itineraryParams.startLocation : null,
            endLocation: (this.preloaded)? this.props.itineraryParams.endLocation : null,
            startLocationName: (this.preloaded)? this.props.itineraryParams.startLocationName : null,
            endLocationName: (this.preloaded)? this.props.itineraryParams.endLocationName : null,
            startTimezone: startTimezone,
            endTimezone: endTimezone,

            // Highlight dots (indices of highlights in the dotsInfo list)
            highlightIndices: null,

            // Times
            startMoment: startMoment,
            endMoment: endMoment,
            actualEndMoment: actualEndMoment,
            plannedStartMoment: startMoment,
            plannedEndMoment: endMoment,
            plannedActualEndMoment: actualEndMoment,

            // Sun times
            plannedSunTimes: null,
            plannedMoonTimes: null,
            plannedDayTime: null,
            plannedOffsetTime: null,
            plannedTotalTime: null,
            plannedStartEndTimeValues: null,
            plannedHour00Moment: null,
            plannedHour24Moment: null,
            sunTimes: null,
            moonTimes: null,
            dayTime: null,
            offsetTime: null,
            totalTime: null,
            startEndTimeValues: null,
            hour00Moment: null,
            hour24Moment: null,

            // Astronomy
            plannedSunMoonOn: (this.preloaded)? this.props.itineraryParams.sunMoonOn : true,
            plannedMoonPhase: (this.preloaded)? this.props.itineraryParams.moonPhase : null,
            plannedMoonPhaseDigits: (this.preloaded)? this.props.itineraryParams.moonPhaseDigits : null,
            sunMoonOn: (this.preloaded)? this.props.itineraryParams.sunMoonOn : true,
            moonPhase: (this.preloaded)? this.props.itineraryParams.moonPhase : null,
            moonPhaseDigits: (this.preloaded)? this.props.itineraryParams.moonPhaseDigits : null,

            // Weather
            plannedWeather: (this.preloaded)? this.props.itineraryParams.weather : null,
            plannedWeatherOn: (this.preloaded)? this.props.itineraryParams.weatherOn : true,
            plannedWeatherIcon: (this.preloaded)? this.props.itineraryParams.weatherIcon : null,
            plannedTempHigh: (this.preloaded)? this.props.itineraryParams.tempHigh : null,
            plannedTempLow: (this.preloaded)? this.props.itineraryParams.tempLow : null,
            plannedPrecipOn: (this.preloaded)? this.props.itineraryParams.precipOn : null,
            plannedPrecipType: (this.preloaded)? this.props.itineraryParams.precipType : null,
            plannedPrecipProb: (this.preloaded)? this.props.itineraryParams.precipProb : null,
            weather: (this.preloaded)? this.props.itineraryParams.weather : null,
            weatherOn: (this.preloaded)? this.props.itineraryParams.weatherOn : true,
            weatherIcon: (this.preloaded)? this.props.itineraryParams.weatherIcon : null,
            tempHigh: (this.preloaded)? this.props.itineraryParams.tempHigh : null,
            tempLow: (this.preloaded)? this.props.itineraryParams.tempLow : null,
            precipOn: (this.preloaded)? this.props.itineraryParams.precipOn : null,
            precipType: (this.preloaded)? this.props.itineraryParams.precipType : null,
            precipProb: (this.preloaded)? this.props.itineraryParams.precipProb : null,

            // Map switch 
            mapOn: true,

            // Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false,

            // Window width
            windowWidth: window.innerWidth,

            // Websocket Group
            webSocketGroup: null,

            // Scroll
            checkScroll: false
        };

        // Bind setState and onScroll functions
        this.setState = this.setState.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);

        // Bind load itinerary
        this.loadItineraryInfo = this.loadItineraryInfo.bind(this);

        // Bind dot click and hover function
        this.dotClick = dotClick.bind(this);
        this.dotHoverOn = dotHoverOn.bind(this);
        this.dotHoverOff = dotHoverOff.bind(this);
        this.endHoverOn = endHoverOn.bind(this);
        this.endHoverOff = endHoverOff.bind(this);

        // Bind date and time change related functions
        this.updateTimes = updateTimes.bind(this);
        this.updateTimesSetState = updateTimesSetState.bind(this);
        this.updateDate = updateDate.bind(this);
        this.updateDayInfo = updateDayInfo.bind(this);
        this.fetchWeather = fetchWeather.bind(this);
        this.syncDayInfo = this.syncDayInfo.bind(this);

        // Bind time slider functions
        this.timeSliderOnChange = timeSliderOnChange.bind(this);
        this.timeSliderOnChangeComplete = timeSliderOnChangeComplete.bind(this);
        this.timeSliderFormatLabel = timeSliderFormatLabel.bind(this);

        // Bind map animation function
        this.resetAnimations = resetAnimations.bind(this);

        // Bind interaction button functions
        this.shareButtonClick = this.shareButtonClick.bind(this);
        this.moreButtonClick = this.moreButtonClick.bind(this);

        // Bind click callbacks
        this.editItinerayClick = this.editItinerayClick.bind(this);
        this.goCancelButtonClick = this.goCancelButtonClick.bind(this);
        this.acceptInviteClick = this.acceptInviteClick.bind(this);
    }

    /* 
    ============================================================================================
        Main Render Function
    ============================================================================================
    */

    render() {
        // Only when itinerary and dot info are ready
        if (this.props.itineraryInfo !== null && this.state.startMoment !== null && this.state.sunTimes !== null) {
            // User logged in
            const loggedIn = (!!localStorage.token && this.props.userInfo !== null);
            const isAuthor = (loggedIn && this.props.userInfo.id === this.props.itineraryInfo.user.id);

            // Guest info
            let isGuest = false;
            let isInvited = false;
            let isApproved = false;
            let guestsInfo = [ this.props.itineraryInfo.user ];
            let numGuests = 1;
            if (loggedIn) {
                for (let i = 0; i < this.props.itineraryInfo.guests.length; i++) {
                    if (this.props.userInfo.id === this.props.itineraryInfo.guests[i].id) {
                        isGuest = true;
                        if (this.props.itineraryInfo.guests[i].is_approved) {
                            isApproved = true;
                        }
                        if (this.props.itineraryInfo.guests[i].is_invited) {
                            isInvited = true;
                        }
                    }
                    if (this.props.itineraryInfo.guests[i].is_approved) {
                        guestsInfo.push(this.props.itineraryInfo.guests[i]);
                        numGuests += 1;
                    }
                }
            }
            //console.log("Itinerary / render - this.props.itineraryInfo = ", this.props.itineraryInfo);
            //console.log("Itinerary / render - isGuest = ", isGuest);
            //console.log("Itinerary / render - isInvited = ", isInvited);
            //console.log("Itinerary / render - isApproved = ", isApproved);
            //console.log("Itinerary / render - guestsInfo = ", guestsInfo);

            // Layouts
            const layoutVertical = this.props.browserWidth <= 3;
            const layoutInline = this.props.itineraryInfo.guests.length <= 2;

            /*
            ============================================================================================
                Map
            ============================================================================================
            */

            /*
            let map = null;
            // Using Google maps
            if (this.mapSource === "google") {
                // Only when google object and locations are ready
                if (this.props.google) {
                    // Set the props for Map component
                    const mapProps = {
                        // Google
                        google: this.props.google,

                        // Map mode
                        mapMode: this.mapMode,

                        // DOM node IDs
                        mapNodeID: this.mapNodeID,
                        mapContainerNodeID : this.mapContainerNodeID,
                        buttonsNodeID: this.mapButtonsNodeID,
                        startInputNodeID: this.startInputNodeID,
                        endInputNodeID: this.endInputNodeID,

                        // Map height
                        mapHeight: this.mapHeight,
                        mapMinHeight: this.mapMinHeight,
                        mapMaxHeight: this.mapMaxHeight,
                        mapHeightIncrement: this.mapHeightIncrement,

                        // Dots
                        dotsInfo: this.props.itineraryInfo.dot.children,

                        // Itinerary
                        itinerary: dotIDsToIndices(
                            this.props.itineraryInfo.route,
                            this.props.itineraryInfo.dot.trip_extension.children
                        ),

                        // Selected / hovered / selectedChild / hoveredChild / displayChildren
                        selected: this.state.selected, 
                        hovered: this.state.hovered,
                        selectedChild: this.state.selectedChild, 
                        hoveredChild: this.state.hoveredChild,
                        displayChildren: null, 

                        // Start and end locations
                        startLocation: this.state.startLocation,
                        endLocation: this.state.endLocation,

                        // Action triggers 
                        mapZoomInAnimation: this.state.mapZoomInAnimation,
                        mapZoomOutAnimation: this.state.mapZoomOutAnimation,
                        mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
                        mapRefreshAnimation: this.state.mapRefreshAnimation,
                        resetAnimations: this.resetAnimations,

                        // Dot click and hover callback
                        dotClick: this.dotClick,
                        dotHoverOn: this.dotHoverOn,
                        dotHoverOff: this.dotHoverOff,
                        endHoverOn: this.endHoverOn,
                        endHoverOff: this.endHoverOff,

                        // Parse callbacks (modify top states)
                        setStartLocation: this.setStartLocation,
                        setEndLocation: this.setEndLocation,

                        // Map type
                        mapType: this.props.itineraryInfo.dot.map_type, 

                        // SetState
                        setState: this.setState
                    }
                    //console.log("Itinerary / render - mapProps = ", mapProps);

                    // Get the Map component
                    map = (<GoogleMap {...mapProps}/>);
                }
            }
            // Using Open Street Maps
            else if (this.mapSource === "open") {
                const averageLocation = getAverageLocation(
                    this.props.itineraryInfo.route,
                    this.props.itineraryInfo.dot.children
                );

                // Set the props for Map component
                const mapProps = {
                    // Google
                    google: null,

                    // Map mode
                    mapMode: this.mapMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    startInputNodeID: this.startInputNodeID,
                    endInputNodeID: this.endInputNodeID,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: this.props.itineraryInfo.dot.children,

                    // Itinerary
                    itinerary: dotIDsToIndices(
                        this.props.itineraryInfo.route,
                        this.props.itineraryInfo.dot.trip_extension.children
                    ),

                    // Selected / hovered / selectedChild / hoveredChild / displayChildren
                    selected: this.state.selected, 
                    hovered: this.state.hovered,
                    selectedChild: this.state.selectedChild, 
                    hoveredChild: this.state.hoveredChild,
                    displayChildren: null, 

                    // Start and end locations
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Dot click and hover callback
                    dotClick: this.dotClick,
                    //dotHoverOn: this.dotHoverOn,
                    //dotHoverOff: this.dotHoverOff,
                    //endHoverOn: this.endHoverOn,
                    //endHoverOff: this.endHoverOff,

                    // Parse callbacks (modify top states)
                    setStartLocation: this.setStartLocation,
                    setEndLocation: this.setEndLocation,

                    // Map center / zoom / type
                    mapCenter: averageLocation,
                    mapZoom: this.mapZoom,
                    mapType: "roadmap",

                    // SetState
                    setState: this.setState
                }
                //console.log("Itinerary / render - mapProps = ", mapProps);

                // Get the Map component
                map = (<OpenMap {...mapProps}/>);
            }

            const mapContianer = (
                <div className="body-narrow">
                    <div id = "itinerary-map-container">
                        <div id = "itinerary-map-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Map
                        </div>
                        <div id = "itinerary-map-wrapper"
                            className = {(this.props.colorMode === "day")?
                                "border-day" : "border-night"}
                        >
                            {map}
                        </div>
                    </div>
                </div>
            );
            */
            const mapContainer = null;


            /*
            ============================================================================================
                More Button
            ============================================================================================
            */

            const moreButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/more-black.png") :
                getStaticPath("/images/common/more-white.png");

            const moreButtonTooltipText = "Options";
            const moreButtonTooltipID = "itinerary-more-button-tooltip";

            const moreButtonTooltip = (
                <ReactTooltip 
                    id = {moreButtonTooltipID}
                    className = "tooltip-s2"
                    type = "dark"
                    place = "bottom" 
                    html = {true} 
                />
            );

            const moreButton = (
                <div id = "itinerary-more-button"
                    className = "image-button-base"
                    onClick = {() => { this.moreButtonClick(isAuthor); }}
                    style = {{ backgroundImage:  moreButtonImage }}
                    data-tip = {moreButtonTooltipText}
                    data-for = {moreButtonTooltipID}
                >
                </div>
            );


            /*
            ============================================================================================
                Itinerary Title
            ============================================================================================
            */

            const itineraryTitle = (
                <div id = "itinerary-title-container">
                    <div id = "itinerary-title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        {this.props.itineraryInfo.dot.title}
                    </div>

                    <div id ="itinerary-more-cotainer">
                        {moreButton}
                        {moreButtonTooltip}
                    </div>

                    <div id = "itinerary-area"
                        className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                    >
                        {this.props.itineraryInfo.dot.area}
                    </div>
                </div>
            );


            /*
            ============================================================================================
                DayInfo (weather / sun / moon)
            ============================================================================================
            */

            // Only when the sunTimes and moonPhase are ready ro render
            let dayInfo = null;
            if ((this.state.plannedSunTimes) && (this.state.plannedMoonPhase)) {
                const dayInfoProps = {
                    colorMode: this.props.colorMode,
                    weatherOn: this.state.plannedWeatherOn,
                    weatherIcon: this.state.plannedWeatherIcon,
                    precipOn: this.state.plannedPrecipOn,
                    precipType: this.state.plannedPrecipType,
                    precipProb: this.state.plannedPrecipProb,
                    tempHigh: this.state.plannedTempHigh,
                    tempLow: this.state.plannedTempLow,
                    sunTimes: this.state.plannedSunTimes,
                    sunMoonOn: this.state.plannedSunMoonOn,
                    moonIcon: this.state.plannedMoonIcon,
                    moonPhase: this.state.plannedMoonPhase,
                    moonPhaseDigits: this.state.plannedMoonPhaseDigits
                };
                //console.log("Itinerary / render - dayInfoProps = ", dayInfoProps);

                dayInfo = DayInfo(dayInfoProps);
            }
            else{
                dayInfo = null;
            }
            
            const itineraryDayInfo = (this.state.plannedStartMoment !== null)? (
                <div id = "itinerary-date-dayinfo-container">
                    <div id = "itinerary-date-dayinfo"
                        className = "clear-fix"
                    >
                        <div id = {(this.props.browserWidth <= 2)?
                                "itinerary-date-column-small" : "itinerary-date-column"}
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-blue" : "font-cabin blue"}
                            onClick = { () => { this.setState({ dayInfoOn: !this.state.dayInfoOn }); }}
                        >
                            {this.state.plannedStartMoment.format("MMM Do YYYY")}
                        </div>

                        {
                            (layoutVertical)? 
                                null : (
                                    <div id = "itinerary-spacer">
                                    </div>
                                )
                        }

                        {
                            (layoutVertical && !this.state.dayInfoOn)? 
                            null : (
                                <div id = "itinerary-dayinfo-column">
                                    <div id = "itinerary-dayinfo">
                                        {dayInfo}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            ) : null;


            /*
            ============================================================================================
                Time Slider
            ============================================================================================
            */

            let timeSlider;
            if ((this.state.sunTimes) && (this.state.startEndTimeValues)) {

                const sliderClassNames = (this.props.colorMode === "day")?
                {
                    inputRange: "input-range",
                    disabledInputRange: "input-range input-range--disabled",

                    slider: "input-range-slider-day",
                    sliderContainer: "input-range__slider-container",

                    track: "input-range-track-day input-range__track--background",
                    activeTrack: "input-range-track-day input-range-track-active-day",

                    labelContainer: "input-range-label-container-day",
                    maxLabel: "input-range__label input-range__label--max",
                    minLabel: "input-range__label input-range__label--min",
                    valueLabel: "input-range__label input-range__label--value"
                } : {
                    inputRange: "input-range",
                    disabledInputRange: "input-range input-range--disabled",

                    slider: "input-range-slider-night",
                    sliderContainer: "input-range__slider-container",

                    track: "input-range-track-night input-range__track--background",
                    activeTrack: "input-range-track-night input-range-track-active-night",

                    labelContainer: "input-range-label-container-night",
                    maxLabel: "input-range__label input-range__label--max",
                    minLabel: "input-range__label input-range__label--min",
                    valueLabel: "input-range__label input-range__label--value"            
                };

                //console.log("this.state.startEndTimeValues = ", this.state.startEndTimeValues);
                // Convert solar and reference times to millisecs (since 1970/01/01)
                const sunrise = this.state.sunTimes.sunrise.unix();
                const sunset = this.state.sunTimes.sunset.unix();
                const solarNoon = this.state.sunTimes.solarNoon.unix();
                const hour00 = this.state.hour00Moment.unix();
                const hour24 = this.state.hour24Moment.unix();

                // Calculate the position of the solar markers on the slider
                const sunriseWidth = (sunrise - hour00) / (hour24 - hour00) * 100 + "%";
                const sunsetWidth = (sunset - hour00) / (hour24 - hour00) * 100 + "%";
                const solarNoonWidth = (solarNoon - hour00) / (hour24 - hour00) * 100 + "%";

                // Icon image
                const timeSliderImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/common/time-black.png") :
                    getStaticPath("/images/common/time-white.png");

                // Set up time slider
                timeSlider = (
                    <div id = "route-time-slider-container">
                        <div id = "route-time-slider-icon" 
                            className = "image-contain"
                            data-tip = "Route Duration"
                            data-for = "route-time-slider-icon-tooltip"
                            style = {{ backgroundImage: timeSliderImage }}
                        >
                            {
                                (window.touchOn)? null :
                                (
                                    <ReactTooltip
                                        id = "route-time-slider-icon-tooltip"
                                        className = "tooltip-s2"
                                        type = "dark"
                                        place = "bottom"
                                    />
                                )
                            }
                        </div>
                        <div id = "route-time-slider">
                            <div className = {(this.props.colorMode === "day")? 
                                    "route-time-slider-dot-day" :
                                    "route-time-slider-dot-night"}
                                style = {{ left: sunriseWidth }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "route-time-slider-text-day" :
                                    "route-time-slider-text-night"}
                                style = {{ left: sunriseWidth }}
                            >
                                {"Sunrise"}
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "route-time-slider-dot-day" :
                                    "route-time-slider-dot-night"}
                                style = {{ left: solarNoonWidth }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "route-time-slider-text-day" :
                                    "route-time-slider-text-night"}
                                style = {{ left: solarNoonWidth }}
                            >
                                {"Solar Noon"}
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "route-time-slider-dot-day" :
                                    "route-time-slider-dot-night"}
                                style = {{ left: sunsetWidth }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "route-time-slider-text-day" :
                                    "route-time-slider-text-night"}
                                style = {{ left: sunsetWidth }}
                            >
                                {"Sunset"}
                            </div>
                            <InputRange
                                classNames = {sliderClassNames}
                                maxValue = {this.state.hour24Moment.unix()}
                                minValue = {this.state.hour00Moment.unix()}
                                value = {this.state.startEndTimeValues}
                                onChange = {this.timeSliderOnChange}
                                onChangeComplete = {(values) => { this.timeSliderOnChangeComplete(values, false); }}
                                formatLabel = {this.timeSliderFormatLabel}
                                showEndLabels = {false}
                                step = {300}
                            />
                        </div>
                    </div>
                );
            }
            else {
                timeSlider = null;
            }


            /*
            ============================================================================================
                Discussion Board
            ============================================================================================
            */

            const board = null;
            /*
            const board = (this.state.dotInfo !== null && this.state.commentsInfo !== null)?
            (
                <div id = "itinerary-board-container">
                    {
                        ((this.props.itineraryInfo.board.comment_count > 0) || (this.props.userInfo !== null))?
                        (
                            <div id = "itinerary-board-title"
                                className = {(this.props.colorMode === "day")? "k2" : "w2"}
                            >
                                Discussion
                            </div>
                        ) : null
                    }
                    <Board
                        key = {"itinerary-" + this.props.itineraryInfo.id + "-board-" + this.props.itineraryInfo.board.id}
                        typePrefix = {"itinerary"}
                        boardID = {this.props.itineraryInfo.board.id}
                        commentCount = {this.props.itineraryInfo.board.comment_count}
                        userInfo = {this.props.userInfo}
                        loggedIn = {loggedIn}
                        newComment = {this.props.newComment}
                        newReply = {this.props.newReply}
                        commentsInfo = {this.state.commentsInfo}
                        webSocketGroupPrefix = {this.webSocketGroupPrefix}
                    />
                </div>
            ) : null;
            */


            /*
            ============================================================================================
                Guests
            ============================================================================================
            */

            const guestsButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/group-light-blue.png") : 
                getStaticPath("/images/common/group-blue.png");

            const guestCount = (layoutInline)?
            null : (
                <div className = {(this.colorMode === "day")?
                    "itinerary-guest-count font-cabin black" :
                    "itinerary-guest-count font-cabin white"}
                >
                    {numGuests}
                </div>
            );

            const guestsButton = (
                <div className = {(layoutInline)? 
                        "itinerary-bottom-menu-button-inline" : 
                        "itinerary-bottom-menu-button"}
                    onClick = {
                        (isAuthor)?
                        (
                            () => {
                                this.props.storeParticipant(
                                    {
                                        modalOn: true
                                    }
                                );
                            }
                        ) : null
                    }
                >
                    <div id = {(this.props.browserWidth <= 3)?
                            "itinerary-guests-button-icon-small" :
                            "itinerary-guests-button-icon"}
                        className = "image-cover"
                        style = {{ backgroundImage: guestsButtonImage }}
                    >
                    </div>
                    <div id = "itinerary-guests-button-text"
                        className = {(this.props.browserWidth <= 3)?
                            (
                                (this.props.colorMode === "day")?
                                    "itinerary-guests-button-text k4" :
                                    "itinerary-guests-button-text w4"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "itinerary-guests-button-text k3" :
                                    "itinerary-guests-button-text w3"
                            )
                        }
                    >
                        Participants
                    </div>

                    {guestCount}

                </div>
            );


            // Guests
            //console.log("Itinerary / render - guestsInfo = ", guestsInfo);

            const guestListProps = {
                colorMode: this.props.colorMode,
                numProfilePics : (this.props.browserWidth <= 2)?
                    this.numGuestProfilePicsMobile : this.numGuestProfilePics,
                usersInfo: guestsInfo,
                userCount: guestsInfo.length,
                userTypeLabel: "Participants",
                userTypeLabelOn: false,
                classNamePrefix: "itinerary",
                classNameUserType: "guest"
            };

            const guestList = (guestsInfo.length > 0)?
            (
                (layoutInline)? 
                (
                    <div className = "itinerary-guests-container-inline">
                        <UserProfilePicList {...guestListProps} />
                    </div>
                ) : (
                    <div className = "itinerary-guests-container">
                        <UserProfilePicList {...guestListProps} />
                    </div>
                )
            ) : null;


            /*
            ============================================================================================
                Checklist
            ============================================================================================
            */

            const checklistButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/bag-light-blue.png") : 
                getStaticPath("/images/common/bag-blue.png");

            const checklistButton = (
                <div className = {(layoutInline)?
                        "itinerary-bottom-menu-button-inline" : 
                        "itinerary-bottom-menu-button"}
                >
                    <div id = {(this.props.browserWidth <= 3)?
                            "itinerary-checklist-button-icon-small" :
                            "itinerary-checklist-button-icon"}
                        className = "image-cover"
                        style = {{ backgroundImage: checklistButtonImage }}
                    >
                    </div>
                    <div id = "itinerary-checklist-button-text"
                        className = {(this.props.browserWidth <= 3)?
                            (
                                (this.props.colorMode === "day")?
                                    "itinerary-checklist-button-text k4" :
                                    "itinerary-checklist-button-text w4"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "itinerary-checklist-button-text k3" :
                                    "itinerary-checklist-button-text w3"
                            )
                        }
                    >
                        Gear Check
                    </div>
                </div>
            );

            const bottomMenu = (
                <div id = "itinerary-bottom-menu">
                    <div className = "itinerary-bottom-menu-column">
                        {guestsButton}
                        {guestList}
                    </div>

                    <div className = "itinerary-bottom-menu-column">
                        {checklistButton}
                    </div>
                </div>
            );


            /*
            ============================================================================================
                Itinerary Stats
            ============================================================================================
            */

            // Rating
            const ratingTitle = (this.props.itineraryInfo.dot.rated_user_count >= this.numThresholdRatings)? "Rating" : "Editor Rating";
            let rating = null;
            let ratingCount = null;

            if (this.props.itineraryInfo.dot.rated_user_count >= this.numThresholdRatings) {
                ratingCount= formatNumbers(this.props.itineraryInfo.dot.rated_user_count).toString() + " Users";
                rating = (
                    <div className = "itinerary-stats-number">
                        <div id = "itinerary-stats-rating"
                            className = "font-cabin blue"
                        >
                            {Number(this.props.itineraryInfo.dot.user_rating).toFixed(1)}
                        </div>
                        <div id = "itinerary-stats-rating-count"
                            className = "b4"
                        >
                            {ratingCount}
                        </div>
                    </div>
                );
            }
            else {
                rating = (
                    <div className = "itinerary-stats-number">
                        <div id = "itinerary-stats-rating"
                            className = "font-cabin blue"
                        >
                            {Number(this.props.itineraryInfo.dot.rating).toFixed(1)}
                        </div>
                    </div>
                );
            }

            // Difficulty
            let difficultyTitle = (this.props.itineraryInfo.dot.difficultied_user_count >= this.numThresholdDifficulties)? "Difficulty" : "Editor Difficulty";
            let difficulty = null;
            let difficultyCount = null;

            if (this.props.itineraryInfo.dot.difficultied_user_count >= this.numThresholdDifficulties) {
                difficultyCount= formatNumbers(this.props.itineraryInfo.dot.difficultied_user_count).toString() + " Users";
                difficulty = (
                    <div className = "itinerary-stats-number">
                        <div id = "itinerary-stats-difficulty"
                            className = "font-cabin dark-red"
                        >
                            {Number(this.props.itineraryInfo.user_difficulty).toFixed(1)}
                        </div>
                        <div id = "itinerary-stats-difficulty-count"
                            className = "dr4"
                        >
                            {difficultyCount}
                        </div>
                    </div>
                );
            }
            else {
                difficulty = (
                    <div className = "itinerary-stats-number">
                        <div id = "itinerary-stats-difficulty"
                            className = "font-cabin dark-red"
                        >
                            {Number(this.props.itineraryInfo.difficulty).toFixed(1)}
                        </div>
                    </div>
                );
            }

            // Duration
            let durationTitle = "Duration";
            let duration = null;

            duration = (
                <div className = "itinerary-stats-number">
                    <div id = "itinerary-stats-duration"
                        className = "font-cabin dark-gray"
                    >
                        {formatRouteDuration(this.props.itineraryInfo.time_value)}
                    </div>
                </div>
            );

            // Distance
            let distanceTitle = "Distance";
            let distance = null;

            distance = (
                <div className = "itinerary-stats-number">
                    <div id = "itinerary-stats-distance"
                        className = "font-cabin dark-gray"
                    >
                        {String(Number(this.props.itineraryInfo.distance).toFixed(1)) + " mi"}
                    </div>
                </div>
            );

            const itineraryStats = (true)? null : (
                <div id = "itinerary-stats">
                    <div id = "itinerary-stats-title"
                        className = "w2"
                    >
                        {(this.props.itineraryInfo.dot.type === "TR")? "Trip Numbers" : "Route Numbers"}
                    </div>
                    <div className = "itinerary-stats-block">
                        <div className = "itinerary-stats-title w4"
                            data-tip = "Test tooltip"
                            data-for = "itinerary-stats-rating-tooltip">
                            {ratingTitle}
                        </div>
                        {rating}
                    </div>

                    <div className = "itinerary-stats-block">
                        <div className = "itinerary-stats-title w4"
                            data-tip = "Test tooltip"
                            data-for = "itinerary-stats-difficulty-tooltip">
                            {difficultyTitle}
                        </div>
                        {difficulty}
                    </div>

                    {(this.props.itineraryInfo.dot.type === "TR")? null : (
                        <div className = "itinerary-stats-block">
                            <div className = "itinerary-stats-title w4"
                                data-tip = "Test tooltip"
                                data-for = "itinerary-stats-duration-tooltip">
                                {durationTitle}
                            </div>
                            {duration}
                        </div>
                    )}

                    {(this.props.itineraryInfo.dot.type === "TR")? null : (
                        <div className = "itinerary-stats-block">
                            <div className = "itinerary-stats-title w4"
                                data-tip = "Test tooltip"
                                data-for = "itinerary-stats-distance-tooltip">
                                {distanceTitle}
                            </div>
                            {distance}
                        </div>
                    )}
                </div>
            );


            /*
            ============================================================================================
                Save Bucket
            ============================================================================================
            */

            const saveBucket = (loggedIn && (this.props.userInfo !== null))?
            ( 
                <SaveBucket 
                    userInfo = {this.props.userInfo} 
                    setState = {this.setState}
                />
            ) : null;


            /*
            ============================================================================================
                Itinerary Overview
            ============================================================================================
            */

            // Set props for itinerary
            const itineraryOverviewProps = {
                // Itinerary dot indices
                itinerary: dotIDsToIndices(
                    this.props.itineraryInfo.route,
                    this.props.itineraryInfo.dot.trip_extension.children
                ),

                // Itinerary info
                itineraryInfo: this.props.itineraryInfo,

                // Itinerary info
                dotInfo: this.props.itineraryInfo.dot,

                // Dots information
                dotsInfo: this.props.itineraryInfo.dot.trip_extension.children,

                // User info
                userInfo: this.props.userInfo, 

                // Map source
                mapSource: this.mapSource,

                // Click and hover callbacks
                dotClick: this.dotClick,
                dotHoverOn: this.dotHoverOn, 
                dotHoverOff: this.dotHoverOff,

                // Set state callback
                setState: this.setState,

                // Redux fetch user info 
                storeUser: this.props.storeUser,

                // Start and end moments
                startMoment: this.state.startMoment,
                endMoment: this.state.endMoment,
                actualEndMoment: this.state.actualEndMoment
            };


            /*
            ============================================================================================
                Modify buttons
            ============================================================================================
            */

            const modifyButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/modify-light-blue.png") : 
                getStaticPath("/images/common/modify-blue.png");

            const modifyButton = (isAuthor)? (
                <div className = {(this.props.browserWidth <= 3)?
                        "itinerary-main-menu-button-small" : 
                        "itinerary-main-menu-button"}
                    onClick = {this.editItinerayClick}
                >
                    <div id = {(this.props.browserWidth <= 3)?
                            "itinerary-modify-button-icon-small" :
                            "itinerary-modify-button-icon"}
                        className = "image-contain"
                        style = {{ backgroundImage: modifyButtonImage }}
                    >
                    </div>
                    <div id = "itinerary-modify-button-text"
                        className = {(this.props.browserWidth <= 3)?
                            (
                                (this.props.colorMode === "day")?
                                    "itinerary-modify-button-text k4" :
                                    "itinerary-modify-button-text w4"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "itinerary-modify-button-text k3" :
                                    "itinerary-modify-button-text w3"
                            )
                        }
                    >
                        {
                            (this.state.routePlanOn)?
                                "Update Itinerary" : "Modify"
                        }
                    </div>
                </div>
            ) : null;


            /*
            ============================================================================================
                Accept Invite Button
            ============================================================================================
            */

            const acceptInviteButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/accept-light-blue.png") :
                getStaticPath("/images/common/accept-blue.png");

            const acceptInviteButton = (isGuest && isInvited && !isApproved)? (
                <div className = {(this.props.browserWidth <= 3)?
                        "itinerary-main-menu-button-small" : 
                        "itinerary-main-menu-button"}
                    onClick = {this.acceptInviteClick}
                >
                    <div id = {(this.props.browserWidth <= 3)?
                            "itinerary-modify-button-icon-small" :
                            "itinerary-modify-button-icon"}
                        className = "image-contain"
                        style = {{ backgroundImage: acceptInviteButtonImage }}
                    >
                    </div>
                    <div id = "itinerary-modify-button-text"
                        className = {(this.props.browserWidth <= 3)?
                            (
                                (this.props.colorMode === "day")?
                                    "itinerary-modify-button-text k4" :
                                    "itinerary-modify-button-text w4"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "itinerary-modify-button-text k3" :
                                    "itinerary-modify-button-text w3"
                            )
                        }
                    >
                        Accept Invite
                    </div>
                </div>
            ) : null;


            /*
            ============================================================================================
                Schedule Button
            ============================================================================================
            */

            // Schedule button
            const scheduleImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/guide-light-blue.png") :
                getStaticPath("/images/common/guide-blue.png")

            const scheduleButton = (!this.state.routePlanOn)? (
                <div className = {(this.props.browserWidth <= 3)?
                        "itinerary-main-menu-button-small" : 
                        "itinerary-main-menu-button"}
                    onClick = {
                        () => {
                            this.props.storeSchedule({
                                modalOn: true,
                                //mode: null,
                                //id: this.props.itineraryInfo.id,
                                //info: this.props.itineraryInfo,
                                //dot: this.props.itineraryInfo.dot.id,
                                //size: (this.props.itineraryInfo.dots.length + 2)
                            });
                        }
                    }                        
                >
                    <div id = {(this.props.browserWidth <= 3)?
                            "itinerary-schedule-button-icon-small" :
                            "itinerary-schedule-button-icon"}
                        className = {(this.props.colorMode === "day")? 
                            "image-button-strong-base" :
                            "image-button-strong-base"}
                        style = {{ backgroundImage: scheduleImage }}
                    >
                    </div>

                    <div id = "itinerary-schedule-button-text"
                        className = {(this.props.browserWidth <= 3)?
                            (
                                (this.props.colorMode === "day")?
                                    "itinerary-modify-button-text k4" :
                                    "itinerary-modify-button-text w4"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "itinerary-modify-button-text k3" :
                                    "itinerary-modify-button-text w3"
                            )
                        }
                    >
                        Virtual Tour
                    </div>

                </div>
            ) : null;


            /*
            ============================================================================================
                Route Plan
            ============================================================================================
            */

            // Cancel image
            const goCancelButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/cancel-black.png") : 
                getStaticPath("/images/common/cancel-white.png");

            // Calendar icon
            const routeCalendarIconImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/calendar-black.png") :
                getStaticPath("/images/common/calendar-white.png");

            // Calendar
            let routeCalendar = null;
            if ((this.state.startMoment) && (this.state.endMoment)) {
                //console.log("Itinerary / render - this.state.startMoment = ", this.state.startMoment.format());
                //console.log("Itinerary / render - this.state.endMoment = ", this.state.endMoment.format());

                // calendar object
                routeCalendar = (
                    <div id = "itinerary-route-calendar-container">
                        <div id = "itinerary-route-calendar-icon"
                            className = "image-contain"
                            data-tip = "Travel Date"
                            data-for = "itinerary-route-calendar-icon-tooltip"
                            style = {{ backgroundImage: routeCalendarIconImage }}
                        >
                            {
                                (window.touchOn)? null :
                                (
                                    <ReactTooltip
                                        id = "itinerary-route-calendar-icon-tooltip"
                                        className = "tooltip-s2"
                                        type = "dark"
                                        place = "bottom"
                                    />
                                )
                            }
                        </div>
                        <div id = "itinerary-route-calendar"
                            data-tip = "Click to Change Date"
                            data-for = "itinerary-route-calendar-input-tooltip">
                            <DatePicker
                                dateFormat = "MMM D YYYY"
                                className = {(this.props.colorMode === "day")?
                                    "itinerary-route-calendar-input-day font-cabin" :
                                    "itinerary-route-calendar-input-night font-cabin"}
                                selected = {this.state.startMoment}
                                onChange = {this.updateDate}
                                readOnly = {true}
                            />
                            {
                                (window.touchOn)? null :
                                (
                                    <ReactTooltip
                                        id = "itinerary-route-calendar-input-tooltip"
                                        className = "tooltip-s2"
                                        type = "dark"
                                        place = "bottom"
                                    />
                                )
                            }
                        </div>
                    </div>
                );
                //console.log("Itinerary / render - calendar = ", calendar);                
            }
            else {
                routeCalendar = null;
            }

            // Only when the sunTimes and moonPhase are ready ro render
            let routeDayInfo = null;
            if ((this.state.sunTimes) && (this.state.moonPhase)) {
                const routeDayInfoProps = {
                    colorMode: this.props.colorMode,
                    weatherOn: this.state.weatherOn,
                    weatherIcon: this.state.weatherIcon,
                    precipOn: this.state.precipOn,
                    precipType: this.state.precipType,
                    precipProb: this.state.precipProb,
                    tempHigh: this.state.tempHigh,
                    tempLow: this.state.tempLow,
                    sunTimes: this.state.sunTimes,
                    sunMoonOn: this.state.sunMoonOn,
                    moonPhase: this.state.moonPhase,
                    moonPhaseDigits: this.state.moonPhaseDigits,
                }
                routeDayInfo = DayInfo(routeDayInfoProps);
                //console.log("Itinerary / render - dayInfo = ", dayInfo);
            }
            else{
                routeDayInfo = null;
            }

            const routePlan = (this.props.itineraryInfo.dot.type === "RT" && this.state.routePlanOn)? (
                <div id = "itinerary-route-go-info-container">
                    <div id = "itinerary-route-go-info"
                    >
                        <div id = "itinerary-route-go-cancel-button"
                            className = {(this.props.colorMode === "day")? 
                                "image-button-weaker-s3" :
                                "image-button-weaker-s3"}
                            style = {{ backgroundImage : goCancelButtonImage }}
                            onClick = {this.goCancelButtonClick}
                        >
                        </div>
                        <div id = "itinerary-route-calendar-dayinfo-container">
                            <div id = "itinerary-route-calendar-dayinfo">
                                <div id = "itinerary-route-calendar-column">
                                    <div id = "itinerary-route-calendar-title"
                                        className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                    >
                                        Travel Date
                                    </div>
                                    {routeCalendar}
                                </div>
                                <div id = "itinerary-route-dayinfo-column">
                                    <div id = "itinerary-route-dayinfo">
                                        <div id = "itinerary-route-dayinfo-title"
                                            className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                        >
                                            Weather and Astronomy
                                        </div>
                                        {routeDayInfo}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id = "itinerary-route-time">
                            <div id = "itinerary-route-time-slider-title"
                                className = {(this.props.colorMode === "day")? "k3" : "w3"}
                            >
                                Route Duration
                            </div>
                            {timeSlider}
                        </div>
                    </div>
                </div>
            ) :  null;
            //console.log("Itinerary / render - routePlan = ", routePlan);


            /*
            ============================================================================================
                Body
            ============================================================================================
            */

            return (
                <div id = "itinerary-container"
                    className = {(this.props.browserWidth <= window.browserWidthSmall)? 
                        "itinerary-container-small" : "itinerary-container"}
                >
                    {saveBucket}

                    <div className = "body-narrow">
                        {itineraryDayInfo}
                    </div>

                    <div className = "body-narrow">
                        <div id = "itinerary-header">
                            {itineraryTitle}
                        </div>
                    </div>

                    <div className = "body-narrow">
                        {bottomMenu}
                    </div>

                    <div className = "body-narrow">
                        <ItineraryOverview {...itineraryOverviewProps} />
                    </div>

                    {mapContainer}

                    <div className = "body-narrow">
                        {itineraryStats}
                    </div>

                    <div className = "body-narrow">
                        {board}
                    </div>


                    <div id = "itinerary-main-menu"
                        className = {(this.props.colorMode === "day")? 
                            "itinerary-main-menu-day" :
                            "itinerary-main-menu-night"}
                    >
                        {routePlan}                    
                        {scheduleButton}
                        {modifyButton}
                        {acceptInviteButton}
                    </div>

                    <Participant 
                        key = {"itinerary-participant-modal-on-" + this.props.participant.modalOn}
                    />
                </div>
            )
        }
        else{
            return null
        }
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentWillUnmount() {
        // Reset itinerary infp
        //this.props.storeItinerary(null);

        // Remove event listeners
        window.removeEventListener("scroll", this.scrollListener);

        // Remove relevant webSocket group
        removeWebSocketGroups(this.state.webSocketGroup, window.webSocket);
    }

    componentDidMount() {
        //console.log("Itinerary / componentDidMount - itineraryID = ", this.props.itineraryID);

        // Get itinerary and dot info from the server
        if (this.preloaded) {
            //console.log("Itinerary / componentDidMount - this.props.itineraryInfo = ", this.props.itineraryInfo);

            // Initialize Time object
            initTime(this.props.itineraryInfo.dot.dot_extension.time_value);

            this.updateDayInfo(
                false,
                false,
                this.syncDayInfo
            );
        }
        else {
            this.loadItineraryInfo(this.props.itineraryID);
        }

        // Add relevant webSocket group
        let webSocketGroup = [];
        webSocketGroup.push(this.webSocketGroupPrefix + "_" + this.props.itineraryID);
        addWebSocketGroups(webSocketGroup, window.webSocket);

        // Websocket
        this.setState({
            webSocketGroup: webSocketGroup
        });

        // Add event listeners
        window.addEventListener("scroll", this.scrollListener);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.browserWidth !== this.props.browserWidth && this.props.browserWidth <= 5) {
            this.setState({ dayInfoOn: false });
        }

        if (prevProps.newParticipant === null && this.props.newParticipant !== null) {
            //console.log("Itinerary / componentDidUpdate - this.props.newParticipant = ", this.props.newParticipant);
            if (this.props.newParticipant.itineraryID === this.props.itineraryInfo.id) {
                const itineraryInfo = Object.assign({}, this.props.itineraryInfo);
                const guestsInfo = itineraryInfo.guests.slice();

                if (this.props.newParticipant.operation === "add") {
                    guestsInfo.push(this.props.newParticipant.user);
                }
                else if (this.props.newParticipant.operation === "remove") {
                    let indexToRemove = null;
                    for (let i = 0; i < guestsInfo.length; i++) {
                        if (guestsInfo[i].id === this.props.newParticipant.user.id) {
                            indexToRemove = i;
                        }
                    }
                    guestsInfo.splice(indexToRemove, 1);
                }
                else if (this.props.newParticipant.operation === "approve") {
                    let indexToApprove = null;
                    for (let i = 0; i < guestsInfo.length; i++) {
                        if (guestsInfo[i].id === this.props.newParticipant.user.id) {
                            indexToApprove = i;
                        }
                    }
                    guestsInfo[indexToApprove] = this.props.newParticipant.user;
                }

                // Update guests
                itineraryInfo.guests = guestsInfo;

                // Update itinerary
                this.props.storeItinerary(itineraryInfo);

                // Reset new participant
                this.props.storeNewParticipant(null);
            }
        }
    }


    /*
    ============================================================================================
        Share Button Click Functions
    ============================================================================================
    */

    shareButtonClick() {
        /*
        const shareInfo = {
            id: this.props.itineraryInfo.id, 
            title: this.props.itineraryInfo.dot.name, 
            description: this.props.itineraryInfo.dot.overview
        };

        this.props.storeShareItinerary({ modal: true, from: "itinerary", id: this.props.itineraryInfo.id, info: shareInfo });
        */
    }


    /*
    ============================================================================================
        More Button Click Functions
    ============================================================================================
    */

    moreButtonClick(isAuthor) {
        this.props.storeMore({
            modalOn: true,
            type: "itinerary",
            id: this.props.itineraryInfo.id,
            info: this.props.itineraryInfo,
            isAuthor: isAuthor
        });
    }


    /*
    ============================================================================================
        Load Itinerary Info
    ============================================================================================
    */

    loadItineraryInfo(itineraryID) {
        //console.log("Itinerary / loadItineraryInfo - itineraryID = ", itineraryID);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            console.log("Itinerary / loadItineraryInfo - response.data.content = ", response.data.content);

            // Initialize time object
            initTime(response.data.content.dot.dot_extension.time_value);

            // Get timezones
            const timezone = response.data.content.dot.timezone;
            const startTimezone = response.data.content.start_timezone;
            const endTimezone = response.data.content.end_timezone;

            // Get start and end moments
            const startMoment = moment(response.data.content.start_time).tz(startTimezone);
            const endMoment = moment(response.data.content.planned_end_time).tz(endTimezone);
            const actualEndMoment = moment(response.data.content.end_time).tz(endTimezone);
            //console.log("Itinerary / loadItineraryInfo - startMoment = ", startMoment);
            //console.log("Itinerary / loadItineraryInfo - endMoment = ", endMoment);
            //console.log("Itinerary / loadItineraryInfo - plannedEndMoment = ", plannedEndMoment);

            // Locations
            const location = response.data.content.dot.location;
            const startLocation = response.data.content.start_location;
            const endLocation = response.data.content.end_location;
            const startLocationName = response.data.content.start_location_name;
            const endLocationName = response.data.content.end_location_name;

            // Itinerary dot indices
            const itinerary = dotIDsToIndices(
                response.data.content.route,
                response.data.content.dot.trip_extension.children
            );
            //console.log("Itinerary / render - itinerary = ", itinerary);

            /*
            // Reserve for itinerary-specific board
            // Fetch board comments 
            this.loadCommentsInfo(response.data.content.board.id);
            */

            // Callback after retrieving the itinerary info
            const afterLoadingCallback = () => {
                //console.log("Itinerary / loadItineraryInfo - this.state = ", this.state);

                // Update itinerary
                this.props.storeItinerary(response.data.content);

                // Update times
                this.updateDayInfo(
                    true,
                    false,
                    () => {
                        this.props.storeItineraryParams({
                            // Locations
                            location: this.state.location,
                            startLocation: this.state.startLocation,
                            endLocation: this.state.endLocation,            
                            startLocationName: this.state.startLocationName,
                            endLocationName: this.state.endLocationName,            
                            timezone: this.state.timezone,
                            startTimezone: this.state.startTimezone,
                            endTimezone: this.state.endTimezone,

                            // Start and end times
                            //startMoment: this.state.startMoment,
                            //endMoment: this.state.endMoment,
                            //actualEndMoment: this.state.actualEndMoment,

                            // Dot times
                            dotTimes: this.props.itineraryInfo.dot_start_times_local_formatted,

                            // Transit times and modes
                            transitTimes: this.props.itineraryInfo.transit_time,
                            transitTimeValues: this.props.itineraryInfo.transit_time_value,
                            transitModes: this.props.itineraryInfo.transit_mode,

                            // Day times
                            //sunTimes: this.state.sunTimes,
                            //moonTimes: this.state.moonTimes,
                            //dayTime: this.state.dayTime,
                            //offsetTime: this.state.offsetTime,
                            //totalTime: this.state.totalTime,
                            //startEndTimeValues: this.state.startEndTimeValues,
                            //hour00Moment: this.state.hour00Moment,
                            //hour24Moment: this.state.hour24Moment,

                            // Astronomy
                            //sunMoonOn: this.state.sunMoonOn,
                            //moonPhase: this.state.moonPhase,
                            //moonPhaseDigits: this.state.moonPhaseDigits,

                            // Weather
                            weather: this.state.weather,
                            weatherOn: this.state.weatherOn,
                            weatherIcon: this.state.weatherIcon,
                            tempHigh: this.state.tempHigh,
                            tempLow: this.state.tempLow,
                            precipOn: this.state.precipOn,
                            precipType: this.state.precipType,
                            precipProb: this.state.precipProb
                        });

                        // Sync day info
                        this.syncDayInfo();
                    }
                );
            };
            //console.log("Itinerary / loadItineraryInfo - this.props.itineraryInfo.dot = ", this.props.itineraryInfo.dot);

            // Set states and check if the logged in user follows the editor
            this.setState(
                {
                    // Route info
                    routeInfo: (response.data.content.dot.type === "RT")? response.data.content.dot : null,

                    // Selected / hovered / selectedChild / hoveredChild
                    selected: null,
                    hovered: null,
                    selectedChild: null,
                    hoveredChild: null,
                    displayChildren: null,

                    // Itinerary information
                    //itineraryInfo: response.data.content,
                    itinerary: itinerary,
                    
                    // Locations and timezones
                    location: location,
                    timezone: timezone,
                    startLocation:  startLocation,
                    endLocation:  endLocation,
                    startLocationName: startLocationName,
                    endLocationName: endLocationName,
                    startTimezone: startTimezone,
                    endTimezone: endTimezone,

                    // Start and end times
                    startMoment: startMoment,
                    endMoment: endMoment,
                    actualEndMoment: actualEndMoment

                    /*
                    // Day times
                    sunTimes : null,
                    moonTimes : null,
                    dayTime : null,
                    offsetTime: null,
                    totalTime: null,
                    startEndTimeValues: null,
                    hour00Moment: null,
                    hour24Moment: null,

                    // Astronomy
                    sunMoonOn: false,
                    moonPhase: null,
                    moonIcon: null,

                    // Weather
                    weather: null,
                    weatherOn: false,
                    weatherIcon: null,
                    tempHigh: null,
                    tempLow: null,
                    precipOn: null,
                    precipType: null,
                    precipProb: null,
                    */
                },
                afterLoadingCallback
            );
        };

        // Send login request using axios get with CSRF token
        getItinerary(itineraryID)
        .then(axiosCallback)
        .catch((response) => { console.log("Itinerary / loadItineraryInfo - Axios error ", response); });
    }


    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    syncDayInfo() {
        this.setState({
            // Times
            plannedStartMoment: this.state.startMoment,
            plannedEndMoment: this.state.endMoment,
            plannedActualEndMoment: this.state.actualEndMoment,

            // Sun times
            plannedSunTimes: this.state.sunTimes,
            plannedMoonTimes: this.state.moonTimes,
            plannedDayTime: this.state.dayTime,
            plannedOffsetTime: this.state.offsetTime,
            plannedTotalTime: this.totalTime,
            plannedStartEndTimeValues: this.startEndTimeValues,
            plannedHour00Moment: this.state.hour00Moment,
            plannedHour24Moment: this.state.hour24Moment,

            // Astronomy
            plannedSunMoonOn: this.state.sunMoonOn,
            plannedMoonPhase: this.state.moonPhase,
            plannedMoonPhaseDigits: this.state.moonPhaseDigits,

            // Weather
            plannedWeather: this.state.weather,
            plannedWeatherOn: this.state.weatherOn,
            plannedWeatherIcon: this.state.weatherIcon,
            plannedTempHigh: this.state.tempHigh,
            plannedTempLow: this.state.tempLow,
            plannedPrecipOn: this.state.precipOn,
            plannedPrecipType: this.state.precipType,
            plannedPrecipProb: this.state.precipProb
        });
    }


    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }


    /*
    ============================================================================================
        Edit Itinerary Click
    ============================================================================================
    */

    editItinerayClick() {
        //console.log("Itinerary / editItinerayClick");
        /*
        console.log("Itinerary / editItinerayClick - startMoment = ", this.state.startMoment);
        console.log("Itinerary / editItinerayClick - endMoment = ", this.state.endMoment);
        console.log("Itinerary / editItinerayClick - sunTimes = ", this.state.sunTimes);
        console.log("Itinerary / editItinerayClick - moonTimes = ", this.state.moonTimes);
        console.log("Itinerary / editItinerayClick - dayTime = ", this.state.dayTime);
        console.log("Itinerary / editItinerayClick - offsetTime = ", this.state.offsetTime);
        console.log("Itinerary / editItinerayClick - totalTime = ", this.state.totalTime);
        console.log("Itinerary / editItinerayClick - startEndTimeValues = ", this.state.startEndTimeValues);
        console.log("Itinerary / editItinerayClick - hour00Moment = ", this.state.hour00Moment);
        console.log("Itinerary / editItinerayClick - hour24Moment = ", this.state.hour24Moment);
        */

        if (this.props.itineraryInfo.dot.type === "RT") {
            if (this.state.routePlanOn) {
                const axiosCallback = (response) => {
                    console.log("Itinerary / editItinerayClick - response.data.content = ", response.data.content);

                    // Initialize time object
                    initTime(response.data.content.dot.dot_extension.time_value);

                    // Get timezones
                    const timezone = response.data.content.dot.timezone;
                    const startTimezone = response.data.content.start_timezone;
                    const endTimezone = response.data.content.end_timezone;

                    // Get start and end moments
                    const startMoment = moment(response.data.content.start_time).tz(startTimezone);
                    const endMoment = moment(response.data.content.planned_end_time).tz(endTimezone);
                    const actualEndMoment = moment(response.data.content.end_time).tz(endTimezone);
                    //console.log("Itinerary / editItinerayClick - startMoment = ", startMoment);
                    //console.log("Itinerary / editItinerayClick - endMoment = ", endMoment);
                    //console.log("Itinerary / editItinerayClick - plannedEndMoment = ", plannedEndMoment);

                    // Locations
                    const location = response.data.content.dot.location;
                    const startLocation = response.data.content.start_location;
                    const endLocation = response.data.content.end_location;
                    const startLocationName = response.data.content.start_location_name;
                    const endLocationName = response.data.content.end_location_name;

                    // Itinerary dot indices
                    const itinerary = dotIDsToIndices(
                        response.data.content.route,
                        response.data.content.dot.trip_extension.children
                    );
                    //console.log("Itinerary / editItinerayClick - itinerary = ", itinerary);

                    // Callback after retrieving the itinerary info
                    const afterLoadingCallback = () => {
                        //console.log("Itinerary / editItinerayClick - this.state = ", this.state);

                        // Update itinerary
                        this.props.storeItinerary(response.data.content);

                        // Update times
                        this.updateDayInfo(
                            true,
                            false,
                            this.syncDayInfo
                        );
                    };

                    // Set states and check if the logged in user follows the editor
                    this.setState(
                        {
                            // Route plan on
                            routePlanOn: false,

                            // Route info
                            routeInfo: response.data.content.dot,

                            // Selected / hovered / selectedChild / hoveredChild
                            selected: null,
                            hovered: null,
                            selectedChild: null,
                            hoveredChild: null,
                            displayChildren: null,

                            // Itinerary information
                            //itineraryInfo: response.data.content,
                            itinerary: itinerary,

                            // Locations and timezones
                            location: location,
                            timezone: timezone,
                            startLocation:  startLocation,
                            endLocation:  endLocation,
                            startLocationName: startLocationName,
                            endLocationName: endLocationName,
                            startTimezone: startTimezone,
                            endTimezone: endTimezone,

                            // Start and end times
                            startMoment: startMoment,
                            endMoment: endMoment,
                            actualEndMoment: actualEndMoment

                            /*
                            // Day times
                            sunTimes : null,
                            moonTimes : null,
                            dayTime : null,
                            offsetTime: null,
                            totalTime: null,
                            startEndTimeValues: null,
                            hour00Moment: null,
                            hour24Moment: null,

                            // Astronomy
                            sunMoonOn: false,
                            moonPhase: null,
                            moonIcon: null,

                            // Weather
                            weather: null,
                            weatherOn: false,
                            weatherIcon: null,
                            tempHigh: null,
                            tempLow: null,
                            precipOn: null,
                            precipType: null,
                            precipProb: null,
                            */
                        },
                        afterLoadingCallback
                    );
                };

                // Clear itinerary warning
                this.props.storeWarningAlert({
                    message: null,
                    on: false
                });

                // Data
                const dataJSON = {
                    "itinerary_id": this.props.itineraryID,
                    "travel_date": getDateShortText(this.state.startMoment),
                    "start_time": this.state.startMoment.format("hh:mm")
                };

                // Create route itinerary
                patchRouteItinerary(this.props.itineraryID, dataJSON)
                .then(axiosCallback)
                .catch((response) => { console.log("Itinerary / editItinerayClick - Axios error ", response); });
            }
            else {
                this.setState({
                    routePlanOn: true
                });
            }
        }
        else {
            // Convert moments
            const startMoment = this.state.startMoment.format();
            const endMoment = this.state.endMoment.format();
            const actualEndMoment = this.state.actualEndMoment.format();
            const sunTimes = {
                sunrise: (this.state.sunTimes.sunrise === null)? null : this.state.sunTimes.sunrise.format(),
                sunset: (this.state.sunTimes.sunset === null)? null : this.state.sunTimes.sunset.format(),
                solarNoon: (this.state.sunTimes.solarNoon === null)? null : this.state.sunTimes.solarNoon.format()
            };
            const moonTimes = {
                moonrise: (this.state.moonTimes.moonrise === null)? null : this.state.moonTimes.moonrise.format(),
                moonset: (this.state.moonTimes.moonset === null)? null : this.state.moonTimes.moonset.format()
            };
            const dayTime = this.state.dayTime;
            const offsetTime = this.state.offsetTime;
            const totalTime = this.state.totalTime;
            const startEndTimeValues = this.state.startEndTimeValues;
            const hour00Moment = this.state.hour00Moment.format();
            const hour24Moment = this.state.hour24Moment.format();

            // States to be passed to plan page
            const planStates = {
                // Trip info
                tripInfo: this.props.itineraryInfo.dot,

                // ItineraryInfo
                itineraryInfo: this.props.itineraryInfo,

                // Locations
                startLocation: this.state.startLocation,
                endLocation: this.state.endLocation,            
                startLocationName: this.state.startLocationName,
                endLocationName: this.state.endLocationName,            
                location: this.state.location,
                startTimezone: this.state.startTimezone,
                endTimezone: this.state.endTimezone,
                timezone: this.state.timezone,

                // Start and end times
                startMoment: startMoment,
                endMoment: endMoment,
                actualEndMoment: actualEndMoment,

                // Dot times
                dotTimes: this.props.itineraryInfo.dot_start_times_local_formatted,

                // Transit times and modes
                transitTimes: this.props.itineraryInfo.transit_time,
                transitTimeValues: this.props.itineraryInfo.transit_time_value,
                transitModes: this.props.itineraryInfo.transit_mode,

                // Day times
                sunTimes: sunTimes,
                moonTimes: moonTimes,
                dayTime: dayTime,
                offsetTime: offsetTime,
                totalTime: totalTime,
                startEndTimeValues: startEndTimeValues,
                hour00Moment: hour00Moment,
                hour24Moment: hour24Moment,

                // Astronomy
                sunMoonOn: this.state.sunMoonOn,
                moonPhase: this.state.moonPhase,
                moonPhaseDigits: this.state.moonPhaseDigits,

                // Weather
                weather: this.state.weather,
                weatherOn: this.state.weatherOn,
                weatherIcon: this.state.weatherIcon,
                tempHigh: this.state.tempHigh,
                tempLow: this.state.tempLow,
                precipOn: this.state.precipOn,
                precipType: this.state.precipType,
                precipProb: this.state.precipProb
            };

            // Clear alert
            this.props.storeWarningAlert({
                message: null,
                on: false
            });

            // Redirect to plan page
            this.props.history.push(
                {
                    pathname: "/plan/" + this.props.itineraryInfo.id,
                    state: planStates
                }
            );
        }
    }


    /* 
    ============================================================================================
        Go Cancel Button Click
    ============================================================================================
    */

    goCancelButtonClick() {
        //console.log("Itinerary / goCancelButtonClick");
        //console.log("Itinerary / goCancelButtonClick - this.state = ", this.state);

        this.setState(
            {
                routePlanOn: false
            }, 
            () => {
                this.props.storeWarningAlert({
                    message: null,
                    on: false
                });
            }
        );
    }


    /*
    ============================================================================================
        Accept Invite Click
    ============================================================================================
    */

    acceptInviteClick() {
        const axiosCallback = (response) => {
            console.log("Itinerary / acceptInviteClick - response = ", response);
        };

        // Data to send
        const dataJSON = {
            operation: "approve",
            user_id: this.props.userInfo.id,
            itinerary_id: this.props.itineraryInfo.id,
            is_invited: true
        };

        // Send data to server to create a new user tag
        postParticipant(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Itinerary / acceptInviteClick - ", response);
            }
        );
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        google: state.map.google,
        userInfo: state.user.userInfo,
        itineraryInfo: state.itinerary.itineraryInfo,
        itineraryParams: state.itinerary.itineraryParams,
        participant: state.itinerary.participant,
        newParticipant: state.itinerary.newParticipant
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeItinerary,
            storeItineraryParams,
            storeWarningAlert,
            storeUser,
            storeSchedule,
            storeParticipant,
            storeNewParticipant,
            storeMore
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);