/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Time.js
    - Provide reference times for a given date
    - First part constructs the object and second part fills out the prototype
--------------------------------------------------------------------------------------------
    Content
    - Time
============================================================================================
*/

// Modules
import moment from "moment-timezone";


// Constructor
function Time(props) {
    //console.log("Time / constructor - props = ", props);

    // inputs to change time
    this.startMoment = props.startMoment;
    this.endMoment = props.endMoment;
    this.startLocation = props.startLocation;
    this.endLocation = props.endLocation;
    this.location = props.location;
    this.startTimezone = props.startTimezone;
    this.endTimezone = props.endTimezone;
    this.timezone = props.timezone;
    this.midMoment = null;
    this.hour00Moment = null;
    this.hour24Moment = null;
    this.sunTimes =
        {
            sunrise: null,
            sunset: null,
            solarNoon: null
        };
    this.moonTimes =
        {
            moonrise: null,
            moonset: null
        };
    this.moonPhase = null;
    this.moonPhaseDigits = null;
    this.totalDayTime = null;
    this.totalOffsetTime = null;
    this.totalTime = null;
    this.initialized = false;
    this.adjustTimes = props.adjustTimes;
}

// Initialize time object
function initTime(duration) {
    //console.log("Time / initTime - duration = ", duration);

    //-------------------------------------------------------------------------------------------------------
    // setStartEndMoments (temporary)
    //-------------------------------------------------------------------------------------------------------
    // - set start and end moments
    //-------------------------------------------------------------------------------------------------------
    Time.prototype.setStartEndMoments = function(callback) {
        //console.log("Time / setStartEndMoments - this = ", this);

        const today = moment().unix();
        const tomorrow = today + 24 * 3600;
        //console.log("Time / initTime - today = ", today);

        // Set it to 7:00am if the start time is not set
        if (this.startMoment === null) {
            //console.log("Time / setStartEndMoments - Initializing start moment");

            this.startMoment = moment.unix(tomorrow).tz(this.startTimezone);
            //console.log("Time / initTime - startMoment (before setting time) = ", this.startMoment.format());
            this.startMoment.set("hour", 9);
            this.startMoment.set("minute", 0);
            this.startMoment.set("second", 0);
            this.startMoment.set("millisecond", 0);
            //console.log("Time / initTime - startMoment (after setting time) = ", this.startMoment.format());
        }
        // change the timezone if the time is already set (due to location / timezone change)
        // without changing local time
        else {
            this.startMoment.tz(this.startTimezone, true);

            // Already initialized 
            this.initialized = true;
        }

        // Set it to 7:00pm if the end time is not set
        if (this.endMoment === null) {
            //console.log("Time / setStartEndMoments - Initializing end moment");

            //console.log("Time / initTime - duration = ", duration);
            if (duration != null) {
                this.endMoment = moment.unix(this.startMoment.unix() + duration).tz(this.endTimezone);
                //console.log("Time / initTime - endMoment = ", this.endMoment.format());
            }
            else {
                this.endMoment = moment.unix(tomorrow).tz(this.endTimezone);
                this.endMoment.set("hour", 18);
                this.endMoment.set("minute", 0);
                this.endMoment.set("second", 0);
                this.endMoment.set("millisecond", 0);
                //console.log("Time / initTime - endMoment = ", this.endMoment.format());
            }
        }

        // Ghange the timezone if the time is already set (due to location / timezone change)
        // without changing local time
        else {
            this.endMoment.tz(this.endTimezone, true);

            // Already initialized 
            this.initialized = true;
        }

        // Get the mid time
        this.midMoment = moment.unix(Math.round(this.startMoment.unix() + this.endMoment.unix()) / 2).tz(this.timezone);
        //console.log("Time / initTime - this.midMoment = ", this.midMoment);
        this.hour00Moment = this.midMoment.clone();
        this.hour24Moment = this.midMoment.clone();
        this.hour00Moment.set("hour", 0);
        this.hour00Moment.set("minute", 0);
        this.hour00Moment.set("second", 0);
        this.hour00Moment.set("millisecond", 0);
        this.hour24Moment.set("hour", 24);
        this.hour24Moment.set("minute", 0);
        this.hour24Moment.set("second", 0);
        this.hour24Moment.set("millisecond", 0);        
        //console.log("Time / initTime - startMoment = ", this.startMoment.format());
        //console.log("Time / initTime - endMoment = ", this.endMoment.format());
        //console.log("Time / initTime - midMoment = ", this.midMoment.format());
        //console.log("Time / initTime - hour00Moment = ", this.hour00Moment.format());
        //console.log("Time / initTime - hour24Moment = ", this.hour24Moment.format());
        
        // Execute callback if it exists and is a function
        if ((callback) && (typeof callback === "function")) {
            callback();
        }
    };


    //-------------------------------------------------------------------------------------------------------
    // getSunTimes / getMoonTimes
    //-------------------------------------------------------------------------------------------------------
    // get sunrise / sunset / moonrise / moonset times and adjust timezone
    //-------------------------------------------------------------------------------------------------------
    Time.prototype.getSunTimes = function(callback) {
        let sunTimes;

        // Get sun times
        sunTimes = window.SunCalc.getTimes(this.midMoment.toDate(), this.location.latitude, this.location.longitude);
        //console.log("Time / getSunTimes - this.midMoment.tz(this.timezone) = ", this.midMoment.tz(this.timezone));
        //console.log("Time / getSunTimes - sunTimes = ",sunTimes);

        // Set the sunTimes object in the timezone of average trip location
        this.sunTimes = {
            sunrise: moment(sunTimes.sunrise).tz(this.timezone),
            sunset: moment(sunTimes.sunset).tz(this.timezone),
            solarNoon: moment(sunTimes.solarNoon).tz(this.timezone)
        };
        //console.log("Time / getSunTimes - this.sunTimes = ", this.sunTimes);

        // Update the start and end times according to the day time
        //console.log("Time / getSunTimes - this.initialized = ", this.initialized);
        if (this.initialized === false && this.adjustTimes === true) {
            //console.log("Time / getSunTimes - Adjusting start and end times");
            if (this.sunTimes.sunrise > this.startMoment) {
                this.startMoment = moment.unix(this.sunTimes.sunrise.unix()).tz(this.startTimezone);
            }
            if (this.sunTimes.sunset < this.endMoment) {
                this.endMoment = moment.unix(this.sunTimes.sunset.unix()).tz(this.endTimezone);
            }
        }

        // Execute callback if it exists and is a function
        if ((callback) && (typeof callback === "function")) {
            callback();
        }        
    };


    Time.prototype.getMoonTimes = function(callback) {
        let moonTimes;

        // Get moon times
        moonTimes = window.SunCalc.getMoonTimes(this.midMoment.toDate(), this.location.latitude, this.location.longitude);
        //console.log("Time / getMoonTimes - this.midMoment.tz(this.timezone) = ", this.midMoment.tz(this.timezone));
        //console.log("Time / getMoonTimes - moonTimes = ",moonTimes);

        // Set the moonTimes object in the timezone of average trip location
        const moonrise = (moonTimes.rise)? moment(moonTimes.rise).tz(this.timezone) : null;
        const moonset = (moonTimes.set)? moment(moonTimes.set).tz(this.timezone) : null;

        // Set moonTimes
        this.moonTimes =
            {
                moonrise: moonrise,
                moonset: moonset
            };
        //console.log("Time / getMoonTimes - this.moonTimes = ", this.moonTimes);
         
        // Get moon phase
        const moonIllumination = window.SunCalc.getMoonIllumination(this.midMoment.toDate());
        //console.log("Time / getMoonTimes - moonIllumination = ", moonIllumination);

        // Get the moon fraction
        const moonPhaseFraction = Math.round(moonIllumination.fraction * 100);

        // Get moonPhase
        let moonPhase = "";
        if (moonPhaseFraction === 100) {
            moonPhase = "Full";
        }
        else if (moonPhaseFraction === 0) {
            moonPhase = "New";
        }
        else{
            moonPhase = "" + moonPhaseFraction + "%";
        }
        this.moonPhase = moonPhase;

        // Get the moon phase filename (0000 to 1875)
        const phase16 = Math.round(moonIllumination.phase * 16)
        this.moonPhaseDigits = (phase16 === 16)? 0 : phase16;
        //console.log("Time / getMoonTimes - moonPhase = ", this.moonPhase);
        //console.log("Time / getMoonTimes - moonPhaseDigits = ", this.moonPhaseDigits);

        // Execute callback if it exists and is a function
        if ((callback) && (typeof callback === "function")) {
            callback();
        }
    };


    //--------------------------------------------------------------------------------------------------
    // getTimes
    //--------------------------------------------------------------------------------------------------
    // - evaluate the start and end times according to the given driveTime
    //--------------------------------------------------------------------------------------------------

    Time.prototype.getTimes = function(callback) {
        // Total time available
        const totalTime = (this.endMoment- this.startMoment) / 1000;

        // Daytime in seconds
        const totalDayTime = (this.sunTimes.sunset - this.sunTimes.sunrise) / 1000;

        // Offset positive if leave earlier than sunrise
        const totalOffsetTime = (this.sunTimes.sunrise - this.startMoment.toDate()) / 1000;
        //console.log("Time / getTimes - totalTime = ", totalTime, " seconds");
        //console.log("Time / getTimes - totalDayTime = ", totalDayTime, " seconds");
        //console.log("Time / getTimes - totalOffsetTime = ", totalOffsetTime, " seconds");

        // Save times
        this.totalTime = totalTime;
        this.totalDayTime = totalDayTime;
        this.totalOffsetTime = totalOffsetTime;
        //console.log("Time / getTimes - totalTime = ", this.totalTime / 3600, " hours");
        //console.log("Time / getTimes - totalDayTime = ", this.totalDayTime / 3600, " hours");
        //console.log("Time / getTimes - totalOffsetTime = ", this.totalOffsetTime / 3600, " hours");

        // Execute callback if it exists and is a function
        if ((callback) && (typeof callback === "function")) {
            callback();
        }
    };
}

export { Time, initTime };