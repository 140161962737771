const itinerariesAPI = "api/itineraries/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const postOpenItineraries = (tripSlug, fetchedCount) => {
    const URL = rootURL + itinerariesAPI + "open/";
    const dataJSON = {
    	"dot_slug": tripSlug,
    	"fetched_count": fetchedCount
    };

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postRouteItinerary = (dataJSON) => {
    const URL = rootURL + itinerariesAPI + "route/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const patchRouteItinerary = (itineraryID, dataJSON) => {
    const URL = rootURL + itinerariesAPI + "route/" + itineraryID + "/";

    return window.axiosCSRF.patch(URL, dataJSON);
};

export const postModifyItinerary = (dataJSON) => {
    const URL = rootURL + itinerariesAPI + "modify/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postCheckConnect = (dataJSON) => {
    const URL = rootURL + itinerariesAPI + "check-connect/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const getItineraries = (numItinerariesLoaded) => {
    const URL = rootURL + itinerariesAPI + "fetch/?num_itineraries_loaded=" + numItinerariesLoaded;
    
    return window.axiosCSRF.get(URL);
};

export const getItinerariesPaginated = (username, page) => {
    const URL = rootURL + itinerariesAPI + "?user__username=" + username + "&page=" + page;
    
    return window.axiosCSRF.get(URL);
};

export const getItinerary = (itineraryID) => {
    const URL = rootURL + itinerariesAPI + itineraryID + "/";
    
    return window.axiosCSRF.get(URL);
};

export const patchItinerary = (itineraryID, dataJSON) => {
    const URL = rootURL + itinerariesAPI + itineraryID+ "/";
    
    return window.axiosCSRF.patch(URL, dataJSON);
};

export const deleteItinerary = (itineraryID) => {
    const URL = rootURL + itinerariesAPI + itineraryID+ "/";
    
    return window.axiosCSRF.delete(URL);
};

export const postParticipant = (dataJSON) => {
    const URL = rootURL + itinerariesAPI + "guests/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postSaveItinerary = (dataJSON) => {
    const URL = rootURL + itinerariesAPI + "save/";

    return window.axiosCSRF.post(URL, dataJSON);
};
