// React / ReactDOM / React-router
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Components
import { Gallery, mediaDimensions } from "components/Gallery";

// Functions
import {
    //getStaticPath,
    getMediaProperty,
    freezeBody,
    unfreezeBody,
    sortMedia
} from "js/Functions";

// Redux 
import { storeGallery, storeWarningAlert } from "actions";

// Axios
//import {} from "requests";

// CSS
import "./GallerySimple.scss";


class GallerySimple extends Component {
    constructor(props){
        super(props);

        // Initialize model DOM reference
        this.modalRef = React.createRef();

        // Media settings
        this.mediaArea = 500000;

        // Minimum / scale / maximum content widths
        this.minContentWidth = 320;
        this.scaleContentWidth = 800;
        this.maxContentWidth = 1200;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media side margin width
        this.marginWidth = 10;

        // Initialize state
        this.state = {
            selectedMediaIndex: 0
        };

        // Bind functions
        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);
    }

    escClick(event) {
        // Disappear gallery modal on pressing esc
        if (this.props.gallery.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        this.props.storeGallery({ modalOn: false, info: null });
    }

    render() {
        //console.log("GallerySimple / render - this.props = ", this.props);
        //console.log("GallerySimple / render - this.state = ", this.state);

        // Effective browser width
        const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.marginWidth;

        // Sort media
        const mediaSorted = sortMedia(this.props.gallery.info);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Get the image dimensions for the right size (small size)
        const mediaInfo = media[this.state.selectedMediaIndex];
        const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
        const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

        // Is video
        const isVideo = (media[this.state.selectedMediaIndex].type === "video");

        // Gallery dimensions
        const galleryDimensions = mediaDimensions({
            colorMode: this.props.colorMode,
            effectiveBrowserWidth: effectiveBrowserWidth,
            isVideo: isVideo,
            mediaArea: this.mediaArea,
            mediaWidth: mediaWidth,
            mediaHeight: mediaHeight,
            minAspectRatio: this.minAspectRatio,
            maxAspectRatio: this.maxAspectRatio,
            minContentWidth: this.minContentWidth,
            scaleContentWidth: this.scaleContentWidth,
            maxContentWidth: this.maxContentWidth
        });

        // Gallery props
        const galleryProps = Object.assign(
            galleryDimensions,
            {
                // General
                id: "",
                idPrefix: "gallery-simple",
                classPrefix: "gallery-simple",
                selectedMediaIndex: this.state.selectedMediaIndex,
                media: media,
                size: "m",
                startPlaying: true,
                checkScroll: false,
                nextMediaClick: this.nextMediaClick,
                prevMediaClick: this.prevMediaClick,
                navDotClick: this.navDotClick,
                index: null,
                square: false,
                muted: false,

                // Interaction buttons
                userTagOn: false,
                saved: false,
                dotTagOn: false,
                unsaveOn: false,
                enlargeOn: false,
                dotInfo: this.props.gallery.info,
                tagMode: "save"
            }
        );

        const gallery = (
            <Gallery {...galleryProps} />
        );


        return (
            <div className = "gallery-modal" 
                style = {{
                    display: (this.props.gallery.modalOn)? "block" : "none"
                }}
            >
                <div className = {(this.props.colorMode === "day")? 
                            "gallery-modal-content modal-day" : 
                            "gallery-modal-content modal-night"} 
                    ref = {this.modalRef}
                >
                    {gallery}                    
                </div>
            </div>
        );
    }

    componentDidMount() {
        //console.log("GallerySimple / componentDidMount - mount");

        // Freeze of unfreeze background
        if (this.props.gallery.modalOn === true) {
            freezeBody();
        }
        if (this.props.gallery.modalOn === false) {
            unfreezeBody();
        }

        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);
    }
    
    componentWillUnmount() {
        //console.log("GallerySimple / componentWillUnmount - unmount");

        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("GallerySimple / componentDidUpdate - update");
    }


    /*
    ============================================================================================
        Gallery Navigation Functions
    ============================================================================================
    */

    nextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex + 1),
            }
        );
    }

    prevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex - 1),
            }
        );
    }

    navDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            selectedMediaIndex: mediaIndex,
        });
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        gallery: state.gallery.gallery
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeGallery, storeWarningAlert }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(GallerySimple); 
