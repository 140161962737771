// React / ReactDOM / React-router
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Axios
import {
    getContributors
} from "requests";

/*
// Websocket
import {
    addWebSocketGroups,
    removeWebSocketGroups
} from "js/WebSocketFunctions";

// Redux
import {
    storeRefresh,
    storeLogInOn,
    storeSignUpOn,
    storeUser,
} from "actions";
*/

import {
    url,
    getStaticPath,
    getMediaProperty,
} from "js/Functions";

// CSS
import "./NationalParkUser.scss";


class NationalParkUser extends Component {
    constructor (props) {
    	super(props);

        this.minWidth = 200;

    	this.state = {
            feedMode: this.props.feedMode,
            contributorsInfo: null,
            usersInfo: null
        };

        // Bind functions
        this.setState = this.setState.bind(this);

        // Bind post tab button functions
        this.switchToUserMode = this.switchToUserMode.bind(this);
        this.switchToContributorMode = this.switchToContributorMode.bind(this);

        //this.loadUserIDs = this.loadUserIDs.bind(this);
        this.loadUsersInfo = this.loadUsersInfo.bind(this);
    }

    /*
    ============================================================================================
        Render
    ============================================================================================
    */
    render() {
        //console.log("NationalParkUser / render - this.props.height = ", this.props.height);

        // Determine if user is logged in
        //const loggedIn = (!!localStorage.token && this.props.userInfo !== null);


        /*
        ============================================================================================
            Users
        ============================================================================================
        */

        const usersInfo = (this.state.feedMode === "contributor")? 
            this.state.contributorsInfo : this.state.usersInfo;

        // Double layout
        const doubleLayout = (this.props.browserWidth >= this.props.doubleLayoutWidth)?
            (this.props.browserWidthPixels / 4 >= this.minWidth) :
            (this.props.browserWidthPixels / 2 >= this.minWidth);
        //console.log("NationalParkUser / render - this.props.doubleLayoutWidth = ", this.props.doubleLayoutWidth);
        //console.log("NationalParkUser / render - this.props.browserWidth = ", this.props.browserWidth);
        //console.log("NationalParkUser / render - this.props.browserWidthPixels = ", this.props.browserWidthPixels);
        //console.log("NationalParkUser / render - doubleLayout = ", doubleLayout);

        let users = [];

        if (usersInfo !== null) {
            const lastItemIndex = usersInfo.length - 1;
            const numRows = (doubleLayout)?
                Math.round(usersInfo.length / 2) : usersInfo.length;
            //console.log("NationalParkUser / render - numRows = ", numRows);

            for (let i = 0; i < numRows; i++) {
                if (doubleLayout) {
                    const firstItemIndex = 2 * i;
                    const secondItemIndex = 2 * i + 1;

                    users.push(
                        <div className = "national-park-user-row"
                            key = {"national-park-user-row-" + i}
                        >
                            <NationalParkUserItem
                                userInfo = {usersInfo[firstItemIndex]}
                                colorMode = {this.props.colorMode}
                                itemIndex = {firstItemIndex}
                                doubleLayout = {true}
                            />
                            {
                                (secondItemIndex <= lastItemIndex)? (
                                    <NationalParkUserItem
                                        userInfo = {usersInfo[secondItemIndex]}
                                        colorMode = {this.props.colorMode}
                                        itemIndex = {secondItemIndex}
                                        doubleLayout = {true}
                                    />
                                ) : (
                                    <div key = {"national-park-user-item-" + secondItemIndex}
                                        className = "national-park-user-item"
                                        style = {{ width: "50%" }}
                                    >
                                    </div>
                                )
                            }

                        </div>
                    );
                }
                else {
                    const itemIndex = i;

                    users.push(
                        <div className = "national-park-user-row"
                            key = {"national-park-user-row-" + i}
                        >
                            <NationalParkUserItem
                                userInfo = {usersInfo[itemIndex]}
                                colorMode = {this.props.colorMode}
                                itemIndex = {itemIndex}
                                doubleLayout = {false}
                            />
                        </div>
                    );
                }
            }
        }
        //console.log("NationalParkUser / render - users = ", users);


        /*
        ============================================================================================
            Body
        ============================================================================================
        */

        return(
            <div id = "national-park-user">
                <div id = "national-park-user-header">
                    <div className = {
                            (this.state.feedMode === "contributor")?
                                (
                                    (this.props.colorMode === "day")?
                                        "national-park-user-header-tab-hover-day lb4" :
                                        "national-park-user-header-tab-hover-night lb4"
                                ) : "national-park-user-header-tab g4"
                        }
                        onClick = {this.switchToContributorMode}
                    >
                        Top Contributors
                    </div>
                    <div className = {
                            (this.state.feedMode === "user")?
                                (
                                    (this.props.colorMode === "day")?
                                        "national-park-user-header-tab-hover-day lb4" :
                                        "national-park-user-header-tab-hover-night lb4"
                                ) : "national-park-user-header-tab g4"
                        }
                        onClick = {this.switchToUserMode}
                    >
                        Newly Joined
                    </div>
                </div>
                <div id = "national-park-user-list"
                    style = {{
                        height: this.props.height
                    }}
                >
                    {users}
                </div>
            </div>
        );
    }

    componentDidMount() {
        //console.log("NationalParkUser / componentDidMount - this.props = ", this.props);
        //console.log("NationalParkUser / componentDidMount - this.state = ", this.state);

        // Load new dots
        //this.loadUserIDs();
        this.loadUsersInfo(this.state.feedMode, null);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("NationalParkUser / componentDidUpdate - this.props = ", this.props);
        //console.log("NationalParkUser / componentDidUpdate - this.state = ", this.state);

    }

    /*
    ============================================================================================
        Switch Post Mode
    ============================================================================================
    */

    switchToUserMode() {
        this.setState({
            feedMode: "user",
        });
    };

    switchToContributorMode() {
        this.setState({
            feedMode: "contributor",
        });
    };


    /*
    ============================================================================================
        Load Users
    ============================================================================================
    */
    /*
    loadUserIDs(feedMode, callback ) {

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            console.log("NationalParkUser / loadUserIDs - response = ", response);

            const userIDsPrevious = this.state.newIDs.slice();
            const userIDs = userIDsPrevious.concat(response.data.content.user_ids)

            // Update state
            this.setState(
                {
                    newIDs: userIDs,
                    newFeedComplete: response.data.content.feed_complete,
                },
                callback
            );

        };

        getUserSearch(null)
        .then(axiosCallback)
        .catch((response) =>{
            console.log("NationalParkUser / loadDotIDs - Axios error ", response)
        });

    }
    */


    /*
    ============================================================================================
        loadDotsInfo
    ============================================================================================
    */

    loadUsersInfo(feedMode, callback) {
        const axiosCallback = (response) => {
            //console.log("NationalParkUser / loadUsersInfo - this.props.setSlug = ", this.props.setSlug);
            //console.log("NationalParkUser / loadUsersInfo - response.data.content = ", response.data.content);

            // Construct users info array
            const usersInfo = [];
            for (let i = 0; i < response.data.content.length; i++) {
                usersInfo.push(response.data.content[i].user);
            }
            //console.log("NationalParkUser / loadUsersInfo - usersInfo = ", usersInfo);
            //console.log("NationalParkUser / loadUsersInfo - feedMode = ", feedMode);

            if (feedMode === "contributor") {
                this.setState(
                    {
                        contributorsInfo: usersInfo
                    },
                    callback
                );
            }
            else {
                this.setState(
                    {
                        usersInfo: usersInfo
                    },
                    callback
                );
            }

            // Execute provided callback
            //if (callback && typeof callback === "function") {
            //    callback();
            //}
        };

        getContributors(this.props.setSlug, null)
        .then(axiosCallback)
        .catch((response) =>{
            console.log("NationalParkUser / loadDotIDs - Axios error ", response)
        });
    }
}


class NationalParkUserItem extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        /*
        ============================================================================================
            Dot and Badge
        ============================================================================================
        */

        const dotImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/type-dot-black.png") :
            getStaticPath("/images/common/type-dot-white.png");

        const badgeImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/editor-pick-black.png") :
            getStaticPath("/images/common/editor-pick-white.png");

        // User picture
        const userMedia = (this.props.userInfo.profile_pic)?
            (
                (this.props.userInfo.profile_pic.external_url === null)?
                    getMediaProperty(this.props.userInfo.profile_pic, "t", "url", true) :
                    url(this.props.userInfo.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        // Dot icon and count
        const dotCountOn = (this.props.userInfo.contributing_dot_count > 0);
        const dotCount = (dotCountOn)? (
            <div className = {(this.props.colorMode === "day")?
                "national-park-user-item-dot-count g4" :
                "national-park-user-item-dot-count g4"}
            >
                {this.props.userInfo.contributing_dot_count}
            </div>
        ) : null;
        const dotIcon = (dotCountOn)? (
            <div className = "national-park-user-item-dot-icon image-cover"
                style = {{ backgroundImage: dotImage }}
            >
            </div>
        ) : null;

        // Badge icon and count
        const badgeCountOn = (this.props.userInfo.editing_dot_count > 0);
        const badgeCount = (badgeCountOn)? (
            <div className = {(this.props.colorMode === "day")?
                "national-park-user-item-badge-count g4" :
                "national-park-user-item-badge-count g4"}
            >
                {this.props.userInfo.editing_dot_count}
            </div>
        ) : null;
        const badgeIcon = (badgeCountOn)? (
            <div className = "national-park-user-item-badge-icon image-cover"
                style = {{ backgroundImage: badgeImage }}
            >
            </div>
        ) : null;

        // Stats space
        const statsSpace = (dotCountOn && badgeCountOn)? (
            <div className = "national-park-user-item-stats-space"></div>
        ) : null;

        // User posting
        const userPosting = (this.props.userInfo.posting)? (
            <div className = "national-park-user-item-posting">
                {this.props.userInfo.posting}
            </div>
        ) : null;

        return(
            <div key = {"national-park-user-item-" + this.props.itemIndex}
                className = "national-park-user-item"
                style = {{ width: (this.props.doubleLayout)? "50%" : "100%" }}
            >
                <div className = "national-park-user-item-profile profile-image-s0"
                    style = {{
                        backgroundImage: userMedia
                    }}
                >
                </div>
                <div className = "national-park-user-item-content">
                    <div className = "national-park-user-item-title">
                        <div className = {(this.props.colorMode === "day")?
                            "national-park-user-item-name k4" :
                            "national-park-user-item-name w4"}
                        >
                            {this.props.userInfo.name}
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                            "national-park-user-item-username lb5" :
                            "national-park-user-item-username b5"}
                        >
                            {this.props.userInfo.username}
                        </div>
                    </div>
                    <div className = "national-park-user-item-stats">
                        {dotIcon}
                        {dotCount}
                        {statsSpace}
                        {badgeIcon}
                        {badgeCount}
                    </div>
                    {userPosting}
                </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        //userInfo: state.user.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            //storeRefresh,
            //storeLogInOn,
            //storeSignUpOn,
            //storeUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NationalParkUser);