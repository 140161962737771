/*
============================================================================================
    customMarker object
--------------------------------------------------------------------------------------------
    - Define custom marker prototype and related functions
============================================================================================
*/

function customMarker(latlng, map, args) {
    this.latlng = latlng;
    this.args = args;
    this.setMap(map);
}


function initCustomMarker(google) {
    //console.log("CustomMarker / initCustomMarker - this = ",this);
    //console.log("CustomMarker / initCustomMarker - customMarker = ", customMarker);

    // Using OverlayView object of google map to define customMarker
    customMarker.prototype = new google.maps.OverlayView();

    // Draw method
    customMarker.prototype.draw = function() {
        // Save customMarker context as self
        const self = this;
        //console.log("CustomMarker / initCustomMarker (inside draw method) - this = ", this);

        // Content of the marker
        let div = this.div;

        // If DOM node is empty
        if (!div) {
            // Create new div
            div = this.div = document.createElement("div");

            // Set type
            //const type = self.args.type;

            // If dot type
            if (self.args.type === "dot" || self.args.type === "child") {
                if (self.args.hovered === true) {
                    div.className = "search-map-marker search-map-marker-hovered";
                }
                else {
                    div.className = "search-map-marker";
                }
            }

            // Set properties of the marker
            div.style.background = self.args.icon;

            // ID should come from args
            if (typeof(self.args.id) !== "undefined") {
                div.id = self.args.id;
            }

            // Media
            const media = document.createElement("div");
            media.className = "search-map-marker-media search-map-marker-media-off";
            media.style.background = self.args.media;
            media.id = self.args.mediaID;

            // Name
            const name = document.createElement("div");
            name.className = "search-map-marker-name search-map-marker-name-off w5";
            name.textContent = self.args.name;
            name.id = self.args.nameID;

            // Insert name and media into marker
            div.appendChild(media);
            div.appendChild(name);

            // 

            // Add event callbacks
            google.maps.event.addDomListener(div, "click", 
                (event) => {

                }
            );

            google.maps.event.addDomListener(div, "mouseover", 
                (event) => {
                    media.className = "search-map-marker-media search-map-marker-media-on";
                    name.className = "search-map-marker-name search-map-marker-name-on w5";

                    // Set hovered child
                    if (self.args.type === "child") {
                        self.args.setHoveredChildID();
                    }
                }
            ); 

            google.maps.event.addDomListener(div, "mouseout",
                (event) => {
                    media.className = "search-map-marker-media search-map-marker-media-off";
                    name.className = "search-map-marker-name search-map-marker-name-off w5";

                    // Set hovered child
                    if (self.args.type === "child") {
                        self.args.clearHoveredChildID();
                    }
                }
            );

            // Set panes and append div
            const panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        // Position marker
        const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
        if (point) {
            div.style.left = (point.x - 14) + "px";
            div.style.top = (point.y - 14) + "px";
        }
    };

    customMarker.prototype.remove = function() {
        if (this.div !== null) {
            this.div.parentNode.removeChild(this.div);
        }
        this.div = null;
        this.setMap(null);
    };

    customMarker.prototype.getPosition = function() {
        return this.latlng;
    };

    customMarker.prototype.setPosition = function(latlng) {
        this.latlng = latlng;
    };
}

export { initCustomMarker, customMarker };
