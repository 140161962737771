const tagsAPI = "api/tags/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;


export const postDotTag = (dataJSON) => {
	let URL = rootURL + tagsAPI + "dots/";

	return window.axiosCSRF.post(URL, dataJSON);
};

export const postUserTag = (dataJSON) => {
	let URL = rootURL + tagsAPI + "users/"

	return window.axiosCSRF.post(URL, dataJSON);
};

export const deleteUserTag = (userTagID) => {
	let URL = rootURL + tagsAPI + "users/" + userTagID + "/";

	return window.axiosCSRF.delete(URL);
};

export const getUserTags = (taggerID, dotID, mode) => {
	let URL = rootURL + tagsAPI + "users/?tagger__id=" + taggerID;
	if (mode === "save") {
		URL += "&dot_save__dot__id=" + dotID;
	}
	else if (mode === "contribute") {
		URL += "&dot_contribution__dot__id=" + dotID;
	}
	else if (mode === "everyday") {
		URL += "&dot_contribution__dot__id=" + dotID;
	}
	else if (mode === "complete") {
		URL += "&dot_completion__dot__id=" + dotID;
	}

	return window.axiosCSRF.get(URL);
};
