/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    SignUp.js
    - User sign up
    - Google and Facebook assisted sign up modes
--------------------------------------------------------------------------------------------
    Content
    - SignUp
============================================================================================
*/

// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Redux
import {
    storeUser,
    storeSignUpOn,
    storeItinerary,
    storeSaveItinerary
} from "actions";

// Modules
import ReactTooltip from "thedots-tooltip";

// Functions
import {
    logInFacebook,
    logInGoogle
} from "js/AuthFunctions";

import {
    getStaticPath
    //freezeBody,
    //unfreezeBody
} from "js/Functions";

// Axios
import { 
    getUserLogin,
    postUser,
    postEmailCheck,
    postSaveItinerary
} from "requests";

// CSS
import "./SignUp.scss";

// Response codes
import { responseResultCodes } from "ResponseCodes";


// SignUp component
class SignUp extends Component {
    constructor(props){
        super(props);

        // Delay time
        this.delayTime = 1000;

        // DOM nodes
        this.emailRef = React.createRef();
        this.firstNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.passwordOnceRef = React.createRef();
        this.passwordTwiceRef = React.createRef();

        // Input focused
        this.emailFocused = false;
        this.firstNameFocused = false;
        this.lastNameFocused = false;
        this.passwordOnceFocused = false;
        this.passwordTwiceFocused = false;

        // Input warning timers
        this.facebookGoogleWarning = null;
        this.emailWarning = null;
        this.firstNameWarning = null;
        this.lastNameWarning = null;
        this.passwordOnceWarning = null;
        this.passwordTwiceWarning = null;
        this.submitButton = null;

        // Area object [lat, lng]
        this.area = null;

        // Initialize state
        this.state = {
            emailBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            firstNameBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            lastNameBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            passwordOnceBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            passwordTwiceBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            facebookGoogleWarningOn: false,
            emailWarningOn: false,
            firstNameWarningOn: false,
            lastNameWarningOn: false,
            passwordOnceWarningOn: false,
            passwordTwiceWarningOn: false,

            facebookGoogleWarningMessage: null,
            emailWarningMessage: null,
            firstNameWarningMessage: null,
            lastNameWarningMessage: null,
            passwordOnceWarningMessage: null,
            passwordTwiceWarningMessage: null,

            submitOn: false,
        };

        // Bind callbacks
        this.resetForm = this.resetForm.bind(this);
        this.cancelSignUp = this.cancelSignUp.bind(this);
        this.inputColorBorders = this.inputColorBorders.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.emailOnChange = this.emailOnChange.bind(this);
        this.firstNameOnChange = this.firstNameOnChange.bind(this);
        this.lastNameOnChange = this.lastNameOnChange.bind(this);
        this.passwordOnceOnChange = this.passwordOnceOnChange.bind(this);
        this.passwordTwiceOnChange = this.passwordTwiceOnChange.bind(this);
        this.checkWarnings = this.checkWarnings.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.submitFacebookGoogle = this.submitFacebookGoogle.bind(this);
        this.logInFacebookSuccess = this.logInFacebookSuccess.bind(this);
        this.logInFacebookFailure = this.logInFacebookFailure.bind(this);
        this.logInGoogleSuccess = this.logInGoogleSuccess.bind(this);
        this.logInGoogleFailure = this.logInGoogleFailure.bind(this);
        this.signUpSuccess = this.signUpSuccess.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.saveItinerary = this.saveItinerary.bind(this);
    }

    escFunction(event) {
        // Disappear SignUp box on pressing esc
        if (this.state.logInOrSignUp === "signup" && event.keyCode === 27 && !!localStorage.token === false) {
            this.cancelSignUp();
        }
    }

    logInFacebookFailure() {
        //console.log("[WARING] SignUp / logInFacebookFailure");

        this.facebookGoogleShowWarning("Facebook log in failure. Try with different credentials.");
    }


    logInFacebookSuccess(authResponse, profileData) {
        //console.log("[WARING] SignUp / logInFacebookSuccess");

        // Submit form
        this.submitFacebookGoogle(profileData.email, profileData.first_name, profileData.last_name, profileData.picture.data.url, "facebook");
    }


    logInGoogleFailure(error) {
        //console.log("[WARING] SignUp / logInGoogleFailure : error = ", error);

        this.facebookGoogleShowWarning("Google log in failure. Try With different credentials.");
    }


    logInGoogleSuccess(authResponse, profileData) {
        //console.log("[WARING] SignUp / logInGoogleSuccess");

        // Submit form
        this.submitFacebookGoogle(profileData.email, profileData.first_name, profileData.last_name, profileData.profile_pic, "google");
    }


    // General signup success callback
    signUpSuccess(response, cancelCallback) {
        //console.log("SignUp / signUpSuccess - response = ", response);

        // Save token to localStorage
        const token = response.data.content.token;
        localStorage.token = token;

        // Set the axios headers with the access token
        window.axios.defaults.headers.common["Authorization"] = " Token " + token;
        window.axiosCSRF.defaults.headers.common["Authorization"] = " Token " + token;

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("SignUp / signUpSuccess - login response = ", response);
            const userInfo = response.data.content;
            //console.log("SignUp / SignUpSuccess - userInfo = ", userInfo);

            // If temporary itinerary exists
            if (this.props.saveItinerary !== null) {
                this.saveItinerary(userInfo);
            }
            else {
                // Dispatch to Redux store
                this.props.storeUser(userInfo);
                this.props.storeSignUpOn(false);

                // Reset form
                this.resetForm();
            }
        };

        // Send user info request using axios post with CSRF token
        getUserLogin()
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] SignUp / SignUpSuccess - axios error = ", response);});
    }

    saveItinerary(userInfo) {
        if (this.props.saveItinerary !== null) {
            //console.log("LogIn / saveItinerary - this.props.saveItinerary = ", this.props.saveItinerary);

            // Get user info and update states
            const axiosCallback = (response) => {
                //console.log("LogIn / saveItinerary - response.data.content = ", response.data.content);

                // Clear save itinerary
                this.props.storeSaveItinerary(null);

                // Dispatch real itinerary to the redux store
                this.props.storeItinerary(response.data.content);

                // Navigate to the saved itinerary
                this.props.history.push(`/itinerary/${response.data.content.id}`);

                // Dispatch to Redux store
                this.props.storeUser(userInfo);
                this.props.storeSignUpOn(false);

                // Reset form
                this.resetForm();
            };

            // Temporary authentication to go through permissions
            window.axios.defaults.headers.common["Authorization"] = " Token " + localStorage.token;
            window.axiosCSRF.defaults.headers.common["Authorization"] = " Token " + localStorage.token;

            // Request data
            const dataJSON = {
                //"user_id": userInfo.id, 
                "itinerary_id": this.props.saveItinerary.id
            };
            //console.log("LogIn / saveItinerary - dataJSON = ", dataJSON);

            // Send find request
            postSaveItinerary(dataJSON)
            .then(axiosCallback)
            .catch(
                (response) => {
                    console.log("[WARNING] LogIn / saveItinerary - error = ", response);
                }
            );
        }
    }

    // Cancel and close sign up menu
    cancelSignUp(callback) {
        //console.log("SignUp / cancelSignUp");

        // Dispatch state to Redux store
        this.props.storeSignUpOn(false);

        // Sign out of Facebook if logged in (need to enable HTTPS)
        window.FB.getLoginStatus(
            function(response) {
                console.log("SignUp / cancelSignUp - response = ", response);
                if (response.status === "connected") {
                    //console.log("SignUp / cancelSignUp - Facebook connected");

                    // Log out of Facebook
                    window.FB.logout();
                    //console.log("SignUp / cancelSignUp - Facebook signed out");

                }
                else if (response.status === "not_authorized") {
                    console.log("[WARNING] SignUp / cancelSignUp - Facebook not_authorized");
                    // The user is logged in to Facebook, but has not authenticated your app
                } else {
                    console.log("[WARNING] SignUp / cancelSignUp - Facebook unknown status");
                    // The user isn"t logged in to Facebook.
                }
            }
        );

        // Sign out of google if logged in
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2.isSignedIn.get()) {
            auth2.signOut().then(
                () => {
                    //console.log("SignUp / cancelSignUp - Google signed out");
                }
            );
        }

        // Reset the warnings / input values / styles
        window.setTimeout(
            () => {
                // Reset warnings and input styles
                this.resetForm();              
            },
            this.delayTime
        );
    }


    resetForm() {
        //console.log("SignUp / resetForm");

        // Clear all inputs
        this.emailRef.current.value = "";
        this.firstNameRef.current.value = "";
        this.lastNameRef.current.value = "";
        this.passwordOnceRef.current.value = "";
        this.passwordTwiceRef.current.value = "";

        // Unfocus all inputs
        this.emailFocused = false;
        this.firstNameFocused = false;
        this.lastNameFocused = false;
        this.passwordOnceFocused = false;
        this.passwordTwiceFocused = false;

        // Input warning timers
        this.facebookGoogleWarning = null;
        this.emailWarning = null;
        this.firstNameWarning = null;
        this.lastNameWarning = null;
        this.passwordOnceWarning = null;
        this.passwordTwiceWarning = null;
        this.submitButton = null;

        this.setState({
            emailBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            firstNameBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            lastNameBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            passwordOnceBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,
            passwordTwiceBorderColor: (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray,

            facebookGoogleWarningOn: false,
            emailWarningOn: false,
            firstNameWarningOn: false,
            lastNameWarningOn: false,
            passwordOnceWarningOn: false,
            passwordTwiceWarningOn: false,

            facebookGoogleWarningMessage: null,
            emailWarningMessage: null,
            firstNameWarningMessage: null,
            lastNameWarningMessage: null,
            passwordOnceWarningMessage: null,
            passwordTwiceWarningMessage: null,

            submitOn: false
        });
    }

    checkWarnings() {
        // Initialize pass check flag
        let passCheckWarnings = true;

        // Check all warnings
        if (this.state.emailWarningOn || 
            this.state.firstNameWarningOn || 
            this.state.lastNameWarningOn || 
            this.state.passwordOnceWarningOn || 
            this.state.passwordTwiceWarningOn
            ) {
            passCheckWarnings = false;
        }

        // Check all input values
        if (
            this.emailRef.current !== null &&
            this.firstNameRef.current !== null &&
            this.lastNameRef.current !== null &&
            this.passwordOnceRef.current !== null &&
            this.passwordTwiceRef.current !== null
            ) {
            if (
                this.emailRef.current.value.length === 0 ||
                this.firstNameRef.current.value.length === 0 ||
                this.lastNameRef.current.value.length === 0 ||
                this.passwordOnceRef.current.value.length === 0 ||
                this.passwordTwiceRef.current.value.length === 0
                ) {
                passCheckWarnings = false;
            }
        }
        else {
            passCheckWarnings = false;            
        }

        return passCheckWarnings;
    }

    submitClick(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Get user information
        const email = this.emailRef.current.value;
        const firstName = this.firstNameRef.current.value;
        const lastName = this.lastNameRef.current.value;
        const password = this.passwordTwiceRef.current.value;

        // Construct json data
        const dataJSON = {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName
        };

        // Axios callback
        // Execute when the server returns a response
        const axiosCallback = (response) => {
            console.log("SignUp / submitClick - response.data.content = ", response.data.content);

            // If sign up was successful
            if (response.data.status.code === responseResultCodes.OK) {
                // execute signup callback
                this.signUpSuccess(response, this.resetForm);
            }
            // If sign up was unsuccessful
            else {
                // Pop an error message
                // TODO: need to make it more intelligent
            }
        };

        // Send sign up request using axios post with CSRF token
        postUser(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] SignUp / submitClick - axios error = ", response);});
    }


    submitFacebookGoogle(email, firstName, lastName, profilePicURL, signUpMode) {
        // Construct json data
        const dataJSON = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            profile_pic_external_url: profilePicURL
        };
        //console.log("SignUp / submitFacebookGoogle - dataJSON = ", dataJSON);

        // Axios callback
        // Execute when the server returns a response
        const axiosCallback = (response) => {
            // console.log("SignUp / submitFacebookGoogle - response = ", response);

            // If sign up was successful
            if (response.data.status.code === responseResultCodes.OK) {
                // execute signup callback
                this.signUpSuccess(response, this.resetForm);
            }
            // If sign up was unsuccessful
            // TODO: need to make it more intelligent
            else {
                if (signUpMode === "facebook") {
                    // Show warning
                    this.facebookGoogleShowWarning("An account already exists with the provided Facebook account.")
                }
                else {
                    // Show warning
                    this.facebookGoogleShowWarning("An account already exists with the provided Google account.")                    
                }
            }
        };

        const axiosErrorCallback = (response) => {
            //console.log("SignUp / submitFacebookGoogle - error response = ", response);

            // Show warning
            this.facebookGoogleShowWarning("An account already exists with the provided email address.")
        };

        // Send sign up request using axios post with CSRF token
        postUser(dataJSON)
        .then(axiosCallback)
        .catch(axiosErrorCallback);
    }


    // Change all border colors
    inputColorBorders() {
        let emailBorderColor,
            firstNameBorderColor,
            lastNameBorderColor,
            passwordOnceBorderColor,
            passwordTwiceBorderColor;

        if (this.state.emailWarningOn === true) {
            emailBorderColor = (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed;
        }
        else {
            emailBorderColor = (this.emailFocused)? 
                window.colorGray : 
                (
                    (this.props.colorMode === "day")? 
                    window.colorLightGray : window.colorDarkestGray
                );
        }

        if (this.state.firstNameWarningOn === true) {
            firstNameBorderColor = (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed;
        }
        else {
            firstNameBorderColor = (this.firstNameFocused)? 
                window.colorGray : 
                (
                    (this.props.colorMode === "day")? 
                     window.colorLightGray : window.colorDarkestGray
               );
        }

        if (this.state.lastNameWarningOn === true) {
            lastNameBorderColor = (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed;
        }
        else {
            lastNameBorderColor = (this.lastNameFocused)? 
                window.colorGray : 
                (
                    (this.props.colorMode === "day")? 
                     window.colorLightGray : window.colorDarkestGray
                );
        }

        if (this.state.passwordOnceWarningOn === true) {
            passwordOnceBorderColor = (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed;
        }
        else {
            passwordOnceBorderColor = (this.passwordOnceFocused)?
                            window.colorGray : 
                (
                    (this.props.colorMode === "day")? 
                    window.colorLightGray : window.colorDarkestGray
                );
        }

        if (this.state.passwordTwiceWarningOn === true) {
            passwordTwiceBorderColor = (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed;
        }
        else {
            passwordTwiceBorderColor = (this.passwordTwiceFocused)? 
                window.colorGray : 
                (
                    (this.props.colorMode === "day")? 
                    window.colorLightGray : window.colorDarkestGray
                );
        }

        // Update states
        this.setState(
            {
                emailBorderColor: emailBorderColor,
                firstNameBorderColor: firstNameBorderColor,
                lastNameBorderColor: lastNameBorderColor,
                passwordOnceBorderColor: passwordOnceBorderColor,
                passwordTwiceBorderColor: passwordTwiceBorderColor,
                facebookGoogleWarningOn: false,
                facebookGoogleWarningMessage: null                
            }
        );
    }

    inputOnFocus(event) {
        //console.log("SignUp / inputOnFocus - event.target.value = ", event.target.value);

        // get the target id
        const id = event.target.id;

        if (id === "sign-up-email") {
            this.emailFocused = true;
        }
        else if (id === "sign-up-first-name") {
            this.firstNameFocused = true;
        }
        else if (id === "sign-up-last-name") {
            this.lastNameFocused = true;
        }
        else if (id === "sign-up-password-once") {
            this.passwordOnceFocused = true;
        }
        else if (id === "sign-up-password-twice") {
            this.passwordTwiceFocused = true;
        }
        else {
            console.log("[WARNING] SignUp / inputOnFocus - unknown input ID");
        }

        // Update the border colors
        this.inputColorBorders();
    }

    inputOnBlur (event) {
        //console.log("SignUp / inputOnBlur - event.target.value = ", event.target.value);

        // Get the target id
        const id = event.target.id;

        if (id === "sign-up-email") {
            this.emailFocused = false;
        }
        else if (id === "sign-up-first-name") {
            this.firstNameFocused = false;
        }
        else if (id === "sign-up-last-name") {
            this.lastNameFocused = false;
        }
        else if (id === "sign-up-password-once") {
            this.passwordOnceFocused = false;
        }
        else if (id === "sign-up-password-twice") {
            this.passwordTwiceFocused = false;
        }
        else {
            console.log("[WARNING] SignUp / inputOnBlur - unknown input ID");
        }

        // Update the border colors
        this.inputColorBorders();
    }


    facebookGoogleClearWarning(callback) {
        // Clear previous timer
        clearTimeout(this.facebookGoogleWarning);

        // State to update
        const stateToUpdate = {
            facebookGoogleWarningOn: false,
            facebookGoogleWarningMessage: null
        };

        // Set up a new timer
        this.facebookGoogleWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                callback
            ),
            this.delayTime
        );
    }

    facebookGoogleShowWarning(warningMessage, callback) {
        //console.log("SignUp / facebookGoogleShowWarning");

        // Clear previous timer
        clearTimeout(this.facebookGoogleWarning);

        // State to update
        const stateToUpdate = {
            facebookGoogleWarningOn: true,
            facebookGoogleWarningMessage: warningMessage
        };
 
        // Set up a new timer
        this.facebookGoogleWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                callback
            ),
            this.delayTime
        );
    }


    emailClearWarning() {
        // Clear previous timer
        clearTimeout(this.emailWarning);

        // Border color
        const borderColor = this.emailFocused? 
            window.colorGray : 
            (
                (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray
            );

        // Update state
        this.setState({
            emailBorderColor: borderColor,
            emailWarningOn: false,
            emailWarningMessage: null
        });
    }

    emailShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.emailWarning);

        // State to update
        const stateToUpdate = {
            emailBorderColor: (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed,
            emailWarningOn: true,
            emailWarningMessage: warningMessage
        };

        // Update state
        this.emailWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    emailOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.emailClearWarning();

        // Clear previous timer
        clearTimeout(this.emailWarning);
        
        // Get email
        const email = "" + this.emailRef.current.value;
        //console.log("SignUp / emailOnChange - email = ", email);


        if ((email.length === 0) || (!email.includes("@") || !email.includes("."))) {
            if (email.length === 0) {
            }
            else {
                this.emailShowWarning("Please enter a valid email.");
            }
        }
        else {
            // Construct json data
            const dataJSON = {
                email: email
            };

            // Axios callback to execute when the server returns a response
            const axiosCallback = (response) => {
                //console.log("SignUp/ emailOnChange - axios response.data.status = ", response.data.status);

                // If there is an email error
                if (response.data.status.code !== responseResultCodes.OK) {
                    this.emailShowWarning(response.data.status.message);
                }
            };

            // Send email check request using axios post with CSRF token
            //console.log("SignUp / emailOnChange - dataJSON = ", dataJSON);
            postEmailCheck(dataJSON)
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] SignUp / emailOnChange - axios error = ",response);});
        }
    }


    firstNameClearWarning() {
        // Clear previous timer
        clearTimeout(this.firstNameWarning);

        // Border color
        const borderColor = (this.firstNameFocused)? 
            window.colorGray : 
            (
                (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray
            );

        // Update state
        this.setState({
            firstNameBorderColor: borderColor,
            firstNameWarningOn: false,
            firstNameWarningMessage: null
        });
    }

    firstNameShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.firstNameWarning);

        // State to update
        const stateToUpdate = {
            firstNameBorderColor: (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed,
            firstNameWarningOn: true,
            firstNameWarningMessage: warningMessage
        };

        // Update state
        this.firstNameWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    firstNameOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.firstNameClearWarning();

        // Clear previous timer
        clearTimeout(this.firstNameWarning);

        // Read first name
        const firstName = "" + this.firstNameRef.current.value;

        // Not enough letters
        if (firstName.length < 2) {
            if (firstName.length !== 0) {
                this.firstNameShowWarning("Enter a valid first name.");
            }
        }
    }


    lastNameClearWarning() {
        // Clear previous timer
        clearTimeout(this.lastNameWarning);

        // Border color
        const borderColor = (this.lastNameFocused)?
            window.colorGray : 
            (
                (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray
            );

        // Update state
        this.setState({
            lastNameBorderColor: borderColor,
            lastNameWarningOn: false,
            lastNameWarningMessage: null
        });
    }

    lastNameShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.lastNameWarning);

        // State to update
        const stateToUpdate = {
            lastNameBorderColor: (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed,
            lastNameWarningOn: true,
            lastNameWarningMessage: warningMessage
        };

        // Update state
        this.lastNameWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    lastNameOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.lastNameClearWarning();

        // Clear previous timer
        clearTimeout(this.lastNameWarning);

        // Get last name
        const lastName = "" + this.lastNameRef.current.value;

        // Not enough letters
        if (lastName.length < 2) {
            if (lastName.length !== 0) {
                this.lastNameShowWarning("Enter a valid last name.");
            }
        }
    }


    passwordOnceClearWarning() {
        // Clear previous timer
        clearTimeout(this.passwordOnceWarning);

        // Border color
        const borderColor = this.passwordOnceFocused? 
            window.colorGray : 
            (
                (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray
            );

        // Update state
        this.setState({
            passwordOnceBorderColor: borderColor,
            passwordOnceWarningOn: false,
            passwordOnceWarningMessage: null
        });
    }

    passwordOnceShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.passwordOnceWarning);

        // State to update
        const stateToUpdate = {
            passwordOnceBorderColor: (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed,
            passwordOnceWarningOn: true,
            passwordOnceWarningMessage: warningMessage
        };

        // Update state
        this.passwordOnceWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    passwordOnceOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.passwordOnceClearWarning();

        // Clear previous timer
        clearTimeout(this.passwordOnceWarning);

        // Read passwords
        const passwordOnce = "" + this.passwordOnceRef.current.value;
        const passwordTwice = "" + this.passwordTwiceRef.current.value;

        if (passwordOnce.length === 0) {
        }
        else if ((passwordOnce.length > 0) && (passwordOnce.length < 8)) {
            this.passwordOnceShowWarning("Password must be at least 8 characters.");
        }
        else {
            if (passwordTwice) {
                if (passwordOnce === passwordTwice) {
                    this.passwordTwiceClearWarning();
                }
                else {
                    this.passwordTwiceShowWarning("The password does not match.");
                }
            }

            // Check the password
            const errorMessage = this.passwordCheck(passwordOnce);

            // If there is an error message
            if (errorMessage) {
                this.passwordOnceShowWarning(errorMessage);
            }
        }
    }


    passwordTwiceClearWarning() {
        // Clear previous timer
        clearTimeout(this.passwordTwiceWarning);

        // Border color
        const borderColor = this.passwordTwiceFocused?
            window.colorGray : 
            (
                (this.props.colorMode === "day")? 
                window.colorLightGray : window.colorDarkestGray
            );

        // Update state
        this.setState({
            passwordTwiceBorderColor: borderColor,
            passwordTwiceWarningOn: false,
            passwordTwiceWarningMessage: null
        });
    }

    passwordTwiceShowWarning(warningMessage) {
        // Clear previous timer
        clearTimeout(this.passwordTwiceWarning);

        // State to update
        const stateToUpdate = {
            passwordTwiceBorderColor: (this.props.colorMode === "day")? 
                window.colorLightRed : window.colorRed,
            passwordTwiceWarningOn: true,
            passwordTwiceWarningMessage: warningMessage
        };

        // Update state
        this.passwordTwiceWarning = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                null
            ),
            this.delayTime
        );
    }

    passwordTwiceOnChange(event) {
        // Stop propagation to parents
        event.stopPropagation();

        // Clear previous warning
        this.passwordTwiceClearWarning();

        // Clear previous timer
        clearTimeout(this.passwordTwiceWarning);

        // Read passwords
        const passwordOnce = "" + this.passwordOnceRef.current.value;
        const passwordTwice = "" + this.passwordTwiceRef.current.value;

        if (passwordTwice.length !== 0) {
            if (passwordOnce !== passwordTwice) {
                this.passwordTwiceShowWarning("The password does not match.");
            }
        }
    }

    passwordCheck(password) {
        let re,
            count,
            errorMessage,
            errorMessageParts,
            errorMessageSigns;

        errorMessageParts = [];
        errorMessageSigns = [];

        // Set count to 0
        count = 0;

        // Lowercase letter
        re = /[a-z]/;

        if (!re.test(password)) {
            errorMessageParts[0] =  "one lowercase letter";
            errorMessageSigns[0] = 1;
            count += 1;
        }
        else {
            errorMessageParts[0] =  "";
            errorMessageSigns[0] = 0;
        }

        // Uppercase letter
        re = /[A-Z]/;

        if (!re.test(password)) {
            errorMessageParts[1] =  "one uppercase letter";
            errorMessageSigns[1] = 1;
            count += 1;
        }
        else {
            errorMessageParts[1] =  "";
            errorMessageSigns[1] = 0;
        }

        // Number
        re = /[0-9]/;
        if (!re.test(password)) {
            errorMessageParts[2] =  "one number";
            errorMessageSigns[2] = 1;
            count += 1;
        }
        else {
            errorMessageParts[2] =  "";
            errorMessageSigns[2] = 0;
        }

        // Special character
        re = /[!@#$%^&*-+=_.,/?~]/;
        if (!re.test(password)) {
            errorMessageParts[3] =  "one special character";
            errorMessageSigns[3] = 1;
            count += 1;
        }
        else {
            errorMessageParts[3] =  "";
            errorMessageSigns[3] = 0;
        }

        // Construct error message
        errorMessage = "Password must include at least ";

        const firstIndex = errorMessageSigns.indexOf(1);
        const lastIndex = errorMessageSigns.lastIndexOf(1);
        for (let i = 0; i < 4; i++) {
            if (count > 2) {
                if (errorMessageSigns[i] === 1) {
                    if (i === lastIndex) {
                        errorMessage +=  "and " + errorMessageParts[i] + ".";
                    }
                    else {
                        errorMessage += errorMessageParts[i] + ", ";
                    }
                }
            }
            else if (count === 2) {
                if (errorMessageSigns[i] === 1) {
                    if (i === firstIndex) {
                        errorMessage += errorMessageParts[i] + " and ";
                    }
                    else if (i === lastIndex) {
                        errorMessage += errorMessageParts[i] + ".";
                    }
                    else {

                    }
                }
            }
            else {
                if (errorMessageSigns[i] === 1) {
                    errorMessage += errorMessageParts[i] + ".";
                }
            }
        }

        if (count === 0) {
            return false;
        }
        else {
            return errorMessage;
        }
    }


    render() {
        //console.log("SignUp / render - this.props = ", this.props);

        // Button images
        const facebookImage = getStaticPath("/images/common/facebook.png");
        const googleImage =  getStaticPath("/images/common/google.png");

        const cancelButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") : 
            getStaticPath("/images/common/cancel-white.png");
        
        // Render content
        return (
            <div id = "sign-up-modal-container" 
                style = {{ display: (this.props.signUpOn)? "block" : "none" }}
            >
                <div id = {(this.props.browserWidth < 2)?
                        "sign-up-modal-mobile" : "sign-up-modal"}
                    className = {(this.props.colorMode === "day")? 
                        "modal-day" : "modal-night"}
                >
                    <div id = "sign-up-modal__cancel"
                        className = {(this.props.colorMode === "day")? 
                            "image-button-weak-s3" : "image-button-s3"}
                        style = {{ backgroundImage : cancelButtonImage }}
                        onClick = {this.cancelSignUp}
                    >
                    </div>

                    <div id = "sign-up-modal__title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        Become a Member
                    </div>

                    <div className = "sign-up-row">
                        <div id = "sign-up-facebook-container">
                            <div id = "sign-up-facebook-button">
                                <div id = "sign-up-facebook-image"
                                    className = {(this.props.colorMode === "day")?
                                        "image-button-strong-reverse-base" : "image-button-base"}
                                    data-tip data-for = "sign-up-facebook-image-tooltip"
                                    style = {{ backgroundImage : facebookImage }}
                                    onClick = {logInFacebook.bind(null, this.logInFacebookSuccess, this.logInFacebookFailure, this.props.userProfilePicSize)}
                                >
                                </div>
                            </div>
                        </div>
                        <ReactTooltip 
                            id = "sign-up-facebook-image-tooltip" 
                            className = "sign-up-facebook-image-tooltip tooltip-s2"
                            type = "dark" 
                            place = "right"
                        >
                            <span>Sign Up Using a Facebook Account</span>
                        </ReactTooltip>
                        <div id = "sign-up-google-container">
                            <div id = "sign-up-google-image"
                                className = {(this.props.colorMode === "day")?
                                    "image-button-strong-reverse-base" : "image-button-base"}
                                data-tip data-for = "sign-up-google-image-tooltip"
                                style = {{ backgroundImage : googleImage }}
                                onClick = {logInGoogle.bind(null, this.logInGoogleSuccess, this.logInGoogleFailure, this.props.userProfilePicSize)}
                            >
                            </div>
                        </div>
                        <ReactTooltip 
                            id = "sign-up-google-image-tooltip" 
                            className = "sign-up-google-image-tooltip tooltip-s2"
                            type = "dark" 
                            place = "right"
                        >
                            <span>Sign Up Using a Google Account</span>
                        </ReactTooltip>
                    </div>

                    <div id = "sign-up-facebook-google-warning">
                        <div className = {(this.props.colorMode === "day")? 
                                "sign-up-warning warning-day-s2" : "sign-up-warning warning-night-s2"}
                            style = {{ display: (this.state.facebookGoogleWarningOn)? "block" : "none" }}>
                            {this.state.facebookGoogleWarningMessage}
                        </div>
                    </div>

                    <div id = "sign-up-or"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        or
                    </div>
                    <div id= "sign-up-form-container">
                        <form className = "sign-up-form">
                            <div className = "sign-up-form-row">
                                <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-form-text k4" : "sign-up-form-text w4"}
                                >
                                    Email
                                </div>
                                <div className = "sign-up-form-input">
                                    <input
                                        ref = {this.emailRef}
                                        type = "text"
                                        id = "sign-up-email"
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s2 input-day" : "input-s2 input-night"}
                                        placeholder = ""
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.emailOnChange}
                                        style = {{ borderColor: this.state.emailBorderColor }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-warning warning-day-s2" : "sign-up-warning warning-night-s2"}
                                style = {{ 
                                    display: (this.state.emailWarningOn)? "block" : "none", 
                                }}>
                                {this.state.emailWarningMessage}
                            </div>


                            <div className = "sign-up-form-row">
                                <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-form-text k4" : "sign-up-form-text w4"}
                                >
                                    First Name
                                </div>
                                <div className = "sign-up-form-input">
                                    <input
                                        ref = {this.firstNameRef}
                                        type = "text"
                                        id = "sign-up-first-name"
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s2 input-day" : "input-s2 input-night"}
                                        placeholder = ""
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.firstNameOnChange}
                                        style = {{ borderColor: this.state.firstNameBorderColor}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-warning warning-day-s2" : "sign-up-warning warning-night-s2"}
                                style = {{ 
                                    display: (this.state.firstNameWarningOn)? "block" : "none", 
                                }}>
                                {this.state.firstNameWarningMessage}
                            </div>

                            <div className = "sign-up-form-row">
                                <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-form-text k4" : "sign-up-form-text w4"}
                                >
                                    Last Name
                                </div>
                                <div className = "sign-up-form-input">
                                    <input
                                        ref = {this.lastNameRef}
                                        type = "text"
                                        id = "sign-up-last-name"
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s2 input-day" : "input-s2 input-night"}
                                        placeholder = ""
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.lastNameOnChange}
                                        style = {{ borderColor: this.state.lastNameBorderColor}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-warning warning-day-s2" : "sign-up-warning warning-night-s2"}
                                style = {{ 
                                    display: (this.state.lastNameWarningOn)? "block" : "none", 
                                }}>
                                {this.state.lastNameWarningMessage}
                            </div>

                            <div className = "sign-up-form-row">
                                <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-form-text k4" : "sign-up-form-text w4"}
                                >
                                    Password
                                </div>
                                <div className = "sign-up-form-input">
                                    <input
                                        ref = {this.passwordOnceRef}
                                        type = "password"
                                        id = "sign-up-password-once"
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s2 input-day" : "input-s2 input-night"}
                                        placeholder = ""
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.passwordOnceOnChange}
                                        style = {{ borderColor: this.state.passwordOnceBorderColor}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-warning warning-day-s2" : "sign-up-warning warning-night-s2"}
                                style = {{ 
                                    display: (this.state.passwordOnceWarningOn)? "block" : "none", 
                                }}>
                                {this.state.passwordOnceWarningMessage}
                            </div>

                            <div className = "sign-up-form-row">
                                <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-form-text k4" : "sign-up-form-text w4"}
                                >
                                    Re-enter Password
                                </div>
                                <div className = "sign-up-form-input">
                                    <input
                                        ref = {this.passwordTwiceRef}
                                        type = "password"
                                        id = "sign-up-password-twice"
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s2 input-day" : "input-s2 input-night"}
                                        placeholder = ""
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.passwordTwiceOnChange}
                                        style = {{ borderColor: this.state.passwordTwiceBorderColor}}
                                        required
                                    />
                                </div>
                            </div>
                            <div className = {(this.props.colorMode === "day")? 
                                    "sign-up-warning warning-day-s2" : "sign-up-warning warning-night-s2"}
                                style = {{ 
                                    display: (this.state.passwordTwiceWarningOn)? "block" : "none", 
                                }}>
                                {this.state.passwordTwiceWarningMessage}
                            </div>
                        </form>
                        <ReactTooltip 
                            id = "sign-up-form-submit-tooltip" 
                            className = "sign-up-form-submit-tooltip tooltip-s2"
                            type = "dark" 
                            place = "bottom"
                        >
                            <span>Submit</span>
                        </ReactTooltip>
                    </div>
                    <div id = "sign-up-form-submit-container"  
                        style = {{ display: this.checkWarnings()? "block" : "none" }}
                    >
                        <div
                            className = "sign-up-form"
                            onClick = {this.submitClick}
                        >
                            <input
                                type = "submit"
                                className = {(this.props.colorMode === "day")? 
                                    "button-light-blue-s2" : "button-blue-s2"}
                                value = "Sign Up"
                                data-tip data-for = "sign-up-form-submit-tooltip"
                                id = "sign-up-form-submit"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        signUpOn: state.sign_up.signUpOn,
        userInfo: state.user.userInfo,
        saveItinerary: state.itinerary.saveItinerary        
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeSignUpOn,
            storeItinerary,
            storeSaveItinerary
        },
        dispatch
    );
}

// Export component
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);