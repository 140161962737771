/*
============================================================================================
    getMapStyles
--------------------------------------------------------------------------------------------
    - Define map styles
    - Customize google map styles
============================================================================================
*/

function getMapStyles() {
    // set map styles
    const mapStyles = [
        {
            stylers: [
                { hue: "#00aeff" },
                { saturation: -30 },
                { lightness: 0 },
                { gamma: 0.6 }
            ]
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [
                { lightness: 100 },
                { visibility: "simplified" }
            ]
        },
        {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
                { lightness: 60 },
                { visibility: "simplified" }
            ]
        },
        {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
                { hue: "#00aeff" },
                { saturation: 15 },
                { lightness: 0 },
                { gamma: 0.6 }
            ]
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                { hue: "#00b4ff" },
                { saturation: 70 },
                { lightness: 60 },
                { gamma: 0.7 }
            ]
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [
                { lightness: 100 },
                { visibility: "simplified" }
            ]
        },
        {
            featureType: "road",
            elementType: "labels",
            stylers: [
                { visibility: "on" }
            ]
        },
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                { visibility: "off" }
            ]
        }
    ];

    return mapStyles
}


/*
============================================================================================
    pointToLatLng
--------------------------------------------------------------------------------------------
    - Convert point {"latitude": 38.0, "longitude": -122.0} to a google latlng object
============================================================================================
*/
function pointToLatLng(point) {
    return new window.google.maps.LatLng(point.latitude, point.longitude);
}


/*
============================================================================================
    latLngToPoint
--------------------------------------------------------------------------------------------
    - Convert a google latlng object to a point {"latitude": 38.0, "longitude": -122.0}
============================================================================================
*/
function latLngToPoint(latLng) {
    return {"latitude": latLng.lat(), "longitude": latLng.lng()};
}


/*
============================================================================================
    convertToDigits
--------------------------------------------------------------------------------------------
    - Convert degrees / minutes / seconds / direction to digits
============================================================================================
*/

function convertToDigits(degrees, minutes, seconds, direction) {
    
    let dd = degrees + (minutes / 60) + (seconds / 3600);
    
    if (direction === "S" || direction === "W") {
        dd = dd * -1; 
    }
    
    return dd;
}


/* 
============================================================================================
    pointsEqual
--------------------------------------------------------------------------------------------
    - Return true if two points (location objects straight from server) are identical
============================================================================================
*/

function pointsEqual(pointA, pointB) {
    return ((pointA.latitude === pointB.latitude) && (pointA.longitude === pointB.longitude))
}


/*
============================================================================================
    getAverageLocation
--------------------------------------------------------------------------------------------
    - Calcuate the average location of dots
============================================================================================
*/

function getAverageLocation(itinerary, dots) {
    // initialize the average location
    let avgLocation = { "latitude": 0.0, "longitude": 0.0};

    // sum up all locations
    for (let i = 0; i < itinerary.length; i++) {
        avgLocation.latitude += dots[itinerary[i]].location.latitude;
        avgLocation.longitude += dots[itinerary[i]].location.longitude;
    }

    // divide by the number of dots
    avgLocation.latitude /= itinerary.length;
    avgLocation.longitude /= itinerary.length;

    // return the average
    return avgLocation
}


/*
============================================================================================
    calculateDisplacement
--------------------------------------------------------------------------------------------
    - Calcuate the displacement in pixels
============================================================================================
*/

function calculateDisplacement(location, center, map) {
    //const center = map.getCenter();
    const locationProjection = map.getProjection().fromLatLngToPoint(pointToLatLng(location));
    const centerProjection = map.getProjection().fromLatLngToPoint(pointToLatLng(center));
    //console.log("MapFunc / calculateDisplacement - locationProjection = ", locationProjection);
    //console.log("MapFunc / calculateDisplacement - centerProjection = ", centerProjection);

    const pixelSize = Math.pow(2, -map.getZoom());
    //console.log("MapFunc / calculateDisplacement - pixelSize = ", pixelSize);

    const dx = (locationProjection.x - centerProjection.x) / pixelSize;
    const dy = (locationProjection.y - centerProjection.y) / pixelSize;
    //console.log("MapFunc / calculateDisplacement - dx = ", dx);
    //console.log("MapFunc / calculateDisplacement - dy = ", dy);

    return [ dx.toFixed(1), dy.toFixed(1) ];
}


/*
============================================================================================
    Get direction
--------------------------------------------------------------------------------------------
    - Input
        startDotID, endDotID, dots, path
============================================================================================
*/

function getDirection(selectedPathIndex, itinerary, dotIDs, directionInfo, type) {
    //console.log("MapFunc / getDirection - selectedPathIndex = ", selectedPathIndex);
    //console.log("MapFunc / getDirection - itinerary = ", itinerary);
    //console.log("MapFunc / getDirection - dotIDs = ", dotIDs);
    //console.log("MapFunc / getDirection - directionInfo = ", directionInfo);

    // Initialize direction
    let direction = null;

    if (selectedPathIndex !== null) {
        if (type === "trip") {
            let startIndex = null;
            let endIndex = null;

            if (selectedPathIndex === 0) {
                startIndex = 0;
                endIndex = itinerary[selectedPathIndex] + 1;
                //endIndex = dotIDs.indexOf(
                //    itinerary[selectedPathIndex]
                //) + 1;
            }
            else if (selectedPathIndex === itinerary.length) {
                startIndex = itinerary[selectedPathIndex - 1] + 1;
                endIndex = (dotIDs.length + 1);

                //startIndex = dotIDs.indexOf(
                //    itinerary[selectedPathIndex - 1]
                //) + 1;
            }
            else {
                startIndex = itinerary[selectedPathIndex - 1] + 1;
                endIndex = itinerary[selectedPathIndex] + 1;

                //startIndex = dotIDs.indexOf(
                //    itinerary[selectedPathIndex - 1]
                //) + 1;
                //endIndex = dotIDs.indexOf(
                //    itinerary[selectedPathIndex]
                //) + 1;
            }
            //console.log("MapFunc / getDirection - startIndex = ", startIndex);
            //console.log("MapFunc / getDirection - endIndex = ", endIndex);

            direction = directionInfo[startIndex][endIndex];
        }
        else {
            direction = directionInfo[0][selectedPathIndex];
        }
    }
    //console.log("MapFunc / getDirection - direction = ", direction);

    return direction;
}


// Convert degrees to rads
function degToRad(deg) {
    return deg * (Math.PI/180);
}


// Calculate Distance from latitude and longitude
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    let dLat = degToRad(lat2 - lat1);  // deg2rad below
    let dLon = degToRad(lon2 - lon1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d;
}


// Detect User Location
function getUserLocation(successCallback, failureCallback, blockCallback) {
    let userLocation = null;

    // If the browser supports geolocation
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (location) => {
                //console.log("MapFunc / getUserLocation - location = ", location);
                const locationLongitude = location.coords.longitude;
                const locationLatitude = location.coords.latitude;

                userLocation = { longitude: locationLongitude, latitude: locationLatitude };

                successCallback(userLocation);
            },
            blockCallback
        );
    }
    else {
        failureCallback();
    }
}

// Export components and functions
export {
    getMapStyles,
    pointToLatLng,
    latLngToPoint,
    convertToDigits,
    pointsEqual,
    getAverageLocation,
    calculateDisplacement,
    getDirection,
    getDistanceFromLatLonInKm,
    degToRad,
    getUserLocation
};

