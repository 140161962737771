/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    RouteOverview.js
--------------------------------------------------------------------------------------------
    Content
    - Gallery module developed to show highlight dots of a route
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";

// Component
import RouteOverviewItem from "./RouteOverviewItem";
import RouteOverviewStartEndItem from "./RouteOverviewStartEndItem";

// Functions
import {
    debounce
} from "js/Functions";

import {
    dotIDsToIndices
} from "js/TripFunctions";

// CSS
import "./RouteOverview.scss";


// RouteOverview component
class RouteOverview extends Component{
    constructor (props){
        super(props);
        //console.log("RouteOverview / constructor - props = ", props);

        // Left column widths
        this.leftColumnWidth = 120;
        this.leftColumnWidthMedium = 90;
        this.leftColumnWidthSmall = 60;

        // Gallery settings
        // Minimum / scale / maximum content widths
        this.minContentWidth = 320;
        this.scaleContentWidth = 600;
        this.maxContentWidth = 900;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media settings
        this.mediaArea = 240000;
        this.mediaMarginWidth = 10;

        // Thumbnail settings
        this.thumbnailArea = 12000;
        this.thumbnailMarginWidth = 10;

        // Number of thumbnails
        this.numThumbnailsSmall = 2;
        this.numThumbnailsMedium = 3;
        this.numThumbnailsLarge = 4;

        // Editor and staff section settings
        this.editorWidth = 72;
        this.staffItemWidth = 36;
        this.staffMarginWidth = 2;
        this.contributorsMinWidth = 100;
        this.helpersMinWidth = 80;
        this.numStaffProfilePics = 4;

        // Initialize states
        this.state = {
            // Number of dots
            numDots: this.props.dotsInfo.length,

            // Scroll
            checkScroll: false
        }

        // Bind functions
        this.setState = this.setState.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    render(){
        let routeOverviewList, routeOverviewItemProps;

        /*
        ============================================================================================
            Left Column
        ============================================================================================
        */

        // Left column width
        const leftColumnWidth = (this.props.browserWidth <= 4)?
            this.leftColumnWidthSmall :
            (
                (this.props.browserWidth <= 8)?
                    this.leftColumnWidthMedium :
                    this.leftColumnWidth
            );


        /*
        ============================================================================================
            Thumbnails
        ============================================================================================
        */

        // Thumbnail area
        const thumbnailArea = (this.props.browserWidth <= 3)?
            this.thumbnailArea * 0.5 : (
                (this.props.browserWidth <= 6)?
                    this.thumbnailArea * 0.75 : this.thumbnailArea
            );

        // Number of thumbnails
        const numThumbnails = (this.props.browserWidth <= 6)?
            this.numThumbnailsSmall : (
                (this.props.browserWidth <= 8)?
                    this.numThumbnailsMedium : this.numThumbnailsLarge
            );


        /*
        ============================================================================================
            For All Highlights
        ============================================================================================
        */

        // Only if all information is ready
        if ((this.props.highlights) && (this.props.dotsInfo)) {
            //console.log("RouteOverview / render - this.props = ", this.props);

            // Get the dot indices of highlights
            const highlightIndices = (this.props.renderItinerary)?
                dotIDsToIndices(this.props.route, this.props.dotsInfo) :
                dotIDsToIndices(this.props.highlights, this.props.dotsInfo);
            //console.log("RouteOverview / render - this.props.highlights = ", this.props.highlights);
            //console.log("RouteOverview / render - this.props.dotsInfo = ", this.props.dotsInfo);
            //console.log("RouteOverview / render - highlightIndices = ", highlightIndices);
            //console.log("RouteOverview / render - this.state.selectedImageIndices = ", this.state.selectedImageIndices);

            routeOverviewList = highlightIndices.map((dotIndex, index) => {
                // Figure out if this is the top or the bottom
                let isTop = (index === 0)? true : false;
                let isBottom = (index === (highlightIndices.length - 1))? true: false;
                let isRoundtripBottom = false;

                if (this.props.renderItinerary) {
                    isTop = false;
                    isBottom = false;
                    isRoundtripBottom = (this.props.roundtrip) && (index === (highlightIndices.length - 1))? true : false;
                }
                //console.log("RouteOverview / render - index = ", index);
                //console.log("RouteOverview / render - dotIndex = ", dotIndex);
                //console.log("RouteOverview / render - highlights = ", this.props.highlights);
                //console.log("RouteOverview / render - isTop = ", isTop);
                //console.log("RouteOverview / render - isBottom = ", isBottom);
                //console.log("RouteOverview / render - isRoundtripBottom = ", isRoundtripBottom);

                // Timestamp and transit
                const dotTimestamp = (this.props.renderItinerary)? this.props.itineraryInfo.dot_start_times_local_formatted[index] : null;
                let transitTimestamp = null;
                let transitMode = null;
                if (this.props.renderItinerary) {
                    //transitTimestamp = this.props.itineraryInfo.transit_time[index + 1];
                    transitTimestamp = (isRoundtripBottom)? "Turnaround" : this.props.itineraryInfo.transit_time[index + 1];
                    transitMode = (isRoundtripBottom)? "turnaround" : this.props.itineraryInfo.transit_mode[index + 1];
                }

                // Parse properties for individual item
                routeOverviewItemProps = {
                    // Itinerary
                    renderItinerary: this.props.renderItinerary,

                    // Left column
                    leftColumnWidth: leftColumnWidth,

                    // Number index
                    number: index + 1,
                    index: index,

                    // Timestamps
                    dotTimestamp: dotTimestamp,
                    transitTimestamp: transitTimestamp,
                    transitMode: transitMode,

                    // Position
                    isTop: isTop,
                    isBottom: isBottom,
                    isRoundtripBottom: isRoundtripBottom,

                    // Selected
                    dotIndex: dotIndex,

                    // Dot information
                    dotInfo: this.props.dotsInfo[dotIndex],

                    // Editor and staff widths
                    editorWidth: this.editorWidth,
                    staffItemWidth: this.staffItemWidth,
                    staffMarginWidth: this.staffMarginWidth,
                    contributorsMinWidth: this.contributorsMinWidth,
                    helpersMinWidth: this.helpersMinWidth,
                    numStaffProfilePics: this.numStaffProfilePics,

                    // Gallery settings
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: this.maxContentWidth,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    mediaArea: this.mediaArea,
                    mediaMarginWidth: this.mediaMarginWidth,
                    thumbnailArea: thumbnailArea,
                    thumbnailMarginWidth: this.thumbnailMarginWidth,
                    numThumbnails: numThumbnails,

                    // Opacity transition time
                    transitionTime: this.transitionTime,

                    // Set state
                    setState: this.props.setState,

                    // Scroll
                    checkScroll: this.state.checkScroll
                };

                // Return an individual item
                return(
                    <div key = {"route-overview-item-container-" + dotIndex}
                        id = {"route-overview-item-container-" + dotIndex}
                        className = "route-overview-item-container"
                    >
                        <RouteOverviewItem
                            key = {"route-overview-item-" + dotIndex +
                                "-browser-width-" + this.props.browserWidthPixels}
                            {...routeOverviewItemProps}
                        />
                    </div>
                );
            });
        }

        const routeOverviewStartItem = (this.props.renderItinerary)? (
            <div key = {"route-overview-item-container-start"} className = "route-overview-item-container" >
                <RouteOverviewStartEndItem
                    colorMode = {this.props.colorMode}
                    isStart = {true}
                    roundtrip = {this.props.roundtrip}
                    renderItinerary = {this.props.renderItinerary}
                    endTimestamp = {this.props.startMoment.format("h:mm a")}
                    transitTimestamp = {this.props.itineraryInfo.transit_time[0]}
                    transitMode = {this.props.itineraryInfo.transit_mode[0]}
                    leftColumnWidth = {this.leftColumnWidth}
                >
                    {this.props.children}
                </RouteOverviewStartEndItem>
            </div>
        ) : null;

        const routeOverviewEndItem = (this.props.renderItinerary)? (
            <div key = {"route-overview-item-container-end"} className = "route-overview-item-container" >
                <RouteOverviewStartEndItem
                    colorMode = {this.props.colorMode}
                    isStart = {false}
                    roundtrip = {this.props.roundtrip}
                    renderItinerary = {this.props.renderItinerary}
                    leftColumnWidth = {this.leftColumnWidth}
                    endTimestamp = {this.props.endMoment.format("h:mm a")}
                >
                </RouteOverviewStartEndItem>
            </div>
        ) : null;

        // Render the list of items
        return(
            <div className = "route-overview-list-container">
                <div className = "route-overview-list">
                    {routeOverviewStartItem}
                    {routeOverviewList}
                    {routeOverviewEndItem}
                </div>
            </div>
        )
    }

    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
    };
}

export default connect(mapStateToProps, null)(RouteOverview);