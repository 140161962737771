// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";

// Modules
import ReactTooltip from "thedots-tooltip";

// Components
import {
    UserProfilePicList,
} from "js/Common";

// Functions
import {
	getStaticPath,
    getMediaProperty,
    formatDatetime
} from "js/Functions";


class ChatWindow extends Component {
	constructor(props) {
		super(props);

        // Comment input
        this.chatWindowInputRef = React.createRef();
        this.chatWindowInputPlaceholder = "";
        this.chatWindowInputID = "chat-window-" + this.props.index + "-input";
        this.chatWindowMessagesRef = React.createRef();
        this.chatWindowMessagesID = "chat-window-" + this.props.index + "-messages";

        // Scroll timer
        this.scrollToBottomTimer = null;

        // Initialize state
        this.state = {
        };

        // Bind functions
        this.chatWindowInputResize = this.chatWindowInputResize.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.sendChatMessage = this.sendChatMessage.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
	}


	render() {
		const chatMessages = this.props.chatMessagesInfo.map((chatMessageInfo, index) => {
			const chatMessageIsFromMe = (chatMessageInfo.user === this.props.userInfo.id);

			// Message continued
			let chatMessageContinued = null;
			if (index === 0) {
				chatMessageContinued = false;
			}
			else {
				chatMessageContinued = (this.props.chatMessagesInfo[index - 1].user === this.props.chatMessagesInfo[index].user);
			}

			// Sender info
			let senderInfo = null;
			for (let i = 0; i < this.props.chatInfo.users.length; i++) {
				if ((chatMessageInfo.user === this.props.chatInfo.users[i].id)
					&& (chatMessageInfo.user !== this.props.userInfo.id)) {
					senderInfo = this.props.chatInfo.users[i];
				}
			}

			console.log("ChatWindow / render - chatMessageInfo.timestamp - ", chatMessageInfo.timestamp);
			console.log("ChatWindow / render - typeof chatMessageInfo.timestamp - ", typeof chatMessageInfo.timestamp);

			// Insert date bar
			let dateBar = null;
			if (index === 0) {
				dateBar = (
					<div className = {(this.props.colorMode === "day")?
							"chat-message-date-bar number-lb5" :
							"chat-message-date-bar number-b5"}
					>
						{formatDatetime(chatMessageInfo.timestamp, false, true, "/")}
					</div>
				);
			}
			else {
				const date = new Date(chatMessageInfo.timestamp * 1000);
				const previousDate = new Date(this.props.chatMessagesInfo[index - 1].timestamp * 1000);
				if (date.getDate() !== previousDate.getDate()) {
					dateBar = (
						<div className = {(this.props.colorMode === "day")?
								"chat-message-date-bar number-lb5" :
								"chat-message-date-bar number-b5"}
						>
							{formatDatetime(chatMessageInfo.timestamp, false, true, "/")}
						</div>
					);
				}
			}

			return (
				<div className = "chat-message-container"
					key = {"chat-window-" + this.props.chatInfo.id.toString() + "-message-" + index.toString()}
				>
					{dateBar}
					<ChatMessage
						colorMode = {this.props.colorMode}
						chatID = {this.props.chatInfo.id}
						messageIndex = {index}
						chatMessageIsFromMe = {chatMessageIsFromMe}
						chatMessageContinued = {chatMessageContinued}
						chatMessageInfo = {chatMessageInfo}
						senderInfo = {senderInfo}
					/>
				</div>
			);
		});

		// Calculate right margin
		const rightMargin = (this.props.chatWindowWidth + this.props.chatWindowSpacing)
			* this.props.index + this.props.chatWindowEndSpacing + this.props.chatWindowEndMargin;

		// Users except me
		const users = [];
		for (let i = 0; i < this.props.chatInfo.users.length; i++) {
			if (this.props.chatInfo.users[i].id !== this.props.userInfo.id) {
				users.push(this.props.chatInfo.users[i]);
			}
		}

		// User profile pic list
	    const userProfilePicListProps = {
	    	colorMode: this.props.colorMode,
	        numProfilePics : this.props.chatNumProfilePics,
	        usersInfo : users,
	        userCount : users.length,
	        userTypeLabel : null,
	        userTypeLabelOn: false,
	        moreBlankOn: false,
	        classNamePrefix: "chat-window",
	        classNameUserType: "user",
	        index: this.props.index
	    };

	    // User names
	    let userNames = "";
	    if (users.length > this.props.chatNumProfilePics) {
		    for (let i = 0; i < users.length - 1; i++) {
		    	userNames += (users[i].name + ", ");
		    }
		    userNames += users[users.length - 1].name + " and " + (users.length - this.props.chatNumProfilePics) + " more";
	    }
	    else if ((users.length >= 2) && (users.length <= this.props.chatNumProfilePics)) {
		    for (let i = 0; i < users.length - 2; i++) {
		    	userNames += (users[i].name + ", ");
		    }
		    userNames += users[users.length - 2].name + " and " + users[users.length - 1].name;
		}
		else {
	    	userNames = users[0].name;
		}

		// Close button image
		const closeButtonImage = (this.props.colorMode === "day")?
			getStaticPath("/images/common/cancel-black.png") :
			getStaticPath("/images/common/cancel-white.png");
		const closeButtonID = "chat-window-close-button" + this.props.chatIndex.id;

        // Create chat window input JSX block
        const chatWindowInputProps = {
            type: "text",
            rows: 1,
            placeholder: this.chatWindowInputPlaceholder,
            onKeyDown: this.handleSubmit,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            autoComplete: "off",
            minLength: 2,
            required: "required"
        };

        // Comment input
        const chatWindowInput = (
            <textarea
            	ref = {this.chatWindowInputRef}
                id = {this.chatWindowInputID}
                className = {(this.props.colorMode === "day")?
	                "chat-window-input-day" : "chat-window-input-night"}
                {...chatWindowInputProps}
            />
        );

		return(
			<div className = "chat-window"
				style = {{
					width: this.props.chatWindowWidth,
				 	right: rightMargin
				}}
			>
				<div className = {(this.props.colorMode === "day")?
						"chat-window-header-day" : "chat-window-header-night"}
				>
					<div className = "chat-window-close-button image-button-s7"
						style = {{ backgroundImage: closeButtonImage }}
						onClick = {() => {this.props.closeChat(this.props.index, this.props.chatIndex);}}
						data-tip = "Close Chat"
						data-for = {closeButtonID}
					>
					</div>
	                <UserProfilePicList {...userProfilePicListProps} />
	                <div className = {(this.props.colorMode === "day")?
	                		"chat-window-user-names k5" :
	                		"chat-window-user-names w5"}
                	>

	                	{userNames}
	                </div>
	                <ReactTooltip
	                    id = {closeButtonID}
	                    className = "chat-window-close-button-tooltip tooltip-s2"
	                    type = "dark"
	                    place = "right"
	                    html={true}
	                />
				</div>
				<div ref = {this.chatWindowMessagesRef}
					id = {this.chatWindowMessagesID}
					className = {(this.props.colorMode === "day")?
						"chat-window-body-day" : "chat-window-body-night"}
				>
					{chatMessages}
				</div>
				<div className = {(this.props.colorMode === "day")?
						"chat-window-footer-day" : "chat-window-footer-night"}
				>
					{chatWindowInput}
				</div>
			</div>
		);
	}


	componentDidMount() {
        // Set up event listeners for comment input
        this.chatWindowInputRef.current.addEventListener("change", this.chatWindowInputResize);
        this.chatWindowInputRef.current.addEventListener("cut", this.chatWindowInputResize);
        this.chatWindowInputRef.current.addEventListener("paste", this.chatWindowInputResize);
        this.chatWindowInputRef.current.addEventListener("drop", this.chatWindowInputResize);
        //this.chatWindowInputRef.current.addEventListener("keydown", this.chatWindowInputResize);

        // Set up event listener for scroll
       	this.chatWindowMessagesRef.current.addEventListener("scroll", this.handleScroll);

       	// Scroll to the bottom
       	this.scrollToBottom();
	}

	componentDidUpdate(prevProps, prevState) {
		//console.log("prevProps.updateScroll = ", prevProps.updateScroll);
		//console.log("this.props.updateScroll = ", this.props.updateScroll);

		if ((prevProps.updateScroll === false) && (this.props.updateScroll === true) && (this.scrollToBottomTimer === null)) {
	       	// Scroll to the bottom
	       	this.scrollToBottomTimer = setTimeout(
	       		() => {
	       			// Scroll to bottom
	       			this.scrollToBottom();

	       			// Reset the timer
	       			this.scrollToBottomTimer = null;
	       		},
	       		100
	       	);
		}
	}

	componentWillUnmount() {
        // Remove event listeners for comment input
        this.chatWindowInputRef.current.removeEventListener("change", this.chatWindowInputResize);
        this.chatWindowInputRef.current.removeEventListener("cut", this.chatWindowInputResize);
        this.chatWindowInputRef.current.removeEventListener("paste", this.chatWindowInputResize);
        this.chatWindowInputRef.current.removeEventListener("drop", this.chatWindowInputResize);
        //this.chatWindowInputRef.current.removeEventListener("keydown", this.chatWindowInputResize);

        // Remove event listener for scroll
       	this.chatWindowMessagesRef.current.removeEventListener("scroll", this.handleScroll);
	}


    chatWindowInputResize() {
        window.setTimeout(
            () => {
                this.chatWindowInputRef.current.style.height = "auto";
                this.chatWindowInputRef.current.style.height = this.chatWindowInputRef.current.scrollHeight + "px";
            },
            0
        );
    }


    handleFocus() {
        // Clear the placeholder
        this.chatWindowInputRef.current.placeholder = "";

        // Text align
        this.chatWindowInputRef.current.style.textAlign = "left";
    }


    handleBlur() {
        // Reset the placeholder
        this.chatWindowInputRef.current.placeholder = this.chatWindowInputPlaceholder;

        // Text align
        if (this.chatWindowInputRef.current.value.length > 0) {
            this.chatWindowInputRef.current.style.textAlign = "left";
        }
        else {
            this.chatWindowInputRef.current.style.textAlign = "center";
        }
    }


    handleReset() {
        // Reset the input value
        this.chatWindowInputRef.current.value = "";
        // Reset chatWindowInputRef.current height
        window.setTimeout(
            () => {
                this.chatWindowInputRef.current.style.height = "auto";
                this.chatWindowInputRef.current.style.height = this.chatWindowInputRef.current.scrollHeight + "px";
            },
            0
        );
    }


    handleSubmit(e) {
        //console.log("Board / handleSubmit - e.target = ", e.target);

        // Submit only when enter is pressed
        if (e.keyCode === 13 && e.shiftKey === false) {
	        // Stop propagation to parents
	        e.stopPropagation();

            // Send message only if there is any content excluding whitespace
            if (this.chatWindowInputRef.current.value.replace(/[\s]/g, "").length > 0) {
                this.sendChatMessage();
            }

            // Reset and Blur the comment input
            this.handleReset();
            this.handleBlur();
        }
        else {
            this.chatWindowInputResize();
        }
    }


    sendChatMessage() {
        // Create chat message JSON for websocket communication
        const message = JSON.stringify({
            request_type: "chat_message",
            user_id: this.props.userInfo.id,
            chat_id: this.props.chatInfo.id,
            content: this.chatWindowInputRef.current.value,
            group: this.props.webSocketChatGroupPrefix + "_" + this.props.chatInfo.id
        });
        //console.log("ChatWindow / sendChatMessage - message = ", message);

        window.webSocket.send(message);
    }


    scrollToBottom() {
    	//console.log("ChatWindow / scrollToBottom");
		this.chatWindowMessagesRef.current.scrollTop = this.chatWindowMessagesRef.current.scrollHeight;
    }


    handleScroll(e) {
        //console.log("ChatWindow / handleScroll - e = ", e);

        // Stop propagation to parents
        //e.stopPropagation();

    	// If end of scroll is reached
		//console.log("ChatWindow / handleScroll - this.chatWindowMessagesRef.current.scrollHeight = ", this.chatWindowMessagesRef.current.scrollHeight);
		//console.log("ChatWindow / handleScroll - this.chatWindowMessagesRef.current.scrollTop = ", this.chatWindowMessagesRef.current.scrollTop);

		if (this.chatWindowMessagesRef.current.scrollTop === 0) {
			//console.log("ChatWindow / handleScroll - end of scroll");

			// Fetch more chat messages
			if (!this.props.chatMessagesFetchComplete) {
				//console.log("ChatWindow / handleScroll - fetching more messages");
				this.props.getChatMessagesInfo(this.props.chatInfo.id, this.props.chatIndex);
			}
		}
    }
}


function ChatMessage(props) {
	// Tooltip
	const messageTooltipID = "chat-" + props.chatID + "-message-" + props.messageIndex + "-tooltip";
	const messageDate = formatDatetime(props.chatMessageInfo.timestamp, true, false, " ");

	// My message
	if (props.chatMessageIsFromMe) {
		return(
			<div className = "chat-my-message">

				<div className = {(props.colorMode === "day")?
						"chat-my-message-content-day comment-k5" :
						"chat-my-message-content-night comment-w5"}
					data-tip = {messageDate}
					data-for = {messageTooltipID}
				>
					{props.chatMessageInfo.content}
				</div>
                <ReactTooltip
                    id = {messageTooltipID}
                    className = "chat-message-tooltip tooltip-s2"
                    type = "info"
                    place = "bottom"
                    html={true}
                />
			</div>
		);
	}
	// Someone else"s message
	else {
        const senderProfileImage = (props.senderInfo.profile_pic == null)?
	        (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            ) : getMediaProperty(props.senderInfo.profile_pic, "t", "url", true);

		const senderProfilePic = (!props.chatMessageContinued)?
		(
			<div className = {(props.colorMode === "day")?
					"chat-message-sender-profile-pic profile-image-s6 border-day" :
					"chat-message-sender-profile-pic profile-image-s6 border-night"}
				style = {{ backgroundImage: senderProfileImage }}
			>
			</div>
		) : null;

		return(
			<div className = "chat-message">
				<div className = "chat-message-sender">
					{senderProfilePic}
				</div>
				<div className = {(props.colorMode === "day")?
						"chat-message-content-day comment-k5" :
						"chat-message-content-night comment-w5"}
					data-tip = {messageDate}
					data-for = {messageTooltipID}
				>
					{props.chatMessageInfo.content}
				</div>
                <ReactTooltip
                    id = {messageTooltipID}
                    className = "chat-message-tooltip tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html={true}
                />
	       	</div>
		);
	}
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
    };
}

export default connect(mapStateToProps, null)(ChatWindow);
