// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 

import InputRange from "thedots-input-range";

// Functions
import { 
    getStaticPath, 
    formatDuration, 
} from "js/Functions";

// Modules
import DatePicker from "react-datepicker";
import TimeKeeper from "thedots-timekeeper";
import ReactTooltip from "thedots-tooltip";

// CSS
import "./DotEditContributor.scss";


class DotEditContributor extends Component {
    constructor (props) {
        super (props);

        // Slider labels and constant presets
        this.bestTimeLabels = [ "Sunrise", "Morning", "Noon", "Afternoon", "Sunset" ];
        this.bestTimeTypes = [ "SR", "MO", "NO", "AF", "SS" ];
        this.physicalityLabels = ["0 - No Effort", "0.5", "1 - Easy / Stroll", "1.5", "2 - Slow Walk", "2.5 - Moderate / Regular Walk", "3 - Fast Walk", "3.5", "4 - Streneous / Jogging", "4.5", "5 - Extreme / Running" ];
        this.durationScales = [ 3600, 7200, 14400, 28800, 57600 ];

        // Bind callbacks
        this.formatDuration = formatDuration.bind(this);
    }

    render() {
        console.log("DotEditContributor / render - this.props = ", this.props);

        /*
        ============================================================================================
            Dot Type Menu
        ============================================================================================
        */

        // Images
        const scenicDotImage = (this.props.dotType === "SC")? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-scenic-light-blue.png") :
                    getStaticPath("/images/common/category-scenic-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-scenic-black.png") :
                    getStaticPath("/images/common/category-scenic-white.png")
            );

        const experienceDotImage = (this.props.dotType === "EX")? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-experience-light-blue.png") :
                    getStaticPath("/images/common/category-experience-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-experience-black.png") :
                    getStaticPath("/images/common/category-experience-white.png")
            );

        const dineDotImage = (this.props.dotType === "DI")? 
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/category-dine-light-blue.png") :
                    getStaticPath("/images/common/category-dine-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-dine-black.png") :
                    getStaticPath("/images/common/category-dine-white.png")
            );

        const dotTypeMenu = (
            <div className = "dot-edit-contributor-row">
                <div className = {(this.props.colorMode === "day")? 
                        "dot-edit-type-title k4": "dot-edit-type-title w4"}
                    data-tip = "<p>The Type of Dot</p><p>You Want to Create</p>"
                    data-for = "dot-edit-type-title-tooltip"
                >
                    Category
                </div>
                <div id = "dot-edit-type-container"
                    className = "clear-fix"
                >
                    <div className = "dot-edit-type-float"
                        style = {
                            (this.props.dotType === "SC")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "dot-edit-type-scenic"
                            className = "image-contain"
                            style = {{ backgroundImage: scenicDotImage }}
                            onClick = {this.props.scenicTypeClick}
                            data-tip = "Scenic Dot"
                            data-for = "dot-edit-type-scenic-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "dot-edit-type-scenic-tooltip"
                        className = "dot-edit-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />

                    <div className = "dot-edit-type-float"
                        style = {
                            (this.props.dotType === "EX")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "dot-edit-type-experience"
                            className = "image-contain"
                            style = {{ backgroundImage: experienceDotImage }}
                            onClick = {this.props.experienceTypeClick}
                            data-tip = "Experiences"
                            data-for = "dot-edit-type-experience-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "dot-edit-type-experience-tooltip"
                        className = "dot-edit-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />

                    <div className = "dot-edit-type-float"
                        style = {
                            (this.props.dotType === "DI")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "dot-edit-type-dine"
                            className = "image-contain"
                            style = {{ backgroundImage: dineDotImage }}
                            onClick = {this.props.dineTypeClick}
                            data-tip = "A Restaurant or Diner"
                            data-for = "dot-edit-type-dine-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "dot-edit-type-dine-tooltip"
                        className = "dot-edit-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />
                </div>
                <ReactTooltip
                    id = "dot-edit-type-title-tooltip"
                    className = "dot-edit-type-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Season Closure
        ============================================================================================
        */
        const createClosureDateArrow = (this.props.colorMode === "day")?
            getStaticPath("/images/create/dates-arrow-black.png") :
            getStaticPath("/images/create/dates-arrow-white.png");

        const closureDates = (
            <div id = "dot-edit-season-closure-dates"
                style = {{ display: ((this.props.seasonClosureType === "estimate") || (this.props.seasonClosureType === "exact"))? "block" : "none" }}
            >
                <div id = "dot-edit-season-closure-start-date"
                    data-tip = {(this.props.seasonClosureType === "estimate")? "Start Date (Estimated)" : "Start Date (Exact)"}
                    data-for = "dot-edit-closure-start-date-tooltip">
                    <DatePicker
                        readOnly = {true}
                        dateFormat = "MMM D"
                        className = "dot-edit-season-closure-date-input font-cabin"
                        selected = {this.props.closureStartDate}
                        onChange = {this.props.setClosureStartDate}
                    />
                </div>
                <div id = "dot-edit-season-closure-date-arrow" 
                    className = "image-contain"
                    style = {{ backgroundImage:  createClosureDateArrow }}
                >
                </div>
                <div id = "dot-edit-season-closure-end-date"
                    data-tip = {(this.props.seasonClosureType === "estimate")? "End Date (Estimated)" : "End Date (Exact)"}
                    data-for = "dot-edit-closure-end-date-tooltip">
                    <DatePicker
                        readOnly = {true}
                        dateFormat = "MMM D"
                        className = "dot-edit-season-closure-date-input font-cabin"
                        selected = {this.props.closureEndDate}
                        onChange = {this.props.setClosureEndDate}
                    />
                    <ReactTooltip
                        id = "dot-edit-closure-end-date-tooltip"
                        className = "dot-edit-closure-date-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />
                </div>
                <ReactTooltip
                    id = "dot-edit-closure-start-date-tooltip"
                    className = "dot-edit-closure-date-tooltip tooltip-s2"
                    type = "dark"
                    place = "top"
                />                
            </div>
        );

        const seasonClosure = (
            <div className = "dot-edit-contributor-row"
                style = {{ addingBottom: (this.props.seasonClosureType === "estimate")? 26 : 16 }}
            >
                <div id = "dot-edit-season-closure-date-title"
                    data-tip = "<p>Estimated Dates of</p><p>Seasonal Closure</p>"
                    data-for = "dot-edit-season-closure-title-tooltip"
                >
                    <div className = {(this.props.colorMode === "day")?
                            "dot-edit-season-closure-date-title-text k4" :
                            "dot-edit-season-closure-date-title-text w4"}
                    >
                        Season
                    </div>
                    <div className = {(this.props.colorMode === "day")?
                            "dot-edit-season-closure-date-title-text k4" :
                            "dot-edit-season-closure-date-title-text w4"}
                    >
                        Closure
                    </div>
                </div>
                <ReactTooltip
                    id = "dot-edit-season-closure-title-tooltip"
                    className = "dot-edit-season-closure-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />

                <div id = "dot-edit-season-closure-dates-container">
                    {closureDates}
                    <div id = "dot-edit-season-closure-date-type">
                        <div id = "dot-edit-season-closure-date-estimate"
                            className = {(this.props.colorMode === "day")?
                                "dot-edit-season-closure-date-button button-light-blue-s3" :
                                "dot-edit-season-closure-date-button button-blue-s3"}
                            style = {
                                (this.props.seasonClosureType === "estimate")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = { 
                                () => {
                                    this.props.setSeasonClosureType("estimate");
                                }
                            }
                        >
                            Estimated
                        </div>
                        <div id = "dot-edit-season-closure-date-open"
                            className = {(this.props.colorMode === "day")?
                                "dot-edit-season-closure-date-button button-light-blue-s3" :
                                "dot-edit-season-closure-date-button button-blue-s3"}
                            style = {
                                (this.props.seasonClosureType === "open")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = { 
                                () => {
                                    this.props.setSeasonClosureType("open");
                                }
                            }
                        >
                            Open All Year
                        </div>
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Rating
        ============================================================================================
        */
        const ratingTooltipText = (this.props.dotType === "RT" || this.props.dotType === "TR")?
            "<p>Overall Satisfaction</p><p>Rating of the Trip</p>"
            : "<p>Overall Satisfaction</p><p>Rating of the Place</p><p>or Experience</p>";

        const ratingSlider = (
            <div className = "dot-edit-detail-row">
                <div id = "dot-edit-rating-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = {ratingTooltipText}
                    data-for = "dot-edit-rating-title-tooltip"
                >
                    Rating
                </div>
                <div id = "dot-edit-rating">
                    <InputRange
                        classNames = {this.props.sliderClassNames}
                        maxValue = {5}
                        minValue = {0}
                        value = {this.props.rating}
                        onChange = {rating => this.props.setRating(rating)}
                        formatLabel = {rating => {return rating}}
                        showEndLabels = {false}
                        step = {0.5}
                    />
                </div>
                <ReactTooltip
                    id = "dot-edit-rating-title-tooltip"
                    className = "dot-edit-rating-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Physicality
        ============================================================================================
        */

        const physicalitySlider = (
            <div className = "dot-edit-contributor-row"
                style = {{ display: ((this.props.dotType === "SC") || (this.props.dotType === "EX"))? "block" : "none" }}
            >
                <div id = "dot-edit-physicality-title"
                    className = {(this.props.colorMode === "day")? "k4": "w4"}
                    data-tip = "<p>Physical Intensity of</p><p>the Stay or Experience</p>"
                    data-for = "dot-edit-physicality-title-tooltip"
                >
                    Physicality
                </div>
                <div id = "dot-edit-physicality">
                    <InputRange
                        classNames = {this.props.sliderClassNames}
                        maxValue = {5}
                        minValue = {0}
                        value = {this.props.physicality}
                        onChange = {
                            (physicality) => { this.props.setPhysicality(physicality); }
                        }
                        formatLabel = {
                            (physicality) => { return this.physicalityLabels[String(Math.round(physicality / 0.5))] }
                        }
                        showEndLabels = {false}
                        step = {0.5}
                    />
                </div>
                <ReactTooltip
                    id = "dot-edit-physicality-title-tooltip"
                    className = "dot-edit-physicality-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Duration Slider
        ============================================================================================
        */

        const sliderIncreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/increase-black.png") :
            getStaticPath("/images/common/increase-white.png");

        const sliderDecreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/decrease-black.png") :
            getStaticPath("/images/common/decrease-white.png");

        let durationTitle = null;
        if (this.props.dotType === "SC") {
            durationTitle = "Stay Time";
        } 
        else if (this.props.dotType === "EX" || this.props.dotType === "DE") {
            durationTitle = "Experience Time";
        }
        else if (this.props.dotType === "DI") {
            durationTitle = "Dine Time";
        }

        const durationSlider = (
            <div className = "dot-edit-contributor-row">
                <div id = "dot-edit-duration-title"
                    className = {(this.props.colorMode === "day")? "k4": "w4"}
                    data-tip = "<p>Needed Time to</p><p>Enjoy the Place</p>"
                    data-for = "dot-edit-duration-title-tooltip"
                >
                    {durationTitle}
                </div>
                <div id = "dot-edit-duration">
                    <div id = "dot-edit-duration-slider-increase"
                        data-tip = "Increase Time Scale"
                        data-for = "dot-edit-duration-slider-increase-tooltip"
                        style = {{
                            backgroundImage: sliderIncreaseImage,
                            display: (this.props.durationScaleIndex === (this.durationScales.length - 1))? "none" : "block"
                        }}
                        onClick = {this.props.setDurationIncrease}
                    >
                    </div>
                    <div id = "dot-edit-duration-slider-decrease"
                        data-tip = "Decrease Time Scale"
                        data-for = "dot-edit-duration-slider-decrease-tooltip"
                        style = {{
                            backgroundImage: sliderDecreaseImage,
                            display: (this.props.durationScaleIndex === 0)? "none" : "block"
                        }}
                        onClick = {this.props.setDurationDecrease}
                    >
                    </div>
                    <InputRange
                        classNames = {this.props.sliderClassNames}
                        maxValue = {this.durationScales[this.props.durationScaleIndex]}
                        minValue = {300}
                        value = {this.props.duration}
                        onChange = {duration => this.props.setDuration(duration)}
                        formatLabel = {duration => this.formatDuration(duration)}
                        showEndLabels = {false}
                        step = {300}
                    />
                </div>
                <ReactTooltip
                    id = "dot-edit-duration-title-tooltip"
                    className = "dot-edit-duration-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
                <ReactTooltip
                    id = "dot-edit-duration-slider-decrease-tooltip"
                    className = "dot-edit-duration-slider-decrease-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                />
                <ReactTooltip
                    id = "dot-edit-duration-slider-increase-tooltip"
                    className = "dot-edit-duration-slider-increase-tooltip tooltip-s2"
                    type = "dark"
                    place = "right"
                />
            </div>
        );


        /*
        ============================================================================================
            Best Time
        ============================================================================================
        */

        const bestTimeSlider = (
            <div className = "dot-edit-contributor-row"
                style = {{ display: (this.props.bestTimeOn)? "block" : "none" }}
            >
                <div id = "dot-edit-best-time-title"
                    className = {(this.props.colorMode === "day")? "k4": "w4"}
                    data-tip = "<p>Best Time of the Day</p><p>to Experience the Place</p>"
                    data-for = "dot-edit-best-time-title-tooltip"
                >
                    Best Time
                </div>

                <div id = "dot-edit-best-time">
                    <div id = "dot-edit-best-time-first"
                        style = {{ display: (this.props.bestTimeMode !== "allday")? "block" : "none" }}
                    >
                        <InputRange
                            classNames = {this.props.sliderClassNames}
                            maxValue = {4}
                            minValue = {0}
                            value = {this.props.bestTimeFirst}
                            onChange = {bestTime => this.props.setBestTimeFirst(bestTime)}
                            formatLabel = {bestTime => this.bestTimeLabels[bestTime]}
                            showEndLabels = {false}
                            step = {1}
                        />
                    </div>

                    <div id = "dot-edit-best-time-second"
                        style = {{ display: (this.props.bestTimeMode === "double")? "block" : "none" }}
                    >
                        <InputRange
                            classNames = {this.props.sliderClassNames}
                            maxValue = {4}
                            minValue = {0}
                            value = {this.props.bestTimeSecond}
                            onChange = {bestTime => this.props.setBestTimeSecond(bestTime)}
                            formatLabel = {bestTime => this.bestTimeLabels[bestTime]}
                            showEndLabels = {false}
                            step = {1}
                        />
                    </div>

                    <div id = "dot-edit-best-time-buttons">
                        <div id = "dot-edit-best-time-single"
                            className = {(this.props.colorMode === "day")?
                                "dot-edit-best-time-button button-light-blue-s3" :
                                "dot-edit-best-time-button button-blue-s3"}
                            style = {
                                (this.props.bestTimeMode === "single")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.props.setBestTimeMode("single")}}
                        >
                            Single
                        </div>
                        <div id = "dot-edit-best-time-double"
                            className = {(this.props.colorMode === "day")?
                                "dot-edit-best-time-button button-light-blue-s3" :
                                "dot-edit-best-time-button button-blue-s3"}
                            style = {
                                (this.props.bestTimeMode === "double")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.props.setBestTimeMode("double")}}
                        >
                            Double
                        </div>
                        <div id = "dot-edit-best-time-all-day"
                            className = {(this.props.colorMode === "day")?
                                "dot-edit-best-time-button button-light-blue-s3" :
                                "dot-edit-best-time-button button-blue-s3"}
                            style = {
                                (this.props.bestTimeMode === "allday")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.props.setBestTimeMode("allday")}}
                        >
                            All Day
                        </div>
                    </div>
                </div>

                <ReactTooltip
                    id = "dot-edit-best-time-title-tooltip"
                    className = "dot-edit-best-time-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Hours
        ============================================================================================
        */

        const hoursArrow = (this.props.colorMode === "day")?
            getStaticPath("/images/create/hours-arrow-black.png") :
            getStaticPath("/images/create/hours-arrow-white.png");

        const hourPickers = (
            <div id = "dot-edit-hours-picker-container"
                style = {{ display: (this.props.hoursOn)? "block" : "none" }}
            >
                <div id = "dot-edit-hours-arrow"
                    style = {{ backgroundImage: hoursArrow }}
                >
                </div>
                <div id = "dot-edit-start-hour">
                    <TimeKeeper 
                        time = {this.props.startHour.formatted}
                        onChange = {(setTime) => { this.props.setStartHour(setTime); }}
                    />
                </div>
                <div id = "dot-edit-end-hour">
                    <TimeKeeper 
                        time = {this.props.endHour.formatted}
                        onChange = {(setTime) => { this.props.setEndHour(setTime); }}
                    />
                </div>
            </div>
        );

        const hours = (
            <div className = "dot-edit-contributor-row"
                style = {{ 
                    display: ((this.props.dotType === "DI") || (this.props.dotType === "EX") || (this.props.dotType === "RT"))? "block" : "none",
                    paddingTop: (this.props.hoursOn)? 0: 10
                }}
            >
                <div id = "dot-edit-hours-title"
                    className = {(this.props.colorMode === "day")? "k4": "w4"}
                    data-tip = "<p>Hours of Business</p><p>or Operation</p>"
                    data-for = "dot-edit-hours-title-tooltip"
                >
                    Hours
                </div>
                <div id = "dot-edit-hours-container">
                    {hourPickers}
                    <div id = "dot-edit-hours-type">
                        <div id = "dot-edit-hours-fixed-button"
                            className = {(this.props.colorMode === "day")?
                                "dot-edit-hours-button button-light-blue-s3" :
                                "dot-edit-hours-button button-blue-s3"}
                            style = {
                                (this.props.hoursOn)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => this.props.setHoursOn(true)}
                        >
                            Fixed Hours
                        </div>
                        <div id = "dot-edit-hours-allday-button"
                            className = {(this.props.colorMode === "day")?
                                "dot-edit-hours-button button-light-blue-s3" :
                                "dot-edit-hours-button button-blue-s3"}
                            style = {
                                (!this.props.hoursOn)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => this.props.setHoursOn(false)}
                        >
                            Open All Day
                        </div>
                    </div>
                </div>

                <ReactTooltip
                    id = "dot-edit-hours-title-tooltip"
                    className = "dot-edit-hours-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );



        /*
        ============================================================================================
            Dining Options
        ============================================================================================
        */

        const diningOptions = (
            <div className = "dot-edit-contributor-row"
                style = {{ display: (this.props.dotType === "EX")? "block" : "none" }}
            >
                <div id = "dot-edit-dining-options-title"
                    className = {(this.props.colorMode === "day")? "k4": "w4"}
                    data-tip = "<p>If the Place Offers</p><p>Dining Options</p>"
                    data-for = "dot-edit-dining-options-title-tooltip"
                >
                    Dining Options
                </div>
                <div id = "dot-edit-dining-options-type">
                    <div id = "dot-edit-dining-options-yes-button"
                        className = "dot-edit-dining-options-button button-blue-s3"
                        style = {
                            (this.props.diningOptions)?
                            {
                                background: "#3284B0",
                                opacity: 1.0
                            } : {
                                background: "#888888"
                            }
                        }
                        onClick = {() => { this.props.setDiningOptions(true); }}
                    >
                        Yes
                    </div>
                    <div id = "dot-edit-dining-options-no-button"
                        className = "dot-edit-dining-options-button button-blue-s3"
                        style = {
                            (this.props.diningOptions)?
                            {
                                background: "#888888"
                            } : {
                                background: "#3284B0",
                                opacity: 1.0
                            }
                        }
                        onClick = {() => { this.props.setDiningOptions(false); }}
                    >
                        No
                    </div>
                </div>

                <ReactTooltip
                    id = "dot-edit-dining-options-title-tooltip"
                    className = "dot-edit-dining-options-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Constributor Menu
        ============================================================================================
        */

        const contributorOnly = (
            <div id = "dot-edit-contributor">
                {dotTypeMenu}

                {ratingSlider}

                {durationSlider}

                {physicalitySlider}

                {bestTimeSlider}

                {hours}

                {seasonClosure}

                {diningOptions}
            </div>
        );


        /*
        ============================================================================================
            Render
        ============================================================================================
        */

        return (
            <div className = "dot-edit-contributor-modal">
                <div className = {
                        (this.props.colorMode === "day")?
                            "dot-edit-contributor-modal-content modal-day" :
                            "dot-edit-contributor-modal-content modal-night"
                    }
                    ref = {this.setModalWrapperReference}
                > 
                    <div className = "dot-edit-contributor-modal-content__cancel image-button-weak-s3"
                        style = {{ 
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                        onClick = {() => { this.props.setContributorMenuOn(false); }}
                    >
                    </div>

                    {contributorOnly}
                </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode
    };
}

export default connect(mapStateToProps, null)(DotEditContributor); 
