// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Modules
import moment from "moment-timezone";

// Functions
import {
    url,
    getStaticPath,
    getMediaProperty,
    freezeBody,
    unfreezeBody
} from "js/Functions";

// Redux
import {
    //storeItinerary,
    storeParticipant,
    storeNewParticipant
} from "actions";

// Axios
import {
    postParticipant,
    getFriends
} from "requests";

// CSS
import "./Participant.scss";


class Participant extends Component {
    constructor(props){
        super(props);

        // Modal DOM node
        this.modalRef = React.createRef();

        // Input DOM node
        this.inputRef = React.createRef();

        // Placeholder for input
        this.inputPlaceholder = "Search friends";

        // Initialize state
        this.state = {
            searchInitiated: false,
            participantsInfo: (this.props.participant.modalOn)?
                this.props.itineraryInfo.guests : [],
            searchedFriendsInfo: []
        };

        // Bind functions
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.inputOnSubmit = this.inputOnSubmit.bind(this);
        this.searchFriends = this.searchFriends.bind(this);

        this.addParticipant = this.addParticipant.bind(this);
        this.removeParticipant = this.removeParticipant.bind(this);

        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        //console.log("Participant / componentDidUpdate - remount");

        // Freeze of unfreeze background
        if (this.props.participant.modalOn === true) {
            freezeBody();
        }
        if (this.props.participant.modalOn === false) {
            unfreezeBody();
        }

        // Set up event listeners
        this.inputRef.current.addEventListener("keydown", this.inputOnSubmit);
        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);
    }

    componentWillUnmount() {
         // Remove event listeners
        this.inputRef.current.removeEventListener("keydown", this.inputOnSubmit);
        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);
    }

    inputOnFocus(event) {
        event.target.placeholder = "";
    }

    inputOnBlur(event) {
        event.target.placeholder = this.inputPlaceholder;
    }

    inputOnSubmit(event) {
        // KeyCode
        const keyCode = event.which || event.keyCode;

        // If enter was pressed
        if (keyCode === 13) {

            if (this.inputRef.current.value.length > 0) {
                // Search friends
                this.searchFriends(this.inputRef.current.value);
            }
            else {
                // Search friends
                this.searchFriends();
            }

            // Clear input
            //this.inputRef.current.value = "";
        }
    }

    searchFriends(keywords) {
        //console.log("Participant / searchFriends");

        const axiosCallback = (response) => {
            //console.log("Participant / searchFriends - response = ", response);

            this.setState({
                searchInitiated: true,
                searchedFriendsInfo: response.data.content.results
            });
        };

        // Send data to server to search friends
        getFriends(keywords)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Participant / searchFriends - ", response);
            }
        );
    }

    addParticipant(event, friendID) {
        const axiosCallback = (response) => {
            //console.log("Participant / addParticipant- response = ", response);

            const participantsInfo = this.state.participantsInfo.slice();
            participantsInfo.push(response.data.content);

            this.setState({
                participantsInfo: participantsInfo
            });
        };

        // Data to send
        const dataJSON = {
            operation: "add",
            user_id: friendID,
            itinerary_id: this.props.itineraryInfo.id,
            is_invited: true
        };

        // Send request to add participant
        postParticipant(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Participant / addParticipant - ", response);
            }
        );
    }

    removeParticipant(event, friendID, index) {
        const axiosCallback = (response) => {
            //console.log("Participant / removeParticipant - response = ", response);

            // Remove corresponding participantInfo
            const participantsInfo = this.state.participantsInfo.slice();
            participantsInfo.splice(index, 1);

            // Update state
            this.setState({
                participantsInfo: participantsInfo
            });

            // Re-search friends
            if (this.state.searchInitiated) {
                if (this.inputRef.current.value.length > 0) {
                    // Search friends
                    this.searchFriends(this.inputRef.current.value);
                }
                else {
                    // Search friends
                    this.searchFriends();
                }
            }
        };

        // Data to send
        const dataJSON = {
            operation: "remove",
            user_id: friendID,
            itinerary_id: this.props.itineraryInfo.id,
            is_invited: true
        };

        // Send data to server to delete a user tag
        postParticipant(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Participant / removeParticipant - ", response);
            }
        );
    }

    approveParticipant(event, userID) {
        const axiosCallback = (response) => {
            console.log("Participant / approveParticipant - response = ", response);

            const participantsInfo = this.state.participantsInfo.slice();
            for (let i = 0; i < participantsInfo.length; i++) {
                if (participantsInfo[i].id === userID) {
                    participantsInfo[i].is_approved = true;
                }
            }

            this.setState({
                participantsInfo: participantsInfo
            });
        };

        // Data to send
        const dataJSON = {
            operation: "approve",
            user_id: userID,
            itinerary_id: this.props.itineraryInfo.id,
            is_invited: false
        };

        // Send request to add participant
        postParticipant(dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] Participant / approveParticipant - ", response);
            }
        );
    }

    escClick(event) {
        // Disappear user tag modal on pressing esc
        if (this.props.participant.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        this.props.storeParticipant({ modalOn: false });

        this.setState({
            expanded: false
        });
    }

    render() {
        //console.log("Participant / render - this.props = ", this.props);
        //console.log("Participant / render - this.props.participant = ", this.props.participant);

        // Approved image
        const approvedImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/approved-light-blue.png") :
            getStaticPath("/images/common/approved-blue.png");

        // Tagged friend ids
        const participatingFriendIDs = [];

        const invitedFriends = this.state.participantsInfo.map(
            (participantInfo, index) => {
                //console.log("Participant / render - participantInfo = ", participantInfo);

                // Add participating friend id to the list (invited or requested)
                participatingFriendIDs.push(participantInfo.id);

                if (participantInfo.is_invited) {
                    // Tagged friend image
                    const invitedFriendImage = (participantInfo.profile_pic)?
                        (
                            (participantInfo.profile_pic.external_url === null)?
                                getMediaProperty(participantInfo.profile_pic, "t", "url", true):
                                url(participantInfo.profile_pic.external_url)
                        ) : (
                            (this.props.colorMode === "day")?
                                getStaticPath("/images/common/no-profile-picture-day.png") :
                                getStaticPath("/images/common/no-profile-picture-night.png")
                        );

                    // Return JSX
                    return(
                        <div key = {"participant-item-" + index.toString()}
                            className = "participant-modal-content__item"
                        >
                            <img className = {(this.props.colorMode === "day")?
                                    "participant-modal-content__item-image image-contain border-day" :
                                    "participant-modal-content__item-image image-contain border-night"}
                                src = {invitedFriendImage}
                                alt = {""}
                            />
                            <span
                                className = {(this.props.colorMode === "day")?
                                        "participant-modal-content__item-name k4" :
                                        "participant-modal-content__item-name w4"}
                            >
                                {participantInfo.name}
                            </span>
                            {
                                (participantInfo.is_approved)?
                                (
                                    <div className = "participant-modal-content__item-approved image-contain"
                                        style = {{ backgroundImage: approvedImage }}
                                    >
                                    </div>
                                ) : null
                            }
                            <div className = "participant-modal-content__item-remove image-contain"
                                style = {{
                                    backgroundImage: (this.props.colorMode === "day")?
                                        getStaticPath("/images/common/decrease-solid-light-blue.png") :
                                        getStaticPath("/images/common/decrease-solid-blue.png")
                                }}
                                onClick = {(event) => { this.removeParticipant(event, participantInfo.id, index); }}
                            >
                            </div>
                        </div>
                    );
                }
                else {
                    return null;
                }
            }
        );

        // Searched friends list
        const searchedFriends = this.state.searchedFriendsInfo.map(
            (searchedFriendInfo, index) => {
                // Is participating
                const isParticipating = (participatingFriendIDs.indexOf(searchedFriendInfo.id) >= 0);

                if (isParticipating) {
                    return null;
                }
                else {
                    // Searched friend image
                    const searchedFriendImage = (searchedFriendInfo.profile_pic)?
                        (
                            (searchedFriendInfo.profile_pic.external_url === null)?
                                getMediaProperty(searchedFriendInfo.profile_pic, "xs", "url", true):
                                url(searchedFriendInfo.profile_pic.external_url)
                        ) : (
                            (this.props.colorMode === "day")?
                                getStaticPath("/images/common/no-profile-picture-day.png") :
                                getStaticPath("/images/common/no-profile-picture-night.png")
                        );

                    // Tag friend button
                    const addParticipantButton = (isParticipating)? null : (
                        <div className = "participant-modal-content__item-add image-contain"
                            style = {{
                                backgroundImage: (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/increase-solid-light-blue.png") :
                                    getStaticPath("/images/common/increase-solid-blue.png")
                            }}
                            onClick = { (event) => { this.addParticipant(event, searchedFriendInfo.id); }}
                        >
                        </div>
                    );

                    return(
                        <div key = {"participant-friend-" + index.toString()}
                            className = "participant-modal-content__item"
                        >
                            <img className= {(this.props.colorMode === "day")?
                                    "participant-modal-content__item-image image-contain border-day" :
                                    "participant-modal-content__item-image image-contain border-night"}
                                src = {searchedFriendImage}
                                alt = {""}
                                style = {(isParticipating)? { filter: "grayscale(100%)" } : null}
                            />
                            <span
                                className = {(this.props.colorMode === "day")?
                                        "participant-modal-content__item-name k4" :
                                        "participant-modal-content__item-name w4"}
                            >
                                {searchedFriendInfo.name}
                            </span>
                            {addParticipantButton}
                        </div>
                    );
                }
            }
        );

        // Approved users list
        let approvedCount = 0;
        const approvedUsers = this.state.participantsInfo.map(
            (approvedUserInfo, index) => {
                if (approvedUserInfo.is_approved && !approvedUserInfo.is_invited) {
                    // Approved count
                    approvedCount += 1;

                    // Approved user image
                    const approvedUserImage = (approvedUserInfo.profile_pic)?
                        (
                            (approvedUserInfo.profile_pic.external_url === null)?
                                getMediaProperty(approvedUserInfo.profile_pic, "xs", "url", true):
                                url(approvedUserInfo.profile_pic.external_url)
                        ) : (
                            (this.props.colorMode === "day")?
                                getStaticPath("/images/common/no-profile-picture-day.png") :
                                getStaticPath("/images/common/no-profile-picture-night.png")
                        );

                    // Disapprove participant button
                    const disapproveUserButton = (
                        <div className = "participant-modal-content__item-add image-contain"
                            style = {{
                                backgroundImage: (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/decrease-solid-light-blue.png") :
                                    getStaticPath("/images/common/decrease-solid-blue.png")
                            }}
                            onClick = {(event) => { this.removeParticipant(event, approvedUserInfo.id, index); }}
                        >
                        </div>
                    );

                    return(
                        <div key = {"participant-friend-" + index.toString()}
                            className = "participant-modal-content__item"
                        >
                            <img className= {(this.props.colorMode === "day")?
                                    "participant-modal-content__item-image image-contain border-day" :
                                    "participant-modal-content__item-image image-contain border-night"}
                                src = {approvedUserImage}
                                alt = {""}
                            />
                            <span
                                className = {(this.props.colorMode === "day")?
                                        "participant-modal-content__item-name k4" :
                                        "participant-modal-content__item-name w4"}
                            >
                                {approvedUserInfo.name}
                            </span>
                            <div className = "participant-modal-content__item-approved image-contain"
                                style = {{ backgroundImage: approvedImage }}
                            >
                            </div>
                            {disapproveUserButton}
                        </div>
                    );
                }
                else {
                    return null;
                }
            }
        );

        // Applied users list
        let appliedCount = 0;
        const appliedUsers = this.state.participantsInfo.map(
            (appliedUserInfo, index) => {
                if (!appliedUserInfo.is_approved && !appliedUserInfo.is_invited) {
                    // Applied count
                    appliedCount += 1;

                    // Applied user image
                    const appliedUserImage = (appliedUserInfo.profile_pic)?
                        (
                            (appliedUserInfo.profile_pic.external_url === null)?
                                getMediaProperty(appliedUserInfo.profile_pic, "xs", "url", true):
                                url(appliedUserInfo.profile_pic.external_url)
                        ) : (
                            (this.props.colorMode === "day")?
                                getStaticPath("/images/common/no-profile-picture-day.png") :
                                getStaticPath("/images/common/no-profile-picture-night.png")
                        );

                    // Approve participant button
                    const approveUserButton = (
                        <div className = "participant-modal-content__item-add image-contain"
                            style = {{
                                backgroundImage: (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/increase-solid-light-blue.png") :
                                    getStaticPath("/images/common/increase-solid-blue.png")
                            }}
                            onClick = {(event) => { this.approveParticipant(event, appliedUserInfo.id); }}
                        >
                        </div>
                    );

                    return(
                        <div key = {"participant-friend-" + index.toString()}
                            className = "participant-modal-content__item"
                        >
                            <img className= {(this.props.colorMode === "day")?
                                    "participant-modal-content__item-image image-contain border-day" :
                                    "participant-modal-content__item-image image-contain border-night"}
                                src = {appliedUserImage}
                                alt = {""}
                            />
                            <span
                                className = {(this.props.colorMode === "day")?
                                        "participant-modal-content__item-name k4" :
                                        "participant-modal-content__item-name w4"}
                            >
                                {appliedUserInfo.name}
                            </span>
                            {approveUserButton}
                        </div>
                    );
                }
                else {
                    return null;
                }
            }
        );

        // Dot media
        let mediaURL = null;
        let mediaClass = null;
        if (this.props.itineraryInfo !== null) {
            // Media info
            const mediaInfo = this.props.itineraryInfo.dot.media[0];

            if (mediaInfo.type === "video") {
                if ("xs" in mediaInfo.files) {
                    mediaURL = getMediaProperty(mediaInfo, "xs", "url", true);
                    mediaClass = (this.props.colorMode === "day")?
                        "participant-modal-content__image image-cover" :
                        "participant-modal-content__image image-cover";
                }
                else {
                    mediaURL = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/video-black.png", true) :
                        getStaticPath("/images/common/video-white.png", true);

                    mediaClass = (this.props.colorMode === "day")?
                        "participant-modal-content__video image-actual" :
                        "participant-modal-content__video image-actual";
                }
                // else {
                //     mediaURL = getMediaProperty(mediaInfo, "xs", 'url', true);
                //     mediaClass = (this.props.colorMode === "day")?
                //         "participant-modal-content__image image-cover" :
                //         "participant-modal-content__image image-cover";
                // }
            }
            else {
                mediaURL = getMediaProperty(mediaInfo, "s", "url", true);
                mediaClass = (this.props.colorMode === "day")?
                    "participant-modal-content__image image-cover" :
                    "participant-modal-content__image image-cover";
            }
        }

        // Shadow image
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette-weak.png");

        return (
            <div className = "participant-modal"
                style = {{
                    display: (this.props.participant.modalOn)? "block" : "none"

                }}
            >
                <div className = {(this.props.colorMode === "day")?
                        "participant-modal-content modal-day" :
                        "participant-modal-content modal-night"}
                    ref = {this.modalRef}
                >
                    <div className = "participant-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage:  (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                        onClick = {this.closeModal}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "participant-modal-content__date font-cabin light-blue" :
                            "participant-modal-content__date font-cabin blue"}
                    >
                        {(this.props.participant.itinerary !== null)?
                            moment(this.props.itineraryInfo.start_time).format("MMM Do YYYY") : null}
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "participant-modal-content__area k4" :
                            "participant-modal-content__area w4"}
                    >
                        {(this.props.participant.itinerary !== null)?
                            this.props.itineraryInfo.dot.area : null}
                    </div>

                    <div className = {mediaClass}
                        style = {{ backgroundImage: mediaURL }}
                    >
                        <div className = "participant-modal-content__shadow"
                            style = {{ backgroundImage: shadowImage }}
                        >
                        </div>
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "participant-modal-content__sub-title k4" :
                            "participant-modal-content__sub-title w4"}
                    >
                        {(this.state.participantsInfo.length === 0)? "Invite a Friend" : "Invited Friends"}
                    </div>

                    <div className = "participant-modal-content__items">
                        {invitedFriends}
                    </div>

                    <input ref = {this.inputRef}
                        id = "participant-modal-input"
                        className = {(this.props.colorMode === "day")?
                            "participant-modal-content__input input-s3 input-day" :
                            "participant-modal-content__input input-s3 input-night"}
                        placeholder = {"Search friends"}
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                    />

                    <div className = "participant-modal-content__items">
                        {searchedFriends}
                    </div>

                    {
                        (approvedCount > 0)?
                            (
                                <div className = {(this.props.colorMode === "day")?
                                        "participant-modal-content__sub-title k4" :
                                        "participant-modal-content__sub-title w4"}
                                >
                                    Approved for Open Trip
                                </div>
                            ) : null
                    }

                    <div className = "participant-modal-content__items">
                        {approvedUsers}
                    </div>

                    {
                        (appliedCount > 0)?
                            (
                                <div className = {(this.props.colorMode === "day")?
                                        "participant-modal-content__sub-title k4" :
                                        "participant-modal-content__sub-title w4"}
                                >
                                    Applied through Open Trip
                                </div>
                            ) : null
                    }

                    <div className = "participant-modal-content__items">
                        {appliedUsers}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        itineraryInfo: state.itinerary.itineraryInfo,
        participant: state.itinerary.participant,
        newParticipant: state.itinerary.newParticipant
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeParticipant,
            storeNewParticipant
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Participant);
