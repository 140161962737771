/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Plan.js
    - Plan a trip and create an itinerary
    - Use Dayinfo / Map / PlanGallery / TimeBar components
--------------------------------------------------------------------------------------------
    Content
    - Plan

    Props
    - trip
    - tripInfo
    - userInfo

    Locations
    - startLocation
    - endLocation
    - startTimezone
    - endTimezone

	Time
    - startMoment
    - endMoment
    - sunTimes
    - moonTimes
    - dayTime
    - offsetTime
    - totalTime
    - startEndTimeValues
    - hour00Moment
    - hour24Moment

	Astronomy
    - sunMoonOn
    - moonPhase
    - moonPhaseDigits

    Weather
    - weather
    - weatherOn
    - weatherIcon
    - tempHigh
    - tempLow
    - precipOn
    - precipType
    - precipProb
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Modules
import DatePicker from "react-datepicker";
import ReactTooltip from "thedots-tooltip";
import InputRange from "thedots-input-range";
import moment from "moment-timezone";

// Constants
import { FITNESS_LEVELS } from "../../components/User/LevelDictionary";

// Axios
import {
    postFindDotsCheck,
    postFindDots,
    postConnectDots,
    //postConnectDotsSync,
    postPartialConnectDots,
    postModifyItinerary,
    postCheckConnect
} from "requests";

// Redux
import {
    storeLogInOn,
    storeSignUpOn,
    storeItinerary,
    storeItineraryParams,
    storeWarningAlert,
    storeItineraryAnimation,
    storeNewItinerary,
    storeSaveItinerary,
    storeItinerariesFetchComplete
} from "actions";

// Components
import {
    SelectedSmall,
    OptionalSmall,
    Selected,
    Optional,
    DotPreview
} from "./DotGroup";
//import { DotList } from "js/DotFunctions";
import { DayInfo } from "components/DayInfo";
import { OpenMap, GoogleMap, getAverageLocation} from "components/Map";
import PlanGallery from "./PlanGallery";

// Shared functions with Itinerary
import {
    dotIndicesToIDs,
    dotIDsToIndices,
    updateTimesSetState,
    updateTimes,
    updateDate,
    updateDayInfo,
    timeSliderOnChange,
    timeSliderOnChangeComplete,
    timeSliderFormatLabel,
    showItineraryTimeWarning,
    startInputOnFocus,
    startInputOnBlur,
    endInputOnFocus,
    endInputOnBlur,
    setStartLocation,
    setEndLocation,
    setStartTimezone,
    setEndTimezone,
    resetAnimations,
    dotClick,
    dotHoverOn,
    dotHoverOff,
    endHoverOn,
    endHoverOff
} from "js/TripFunctions";

import { fetchWeather } from "components/DayInfo";

// Functions
import {
    updateStateChain2,
    getStaticPath,
    getMediaProperty
} from "js/Functions";

// CSS
import "./Plan.scss";


class Plan extends Component {
    constructor (props) {
        super (props);
        //console.log("Plan / constructor - props = ", props);

        // Default fitness level
        this.defaultFitnessLevel = 2;

        // Max number of dots that can be connected
        this.maxNumDotsToConnect = 11;

        // Time margin for warning in seconds (30 mins)
        this.warningTimeMargin = 30 * 60;

        // Connect settings
        this.connectCheckInterval = null;
        this.connectCheckIntervalTime = 500;
        this.connectCheckCompletedCode = 200000;
        this.connectCheckInProgressCode = 200001;

        // Map Mode (google / open / static)
        this.mapSource = "open";
        this.mapMode = "itinerary";

        // Main and right section width
        this.itineraryWidthRightOn = 1116;
        this.itineraryWidthRightOff = 1078;
        this.itineraryOptionsWidth = 304;
        this.itineraryRightMenuWidth = 50;

        // Preview image settings (conserve image area for all images)
        this.previewMediaMinWidth = 300;
        this.previewMediaMaxWidth = 500;
        this.previewMediaMaxHeight = 400;
        this.previewMediaBorder = 1;
        this.previewMediaArea = 100000;

        // Map settings
        this.mapZoom = 10;
        this.mapHeight = 300;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // Find transition time
        this.findDotsAnimationTransitionTime = 250;
        this.findDotsAnimationEndTime = 500;

        // Temporary itinerary and dot position shifts
        // For connect algorithm animation
        this.tempItinerary = null;
        this.tempDotShiftTops = null;
        this.tempDotShiftLefts = null;

        // Dot DOM IDs
        this.dotNodes = [];

        // Map DOM IDs
        this.mapNodeID = "plan-map";
        this.mapContainerNodeID = "plan-map-wrapper";
        this.mapButtonsNodeID = "plan-map-buttons";

        // Location input DOM IDs
        this.startInputNodeID = "plan-start-location-input";
        this.endInputNodeID = "plan-end-location-input";

        // Number of dots displayed in the options window
        this.optionsNumDotsCategoryDisplayed = 4;

        // Weather on
        //this.showWeather = true;

        // Parse the initial state
        //console.log("Plan / constructor - this.props.history.location.state = ", this.props.history.location.state);
        let tripInfo = null;
        let fitnessLevel = null;
        let itineraryID = null;
        let itinerary = null;
        let itineraryInfo = null;

        let dotOpacities = null;
        let dotShiftTops = null;
        let dotShiftLefts = null;

        let dotIndexOn = false;
        let dotIndexOpacity = 0.0;

        // Locations
        let startLocation = this.props.history.location.state.startLocation;
        let endLocation = this.props.history.location.state.endLocation;
        let startLocationName = this.props.history.location.state.startLocationName;
        let endLocationName = this.props.history.location.state.endLocationName;
        let location = this.props.history.location.state.location;
        let startTimezone = this.props.history.location.state.startTimezone;
        let endTimezone = this.props.history.location.state.endTimezone;
        let timezone = this.props.history.location.state.timezone;

        // Time
        let startMoment = moment(this.props.history.location.state.startMoment);
        let endMoment = moment(this.props.history.location.state.endMoment);
        let actualEndMoment = null;
        let sunTimes = {
            sunrise: (this.props.history.location.state.sunTimes.sunrise === null)?
                null : moment(this.props.history.location.state.sunTimes.sunrise),
            sunset: (this.props.history.location.state.sunTimes.sunset === null)?
                null : moment(this.props.history.location.state.sunTimes.sunset),
            solarNoon: (this.props.history.location.state.sunTimes.solarNoon === null)?
                null : moment(this.props.history.location.state.sunTimes.solarNoon)
        };
        let moonTimes = {
            moonrise: (this.props.history.location.state.moonTimes.moonrise === null)?
                null : moment(this.props.history.location.state.moonTimes.moonrise),
            moonset: (this.props.history.location.state.moonTimes.moonset === null)?
                null : moment(this.props.history.location.state.moonTimes.moonset)
        };
        let dayTime = this.props.history.location.state.dayTime;
        let offsetTime = this.props.history.location.state.offsetTime;
        let totalTime = this.props.history.location.state.totalTime;
        let startEndTimeValues = this.props.history.location.state.startEndTimeValues;
        let hour00Moment = moment(this.props.history.location.state.hour00Moment);
        let hour24Moment = moment(this.props.history.location.state.hour24Moment);

        // Astronomy
        let sunMoonOn = this.props.history.location.state.sunMoonOn;
        let moonPhase = this.props.history.location.state.moonPhase;
        let moonPhaseDigits = this.props.history.location.state.moonPhaseDigits;

        // Dot times and transit times and modes
        let dotTimes = null;
        let transitTimes = null;
        let transitTimeValues = null;
        let transitModes = null;

        // Weather
        let weather = this.props.history.location.state.weather;
        let weatherOn = this.props.history.location.state.weatherOn;
        let weatherIcon = this.props.history.location.state.weatherIcon;
        let tempHigh = this.props.history.location.state.tempHigh;
        let tempLow = this.props.history.location.state.tempLow;
        let precipOn = this.props.history.location.state.precipOn;
        let precipType = this.props.history.location.state.precipType;
        let precipProb = this.props.history.location.state.precipProb;

        // Meal switches
        let breakfastSwitch = null;
        let lunchSwitch = null;
        let dinnerSwitch = null;

        // Main layout switches
        let previewOn = false;
        let previewContentOn = false;
        let galleryOn = false;
        let finalizeOn = false;

        // Connect and find algorithm complete
        let findDotsComplete = false;
        let findDotsAnimationComplete = false;
        let connectDotsComplete = false;
        let connectDotsInProgress = false;
        let connectDotsAnimationComplete = false;

        // Only when the state has been provided
        if (this.props.history.state !== null) {
            if (this.props.history.location.state.itineraryInfo !== null) {
                tripInfo = this.props.history.location.state.tripInfo;
                fitnessLevel = this.props.history.location.state.itineraryInfo.fitness_level;
                itineraryID = this.props.history.location.state.itineraryInfo.id;
                itinerary = dotIDsToIndices(
                    this.props.history.location.state.itineraryInfo.route,
                    tripInfo.trip_extension.children
                );
                itineraryInfo = this.props.history.location.state.itineraryInfo;

                dotOpacities = [];
                dotShiftTops = [];
                dotShiftLefts = [];
                for (let i = 0; i < itinerary.length; i++) {
                    dotOpacities.push(1.0);
                    dotShiftTops.push(0);
                    dotShiftLefts.push(0);
                }

                dotIndexOn = true;
                dotIndexOpacity = 1.0;

                actualEndMoment = moment(this.props.history.location.state.actualEndMoment);

                dotTimes = this.props.history.location.state.dotTimes;
                transitTimes = this.props.history.location.state.transitTimes;
                transitTimeValues = this.props.history.location.state.transitTimeValues;
                transitModes = this.props.history.location.state.transitModes;

                breakfastSwitch = this.props.history.location.state.itineraryInfo.breakfast_switch;
                lunchSwitch = this.props.history.location.state.itineraryInfo.lunch_switch;
                dinnerSwitch = this.props.history.location.state.itineraryInfo.dinner_switch;

                galleryOn = true;
                finalizeOn = true;

                findDotsComplete = true;
                findDotsAnimationComplete = true;
                connectDotsComplete = true;
                connectDotsAnimationComplete = true;
            }
            else {
                tripInfo = this.props.history.location.state.tripInfo;
                fitnessLevel = (this.props.userInfo === null)?
                    this.defaultFitnessLevel : this.props.userInfo.fitness_level;
            }
        }

        // Initialize states
        this.state = {
            // Selected / hovered / selectedChild / hoveredChild
            selected: null,
            selectedChild : null, // hike dot index of the selected hike dot
            hovered: null,
            hoveredChild: null,
            displayChildren : null, // dot index of the selected hike trail

            // Find and connect ready
            findReady: false,
            connectReady: false,

            // Dot display
            dotOpacities: dotOpacities,
            dotIndexOn: dotIndexOn,
            dotIndexOpacity: dotIndexOpacity,
            dotConnectAnimationClass: "dot-object-wrapper",

            // Connect algorithm dot shifts
            dotShiftTops: dotShiftTops,
            dotShiftLefts: dotShiftLefts,

            // Trip information
            tripInfo: tripInfo,

            // User information (store to check if the logged in user is changed)
            userInfo: this.props.userInfo,

            // Fitness level
            fitnessLevel: fitnessLevel,

            // Itinerary ID
            itineraryID: itineraryID,
            itineraryInfo: itineraryInfo,

            // Selected dots
            // itinerary : indices of dots in reference to the dots dictionary
            itinerary: itinerary,

            // Locations
            startLocation: startLocation,
            endLocation: endLocation,
            startLocationName: startLocationName,
            endLocationName: endLocationName,
            location: location,
            startTimezone: startTimezone,
            endTimezone: endTimezone,
            timezone: timezone,

            // Start and end times
            startMoment: startMoment,
            endMoment: endMoment,
            actualEndMoment: actualEndMoment,

            // Dot times
            dotTimes: dotTimes,

            // Transit times and modes
            transitTimes: transitTimes,
            transitTimeValues: transitTimeValues,
            transitModes: transitModes,

            // Day times
            sunTimes : sunTimes,
            moonTimes : moonTimes,
            dayTime : dayTime,
            offsetTime: offsetTime,
            totalTime: totalTime,
            startEndTimeValues: startEndTimeValues,
            hour00Moment: hour00Moment,
            hour24Moment: hour24Moment,

            // Astronomy
            sunMoonOn: sunMoonOn,
            moonPhase: moonPhase,
            moonPhaseDigits: moonPhaseDigits,

            // Weather
            weather: weather,
            weatherOn: weatherOn,
            weatherIcon: weatherIcon,
            tempHigh: tempHigh,
            tempLow: tempLow,
            precipOn: precipOn,
            precipType: precipType,
            precipProb: precipProb,

            // Meal switches
            breakfastSwitch: breakfastSwitch,
            lunchSwitch: lunchSwitch,
            dinnerSwitch: dinnerSwitch,

            // Main layout switches
            previewOn: previewOn,
            previewContentOn: previewContentOn,
            galleryOn: galleryOn,
            finalizeOn: finalizeOn,

            // Preview
            previewWidth: "narrow",

            // Selection layout switches
            basicSettingsOn: false,
            mapOn: false,
            mapBeforeCustomizeOn: false,
            customizeOn: false,
            customSettingsOn: false,

            // Basic settings layout switches
            calendarOn: true,
            timeOn: true,
            locationInputOn: true,

            // Options category settings
            optionsCategory: "scenic",
            optionsScenicPage: 0,
            optionsDinePage: 0,
            optionsExperiencePage: 0,
            optionsRoutePage: 0,

            // Preview gallery
            selectedMediaIndex: 0,

            // Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false,

            // Connect and find algorithm complete
            findDotsComplete: findDotsComplete,
            findDotsAnimationComplete: findDotsAnimationComplete,
            connectDotsComplete: connectDotsComplete,
            connectDotsInProgress: connectDotsInProgress,
            connectDotsAnimationComplete: connectDotsAnimationComplete,

            // Optional small
            optionalSmallOn: false,

            // Finalize modal
            finalizeModalOn: false,

            // Scroll
            isScrolled: false
        };
        //console.log("Plan / constructor - this.state = ", this.state);

        // Bind setState function
        this.setState = this.setState.bind(this);

        // Bind find and connect dots function
        this.setDotNodes = this.setDotNodes.bind(this);
        this.findDots = this.findDots.bind(this);
        this.findDotsAnimation = this.findDotsAnimation.bind(this);
        this.connectDots = this.connectDots.bind(this);
        this.connectDotsAnimation = this.connectDotsAnimation.bind(this);

        // Bind checkUpdate
        this.checkUpdate = this.checkUpdate.bind(this);

        // Bind dot click and hover function
        this.dotClick = dotClick.bind(this);
        this.dotHoverOn = dotHoverOn.bind(this);
        this.dotHoverOff = dotHoverOff.bind(this);
        this.endHoverOn = endHoverOn.bind(this);
        this.endHoverOff = endHoverOff.bind(this);

        // Bind dot functions
        this.dotAddClick = this.dotAddClick.bind(this);
        this.dotRemoveClick = this.dotRemoveClick.bind(this);
        this.dotLeftClick = this.dotLeftClick.bind(this);
        this.dotRightClick = this.dotRightClick.bind(this);

        // Bind date and time change related functions
        this.updateTimes = updateTimes.bind(this);
        this.updateTimesSetState = updateTimesSetState.bind(this);
        this.updateDate = updateDate.bind(this);
        this.updateDayInfo = updateDayInfo.bind(this);
        this.fetchWeather = fetchWeather.bind(this);

        // Bind time slider functions
        this.timeSliderOnChange = timeSliderOnChange.bind(this);
        this.timeSliderOnChangeComplete = timeSliderOnChangeComplete.bind(this);
        this.timeSliderFormatLabel = timeSliderFormatLabel.bind(this);

        // Bind itinerary time warning function
        this.showItineraryTimeWarning = showItineraryTimeWarning.bind(this);

        // Bind location input focus and blur functions
        this.startInputOnFocus = startInputOnFocus.bind(this);
        this.startInputOnBlur = startInputOnBlur.bind(this);
        this.endInputOnFocus = endInputOnFocus.bind(this);
        this.endInputOnBlur = endInputOnBlur.bind(this);

        // Bind set location functions
        this.setStartLocation = setStartLocation.bind(this);
        this.setEndLocation = setEndLocation.bind(this);
        this.setStartTimezone = setStartTimezone.bind(this);
        this.setEndTimezone = setEndTimezone.bind(this);

        // Bind map animation function
        this.resetAnimations = resetAnimations.bind(this);

        // Bind preview image navigation functions
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);

        // Bind options functions
        this.optionsPreviousPage = this.optionsPreviousPage.bind(this);
        this.optionsNextPage = this.optionsNextPage.bind(this);

        // Finalize click
        this.finalizeClick = this.finalizeClick.bind(this);

        // Bind scroll function
        this.checkScroll = this.checkScroll.bind(this);
    }


    /*
    ============================================================================================
        Main JSX Render
    ============================================================================================
    */

    render() {
        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && (this.props.userInfo !== null));

        // Small layout
        const layoutSmall = (this.props.browserWidth <= window.browserWidthSmall);

        // Slider settings
        const sliderClassNames = (this.props.colorMode === "day")?
        {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-day",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-day input-range__track--background",
            activeTrack: "input-range-track-day input-range-track-active-day",

            labelContainer: "input-range-label-container-day",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        } : {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-night",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-night input-range__track--background",
            activeTrack: "input-range-track-night input-range-track-active-night",

            labelContainer: "input-range-label-container-night",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        };

        /*
        ============================================================================================
        ============================================================================================
            
            Common Components

            - Calendar
            - DayInfo
            - Locations
            - Slider
            - Map

        ============================================================================================
        ============================================================================================
        */


        /*
        ============================================================================================
            Calendar
        ============================================================================================
        */

        // Calendar icon
        const calendarIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/calendar-black.png") :
            getStaticPath("/images/common/calendar-white.png");

        // Calendar
        let calendar = null;
        if ((this.state.startMoment) && (this.state.endMoment)) {
            //console.log("Plan / render - this.state.startMoment = ", this.state.startMoment.format());
            //console.log("Plan / render - this.state.endMoment = ", this.state.endMoment.format());

            // calendar object
            calendar = (
                <div id = "plan-calendar-container" className = "clear-fix">
                    <div id = "plan-calendar-icon"
                        className = "image-contain"
                        data-tip = "Travel Date"
                        data-for = "plan-calendar-icon-tooltip"
                        style = {{ backgroundImage: calendarIconImage }}
                    >
                        <ReactTooltip
                            id = "plan-calendar-icon-tooltip"
                            className = "plan-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom"
                        />
                    </div>
                    <div id = "plan-calendar"
                        data-tip = "Click to Change Date"
                        data-for = "plan-calendar-input-tooltip">
                        <DatePicker
                            readOnly = {true}
                            dateFormat = "MMM D YYYY"
                            className = {(this.props.colorMode === "day")?
                                "plan-calendar-input-day font-cabin" :
                                "plan-calendar-input-night font-cabin"}
                            selected = {this.state.startMoment}
                            onChange = {this.updateDate}
                        />
                        <ReactTooltip
                            id = "plan-calendar-input-tooltip"
                            className = "plan-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom"
                        />
                    </div>
                </div>
            );
            //console.log("Plan / render - calendar = ", calendar);
        }
        else {
            calendar = null;
        }


        /*
        ============================================================================================
            Day Info
        ============================================================================================
        */

        // Only when the sunTimes and moonPhase are ready ro render
        let dayInfo = null;
        if ((this.state.sunTimes) && (this.state.moonPhase)) {
            const dayInfoProps = {
                colorMode: this.props.colorMode,
                weatherOn: this.state.weatherOn,
                weatherIcon: this.state.weatherIcon,
                precipOn: this.state.precipOn,
                precipType: this.state.precipType,
                precipProb: this.state.precipProb,
                tempHigh: this.state.tempHigh,
                tempLow: this.state.tempLow,
                sunTimes: this.state.sunTimes,
                sunMoonOn: this.state.sunMoonOn,
                moonPhase: this.state.moonPhase,
                moonPhaseDigits: this.state.moonPhaseDigits
            }
            dayInfo = DayInfo(dayInfoProps);
            //console.log("Plan / render - dayInfo = ", dayInfo);
        }
        else{
            dayInfo = null;
        }


        /*
        ============================================================================================
            Location Input
        ============================================================================================
        */

        // Set props for start and end location inputs
        const startInputProps = {
            type: "text",
            placeholder: this.state.startLocationName,
            onFocus: this.startInputOnFocus,
            onBlur: this.startInputOnBlur,
            autoComplete: "off"
        }

        const endInputProps = {
            type: "text",
            placeholder: this.state.endLocationName,
            onFocus: this.endInputOnFocus,
            onBlur: this.endInputOnBlur,
            autoComplete: "off"
        }

        // Location input
        const locationInputIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/location-black.png") :
            getStaticPath("/images/common/location-white.png");

        const locationInputIcon = (
            <div id = "plan-location-icon"
                className = "image-contain"
                data-tip = "Start and End Locations"
                data-for = "plan-location-icon-tooltip"
                style = {{ backgroundImage: locationInputIconImage }}
            >
                <ReactTooltip
                    id = "plan-location-icon-tooltip"
                    className = "tooltip-s2"
                    type = "dark"
                    place = "right"
                />
            </div>
        );


        /*
        ============================================================================================
            Find and Connect Buttons
        ============================================================================================
        */

        const findButtonImage = (this.state.findReady)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/find-light-blue.png") :
                    getStaticPath("/images/common/find-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/find-black.png") :
                    getStaticPath("/images/common/find-white.png")
            );

        const findButtonTooltipText = "Re-find Dots";
        const findButtonTooltipStyle = (this.state.findReady)? "info" : "dark";
        const findButton = (this.state.findReady)? (
            <div id = "plan-find-button" 
                className = "image-contain"
                data-tip = {findButtonTooltipText}
                data-for = "plan-find-button-tooltip"
                style = {{ backgroundImage: findButtonImage }}
                onClick = {this.findDots.bind(
                    this,
                    this.findDotsAnimation.bind(
                        this,
                        () => {
                            //console.log("Plan / componentDidMount - this.state.connectReady #2 = ", this.state.connectReady);

                            if (this.state.connectReady) {
                                this.connectDotsAnimation();
                            }
                            else {
                                // Open basic settings
                                this.setState({ 
                                    basicSettingsOn: true 
                                });

                                // Show alert
                                this.props.storeWarningAlert({
                                    message: "Too Many Dots are Selected. Reduce the Trip Duration and Try Again.",
                                    on: true
                                });
                            }
                        }
                    )
                )}
            >
            </div>
        ):(
            <div id = "plan-find-button-inactive"
                className = "image-contain"
                data-tip = {findButtonTooltipText}
                data-for = "plan-find-button-tooltip"
                style = {{ backgroundImage: findButtonImage }}
            >
            </div>
        );
        const findButtonText = (
            <div className = {(this.props.colorMode === "day")?
                    "plan-buttons-title k3" :
                    "plan-buttons-title w3"}
                style = {{ opacity: (this.state.findReady)? 1.0: 0.25 }}
            >
                Find Dots
            </div>
        );

        const connectButtonImage = (this.state.connectReady)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/connect-light-blue.png") :
                    getStaticPath("/images/common/connect-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/connect-black.png") :
                    getStaticPath("/images/common/connect-white.png")
            );

        const connectButtonTooltipText = "Re-connect Dots";
        const connectButtonTooltipStyle = (this.state.connectReady)? "info" : "dark";
        const connectButton = (this.state.connectReady)? (
            <div id = "plan-connect-button"
                className = "image-contain"
                data-tip = {connectButtonTooltipText}
                data-for = "plan-connect-button-tooltip"
                style = {{ backgroundImage: connectButtonImage }}
                onClick = {this.connectDots.bind(this, this.connectDotsAnimation)}
            >
            </div>
        ):(
            <div id = "plan-connect-button-inactive"
                className = "image-contain"
                data-tip = {connectButtonTooltipText}
                data-for = "plan-connect-button-tooltip"
                style = {{ backgroundImage: connectButtonImage }}
            >
            </div>
        );
        const connectButtonText = (
            <div className = {(this.props.colorMode === "day")?
                    "plan-buttons-title k3" : 
                    "plan-buttons-title w3"}
                style = {{ opacity: (this.state.connectReady)? 1.0: 0.25 }}
            >
                Connect Dots
            </div>
        );


        /*
        ============================================================================================
            Time Slider
        ============================================================================================
        */

        let timeSlider;
        if ((this.state.sunTimes) && (this.state.startEndTimeValues)) {
            // Convert solar and reference times to millisecs (since 1970/01/01)
            const sunrise = this.state.sunTimes.sunrise.unix();
            const sunset = this.state.sunTimes.sunset.unix();
            const solarNoon = this.state.sunTimes.solarNoon.unix();
            const hour00 = this.state.hour00Moment.unix();
            const hour24 = this.state.hour24Moment.unix();

            // Calculate the position of the solar markers on the slider
            const sunriseWidth = (sunrise - hour00) / (hour24 - hour00) * 100 + "%";
            const sunsetWidth = (sunset - hour00) / (hour24 - hour00) * 100 + "%";
            const solarNoonWidth = (solarNoon - hour00) / (hour24 - hour00) * 100 + "%";

            // Icon image
            const timeSliderImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/time-black.png") :
                getStaticPath("/images/common/time-white.png");

            // Set up time slider
            timeSlider = (
                <div id = "plan-time-slider-container">
                    <div id = "plan-time-slider-icon"
                        className = "image-contain"
                        data-tip = "Trip Duration"
                        data-for = "plan-time-slider-icon-tooltip"
                        style = {{ backgroundImage: timeSliderImage }}
                    >
                        <ReactTooltip
                            id = "plan-time-slider-icon-tooltip"
                            className = "plan-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom"
                        />
                    </div>
                    <div id = "plan-time-slider">
                        <div className = {(this.props.colorMode === "day")?
                                "plan-time-slider-dot-day" :
                                "plan-time-slider-dot-night"}
                            style = {{ left: sunriseWidth }}
                        >
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "plan-time-slider-text-day" :
                                "plan-time-slider-text-night"}
                            style = {{ left: sunriseWidth }}>
                            {"Sunrise"}
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "plan-time-slider-dot-day" :
                                "plan-time-slider-dot-night"}
                            style = {{ left: solarNoonWidth }}
                        >
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "plan-time-slider-text-day" :
                                "plan-time-slider-text-night"}
                            style = {{ left: solarNoonWidth }}
                        >
                            {"Solar Noon"}
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "plan-time-slider-dot-day" :
                                "plan-time-slider-dot-night"}
                            style = {{ left: sunsetWidth }}
                        >
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                                "plan-time-slider-text-day" :
                                "plan-time-slider-text-night"}
                            style = {{ left: sunsetWidth }}
                        >
                            {"Sunset"}
                        </div>
                        <InputRange
                            classNames = {sliderClassNames}
                            maxValue = {this.state.hour24Moment.unix()}
                            minValue = {this.state.hour00Moment.unix()}
                            value = {this.state.startEndTimeValues}
                            onChange = {this.timeSliderOnChange}
                            onChangeComplete = {(values) => { this.timeSliderOnChangeComplete(values, true); }}
                            formatLabel = {this.timeSliderFormatLabel}
                            showEndLabels = {false}
                            step = {300}
                        />
                    </div>
                </div>
            );
        }
        else {
            timeSlider = null;
        }


        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        let map = null;

        // Using Google maps
        if (this.mapSource === "google") {
            // Only when google object and locations are ready
            if ((this.props.google) && (this.state.itinerary !== null)) {
                // Set the props for Map component
                const mapProps = {
                    // Google
                    google: this.props.google,

                    // Map mode
                    mapMode: this.mapMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    mapContainerNodeID : this.mapContainerNodeID,
                    buttonsNodeID: this.mapButtonsNodeID,
                    startInputNodeID: this.startInputNodeID,
                    endInputNodeID: this.endInputNodeID,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: this.state.tripInfo.trip_extension.children,

                    // Itinerary and selected settings
                    itinerary: this.state.itinerary,

                    // Selected / hovered / selectedChild / hoveredChild / displayChildren
                    selected: this.state.selected,
                    hovered: this.state.hovered,
                    selectedChild: this.state.selectedChild,
                    hoveredChild: this.state.hoveredChild,
                    displayChildren: this.state.displayChildren,

                    // Parking / start / end locations
                    parkingLocation: null,
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Action triggers
                    mapZoomInAnimation: this.state.mapZoomInAnimation,
                    mapZoomOutAnimation: this.state.mapZoomOutAnimation,
                    mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
                    mapRefreshAnimation: this.state.mapRefreshAnimation,
                    resetAnimations: this.resetAnimations,

    	            // Dot click and hover callback
    	            dotClick: this.dotClick,
                    //dotAddClick: this.dotAddClick,
                    //dotRemoveClick: this.dotRemoveClick,
    	            dotHoverOn: this.dotHoverOn,
    	            dotHoverOff: this.dotHoverOff,
    	            endHoverOn: this.endHoverOn,
    	            endHoverOff: this.endHoverOff,

                    // Parse callbacks (modify top states)
                    setParkingLocation: null,
                    setStartLocation: this.setStartLocation,
                    setEndLocation: this.setEndLocation,

                    // Map type
                    mapType: this.state.tripInfo.map_type,

                    // Default zoom level
                    mapZoom: this.mapZoom
                }
                //console.log("Plan / render - mapProps = ", mapProps);

                // Get the Map component
                map = (<GoogleMap {...mapProps}/>);
            }
        }

        // Using Open Street Maps
        else if (this.mapSource === "open") {
            if ((this.state.tripInfo !== null) && (this.state.itinerary !== null) && (this.state.mapOn)) {
                //console.log("tripInfo = ", this.state.tripInfo);
                //console.log("itinerary = ", this.state.itinerary);
                const averageLocation = getAverageLocation(
                    this.state.itinerary,
                    this.state.tripInfo.trip_extension.children
                );
                //console.log("averageLocation = ", averageLocation);

                // Set the props for Map component
                const mapProps = {
                    // Map refresh
                    mapRefresh: this.state.customizeOn,

                    // Google
                    google: this.props.google,

                    // Map mode
                    mapMode: this.mapMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    startInputNodeID: this.startInputNodeID,
                    endInputNodeID: this.endInputNodeID,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: this.state.tripInfo.trip_extension.children,

                    // Itinerary
                    itinerary: this.state.itinerary,

                    // Selected / hovered / selectedChild / hoveredChild / displayChildren
                    selected: this.state.selected,
                    hovered: this.state.hovered,
                    selectedChild: this.state.selectedChild,
                    hoveredChild: this.state.hoveredChild,
                    displayChildren: this.state.displayChildren,

                    // Parking / start / end locations
                    parkingLocation: null,
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Dot click and hover callback
                    dotClick: this.dotClick,
                    //dotAddClick: this.dotAddClick,
                    //dotRemoveClick: this.dotRemoveClick,
                    //dotHoverOn: this.dotHoverOn,
                    //dotHoverOff: this.dotHoverOff,
                    //endHoverOn: this.endHoverOn,
                    //endHoverOff: this.endHoverOff,

                    // Parse callbacks (modify top states)
                    setParkingLocation: null,
                    setStartLocation: this.setStartLocation,
                    setEndLocation: this.setEndLocation,

                    // Map center / zoom / type
                    mapCenter: averageLocation,
                    mapZoom: this.mapZoom,
                    mapType: "roadmap",

                    // SetState
                    setState: this.setState
                }
                //console.log("Plan / render - mapProps = ", mapProps);

                // Get the Map component
                map = (<OpenMap {...mapProps}/>);
            }
        }


        /*
        ============================================================================================
        ============================================================================================
            Wide Format Components
            - Preview
            - Selection
            - Main Menu
            - Category Menu
        ============================================================================================
        ============================================================================================
        */


        /*
        ============================================================================================
            Dot Preview
        ============================================================================================
        */

        let previewResizedMediaWidth = null;
        let previewResizedMediaHeight = null;
        let previewMediaURL = null;
        let previewShadowImage = null;
        let previewMediaWrapperStyle = null;
        let previewNextArrow = null;
        let previewPrevArrow = null;
        let previewName = null;
        let previewOverview = null;
        let previewDifficulty = null;
        let previewRating = null;
        let previewHours = null;
        let previewTime = null;

        if (!layoutSmall && ((this.state.selected !== null) || (this.state.selectedChild))) {
            const dotInfo = (this.state.selectedChild)? 
                this.state.tripInfo.trip_extension.children[this.state.selected].trip_extension.children[this.state.selectedChild] : 
                this.state.tripInfo.trip_extension.children[this.state.selected];

            // Common dot
            const commonDot = (dotInfo.type === "EV" || dotInfo.type === "AU");

            /*
            ============================================================================================
                Preview Gallery
            ============================================================================================
            */

            // Get the media dimensions for the right size (medium size: "m")
            // Pick the properties of the right size
            const mediaWidth = getMediaProperty(dotInfo.media[this.state.selectedMediaIndex], "o", 'width', false);
            const mediaHeight = getMediaProperty(dotInfo.media[this.state.selectedMediaIndex], "o", 'height', false);
            const resizeRatio = Math.sqrt(this.previewMediaArea / (mediaWidth * mediaHeight));
            const aspectRatio = mediaHeight / mediaWidth;

            // Resized media size
            const resizedMediaWidth = Math.round(mediaWidth * resizeRatio);
            if (resizedMediaWidth < this.previewMediaMinWidth) {
                previewResizedMediaWidth = this.previewMediaMinWidth;
            }
            else if (resizedMediaWidth > this.previewMediaMaxWidth) {
                previewResizedMediaWidth = this.previewMediaMaxWidth;
            }
            else {
                previewResizedMediaWidth = resizedMediaWidth;
            }
            previewResizedMediaHeight = Math.round(previewResizedMediaWidth * aspectRatio);

            // Media URL
            previewMediaURL = getMediaProperty(dotInfo.media[this.state.selectedMediaIndex], "s", 'url', true);
            previewShadowImage = (this.props.colorMode === "day")?
                null : getStaticPath("/images/shadow/vignette.png");

            // Media style
            previewMediaWrapperStyle = {
                width: previewResizedMediaWidth,
                height: Math.min(this.previewMediaMaxHeight, previewResizedMediaHeight),
            };

            // Preview media navigation arrows
            const nextArrowImage = getStaticPath("/images/common/arrow-right-white.png");

            previewNextArrow = this.state.selectedMediaIndex < (dotInfo.media.length - 1)?
            (
                <div id = "plan-item-preview-image-next-arrow"
                    style = {{ backgroundImage: nextArrowImage }}
                    onClick = {this.nextMediaClick}
                >
                </div>
            ) : null;

            const prevArrowImage = getStaticPath("/images/common/arrow-left-white.png");

            previewPrevArrow = this.state.selectedMediaIndex > 0?
            (
                <div id = "plan-item-preview-image-prev-arrow"
                    style = {{ backgroundImage: prevArrowImage  }}
                    onClick = {this.prevMediaClick}
                >
                </div>
            ) : null;

            // Preview name and overview
            previewName = (dotInfo.name)? (
                <div id = "plan-item-preview-name"
                    className = {(this.props.colorMode === "day")? "k2" : "w2"}
                >
                    {dotInfo.name}
                </div>
            ) : null;

            previewOverview = (dotInfo.overview)? (
                <div id = "plan-item-preview-overview"
                    className = {(this.props.colorMode === "day")? "dg4" : "g4"}
                >
                    {dotInfo.overview.replace(/[*]/g, "")}
                </div>
            ) : null;

            if (!commonDot) {
                // Preview difficulty and rating
                if (dotInfo.dot_extension.difficulty !== null) {
                    const difficultyValue = Math.min(Math.ceil(dotInfo.dot_extension.difficulty), 5);
                    const difficultyBar = (difficultyValue < 5)?
                    (
                        <div id = {(this.props.colorMode === "day")?
                            "plan-item-preview-difficulty-bar-front-regular-day" :
                            "plan-item-preview-difficulty-bar-front-regular-night"}
                            style = {{ width: Math.round(difficultyValue * 60 / 5) }}
                        >
                        </div>
                    ) : (
                        <div id = {(this.props.colorMode === "day")?
                            "plan-item-preview-difficulty-bar-front-round-day" :
                            "plan-item-preview-difficulty-bar-front-round-night"}
                            style = {{ width: Math.round(difficultyValue * 60 / 5) }}
                        >
                        </div>
                    );
                    previewDifficulty = (
                        <div id = "plan-item-preview-difficulty">
                            <div id = "plan-item-preview-difficulty-text"
                                className = {(this.props.colorMode === "day")? "k4" : "w4"}
                            >
                                Difficulty
                            </div>
                            <div id = "plan-item-preview-difficulty-value"
                                className = {(this.props.colorMode === "day")?
                                "font-cabin light-red" : "font-cabin red"}
                            >
                                {Math.ceil(dotInfo.dot_extension.difficulty).toFixed(1)}
                            </div>
                            <div id = "plan-item-preview-difficulty-bar">
                                <div id = {(this.props.colorMode === "day")?
                                    "plan-item-preview-difficulty-bar-back-day" :
                                    "plan-item-preview-difficulty-bar-back-night"}
                                >
                                    {difficultyBar}
                                </div>
                            </div>
                        </div>
                    );
                }

                if (dotInfo.dot_extension.rating !== null) {
                    const ratingBar = (dotInfo.dot_extension.rating < 5)?
                    (
                        <div id = {(this.props.colorMode === "day")?
                            "plan-item-preview-rating-bar-front-regular-day" :
                            "plan-item-preview-rating-bar-front-regular-night"}
                            style = {{ width: Math.round(dotInfo.dot_extension.rating * 60 / 5) }}
                        >
                        </div>
                    ) : (
                        <div id = {(this.props.colorMode === "day")?
                            "plan-item-preview-rating-bar-front-round-day" :
                            "plan-item-preview-rating-bar-front-round-night"}
                            style = {{ width: Math.round(dotInfo.dot_extension.rating * 60 / 5) }}
                        >
                        </div>
                    );
                    previewRating = (
                        <div id = "plan-item-preview-rating">
                            <div id = "plan-item-preview-rating-text"
                                className = {(this.props.colorMode === "day")? "k4" : "w4"}
                            >
                                Rating
                            </div>
                            <div id = "plan-item-preview-rating-value"
                                className = {(this.props.colorMode === "day")?
                                "font-cabin light-blue" : "font-cabin blue"}
                            >
                                {dotInfo.dot_extension.rating}
                            </div>
                            <div id = "plan-item-preview-rating-bar">
                                <div id = {(this.props.colorMode === "day")?
                                    "plan-item-preview-rating-bar-back-day" :
                                    "plan-item-preview-rating-bar-back-night"}
                                >
                                    {ratingBar}
                                </div>
                            </div>
                        </div>
                    );
                }

                // Preview hours
                previewHours = ((dotInfo.dot_extension.start_hour) && (dotInfo.dot_extension.end_hour))? (
                    <div id = "plan-item-preview-hours">
                        <div id = "plan-item-preview-hours-text"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        >
                            Hours
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                            "plan-item-preview-hours-value font-cabin black" :
                            "plan-item-preview-hours-value font-cabin white"}
                        >
                            {dotInfo.dot_extension.start_hour}
                        </div>
                        <div id = "plan-item-preview-hours-line"
                            className = {(this.props.colorMode === "day")?
                            "light-gray" : "dark-gray"}
                        >
                            |
                        </div>
                        <div className = {(this.props.colorMode === "day")?
                            "plan-item-preview-hours-value font-cabin black" :
                            "plan-item-preview-hours-value font-cabin day"}
                        >
                            {dotInfo.dot_extension.end_hour}
                        </div>
                    </div>
                ) : null;

                // Preview time
                previewTime = (dotInfo.dot_extension.time)? (
                    <div id = "plan-item-preview-time">
                        <div id = "plan-item-preview-time-text"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        >
                            Estimated Time
                        </div>
                        <div id = "plan-item-preview-time-value"
                            className = {(this.props.colorMode === "day")?
                            "font-cabin black" : "font-cabin white"}
                        >
                            {dotInfo.dot_extension.time}
                        </div>
                    </div>
                ) : null;
            }
        }


        /*
        ============================================================================================
            Main Menu
        ============================================================================================
        */
        
        // Selection basic settings icon
        const basicSettingsIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/basic-settings-black.png") :
            getStaticPath("/images/common/basic-settings-white.png");

        const basicSettingsIconClick = (this.props.itineraryAnimation === null)?
            this.basicSettingsIconClick.bind(this) : null;
        const basicSettingsIconClass = (this.props.itineraryAnimation === null)?
            (
                this.state.basicSettingsOn?
                    "plan-menu-icon-on image-button-s3" :
                    "plan-menu-icon image-button-s3"
            ) : "plan-menu-icon-off image-button-s3";
        let basicSettingsIcon = (
            <div
                id = "plan-basic-settings-icon"
                className = {basicSettingsIconClass}
                data-tip data-for = "plan-basic-settings-icon-tooltip"
                style = {{ backgroundImage: basicSettingsIconImage }}
                onClick = {basicSettingsIconClick}
            >
            </div>
        );
        let basicSettingsIconTooltip = (
          	<ReactTooltip id = "plan-basic-settings-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
            	<span>Basic Settings</span>
          	</ReactTooltip>
        );

        // Selection Map Icon
        const mapIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/map-black.png") :
            getStaticPath("/images/common/map-white.png");
        const mapIconClick = (this.props.itineraryAnimation === null)?
            this.mapIconClick.bind(this) : null;
        const mapIconClass = (this.props.itineraryAnimation === null)?
            (this.state.mapOn?
                "plan-menu-icon-on image-button-s3" :
                "plan-menu-icon image-button-s3") :
             "plan-menu-icon-off image-button-s3";
        let mapIcon = (
            <div
                id = "plan-map-icon"
                className = {mapIconClass}
                data-tip data-for = "plan-map-icon-tooltip"
                style = {{ backgroundImage: mapIconImage }}
                onClick = {mapIconClick}
            >
            </div>
        );
        let mapIconTooltip = (
          	<ReactTooltip id = "plan-map-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
            	<span>Map</span>
          	</ReactTooltip>
        );

        // Selection CutomizeIcon
        const customizeIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/customize-black.png") :
            getStaticPath("/images/common/customize-white.png");

        const customizeIconClick = (this.props.itineraryAnimation === null)?
            this.customizeIconClick.bind(this) : null;
        const customizeIconClass = (this.props.itineraryAnimation === null)?
            (this.state.customizeOn?
                "plan-menu-icon-on image-button-s3" :
                "plan-menu-icon image-button-s3") :
             "plan-menu-icon-off image-button-s3";
        let customizeIcon = (
            <div
                id = "plan-customize-icon"
                className = {customizeIconClass}
                data-tip data-for = "plan-customize-icon-tooltip"
                style = {{ backgroundImage: customizeIconImage }}
                onClick = {customizeIconClick}
            >
            </div>
        );
        let customizeIconTooltip = (
          	<ReactTooltip id = "plan-customize-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
            	<span>Customize</span>
          	</ReactTooltip>
        );

        // Selection CustomSettingsIcon
        const customSettingsIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/custom-settings-black.png") :
            getStaticPath("/images/common/custom-settings-white.png");

        const customSettingsIconClick = (this.props.itineraryAnimation === null)?
            this.customSettingsIconClick.bind(this) : null;
        const customSettingsIconClass = (this.props.itineraryAnimation === null)?
            (this.state.customSettingsOn?
                "plan-menu-icon-on image-button-s3" : "plan-menu-icon image-button-s3") :
             "plan-menu-icon-off image-button-s3";

        let customSettingsIcon = (
            <div
                id = "plan-custom-settings-icon"
                className = {customSettingsIconClass}
                data-tip data-for = "plan-custom-settings-icon-tooltip"
                style = {{ backgroundImage: customSettingsIconImage }}
                onClick = {customSettingsIconClick}
            >
            </div>
        );
        let customSettingsIconTooltip = (
          	<ReactTooltip id = "plan-custom-settings-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
            	<span>Advanced Settings</span>
          	</ReactTooltip>
        );

        // Set the buttons according to the current layout
        let selectionBasicSettingsIcon, selectionMapIcon, selectionCustomizeIcon, selectionCustomSettingsIcon;
        let selectionBasicSettingsIconTooltip, selectionMapIconTooltip, selectionCustomizeIconTooltip, selectionCustomSettingsIconTooltip;

        if (!this.state.basicSettingsOn) {
            selectionBasicSettingsIcon = basicSettingsIcon;
            selectionBasicSettingsIconTooltip = basicSettingsIconTooltip;
            basicSettingsIcon = null;
            basicSettingsIconTooltip = null;
        }
        else {
            selectionBasicSettingsIcon = null;
            selectionBasicSettingsIconTooltip = null;
        }

        if (!this.state.mapOn) {
            selectionMapIcon = mapIcon;
            selectionMapIconTooltip = mapIconTooltip;
            mapIcon = null;
            mapIconTooltip = null;
        }
        else {
            selectionMapIcon = null;
            selectionMapIconTooltip = null;
        }

       	selectionCustomizeIcon = customizeIcon;
       	selectionCustomizeIconTooltip = customizeIconTooltip;

        if (!this.state.customSettingsOn) {
            selectionCustomSettingsIcon = customSettingsIcon;
            selectionCustomSettingsIconTooltip = customSettingsIconTooltip;
            customSettingsIcon = null;
            customSettingsIconTooltip = null;
        }
        else {
            selectionCustomSettingsIcon = null;
            selectionCustomSettingsIconTooltip = null;
        }


        /*
        ============================================================================================
            Selection
        ============================================================================================
        */

        // Dot selection component
        const selection = ((this.state.itinerary) && (this.state.tripInfo))? (
            <Selected
                colorMode = {this.props.colorMode}

            	dots = {this.state.itinerary}
                itinerary = {this.state.itinerary}
                dotsInfo = {this.state.tripInfo.trip_extension.children}
                selected = {this.state.selected}
                hovered = {this.state.hovered}
                selectedChild = {this.state.selectedChild}
                hoveredChild = {this.state.hoveredChild}
                displayChildren = {this.state.displayChildren}
                customizeOn = {this.state.customizeOn}
                informationOn = {false}

                dotOpacities = {this.state.dotOpacities}
                dotShiftTops = {this.state.dotShiftTops}
                dotShiftLefts = {this.state.dotShiftLefts}
                dotIndexOn = {this.state.dotIndexOn}
                dotIndexOpacity = {this.state.dotIndexOpacity}

                dotConnectAnimationClass = {this.state.dotConnectAnimationClass}
				setDotNodes = {this.setDotNodes}

				dotClick = {this.dotClick}
                dotAddClick = {this.dotAddClick}
                dotRemoveClick = {this.dotRemoveClick}
				dotHoverOn = {this.dotHoverOn}
				dotHoverOff = {this.dotHoverOff}
                dotLeftClick = {this.dotLeftClick}
                dotRightClick = {this.dotRightClick}

	            inItinerary = {true}

                startMoment = {this.state.startMoment}
                endMoment = {this.state.endMoment}
            />
        ): null;

        // Calculate selection width
        let selectionWidth = null;
        if (this.state.customizeOn) {
            const occupiedWidth = this.itineraryOptionsWidth + 2 * this.itineraryRightMenuWidth;
            selectionWidth = "calc(100% - " + occupiedWidth + "px)";
        }
        else {
            const occupiedWidth = 2 * this.itineraryRightMenuWidth;
            selectionWidth = "calc(100% - " + occupiedWidth + "px)";
        }


        /*
        ============================================================================================
            Options
        ============================================================================================
        */

		    // Get the non-selected dots
        const dots = [];
        for (let i = 0; i < this.state.tripInfo.trip_extension.children; i++) {
        	if (this.state.itinerary.indexOf(i) === -1) {
        		dots.push(i);
        	}
        }

        // Options component
        const options = ((this.state.itinerary) && (this.state.tripInfo))? (
            <Optional
                colorMode = {this.props.colorMode}
                itinerary = {this.state.itinerary}
                dotsInfo = {this.state.tripInfo.trip_extension.children}
                selected = {this.state.selected}
                hovered = {this.state.hovered}
                selectedChild = {this.state.selectedChild}
                hoveredChild = {this.state.hoveredChild}
                displayChildren = {this.state.displayChildren}
                customizeOn = {this.state.customizeOn}
                informationOn = {false}
                optionsNumDotsCategoryDisplayed = {this.optionsNumDotsCategoryDisplayed}
                optionsCategory = {this.state.optionsCategory}
                optionsScenicPage = {this.state.optionsScenicPage}
                optionsDinePage = {this.state.optionsDinePage}
                optionsExperiencePage = {this.state.optionsExperiencePage}
                optionsRoutePage = {this.state.optionsRoutePage}
                optionsNextPage = {this.optionsNextPage}
                optionsPreviousPage = {this.optionsPreviousPage}
                dotConnectAnimationClass = {"dot-object-wrapper"}
                dotClick = {this.dotClick}
                dotAddClick = {this.dotAddClick}
                dotRemoveClick = {this.dotRemoveClick}
                dotHoverOn = {this.dotHoverOn}
                dotHoverOff = {this.dotHoverOff}
                dotLeftClick = {null}
                dotRightClick = {null}
                startMoment = {this.state.startMoment}
                endMoment = {this.state.endMoment}
            />
        ): null;

        // Calculate options width
        const optionsWidth = this.state.customizeOn? this.itineraryOptionsWidth : 0;


        /*
        ============================================================================================
            Category Menu
        ============================================================================================
        */

       	// Scenic category
        const scenicIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/category-scenic-black.png") :
            getStaticPath("/images/common/category-scenic-white.png");

        const scenicIconClick = this.scenicIconClick.bind(this);
        const scenicIconClass = (this.props.itineraryAnimation === null)?
            (this.state.optionsCategory === "scenic"?
                "plan-menu-icon-on image-button-s3" :
                "plan-menu-icon image-button-s3") :
             "plan-menu-icon-off image-button-s3";
        const scenicIcon = (
            <div
                id = "plan-options-scenic-icon"
                className = {scenicIconClass}
                data-tip data-for = "plan-options-scenic-icon-tooltip"
                style = {{
                    display: this.state.customizeOn? "inline-block" : "none",
                    backgroundImage: scenicIconImage
                }}
                onClick = {scenicIconClick}
            >
            </div>
        );
        const scenicIconTooltip = (
			<ReactTooltip id = "plan-options-scenic-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
				<span>Scenic Places</span>
			</ReactTooltip>
        );


        // Dine category
        const dineIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/category-dine-black.png") :
            getStaticPath("/images/common/category-dine-white.png");

        const dineIconClick = this.dineIconClick.bind(this);
        const dineIconClass = (this.props.itineraryAnimation === null)?
            (this.state.optionsCategory === "dine"?
                "plan-menu-icon-on image-button-s3" :
                "plan-menu-icon image-button-s3") :
             "plan-menu-icon-off image-button-s3";
        const dineIcon = (
            <div
                id = "plan-options-dine-icon"
                className = {dineIconClass}
                data-tip data-for = "plan-options-dine-icon-tooltip"
                style = {{
                    display: this.state.customizeOn? "inline-block" : "none",
                    backgroundImage: dineIconImage
                }}
                onClick = {dineIconClick}
            >
            </div>
        );
        const dineIconTooltip = (
			<ReactTooltip id = "plan-options-dine-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
				<span>Dining Options</span>
			</ReactTooltip>
        );

        // Experience category
        const experienceIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/category-experience-black.png") :
            getStaticPath("/images/common/category-experience-white.png");

        const experienceIconClick = this.experienceIconClick.bind(this);
        const experienceIconClass = (this.props.itineraryAnimation === null)?
            (this.state.optionsCategory === "experience"?
                "plan-menu-icon-on image-button-s3" :
                "plan-menu-icon image-button-s3") :
             "plan-menu-icon-off image-button-s3";
        const experienceIcon = (
            <div
                id = "plan-options-experience-icon"
                className = {experienceIconClass}
                data-tip data-for = "plan-options-experience-icon-tooltip"
                style = {{
                    display: this.state.customizeOn? "inline-block" : "none",
                    backgroundImage: experienceIconImage
                }}
                onClick = {experienceIconClick}
            >
            </div>
        );
        const experienceIconTooltip = (
			<ReactTooltip id = "plan-options-experience-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
				<span>Experiences</span>
			</ReactTooltip>
        );

        // Route category
        const routeIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/category-route-black.png") :
            getStaticPath("/images/common/category-route-white.png");

        const routeIconClick = this.routeIconClick.bind(this);
        const routeIconClass = (this.props.itineraryAnimation === null)?
            (this.state.optionsCategory === "route"?
                "plan-menu-icon-on image-button-s3" :
                "plan-menu-icon image-button-s3") :
             "plan-menu-icon-off image-button-s3";
        const routeIcon = (
            <div
                id = "plan-options-route-icon"
                className = {routeIconClass}
                data-tip data-for = "plan-options-route-icon-tooltip"
                style = {{
                    display: this.state.customizeOn? "inline-block" : "none",
                    backgroundImage: routeIconImage
                }}
                onClick = {routeIconClick}
            >
            </div>
        );
        const routeIconTooltip = (
			<ReactTooltip id = "plan-options-route-icon-tooltip"
                className = "tooltip-s2"
                type = "dark"
            >
				<span>Routes</span>
			</ReactTooltip>
		);



        /*
        ============================================================================================
        ============================================================================================
            
            Itinerary Gallery

        ============================================================================================
        ============================================================================================
        */


        /*
        ============================================================================================
            Display Switches
        ============================================================================================
        */

        // display switches
        const galleryOn = this.state.galleryOn? "block" : "none";
        //const finalizeOn = this.state.finalizeOn? "block" : "none";

        const basicSettingsOn = this.state.basicSettingsOn? "table" : "none";
        const customSettingsOn = this.state.customSettingsOn? "table" : "none";

        const mapOn = this.state.mapOn? "table" : "none";
        const previewOn = this.state.previewOn? "table" : "none";
        const previewContentOn = this.state.previewContentOn? "block" : "none";

        const optionsOn = this.state.customizeOn? "table-cell" : "none";


        /*
        ============================================================================================
            Plan Gallery
        ============================================================================================
        */

		const planGalleryProps = {
    		mode: "itinerary",
    		itinerary: this.state.itinerary,
    		startMoment: this.state.startMoment,
    		endMoment: this.state.endMoment,
    		dotsInfo: this.state.tripInfo.trip_extension.children,
    		dotTimes: this.state.dotTimes,
    		transitTimes: this.state.transitTimes,
    		transitTimeValues: this.state.transitTimeValues,
    		transitModes: this.state.transitModes,
    		randomizeMediaSets: false,
    		setState: this.setState
		};

		const itinerarySchedule = (
            (!layoutSmall) &&
            (this.state.itinerary) &&
            (this.state.connectDotsComplete) &&
            (this.state.connectDotsAnimationComplete) &&
            (this.state.dotTimes)
        )? (
            <div id = "plan-schedule">
                <div id = "plan-schedule-date"
                    className = {(this.props.colorMode === "day")? "number-lb0" : "number-b0"}
                >
                    {this.state.startMoment.format("MMM Do YYYY")}
                    <div id = "plan-schedule-start"
                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    >
                        Start
                    </div>
                    <div id = "plan-schedule-end"
                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    >
                        End
                    </div>
                </div>
    			<div id = "plan-schedule-gallery"
                    style = {{ display: galleryOn }}
                >
    				<PlanGallery {...planGalleryProps} />
    			</div>
            </div>
		): null;


        /*
        ============================================================================================
            Finalize Button Image
        ============================================================================================
        */

        const finalizeButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/print-light-blue.png") :
            getStaticPath("/images/common/print-blue.png");

        const finalizeButton = (this.state.finalizeOn)? (
            (loggedIn)?
            (
                <div id = {(!layoutSmall && this.state.isScrolled)?
                        "plan-finalize-bar-float" : "plan-finalize-bar"}
                    className = {(this.props.colorMode === "day")?
                        "plan-finalize-bar-day" : "plan-finalize-bar-night"}
                    style = {{ display: (this.state.finalizeOn)? "block" : "none" }}
                >
                    <div id = "plan-finalize-button"
                        onClick = {this.finalizeClick}
                    >
                        <div id = "plan-finalize-button-icon"
                            className = "image-contain"
                            style = {{ backgroundImage: finalizeButtonImage }}
                        >
                        </div>
                        <div id = "plan-finalize-button-text"
                            className = {(this.props.colorMode === "day")?
                                "k2" : "w2"}
                        >
                            Finalize
                        </div>
                    </div>
                </div>
            ) : (
                <div id = {(!layoutSmall && this.state.isScrolled)?
                        "plan-finalize-bar-float" : "plan-finalize-bar"}
                    className = {(this.props.colorMode === "day")?
                        "plan-finalize-bar-day" : "plan-finalize-bar-night"}
                    style = {{ display: (this.state.finalizeOn)? "block" : "none" }}
                    onClick = {this.finalizeClick}
                >
                    <div id = "plan-finalize-button">
                        <div id = "plan-finalize-button-icon"
                            className = "image-contain"
                            style = {{ backgroundImage: finalizeButtonImage }}
                        >
                        </div>
                        <div id = "plan-finalize-button-text"
                            className = {(this.props.colorMode === "day")?
                                "k2" : "w2"}
                        >
                            Finalize
                        </div>
                    </div>
                </div>
            )
        ) : null;


        /*
        ============================================================================================
        ============================================================================================
            Small Format Components
            - Selection
            - Options 
            - Category Menu
            - Preview Modal
        ============================================================================================
        ============================================================================================
        */

        const selectedSmall = (layoutSmall && (this.state.itinerary !== null) && (this.state.tripInfo !== null))? (
            <SelectedSmall 
                colorMode = {this.props.colorMode}

                itinerary = {this.state.itinerary}
                dotsInfo = {this.state.tripInfo.trip_extension.children}
                selected = {this.state.selected}
                hovered = {this.state.hovered}
                selectedChild = {this.state.selectedChild}
                hoveredChild = {this.state.hoveredChild}
                displayChildren = {this.state.displayChildren}
                customizeOn = {this.state.dotIndexOn}
                informationOn = {true}

                dotOpacities = {this.state.dotOpacities}
                dotShiftTops = {this.state.dotShiftTops}
                dotShiftLefts = {this.state.dotShiftLefts}
                dotIndexOn = {this.state.dotIndexOn}
                dotIndexOpacity = {this.state.dotIndexOpacity}

                dotConnectAnimationClass = {this.state.dotConnectAnimationClass}
                setDotNodes = {this.setDotNodes}

                dotClick = {this.dotClick}
                dotAddClick = {this.dotAddClick}
                dotRemoveClick = {this.dotRemoveClick}
                dotHoverOn = {this.dotHoverOn}
                dotHoverOff = {this.dotHoverOff}
                dotLeftClick = {this.dotLeftClick}
                dotRightClick = {this.dotRightClick}

                inItinerary = {true}

                dotTimes = {this.state.dotTimes}
                transitModes = {this.state.transitModes}
                transitTimes = {this.state.transitTimes}

                startLocationName = {this.state.startLocationName}
                endLocationName = {this.state.endLocationName}

                startMoment = {this.state.startMoment}
                endMoment = {this.state.endMoment}
                actualEndMoment = {this.state.actualEndMoment}
            />
        ) : null;


        // Optional Small
        const optionalSmall = (layoutSmall && (this.state.itinerary !== null) && (this.state.tripInfo !== null))? (
            <OptionalSmall
                loggedIn = {loggedIn}
                browserWidth = {this.props.browserWidth}
                colorMode = {this.props.colorMode}
                itinerary = {this.state.itinerary}
                itineraryID = {this.state.itineraryID}
                dotsInfo = {this.state.tripInfo.trip_extension.children}
                selected = {this.state.selected}
                hovered = {this.state.hovered}
                selectedChild = {this.state.selectedChild}
                hoveredChild = {this.state.hoveredChild}
                displayChildren = {this.state.displayChildren}
                dotIndexOn = {this.state.dotIndexOn}
                customizeOn = {this.state.dotIndexOn}
                finalizeOn = {this.state.finalizeOn}
                informationOn = {true}
                optionalSmallOn = {this.state.optionalSmallOn}
                openOptionalSmall = {() => { this.setState({ optionalSmallOn: true }); }}
                closeOptionalSmall = {() => { this.setState({ optionalSmallOn: false }); }}
                dotConnectAnimationClass = {"dot-object-wrapper"}
                dotClick = {this.dotClick}
                dotAddClick = {this.dotAddClick}
                dotRemoveClick = {this.dotRemoveClick}
                dotHoverOn = {this.dotHoverOn}
                dotHoverOff = {this.dotHoverOff}
                dotLeftClick = {null}
                dotRightClick = {null}
                startMoment = {this.state.startMoment}
                endMoment = {this.state.endMoment}
                setState = {this.setState}
            />
        ): null;

        //console.log("preview flag = ", (layoutSmall) && ((this.state.selected !== null) && (this.state.tripInfo !== null)));

        const dotPreview = (layoutSmall && (this.state.itinerary  !== null) && (this.state.tripInfo !== null))? (
            <DotPreview
                // Color mode
                browserWidth = {this.props.browserWidth}
                browserWidthPixels = {this.props.browserWidthPixels}
                colorMode = {this.props.colorMode}

                // Dots
                tripInfo = {this.state.tripInfo}

                // Itinerary
                itinerary = {this.state.itinerary}

                // Selected / hovered / selectedChild / hoveredChild / displayChildren
                selected = {this.state.selected}
                hovered = {this.state.hovered}
                selectedChild = {this.state.selectedChild}
                hoveredChild = {this.state.hoveredChild}
                displayChildren = {this.state.displayChildren}

                // Start and end locations
                startLocation =  {this.state.startLocation}
                endLocation = {this.state.endLocation}

                // Action triggers 
                mapZoomInAnimation = {this.state.mapZoomInAnimation}
                mapZoomOutAnimation = {this.state.mapZoomOutAnimation}
                mapZoomHikeAnimation = {this.state.mapZoomHikeAnimation}
                mapRefreshAnimation = {this.state.mapRefreshAnimation}
                resetAnimations = {this.resetAnimations}

                // Dot click and hover callback
                dotClick = {this.dotClick}
                dotAddClick = {this.dotAddClick}
                dotRemoveClick = {this.dotRemoveClick}
                dotHoverOn = {this.dotHoverOn}
                dotHoverOff = {this.dotHoverOff}
                endHoverOn = {this.endHoverOn}
                endHoverOf = {this.endHoverOff}

                // Google
                google = {this.props.google}
            />
        ) : null;


        /*
        ============================================================================================
            Finalize Modal
        ============================================================================================
        */

        const finalizeModal = (
            <div id = "plan-finalize-modal" 
                style = {{ display: (this.state.finalizeModalOn)? "block" : "none" }}
            >
                <div id = "plan-finalize-itinerary"
                    className = {(this.props.colorMode === "day")? 
                        "modal-day" :
                        "modal-night"} 
                >
                    <div id = "plan-finalize-itinerary__cancel"
                        className = "image-button-weaker-s3"
                        style = {{ 
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png") 
                        }}
                       onClick = {() => { this.setState({ finalizeModalOn: false }); }}
                    >
                    </div>

                    <div id = "plan-finalize-title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        Save Itinerary
                    </div>
                    <div id = "plan-finalize-message"
                        className ={(this.props.colorMode === "day")? "dg4" : "g4"}
                    >
                        Log in or sign up to save the planned itinerary.
                    </div>
                    <div id = "plan-finalize-log-in"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-gray-s2" : "button-blue-gray-s2"}
                        onClick = {
                            () => {
                                this.setState(
                                    {
                                        finalizeModalOn: false
                                    },
                                    () => {
                                        // Open log in
                                        this.props.storeLogInOn(true);
                                    }
                                );
                            }
                        }
                    >
                        Log In
                    </div>
                    <div id = "plan-finalize-sign-up"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-gray-s2" : "button-blue-gray-s2"}
                        onClick = {
                            () => {
                                this.setState(
                                    {
                                        finalizeModalOn: false
                                    },
                                    () => {
                                        // Open sign up
                                        this.props.storeSignUpOn(true);
                                    }
                                );
                            }
                        }
                    >
                        Sign Up
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
        ============================================================================================
            Main Render
        ============================================================================================
        ============================================================================================
        */

        // Itinerary body width
        const itineraryWidth = (this.state.customizeOn)? this.itineraryWidthRightOn : this.itineraryWidthRightOff;
        // console.log(this.state.google)

        if (layoutSmall) {
            return (
                <div id = "plan-container-small">
                    {finalizeModal}
                    {selectedSmall}
                    {optionalSmall}
                    {dotPreview}
                </div>
            );
        }
        else {
        	return (
                <div id = "plan-container">

                    {finalizeModal}

        			<div className = "plan-section"
                        style = {{ maxWidth: itineraryWidth }}
                    >
        				<div className = "plan-table"
                            style = {{ display: basicSettingsOn }}
                        >
        					<div className = "plan-table-row">
        						<div className = "plan-left-menu">
        							{basicSettingsIcon}
        							{basicSettingsIconTooltip}							
        		                </div>

        						<div className = "plan-rest">
        							<div id = "plan-calendar-dayinfo">
                                        <div className = "plan-calendar-dayinfo-row clear-fix">
                                            <div id = "plan-calendar-title"
                                                className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                            >
                                                Travel Date
                                            </div>
                                            <div id = "plan-dayinfo-title"
                                                className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                            >
                                                Weather and Astronomy
                                            </div>
                                        </div>
                                        <div className = "plan-calendar-dayinfo-row clear-fix">
                                            <div id = "plan-calendar-column">
                                                {calendar}
                                            </div>
                                            <div id = "plan-dayinfo-column">
                                                <div id = "plan-dayinfo">
                                                    {dayInfo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id = "plan-time">
                                        <div id = "plan-time-slider-title"
                                            className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                        >
                                            Trip Duration
                                        </div>
                                        {timeSlider}
                                    </div>

                                    <div id = "plan-locations-container">
                                        <div id = "plan-location-title"
                                            className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                        >
                                            Start and End Locations
                                        </div>
                                        <div id = "plan-locations" className = "clear-fix">
                                            {locationInputIcon}
                                            <input {...startInputProps}
                                                id = {this.startInputNodeID}
                                                className = {(this.props.colorMode === "day")?
                                                    "input-s3 input-day" : "input-s3 input-night"}
                                                ref = {(node) => {this.startInputNode = node}}
                                                data-tip = "Start Location"
                                                data-for = {this.startInputNodeID + "-tooltip"}
                                            />
                                            <ReactTooltip
                                                id = {this.startInputNodeID + "-tooltip"}
                                                className = "plan-tooltip tooltip-s2"
                                                type = "dark"
                                                place = "bottom"
                                            />
                                            <input {...endInputProps}
                                                id = {this.endInputNodeID}
                                                className = {(this.props.colorMode === "day")?
                                                    "input-s3 input-day" : "input-s3 input-night"}
                                                ref = {(node) => {this.endInputNode = node}}
                                                data-tip = "End Location"
                                                data-for = {this.endInputNodeID + "-tooltip"}
                                            />
                                            <ReactTooltip
                                                id = {this.endInputNodeID + "-tooltip"}
                                                className = "plan-tooltip tooltip-s2"
                                                type = "dark"
                                                place = "bottom"
                                            />
                                        </div>
                                    </div>
                                </div>

        						<div id = "plan-buttons">
        							{findButtonText}
        							{findButton}
        							{connectButtonText}
        							{connectButton}
                                    <ReactTooltip
                                        id = {"plan-find-button-tooltip"}
                                        className = "plan-tooltip tooltip-s2"
                                        type = {findButtonTooltipStyle}
                                        place = "bottom"
                                    />
                                    <ReactTooltip
                                        id = {"plan-connect-button-tooltip"}
                                        className = "plan-tooltip tooltip-s2"
                                        type = {connectButtonTooltipStyle}
                                        place = "bottom"
                                    />
        						</div>
        						<div className = "plan-right-menu">
        						</div>
                            </div>
                        </div>
                    </div>

                    <div className = "plan-section" style = {{ maxWidth: itineraryWidth }}>
                        <div className = "plan-table" style = {{ display: customSettingsOn }}>
                            <div className = "plan-table-row">
                                <div className = "plan-left-menu">
                                    {customSettingsIcon}
                                    {customSettingsIconTooltip}
                                </div>

                                <div className = "plan-rest">
                                    <div id = "plan-custom-settings">
                                        <div id = "plan-fitness-level">
                                            <div id = "plan-fitness-level-text"
                                                className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                            >
                                                Fitness Level
                                            </div>
                                            <div id = "plan-fitness-level-slider">
                                                <InputRange
                                                    classNames = {sliderClassNames}
                                                    maxValue = {5}
                                                    minValue = {1}
                                                    value = {this.state.fitnessLevel}
                                                    onChange = {
                                                        (fitnessLevel) => {
                                                            this.setState({ 
                                                                fitnessLevel: fitnessLevel,
                                                            });
                                                        }
                                                    }
                                                    onChangeComplete = {
                                                        (fitnessLevel) => {
                                                            this.setState(
                                                                {
                                                                    fitnessLevel: fitnessLevel
                                                                },
                                                                this.checkUpdate
                                                            );
                                                        }
                                                    }
                                                    formatLabel = {
                                                        fitnessLevel => {
                                                            return FITNESS_LEVELS[fitnessLevel];
                                                        }
                                                    }
                                                    showEndLabels = {false}
                                                    step = {1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className = "plan-right-menu">
                                </div>
                            </div>
                        </div>
                    </div>
        			<div className = "plan-section" style = {{ maxWidth: itineraryWidth }}>
        				<div className = "plan-table" style = {{ display: mapOn }}>
        					<div className = "plan-table-row">
        						<div className = "plan-left-menu">
        							{mapIcon}
        							{mapIconTooltip}
        						</div>
        						<div id = "plan-map-section">
                                    <div id = "plan-map-wrapper"
                                        className = {(this.props.colorMode === "day")?
                                            "border-day" : "border-night"}
                                    >
                                        {map}
                                    </div>
        						</div>
        						<div className = "plan-right-menu">
        						</div>  
        					</div>
        				</div>
        			</div>

                    <div className = "plan-section" style = {{ maxWidth: itineraryWidth }}>
                        <div className = "plan-table" style = {{ display: previewOn }}>
                            <div className = "plan-table-row">
                                <div className = "plan-left-menu">
                                </div>
                                <div className = "plan-rest">
                                    <div id = "plan-item-preview-container" 
                                        style = {{ display: previewContentOn }}
                                    >
                                        <div id = "plan-item-preview">
                                            <div id = "plan-item-preview-image-container"
                                                className = {(this.props.colorMode === "day")? 
                                                    "border-day image-loader-s2" : "border-night image-loader-s2"}
                                                style = {{
                                                    backgroundImage: (this.props.colorMode === "day")? 
                                                        getStaticPath("/images/loader/loader-black.gif") :
                                                        getStaticPath("/images/loader/loader-white.gif"),
                                                    width: previewResizedMediaWidth, 
                                                    height: previewResizedMediaHeight
                                                }}
                                            >
                                                <div id = "plan-item-preview-image-wrapper" 
                                                    style = {previewMediaWrapperStyle}
                                                >
                                                    <div id = "plan-item-preview-image-shadow"
                                                        style = {{ backgroundImage: previewShadowImage }}
                                                    >
                                                        <div id = "plan-item-preview-image-buttons">
                                                            {previewNextArrow}
                                                            {previewPrevArrow}
                                                        </div>
                                                    </div>
                                                    <div id = "plan-item-preview-image"
                                                        className = "image-gallery"
                                                        style = {{ backgroundImage: previewMediaURL }}
                                                    >
                                                    </div>
                                                </div>
                                            </div>
                                            {previewName}
                                            {previewOverview}
                                            <div id = "plan-item-preview-stats">
                                                {previewRating}
                                                {previewDifficulty}
                                                {previewHours}
                                                {previewTime}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className = "plan-right-menu">
                                </div>
                            </div>
                        </div>
                    </div>
        			<div className = "plan-section" style = {{ maxWidth: itineraryWidth }}>
        				<div className = "plan-table">
        					<div className = "plan-table-row">
        						<div id = "plan-selection-menu">
        							{selectionBasicSettingsIcon}
        							{selectionMapIcon}
        							{selectionCustomizeIcon}
        							{selectionCustomSettingsIcon}
        							{selectionBasicSettingsIconTooltip}
        							{selectionMapIconTooltip}
        							{selectionCustomizeIconTooltip}
        							{selectionCustomSettingsIconTooltip}
        						</div>
        						<div id = "plan-selection" style = {{ width: selectionWidth }}>
        							{selection}
        						</div>
        						<div id = "plan-options" style = {{ width: optionsWidth, display: optionsOn }}>
        							{options}
        						</div>
        						<div id = "plan-options-menu">
        							{scenicIcon}
        							{dineIcon}
        							{experienceIcon}
        							{routeIcon}
        							{scenicIconTooltip}
        							{dineIconTooltip}
        							{experienceIconTooltip}
        							{routeIconTooltip}
        						</div>
        					</div>
        				</div>
        			</div>
        			{itinerarySchedule}

                    {finalizeButton}
        		</div>
            );
        }
    }

    componentDidMount() {
        //console.log("Plan / componentDidMount");

        // Add event listener 
        document.addEventListener("click", this.checkScroll);

        // Find algorithm if no itinerary is provided
        if (this.state.itineraryID === null) {
            this.findDots(
    			() => {
                    // Whichever (connectDots vs findDotsAnimation) finishes first triggers connectDotsAnimation
                    if (this.state.connectReady) {
                        //console.log("Plan / componentDidMount - this.state.connectReady #1 = ", this.state.connectReady);

                        this.connectDots(
                            this.connectDotsAnimation.bind(this)
                        );
                    }

                    // Call find dots animation and subsequently find dots animation
    	    		this.findDotsAnimation(
                        () => {
                            //console.log("Plan / componentDidMount - this.state.connectReady #2 = ", this.state.connectReady);

                            if (this.state.connectReady) {
                                this.connectDotsAnimation();
                            }
                            else {
                                // Open basic settings
                                this.setState({
                                    basicSettingsOn: true
                                });

                                // Show alert
                                this.props.storeWarningAlert({
                                    message: "Too Many Dots are Selected. Reduce the Trip Duration and Retry.",
                                    on: true
                                });
                            }
                        }
    	    		);
    	        }
            );
        }
    }

    componentWillUnmount() {
        // Remove event listener
        document.removeEventListener("click", this.checkScroll);

        // Reset itinerary animation
        this.props.storeItineraryAnimation(null);

        // Reset save itinerary
        this.props.storeSaveItinerary(null);
    }


    checkScroll() {
        const checkScroll = () => {
            const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            const scrollHeight = Math.max(document.documentElement.scrollHeight, window.scrollHeight || 0);
            const isScrolled = (scrollHeight > viewportHeight);
            //console.log("Trip / render - viewportHeight = ", viewportHeight);
            //console.log("Trip / render - scrollHeight = ", scrollHeight);
            //console.log("Trip / render - isScrolled = ", isScrolled);

            this.setState({
                isScrolled: isScrolled
            });
        };

        // First check
        checkScroll();

        // Second check
        setTimeout(checkScroll, 600);
    }


    /*
    ============================================================================================
        setDotNodes
    --------------------------------------------------------------------------------------------
        - Callback to save dot node to operate on connect animation
    ============================================================================================
    */

    setDotNodes (index, dotNode) {
        //console.log("Plan / setDotNode - index / dotNode / this.dotNodes", index, dotNode, this.dotNodes);
        this.dotNodes[index] = dotNode;
    }


    /*
    ============================================================================================
        findDots
    --------------------------------------------------------------------------------------------
        - Send find dots query
    ============================================================================================
    */

    findDots(callback) {
        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("Plan / findDots - response.data.content = ", response.data.content);
            //console.log("Plan / findDots - newItinerary = ", response.data.content);

            // Initialize dot display properties
            const dotOpacities = [];
            const dotShiftTops = [];
            const dotShiftLefts = [];
            for (let i = 0; i < response.data.content.route.length; i++) {
            	dotOpacities.push(0.0);
            	dotShiftTops.push(0);
            	dotShiftLefts.push(0);
            }
            //const connectReady = (response.data.content.route.length <= this.maxNumDotsToConnect);
            //console.log("Plan / findDots - connectReady = ", connectReady);
            //console.log("Plan / findDots - ", dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children));

            // Update new itinerary
            this.props.storeNewItinerary({ operation: "add", info: response.data.content });
            this.props.storeItinerariesFetchComplete(false);

            // Update save itinerary
            const loggedIn = !!(localStorage.token && this.props.userInfo !== null);
            if (!loggedIn) {
                this.props.storeSaveItinerary(response.data.content);
            }

            // Set states
            this.setState(
                {
                    itineraryID: response.data.content.id,
                    itineraryInfo: response.data.content,
                	itinerary: dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children),
                    breakfastSwitch: response.data.content.breakfast_switch,
                    lunchSwitch: response.data.content.lunch_switch,
                    dinnerSwitch: response.data.content.dinner_switch,
                	dotOpacities: dotOpacities,
                	dotShiftTops: dotShiftTops,
                	dotShiftLefts: dotShiftLefts,
                    findDotsComplete: true,
                    connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                },
                callback
            );
        };

        // Request data
        const dataJSON = {
			"dot_id": this.state.tripInfo.id,
			"travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD"),
			"start_time": this.state.startMoment.tz(this.state.startTimezone).format("HH:mm"),
			"planned_end_time": this.state.endMoment.tz(this.state.endTimezone).format("HH:mm"),
			"start_location": this.state.startLocation,
			"end_location": this.state.endLocation,
            "start_location_name": this.state.startLocationName,
            "end_location_name": this.state.endLocationName,
            "fitness_level": this.state.fitnessLevel
        };
        //console.log("Plan / findDots - dataJSON = ", dataJSON);

        // Send find request
        const sendRequest = () => {
            postFindDots(dataJSON)
	        .then(axiosCallback)
	        .catch(
                (response) => {
                    // Turn off itinerary animation
                    this.props.storeItineraryAnimation(null);

                    console.log("[WARNING] Plan / findDots - error = ", response);
                }
            );
    	};

        // Find dots
        const findDotsCallback = () => {
	        this.setState(
	        	{
	        		finalizeOn: false,
	                findReady: false,
	                findDotsComplete: false,
	                findDotsAnimationComplete: false,
	                connectReady: false,
	            	connectDotsComplete: false,
	            	connectDotsAnimationComplete: false,
	            	dotTimes: null
	        	},
		        sendRequest
	        );
        };

        // Clear alert
        this.props.storeWarningAlert({
            message: null,
            on: false
        });

        // Turn on progress animation
        this.props.storeItineraryAnimation("find");

        // Execute callback
        if ((findDotsCallback) && (typeof findDotsCallback === "function")) {
            findDotsCallback();
        }
    }


    /*
    ============================================================================================
        findDotsAnimation
    --------------------------------------------------------------------------------------------
        - Find dot animation effects
    ============================================================================================
    */

    // Unfold the itinerary section
    findDotsAnimation(callback) {
        //console.log("Plan / findDotsAnimation");
        //console.log("Plan / findDotsAnimation - this.state = ", this.state);

        let findDotsInterval;

        const findDotsAppear = () => {
            //console.log("Plan / findDotsAnimation - this.state = ", this.state);

            // Check if all dots have appeared
            let stopSwitch = true;
            const newDotOpacities = [];

            for (let i = 0; i < this.state.itinerary.length; i++){
                if ((this.state.dotOpacities[i] === 0) && (stopSwitch === true)) {
                    newDotOpacities[i] = 1.0;
                    stopSwitch = false;
                }
                else {
                    newDotOpacities[i] = this.state.dotOpacities[i];
                }
            }

            // Stop the animation if all dots have appeared
            if (stopSwitch) {
                //console.log("Plan / findDotsAnimation - clearing interval");
                clearInterval(findDotsInterval);

		        // Reset progress status animation and initiate connect animation sequence
                setTimeout(
                    () => {
                        // Turn off progress animation
                        this.props.storeItineraryAnimation(null);

                        // Turn on connect animation if connect dots is still in progress
                        if (this.state.connectDotsInProgress) {
                            setTimeout(
                                () => {
                                    this.props.storeItineraryAnimation("connect");
                                },
                                this.findDotsAnimationEndTime
                            );
                        }

                        // Set find animation complete flag
                        this.setState(
                            {
                                findDotsAnimationComplete: true
                            },
                            callback
                        );
                    },
                    this.findDotsAnimationTransitionTime
                );
            }
            else {
                //console.log("Plan / findDotsAnimation - newDotOpacities = ", newDotOpacities);
                this.setState({
                    dotOpacities: newDotOpacities,
                });
            }
        };

        // Trigger interval
        findDotsInterval = setInterval(findDotsAppear, this.findDotsAnimationTransitionTime);
    }


    /*
    ============================================================================================
        connectDots
    --------------------------------------------------------------------------------------------
        - Send connect dots query
    ============================================================================================
    */

    connectDots(callback) {
    	// Update the itinerary and set off connect animation
        const axiosConnectCompleteCallback = (response) => {
            //console.log("Plan / connectDots - response.data = ", response.data);

            // New and old itineraries
            const newItinerary = dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children);
            const oldItinerary = this.state.itinerary;
	        //console.log("Plan / connectDots - newItinerary = ", newItinerary);
	        //console.log("Plan / connectDots - oldItinerary = ", oldItinerary);

	    	// Calculate the position shifts
			const dotShiftTops = [];
			const dotShiftLefts = [];

	        for (let oldIndex = 0; oldIndex < oldItinerary.length; oldIndex ++) {
	            const dotIndex = oldItinerary[oldIndex];

	            // new index (old index i)
	            const newIndex = newItinerary.indexOf(dotIndex);

	            // position
	            const oldPosition = this.dotNodes[oldIndex].getBoundingClientRect();
	            const newPosition = this.dotNodes[newIndex].getBoundingClientRect();

	            dotShiftTops.push(newPosition.top - oldPosition.top);
	            dotShiftLefts.push(newPosition.left - oldPosition.left);
	            //console.log("Plan / connectDots - dotIndex / oldIndex / newIndex / oldPosition / newPosition = ", dotIndex, oldIndex, newIndex, oldPosition, newPosition);
	        }

	        // Save the temporary itinerary and shift arrays
	        this.tempDotShiftTops = dotShiftTops;
	        this.tempDotShiftLefts = dotShiftLefts;
	        this.tempItinerary = newItinerary;

            // Logged in
            const loggedIn = !!(localStorage.token && this.props.userInfo !== null);

            // Update new itinerary
            if (loggedIn) {
                this.props.storeNewItinerary({ operation: "add", info: response.data.content });
                this.props.storeItinerariesFetchComplete(false);
            }
            // Update save itinerary
            else {
                this.props.storeSaveItinerary(response.data.content);
            }

	        // Update states
            this.setState(
                {
                    itineraryInfo: response.data.content,
                	connectDotsComplete: true,
                    connectDotsInProgress: false,
                    actualEndMoment: moment(response.data.content.end_time),
                	dotTimes: response.data.content.dot_start_times_local_formatted,
                	transitTimes: response.data.content.transit_time,
                	transitTimeValues: response.data.content.transit_time_value,
                	transitModes: response.data.content.transit_mode
                },
                callback
            );
        };


        // Execute the connect computation and periodically send request to check if the connect is complete
        const axiosConnectCallback = (response) => {
            const axiosCheckConnectCallback = (response) => {
                //console.log("Plan / connectDots - response.data.content = ", response.data.content);

                if (response.data.status.code === this.connectCheckCompletedCode) {
                    // Clear interval
                    clearInterval(this.connectCheckInterval);

                    // Execute complete callback
                    axiosConnectCompleteCallback(response);
                }
                else if (response.data.status.code === this.connectCheckInProgressCode) {
                    //console.log("[WARNING] Plan / connectDots - connect in progress = ", response);
                }
                else {
                    console.log("[WARNING] Plan / connectDots - error = ", response);
                }
            };

            this.connectCheckInterval = setInterval(
                () => {
                    let dataJSON = {
                        "itinerary_id": this.state.itineraryID,
                        //"travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD")
                    };

                    postCheckConnect(dataJSON)
                    .then(axiosCheckConnectCallback)
                    .catch((response) => {console.log("[WARNING] Plan / connectDots - error = ", response);});
                },
                this.connectCheckIntervalTime
            );
        };

        // Connect request
        const sendRequest = () => {
            let dataJSON = {
                "itinerary_id": this.state.itineraryID,
                //"dot_id": this.state.tripInfo.id,
                "dot_ids": dotIndicesToIDs(this.state.itinerary, this.state.tripInfo.trip_extension.children),
                "travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD"),
                "start_time": this.state.startMoment.tz(this.state.startTimezone).format("HH:mm"),
                "start_location": this.state.startLocation,
                "end_location": this.state.endLocation,
                "breakfast_switch": this.state.breakfastSwitch,
                "lunch_switch": this.state.lunchSwitch,
                "dinner_switch": this.state.dinnerSwitch
            };

            //postConnectDotsSync(dataJSON)
            postConnectDots(dataJSON)
	        .then(axiosConnectCallback)
	        .catch(
                (response) => {
                    // Turn off itinerary animation
                    this.props.storeItineraryAnimation(null);

                    console.log("[WARNING] Plan / connectDots - error = ", response);
                }
            );
	    };

        // Start connect animation if find animation is already complete
        if (this.state.findDotsAnimationComplete) {
            this.props.storeItineraryAnimation("connect");
        }

        // Connect dots
        this.setState(
            {
                connectDotsComplete: false,
                connectDotsInProgress: true,
                connectDotsAnimationComplete: false,
                dotTimes: null
            },
            sendRequest
        );
    }


    /*
    ============================================================================================
        connectDotsAnimation
    --------------------------------------------------------------------------------------------
        - Connect dot animation effects
    ============================================================================================
    */

    connectDotsAnimation (callback) {
    	//console.log("Plan / connectDotsAnimation");
    	//console.log("Plan / connectDotsAnimation - this.state.findDotsComplete", this.state.findDotsComplete);
    	//console.log("Plan / connectDotsAnimation - this.state.itinerary", this.state.itinerary);
    	//console.log("Plan / connectDotsAnimation - this.tempItinerary", this.tempItinerary);
    	if ((this.state.findDotsComplete) && (this.state.findDotsAnimationComplete) && (this.state.connectDotsComplete)) {
	    	// Reset shifts
	        const resetDotShiftTops = [];
	        const resetDotShiftLefts = [];
	        for (let i = 0; i < this.state.itinerary.length; i ++){
	            resetDotShiftTops.push(0);
	            resetDotShiftLefts.push(0);
	        }
	        //console.log("Plan / connectDotsAnimation - this.tempDotShiftTops / this.tempDotShiftLefts = ", this.tempDotShiftTops, this.tempDotShiftLefts);
	        //console.log("Plan / connectDotsAnimation - resetDotShiftTops / resetDotShiftLefts = ", resetDotShiftTops, resetDotShiftLefts);

	        // Reset DOMs
	        this.dotNodes = [];

	        // Clear progress status animation
	        const connectDotsAnimationClearCallback = () => {
                setTimeout(
                    () => {
                        // Turn off progress animation
                        this.props.storeItineraryAnimation(null);

                        // Execute callback
                        if ((callback) && (typeof callback === "function")) {
                            callback();
                        }
                    },
                    1000
                );

                const animationClearCallback = () => {
                };

                // Close the settings windows if open and clear animation
                if (this.state.basicSettingsOn) {
                    this.setState(
                        {
                            basicSettingsOn: false,
                            customSettingsOn: false
                        },
                        animationClearCallback
                    );
                }
                else {
                    // Clear animation
                    animationClearCallback();
                }
	        };

	        // Set states
	        const connectDotsAnimationCallback = () => {
	        	setTimeout(
	        		() => {
			        	this.setState(
				            {
				            	// Turn on animation
				                dotShiftTops: this.tempDotShiftTops,
				                dotShiftLefts: this.tempDotShiftLefts,
				                dotConnectAnimationClass: "dot-object-wrapper-animation"
				            },
				            setTimeout.bind(
				                null,
				                this.setState.bind(
				                    this,
				                    {
				                        // Renew the itinerary and highlights
				                        itinerary: this.tempItinerary,

				                        // Turn off animation
				                        dotShiftTops: resetDotShiftTops,
				                        dotShiftLefts: resetDotShiftLefts,
				                        dotConnectAnimationClass: "dot-object-wrapper"
				                    },
				                    // Add index and fade in
				                    this.setState.bind(
				                        this,
				                        {
				                            dotIndexOn: true,
				                            dotIndexOpacity: 0.0
				                        },
				                        setTimeout.bind(
				                            null,
				                            this.setState.bind(
				                                this,
				                                {
				                                    dotIndexOpacity: 0.9,
                                                    connectReady: false,
									            	connectDotsAnimationComplete: true,
									            	galleryOn: true,
									            	finalizeOn: true
				                                },
				                                connectDotsAnimationClearCallback
				                            ),
				                            100
				                        )
				                    )
				                ),
				                1000
				            )
			        	);
			        },
			        1000
			    );
			};

            // Turn on progress animation if connect dots animation is mot called by find dots animation
            if (!this.state.connectDotsInProgress) {
                this.props.storeItineraryAnimation("connect");
            }

            // Execute callback
            if ((connectDotsAnimationCallback) && (typeof connectDotsAnimationCallback === "function")) {
                connectDotsAnimationCallback();
            }
	    }
    }


    /*
    ============================================================================================
        checkUpdate
    --------------------------------------------------------------------------------------------
        - Send query to see if an update is needed
    ============================================================================================
    */

    checkUpdate(callback) {
        const axiosCallback = (response) => {
            //console.log("Plan / checkUpdate - response.data = ", response.data);

            if (response.data.content.recalculate) {
                this.setState(
                    {
                        galleryOn: false,
                        finalizeOn: false,
                        findReady: true,
                        findDotsComplete: false,
                        connectReady: false,
                        connectDotsComplete: false,
                        connectDotsAnimationComplete: false,
                        dotTimes: null
                    },
                    callback
                );
	        }
	        else {
                this.setState(
                    {
                        galleryOn: true,
                        finalizeOn: true,
                        findReady: false,
                        findDotsComplete: true,
                        connectReady: false,
                        connectDotsComplete: true,
                        connectDotsAnimationComplete: true,
                        dotTimes: response.data.content.dot_start_times_local_formatted
                    },
                    callback
                );
            }
        };

        // Request data
        const dataJSON = {
			//"dot_id": this.state.tripInfo.id,
            "itinerary_id": this.state.itineraryID,
            "travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD"),
			"start_time": this.state.startMoment.tz(this.state.startTimezone).format("HH:mm"),
			"planned_end_time": this.state.endMoment.tz(this.state.endTimezone).format("HH:mm"),
			"start_location": this.state.startLocation,
			"end_location": this.state.endLocation,
            "fitness_level": this.state.fitnessLevel
        };
        //console.log("Plan / findDots - dataJSON = ", dataJSON);

        // Send find request
        postFindDotsCheck(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Plan / checkUpdate - error = ", response);});
    }


    /*
    ============================================================================================
        Selection icons click callbacks
    ============================================================================================
    */

    basicSettingsIconClick() {
        if (this.state.basicSettingsOn) {
            this.setState({
                basicSettingsOn: false,
                customSettingsOn: false
            });
        }
        else {
            this.setState({
                basicSettingsOn: true
            });
        }
    }

    mapIconClick() {
        // Only when the customization is not on
        if (!this.state.customizeOn) {
            // Close map if it is on
            if (this.state.mapOn) {
                // Close map
                const closeMapCallback = () => {
                    this.setState({
                        mapOn: false
                    });
                }

                // Cancel preview if a dot was selected
                if ((this.state.selected !== null) || (this.state.selectedChild !== null)) {
                    // Cancel preview
                    this.setState(
                        {
                            selected: null,
                            selectedChild: null,
                            //displayChildren: dotIndex,
                            selectedMediaIndex: 0,
                            previewOn: false,
                            previewContentOn: false,
                            mapZoomOutAnimation: true, // Controls zooming of Google map
                        },
                        closeMapCallback
                    );
                }
                // If no dot was selected
                else {
                    closeMapCallback();
                }
            }
            // Open map when it is off
            else {
                this.setState({
                    mapOn: true,
                    mapZoomOutAnimation: true,
                    mapRefreshAnimation: true
                });
            }
        }
    }

    customizeIconClick() {
        if (this.state.customizeOn) {
            this.setState({
                customizeOn: false,
                mapOn: (this.state.mapBeforeCustomizeOn || this.state.previewOn)? true : false,
                optionsOn: false,
                optionsCategory: "scenic",
            });
        }
        else {
            this.setState({
                customizeOn: true,
                mapBeforeCustomizeOn: this.state.mapOn,
                mapOn: true,
                optionsOn: true,
                optionsCategory: "scenic",
                mapZoomOutAnimation: true,
                mapRefreshAnimation: true
            });
        }
    }

    customSettingsIconClick() {
        if (this.state.customSettingsOn) {
            this.setState({
                customSettingsOn: false
            });
        }
        else {
            this.setState({
                basicSettingsOn: true,
                customSettingsOn: true
            });
        }
    }

    // Navigate to the next page
    optionsNextPage() {
        // Change page
        switch (this.state.optionsCategory) {
            case "scenic":
                this.setState({
                    optionsScenicPage: this.state.optionsScenicPage + 1
                });

                break;
            case "dine":
                this.setState({
                    optionsDinePage: this.state.optionsDinePage + 1
                });

                break;
            case "experience":
                this.setState({
                    optionsExperiencePage: this.state.optionsExperiencePage + 1
                });

                break;
            case "route":
                this.setState({
                    optionsRoutePage: this.state.optionsRoutePage + 1
                });

                break;
            default:
                console.log("[Warning] Plan / optionsNextPage - Wrong options category");

                break;
        }
    }

    optionsPreviousPage() {
        // Change page
        switch (this.state.optionsCategory) {
            case "scenic":
                this.setState({
                    optionsScenicPage: this.state.optionsScenicPage - 1
                });

                break;
            case "dine":
                this.setState({
                    optionsDinePage: this.state.optionsDinePage - 1
                });

                break;
            case "experience":
                this.setState({
                    optionsExperiencePage: this.state.optionsExperiencePage - 1
                });

                break;
            case "route":
                this.setState({
                    optionsRoutePage: this.state.optionsRoutePage - 1
                });

                break;
            default:
                console.log("[Warning] Plan / optionsPreviousPage - Wrong options category");

                break;
        }
    }


    /*
    ============================================================================================
        Options category buttons click
    ============================================================================================
    */

    scenicIconClick () {
        if ((this.state.optionsCategory !== "scenic") && (this.state.customizeOn)) {
            this.setState({
                optionsCategory: "scenic"
            });
        }
    }

    dineIconClick() {
        if ((this.state.optionsCategory !== "dine") && (this.state.customizeOn)) {
            this.setState({
                optionsCategory: "dine"
            });
        }
    }

    experienceIconClick() {
        if ((this.state.optionsCategory !== "experience") && (this.state.customizeOn)) {
            this.setState({
                optionsCategory: "experience"
            });
        }
    }

    routeIconClick() {
        if ((this.state.optionsCategory !== "route") && (this.state.customizeOn)) {
            this.setState({
                optionsCategory: "route"
            });
        }
    }


    /*
    ============================================================================================
        Item Preview
    ============================================================================================
    */

    nextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex + 1)
            }
        );
    }

    prevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex - 1)
            }
        );
    }

    navDotClick(event, imageIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            selectedMediaIndex: imageIndex
        });
    }


    /*
    ============================================================================================
        Dot Left Click
    ============================================================================================
    */

    dotLeftClick(event, dotIndex, childIndex) {
        //console.log("Plan / dotLeftClick - dotIndex = ", dotIndex);

        // Stop propagation
        event.stopPropagation();

        // Modify itinerary
        const itinerary = this.state.itinerary.slice();
        const index = itinerary.indexOf(dotIndex);
        itinerary[index - 1] = this.state.itinerary[index]
        itinerary[index] = this.state.itinerary[index - 1]

        const axiosCallback = (response) => {
            //console.log("Plan / dotLeftClick - response.data = ", response.data);

            // When clicked a selected dot
            if (((dotIndex != null) && (this.state.selected === dotIndex)) || ((childIndex != null) && (this.state.selectedChild === childIndex))) {
                this.setState({
                    selected: itinerary[index - 1],
                    selectedChild: null,
                    //displayChildren: itinerary[index - 1],
                    selectedMediaIndex: 0,
                    itinerary: dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children),
                    actualEndMoment: moment(response.data.content.end_time),
                    dotTimes: response.data.content.dot_start_times_local_formatted,
                    transitTimes: response.data.content.transit_time,
                    transitTimeValues: response.data.content.transit_time_value,
                    transitModes: response.data.content.transit_mode,
                    connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                });
            }
            else {
                this.setState({
                    itinerary: dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children),
                    actualEndMoment: moment(response.data.content.end_time),
                    dotTimes: response.data.content.dot_start_times_local_formatted,
                    transitTimes: response.data.content.transit_time,
                    transitTimeValues: response.data.content.transit_time_value,
                    transitModes: response.data.content.transit_mode,
                    connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                });
            }
        };

        const dataJSON = {
            //"dot_id": this.state.tripInfo.id,
            "itinerary_id": this.state.itineraryID,
            "dot_ids": dotIndicesToIDs(itinerary, this.state.tripInfo.trip_extension.children),
            "travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD")
        };

        postModifyItinerary(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Plan / dotLeftClick - error = ", response);});
    }


    /*
    ============================================================================================
        Dot Right Click
    ============================================================================================
    */

    dotRightClick(event, dotIndex, childIndex) {
        //console.log("Plan / dotRightClick - dotIndex = ", dotIndex);

        // Stop propagation
        event.stopPropagation();

        // Modify itinerary
        const itinerary = this.state.itinerary.slice();
        const index = itinerary.indexOf(dotIndex);
        itinerary[index + 1] = this.state.itinerary[index]
        itinerary[index] = this.state.itinerary[index + 1]

        const axiosCallback = (response) => {
            //console.log("Plan / dotRightClick - response.data = ", response.data);

            // When clicked a selected dot
            if (((dotIndex != null) && (this.state.selected === dotIndex)) || ((childIndex != null) && (this.state.selectedChild === childIndex))) {
                this.setState({
                    selected: itinerary[index + 1],
                    selectedChild: null,
                    //displayChildren: itinerary[index + 1],
                    selectedMediaIndex: 0,
                    itinerary: dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children),
                    actualEndMoment: moment(response.data.content.end_time),
                    dotTimes: response.data.content.dot_start_times_local_formatted,
                    transitTimes: response.data.content.transit_time,
                    transitTimeValues: response.data.content.transit_time_value,
                    transitModes: response.data.content.transit_mode,
                    connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                });
            }
            else {
                this.setState({
                    itinerary: dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children),
                    actualEndMoment: moment(response.data.content.end_time),
                    dotTimes: response.data.content.dot_start_times_local_formatted,
                    transitTimes: response.data.content.transit_time,
                    transitTimeValues: response.data.content.transit_time_value,
                    transitModes: response.data.content.transit_mode,
                    connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                });
            }
        };

        const dataJSON = {
            //"dot_id": this.state.tripInfo.id,
            "itinerary_id": this.state.itineraryID,
            "dot_ids": dotIndicesToIDs(itinerary, this.state.tripInfo.trip_extension.children),
            "travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD")
        };

        postModifyItinerary(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Plan / dotRightClick - error = ", response);});
    }


    /*
    ============================================================================================
        Dot Add Click
    ============================================================================================
    */

    dotAddClick(event, dotIndex, childIndex) {
        console.log("Plan / dotAddClick - event = ", event);
        console.log("Plan / dotAddClick - dotIndex = ", dotIndex);
        //console.log("Plan / dotAddClick - dotID = ", dotID);

        // Stop propagation
        event.stopPropagation();

        // Update the itinerary and set off connect animation
        const axiosCallback = (response) => {
            //console.log("Plan / dotAddClick - response.data = ", response.data);

            // Update states
            this.setState(
                {
                    itinerary: dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children),
                    itineraryInfo: response.data.content,
                    actualEndMoment: moment(response.data.content.end_time),
                    dotTimes: response.data.content.dot_start_times_local_formatted,
                    transitTimes: response.data.content.transit_time,
                    transitTimeValues: response.data.content.transit_time_value,
                    transitModes: response.data.content.transit_mode,
                    connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                },
                null
            );

            // Issue warning
            if (moment(response.data.content.end_time_local).unix() - moment(response.data.content.sunset_time_local).unix() > this.warningTimeMargin) {
                this.props.storeWarningAlert({
                    message: "Trip ends after sunset. Optimzation may not work properly.",
                    on: true
                });
            }
        };

        // Construct itinerary (current selection + a new dot to add to the selection)
        const itinerary = this.state.itinerary.slice();
        itinerary.push(dotIndex);
        //console.log("Plan / dotAddClick - itinerary = ", itinerary);

        // Partial connect request
        let dataJSON = {
            //"dot_id": this.state.tripInfo.id,
            "itinerary_id": this.state.itineraryID,
            "dot_ids": dotIndicesToIDs(itinerary, this.state.tripInfo.trip_extension.children),
            "travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD"),
            "start_time": this.state.startMoment.format("hh:mm"),
            "start_location": this.state.startLocation,
            "end_location": this.state.endLocation,
            "breakfast_switch": this.state.breakfastSwitch,
            "lunch_switch": this.state.lunchSwitch,
            "dinner_switch": this.state.dinnerSwitch
        };

        postPartialConnectDots(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Plan / dotAddClick - error = ", response);});
    }


    /*
    ============================================================================================
        Dot Remove Click
    ============================================================================================
    */

    dotRemoveClick(event, dotIndex, childIndex) {
        //console.log("Plan / dotRemoveClick - dotIndex = ", dotIndex);
        //console.log("Plan / dotRemoveClick - this.state.itinerary = ", this.state.itinerary);

        // Stop propagation
        event.stopPropagation();

        if (this.state.itinerary.length === 1) {
            console.log("Plan / dotRemoveClick - one left");
        }
        else {
            // Remove from itinerary
            const itinerary = this.state.itinerary.slice();
            const index = itinerary.indexOf(dotIndex);
            //console.log("Plan / dotRemoveClick - index = ", index);
            if (index >= 0) {
                itinerary.splice(index, 1);
            }
            //console.log("Plan / dotRemoveClick - itinerary = ", itinerary);

            const axiosCallback = (response) => {
                //console.log("Plan / dotRemoveClick - response.data = ", response.data);

                // When clicked a selected dot
                if (((dotIndex != null) && (this.state.selected === dotIndex)) || ((childIndex != null) && (this.state.selectedChild === childIndex))) {
                    // First state
                    const firstState = {
                        mapZoomOutAnimation: true // Controls zooming of Google map
                    };

                    // Second state
                    const secondState = {
                        itinerary: dotIDsToIndices(
                            response.data.content.route,
                            this.state.tripInfo.trip_extension.children
                        ),
                        itineraryInfo: response.data.content,
                        selected: null,
                        selectedChild: null,
                        //displayChildren: null,
                        selectedMediaIndex: 0,
                        previewOn: false,
                        previewContentOn: false,
                        actualEndMoment: moment(response.data.content.end_time),
                        dotTimes: response.data.content.dot_start_times_local_formatted,
                        transitTimes: response.data.content.transit_time,
                        transitTimeValues: response.data.content.transit_time_value,
                        transitModes: response.data.content.transit_mode,
                        connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                    };

                    // Make sure the marker updates before updating selected
                    updateStateChain2(this.setState, firstState, secondState, null, 0);
                }
                else {
                    this.setState({
                        itinerary: dotIDsToIndices(response.data.content.route, this.state.tripInfo.trip_extension.children),
                        itineraryInfo: response.data.content,
                        actualEndMoment: moment(response.data.content.end_time),
                        dotTimes: response.data.content.dot_start_times_local_formatted,
                        transitTimes: response.data.content.transit_time,
                        transitTimeValues: response.data.content.transit_time_value,
                        transitModes: response.data.content.transit_mode,
                        connectReady: (response.data.content.route.length <= this.maxNumDotsToConnect)
                    });
                }

                // Clear warning
                this.props.storeWarningAlert({
                    message: null,
                    on: false
                });
            };

            const dataJSON = {
                //"dot_id": this.state.tripInfo.id,
                "itinerary_id": this.state.itineraryID,
                "dot_ids": dotIndicesToIDs(itinerary, this.state.tripInfo.trip_extension.children),
                "travel_date": this.state.startMoment.tz(this.state.startTimezone).format("YYYY-MM-DD")
            };

            postModifyItinerary(dataJSON)
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] Plan / dotRemoveClick - error = ", response);});
        }
    }

    finalizeClick() {
        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && (this.props.userInfo !== null));

        // Update itinerary parameters
        this.props.storeItineraryParams({
            // Locations
            location: this.state.location,
            startLocation: this.state.startLocation,
            endLocation: this.state.endLocation,
            startLocationName: this.state.startLocationName,
            endLocationName: this.state.endLocationName,
            timezone: this.state.timezone,
            startTimezone: this.state.startTimezone,
            endTimezone: this.state.endTimezone,

            // Start and end times
            //startMoment: this.state.startMoment,
            //endMoment: this.state.endMoment,
            //actualEndMoment: this.state.actualEndMoment,

            // Dot times
            dotTimes: this.state.itineraryInfo.dot_start_times_local_formatted,

            // Transit times and modes
            transitTimes: this.state.itineraryInfo.transit_time,
            transitTimeValues: this.state.itineraryInfo.transit_time_value,
            transitModes: this.state.itineraryInfo.transit_mode,

            // Day times
            //sunTimes: this.state.sunTimes,
            //moonTimes: this.state.moonTimes,
            //dayTime: this.state.dayTime,
            //offsetTime: this.state.offsetTime,
            //totalTime: this.state.totalTime,
            //startEndTimeValues: this.state.startEndTimeValues,
            //hour00Moment: this.state.hour00Moment,
            //hour24Moment: this.state.hour24Moment,

            // Astronomy
            //sunMoonOn: this.state.sunMoonOn,
            //moonPhase: this.state.moonPhase,
            //moonPhaseDigits: this.state.moonPhaseDigits,

            // Weather
            weather: this.state.weather,
            weatherOn: this.state.weatherOn,
            weatherIcon: this.state.weatherIcon,
            tempHigh: this.state.tempHigh,
            tempLow: this.state.tempLow,
            precipOn: this.state.precipOn,
            precipType: this.state.precipType,
            precipProb: this.state.precipProb
        });

        // If logged in
        if (loggedIn) {
            // Update itinerary
            this.props.storeItinerary(this.state.itineraryInfo);

            // Route to the itinerary page
            this.props.history.push("/itinerary/" + this.state.itineraryID);
        }
        else {
            this.setState({ finalizeModalOn: true });            
        }
    }
}


// export component
function mapStateToProps(state) {
    return {
        browserWidthPixels: state.nav.browserWidthPixels,
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        google: state.map.google,
        userInfo: state.user.userInfo,
        itineraryAnimation: state.nav.itineraryAnimation
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeLogInOn,
            storeSignUpOn,
            storeItinerary,
            storeItineraryParams,
            storeWarningAlert,
            storeItineraryAnimation,
            storeNewItinerary,
            storeSaveItinerary,
            storeItinerariesFetchComplete
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Plan);