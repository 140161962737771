/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Chat.js
--------------------------------------------------------------------------------------------
    Content
    - Chat
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";

// Modules
import ReactTooltip from "thedots-tooltip";

// Axios
import { getUserSearch } from "requests";

// Functions
import {
    url,
	getStaticPath,
    getMediaProperty,
    formatDatetime,
    formatDatetimeAdaptive
} from "js/Functions";

// CSS
import "./Chat.scss";


class Chat extends Component {
	constructor(props) {
		super(props);

        // Initialize state
        this.state = {
            scrollBarOn: false
        };

		// Name and message truncation
		this.truncationLength = 54;

		// DOM nodes
		this.chatsRef = React.createRef();

		// Bind functions
		this.setState = this.setState.bind(this);
		this.chatsOnScroll = this.chatsOnScroll.bind(this);
		this.newGroupChatClick = this.newGroupChatClick.bind(this);
		this.newChatClick = this.newChatClick.bind(this);
	}

	render() {
		const menuTriangleImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/top-menu-triangle-day.png") :
            getStaticPath("/images/common/top-menu-triangle-night.png");

		const chatsList = (this.props.chatsInfo.length === 0)?
		(
			<div id = "chat-item-blank"
				className = "g5"
			>
				No Chats
			</div>
		) : this.props.chatsInfo.map((chatInfo, index) => {
			const chatMessagesInfo = ((this.props.chatsMessagesInfo !== [])
				&& (this.props.chatsInfo.length === this.props.chatsMessagesInfo.length))?
				this.props.chatsMessagesInfo[index] : null;

	        // Return an individual item
	        return(
	        	<ChatItem
                    colorMode = {this.props.colorMode}
                    scrollBarOn = {this.state.scrollBarOn}
		        	key = {"chat-item-" + index.toString()}
	        		index = {index}
	        		userInfo = {this.props.userInfo}
	        		chatInfo = {chatInfo}
	        		chatChecked = {this.props.chatsChecked[index]}
	        		chatMessagesInfo = {chatMessagesInfo}
	        		truncationLength = {this.truncationLength}
                    numChatWindowsMax = {this.props.numChatWindowsMax}
                    chatWindowsOpen = {this.props.chatWindowsOpen}
	        		setState = {this.props.setState}
 	              	storeChatMenuOn = {this.props.storeChatMenuOn}
	        	/>
	        );
	    });

	    // New chat and group chat images
	    // const newGroupChatImage = (this.props.colorMode === "day")?
        //    getStaticPath("/images/common/new-group-chat-black.png") :
        //    getStaticPath("/images/common/new-group-chat-white.png");

	    const newChatImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/new-chat-black.png") :
            getStaticPath("/images/common/new-chat-white.png");

        const closeButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") :
            getStaticPath("/images/common/cancel-white.png");

	    // Render JSX
		return (
			<div id = "chats"
                className = {(this.props.buttonsOff)?
                    "chats-small" : "chats"}
            >
				<NewChat
					buttonsOff = {this.props.buttonsOff}
					colorMode = {this.props.colorMode}
					userInfo = {this.props.userInfo}
					newChatOpen = {this.props.newChatOpen}
					setState = {this.props.setState}
					addChat = {this.props.addChat}
				/>
                {
                    (this.props.buttonsOff)?
                        null : (
                            <div id = "chats-triangle"
                                style = {{ backgroundImage: menuTriangleImage }}
                            >
                            </div>
                        )
                }
				<div id = "chats-shadow">

					<div id = "chats-header"
                        className = {(this.props.colorMode === "day")?
                            "chats-header-day k5" :
                            "chats-header-night w5"}
                    >
						Chats
                        {
                        	(this.props.buttonsOff)?
                        	(
	                            <div id = "chats-close-button"
	                                className = "image-button-s8"
	                                style = {{ backgroundImage: closeButtonImage }}
	                                onClick = {() => { this.props.storeChatMenuOn(false); }}
	                            >
	                            </div>
                            ) : null
                        }
						<div id = "chats-new-chat-button"
							className = {(this.props.buttonsOff)?
								"chats-new-chat-button-small image-cover" :
								"chats-new-chat-button image-cover"}
							style = {{ backgroundImage: newChatImage }}
							onClick = {this.newChatClick}
							data-for = "chats-new-chat-button-tooltip"
							data-tip = "New Message"
						>
						</div>
					    <ReactTooltip
					        id = "chats-new-chat-button-tooltip"
					        className = "tooltip-s2"
					        type = "dark"
					        place = "right"
					        html = {true}
					    />
					</div>
					<div ref = {this.chatsRef}
						id = "chats-container"
                        className = {
                            (this.props.buttonsOff)?
                                (
                                    (this.props.colorMode === "day")?
                                    "chats-container-long chats-container-day k5" :
                                    "chats-container-long chats-container-night w5"
                                ) : (
                                    (this.props.colorMode === "day")?
                                    "chats-container-short chats-container-day k5" :
                                    "chats-container-short chats-container-night w5"
                                )
                        }
                    >
						{chatsList}
					</div>
					<div id = "chats-footer"
                        className = {(this.props.colorMode === "day")?
                            "chats-footer-day k5" :
                            "chats-footer-night w5"}
                    >
					</div>
				</div>
			</div>
		)
	}
	/*
						<div id = "chats-new-group-chat-button"
							style = {{ backgroundImage: newGroupChatImage }}
							onClick = {this.newGroupChatClick}
							data-for = "chats-new-group-chat-button-tooltip"
							data-tip = "New Group Message"
						>
						</div>
					    <ReactTooltip
					        id = "chats-new-group-chat-button-tooltip"
					        className = "tooltip-s2"
					        type = "dark"
					        place = "left"
					        html = {true}
					    />
	*/


	componentDidMount() {
        // Add event listeners
       	this.chatsRef.current.addEventListener("scroll", this.chatsOnScroll);
        //this.chatsRef.current.addEventListener("resize", this.detectScrollBar);

        // Detect scroll bar
        this.detectScrollBar();
	}

    componentWillUnmount() {
        // Remove event listeners
        this.chatsRef.current.removeEventListener("scroll", this.chatsOnScroll);
        //this.chatsRef.current.removeEventListener("resize", this.detectScrollBar);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.chatsInfo !== null) &&
        	(prevProps.chatsInfo.length !== this.props.chatsInfo.length)) {
            this.detectScrollBar();
        }
    }

    detectScrollBar() {
    	//console.log("Chat / detectScrollBar - this.chatsRef.current.scrollHeight = ", this.chatsRef.current.scrollHeight);
    	//console.log("Chat / detectScrollBar - this.chatsRef.current.clientHeight = ", this.chatsRef.current.clientHeight);
        const scrollBarOn = this.chatsRef.current.scrollHeight > this.chatsRef.current.clientHeight;

        // Update state
        this.setState({
            scrollBarOn: scrollBarOn
        });
    }

    chatsOnScroll(e) {
        //console.log("Chat / chatsOnScroll - e = ", e);

        // Stop event propagation
        //e.stopPropagation();

    	// If end of scroll is reached
		if (Math.floor(this.chatsRef.current.scrollHeight - this.chatsRef.current.scrollTop) === Math.floor(this.chatsRef.current.clientHeight)) {
			//console.log("Chat - chatsOnScroll - end of scroll");

			// Fetch more chats
			if (!this.props.chatsFetchComplete) {
				//console.log("Chat - chatsOnScroll - fetching more chats");
				this.props.getChatsInfo();
			}
		}
		/*
		console.log("--------------------------------------------------------------------------------");
		console.log("Chat - chatsOnScroll - scrollHeight = ", this.chatsRef.current.scrollHeight);
		console.log("Chat - chatsOnScroll - scrollTop = ", this.chatsRef.current.scrollTop);
		console.log("Chat - chatsOnScroll - clientHeight = ", this.chatsRef.current.clientHeight);
		*/
    }


    newGroupChatClick() {
    	this.props.setState({
    		newGroupChatOpen: !(this.props.newGroupChatOpen)
    	});
    }


    newChatClick() {
    	this.props.setState({
    		newChatOpen: !(this.props.newChatOpen)
    	});
    }
}


class NewChat extends Component {
	constructor(props) {
		super(props);

		// Search input and results
		this.searchInputRef = React.createRef();
        this.searchInputID = "new-chat-search-input";
        this.searchInputPlaceholder = "Search User";
		this.searchResultsRef = React.createRef();
		this.searchTypingInProgress = false;
		this.searchTypingDelay = 150;
		this.searchTypingTimer = null;

		// Fetch in progress flag
		this.fetchUsersInProgress = false;

		// Initialize state
		this.state = {
			users: [],
			searchResultsPage: 1,
			searchResultsPageMax: 1
		};

		// Bind functions
		this.searchUsers = this.searchUsers.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleTyping = this.handleTyping.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	render() {
        // Create search input JSX block
        const searchInputProps = {
            placeholder: this.searchInputPlaceholder,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur
        };

        // Search inputchat
        const searchInput = (
            <input ref = {this.searchInputRef}
                id = {this.searchInputID}
                className = {(this.props.colorMode === "day")?
                	"new-chat-search-input input-s3 input-day" :
                	"new-chat-search-input input-s3 input-night"}
                {...searchInputProps}
            />
        );

        // Search list
        const searchResults = (this.state.users.length > 0)?
        this.state.users.map((user, index) => {
	        const userProfileImage = (user.profile_pic !== null)?
                (
                    (user.profile_pic.external_url === null)?
                        getMediaProperty(user.profile_pic, "t", "url", true) :
                        url(user.profile_pic.external_url)
                ) : (
	                (this.props.colorMode === "day")?
	                    getStaticPath("/images/common/no-profile-picture-day.png") :
	                    getStaticPath("/images/common/no-profile-picture-night.png")
	            );

	        const userIDs = [ this.props.userInfo.id, user.id ];

        	return(
        		<div className = {(this.props.colorMode === "day")?
        				"new-chat-search-results-item-day" :
        				"new-chat-search-results-item-night"}
        			key = { "new-chat-search-results-item-" + index.toString()}
        			onClick = {() => {this.props.addChat(userIDs);}}
        		>
	        		<div className = {(this.props.colorMode === "day")?
                          	"new-chat-search-results-item-profile-pic profile-image-s6 border-day" :
                          	"new-chat-search-results-item-profile-pic profile-image-s6 border-night"}
	        			style = {{ backgroundImage: userProfileImage }}
	        		>
	        		</div>
	        		<div className = {(this.props.colorMode === "day")?
                            "new-chat-search-results-item-name k5" :
                            "new-chat-search-results-item-name w5"}
					>
	        			{user.name}
	        		</div>
        		</div>
        	);
        }) : null;

        // Render JSX
		return(
			<div id = {(this.props.buttonsOff)?
					"new-chat-container-small" : "new-chat-container"}
				style = {{ display: this.props.newChatOpen? "block" : "none" }}
			>
				<div id = "new-chat-shadow">
					<div id = "new-chat-header"
						className = {(this.props.colorMode === "day")?
							"new-chat-header-day k5" :
							"new-chat-header-night w5"}
					>
						Start a New Chat
					</div>
					<div id = "new-chat-search-input-container"
						className = {(this.props.colorMode === "day")?
							"new-chat-search-input-container-day" :
							"new-chat-search-input-container-night"}
					>
						{searchInput}
					</div>
					<div ref = {this.searchResultsRef}
						id = "new-chat-search-results"
						className = {(this.props.colorMode === "day")?
							"new-chat-search-results-day" :
							"new-chat-search-results-night"}
					>
						{searchResults}
					</div>
					<div id = "new-chat-footer"
						className = {(this.props.colorMode === "day")?
							"new-chat-footer-day k5" :
							"new-chat-footer-night w5"}
					>
					</div>
				</div>
			</div>
		);
	}


	componentDidMount() {
        // Set up event listeners for comment input
        this.searchInputRef.current.addEventListener("change", this.handleTyping);
        this.searchInputRef.current.addEventListener("cut", this.handleTyping);
        this.searchInputRef.current.addEventListener("paste", this.handleTyping);
        this.searchInputRef.current.addEventListener("keydown", this.handleTyping);

        // Set up an event listener for scrolling
       	this.searchResultsRef.current.addEventListener("scroll", this.handleScroll);
	}

	componentDidUpdate() {
	}

	componentWillUnmount() {
        // Remove event listeners
        this.searchInputRef.current.removeEventListener("change", this.handleTyping);
        this.searchInputRef.current.removeEventListener("cut", this.handleTyping);
        this.searchInputRef.current.removeEventListener("paste", this.handleTyping);
        this.searchInputRef.current.removeEventListener("keydown", this.handleTyping);
       	this.searchResultsRef.current.removeEventListener("scroll", this.handleScroll);
	}

	searchUsers(page, isNewSearch) {
		if (this.searchInputRef.current.value !== undefined) {
			// If the input string is not blank space
	        if (this.searchInputRef.current.value.replace(/[\s]/g, "").length > 0) {
		        if (!this.searchTypingInProgress) {
		        	// Turn on the fetch in progress flag
		        	this.fetchUsersInProgress = true;

			        // Axios callback : execute when the server returns a response
			        const axiosCallback = (response) => {
			            //console.log("Nav / NewChat / searchUsers - response.data.content = ", response.data.content);
			            const newUsers = (response.data.content.users == null)? [] : response.data.content.users;
			            const users = isNewSearch? newUsers : this.state.users.concat(newUsers);

			            // Update state
			            this.setState(
			                {
			                    users: users,
			                    searchResultsPage: page,
			                    searchResultsPageMax: response.data.content.user_results_total_pages
			                },
			                () => {	this.fetchUsersInProgress = false; }
			            );
			        };

			        // Request data to send
			        getUserSearch(this.searchInputRef.current.value, page)
			        .then(axiosCallback)
			        .catch((response) => { console.log("Nav / NewChat / searchUsers - Axios error ", response); });
				}
			}
			// If the input string is blank space
			else {
	        	// Switch on the in progress flag
	        	this.searchInProgress = true;

	        	// Reset the search results
				this.setState(
					{
						users: []
					},
					() => { this.searchInProgress = false; }
				);
			}
		}
	}

	handleTyping() {
		// Turn on the typing in progress flag
		if (this.searchTypingInProgress) {
			// Clear previous timer
			clearTimeout(this.searchTypingTimer);
		}
		else {
			this.searchTypingInProgress = true;
		}

		// Reset the timer
        this.searchTypingTimer = setTimeout(
        	() => {
        		this.searchTypingInProgress = false;
        		this.searchUsers(1, true);
        	},
        	this.searchTypingDelay
        );
	}

    handleFocus() {
        if (this.searchInputRef.current) {
            // Clear the placeholder
            this.searchInputRef.current.placeholder = "";
        }
    }

    handleBlur() {
        // On Blur event, remove event listeners and remove reply input bar.
        if (this.searchInputRef.current) {
            // Reset the placeholder
            this.searchInputRef.current.placeholder = this.searchInputPlaceholder;
        }
    }

    handleScroll(e) {
        //console.log("NewChat / handleScroll - e = ", e);

        // Stop event propagation
        //e.stopPropagation();

    	// If end of scroll is reached
		if (Math.floor(this.searchResultsRef.current.scrollHeight - this.searchResultsRef.current.scrollTop) === Math.floor(this.searchResultsRef.current.clientHeight)) {
			//console.log("NewChat / handleScroll - end of scroll");

			// Fetch more chats
			if ((!this.fetchUsersInProgress)
				&& (this.state.searchResultsPage < this.state.searchResultsPageMax)) {
				//console.log("NewChat / handleScroll - fetching more users");
				this.searchUsers(this.state.searchResultsPage + 1, false);
			}
		}
    }
}


function ChatItem(props) {
	// Class name
	const chatItemClassName = (props.chatChecked)?
        (
            (props.colorMode === "day")?
                "chat-item-checked-day" :
                "chat-item-checked-night"
        ) : (
            (props.colorMode === "day")?
                "chat-item-unchecked-day" :
                "chat-item-unchecked-night"
        );

	// Timestamp
    const timestampTextAdaptive = formatDatetimeAdaptive(props.chatInfo.timestamp);
    const timestampText = formatDatetime(props.chatInfo.timestamp, true, false, " ");
    const timestampTooltipID = "chat-item-" + props.index + "-timestamp-tooltip";

    const timestamp = (
        <span className = {(props.colorMode === "day")?
				"chat-item-time number-lb5" :
				"chat-item-time number-b5"}
            data-for = {timestampTooltipID}
            data-tip = {timestampText}
        >
            {timestampTextAdaptive}
        </span>
    );
    const timestampTooltip = (
        <ReactTooltip
            id = {timestampTooltipID}
            className = {"chat-item-time-tooltip tooltip-s2"}
            type = "info"
            place = "right"
            html = {true}
        />
    );


    // User profile pics and names
    let userProfilePics = null;
    let userNames = null;
    const users = [];
    for (let i = 0; i < props.chatInfo.users.length; i++) {
    	if (props.chatInfo.users[i].id !== props.userInfo.id) {
	    	users.push(props.chatInfo.users[i]);
    	}
    }

    if (users.length === 1) {
        const userImage = (users[0].profile_pic == null)?
            (
                (users[0].profile_pic.external_url === null)?
                    getMediaProperty(users[0].profile_pic, "t", "url", true) :
                    url(users[0].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

    	userProfilePics = (
			<div className = "chat-item-left">
	        	<div className = "chat-item-user-profile-pic profile-image-s6"
	        		style = {{ backgroundImage: userImage }}
	        	>
	        	</div>
	        </div>
		);

		userNames = users[0].name;
    }
    else if (users.length === 2) {
        const userImageLeft = (users[0].profile_pic !== null)?
            (
                (users[0].profile_pic.external_url === null)?
                    getMediaProperty(users[0].profile_pic, "t", "url", true) :
                    url(users[0].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        const userImageRight = (users[1].profile_pic !== null)?
            (
                (users[1].profile_pic.external_url === null)?
                    getMediaProperty(users[1].profile_pic, "t", "url", true) :
                    url(users[1].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

    	userProfilePics = (
			<div className = "chat-item-left">
	        	<div className = "chat-item-user-profile-pic-left"
	        		style = {{ backgroundImage: userImageLeft }}
	        	>
	        	</div>
	        	<div className = "chat-item-user-profile-pic-right"
	        		style = {{ backgroundImage: userImageRight }}
	        	>
	        	</div>
	        </div>
		);

		userNames = users[0].name + " and " + users[1].name;
    }
    else if (users.length === 3) {
        const userImageLeft = (users[0].profile_pic !== null)?
            (
                (users[0].profile_pic.external_url === null)?
                    getMediaProperty(users[0].profile_pic, "t", "url", true) :
                    url(users[0].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
        const userImageRightTop = (users[1].profile_pic !== null)?
            (
                (users[1].profile_pic.external_url === null)?
                    getMediaProperty(users[1].profile_pic, "t", "url", true) :
                    url(users[1].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
        const userImageRightBottom = (users[2].profile_pic !== null)?
            (
                (users[2].profile_pic.external_url === null)?
                    getMediaProperty(users[2].profile_pic, "t", "url", true) :
                    url(users[2].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

    	userProfilePics = (
			<div className = "chat-item-left">
	        	<div className = "chat-item-user-profile-pic-left"
	        		style = {{ backgroundImage: userImageLeft }}
	        	>
	        	</div>
	        	<div className = "chat-item-user-profile-pic-right-container">
		        	<div className = "chat-item-user-profile-pic-right-top"
		        		style = {{ backgroundImage: userImageRightTop }}
		        	>
		        	</div>
		        	<div className = "chat-item-user-profile-pic-right-bottom"
		        		style = {{ backgroundImage: userImageRightBottom }}
		        	>
		        	</div>
	        	</div>
	        </div>
		);

		userNames = users[0].name + ", " + users[1].name  + " and " + users[2].name;
    }
    else if (users.length >= 4) {
        const userImageLeftTop = (users[0].profile_pic == null)?
            (
                (users[0].profile_pic.external_url === null)?
                    getMediaProperty(users[0].profile_pic, "t", "url", true) :
                    url(users[0].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
        const userImageLeftBottom = (users[1].profile_pic == null)?
            (
                (users[1].profile_pic.external_url === null)?
                    getMediaProperty(users[1].profile_pic, "t", "url", true) :
                    url(users[1].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
        const userImageRightTop = (users[2].profile_pic == null)?
            (
                (users[2].profile_pic.external_url === null)?
                    getMediaProperty(users[2].profile_pic, "t", "url", true) :
                    url(users[2].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
        const userImageRightBottom = (users[3].profile_pic == null)?
            (
                (users[3].profile_pic.external_url === null)?
                    getMediaProperty(users[3].profile_pic, "t", "url", true) :
                    url(users[3].profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
    	userProfilePics = (
			<div className = "chat-item-left">
	        	<div className = "chat-item-user-profile-pic-left-container">
		        	<div className = "chat-item-user-profile-pic-left-top"
		        		style = {{ backgroundImage: userImageLeftTop }}
		        	>
		        	</div>
		        	<div className = "chat-item-user-profile-pic-left-bottom"
		        		style = {{ backgroundImage: userImageLeftBottom }}
		        	>
		        	</div>
	        	</div>
	        	<div className = "chat-item-user-profile-pic-right-container">
		        	<div className = "chat-item-user-profile-pic-right-top"
		        		style = {{ backgroundImage: userImageRightTop }}
		        	>
		        	</div>
		        	<div className = "chat-item-user-profile-pic-right-bottom"
		        		style = {{ backgroundImage: userImageRightBottom }}
		        	>
		        	</div>
	        	</div>
	        </div>
		);

    	userNames = (users.length > 4)?
		(users[0].name + ", " + users[1].name  +
		", " + users[2].name + ", " + users[3].name + " and " + (users.length - 4) + " more") :
		(users[0].name + ", " + users[1].name  +
		", " + users[2].name + " and " + users[3].name);
    }

    // Truncate user names
    const userNamesTruncated = (userNames.length > props.truncationLength)? userNames.substring(0, props.truncationLength) + "..." : userNames;

    // Last message
    //console.log("ChatItem / render - props.chatMessagesInfo = ", props.chatMessagesInfo);
    let lastMessage = null;
    if (props.chatMessagesInfo != null) {
       	lastMessage = (props.chatMessagesInfo.length > 0)?
    		props.chatMessagesInfo[props.chatMessagesInfo.length - 1].content : null;
    }

    // Truncate last mesage
    const lastMessageTruncated = ((lastMessage != null) && (lastMessage.length > props.truncationLength))?
		lastMessage.substring(0, props.truncationLength) + "..." : lastMessage;

	// Click callback
	const chatItemClick = (e) => {
		//console.log("ChatItem / chatItemClick - props.numChatWindowsMax = ", props.numChatWindowsMax);
		//console.log("ChatItem / chatItemClick - props.chatWindowsOpen = ", props.chatWindowsOpen);

		// IDs of open chats
		let chatWindowsOpen = null;
		if (props.chatWindowsOpen.length === 0) {
			chatWindowsOpen = [ props.chatInfo.id ];
		}
		else {
			chatWindowsOpen = props.chatWindowsOpen;
			if (chatWindowsOpen.indexOf(props.chatInfo.id) === -1) {
				chatWindowsOpen.push(props.chatInfo.id);
			}
			else {
				chatWindowsOpen.splice(chatWindowsOpen.indexOf(props.chatInfo.id), 1);
			}
		}

		// Remove the first one if the total number exceeds the max
		if (chatWindowsOpen.length > props.numChatWindowsMax) {
			chatWindowsOpen.shift();
		}
		//console.log("ChatItem / chatItemClick - chatWindowsOpen = ", chatWindowsOpen);

		// Close chat window
 	    props.storeChatMenuOn(false);

		// Update open chat windows
		props.setState({
			chatWindowsOpen: chatWindowsOpen
		});
	};


	return (
		<div key = {"chat-item-content-" + props.index.toString()}
			className = {chatItemClassName}
			onClick = {chatItemClick}
		>
			{userProfilePics}
        	<div className = {(props.scrollBarOn)?
        		"chat-item-middle-scroll" : "chat-item-middle"}
        	>

        		<div className = {(props.colorMode === "day")?
						"chat-item-user-names k5" : "chat-item-user-names w5"}
				>
        			{userNamesTruncated}
        		</div>

        		<div className = {(props.colorMode === "day")?
						"chat-item-last-message comment-dg4" :
						"chat-item-last-message comment-g4"}
				>
            		{lastMessageTruncated}
            	</div>
            </div>
            <div className = "chat-item-right">
        		{timestamp}
        		{timestampTooltip}
        	</div>
		</div>
	);
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
    };
}

export default connect(mapStateToProps, null)(Chat);
