import { STORE_MEMO, STORE_NEW_MEMO } from "actions"; 

/*
============================================================================================
	Parameters
--------------------------------------------------------------------------------------------
	memo
	- modalOn: true | false
\	- mode: save | complete | contribute
	- id: dot save id | dot completion id | dot contribution id
	- dot: dot object
	- memo: memo

	newMemo
	- mode: save | complete | contribute
	- id: dot save id | dot completion id | dot contribution id
	- memo: memo
============================================================================================
*/

const INITIAL_STATE = {
	memo: { modalOn: false, mode: null, id: null, dot: null, memo: null },
	newMemo: { mode: null, id: null, memo: null }
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_MEMO:
			return { ...state, memo: action.payload };
		case STORE_NEW_MEMO:
			return { ...state, newMemo: action.payload };
		default:
			return state;
	}
};