/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    CreateConnect.js
    - Connect page for content creation
--------------------------------------------------------------------------------------------
    Content
    - CreateConnect
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";

// Modules
import InputRange from "thedots-input-range";
import ReactTooltip from "thedots-tooltip";

// Components
import { Dot } from "js/DotFunctions";
import { DropDownMenu } from "js/Common";
import { OpenMap, GoogleMap, getAverageLocation } from "components/Map";

// Functions
import {
    url,
    getStaticPath,
    updateStateChain2,
    getMediaProperty,
    formatDuration,
    getTransitImage
} from "js/Functions";

// Axios
import {
    postCreateSearch,
    postTransitEstimate
} from "requests";

// Load shared functions with Itinerary
import {
    dotHoverOn,
    dotHoverOff,
    endHoverOn,
    endHoverOff
} from "js/TripFunctions";

// CSS
import "./CreateConnect.scss";


/*
============================================================================================
    CreateConnect
============================================================================================
*/

// Full component
class CreateConnect extends Component {
    constructor(props){
        super(props);

        // Map Mode
        this.mapSource = "google";
        this.mapMode = "itinerary";

        // Map settings
        this.mapZoom = 15;
        this.mapHeight = 300;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

		// Pagination settings
		this.numPagesPerGroup = 4;

        // Parameters
        this.transitionTime = 300;
		this.userKeywordInputWidth = 300;
		this.generalKeywordInputWidth = 366;
		this.generalUserInputWidth = 300;
		this.inputRightMargin = 10;

        // DOM nodes of the inputs
        this.userKeywordNode = null;
        this.generalKeywordNode = null;
        this.generalUserNode = null;

        // DOM nodes of the maps
        this.mapNodeID = "map-connect";
		this.mapContainerNodeID = "map-connect-container";
		this.mapButtonsNodeID =  "map-connect-buttons";

        // Input focused states
        this.userKeywordFocused = false;
        this.generalKeywordFocused = false;
        this.generalUserFocused = false;

        // Input placeholders
        this.placeholderUserKeyword = "Location name, title, or overview";
        this.placeholderGeneralKeyword = "Location name, title, or overview";
        this.placeholderGeneralUser = "User name or ID";

    	// Search strings
    	this.userKeyword = "";
    	this.generalKeyword = "";
    	this.generalUser = "";

        // Initialize state
        this.state = {
        	// Search results mode
        	searchMode: "user", // "user" vs "general"

        	// Main compartment
        	userResultsOn: true,
        	userResultsOpacity: 1.0,
        	generalResultsOn: false,
        	generalResultsOpacity: 0.0,

        	// Object made by the logged-in user
        	userPage: null,
        	userPageMax: null,
        	userObjects: null,

        	// General search
        	generalPage: null,
        	generalPageMax: null,
        	generalObjects: null,

        	// Selected
        	selectedObjects: [],

        	// Map
        	selected: null,
            hovered: null,
            selectedChild: null,
            displayChildren: null,

            // Map switch
            // - Only used in Itinerary and not used in Trip
            // - Still needed to run image hover handlers in TripOverview
            mapOn: true,

           	// Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false,

        	// Border colors
        	userKeywordBorderColor: "#464646",
        	generalUserBorderColor: "#464646",
        	generalKeywordBorderColor: "#464646",

            // Set map
            setMap: (mapConnect) => {this.props.setState({ mapConnect: mapConnect });},
        };

        // Bind setState
        this.setState = this.setState.bind(this);

        // Clear search results
        this.getInitialResults = this.getInitialResults.bind(this);
        this.clearResults = this.clearResults.bind(this);

        // Bind callbacks
		this.userClick = this.userClick.bind(this);
		this.generalClick = this.generalClick.bind(this);
		this.searchClick = this.searchClick.bind(this);
        this.colorInputBorders = this.colorInputBorders.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.userKeywordOnChange = this.userKeywordOnChange.bind(this);
        this.generalKeywordOnChange = this.generalKeywordOnChange.bind(this);
        this.generalUserOnChange = this.generalUserOnChange.bind(this);

        this.previousGroup = this.previousGroup.bind(this);
        this.nextGroup = this.nextGroup.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.fetchObjects = this.fetchObjects.bind(this);

        this.addClick = this.addClick.bind(this);
        this.removeClick = this.removeClick.bind(this);
        this.updateSelectedIDs = this.updateSelectedIDs.bind(this);
        this.getSelectedIDs = this.getSelectedIDs.bind(this);

        this.resetAnimations = this.resetAnimations.bind(this);

        // Bind dot hover function
        this.dotHoverOn = dotHoverOn.bind(this);
        this.dotHoverOff = dotHoverOff.bind(this);
        this.endHoverOn = endHoverOn.bind(this);
        this.endHoverOff = endHoverOff.bind(this);
    }

    render() {
    	//console.log("CreateConnect / render - this.props = ", this.props);
    	//console.log("CreateConnect / render - this.state = ", this.state);

        /*
        ============================================================================================
            Search inputs
        ============================================================================================
        */

    	// Search inputs
    	const userKeywordInput = (
            <input
                type = "text"
                id = "create-connect-input-user-keyword"
                className = {(this.props.colorMode === "day")?
                    "input-s3 input-day" : "input-s3 input-night"}
                placeholder = {this.placeholderUserKeyword}
                onFocus = {this.inputOnFocus}
                onBlur = {this.inputOnBlur}
                onChange = {this.userKeywordOnChange}
                style = {{
                	display: this.state.searchMode === "user"? "inline-block" : "none",
                	width: this.userKeywordInputWidth,
                	marginRight: this.inputRightMargin,
                	borderColor: this.state.userKeywordBorderColor
                }}
                required
            />
        );

    	const generalUserInput = (
            <input
                type = "text"
                id = "create-connect-input-general-user"
                className = {(this.props.colorMode === "day")?
                    "input-s3 input-day" : "input-s3 input-night"}
                placeholder = {this.placeholderGeneralUser}
                onFocus = {this.inputOnFocus}
                onBlur = {this.inputOnBlur}
                onChange = {this.generalUserOnChange}
                style = {{
                	display: this.state.searchMode === "user"? "none" : "inline-block",
                	width: this.generalUserInputWidth,
                	marginRight: this.inputRightMargin,
                	borderColor: this.state.generalUserBorderColor
                }}
                required
            />
    	);

        const generalKeywordInput = (
            <input
                type = "text"
                id = "create-connect-input-general-keyword"
                className = {(this.props.colorMode === "day")?
                    "input-s3 input-day" : "input-s3 input-night"}
                placeholder = {this.placeholderGeneralKeyword}
                onFocus = {this.inputOnFocus}
                onBlur = {this.inputOnBlur}
                onChange = {this.generalKeywordOnChange}
                style = {{
                	display: this.state.searchMode === "user"? "none" : "inline-block",
                	width: this.generalKeywordInputWidth,
                	borderColor: this.state.generalKeywordBorderColor
                }}
                required
            />
      	);

    	const search = this.state.searchMode === "user"? (
            <div id = "create-connect-search">
            	{userKeywordInput}

        		{generalKeywordInput}

                {generalUserInput}

                <div id = "create-connect-search-button"
                    className = {(this.props.colorMode === "day")?
                        "button-light-blue-gray-s3" : "button-blue-gray-s3"}
		    		onClick = {this.searchClick}
                >
                	Search
                </div>
            </div>
    	) : (
            <div id = "create-connect-search">
            	{userKeywordInput}

            	<div className = "create-connect-search-row">
            		{generalKeywordInput}
	            </div>

            	<div className = "create-connect-search-row">
	                {generalUserInput}
	                <div id = "create-connect-search-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-gray-s3" : "button-blue-gray-s3"}
			    		onClick = {this.searchClick}
	                >
	                	Search
	                </div>
	            </div>
            </div>
        );


        /*
        ============================================================================================
            Search results
        ============================================================================================
        */

    	// User results
    	let userResults = null;
    	if (this.state.userObjects !== null) {
    		//console.log("createConnect / render - userObjects = ", this.state.userObjects[this.state.userPage.toString()]);

	    	const userResultsProps = {
	    		objectsInfo: this.state.userObjects[this.state.userPage.toString()]
	    	};

	    	userResults = (
				<SearchResult {...userResultsProps}
                    colorMode = {this.props.colorMode}
	        		addClick = {this.addClick}
	        		removeClick = {this.removeClick}
	        		updateSelectedIDs = {this.updateSelectedIDs}
	        		selectedObjects = {this.state.selectedObjects}
	        		selectedIDs = {this.props.selectedIDs}
	        		searchMode = {this.state.searchMode}
                    defaultTransitMode = {this.props.defaultTransitMode}
                    defaultTransitDuration = {this.props.defaultTransitDuration}
                    defaultTransitPhysicality = {this.props.defaultTransitPhysicality}
                    defaultTransitDistance = {this.props.defaultTransitDistance}
				>
                </SearchResult>
	    	);
    	}

    	// General results
    	let generalResults = null;
    	if (this.state.generalObjects !== null) {
    		//console.log("createConnect / render - generalObjects = ", this.state.generalObjects[this.state.generalPage.toString()]);

	    	const generalResultsProps = {
	    		objectsInfo: this.state.generalObjects[this.state.generalPage.toString()]
	    	};

	    	generalResults = (
				<SearchResult {...generalResultsProps}
	        		addClick = {this.addClick}
	        		removeClick = {this.removeClick}
	        		updateSelectedIDs = {this.updateSelectedIDs}
	        		selectedObjects = {this.state.selectedObjects}
	        		selectedIDs = {this.props.selectedIDs}
	        		searchMode = {this.state.searchMode}
                    defaultTransitMode = {this.props.defaultTransitMode}
                    defaultTransitDuration = {this.props.defaultTransitDuration}
                    defaultTransitPhysicality = {this.props.defaultTransitPhysicality}
                    defaultTransitDistance = {this.props.defaultTransitDistance}
				>
                </SearchResult>
	    	);
    	}


        /*
        ============================================================================================
            Pagination
        ============================================================================================
        */

        let previous, next, pages = null;

        if ((this.state.searchMode === "user" && this.state.userObjects !== null && this.state.userPageMax > 1)
        	|| (this.state.searchMode === "general" && this.state.generalObjects !== null && this.state.generalPageMax > 1)) {
	        const maxPage = (this.state.searchMode === "user")? this.state.userPageMax : this.state.generalPageMax;
	        const currentPage = (this.state.searchMode === "user")? this.state.userPage : this.state.generalPage;
	        const maxPageGroup = Math.floor((maxPage - 1) / this.numPagesPerGroup); // indexed from 0
	        const currentPageGroup = Math.floor((currentPage - 1) / this.numPagesPerGroup); // indexed from 0
            const prevPageButton = (this.props.colorMode === "day")?
                getStaticPath("/images/common/arrow-left-black.png") :
                getStaticPath("/images/common/arrow-left-white.png");
            const nextPageButton = (this.props.colorMode === "day")?
                getStaticPath("/images/common/arrow-right-black.png") :
                getStaticPath("/images/common/arrow-right-white.png");

	        // Get the navigation arrows
	        if (currentPageGroup > 0) {
				previous = (
					<div className = "create-connect-previous image-button-s9"
						style = {{
							backgroundImage: prevPageButton
						}}
						onClick = {this.previousGroup}
					>
					</div>
				);
	        }

	        if (currentPageGroup < maxPageGroup) {
				next = (
					<div className = "create-connect-next image-button-s9"
						style = {{
							backgroundImage: nextPageButton
						}}
						onClick = {this.nextGroup}
					>
					</div>
				);
	        }

	        // Page groups
	        const currentPageGroupStart = currentPageGroup * this.numPagesPerGroup + 1;
			const currentPageGroupEnd = (maxPage > (currentPageGroup + 1) * this.numPagesPerGroup)?
				(currentPageGroup + 1) * this.numPagesPerGroup : maxPage;
			//console.log("CreateConnect / render - maxPage = ", maxPage);
			//console.log("CreateConnect / render - maxPageGroup = ", maxPageGroup);
			//console.log("CreateConnect / render - currentPage = ", currentPage);
			//console.log("CreateConnect / render - currentPageGroup = ", currentPageGroup);
			//console.log("CreateConnect / render - currentPageGroupStart = ", currentPageGroupStart);
			//console.log("CreateConnect / render - currentPageGroupEnd = ", currentPageGroupEnd);

			// Pages
			const pageNumbers = [];
			for (let pageNumber = currentPageGroupStart; pageNumber <= currentPageGroupEnd; pageNumber++) {
				pageNumbers.push(pageNumber);
			}

			// JSX
		    // For all dots in the list
		    pages = pageNumbers.map((pageNumber, index) => {
		    	const currentPage = this.state.searchMode === "user"? this.state.userPage : this.state.generalPage;
		    	const pageClass = (pageNumber === currentPage)? "create-connect-page page-selected-s2" : "create-connect-page page-s2";

		    	return (
		    		<div key = {"create-connect-search-" + this.state.searchMode + "-page-" + pageNumber.toString()}
		    			className = {pageClass}
		    			onClick = {() => {this.goToPage(pageNumber);}}
		    		>
		    			{pageNumber}
		    		</div>
		    	);
		    });
		}

        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        let map = null;


        // Only when google object and locations are ready
        if (this.state.selectedObjects.length > 0) {

            // Construct itinerary
        	const itinerary = [];
        	for (let i = 0; i < this.state.selectedObjects.length; i++) {
        		itinerary.push(i);
        	}

            if ((this.mapSource === "google") && (this.props.google)) {
                // Set the props for Map component
                const mapProps = {
                    // Google
                    google: this.props.google,

                    // Map mode
                    mapMode: this.mapMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    mapContainerNodeID : this.mapContainerNodeID,
                    buttonsNodeID: this.mapButtonsNodeID,
                    startInputNodeID: null,
                    endInputNodeID: null,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: this.state.selectedObjects,

                    // Selected / hovered / selectedChild / hoveredChild / displayChildren
                    itinerary: itinerary,
                    selected: null,
                    hovered: this.state.hovered,
                    selectedChild: null,
                    displayChildren: null,

                    // Start and end locations
                    startLocation: this.props.startLocation,
                    endLocation: this.props.endLocation,

                    // Action triggers
    	            mapZoomInAnimation: this.state.mapZoomInAnimation,
    	            mapZoomOutAnimation: this.state.mapZoomOutAnimation,
    	            mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
    	            mapRefreshAnimation: this.state.mapRefreshAnimation,
                    resetAnimations: this.resetAnimations,

    	            // Dot click and hover callback
    	            dotClick: () => {},
    	            dotHoverOn: this.dotHoverOn,
    	            dotHoverOff: this.dotHoverOff,
    	            endHoverOn: this.endHoverOn,
    	            endHoverOff: this.endHoverOff,

                    // Parse callbacks (modify top states)
                    setStartLocation: null,
                    setEndLocation: null,

                    // Map zoom / center / type
                    mapZoom: this.mapZoom,
                    mapType: "hybrid"
                }
                //console.log("Trip / render - mapProps = ", mapProps);

                // Get the Map component
                map = (
                    <div id = "create-connect-map-container"
                        className = {(this.props.colorMode === "day")?
                            "border-day" : "border-night"}
                    >
                        <GoogleMap {...mapProps}/>
                    </div>
                );
            }
            else if (this.mapSource === "open") {
                const averageLocation = getAverageLocation(
                    itinerary,
                    this.state.selectedObjects
                );

                // Set the props for Map component
                const openMapProps = {
                    // Map mode
                    mapMode: this.mapMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: this.state.selectedObjects,

                     // Selected / hovered / selectedChild / hoveredChild / displayChildren
                    itinerary: itinerary,
                    selected: null,
                    hovered: this.state.hovered,
                    selectedChild: null,
                    displayChildren: null,

                    // Start and end locations
                    startLocation: this.props.startLocation,
                    endLocation: this.props.endLocation,

                    // Map zoom / center / type
                    mapZoom: this.mapZoom,
                    mapCenter: averageLocation,
                    mapType: "hybrid",

                    // SetState
                    setState: this.setState
                };

                // Get the Map component
                map = (
                    <div id = "create-connect-map-container"
                        className = {(this.props.colorMode === "day")?
                            "border-day" : "border-night"}
                    >
                        <OpenMap {...openMapProps}/>
                    </div>
                );
            }
        }


        /*
        ============================================================================================
            Selected list
        ============================================================================================
        */

        let selected = null;
        if (this.props.dotType === "TR") {
            selected = (this.state.selectedObjects.length > 0)? (
                <SelectedListTrip
                    colorMode = {this.props.colorMode}
                    hovered = {this.state.hovered}
                    selectedInfo = {this.state.selectedObjects}
                    removeClick = {this.removeClick}
                    updateSelectedIDs = {this.updateSelectedIDs}
                    dotType = {this.props.dotType}
                    highlightIDs = {this.props.highlightIDs}
                    highlightCount = {this.props.highlightCount}
                    setState = {this.props.setState}
                    dotHoverOn = {this.dotHoverOn}
                    dotHoverOff = {this.dotHoverOff}
                />
            ) : null;
        }
        else if (this.props.dotType === "RT") {
            selected = (this.state.selectedObjects.length > 0)? (
                <SelectedListRoute
                    colorMode = {this.props.colorMode}
                    sliderClassNames = {this.props.sliderClassNames}
                    hovered = {this.state.hovered}
                    selectedInfo = {this.state.selectedObjects}
                    removeClick = {this.removeClick}
                    updateSelectedIDs = {this.updateSelectedIDs}
                    dotType = {this.props.dotType}
                    roundtrip = {this.props.roundtrip}
                    loop = {this.props.loop}
                    highlightIDs = {this.props.highlightIDs}
                    highlightCount = {this.props.highlightCount}
                    setState = {this.props.setState}
                    dotHoverOn = {this.dotHoverOn}
                    dotHoverOff = {this.dotHoverOff}
                    transitModes = {this.props.transitModes}
                    transitDurations = {this.props.transitDurations}
                    transitPhysicalities = {this.props.transitPhysicalities}
                    transitDistances = {this.props.transitDistances}
                    durationScaleIndices = {this.props.durationScaleIndices}
                    distanceScaleIndices = {this.props.distanceScaleIndices}
                    updateDurationScaleIndices = {this.props.updateDurationScaleIndices}
                    updateDistanceScaleIndices = {this.props.updateDistanceScaleIndices}
                    durationScales = {this.props.durationScales}
                    distanceScales = {this.props.distanceScales}
                    physicalityLabels = {this.props.physicalityLabels}
                    updateTransitDurations = {this.props.updateTransitDurations}
                    updateTransitPhysicalities = {this.props.updateTransitPhysicalities}
                    updateTransitDistances = {this.props.updateTransitDistances}
                    defaultPhysicalities = {this.props.defaultPhysicalities}
                    defaultSpeeds = {this.props.defaultSpeeds}
                />
            ) : null;
        }
        else {
            console.log("[WARNING] CreateConnect - render / wrong dot type");
        }

    	// Return JSX
    	return (
    		<div id = "create-connect-wrapper">
	    		<div id = "create-connect-left">
	    			<div id = "create-connect-mode">
		    			<div id = "create-connect-mode-user"
                            className = {(this.state.searchMode === "user")?
                                (
                                    (this.props.colorMode === "day")?
                                        "button-light-blue-s3 create-connect-mode-on" :
                                        "button-blue-s3 create-connect-mode-on"
                                ) : (
                                    (this.props.colorMode === "day")?
                                        "button-light-gray-s3" : "button-gray-s3"
                                )}
		    				onClick = {this.userClick}
		    			>
		    				My Dots
		    			</div>
		    			<div id = "create-connect-mode-general"
                            className = {(this.state.searchMode === "general")?
                                (
                                    (this.props.colorMode === "day")?
                                        "button-light-blue-s3 create-connect-mode-on" :
                                        "button-blue-s3 create-connect-mode-on"
                                ) : (
                                    (this.props.colorMode === "day")?
                                        "button-light-gray-s3" : "button-gray-s3"
                                )}
		    				onClick = {this.generalClick}
		    			>
		    				General
		    			</div>
	    			</div>

	    			{search}

			    	<div id = "create-connect-user"
			    		style = {{
			    			display: this.state.userResultsOn? "block" : "none",
			    			opacity: this.state.userResultsOpacity
			    		}}
			    	>
		    			{userResults}
		    		</div>

			    	<div id = "create-connect-general"
			    		style = {{
			    			display: this.state.generalResultsOn? "block" : "none",
			    			opacity: this.state.generalResultsOpacity
			    		}}
			    	>
		    			{generalResults}
		    		</div>

		    		<div className = "create-connect-pages-container">
			    		{previous}
			    		<div className = "create-connect-pages">
			    			{pages}
			    		</div>
			    		{next}
			    	</div>
		    	</div>
	    		<div id = "create-connect-right">
                    {map}

		    		{selected}
		    	</div>
		    </div>
    	);
    }

    componentDidMount() {
        // Grab the DOM nodes of inputs
        this.userKeywordNode = document.getElementById("create-connect-input-user-keyword");
        this.generalKeywordNode = document.getElementById("create-connect-input-general-keyword");
        this.generalUserNode = document.getElementById("create-connect-input-general-user");
        //console.log("CreateSearch / componentDidMount - this.userKeywordNode = ", this.userKeywordNode);
        //console.log("CreateSearch / componentDidMount - this.generalKeywordNode = ", this.generalKeywordNode);
        //console.log("CreateSearch / componentDidMount - this.generalUserNode = ", this.generalUserNode);

        // Initial search results
        this.getInitialResults();
    }

    componentDidUpdate() {
        this.userKeywordNode = document.getElementById("create-connect-input-user-keyword");
        this.generalKeywordNode = document.getElementById("create-connect-input-general-keyword");
        this.generalUserNode = document.getElementById("create-connect-input-general-user");

        // Clear results if needed
        if (this.props.refreshResults) {
        	this.props.setState(
        		{
        			refreshResults: false
        		},
	        	() => {this.clearResults();}
        	)
        }
    }


    /*
    ============================================================================================
        Clear search results and selected
    ============================================================================================
    */

    clearResults() {
    	this.userKeyword = "";
    	this.generalKeyword = "";
    	this.generalUser = "";

    	this.setState(
    		{
	        	// Search results mode
	        	searchMode: "user",

	        	// Main compartment
	        	userResultsOn: true,
	        	userResultsOpacity: 1.0,
	        	generalResultsOn: false,
	        	generalResultsOpacity: 0.0,

	        	// Object made by the logged-in user
	        	userPage: null,
	        	userPageMax: null,
	        	userObjects: null,

	        	// General search
	        	generalPage: null,
	        	generalPageMax: null,
	        	generalObjects: null,

	        	// Selected
	        	selectedObjects: [],
    		},
    	this.getInitialResults
    	);
    }


    /*
    ============================================================================================
        getInitialResults
    ============================================================================================
    */

    getInitialResults() {
		// Get response and update states
        const axiosCallback = (response) => {
            //console.log("CreateSearch / componentDidMount - response.data = ", response.data);

            const userObjects = {};
            userObjects["1"] = response.data.content.results;
            //console.log("CreateSearch / componentDidMount - userObjects = ", userObjects);
            //console.log("CreateSearch / componentDidMount - userObjects["1"] = ", userObjects["1"]);

            const firstState = {
				userPageMax: response.data.content.page_max,
				userObjects: userObjects,
				userPage: 1,
            	userResultsOn: true
            };

            const secondState = {
            	userResultsOpacity: 1.0
            };

            // Set state chain
            updateStateChain2(this.setState, firstState, secondState, null, this.transitionTime);
        };

        // Request data
        const dataJSON = {
        	search_mode: "user",
        	keyword: null,
        	user: null,
        	page: 1,
        	//location: null
        };
        //console.log("CreateSearch / componentDidMount - dataJSON = ", dataJSON);

        // Send create request
        postCreateSearch(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] CreateSearch / componentDidMount - error = ", response);});
    }


    /*
    ============================================================================================
        Mode buttons
    ============================================================================================
    */

    userClick() {
        if (!this.state.userResultsOn) {
            this.setState(
            	{
	            	searchMode: "user",
	            	userResultsOn: true,
	                userResultsOpacity: 1.0,
	            	generalResultsOn: false,
                    generalResultsOpacity: 0.0
	            },
	            () => {
	            	this.userKeywordNode.value = this.userKeyword;
	            	//this.generalKeywordNode.value = this.generalKeyword;
	            	//this.generalUserNode.value = this.generalUser;
	            }
            );
        }
    }

    generalClick() {
        if (!this.state.generalResultsOn) {
            this.setState(
            	{
	            	searchMode: "general",
	            	userResultsOn: false,
                    userResultsOpacity: 0.0,
	            	generalResultsOn: true,
                    generalResultsOpacity: 1.0
            	},
	            () => {
	            	//this.userKeywordNode.value = this.userKeyword;
	            	this.generalKeywordNode.value = this.generalKeyword;
	            	this.generalUserNode.value = this.generalUser;
	            }
            );
        }
    }


    /*
    ============================================================================================
        Input functions
    ============================================================================================
    */

    colorInputBorders() {
        const userKeywordBorderColor = (this.userFocused)? "#FFFFFF" : "#464646";
        const generalKeywordBorderColor = (this.generalKeywordFocused)? "#FFFFFF" : "#464646";
        const generalUserBorderColor = (this.generalUserFocused)? "#FFFFFF" : "#464646";

        // Update states
        this.setState({
        	userKeywordBorderColor: userKeywordBorderColor,
        	generalKeywordBorderColor: generalKeywordBorderColor,
        	generalUserBorderColor: generalUserBorderColor
        });
    }


    inputOnFocus(event) {
        //console.log("CreateConnect / inputOnFocus - event.target.value = ", event.target.value);
        event.target.placeholder = "";

        // get the target id
        const id = event.target.id;

        if (id === "create-connect-input-user-keyword") {
            this.userKeywordFocused = true;
        }
        else if (id === "create-connect-input-general-keyword") {
            this.generalKeywordFocused = true;
        }
        else if (id === "create-connect-input-general-user") {
            this.generalUserFocused = true;
        }
        else {
            console.log("[WARNING] CreateConnect / inputOnFocus - unknown input ID");
        }

        // Update the border colors
        this.colorInputBorders();
    }


    inputOnBlur(event) {
        //console.log("CreateConnect / inputOnBlur - event.target.value = ", event.target.value);

        // Get the target id
        const id = event.target.id;

        if (id === "create-connect-input-user-keyword") {
            this.userKeywordFocused = false;
            event.target.placeholder = this.placeholderUserKeyword;
        }
        else if (id === "create-connect-input-general-keyword") {
            this.generalKeywordFocused = false;
            event.target.placeholder = this.placeholderGeneralKeyword;
        }
        else if (id === "create-connect-input-general-user") {
            this.generalUserFocused = false;
            event.target.placeholder = this.placeholderGeneralUser;
        }
        else {
            console.log("[WARNING] CreateConnect / inputOnBlur - unknown input ID");
        }

        // Update the border colors
        this.colorInputBorders();
    }

    userKeywordOnChange(event) {
    	this.userKeyword = this.userKeywordNode.value;
    }

	generalKeywordOnChange(event) {
    	this.generalKeyword = this.generalKeywordNode.value;
	}

	generalUserOnChange(event) {
    	this.generalUser = this.generalUserNode.value;
	}

    /*
    ============================================================================================
        Search
    ============================================================================================
    */

    searchClick(event) {
        //console.log("CreateSearch / searchClick - this.userKeywordNode = ", this.userKeywordNode);
        //console.log("CreateSearch / searchClick - this.generalKeywordNode = ", this.generalKeywordNode);
        //console.log("CreateSearch / searchClick - this.generalUserNode = ", this.generalUserNode);

		// Get response and update states
        const axiosCallback = (response) => {
            //console.log("CreateSearch / searchClick - response.data = ", response.data);

            // Objects
            const objects = {};
            objects["1"] = response.data.content.results;
            //console.log("CreateSearch / componentDidMount - objects = ", objects);
            //console.log("CreateSearch / componentDidMount - objects["1"] = ", objects["1"]);

            let stateToUpdate = null;
            if (this.state.searchMode === "user") {
	            stateToUpdate = {
					userPageMax: response.data.content.page_max,
					userObjects: objects,
					userPage: 1, // Reset page
					userKeyword: this.userKeywordNode.value,
	            };

            }
            else {
	            stateToUpdate = {
					generalPageMax: response.data.content.page_max,
					generalObjects: objects,
					generalPage: 1, // Reset page
		        	generalKeyword: this.generalKeywordNode.value,
		        	generalUser: this.generalUserNode.value
	            };
            }

            this.setState(stateToUpdate);
        };

        // Request data
        let dataJSON = null;

        if (this.state.searchMode === "user") {
            const keyword = (this.userKeywordNode.value.replace(" ","").length > 0)?
                this.userKeywordNode.value : null;
	        dataJSON = {
	        	search_mode: this.state.searchMode,
	        	keyword: keyword,
	        	user: null,
	        	page: 1,
	        	//location: null
	        };
	    }
	    else {
            const keyword = (this.generalKeywordNode.value.replace(" ","").length > 0)?
                this.generalKeywordNode.value : null;
            const user = (this.generalUserNode.value.replace(" ","").length > 0)?
                this.generalUserNode.value : null;

	        dataJSON = {
	        	search_mode: this.state.searchMode,
	        	keyword: keyword,
	        	user: user,
	        	page: 1,
	        	//location: null
	        };
	    }
        //console.log("CreateConnect / searchClick - dataJSON: ", dataJSON);

        // Send create request
        postCreateSearch(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] CreateSearch / searchClick - error = ", response);});
    }

    /*
    ============================================================================================
        Input functions
    ============================================================================================
    */

    previousGroup() {
   		const previousPage = (this.state.searchMode === "user")?
    		((Math.floor((this.state.userPage - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1 ) : ((Math.floor((this.state.generalPage - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1 );

    	this.goToPage(previousPage);
    }

    nextGroup() {
    	const nextPage = (this.state.searchMode === "user")?
    		((Math.floor((this.state.userPage - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1 ) : ((Math.floor((this.state.generalPage - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1 );

    	this.goToPage(nextPage);
    }

    goToPage(page) {
    	//console.log("CreateConnect / goToPage - this.state.userObjects = ", this.state.userObjects);
    	//console.log("CreateConnect / goToPage - this.state.generalObjects = ", this.state.generalObjects);
    	if (this.state.searchMode === "user") {
    		if (page.toString() in this.state.userObjects) {
		    	this.setState(
		    		{
		    			userPage: page,
		    		}
		    	);
    		}
    		else {
				this.fetchObjects(page);
    		}
    	}
    	else {
    		if (page.toString() in this.state.generalObjects) {
		    	this.setState(
		    		{
		    			generalPage: page,
		    		}
		    	);
    		}
    		else {
				this.fetchObjects(page);
    		}
    	}
    }

    fetchObjects(pageToFetch) {
        //console.log("CreateSearch / fetchObjects - pageToFetch = ", pageToFetch);

		// Get response and update states
        const axiosCallback = (response) => {
            //console.log("CreateSearch / fetchObjects - response.data = ", response.data);

            // Objects
            const objects = this.state.searchMode === "user"? Object.assign({}, this.state.userObjects) : Object.assign({}, this.state.generalObjects);
            objects[pageToFetch.toString()] = response.data.content.results;
            //console.log("CreateSearch / fetchObjects - objects = ", objects);
            //console.log("CreateSearch / fetchObjects - objects[pageToFetch.toString()] = ", objects[pageToFetch.toString()]);

            let stateToUpdate = null;
            if (this.state.searchMode === "user") {
	            stateToUpdate = {
					userObjects: objects,
					userPage: pageToFetch,
					userKeyword: this.userKeywordNode.value,
	            };

            }
            else {
	            stateToUpdate = {
					generalObjects: objects,
					generalPage: pageToFetch,
		        	generalKeyword: this.generalKeywordNode.value,
		        	generalUser: this.generalUserNode.value
	            };
            }

            this.setState(stateToUpdate);
        };

        // Request data
        let dataJSON = null;

        if (this.state.searchMode === "user") {
            const keyword = (this.userKeywordNode.value.replace(" ","").length > 0)?
                this.userKeywordNode.value : null;
            dataJSON = {
                search_mode: this.state.searchMode,
                keyword: keyword,
                user: null,
                page: pageToFetch,
                //location: null
            };
        }
        else {
            const keyword = (this.generalKeywordNode.value.replace(" ","").length > 0)?
                this.generalKeywordNode.value : null;
            const user = (this.generalUserNode.value.replace(" ","").length > 0)?
                this.generalUserNode.value : null;

            dataJSON = {
                search_mode: this.state.searchMode,
                keyword: keyword,
                user: user,
                page: pageToFetch,
                //location: null
            };
        }
        //console.log("CreateConnect / fetchObjects - dataJSON: ", dataJSON);

        // Send create request
        postCreateSearch(dataJSON)
        .then(axiosCallback)
    }

    addClick(object, callback) {
    	//console.log("CreateConnect / addClick - object = ", object);
    	//console.log("CreateConnect / addClick - this.state.selectedObjects = ", this.state.selectedObjects);
        if ((this.props.dotType === "RT") && (this.props.startLocation === null ||
            (!this.props.loop && !this.props.roundtrip && this.props.endLocation === null))) {
            //console.log("CreateConnect / addClick - set start and end locations first");

            const connectWarningMessage = (!this.props.loop && !this.props.roundtrip && this.props.endLocation === null)?
                (
                    (this.props.startLocation === null)?
                        this.props.connectStartEndLocationWarningMessage : this.props.connectEndLocationWarningMessage
                ) : this.props.connectStartLocationWarningMessage;

            // Check locations
            let locationWarningMessage = null;
            // Check locations
            if ((this.props.startLocation === null) && ((!this.props.roundtrip && !this.props.loop) && (this.props.endLocation === null))) {
                locationWarningMessage = this.props.locationStartEndWarningMessage;
            }
            else if ((this.props.startLocation !== null) && (!this.props.roundtrip && !this.props.loop) && (this.props.endLocation === null)) {
                locationWarningMessage = this.props.locationEndWarningMessage;
            }
            else if ((this.props.startLocation === null) && (this.props.roundtrip || this.props.loop) && (this.props.endLocation !== null)) {
                locationWarningMessage = this.props.locationStartWarningMessage;
            }
            //console.log("CreateConnect / addClick - locationWarningMessage = ", locationWarningMessage);

            this.props.setState(
                {
                    locationWarningOn: true,
                    locationWarningMessage: locationWarningMessage,
                    connectWarningOn: true,
                    connectWarningMessage: connectWarningMessage
                },
                () => {
                    this.props.scrollToTop();
                    this.props.colorInputBorders();
                }
            );
        }
        else {
            //console.log("CreateConnect / addClick");

            // Selected objects
        	const selectedObjects = this.state.selectedObjects.slice();

            // Transit modes / durations / physicalitise / distances
            const transitModes = this.props.transitModes.slice();
            const transitDurations = this.props.transitDurations.slice();
            const transitPhysicalities = this.props.transitPhysicalities.slice();
            const transitDistances = this.props.transitDistances.slice();
            const durationScaleIndices = this.props.durationScaleIndices.slice();
            const distanceScaleIndices = this.props.distanceScaleIndices.slice();

            // Start and end location
            const previousLocation = (selectedObjects.length === 0)?
                this.props.startLocation : selectedObjects[selectedObjects.length - 1].location;
            const currentLocation = object.location;
            /*
            if (selectedObjects.length > 0) {
                console.log("CreateConnect / addClick - previous location name = ", selectedObjects[selectedObjects.length - 1].name);
                console.log("CreateConnect / addClick - previous location = ", selectedObjects[selectedObjects.length - 1].location);
            }
            console.log("CreateConnect / addClick - current location name = ", object.name);
            console.log("CreateConnect / addClick - current location = ", object.location);
            */

            //console.log("CreateConnect / addClick - previousLocation = ", previousLocation);
            //console.log("CreateConnect / addClick - currentLocation = ", currentLocation);

            // Add object
        	selectedObjects.push(object);
        	//console.log("CreateConnect / addClick (after addition) - selectedObjects = ", selectedObjects);

            // Fill the missing elements of the transit lists
            const numMissing = selectedObjects.length + 1 - transitModes.length;
            if (numMissing > 0) {
                for (let i = 0; i < numMissing; i++) {
                    transitModes.push(this.props.defaultTransitMode);
                    transitDurations.push(this.props.defaultTransitDuration);
                    transitPhysicalities.push(this.props.defaultTransitPhysicality);
                    transitDistances.push(this.props.defaultTransitDistance);
                    durationScaleIndices.push(0);
                    distanceScaleIndices.push(0);
                }
            }
            //console.log("Create / routeTypeClick - transitModes = ", transitModes);

            // Update transit properties for routes
            if (this.props.dotType === "RT") {
                // Callback
                const axiosCallback = (response) => {
                    //console.log("CreateConnect / addClick - response = ", response);

                    const firstIndex = transitModes.length - 2;
                    const secondIndex = transitModes.length - 1;

                    // Successful
                    if (response.data.content.first_distance !== null) {
                        //const firstDistance = Math.floor(Number(response.data.content.first_distance) / 5);
                        const first_distance = Number(response.data.content.first_distance) * this.props.metersToMiles;
                        transitModes[firstIndex] = Number(response.data.content.first_mode);
                        transitDurations[firstIndex] = Number(response.data.content.first_duration);
                        transitPhysicalities[firstIndex] = this.props.defaultPhysicalities[Number(response.data.content.first_mode)];
                        transitDistances[firstIndex] = first_distance;

                        let durationScaleIndex = null;
                        for (let i = 0; i < this.props.durationScales.length; i++) {
                            if ((this.props.durationScales[i] > Number(response.data.content.first_duration)) && (durationScaleIndex === null)) {
                                durationScaleIndex = i;
                            }
                        }
                        durationScaleIndex = Math.min(durationScaleIndex, this.props.durationScales.length - 1);
                        //console.log("CreateConnect / addClick - this.props.durationScales = ", this.props.durationScales);
                        //console.log("CreateConnect / addClick - Number(response.data.content.first_duration) = ", Number(response.data.content.first_duration));
                        //console.log("CreateConnect / addClick - durationScaleIndex = ", durationScaleIndex);

                        let distanceScaleIndex = null;
                        for (let i = 0; i < this.props.distanceScales.length; i++) {
                            if ((this.props.distanceScales[i] > first_distance) && (distanceScaleIndex === null)) {
                                distanceScaleIndex = i;
                            }
                        }
                        distanceScaleIndex = Math.min(distanceScaleIndex, this.props.distanceScales.length - 1);

                        durationScaleIndices[firstIndex] = durationScaleIndex;
                        distanceScaleIndices[firstIndex] = distanceScaleIndex;
                    }
                    else {
                        transitModes[firstIndex] = this.props.defaultTransitMode;
                        transitDurations[firstIndex] = this.props.defaultTransitDuration;
                        transitPhysicalities[firstIndex] = this.props.defaultTransitPhysicality;
                        transitDistances[firstIndex] = this.props.defaultTransitDistance;
                        durationScaleIndices[firstIndex] = 0;
                        distanceScaleIndices[firstIndex] = 0;
                    }

                    // Successful
                    if (response.data.content.second_distance !== null) {
                        const second_distance = Number(response.data.content.second_distance) * this.props.metersToMiles;
                        transitModes[secondIndex] = Number(response.data.content.second_mode);
                        transitDurations[secondIndex] = Number(response.data.content.second_duration);
                        transitPhysicalities[secondIndex] = this.props.defaultPhysicalities[Number(response.data.content.second_mode)];
                        transitDistances[secondIndex] = second_distance;

                        let durationScaleIndex = null;
                        for (let i = 0; i < this.props.durationScales.length; i++) {
                            if ((this.props.durationScales[i] > Number(response.data.content.second_duration)) && (durationScaleIndex === null)) {
                                durationScaleIndex = i;
                            }
                        }
                        durationScaleIndex = Math.min(durationScaleIndex, this.props.durationScales.length - 1);

                        let distanceScaleIndex = null;
                        for (let i = 0; i < this.props.distanceScales.length; i++) {
                            if ((this.props.distanceScales[i] > second_distance) && (distanceScaleIndex === null)) {
                                distanceScaleIndex = i;
                            }
                        }
                        distanceScaleIndex = Math.min(distanceScaleIndex, this.props.distanceScales.length - 1);

                        durationScaleIndices[secondIndex] = durationScaleIndex;
                        distanceScaleIndices[secondIndex] = distanceScaleIndex;
                    }
                    else {
                        transitModes[secondIndex] = this.props.defaultTransitMode;
                        transitDurations[secondIndex] = this.props.defaultTransitDuration;
                        transitPhysicalities[secondIndex] = this.props.defaultTransitPhysicality;
                        transitDistances[secondIndex] = this.props.defaultTransitDistance;
                        durationScaleIndices[secondIndex] = 0;
                        distanceScaleIndices[secondIndex] = 0;
                    }

                    // Update states
                    const combinedCallback = () => {
                        this.props.colorInputBorders();
                        this.setState(
                            {
                                selectedObjects: selectedObjects
                            },
                            callback
                        );
                    };

                    // Update states
                    this.props.setState(
                        {
                            transitModes: transitModes,
                            transitDurations: transitDurations,
                            transitPhysicalities: transitPhysicalities,
                            transitDistances: transitDistances,
                            durationScaleIndices: durationScaleIndices,
                            distanceScaleIndices: distanceScaleIndices,
                            locationWarningOn: false,
                            locationWarningMessage: null
                        },
                        combinedCallback
                    );
                };

                // Data
                const dataJSON = {
                    previous_location: previousLocation,
                    current_location: currentLocation,
                    end_location: (this.props.loop)? this.props.startLocation : this.props.endLocation
                };
                //console.log("CreateConnect / addClick - dataJSON = ", dataJSON);

                // Send create request
                postTransitEstimate(dataJSON)
                .then(axiosCallback)
                .catch((response) => {console.log("[WARNING] CreateConnect / addClick - error = ", response);});
            }
            else {
                // Update states
                this.setState(
                    {
                        selectedObjects: selectedObjects
                    },
                    callback
                );
            }
        }
    }

    removeClick(object, callback) {
    	//console.log("CreateConnect / removeClick - object = ", object);
    	//console.log("CreateConnect / removeClick - this.state.selectedObjects = ", this.state.selectedObjects);
    	const selectedIDs = this.getSelectedIDs();
    	const index = selectedIDs.indexOf(object.id);
    	const selectedObjects = this.state.selectedObjects.slice();
    	selectedObjects.splice(index, 1);
    	//console.log("CreateConnect / removeClick - selectedIDs = ", selectedIDs);
    	//console.log("CreateConnect / removeClick - index = ", index);
    	//console.log("CreateConnect / removeClick (after removal) - selectedObjects = ", selectedObjects);

        // Update states
    	this.setState(
    		{
    			selectedObjects: selectedObjects
    		},
    		callback
    	);

        // Remove highlights
        const highlightIDs = this.props.highlightIDs.slice();
        highlightIDs.splice(index, 1);
        this.props.setState({
            highlightIDs: highlightIDs
        });

        // Update transit properties for routes
        if (this.props.dotType === "RT") {
            const transitModes = this.props.transitModes.slice();
            const transitDurations = this.props.transitDurations.slice();
            const transitPhysicalities = this.props.transitPhysicalities.slice();
            const transitDistances = this.props.transitDistances.slice();
            const durationScaleIndices = this.props.durationScaleIndices.slice();
            const distanceScaleIndices = this.props.distanceScaleIndices.slice();

            // Remove one element
            transitModes.splice(index + 1, 1);
            transitDurations.splice(index + 1, 1);
            transitPhysicalities.splice(index + 1, 1);
            transitDistances.splice(index + 1, 1);
            durationScaleIndices.splice(index + 1, 1);
            distanceScaleIndices.splice(index + 1, 1);

            // Update states
            this.props.setState({
                transitModes: transitModes,
                transitDurations: transitDurations,
                transitPhysicalities: transitPhysicalities,
                transitDistances: transitDistances,
                durationScaleIndices: durationScaleIndices,
                distanceScaleIndices: distanceScaleIndices
            });
        }
    }

    updateSelectedIDs() {
    	const selectedIDs = this.getSelectedIDs();

    	this.props.setState({
    		selectedIDs: selectedIDs,
    		connectWarningOn: false,
    		connectWarningOpacity: 0.0
    	});
    }

    getSelectedIDs() {
    	const selectedIDs = [];

    	for (let selectedIndex = 0; selectedIndex < this.state.selectedObjects.length; selectedIndex++) {
    		selectedIDs.push(this.state.selectedObjects[selectedIndex].id);
    	}

    	return selectedIDs
    }

    /*
    ============================================================================================
        Map resetAnimations
    ============================================================================================
    */

    resetAnimations(callback) {
        //console.log("Create / resetAnimations - executed");
        this.setState(
            {
                mapZoomInAnimation: false,
                mapZoomOutAnimation: false,
                mapZoomHikeAnimation: false,
                mapRefreshAnimation: false
            },
            callback
        );
    }
}


function SearchResult(props) {
    //console.log("SearchResult - props = ", props);

    // For all dots in the list

    const searchList = props.objectsInfo !== null?
    props.objectsInfo.map((object, objectIndex) => {
        return (
            <SearchResultItem
                colorMode = {props.colorMode}
                key = {"create-connect-searched-item-" + objectIndex.toString()}
                object = {object}
                objectIndex = {objectIndex}
                addClick = {props.addClick}
                removeClick = {props.removeClick}
                updateSelectedIDs = {props.updateSelectedIDs}
                selectedObjects = {props.selectedObjects}
                selectedIDs = {props.selectedIDs}
                searchMode = {props.searchMode}
                defaultTransitMode = {props.defaultTransitMode}
                defaultTransitDuration = {props.defaultTransitDuration}
                defaultTransitPhysicality = {props.defaultTransitPhysicality}
                defaultTransitDistance = {props.defaultTransitDistance}
            >
            </SearchResultItem>
        );
    }) : null;

    return (
    	<div>
    		{searchList}
    	</div>
    )
}


class SearchResultItem extends Component {
    constructor(props) {
        super(props);

        // Max height of content
        this.maxContentHeight = 140;

        // Ref to content
        this.contentRef = React.createRef();

        // States
        this.state = {
            moreOn: null
        };
    }

    render() {
        //console.log("SearchResultItem - objectIndex = ", this.props.objectIndex);

        // Get mediaURL for the right size (medium size: "xs")
        const mediaURL = getMediaProperty(this.props.object.media[0], "xs", 'url', true);
        //console.log("SearchResultItem - mediaURL = ", mediaURL);

        // Content more button
        const moreButtonImage = (this.state.moreOn === false)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/more-info-black.png") :
                    getStaticPath("/images/common/more-info-white.png")
            ) : (
               (this.props.colorMode === "day")?
                    getStaticPath("/images/common/less-info-black.png") :
                    getStaticPath("/images/common/less-info-white.png")
            );

        // Content more button class
        const moreButtonClass = (this.state.moreOn === false)?
            "create-connect-object-item-content-more" :
            "create-connect-object-item-content-less";

        // Add or remove button
        const addButton = this.props.selectedIDs.indexOf(this.props.object.id) === -1?
        (
            <div className = "create-connect-object-add-button image-button-s4"
                style = {{
                    backgroundImage: (this.props.colorMode === "day")?
                        getStaticPath("/images/common/add-black.png") :
                        getStaticPath("/images/common/add-white.png")
                }}
                onClick = {() => {this.props.addClick(this.props.object, this.props.updateSelectedIDs);}}
            >
            </div>
        ) : (
            <div className = "create-connect-object-remove-button image-button-s4"
                style = {{
                    backgroundImage: (this.props.colorMode === "day")?
                        getStaticPath("/images/common/remove-black.png") :
                        getStaticPath("/images/common/remove-white.png")
                }}
                onClick = {() => {this.props.removeClick(this.props.object, this.props.updateSelectedIDs);}}
            >
            </div>
        );

        // Editor image
        const editorImage = (this.props.object.editor.profile_pic)?
            (
                (this.props.object.editor.profile_pic.external_url === null)?
                    getMediaProperty(this.props.object.editor.profile_pic, "t", "url", true) :
                    url(this.props.object.editor.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        // Editor tooltip id
        const editorTooltipID = "create-connect-object-editor-tooltip-" + this.props.object.id.toString()

        // Return an individual item
        return (
            <div className = "create-connect-object-item-container"
                style = {
                    (this.props.selectedIDs.indexOf(this.props.object.id) === -1)?
                        null : { background: (this.props.colorMode === "day")?
                            window.colorCloseToWhite : window.colorCloserToBlack
                        }
                }
            >
                <div className = "create-connect-object-item-left">
                    <Dot
                        colorMode = {this.props.colorMode}
                        index = {this.props.objectIndex}
                        dotIndex = {this.props.objectIndex}
                        dot = {this.props.object}
                        selected = {false}
                        hovered = {false}
                        dotOpacity = {1.0}
                        dotIndexOn = {false}
                        dotIndexOpacity = {0.0}
                        mediaURL = {mediaURL}
                        options = {true}
                        dotClick = {null}
                        dotHoverOn = {null}
                        dotHoverOff = {null}
                        inItinerary = {false}
                    />
                </div>
                <div className = "create-connect-object-item-center">
                    <div ref = {this.contentRef}
                        id = {"create-connect-object-item-content" + this.props.objectIndex}
                        className = "create-connect-object-item-content"
                        style = {this.state.moreOn === false? {
                            maxHeight: this.maxContentHeight,
                            overflow: "hidden"
                        } : {}}
                    >
                        <div className = {(this.props.colorMode === "day")?
                             "create-connect-object-title k4" :
                             "create-connect-object-title w4"}
                        >
                            {this.props.object.title.replace(/[*]/g, "")}
                        </div>
                        <div className = "create-connect-object-overview text-small gray">
                            {this.props.object.overview.replace(/[*]/g, "")}
                        </div>
                    </div>
                    <div className = {moreButtonClass}
                        style = {{
                            display: (this.state.moreOn === null)? "none" : "block",
                            backgroundImage: moreButtonImage
                        }}
                        onClick = {() => {this.setState({moreOn: !this.state.moreOn});}}
                    >
                    </div>
                </div>
                <div className = "create-connect-object-item-right">
                    {addButton}
                    <div className = {(this.props.colorMode === "day")?
                             "create-connect-object-editor profile-image-s2 border-day" :
                             "create-connect-object-editor profile-image-s2 border-night"}
                        style = {{
                            backgroundImage: editorImage
                        }}
                        data-tip = {this.props.object.editor.name}
                        data-for = {editorTooltipID}
                    >
                    </div>
                    <ReactTooltip
                        id = {editorTooltipID}
                        className = {"create-connect-object-editor-tooltip"}
                        type = "dark"
                        place = "bottom"
                        html = {true}
                    />
                </div>
            </div>
        )
    }


    componentDidMount() {
        if ((this.contentRef.current.clientHeight > this.maxContentHeight) && (this.state.moreOn === null)) {
            this.setState({ moreOn: false });
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.object.id !== prevProps.object.id) {
            this.setState({ moreOn: null });
        }
        if ((this.contentRef.current.clientHeight > this.maxContentHeight) && (this.state.moreOn === null)) {
            this.setState({ moreOn: false });
        }
    }
}


function SelectedListTrip(props) {
    //console.log("SelectedListTrip - props = ", props);
    // For all dots in the list
    const selectedListTrip = props.selectedInfo.map((selected, selectedIndex) => {
        //console.log("SelectedListTrip - selectedIndex = ", selectedIndex);

        // Get mediaURL for the right size (medium size: "xs")
        const mediaURL = getMediaProperty(selected.media[0], "xs", 'url', true);
        //console.log("SearchResultItem - mediaURL = ", mediaURL);

        // Class name
        const highlightClassName = (props.highlightIDs.indexOf(selected.id) === -1)?
            (
                (props.colorMode === "day")?
                    "create-connect-selected-highlight-off-day font-cabin-medium" :
                    "create-connect-selected-highlight-off-night font-cabin-medium"
            ) : (
                (props.colorMode === "day")?
                    "create-connect-selected-highlight-on-day font-cabin-medium" :
                    "create-connect-selected-highlight-on-night font-cabin-medium"
            );

	    const highlightButton = (
            <div className = {highlightClassName}
            	onClick = {
            		() => {
            			const highlightIDs = props.highlightIDs;
            			const index = highlightIDs.indexOf(selected.id);
            			if (index === -1) {
            				highlightIDs.push(selected.id);
                			props.setState({
                				highlightIDs: highlightIDs,
                                highlightCount: props.highlightCount + 1,
                				connectWarningOn: false,
                				connectWarningOpacity: 0.0
                			});
            			}
            			else {
            				highlightIDs.splice(index, 1);
                			props.setState({
                				highlightIDs: highlightIDs,
                                highlightCount: props.highlightCount - 1
                			});
            			}
            		}
            	}
            >
            	HIGHLIGHT
            </div>
		);

        // Return an individual item
        return (
            <div key = {"create-connect-selected-item-container-" + selected.id.toString()}
            	className = "create-connect-selected-item-container"
            >
                <Dot
                    colorMode = {props.colorMode}
                    index = {selectedIndex}
                    dotIndex = {selectedIndex}
                    dot = {selected}
                    selected = {false}
                    hovered = {(props.hovered === selectedIndex)? true: false}
                    dotOpacity = {1.0}
                    dotIndexOn = {true}
                    dotIndexOpacity = {1.0}
                    mediaURL = {mediaURL}
                    options = {false}
                    dotClick = {null}
                    dotHoverOn = {() => {props.dotHoverOn(selectedIndex, selected, null, true);}}
                    dotHoverOff = {() => {props.dotHoverOff(selectedIndex, null, true);}}
                    inItinerary = {false}
                />

                {highlightButton}

            	<div className = "create-connect-selected-item-remove-button image-button-s4"
            		style = {{
	            		backgroundImage: getStaticPath("/images/common/remove-white-shadow.png")
            		}}
            		onClick = {() => {props.removeClick(selected, props.updateSelectedIDs);}}
            	>
            	</div>
           </div>
        )
    });

    const selectedListTitle = props.selectedInfo.length > 0? (
		<div id = "create-connect-selected-title"
             className = {(props.colorMode === "day")? "k4" : "w4"}
        >
			Selected Dots
		</div>
	) : null;

    const selectedListStats = props.selectedInfo.length > 0? (
		<div id = "create-connect-selected-stats"
             className = {(props.colorMode === "day")? "k4" : "w4"}
        >
            {props.selectedInfo.length.toString() + (props.selectedInfo.length === 1? " Item" : " Items") }
		</div>
	) : null;

    return (
    	<div id = "create-connect-selected-trip"
            className = {(props.colorMode === "day")?
                "create-connect-selected-trip-day" :
                "create-connect-selected-trip-night"}
        >
    		{selectedListTitle}
    		{selectedListTrip}
    		{selectedListStats}
    	</div>
    )
}


function SelectedListRoute(props) {
    //console.log("SelectedListRoute - props = ", props);

    // Number of selected dots
    const numDots = props.selectedInfo.length;
    const numTransits = numDots + 1;
    const numNodes = numDots + 2;

    // Construct index lists
    let nodeIndices = [];
    for (let i = 0; i < numNodes; i++) {
        nodeIndices.push(i);
    }
    let transitIndices = [];
    for (let i = 0; i < numTransits; i++) {
        transitIndices.push(i);
    }

    // Numbers column
    const numbers = (numDots > 0)? nodeIndices.map((nodeIndex) => {
        let number = null;
        if (nodeIndex === 0) {
            number = (
                <div
                    key = {"create-connect-selected-route-number-start"}
                    id = "create-connect-selected-route-number-start">
                </div>
            );
            /*
            const startIcon =  getFilePath("/images/numbers/single_black_S.png");
            number = (
                <div id = "create-connect-selected-route-number-start"
                    style = {{
                        backgroundImage: startIcon
                    }}
                >
                </div>
            );
            */
        }
        else if (nodeIndex === (numNodes - 1)) {
            /*
            const endIcon =  getFilePath("/images/numbers/single_black_E.png");
            number = (
                <div id = "create-connect-selected-route-number-end"
                    style = {{
                        backgroundImage: endIcon
                    }}
                >
                </div>
            );
            */
            number = (props.roundtrip)? null : (
                <div
                    key = {"create-connect-selected-route-number-end"}
                    id = "create-connect-selected-route-number-end">
                </div>
            );
        }
        else {
            // Get dot index display and icon
            const dotIndexIcon = (props.colorMode === "day")?
                getStaticPath("/images/number/single_red_" + nodeIndex + ".png") :
                getStaticPath("/images/number/single_red_" + nodeIndex + ".png");

            number = (
                <div
                    key = {"create-connect-selected-route-number-" + nodeIndex.toString()}
                    className = "create-connect-selected-route-number"
                    style = {{
                        backgroundImage: dotIndexIcon
                    }}
                >
                </div>
            );
        }

        return number;
    }) : null;

    // Dots column
    const dots = (numDots > 0)? nodeIndices.map((nodeIndex) => {
        let dot = null;
        if (nodeIndex === 0) {
            dot = (
                <div key = {"create-connect-selected-route-item-container-start"}
                    id = "create-connect-selected-route-item-container-start"
                >
                    <div id = "create-connect-selected-route-item-container-start-button"
                        className = {(props.colorMode === "day")?
                            "button-light-gray-s3" : "button-gray-s3"}
                    >
                        Start Location
                    </div>
                </div>
            );
        }
        else if (nodeIndex === (numNodes - 1)) {
            dot = (props.roundtrip)? null :
            (
                <div key = {"create-connect-selected-route-item-container-end"}
                    id = "create-connect-selected-route-item-container-end"
                >
                    <div id = "create-connect-selected-route-item-container-end-button"
                        className = {(props.colorMode === "day")?
                            "button-light-gray-s3" : "button-gray-s3"}
                    >
                        {(props.loop)? "Start Location" : "End Location"}
                    </div>
                </div>
            );
        }
        else {
            // Actual dot list index
            const dotIndex = nodeIndex - 1;

            // Get mediaURL for the right size (medium size: "xs")
            const mediaURL = getMediaProperty(props.selectedInfo[dotIndex].media[0], "xs", 'url', true);
            //console.log("SearchResultItem - mediaURL = ", mediaURL);

            // Class name
            const highlightClassName = (props.highlightIDs.indexOf(props.selectedInfo[dotIndex].id) === -1)?
                (
                    (props.colorMode === "day")?
                        "create-connect-selected-highlight-off-day font-cabin-medium" :
                        "create-connect-selected-highlight-off-night font-cabin-medium"
                ) : (
                    (props.colorMode === "day")?
                        "create-connect-selected-highlight-on-day font-cabin-medium" :
                        "create-connect-selected-highlight-on-night font-cabin-medium"
                );

            // Highlight button
            const highlightButton = (
                <div key = {"create-connect-selected-route-item-highlight-button-" + props.selectedInfo[dotIndex].id.toString()}
                    className = {highlightClassName}
                    onClick = {
                        () => {
                            const highlightIDs = props.highlightIDs;
                            const index = highlightIDs.indexOf(props.selectedInfo[dotIndex].id);
                            if (index === -1) {
                                highlightIDs.push(props.selectedInfo[dotIndex].id);
                                props.setState({
                                    highlightIDs: highlightIDs,
                                    highlightCount: props.highlightCount + 1,
                                    connectWarningOn: false,
                                    connectWarningOpacity: 0.0
                                });
                            }
                            else {
                                highlightIDs.splice(index, 1);
                                props.setState({
                                    highlightIDs: highlightIDs,
                                    highlightCount: props.highlightCount - 1
                                });
                            }
                        }
                    }
                >
                    HIGHLIGHT
                </div>
            );

            // Construct dot
            dot = (
                <div key = {"create-connect-selected-route-item-container-" + nodeIndex.toString()}
                    className = "create-connect-selected-route-item-container"
                >
                    <Dot
                        colorMode = {props.colorMode}
                        index = {dotIndex}
                        dotIndex = {dotIndex}
                        dot = {props.selectedInfo[dotIndex]}
                        selected = {false}
                        hovered = {(props.hovered === dotIndex)? true: false}
                        dotOpacity = {1.0}
                        dotIndexOn = {false}
                        dotIndexOpacity = {1.0}
                        mediaURL = {mediaURL}
                        options = {false}
                        dotClick = {null}
                        dotHoverOn = {() => {props.dotHoverOn(dotIndex, props.selectedInfo[dotIndex], null, true);}}
                        dotHoverOff = {() => {props.dotHoverOff(dotIndex, null, true);}}
                        inItinerary = {false}
                    />

                    {highlightButton}

                    <div className = "create-connect-selected-route-item-remove-button image-button-s4"
                        style = {{
                            backgroundImage: (props.colorMode === "day")?
                                getStaticPath("/images/common/remove-black.png") :
                                getStaticPath("/images/common/remove-white.png")
                        }}
                        onClick = {() => {props.removeClick(props.selectedInfo[dotIndex], props.updateSelectedIDs);}}
                    >
                    </div>
                </div>
            );
        }

        return dot;
    }) : null;

    // Lines
    const lines = (numDots > 0)? nodeIndices.map((nodeIndex) => {
        let line = null;
        const lineImage = (props.colorMode === "day")?
            getStaticPath("/images/create/line-middle-black.png") :
            getStaticPath("/images/create/line-middle-white.png");

        const lineImageStart = (props.colorMode === "day")?
            getStaticPath("/images/create/line-start-black.png") :
            getStaticPath("/images/create/line-start-white.png");

        const lineImageEnd = (props.colorMode === "day")?
            getStaticPath("/images/create/line-end-black.png") :
            getStaticPath("/images/create/line-end-white.png");

        if (nodeIndex === 0) {
            line = (
                <div key = "create-connect-selected-route-line-start"
                    id = "create-connect-selected-route-line-start"
                    style = {{ backgroundImage: lineImageStart }}
                >
                </div>
            );
        }
        else if (nodeIndex === (numNodes - 1)) {
            line = (props.roundtrip)? null :
            (
                <div key = "create-connect-selected-route-line-end"
                    id = "create-connect-selected-route-line-end"
                    style = {{ backgroundImage: lineImageEnd }}
                >
                </div>
            );
        }
        else {
            line = (
                <div key = {"create-connect-selected-route-line-" + nodeIndex.toString()}
                    className = "create-connect-selected-route-line"
                    style = {{
                        backgroundImage: ((props.roundtrip) && nodeIndex === (numNodes - 2))? lineImageEnd : lineImage
                    }}
                >
                </div>
            );
        }

        return line;
    }) : null;

    // Transits
    const transitIndicesTrimmed = (props.roundtrip)? transitIndices.slice(0, numTransits - 1) : transitIndices;

    const transits = (numDots > 0)? transitIndicesTrimmed.map((transitIndex) => {
        // Get transit image
        const transitMode = window.transitModes[props.transitModes[transitIndex]];
        const transitImage = getTransitImage(transitMode, props.colorMode, true);

        // Drop down menu
        // Construct drop down menu
        const updateItems = (transitModes) => {
            const mode = transitModes[transitIndex];
            const physicality = props.defaultPhysicalities[mode];
            const transitPhysicalities = props.transitPhysicalities.slice(0, transitIndex).concat([physicality], props.transitPhysicalities.slice(transitIndex + 1));
            //const distance = Math.round(props.transitDurations[transitIndex] * props.defaultSpeeds[mode] * 10) / 10;
            //const transitDistances = props.transitDistances.slice(0, transitIndex).concat([distance], props.transitDistances.slice(transitIndex + 1));
            const duration = Math.round(props.transitDistances[transitIndex] / props.defaultSpeeds[mode] * 10) / 10;
            const transitDurations = props.transitDurations.slice(0, transitIndex).concat([ duration ], props.transitDurations.slice(transitIndex + 1));

            let durationScaleIndex = null;
            for (let i = 0; i < props.durationScales.length; i++) {
                if ((props.durationScales[i] > duration) && (durationScaleIndex === null)) {
                    durationScaleIndex = i;
                }
            }
            durationScaleIndex = Math.min(durationScaleIndex, props.durationScales.length - 1);
            const durationScaleIndices = props.durationScaleIndices.slice();
            durationScaleIndices[transitIndex] = durationScaleIndex;

            props.setState(
                {
                    transitModes: transitModes,
                    transitPhysicalities: transitPhysicalities,
                    //transitDistances: transitDistances
                    transitDurations: transitDurations,
                    durationScaleIndices: durationScaleIndices
                }
            );
        };

        const dropDownMenuProps = {
            colorMode: props.colorMode,
            objectIndex: transitIndex,
            selectedItemIndex : props.transitModes[transitIndex],
            items: props.transitModes,
            itemLabels: [ "Drive", "Walk", "Bus", "Subway", "Train", "Flight", "Cruise", "Backpack", "Swim", "Crosscountry", "Downhill", "Climb", "Scramble", "Kayak" ],
            updateItems: updateItems,
            showSelectedLabel: false,
            width: 80,
            height: 40,
            top: -160,
            left: 54
        };

        const dropDownMenu = (
            <DropDownMenu {...dropDownMenuProps} />
        );

        const mode = (
            <div className = "create-connect-transit-mode">
                <div className = "create-connect-transit-mode-image image-contain"
                    style = {{ backgroundImage: transitImage }}
                >
                </div>
                {dropDownMenu}
                <div className = "create-connect-transit-summary">
                    <div className = {(props.colorMode === "day")?
                             "create-connect-transit-summary-duration k4" :
                             "create-connect-transit-summary-duration w4"}
                    >
                        {formatDuration(props.transitDurations[transitIndex], "long")}
                    </div>
                    <div className = {(props.colorMode === "day")?
                             "create-connect-transit-summary-distance k4" :
                             "create-connect-transit-summary-distance w4"}
                    >
                        {props.transitDistances[transitIndex].toFixed(1) + " miles"}
                    </div>
                </div>
            </div>
        );

        const duration = (
            <DurationSlider
                colorMode = {props.colorMode}
                sliderClassNames = {props.sliderClassNames}
                mode = {props.transitModes[transitIndex]}
                transitIndex = {transitIndex}
                duration = {props.transitDurations[transitIndex]}
                durationScales = {props.durationScales}
                durationScaleIndices = {props.durationScaleIndices}
                updateDurationScaleIndices = {props.updateDurationScaleIndices}
                updateTransitDurations = {props.updateTransitDurations}
                updateTransitDistances = {props.updateTransitDistances}
                defaultSpeeds = {props.defaultSpeeds}
            />
        );

        const physicality = (
            <div className = "create-connect-transit-physicality">
                <div className = {(props.colorMode === "day")?
                         "create-connect-transit-physicality-title k4" :
                         "create-connect-transit-physicality-title w4"}
                >
                    Physicality
                </div>
                <div className = "create-connect-transit-slider-physicality">
                    <InputRange
                        classNames = {props.sliderClassNames}
                        maxValue = {5}
                        minValue = {0}
                        value = {props.transitPhysicalities[transitIndex]}
                        onChange = {(physicality) => {props.updateTransitPhysicalities(transitIndex, physicality);}}
                        formatLabel = {(physicality) => {return props.physicalityLabels[Math.round(physicality / 0.5)];}}
                        showEndLabels = {false}
                        step = {0.5}
                    />
                </div>
            </div>
        );

        const distance = (
            <div className = "create-connect-transit-distance">
                <DistanceSlider
                    colorMode = {props.colorMode}
                    sliderClassNames = {props.sliderClassNames}
                    mode = {props.transitModes[transitIndex]}
                    transitIndex = {transitIndex}
                    distance = {props.transitDistances[transitIndex]}
                    distanceScales = {props.distanceScales}
                    distanceScaleIndices = {props.distanceScaleIndices}
                    updateDistanceScaleIndices = {props.updateDistanceScaleIndices}
                    updateTransitDurations = {props.updateTransitDurations}
                    updateTransitDistances = {props.updateTransitDistances}
                    defaultSpeeds = {props.defaultSpeeds}
                />
            </div>
        );

        return (
            <div key = {"create-connect-selected-route-transit-" + transitIndex.toString()}
                className = "create-connect-selected-route-transit"
            >
                {mode}
                <div className = "create-connect-transit-sliders">
                    {duration}
                    {physicality}
                    {distance}
                </div>
            </div>
        );
    }) : null;

    // Title
    const selectedListTitle = (numDots > 0)? (
        <div id = "create-connect-selected-title"
            className = {(props.colorMode === "day")? "k4" : "w4"}
        >
            Selected Dots
        </div>
    ) : null;

    // Stats
    const selectedListStats = (numDots > 0)? (
        <div id = "create-connect-selected-stats"
            className = {(props.colorMode === "day")? "k4" : "w4"}
        >
            {props.selectedInfo.length.toString() + (numDots === 1? " Item" : " Items") }
        </div>
    ) : null;

    return (numDots > 0)? (
        <div id = "create-connect-selected-route-container"
            className = {(props.colorMode === "day")?
                "create-connect-selected-route-container-day" :
                "create-connect-selected-route-container-night"}
        >
            {selectedListTitle}
            <div id = "create-connect-selected-route">
                <div id = "create-connect-selected-route-numbers">
                    {numbers}
                </div>
                <div id = "create-connect-selected-route-dots">
                    {dots}
                </div>
                <div id = "create-connect-selected-route-lines">
                    {lines}
                </div>
                <div id = "create-connect-selected-route-transits">
                    {transits}
                </div>
            </div>
            {selectedListStats}
        </div>
    ) : null;
}



class DurationSlider extends Component {
    render () {
        //console.log("DurationSlider / render - this.props.durationScales = ", this.props.durationScales);
        const sliderIncreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/increase-black.png") :
            getStaticPath("/images/common/increase-white.png");

        const sliderDecreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/decrease-black.png") :
            getStaticPath("/images/common/decrease-white.png");

        // Duration scale index
        const durationScaleIndex = this.props.durationScaleIndices[this.props.transitIndex];
        //console.log("DurationSlider / render - this.props.durationScaleIndices = ", this.props.durationScaleIndices);
        //console.log("DurationSlider / render - durationScaleIndex = ", durationScaleIndex);

        return (
            <div className = "create-connect-transit-duration">
                <div className = {(this.props.colorMode === "day")?
                         "create-connect-transit-duration-title k4" :
                         "create-connect-transit-duration-title w4"}
                >
                    Duration
                </div>
                <div className = "create-connect-transit-slider-duration">
                    <div className = "create-connect-transit-duration-increase"
                        style = {{
                            backgroundImage: sliderIncreaseImage,
                            display: (durationScaleIndex === (this.props.durationScales.length - 1))? "none" : "block"
                        }}
                        onClick = {
                            ()=> {
                                this.props.updateDurationScaleIndices(
                                    this.props.transitIndex,
                                    durationScaleIndex + 1
                                );
                            }
                        }
                    >
                    </div>
                    <div className = "create-connect-transit-duration-decrease"
                        style = {{
                            backgroundImage: sliderDecreaseImage,
                            display: (durationScaleIndex === 0)? "none" : "block"
                        }}
                        onClick = {
                            ()=> {
                                this.props.updateDurationScaleIndices(
                                    this.props.transitIndex,
                                    durationScaleIndex - 1
                                );

                                this.props.updateTransitDurations(
                                    this.props.transitIndex,
                                    Math.min(this.props.duration, this.props.durationScales[durationScaleIndex - 1])
                                );
                            }
                        }
                    >
                    </div>
                    <InputRange
                        classNames = {this.props.sliderClassNames}
                        maxValue = {this.props.durationScales[durationScaleIndex]}
                        minValue = {60}
                        value = {this.props.duration}
                        onChange = {
                            (duration) => {
                                this.props.updateTransitDurations(this.props.transitIndex, duration);
                                //const distance = Math.round(duration * this.props.defaultSpeeds[this.props.mode] * 10 ) / 10;
                                //this.props.updateTransitDistances(this.props.transitIndex, distance);
                            }
                        }
                        formatLabel = {(duration) => {return formatDuration(duration);}}
                        showEndLabels = {false}
                        step = {60}
                    />
                </div>
            </div>
        );
    }
}


class DistanceSlider extends Component {
    render () {
        const sliderIncreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/increase-black.png") :
            getStaticPath("/images/common/increase-white.png");

        const sliderDecreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/decrease-black.png") :
            getStaticPath("/images/common/decrease-white.png");

        // Duration scale index
        const distanceScaleIndex = this.props.distanceScaleIndices[this.props.transitIndex];

        return (
            <div className = "create-connect-transit-distance">
                <div className = "create-connect-transit-distance-title w4">
                    Distance
                </div>
                <div className = "create-connect-transit-slider-distance">
                    <div className = "create-connect-transit-distance-increase"
                        style = {{
                            backgroundImage: sliderIncreaseImage,
                            display: (distanceScaleIndex === (this.props.distanceScales.length - 1))? "none" : "block"
                        }}
                        onClick = {
                            () => {
                                this.props.updateDistanceScaleIndices(
                                    this.props.transitIndex,
                                    distanceScaleIndex + 1
                                );
                            }
                        }
                    >
                    </div>
                    <div className = "create-connect-transit-distance-decrease"
                        style = {{
                            backgroundImage: sliderDecreaseImage,
                            display: (distanceScaleIndex === 0)? "none" : "block"
                        }}
                        onClick = {
                            () => {
                                this.props.updateDistanceScaleIndices(
                                    this.props.transitIndex,
                                    distanceScaleIndex - 1
                                );

                                this.props.updateTransitDistances(
                                    this.props.transitIndex,
                                    Math.min(this.props.distance, this.props.distanceScales[distanceScaleIndex - 1])
                                );
                            }
                        }
                    >
                    </div>
                    <InputRange
                        classNames = {this.props.sliderClassNames}
                        maxValue = {this.props.distanceScales[distanceScaleIndex]}
                        minValue = {0}
                        value = {this.props.distance}
                        onChange = {
                            (distance) => {
                                const duration = Math.round(distance / this.props.defaultSpeeds[this.props.mode] * 10 ) / 10;
                                this.props.updateTransitDurations(this.props.transitIndex, duration);
                                this.props.updateTransitDistances(this.props.transitIndex, distance);
                            }
                        }
                        formatLabel = {(distance) => {return (distance.toFixed(1) + " miles");}}
                        showEndLabels = {false}
                        step = {0.1}
                    />
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.mode !== prevProps.mode) {
            let distanceScaleIndex = null;
            for (let i = 0; i < this.props.distanceScales.length; i++) {
                if ((this.props.distanceScales[i] > this.props.distance) && (distanceScaleIndex === null)) {
                    distanceScaleIndex = i;
                }
            }

            this.props.updateDistanceScaleIndices(
                this.props.transitIndex,
                distanceScaleIndex
            );
        }
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode
    };
}

export default connect(mapStateToProps, null)(CreateConnect);