/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Gallery.js
    - General gallery for curations
--------------------------------------------------------------------------------------------
    Content
    - Gallery

    Video
    - startPlaying
        true: start video right after mount
    - checkScroll
        true: check scroll
        false: don't check scroll
        null || false -> true: check in viewPort
        null: stop all playback
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    storeUser,
    storeDotTag,
    storeUserTag,
    storeMemo,
    storeGallery    
} from "actions";

// Modules
import ReactPlayer from "react-player";

// Functions
import { unsaveClick } from "components/User";
import {
    getStaticPath,
    getMediaProperty,
    url
} from "js/Functions";


// Gallery
class UnconnectedGallery extends Component {
    constructor(props) {
        super(props);

        // Ref
        this.galleryRef = React.createRef();

        // Gallery container node
        //this.galleryContainerNodeID = this.props.idPrefix + "-gallery-container-" + this.props.id;
        //this.galleryContainerNode = null;

        // Initialize state
        this.state = {
            inViewport: this.props.startPlaying
        };

        // Bind setState function
        this.setState = this.setState.bind(this);
        this.setUserTagClick = this.setUserTagClick.bind(this);
        this.setDotTagClick = this.setDotTagClick.bind(this);
        this.setMemoClick = this.setMemoClick.bind(this);
        this.unsaveClick = unsaveClick.bind(this);
    }

    render() {
        //console.log("Gallery / render - this.props = ", this.props);

        // Is video
        const isVideo = this.props.media[this.props.selectedMediaIndex].type === "video";

        /*
        ============================================================================================
            Default images
        ============================================================================================
        */
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette.png");

        const nextArrowImage = getStaticPath("/images/common/arrow-right-white.png");
        const prevArrowImage = getStaticPath("/images/common/arrow-left-white.png");
        const navDotImage = getStaticPath("/images/common/image-dot-white.png");


        /*
        ============================================================================================
            Media
        ============================================================================================
        */
        // Media URL
        const mediaURL = getMediaProperty(this.props.media[this.props.selectedMediaIndex], this.props.size, "url", false);


        /*
        ============================================================================================
            Navigation arrows
        ============================================================================================
        */

        // Navigation arrows
        const nextArrow = this.props.selectedMediaIndex < (this.props.media.length - 1)?
        (
            <div id = {this.props.idPrefix + "-gallery-next-arrow-" + this.props.id}
                className = {this.props.classPrefix + "-gallery-next-arrow"}
                style = {{ backgroundImage: nextArrowImage }}
                onClick = {
                    (event) => {
                        this.props.nextMediaClick(event, this.props.index);
                    }
                }
            >
            </div>
        ) : null;

        const prevArrow = this.props.selectedMediaIndex > 0?
        (
            <div id = {this.props.idPrefix + "-gallery-prev-arrow-" + this.props.id}
                className = {this.props.classPrefix + "-gallery-prev-arrow"}
                style = {{ backgroundImage: prevArrowImage }}
                onClick = {
                    (event) => {
                        this.props.prevMediaClick.bind(event, this.props.index);
                    }
                }
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            User Tag / Dot Tag / Memo / Unsave
        ============================================================================================
        */

        // Set user tag image
        const setUserTagImage = getStaticPath("/images/common/user-tag-white.png");

        // Set user tag button
        const setUserTagButton = (this.props.userTagOn)? (
            <div id = {this.props.idPrefix + "-gallery-user-tag-" + this.props.id}
                className = {
                    (isVideo)?
                        this.props.classPrefix + "-gallery-user-tag-video image-button-s3" :
                        this.props.classPrefix + "-gallery-user-tag-image image-button-s3"
                }
                style = {{ backgroundImage: setUserTagImage }}
                onClick = {
                    (event) => {
                        this.setUserTagClick(event, this.props.dotInfo.id);
                    }
                }
            >
            </div>
        ) : null;

        // Set dot tag image
        const setDotTagImage = getStaticPath("/images/common/tag-white.png");

        // Set dot tag button
        const setDotTagButton = (this.props.dotTagOn)? (
            <div id = {this.props.idPrefix + "-gallery-dot-tag-" + this.props.id}
                className = {
                    (isVideo)?
                        this.props.classPrefix + "-gallery-dot-tag-video image-button-s3" :
                        this.props.classPrefix + "-gallery-dot-tag-image image-button-s3"
                }
                style = {{ backgroundImage: setDotTagImage }}
                onClick = {
                    (event) => {
                        this.setDotTagClick(event, this.props.dotInfo.id);
                    }
                }
            >
            </div>
        ) : null;

        // Set memo image
        const setMemoImage = getStaticPath("/images/common/memo-white.png");

        // Set user tag button
        const setMemoButton = (this.props.memoOn)? (
            <div id = {this.props.idPrefix + "-gallery-memo-" + this.props.id}
                className = {
                    (isVideo)?
                        this.props.classPrefix + "-gallery-memo-video image-button-s3" :
                        this.props.classPrefix + "-gallery-memo-image image-button-s3"
                }
                style = {{ backgroundImage: setMemoImage }}
                onClick = {
                    (event) => {
                        this.setMemoClick(event, this.props.dotInfo.id);
                    }
                }
            >
            </div>
        ) : null;

        // Unsave image
        const unsaveImage = getStaticPath("/images/user/bucket-remove-white.png");

        // Unsave button
        const unsaveButton = (this.props.unsaveOn)? (
            <div id = {this.props.idPrefix + "-gallery-unsave-" + this.props.id}
                className = {
                    (isVideo)?
                        this.props.classPrefix + "-gallery-unsave-video image-button-s3" :
                        this.props.classPrefix + "-gallery-unsave-image image-button-s3"
                }
                style = {{ backgroundImage: unsaveImage }}
                onClick = {
                    (event) => {this.unsaveClick(event, this.props.dotInfo);
                    }
                }
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            Media enlarge button
        ============================================================================================
        */

        const enlargeButtonImage = getStaticPath("/images/common/enlarge-white.png");
        const enlargeButton = (this.props.enlargeOn)? (
            <div id = {this.props.idPrefix + "-gallery-enlarge-" + this.props.id}
                className = {
                    (isVideo)?
                        this.props.classPrefix + "-gallery-enlarge-video image-button-s3" :
                        this.props.classPrefix + "-gallery-enlarge-image image-button-s3"
                }
                style = {{ backgroundImage: enlargeButtonImage }}
                onClick = {
                    (event) => {
                        // Stop propagation
                        event.stopPropagation();

                        // Open gallery modal
                        this.props.storeGallery({
                            modalOn: true,
                            info: this.props.dotInfo
                        });
                    }
                }
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            Navigation Dots
        ============================================================================================
        */

        // Navigation dots
        let navDots = null;

        if (this.props.media.length > 1) {
            const dots = this.props.media.map((media, index) => {
                // If selected
                const navDotClass = (this.props.selectedMediaIndex === index)?
                    (this.props.classPrefix + "-gallery-dot-on") :
                    (this.props.classPrefix + "-gallery-dot-off");

                return(
                    <div key = {this.props.classPrefix + "-gallery-" + this.props.id + "-dot-" + index.toString()}
                        className = {navDotClass}
                        style = {{ backgroundImage: navDotImage }}
                        onClick = {
                            (event) => {
                                this.props.navDotClick(event, index, this.props.index);
                            }
                        }
                    >
                    </div>
                );
            })

            navDots = (
                <div id = {this.props.idPrefix + "-gallery-dots-" + this.props.id}
                    className = {this.props.classPrefix + "-gallery-dots"}
                >
                    {dots}
                </div>
            );
        }


        /*
        ============================================================================================
            Media
        ============================================================================================
        */
        //console.log("Gallery / render - this.state.inViewport = ", this.state.inViewport);

        const media = (isVideo)? (
            <div id = {this.props.idPrefix + "-gallery-wrapper-" + this.props.id}
                className = {
                    (this.props.square)? (
                        (this.props.colorMode === "day")?
                            this.props.classPrefix + "-gallery-wrapper-square content-box image-loader-s2" :
                            this.props.classPrefix + "-gallery-wrapper-square content-box image-loader-s2"
                    ) : (
                        (this.props.colorMode === "day")?
                            this.props.classPrefix + "-gallery-wrapper content-box border-day image-loader-s2" :
                            this.props.classPrefix + "-gallery-wrapper content-box border-night image-loader-s2"
                    )
                }
                style = {this.props.mediaWrapperStyle}
            >
                <ReactPlayer id = {this.props.idPrefix + "-gallery-video-" + this.props.id}
                    className = {this.props.classPrefix + "-gallery-video"}
                    url = {mediaURL}
                    width = "100%"
                    height = "100%"
                    volume = {0.05}
                    playing = {this.state.inViewport}
                    controls
                    playsinline
                    muted = {this.props.muted}
                />
                {setUserTagButton}
                {setDotTagButton}
                {setMemoButton}
                {unsaveButton}
                {enlargeButton}
                {nextArrow}
                {prevArrow}
                <div id = {this.props.idPrefix + "-gallery-shadow-" + this.props.id}
                    className = {this.props.classPrefix + "-gallery-shadow image-cover"}
                    style = {{ backgroundImage: shadowImage }}
                >
                </div>
            </div>
        ) : (
            <div id = {this.props.idPrefix + "-gallery-wrapper-" + this.props.id}
                className = {
                    (this.props.square)? (
                        (this.props.colorMode === "day")?
                            this.props.classPrefix + "-gallery-wrapper-square content-box image-loader-s2" :
                            this.props.classPrefix + "-gallery-wrapper-square content-box image-loader-s2"
                    ) : (
                        (this.props.colorMode === "day")?
                            this.props.classPrefix + "-gallery-wrapper content-box border-day image-loader-s2" :
                            this.props.classPrefix + "-gallery-wrapper content-box border-night image-loader-s2"
                    )
                }
                style = {this.props.mediaWrapperStyle}
            >
                <div id = {this.props.idPrefix + "-gallery-image-" + this.props.id}
                    className = {this.props.classPrefix + "-gallery-image image-gallery"}
                    style = {{ backgroundImage: url(mediaURL) }}
                >
                </div>
                {setUserTagButton}
                {setDotTagButton}
                {setMemoButton}
                {unsaveButton}
                {enlargeButton}
                {nextArrow}
                {prevArrow}
                <div id = {this.props.idPrefix + "-gallery-shadow-" + this.props.id}
                    className = {this.props.classPrefix + "-gallery-shadow image-cover"}
                    style = {{ backgroundImage: shadowImage }}
                >
                </div>
            </div>
        );


        /*
        ============================================================================================
            JSX
        ============================================================================================
        */

        return (
            <div ref = {this.galleryRef}
                id = {this.props.idPrefix + "-gallery-container-" + this.props.id}
                className = {this.props.classPrefix + "-gallery-container"}
                style = {{
                    width: this.props.containerWidth
                }}
            >
                {navDots}
                {media}
            </div>
        );
    }


    componentDidMount() {
        // Grab DOM node
        //this.galleryContainerNode = document.getElementById(this.galleryContainerNodeID);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("Gallery / componentDidUpdate - prevProps.checkScroll = ", prevProps.checkScroll);
        //console.log("Gallery / componentDidUpdate - this.props.checkScroll = ", this.props.checkScroll);

        if ((prevProps.checkScroll === false || prevProps.checkScroll === null) && (this.props.checkScroll === true)) {
            //console.log("Gallery / componentDidUpdate");
            //console.log("Gallery / componentDidUpdate - turn on play");

            //if (this.galleryContainerNode !== null){
            if (this.galleryRef.current !== null) {
                const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                // Judge if this is within viewport
                const galleryBoundingBox = this.galleryRef.current.getBoundingClientRect();
                //const galleryBoundingBox = this.galleryContainerNode.getBoundingClientRect();
                const inViewport = (
                    (galleryBoundingBox.top >= 0)
                    &&
                    (galleryBoundingBox.bottom <= viewportHeight)
                );

                if (inViewport !== this.state.inViewport) {
                    this.setState({
                        inViewport: inViewport
                    });
                }
            }
        }
        else if (prevProps.checkScroll !== null && this.props.checkScroll === null) {
            //console.log("Gallery / componentDidUpdate");
            //console.log("Gallery / componentDidUpdate - turn off play");

            this.setState({
                inViewport: false
            });
        }
    }


    // Set user tag click
    setUserTagClick(event, dotID) {
        //console.log("Timeline / render / setUserTagClick - event = ", event);
        //console.log("Timeline / render / setUserTagClick - dotID = ", dotID);

        // Prevent default and stop event propagation to parent
        event.preventDefault();
        event.stopPropagation();

        // Display user tag modal
        this.props.storeUserTag(
            {
                modalOn: true,
                mode: this.props.tagMode,
                id: this.props.dotInfo.id,
                dot: this.props.dotInfo,
                saved: this.props.saved
            }
        );
    }

    // Set dot tag click
    setDotTagClick(event, dotID) {
        //console.log("Gallery / render / setDotTagClick - event = ", event);
        //console.log("Gallery / render / setDotTagClick - dotID = ", dotID);

        // Prevent default and stop event propagation to parent
        event.preventDefault();
        event.stopPropagation();

        // Display dot tag modal
        this.props.storeDotTag(
            {
                modalOn: true,
                type: "tag",
                mode: this.props.tagMode,
                id: this.props.dotInfo.id,
                dot: this.props.dotInfo,
                tags: this.props.dotInfo.dot_tags
            }
        );
    }

    // Set dot tag click
    setMemoClick(event, dotID) {
        //console.log("Gallery / render / setMemoClick - event = ", event);
        //console.log("Gallery / render / setMemoClick - dotID = ", dotID);

        // Prevent default and stop event propagation to parent
        event.preventDefault();
        event.stopPropagation();

        // Display dot tag modal
        this.props.storeMemo(
            {
                modalOn: true,
                mode: this.props.tagMode,
                id: this.props.dotInfo.interaction_id,
                dot: this.props.dotInfo,
                memo: this.props.dotInfo.memo
            }
        );
    }
}


function mediaDimensions(props) {

    /*
    ============================================================================================
        - effectiveBrowserWidth
        - isVideo
        - mediaArea
        - mediaWidth
        - mediaHeight
        - minAspectRatio
        - maxAspectRatio
        - minContentWidth
        - scaleContentWidth
        - maxContentWidth (null)
    ============================================================================================
    */
    /*
    console.log("===================================================================");
    console.log("Gallery / mediaDimensions - effectiveBrowserWidth = ", props.effectiveBrowserWidth);
    console.log("Gallery / mediaDimensions - minContentWidth = ", props.minContentWidth);
    console.log("Gallery / mediaDimensions - scaleContentWidth = ", props.scaleContentWidth);
    console.log("Gallery / mediaDimensions - maxContentWidth = ", props.maxContentWidth);
    */

    // Final width and height
    let finalMediaWidth = null;
    let finalMediaHeight = null;
    let resizedMediaWidth = null;
    let resizedMediaHeight = null;
    let adjustedMediaWidth = null;
    let adjustedMediaHeight = null;

    // If video
    if (props.isVideo) {
        /*
        ============================================================================================
            Video - Step 1 : Resize to keep constant area
        ============================================================================================
        */

        // Resize media
        const resizeRatio = Math.sqrt(props.mediaArea / (props.mediaWidth * props.mediaHeight));
        resizedMediaWidth = Math.round(props.mediaWidth * resizeRatio);
        resizedMediaHeight = Math.round(props.mediaHeight * resizeRatio);

        //console.log("Gallery / mediaDimensions - resizeRatio = ", resizeRatio);
        //console.log("Gallery / mediaDimensions - resizedMediaWidth = ", resizedMediaWidth);
        //console.log("Gallery / mediaDimensions - resizedMediaHeight = ", resizedMediaHeight);

        /*
        ============================================================================================
            Video - Step 2 : Linear Scaling
        ============================================================================================
        */

        // Choose smaller between resized media width and effective browser width
        const lessorWidth = Math.min(resizedMediaWidth, props.effectiveBrowserWidth)

        // Apply upper bound
        const upperBoundingWidth = (props.maxContentWidth !== null)?
            Math.min(lessorWidth, props.maxContentWidth) : lessorWidth;

        // Apply lower bound
        const lowerBoundingWidth = Math.max(upperBoundingWidth, props.minContentWidth);

        // Scale ratio
        const scaleRatio = lowerBoundingWidth / resizedMediaWidth;

        // Final width and height
        finalMediaWidth = lowerBoundingWidth;
        finalMediaHeight = resizedMediaHeight * scaleRatio;

        //console.log("Gallery / mediaDimensions - scaleRatio = ", scaleRatio);
    }
    else {
        /*
        ============================================================================================
            Image - Step 1 : Contrain Aspect Ratio
        ============================================================================================
        */

        // Aspect ratio adjusted width and height
        let aspectRatio = props.mediaWidth / props.mediaHeight;
        adjustedMediaWidth = props.mediaWidth;
        adjustedMediaHeight = props.mediaHeight;

        //console.log("Gallery / mediaDimensions - aspectRatio = ", aspectRatio);

        // If the media is vertically long
        if (aspectRatio < props.minAspectRatio) {
            //console.log("Gallery / mediaDimensions - too small aspect ratio");

            aspectRatio = props.minAspectRatio;
            adjustedMediaHeight = adjustedMediaWidth / aspectRatio;
        }
        else if (aspectRatio > props.maxAspectRatio) {
            //console.log("Gallery / mediaDimensions - too large aspect ratio");

            aspectRatio = props.maxAspectRatio;
            adjustedMediaWidth = adjustedMediaHeight * aspectRatio;
        }
        else if (aspectRatio >= props.minAspectRatio && aspectRatio <= props.maxAspectRatio) {
            //console.log("Gallery / mediaDimensions - normal aspect ratio");
        }
        else {
            console.log("[WARNING] Gallery / mediaDimensions - unexpected case");
        }

        //console.log("Gallery / mediaDimensions - adjustedMediaWidth = ", adjustedMediaWidth);
        //console.log("Gallery / mediaDimensions - adjustedMediaHeight = ", adjustedMediaHeight);


        /*
        ============================================================================================
            Image - Step 2 : Resize to keep constant area
        ============================================================================================
        */

        // Resize media
        const resizeRatio = Math.sqrt(props.mediaArea / (adjustedMediaWidth * adjustedMediaHeight));
        resizedMediaWidth = Math.round(adjustedMediaWidth * resizeRatio);
        resizedMediaHeight = Math.round(adjustedMediaHeight * resizeRatio);

        //console.log("Gallery / mediaDimensions - resizeRatio = ", resizeRatio);
        //console.log("Gallery / mediaDimensions - resizedMediaWidth = ", resizedMediaWidth);
        //console.log("Gallery / mediaDimensions - resizedMediaHeight = ", resizedMediaHeight);


        /*
        ============================================================================================
            Image - Step 3 : Constant height until the min content width
            Image - Step 4 : Linear Scale as further constrained by the browser width
        ============================================================================================
        */

        // Choose smaller between resized media width and effective browser width
        const lessorWidth = Math.min(resizedMediaWidth, props.effectiveBrowserWidth)

        // If below scale width
        if (lessorWidth < props.scaleContentWidth) {
            // Apply lower bound
            const lowerBoundingWidth = Math.max(lessorWidth, props.minContentWidth);

            // Scale ratio
            const scaleRatio = lowerBoundingWidth / Math.min(resizedMediaWidth, props.scaleContentWidth);

            // Final width and height
            finalMediaWidth = lowerBoundingWidth;
            finalMediaHeight = resizedMediaHeight * scaleRatio;
        }
        else {
            // Apply upper bound
            const upperBoundingWidth = (props.maxContentWidth !== null)?
                Math.min(lessorWidth, props.maxContentWidth) : lessorWidth;

            // Final width and height
            finalMediaWidth = upperBoundingWidth;
            finalMediaHeight = resizedMediaHeight;
        }
    }

    // Media style
    const mediaWrapperStyle = {
        width: finalMediaWidth,
        height: finalMediaHeight,
        backgroundImage: (props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif")
    };

    return {
        mediaWrapperStyle: mediaWrapperStyle,
        finalMediaWidth: finalMediaWidth,
        finalMediaHeight: finalMediaHeight,
        resizedMediaWidth: resizedMediaWidth,
        resizedMediaHeight: resizedMediaHeight,
        adjustedMediaWidth: adjustedMediaWidth,
        adjustedMediaHeight: adjustedMediaHeight
    };
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeDotTag,
            storeUserTag,
            storeMemo,
            storeGallery
        },
        dispatch
    );
}


const Gallery = connect(mapStateToProps, mapDispatchToProps)(UnconnectedGallery);

export {
    Gallery,
    mediaDimensions
};
