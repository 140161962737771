import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Functions
import { getStaticPath } from "js/Functions";

// Redux
import { storeWarningAlert } from "actions";

// CSS
import "./WarningAlert.scss";


class WarningAlert extends Component {
    constructor(props) {
        super(props);

        this.clearAlert = this.clearAlert.bind(this);
    }

    clearAlert() {
        this.props.storeWarningAlert({ message: null, on: false });
    }

    render() {
        if (this.props.warningAlertInfo.on) {
            return (
                <div className = {(this.props.colorMode === "day")?
                    "alert-warning alert-warning-day" :
                    "alert-warning alert-warning-night"}
                >
                    <div className = "alert-warning__title">
                        <span className = {(this.props.colorMode === "day")?
                            "w2" : "w2"}>
                            Warning
                        </span>
                    </div>
                    <div className = "alert-warning__message">
                        <span className = {(this.props.colorMode === "day")?
                            "w4" : "w4"}>
                            {this.props.warningAlertInfo.message}
                        </span>
                    </div>
                    <div className = "alert-warning__cancel">
                        <div className = "alert-warning__cancel-image image-button-weak-s7"
                            style = {{ backgroundImage:
                                (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/cross-white.png") :
                                    getStaticPath("/images/common/cross-white.png")
                            }}
                            onClick = { this.clearAlert }
                        >
                        </div>
                    </div>
                </div> 
            );
        } 
        else {
            return null;
        }        
    }
}

function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        warningAlertInfo: state.alert.warningAlertInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeWarningAlert }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(WarningAlert); 