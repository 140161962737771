/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    OpenMap.js
    - Open Street Map Based Components and Functions
--------------------------------------------------------------------------------------------
    Content
    - OpenMap
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";

// Modules
import polyline from "@mapbox/polyline";

// Map and open layers
import "ol/ol.css";
import { Map } from "ol";
import { 
    //defaults as defaultControls,
    Control
} from "ol/control";

// View
import { View } from "ol";

// Projection
import * as projection from "ol/proj";
import { fromLonLat } from "ol/proj";

// Open street map and bing map tiles
import { OSM } from "ol/source";
import { BingMaps } from "ol/source";

// Tile and tile grid
import { Tile } from "ol/layer";
import { TileJSON } from "ol/source";
import { XYZ } from "ol/source";

// Geometry
//import * as geom from "ol/geom";
import { Point } from "ol/geom";
//import { Circle } from "ol/geom";

// Vector layer and source
import { Vector as VectorSource} from "ol/source";
import { Vector as VectorLayer} from "ol/layer";

// Overlay and Feature
import { Overlay } from "ol";
import { Feature } from "ol";

// Geo json
import { GeoJSON } from "ol/format";

// Style
import { Style } from "ol/style";
import { Icon as IconStyle } from "ol/style";
import { Circle as CircleStyle } from "ol/style";
import { Fill as FillStyle } from "ol/style";
import { Stroke as StrokeStyle } from "ol/style";
//import { Text as TextStyle } from "ol/style";
//import { RegularShape as RegularShapeStyle } from "ol/style";

// Vector tiles from mapbox
//import MVT from "ol/format/MVT";
//import VectorTileLayer from "ol/layer/VectorTile";
//import VectorTileSource from "ol/source/VectorTile";

// Open layers mapbox styles
//import { register } from "ol/proj/proj4";
//import proj4 from "proj4";
//import olms from 'ol-mapbox-style';
import apply from 'ol-mapbox-style';
import FullScreen from 'ol/control/FullScreen';
//import { defaultResolutions } from 'ol-mapbox-style/dist/util';
//import { applyBackground, applyStyle } from "ol-mapbox-style";
//import stylefunction from 'ol-mapbox-style/dist/stylefunction';

// Functions
import {
    getStaticPath,
    arraysOrderEqual
} from "js/Functions";

import {
    latLngToPoint
} from "components/Map";

// CSS
import "./OpenMap.css";


class OpenMap extends Component {
    constructor(props) {
        super(props);

        // Use cloud tiles
        this.cloudTiles = false;

        // Zoom in level for "overview" or "itinerary" mode
        this.zoomInLevel = 16;

        // Zoom limits for cloud tiles
        this.maxZoomCloudDict = {
            "roadmap": 18,
            "terrain": 18,
            "hybrid":  18,
            "satellite": 18
        };

        // Zoom limits for self-hosted tiles
        this.maxZoomDict = {
            "roadmap": 18,
            "terrain": 18,
            "hybrid": 13,
            "satellite": 13
        };

        // Handles to store map related objects
        this.openMap = null;
        this.markersLayer = null;
        this.markersSource = null;
        this.parkingMarkerLayer = null;
        this.endMarkersLayer = null;
        this.childMarkersLayer = null;
        this.childMarkersSource = null;
        this.pathSource = null;
        this.pathLayer = null;

        // Map click action
        this.mapClickListenerKey = null;

        // Marker action listener keys
        this.markerHoverListenerKey = null;
        this.markerClickListenerKey = null;

        // Marker information window
        this.markerInformationOverlay = null;

        // Marker hover shade
        this.markerHoverOverlay = null;

        // Marker pulse animation
        this.markerPulseOverlay = null;
        this.markerPulseListenerKey = null;
        this.markerPulsePeriod = 3600;

        // Initialize state
        this.state = {
            // Being hovered
            hovered: false,

            // Map height
            mapHeight: props.mapHeight,
            mapMinHeight: props.mapMinHeight,
            mapMaxHeight: props.mapMaxHeight,
            mapHeightIncrement: props.mapHeightIncrement,

            // Markers
            markers: null,
            parkingMarker: null,
            startMarker: null,
            endMarker: null,
            childMarkers: null
        };

        // Initialize map
        this.initializeMap = this.initializeMap.bind(this);
        this.initializeLayout = this.initializeLayout.bind(this);

        // Bind marker functions
        this.setMarkers = this.setMarkers.bind(this);
        this.setParkingMarker = this.setParkingMarker.bind(this);
        this.setEndMarkers = this.setEndMarkers.bind(this);
        this.createMarker = this.createMarker.bind(this);
        this.removeMarker = this.removeMarker.bind(this);
        this.removeMarkers = this.removeMarkers.bind(this);
        this.removeParkingMarker = this.removeParkingMarker.bind(this);
        this.removeStartMarker = this.removeStartMarker.bind(this);
        this.removeEndMarker = this.removeEndMarker.bind(this);
        this.removeEndMarkers = this.removeEndMarkers.bind(this);
        this.removeChildMarkers = this.removeChildMarkers.bind(this);

        // Mouse action listeners
        this.setHoverListener = this.setHoverListener.bind(this);
        this.setMapClickListener = this.setMapClickListener.bind(this);
        this.setMarkerClickListener = this.setMarkerClickListener.bind(this);

        // Mouse action state updaters
        this.hoverOn = this.hoverOn.bind(this);
        this.hoverOff = this.hoverOff.bind(this);        
        this.clickOn = this.clickOn.bind(this);
        this.clickOff = this.clickOff.bind(this);

        // Marker hover
        this.setMarkerHover = this.setMarkerHover.bind(this);
        this.markerHoverOn = this.markerHoverOn.bind(this);

        // Marker information
        this.setMarkerInformation = this.setMarkerInformation.bind(this);
        this.markerInformationOn = this.markerInformationOn.bind(this);
        this.markerInformationOff = this.markerInformationOff.bind(this);

        // Marker pulse
        this.setMarkerPulse = this.setMarkerPulse.bind(this);
        this.markerPulseOn = this.markerPulseOn.bind(this);

        // Bind map height functions
        this.addMapHeightButtons = this.addMapHeightButtons.bind(this);
        this.mapHeightIncrease = this.mapHeightIncrease.bind(this);
        this.mapHeightDecrease = this.mapHeightDecrease.bind(this);

        // Zoom functions
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomToFit = this.zoomToFit.bind(this);
        this.zoomToFitChildren = this.zoomToFitChildren.bind(this);
        this.getZoom = this.getZoom.bind(this);

        // Start and end location input listeners
        this.setLocationInputListeners = this.setLocationInputListeners.bind(this);

        // Path related functions
        this.addPath = this.addPath.bind(this);
        this.removePath = this.removePath.bind(this);
        this.zoomToFitPath = this.zoomToFitPath.bind(this);
        this.getFeatureStyle = this.getFeatureStyle.bind(this);
    }

    render() {
        // Marker hover classes
        let markerHoverClassName = null;
        if ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create")) {
            markerHoverClassName = "open-map-marker-hover-black";
        }
        else if (this.props.mapMode === "trip-overview") {
            markerHoverClassName = "open-map-marker-hover-black";
        }
        else if (this.props.mapMode === "itinerary") {
            markerHoverClassName = "open-map-marker-hover-red";
            if (this.props.hovered !== null) {
                markerHoverClassName = "open-map-marker-hover-red";
            }
            if (this.props.hoveredChild !== null) {
                markerHoverClassName = "open-map-marker-hover-black";
            }
        }
        const tempMarkerhoverClassName = "open-map-temp-marker-black";

        // Marker pulse classes
        let markerPulseClassName = null;
        if ((this.props.mapMode === "dots-home") || (this.props.mapMode === "create")) {
            markerPulseClassName = "open-map-marker-pulse-black";
        }
        else if (this.props.mapMode === "trip-overview") {
            markerPulseClassName = "open-map-marker-pulse-black";
        }
        else if (this.props.mapMode === "itinerary") {
            markerPulseClassName = "open-map-marker-pulse-red";
            if (this.props.selected !== null) {
                markerPulseClassName = "open-map-marker-pulse-red";
            }
            if (this.props.selectedChild !== null) {
                markerPulseClassName = "open-map-marker-pulse-black";
            }
        }
        const tempMarkerPulseClassName = "open-map-temp-marker-black";


        // Figure out to display marker information
        /*
        let markerInformationOn = false;

        if (this.state.markers !== null) {
            if (this.props.displayChildren === null) {
                markerInformationOn = (this.props.hovered !== null)? true : false;
            }
            else {
                markerInformationOn = (this.props.hovered !== null || this.props.hoveredChild !== null)? true : false;
            }
        }
        */

        // Marker information
        const markerInformation = (this.props.mapMode === "create")? null : (
            <div id = {this.props.mapNodeID + "-marker-information-container"}
                className = "open-map-marker-information-container"
                style = {{
                    display: ((((this.props.hovered !== null) && (this.props.hovered !== this.props.selected))
                        || ((this.props.hoveredChild !== null) && (this.props.hoveredChild !== this.props.selectedChild))) 
                        && (this.state.markers !== null))?
                    "block" : "none"
                }}
            >
                <div id = {this.props.mapNodeID + "-marker-information"}
                    className = "open-map-marker-information"
                    style = {{ zIndex: 106 }}
                >
                    <div 
                        id = {this.props.mapNodeID + "-marker-information-name"}
                        className = "open-map-marker-information-name"
                    >
                    </div>
                    <div className = "open-map-marker-information-factors">
                        <div 
                            id = {this.props.mapNodeID + "-marker-information-rating-container"}
                            className = "open-map-marker-information-rating-container"
                        >
                            <div 
                                id = {this.props.mapNodeID + "-marker-information-rating"}
                                className = "open-map-marker-information-rating"
                            >
                            </div>
                        </div>
                        <div 
                            id = {this.props.mapNodeID + "-marker-information-difficulty-container"}
                            className = "open-map-marker-information-difficulty-container"
                        >
                            <div 
                                id = {this.props.mapNodeID + "-marker-information-difficulty"}
                                className = "open-map-marker-information-difficulty"
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        // Figure out if the hovered and selected are in the itinerary
        const hoveredInItinerary = (this.props.itinerary.indexOf(this.props.hovered) >= 0);
        const selectedInItinerary = (this.props.itinerary.indexOf(this.props.selected) >= 0);
        //console.log("OpenMap / render - hoveredInItinerary = ", hoveredInItinerary);
        //console.log("OpenMap / render - selectedInItinerary = ", selectedInItinerary);

        // Figure out to apply hovered effects
        let hoveredEffectsOn = false;

        if (this.state.markers !== null) {
            hoveredEffectsOn = (
                ((this.props.selected !== null && this.props.hovered !== null && this.props.hovered !== this.props.selected) ||
                (this.props.selected === null && this.props.hovered !== null)) || 
                ((this.props.selectedChild !== null && this.props.hoveredChild !== null && this.props.hoveredChild !== this.props.selectedChild) ||
                (this.props.selectedChild === null && this.props.hoveredChild !== null))
            )? true : false;
        }

        // Construct hover effects
        const hoverEffects = (this.props.mapMode === "create")? null : (
            <div
                id = {this.props.mapNodeID + "-marker-hover-container"}
                className = "open-map-marker-hover-container"
            >
                <div id = {this.props.mapNodeID + "-marker-hover"}
                    className = {(hoveredInItinerary)? markerHoverClassName : tempMarkerhoverClassName}
                    style = {{
                        display: (hoveredEffectsOn)? "block" : "none"
                    }}
                >
                </div>
            </div>
        );

        // Construct click effects
        const clickEffects = (this.props.mapMode === "create")? null : (
            <div
                id = {this.props.mapNodeID + "-marker-pulse-container"}
                className = "open-map-marker-pulse-container"
            >
                <div id = {this.props.mapNodeID + "-marker-pulse"}
                    className = {(selectedInItinerary)? markerPulseClassName : tempMarkerPulseClassName}
                    style = {{
                        display: (((this.props.selected !== null) || (this.props.selectedChild !== null)) && (this.state.markers !== null))? 
                            "block" : "none" 
                    }}
                >
                </div>
            </div>
        );

        // JSX
        return (
            <div className = "open-map-container">
                <div id = {this.props.mapNodeID} 
                    className = "open-map"
                    style = {{ height: this.state.mapHeight }}
                >
                </div>
                {hoverEffects}
                {clickEffects}
                {markerInformation}
            </div>
        );
    }

    initializeMap() {
        let baseLayerSelected = null;
        let terrainLayerSelected = null;
        let hybridLayerSelected = null;
        let satelliteLayerSelected = null;

        // OSM Base map layer
        const developLayer = new Tile(
            {
                source: new OSM()
            }
        );

        /*
        ============================================================================================
            Tile Server
        ============================================================================================
        */

        // Base raster layer
        const baseLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/klokantech-basic/{z}/{x}/{y}.png"
            })
        });

        // Terrain raster layer
        const terrainLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/klokantech-terrain/{z}/{x}/{y}.png"
            })
        });

        // Hybrid raster layer
        const hybridLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/hybrid/{z}/{x}/{y}.png"
            })
        });

        // Satellite raster layer
        const satelliteLayer = new Tile({
            source: new XYZ({
                url: "https://tiles.thedots.co/styles/satellite/{z}/{x}/{y}.png"
            })
        });


        /*
        ============================================================================================
            V-world Layers
        ============================================================================================
        */

        // V-world layers
        const vWorldBaseLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Base/{z}/{y}/{x}.png"
            })
        });

        const vWorldTerrainLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Terrain/{z}/{y}/{x}.png"
            })
        });

        const vWorldHybridLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Hybrid/{z}/{y}/{x}.png"
            })
        });

        const vWorldSatelliteLayer = new Tile({
            source: new XYZ({
                url: "http://api.vworld.kr/req/wmts/1.0.0/E8C92D17-B7AD-39CD-877F-D7F2A5D5F68C/Satellite/{z}/{y}/{x}.jpeg"
            })
        });


        /*
        ============================================================================================
            Bing Layers
        ============================================================================================
        */

        // Bing Arial with roads
        const bingSatelliteLayer = new Tile({
            visible: true,
            preload: Infinity,
            source: new BingMaps({
                key: "Ag6EnD5xB98riaGAyNCa-kpBtogfQYVAYprgoBefcywRC-4tjNDA0yK_HVma1gm0",
                imagerySet: "AerialWithLabels",
                maxZoom: 19
            })
        });


        /*
        ============================================================================================
            MapTiler Layers
        ============================================================================================
        */

        // MapTiler Satellite
        const mapTilerSatelliteLayer = new Tile(
            {
                source: new TileJSON(
                    {
                        url: "https://api.maptiler.com/maps/hybrid/tiles.json?key=zh1EW7kV5q0kzABuQrMS",
                        crossOrigin: "anonymous"
                    }
                )
            }
        );


        /*
        ============================================================================================
            MapBox Layers
        ============================================================================================
        */

        // Mapbox cloud API key
        const mapBoxKey = "pk.eyJ1IjoiaGFya2xlZSIsImEiOiJja2FvaXoxbHEwMnZ5MnBxaW5oNGhlcHljIn0.sVCVavi9TlJMgTFuC25dMw";

        // Mapbox cloud satellite
        const mapBoxSatelliteLayer = new Tile({
            source: new XYZ({
                url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=" + mapBoxKey
            })
        });


        /*
        ============================================================================================
            Tile Layers
        ============================================================================================
        */

        if (this.props.mapSource === "vworld") {
            baseLayerSelected = vWorldBaseLayer;
            terrainLayerSelected = vWorldTerrainLayer;
            hybridLayerSelected = vWorldSatelliteLayer;
            satelliteLayerSelected = vWorldSatelliteLayer;
        }
        else {
            baseLayerSelected = developLayer;
            terrainLayerSelected = terrainLayer;
            hybridLayerSelected = bingSatelliteLayer;
            satelliteLayerSelected = bingSatelliteLayer;
        }
        // Tile layers
        const tileLayersDict = {
            "roadmap": baseLayerSelected,
            "terrain": terrainLayerSelected,
            "hybrid": hybridLayerSelected,
            "satellite": satelliteLayerSelected
        };

        // Get the max zoom
        const maxZoom = this.getZoom(20);

        // Layers
        const tileLayers = (this.cloudTiles)? 
            [ tileLayersDict[String(this.props.mapType)] ] : [];

        // Set up map
        this.openMap = new Map({
            layers: tileLayers,
            target: this.props.mapNodeID,
            control: new FullScreen(),
            view: new View({
                center: fromLonLat([
                    this.props.mapCenter.longitude,
                    this.props.mapCenter.latitude
                ]),
                zoom: this.getZoom(this.props.mapZoom),
                maxZoom: maxZoom,
                projection: "EPSG:3857"
            })
        });

        // For self-hosted tiles
        // Enable vector layers
        if (!this.cloudTiles) {
            // GL Style jsons
            const styleJson = {
                "roadmap": "https://tiles.thedots.co/styles/klokantech-basic/style.json",
                "terrain": "https://tiles.thedots.co/styles/klokantech-terrain/style.json",
                "hybrid": "https://tiles.thedots.co/styles/hybrid/style.json",
                "satellite": "https://tiles.thedots.co/styles/klokantech-terrain/style.json"
            };

            // Apply mapbox style
            apply(this.openMap, styleJson[String(this.props.mapType)]).then(
                (openMap) => {
                    this.initializeLayout();
                }
            );
        }
        // For cloud tiles
        else {
            this.initializeLayout();
        }
    }


    /*
    ============================================================================================
        Initialize layout
    ============================================================================================
    */

    initializeLayout() {
        // Set markers
        this.setMarkers(null);

        // Set parking marker
        this.setParkingMarker();

        // Set end markers
        this.setEndMarkers();

        // Add height control buttons
        this.addMapHeightButtons();

        // Setup marker hover and information
        this.setMarkerInformation(this.props.mapCenter);
        this.setMarkerHover(this.props.mapCenter);

        // Setup marker pulse
        if ((this.props.selected !== null) || (this.props.selectedChild !== null)) {
            if (this.props.selected !== null) {
                this.setMarkerPulse(this.props.dotsInfo[this.props.selected].location);
            }
            else {
                this.setMarkerPulse(this.props.dotsInfo[this.props.displayChildren].children[this.props.selectedChild].location);
            }
        }
        else {
            this.setMarkerPulse(this.props.mapCenter);
        }

        // Setup mouse action listeners
        this.setHoverListener();

        if (this.props.mapMode === "create") {
            this.setMapClickListener();            
        }

        if ((this.props.mapMode === "trip-overview") || (this.props.mapMode === "itinerary")) {
            this.setMarkerClickListener();
            this.setLocationInputListeners();
        }

        // Zoom to fit markers
        if ((this.props.mapMode === "trip-overview") || (this.props.mapMode === "itinerary")) {
            this.zoomToFit();
        }

        // Set map as a parent state
        if (this.props.mapMode === "create") {
            this.props.setMap(this.openMap);
        }
    }


    componentDidMount() {
        //console.log("OpenMap / componentDidMount");
        //console.log("OpenMap / componentDidMount - this.props.mapNodeID = ", this.props.mapNodeID);
        //console.log("OpenMap / componentDidMount - this.props.dots", this.props.dots);

        // Initialize map
        this.initializeMap();
    }

    componentWillUnmount() {
        //console.log("OpenMap / componentWillUnmount");

        // Make sure to prevent post render timeout
        this.openMap.setTarget(null);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("OpenMap / componentDidUpdate - prevProps = ", prevProps);
        //console.log("OpenMap / componentDidUpdate - this.props = ", this.props);
        //console.log("OpenMap / componentDidUpdate - this.props = ", this.props);
        //console.log("OpenMap / componentDidUpdate - this.props.itinerary = ", this.props.itinerary);

        if (this.openMap !== null) {

            /*
            ============================================================================================
                Color mode changed
            ============================================================================================
            */

            if (this.props.colorMode !== prevProps.colorMode) {
                // Reset the marker
                this.setMarkers(null);

                // Set parking marker
                this.setParkingMarker();

                // Set end markers
                this.setEndMarkers();
            }


            /*
            ============================================================================================
                Create Mode
            ============================================================================================
            */

            // Reset markers if the location has changed
            if ((this.props.mapMode === "create")) {
                // Get the view of the map
                const view = this.openMap.getView();

                // Map center coordinates
                let mapCenterCoordinates = null;

                // Flags
                let locationChanged = false;
                let parkingLocationChanged = false;
                let startLocationChanged = false;
                let endLocationChanged = false;
                let roundtripChanged = false;

                // If location has changed
                if (this.props.createMode === "dot") {
                    if ((prevProps.locationType !== this.props.locationType) && (this.props.locationType === "location") && (this.props.dotsInfo[0].location !== null)) {
                        mapCenterCoordinates = fromLonLat([ this.props.dotsInfo[0].location.longitude, this.props.dotsInfo[0].location.latitude ]);
                    }

                    if ((this.props.dotsInfo[0].location.latitude !== prevProps.dotsInfo[0].location.latitude) || 
                        (this.props.dotsInfo[0].location.longitude !== prevProps.dotsInfo[0].location.longitude))
                    {
                        // Map center coordinate
                        mapCenterCoordinates = fromLonLat([ this.props.dotsInfo[0].location.longitude, this.props.dotsInfo[0].location.latitude ]);

                        // Set the flag
                        locationChanged = true;
                    }

                    // If parking location has changed
                    if ((prevProps.locationType !== this.props.locationType) && (this.props.locationType === "parking") && (this.props.parkingLocation !== null)) {
                        mapCenterCoordinates = fromLonLat([ this.props.parkingLocation.longitude, this.props.parkingLocation.latitude ]);
                    }

                    if ((prevProps.parkingLocation === null) && (this.props.parkingLocation !== null)) {
                        parkingLocationChanged = true;
                        mapCenterCoordinates = fromLonLat([ this.props.parkingLocation.longitude, this.props.parkingLocation.latitude ]);
                    }
                    else if ((prevProps.parkingLocation !== null) && (this.props.parkingLocation === null)) {
                        parkingLocationChanged = true;
                        mapCenterCoordinates = fromLonLat([ this.props.dotsInfo[0].location.longitude, this.props.dotsInfo[0].location.latitude ]);
                    }
                    else if ((prevProps.parkingLocation !== null) && (this.props.parkingLocation !== null)) {
                        if ((this.props.parkingLocation.latitude !== prevProps.parkingLocation.latitude) || 
                        (this.props.parkingLocation.longitude !== prevProps.parkingLocation.longitude)) {
                            parkingLocationChanged = true;
                            mapCenterCoordinates = fromLonLat([ this.props.parkingLocation.longitude, this.props.parkingLocation.latitude ]);
                        }
                    }
                }

                // Check trip locations
                //console.log("OpenMap / componentDidUpdate - prevProps.startLocation = ", prevProps.startLocation);
                //console.log("OpenMap / componentDidUpdate - this.props.startLocation = ", this.props.startLocation);
                //console.log("OpenMap / componentDidUpdate - prevProps.endLocation = ", prevProps.endLocation);
                //console.log("OpenMap / componentDidUpdate - this.props.endLocation = ", this.props.endLocation);
                if (this.props.createMode !== "dot") {
                    if ((prevProps.locationType !== this.props.locationType) && (this.props.locationType === "start") && (this.props.startLocation !== null)) {
                        mapCenterCoordinates = fromLonLat([ this.props.startLocation.longitude, this.props.startLocation.latitude ]);
                    }

                    if ((prevProps.startLocation === null) && (this.props.startLocation !== null)) {
                        startLocationChanged = true;
                        mapCenterCoordinates = fromLonLat([ this.props.startLocation.longitude, this.props.startLocation.latitude ]);
                    }
                    else if ((prevProps.startLocation !== null) && (this.props.startLocation !== null)) {
                        if ((this.props.startLocation.latitude !== prevProps.startLocation.latitude) || 
                        (this.props.startLocation.longitude !== prevProps.startLocation.longitude)) {
                            startLocationChanged = true;
                            mapCenterCoordinates = fromLonLat([ this.props.startLocation.longitude, this.props.startLocation.latitude ]);
                        }
                    }

                    if ((prevProps.locationType !== this.props.locationType) && (this.props.locationType === "end") && (this.props.endLocation !== null)) {
                        mapCenterCoordinates = fromLonLat([ this.props.endLocation.longitude, this.props.endLocation.latitude ]);
                    }

                    if ((prevProps.endLocation === null) && (this.props.endLocation !== null)) {
                        endLocationChanged = true;
                        mapCenterCoordinates = fromLonLat([ this.props.endLocation.longitude, this.props.endLocation.latitude ]);
                    }
                    else if ((prevProps.endLocation !== null) && (this.props.endLocation === null)) {
                        endLocationChanged = true;
                        mapCenterCoordinates = fromLonLat([ this.props.startLocation.longitude, this.props.startLocation.latitude ]);
                    }
                    else if ((prevProps.endLocation !== null) && (this.props.endLocation !== null)) {
                        if ((this.props.endLocation.latitude !== prevProps.endLocation.latitude) || 
                        (this.props.endLocation.longitude !== prevProps.endLocation.longitude)) {
                            endLocationChanged = true;
                            mapCenterCoordinates = fromLonLat([ this.props.endLocation.longitude, this.props.endLocation.latitude ]);
                        }
                    }

                    if (prevProps.roundtrip !== this.props.roundtrip || prevProps.loop !== this.props.loop || prevProps.drivable !== this.props.drivable) {
                        roundtripChanged = true;
                    }
                }
                //console.log("OpenMap / componentDidUpdate - startLocationChanged = ", startLocationChanged);
                //console.log("OpenMap / componentDidUpdate - endLocationChanged = ", endLocationChanged);

                // Move the map center if necessary
                if (mapCenterCoordinates !== null) {
                    // Zoom in and center the map
                    view.animate(
                        {   
                            duration: 600,
                            center: mapCenterCoordinates,
                            zoom: (this.props.mapZoom > view.getZoom())? 
                                this.getZoom(this.props.mapZoom) :
                                this.getZoom(view.getZoom())
                        }
                    );
                }

                // Reset end markers if necessary
                if (startLocationChanged || endLocationChanged || roundtripChanged) {
                    // Set end markers
                    this.setEndMarkers();
                }

                // Reset end markers if necessary
                if (parkingLocationChanged) {
                    // Set parking marker
                    this.setParkingMarker();
                }

                // If the main location has changed
                if (locationChanged) {
                    // Reset the marker
                    this.setMarkers(null);

                    // Move pulse animation
                    setTimeout(
                        () => { this.markerPulseOn(this.props.dotsInfo[0].location); },
                        600
                    );
                }
            }


            /*
            ============================================================================================
                Dot Home Mode
            ============================================================================================
            */

            // Update map when the width has changed - dots-home mode / trip overview
            if (this.props.mapWidth !== prevProps.mapWidth || 
                (this.props.mapRefresh === true && this.props.mapRefresh !== prevProps.mapRefresh)) {
                if (this.props.mapMode === "dots-home") {
                    this.openMap.updateSize();
                }
                else {
                    setTimeout(
                        () => {
                            this.openMap.updateSize();
                        },
                        0
                    );
                }
            }


            /*
            ============================================================================================
                Trip Overview or Itinerary Mode
            ============================================================================================
            */

            // Reset markers if the itinerary has changed
            if ((this.props.mapMode === "trip-overview") || (this.props.mapMode === "itinerary")) {
                // Compare itineraries (trips)
                if (!arraysOrderEqual(this.props.itinerary, prevProps.itinerary)) {
                    //console.log("OpenMap / componentDidUpdate - itinerary changed");
                    
                    // Reset markers
                    this.setMarkers(null);
                    //this.openMap.updateSize();

                    // Zoom map
                    if (this.props.itinerary.length === 1) {
                        // Zoom in on the one dot
                        this.zoomIn(this.props.itinerary[0], null);
                    }
                    else {
                        // Zoom to fit all markers
                        this.zoomToFit();
                    }
                }
            }

            // Trigger hover action
            if (prevProps.hovered !== this.props.hovered) {
                if (prevProps.hovered !== null && this.props.hovered === null) {
                    if (this.state.hovered) {
                        this.setState(
                            {
                                hovered: false
                            },
                            () => { 
                                this.markerInformationOff();
                            }
                        );
                    }
                }
                else if (prevProps.hovered === null && this.props.hovered !== null) {
                    if (!this.state.hovered) {
                        this.setState(
                            {
                                hovered: true
                            },
                            () => { 
                                // Convert location to coordinates
                                const coordinates = fromLonLat([
                                    this.props.dotsInfo[this.props.hovered].location.longitude,
                                    this.props.dotsInfo[this.props.hovered].location.latitude
                                ]);

                                // Turn on hover
                                this.markerHoverOn(coordinates);

                                // Turn on winformation window
                                this.markerInformationOn("dot", this.props.hovered, null, null);
                            }
                        );
                    }
                }
                else if (prevProps.hovered !== null && this.props.hovered !== null) {
                    // Remove marker information
                    this.markerInformationOff();

                    // Convert location to coordinates
                    const coordinates = fromLonLat([
                        this.props.dotsInfo[this.props.hovered].location.longitude,
                        this.props.dotsInfo[this.props.hovered].location.latitude
                    ]);

                    // Turn on hover
                    this.markerHoverOn(coordinates);

                    // Turn on winformation window
                    this.markerInformationOn("dot", this.props.hovered, null, null);
                }
            }

            // If hovered is changed
            if (prevProps.hoveredChild !== this.props.hoveredChild) {
                if (this.props.hoveredChild === null) {
                    if (this.state.hovered) {
                        this.setState(
                            {
                                hovered: false
                            },
                            () => { 
                                this.markerInformationOff();
                            }
                        );
                    }
                }
                else {
                    if (!this.state.hovered) {
                        this.setState(
                            {
                                hovered: true
                            },
                            () => { 
                                // Convert location to coordinates
                                const coordinates = fromLonLat([
                                    this.props.dotsInfo[this.props.displayChildren].trip_extension.children[this.props.hoveredChild].location.longitude,
                                    this.props.dotsInfo[this.props.displayChildren].trip_extension.children[this.props.hoveredChild].location.latitude
                                ]);

                                // Turn on hover
                                this.markerHoverOn(coordinates);

                                // Turn on winformation window
                                this.markerInformationOn("child", this.props.displayChildren, this.props.hoveredChild, null);
                            }
                        );
                    }
                }
            }

            // Trigger click action
            // If selected is changed
            if (prevProps.selected !== this.props.selected) {
                if (this.props.selected === null) {
                    this.zoomToFit();
                }
                else {
                    this.zoomIn(this.props.selected, null);
                }
            }

            // If selected child is changed
            if (prevProps.selectedChild !== this.props.selectedChild) {
                if (this.props.selectedChild === null) {
                    this.zoomToFit();
                }
                else {
                    this.zoomIn(this.props.displayChildren, this.props.selectedChild);
                }
            }

            // Update pulse animation
            // If selected is changed
            if (prevProps.selected !== this.props.selected) {
                if (this.props.selected !== null) {
                    // Turn on the marker pulse
                    this.markerPulseOn(this.props.dotsInfo[this.props.selected].location);
                }
            }

            // If selected child is changed
            if (prevProps.hoveredChild !== this.props.hoveredChild) {
                if (this.props.selectedChild !== null) {
                    // Turn on the marker pulse
                    this.markerPulseOn(this.props.dotsInfo[this.props.displayChildren].children[this.props.selectedChild].location);
                }
            }

            // Path added or removed
            if (this.props.path !== null && prevProps.path === null) {
                this.addPath(this.props.path);
            }
            else if (this.props.path === null && prevProps.path !== null) {
                this.removePath();
                if (this.props.selected !== null) {
                    this.zoomIn(this.props.selected, null);
                }
            }
            else if ((this.props.path !== null && prevProps.path !== null) && (this.props.path !== prevProps.path)) {
                this.removePath();
                this.addPath(this.props.path);
            }
        }
    }


    /*
    ============================================================================================
        Marker Actions
    ============================================================================================
    */

    setMarkers(callback) {
        //console.log("OpenMap / setMarkers");
        //console.log("OpenMap / setMarkers - this.props = ", this.props);
        //console.log("OpenMap / setMarkers - this.state = ", this.state);
        //console.log("OpenMap / setMarkers - this.props.itinerary = ", this.props.itinerary);
        //console.log("OpenMap / setMarkers - this.props.dotsInfo = ", this.props.dotsInfo);

        // Clear previous markers
        this.removeMarkers(false);
        this.removeChildMarkers(false);

        // Initialize markers
        let markers = [];
        let childMarkers = [];

        // Set up counter
        let markerCount = 0;
        let childMarkerCount = 0;

        // For all dots
        for (let i = 0; i < this.props.itinerary.length; i++) {
            // Get element dot index
            const dotIndex = this.props.itinerary[i];
            
            // If child dots of the dot are displayed
            if (this.props.displayChildren === dotIndex) {
                // For all child dots
                for (let j = 0; j < this.props.dotsInfo[dotIndex].trip_extension.children.length; j++){
                    // Get child dot index
                    const childIndex = j;

                    // Get location
                    const location = this.props.dotsInfo[dotIndex].trip_extension.children[childIndex].location;

                    // Get marker image
                    const markerImage = (this.props.mapMode === "trip-overview")?
                        (
                            (this.props.colorMode === "day")?
                                getStaticPath("/images/number/single_gray_" + (childMarkerCount + 1) + ".png", false) :
                                getStaticPath("/images/number/single_gray_" + (childMarkerCount + 1) + ".png", false)
                        ) : (
                            (this.props.colorMode === "day")?
                                getStaticPath("/images/number/single_gray_" + (childMarkerCount + 1) + ".png", false) :
                                getStaticPath("/images/number/single_gray_" + (childMarkerCount + 1) + ".png", false)
                        );
                    
                    // Create a child marker
                    const childMarker = this.createMarker(
                        location,
                        markerImage,
                        "child",
                        dotIndex, 
                        childIndex
                    );

                    // Add marker to the array
                    childMarkers.push(childMarker);

                    // Increase counter
                    childMarkerCount += 1;
                }
            }

            // Get location
            const location = this.props.dotsInfo[dotIndex].location;

            // Get marker image
            let markerImage = null;
            if (this.props.mapMode === "dots-home") {
                markerImage = getStaticPath("/images/map/dot-marker-black.png", false)
            }
            else if (this.props.mapMode === "create") {
                markerImage = getStaticPath("/images/map/dot-marker-black.png", false)
            }
            else if (this.props.mapMode === "trip-overview") {
                markerImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/number/single_white_" + (markerCount + 1) + ".png", false) :
                    getStaticPath("/images/number/single_black_" + (markerCount + 1) + ".png", false);
            }
            else if (this.props.mapMode === "itinerary") {
                markerImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/number/single_red_" + (markerCount + 1) + ".png", false) :
                    getStaticPath("/images/number/single_red_" + (markerCount + 1) + ".png", false);
            }
            else {
                console.log("[WARNING] Map / setMarkers - wrong map mode - ", this.props.mapMode);
            }
            //console.log("OpenMap / setMarkers - markerIcon = ", markerIcon);

            // Create a marker
            const marker = this.createMarker(
                location,
                markerImage,
                "dot",
                dotIndex,
                null
            );

            // Add marker to the array
            markers.push(marker);

            // Increase counter
            markerCount += 1;
        }

        if (childMarkerCount > 0) {
            // Child markers vector
            const childMarkersSource = new VectorSource({
                features: childMarkers
            });

            const childMarkersLayer = new VectorLayer({
                className: "open-map-child-layer",
                source: childMarkersSource,
                zIndex: 104
            });

            // Set name
            childMarkersLayer.set("name", "child", true);

            // Save the vector source
            this.childMarkersSource = childMarkersSource;

            // Save the layer handle
            this.childMarkersLayer = childMarkersLayer;

            // Add vector layer
            this.openMap.addLayer(childMarkersLayer);
        }
        else {
            childMarkers = null;
        }

        if (markerCount > 0) {
            // Markers vector
            const markersSource = new VectorSource({
                features: markers
            });

            const markersLayer = new VectorLayer({
                className: "open-map-marker-layer",
                source: markersSource,
            });
            markersLayer.setZIndex(105);

            // Set name
            markersLayer.set("name", "marker", true);

            // Save the vector source
            this.markersSource = markersSource;

            // Save the layer handle
            this.markersLayer = markersLayer;

            // Add vector layer
            this.openMap.addLayer(markersLayer);
        }
        else {
            markers = null;
        }

        // Set state and refresh map
        this.setState(
            {
                markers: markers,
                childMarkers: childMarkers
            },
            callback
        );
    }


    setParkingMarker() {
        // Initialize parking marker
        let parkingMarker = null;

        // Clear previous parking marker
        this.removeParkingMarker(false);

        // If parking location is set
        if ((typeof this.props.parkingLocation === "object") && (this.props.parkingLocation !== null)) {
            // Set marker icon
            const markerImage = (this.props.colorMode === "day")?
                getStaticPath("/images/number/single_black_P.png", false) :
                getStaticPath("/images/number/single_white_P.png", false);

            // Create marker
            parkingMarker = this.createMarker(
                this.props.parkingLocation,
                markerImage,
                "parking",
                null,
                null
            );

            // Vector
            const parkingMarkerSource = new VectorSource({
                features: [ parkingMarker ]
            });

            const parkingMarkerLayer = new VectorLayer({
                className: "open-map-parking-layer",
                source: parkingMarkerSource,
                zIndex: 102
            });

            // Set name
            parkingMarkerLayer.set("name", "parking", true);

            // Save the layer handle
            this.parkingMarkerLayer = parkingMarkerLayer;

            // Add vector layer
            this.openMap.addLayer(parkingMarkerLayer);

            // Update state
            this.setState({
                parkingMarker: parkingMarker
            });            
        }
    }


    setEndMarkers() {
        // Initialize end Marker lists
        let startMarker = null;
        let endMarker = null;
        const endMarkers = [];

        // Clear previous end markers
        this.removeEndMarkers(false);

        // If start location is set
        if ((typeof this.props.startLocation === "object") && (this.props.startLocation !== null)) {
            // Set marker icon
            const markerImage = ((this.props.roundtrip || this.props.loop) && (this.props.drivable))?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/number/single_black_P.png", false) :
                        getStaticPath("/images/number/single_white_P.png", false)
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/number/single_black_S.png", false) :
                        getStaticPath("/images/number/single_white_S.png", false)
                );

            // Create marker
            startMarker = this.createMarker(
                this.props.startLocation,
                markerImage,
                "start",
                null,
                null
            );

            // Add marker to the array
            endMarkers.push(startMarker);
        }

        // End location
        if ((typeof this.props.endLocation === "object") && (this.props.endLocation !== null)) {
            // Set marker icon
            const markerImage = (this.props.colorMode === "day")?
                getStaticPath("/images/number/single_black_E.png", false) :
                getStaticPath("/images/number/single_white_E.png", false);

            // Create marker
            endMarker = this.createMarker(
                this.props.endLocation, 
                markerImage,
                "end",
                null,
                null                
            );

            // Add marker to the array
            endMarkers.push(endMarker);
        }

        // Vector
        const endMarkersSource = new VectorSource({
            features: endMarkers
        });

        const endMarkersLayer = new VectorLayer({
            className: "open-map-end=layer",
            source: endMarkersSource,
            zIndex: 103
        });

        // Set name
        endMarkersLayer.set("name", "end", true);

        // Save the layer handle
        this.endMarkersLayer = endMarkersLayer;

        // Add vector layer
        this.openMap.addLayer(endMarkersLayer);

        // Update state
        this.setState({
            startMarker: startMarker,
            endMarker: endMarker
        });
    }


    createMarker(location, markerImage, type, dotIndex, childIndex) {
        // Set up a marker
        const marker = new Feature({
            geometry: new Point(
                fromLonLat([
                    location.longitude,
                    location.latitude
                ])
            ),
            type: type,
            dotIndex: dotIndex,
            childIndex: childIndex
        });

        // Set icon and style
        const markerIcon = new IconStyle(({
            crossOrigin: "anonymous",
            src: markerImage,
            scale: 0.25
        }));

        marker.setStyle(new Style({
            image: markerIcon,
            zIndex: 105
        }));

        return marker;
    }


    removeMarker(marker, updateState) {
        if ((this.state.markers !== null) && (this.markersLayer !== null)) {
            this.markersLayer.getSource().removeFeature(marker);

            // If requires state update
            if (updateState) {
                // Get markers
                const markers = this.state.markers.slice();
                const index = markers.indexOf(marker);
                markers.splice(index, 1);

                // Update state
                this.setState({
                    markers: markers
                });
            }
        }
    }


    removeMarkers(updateState) {
        if ((this.state.markers !== null) && (this.markersLayer !== null)) {
            this.markersLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    markers: null
                });
            }
        }
    }

    removeParkingMarker(updateState) {
        if ((this.state.parkingMarker !== null) && (this.parkingMarkerLayer !== null)) {
            this.parkingMarkerLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    parkingMarker: null,
                });
            }            
        }
    }

    removeStartMarker(updateState) {
        if ((this.state.startMarker !== null) && (this.endMarkersLayer !== null)) {
            this.endMarkersLayer.getSource().removeFeature(this.state.startMarker);

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    startMarker: null
                });
            }
        }
    }


    removeEndMarker(updateState) {
        if ((this.state.endMarker !== null) && (this.endMarkersLayer !== null)) {
            this.endMarkersLayer.getSource().removeFeature(this.state.EndMarker);

            // If requires state update
            if (updateState) {
                // Updat state
                this.setState({
                    endMarker: null
                });
            }
        }
    }


    removeEndMarkers(updateState) {
        if (((this.state.startMarker !== null) || (this.state.startMarker !== null)) && (this.endMarkersLayer !== null)) {
            this.endMarkersLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    startMarker: null,
                    endMarker: null
                });
            }            
        }
    }


    removeChildMarkers(updateState) {
        if ((this.state.childMarkers !== null) && (this.childMarkersLayer !== null)) {
            this.childMarkersLayer.getSource().clear();

            // If requires state update
            if (updateState) {
                // Update state
                this.setState({
                    childMarkers: null
                });
            }

        }
    }


    /* 
    ============================================================================================
        Map Height
    ============================================================================================
    */

    addMapHeightButtons() {
        const heightDecreaseButton = document.createElement("button");
        heightDecreaseButton.innerHTML = "∧";
        heightDecreaseButton.addEventListener("click", this.mapHeightDecrease, false);

        const heightIncreaseButton = document.createElement("button");
        heightIncreaseButton.innerHTML = "∨";
        heightIncreaseButton.addEventListener("click", this.mapHeightIncrease, false);

        const heightButtons = document.createElement("div");
        heightButtons.className = "open-map-height-buttons ol-unselectable ol-control";
        heightButtons.appendChild(heightDecreaseButton);
        heightButtons.appendChild(heightIncreaseButton);

        const heightControl = new Control({
            element: heightButtons
        });

        this.openMap.addControl(heightControl);
    }


    mapHeightIncrease() {
        const mapHeight = (this.state.mapHeight < this.props.mapMaxHeight)? 
            this.state.mapHeight + this.props.mapHeightIncrement : this.state.mapHeight;

        this.setState(
            {
                mapHeight: mapHeight
            }, 
            () => {this.openMap.updateSize();}
        );

        if (this.props.mapHeightUpdate) {
            this.props.setState({
                mapHeight: mapHeight
            });
        }
    }


    mapHeightDecrease() {
        const mapHeight = (this.state.mapHeight > this.props.mapMinHeight)?
            this.state.mapHeight - this.props.mapHeightIncrement : this.state.mapHeight;

        this.setState(
            {
                mapHeight: mapHeight
            },
            () => {this.openMap.updateSize();}
        );

        if (this.props.mapHeightUpdate) {
            this.props.setState({
                mapHeight: mapHeight
            });
        }
    }


    /* 
    ============================================================================================
        Mouse Actions Listeners
    ============================================================================================
    */

    setHoverListener() {
        // Save context
        const that = this;
            
        // Set up hover event listener
        that.markerHoverListenerKey = that.openMap.on("pointermove", function(event) {
            // Initialize hovered feature
            let hoveredFeature = null;
            let hoveredLayer = null;

            // When not in create mode
            if (that.props.mapMode !== "create") {
                // Detect feature
                that.openMap.forEachFeatureAtPixel(
                    event.pixel,
                    (detectedFeature, detectedLayer) => {
                        //console.log("OpenMap / setHoverListener - detectedFeature = ", detectedFeature);
                        //console.log("OpenMap / setHoverListener - detectedLayer = ", detectedLayer);
                        hoveredFeature = detectedFeature;
                        hoveredLayer = detectedLayer;
                    }, 
                    {
                        hitTolerance: 0,
                        layerFilter: (detectedLayer) => {
                            return (detectedLayer === that.markersLayer) ||
                            (detectedLayer === that.childMarkersLayer);
                        }
                    }
                );

                if (hoveredFeature !== null && hoveredLayer !== null) {
                    if (!that.state.hovered) {
                        if (hoveredLayer.get("name") === "marker") {
                            // Mouse cursor style
                            that.openMap.getTargetElement().style.cursor = "pointer";

                            // Fetch properties
                            const hoveredFeatureProperties = hoveredFeature.getProperties();
                            const type = hoveredFeatureProperties.type;
                            const dotIndex = hoveredFeatureProperties.dotIndex;
                            const childIndex = hoveredFeatureProperties.childIndex;
                            //console.log("OpenMap / setHoverListener - hoveredFeature = ", hoveredFeature);
                            //console.log("OpenMap / setHoverListener - hoveredFeatureProperties = ", hoveredFeatureProperties);
                            //console.log("OpenMap / setHoverListener - dotIndex = ", dotIndex);

                            // Hover marker shade on
                            if (childIndex === null) {
                                if (dotIndex === null) {
                                   // Turn on hover
                                     that.markerHoverOn(event.coordinate);
                                }
                                else {
                                    // Convert location to coordinates
                                    const coordinates = fromLonLat([
                                        that.props.dotsInfo[dotIndex].location.longitude,
                                        that.props.dotsInfo[dotIndex].location.latitude
                                    ]);

                                    // Turn on hover
                                    that.markerHoverOn(coordinates);
                                }
                            }
                            else {
                                // Convert location to coordinates
                                const coordinates = fromLonLat([
                                    that.props.dotsInfo[dotIndex].children[childIndex].location.longitude,
                                    that.props.dotsInfo[dotIndex].children[childIndex].location.latitude
                                ]);

                                // Turn on hover
                                this.markerHoverOn(coordinates);
                            }

                            // Marker information on (using mouse event"s coordinate)
                            that.markerInformationOn(type, dotIndex, childIndex, event.coordinate);

                            // Update hover state
                            that.hoverOn(dotIndex, childIndex);
                        }
                    }
                }
                else {
                    if (that.state.hovered) {
                        // Mouse cursor style
                        that.openMap.getTargetElement().style.cursor = "default";

                        // Marker information off
                        that.markerInformationOff();

                        // Update hover state
                        that.hoverOff();
                    }
                }
            }
        });
    }


    setMapClickListener() {
        // Save context
        const that = this;

        // Set up click event listener
        that.mapClickListenerKey = that.openMap.on("click", function(event) {
            //console.log("OpenMap / setClickListener - event = ", event);
            const lnglat = projection.transform(event.coordinate, "EPSG:3857", "EPSG:4326");
            //console.log("OpenMap / setClickListener - lnglat = ", lnglat);

            // Location
            const location = {
                latitude: lnglat[1],
                longitude: lnglat[0]
            };

            // Clear location search input
            that.props.clearLocationSearch();

            // Clear GPS inputs (only in create mode)
            that.props.clearLocationGPS();

            // Change the location state of the parent component
            if (that.props.locationType === "start") {
                that.props.setStartLocation(location);
            }
            else if (that.props.locationType === "end") {
                that.props.setEndLocation(location);
            }
            else if (that.props.locationType === "parking") {
                that.props.setParkingLocation(location);
            }
            else {
                that.props.setLocation(location);
            }
        });
    }


    setMarkerClickListener() {
        // Save context
        const that = this;

        // Set up click event listener
        that.markerClickListenerKey = that.openMap.on("click", function(event) {
            // Initialize hovered feature
            let clickedFeature = null;
            let clickedLayer = null;

            // Detect feature
            that.openMap.forEachFeatureAtPixel(
                event.pixel,
                (detectedFeature, detectedLayer) => {
                    //console.log("OpenMap / setClickListener - detectedFeature = ", detectedFeature);                    
                    //console.log("OpenMap / setClickListener - detectedLayer = ", detectedLayer);                    
                    clickedFeature = detectedFeature;
                    clickedLayer = detectedLayer;
                },
                { hitTolerance: 0 }
            );

            if (clickedFeature && (clickedLayer.get("name") === "marker")) {
                // Fetch properties
                const clickedFeatureProperties = clickedFeature.getProperties();
                const dotIndex = clickedFeatureProperties.dotIndex;
                const childIndex = clickedFeatureProperties.childIndex;

                that.props.dotClick(dotIndex, childIndex);

                /*
                if (childIndex === null) {
                    if (dotIndex === that.props.selected) {
                       that.clickOff();
                    }
                    else {
                       that.clickOn(null, childIndex);
                    }
                }
                else {
                    if (childIndex === that.props.selectedChild) {
                       that.props.dotClick(dotIndex, null);
                       that.clickOff();
                    }
                    else {
                       that.clickOn(null, childIndex);
                    }
                }
                */
            }
        });
    }


    /* 
    ============================================================================================
        Mouse Actions State Updaters
    ============================================================================================
    */

    hoverOn(dotIndex, childIndex) {
        // Turn on being hovered
        this.setState(
            {
                hovered: true
            },
            // Update parent hovered state
            () => {
                this.props.setState({
                    hovered: (childIndex === null)? dotIndex : null,
                    hoveredChild: childIndex
                });
            }
        );        
    }


    hoverOff() {
        // Turn off being hovered
        this.setState(
            {
                hovered: false
            },
            () => {
                // Update hovered state
                this.props.setState({
                    hovered: null,
                    hoveredChild: null
                });
            }
        );        
    }


    clickOn(dotIndex, childIndex) {
        this.props.setState({
            selected: dotIndex, 
            selectedChild: childIndex,
            selectedImageIndex: 0
        });
    }


    clickOff() {
        this.props.setState({
            selected: null,
            selectedChild: null,
            displayChildren: null
        });
    }



    /* 
    ============================================================================================
        Marker Hover
    ============================================================================================
    */

    setMarkerHover(location) {
        // Set up a new overlay
        this.markerHoverOverlay = new Overlay({
            position: fromLonLat([ location.longitude, location.latitude ]),
            positioning: "center-center",
            element: document.getElementById(this.props.mapNodeID + "-marker-hover"),
            stopEvent: false
        });

        this.openMap.addOverlay(this.markerHoverOverlay);
        //console.log("OpenMap / setMarkerHover - this.markerHoverOverlay = ", this.markerHoverOverlay);
        //console.log("OpenMap / setMarkerHover - location = ", location);
    }


    markerHoverOn(coordinates) {
        // Move the marker information to the hovered location
        this.markerHoverOverlay.setPosition(coordinates);        
    }


    /* 
    ============================================================================================
        Marker Information
    ============================================================================================
    */

    setMarkerInformation(location) {
        //console.log("OpenMap / markerInformation - location = ", location);

        // Set up a new overlay
        this.markerInformationOverlay = new Overlay({
            position: fromLonLat([ location.longitude, location.latitude ]),
            positioning: "center-center",
            element: document.getElementById(this.props.mapNodeID + "-marker-information"),
            stopEvent: false
        });

        this.openMap.addOverlay(this.markerInformationOverlay);
    }


    markerInformationOn(type, dotIndex, childIndex, coordinatesProvided) {
        // Grab the marker information window
        const markerInformation = document.getElementById(this.props.mapNodeID + "-marker-information");

        // Grab content
        const markerInformationName = document.getElementById(this.props.mapNodeID + "-marker-information-name");
        const markerInformationRating = document.getElementById(this.props.mapNodeID + "-marker-information-rating");
        const markerInformationRatingContainer = document.getElementById(this.props.mapNodeID + "-marker-information-rating-container");
        const markerInformationDifficulty = document.getElementById(this.props.mapNodeID + "-marker-information-difficulty");
        const markerInformationDifficultyContainer = document.getElementById(this.props.mapNodeID + "-marker-information-difficulty-container");

        if (type === "parking") {
            // Move the marker information to the hovered location
            this.markerInformationOverlay.setPosition(coordinatesProvided);

            // Set name for the parking point
            const name = "Parking";
            markerInformationName.innerHTML = name;

            // Set factors
            markerInformationRatingContainer.style.display = "none";
            markerInformationDifficultyContainer.style.display = "none";

            // Make it visible
            markerInformation.style.opacity = 1.0;
        }
        else if ((type === "start") || (type === "end")) {
            // Move the marker information to the hovered location
            this.markerInformationOverlay.setPosition(coordinatesProvided);

            // Set name for the start or end point
            const name = (type === "start")? "Start Point" : "End Point";
            markerInformationName.innerHTML = name;

            // Set factors
            markerInformationRatingContainer.style.display = "none";
            markerInformationDifficultyContainer.style.display = "none";

            // Make it visible
            markerInformation.style.opacity = 1.0;
        }
        else {
            // Get dot information
            let dotInfo = null;
            if (childIndex === null) {
                dotInfo = this.props.dotsInfo[dotIndex];
            }
            else { 
                dotInfo = this.props.dotsInfo[dotIndex].children[childIndex];
            }

            // Common dot flag
            const commonDot = dotInfo.type === "EV" || dotInfo.type === "AU";

            // Get info
            const location = dotInfo.location;
            const name = dotInfo.name;
            const rating = (commonDot)? null :
            (
                (dotInfo.dot_extension.rating === null)?
                    null : Number(dotInfo.dot_extension.rating).toFixed(1)
            );
            const difficulty = (commonDot)? null :
            (
                (dotInfo.dot_extension.difficulty === null)? 
                    null : Math.min(Math.ceil(dotInfo.dot_extension.difficulty), 5).toFixed(1)
            );

            // Fetch coordinate if not provided
            let coordinates = null;
            if (coordinatesProvided === null) {
                coordinates = fromLonLat([ location.longitude, location.latitude ]);
            }
            else {
                coordinates = coordinatesProvided;
            }

            // Move the marker information to the hovered location
            this.markerInformationOverlay.setPosition(coordinates);

            // Set name
            markerInformationName.innerHTML = name;

            // Set factors
            markerInformationRatingContainer.style.display = (rating === null)? "none" : "inline-block";
            markerInformationDifficultyContainer.style.display = (difficulty === null)? "none" : "inline-block";
            markerInformationRating.style.backgroundColor = (difficulty === null)? "#C5833B" : "#249CCE";
            markerInformationRating.style.borderRadius = (rating === 5.0)? "3px 3px 3px 3px": "3px 0px 0px 3px";
            markerInformationDifficulty.style.borderRadius = (difficulty === 5.0)? "3px 3px 3px 3px": "3px 0px 0px 3px";
            markerInformationRating.style.width = "" + Math.round(rating / 5 * 70) + "px";
            markerInformationDifficulty.style.width = (difficulty === null)? "0px" : "" + Math.round(difficulty / 5 * 70) + "px";

            // Make it visible
            markerInformation.style.opacity = 1.0;
        }
    }


    markerInformationOff() {
        // Get the marker information
        const markerInformation = document.getElementById(this.props.mapNodeID + "-marker-information");

        // Make it invisible
        if (markerInformation !== null) {
            markerInformation.style.opacity = 0.0;
        }
    }


    /* 
    ============================================================================================
        Marker Pulse
    ============================================================================================
    */

    setMarkerPulse(location) {
        // Set up a new overlay
        this.markerPulseOverlay = new Overlay({
            position: fromLonLat([ location.longitude, location.latitude ]),
            positioning: "center-center",
            element: document.getElementById(this.props.mapNodeID + "-marker-pulse"),
            stopEvent: false
        });

        this.openMap.addOverlay(this.markerPulseOverlay);
        //console.log("OpenMap / setMarkerPulse - this.markerPulseOverlay = ", this.markerPulseOverlay);
        //console.log("OpenMap / setMarkerPulse - location = ", location);
    }


    markerPulseOn(location) {
        const coordinates = fromLonLat([
            location.longitude,
            location.latitude
        ]);

        // Move the marker information to the hovered location
        this.markerPulseOverlay.setPosition(coordinates);        
    }


    /* 
    ============================================================================================
        Zoom Actions
    ============================================================================================
    */

    zoomIn(dotIndex, childIndex) {
        // Get the view of the map
        const view = this.openMap.getView();

        // Get the location and coordinates
        let location = null;
        if (childIndex === null) {
            location = this.props.dotsInfo[dotIndex].location;
        }
        else {
            location = this.props.dotsInfo[dotIndex].children[childIndex].location;
        }
        const centerCoordinates = fromLonLat([ location.longitude, location.latitude ]);

        // Zoom in and center the map
        view.animate(
            {   
                duration: 600,
                center: centerCoordinates,
                zoom: this.getZoom(this.zoomInLevel)
            }
        );
    }

    zoomToFit() {
        //console.log("OpenMap / zoomToFit - this.openMap = ", this.openMap);
        //console.log("OpenMap / zoomToFit - this.openMapMarkersSource = ", this.openMapMarkersSource);
        this.openMap.getView().fit(
            this.markersSource.getExtent(),
            { 
                duration: 600,
                padding: [50, 50, 50, 50],
                constrainResolution: false, 
                callback: () => { this.openMap.updateSize(); }
            }
        );
    }

    zoomToFitChildren() {
        this.openMap.getView().fit(
            this.childMarkersSource.getExtent(),
            {   
                duration: 600,
                padding: [50, 50, 50, 50],
                constrainResolution: false,
                callback: () => { this.openMap.updateSize(); }
            }
        );
    }


    /* 
    ============================================================================================
        Get max zoom
    ============================================================================================
    */

    getZoom(zoom) {
        let adjustedZoom = null;
        if (this.cloudTiles) {
            adjustedZoom = Math.min(this.maxZoomCloudDict[String(this.props.mapType)], zoom);
        }
        else {
            adjustedZoom = Math.min(this.maxZoomDict[String(this.props.mapType)], zoom);
        }

        return adjustedZoom;
    }


    /* 
    ============================================================================================
        Location Inputs
    ============================================================================================
    */

    /*
    google
    startInputNodeID, 
    endInputNodeID, 
    setStartLocation,
    setEndLocation,
    displayChildren,
    */

    setLocationInputListeners() {
        // Save context
        const that = this;

        // Get the view of the map
        const view = this.openMap.getView();

        if (typeof this.props.startInputNodeID === "string") {
            // Get the start point input element
            const startInput = document.getElementById(this.props.startInputNodeID);
            const startAutocomplete = new this.props.google.maps.places.Autocomplete(startInput);

            // Add a Listener to the start point input window
            startAutocomplete.addListener("place_changed", function() {
                // Get place from the input address
                const place = startAutocomplete.getPlace();
                if (!place.geometry) {
                    window.alert("Autocomplete returned place contains no geometry");
                    return;
                }

                // Get the coordinates
                const coordinates = fromLonLat([ 
                    place.geometry.location.lng(),
                    place.geometry.location.lat()
                ]);

                // Reset the coordinates of the start marker
                that.state.startMarker.getGeometry().setCoordinates(coordinates);

                // Zoom in and center the map
                view.animate(
                    {   
                        duration: 600,
                        center: coordinates,
                        zoom: that.getZoom(that.zoomInLevel)
                    }
                );

                // Zoom out and fit the markers
                setTimeout(
                    () => {
                        if (that.props.displayChildren === null) {
                            that.zoomToFit();
                        }
                        else {
                            that.zoomToFitChildren();                            
                        }
                    }, 
                    3000
                );

                // Set start location
                const startLocation = latLngToPoint(place.geometry.location);
                const startLocationName = place.name;
                //console.log("Map / initMap - place = ", place);
                //console.log("Map / initMap - startLocation = ", startLocation);
                //console.log("Map / initMap - startLocationName = ", startLocationName);

                // Update state and update times
                that.props.setStartLocation(startLocation, startLocationName);

                /*
                // If the place has a geometry, present it the map.
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                }

                // Fetch address
                const startLocationAddress = (place.address_components)? [
                    (place.address_components[0] && place.address_components[0].short_name || ""),
                    (place.address_components[1] && place.address_components[1].short_name || ""),
                    (place.address_components[2] && place.address_components[2].short_name || "")
                ].join(" ") : null;
                console.log("Map / initMap - startLocationAddress = ", startLocationAddress);
                */
            });
        }

        if (typeof this.props.endInputNodeID === "string") {
            // Get the start point input element
            const endInput = document.getElementById(this.props.endInputNodeID);
            const endAutocomplete = new this.props.google.maps.places.Autocomplete(endInput);

            // Add a Listener to the start point input window
            endAutocomplete.addListener("place_changed", function() {
                // Get place from the input address
                const place = endAutocomplete.getPlace();
                if (!place.geometry) {
                    window.alert("Autocomplete returned place contains no geometry");
                    return;
                }

                // Get the coordinates
                const coordinates = fromLonLat([ 
                    place.geometry.location.lng(),
                    place.geometry.location.lat()
                ]);


                // Reset the coordinates of the start marker
                that.state.endMarker.getGeometry().setCoordinates(coordinates);

                // Zoom in and center the map
                view.animate(
                    {   
                        duration: 600,
                        center: coordinates,
                        zoom: that.getZoom(that.zoomInLevel)
                    }
                );

                // Zoom out and fit the markers
                setTimeout(
                    () => {
                        if (that.props.displayChildren === null) {
                            that.zoomToFit();
                        }
                        else {
                            that.zoomToFitChildren();                            
                        }
                    }, 
                    3000
                );

                // Set start location
                const endLocation = latLngToPoint(place.geometry.location);
                const endLocationName = place.name;
                //console.log("Map / initMap - place = ", place);
                //console.log("Map / initMap - startLocation = ", startLocation);
                //console.log("Map / initMap - startLocationName = ", startLocationName);

                // Update state and update times
                that.props.setEndLocation(endLocation, endLocationName);
            });
        }
    }

    /*
    ============================================================================================
        path
    --------------------------------------------------------------------------------------------
        - Refresh map after changes
    ============================================================================================
    */

    addPath(pathString) {
        //console.log("OpenMap / addPath - pathString = ", pathString);

        // Construct geoJson
        const geoJson = {
            "type": "Feature",
            "properties": {
                "name": "path"
            },
            "geometry": polyline.toGeoJSON(pathString)
        };
        //console.log("OpenMap / addPath - geoJson = ", geoJson);
        
        // Coordinate conversion
        const coordinates = [];
        for (let i = 0; i < geoJson.geometry.coordinates.length; i++) {
            coordinates.push(
                fromLonLat([
                    geoJson.geometry.coordinates[i][0],
                    geoJson.geometry.coordinates[i][1]
                ])
            );
        }

        // Replace coordinates
        geoJson.geometry.coordinates = coordinates;

        // Get features
        const features = (
            new GeoJSON({})
        ).readFeatures(geoJson);
        //console.log("OpenMap / addPath - features = ", features);

        // Vector source
        const pathSource = new VectorSource({
            features: features
        });
        //console.log("OpenMap / addPath - pathSource = ", pathSource);

        // Start feature
        const startImage = getStaticPath("/images/map/path_S.png", false);
        const startIcon = new IconStyle(({
            crossOrigin: "anonymous",
            src: startImage,
            scale: 0.20
        }));

        const startFeature = new Feature({
            geometry: new Point(coordinates[0]),
        });

        startFeature.setStyle(
            new Style({
                image: startIcon
            })
        );
    
        // End feature
        const endImage = getStaticPath("/images/map/path_E.png", false);
        const endIcon = new IconStyle(({
            crossOrigin: "anonymous",
            src: endImage,
            scale: 0.20
        }));

        const endFeature = new Feature({
            geometry: new Point(coordinates[coordinates.length - 1]),
        });

        endFeature.setStyle(
            new Style({
                image: endIcon
            })
        );

        // Add start and end features
        pathSource.addFeature(startFeature);
        pathSource.addFeature(endFeature);
        
        // Vector layer
        const pathLayer = new VectorLayer({
            className: "open-map-path-layer",
            name: "open-map-path-layer",
            source: pathSource,
            style: this.getFeatureStyle,
            zIndex: 101,
            opacity: 0.8
        });
        //console.log("OpenMap / addPath - pathLayer = ", pathLayer);

        // Set name
        pathLayer.set("name", "path", true);

        // Add path to the map
        this.openMap.addLayer(pathLayer);

        // Store
        this.pathSource = pathSource;
        this.pathLayer = pathLayer;

        // Zoom to fit path
        this.zoomToFitPath();
    }

    removePath() {
        // Remove the vector layer
        this.pathLayer.getSource().clear();
    }

    zoomToFitPath() {
        //console.log("OpenMap / zoomToFitPath - this.openMap = ", this.openMap);
        //console.log("OpenMap / zoomToFitPath - this.pathSource = ", this.pathSource);
        this.openMap.getView().fit(
            this.pathSource.getExtent(),
            { 
                duration: 600,
                padding: [50, 50, 50, 50],
                constrainResolution: false, 
            },
            () => { this.openMap.updateSize(); }
        );
    }

    getFeatureStyle(feature) {
        //console.log("OpenMap / getFeatureStyle - feature.getGeometry() = ", feature.getGeometry());
        //console.log("OpenMap / getFeatureStyle - feature.getGeometry().getType() = ", feature.getGeometry().getType());

        const image = getStaticPath("/images/map/open-map-point.png", false);

        const styles = {
            "Point": new Style({
                image: image
            }),
            "LineString": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 10
                })
            }),
            "MultiLineString": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 12
                })
            }),
            "MultiPoint": new Style({
                image: image
            }),
            "MultiPolygon": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            }),
            "Polygon": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    lineDash: [ 10 ],
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            }),
            "GeometryCollection": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 6
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                }),
                image: new CircleStyle({
                    radius: 10,
                    fill: null,
                    stroke: new StrokeStyle({
                        color: "#4EA3D0"
                    })
                })
            }),
            "Circle": new Style({
                stroke: new StrokeStyle({
                    color: "#4EA3D0",
                    width: 10
                }),
                fill: new FillStyle({
                    color: "#4EA3D0"
                })
            })
        };        

        return styles[feature.getGeometry().getType()];
    };

}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

export default connect(mapStateToProps, null)(OpenMap);
