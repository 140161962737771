/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    ItineraryOverview.js
--------------------------------------------------------------------------------------------
    Content
    - Gallery module developed to show dots of an itinerary
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 

// Component
import ItineraryOverviewItem from "./ItineraryOverviewItem";
import ItineraryOverviewStartEndItem from "./ItineraryOverviewStartEndItem";

// Functions
import {
    debounce    
} from "js/Functions";

// CSS
import "./ItineraryOverview.scss";


// ItineraryOverview component
class ItineraryOverview extends Component{
    constructor (props){
        super(props);
        //console.log("ItineraryOverview / constructor - props = ", props);

        // Left column widths
        this.leftColumnWidth = 120;
        this.leftColumnWidthMedium = 90;
        this.leftColumnWidthSmall = 60;

        // Gallery settings
        // Minimum / scale / maximum content widths
        this.minContentWidth = 320;
        this.scaleContentWidth = 600;
        this.maxContentWidth = 900;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media settings
        this.mediaArea = 240000;
        this.mediaMarginWidth = 10;

        // Thumbnail settings
        this.thumbnailArea = 12000;
        this.thumbnailMarginWidth = 10;

        // Number of thumbnails
        this.numThumbnailsSmall = 2;
        this.numThumbnailsMedium = 3;
        this.numThumbnailsLarge = 4;

        // Editor and staff section settings
        this.editorWidth = 72;
        this.staffItemWidth = 36;
        this.staffMarginWidth = 2;
        this.contributorsMinWidth = 100;
        this.helpersMinWidth = 80;
        this.numStaffProfilePics = 4;

        // Initialize states
        this.state = {
            // Number of dots
            numDots: this.props.dotsInfo.length,

            // Scroll
            checkScroll: false
        }

        // Bind setState and onScroll functions
        this.setState = this.setState.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollListener);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollListener);
    }

    render() {
        let itineraryOverviewList, itineraryOverviewItemProps;

        /*
        ============================================================================================
            Left Column
        ============================================================================================
        */

        // Left column width
        const leftColumnWidth = (this.props.browserWidth <= 4)?
            this.leftColumnWidthSmall :
            (
                (this.props.browserWidth <= 8)?
                    this.leftColumnWidthMedium :
                    this.leftColumnWidth
            );


        /*
        ============================================================================================
            Thumbnails
        ============================================================================================
        */

        // Thumbnail area
        const thumbnailArea = (this.props.browserWidth <= 3)?
            this.thumbnailArea * 0.5 : (
                (this.props.browserWidth <= 6)?
                    this.thumbnailArea * 0.75 : this.thumbnailArea
            );

        // Number of thumbnails
        const numThumbnails = (this.props.browserWidth <= 6)?
            this.numThumbnailsSmall : (
                (this.props.browserWidth <= 8)? 
                    this.numThumbnailsMedium : this.numThumbnailsLarge
            );


        // Only if all information is ready
        if ((this.props.itinerary) && (this.props.dotsInfo)) {
            //console.log("ItineraryOverview / render - this.props = ", this.props);

            itineraryOverviewList = this.props.itinerary.map((dotIndex, index) => {
                // Figure out if this is the top or the bottom
                const isRoundtripBottom = (this.props.dotInfo.dot_extension.roundtrip) && (index === (this.props.itinerary.length - 1))? true : false;
                //console.log("ItineraryOverview / render - index = ", index);
                //console.log("ItineraryOverview / render - dotIndex = ", dotIndex);
                //console.log("ItineraryOverview / render - highlights = ", this.props.highlights);

                // Calculate the new sizes
                // Resized to fit the specified regular and focused image sizes
                const isHighlight = (this.props.dotInfo.trip_extension.highlights.indexOf(this.props.dotsInfo[dotIndex].id) >= 0);
                //console.log("ItineraryOverview / render - this.props.itinerary = ", this.props.itinerary);
                //console.log("ItineraryOverview / render - this.props.dotsInfo[dotIndex].id = ", this.props.dotsInfo[dotIndex].id);
                //console.log("ItineraryOverview / render - isHighlight = ", isHighlight);

                // Timestamps
                const dotTimestamp = (this.props.itineraryInfo === null)?
                    null : this.props.itineraryInfo.dot_start_times_local_formatted[index];
                let transitTimestamp = null;
                let transitMode = null;
                if (this.props.itineraryInfo != null) {
                    //transitTimestamp = this.props.itineraryInfo.transit_time[index + 1];
                    transitTimestamp = (isRoundtripBottom)? "Turnaround" : this.props.itineraryInfo.transit_time[index + 1];
                    transitMode = (isRoundtripBottom)? "turnaround" : this.props.itineraryInfo.transit_mode[index + 1];
                }

                /*
                ============================================================================================
                    Itinerary Overview Item
                ============================================================================================
                */

                // Parse properties for individual itinerary item
                itineraryOverviewItemProps = {
                    // Left column
                    leftColumnWidth: leftColumnWidth,

                    // Timestamps
                    dotTimestamp: dotTimestamp,
                    transitTimestamp: transitTimestamp,
                    transitMode: transitMode,

                    // Number index
                    number: index + 1,
                    index: index,

                    // Position
                    isRoundtripBottom: isRoundtripBottom,

                    // Is highlight
                    isHighlight: isHighlight,

                    // Selected
                    dotIndex: dotIndex,

                    // Dot information
                    dotInfo: this.props.dotsInfo[dotIndex],

                    // Editor and staff widths
                    editorWidth: this.editorWidth,
                    staffItemWidth: this.staffItemWidth,
                    staffMarginWidth: this.staffMarginWidth,
                    contributorsMinWidth: this.contributorsMinWidth,
                    helpersMinWidth: this.helpersMinWidth,
                    numStaffProfilePics: this.numStaffProfilePics,

                    // Gallery settings
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: this.maxContentWidth,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    mediaArea: this.mediaArea,
                    mediaMarginWidth: this.mediaMarginWidth,
                    thumbnailArea: thumbnailArea,
                    thumbnailMarginWidth: this.thumbnailMarginWidth,
                    numThumbnails: numThumbnails,

                    // Set state
                    setState: this.props.setState,
                    
                    // Scroll
                    checkScroll: this.state.checkScroll
                };

                // Return an individual item
                return(
                    <div id = {"itinerary-overview-item-container-" + dotIndex}
                        key = {"itinerary-overview-item-container-" + dotIndex}
                        className = "itinerary-overview-item-container"
                    >
                        <ItineraryOverviewItem
                            key = {"itinerary-overview-item-" + dotIndex + 
                                "-browser-width-" + this.props.browserWidthPixels}
                            {...itineraryOverviewItemProps} 
                        />
                    </div>
                )
            });
        }

        const itineraryOverviewStartItem = (
            <div key = {"itinerary-overview-item-container-start"} 
                className = "itinerary-overview-item-container" 
            >
                <ItineraryOverviewStartEndItem 
                    colorMode = {this.props.colorMode}
                    locationName = {this.props.itineraryInfo.start_location_name}
                    isStart = {true}
                    roundtrip = {this.props.dotInfo.roundtrip}
                    endTimestamp = {this.props.startMoment.format("h:mm a")}
                    transitTimestamp = {this.props.itineraryInfo.transit_time[0]}
                    transitMode = {this.props.itineraryInfo.transit_mode[0]}
                    leftColumnWidth = {leftColumnWidth}
                >
                </ItineraryOverviewStartEndItem>
            </div>
        );

        const itineraryOverviewEndItem = (
            <div key = {"itinerary-overview-item-container-end"} 
                className = "itinerary-overview-item-container"
            >
                <ItineraryOverviewStartEndItem 
                    colorMode = {this.props.colorMode}
                    locationName = {this.props.itineraryInfo.end_location_name}
                    isStart = {false}
                    roundtrip = {this.props.dotInfo.roundtrip}
                    leftColumnWidth = {leftColumnWidth}
                    endTimestamp = {this.props.actualEndMoment.format("h:mm a")}
                >
                </ItineraryOverviewStartEndItem>
            </div>
        );

        // Render the list of items
        return(
            <div className = "itinerary-overview-list-container">
                <div className = "itinerary-overview-list">
                    {itineraryOverviewStartItem}
                    {itineraryOverviewList}
                    {itineraryOverviewEndItem}
                </div>
            </div>
        )
    }


    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }    
} 


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode
    };
}

export default connect(mapStateToProps, null)(ItineraryOverview);
