/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Board.js
    - Board / Comment / Reply components
--------------------------------------------------------------------------------------------
    Content
    - Board
    - Comment
    - Reply
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";

// Modules
import ReactTooltip from "thedots-tooltip";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeNewComment, storeNewReply } from "actions";

// Axios
import {
    getSingleBoardComments,
    getSingleCommentReplies,
    postCreateComment,
    //patchEditComment,
    deleteComment
} from "requests";

// Functions
import {
    url,
    getStaticPath,
    getMediaProperty,
    formatDatetime,
    formatTimeElapsed
} from "js/Functions";

// CSS
import "./Board.scss"


class Board extends Component {
    constructor(props) {
        super(props);

        // Comment settings
        this.numCommentsWhenFolded = 2;

        // Comment input
        this.commentInputRef = React.createRef();
        this.commentInputPlaceholder = "Write a comment";
        this.commentInputID = "comment-input-" + this.props.boardID;

        // Comment loading flag
        this.loadingComment = false;

        // Initialize state
        this.state = {
            folded: true,
            commentsInfo: this.props.commentsInfo,
            commentCount: this.props.commentCount
        };
        //console.log("Board / constructor - this.props.commentCount = ", this.props.commentCount);

        // Bind functions
        this.getMoreCommentsInfo = this.getMoreCommentsInfo.bind(this);
        this.commentInputResize = this.commentInputResize.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.createComment = this.createComment.bind(this);
        this.commentCountClick = this.commentCountClick.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
    }

    render() {
        // console.log("Board / render - this.props = ", this.props);
        // console.log("Board / render - this.state = ", this.state);

        // Create comments JSX block
        const commentsList = (this.state.commentsInfo.length > 0)
        ? this.state.commentsInfo.map((commentInfo, index) => {
            // console.log("Board / render - commentInfo = ", commentInfo);

            // if there is any comment after folding them (showing 2 comments only),
            // and its index is smaller than those who should be shown (i.e. those 2 comments,
            // do not render them.
            if ((this.state.folded) && (this.state.commentsInfo.length > this.numCommentsWhenFolded)
                && (index < (this.state.commentsInfo.length - this.numCommentsWhenFolded))) {
                return null;
            }
            else {
                return(
                    <Comment
                        colorMode = {this.props.colorMode}
                        key = {this.props.typePrefix + "-board-" + this.props.boardID + "-comment-" + commentInfo.id}
                        classPrefix = {this.props.typePrefix + "-board-" + this.props.boardID + "-comment-" + commentInfo.id}
                        index = {index}
                        boardID = {this.props.boardID}
                        commentInfo = {commentInfo}
                        loggedIn = {this.props.loggedIn}
                        userInfo = {this.props.userInfo}
                        appendToRepliesInfo = {this.props.appendToRepliesInfo}
                        newReply = {this.props.newReply}
                        storeNewReply = {this.props.storeNewReply}
                        deleteComment = {this.deleteComment}
                    >
                    </Comment>
                );
            }
        }) : null;

        // Create comment input JSX block
        const commentInputProps = {
            type: "text",
            rows: 1,
            placeholder: this.commentInputPlaceholder,
            onKeyDown: this.handleSubmit,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            // onChange: this.handleChange,
            autoComplete: "off",
            minLength: 2,
            required: "required"
        };

        // Comment input
        const commentInput = (
            <div
                // No specific CSS definition for "comment-input-form-container"
                // in the Board.css
                // className = "comment-input-form-container"
            >
                <textarea
                    ref = {this.commentInputRef}
                    id = {this.commentInputID}
                    style = {{ display: this.props.loggedIn? "inline-block" : "none" }}
                    className = {(this.props.colorMode === "day")?
                        "comment-input input-s2 input-day" :
                        "comment-input input-s2 input-night"}
                    {...commentInputProps}
                />
            </div>
        );

        // Comment count
        let commentCount = null;
        if (this.state.folded) {
            // If folded, and there are hidden comments,
            if (this.state.commentCount - this.numCommentsWhenFolded > 0) {
                const commentCountText = (this.state.commentCount - this.numCommentsWhenFolded > 1)?
                    ("View " + (this.state.commentCount - this.numCommentsWhenFolded).toString() + " more comments") :
                    ("View " + (this.state.commentCount - this.numCommentsWhenFolded).toString() + " more comment");
                commentCount = (
                    <div className = {(this.props.colorMode === "day")?
                            "comment-count lb4" : "comment-count b4"}
                        onClick = {this.commentCountClick}
                    >
                        {commentCountText}
                    </div>
                );
            }
        }
        else {
            // If more comments were fetched by unfolding, and there are still hidden comments,
            if (this.state.commentCount - this.state.commentsInfo.length > 0) {
                const commentCountText = (this.state.commentCount - this.state.commentsInfo.length > 1)?
                    ("View " + (this.state.commentCount - this.state.commentsInfo.length).toString() + " more comments") :
                    ("View " + (this.state.commentCount - this.state.commentsInfo.length).toString() + " more comment");
                commentCount = (
                    <div className = {(this.props.colorMode === "day")?
                            "comment-count lb4" : "comment-count b4"}
                        onClick = {this.commentCountClick}
                    >
                        {commentCountText}
                    </div>
                );
            }
        }

        // Main render
        return(
            <div className = "comments-container">
                {commentCount}
                {commentsList}
                {commentInput}
            </div>
        );
    }

    componentDidMount() {
        // Set up event listeners for comment input
        this.commentInputRef.current.addEventListener("change", this.commentInputResize);
        this.commentInputRef.current.addEventListener("cut", this.commentInputResize);
        this.commentInputRef.current.addEventListener("paste", this.commentInputResize);
        this.commentInputRef.current.addEventListener("drop", this.commentInputResize);
        //this.commentInputRef.current.addEventListener("keydown", this.commentInputResize);
    }

    componentWillUnmount() {
        // Remove event listeners for comment input
        this.commentInputRef.current.removeEventListener("change", this.commentInputResize);
        this.commentInputRef.current.removeEventListener("cut", this.commentInputResize);
        this.commentInputRef.current.removeEventListener("paste", this.commentInputResize);
        this.commentInputRef.current.removeEventListener("drop", this.commentInputResize);
        //this.commentInputRef.current.removeEventListener("keydown", this.commentInputResize);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("Board / componentDidUpdate - this.props.boardID = ", this.props.boardID);
        //console.log("Board / componentDidUpdate - this.props.commentsInfo = ", this.props.commentsInfo);

        // Add new comment
        if (((prevProps.newComment === null) && (this.props.newComment !== null)) &&
            (this.props.newComment.board === this.props.boardID)) {
            //console.log("Board / componentDidUpdate - this.props.newComment = ", this.props.newComment);

            // Add the new comment to the already loaded comments
            const commentsInfo = (this.state.commentsInfo == null)? [] : this.state.commentsInfo.slice();
            commentsInfo.push(this.props.newComment);

            // Update state
            this.setState(
                {
                    commentsInfo: commentsInfo,
                    commentCount: (this.state.commentCount + 1)
                },
                () => {
                    // Reset new comment
                    this.props.storeNewComment(null);
                }
            );
        }
    }

    getMoreCommentsInfo() {
        // Retrieve fixed number of additional comments in addition to
        // already loaded comments, and concat them to existing state.

        // Axios callback : execute when the server returns a response,
        // and sets the this.loadingComment flag to false after completion of updating state.
        const axiosCallback = (response) => {
            //console.log("Board / getMoreCommentsInfo - response.data.content = ", response.data.content);

            // Update state
            this.setState(
                {
                    commentsInfo: response.data.content.concat(this.state.commentsInfo)
                },
                () => { this.loadingComment = false; }
            );
        };

        // Send request only when the this.loadingComment flag is set to true
        if (this.loadingComment) {
            //console.log("Board / getMoreCommentsInfo - this.props.boardID = ", this.props.boardID);
            //console.log("Board / getMoreCommentsInfo - this.state.commentsInfo.length = ", this.state.commentsInfo.length);

            getSingleBoardComments(this.props.boardID, this.state.commentsInfo.length)
            .then(axiosCallback)
            .catch((response) => {console.log("DotHome / getMoreCommentsInfo - Axios error ", response);});
        }
    }

    commentInputResize() {
        window.setTimeout(
            () => {
                this.commentInputRef.current.style.height = "auto";
                this.commentInputRef.current.style.height = this.commentInputRef.current.scrollHeight + "px";
            },
            0
        );
    }

    handleFocus() {
        // Clear the placeholder
        this.commentInputRef.current.placeholder = "";

        // Text align
        this.commentInputRef.current.style.textAlign = "left";
    }

    handleBlur() {
        // Reset the placeholder
        this.commentInputRef.current.placeholder = this.commentInputPlaceholder;

        // Text align
        if (this.commentInputRef.current.value.length > 0) {
            this.commentInputRef.current.style.textAlign = "left";
        }
        else {
            this.commentInputRef.current.style.textAlign = "center";
        }
    }

    handleReset() {
        // Reset the input value
        this.commentInputRef.current.value = "";

        // Reset commentInputRef.current height
        window.setTimeout(
            () => {
                this.commentInputRef.current.style.height = "auto";
                //this.commentInputRef.current.style.height = this.commentInputRef.current.scrollHeight + "px";
            },
            0
        );
    }

    handleSubmit(e) {
        //console.log("Board / handleSubmit - e.target = ", e.target);

        // Submit only when enter is pressed
        if (e.keyCode === 13 && e.shiftKey === false) {
            // Stop event propagation
            e.stopPropagation();

            // Send message only if there is any content excluding whitespace
            if (this.commentInputRef.current.value.replace(/[\s]/g, "").length > 0) {
                this.createComment();
            }

            // Reset and Blur the comment input
            this.handleReset();
            this.handleBlur();
        }
        else {
            this.commentInputResize();
        }
    }

    createComment() {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Board / createComment - axiosCallback - response.data.content = ", response.data.content);
        }

        // JSON to send to the API
        const dataJSON = {
            board_id: this.props.boardID,
            content: this.commentInputRef.current.value
        };
        //console.log("Board / createComment - dataJSON = ", dataJSON);

        // Send POST request using axios with CSRF token
        postCreateComment(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("Board / createComment - Axios error = ", response);});
    }

    commentCountClick() {
        // Below process is called when the this.loadingComment flag is set to false
        if (this.loadingComment === false) {
            // Set the this.loadingComment flag to true, to prevent any duplicate triggering
            // of this process
            this.loadingComment = true;

            if (this.loadingComment) {
                // Fetch more comments
                this.getMoreCommentsInfo();

                // Unfold the board
                if (this.state.folded) {
                    this.setState({ folded: false });
                }
            }
        } else {
            console.log("Fetching comment process is not completed.")
        }
    }

    deleteComment(commentID) {
        // Callback
        const axiosCallback = (response) => {
            // console.log("Board / deleteComment - response.data.content = ", response.data.content);

            // Make a copy of the already loaded comments
            const commentsInfo = this.state.commentsInfo.slice();

            // Find the comment to delete
            let commentIndex = null;
            for (let i = 0; i < commentsInfo.length; i++) {
                if (commentsInfo[i].id === commentID) {
                    commentIndex = i;
                }
            }

            // Remove the comment
            commentsInfo.splice(commentIndex, 1);

            // Update state
            this.setState(
                {
                    commentsInfo: commentsInfo,
                    commentCount: this.state.commentCount - 1
                }
            );
        }

        // Send delete comment request
        deleteComment(commentID)
        .then(axiosCallback)
        .catch((response) => {console.log("Board / deleteComment - Axios error = ", response);});
    }
}


class Comment extends Component {
    constructor (props) {
        super(props);

        // Initialize state
        this.state = {
            replyInputOn: false,
            replyTargetUserID: null,
            replyFolded: true,
            replyCount: this.props.commentInfo.reply_count,
            repliesInfo: this.props.commentInfo.replies,
            deleteModalOn: false
        };

        // Bind callbacks
        this.editButtonClick = this.editButtonClick.bind(this);
        this.replyButtonClick = this.replyButtonClick.bind(this);
        this.replyButtonClick = this.replyButtonClick.bind(this);
        this.replyInputResize = this.replyInputResize.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createReply = this.createReply.bind(this);
        this.replyCountClick = this.replyCountClick.bind(this);
        this.getMoreRepliesInfo = this.getMoreRepliesInfo.bind(this);
        this.deleteReply = this.deleteReply.bind(this);

        // Delete modal
        this.deleteModalReference = null;
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteModalOutsideClick = this.deleteModalOutsideClick.bind(this);
        this.getDeleteModalReference = this.getDeleteModalReference.bind(this);

        // Settings
        this.replyInputRef = React.createRef();
        this.replyInputPlaceholder = "Write a reply";
        this.replyInputID = this.props.classPrefix + "-reply-input";
        this.numRepliesWhenFolded = 2;

        // Reply loading flag
        this.loadingReply = false;
    }

    render() {
        //console.log("Comment / render - this.props = ", this.props);
        //console.log("Comment / render - this.state = ", this.state);
        //console.log("Comment / render - this.props.newReply = ", this.props.newReply);

        const timeElapsed = formatTimeElapsed(this.props.commentInfo.timestamp);
        const timestamp = formatDatetime(this.props.commentInfo.timestamp, true, false, " ");
        //console.log("Comment / render - timeElapsed - ", timeElapsed);
        //console.log("Comment / render - timeElapsed - ", timestamp);

        /*
        ============================================================================================
            User
        ============================================================================================
        */
        const userProfilePic = (this.props.commentInfo.user.profile_pic)?
            (
                (this.props.commentInfo.user.profile_pic.external_url === null)?
                    getMediaProperty(this.props.commentInfo.user.profile_pic, "t", "url", true):
                    url(this.props.commentInfo.user.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );
        const userTooltipID = this.props.classPrefix + "-user-tooltip";
        const userTooltipContent = this.props.commentInfo.user.name;

        /*
        ============================================================================================
            Timestamp
        ============================================================================================
        */
        const timestampTooltipID = this.props.classPrefix + "-timestamp-tooltip";


        /*
        ============================================================================================
            Reply button
        ============================================================================================
        */

        // Reply button
        let replyButton = null;
        let replyButtonTooltip = null;

        // When logged in
        if (this.props.loggedIn) {
            // Reply button
            const replyButtonTooltipText = "Reply";
            const replyButtonTooltipID = "comment-reply-button-tooltip-board-" + this.props.boardID + "-comment-" + this.props.index;
            replyButtonTooltip = (
                <ReactTooltip
                    id = {replyButtonTooltipID}
                    className = "comment-reply-button-tooltip tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html = {true}
                />
            );

            const replyButtonClickProps = {
                userID: this.props.userInfo.id,
                targetUserID: this.props.commentInfo.user.id,
                commentID: this.props.commentInfo.id
            };

            const replyButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/reply-black.png") :
                getStaticPath("/images/common/reply-white.png");

            replyButton = (
                <div className = "comment-reply-button image-button-s6"
                    style = {{ backgroundImage: replyButtonImage }}
                    onClick = {()=>this.replyButtonClick(replyButtonClickProps)}
                    data-tip = {replyButtonTooltipText}
                    data-for = {replyButtonTooltipID}
                >
                </div>
            );
        }


        /*
        ============================================================================================
            Replies
        ============================================================================================
        */

        // Replies JSX
        const repliesList = (this.state.repliesInfo != null)?
            this.state.repliesInfo.map((replyInfo, index) => {
                    // If the index of the reply is located in the folded part of the repliesInfo,
                    // do not render.
                    if((this.state.replyFolded)
                        && (this.state.repliesInfo.length > this.numRepliesWhenFolded)
                        && (index < (this.state.repliesInfo.length - this.numRepliesWhenFolded))) {
                        return null
                    }
                    else {
                        return(
                            <Reply
                                colorMode = {this.props.colorMode}
                                key = {this.props.classPrefix + "-reply-" + replyInfo.id}
                                classPrefix = {this.props.classPrefix}
                                replyInfo = {replyInfo}
                                userInfo = {this.props.userInfo}
                                replyTargetUserID = {this.state.replyTargetUserID}
                                replyButtonClick = {this.replyButtonClick}
                                loggedIn = {this.props.loggedIn}
                                deleteReply = {this.deleteReply}
                            />
                        );
                    }
                }
            ) : null;


        /*
        ============================================================================================
            Reply input
        ============================================================================================
        */

        // Reply Input
        const replyInputProps = {
            type: "text",
            rows: 1,
            placeholder: this.replyInputPlaceholder,
            onKeyDown: this.handleSubmit,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            autoComplete: "off",
            minLength: 2,
            required: "required"
        };

        const replyInput = (
            <div
                // className = "reply-input-form-container"
            >
                <textarea
                    ref = {this.replyInputRef}
                    id = {this.replyInputID}
                    style = {{ display: (this.props.loggedIn && this.state.replyInputOn)? "inline-block" : "none" }}
                    className = {(this.props.colorMode === "day")?
                        "reply-input input-s2 input-day" : "reply-input input-s2 input-night"}
                    {...replyInputProps}
                />
            </div>
        );

        // Reply Count
        let replyCount = null;
        if (this.state.replyFolded) {
            // If folded, and there are hidden comments
            if (this.state.replyCount - this.numRepliesWhenFolded > 0) {
                const replyCountText = (this.state.replyCount - this.numRepliesWhenFolded > 1)
                                ? ("View " + (this.state.replyCount - this.numRepliesWhenFolded).toString() + " more replies")
                                : ("View " + (this.state.replyCount - this.numRepliesWhenFolded).toString() + " more reply");
                replyCount = (
                    <div
                        className = "reply-count b4"
                        onClick = {this.replyCountClick}
                    >
                        {replyCountText}
                    </div>
                );
            }
        }
        else {
            // If more comments were fetched by unfolding, and there are still hidden comments
            if (this.state.replyCount - this.state.repliesInfo.length > 0) {
                const replyCountText = (this.state.replyCount - this.state.repliesInfo.length > 1)
                                ? ("View " + (this.state.replyCount - this.state.repliesInfo.length).toString() + " more replies")
                                : ("View " + (this.state.replyCount - this.state.repliesInfo.length).toString() + " more reply");
                replyCount = (
                    <div
                        className = "reply-count b4"
                        onClick = {this.replyCountClick}
                    >
                        {replyCountText}
                    </div>
                );
            }
        }


        /*
        ============================================================================================
            Edit and delete buttons
        ============================================================================================
        */

        // Edit and delete buttons and tooltips
        let editButton = null;
        let editButtonTooltip = null;
        let deleteButton = null;
        let deleteButtonTooltip = null;

        // Show edit and delete buttons
        if (this.props.userInfo !== null) {
            if (this.props.commentInfo.user.id === this.props.userInfo.id) {
                /*
                const editButtonID = "comment-edit-button-" + this.props.commentInfo.id;
                const editButtonTooltipID = "comment-edit-button-tooltip-" + this.props.commentInfo.id;
                const editButtonImage = getStaticPath("/images/icons/edit.png");

                editButton = (
                    <div id = {editButtonID}
                        className = "comment-edit-button image-button-s9"
                        style = {{
                            backgroundImage: editButtonImage
                        }}
                        data-tip = "Edit Comment"
                        data-for = {editButtonTooltipID}
                        onClick = {this.editButtonClick}
                    >
                    </div>
                );

                editButtonTooltip = (
                    <ReactTooltip
                        id = {editButtonTooltipID}
                        className = {"comment-edit-button-tooltip tooltip-s2"}
                        type = "dark"
                        place = "top"
                        html = {false}
                    />
                );
                */


                const deleteButtonID = "comment-delete-button-" + this.props.commentInfo.id;
                const deleteButtonTooltipID = "comment-delete-button-tooltip-" + this.props.commentInfo.id;
                const deleteButtonImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/common/trash-can-black.png") :
                    getStaticPath("/images/common/trash-can-white.png");

                deleteButton = (
                    <div id = {deleteButtonID}
                        className = "comment-delete-button image-button-s9"
                        style = {{
                            backgroundImage: deleteButtonImage
                        }}
                        data-tip = "Delete Comment"
                        data-for = {deleteButtonTooltipID}
                        onClick = {this.openDeleteModal}
                    >
                    </div>
                );

                deleteButtonTooltip = (
                    <ReactTooltip
                        id = {deleteButtonTooltipID}
                        className = {"comment-delete-button-tooltip tooltip-s2"}
                        type = "dark"
                        place = "top"
                        html = {false}
                    />
                );
            }
        }


        /*
        ============================================================================================
            Delete modal
        ============================================================================================
        */

        const deleteModal = (this.state.deleteModalOn)? (
            <div className = "comment-delete-modal">
                <div ref = {this.getDeleteModalReference}
                    className = {(this.props.colorMode === "day")?
                        "comment-delete-modal-content modal-day" :
                        "comment-delete-modal-content modal-night"}
                >
                    <div className = "comment-delete-modal-content__title w2">
                        Delete Comment
                    </div>

                    <div className = "comment-delete-modal-content__text g4">
                        Are you sure to delete your comment?
                    </div>

                    <div className = "comment-delete-modal-content__confirm">
                        <div className = "comment-delete-modal-content__confirm__select">
                            <div className = "comment-delete-modal-content__confirm__select__yes button-gray-s2"
                                onClick = {() => {this.props.deleteComment(this.props.commentInfo.id);}}
                            >
                                Yes
                            </div>
                            <div className = "comment-delete-modal-content__confirm__select__no button-gray-s2"
                                onClick = {this.closeDeleteModal}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Main JSX
        ============================================================================================
        */

        return(
            <div className = "comment-container">
                {deleteModal}
                <div className = "comment-upper">
                    <div className = "comment-left">
                        <Link
                            className = {(this.props.colorMode === "day")?
                                "comment-user-profile-pic profile-image-s4 border-day" :
                                "comment-user-profile-pic profile-image-s4 border-night"}
                            style = {{ backgroundImage: userProfilePic, cursor: "pointer" }}
                            data-tip = {userTooltipContent}
                            data-for = {userTooltipID}
                            to = {`/user/${this.props.commentInfo.user.id}`}
                        >
                        </Link>
                    </div>
                    <ReactTooltip
                        id = {userTooltipID}
                        className = {"comment-user-profile-pic-tooltip tooltip-s2"}
                        type = "dark"
                        place = "bottom"
                        html = {true}
                    />
                    <div className = {(this.props.colorMode === "day")?
                            "comment-middle-day" : "comment-middle-night"}
                    >
                        <Link className = {(this.props.colorMode === "day")?
                                "comment-user-name k4" : "comment-user-name w4"}
                            to = {`/user/${this.props.commentInfo.user.id}`}
                        >
                            {this.props.commentInfo.user.name}
                        </Link>
                        <span className = {(this.props.colorMode === "day")?
                                "comment-content comment-dg4" : "comment-content comment-g4"}
                        >
                            {this.props.commentInfo.content}
                        </span>
                        <span className = {(this.props.colorMode === "day")?
                                "comment-timestamp lb4" : "comment-timestamp b4"}
                            data-for = {timestampTooltipID}
                            data-tip = {timestamp}
                        >
                            {timeElapsed}
                        </span>
                        <ReactTooltip
                            id = {timestampTooltipID}
                            className = {"comment-timestamp-tooltip tooltip-s2"}
                            type = "info"
                            place = "right"
                            html = {true}
                        />

                        {deleteButton}
                        {editButton}
                        {deleteButtonTooltip}
                        {editButtonTooltip}
                    </div>
                    <div className = "comment-right">
                        <div className = "comment-interactions">
                            {replyButton}
                            {replyButtonTooltip}
                        </div>
                    </div>

                </div>
                <div>
                    {replyCount}
                    {repliesList}
                </div>
                <div>
                    {replyInput}
                </div>
            </div>
        );
    }

    componentDidMount() {
        // Set up event listeners for reply input
        this.replyInputRef.current.addEventListener("change", this.replyInputResize);
        this.replyInputRef.current.addEventListener("cut", this.replyInputResize);
        this.replyInputRef.current.addEventListener("paste", this.replyInputResize);
        this.replyInputRef.current.addEventListener("drop", this.replyInputResize);
        //this.replyInputRef.current.addEventListener("keydown", this.replyInputResize);

        // Modal outside click
        document.addEventListener("mousedown", this.deleteModalOutsideClick);
    }

    componentWillUnmount() {
        // Remove event listeners for reply input
        this.replyInputRef.current.removeEventListener("change", this.replyInputResize);
        this.replyInputRef.current.removeEventListener("cut", this.replyInputResize);
        this.replyInputRef.current.removeEventListener("paste", this.replyInputResize);
        this.replyInputRef.current.removeEventListener("drop", this.replyInputResize);
        //this.replyInputRef.current.removeEventListener("keydown", this.replyInputResize);

        // Modal outside click
        document.removeEventListener("mousedown", this.deleteModalOutsideClick);
    }

    componentDidUpdate (prevProps, prevState) {
        // Update newly received reply
        if ((prevProps.newReply == null) && (this.props.newReply != null)) {
            console.log("Comment / componentDidUpdate - this.props.newReply = ", this.props.newReply);
            console.log("Comment / componentDidUpdate - this.props.commentInfo = ", this.props.commentInfo);

            // If newly received reply is a reply for this comment
            if (this.props.newReply.parent === this.props.commentInfo.id) {
                // Create an array adding the newReply to the already loaded replies
                const repliesInfo = (this.state.repliesInfo == null) ? [] : this.state.repliesInfo.slice();
                repliesInfo.push(this.props.newReply);

                // Update state and reset new reply
                this.setState(
                    {
                        repliesInfo: repliesInfo,
                        replyCount: prevState.replyCount + 1
                    },
                    () => {
                        // Reset new reply
                        this.props.storeNewReply(null);
                    }
                );
            }
        }
    }

    editButtonClick() {
        //console.log("Comment / editButtonClick");
    }

    openDeleteModal() {
        this.setState({
            deleteModalOn: true
        });
    }

    closeDeleteModal() {
        this.setState({
            deleteModalOn: false
        });
    }

    deleteModalOutsideClick(event) {
        if (this.deleteModalReference && !this.deleteModalReference.contains(event.target)) {
            this.setState(
                {
                    deleteModalOn: false
                }
            );
        }
    }

    getDeleteModalReference(node) {
        this.deleteModalReference = node;
    }

    replyButtonClick (replyButtonClickProps) {
        // console.log("Comment / replyButtonClick - replyButtonClickProps = ", replyButtonClickProps);
        this.setState({
            replyInputOn: ((!this.state.replyInputOn) ||
             ((this.state.replyInputOn) && (this.state.replyTargetUserID !== replyButtonClickProps.targetUserID))),
            replyTargetUserID: replyButtonClickProps.targetUserID
        });
    }

    replyInputResize() {
        if (this.replyInputRef.current) {
            // console.log("replyInputResize() - this.replyInputRef.current.style = ", this.replyInputRef.current.style);
            //this.replyInputRef.current.style.height = "auto";
            //this.replyInputRef.current.style.height = this.replyInputRef.current.scrollHeight + "px";

            // Below approach of using setTimeout() function raises an Uncaught Type error.
            window.setTimeout(
                () => {
                    this.replyInputRef.current.style.height = "auto";
                    this.replyInputRef.current.style.height = this.replyInputRef.current.scrollHeight + "px";
                },
                0
            );
        }
    }

    handleFocus() {
        if (this.replyInputRef.current) {
            // Clear the placeholder
            this.replyInputRef.current.placeholder = "";

            // Text align
            this.replyInputRef.current.style.textAlign = "left";
        }
    }

    handleBlur() {
        // On Blur event, remove event listeners and remove reply input bar.
        if (this.replyInputRef.current) {
            // Reset the placeholder
            this.replyInputRef.current.placeholder = this.replyInputPlaceholder;

            // Update state
            this.setState({ replyInputOn: false });
        }
    }

    handleReset() {
        // Reset the input value
        this.replyInputRef.current.value = "";

        // Reset replyInputRef.current height
        window.setTimeout(
            () => {
                this.replyInputRef.current.style.height = "auto";
                //this.replyInputRef.current.style.height = this.replyInputRef.current.scrollHeight + "px";
            },
            0
        );
    }

    handleSubmit(e) {
        //console.log("Comment / handleSubmit - e.target = ", e.target);

        // Submit only when enter is pressed
        if (e.keyCode === 13 && e.shiftKey === false) {
            // Stop event propagation
            e.stopPropagation();

            // Send message only if there is any content excluding whitespace
            if (this.replyInputRef.current.value.replace(/[\s]/g, "").length > 0) {
                this.createReply();
            }

            // Reset and Blur the comment input
            this.handleReset();
            this.handleBlur();
        }
        else {
            this.replyInputResize();
        }
    }

    createReply(message) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            console.log("Comment / createReply - axiosCallback - response.data.content = ", response.data.content);
        }

        // JSON to send to the API
        const dataJSON = {
            board_id: this.props.boardID,
            parent_id: this.props.commentInfo.id,
            content: this.replyInputRef.current.value
        };
        //console.log("Comment / createReply - dataJSON = ", dataJSON);

        // Send POST request using axios with CSRF token
        postCreateComment(dataJSON)
        .then(axiosCallback)
        .catch((response) => {console.log("Comment / createReply - Axios error = ", response);});
    }

    replyCountClick () {
        // Below process is called when the this.loadingReply flag is set to false
        if (this.loadingReply === false) {
            // Set the this.loadingReply flag to true, to prevent any duplicate trigerring
            // of this process
            this.loadingReply = true;

            if (this.loadingReply) {
                // Fetch more replies
                this.getMoreRepliesInfo();

                // Update folded state
                if (this.state.replyFolded) {
                    this.setState({ replyFolded: false });
                }
            } else {
                console.log("Fetching reply process is not completed.")
            }
        }

    }

    getMoreRepliesInfo () {
        // Axios callback
        const axiosCallback = (response) => {
            // console.log("Comment / getMoreRepliesInfo - respose.data.content = ", response.data.content);
            this.setState(
                {
                    repliesInfo: response.data.content.concat(this.state.repliesInfo)
                },
                () => { this.loadingReply = false }
            );
        }

        // Send request only when the this.loadingReply flag is set to true
        if (this.loadingReply) {
            getSingleCommentReplies(this.props.commentInfo.id, this.state.repliesInfo.length)
            .then(axiosCallback)
            .catch((response) => {console.log("Comment / getMoreRepliesInfo - Axios error = ", response)})
        }
    }

    deleteReply(replyID) {
        // Callback
        const axiosCallback = (response) => {
            // console.log("Board / deleteReply - response.data.content = ", response.data.content);

            // Make a copy of the already loaded replies
            const repliesInfo = this.state.repliesInfo.slice();

            // Find the reply to delete
            let replyIndex = null;
            for (let i = 0; i < repliesInfo.length; i++) {
                if (repliesInfo[i].id === replyID) {
                    replyIndex = i;
                }
            }

            // Remove the reply
            repliesInfo.splice(replyIndex, 1);

            // Update state
            this.setState(
                {
                    repliesInfo: repliesInfo,
                    replyCount: this.state.replyCount - 1
                }
            );
        }

        // Send delete reply request
        deleteComment(replyID)
        .then(axiosCallback)
        .catch((response) => {console.log("Comment / replyComment - Axios error = ", response);});
    }
}


class Reply extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        // Bind functions
        this.editButtonClick = this.editButtonClick.bind(this);

        // Delete modal
        this.deleteModalReference = null;
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteModalOutsideClick = this.deleteModalOutsideClick.bind(this);
        this.getDeleteModalReference = this.getDeleteModalReference.bind(this);
    }

    render() {
        // console.log("reply props = ", props);
        const userProfilePic = (this.props.replyInfo.user.profile_pic)?
            (
                (this.props.replyInfo.user.profile_pic.external_url === null)?
                    getMediaProperty(this.props.replyInfo.user.profile_pic, "t", "url", true):
                    url(this.props.replyInfo.user.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        const timeElapsed = formatTimeElapsed(this.props.replyInfo.timestamp);
        const timestamp = formatDatetime(this.props.replyInfo.timestamp, true, false, " ");

        // React Tooltip
        const timestampTooltipID = this.props.classPrefix + "-timestamp-toolltip"
        const userTooltipID = this.props.classPrefix + "-user-tooltip";
        const userTooltipContent = this.props.replyInfo.user.name;

        // Interaction Buttons - Reply Button
        let replyButton = null;
        let replyButtonTooltipText = null;
        let replyButtonTooltipID = null;
        let replyButtonTooltip = null;

        if (this.props.loggedIn) {
            const replyButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/reply-black.png") :
                getStaticPath("/images/common/reply-white.png");

            replyButtonTooltipText = "Reply";
            replyButtonTooltipID = this.props.classPrefix + "-reply-button-tooltip";

            const replyButtonClickProps = {
                userID: this.props.userInfo.id,
                targetUserID: this.props.replyInfo.user.id,
                commentID: this.props.replyInfo.comment
            };

            replyButton = (
                <div
                    className = "reply-reply-button  image-button-s6"
                    style = {{ backgroundImage: replyButtonImage }}
                    onClick = {() => this.props.replyButtonClick(replyButtonClickProps)}
                    data-tip = {replyButtonTooltipText}
                    data-for = {replyButtonTooltipID}
                >
                </div>
            );

            replyButtonTooltip = (
                <ReactTooltip
                    id = {replyButtonTooltipID}
                    className = "reply-reply-button-tooltip tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html = {true}
                />
            );
        }

        // Edit and delete button
        let editButton = null;
        let editButtonTooltip = null;
        let deleteButton = null;
        let deleteButtonTooltip = null;

        // Show edit and delete buttons
        if (this.props.loggedIn) {
            if (this.props.replyInfo.user.id === this.props.userInfo.id) {
                /*
                const editButtonID = "reply-edit-button-" + this.props.replyInfo.id;
                const editButtonTooltipID = "reply-edit-button-tooltip-" + this.props.replyInfo.id;
                const editButtonImage = getStaticPath("/images/icons/edit.png");

                editButton = (
                    <div id = {editButtonID}
                        className = "reply-edit-button image-button-s9"
                        style = {{
                            backgroundImage: editButtonImage
                        }}
                        data-tip = "Edit Reply"
                        data-for = {editButtonTooltipID}
                        onClick = {this.editButtonClick}
                    >
                    </div>
                );

                editButtonTooltip = (
                    <ReactTooltip
                        id = {editButtonTooltipID}
                        className = {"reply-edit-button-tooltip tooltip-s2"}
                        type = "dark"
                        place = "top"
                        html = {false}
                    />
                );
                */

                const deleteButtonID = "reply-delete-button-" + this.props.replyInfo.id;
                const deleteButtonTooltipID = "reply-delete-button-tooltip-" + this.props.replyInfo.id;
                const deleteButtonImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/common/trash-can-black.png") :
                    getStaticPath("/images/common/trash-can-white.png");

                deleteButton = (
                    <div id = {deleteButtonID}
                        className = "reply-delete-button image-button-s9"
                        style = {{
                            backgroundImage: deleteButtonImage
                        }}
                        data-tip = "Delete Reply"
                        data-for = {deleteButtonTooltipID}
                        onClick = {this.openDeleteModal}
                    >
                    </div>
                );

                deleteButtonTooltip = (
                    <ReactTooltip
                        id = {deleteButtonTooltipID}
                        className = {"reply-delete-button-tooltip tooltip-s2"}
                        type = "dark"
                        place = "top"
                        html = {false}
                    />
                );
            }
        }



        /*
        ============================================================================================
            Delete modal
        ============================================================================================
        */

        const deleteModal = (this.state.deleteModalOn)? (
            <div className = "reply-delete-modal">
                <div ref = {this.getDeleteModalReference}
                    className = {(this.props.colorMode === "day")?
                        "reply-delete-modal-content modal-day" :
                        "reply-delete-modal-content modal-night"}
                >
                    <div className = "reply-delete-modal-content__title w2">
                        Delete Reply
                    </div>

                    <div className = "reply-delete-modal-content__text g4">
                        Are you sure to delete your reply?
                    </div>

                    <div className = "reply-delete-modal-content__confirm">
                        <div className = "reply-delete-modal-content__confirm__select">
                            <div className = "reply-delete-modal-content__confirm__select__yes button-gray-s2"
                                onClick = {() => {this.props.deleteReply(this.props.replyInfo.id);}}
                            >
                                Yes
                            </div>
                            <div className = "reply-delete-modal-content__confirm__select__no button-gray-s2"
                                onClick = {this.closeDeleteModal}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Main JSX
        ============================================================================================
        */

        return(
          <div className = "reply-container">
            {deleteModal}
            <div className = "reply-upper">
                <div className = "reply-left">
                    <Link
                        className = {(this.props.colorMode === "day")?
                            "reply-user-profile-pic profile-image-s4 border-day" :
                            "reply-user-profile-pic profile-image-s4 border-night"}
                        style = {{ backgroundImage: userProfilePic }}
                        to = {`/users/${this.props.replyInfo.user.id}`}
                        data-tip = {userTooltipContent}
                        data-for = {userTooltipID}
                    >
                    </Link>
                    <ReactTooltip
                        id = {userTooltipID}
                        className = {"reply-user-profile-pic-tooltip tooltip-s2"}
                        type = "dark"
                        place = "bottom"
                        html = {true}
                    />
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "reply-middle-day" : "reply-middle-night"}
                >
                    <Link
                        className = {(this.props.colorMode === "day")?
                            "reply-user-name k4" : "reply-user-name w4"}
                        to = {`/users/${this.props.replyInfo.user.id}`}
                    >
                        {this.props.replyInfo.user.name}
                    </Link>
                    <span className = {(this.props.colorMode === "day")?
                            "reply-content comment-dg4" : "reply-content comment-g4"}
                    >
                        {this.props.replyInfo.content}
                    </span>
                    <span
                        className = {(this.props.colorMode === "day")?
                            "reply-timestamp lb4" : "reply-timestamp b4"}
                        data-for = {timestampTooltipID}
                        data-tip = {timestamp}
                    >
                        {timeElapsed}
                    </span>
                    <ReactTooltip
                        id = {timestampTooltipID}
                        className = {"reply-timestamp-tooltip tooltip-s2"}
                        type = "info"
                        place = "right"
                        html = {true}
                    />

                    {deleteButton}
                    {editButton}
                    {deleteButtonTooltip}
                    {editButtonTooltip}

                </div>
                <div className = "reply-right">
                    <div className = "reply-interactions">
                        {replyButton}
                        {replyButtonTooltip}
                    </div>
                </div>
            </div>
          </div>
        );
    }

    componentDidMount() {
        // Modal outside click
        document.addEventListener("mousedown", this.deleteModalOutsideClick);
    }

    componentWillUnmount() {
        // Modal outside click
        document.removeEventListener("mousedown", this.deleteModalOutsideClick);
    }

    editButtonClick() {
        //console.log("Reply / editButtonClick");
    }

    openDeleteModal() {
        this.setState({
            deleteModalOn: true
        });
    }

    closeDeleteModal() {
        this.setState({
            deleteModalOn: false
        });
    }

    deleteModalOutsideClick(event) {
        if (this.deleteModalReference && !this.deleteModalReference.contains(event.target)) {
            this.setState(
                {
                    deleteModalOn: false
                }
            );
        }
    }

    getDeleteModalReference(node) {
        this.deleteModalReference = node;
    }
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        newComment: state.board.newComment,
        newReply: state.board.newReply
    };
}

// Dispatch to Redux store
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeNewComment,
            storeNewReply
        },
        dispatch
    );
}

// Export component
export default connect(mapStateToProps, mapDispatchToProps)(Board);
