/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    ItineraryOverviewItem.js
--------------------------------------------------------------------------------------------
    Content
    - Individual item for ItineraryOverview
============================================================================================
*/

// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

// Redux
import {
    storeUser,
    storeShare
} from "actions";

// Modules
//import ReactTooltip from "thedots-tooltip";

// Components
import {
    Gallery,
    mediaDimensions
} from "components/Gallery";

import {
    DotStats
} from "components/DotStats";

// Functions
import {
    getStaticPath,
    getMediaProperty,
    sortMedia,
    getTransitImage
} from "js/Functions";

import {
    regularCuration
} from "js/Curation";


// Individual items
class ItineraryOverviewItem extends Component{
    constructor(props) {
        super(props);

        // Media
        const mediaSorted = sortMedia(this.props.dotInfo);
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Set the start and end media indices
        let startMediaIndex, endMediaIndex;

        // If more than one media --> thumbnails will be displayed
        if (media.length > 1) {
            // Index of the first thumbnail of the first page
            startMediaIndex = 0;

            // Index of the last thumbnail of the first page
            if (media.length > this.props.numThumbnails) {
                endMediaIndex = this.props.numThumbnails - 1;
            }
            else {
                endMediaIndex = media.length - 1;
            }
        }
        else {
            startMediaIndex = null;
            endMediaIndex = null;
        }

        // Stats widths
        this.statsWidth = 60;
        this.statsWidthSmall= 50;
        this.statsPaddingWidth = 1;
        this.statsHeight = 8;
        this.statsHeightSmall = 6;

        // Initialize states
        this.state = {
            // Media
            media: media,

            // Media indices
            selectedMediaIndex: 0,
            startMediaIndex: startMediaIndex,
            endMediaIndex: endMediaIndex,
        };


        // Bind functions
        this.setState = this.setState.bind(this);
        this.updateThumbnails = this.updateThumbnails.bind(this);
        this.thumbnailPrevPage = this.thumbnailPrevPage.bind(this);
        this.thumbnailNextPage = this.thumbnailNextPage.bind(this);

        // Gallery functions
        this.selectMediaClick = this.selectMediaClick.bind(this);
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);
    }


    render () {
        //console.log("ItineraryOverviewItem / render - this.props = ", this.props);
        //console.log("ItineraryOverviewItem / render - this.props.thumbnailsNarrowLayout = ", this.props.thumbnailsNarrowLayout);

        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && this.props.userInfo !== null);

        // Common dot
        const commonDot = (this.props.dotInfo.type === "EV" || this.props.dotInfo.type === "AU");

        /*
        ============================================================================================
            Effective Browser Width
        ============================================================================================
        */

        // Effective browser width
        const effectiveBrowserWidth = this.props.browserWidthPixels - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        const minContentWidth = this.props.minContentWidth - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        const scaleContentWidth = this.props.scaleContentWidth - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        const maxContentWidth = this.props.maxContentWidth - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        //console.log("ItineraryOverviewItem / render - effectiveBrowserWidth = ", effectiveBrowserWidth);


        /*
        ============================================================================================
            Shadow Image
        ============================================================================================
        */

        // Shadow image
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette.png");


        /*
        ============================================================================================
            Gallery
        ============================================================================================
        */

        let gallery = null;
        let galleryDimensions = null;
        if (this.state.media.length > 0) {
            // Get the image dimensions for the right size (small size)
            const mediaInfo = this.state.media[this.state.selectedMediaIndex];
            const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
            const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

            // Is video
            const isVideo = (this.state.media[this.state.selectedMediaIndex].type === "video");

            // Gallery dimensions
            galleryDimensions = mediaDimensions({
                colorMode: this.props.colorMode,
                effectiveBrowserWidth: effectiveBrowserWidth,
                isVideo: isVideo,
                mediaArea: this.props.mediaArea,
                mediaWidth: mediaWidth,
                mediaHeight: mediaHeight,
                minAspectRatio: this.props.minAspectRatio,
                maxAspectRatio: this.props.maxAspectRatio,
                minContentWidth: minContentWidth,
                scaleContentWidth: scaleContentWidth,
                maxContentWidth: maxContentWidth
            });
            //console.log("this.props.effectiveBrowserWidth = ", this.props.effectiveBrowserWidth);
            //console.log("galleryDimensions.finalMediaWidth = ", galleryDimensions.finalMediaWidth);

            // Gallery props
            const galleryProps = Object.assign(
                galleryDimensions,
                {
                    // General
                    id: "",
                    idPrefix: "itinerary-overview-item",
                    classPrefix: "itinerary-overview-item",
                    selectedMediaIndex: this.state.selectedMediaIndex,
                    media: this.state.media,
                    size: "s",
                    startPlaying: false,
                    checkScroll: this.props.checkScroll,
                    nextMediaClick: this.nextMediaClick,
                    prevMediaClick: this.prevMediaClick,
                    navDotClick: this.navDotClick,
                    index: null,
                    square: false,
                    muted: false,

                    // Interaction buttons
                    userTagOn: true,
                    dotTagOn: false,
                    unsaveOn: false,
                    enlargeOn: true,
                    dotInfo: this.props.dotInfo,
                    tagMode: "save"
                }
            );

            gallery = (
                <Gallery {...galleryProps} />
            )
        }


        /*
        ============================================================================================
            Thumbnails
        ============================================================================================
        */

        // Calculate thumbnail image sizes
        let maxThumbnailWidth = 0;
        const thumbnailWidths = [];
        const thumbnailHeights = [];
        if (this.state.media.length > 1) {
            for (let i = 0; i < this.state.media.length; i++) {
                // Pick the properties of the right size
                const thumbnailMediaWidth = getMediaProperty(this.state.media[i], "s", 'width', false);
                const thumbnailMediaHeight = getMediaProperty(this.state.media[i], "s", 'height', false);
                const thumbnailResizeRatio = Math.sqrt(this.props.thumbnailArea / (thumbnailMediaWidth * thumbnailMediaHeight));
                const resizedThumbnailWidth = Math.round(thumbnailMediaWidth * thumbnailResizeRatio);
                const resizedThumbnailHeight = Math.round(thumbnailMediaHeight * thumbnailResizeRatio);

                if (resizedThumbnailWidth > maxThumbnailWidth) {
                    maxThumbnailWidth = resizedThumbnailWidth;
                }

                thumbnailWidths.push(resizedThumbnailWidth);
                thumbnailHeights.push(resizedThumbnailHeight);

                /*
                console.log("ItineraryOverview / render - tthumbnailMediaWidth = ", thumbnailMediaWidth);
                console.log("ItineraryOverview / render - resizedThumbnailHeight = ", resizedThumbnailHeight);
                console.log("ItineraryOverview / render - resizedThumbnailWidth = ", resizedThumbnailWidth);
                console.log("ItineraryOverview / render - resizedThumbnailHeight = ", resizedThumbnailHeight);
                */
           }
        }

        // Thumbnails layout
        const thumbnailsNarrowLayout = (this.props.browserWidthPixels <
            galleryDimensions.resizedMediaWidth + this.props.leftColumnWidth +
            maxThumbnailWidth + this.props.thumbnailMarginWidth);

        /*
        console.log("ItineraryOverview / render - this.props.browserWidthPixels = ", this.props.browserWidthPixels);
        console.log("ItineraryOverview / render - galleryDimensions.resizedMediaWidth = ", galleryDimensions.resizedMediaWidth);
        console.log("ItineraryOverview / render - this.props.leftColumnWidth = ", this.props.leftColumnWidth);
        console.log("ItineraryOverview / render - maxThumbnailWidth = ", maxThumbnailWidth);
        console.log("ItineraryOverview / render - this.props.thumbnailMarginWidth = ", this.props.thumbnailMarginWidth);
        console.log("ItineraryOverview / render - galleryDimensions = ", galleryDimensions);
        */

        // Compile thumbnail list
        const thumbnailList = (this.state.media.length === 1)?
            null : this.state.media.map(
                (media, index) => {
                    if ((index >= this.state.startMediaIndex) && (index <= this.state.endMediaIndex)) {
                        // Thumbnail image
                        let thumbnailURL = null;

                        // For videos without gif animation
                        if (media.type === "video" && !("t" in media.files)) {
                            thumbnailURL = (this.props.colorMode === "day")?
                                getStaticPath("/images/common/video-black.png", true) :
                                getStaticPath("/images/common/video-white.png", true);
                        }
                        // For all else
                        else {
                            thumbnailURL = getMediaProperty(media, "t", "url", true);
                        }

                        // Thumbnail class
                        const thumbnailClass = (index === this.state.selectedMediaIndex)?
                            (
                                (this.props.colorMode === "day")?
                                    "itinerary-overview-thumbnail-on image-cover border-day" :
                                    "itinerary-overview-thumbnail-on image-cover border-night"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "itinerary-overview-thumbnail-off image-cover border-day" :
                                    "itinerary-overview-thumbnail-off image-cover border-night"
                            );

                        return(
                            <div key = {"itinerary-overview-thumbnail-container-" + index.toString()}
                                className = {(thumbnailsNarrowLayout)?
                                    "itinerary-overview-thumbnail-column" : "itinerary-overview-thumbnail-row"
                                }
                            >
                                <div className = {thumbnailClass}
                                    key = {"itinerary-overview-thumbnail-" + index.toString()}
                                    onClick = {(e) => { this.selectMediaClick(e, index); }}
                                    style = {{
                                        width: thumbnailWidths[index],
                                        height: thumbnailHeights[index],
                                        backgroundImage: thumbnailURL
                                    }}
                                >
                                    <div className = "itinerary-overview-thumbnail-shadow image-cover"
                                        style = {{ backgroundImage: shadowImage }}
                                    >
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        return null;
                    }
                }
            );

        // Arrow images
        const prevPageButtonImage = (thumbnailsNarrowLayout)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-left-black.png") :
                    getStaticPath("/images/common/arrow-left-white.png")

            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-up-black.png") :
                    getStaticPath("/images/common/arrow-up-white.png")
            );

        const nextPageButtonImage = (thumbnailsNarrowLayout)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-right-black.png") :
                    getStaticPath("/images/common/arrow-right-white.png")

            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-down-black.png") :
                    getStaticPath("/images/common/arrow-down-white.png")
            );

        // Get the thumbnail page navigation buttons
        let prevPageButton, nextPageButton;
        if (this.state.startMediaIndex > 0) {
            prevPageButton = (
                <div className = {
                        (thumbnailsNarrowLayout)?
                            "itinerary-overview-thumbnail-prev-small image-button-base" :
                            "itinerary-overview-thumbnail-prev image-button-base"
                    }
                    onClick = {(e) => this.thumbnailPrevPage(e)}
                    style = {{ backgroundImage: prevPageButtonImage }}
                >
                </div>
            );
        }
        else {
            prevPageButton = null;
        }

        if (this.state.endMediaIndex < (this.state.media.length - 1)) {
            nextPageButton = (
                <div className = {
                        (thumbnailsNarrowLayout)?
                            "itinerary-overview-thumbnail-next-small image-button-base" :
                            "itinerary-overview-thumbnail-next image-button-base"
                    }
                    onClick = {(e) => this.thumbnailNextPage(e)}
                    style = {{ backgroundImage: nextPageButtonImage }}
                >
                </div>
            );
        }
        else {
            nextPageButton = null;
        }

        // Attach previous and next page navigation buttons
        const thumbnails = (
            <div className = {(thumbnailsNarrowLayout)?
                    "itinerary-overview-thumbnails-small" : "itinerary-overview-thumbnails"}
            >
                {prevPageButton}
                {thumbnailList}
                {nextPageButton}
            </div>
        );


        /*
        ============================================================================================
            Number Column
        ============================================================================================
        */

        // Line images
        let lineImage = null;
        let bottomLineImage = null;

        // First dot
        if (this.props.isTop) {
            lineImage = (this.props.colorMode === "day")?
                getStaticPath("/images/line/vertical/solid-middle-light.png") :
                getStaticPath("/images/line/vertical/solid-middle-dark.png");
            bottomLineImage = (this.props.colorMode === "day")?
                getStaticPath("/images/line/vertical/solid-middle-light.png") :
                getStaticPath("/images/line/vertical/solid-middle-dark.png");
        }
        else {
            // Last dot
            if (this.props.isBottom) {
                lineImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/solid-middle-light.png") :
                    getStaticPath("/images/line/vertical/solid-middle-dark.png");
                bottomLineImage = null;
            }
            // Middle dot
            else {
                lineImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/solid-middle-light.png") :
                    getStaticPath("/images/line/vertical/solid-middle-dark.png");

                bottomLineImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/solid-middle-light.png") :
                    getStaticPath("/images/line/vertical/solid-middle-dark.png");
            }
        }

        // Timestamp
        const timestamp = (
            <div className = {(this.props.colorMode === "day")?
                    "itinerary-overview-timestamp-day k4" :
                    "itinerary-overview-timestamp-night w4"}
            >
                {this.props.dotTimestamp}
            </div>
        );

        // Number image
        const numberImage = (this.props.colorMode === "day")?
            getStaticPath("/images/number/double_red_day_" + this.props.number + ".png") :
            getStaticPath("/images/number/double_red_night_" + this.props.number + ".png");

        // Get the dot image
        const numberColumn = (
            <div className = "itinerary-overview-number-container">
                <div className = "itinerary-overview-number image-cover"
                    style = {{ backgroundImage: numberImage }}
                >
                </div>
            </div>
        );


        /*
        ============================================================================================
            Transit
        ============================================================================================
        */

        // Transit circle
        const circleImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/transit-down-day.png", true) :
            getStaticPath("/images/common/transit-down-night.png", true);
            //getStaticPath("/images/common/user-time-bar-dot-light.png", true) :
            //getStaticPath("/images/common/user-time-bar-dot-dark.png", true);

        const transitCircle = (
            <div className = "itinerary-overview-transit-circle image-contain"
                style = {{ backgroundImage: circleImage }}
            >
            </div>
        );

        // Transit timestamp
        const transitTimestamp = (
            <div className = {(this.props.colorMode === "day")?
                    "itinerary-overview-transit-time k4" :
                    "itinerary-overview-transit-time w4"}
            >
                {this.props.transitTimestamp}
            </div>
        );

        // Transit mode
        const transitImage = getTransitImage(this.props.transitMode, this.props.colorMode, true);
        const transitMode = (
            <div className = "itinerary-overview-transit-mode image-contain"
                style = {{ backgroundImage: transitImage }}
            >
            </div>
        );


        /*
        ============================================================================================
            Descriptions
        ============================================================================================
        */

        // Description style
        const descriptionStyle = {
            width: galleryDimensions.finalMediaWidth
        };

        // Stats width and height
        const statsWidth = (this.props.browserWidth <= 4)?
            this.statsWidthSmall : this.statsWidth;
        const statsHeight = (this.props.browserWidth <= 4)?
            this.statsHeightSmall : this.statsHeight;

        // Descriptions
        const stats = (!commonDot)? (
            <DotStats
                classPrefix = {"itinerary-overview"}
                browserWidth = {this.props.browserWidth}
                colorMode = {this.props.colorMode}
                dotInfo = {this.props.dotInfo}
                statsWidth = {statsWidth}
                statsHeight = {statsHeight}
                statsPaddingWidth = {this.statsPaddingWidth}
            />
        ) : null;

        //style = {{ opacity: this.props.isHighlight? this.opacityHighlight : this.opacityNonHighlight }}

        // Render component
        return (
            <div key = {"itinerary-overview-item-" + this.props.index.toString()}
                className = "itinerary-overview-item"
            >
                <div className = "itinerary-overview-item-row">
                    <div className = "itinerary-overview-media-left-column"
                    style = {{
                        minWidth: this.props.leftColumnWidth,
                        width: this.props.leftColumnWidth,
                        backgroundImage: lineImage
                    }}
                >
                        {numberColumn}
                    </div>
                    <div className = "itinerary-overview-media-column">

                        {(thumbnailsNarrowLayout)? thumbnails : null}

                        {timestamp}

                        <div className = "itinerary-overview-media">
                            {gallery}
                        </div>
                    </div>
                    <div className = "itinerary-overview-media-right-column"
                        style = {{ width: this.props.rightColumnWidth}}
                    >

                        {(thumbnailsNarrowLayout)? null : thumbnails}

                    </div>
                </div>
                <div className = "itinerary-overview-item-row">
                    <div className = "itinerary-overview-description-left-column"
                        style = {{
                            width: this.props.leftColumnWidth,
                            backgroundImage: bottomLineImage
                        }}
                    >
                    </div>
                    <div className = "itinerary-overview-description-column">
                        <div className = "itinerary-overview-description"
                            style = {descriptionStyle}
                        >
                            <ItineraryOverviewItemCuration
                                colorMode = {this.props.colorMode}
                                index = {this.props.index}
                                loggedIn = {loggedIn}
                                commonDot = {commonDot}
                                userInfo = {this.props.userInfo}
                                dotInfo = {this.props.dotInfo}
                                setState = {this.setState}
                                storeUser = {this.props.storeUser}
                                storeShare = {this.props.storeShare}
                            />
                        </div>
                    </div>
                </div>
                <div className = "itinerary-overview-item-row">
                    <div className = "itinerary-overview-transit-left-column"
                        style = {{
                            width: this.props.leftColumnWidth,
                            backgroundImage: bottomLineImage
                        }}
                    >
                        <div className = {(this.props.browserWidth <= 3)?
                            "itinerary-overview-transit-container-small" :
                            "itinerary-overview-transit-container"}
                        >
                            {transitCircle}
                            {transitMode}
                            {transitTimestamp}
                        </div>
                    </div>
                    <div className = "itinerary-overview-transit-column">
                        {stats}
                    </div>
                </div>

            </div>
        )
    }

    componentDidMount () {
    }


    /*
    ============================================================================================
        Gallery Navigation Functions
    ============================================================================================
    */

    selectMediaClick(e, mediaIndex) {
        this.setState(
            {
                selectedMediaIndex: mediaIndex
            }
        );
    }

    nextMediaClick() {
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex + 1)
            }
        );
    }

    prevMediaClick() {
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex - 1)
            }
        );
    }

    navDotClick(mediaIndex) {
        this.setState({
            selectedMediaIndex: mediaIndex
        });
    }

    thumbnailPrevPage (e) {
        // Determine the first and last image indices of the displayed thumbnails
        const startMediaIndex = this.state.startMediaIndex - this.props.numThumbnails;
        const endMediaIndex = this.state.startMediaIndex - 1;
        //console.log("TripOverviewItem / thumbnailPreviousPage - this.state.startMediaIndex = ", this.state.startMediaIndex);
        //console.log("TripOverviewItem / thumbnailPreviousPage - this.state.endMediaIndex = ", this.state.endMediaIndex);
        //console.log("TripOverviewItem / thumbnailPreviousPage - startMediaIndex = ", startMediaIndex);
        //console.log("TripOverviewItem / thumbnailPreviousPage - endMediaIndex = ", endMediaIndex);

        // Update thumbnail indices
        this.updateThumbnails(startMediaIndex, endMediaIndex);
    }

    thumbnailNextPage (e) {
        // Determine the first and last image indices of the displayed thumbnails
        const startMediaIndex = this.state.startMediaIndex + this.props.numThumbnails;
        const endMediaIndex = ((this.state.endMediaIndex + this.props.numThumbnails) > (this.state.media.length - 1))?
            (this.state.endMediaIndex + this.props.numThumbnails) :
            (this.state.media.length - 1);
        //console.log("TripOverviewItem / thumbnailNextPage - this.state.startMediaIndex = ", this.state.startMediaIndex);
        //console.log("TripOverviewItem / thumbnailNextPage - this.state.endMediaIndex = ", this.state.endMediaIndex);
        //console.log("TripOverviewItem / thumbnailNextPage - startMediaIndex = ", startMediaIndex);
        //console.log("TripOverviewItem / thumbnailNextPage - endMediaIndex = ", endMediaIndex);

        // Update thumbnail indices
        this.updateThumbnails(startMediaIndex, endMediaIndex);
    }

    updateThumbnails(startMediaIndex, endMediaIndex) {
        this.setState({
            startMediaIndex: startMediaIndex,
            endMediaIndex: endMediaIndex,
        });
    }
}


function ItineraryOverviewItemCuration(props) {
    /*
    ============================================================================================
        Open and seasonal status
    ============================================================================================
    */
    // Open and seasonal status
    let statusOpen = null;
    let statusSeasonal = null;

    if (!props.commonDot) {
        statusOpen = (props.dotInfo.dot_extension.is_open)? (
            <span className = {(props.colorMode === "day")?
                "itinerary-overview-status-open-day font-century" :
                "itinerary-overview-status-open-night font-century"}
            >
                Open
            </span>
        ):(
            <span className = {(props.colorMode === "day")?
                "itinerary-overview-status-closed-day font-century" :
                "itinerary-overview-status-closed-night font-century"}
            >
                Closed
            </span>
        );

        statusSeasonal = (props.dotInfo.dot_extension.closure_estimate !== null)? (
            <span className = {(props.colorMode === "day")?
                "itinerary-overview-status-seasonal-day font-century" :
                "itinerary-overview-status-seasonal-night font-century"}
            >
                Seasonal
            </span>
        ): null;
    }


    /*
    ============================================================================================
        Curation
    ============================================================================================
    */

    // Stylize curation
    const curationOverview = regularCuration(
        props.dotInfo.overview,
        "itinerary-overview",
        "itinerary-overview",
        props.colorMode
    );

    return(
        <div className = "itinerary-overview-curation">
            <div className = "itinerary-overview-header">
                <Link to = {`/dot/${props.dotInfo.slug}`} target="_blank">
                    <div className = {(props.colorMode === "day")?
                            "itinerary-overview-title k4" :
                            "itinerary-overview-title w4"}
                    >
                        {props.dotInfo.title}
                    </div>
                </Link>
                <div className = "itinerary-overview-status clear-fix">
                    {statusOpen}
                    {statusSeasonal}
                </div>
                <Link to = {`/dot/${props.dotInfo.slug}`} target="_blank">
                    <div className = {(props.colorMode === "day")?
                            "itinerary-overview-location lb4" :
                            "itinerary-overview-location b4"}
                    >
                        {props.dotInfo.name}
                    </div>
                </Link>
            </div>
            <div className = "itinerary-overview-curation">
                {curationOverview}
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeShare
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryOverviewItem);
