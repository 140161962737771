
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux"; 
import { store, persistor } from "./configureStore";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react"
import * as serviceWorker from "./serviceWorker";
import { getStaticPath } from "js/Functions";

const globeOn = true;

if (globeOn) {
    d3.json(
        getStaticPath('/json/globe.json', false),
        //"https://codepen.io/frontendcharts/pen/JBprpp.js"
    ).then(
        (json) => {
        	//console.log("index / json = ", json);
            window.geoJson = topojson.feature(json, json.objects.ne_110m_admin_0_countries);
            window.d3 = d3;

    		ReactDOM.render(
    		    <Provider store = {store}>
    		        <PersistGate
    		        	loading = {null}
    		        	persistor = {persistor}
    		    	>
    		        	<App />
    		        </PersistGate>
    		    </Provider>,
    			document.getElementById("root")
    		);
        }
    );
}
else {
    ReactDOM.render(
        <Provider store = {store}>
            <PersistGate
                loading = {null}
                persistor = {persistor}
            >
                <App />
            </PersistGate>
        </Provider>,
        document.getElementById("root")
    );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
