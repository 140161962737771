/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    User.js
    - User Info component to display user page
--------------------------------------------------------------------------------------------
    Content
    - User
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { bindActionCreators } from "redux"; 
import { connect } from "react-redux"; 

// Redux
import { storeUser } from "actions";

// Components
import { Bucket, Profile } from "components/User";
// import CompletedDots from "./UserComponents/CompletedDots";
// import CompletedTrips from "./UserComponents/CompletedTrips";

// Axios
import { 
    getUser, 
    postFollowRequest
} from "requests"; 

// CSS
import "./User.scss";


class User extends Component {
    constructor (props) {
        super(props);

        // Initialize state
        this.state = {
            targetUserInfo: null
        }
        
        // Bind functions
        this.fetchUserInfo = this.fetchUserInfo.bind(this);
        this.followRequest = this.followRequest.bind(this);
        this.unfollowRequest = this.unfollowRequest.bind(this);
    }

    fetchUserInfo (username) {
        const axiosCallback = (response) => {
            //console.log("User / fetchUserInfo - response.data.content = ", response.data.content);
            
            // Update state
            this.setState({
                targetUserInfo: response.data.content
            });
        }
        
        getUser(username)
        .then(axiosCallback)
        .catch((response) => { console.log("User / fetchUserInfo - response.error = ", response.error); })
    }

    componentDidMount () {
        const loggedIn = !!localStorage.token;
        if ((loggedIn) && (this.props.username === this.props.userInfo.username)) {
            // console.log("User / render = ", this.props.username);

            // Update state
            this.setState({
                targetUserInfo: this.props.userInfo,
            });
        } 
        else {
            this.fetchUserInfo(this.props.username);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const loggedIn = (!!localStorage.token && this.props.userInfo !== null);
        const isMyself = (loggedIn)? (this.props.username === this.props.userInfo.username) : false;

        // If need an update
        if (isMyself && JSON.stringify(this.props.userInfo) !== JSON.stringify(prevProps.userInfo)) {
            //console.log("User / componentDidUpdate - update required");

            this.setState({ 
                targetUserInfo: this.props.userInfo,
            });
        }
    }
    
    render () {
        //console.log("this.props.userInfo.following = ", this.props.userInfo.following);

        // Logged in and is myself flags
        const loggedIn = !!localStorage.token;
        let isMyself = false;
        if (loggedIn) {
            //console.log("User / render - this.props = ", this.props);
            isMyself = (this.props.username === this.props.userInfo.username);
        }

        // Bucket mode
        let bucketMode = null;
        if (this.props.history.location.state !== undefined) {
            if (this.props.history.location.state.bucketMode !== undefined) {
                bucketMode = this.props.history.location.state.bucketMode;
            }
        }
        //console.log("User / render - bucketMode = ", bucketMode);

        // Display mode
        let displayMode = null;
        if (this.props.history.location.state !== undefined) {
            if (this.props.history.location.state.displayMode !== undefined) {
                displayMode = this.props.history.location.state.displayMode;
            }
        }
        //console.log("User / render - displayMode = ", displayMode);

        // User page component
        const userPage = (this.state.targetUserInfo == null) ? null : 
        (
            <div key = {"user-page-" + this.state.targetUserInfo.id} 
                id = "user-container"
                className = {(this.props.browserWidth <= window.browserWidthSmall)?
                    "user-container-small" : "user-container"
                }
            >
                <div className = "body-narrow" 
                    style = {{ textAlign: "center" }}
                >
                    <Profile
                        isMyself = {isMyself}
                        loggedIn = {loggedIn}
                        userInfo = {this.state.targetUserInfo}
                        followRequest = {this.followRequest}
                        unfollowRequest = {this.unfollowRequest}
                        fetchUserInfo = {this.fetchUserInfo}
                    />
                </div>
                <div className = "body-narrow" 
                    style = {{ textAlign: "center" }}
                >
                    <Bucket
                        bucketMode = {bucketMode}
                        displayMode = {displayMode}
                        isMyself = {isMyself}
                        loggedIn = {loggedIn}
                        userInfo = {this.state.targetUserInfo}
                        storeUser = {this.props.storeUser}
                    />
                </div>
            </div>
        );

        return (
            <div>
                {userPage}
            </div>
        );
    }


    /*
    ============================================================================================
        Follow Request
    ============================================================================================
    */

    followRequest() {
        //console.log("User / followRequest - this.props = ", this.props);

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("User / followRequest - response.data = ", response.data);

            // Update local and global state
            this.setState(
                {
                    followedByMe: response.data.content.followed_by_me,
                    followerCount: response.data.content.follower_count
                },
                () => {
                    // Construct a shallow copy of the userInfo
                    const userInfo = Object.assign({}, this.props.userInfo);

                    // Update user info
                    userInfo.following_recent = response.data.content.following_recent; 
                    userInfo.following_count = response.data.content.following_count;

                    // Dispatch to Redux store
                    this.props.storeUser(userInfo);

                    // Update target user info
                    this.fetchUserInfo(this.props.username);
                }
            );
        };

        // Send request
        postFollowRequest("create", this.state.targetUserInfo.id)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] User / followRequest - error = ", response);})       
    }


    /*
    ============================================================================================
        Unfollow Request
    ============================================================================================
    */

    unfollowRequest() {
        //console.log("User / unfollowRequest - this.props = ", this.props);

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("User / unfollowRequest - response.data = ", response.data);

            // Update local and global state
            this.setState(
                {
                    followedByMe: response.data.content.followed_by_me,
                    followerCount: response.data.content.follower_count
                },
                () => {
                    // Construct a shallow copy of the userInfo
                    const userInfo = Object.assign({}, this.props.userInfo);

                    // Update user info
                    userInfo.following_recent = response.data.content.following_recent; 
                    userInfo.following_count = response.data.content.following_count;

                    // Dispatch to Redux store
                    this.props.storeUser(userInfo);

                    // Update target user info
                    this.fetchUserInfo(this.props.username);
                }
            );
        };

        // Send request
        postFollowRequest("destroy", this.state.targetUserInfo.id)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] User / unfollowRequest - error = ", response);})       
    }  
}


// Import necessary global state from the store as props
function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeUser }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(User);