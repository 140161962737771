/*
============================================================================================
    Search Results
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Components
import {
    SearchMap,
    SearchOpenMap
} from "components/Map";

// Functions
import {
    url,
    getStaticPath,
    getMediaProperty,
    sortMedia
} from "js/Functions";

import {
    getAverageLocation
} from "components/Map/MapFunc";

// Axios
import {
    getSearch,
    getUserSearch,
    getDotSearch,
    getTripSearch,
    getNearbySearch,
    postFetchDots,
    //postFetchMedia,
    postFetchUser
} from "requests";

// CSS
import "./SearchResults.scss";


class SearchResults extends Component {
    constructor (props) {
        super(props);

        // Margin width
        this.marginWidth = 10;

        // Container Min width
        this.containerMinWidth = 300;

        // Container Max width (180 X 6)
        this.containerMaxWidth = 1080;

        // Number of pages
        this.numPagesPerGroup = 12;

        // Map width (180 X 6)
        this.mapMaxWidth = 1080;

        // Map height
        this.mapHeight = 400;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // Map Left column width
        this.leftColumnWidth = 60;

        // Item (dot, trip)
        this.itemWidth = 180;
        this.itemPadding = 5;
        this.itemPaddingRatio = 25;

        // User result
        this.userWidth = 300;
        this.userHeight = 150;

        this.userPicSize = 80;
        this.userInfoWidth = 150;

        // Map Mode
        //this.mapSource = "google";
        this.mapSource = "open";

        // Nearby search radius unit
        this.searchRadiusDefault = 100;
        this.searchRadiusUnit = 'mi';

        // Item per page
        this.numItemsPerPage = 12

        // Search default location
        // (used only when the initial keywords search does not return any results)
        this.searchDefaultLocation = { "latitude": 37.831108, "longitude": -119.466957 };

        // Get user location
        const keywords = this.props.history.location.state.keywords;
        const userLocation = this.props.history.location.state.userLocation;
        const nearbyMode = (keywords === null && userLocation !== null);

        // Initialize state
        this.state = {
            keywords: keywords,
            userLocation: userLocation,
            searchMode: (nearbyMode)? "nearby" : "keywords",
            searchCenterLocation: (nearbyMode)? userLocation : null,
            searchRadius : (nearbyMode)? this.searchRadiusDefault : null,
            resultMode: "dot",
            dotPage: 1,
            dotsInfo: null,
            dotMaxPage: null,
            tripPage: 1,
            tripsInfo: null,
            tripMaxPage: null,
            userPage: 1,
            usersInfo: null,
            userMaxPage: null,
            hovered: null,
            selected: null,
            hoveredChildID: null,
            mapInitialized: false,
            hoveredMarker: null
        };

        // Bind functions
        this.setState = this.setState.bind(this);
        this.searchModeButtonClick = this.searchModeButtonClick.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.previousGroup = this.previousGroup.bind(this);
        this.nextGroup = this.nextGroup.bind(this);
        this.loadSearchResults = this.loadSearchResults.bind(this);
        this.fetchSearchResults = this.fetchSearchResults.bind(this);
        this.itemHoverOn = this.itemHoverOn.bind(this);
        this.itemHoverOff = this.itemHoverOff.bind(this);
        this.itemClick = this.itemClick.bind(this);
        this.switchToDotMode = this.switchToDotMode.bind(this);
        this.switchToTripMode = this.switchToTripMode.bind(this);
        this.switchToUserMode = this.switchToUserMode.bind(this);
    }


    render () {

        // Calculate number of columns
        const numColumns = (this.props.browserWidth <= 6)?
            Math.round(this.props.browserWidthPixels / this.itemWidth) :
            Math.round((this.props.browserWidthPixels - this.leftColumnWidth) / this.itemWidth);

        // Dots Results, Tips Results Container width (items)
        const containerWidth = (numColumns <= 2)?
            Math.max(
                this.containerMinWidth,
                (this.props.browserWidthPixels - 2 * this.marginWidth)
            ) : (
                (numColumns >= 6)?
                    this.containerMaxWidth :
                    numColumns * this.itemWidth
            );

        // Map width
        const mapWidth = (this.props.browserWidth <= 6)?
            "100%" : (Math.min(containerWidth - 2 * this.itemPadding, this.mapMaxWidth));

        // Map left padding width
        const mapLeftColumWidth = (this.props.browserWidth <= 6)?
            null : this.leftColumnWidth;

        // Map Container width (left padding + Map)
        const mapContainerWidth = (this.props.browserWidth <= 6)?
            "100%" : (mapLeftColumWidth + containerWidth);

        // Dots Results, Tips Results Container width (left padding + items)
        const partitionWidth = mapLeftColumWidth + containerWidth;

        // Items (dot, trip)
        const itemPadding = (numColumns <= 2)?
            (partitionWidth / 2 / this.itemPaddingRatio) :
            this.itemPadding;

        const itemSize = (numColumns <= 2)?
            (partitionWidth / 2 - 2 * itemPadding) :
            (this.itemWidth - 2 * itemPadding);

        // User Info Width (user container width - userPicSize - 2 X padding(scss))
        const userInfoWidth = this.userWidth - this.userPicSize - 20;

        // Icon
        const dotIcon = (this.props.colorMode === "day")?
        getStaticPath("/images/common/type-dot-black.png") :
        getStaticPath("/images/common/type-dot-white.png");

        const tripIcon = (this.props.colorMode === "day")?
        getStaticPath("/images/common/type-trip-black.png") :
        getStaticPath("/images/common/type-trip-white.png");

        const userIcon = (this.props.colorMode === "day")?
        getStaticPath("/images/common/user-tag-black.png") :
        getStaticPath("/images/common/user-tag-white.png");

        /*
        ============================================================================================
            Search Result Mode
        ============================================================================================
        */

        let results = null;
        const noResults = (this.state.mapInitialized)?
        (
            <div id = "search-no-results"
                className = {(this.props.colorMode === "day")? "k2" : "w2"}
            >
                No Results Have Been Returned.
            </div>
        ) : null;

        if (this.state.resultMode === "dot") {
            results = ((this.state.dotsInfo === null)?
                noResults : (
                    <Results
                        colorMode = {this.props.colorMode}
                        browserWidth = {this.props.browserWidth}
                        containerWidth = {containerWidth}
                        resultMode = {this.state.resultMode}
                        dotsInfo = {this.state.dotsInfo[this.state.dotPage]}
                        maxPage = {this.state.dotMaxPage}
                        numPagesPerGroup = {this.numPagesPerGroup}
                        page = {this.state.dotPage}
                        itemSize = {itemSize}
                        itemPadding = {itemPadding}
                        itemHoverOn = {this.itemHoverOn}
                        itemHoverOff = {this.itemHoverOff}
                        itemClick = {this.itemClick}
                        goToPage = {this.goToPage}
                        previousGroup = {this.previousGroup}
                        nextGroup = {this.nextGroup}
                        hoveredMarker = {this.state.hoveredMarker}
                    />
                )
            );
        }
        else if (this.state.resultMode === "trip") {
            results = ((this.state.tripsInfo === null)?
                noResults : (
                    <Results
                        colorMode = {this.props.colorMode}
                        browserWidth = {this.props.browserWidth}
                        containerWidth = {containerWidth}
                        resultMode = {this.state.resultMode}
                        tripsInfo = {this.state.tripsInfo[this.state.tripPage]}
                        maxPage = {this.state.tripMaxPage}
                        numPagesPerGroup = {this.numPagesPerGroup}
                        page = {this.state.tripPage}
                        itemSize = {itemSize}
                        itemPadding = {itemPadding}
                        itemHoverOn = {this.itemHoverOn}
                        itemHoverOff = {this.itemHoverOff}
                        itemClick = {this.itemClick}
                        goToPage = {this.goToPage}
                        previousGroup = {this.previousGroup}
                        nextGroup = {this.nextGroup}
                        hoveredMarker = {this.state.hoveredMarker}
                    />
                )
            );
        }
        else if (this.state.resultMode === "user") {
            results = ((this.state.usersInfo === null)?
                noResults : (
                    <Results
                        colorMode = {this.props.colorMode}
                        browserWidth = {this.props.browserWidth}
                        containerWidth = {containerWidth}
                        resultMode = {this.state.resultMode}
                        usersInfo = {this.state.usersInfo[this.state.userPage]}
                        maxPage = {this.state.userMaxPage}
                        numPagesPerGroup = {this.numPagesPerGroup}
                        page = {this.state.userPage}
                        userWidth = {this.userWidth}
                        userHeight = {this.userHeight}
                        userPicSize = {this.userPicSize}
                        userInfoWidth = {userInfoWidth}
                        itemSize = {itemSize}
                        itemPadding = {itemPadding}
                        itemHoverOn = {this.itemHoverOn}
                        itemHoverOff = {this.itemHoverOff}
                        itemClick = {this.itemClick}
                        goToPage = {this.goToPage}
                        previousGroup = {this.previousGroup}
                        nextGroup = {this.nextGroup}
                        hoveredMarker = {this.state.hoveredMarker}
                    />
                )
            );
        }

        /*
        ============================================================================================
            Center Location
        ============================================================================================
        */
        let centerLocation = (this.state.mapInitialzed)? null : (
            (this.state.userLocation === null)?
                this.searchDefaultLocation : this.state.userLocation
        );
        let dotPageKey = null;
        let tripPageKey = null;

        // Only when dotsInfo, tripsInfo is ready
        if (this.state.resultMode === "dot" && this.state.dotsInfo !== null) {
            // Only when dotsInfo is ready
            if (this.state.dotsInfo !== null) {
                // Get the page key
                dotPageKey = this.state.dotPage.toString();

                // Get dot indices
                const dotIndices = [];
                for (let i = 0; i < this.state.dotsInfo[dotPageKey].length; i++) {
                    dotIndices.push(i);
                }

                // Calculate average location
                centerLocation = getAverageLocation(dotIndices, this.state.dotsInfo[this.state.dotPage]);
            }
        }
        else if (this.state.resultMode === "trip" && this.state.tripsInfo !== null) {
            // Only when tripsInfo is ready
            if (this.state.tripsInfo !== null) {
                // Get the page key
                tripPageKey = this.state.tripPage.toString();

                // Get dot indices
                const tripIndices = [];
                for (let i = 0; i < this.state.tripsInfo[tripPageKey].length; i++) {
                    tripIndices.push(i);
                }

                // Calculate average location
                centerLocation = getAverageLocation(tripIndices, this.state.tripsInfo[this.state.tripPage]);
            }
        }
        else if (this.state.resultMode === "user" && this.state.dotsInfo !== null) {
            // Only when dotsInfo is ready
            if (this.state.dotsInfo !== null) {
                // Get the page key
                dotPageKey = this.state.dotPage.toString();

                // Get dot indices
                const dotIndices = [];
                for (let i = 0; i < this.state.dotsInfo[dotPageKey].length; i++) {
                    dotIndices.push(i);
                }

                // Calculate average location
                centerLocation = getAverageLocation(dotIndices, this.state.dotsInfo[this.state.dotPage]);
            }
        }

        /*
        ============================================================================================
            Dots Data to Feed into Map
        ============================================================================================
        */

        const searchDotsInfo = (this.state.resultMode === "dot")?
        (
            (this.state.dotsInfo !== null)?
                this.state.dotsInfo[this.state.dotPage] : []
        ) : (
            (this.state.tripsInfo !== null)?
                this.state.tripsInfo[this.state.tripPage] : []
        );

        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        let map = null;

        // Using Google maps
        if (this.mapSource === "google") {
            // Only when google object and locations are ready
            if (this.state.mapInitialized && searchDotsInfo !== null) {
                const mapProps = {
                    // Google
                    google: this.props.google,

                    // Map mode
                    resultMode: this.state.resultMode,

                    // Map width
                    mapContainerWidth: mapContainerWidth,
                    leftColumnWidth:mapLeftColumWidth,
                    mapWidth: mapWidth,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Map Center location
                    mapZoom: 12,
                    mapCenter: centerLocation,
                    mapType: "roadmap",

                    // Keywords
                    keywords: this.state.keywords,

                    // Search radius unit
                    searchRadiusUnit: this.searchRadiusUnit,

                    // Page and max page
                    dotPage: this.state.dotPage,
                    dotMaxPage: this.state.dotMaxPage,
                    tripPage: this.state.tripPage,
                    tripMaxPage: this.state.tripMaxPage,

                    // Dots
                    dotsInfo: searchDotsInfo,

                    // Selected and hovered
                    selected: this.state.selected,
                    hovered: this.state.hovered,
                    hoveredChildID: this.state.hoveredChildID,
                    hoveredMarker: this.state.hoveredMarker,

                    // Set state
                    setState: this.setState
                };

                // Get the Map component
                map = (
                    <SearchMap
                        {...mapProps}
                    />
                );
            }
        }

        // Using Open Street Maps
        else if (this.mapSource === "open") {
            // Only when google object and locations are ready
            if (this.state.mapInitialized && searchDotsInfo !== null) {
                const mapProps = {
                    // History
                    history: this.props.history,

                    // Map mode
                    resultMode: this.state.resultMode,

                    // Map width
                    mapContainerWidth: mapContainerWidth,
                    leftColumnWidth:mapLeftColumWidth,
                    mapWidth: mapWidth,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Map Center location
                    mapZoom: 12,
                    mapCenter: centerLocation,
                    mapType: "roadmap",

                    // Keywords
                    keywords: this.state.keywords,

                    // Search radius unit
                    searchRadiusUnit: this.searchRadiusUnit,

                    // Page and max page
                    dotPage: this.state.dotPage,
                    dotMaxPage: this.state.dotMaxPage,
                    tripPage: this.state.tripPage,
                    tripMaxPage: this.state.tripMaxPage,

                    // Dots
                    dotsInfo: searchDotsInfo,

                    // Selected and hovered
                    selected: this.state.selected,
                    hovered: this.state.hovered,
                    hoveredChildID: this.state.hoveredChildID,
                    hoveredMarker: this.state.hoveredMarker,

                    // Set state
                    setState: this.setState
                };

                // Get the Map component
                map = (
                    <SearchOpenMap
                        {...mapProps}
                    />
                );
            }
        }

        /*
        ============================================================================================
            Body
        ============================================================================================
        */
        return (
            <div id = "search-results-container"
                className = {(this.props.browserWidth <= window.browserWidthSmall)?
                    "search-results-container-small" : "search-results-container"}
            >
                <div id = "search-results-map">
                    {map}
                </div>
                <div className = "search-results-partition"
                    style= {{ width : partitionWidth }}
                >
                    <div id = {(this.props.browserWidth <= 6)?
                        "search-results-header-small" : "search-results-header"}
                    >
                        <div className = "search-results-mode-container"
                            style = {{ display:(this.state.dotIDs === null)? "none" : "inline-block" }}
                        >
                            <div className = {(this.state.resultMode === "dot")?
                                "search-results-mode-button-hover": "search-results-mode-button"}
                                style = {{display:"inline-block"}}
                                onClick = {this.switchToDotMode}
                            >
                                <div className = {(this.props.browserWidth <= 6)?
                                    "search-results-dots-icon-small" : "search-results-dots-icon"}
                                    style = {{ backgroundImage: dotIcon }}
                                >
                                </div>
                                <div id = {(this.props.browserWidth <= 6)?
                                    "search-results-text-small" : "search-results-text"}
                                    className = {(this.props.browserWidth <= 6)?
                                        ((this.props.colorMode === "day")? "k1" : "w1"):
                                        ((this.props.colorMode === "day")? "k4" : "w4")}
                                >
                                    Dots
                                </div>
                            </div>
                        </div>
                        <div className = "search-results-mode-container"
                            style = {{ display:(this.state.tripIDs === null)? "none" : "inline-block" }}
                        >
                            <div className = {(this.state.resultMode === "trip")?
                                "search-results-mode-button-hover": "search-results-mode-button"}
                                style = {{display:"inline-block"}}
                                onClick = {this.switchToTripMode}
                            >
                                <div className = {(this.props.browserWidth <= 6)?
                                    "search-results-trips-icon-small" : "search-results-trips-icon"}
                                    style = {{ backgroundImage: tripIcon }}
                                >
                                </div>
                                <div id = {(this.props.browserWidth <= 6)?
                                    "search-results-text-small" : "search-results-text"}
                                    className = {(this.props.browserWidth <= 6)?
                                        ((this.props.colorMode === "day")? "k1" : "w1"):
                                        ((this.props.colorMode === "day")? "k4" : "w4")}
                                >
                                    Trips
                                </div>
                            </div>
                        </div>
                        <div className = "search-results-mode-container"
                            style = {{ display:(this.state.userIDs === null)? "none" : "inline-block" }}
                        >
                            <div className = {(this.state.resultMode === "user")?
                                "search-results-mode-button-hover": "search-results-mode-button"}
                                style = {{display:"inline-block"}}
                                onClick = {this.switchToUserMode}
                            >
                                <div className = {(this.props.browserWidth <= 6)?
                                    "search-results-users-icon-small" : "search-results-users-icon"}
                                    style = {{ backgroundImage: userIcon }}
                                >
                                </div>
                                <div id = {(this.props.browserWidth <= 6)?
                                    "search-results-text-small" : "search-results-text"}
                                    className = {(this.props.browserWidth <= 6)?
                                        ((this.props.colorMode === "day")? "k1" : "w1"):
                                        ((this.props.colorMode === "day")? "k4" : "w4")}
                                >
                                    Users
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id = "search-results"
                        style = {{ width: containerWidth }}
                    >
                        {results}
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("SearchResults / componentDidUpdate");

        if (prevProps.history.location.state.keywords !== this.props.history.location.state.keywords) {
            console.log("keywords = ", this.props.history.location.state.keywords);
            console.log("previous keywords = ", prevProps.history.location.state.keywords);

            // Get user location
            const keywords = this.props.history.location.state.keywords;

            const callback = () => {
                // Execute first search
                this.loadSearchResults(
                    "all",
                    keywords,
                    true,
                    () => { this.fetchSearchResults(this.state.resultMode, keywords, 1, true); }
                )
            };

            this.setState(
                {
                    searchMode: "keywords"
                },
                callback
            );
        }
    }

    componentDidMount() {
        //console.log("SearchResults / componentDidMount");

        // Execute first search
        this.loadSearchResults(
            (this.state.searchMode === "nearby")? "dot" : "all",
            this.state.keywords,
            true,
            () => { this.fetchSearchResults(this.state.resultMode, this.state.keywords, 1, true); }
        )
    }

    searchModeButtonClick(event) {
        if (this.state.searchMode === "keywords") {
            this.setState(
                {
                    searchMode: "nearby"
                }
            );
        }
        else if (this.state.searchMode === "nearby") {
            this.setState(
                {
                    searchMode: "keywords"
                }
            );
        }
    }


    goToPage(page, type, resetData) {
        if (type === "dot") {
            if (Object.keys(this.state.dotsInfo).indexOf(page) >= 0) {
                // Change page
                this.setState({ dotPage: page });
            }
            else {
                // Execute search for given page
                this.fetchSearchResults("dot", this.state.keywords, page, false);
            }
        }
        else if (type === "trip") {
            if (Object.keys(this.state.tripsInfo).indexOf(page) >= 0) {
                // Change page
                this.setState({ tripPage: page });
            }
            else {
                // Execute search for given page
                this.fetchSearchResults("trip", this.state.keywords, page, false);
            }
        }
        else if (type === "user") {
            if (Object.keys(this.state.usersInfo).indexOf(page) >= 0) {
                // Change page
                this.setState({ userPage: page });
            }
            else {
                // Execute search for given page
                this.fetchSearchResults("user", this.state.keywords, page, false);
            }
        }
    }

    previousGroup(type) {
        let previousPage = null;
        if (type === "dot") {
            previousPage = (Math.floor((this.state.dotPage - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1;
        }
        else if (type === "trip") {
            previousPage = (Math.floor((this.state.tripPage - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1;
        }
        else if (type === "user") {
            previousPage = (Math.floor((this.state.userPage - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1;
        }

        this.goToPage(previousPage, type, false);
    }

    nextGroup(type) {
        let nextPage = null;
        if (type === "dot") {
            nextPage = (Math.floor((this.state.dotPage - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1;
        }
        else if (type === "trip") {
            nextPage = (Math.floor((this.state.tripPage - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1;
        }
        else if (type === "user") {
            nextPage = (Math.floor((this.state.userPage - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1;
        }

        this.goToPage(nextPage, type, false);
    }

    loadSearchResults(type, keywords, resetData, callback) {

        // Convert search location to string
        const centerLocationString = (this.state.searchMode !== "keywords")?
            (
                "{'latitude':"
                + this.state.searchCenterLocation.latitude
                + ",'longitude':"
                + this.state.searchCenterLocation.longitude
                + "}"
            ) : null;

        const axiosCallback = (response) => {

            let dotIDs = null;
            let tripIDs = null;
            let userIDs = null;
            let dotMaxPage = null;
            let tripMaxPage = null;
            let userMaxPage = null;

            if (type === "all" || type === "dot") {
                dotIDs = (resetData)? {} : Object.assign({}, this.state.dotIDs);
                dotMaxPage = this.state.dotMaxPage;

                if (response.data.content.dot_ids.length === 0) {
                    dotIDs = null;
                    dotMaxPage = null;
                }
                else {
                    dotIDs = response.data.content.dot_ids;
                    dotMaxPage = Math.ceil(dotIDs.length / this.numItemsPerPage);
                }

                if (type === "dot") {
                    this.setState(
                        {
                            dotIDs: dotIDs,
                            dotMaxPage: dotMaxPage,
                            keywords: keywords
                        },
                        callback
                    )
                }
            }

            if (type === "all" || type === "trip") {
                tripIDs = (resetData)? {} : Object.assign({}, this.state.tripIDs);
                tripMaxPage = this.state.tripMaxPage;

                if (response.data.content.trip_ids.length === 0) {
                    tripIDs = null;
                    tripMaxPage = null;
                }
                else {
                    tripIDs = response.data.content.trip_ids;
                    tripMaxPage = Math.ceil(tripIDs.length / this.numItemsPerPage);
                }

                if (type === "trip") {
                    this.setState(
                        {
                            tripIDs: tripIDs,
                            tripMaxPage: tripMaxPage,
                            keywords: keywords
                        },
                        callback
                    )
                }
            }

            if (type === "all" || type === "user") {
                userIDs = (resetData)? {} : Object.assign({}, this.state.userIDs);
                userMaxPage = this.state.userMaxPage;

                if (response.data.content.user_ids.length === 0) {
                    userIDs = null;
                    userMaxPage = null;
                }
                else {
                    userIDs = response.data.content.user_ids;
                    userMaxPage = Math.ceil(userIDs.length / this.numItemsPerPage);
                }

                if (type === "user") {
                    this.setState(
                        {
                            userIDs: userIDs,
                            userMaxPage: userMaxPage,
                            keywords: keywords
                        },
                        callback
                    )
                }
            }

            if (type === "all") {
                let resultMode = "dot";
                if (dotIDs.length === 0 && tripIDs.length !== 0) {
                    resultMode = "trip";
                }
                else if (dotIDs.length === 0 && tripIDs.length ===0 && userIDs.length !== 0) {
                    resultMode = "user";
                }

                this.setState(
                    {
                        resultMode: resultMode,
                        dotIDs: dotIDs,
                        dotMaxPage: dotMaxPage,
                        tripIDs: tripIDs,
                        tripMaxPage: tripMaxPage,
                        userIDs: userIDs,
                        userMaxPage: userMaxPage,
                        keywords: keywords,
                        dotPage: 1,
                        tripPage: 1,
                        userPage: 1
                    },
                    callback
                )
            }
        };

        if (this.state.searchMode === "keywords") {
            if (type === "dot") {
                getDotSearch(type, keywords)
                .then(axiosCallback)
                .catch((response) => {
                    console.log("[WARNING] Search / loadSearchResults - Axios error ", response)
                })
            }
            else if (type === "trip") {
                getTripSearch(type, keywords)
                .then(axiosCallback)
                .catch((response) => {
                    console.log("[WARNING] Search / loadSearchResults - Axios error ", response)
                })
            }
            else if (type === "user") {
                getUserSearch(keywords)
                .then(axiosCallback)
                .catch((response) => {
                    console.log("[WARNING] Search / loadSearchResults - Axios error ", response)
                })
            }
            else {
                getSearch(type, keywords)
                .then(axiosCallback)
                .catch((response) => {
                    console.log("[WARNING] Search / loadSearchResults - Axios error ", response)
                })
            }
        }
        else {
            getNearbySearch(
                (type === "dot")? "dots" : "trips",
                centerLocationString,
                this.state.searchRadius,
                this.searchRadiusUnit,
                keywords
            )
            .then(axiosCallback)
            .catch((response) => {
                console.log("[WARNING] Search / nearbySearch - error = ", response);
            });
        }
    }

    fetchSearchResults(type, keywords, page, resetData, callback) {

        // Axios callback
        const axiosCallback = (response) => {
            //console.log("SearchResult / fetchSearchResults - response = ", response);

            // Initialize results
            let dotsInfo = null;
            let tripsInfo = null;
            let usersInfo = null;

            // Searched dots
            if (type === "dot") {
                dotsInfo = (resetData)? {} : Object.assign({}, this.state.dotsInfo);

                if (response.data.content.dots === null) {
                    dotsInfo = null;
                }
                else {
                    dotsInfo[page.toString()] = response.data.content.dots;
                }

                if (type === "dot") {
                    this.setState(
                        {
                            dotsInfo: dotsInfo,
                            keywords: keywords,
                            dotPage: page,
                            mapInitialized: true
                        },
                        callback
                    );
                }
            }

            // Searched trips
            if (type === "trip") {
                tripsInfo = (resetData) ? {} : Object.assign({}, this.state.tripsInfo);

                if (response.data.content.dots === null) {
                    tripsInfo = null;
                }
                else {
                    tripsInfo[page.toString()] = response.data.content.dots;
                }

                if (type === "trip") {
                    this.setState(
                        {
                            tripsInfo: tripsInfo,
                            keywords: keywords,
                            tripPage: page,
                            mapInitialized: true
                        },
                        callback
                    );
                }
            }

            // Searched users
            if (type === "user") {
                usersInfo = (resetData) ? {} : Object.assign({}, this.state.usersInfo);

                if (response.data.content.users === null) {
                    usersInfo = null;
                }
                else {
                    usersInfo[page.toString()] = response.data.content.users;
                }

                if (type === "user") {
                    this.setState(
                        {
                            usersInfo: usersInfo,
                            keywords: keywords,
                            mapInitialized: true
                        },
                        callback
                    );
                }
            }

        };

        if (type === "dot") {
            let dotIDs = this.state.dotIDs.slice(this.numItemsPerPage * (page - 1), this.numItemsPerPage * page)
            const dataJSON = {
                dot_ids: dotIDs
            };

            postFetchDots(dataJSON)
            .then(axiosCallback)
            .catch((response) => {
                console.log("[WARNING] Search / handleAxios - Axios error ", response)
            })
        }
        else if (type === "trip") {
            let tripIDs = this.state.tripIDs.slice(this.numItemsPerPage * (page - 1), this.numItemsPerPage * page)
            const dataJSON = {
                trip_ids: tripIDs
            };

            postFetchDots(dataJSON)
            .then(axiosCallback)
            .catch((response) => {
                console.log("[WARNING] Search / handleAxios - Axios error ", response)
            })
        }
        else if (type === "user") {
            let userIDs = this.state.userIDs.slice(this.numItemsPerPage * (page - 1), this.numItemsPerPage * page)
            const dataJSON = {
                user_ids: userIDs
            };

            postFetchUser(dataJSON)
            .then(axiosCallback)
            .catch((response) => {
                console.log("[WARNING] Search / handleAxios - Axios error ", response)
            })
        }

    }

    itemHoverOn(event, itemIndex) {
        //console.log("ResultItem / itemHoverOn");

        // Prevent event actinos
        //event.stopPropagation();
        //event.preventDefault();
        if (window.touchOn) {
            // Update state
            this.setState({
                hovered: null,
            });
        }
        else{
            // Update state
            this.setState({
                hovered: itemIndex,
            });
        }

    }

    itemHoverOff(event, itemIndex) {
        //console.log("ResultItem / itemHoverOff");

        // Prevent event actinos
        //event.stopPropagation();
        //event.preventDefault();

        // Update state
        this.setState({
            hovered: null,
        });

    }

    itemClick(event, itemIndex) {
        //console.log("SearchResults / itemClick - itemIndex = ", itemIndex);
        if (window.touchOn) {
            if (this.state.resultMode === "dot") {
                // Navigate to dot
                setTimeout(
                    () => {
                        this.props.history.push(`/dot/${this.state.dotsInfo[this.state.dotPage][itemIndex].slug}`);
                    },
                    500
                );
            }
            else if (this.state.resultMode === "trip") {
                if (this.state.selected !== null && this.state.selected === itemIndex) {
                    // Navigate to trip

                    setTimeout(
                        () => {
                            //console.log("SearchResults / itemClick - second click");
                            this.props.history.push(`/trip/${this.state.tripsInfo[this.state.tripPage][itemIndex].slug}`);
                        },
                        500
                    );
                }
                else{
                    //console.log("SearchResults / itemClick - updating selected");
                    this.setState({ selected: itemIndex });
                }
            }
            else{
                setTimeout(
                    () => {
                        // Navigate to a user
                        this.props.history.push(`/user/${this.state.usersInfo[this.state.userPage][itemIndex].username}`);
                    },
                    500
                );
            }
        }
        else {
            if (this.state.resultMode === "dot") {
                if (this.state.hovered !== null && this.state.hovered === itemIndex) {
                    // Navigate to dot
                    setTimeout(
                        () => {
                        this.props.history.push(`/dot/${this.state.dotsInfo[this.state.dotPage][itemIndex].slug}`);
                        },
                        500
                    );
                }
            }
            else if (this.state.resultMode === "trip") {
                if (this.state.selected !== null && this.state.selected === itemIndex) {
                    // Navigate to trip
                    //console.log("SearchResults / itemClick - second click");
                    setTimeout(
                        () => {
                            this.props.history.push(`/trip/${this.state.tripsInfo[this.state.tripPage][itemIndex].slug}`);
                        },
                        500
                    );
                }
                else {
                    //console.log("SearchResults / itemClick - updating selected");
                    this.setState({ selected: itemIndex });
                }
            }
            else {
                // Navigate to a user
                setTimeout(
                    () => {
                        this.props.history.push(`/user/${this.state.usersInfo[this.state.userPage][itemIndex].username}`);
                    },
                    500
                );
            }
        }

    }

    switchToDotMode () {
        if (this.state.dotsInfo === null) {
            // Execute search for given page
            this.fetchSearchResults(
                "dot",
                this.state.keywords,
                this.state.dotPage,
                false,
                () => {
                    // Change page
                    this.setState({
                        resultMode: "dot",
                        selected: null
                    });
                });
        }
        else {
            // Change page
            this.setState({
                resultMode: "dot",
                selected: null
            });
        }
    }

    switchToTripMode () {
        if (this.state.tripsInfo === null) {
            // Execute search for given page
            this.fetchSearchResults(
                "trip",
                this.state.keywords,
                this.state.tripPage,
                false,
                () => {
                    // Change page
                    this.setState({
                        resultMode: "trip",
                        selected: null
                    });
                });
        }
        else {
            // Change page
            this.setState({
                resultMode: "trip",
                selected: null
            });
        }
    }

    switchToUserMode () {
        if (this.state.usersInfo === null) {
            // Execute search for given page
            this.fetchSearchResults(
                "user",
                this.state.keywords,
                this.state.userPage,
                false,
                () => {
                    // Change page
                    this.setState({
                        resultMode: "user",
                        selected: null
                    });
                } );
        }
        else {
            // Change page
            this.setState({
                resultMode: "user",
                selected: null
            });
        }
    }

}


/*
============================================================================================
    Result
============================================================================================
*/

const Results = (props) => {
    //console.log("Results - props = ", props);

    const resultsInfo = (props.resultMode === "dot")?
        (props.dotsInfo) : (
            (props.resultMode === "trip")?
                (props.tripsInfo) : (props.usersInfo)
        );
    //console.log("Results / render - resultsInfo = ", resultsInfo);

    const results = resultsInfo.map(
        (resultInfo, resultIndex) => {
            //console.log("Results / render - resultIndex = ", resultIndex);
            if (props.resultMode === "dot") {
                // Media
                const mediaSorted = sortMedia(resultInfo);
                const media = [
                    ...mediaSorted.overview,
                    ...mediaSorted.todos,
                    ...mediaSorted.history,
                    ...mediaSorted.stories
                ];

                // Is video
                const isVideo = (media[0].type === "video");

                // Dot media
                const dotMedia = (isVideo)?
                    (
                        (media[0].files.indexOf("xs") === -1)?
                            getStaticPath("/images/common/search-map-video.png", true) :
                            getMediaProperty(media[0], "xs", "url",true)
                    ) : (
                        getMediaProperty(media[0], "xs", "url", true)
                    );

                return(
                    <div
                        key = {"search-results-dot-container-" + resultIndex}
                        className = "search-results-item-container"
                        onMouseEnter = {(event) => { props.itemHoverOn(event, resultIndex); }}
                        onMouseLeave = {(event) => { props.itemHoverOff(event, resultIndex); }}
                    >
                        <div
                            className = {
                                (props.hoveredMarker === resultIndex)?
                                    (
                                        (props.colorMode === "day")?
                                        "search-results-item-day-hover" :
                                        "search-results-item-night-hover"
                                    ):(
                                        (props.colorMode === "day")?
                                        "search-results-item-day" :
                                        "search-results-item-night"
                                    )
                            }
                            style = {{
                                backgroundImage: dotMedia,
                                width: props.itemSize,
                                height: props.itemSize,
                                margin: props.itemPadding
                            }}
                            onClick = {(event) => { props.itemClick(event, resultIndex); }}
                        >
                            <div className = {(props.colorMode === "day")?
                               "search-results-item-shadow-day" :
                               "search-results-item-shadow-night"}
                            >
                                <div className = "search-results-item-title">
                                    <div className = "search-results-item-name w4">
                                        {resultInfo.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (props.resultMode === "trip") {

                // Media
                const mediaSorted = sortMedia(resultInfo);
                const media = [
                    ...mediaSorted.overview,
                    ...mediaSorted.todos,
                    ...mediaSorted.history,
                    ...mediaSorted.stories
                ];

                // Is video
                const isVideo = (media[0].type === "video");

                // Dot media
                const tripMedia = (isVideo)?
                    (
                        (media[0].files.indexOf("xs") === -1)?
                            getStaticPath("/images/common/search-map-video.png", true) :
                            getMediaProperty(media[0], "xs", "url", true)
                    ) : (
                        getMediaProperty(media[0], "xs", "url", true)
                    );

                return(
                    <div
                        key = {"search-results-trip-container-" + resultIndex}
                        className = "search-results-item-container"
                        onMouseEnter = {(event) => { props.itemHoverOn(event, resultIndex); }}
                        onMouseLeave = {(event) => { props.itemHoverOff(event, resultIndex); }}
                    >
                        <div
                            className = {
                                (props.hoveredMarker === resultIndex)?
                                    (
                                        (props.colorMode === "day")?
                                        "search-results-item-day-hover" :
                                        "search-results-item-night-hover"
                                    ):(
                                        (props.colorMode === "day")?
                                        "search-results-item-day" :
                                        "search-results-item-night"
                                )
                            }
                            style = {{
                                backgroundImage: tripMedia,
                                width: props.itemSize,
                                height: props.itemSize,
                                margin: props.itemPadding
                            }}
                            to = {`/trip/${resultInfo.id}`}
                            onClick = {(event) => { props.itemClick(event, resultIndex); }}
                        >
                            <div className = {(props.colorMode === "day")?
                               "search-results-item-shadow-day" :
                               "search-results-item-shadow-night"}
                            >
                                <div className = "search-results-item-title">
                                    <div className = "search-results-item-name w4">
                                        {resultInfo.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (props.resultMode === "user") {
                const userPic = (resultInfo.profile_pic)?
                    (
                        (resultInfo.profile_pic.external_url === null)?
                            getMediaProperty(resultInfo.profile_pic, "t", "url", true) :
                            url(resultInfo.profile_pic.external_url)
                    ) : (
                        (props.colorMode === "day")?
                            getStaticPath("/images/common/no-profile-picture-day.png") :
                            getStaticPath("/images/common/no-profile-picture-night.png")
                    );

                return(
                    <div
                        key = {"search-results-user-" + resultIndex}
                        className = "search-results-user"
                        style = {{
                            display: "inline-block",
                            width: props.userWidth,
                            height: props.userHeight
                        }}
                        //onMouseEnter = {props.itemHoverOn}
                        //onMouseLeave = {props.itemHoverOff}
                        onClick = {(event) => { props.itemClick(event, resultIndex); }}
                    >
                        <div className = {(props.colorMode === "day")?
                            "search-results-user-pic border-day" : "search-results-user-pic border-night"}
                            style = {{
                                backgroundImage: userPic,
                                width: props.userPicSize,
                                height: props.userPicSize
                            }}
                        >
                        </div>
                        <div className = "search-results-user-info"
                            style = {{width: props.userInfoWidth}}
                        >
                            <div className = {(props.colorMode === "day")?
                            "search-results-user-name k3" : "search-results-user-name w3"}
                                style = {{display: "inline-block"}}
                            >
                                {resultInfo.name}
                            </div>
                            <div className = {(props.colorMode === "day")? "lb5" : "b5"}
                                style = {{display: "inline-block"}}
                            >
                                {resultInfo.username}
                            </div>
                        </div>
                    </div>
                );
            }
        }
    );

    return(
        <div>
            <div id = "search-results"
                style = {{ width: props.containerWidth }}
            >
                {results}
            </div>
            <Pagination
                colorMode = {props.colorMode}
                browserWidth = {props.browserWidth}
                items = {resultsInfo}
                maxPage = {props.maxPage}
                numPagesPerGroup = {props.numPagesPerGroup}
                page = {props.page}
                type = {props.resultMode}
                goToPage = {props.goToPage}
                previousGroup = {props.previousGroup}
                nextGroup = {props.nextGroup}
            />
        </div>
    );
}

/*
============================================================================================
    Pagination
============================================================================================
*/

const Pagination = (props) => {
    //console.log("Pagination - props = ", props);
    let previous = null;
    let next = null;
    //let pages = null;
    let pageItems = null;

    if (props.items != null && props.maxPage > 1) {
        const maxPageGroup = Math.floor((props.maxPage - 1) / props.numPagesPerGroup); // number group dotIndex
        const pageGroup = Math.floor((props.page - 1) / props.numPagesPerGroup); // current nunber group dotIndex

        if (pageGroup > 0) {
            previous = (
                <div className = "search-results-previous"
                    style = {{
                        backgroundImage: (props.colorMode === "day")?
                            getStaticPath("/images/common/single-arrow-left-black.png") :
                            getStaticPath("/images/common/single-arrow-left-white.png")
                    }}
                    onClick = {() => { props.previousGroup(props.type); }}
                >
                </div>
            )
        }

        if (pageGroup < maxPageGroup) {
            next = (
                <div className = "search-results-next"
                    style = {{
                        backgroundImage: (props.colorMode === "day")?
                            getStaticPath("/images/common/single-arrow-right-black.png") :
                            getStaticPath("/images/common/single-arrow-right-white.png")
                    }}
                    onClick = {() => { props.nextGroup(props.type); }}
            >
            </div>
            )
        }

        // Page groups
        const pageGroupStart = pageGroup * props.numPagesPerGroup + 1;
        const pageGroupEnd = (props.maxPage > (pageGroup + 1) * props.numPagesPerGroup)?
                            (pageGroup + 1) * props.numPagesPerGroup : props.maxPage;

        // Create an array of page numbers
        const pageNumbers = [];
        for (let pageNumber = pageGroupStart; pageNumber <= pageGroupEnd; pageNumber++) {
            pageNumbers.push(pageNumber);
        }

        // Page
        pageItems = pageNumbers.map(
            (pageNumber, index) => {
                const pageClassName = (pageNumber === props.page)?
                    (
                        (props.colorMode === "day")?
                            "search-results-page page-selected-day-s2 " :
                            "search-results-page page-selected-night-s2"
                    ) : (
                        (props.colorMode === "day")?
                            "search-results-page page-day-s2 " :
                            "search-results-page page-night-s2"
                    );

                return(
                    <div
                        key = {"search-results-pagination-number-" + pageNumber.toString()}
                        className = {pageClassName}
                        onClick = {
                            () => { props.goToPage(pageNumber, props.type, false); }
                        }
                    >
                        {pageNumber}
                    </div>
                )
            }
        )
    }

    return(
        <div className = "search-results-pages-container">
            {previous}
            <div className = "search-results-pages">
                {pageItems}
            </div>
            {next}
        </div>
    )

}

function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        google: state.map.google
    };
}


export default connect(mapStateToProps, null)(SearchResults);
