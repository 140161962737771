// Refresh
export const STORE_REFRESH = "STORE_REFRESH";

// Navigation
export const STORE_BROWSER_WIDTH = "STORE_BROWSER_WIDTH";
export const STORE_BROWSER_WIDTH_PIXELS = "STORE_BROWSER_WIDTH_PIXELS";
export const STORE_MAIN_MODE = "STORE_MAIN_MODE";
export const STORE_ITINERARY_ANIMATION = "STORE_ITINERARY_ANIMATION";
export const STORE_COLOR_MODE = "STORE_COLOR_MODE";
export const STORE_CHAT_MENU_ON = "STORE_CHAT_MENU_ON";
export const STORE_ITINERARY_MENU_ON = "STORE_ITINERARY_MENU_ON"; 
export const STORE_NOTIFICATION_MENU_ON = "STORE_NOTIFICATION_MENU_ON";
export const STORE_DOTS_HOME_ON = "STORE_DOTS_HOME_ON";

// Log In
export const STORE_LOG_IN_ON = "STORE_LOG_IN_ON";
export const STORE_LOG_IN_SIMPLE_ON = "STORE_LOG_IN_SIMPLE_ON";

// Sign Up
export const STORE_SIGN_UP_ON = "STORE_SIGN_UP_ON";

// User
export const STORE_USER = "STORE_USER";

// Alert
export const STORE_WARNING_ALERT = "STORE_WARNING_ALERT";
export const STORE_NOTIFICATION_ALERT = "STORE_NOTIFICATION_ALERT";
export const STORE_RESET_PASSWORD_EMAIL_SENT_ALERT = "STORE_RESET_PASSWORD_EMAIL_SENT_ALERT";
export const STORE_RESET_PASSWORD_TOKEN_EXPIRED_ALERT = "STORE_RESET_PASSWORD_TOKEN_EXPIRED_ALERT";

// Map
export const STORE_MAP = "STORE_MAP";

// Share
export const STORE_SHARE = "STORE_SHARE";

// Tag
export const STORE_DOT_TAG = "STORE_DOT_TAG";
export const STORE_NEW_DOT_TAG = "STORE_NEW_DOT_TAG";
export const STORE_USER_TAG = "STORE_USER_TAG";
export const STORE_NEW_USER_TAG = "STORE_NEW_USER_TAG";

// Memo
export const STORE_MEMO = "STORE_MEMO";
export const STORE_NEW_MEMO = "STORE_NEW_MEMO";

// Follow
export const STORE_FOLLOW = "STORE_FOLLOW";

// Posting
export const STORE_POSTING = "STORE_POSTING";

// Edit user info
export const STORE_EDIT_USER_INFO_ON = "STORE_EDIT_USER_INFO_ON";

// Dot more modal
export const STORE_MORE = "STORE_MORE";

// Edit dot detail
export const STORE_EDIT_DETAIL = "STORE_EDIT_DETAIL";

// Board
export const STORE_NEW_COMMENT = "STORE_NEW_COMMENT";
export const STORE_NEW_REPLY = "STORE_NEW_REPLY";

// Notification
export const STORE_NEW_NOTIFICATION = "STORE_NEW_NOTIFICATION";

// Chat
export const STORE_NEW_CHAT = "STORE_NEW_CHAT";
export const STORE_NEW_CHAT_MESSAGE = "STORE_NEW_CHAT_MESSAGE";

// Interaction
export const STORE_NEW_LIKE = "STORE_NEW_LIKE";
export const STORE_NEW_SAVE = "STORE_NEW_SAVE";

// Itinerary
export const STORE_ITINERARY = "STORE_ITINERARY";
export const STORE_ITINERARY_PARAMS = "STORE_ITINERARY_PARAMS";
export const STORE_NEW_ITINERARY = "STORE_NEW_ITINERARY";
export const STORE_ITINERARIES_FETCH_COMPLETE = "STORE_ITINERARIES_FETCH_COMPLETE";
export const STORE_SAVE_ITINERARY = "STORE_SAVE_ITINERARY";

// Schedule
export const STORE_SCHEDULE = "STORE_SCHEDULE";

// Participant
export const STORE_PARTICIPANT = "STORE_PARTICIPANT";
export const STORE_NEW_PARTICIPANT = "STORE_NEW_PARTICIPANT";

// Create
export const STORE_CREATE = "STORE_CREATE";

// Gallery
export const STORE_GALLERY = "STORE_GALLERY";

// Navigation
export const storeRefresh = (number) => {
	return { type: STORE_REFRESH, payload: number };
};

// Navigation
export const storeBrowserWidth = (number) => {
	return { type: STORE_BROWSER_WIDTH, payload: number };
};

export const storeBrowserWidthPixels = (number) => {
	return { type: STORE_BROWSER_WIDTH_PIXELS, payload: number };
};

export const storeMainMode = (string) => {
	return { type: STORE_MAIN_MODE, payload: string };
};

export const storeItineraryAnimation = (string) => {
	return { type: STORE_ITINERARY_ANIMATION, payload: string };
};

export const storeColorMode = (string) => {
	return { type: STORE_COLOR_MODE, payload: string };
};

export const storeChatMenuOn = (boolean) => {
	return { type: STORE_CHAT_MENU_ON, payload: boolean };
};

export const storeItineraryMenuOn = (boolean) => {
	return { type: STORE_ITINERARY_MENU_ON, payload: boolean };
};

export const storeNotificationMenuOn = (boolean) => {
	return { type: STORE_NOTIFICATION_MENU_ON, payload: boolean };
};

export const storeDotsHomeOn = (boolean) => {
	return { type: STORE_DOTS_HOME_ON, payload: boolean }
};

// Log in
export const storeLogInOn = (boolean) => {
	return { type: STORE_LOG_IN_ON, payload: boolean };
};

export const storeLogInSimpleOn = (boolean) => {
	return { type: STORE_LOG_IN_SIMPLE_ON, payload: boolean };
};

// Sign up
export const storeSignUpOn = (boolean) => {
	return { type: STORE_SIGN_UP_ON, payload: boolean };
};

// User
export const storeUser = (object) => {
	//console.log("actions / storeUser - userInfo = ", object);
	return { type: STORE_USER, payload: object };
};

// Alert
export const storeWarningAlert = (warningAlertInfo) => {
	return { type: STORE_WARNING_ALERT, payload: warningAlertInfo };
};

export const storeNotificationAlert = (notificationAlertInfo) => {
	return { type: STORE_NOTIFICATION_ALERT, payload: notificationAlertInfo };
};

export const storeResetPasswordEmailSentAlert = (emailPasswordInfo) =>  {
	return { type: STORE_RESET_PASSWORD_EMAIL_SENT_ALERT, payload: emailPasswordInfo };
};

export const storeResetPasswordTokenExpiredAlert = (boolean) =>  {
	return { type: STORE_RESET_PASSWORD_TOKEN_EXPIRED_ALERT, payload: boolean };
};


// Map
export const storeMap = (object) => {
	return { type: STORE_MAP, payload: object };
};

// Share
export const storeShare = (object) =>  {
	return { type: STORE_SHARE, payload: object };
};

// Tag
export const storeDotTag = (object) =>  {
	return { type: STORE_DOT_TAG, payload: object };
};

export const storeNewDotTag = (object) =>  {
	return { type: STORE_NEW_DOT_TAG, payload: object };
};

export const storeUserTag = (object) =>  {
	return { type: STORE_USER_TAG, payload: object };
};

export const storeNewUserTag = (object) =>  {
	return { type: STORE_NEW_USER_TAG, payload: object };
};

// Memo
export const storeMemo = (object) =>  {
	return { type: STORE_MEMO, payload: object };
};

export const storeNewMemo = (object) =>  {
	return { type: STORE_NEW_MEMO, payload: object };
};

// Follow
export const storeFollow = (object) =>  {
	return { type: STORE_FOLLOW, payload: object };
};

// Posting
export const storePosting = (object) =>  {
	return { type: STORE_POSTING, payload: object };
};

// Edit user info
export const storeEditUserInfoOn = (boolean) => {
	return { type: STORE_EDIT_USER_INFO_ON, payload: boolean };
};

// More
export const storeMore = (object) => {
	return { type: STORE_MORE, payload: object };
};

// Board
export const storeNewComment = (object) => {
	return { type: STORE_NEW_COMMENT, payload: object };
};

export const storeNewReply = (object) => {
	return { type: STORE_NEW_REPLY, payload: object };
};

// Notification
export const storeNewNotification = (object) => {
	return { type: STORE_NEW_NOTIFICATION, payload: object };
};

// Chat
export const storeNewChat = (object) => {
	return { type: STORE_NEW_CHAT, payload: object };
};

export const storeNewChatMessage = (object) => {
	return { type: STORE_NEW_CHAT_MESSAGE, payload: object };
};

// Interaction
export const storeNewLike = (object) => {
	return { type: STORE_NEW_LIKE, payload: object };
};

export const storeNewSave = (object) => {
	return { type: STORE_NEW_SAVE, payload: object };
};

// Itinerary
export const storeItinerary = (object) => {
	return { type: STORE_ITINERARY, payload: object };
};

export const storeItineraryParams = (object) => {
	return { type: STORE_ITINERARY_PARAMS, payload: object };
};

export const storeNewItinerary = (object) => {
	return { type: STORE_NEW_ITINERARY, payload: object };
};

export const storeItinerariesFetchComplete = (boolean) => {
	return { type: STORE_ITINERARIES_FETCH_COMPLETE, payload: boolean };
}

export const storeSaveItinerary = (object) => {
	return { type: STORE_SAVE_ITINERARY, payload: object };
};

export const storeSchedule = (object) => {
	return { type: STORE_SCHEDULE, payload: object };
};

export const storeParticipant = (object) => {
	return { type: STORE_PARTICIPANT, payload: object };
};

export const storeNewParticipant = (object) => {
	return { type: STORE_NEW_PARTICIPANT, payload: object };
};

export const storeCreate = (object) => {
	return { type: STORE_CREATE, payload: object };
};

export const storeGallery = (object) => {
	return { type: STORE_GALLERY, payload: object };
};
