import { 
	STORE_BROWSER_WIDTH,
	STORE_BROWSER_WIDTH_PIXELS,
	STORE_MAIN_MODE,
	STORE_ITINERARY_ANIMATION,
	STORE_COLOR_MODE,
	STORE_CHAT_MENU_ON,
	STORE_ITINERARY_MENU_ON,
	STORE_NOTIFICATION_MENU_ON,
	STORE_DOTS_HOME_ON
} from "actions";

const INITIAL_STATE = {
	browserWidth: null,
	browserWidthPixels: null,
	mainMode: "trips",
	itineraryAnimation: null,
	colorMode: "day",
	chatMenuOn: false,
	itineraryMenuOn: false,
	notificationMenuOn: false,
	dotsHomeOn: false
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_BROWSER_WIDTH:
			return { ...state, browserWidth: action.payload };
		case STORE_BROWSER_WIDTH_PIXELS:
			return { ...state, browserWidthPixels: action.payload };
		case STORE_COLOR_MODE:
			return { ...state, colorMode: action.payload };
		case STORE_MAIN_MODE:
			return { ...state, mainMode: action.payload };
		case STORE_ITINERARY_ANIMATION:
			return { ...state, itineraryAnimation: action.payload };
		case STORE_CHAT_MENU_ON:
			return { ...state, chatMenuOn: action.payload };
		case STORE_ITINERARY_MENU_ON:
			return { ...state, itineraryMenuOn: action.payload };
		case STORE_NOTIFICATION_MENU_ON:
			return { ...state, notificationMenuOn: action.payload };
		case STORE_DOTS_HOME_ON:
			return { ...state, dotsHomeOn: action.payload };
		default:
			return state;
	}
};