/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    CreateSimple.js
    - Create modal for simple dots
--------------------------------------------------------------------------------------------
    Content
    - CreateSimple
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Modules
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import InputRange from "thedots-input-range";
import TimeKeeper from "thedots-timekeeper";
import ReactTooltip from "thedots-tooltip";

// Redux 
import {
    storeRefresh,
    storeCreate,
    storeWarningAlert
} from "actions";

// Axios
import {
    postDot,
    postSetChildDot,
    patchDot
} from "requests";

// Open Source Map and Open Layers
import * as openLayersProj from "ol/proj";

// Components
import {
    OpenMap,
    GoogleMap,
    pointToLatLng,
    latLngToPoint,
    calculateDisplacement
} from "components/Map";

import { 
    UploadGallery,
    addImage,
    addVideo,
    removeMedia,
    addSearchedMedia,
    clearSearchedMedia,
    selectMedia,
    moveMediaLeft,
    moveMediaRight,
    thumbnailPrevPage,
    thumbnailNextPage,
    updateIndices
} from "components/UploadGallery";

// Functions
import {
    getTimestamp,
    getStaticPath,
    //updateStateChain2,
    pad,
    formatDuration,
    getMediaProperty,
    freezeBody,
    unfreezeBody,
    //formatNumbers,
    IntervalToSeconds,
    getDurationScaleIndex,
    getClockTime,
    getCloseDate,
    getBestTimeIndex    
} from "js/Functions";

// CSS
import "./CreateSimple.scss";


class CreateSimple extends Component {
    constructor(props){
        super(props);

        // Search mode width
        this.searchModeWidth = 320;

        // Edit mode on
        this.createModeOn = (this.props.create.mode === "create");

        // Map Mode (google / open)
        this.mapSource = "open";
        this.mapMode = "create";

        // Modal DOM node
        this.modalRef = React.createRef();

        // Font weight
        this.fontWeightRegular = 100;
        this.fontWeightBold = 600;

        // Overview text input max height
        this.overviewMaxHeight = 240;

        // Map settings
        this.mapZoom = (this.createModeOn)? 15 : this.props.create.info.map_zoom;
        this.mapWidth = 360;
        this.mapHeight = 260;
        this.mapHeightSmall = 260;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // Focus and unfocus opacities
        this.focusOpacity = 1.0;
        this.unfocusOpacity = 0.75;

        // Margin width
        this.marginWidth = 10;

        // Media upload input IDs
        this.imageUploadID = "create-modal-upload-gallery-add-image";
        this.videoUploadID = "create-modal-upload-gallery-add-video";

        // Aspect ratios
        this.minAspectRatio = 0.5;
        this.maxAspectRatio = 2.0;

        // Content widths
        this.minContentWidth = 300;
        this.scaleContentWidth = 540;
        this.maxContentWidth = 580;

        // Media settings
        this.mediaArea = 200000;

        // Thumbnails
        this.thumbnailArea = 9000;

        // Video thumbnails
        this.videoThumbnailWidth = 100;
        this.videoThumbnailHeight = 100;

        // Number of thumbnails
        this.numThumbnailsSmall = 2;
        this.numThumbnailsMedium = 3;
        this.numThumbnailsLarge = 3;

        // Number of thumbnails
        const numThumbnails = (this.props.browserWidth <= 4)?
            this.numThumbnailsSmall :
            (
                (this.props.browserWidth <= 8)?
                    this.numThumbnailsMedium : this.numThumbnailsLarge
            );

        // Slider labels and constant presets
        this.bestTimeLabels = [ "Sunrise", "Morning", "Noon", "Afternoon", "Sunset" ];
        this.bestTimeTypes = [ "SR", "MO", "NO", "AF", "SS" ];
        this.physicalityLabels = ["0 - No Effort", "0.5", "1 - Easy / Stroll", "1.5", "2 - Slow Walk", "2.5 - Moderate / Regular Walk", "3 - Fast Walk", "3.5", "4 - Streneous / Jogging", "4.5", "5 - Extreme / Running" ];
        this.durationScales = [ 3600, 7200, 14400, 28800, 57600, 115200 ];

        // Map DOM IDs
        this.mapNodeID = "map";
        this.mapContainerNodeID = "map-container";
        this.mapButtonsNodeID = "map-buttons";
        this.mapStartNodeID = "map-start";
        this.mapStartContainerNodeID = "map-start-container";
        this.mapStartButtonsNodeID = "map-start-buttons";
        this.mapEndNodeID = "map-end";
        this.mapEndContainerNodeID = "map-end-container";
        this.mapEndButtonsNodeID = "map-end-buttons";

        // Placeholders
        this.placeholderLocation = "Search area and click on map";
        this.placeholderName = "Name the place if necessary";
        this.placeholderArea = "Recognizable name of the area";
        this.placeholderTitle = "Attention grabbing title";
        this.placeholderOverviewDot = "Brief introduction of the place";
        this.placeholderOverviewTrip = "Overview of the trip";
        this.placeholderTodos = "What-to-do ideas to experience the place";
        this.placeholderTodosDine = "What-to-order ideas for best experience";
        this.placeholderHistory = "Some history that gives a perspective";
        this.placeholderStories = "Other interesting stories or anecdotes";

        // General user mode placeholder
        this.placeholderLocationSimple = "Search area to start";
        this.placeholderNameSimple = "Give the dot a name";
        this.placeholderTitleSimple = "Attention-grabbing title";
        this.placeholderOverviewSimple = "Story to tell";

        // Media arning Messages
        this.mediaWarningMessage = "Upload at least one media file.";
        this.mediaWarningUploadMessage = "Wait until upload is complete or remove media with errors.";
        this.mediaWarningOverviewMessage = "At least one media file must be for overview.";

        // Contributor mode warning messages
        this.locationWarningMessage = "Search location or provide coordinates.";
        this.locationParkingWarningMessage = "Set the parking location.";
        this.locationStartWarningMessage = "Choose a good place to start the trip \n(airport / hotel / park entrance / trailhead).";
        this.locationEndWarningMessage = "Choose a good place to end the trip \n(Airport / hotel / park entrance / trailhead).";
        this.locationStartEndWarningMessage = "Choose good places to start and end the trip \n(Airport / hotel / park entrance / trailhead).";
        this.nameWarningMessage = "A dot must have a name.";
        this.areaWarningMessage = "Write what area this place belongs in.";
        this.titleWarningMessage = "Give it a title.";
        this.overviewWarningMessage = "An overview is required.";
        this.connectDotWarningMessage = "Connect the trip with at least one dot.";
        this.connectHighlightWarningMessage = "Highlight dots that represent the trip.";
        this.connectStartLocationWarningMessage = "Set start location before adding a dot";
        this.connectEndLocationWarningMessage = "Set end location before adding a dot";
        this.connectStartEndLocationWarningMessage = "Set start and end locations before adding a dot";

        // General user mode warning messages 
        this.locationWarningMessageSimple = "Search location.";
        this.locationParkingWarningMessageSimple = "Set the parking location.";
        this.nameWarningMessageSimple = "A dot must have a name.";
        this.titleWarningMessageSimple = "Give it a title.";
        this.overviewWarningMessageSimple = "Write something about the place.";
        
        // Media file count
        this.mediaCount = 0;

        // Media objects using file upload order as key
        this.media = {};
        
        // Upload indicator objects using file upload order as key
        this.uploadProgress = {};

        // Upload process interval and time
        this.uploadProcessTime = 2000;
        this.uploadProcessIntervalTime = 100;

        // Upload update interval
        this.uploadUpdateIntervalTime = 200;
        this.uploadUpdateInterval = null;

        // Save limit
        this.saveLimitLabels = [ "10", "100", "1000", "10k", "Unlimited" ];
        this.saveLimits = [ 10, 100, 1000, 10000, null ];


        /*
        ============================================================================================
            Initialize Dot Information
        ============================================================================================
        */

        let userIsContributor = null;
        let everydayModeOn = null;
        let authorModeOn = null;
        let contributorModeOn = null;
        let dotType = null;
        let history = null;
        let stories = null;
        let todos = null;
        let rating = null;
        let physicality = null;
        let duration = null;
        let durationScaleIndex = null;
        let startHour = null;
        let endHour = null;
        let hoursOn = false;
        let bestTimeOn = null;
        let bestTimeType = null;
        let bestTimeFirst = null;
        let bestTimeSecond = null;
        let seasonClosureType = null;
        let closureStartDate = null;
        let closureEndDate = null;
        let accessibilityMode = null;
        let parkingLocation = null;
        let preferredMode = null;
        let location = null;
        let startLocation = null;
        let startLocationName = null;
        let endLocation = null;
        let endLocationName = null;
        let diningOptions = null;
        let mediaKeys = [];
        let mediaCategories = [];
        let mediaIDs = [];


        if (this.props.create.mode === "edit") {
            /*
            --------------------------------------------------------------------------------------------
                User Is Contributor and Write Mode
            --------------------------------------------------------------------------------------------
            */

            // Is contributor
            userIsContributor = (this.props.userInfo.create_level > 3)? true : false;

            // Everyday / author / contributor mode on
            everydayModeOn = (this.props.create.info.type === "EV");
            authorModeOn =  (this.props.create.info.type === "AU");
            contributorModeOn = (this.props.create.info.type !== "AU" && this.props.create.info.type !== "EV");

            /*
            --------------------------------------------------------------------------------------------
                Dot Type
            --------------------------------------------------------------------------------------------
            */

            const dotTypeDefault = "SC";
            dotType = (this.props.create.info.type === "DE")?
                "EX" :
                (
                    (this.props.create.info.type === "EV" || this.props.create.info.type === "AU")?
                        dotTypeDefault  : this.props.create.info.type
                );

            /*
            --------------------------------------------------------------------------------------------
                Curation
            --------------------------------------------------------------------------------------------
            */

            history = (contributorModeOn)?
                this.props.create.info.dot_extension.history : null;
            stories = (contributorModeOn)?
                this.props.create.info.dot_extension.stories : null;
            todos = (contributorModeOn)?
                this.props.create.info.dot_extension.todos : null;


            /*
            --------------------------------------------------------------------------------------------
                Rating
            --------------------------------------------------------------------------------------------
            */
            const ratingDefault = 3;
            rating = (contributorModeOn)?
                (
                    (this.props.create.info.dot_extension.rating !== null)?
                        Number(this.props.create.info.dot_extension.rating) : ratingDefault
                ) : ratingDefault;


            /*
            --------------------------------------------------------------------------------------------
                Physicality
            --------------------------------------------------------------------------------------------
            */

            // Physicality
            const physicalityDefault = 2;
            physicality = (contributorModeOn)?
                (
                    (this.props.create.info.dot_extension.physicality !== null)?
                        Number(this.props.create.info.dot_extension.physicality) : physicalityDefault
                ) : physicalityDefault;


            /*
            --------------------------------------------------------------------------------------------
                Duration
            --------------------------------------------------------------------------------------------
            */

            const durationDefault = 1800; // 30 mins as default
            const durationScaleIndexDefault = 0;

            if (contributorModeOn) {
                if (this.props.create.info.dot_extension.duration !== null) {
                    duration = IntervalToSeconds(this.props.create.info.dot_extension.duration);
                    durationScaleIndex = getDurationScaleIndex(duration);
                }
                else {
                    duration = durationDefault;
                    durationScaleIndex = durationScaleIndexDefault;
                }
            }
            else {
                duration = durationDefault;
                durationScaleIndex = durationScaleIndexDefault;
            }


            /*
            --------------------------------------------------------------------------------------------
                Start and End hours
            --------------------------------------------------------------------------------------------
            */

            const startHourDefault = {
                hour: 6,
                minute: 0,
                meridiem: "am",
                formatted: "6:00am"
            };
            const endHourDefault = {
                hour: 9,
                minute: 0,
                meridiem: "pm",
                formatted: "9:00pm"
            };
            const hoursOnDefault = false;

            if (contributorModeOn) {
                if (this.props.create.info.dot_extension.start_hour === null &&
                    this.props.create.info.dot_extension.end_hour === null) {
                    startHour = startHourDefault;
                    endHour = endHourDefault;
                    hoursOn = false;
                }
                else {
                    startHour = getClockTime(this.props.create.info.dot_extension.start_hour);
                    endHour = getClockTime(this.props.create.info.dot_extension.end_hour);
                    hoursOn = true;
                }
            }
            else {
                startHour = startHourDefault;
                endHour = endHourDefault;
                hoursOn = hoursOnDefault;
            }


            /*
            --------------------------------------------------------------------------------------------
                Time Type
            --------------------------------------------------------------------------------------------
            */

            const bestTimeOnDefault = true;
            const bestTimeTypeDefault = "single";
            const bestTimeFirstDefault = 0;
            const bestTimeSecondDefault = 4;

            if (contributorModeOn) {
                if (this.props.create.info.dot_extension.time_type === null) {
                    bestTimeOn = false;
                    bestTimeType = bestTimeTypeDefault;
                    bestTimeFirst = bestTimeFirstDefault;
                    bestTimeSecond = bestTimeSecondDefault;
                }
                else if (this.props.create.info.dot_extension.time_type === "AD") {
                    bestTimeOn = true;
                    bestTimeType = "allday";
                    bestTimeFirst = bestTimeFirstDefault;
                    bestTimeSecond = bestTimeSecondDefault;
                }
                else {
                    bestTimeOn = true;
                    if (this.props.create.info.dot_extension.time_type_optional === null) {
                        bestTimeType = "single";
                        bestTimeFirst = getBestTimeIndex(this.bestTimeTypes, this.props.create.info.dot_extension.time_type);
                        bestTimeSecond = bestTimeSecondDefault;
                    }
                    else {
                        bestTimeType = "double";
                        bestTimeFirst = getBestTimeIndex(this.bestTimeTypes, this.props.create.info.dot_extension.time_type);
                        bestTimeSecond = getBestTimeIndex(this.bestTimeTypes, this.props.create.info.dot_extension.time_type_optional);
                    }
                }
            }
            else {
                bestTimeOn = bestTimeOnDefault;
                bestTimeType = bestTimeTypeDefault;
                bestTimeFirst = bestTimeFirstDefault;
                bestTimeSecond = bestTimeSecondDefault;
            }


            /*
            --------------------------------------------------------------------------------------------
                Seasonal Closure
            --------------------------------------------------------------------------------------------
            */

            const seasonClosureTypeDefault = "estimate";
            const closureStartDateDefault = moment().month(11).date(1);
            const closureEndDateDefault = moment().year(moment().year() + 1).month(5).date(15);

            if (contributorModeOn) {
                if (this.props.create.info.dot_extension.closure_estimate === true) {
                    seasonClosureType = "estimate";
                    closureStartDate = getCloseDate(moment, this.props.create.info.dot_extension.closure_start_date);
                    closureEndDate = getCloseDate(moment, this.props.create.info.dot_extension.closure_end_date);
                }
                else if (this.props.create.info.dot_extension.closure_estimate === false) {
                    seasonClosureType = "exact";
                    closureStartDate = getCloseDate(moment, this.props.create.info.dot_extension.closure_start_date);
                    closureEndDate = getCloseDate(moment, this.props.create.info.dot_extension.closure_end_date);
                }
                else if (this.props.create.info.dot_extension.closure_estimate === null) {
                    seasonClosureType = "open";
                    closureStartDate = closureStartDateDefault;
                    closureEndDate = closureEndDateDefault;
                }
            }
            else {
                seasonClosureType = seasonClosureTypeDefault;
                closureStartDate = closureStartDateDefault;
                closureEndDate = closureEndDateDefault;
            }


            /*
            --------------------------------------------------------------------------------------------
                Accessibility and Preferred Mode
            --------------------------------------------------------------------------------------------
            */

            const accessibilityModeDefault = "drivable";

            if (this.props.create.info.drivable === null) {
                accessibilityMode = accessibilityModeDefault;
            }
            else {
                if (this.props.create.info.drivable) {
                    if (this.props.create.info.parking_location === null) {
                        accessibilityMode = "drivable";
                    }
                    else {
                        accessibilityMode = "parkandwalk";
                        parkingLocation = this.props.create.info.parking_location;
                    }
                }
                else {
                    accessibilityMode = "undrivable";
                }
            }

            const preferredModeDefault = "drive";

            if (this.props.create.info.public_transportation !== null) {
                preferredMode = (this.props.create.info.public_transportation)?
                    "public" : "drive";
            }
            else {
                preferredMode = preferredModeDefault;
            }


            /*
            --------------------------------------------------------------------------------------------
                Locations
            --------------------------------------------------------------------------------------------
            */

            // Location (default location for debugging purposes)
            location = this.props.create.info.location;

            // For trips
            startLocation = null;
            startLocationName = null;
            endLocation = null;
            endLocationName = null;

            /*
            // Start and end locations (for trips)
            startLocation = (this.props.create.info.trip_extension.start_location !== null)?
                this.props.create.info.trip_extension.start_location : null;

            startLocationName = (this.props.create.info.trip_extension.start_location_name !== null)?
                this.props.create.info.trip_extension.start_location_name : null;

            endLocation = (this.props.create.info.trip_extension.end_location !== null)?
                this.props.create.info.trip_extension.end_location : null;

            endLocationName = (this.props.create.info.trip_extension.end_location_name !== null)?
                this.props.create.info.trip_extension.end_location_name : null;
            */


            /*
            --------------------------------------------------------------------------------------------
                Dining Option
            --------------------------------------------------------------------------------------------
            */

            const diningOptionsDefault = false;

            if (this.props.create.info.type === "DE") {
                diningOptions = true;
            }
            else {
                diningOptions = diningOptionsDefault;
            }


            /*
            ============================================================================================
                Load Media Information
            ============================================================================================
            */

            // Media file count
            this.mediaCount = this.props.create.info.media.length;

            // Construct files
            let mediaCount = 0;
            for (let i = 0; i < this.props.create.info.media_overview.length; i++) {
                mediaKeys.push(mediaCount);
                mediaCategories.push(0);
                mediaIDs.push(this.props.create.info.media_overview[i]);

                // Media info
                let mediaInfo = null;
                for (let j = 0; j < this.props.create.info.media.length; j++) {
                    if (this.props.create.info.media_overview[i] === this.props.create.info.media[j].id) {
                        mediaInfo = this.props.create.info.media[j];
                    }
                }

                // Calculate thumbnail width and height
                const mediaWidth = getMediaProperty(mediaInfo, "o", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "o", 'height', false);
                const mediaURL = getMediaProperty(mediaInfo, "o", "url", false);
                // Calculate the resized width and height of media and thumbnail
                const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                // Figure out media source
                let mediaSource = "search";
                //console.log("CreateSimple / constructor - mediaInfo = ", mediaInfo);
                if (mediaInfo.editor !== null && mediaInfo.editor !== undefined) {
                    if (mediaInfo.editor.username === this.props.userInfo.username) {
                        mediaSource = "upload";
                    }
                }

                // Initialize media object
                this.media[mediaCount] = {
                    mediaType: mediaInfo.type,
                    mediaURL: mediaURL,
                    mediaWidth: resizedMediaWidth,
                    mediaHeight: resizedMediaHeight,
                    mediaCategory: 0,
                    thumbnailWidth: (mediaInfo.type === "video")?
                        this.videoThumbnailWidth : thumbnailWidth,
                    thumbnailHeight: (mediaInfo.type === "video")?
                        this.videoThumbnailHeight : thumbnailHeight,
                    source: mediaSource
                };

                // Initialize upload progress object
                this.uploadProgress[mediaCount] = {
                    uploadPercentage: 100,
                    uploadComplete: true,
                    uploadProcessPercentage: 100,
                    uploadProcessComplete: true,
                    uploadProcessInterval: null,
                    uploadError: false,
                    removed: false
                };

                // Increase count
                mediaCount += 1;
            }

            if (contributorModeOn) {
                for (let i = 0; i < this.props.create.info.dot_extension.media_todos.length; i++) {
                    mediaKeys.push(mediaCount);
                    mediaCategories.push(1);
                    mediaIDs.push(this.props.create.info.dot_extension.media_todos[i]);

                    // Media info
                    let mediaInfo = null;
                    for (let j = 0; j < this.props.create.info.media.length; j++) {
                        if (this.props.create.info.dot_extension.media_todos[i] === this.props.create.info.media[j].id) {
                            mediaInfo = this.props.create.info.media[j];
                        }
                    }

                    // Calculate thumbnail width and height
                    const mediaWidth = getMediaProperty(mediaInfo, "o", 'width', false);
                    const mediaHeight = getMediaProperty(mediaInfo, "o", 'height', false);
                    const mediaURL = getMediaProperty(mediaInfo, "xs", "url", false);

                    // Calculate the resized width and height of media and thumbnail
                    const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                    const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                    const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                    const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                    const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                    const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                    // Initialize media object
                    this.media[mediaCount] = {
                        mediaType: mediaInfo.type,
                        mediaURL: mediaURL,
                        mediaWidth: resizedMediaWidth,
                        mediaHeight: resizedMediaHeight,
                        mediaCategory: 1,
                        thumbnailWidth: (mediaInfo.type === "video")? this.videoThumbnailWidth : thumbnailWidth,
                        thumbnailHeight: (mediaInfo.type === "video")? this.videoThumbnailHeight : thumbnailHeight
                    };

                    // Initialize upload progress object
                    this.uploadProgress[mediaCount] = {
                        uploadPercentage: 100,
                        uploadComplete: true,
                        uploadProcessPercentage: 100,
                        uploadProcessComplete: true,
                        uploadProcessInterval: null,
                        uploadError: false,
                        removed: false
                    };

                    // Increase count
                    mediaCount += 1;
                }

                for (let i = 0; i < this.props.create.info.dot_extension.media_history.length; i++) {
                    mediaKeys.push(mediaCount);
                    mediaCategories.push(2);
                    mediaIDs.push(this.props.create.info.dot_extension.media_history[i]);

                    // Media info
                    let mediaInfo = null;
                    for (let j = 0; j < this.props.create.info.media.length; j++) {
                        if (this.props.create.info.dot_extension.media_history[i] === this.props.create.info.media[j].id) {
                            mediaInfo = this.props.create.info.media[j];
                        }
                    }

                    // Calculate thumbnail width and height
                    const mediaWidth = getMediaProperty(mediaInfo, "m", 'width', false);
                    const mediaHeight = getMediaProperty(mediaInfo, "m", 'height', false);
                    const mediaURL = getMediaProperty(mediaInfo, "s", "url", false);

                    // Calculate the resized width and height of media and thumbnail
                    const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                    const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                    const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                    const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                    const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                    const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                    // Initialize media object
                    this.media[mediaCount] = {
                        mediaType: mediaInfo.type,
                        mediaURL: mediaURL,
                        mediaWidth: resizedMediaWidth,
                        mediaHeight: resizedMediaHeight,
                        mediaCategory: 2,
                        thumbnailWidth: (mediaInfo.type === "video")?
                            this.videoThumbnailWidth : thumbnailWidth,
                        thumbnailHeight: (mediaInfo.type === "video")?
                            this.videoThumbnailHeight : thumbnailHeight
                    };

                    // Initialize upload progress object
                    this.uploadProgress[mediaCount] = {
                        uploadPercentage: 100,
                        uploadComplete: true,
                        uploadProcessPercentage: 100,
                        uploadProcessComplete: true,
                        uploadProcessInterval: null,
                        uploadError: false,
                        removed: false
                    };

                    // Increase count
                    mediaCount += 1;
                }

                for (let i = 0; i < this.props.create.info.dot_extension.media_stories.length; i++) {
                    mediaKeys.push(mediaCount);
                    mediaCategories.push(3);
                    mediaIDs.push(this.props.create.info.dot_extension.media_stories[i]);

                    // Media info
                    let mediaInfo = null;
                    for (let j = 0; j < this.props.create.info.media.length; j++) {
                        if (this.props.create.info.dot_extension.media_stories[i] === this.props.create.info.media[j].id) {
                            mediaInfo = this.props.create.info.media[j];
                        }
                    }

                    // Calculate thumbnail width and height
                    const mediaWidth = getMediaProperty(mediaInfo, "m", 'width', false);
                    const mediaHeight = getMediaProperty(mediaInfo, "m", 'height', false);
                    const mediaURL = getMediaProperty(mediaInfo, "m", "url", false);

                    // Calculate the resized width and height of media and thumbnail
                    const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                    const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                    const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                    const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                    const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                    const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                    // Initialize media object
                    this.media[mediaCount] = {
                        mediaType: mediaInfo.type,
                        mediaURL: mediaURL,
                        mediaWidth: resizedMediaWidth,
                        mediaHeight: resizedMediaHeight,
                        mediaCategory: 3,
                        thumbnailWidth: (mediaInfo.type === "video")? this.videoThumbnailWidth : thumbnailWidth,
                        thumbnailHeight: (mediaInfo.type === "video")? this.videoThumbnailHeight : thumbnailHeight
                    };

                    // Initialize upload progress object
                    this.uploadProgress[mediaCount] = {
                        uploadPercentage: 100,
                        uploadComplete: true,
                        uploadProcessPercentage: 100,
                        uploadProcessComplete: true,
                        uploadProcessInterval: null,
                        uploadError: false,
                        removed: false
                    };

                    // Increase count
                    mediaCount += 1;
                }
            }

            //console.log("CreateSimple / constructor - mediaKeys = ", mediaKeys);
            //console.log("CreateSimple / constructor - mediaCategories = ", mediaCategories);
            //console.log("CreateSimple / constructor - mediaIDs = ", mediaIDs);
            //console.log("CreateSimple / constructor - this.media = ", this.media);
            //console.log("CreateSimple / constructor - this.uploadProgress = ", this.uploadProgress);
        }

        // Initialize states
        this.state = {
            /*
            ============================================================================================
                User Create Level
            ============================================================================================
            */
            userIsContributor: userIsContributor,

            /*
            ============================================================================================
                Write Mode
            ============================================================================================
            */
            everydayModeOn: (this.createModeOn)? false : everydayModeOn,
            authorModeOn: (this.createModeOn)? true : authorModeOn,
            contributorModeOn: (this.createModeOn)? false : contributorModeOn,

            /*
            ============================================================================================
                Dot Type Menu
            ============================================================================================
            */
            dotType: (this.createModeOn)? "SC" : dotType,

            /*
            ============================================================================================
                Create Progress
            ============================================================================================
            */
            createStage: 1,

            /*
            ============================================================================================
                Media
            ============================================================================================
            */
            selectedMediaIndex: (this.createModeOn)? null : 0,
            startMediaIndex: (this.createModeOn)? null : 0,
            endMediaIndex: (this.createModeOn)? null : Math.min(numThumbnails - 1, this.mediaCount - 1),
            numThumbnails: numThumbnails,

            // Media keys and categories
            mediaKeys: (this.createModeOn)? [] : mediaKeys,
            mediaCategories: (this.createModeOn)? [] : mediaCategories,
            mediaIDs: (this.createModeOn)? [] : mediaIDs,

            // Media warning
            mediaWarningOn: false,
            mediaWarningMessage: null,


            /*
            ============================================================================================
                Curation
            ============================================================================================
            */
            title: (this.createModeOn)? "" : this.props.create.info.title,
            overview: (this.createModeOn)? "" : this.props.create.info.overview,
            todos: "",
            history: "",
            stories: "",


            /*
            ============================================================================================
                Name and Area
            ============================================================================================
            */

            area: "",
            name: (this.createModeOn)? "" : this.props.create.info.name,
            nameOn: true,

            // Name
            nameBorderColor: "#464646",
            nameeWarningOn: false,
            nameWarningMessage: null,

            // Area
            areaBorderColor: "#464646",
            areaWarningOn: false,
            areaWarningMessage: null,

            /*
            ============================================================================================
                Sliders
            ============================================================================================
            */

            // Rating and physicality
            rating: (this.createModeOn)? 3 : rating,
            physicality: (this.createModeOn)? 2 : physicality,

            // Duration
            duration: (this.createModeOn)? 1800 : duration, // 30 mins as default
            durationScaleIndex: 0,

            // Best time
            bestTimeOn: (this.createModeOn)? true : bestTimeOn,
            bestTimeMode: (this.createModeOn)? "single" : bestTimeType, // allday | single | double,
            bestTimeFirst: (this.createModeOn)? 0 : bestTimeFirst,
            bestTimeSecond: (this.createModeOn)? 4 : bestTimeSecond,

            /*
            ============================================================================================
                Location
            ============================================================================================
            */
            // Location options
            locationOptionsOn: false,

            // Location detected on
            locationDetectedOn: false,
            locationDetected: null,

            // Accessibility
            accessibilityMode: (this.createModeOn)? "drivable" : accessibilityMode,

            // Preferred travel method
            preferredMode: (this.createModeOn)? "drive" : preferredMode,

            // Location type
            locationType: "location",

            // Location object
            location: (this.createModeOn)? this.props.create.location : this.props.create.info.location,
            parkingLocation: (this.createModeOn)? null : parkingLocation,
            startLocation: (this.createModeOn)? null : startLocation,
            startLocationName: (this.createModeOn)? null : startLocationName,
            endLocation: (this.createModeOn)? null : endLocation,
            endLocationName: (this.createModeOn)? null : endLocationName,

            // Location and warning
            locationBorderColor: "#464646",
            locationLatBorderColor: "#464646",
            locationLngBorderColor: "#464646",
            locationWarningOn: false,
            locationWarningMessage: null,

            // Roundtrip
            //roundtrip: true,

            // Loop
            //loop: false,

            // Reversible
            //reversible: true,

            // State
            state: (this.createModeOn)? null : this.props.create.info.state,
            //stateStart: null,
            //stateEnd: null,

            /*
            ============================================================================================
                Map
            ============================================================================================
            */

            // Map objects
            map: null,
            mapConnet: null,

            // Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false, 

            /*
            ============================================================================================
                Business Hours
            ============================================================================================
            */

            hoursOn: (this.createModeOn)? true : hoursOn,
            startHour: (this.createModeOn)? {
                hour: 6,
                minute: 0,
                meridiem: "am",
                formatted: "6:00am"
            } : startHour,
            endHour: (this.createModeOn)? {
                hour: 9,
                minute: 0,
                meridiem: "pm",
                formatted: "9:00pm"
            } : endHour,

            /*
            ============================================================================================
                Season Closure Dates
            ============================================================================================
            */

            seasonClosureType: (this.createModeOn)? "estimate" : seasonClosureType,
            closureStartDate: (this.createModeOn)? moment().month(11).date(1) : closureStartDate,
            closureEndDate: (this.createModeOn)? moment().year(moment().year() + 1).month(5).date(15) : closureEndDate,

            /*
            ============================================================================================
                Dining Options
            ============================================================================================
            */
            diningOptions: (this.createModeOn)? false : diningOptions,

            /*
            ============================================================================================
                Stories
                - title
                - overview 
                - todos
                - history
                - stories
            ============================================================================================
            */
            // Title / overview warnings
            titleBorderColor: "#464646",
            titleWarningOn: false,
            titleWarningMessage: null,

            overviewBorderColor: "#464646",
            overviewWarningOn: false,
            overviewMessage: null,

            // Todos / history / stories warnings
            todosBorderColor: "#464646",
            todosWarningOn: false,
            todosWarningMessage: null,

            historyBorderColor: "#464646",
            historyWarningOn: false,
            historyWarningMessage: null,

            storiesBorderColor: "#464646",
            storiesWarningOn: false,
            storiesWarningMessage: null,


            /*
            ============================================================================================
                Curation Mode
            ============================================================================================
            */
            curationMode: "todos",

            /*
            ============================================================================================
                Progress Warnings
            ============================================================================================
            */
            progressFirstWarningOn: false,
            progressFirstWarningMessage: null,
            progressSecondWarningOn: false,
            progressSecondWarningMessage: null,
            progressThirdWarningOn: false,
            progressThirdWarningMessage: null,


            /*
            ============================================================================================
                Publish Button
            ============================================================================================
            */
            progressPublishButtonHoverOn: false,


            /*
            ============================================================================================
                Publish Button
            ============================================================================================
            */
            completeModalOn: false,


            /*
            ============================================================================================
                Created Object ID
            ============================================================================================
            */
            objectSlug: null,


            /*
            ============================================================================================
                Upload Indicator
            ============================================================================================
            */
            uploadUpdate: false,


            /*
            ============================================================================================
                Complete
            ============================================================================================
            */
            isPublic: true,
            saveLimit: 4,
            completeButtonHoverOn: false
        };

        // Location object [lat, lng]
        this.location = null;
        
        // DOM nodes of inputs
        this.imageNode = null;
        this.videoNode = null;
        this.locationRef = React.createRef();
        this.locationLatRef = React.createRef();
        this.locationLngRef = React.createRef();
        this.nameRef = React.createRef();
        this.areaRef = React.createRef();
        this.titleRef = React.createRef();
        this.overviewRef = React.createRef();
        this.todosRef = React.createRef();
        this.historyRef = React.createRef();
        this.storiesRef = React.createRef();
        this.autoCompleteNode = null;

        // Form input focused states
        this.mediaFocused = false;
        this.locationFocused = false;
        this.locationLatFocused = false;
        this.locationLngFocused = false;
        this.nameFocused = false;
        this.areaFocused = false;
        this.titleFocused = false;
        this.overviewFocused = false;
        this.todosFocused = false;
        this.historyFocused = false;
        this.storiesFocused = false;
        this.connectFocused = false;

        // Media warning timer
        this.mediaWarningTimer = null;
        this.locationWarningTimer = null;
        this.nameWarningTimer = null;
        this.areaWarningTimer = null;
        this.titleWarningTimer = null;
        this.overviewWarningTimer = null;
        this.todosWarningTimer = null;
        this.historyWarningTimer = null;
        this.storiesWarningTimer = null;
        this.connectWarningTimer = null;

        // Transition times
        this.transitionTime = 300;
        this.delayTime = 1000;

        // Next button
        this.nextButton = null;

        // Bind setState
        this.setState = this.setState.bind(this);

        // Bind location callbacks
        this.locationDetectConfirm = this.locationDetectConfirm.bind(this);
        this.locationDetectCancel = this.locationDetectCancel.bind(this);

        // Bind the object type callbacks
        this.dotModeClick = this.dotModeClick.bind(this);

        // Bind write mode callbacks
        this.everydayModeClick = this.everydayModeClick.bind(this);
        this.authorModeClick = this.authorModeClick.bind(this);
        this.contributorModeClick = this.contributorModeClick.bind(this);

        // Bind the dot type callbacks
        this.scenicTypeClick = this.scenicTypeClick.bind(this);
        this.experienceTypeClick = this.experienceTypeClick.bind(this);
        this.dineTypeClick = this.dineTypeClick.bind(this);

        // Input callbacks
        this.colorInputBorders = this.colorInputBorders.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        //this.clearInput = this.clearInput.bind(this);

        // Bind media callbacks
        this.addImage = addImage.bind(this);
        this.addVideo = addVideo.bind(this);
        this.removeMedia = removeMedia.bind(this);
        this.addSearchedMedia = addSearchedMedia.bind(this);
        this.clearSearchedMedia = clearSearchedMedia.bind(this);
        this.selectMedia = selectMedia.bind(this);
        this.moveMediaLeft = moveMediaLeft.bind(this);
        this.moveMediaRight = moveMediaRight.bind(this);
        this.thumbnailPrevPage = thumbnailPrevPage.bind(this);
        this.thumbnailNextPage = thumbnailNextPage.bind(this);
        this.updateIndices = updateIndices.bind(this);

        // Bind map callbacks
        this.resetMapAnimations = this.resetMapAnimations.bind(this);

        // Location
        this.toggleLocationOptions = this.toggleLocationOptions.bind(this);
        this.clearLocationSearch = this.clearLocationSearch.bind(this);
        this.clearLocationGPS = this.clearLocationGPS.bind(this);
        this.dotLocationDrivableClick = this.dotLocationDrivableClick.bind(this);
        this.dotLocationParkAndWalkClick = this.dotLocationParkAndWalkClick.bind(this);
        this.dotLocationUndrivableClick = this.dotLocationUndrivableClick.bind(this);
        this.tripLocationDrivableClick = this.tripLocationDrivableClick.bind(this);
        this.tripLocationUndrivableClick = this.tripLocationUndrivableClick.bind(this);
        this.locationGPSClick = this.locationGPSClick.bind(this);
        this.locationOnChange = this.locationOnChange.bind(this);
        this.locationLatOnChange = this.locationLatOnChange.bind(this);
        this.locationLngOnChange = this.locationLngOnChange.bind(this);
        
        // Duration callback
        this.formatDuration = formatDuration.bind(this);

        // Bind the season closure type callbacks
        this.updateSeasonClosureStartDate = this.updateSeasonClosureStartDate.bind(this);
        this.updateSeasonClosureEndDate = this.updateSeasonClosureEndDate.bind(this);        

        // Name
        this.nameOnChange = this.nameOnChange.bind(this);

        // Area
        this.areaOnChange = this.areaOnChange.bind(this);

        // Title
        this.titleOnChange = this.titleOnChange.bind(this);

        // Overview
        this.overviewOnChange = this.overviewOnChange.bind(this);
        this.overviewResize = this.overviewResize.bind(this);
        this.overviewResizeDelayed = this.overviewResizeDelayed.bind(this);

        // Curation
        this.todosOnChange = this.todosOnChange.bind(this);
        this.historyOnChange = this.historyOnChange.bind(this);
        this.storiesOnChange = this.storiesOnChange.bind(this);
        this.todosResize = this.todosResize.bind(this);
        this.historyResize = this.historyResize.bind(this);
        this.storiesResize = this.storiesResize.bind(this);
        this.todosResizeDelayed = this.todosResizeDelayed.bind(this);
        this.historyResizeDelayed = this.historyResizeDelayed.bind(this);
        this.storiesResizeDelayed = this.storiesResizeDelayed.bind(this);

        // Connect
        this.updateTransitDurations = this.updateTransitDurations.bind(this);
        this.updateTransitPhysicalities = this.updateTransitPhysicalities.bind(this);
        this.updateTransitDistances = this.updateTransitDistances.bind(this);
        this.updateDurationScaleIndices = this.updateDurationScaleIndices.bind(this);
        this.updateDistanceScaleIndices = this.updateDistanceScaleIndices.bind(this);

        // Bind warning callbacks
        this.clearLocationWarning = this.clearLocationWarning.bind(this);
        this.clearNameWarning = this.clearNameWarning.bind(this);
        this.clearTitleWarning = this.clearTitleWarning.bind(this);
        this.clearOverviewWarning = this.clearOverviewWarning.bind(this);
        this.clearTodosWarning = this.clearTodosWarning.bind(this);
        this.clearHistoryWarning = this.clearHistoryWarning.bind(this);
        this.clearStoriesWarning = this.clearStoriesWarning.bind(this);

        /*
        this.showLocationWarning = this.showLocationWarning.bind(this);
        this.showTitleWarning = this.showTitleWarning.bind(this);
        this.showOverviewWarning = this.showOverviewWarning.bind(this);
        this.showTodosWarning = this.showTodosWarning.bind(this);
        this.showHistoryWarning = this.showHistoryWarning.bind(this);
        this.showStoriesWarning = this.showStoriesWarning.bind(this);
        this.clearConnectWarning = this.clearConnectWarning.bind(this);
        this.showConnectWarning = this.showConnectWarning.bind(this);
        */
        this.checkProgressFirstWarning = this.checkProgressFirstWarning.bind(this);
        this.checkProgressSecondWarning = this.checkProgressSecondWarning.bind(this);
        this.checkProgressThirdWarning = this.checkProgressThirdWarning.bind(this);

        // Bind the progress button callbacks
        this.progressPublishClick = this.progressPublishClick.bind(this);

        // Get state
        this.getState = this.getState.bind(this);

        // Complete
        this.completeButtonClick = this.completeButtonClick.bind(this);

        // Scroll to top
        this.scrollToTop = this.scrollToTop.bind(this);

        // Modal functions
        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);        
    }

    
    escClick(event) {
        // Disappear create modal on pressing esc
        if (this.props.create.modalOn === true && event.keyCode === 27) {
            //console.log("CreateSimple / escClick");

            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            //console.log("CreateSimple / clickOutside");
            //console.log("CreateSimple / clickOutside - event.target = ", event.target);

            //this.closeModal();
        }
    }

    closeModal() {
        this.props.storeCreate({
            modalOn: false,
            mode: null,
            setSlug: null,
            location: null,
            info: null
        });
    }
    
    render() {
        //console.log("CreateSimple / render - this.props = ", this.props);
        //console.log("CreateSimple / render - this.props.create = ", this.props.create);

        /*
        ============================================================================================
            Object Type
        ============================================================================================
        */
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";


        /*
        ============================================================================================
            Everyday Mode
        ============================================================================================
        */
        const everydayMode = (this.state.everydayModeOn)?
        (
            <div id = "create-modal-everyday-mode"
                onClick = {this.everydayModeClick}
            >
                <div id = "create-modal-everyday-mode-icon"
                    className = "create-modal-everyday-mode-icon"
                    style = {{ 
                        backgroundImage: (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/everyday-light-blue.png") :
                            getStaticPath("/images/create/everyday-blue.png")
                    }}
                >
                </div>
                <div id = "create-modal-everyday-mode-text"
                    className = {(this.props.colorMode === "day")?
                        "create-modal-everyday-mode-text k4" :
                        "create-modal-everyday-mode-text w4" }
                >
                    Everyday Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Author Mode
        ============================================================================================
        */
        const authorMode = (this.state.authorModeOn)? 
        (
            <div id = "create-modal-author-mode"
                onClick = {this.authorModeClick}
            >
                <div id = "create-modal-author-mode-icon"
                    className = "create-modal-author-mode-icon"
                    style = {{ 
                        backgroundImage: (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/fountain-pen-light-blue.png") :
                            getStaticPath("/images/create/fountain-pen-blue.png")
                    }}
                >
                </div>
                <div id = "create-modal-author-mode-text"
                    className = {(this.props.colorMode === "day")?
                        "create-modal-author-mode-text k4" :
                        "create-modal-author-mode-text w4" }
                >
                    Author Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Contributor Mode
        ============================================================================================
        */
        const contributorMode = (this.state.userIsContributor && this.state.contributorModeOn)?
        (
            <div id = "create-modal-contributor-mode"
                style = {{ display: (this.state.userIsContributor)?  "inline-block" : "none" }}
                onClick = {this.contributorModeClick}
            >
                <div id = "create-modal-contributor-mode-icon"
                    className = "create-modal-contributor-mode-icon"
                    style = {{
                        backgroundImage: (this.props.colorMode === "day")?
                            getStaticPath("/images/create/contributor-light-blue.png") :
                            getStaticPath("/images/create/contributor-blue.png")
                    }}
                >
                </div>
                <div id = "create-modal-contributor-mode-text"
                    className = { 
                        (this.props.colorMode === "day")?
                            "create-modal-contributor-mode-text k4" :
                            "create-modal-contributor-mode-text w4"}
                >
                    Contributor Mode
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Map and Location
        ============================================================================================
        */

        /*
            <div id = "create-modal-location-search-title">
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-location-search-title-text-double k4" :
                        "create-modal-location-search-title-text-double w4"}
                >
                    Location
                </div>
                 <div className = {(this.props.colorMode === "day")?
                        "create-modal-location-search-title-text-double k4" :
                        "create-modal-location-search-title-text-double w4"}
                >
                    Search
                </div>
            </div>
        */

        // Location placeholder
        const locationPlaceholder = (this.state.contributorModeOn)? 
            this.placeholderLocation : this.placeholderLocationSimple;

        // TODO - Add name and other things?
        let map = null;
        let locationType = null;

        // Construct a temporary dot
        const dotInfo = {
            name: "location",
            location: this.state.location
        };

        const dotsInfo = [
            dotInfo
        ];

        // Create mode
        let createMode = null;
        if ((this.state.dotType !== "TR") && (this.state.dotType !== "RT")) {
            createMode = "dot";
        }
        else {
            createMode = (this.state.dotType === "TR")? "trip" : "route";
        }

        // Only when google object and locations are ready
        if ((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null)) {
            if ((this.mapSource === "google") && (this.props.google)) {
                // Set the props for Map component
                const mapProps = {
                    // Google
                    google: this.props.google,

                    // Map mode
                    mapMode: this.mapMode,

                    // Create mode
                    createMode: createMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    mapContainerNodeID : this.mapContainerNodeID,
                    buttonsNodeID: this.mapButtonsNodeID,
                    startInputNodeID: null,
                    endInputNodeID: null,

                    // Dots
                    dotsInfo: (objectType === "dot")? dotsInfo : null,

                    // Itinerary and selected settings
                    itinerary: (objectType === "dot")? [0] : [],
                    selected: 0,
                    hovered: null,
                    selectedChild: null,
                    hoveredChild: null,
                    displayChildren: null,

                    // Roundtrip
                    roundtrip: this.state.roundtrip,

                    // Loop
                    loop: this.state.loop,

                    // Drivable
                    drivable: (this.state.accessibilityMode === "undrivable")? false : true,

                    // Location mode
                    locationType: this.state.locationType,

                    // Locations
                    parkingLocation: this.state.parkingLocation,
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Map height
                    mapWidth: this.mapWidth,
                    mapHeight: (this.props.browserWidth <= 4)? 
                        this.mapHeightSmall : this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Action triggers
                    mapResetMarkers: this.state.mapResetMarkers,
                    mapZoomInAnimation: this.state.mapZoomInAnimation,
                    mapZoomOutAnimation: this.state.mapZoomOutAnimation,
                    mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
                    mapRefreshAnimation: this.state.mapRefreshAnimation,
                    resetAnimations: this.resetMapAnimations,

                    // Dot click and hover callback
                    dotClick: null,
                    dotHoverOn: null,
                    dotHoverOff: null,
                    endHoverOn: null,
                    endHoverOff: null,

                    // Default zoom level
                    mapZoom: this.mapZoom,
                    mapType: "hybrid",

                    // Set location
                    setLocation: (location) => {
                        if (this.state.accessibilityMode !== "parkandwalk") {
                            this.setState(
                                {
                                    location: location,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    location: location,
                                }
                            );
                        }
                    },

                    // Set parking location
                    setParkingLocation: (parkingLocation) => {
                        if (this.state.location !== null) {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                }
                            );
                        }
                    },

                    // Set start location
                    setStartLocation: (startLocation) => {
                        if (this.state.loop || this.state.roundtrip || this.state.endLocation !== null) {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set end location
                    setEndLocation: (endLocation) => {
                        if (this.state.startLocation !== null) {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set map
                    setMap: (map) => {this.setState({ map: map });},

                    // Clear location input
                    clearLocationSearch: this.clearLocationSearch,
                    clearLocationGPS: this.clearLocationGPS
                };

                // Create map
                map = (
                    <GoogleMap {...mapProps}/>
                );
            }
            else if (this.mapSource === "open") {
                // Set the props for Map component
                const openMapProps = {
                    // Map mode
                    mapMode: this.mapMode,

                    // Create mode
                    createMode: createMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,

                    // Map width and height
                    mapWidth: this.mapWidth,
                    mapHeight: (this.props.browserWidth <= 4)? 
                        this.mapHeightSmall : this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: dotsInfo,

                    // Itinerary and selected settings
                    itinerary: (objectType === "dot")? [0] : [],
                    selected: (objectType === "dot")? 0 : null,
                    selectedChild: null,
                    displayChildren: null,

                    // Roundtrip
                    roundtrip: this.state.roundtrip,

                    // Loop
                    loop: this.state.loop,

                    // Drivable
                    drivable: (this.state.accessibilityMode === "undrivable")? false : true,

                    // Location mode
                    locationType: this.state.locationType,

                    // Parking / start / end locations
                    parkingLocation: this.state.parkingLocation,
                    startLocation: this.state.startLocation,
                    endLocation: this.state.endLocation,

                    // Map zoom / center / type
                    mapZoom: this.mapZoom,
                    mapCenter: (objectType === "dot")? 
                        (
                            (this.state.locationType === "parking")?
                                this.state.parkingLocation : this.state.location
                        ) : (
                            (this.state.locationType === "start")?
                                this.state.startLocation : this.state.endLocation
                        ),
                    mapType: "hybrid",

                    // SetState
                    setState: this.setState,

                     // Set location
                    setLocation: (location) => {
                        if (this.state.accessibilityMode !== "parkandwalk") {
                            this.setState(
                                {
                                    location: location,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    location: location,
                                }
                            );
                        }
                    },

                    // Set parking location
                    setParkingLocation: (parkingLocation) => {
                        if (this.state.location !== null) {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    parkingLocation: parkingLocation,
                                }
                            );
                        }
                    },

                    // Set start location
                    setStartLocation: (startLocation) => {
                        if (this.state.loop || this.state.roundtrip || this.state.endLocation !== null) {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    startLocation: startLocation,
                                    startLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + startLocation.latitude + ", " + startLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set end location
                    setEndLocation: (endLocation) => {
                        if (this.state.startLocation !== null) {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                    locationWarningOn: false,
                                    locationWarningMessage: null
                                },
                                this.colorInputBorders
                            );
                        }
                        else {
                            this.setState(
                                {
                                    endLocation: endLocation,
                                    endLocationName: (this.locationRef.current.value.length === 0)? 
                                    "" + endLocation.latitude + ", " + endLocation.longitude : this.locationRef.current.value,
                                }
                            );
                        }
                    },

                    // Set map
                    setMap: (map) => {this.setState({ map: map });},

                    // Clear location input
                    clearLocationSearch: this.clearLocationSearch,
                    clearLocationGPS: this.clearLocationGPS                    
                };

                // Get the Map component
                map = (
                    <OpenMap {...openMapProps}/>
                );
            }

            // Location type buttons
            if (objectType === "dot") {
                locationType = (this.state.accessibilityMode === "parkandwalk")? (
                    <div id = "create-modal-location-type">
                        <div id = "create-modal-location-type-actual"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}
                            style = {
                                (this.state.locationType === "location")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => { this.setState({ locationType: "location" }); }}
                        >
                            Location
                        </div>
                        <div id = "create-modal-location-type-parking"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}
                            style = {
                                (this.state.locationType === "parking")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => { this.setState({ locationType: "parking" }); }}
                        >
                            Parking
                        </div>
                    </div>
                ) : null;
            }
            else {
                // End location
                const endLocationButton = (!this.state.roundtrip && !this.state.loop)? (
                    <div id = "create-modal-location-type-end"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.locationType === "end")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => { this.setState({ locationType: "end" }); }}
                    >
                        End Location
                    </div>
                ) : null;

                // Start location
                const startLocationButtonText = (this.state.roundtrip || this.state.loop)?
                    "Parking Location" : "Start Location";

                locationType = ((this.state.roundtrip || this.state.loop) && (this.state.accessibilityMode === "undrivable"))? null : (
                    <div id = "create-modal-location-type">
                        <div id = "create-modal-location-type-start"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}

                            style = {
                                (this.state.locationType === "start")?
                                    {
                                        width: (this.state.roundtrip || this.state.loop)? "100%" : "50%",
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        width: (this.state.roundtrip || this.state.loop)? "100%" : "50%",
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {(this.state.roundtrip || this.state.loop)?
                                null: () => { this.setState({ locationType: "start" }); }}
                        >
                            {startLocationButtonText}
                        </div>
                        {endLocationButton}
                    </div>
                );
            }
        }

        // Location values
        //console.log("this.state.location = ", this.state.location);
        let locationLat = null;
        let locationLng = null;
        if (this.state.locationType === "location") {
            locationLat = (this.state.location === null)? "Latitude" : this.state.location.latitude.toFixed(6);
            locationLng = (this.state.location === null)? "Longitude" : this.state.location.longitude.toFixed(6);
        }
        else if (this.state.locationType === "parking") {
            locationLat = (this.state.parkingLocation === null)? "Latitude" : this.state.parkingLocation.latitude.toFixed(6);
            locationLng = (this.state.parkingLocation === null)? "Longitude" : this.state.parkingLocation.longitude.toFixed(6);
        }
        else if (this.state.locationType === "start") {
            locationLat = (this.state.startLocation === null)? "Latitude" : this.state.startLocation.latitude.toFixed(6);
            locationLng = (this.state.startLocation === null)? "Longitude" : this.state.startLocation.longitude.toFixed(6);
        }
        else if (this.state.locationType === "end") {
            locationLat = (this.state.endLocation === null)? "Latitude" : this.state.endLocation.latitude.toFixed(6);
            locationLng = (this.state.endLocation === null)? "Longitude" : this.state.endLocation.longitude.toFixed(6);
        }


        /*
        ============================================================================================
            Name
        ============================================================================================
        */
        const namePlaceholder = (this.state.contributorModeOn)? this.placeholderName : this.placeholderNameSimple;

        const name = (
            <div style = {{ display: (objectType === "dot" && this.state.nameOn)? "block" : "none" }}>
                <div className = "create-modal-basic-row create-modal-content">
                    <input
                        ref = {this.nameRef}
                        type = "text"
                        id = "create-modal-name"
                        className = {(this.props.colorMode === "day")? 
                            "input-s3 input-day" : "input-s3 input-night"}
                        value = {this.state.name}
                        placeholder = {namePlaceholder}
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                        onChange = {this.nameOnChange}
                        style = {{ borderColor: this.state.nameBorderColor }}
                        required
                    />
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-warning warning-day-s2" :
                        "create-modal-warning warning-night-s2"}
                    style = {{ display: this.state.nameWarningOn? "block" : "none" }}
                >
                    {this.state.nameWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Area
        ============================================================================================
        */
        const area = (
            <div style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}>
                <div className = "create-modal-basic-row create-modal-content">
                    <input
                        ref = {this.areaRef}
                        type = "text"
                        id = "create-modal-area"
                        className = {(this.props.colorMode === "day")? 
                            "input-s3 input-day" : "input-s3 input-night"}
                        value = {this.state.area}
                        placeholder = {this.placeholderArea}
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                        onChange = {this.areaOnChange}
                        style = {{ borderColor: this.state.areaBorderColor }}
                        required
                    />
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-warning warning-day-s2" :
                        "create-modal-warning warning-night-s2"}
                    style = {{ display: this.state.areaWarningOn? "block" : "none" }}>
                    {this.state.areaWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Sliders
        ============================================================================================
        */

        /*
        inputRange: "input-range",
        disabledInputRange: "input-range input-range--disabled",

        slider: "input-range__slider",
        sliderContainer: "input-range__slider-container",

        track: "input-range__track input-range__track--background",
        activeTrack: "input-range__track input-range__track--active",

        labelContainer: "input-range__label-container",
        maxLabel: "input-range__label input-range__label--max",
        minLabel: "input-range__label input-range__label--min",
        valueLabel: "input-range__label input-range__label--value"
        */

        const sliderClassNames = (this.props.colorMode === "day")? 
        {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-day",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-day input-range__track--background",
            activeTrack: "input-range-track-day input-range-track-active-day",

            labelContainer: "input-range-label-container-day",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"
        } : {
            inputRange: "input-range",
            disabledInputRange: "input-range input-range--disabled",

            slider: "input-range-slider-night",
            sliderContainer: "input-range__slider-container",

            track: "input-range-track-night input-range__track--background",
            activeTrack: "input-range-track-night input-range-track-active-night",

            labelContainer: "input-range-label-container-night",
            maxLabel: "input-range__label input-range__label--max",
            minLabel: "input-range__label input-range__label--min",
            valueLabel: "input-range__label input-range__label--value"            
        };

        const ratingTooltipText = (this.state.dotType === "RT" || this.state.dotType === "TR")?
            "<p>Overall Satisfaction</p><p>Rating of the Trip</p>"
            : "<p>Overall Satisfaction</p><p>Rating of the Place</p><p>or Experience</p>";
        const ratingSlider = (
            <div className = "create-modal-detail-row"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "create-modal-rating-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = {ratingTooltipText}
                    data-for = "create-modal-rating-title-tooltip"
                >
                    Rating
                </div>
                <div id = "create-modal-rating">
                    <InputRange
                        classNames = {sliderClassNames}
                        maxValue = {5}
                        minValue = {0}
                        value = {this.state.rating}
                        onChange = {rating => this.setState({ rating: rating })}
                        formatLabel = {rating => {return rating}}
                        showEndLabels = {false}
                        step = {0.5}
                    />
                </div>
                <ReactTooltip
                    id = "create-modal-rating-title-tooltip"
                    className = "create-modal-rating-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );

        const physicalitySlider = (
            <div className = "create-modal-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && ((objectType === "dot") && ((this.state.dotType === "SC") || (this.state.dotType === "EX"))))? "block" : "none" }}
            >
                <div id = "create-modal-physicality-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Physical Intensity of</p><p>the Stay or Experience</p>"
                    data-for = "create-modal-physicality-title-tooltip"
                >
                    Physicality
                </div>
                <div id = "create-modal-physicality">
                    <InputRange
                        classNames = {sliderClassNames}
                        maxValue = {5}
                        minValue = {0}
                        value = {this.state.physicality}
                        onChange = {physicality => this.setState({ physicality: physicality })}
                        formatLabel = {physicality => {return this.physicalityLabels[String(Math.round(physicality / 0.5))]}}
                        showEndLabels = {false}
                        step = {0.5}
                    />
                </div>
                <ReactTooltip
                    id = "create-modal-physicality-title-tooltip"
                    className = "create-modal-physicality-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );

        const sliderIncreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/increase-black.png") :
            getStaticPath("/images/common/increase-white.png");

        const sliderDecreaseImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/decrease-black.png") :
            getStaticPath("/images/common/decrease-white.png");

        let durationTitle = null;
        if (this.state.dotType === "SC") {
            durationTitle = "Stay Time";
        }
        else if (this.state.dotType === "EX" || this.state.dotType === "DE") {
            durationTitle = "Activity Time";
        }
        else if (this.state.dotType === "DI") {
            durationTitle = "Dine Time";
        }

        const durationSlider = (
            <div className = "create-modal-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (objectType === "dot"))? "block" : "none" }}
            >
                <div id = "create-modal-duration-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Needed Time to</p><p>Enjoy the Place</p>"
                    data-for = "create-modal-duration-title-tooltip"
                >
                    {durationTitle}
                </div>
                <div id = "create-modal-duration">
                    <div id = "create-modal-duration-slider-increase"
                        data-tip = "Increase Time Scale"
                        data-for = "create-modal-duration-slider-increase-tooltip"
                        style = {{
                            backgroundImage: sliderIncreaseImage,
                            display: (this.state.durationScaleIndex === (this.durationScales.length - 1))? "none" : "block"
                        }}
                        onClick = {
                            ()=> {
                                this.setState({ durationScaleIndex: this.state.durationScaleIndex + 1 });
                            }
                        }
                    >
                    </div>
                    <div id = "create-modal-duration-slider-decrease"
                        data-tip = "Decrease Time Scale"
                        data-for = "create-modal-duration-slider-decrease-tooltip"
                        style = {{
                            backgroundImage: sliderDecreaseImage,
                            display: (this.state.durationScaleIndex === 0)? "none" : "block"
                        }}
                        onClick = {
                            ()=> {
                                this.setState({
                                    durationScaleIndex: this.state.durationScaleIndex - 1,
                                    duration: Math.min(this.state.duration, this.durationScales[this.state.durationScaleIndex - 1])
                                })
                            }
                        }
                    >
                    </div>
                    <InputRange
                        classNames = {sliderClassNames}
                        maxValue = {this.durationScales[this.state.durationScaleIndex]}
                        minValue = {300}
                        value = {this.state.duration}
                        onChange = {duration => this.setState({ duration: duration })}
                        formatLabel = {duration => this.formatDuration(duration)}
                        showEndLabels = {false}
                        step = {300}
                    />
                </div>
                <ReactTooltip
                    id = "create-modal-duration-title-tooltip"
                    className = "create-modal-duration-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
                <ReactTooltip
                    id = "create-modal-duration-slider-decrease-tooltip"
                    className = "create-modal-duration-slider-decrease-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                />
                <ReactTooltip
                    id = "create-modal-duration-slider-increase-tooltip"
                    className = "create-modal-duration-slider-increase-tooltip tooltip-s2"
                    type = "dark"
                    place = "right"
                />
            </div>
        );

        const bestTimeSlider = (
            <div className = "create-modal-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (this.state.bestTimeOn))? "block" : "none" }}
            >
                <div id = "create-modal-best-time-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Best Time of the Day</p><p>to Experience the Place</p>"
                    data-for = "create-modal-best-time-title-tooltip"
                >
                    Best Time
                </div>

                <div id = "create-modal-best-time">
                    <div id = "create-modal-best-time-first"
                        style = {{ display: (this.state.bestTimeMode !== "allday")? "block" : "none" }}
                    >
                        <InputRange
                            classNames = {sliderClassNames}
                            maxValue = {4}
                            minValue = {0}
                            value = {this.state.bestTimeFirst}
                            onChange = {bestTime => this.setState({ bestTimeFirst: bestTime })}
                            formatLabel = {bestTime => this.bestTimeLabels[bestTime]}
                            showEndLabels = {false}
                            step = {1}
                        />
                    </div>

                    <div id = "create-modal-best-time-second"
                        style = {{ display: (this.state.bestTimeMode === "double")? "block" : "none" }}
                    >
                        <InputRange
                            classNames = {sliderClassNames}
                            maxValue = {4}
                            minValue = {0}
                            value = {this.state.bestTimeSecond}
                            onChange = {bestTime => this.setState({ bestTimeSecond: bestTime })}
                            formatLabel = {bestTime => this.bestTimeLabels[bestTime]}
                            showEndLabels = {false}
                            step = {1}
                        />
                    </div>

                    <div id = "create-modal-best-time-buttons">
                        <div id = "create-modal-best-time-single"
                            className = {(this.props.colorMode === "day")?
                                "create-modal-best-time-button button-light-blue-s3" :
                                "create-modal-best-time-button button-blue-s3"}
                            style = {
                                (this.state.bestTimeMode === "single")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ bestTimeMode: "single" });}}
                        >
                            Single
                        </div>
                        <div id = "create-modal-best-time-double"
                            className = {(this.props.colorMode === "day")?
                                "create-modal-best-time-button button-light-blue-s3" :
                                "create-modal-best-time-button button-blue-s3"}
                            style = {
                                (this.state.bestTimeMode === "double")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ bestTimeMode: "double" });}}
                        >
                            Double
                        </div>
                        <div id = "create-modal-best-time-all-day"
                            className = {(this.props.colorMode === "day")?
                                "create-modal-best-time-button button-light-blue-s3" :
                                "create-modal-best-time-button button-blue-s3"}
                            style = {
                                (this.state.bestTimeMode === "allday")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ bestTimeMode: "allday" });}}
                        >
                            All Day
                        </div>
                    </div>
                </div>

                <ReactTooltip
                    id = "create-modal-best-time-title-tooltip"
                    className = "create-modal-best-time-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );

        
        /*
        ============================================================================================
            Dot Type Menu
        ============================================================================================
        */

        // Images
        const scenicDotImage = (this.state.dotType === "SC")? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-scenic-light-blue.png") :
                    getStaticPath("/images/common/category-scenic-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-scenic-black.png") :
                    getStaticPath("/images/common/category-scenic-white.png")
            );

        const experienceDotImage = (this.state.dotType === "EX")? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-experience-light-blue.png") :
                    getStaticPath("/images/common/category-experience-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-experience-black.png") :
                    getStaticPath("/images/common/category-experience-white.png")
            );

        const dineDotImage = (this.state.dotType === "DI")? 
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/category-dine-light-blue.png") :
                    getStaticPath("/images/common/category-dine-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/common/category-dine-black.png") :
                    getStaticPath("/images/common/category-dine-white.png")
            );

        const dotTypeMenu = (
            <div className = "create-modal-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (objectType === "dot"))? "block" : "none" }}
            >
                <div className = {(this.props.colorMode === "day")? 
                        "create-modal-dot-type-title k4" : 
                        "create-modal-dot-type-title w4"}
                    data-tip = "<p>The Type of Dot</p><p>You Want to Create</p>"
                    data-for = "create-modal-dot-type-title-tooltip"
                >
                    Category
                </div>
                <div id = "create-modal-dot-type-container"
                    className = "clear-fix"
                >
                    <div className = "create-modal-dot-type-float"
                        style = {
                            (this.state.dotType === "SC")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "create-modal-dot-type-scenic"
                            className = "image-contain"
                            style = {{ backgroundImage: scenicDotImage }}
                            onClick = {this.scenicTypeClick}
                            data-tip = "Scenic Dot"
                            data-for = "create-modal-dot-type-scenic-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-modal-dot-type-scenic-tooltip"
                        className = "create-modal-dot-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />

                    <div className = "create-modal-dot-type-float"
                        style = {
                            (this.state.dotType === "EX")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "create-modal-dot-type-experience"
                            className = "image-contain"
                            style = {{ backgroundImage: experienceDotImage }}
                            onClick = {this.experienceTypeClick}
                            data-tip = "Experiences"
                            data-for = "create-modal-dot-type-experience-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-modal-dot-type-experience-tooltip"
                        className = "create-modal-dot-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />

                    <div className = "create-modal-dot-type-float"
                        style = {
                            (this.state.dotType === "DI")? { opacity: 1.0 } : null
                        }
                    >
                        <div id = "create-modal-dot-type-dine"
                            className = "image-contain"
                            style = {{ backgroundImage: dineDotImage }}
                            onClick = {this.dineTypeClick}
                            data-tip = "A Restaurant or Diner"
                            data-for = "create-modal-dot-type-dine-tooltip"
                        >
                        </div>
                    </div>

                    <ReactTooltip
                        id = "create-modal-dot-type-dine-tooltip"
                        className = "create-modal-dot-type-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />
                </div>
                <ReactTooltip
                    id = "create-modal-dot-type-title-tooltip"
                    className = "create-modal-dot-type-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Hours
        ============================================================================================
        */
        const hoursArrow = (this.props.colorMode === "day")?
            getStaticPath("/images/create/hours-arrow-black.png") :
            getStaticPath("/images/create/hours-arrow-white.png");

        const hourPickers = (
            <div id = "create-modal-hours-picker-container"
                style = {{ display: (this.state.hoursOn)? "block" : "none" }}
            >
                <div id = "create-modal-hours-arrow"
                    style = {{ backgroundImage: hoursArrow }}
                >
                </div>
                <div id = "create-modal-start-hour">
                    <TimeKeeper 
                        time = {this.state.startHour.formatted}
                        onChange = {(setTime) => {
                            this.setState(
                                {
                                    startHour: setTime
                                }
                            );
                        }}
                    />
                </div>
                <div id = "create-modal-end-hour">
                    <TimeKeeper 
                        time = {this.state.endHour.formatted}
                        onChange = {(setTime) => {
                            this.setState(
                                {
                                    endHour: setTime
                                }
                            );
                        }}
                    />
                </div>
            </div>
        );

        const hours = (
            <div className = "create-modal-detail-row"
                style = {{ 
                    display: ((this.state.contributorModeOn) && ((this.state.dotType === "DI") || (this.state.dotType === "EX") || (this.state.dotType === "RT")))? "block" : "none",
                    paddingTop: (this.state.hoursOn)? 0: 10
                }}
            >
                <div id = "create-modal-hours-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>Hours of Business</p><p>or Operation</p>"
                    data-for = "create-modal-hours-title-tooltip"
                >
                    Hours
                </div>
                <div id = "create-modal-hours-container">
                    {hourPickers}
                    <div id = "create-modal-hours-type">
                        <div id = "create-modal-hours-fixed-button"
                            className = {(this.props.colorMode === "day")?
                                "create-modal-hours-button button-light-blue-s3" :
                                "create-modal-hours-button button-blue-s3"}
                            style = {
                                (this.state.hoursOn)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ hoursOn: true });}}
                        >
                            Fixed Hours
                        </div>
                        <div id = "create-modal-hours-allday-button"
                            className = {(this.props.colorMode === "day")?
                                "create-modal-hours-button button-light-blue-s3" :
                                "create-modal-hours-button button-blue-s3"}
                            style = {
                                (!this.state.hoursOn)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => {this.setState({ hoursOn: false });}}
                        >
                            Open All Day
                        </div>
                    </div>
                </div>

                <ReactTooltip
                    id = "create-modal-hours-title-tooltip"
                    className = "create-modal-hours-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Season Closure
        ============================================================================================
        */
        const createClosureDateArrow = (this.props.colorMode === "day")?
            getStaticPath("/images/create/dates-arrow-black.png") :
            getStaticPath("/images/create/dates-arrow-white.png");

        const closureDates = (
            <div id = "create-modal-season-closure-dates"
                style = {{ display: ((this.state.contributorModeOn) && ((this.state.seasonClosureType === "estimate") || (this.state.seasonClosureType === "exact")))? "block" : "none" }}
            >
                <div id = "create-modal-season-closure-start-date"
                    data-tip = {(this.state.seasonClosureType === "estimate")? "Start Date (Estimated)" : "Start Date (Exact)"}
                    data-for = "create-modal-closure-start-date-tooltip">
                    <DatePicker
                        readOnly = {true}
                        dateFormat = "MMM D"
                        className = "create-modal-season-closure-date-input font-cabin"
                        selected = {this.state.closureStartDate}
                        onChange = {this.updateSeasonClosureStartDate}
                    />
                </div>
                <div id = "create-modal-season-closure-date-arrow" 
                    className = "image-contain"
                    style = {{ backgroundImage:  createClosureDateArrow }}
                >
                </div>
                <div id = "create-modal-season-closure-end-date"
                    data-tip = {(this.state.seasonClosureType === "estimate")? "End Date (Estimated)" : "End Date (Exact)"}
                    data-for = "create-modal-closure-end-date-tooltip">
                    <DatePicker
                        readOnly = {true}
                        dateFormat = "MMM D"
                        className = "create-modal-season-closure-date-input font-cabin"
                        selected = {this.state.closureEndDate}
                        onChange = {this.updateSeasonClosureEndDate}
                    />
                    <ReactTooltip
                        id = "create-modal-closure-end-date-tooltip"
                        className = "create-modal-closure-date-tooltip tooltip-s2"
                        type = "dark"
                        place = "top"
                    />
                </div>
                <ReactTooltip
                    id = "create-modal-closure-start-date-tooltip"
                    className = "create-modal-closure-date-tooltip tooltip-s2"
                    type = "dark"
                    place = "top"
                />                
            </div>
        );

        const seasonClosure = (
            <div className = "create-modal-detail-row"
                style = {{ 
                    display: (this.state.contributorModeOn)? "block" : "none",
                    addingBottom: (this.state.seasonClosureType === "estimate")? 26 : 16 
                }}
            >
                <div id = "create-modal-season-closure-date-title"
                    data-tip = "<p>Estimated Dates of</p><p>Seasonal Closure</p>"
                    data-for = "create-modal-season-closure-title-tooltip"
                >
                    <div className = {(this.props.colorMode === "day")? 
                            "create-modal-season-closure-date-title-text k4" : 
                            "create-modal-season-closure-date-title-text w4"}
                    >
                        Season
                    </div>
                    <div className = {(this.props.colorMode === "day")? 
                            "create-modal-season-closure-date-title-text k4" : 
                            "create-modal-season-closure-date-title-text w4"}
                    >
                        Closure
                    </div>
                </div>
                <ReactTooltip
                    id = "create-modal-season-closure-title-tooltip"
                    className = "create-modal-season-closure-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />

                <div id = "create-modal-season-closure-dates-container">
                    {closureDates}
                    <div id = "create-modal-season-closure-date-type">
                        <div id = "create-modal-season-closure-date-estimate"
                            className = {(this.props.colorMode === "day")?
                                "create-modal-season-closure-date-button button-light-blue-s3" :
                                "create-modal-season-closure-date-button button-blue-s3"}
                            style = {
                                (this.state.seasonClosureType === "estimate")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {
                                () => {
                                    this.setState({ seasonClosureType: "estimate" });
                                }
                            }
                        >
                            Estimated
                        </div>
                        <div id = "create-modal-season-closure-date-open"
                            className = {(this.props.colorMode === "day")?
                                "create-modal-season-closure-date-button button-light-blue-s3" :
                                "create-modal-season-closure-date-button button-blue-s3"}
                            style = {
                                (this.state.seasonClosureType === "open")?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {
                                () => {
                                    this.setState({ seasonClosureType: "open" });
                                }
                            }
                        >
                            Open All Year
                        </div>
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Dining Options
        ============================================================================================
        */

        const diningOptions = (
            <div className = "create-modal-detail-row"
                style = {{ display: ((this.state.contributorModeOn) && (this.state.dotType === "EX"))? "block" : "none" }}
            >
                <div id = "create-modal-dining-options-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    data-tip = "<p>If the Place Offers</p><p>Dining Options</p>"
                    data-for = "create-modal-dining-options-title-tooltip"
                >
                    Dining Options
                </div>
                <div id = "create-modal-dining-options-type">
                    <div id = "create-modal-dining-options-yes-button"
                        className = {(this.props.colorMode === "day")?
                            "create-modal-dining-options-button button-light-blue-s3" :
                            "create-modal-dining-options-button button-blue-s3"}
                        style = {
                            (this.state.diningOptions)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ diningOptions: true })}}
                    >
                        Yes
                    </div>
                    <div id = "create-modal-dining-options-no-button"
                        className = {(this.props.colorMode === "day")?
                            "create-modal-dining-options-button button-light-blue-s3" :
                            "create-modal-dining-options-button button-blue-s3"}
                        style = {
                            (!this.state.diningOptions)?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ diningOptions: false })}}
                    >
                        No
                    </div>
                </div>

                <ReactTooltip
                    id = "create-modal-dining-options-title-tooltip"
                    className = "create-modal-dining-options-title-tooltip tooltip-s2"
                    type = "dark"
                    place = "left"
                    html = {true}
                />
            </div>
        );


        /*
        ============================================================================================
            Toggle Location Options Button
        ============================================================================================
        */

        const toggleLocationOptionsButton = (
             <div id = "create-modal-location-options"
                style = {{ display: (this.state.location === null)? "none" : "block" }}
                onClick = {this.toggleLocationOptions}
             >
                <div id = "create-modal-location-options-image"
                    className = "image-cover"
                    style = {{ backgroundImage: (this.props.colorMode === "day")? 
                        getStaticPath("/images/common/map-black.png", true) :
                        getStaticPath("/images/common/map-white.png", true)
                    }}
                >
                </div>
                <div id = "create-modal-location-options-text"
                >
                     Location Options
                </div>
             </div>           
        );


        /*
        ============================================================================================
            Accessibility
        ============================================================================================
        */

        const parkAndWalk = (
            <div id = "create-modal-park-and-walk-button"
                className = {(this.props.colorMode === "day")?
                    "button-light-blue-s3" : "button-blue-s3"}
                style = {
                    (this.state.accessibilityMode === "parkandwalk")?
                        {
                            display: (objectType === "dot")? "inline-block" : "none",
                            background: (this.props.colorMode === "day")?
                                window.colorLightBlue : window.colorBlue,
                            opacity: 1.0
                        } : {
                            display: (objectType === "dot")? "inline-block" : "none",
                            background: (this.props.colorMode === "day")?
                                window.colorLightGray : window.colorGray
                        }
                }
                onClick = {this.dotLocationParkAndWalkClick}
            >
                Park and Walk
            </div>
        );

        const accessibilityContainerID = (objectType === "dot")? 
        "create-modal-location-access-wide" : "create-modal-location-access-narrow";

        const accessibilityDrivableLabel = (objectType === "dot")? 
        "Drivable" : "Drivable";

        const accessibilityTitle = (objectType === "dot")? 
        "Access" : "Access";

        const accessibility = (this.state.everydayModeOn)? null : (
            <div className = "create-modal-location-feature-row"
                style = {{ display: ((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null))? "block" : "none" }}
            >
                <div id = "create-modal-location-access-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    {accessibilityTitle}
                </div>
                <div id = {accessibilityContainerID}>
                    <div id = "create-modal-drivable-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.accessibilityMode === "drivable")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {(objectType === "dot")? this.dotLocationDrivableClick : this.tripLocationDrivableClick}
                    >
                        {accessibilityDrivableLabel}
                    </div>
                    <div id = "create-modal-undrivable-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.accessibilityMode === "undrivable")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {(objectType === "dot")? this.dotLocationUndrivableClick : this.tripLocationUndrivableClick}
                    >
                        Undrivable
                    </div>
                    {parkAndWalk}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Preferred 
        ============================================================================================
        */

        const preferred = (this.state.everydayModeOn)? null : (
            <div className = "create-modal-location-feature-row"
                style = {{ display: (((this.state.location !== null) || (this.state.startLocation !== null) || (this.state.endLocation !== null)) && ((this.state.accessibilityMode !== "undrivable")))? "block" : "none" }}
            >
                <div id = "create-modal-location-preferred-title"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    Preferred
                </div>
                <div id = "create-modal-location-preferred">
                    <div id = "create-modal-drive-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.preferredMode === "drive")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ preferredMode: "drive" });}}
                    >
                        Drive
                    </div>
                    <div id = "create-modal-public-transportation-button"
                        className = {(this.props.colorMode === "day")?
                            "button-light-blue-s3" : "button-blue-s3"}
                        style = {
                            (this.state.preferredMode === "public")?
                                {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                    background: (this.props.colorMode === "day")?
                                        window.colorLightGray : window.colorGray
                                }
                        }
                        onClick = {() => {this.setState({ preferredMode: "public" });}}
                    >
                        Public Transportation
                    </div>
                </div>
            </div>
        );


        /*
        ============================================================================================
            Location Detect Modal
        ============================================================================================
        */

        const locationDetect = (this.state.locationDetectOn)? (
            <div className = "create-modal-location-detect-modal">
                <div className =  {(this.props.colorMode === "day")?
                        "create-modal-location-detect-modal-content modal-day" :
                        "create-modal-location-detect-modal-content modal-night"}
                >
                    <div className = "create-modal-location-detect-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage: (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                       onClick = {this.locationDetectCancel}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "create-modal-location-detect-modal-content__title k2" :
                            "create-modal-location-detect-modal-content__title w2"}
                    >
                        Location Detected
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                            "create-modal-location-detect-modal-content__text dg4" :
                            "create-modal-location-detect-modal-content__text g4"}
                    >
                        <p>Use the location extracted</p>
                        <p>from the media?</p>
                    </div>

                    <div className = "create-modal-location-detect-delete-modal-content__confirm">
                        <div className = "create-modal-location-detect-delete-modal-content__confirm-select">
                            <div className =  {(this.props.colorMode === "day")?
                                    "create-modal-location-detect-modal-content__confirm-select-yes button-light-blue-gray-s2" :
                                    "create-modal-location-detect-modal-content__confirm-select-yes button-blue-gray-s2"}
                                onClick = {this.locationDetectConfirm}
                            >
                                Yes
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                    "create-modal-location-detect-modal-content__confirm-select-no button-light-blue-gray-s2" :
                                    "create-modal-location-detect-modal-content__confirm-select-no button-blue-gray-s2"}
                                onClick = {this.locationDetectCancel}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Basic Curation - Title / Overview
        ============================================================================================
        */
        const titlePlaceholder = (this.state.contributorModeOn)? this.placeholderTitle : this.placeholderTitleSimple;

        const title = (
            <div>
                <div className = "create-modal-basic-row create-modal-content">
                    <div className = {(this.props.colorMode === "day")?
                            "create-modal-basic-title k4" :
                            "create-modal-basic-title w4"}
                    >
                        Title
                    </div>
                    <div className = "create-modal-basic-input">
                        <input
                            ref = {this.titleRef}
                            type = "text"
                            id = "create-modal-title"
                            className = {(this.props.colorMode === "day")? 
                                "input-s3 input-day" : "input-s3 input-night"}
                            value = {this.state.title}
                            placeholder = {titlePlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.titleOnChange}
                            style = {{ borderColor: this.state.titleBorderColor }}
                            required
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-warning warning-day-s2" :
                        "create-modal-warning warning-night-s2"}
                    style = {{ display: this.state.titleWarningOn? "block" : "none" }}>
                    {this.state.titleWarningMessage}
                </div>
            </div>
        );


        let overviewPlaceholder = null;

        if (this.state.contributorModeOn) {
            if (objectType === "trip") {
                overviewPlaceholder = this.placeholderOverviewTrip;
            }
            else {
                overviewPlaceholder = this.placeholderOverviewDot;
            }
        }
        else {
            overviewPlaceholder = this.placeholderOverviewSimple;
        }

        const overviewTitle = (this.state.contributorModeOn)? "Overview" : "Story";

        const overview = (
            <div>

                <div className = "create-modal-basic-row create-modal-content">
                    <div className = {(this.props.colorMode === "day")?
                            "create-modal-basic-title k4" :
                            "create-modal-basic-title w4"}
                    >
                        {overviewTitle}
                    </div>
                    <div className = "create-modal-basic-input">
                        <textarea
                            ref = {this.overviewRef}
                            id = "create-modal-overview"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            value = {this.state.overview}
                            rows = {4}
                            placeholder = {overviewPlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.overviewOnChange}
                            style = {{ borderColor: this.state.overviewBorderColor }}
                            required
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-warning warning-day-s2" :
                        "create-modal-warning warning-night-s2"}
                    style = {{ display: this.state.overviewWarningOn? "block" : "none" }}>
                    {this.state.overviewWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Advanced Curation Type
        ============================================================================================
        */

        const curationType = (
            <div id = "create-modal-curation-type"
                style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}
            >
                <div id = "create-modal-curation-type-todos"
                    className = {(this.props.colorMode === "day")?
                        "button-light-blue-s3" : "button-blue-s3"}
                    style = {
                        (this.state.curationMode === "todos")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => {this.setState({ curationMode: "todos" });}}
                >
                    Todos
                </div>
                <div id = "create-modal-curation-type-history"
                    className = {(this.props.colorMode === "day")?
                        "button-light-blue-s3" : "button-blue-s3"}
                    style = {
                        (this.state.curationMode === "history")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => {this.setState({ curationMode: "history" });}}
                >
                    History
                </div>
                <div id = "create-modal-curation-type-stories"
                    className = {(this.props.colorMode === "day")?
                        "button-light-blue-s3" : "button-blue-s3"}
                    style = {
                        (this.state.curationMode === "stories")?
                            {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                                background: (this.props.colorMode === "day")?
                                    window.colorLightGray : window.colorGray
                            }
                    }
                    onClick = {() => {this.setState({ curationMode: "stories" });}}
                >
                    Stories
                </div>
            </div>
        );


        /*
        ============================================================================================
            Advanced Curation - Todos / History / Stories
        ============================================================================================
        */

        let todosPlaceholder = null;

        if (this.state.dotType === "DI") {
            todosPlaceholder = this.placeholderTodosDine;
        }
        else {
            todosPlaceholder = this.placeholderTodos;
        }

        const curation = (
            <div style = {{ display: (this.state.contributorModeOn)? "block" : "none" }}>
                <div className = "create-modal-curation-row">
                    <div className = {(this.props.browserWidth <= 8)?
                            "create-modal-curation-title-small" : "create-modal-curation-optional-title"}
                    >
                        <div className = {(this.props.colorMode === "day")? 
                                "create-modal-curation-title-text k4" : 
                                "create-modal-curation-title-text w4"}
                        >
                            Optional
                        </div>
                    </div>
                    
                    <div className = "create-modal-curation-input">
                        {curationType}
                        <textarea
                            ref = {this.todosRef}
                            id = "create-modal-todos"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            value = {this.state.todos}
                            rows = {4}
                            placeholder = {todosPlaceholder}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.todosOnChange}
                            style = {{
                                display: (this.state.curationMode === "todos")? "block" : "none",
                                borderColor: this.state.todosBorderColor
                            }}
                        />

                        <textarea
                            ref = {this.historyRef}
                            id = "create-modal-history"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            value = {this.state.history}
                            rows = {4}
                            placeholder = {this.placeholderHistory}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.historyOnChange}
                            style = {{
                                display: (this.state.curationMode === "history")? "block" : "none",
                                borderColor: this.state.historyBorderColor
                            }}
                        />

                        <textarea
                            ref = {this.storiesRef}
                            id = "create-modal-stories"
                            className = {(this.props.colorMode === "day")? 
                                "text-input input-day" : "text-input input-night"}
                            value = {this.state.stories}
                            rows = {4}
                            placeholder = {this.placeholderStories}
                            onFocus = {this.inputOnFocus}
                            onBlur = {this.inputOnBlur}
                            onChange = {this.storiesOnChange}
                            style = {{
                                display: (this.state.curationMode === "stories")? "block" : "none",
                                borderColor: this.state.storiesBorderColor
                            }}
                        />
                    </div>
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-warning warning-day-s2" :
                        "create-modal-warning warning-night-s2"}
                    style = {{ display: (this.state.todosWarningOn)? "block" : "none" }}>
                    {this.state.todosWarningMessage}
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-warning warning-day-s2" :
                        "create-modal-warning warning-night-s2"}
                    style = {{ display: (this.state.historyWarningOn)? "block" : "none" }}>
                    {this.state.historyWarningMessage}
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "create-modal-warning warning-day-s2" :
                        "create-modal-warning warning-night-s2"}
                    style = {{ display: (this.state.storiesWarningOn)? "block" : "none" }}>
                    {this.state.storiesWarningMessage}
                </div>
            </div>
        );


        /*
        ============================================================================================
            Progress First and Second Button Titles
        ============================================================================================
        */

        /*
        const progressFirstButtonTitle = (this.state.contributorModeOn)? "Basics" : "Location";
        const progressSecondButtonTitle = (this.state.contributorModeOn)? "Stories" : "Story";
        */

        /*
        ============================================================================================
            Progress Buttons and Sections
        ============================================================================================
        */

        /*
        const progressLineImage = (this.props.colorMode === "day")? 
            getStaticPath("/images/create/progress-line-day.png") :
            getStaticPath("/images/create/progress-line-night.png");

        let progressFirstButtonNumber, progressSecondButtonNumber, progressThirdButtonNumber = null;
        let progressFirstButtonTitleColor, progressSecondButtonTitleColor, progressThirdButtonTitleColor;
        let progressFirstButtonTitleWeight, progressSecondButtonTitleWeight, progressThirdButtonTitleWeight;

        if (this.checkProgressFirstWarning()) {
            progressFirstButtonNumber = 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_red_day_1.png") :
                        getStaticPath("/images/number/double_red_night_1.png")
                );

            progressFirstButtonTitleColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;

            progressFirstButtonTitleWeight = this.fontWeightBold;
        }
        else {
            progressFirstButtonNumber = (this.state.createStage === 1)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_light_blue_day_1.png") :
                        getStaticPath("/images/number/double_blue_night_1.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_gray_day_1.png") :
                        getStaticPath("/images/number/double_gray_night_1.png")
                );

            progressFirstButtonTitleColor = (this.props.colorMode === "day")?
                window.colorBlack : window.colorWhite;

            progressFirstButtonTitleWeight = this.fontWeightRegular;
        }
        if (this.checkProgressSecondWarning()) {
            progressSecondButtonNumber = 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_red_day_2.png") :
                        getStaticPath("/images/number/double_red_night_2.png")
                );

            progressSecondButtonTitleColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;

            progressSecondButtonTitleWeight = this.fontWeightBold;
        }
        else {
            progressSecondButtonNumber = (this.state.createStage === 2)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_light_blue_day_2.png") :
                        getStaticPath("/images/number/double_blue_night_2.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_gray_day_2.png") :
                        getStaticPath("/images/number/double_gray_night_2.png")
                );

            progressSecondButtonTitleColor = (this.props.colorMode === "day")?
                window.colorBlack : window.colorWhite;

            progressSecondButtonTitleWeight = this.fontWeightRegular;
        }
        if (this.checkProgressThirdWarning()) {
            progressThirdButtonNumber = 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_red_day_3.png") :
                        getStaticPath("/images/number/double_red_night_3.png")
                );

            progressThirdButtonTitleColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;

            progressThirdButtonTitleWeight = this.fontWeightBold;
        }
        else {
            progressThirdButtonNumber = (this.state.createStage === 3)? 
                (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_light_blue_day_3.png") :
                        getStaticPath("/images/number/double_blue_night_3.png")
                ) : (
                    (this.props.colorMode === "day")? 
                        getStaticPath("/images/number/double_gray_day_3.png") :
                        getStaticPath("/images/number/double_gray_night_3.png")
                );

            progressThirdButtonTitleColor = (this.props.colorMode === "day")?
                window.colorBlack : window.colorWhite;

            progressThirdButtonTitleWeight = this.fontWeightRegular;
        }
        const progressSecondSection = (objectType === "trip")?
        (
            <div id = "create-modal-progress-second-section"
                style = {{
                    backgroundImage: progressLineImage,
                }}
            >
                <div id = "create-modal-progress-third"
                    className = "image-contain"
                    style = {
                        (this.state.createStage === 3)?
                        {
                            opacity: 1.0
                        } : {
                        }
                    }
                >
                    <div id = "create-modal-progress-third-number"
                        className = "image-contain"
                        style = {{ backgroundImage: progressThirdButtonNumber }}
                        onClick = {() => {this.setState({ createStage: 3 });}}
                    >
                    </div>
                    <div id = "create-modal-progress-third-title"
                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        style = {{
                            color: progressThirdButtonTitleColor,
                            fontWeight: progressThirdButtonTitleWeight
                        }}
                    >
                        Connect
                    </div>
                </div>
            </div>
        ): null;
        */
        //console.log("progressSecondSection = ", progressSecondSection);


        /*
        ============================================================================================
            Publish Button
        ============================================================================================
        */

        let publishButton = null;
        let publishButtonImage = null;

        if (this.checkProgressFirstWarning() || this.checkProgressSecondWarning() || this.checkProgressThirdWarning()) {
            publishButtonImage = (this.state.progressPublishButtonHoverOn)? 
                (
                    (this.createModeOn)?
                    (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/publish-red-day.png") : 
                            getStaticPath("/images/create/publish-red-night.png")
                    ) : (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/save-red-day.png") : 
                            getStaticPath("/images/create/save-red-night.png")
                    )
                ) : (
                    (this.createModeOn)?
                    (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/publish-gray-day.png") : 
                            getStaticPath("/images/create/publish-gray-night.png")
                    ) : (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/save-gray-day.png") : 
                            getStaticPath("/images/create/save-gray-night.png")
                    )
                );
        }
        else {
            publishButtonImage = (this.state.progressPublishButtonHoverOn)? 
                (
                    (this.createModeOn)?
                    (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/publish-blue-day.png") : 
                            getStaticPath("/images/create/publish-blue-night.png")
                    ) : (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/save-blue-day.png") : 
                            getStaticPath("/images/create/save-blue-night.png")
                    )
                ) : (
                    (this.createModeOn)?
                    (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/publish-gray-day.png") : 
                            getStaticPath("/images/create/publish-gray-night.png")
                    ) : (
                        (this.props.colorMode === "day")? 
                            getStaticPath("/images/create/save-gray-day.png") : 
                            getStaticPath("/images/create/save-gray-night.png")
                    )
                );
        }

        /*
        if (objectType === "trip") {
            publishButton = (this.state.createStage === 3)?
            (
                <div id = "create-modal-progress-publish-container">
                    <div id = "create-modal-progress-publish"
                        className = "image-contain"
                        style = {{ backgroundImage: publishButtonImage }}
                        onClick = {this.getState.bind(this, this.progressPublishClick)}
                        onMouseEnter = {() => {this.setState({ progressPublishButtonHoverOn : true });}}
                        onMouseLeave = {() => {this.setState({ progressPublishButtonHoverOn : false });}}
                    >
                    </div>
                </div>
            ) : null;
        }
        else {
        */
            publishButton = //(this.state.createStage === 2)?
            (
                <div id = "create-modal-progress-publish-container">
                    <div id = "create-modal-progress-publish"
                        className = "image-contain"
                        style = {{ backgroundImage: publishButtonImage }}
                        onClick = {this.getState.bind(this, this.progressPublishClick)}
                        onMouseEnter = {() => {this.setState({ progressPublishButtonHoverOn : true });}}
                        onMouseLeave = {() => {this.setState({ progressPublishButtonHoverOn : false });}}
                    >
                    </div>
                </div>
            );
            // : null;
        //}

        // Publish message
        let publishMessage = null;
        if (objectType === "dot") {
            publishMessage = (this.createModeOn)?
                "You just published a dot." : "The dot is updated successfully.";
        }
        else if ((objectType === "trip") && (this.state.dotType === "TR")) {
            publishMessage = (this.createModeOn)?
                "You just published a trip." : "The trip is updated successfully.";
        }
        else if ((objectType === "trip") && (this.state.dotType === "RT")) {
            publishMessage = (this.createModeOn)?
                "You just published a route." : "The route is updated successfully.";
        }
        else {
            console.log("[WARNING] CreateSimple / render - wrong objectType");
        }

        // Contributor only part (left section)
        const contributorOnly = (this.state.contributorModeOn)? (
            <div id = "create-modal-bottom">
                <div id = "create-modal-detail-container">
                    {dotTypeMenu}

                    {ratingSlider}

                    {durationSlider}

                    {physicalitySlider}

                    {bestTimeSlider}

                    {hours}

                    {seasonClosure}

                    {diningOptions}
                </div>
            </div>
        ) : null;

        // Complete button image
        const completeButtonImage = (this.state.completeButtonHoverOn)? 
            (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/create/confirm-light-blue.png") : 
                    getStaticPath("/images/create/confirm-blue.png")
            ) : (
                (this.props.colorMode === "day")? 
                    getStaticPath("/images/create/confirm-light-gray.png") : 
                    getStaticPath("/images/create/confirm-gray.png")
            );

        // Cancel button image
        const cancelButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-white.png") : 
            getStaticPath("/images/common/cancel-white.png");

        // JSX part to render html elements
        return (
            <div id = "create-modal-container" 
                style = {{
                    display: (this.props.create.modalOn)? "block" : "none"
                }}
            >
                {locationDetect}

                <div id = "create-modal-complete-modal" 
                    style = {{ 
                        display: (this.state.completeModalOn)? "block" : "none"
                    }}
                >
                    <div id = "create-modal-complete-publish"
                        className = {(this.props.colorMode === "day")? 
                            "modal-day" :
                            "modal-night"} 
                    >
                        <div id = "create-modal-complete-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Congratulations
                        </div>
                        <div id = "create-modal-complete-message"
                            className ={(this.props.colorMode === "day")? "dg4" : "g4"}
                        >
                            {publishMessage}
                        </div>
                        <div id = "create-modal-complete-public"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s2" : "button-blue-s2"}
                            style = {
                                (this.state.isPublic)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    }
                            }
                            onClick = {() => { this.setState({ isPublic: true }); }}
                            data-tip = "Submit for a Review and Get Published in the Magazine"
                            data-for = "create-modal-complete-public-tooltip"
                        >
                            Public
                        </div>
                        <div id = "create-modal-complete-personal"
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s2" : "button-blue-s2"}
                            style = {
                                (this.state.isPublic)?
                                    {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightGray : window.colorGray
                                    } : {
                                        background: (this.props.colorMode === "day")?
                                            window.colorLightBlue : window.colorBlue,
                                        opacity: 1.0
                                    }
                            }
                            onClick = {() => { this.setState({ isPublic: false }); }}
                            data-tip = "Keep It To Yourself and Your Followers"
                            data-for = "create-modal-complete-personal-tooltip"
                        >
                            Private
                        </div>

                        {
                            (this.state.isPublic)?
                                (
                                    <div id = "create-modal-complete-slider-container">
                                        <div id = "create-modal-complete-slider-title"
                                            className = {(this.props.colorMode === "day")? "k3" : "w3"}
                                            data-tip = "Reduce the Number of Visitors for Conservation Purposes."
                                            data-for = "create-modal-complete-slider-tooltip"
                                        >
                                            Bucketing Limit
                                        </div>
                                        <div id = "create-modal-complete-slider">
                                            <InputRange
                                                classNames = {sliderClassNames}
                                                maxValue = {4}
                                                minValue = {0}
                                                value = {this.state.saveLimit}
                                                onChange = {saveLimit => this.setState({ saveLimit: saveLimit })}
                                                formatLabel = {saveLimit => this.saveLimitLabels[saveLimit]}
                                                showEndLabels = {false}
                                                step = {1}
                                            />
                                        </div>
                                    </div>
                                ) : null
                        }
                        <div id = "create-modal-complete-button-container"
                            onMouseEnter = {() => {this.setState({ completeButtonHoverOn : true });}}
                            onMouseLeave = {() => {this.setState({ completeButtonHoverOn : false });}}
                            onClick = {this.completeButtonClick}
                        >
                            <Link to = {`/nationalpark`}>
                                <div id = "create-modal-complete-button"
                                    className = "image-contain"
                                    style = {{ backgroundImage: completeButtonImage }}
                                    data-tip = "Submit"
                                    data-for = "create-modal-complete-button-tooltip"
                                >
                                </div>
                                <div id = "create-modal-complete-text"
                                    className = {(this.props.colorMode === "day")? "k2" : "w2"}
                                    style = {{ opacity: (this.state.completeButtonHoverOn)? 1.0 : 0.6 }}
                                >
                                    Finish
                                </div>
                            </Link>
                        </div>
                        <ReactTooltip
                            id = "create-modal-complete-public-tooltip"
                            className = "create-modal-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                        <ReactTooltip
                            id = "create-modal-complete-personal-tooltip"
                            className = "create-modal-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                        <ReactTooltip
                            id = "create-modal-complete-slider-tooltip"
                            className = "create-modal-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                        <ReactTooltip
                            id = "create-modal-complete-button-tooltip"
                            className = "create-modal-complete-tooltip tooltip-s2"
                            type = "dark"
                            place = "bottom" 
                            html = {true} 
                        />
                    </div>
                </div>


                <div ref = {this.modalRef}
                    id = {(this.props.browserWidth <= window.browserWidthSmall)? 
                        "create-modal-small" : "create-modal"}
                    className = {(this.props.colorMode === "day")? 
                        "modal-day" : "modal-night"}
                    style = {{
                        width: (this.state.location === null)? this.searchModeWidth : "calc(100% - 30px)"
                    }}
                >
                    <div id = "create-modal-cancel"
                        className = {(this.props.colorMode === "day")? 
                            "image-button-weak-s3" : "image-button-s3"}
                        style = {{ backgroundImage : cancelButtonImage }}
                        onClick = {this.closeModal}
                    >
                    </div>

                    <div className = "body-narrow"
                        style = {{ display: (this.state.createStage === 1)? "block" : "none" }}
                    >
                        <div id = "create-modal-top">
                            <div id = "create-modal-basic-container">
                                <div className = "create-modal-basic-row"
                                    style = {{
                                        display: ((this.state.location === null) && (this.state.startLocation === null))? "none" : "block"
                                    }}
                                >
                                    <div id = "create-modal-map-container">
                                        {locationType}
                                        {map}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className = "body-narrow">
                        <div id = "create-modal-location">
                            <div id = "create-modal-basic-container">
                                {toggleLocationOptionsButton}

                                <div className = "create-modal-basic-row create-modal-content"
                                    style = {{
                                        display: (!this.state.locationOptionsOn && this.state.location === null && this.props.create.mode === "create")? "block": "none"
                                    }}
                                >
                                    Create a New Dot
                                </div>

                                <div className = "create-modal-basic-row create-modal-content"
                                    style = {{
                                        display: (this.state.locationOptionsOn || (!this.state.locationOptionsOn && this.state.location === null && this.props.create.mode === "create"))? "block": "none"
                                    }}
                                >
                                    <div id = "create-modal-location-search-title"
                                        style = {{
                                            display: (this.state.locationOptionsOn && this.state.location !== null)? "inline-block" : "none"
                                        }}   
                                    >
                                        Search
                                    </div>

                                    <input
                                        ref = {this.locationRef}
                                        type = "text"
                                        id = {(this.state.locationOptionsOn)? "create-modal-location-search-short" : "create-modal-location-search"}
                                        className = {(this.props.colorMode === "day")? 
                                            "input-s3 input-day" : "input-s3 input-night"}
                                        placeholder = {locationPlaceholder}
                                        onFocus = {this.inputOnFocus}
                                        onBlur = {this.inputOnBlur}
                                        onChange = {this.locationOnChange}
                                        style = {{ borderColor: this.state.locationBorderColor }}
                                    >
                                    </input>
                                </div>

                                <div className = "create-modal-basic-row create-modal-content"
                                    style = {{ display: (this.state.locationOptionsOn && this.state.location !== null)? "block" : "none" }}
                                >
                                    <div id = "create-modal-location-gps">
                                        <div id = "create-modal-location-gps-title"
                                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                                        >
                                            GPS
                                        </div>
                                        <input
                                            ref = {this.locationLatRef}
                                            type = "text"
                                            id = "create-modal-location-lat"
                                            className = {(this.props.colorMode === "day")? 
                                                "input-s3 input-day" : "input-s3 input-night"}
                                            placeholder = {locationLat}
                                            onFocus = {this.inputOnFocus}
                                            onBlur = {this.inputOnBlur}
                                            onChange = {this.locationLatOnChange}
                                            style = {{ borderColor: this.state.locationLatBorderColor }}
                                        />
                                        <input
                                            ref = {this.locationLngRef}
                                            type = "text"
                                            id = "create-modal-location-lng"
                                            className = {(this.props.colorMode === "day")? 
                                                "input-s3 input-day" : "input-s3 input-night"}
                                            placeholder = {locationLng}
                                            onFocus = {this.inputOnFocus}
                                            onBlur = {this.inputOnBlur}
                                            onChange = {this.locationLngOnChange}
                                            style = {{ borderColor: this.state.locationLngBorderColor }}
                                        />
                                        <div id = "create-modal-location-gps-button"
                                            className = {(this.props.colorMode === "day")?
                                                "button-light-blue-s3" : "button-blue-s3"}
                                            onClick = {this.locationGPSClick}
                                        > 
                                            Set
                                        </div>
                                    </div>
                                </div>

                                {
                                    (this.state.locationOptionsOn && this.state.location !== null)? (
                                        <div className = "create-modal-basic-row create-modal-content">
                                            {accessibility}
                                            {preferred}
                                        </div>
                                    ) : null
                                }

                                <div className = {(this.props.colorMode === "day")?
                                        "create-modal-warning warning-day-s2" :
                                        "create-modal-warning warning-night-s2"}
                                    style = {{ display: (this.state.locationWarningOn)? "block" : "none" }}>
                                    {this.state.locationWarningMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className = "body-narrow" 
                        style = {{ display: (this.state.location === null)? "none" : "block" }}
                    >
                        <UploadGallery
                            imageUploadID = {this.imageUploadID}
                            videoUploadID = {this.videoUploadID}

                            colorMode = {this.props.colorMode}

                            searchOn = {false}

                            mediaArea = {this.mediaArea}
                            thumbnailArea = {this.thumbnailArea}
                            marginWidth = {this.marginWidth}
                            minAspectRatio = {this.minAspectRatio}
                            maxAspectRatio = {this.maxAspectRatio}
                            minContentWidth = {this.minContentWidth}
                            scaleContentWidth = {this.scaleContentWidth}
                            maxContentWidth = {this.maxContentWidth}

                            objectType = {objectType}
                            userIsContributor = {this.state.userIsContributor}
                            contributorModeOn = {this.state.contributorModeOn}

                            selectedMediaIndex = {this.state.selectedMediaIndex}
                            startMediaIndex = {this.state.startMediaIndex}
                            endMediaIndex = {this.state.endMediaIndex}
                            numThumbnails = {this.state.numThumbnails}

                            media = {this.media}
                            mediaKeys = {this.state.mediaKeys}
                            mediaIDs = {this.state.mediaIDs}
                            mediaCategories = {this.state.mediaCategories}
                            
                            setState = {this.setState}
                            addImage = {this.addImage}
                            addVideo = {this.addVideo}
                            removeMedia = {this.removeMedia}
                            addSearchedMedia = {this.addSearchedMedia}
                            clearSearchedMedia = {this.clearSearchedMedia}

                            selectMedia = {this.selectMedia}
                            thumbnailPrevPage = {this.thumbnailPrevPage}
                            thumbnailNextPage = {this.thumbnailNextPage}
                            moveMediaRight = {this.moveMediaRight}
                            moveMediaLeft = {this.moveMediaLeft}

                            focusOpacity = {this.focusOpacity}
                            unfocusOpacity = {this.unfocusOpacity}

                            mediaWarningOn = {this.state.mediaWarningOn}
                            mediaWarningMessage = {this.state.mediaWarningMessage}

                            uploadProgress = {this.uploadProgress}
                            uploadUpdate = {this.state.uploadUpdate}
                        />
                    </div>

                    {
                        ((this.props.browserWidth <= 7) && (this.state.contributorModeOn))? 
                        (
                            <div className = "body-narrow"
                                style = {{ display: (this.state.createStage === 1)? "block" : "none" }}
                            >
                                <div id = "create-modal-contributor-only">
                                    {contributorOnly}
                                </div>
                            </div>
                        ) : null
                    }

                    <div className = "body-narrow"
                        style = {{ display: (this.state.location === null)? "none" : "block" }}
                    >
                        <div id = "create-modal-curation-container">
                            {name}

                            {area}

                            {(this.props.browserWidth <= 7)? null : contributorOnly}
                        </div>
                    </div>

                    <div className = "body-narrow"
                        style = {{ display: (this.state.location === null)? "none" : "block" }}
                    >
                        <div id = "create-modal-curation-container"
                            //style = {{ display: (this.state.createStage === 2)? "block" : "none" }}
                        >
                            {title}

                            {overview}

                            {curation}
                        </div>
                    </div>

                    <div id = "create-modal-progress-container"
                        style = {{ display: (this.state.location === null)? "none" : "block" }}
                    >
                        {publishButton}
                    </div>

                    {
                        (true)? null : (
                            <div className = "body-narrow">
                                <div id = "create-modal-write-mode-container">
                                    {everydayMode}

                                    {authorMode}

                                    {contributorMode}
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        );
    }

    componentDidMount() {
        //console.log("CreateSimple / componentDidMount");

        // Freeze or unfreeze background
        if (this.props.create.modalOn === true) {
            freezeBody();
        }
        if (this.props.create.modalOn === false) {
            unfreezeBody();
        }

        // Add event listeners
        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);
        //console.log("CreateSimple / componentDidMount - this.nameRef = ", this.nameRef);

        // Grab the DOM nodes of inputs
        this.imageNode = document.getElementById(this.imageUploadID);
        this.videoNode = document.getElementById(this.videoUploadID);
        //console.log("CreateSimple / componentDidMount - this.imageUploadID = ", this.imageUploadID);
        //console.log("CreateSimple / componentDidMount - this.videoUploadID = ", this.videoUploadID);
        //console.log("CreateSimple / componentDidMount - imageNode = ", this.imageNode);
        //console.log("CreateSimple / componentDidMount - videoNode = ", this.videoNode);

        // Set up an event listener for the media upload
        this.imageNode.addEventListener("change", this.addImage);
        this.videoNode.addEventListener("change", this.addVideo);

        // Set up event listeners for the overview input
        this.overviewRef.current.addEventListener("paste", this.overviewResizeDelayed);
        this.overviewRef.current.addEventListener("keydown", this.overviewResizeDelayed);

        // Set up event listeners for the todos input
        this.todosRef.current.addEventListener("paste", this.todosResizeDelayed);
        this.todosRef.current.addEventListener("keydown", this.todosResizeDelayed);

        // Set up event listeners for the history input
        this.historyRef.current.addEventListener("paste", this.historyResizeDelayed);
        this.historyRef.current.addEventListener("keydown", this.historyResizeDelayed);

        // Set up event listeners for the stories input
        this.storiesRef.current.addEventListener("paste", this.storiesResizeDelayed);
        this.storiesRef.current.addEventListener("keydown", this.storiesResizeDelayed);

        // Set up google autocomplete for location
        //console.log("CreateSimple / componentDidMount - this.props.google = ", this.props.google);
        const locationAutocomplete = new this.props.google.maps.places.Autocomplete(this.locationRef.current);
        //console.log("this.locationRef.current = ", this.locationRef.current);
        //console.log("CreateSimple / componentDidMount - locationAutocomplete = ", locationAutocomplete);

        // Save scope
        const that = this;

        // Add a Listener to the location input
        this.props.google.maps.event.addListener(
            locationAutocomplete,
            "place_changed",
            () => {
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - that.nameRef = ", that.nameRef);

                // State
                let state = null;

                // Get place from the input address
                const place = locationAutocomplete.getPlace();
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - place = ", place);

                if (!place.geometry) {
                    console.log("[WARNING] CreateSimple / componentDidMount - Autocomplete returned a place with no geometry");
                }
                else {
                    for (let i=0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types[0] === "administrative_area_level_1") {
                            state = place.address_components[i].short_name;
                            //console.log("CreateSimple / componentDidMount - locationAutocomplete - place = ", place);
                            //console.log("CreateSimple / componentDidMount - locationAutocomplete - place.address_components[i].short_name = ", place.address_components[i].short_name);
                         }
                    }

                    if (state === null) {
                        state = place.address_components[0].short_name;
                    }
                }
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - that.props = ", that.props);
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - state = ", state);
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - place.geometry.location = ", place.geometry.location);
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - latitude = ", place.geometry.location.lat());
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - longitude = ", place.geometry.location.lng());

                // Set start location
                that.location = latLngToPoint(place.geometry.location);
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - latLngToPoint(place.geometry.location) = ", latLngToPoint(place.geometry.location));
                //console.log("CreateSimple / componentDidMount - locationAutocomplete - that.location = ", that.location);

                // Set states
                if ((that.state.dotType !== "TR") && (that.state.dotType !== "RT")) {
                    if (that.state.locationType === "parking") {
                        that.setState(
                            {
                                parkingLocation: that.location,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                    else {
                        if ((that.nameRef.current.value === null) || (that.nameRef.current.value === "")) {
                            that.nameRef.current.value = place.name;

                            that.setState(
                                {
                                    location: that.location,
                                    state: state,
                                    nameOn: true,
                                    nameWarningOn: false,
                                    nameWarningMessage: null,
                                    mapResetMarkers: false,
                                    mapZoomInAnimation: false,
                                    mapZoomOutAnimation: false,
                                    mapZoomHikeAnimation: false,
                                    mapRefreshAnimation: true
                                },
                                that.colorInputBorders
                            );
                        }
                        else {
                            that.setState(
                                {
                                    location: that.location,
                                    state: state,
                                    mapResetMarkers: false,
                                    mapZoomInAnimation: true,
                                    mapZoomOutAnimation: false,
                                    mapZoomHikeAnimation: false,
                                    mapRefreshAnimation: true
                                }
                            );
                        }
                    }
                }
                else {
                    if (that.state.locationType === "start") {
                        that.setState(
                            {
                                startLocation: that.location,
                                startLocationName: (place.vicinity)? place.vicinity : place.formatted_address,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                    else {
                        that.setState(
                            {
                                endLocation: that.location,
                                endLocationName: (place.vicinity)? place.vicinity : place.formatted_address,
                                mapResetMarkers: false,
                                mapZoomInAnimation: false,
                                mapZoomOutAnimation: false,
                                mapZoomHikeAnimation: false,
                                mapRefreshAnimation: true
                            }
                        );
                    }
                }

                // Clear GPS inputs (only in create-modal-dot mode)
                that.clearLocationGPS();
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.browserWidth !== prevProps.browserWidth) {
            this.updateIndices();            
        }
    }

    componentWillUnmount() {
        // Remove event listeners
        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);

        // Remove an event listener for the media upload
        this.imageNode.removeEventListener("change", this.addImage);
        this.videoNode.removeEventListener("change", this.addVideo);

        // Remove event listeners for the overview input
        this.overviewRef.current.removeEventListener("paste", this.overviewResizeDelayed);
        this.overviewRef.current.removeEventListener("keydown", this.overviewResizeDelayed);

        // Remove event listeners for the todos input
        this.todosRef.current.removeEventListener("paste", this.todosResizeDelayed);
        this.todosRef.current.removeEventListener("keydown", this.todosResizeDelayed);

        // Remove event listeners for the history input
        this.historyRef.current.removeEventListener("paste", this.historyResizeDelayed);
        this.historyRef.current.removeEventListener("keydown", this.historyResizeDelayed);

        // Remove event listeners for the stories input
        this.storiesRef.current.removeEventListener("paste", this.storiesResizeDelayed);
        this.storiesRef.current.removeEventListener("keydown", this.storiesResizeDelayed);
    }


    /*
    ============================================================================================
        Location Detect Click
    ============================================================================================
    */

    locationDetectConfirm(event) {
        // Stop propagation
        event.stopPropagation();

        // Set detected location
        this.setState({
            location: this.state.locationDetected,
            locationDetectOn: false,
            locationDetected: null
        });
    }

    locationDetectCancel(event) {
        // Stop propagation
        event.stopPropagation();

        // Reset location detect
        this.setState({
            locationDetectOn: false,
            locationDetected: null
        });
    }


    /*
    ============================================================================================
        Object Type Click Callbacks
    ============================================================================================
    */

    dotModeClick() {
        this.clearLocationSearch();
        this.nameRef.current.value = "";

        this.setState({
            dotType: "SC",
            roundtrip: null,
            reversible: null,
            bestTimeOn: true,
            bestTimeMode: "single",
            hoursOn: false,
            seasonClosureType: "estimate",
            diningOptions: false,

            accessibilityMode: "drivable",
            locationType: "location",
            location: null,
            parkingLocation: null,
            startLocation: null,
            endLocation: null,
            preferredMode: "drive",
            
            selectedIDs: [],
            highlightIDs: [],
            highlightCount: 0,
            refreshResults: true,
            
            mapRefreshAnimation: true,

            createStage: 1
        });
    }


    /*
    ============================================================================================
        Everyday Mode Click
    ============================================================================================
    */

    everydayModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            this.dotModeClick();
        }

        // Turn on author mode
        this.setState({
            everydayModeOn: false,
            authorModeOn: true,
            contributorModeOn: false,
            nameOn: true
        });
    }


    /*
    ============================================================================================
        Author Mode Click
    ============================================================================================
    */

    authorModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            this.dotModeClick();
        }

        if (this.state.userIsContributor) {
            // Turn on contributor mode
            this.setState({
                everydayModeOn: false,
                authorModeOn: false,
                contributorModeOn: true
            });
        }
        else {
            // Turn on everyday mode
            this.setState({
                everydayModeOn: true,
                authorModeOn: false,
                contributorModeOn: false,
                nameOn: ((this.nameRef.current.value === null) || (this.nameRef.current.value === ""))? true : true
            });
        }
    }


    /*
    ============================================================================================
        Contribute Click Callback
    ============================================================================================
    */

    contributorModeClick() {
        const objectType = ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))? "dot" : "trip";

        // Switch to dot mode
        if (objectType !== "dot") {
            this.dotModeClick();
        }

        // Turn on author mode
        this.setState({
            everydayModeOn: true,
            authorModeOn: false,
            contributorModeOn: false,
            nameOn: ((this.nameRef.current.value === null) || (this.nameRef.current.value === ""))? true : true
        });
    }


    /*
    ============================================================================================
        Dot Type Click Callbacks
    ============================================================================================
    */

    scenicTypeClick() {
        this.setState({
            dotType: "SC",
            bestTimeOn: true,
            bestTimeMode: "single",
            hoursOn: false,
            seasonClosureType: "estimate",
            diningOptions: false
        });
    }

    experienceTypeClick() {
        //console.log("CreateSimple / experienceTypeClick");
        this.setState({
            dotType: "EX",
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: true,
            seasonClosureType: "open",
            diningOptions: false
        });
    }

    dineTypeClick() {
        this.setState({
            dotType: "DI",
            bestTimeOn: false,
            bestTimeMode: "allday",
            hoursOn: true,
            seasonClosureType: "open",
            diningOptions: false
        });
    }


    /*
    ============================================================================================
        Change Border Colors
    ============================================================================================
    */

    colorInputBorders() {
        let locationBorderColor,
            locationLatBorderColor, 
            locationLngBorderColor,
            nameBorderColor,
            areaBorderColor,
            titleBorderColor,
            overviewBorderColor,
            todosBorderColor,
            storiesBorderColor,
            historyBorderColor,
            connectBorderColor;

        if (this.state.locationWarningOn) {
            locationBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
            locationLatBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
            locationLngBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            locationBorderColor = (this.locationFocused)? 
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );

            locationLatBorderColor = (this.locationFocused)? 
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );
            locationLngBorderColor = (this.locationFocused)? 
                (
                    window.colorGray
                ) : (
                    (this.props.colorMode === "day")?
                        window.colorLightGray : window.colorDarkestGray
                );
        }

        if (this.state.nameWarningOn) {
            nameBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            nameBorderColor = (this.nameFocused)? 
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.areaWarningOn) {
            areaBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            areaBorderColor = (this.areaFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.titleWarningOn) {
            titleBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            titleBorderColor = (this.titleFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.overviewWarningOn) {
            overviewBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            overviewBorderColor = (this.overviewFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.todosWarningOn) {
            todosBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            todosBorderColor = (this.todosFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.historyWarningOn) {
            historyBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            historyBorderColor = (this.historyFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.storiesWarningOn) {
            storiesBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed;
        }
        else {
            storiesBorderColor = (this.storiesFocused)?
            (
                window.colorGray
            ) : (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );
        }

        if (this.state.connectWarningOn) {
            connectBorderColor = (this.props.colorMode === "day")?
                window.colorLightRed : window.colorRed
        }
        else {
            connectBorderColor =  (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray
        }

        // Update states
        this.setState({
            locationBorderColor: locationBorderColor,
            locationLatBorderColor: locationLatBorderColor,
            locationLngBorderColor: locationLngBorderColor,
            nameBorderColor: nameBorderColor,
            areaBorderColor: areaBorderColor,
            titleBorderColor: titleBorderColor,
            overviewBorderColor: overviewBorderColor,
            todosBorderColor: todosBorderColor,
            historyBorderColor: historyBorderColor,
            storiesBorderColor: storiesBorderColor,
            connectBorderColor: connectBorderColor,
        });
    }


    inputOnFocus(event) {
        //console.log("CreateSimple / inputOnFocus - event.target.value = ", event.target.value);
        event.target.placeholder = "";

        // get the target id
        const id = event.target.id;

        if (id === "create-modal-location-search") {
            this.locationFocused = true;
        }
        else if (id === "create-modal-location-lat") {
            this.locationLatFocused = true;
            event.target.value = (this.state.location === null)?
                "" : this.state.location.latitude.toFixed(6)
        }
        else if (id === "create-modal-location-lng") {
            this.locationLngFocused = true;
            event.target.value = (this.state.location === null)?
                "" : this.state.location.longitude.toFixed(6)
        }
        else if (id === "create-modal-name") {
            this.nameFocused = true;
        }
        else if (id === "create-modal-area") {
            this.areaFocused = true;
        }
        else if (id === "create-modal-title") {
            this.titleFocused = true;
        }
        else if (id === "create-modal-overview") {
            this.overviewFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "create-modal-todos") {
            this.todosFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "create-modal-history") {
            this.historyFocused = true;
            event.target.style.textAlign = "left";
        }
        else if (id === "create-modal-stories") {
            this.storiesFocused = true;
            event.target.style.textAlign = "left";
        }
        else {
            console.log("[WARNING] CreateSimple / inputOnFocus - unknown input ID = ", id);
        }

        // Update the border colors
        this.colorInputBorders();
    }


    inputOnBlur (event) {
        //console.log("CreateSimple / inputOnBlur - event.target.value = ", event.target.value);

        // Get the target id
        const id = event.target.id;

        if (id === "create-modal-location-search") {
            this.locationFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderLocation : this.placeholderLocationSimple;
        }
        else if (id === "create-modal-location-lat") {
            this.locationLatFocused = false;
            event.target.placeholder = (this.state.location === null)? "Latitude" : this.state.location.latitude.toFixed(6);
        }
        else if (id === "create-modal-location-lng") {
            this.locationLngFocused = false;
            event.target.placeholder = (this.state.location === null)? "Longitude" : this.state.location.longitude.toFixed(6);
        }
        else if (id === "create-modal-name") {
            this.nameFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderName : this.placeholderNameSimple;
        }
        else if (id === "create-modal-area") {
            this.areaFocused = false;
            event.target.placeholder = this.placeholderArea;
        }
        else if (id === "create-modal-title") {
            this.titleFocused = false;
            event.target.placeholder = (this.state.contributorModeOn)? this.placeholderTitle : this.placeholderTitleSimple;
        }
        else if (id === "create-modal-overview") {
            this.overviewFocused = false;

            let placeholder = null;
            if (this.state.contributorModeOn) {
                if ((this.state.dotType === "TR") || (this.state.dotType === "RT")) {
                    placeholder = this.placeholderOverviewTrip;
                }
                else {
                    placeholder = this.placeholderOverviewDot;
                }
            }
            else {
                placeholder = this.placeholderOverviewSimple;
            }

            event.target.placeholder = placeholder;

            if (this.overviewRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "create-modal-todos") {
            this.todosFocused = false;

            let placeholder = null;

            if (this.state.dotType === "DI") {
                placeholder = this.placeholderTodosDine;
            }
            else {
                placeholder = this.placeholderTodos;
            }

            event.target.placeholder = placeholder;

            if (this.todosRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "create-modal-history") {
            this.historyFocused = false;

            event.target.placeholder = this.placeholderHistory;

            if (this.historyRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else if (id === "create-modal-stories") {
            this.storiesFocused = false;

            event.target.placeholder = this.placeholderStories;

            if (this.storiesRef.current.value.length > 0) {
                event.target.style.textAlign = "left";
            }
            else {
                event.target.style.textAlign = "center";
            }
        }
        else {
            console.log("[WARNING] CreateSimple / inputOnBlur - unknown input ID = ", id);
        }

        // Update the border colors
        this.colorInputBorders();
    }


    /*
    ============================================================================================
        Clear Location Search and GPS
    ============================================================================================
    */

    toggleLocationOptions() {
        this.setState({
            locationOptionsOn: !this.state.locationOptionsOn
        });
    }


    /*
    ============================================================================================
        Clear Location Search and GPS
    ============================================================================================
    */

    clearLocationSearch() {
        //this.locationRef.current.value = "";
    }

    clearLocationGPS() {
        //this.locationLatRef.current.value = "";
        //this.locationLngRef.current.value = "";
    }


    /*
    ============================================================================================
        Location Drivable Click
    ============================================================================================
    */

    dotLocationDrivableClick() {
        this.setState({
            accessibilityMode: "drivable",
            locationType: "location",
            parkingLocation: null,
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Park and Walk Click
    ============================================================================================
    */

    dotLocationParkAndWalkClick() {
        this.setState({
            accessibilityMode: "parkandwalk",
            locationType: "location",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Undrivable Click
    ============================================================================================
    */

    dotLocationUndrivableClick() {
        this.setState({
            accessibilityMode: "undrivable",
            locationType: "location",
            parkingLocation: null,
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Drivable Click
    ============================================================================================
    */

    tripLocationDrivableClick() {
        this.setState({
            accessibilityMode: "drivable",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location Undrivable Click
    ============================================================================================
    */

    tripLocationUndrivableClick() {
        this.setState({
            accessibilityMode: "undrivable",
            mapRefreshAnimation: true
        });
    }


    /*
    ============================================================================================
        Location GPS Click
    ============================================================================================
    */

    locationGPSClick() {
        //console.log("CreateSimple / locationGPSClick");

        if ((this.locationLatRef.current.value !== null) || (this.locationLngRef.current.value !== null)) {
            // Read latitude and longitude from input values
            const latitude = (this.locationLatRef.current.value === null || this.locationLatRef.current.value === "")?
                this.state.location.latitude : parseFloat(this.locationLatRef.current.value);
            const longitude = (this.locationLngRef.current.value === null || this.locationLngRef.current.value === "")?
                this.state.location.longitude : parseFloat(this.locationLngRef.current.value);

            // Check the ranges
            if (((latitude >= -90) && (latitude <= 90)) && ((longitude >= -180) && (longitude <= 180))) {
                // Clear location
                this.clearLocationSearch();

                const location = { "latitude": latitude, "longitude": longitude };
                //console.log("CreateSimple / locationGPSClick - location = ", location);

                if (this.state.locationType === "location") {
                    this.setState({
                        location: location
                    });
                }
                else if (this.state.locationType === "parking") {
                    this.setState({
                        parkingLocation: location
                    });
                }
                else if (this.state.locationType === "start") {
                    this.setState({
                        startLocation: location
                    });
                }
                else if (this.state.locationType === "end") {
                    this.setState({
                        endLocation: location
                    });
                }
            }
            else {
                // Issue warning

            }
        }
    }


    /*
    ============================================================================================
        Location
    ============================================================================================
    */

    locationOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const location = "" + this.locationRef.current.value;

        if (location.length > 0) {
            this.clearLocationWarning(false);
        }
    }


    locationLatOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const locationLat = "" + this.locationLatRef.current.value;

        if (locationLat.length > 0) {
            this.clearLocationWarning(false);
        }
    }


    locationLngOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        const locationLng = "" + this.locationLngRef.current.value;

        if (locationLng.length > 0) {
            this.clearLocationWarning(false);
        }
    }


    clearLocationWarning(delay) {
        clearTimeout(this.locationWarningTimer);

        const locationBorderColor = (this.locationFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const locationLatBorderColor = (this.locationLatFocused)? 
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const locationLngBorderColor = (this.locationLngFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            locationBorderColor: locationBorderColor,
            locationLatBorderColor: locationLatBorderColor,
            locationLngBorderColor: locationLngBorderColor,
            locationWarningOn: false,
            locationWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.locationWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }



    /* 
    ============================================================================================
        Map Reset Animations
    ============================================================================================
    */

    resetMapAnimations(callback) {
        //console.log("CreateSimple / resetAnimations - executed");
        this.setState(
            {
                mapZoomInAnimation: false,
                mapZoomOutAnimation: false,
                mapZoomHikeAnimation: false,
                mapRefreshAnimation: false
            }, 
            callback
        );
    }


    /*
    ============================================================================================
        Name Warning
    ============================================================================================
    */

    nameOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState(
            {
                name: event.target.value
            },
            () => {
                if (this.state.name.length > 0) {
                    this.clearNameWarning(false);
                }
            }
        );
    }


    clearNameWarning(delay) {
        clearTimeout(this.nameWarningTimer);

        const borderColor = (this.nameFocused)? 
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            nameBorderColor: borderColor,
            nameWarningOn: false,
            nameWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.nameWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Area Warning
    ============================================================================================
    */

    areaOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState(
            {
                overview: event.target.value
            },
            () => {
                if (this.state.area.length > 0) {
                    this.clearAreaWarning(false);
                }
            }
        );
    }


    clearAreaWarning(delay) {
        clearTimeout(this.areaWarningTimer);

        const borderColor = (this.areaFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            areaBorderColor: borderColor,
            areaWarningOn: false,
            areaWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.areaWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Title Warning
    ============================================================================================
    */

    titleOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState(
            {
                title: event.target.value
            },
            () => {
                if (this.state.title.length > 0) {
                    this.clearTitleWarning(false);
                }
            }
        );
    }


    clearTitleWarning(delay) {
        clearTimeout(this.titleWarningTimer);

        const borderColor = (this.titleFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            titleBorderColor: borderColor,
            titleWarningOn: false,
            titleWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.titleWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Overview
    ============================================================================================
    */

    overviewOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState(
            {
                overview: event.target.value
            },
            () => {
                if (this.state.overview.length > 0) {
                    this.clearOverviewWarning(false);
                }
            }
        );
    }


    overviewResize() {
        const overviewHeight = this.overviewRef.current.getBoundingClientRect().height;

        if (overviewHeight < this.overviewMaxHeight) {
            this.overviewRef.current.style.height = "auto";
            this.overviewRef.current.style.height = this.overviewRef.current.scrollHeight + "px";
        }
    }


    overviewResizeDelayed() {
        window.setTimeout( this.overviewResize, 0 );
    }

    clearOverviewWarning(delay) {
        clearTimeout(this.overviewWarningTimer);

        const borderColor = (this.overviewFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            overviewBorderColor: borderColor,
            overviewWarningOn: false,
            overviewWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.overviewWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Todos Warning
    ============================================================================================
    */

    todosOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState(
            {
                todos: event.target.value
            },
            () => {
                if (this.state.todos.length > 0) {
                    this.clearTodosWarning(false);
                }
            }
        );
    }


    todosResize() {
        this.todosRef.current.style.height = "auto";
        this.todosRef.current.style.height = this.todosRef.current.scrollHeight + "px";
    }


    todosResizeDelayed() {
        window.setTimeout( this.todosResize, 0 );
    }


    clearTodosWarning(delay) {
        clearTimeout(this.todosWarningTimer);

        const borderColor = (this.todosFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            todosBorderColor: borderColor,
            todosWarningOn: false,
            todosWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.todosWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        History Warning
    ============================================================================================
    */

    historyOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState(
            {
                history: event.target.value
            },
            () => {
                if (this.state.history.length > 0) {
                    this.clearHistoryWarning(false);
                }
            }
        );
    }


    historyResize() {
        this.historyRef.current.style.height = "auto";
        this.historyRef.current.style.height = this.historyRef.current.scrollHeight + "px";
    }


    historyResizeDelayed() {
        window.setTimeout( this.historyResize, 0 );
    }


    clearHistoryWarning(delay) {
        clearTimeout(this.historyWarningTimer);

        const borderColor = (this.historyFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            historyBorderColor: borderColor,
            historyWarningOn: false,
            historyWarningMessage: null
        };

        const delayTime = (delay)? this.delayTime : 0;

        this.historyWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /*
    ============================================================================================
        Stories Warning
    ============================================================================================
    */

    storiesOnChange(event) {
        // Stop event propagation
        event.stopPropagation();

        this.setState(
            {
                stories: event.target.value
            },
            () => {
                if (this.state.stories.length > 0) {
                    this.clearStoriesWarning(false);
                }
            }
        );
    }


    storiesResize() {
        this.storiesRef.current.style.height = "auto";
        this.storiesRef.current.style.height = this.storiesRef.current.scrollHeight + "px";
    }


    storiesResizeDelayed() {
        window.setTimeout( this.storiesResize, 0 );
    }


    clearStoriesWarning(delay) {
        clearTimeout(this.storiesWarningTimer);

        const borderColor = (this.storiesFocused)?
            window.colorLightGray :
            (
                (this.props.colorMode === "day")?
                    window.colorLightGray : window.colorDarkestGray
            );

        const stateToUpdate = {
            storiesBorderColor: borderColor,
            storiesWarningOn: false,
            storiesWarningMessage: null
        };

        const delayTime = delay? this.delayTime : 0;

        this.storiesWarningTimer = setTimeout(
            this.setState.bind(
                this,
                stateToUpdate,
                this.colorInputBorders
            ),
            delayTime
        );
    }


    /* 
    ============================================================================================
        Update Season Closure Dates
    ============================================================================================
    */

    updateSeasonClosureStartDate(date) {
        const closureStartMoment = moment(date);

        // Set state
        this.setState(
            {
                closureStartDate: closureStartMoment
            }
        );
    }


    updateSeasonClosureEndDate(date) {
        const closureEndMoment = moment(date);

        // Set state
        this.setState(
            {
                closureEndDate: closureEndMoment
            }
        );
    }


    updateTransitPhysicalities(transitIndex, physicality) {
        // Construct new transit physicalities
        const newTransitPhysicalities = this.state.transitPhysicalities.slice(0, transitIndex).concat([ physicality ], this.state.transitPhysicalities.slice(transitIndex + 1));

        // Update state
        this.setState({
            transitPhysicalities: newTransitPhysicalities
        });
    }


    updateTransitDurations(transitIndex, duration) {
        // Construct new transit durations
        const newTransitDurations = this.state.transitDurations.slice(0, transitIndex).concat([ duration ], this.state.transitDurations.slice(transitIndex + 1));

        // Update state
        this.setState({
            transitDurations: newTransitDurations
        });
    }


    updateTransitDistances(transitIndex, distance) {
        // Construct new transit distances
        const newTransitDistances = this.state.transitDistances.slice(0, transitIndex).concat([ distance ], this.state.transitDistances.slice(transitIndex + 1));

        // Update state
        this.setState({
            transitDistances: newTransitDistances
        });
    }

    updateDurationScaleIndices(transitIndex, durationScaleIndex) {
        // Construct new duration scale indices
        const newDurationScaleIndices = this.state.durationScaleIndices.slice(0, transitIndex).concat([ durationScaleIndex ], this.state.durationScaleIndices.slice(transitIndex + 1));

        // Update state
        this.setState({
            durationScaleIndices: newDurationScaleIndices
        });
    }

    updateDistanceScaleIndices(transitIndex, distanceScaleIndex) {
        // Construct new distance scale indices
        const newDistanceScaleIndices = this.state.distanceScaleIndices.slice(0, transitIndex).concat([ distanceScaleIndex ], this.state.distanceScaleIndices.slice(transitIndex + 1));

        // Update state
        this.setState({
            distanceScaleIndices: newDistanceScaleIndices
        });
    }


    /* 
    ============================================================================================
        Progress Warnings
    ============================================================================================
    */

    checkProgressFirstWarning() {
        if ((this.state.dotType !== "TR") && (this.state.dotType !== "RT")) {
            if ((this.state.mediaWarningOn) || (this.state.locationWarningOn) || (this.state.nameWarningOn) || (this.state.areaWarningOn)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if ((this.state.mediaWarningOn) || (this.state.areaWarningOn) || (this.state.locationWarningOn)) {
                return true;
            }
            else {
                return false;
            }
        }
    }


    checkProgressSecondWarning() {
            if ((this.state.titleWarningOn) || (this.state.overviewWarningOn) || (this.state.todosWarningOn) || (this.state.historyWarningOn) || (this.state.storiesWarningOn)) {
                return true;
            }
            else {
                return false;
            }
    }


    checkProgressThirdWarning() {
            if ((this.state.connectWarningOn)) {
                return true;
            }
            else {
                return false;
            }
    }


    /* 
    ============================================================================================
        Progress Callbacks
    ============================================================================================
    */

    progressPublishClick(state) {
        //console.log("CreateSimple / progressPublishClick");
        //console.log("CreateSimple / progressPublishClick - state = ", state);
        //console.log("CreateSimple / progressPublishClick - this.state = ", this.state);
        //console.log("CreateSimple / progressPublishClick - this.state.location = ", this.state.location);

        // Data warning
        let dataWarning = false;

        // Initialize warnings
        let mediaWarningOn = false;
        let locationWarningOn = false;
        let nameWarningOn = false;
        let areaWarningOn = false;
        let titleWarningOn = false;
        let overviewWarningOn = false;
        let connectWarningOn = false;
        let mediaWarningMessage = null;
        let locationWarningMessage = null;
        let nameWarningMessage = null;
        let areaWarningMessage = null;
        let titleWarningMessage = null;
        let overviewWarningMessage = null;
        let connectWarningMessage = null;

        // Construct request data
        let dataJSON = null;

        // General dot
        if ((this.state.contributorModeOn === false) && ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))) {
            // Media
            const mediaIDs = [];
            for (let i = 0; i < this.state.mediaKeys.length; i++) {
                const mediaKey = this.state.mediaKeys[i];
                const mediaID = this.state.mediaIDs[mediaKey];

                // Media IDs
                mediaIDs.push(mediaID)
            }
            //console.log("CreateSimple / progressPublishClick - this.state.mediaKeys = ", this.state.mediaKeys);
            //console.log("CreateSimple / progressPublishClick - this.state.mediaIDs = ", this.state.mediaIDs);

            // Check if all upload processes are complete
            let allUploadComplete = true;
            const keys = Object.keys(this.uploadProgress);
            for (let i = 0; i < keys.length; i++) {
                const key = Number(keys[i]);
                if (this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) {
                    allUploadComplete = false;
                }
            }

            // Check media
            if (!allUploadComplete) {
                dataWarning = true;
                mediaWarningOn = true;
                mediaWarningMessage = this.mediaWarningUploadMessage;
            }
            else { 
                if (this.state.mediaKeys.length === 0) {
                    dataWarning = true;
                    mediaWarningOn = true;
                    mediaWarningMessage = this.mediaWarningMessage;
                }
            }

            // Check location
            if ((this.state.accessibilityMode === "parkandwalk") && (this.state.parkingLocation === null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationParkingWarningMessageSimple;
            }
            if (this.state.location === null) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationWarningMessage;
            }

            // Check name
            if ((this.nameRef.current.value === null) || (this.nameRef.current.value === "")) {
                dataWarning = true;
                nameWarningOn = true;
                nameWarningMessage = this.nameWarningMessageSimple;
            }
            // Check title
            if ((this.titleRef.current.value === null) || (this.titleRef.current.value === "")) {
                dataWarning = true;
                titleWarningOn = true;
                titleWarningMessage = this.titleWarningMessageSimple;
            }
            // Check overview
            if ((this.overviewRef.current.value === null) || (this.overviewRef.current.value === "")) {
                dataWarning = true;
                overviewWarningOn = true;
                overviewWarningMessage = this.overviewWarningMessageSimple;
            }

            // Issue warnings
            const stateToUpdate = {
                mediaWarningOn: mediaWarningOn,
                mediaWarningMessage: mediaWarningMessage,
                locationWarningOn: locationWarningOn,
                locationWarningMessage: locationWarningMessage,
                nameWarningOn: nameWarningOn,
                nameWarningMessage: nameWarningMessage,
                //areaWarningOn: areaWarningOn,
                //areaWarningMessage: areaWarningMessage,
                titleWarningOn: titleWarningOn,
                titleWarningMessage: titleWarningMessage,
                overviewWarningOn: overviewWarningOn,
                overviewWarningMessage: overviewWarningMessage,
                //todosWarningOn: todosWarningOn,
                //todosWarningMessage: todosWarningMessage,
                //historyWarningOn: historyWarningOn,
                //historyWarningMessage: historyWarningMessage,
                //storiesWarningOn: storiesWarningOn,
                //storiesWarningMessage: storiesWarningMessage,
                //connectWarningOn: connectWarningOn,
                //connectWarningMessage: connectWarningMessage
            };

            // Update state
            this.setState(stateToUpdate, this.colorInputBorders);

            // Send request if no errors
            if (dataWarning === false) {
                //console.log("CreateSimple / progressPublishClick (dot) - state = ", state)

                // Parking location
                const parkingLocation = (this.state.everydayModeOn)? null : 
                    ((this.state.accessibilityMode === "parkandwalk")? this.state.parkingLocation : null);

                // Drivable
                const drivable = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "undrivable")? false : true);

                // Public Transportation
                const publicTransportation = (this.state.everydayModeOn)? null :
                    (
                        (this.state.accessibilityMode === "undrivable")?
                            null :
                            (
                                (this.state.preferredMode === "public")? true : false
                            )
                    );

                // Map zoom level / center / type / displacement
                let mapZoom = null;
                let mapCenter = null;
                let mapType = null;

                if (this.mapSource === "google") {
                    mapZoom = this.state.map.getZoom()
                    mapCenter = latLngToPoint(this.state.map.getCenter());
                    mapType = this.state.map.getMapTypeId();
                }
                else {
                    const view = this.state.map.getView();
                    mapZoom = Math.round(view.getZoom());
                    const lnglat = openLayersProj.transform(view.getCenter(), "EPSG:3857", "EPSG:4326");
                    //console.log("CreateSimple / progressPublishClick - lnglat = ", lnglat);
                    mapCenter = {
                        latitude: lnglat[1],
                        longitude: lnglat[0]
                    };
                    mapType = "hybrid";
                }

                // Displacement and create map media switch
                const displacement = (this.mapSource === "google")?
                    calculateDisplacement(this.state.location, mapCenter, this.state.map) : [ 0.0, 0.0 ];
                const createMapMedia = (this.mapSource === "google");

                //console.log("CreateSimple / progressPublishClick - mapZoom = ", mapZoom);
                //console.log("CreateSimple / progressPublishClick - mapCenter = ", mapCenter);
                //console.log("CreateSimple / progressPublishClick - mapType = ", mapType);
                //console.log("CreateSimple / progressPublishClick - displacement = ", displacement);

                // Request data
                dataJSON = {
                    // Editor
                    editor_id: this.props.userInfo.id,
                    // Media
                    media_ids: mediaIDs,
                    media_overview: mediaIDs,
                    // Dot type
                    type: (this.state.everydayModeOn)? "EV" : "AU",
                    // Name
                    name: this.nameRef.current.value,
                    // Area
                    area: null,
                    // Rating and physicality 
                    //rating: this.state.rating,
                    //physicality: this.state.physicality,
                    // Duration
                    //duration: this.state.duration,
                    // Best time
                    //time_type: timeType,
                    //time_type_optional: timeTypeOptional,
                    // Hours
                    //start_hour: startHour,
                    //end_hour: endHour,
                    // Season closure
                    //closure_estimate: seasonClosureEstimate,
                    //closure_start_date: seasonClosureStart,
                    //closure_end_date: seasonClosureEnd,
                    // Locations
                    location: this.state.location,
                    parking_location: parkingLocation,
                    //start_location: startLocation,
                    //end_location: (this.state.loop)? null : endLocation,
                    //start_location_name: startLocationName,
                    //end_location_name: (this.state.loop)? null : endLocationName,
                    // Drivable
                    drivable: drivable,
                    // Prefered transportation
                    public_transportation: publicTransportation,
                    // Roundtrip
                    //roundtrip: this.state.roundtrip,
                    // Loop
                    //loop: this.state.loop,
                    // Reversible
                    //reversible: this.state.reversible,
                    // State
                    state: this.state.state,
                    // Map zoom level and center
                    map_zoom: mapZoom,
                    map_center: mapCenter,
                    map_type: mapType,
                    map_source: this.mapSource,
                    map_displacement: displacement,
                    create_map_media: createMapMedia,
                    // Curation
                    title: this.titleRef.current.value,
                    overview: this.overviewRef.current.value,
                    //todos: (this.todosRef.current.value.length === 0)? null: this.todosRef.current.value,
                    //history: (this.historyRef.current.value.length === 0)? null: this.historyRef.current.value,
                    //stories: (this.storiesRef.current.value.length === 0)? null: this.storiesRef.current.value,
                    // Connect
                    //child_ids: null,
                    //highlights: null
                    // Automatically approved
                    is_approved: (this.props.userInfo.create_level === 5)? true : false
                    // Public and save limit
                    //is_public: this.state.is_public,
                    //save_limit: this.saveLimits[this.state.saveLimit]
                };
            }
        }

        // Contributor dot
        else if ((this.state.contributorModeOn === true) && ((this.state.dotType !== "TR") && (this.state.dotType !== "RT"))) {
            // Media
            const mediaIDs = [];
            const mediaOverview = [];
            const mediaTodos = [];
            const mediaHistory = [];
            const mediaStories = [];
            for (let i = 0; i < this.state.mediaKeys.length; i++) {
                const mediaKey = this.state.mediaKeys[i];
                const mediaID = this.state.mediaIDs[mediaKey];
                const mediaCategory = this.state.mediaCategories[this.state.mediaKeys[i]];

                // Media IDs
                mediaIDs.push(mediaID)

                // Categorize media IDs
                if (this.state.mediaCategories[mediaKey] === 0) {
                    mediaOverview.push(mediaID);
                }
                else if (mediaCategory === 1) {
                    mediaTodos.push(mediaID);
                }
                else if (mediaCategory === 2) {
                    mediaHistory.push(mediaID);
                }
                else if (mediaCategory === 3) {
                    mediaStories.push(mediaID);
                }
                else {
                    console.log("[WARNING] CreateSimple / progressPublishClick - wrong media category");
                }
            }

            // Check if all upload processes are complete
            let allUploadComplete = true;
            const keys = Object.keys(this.uploadProgress);
            for (let i = 0; i < keys.length; i++) {
                const key = Number(keys[i]);
                if (this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) {
                    allUploadComplete = false;
                }
            }

            // Check media
            if (allUploadComplete) {
                if (this.state.mediaKeys.length === 0) {
                    dataWarning = true;
                    mediaWarningOn = true;
                    mediaWarningMessage = this.mediaWarningMessage;
                }
                else {
                    if (mediaOverview.length === 0) {
                        dataWarning = true;
                        mediaWarningOn = true;
                        mediaWarningMessage = this.mediaWarningOverviewMessage;
                    }
                }
            }
            else {
                dataWarning = true;
                mediaWarningOn = true;
                mediaWarningMessage = this.mediaWarningUploadMessage;
            }
  
            // Check location
            if ((this.state.accessibilityMode === "parkandwalk") && (this.state.parkingLocation === null)) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationParkingWarningMessage;
            }
            if (this.state.location === null) {
                dataWarning = true;
                locationWarningOn = true;
                locationWarningMessage = this.locationWarningMessage;
            }

            // Check name
            if ((this.nameRef.current.value === null) || (this.nameRef.current.value === "")) {
                dataWarning = true;
                nameWarningOn = true;
                nameWarningMessage = this.nameWarningMessage;
            }
            // Check area
            if ((this.areaRef.current.value === null) || (this.areaRef.current.value === "")) {
                dataWarning = true;
                areaWarningOn = true;
                areaWarningMessage = this.areaWarningMessage;
            }
            // Check title
            if ((this.titleRef.current.value === null) || (this.titleRef.current.value === "")) {
                dataWarning = true;
                titleWarningOn = true;
                titleWarningMessage = this.titleWarningMessage;
            }
            // Check overview
            if ((this.overviewRef.current.value === null) || (this.overviewRef.current.value === "")) {
                dataWarning = true;
                overviewWarningOn = true;
                overviewWarningMessage = this.overviewWarningMessage;
            }
            /*
            // Check todos
            if ((this.todosRef.current.value === null) || (this.todosRef.current.value === "")) {
                dataWarning = true;
                todosWarningOn = true;
                todosWarningMessage = "What-to-do Ideas to Fully Enjoy the Place";
            }
            // Check history
            if ((this.historyRef.current.value === null) || (this.historyRef.current.value === "")) {
                dataWarning = true;
                historyWarningOn = true;
                historyWarningMessage = "A Little History That Gives a Different Perspective";
            }
            // Check stories
            if ((this.storiesRef.current.value === null) || (this.storiesRef.current.value === "")) {
                dataWarning = true;
                storiesWarningOn = true;
                storiesWarningMessage = "Other Interesting Stories or Anecdotes";
            }
            */

            // Issue warnings
            const stateToUpdate = {
                mediaWarningOn: mediaWarningOn,
                mediaWarningMessage: mediaWarningMessage,
                locationWarningOn: locationWarningOn,
                locationWarningMessage: locationWarningMessage,
                nameWarningOn: nameWarningOn,
                nameWarningMessage: nameWarningMessage,
                areaWarningOn: areaWarningOn,
                areaWarningMessage: areaWarningMessage,
                titleWarningOn: titleWarningOn,
                titleWarningMessage: titleWarningMessage,
                overviewWarningOn: overviewWarningOn,
                overviewWarningMessage: overviewWarningMessage,
                //todosWarningOn: todosWarningOn,
                //todosWarningMessage: todosWarningMessage,
                //historyWarningOn: historyWarningOn,
                //historyWarningMessage: historyWarningMessage,
                //storiesWarningOn: storiesWarningOn,
                //storiesWarningMessage: storiesWarningMessage,
                //connectWarningOn: connectWarningOn,
                //connectWarningMessage: connectWarningMessage
            };

            // Update state
            this.setState(stateToUpdate, this.colorInputBorders);

            // Send request if no errors
            if (dataWarning === false) {
                // console.log("CreateSimple / progressPublishClick (dot) - state = ", state)

                // Dot Type
                let dotType = null;
                if (this.state.everydayModeOn) {
                    dotType = "EV";
                }
                else if (this.state.authorModeOn) {
                    dotType = "AU";
                }
                else {
                    dotType = ((this.state.dotType === "EX") && (this.state.diningOptions))? "DE" : this.state.dotType;
                }

                // Parking location
                const parkingLocation = (this.state.everydayModeOn)? null : 
                    ((this.state.accessibilityMode === "parkandwalk")? this.state.parkingLocation : null);

                // Drivable
                const drivable = (this.state.everydayModeOn)? null :
                    ((this.state.accessibilityMode === "undrivable")? false : true);

                // Public Transportation
                const publicTransportation = (this.state.everydayModeOn)? null :
                    (
                        (this.state.accessibilityMode === "undrivable")?
                            null :
                            (
                                (this.state.preferredMode === "public")? true : false
                            )
                    );

                // Hours
                let startHour = null;
                let endHour = null;
                if (this.state.hoursOn) {
                    //console.log("CreateSimple / progressPublishClick - this.state.startHour.meridiem = ", this.state.startHour.meridiem)
                    let startAddHour, endAddHour;

                    if ((this.state.startHour.meridiem === "pm") && (this.state.startHour.hour < 12)) {
                        startAddHour = 12;
                    }
                    else if ((this.state.startHour.meridiem === "am") && (this.state.startHour.hour === 12)) {
                        startAddHour = -12;
                    }
                    else {
                        startAddHour = 0;
                    }

                    if ((this.state.endHour.meridiem === "pm") && (this.state.endHour.hour < 12)) {
                        endAddHour = 12;
                    }
                    else if ((this.state.endHour.meridiem=== "am") && (this.state.endHour.hour === 12)) {
                        endAddHour = -12;
                    }
                    else {
                        endAddHour = 0;
                    }

                    startHour = "" + pad(this.state.startHour.hour + startAddHour, 2, 0) + ":" + pad(this.state.startHour.minute, 2, 0);
                    endHour = "" + pad(this.state.endHour.hour + endAddHour, 2, 0) + ":" + pad(this.state.endHour.minute, 2, 0);
                }


                // Time types
                let timeType = null;
                let timeTypeOptional = null;
                if (this.state.bestTimeOn) {
                    if (this.state.bestTimeMode === "double") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                        timeTypeOptional = this.bestTimeTypes[this.state.bestTimeSecond];
                    }
                    else if (this.state.bestTimeMode === "single") {
                        timeType = this.bestTimeTypes[this.state.bestTimeFirst];
                    }
                    else if (this.state.bestTimeMode === "allday") {
                        timeType = "AD";
                    }
                    else {
                        console.log("[WARNING] CreateSimple / progressPublishClick - wrong best time mode");
                    }
                }

                // Seasonal information
                let seasonClosureEstimate = null;
                if (this.state.seasonClosureType === "open") {
                    seasonClosureEstimate = null;
                }
                else if (this.state.seasonClosureType === "estimate") {
                    seasonClosureEstimate = true;
                }
                else {
                    seasonClosureEstimate = false;
                }
                let seasonClosureStart = null;
                let seasonClosureEnd = null;
                if (this.state.seasonClosureType !== "open") {
                    seasonClosureStart = "" + pad(this.state.closureStartDate.year(), 4, 0) + "-" + pad(this.state.closureStartDate.month() + 1, 2, 0) + "-" + pad(this.state.closureStartDate.date(), 2, 0);
                }
                if (this.state.seasonClosureType !== "open") {
                    seasonClosureEnd = "" + pad(this.state.closureEndDate.year(), 4, 0) + "-" + pad(this.state.closureEndDate.month() + 1, 2, 0) + "-" + pad(this.state.closureEndDate.date(), 2, 0);
                }

                // Map zoom level / center / type
                let mapZoom = null;
                let mapCenter = null;
                let mapType = null;

                if (this.mapSource === "google") {
                    mapZoom = this.state.map.getZoom()
                    mapCenter = latLngToPoint(this.state.map.getCenter());
                    mapType = this.state.map.getMapTypeId();
                }
                else {
                    const view = this.state.map.getView();
                    mapZoom = Math.round(view.getZoom());
                    const lnglat = openLayersProj.transform(view.getCenter(), "EPSG:3857", "EPSG:4326");
                    //console.log("CreateSimple / progressPublishClick - lnglat = ", lnglat);
                    mapCenter = {
                        latitude: lnglat[1],
                        longitude: lnglat[0]
                    };
                    mapType = "hybrid";
                }

                // Displacement and create map media switch
                const displacement = (this.mapSource === "google")?
                    calculateDisplacement(this.state.location, mapCenter, this.state.map) : [ 0.0, 0.0 ];
                const createMapMedia = (this.mapSource === "google");

                //console.log("CreateSimple / progressPublishClick - mapZoom = ", mapZoom);
                //console.log("CreateSimple / progressPublishClick - mapCenter = ", mapCenter);
                //console.log("CreateSimple / progressPublishClick - mapType = ", mapType);

                // Request data
                dataJSON = {
                    // Editor
                    editor_id: this.props.userInfo.id,
                    // Media
                    media_ids: mediaIDs,
                    media_overview: mediaOverview,
                    media_todos: mediaTodos,
                    media_history: mediaHistory,
                    media_stories: mediaStories,
                    // Object and dot type
                    type: dotType,
                     // Name
                    name: this.nameRef.current.value,
                    // Area
                    area: this.areaRef.current.value,
                    // Rating and physicality 
                    rating: this.state.rating,
                    physicality: (this.state.dotType === "DI")? null : this.state.physicality,
                    // Duration
                    duration: this.state.duration,
                    // Best time
                    time_type: timeType,
                    time_type_optional: timeTypeOptional,
                    // Hours
                    start_hour: startHour,
                    end_hour: endHour,
                    // Season closure
                    closure_estimate: seasonClosureEstimate,
                    closure_start_date: seasonClosureStart,
                    closure_end_date: seasonClosureEnd,
                    // Locations
                    location: this.state.location,
                    parking_location: parkingLocation,
                    //start_location: startLocation,
                    //end_location: (this.state.loop)? null : endLocation,
                    //start_location_name: startLocationName,
                    //end_location_name: (this.state.loop)? null : endLocationName,
                    // Drivable
                    drivable: drivable,
                    // Prefered transportation
                    public_transportation: publicTransportation,
                    // Roundtrip
                    //roundtrip: this.state.roundtrip,
                    // Loop
                    //loop: this.state.loop,
                    // Reversible
                    //reversible: this.state.reversible,
                    // State
                    state: this.state.state,
                    // Map zoom level and center
                    map_zoom: mapZoom,
                    map_center: mapCenter,
                    map_type: mapType,
                    map_source: this.mapSource,
                    map_displacement: displacement,
                    create_map_media: createMapMedia,
                    // Curation
                    title: this.titleRef.current.value,
                    overview: this.overviewRef.current.value,
                    todos: (this.todosRef.current.value.length === 0)? null : this.todosRef.current.value,
                    history: (this.historyRef.current.value.length === 0)? null : this.historyRef.current.value,
                    stories: (this.storiesRef.current.value.length === 0)? null : this.storiesRef.current.value,
                    // Connect
                    //child_ids: null,
                    //highlights: null
                    // Automatically approved
                    is_approved: (this.props.userInfo.create_level === 5)? true : false
                    // Public and save limit
                    //is_public: this.state.is_public,
                    //save_limit: this.saveLimits[this.state.saveLimit]
                };
            }
        }
        else {
            console.log("[WARNING] CreateSimple / progressPublishClick - wrong object type");
        }
        //console.log("CreateSimple / progressPublishClick - dataJSON = ", dataJSON);

        // Send create request
        if (dataWarning === false) {
            // Get response and update states
            const axiosCallback = (response) => {
                console.log("CreateSimple / progressPublishClick - response.data.content = ", response.data.content);

                // Close modal
                this.props.storeCreate({
                    modalOn: false,
                    mode: null,
                    setSlug: null,
                    location: null,
                    info: null
                });

                // Refresh timestamp
                this.props.storeRefresh({
                    timestamp: getTimestamp()
                });

                /*
                const firstState = {
                    completeModalOn: true,
                    objectSlug: response.data.content.slug
                };

                const secondState = {
                    completeModalOpacity: 1.0
                };

                // Set state chain
                updateStateChain2(this.setState, firstState, secondState, null, this.transitionTime);
                */
            };
 
            if (this.createModeOn && this.props.create.setSlug !== null) {
                //console.log("CreateSimple / progressPublishClick - Create Set Child Mode");

                // Send create request to create set child
                postSetChildDot(this.props.create.setSlug, dataJSON)    
                .then(axiosCallback)
                .catch((response) => {console.log("[WARNING] CreateSimple / progressPublishClick - error = ", response);});
            }
            else if (this.createModeOn && this.props.create.setSlug === null) {
                //console.log("CreateSimple / progressPublishClick - Create Regular Mode");

                // Send create request to create a regular dot
                postDot(dataJSON)    
                .then(axiosCallback)
                .catch((response) => {console.log("[WARNING] CreateSimple / progressPublishClick - error = ", response);});
            }
            else {
                //console.log("CreateSimple / progressPublishClick - Edit Mode");

                // Send edit request
                patchDot(this.props.create.info.slug, dataJSON)
                .then(axiosCallback)
                .catch((response) => {console.log("[WARNING] CreateSimple / progressPublishClick - error = ", response);});                
            }
        }
    }


    getState(progressPublishClick) {
        // If location is set
        if (this.state.location !== null) {
            // Geocode
            const geocoder = new this.props.google.maps.Geocoder();

            geocoder.geocode({"location": pointToLatLng(this.state.location)}, function(results, status) {
                if (status === "OK") {

                    let state = null;

                    for (let i=0; i < results[0].address_components.length; i++) {
                        if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
                            state = results[0].address_components[i].short_name;
                            //console.log("CreateSimple / getState - state = ", state);
                        }
                    }

                    if (state === null) {
                        state = results[0].address_components[0].short_name;
                    }

                    if ((progressPublishClick) && (typeof progressPublishClick === "function")) {
                        progressPublishClick(state);
                    }
                }
            });
        }
        // When location is not set
        else {
            progressPublishClick(null);
        }
    }

    completeButtonClick() {
        //console.log("CreateSimple / completeButtonClick");
        //console.log("CreateSimple / completeButtonClick - this.state.isPublic = ", this.state.isPublic);
        //console.log("CreateSimple / completeButtonClick - this.state.saveLimit = ", this.state.saveLimit);
        //console.log("CreateSimple / completeButtonClick - this.saveLimits.length = ", this.saveLimits.length);

        if (!this.state.isPublic || 
            (this.state.isPublic && this.state.saveLimit < (this.saveLimits.length - 1))) {
            // Submit the content to editorial team
            const axiosCallback = (response) => {
                console.log("CreateSimple / completeButtonClick - response.data.content = ", response.data.content);

                // Reset create
                this.props.storeCreate({
                    modalOn: false,
                    mode: null,
                    setSlug: null,
                    location: null,
                    info: null
                });
            };

            const dataJSON = {
                is_public: this.state.isPublic,
                save_limit: this.saveLimits[this.state.saveLimit]
            };
            //console.log("CreateSimple / completeButtonClick - dataJSON = ", dataJSON);

            patchDot(this.state.objectSlug, dataJSON)
            .then(axiosCallback)
            .catch((response) => {console.log("[WARNING] CreateSimple / completeButtonClick - error = ", response);});
        }
        else {
            // Reset create
            this.props.storeCreate({
                modalOn: false,
                mode: null,
                setSlug: null,
                location: null,
                info: null
            });
        }
    }

    scrollToTop() {
        // Scroll to the top
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }    
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        google: state.map.google,
        create: state.create.create
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        storeRefresh,
        storeCreate,
        storeWarningAlert
    }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSimple); 
