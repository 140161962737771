// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Modules
import ReactTooltip from "thedots-tooltip";

// Functions
import {
    getStaticPath,
    getMediaProperty,
    sortMedia
} from "js/Functions";

// CSS
import "./SaveBucket.scss";


/*
============================================================================================
    Save bucket
============================================================================================
*/

class SaveBucket extends Component {
    constructor(props) {
        super(props);

        // Settings
        this.numSavedItems = 5;
    }

    getSavedItemsSorted(savedDots) {
        // Sort list
        savedDots.sort(function(a, b){return b.saved_time_by_me - a.saved_time_by_me});

        return savedDots
    }

    render() {
        //console.log("----------------------------------");
        //console.log(" saveBucket / render");
        //console.log("----------------------------------");
        //console.log("SaveBucket / render - this.props = ", this.props);
        //console.log("SaveBucket / render - this.state = ", this.state);
        //console.log("----------------------------------");
        if (
            ((this.props.userInfo) && (this.props.userInfo.saved_dot_count > 0)) &&
            (this.props.browserWidth >= 8)
        ) {
            // Sort saved objects by date
            const savedItemsSorted = this.getSavedItemsSorted(
                this.props.userInfo.saved_dots_recent
            );

            // Saved and completed counts
            const savedDotCount = this.props.userInfo.saved_dot_count;

            // Bucket images
            const saveBucketImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/bucket-black.png") :
                getStaticPath("/images/common/bucket-white.png");

            // Construct list of items
            const savedItemList = savedItemsSorted.slice(0, this.numSavedItems).map(
                (savedItem, index) => {
                    //console.log("SaveBucket / render - savedItem = ", savedItem);
                    //console.log("SaveBucket / render - index = ", index);

                    // Sort media
                    const mediaSorted = sortMedia(savedItem);

                    // Merge all media categories
                    const media = [
                        ...mediaSorted.overview,
                        ...mediaSorted.todos,
                        ...mediaSorted.history,
                        ...mediaSorted.stories
                    ];

                    // Pick the first item
                    const mediaInfo = media[0];

                    let savedItemImage = null;
                    let savedItemClass = null;
                    if (mediaInfo.type === "video") {
                        if ("t" in mediaInfo.files) {
                            savedItemImage = getMediaProperty(mediaInfo, "t", "url", true);
                            savedItemClass = (this.props.colorMode === "day")?
                                "save-bucket-item-image profile-image-s1 image-button-strong-reverse-base border-day" :
                                "save-bucket-item-image profile-image-s1 image-button-strong-base border-night";
                        }
                        else {
                            savedItemImage = (this.props.colorMode === "day")?
                                getStaticPath("/images/common/video-black.png", true) :
                                getStaticPath("/images/common/video-white.png", true);

                            savedItemClass = (this.props.colorMode === "day")?
                                "save-bucket-item-video profile-image-s1 image-button-strong-reverse-base border-day" :
                                "save-bucket-item-video profile-image-s1 image-button-strong-base border-night";
                        }
                        // else {
                        //     savedItemImage = getMediaProperty(mediaInfo, "t", 'url', true);
                        //     savedItemClass = (this.props.colorMode === "day")?
                        //         "save-bucket-item-image profile-image-s1 image-button-strong-reverse-base border-day" :
                        //         "save-bucket-item-image profile-image-s1 image-button-strong-base border-night";
                        // }
                    }
                    else {
                        savedItemImage = getMediaProperty(mediaInfo, "t", "url", true);
                        savedItemClass = (this.props.colorMode === "day")?
                            "save-bucket-item-image profile-image-s1 image-button-strong-reverse-base border-day" :
                            "save-bucket-item-image profile-image-s1 image-button-strong-base border-night";
                    }

                    // Item name
                    const savedItemName = savedItem.object_type === "trip"? savedItem.title : savedItem.name;

                    // Item type icon
                    /*
                    let savedItemIcon;
                    if (savedItem.type == "TR") {
                        savedItemIcon = (<div className = "save-bucket-item-icon">TR</div>)
                    }
                    else if (savedItem.type == "RT") {
                        savedItemIcon = (<div className = "save-bucket-item-icon">RT</div>)
                    }
                    else if (savedItem.type == "EX") {
                        savedItemIcon = (<div className = "save-bucket-item-icon">EX</div>)
                    }
                    else if (savedItem.type == "DE") {
                        savedItemIcon = (<div className = "save-bucket-item-icon">DE</div>)
                    }
                    else if (savedItem.type == "DI") {
                        savedItemIcon = (<div className = "save-bucket-item-icon">DI</div>)
                    }
                    else if (savedItem.type == "SC") {
                        savedItemIcon = (<div className = "save-bucket-item-icon">SC</div>)
                    }
                    else {
                        console.log("[WARNING] SaveBucket / render - wrong item type");
                    }
                    */

                    // Tooltip settings
                    const tooltipID = "save-bucket-item-tooltip-" + index.toString();

                    // Get the link URL
                    let linkURL = null;
                    if (savedItem.type === "TR") {
                        linkURL = "/trip/" + String(savedItem.slug);
                    }
                    else if (savedItem.type === "RT") {
                        linkURL = "/route/" + String(savedItem.slug);
                    }
                    else {
                        linkURL = "/dot/" + String(savedItem.slug);
                    }

                    // JSX
                    return(
                        <Link key = {"save-bucket-item-link-" + index.toString()}
                            to = {linkURL}>
                            <div key = {"Save-bucket-item-" + index.toString()}
                                className = "save-bucket-item">
                                <div className = {savedItemClass}
                                    style = {{ backgroundImage: savedItemImage }}
                                    data-tip = {savedItemName}
                                    data-for = {tooltipID}
                                >
                                </div>
                                <ReactTooltip
                                    id = {tooltipID}
                                    className = "save-bucket-item-tooltip tooltip-s2"
                                    type = "dark"
                                    place = "left"
                                    html = {true}
                                />
                            </div>
                        </Link>
                    );
                }
            );

            const savedCount = savedDotCount > 0?
            (
                <div id = "save-bucket-saved-count"
                    className = {(this.props.colorMode === "day")? "number-lb0" : "number-b0"}
                    data-tip = "Saved"
                    data-for = "save-bucket-saved-count-tooltip"
                >
                    {savedDotCount}
                </div>
            ) : null;


            // User page link path
            const userPageLink = {
                pathname: "/user/" + String(this.props.userInfo.username),
                state: { bucketMode: "save", displayMode: "bucket" }
            };

            return(
                <div id = "save-bucket-container"
                    className = {(this.props.colorMode === "day")?
                        "save-bucket-container-day" : "save-bucket-container-night"}
                >
                    {savedCount}
                    <div id = "save-bucket-items-container">
                        {savedItemList}
                    </div>
                    <div id = "save-bucket-title"
                        className = {(this.props.colorMode === "day")? "k4" : "w4"}
                    >
                        Bucket
                    </div>
                    <Link to = {userPageLink}>
                        <div id = "save-bucket-image"
                            className = "image-contain"
                            style = {{ backgroundImage: saveBucketImage }}
                        >
                        </div>
                    </Link>
                    <ReactTooltip
                        id = "save-bucket-saved-count-tooltip"
                        className = "save-bucket-item-tooltip tooltip-s2"
                        type = "info"
                        place = "left"
                        html = {true}
                    />
                    <ReactTooltip
                        id = "save-bucket-completed-count-tooltip"
                        className = "save-bucket-item-tooltip tooltip-s2"
                        type = "dark"
                        place = "left"
                        html = {true}
                    />
                </div>
            );
        }
        else {
            return null;
        }

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
    }
}

/*
============================================================================================
    SaveBucket Hover
============================================================================================
*/
/*
function saveBucketMouseEnter(setState) {
    setState({
        saveBucketOpacity: 1.0,
        saveBucketHovered: true
    })
}

function saveBucketMouseLeave(setState) {
    setState({
        saveBucketOpacity: 0.0,
        saveBucketHovered: false
    })
}
*/

/*
============================================================================================
    showSaveBucket
============================================================================================
*/
/*
function showSaveBucket() {
    const hideSaveBucket = () => {
        setTimeout(
            () => {
                if (!this.state.saveBucketHovered) {
                    this.setState(
                        {
                            saveBucketOpacity: 0.0
                        }
                    )
                }
            },
            2500
        );
    };

    this.setState(
        {
            saveBucketOpacity: 1.0
        },
        hideSaveBucket
    )
}*/

function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        google: state.map.google
    };
}

export default connect(mapStateToProps, null)(SaveBucket);
