const createAPI = "api/create/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const postPublicClick = (object_id) => {
    let URL = rootURL + createAPI + "public/";
    
    return window.axiosCSRF.post(URL, {object_id});
};

// Send create request
export const postCreateSearch = (dataJSON) => {
    let URL = rootURL + createAPI + "search/";

    return window.axiosCSRF.post(URL, dataJSON);
};
