import { STORE_MORE } from "actions";

const INITIAL_STATE = {
    more: { modalOn: false, type: "", info: "", isAuthor: false }
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
        case STORE_MORE:
            return { ...state, more: action.payload };
		default:
			return state;
	}
};