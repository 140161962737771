import { STORE_SHARE } from "actions"; 

const INITIAL_STATE = { 
	share: { modalOn: false, type: "", slug: "" } 
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_SHARE:
			return { ...state, share: action.payload }; 
		default:
			return state;
	}
};