/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Dot.js
    - Home page of a dot
--------------------------------------------------------------------------------------------
    Content
    - Dot
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Modules
import ReactTooltip from "thedots-tooltip";
import { MetaTags } from "react-meta-tags";

// Components
import { OpenMap, GoogleMap } from "components/Map";
import SaveBucket from "components/SaveBucket";
import { Gallery, mediaDimensions } from "components/Gallery";
import Board from "components/Board";
import Draggable from "js/Draggable";

// Axios
import {
    getDot,
    postFollowRequest,
    getSingleBoardComments
} from "requests";

// Websocket
import {
    addWebSocketGroups,
    removeWebSocketGroups
} from "js/WebSocketFunctions";

// Redux
import {
    storeUser,
    storeMore,
    storeNewLike,
    storeNewSave,
    storeShare
} from "actions";

// Functions
import {
    BottleProfilePicList,
    UserProfilePicList,
    UserSimpleList
} from "js/Common";

import {
    updateCurationStyle,
    curationCapitalize,
    curationPartition,
    removeFirstSpace
}
from "js/Curation";

import {
    likeButtonClick,
    saveButtonClick
} from "js/Interaction";

import {
    url,
    getStaticPath,
    getMediaProperty,
    sortMedia,
    formatNumbers,
    debounce
} from "js/Functions";

// CSS
import "./Dot.scss";


// Dot class
class Dot extends Component {
    // Constructor receives dotSlug as the prop
    constructor (props) {
        super (props);
        //console.log("Dot / constructor - props = ", props);

        // Map Mode (google / open / static)
        this.mapSource = "static";
        this.mapMode = "dots-home";

        // Displacement ratio
        this.displacementRatio = Number(720/640);

        // Name Mobile Max Length
        this.nameMobileMaxLength = 8;

        // Side column widths
        this.editorColumnWidth = 160;
        this.statsColumnWidth = 160;

        // Minimum / scale / maximum content widths
        this.minContentWidth = 320;
        this.scaleContentWidth = 600;
        this.maxContentWidth = 720;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media area
        this.mediaArea = 360000;

        // Media side margin width
        this.marginWidth = 10;

        // Gallery spacing
        this.gallerySpacing = 32;

        // Map height
        this.mapHeight = 280;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // User ratings / difficulties settings
        this.numThresholdRatings = 5;
        this.numThresholdDifficulties = 5;
        this.numCompletedUserProfilePics = 3;

        // Staff section settings
        this.numStaffProfilePics = 3;

        // Bottle size
        this.minBottleSize = 3;
        this.maxBottleSize = 5;

        // Transition effect
        this.transitionTime = 300;

        // Curation refs
        this.curationTitleRef = null;
        this.curationTitleLetterRef = null;
        this.curationOverviewRef = null;
        this.curationOverviewLetterRef = null;
        this.curationTodosRef = null;
        this.curationTodosLetterRef = null;
        this.curationHistoryRef = null;
        this.curationHistoryLetterRef = null;
        this.curationStoriesRef = null;
        this.curationStoriesLetterRef = null;

        // Curation ref and height
        this.curationHeight = 100;
        this.curationLineHeight = 20;
        this.curationMargin = 2;
        this.overviewRef = React.createRef();
        this.todosRef = React.createRef();
        this.historyRef = React.createRef();
        this.storiesRef = React.createRef();

        // Map DOM IDs
        this.mapNodeID = "dot-map";
        this.mapContainerNodeID = "dot-map-wrapper";
        this.mapButtonsNodeID = "dot-map-buttons";

        // WebSocket Prefix
        this.webSocketGroupPrefix = "dot_board";

        // Initialize states
        this.state = {
            // Dot information
            dotInfo: null,

            // Comments info
            commentsInfo: null,

            // Media galleries
            mediaOverview: null,
            mediaTodos: null,
            mediaHistory: null,
            mediaStories: null,
            overviewSelectedMediaIndex: 0,
            todosSelectedMediaIndex: 0,
            historySelectedMediaIndex: 0,
            storiesSelectedMediaIndex: 0,

            // Curation
            overviewExpanded: false,
            overviewVariableHeight: false,
            todosExpanded: false,
            todosVariableHeight: false,
            historyExpanded: false,
            historyVariableHeight: false,
            storiesExpanded: false,
            storiesVariableHeight: false,

            // Like / save
            likeCount: null,
            likedByMe: null,
            saveCount: null,
            savedByMe: null,

            // Editor buttons
            editorChatButtonOn: false,
            editorCalendarButtonOn: false,
            editorInfoButtonOn: false,

            // Editor information window
            editorInfoOn: false,

            // If the editor is followed by the logged in user
            followedByMe: null,
            followerCount: null,

            // Preview switches and opacities
            // - Only used in Trip Itinerary
            previewOn: false,
            previewContentOn: false,

            // Static map drag
            dragOn: (window.touchOn)? false : true,

            // Window width
            windowWidth: window.innerWidth,

            // Websocket Group
            webSocketGroup: null,

            // Scroll
            checkScroll: false
        };

        // Bind setState and onScroll functions
        this.setState = this.setState.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);

        // Bind editor follow / unfollow / editor info functions
        this.followRequest = this.followRequest.bind(this);
        this.unfollowRequest = this.unfollowRequest.bind(this);
        this.clickEditorInfo = this.clickEditorInfo.bind(this);

        // Bind gallery click functions
        this.overviewNextMediaClick = this.overviewNextMediaClick.bind(this);
        this.overviewPrevMediaClick = this.overviewPrevMediaClick.bind(this);
        this.overviewNavDotClick = this.overviewNavDotClick.bind(this);
        this.todosNextMediaClick = this.todosNextMediaClick.bind(this);
        this.todosPrevMediaClick = this.todosPrevMediaClick.bind(this);
        this.todosNavDotClick = this.todosNavDotClick.bind(this);
        this.historyNextMediaClick = this.historyNextMediaClick.bind(this);
        this.historyPrevMediaClick = this.historyPrevMediaClick.bind(this);
        this.historyNavDotClick = this.historyNavDotClick.bind(this);
        this.storiesNextMediaClick = this.storiesNextMediaClick.bind(this);
        this.storiesPrevMediaClick = this.storiesPrevMediaClick.bind(this);
        this.storiesNavDotClick = this.storiesNavDotClick.bind(this);

        // Bind curation expand functions
        this.toggleOverviewExpanded = this.toggleOverviewExpanded.bind(this);
        this.toggleTodosExpanded = this.toggleTodosExpanded.bind(this);
        this.toggleHistoryExpanded = this.toggleHistoryExpanded.bind(this);
        this.toggleStoriesExpanded = this.toggleStoriesExpanded.bind(this);

        // Bind like / save / share / more button functions
        this.likeButtonClick = likeButtonClick.bind(this);
        this.saveButtonClick = saveButtonClick.bind(this);
        this.shareButtonClick = this.shareButtonClick.bind(this);
        this.moreButtonClick = this.moreButtonClick.bind(this);

        // Bind curation stylize functions
        this.updateCurationStyle = updateCurationStyle.bind(this);
        this.updateCurationLayout = this.updateCurationLayout.bind(this);
        this.curationCapitalize = curationCapitalize.bind(this);
        this.curationPartition = curationPartition.bind(this);
        this.removeFirstSpace = removeFirstSpace.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        // Static map drag
        this.staticMapDragClick = this.staticMapDragClick.bind(this);
    }


    /*
    ============================================================================================
        Update State from New Props
    ============================================================================================
    */

    /*
    static getDerivedStateFromProps(nextProps, prevState) {
    }
    */


    /*
    ============================================================================================
        Main Render Function
    ============================================================================================
    */
    render() {
        // Only when dot info is ready
        if (this.state.dotInfo) {
            // Common dot flag
            const commonDot = this.state.dotInfo.type === "EV" || this.state.dotInfo.type === "AU";

            // Determine if user is logged in
            const loggedIn = (!!localStorage.token && this.props.userInfo !== null);
            const isAuthor = (loggedIn && this.props.userInfo.id === this.state.dotInfo.editor.id);

            // Small layout
            const smallLayout = this.props.browserWidth <= 3;

            // Save limit
            const bucketedOut = (this.state.dotInfo.save_limit === null)? false :
                (
                    (this.state.dotInfo.saved_user_count >= this.state.dotInfo.save_limit)?
                        true : false
                );


            /*
            ============================================================================================
                Dot Stats
            ============================================================================================
            */

            // Rating
            let ratingTitle = null;
            let rating = null;
            let ratingCount = null;

            // Difficulty
            let difficultyTitle = null;
            let difficulty = null;
            let difficultyCount = null;

            if (!commonDot) {
                if (((this.state.dotInfo.dot_extension.rating !== null) && (this.state.dotInfo.dot_extension.rating > 0.0)) ||
                    ((this.state.dotInfo.rated_user_count >= this.numThresholdRatings) && (this.state.dotInfo.user_rating != null))) {
                    ratingTitle = (this.state.dotInfo.rated_user_count >= this.numThresholdRatings)?
                        "Rating" :
                        (
                            (this.props.browserWidth <= 6)?
                                "Rating" : "Editor Rating"
                        );

                    if ((this.state.dotInfo.rated_user_count >= this.numThresholdRatings) && (this.state.dotInfo.user_rating != null)) {
                        ratingCount= formatNumbers(this.state.dotInfo.rated_user_count).toString() + " Users";
                        rating = (
                            <div className = "dot-preview-numbers-container">
                                <div id = {
                                        (this.props.browserWidth <= 4)?
                                            "dot-preview-numbers-rating-mobile" :
                                            "dot-preview-numbers-rating"
                                    }
                                    className = {(this.props.colorMode === "day")?
                                        "font-cabin light-blue" : "font-cabin blue"}
                                >
                                    {Number(this.state.dotInfo.user_rating).toFixed(1)}
                                </div>
                                <div id = {
                                        (this.props.browserWidth <= 4)?
                                            "dot-preview-numbers-rating-count" :
                                            "dot-preview-numbers-rating-count"
                                    }
                                    className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                                >
                                    {ratingCount}
                                </div>
                            </div>
                        );
                    }
                    else {
                        rating = (
                            <div className = "dot-preview-numbers-container">
                                <div id = {
                                        (this.props.browserWidth <= 4)?
                                            "dot-preview-numbers-rating-mobile" :
                                            "dot-preview-numbers-rating"
                                    }
                                    className = {(this.props.colorMode === "day")?
                                        "font-cabin light-blue" : "font-cabin blue"}
                                >
                                    {Number(this.state.dotInfo.dot_extension.rating).toFixed(1)}
                                </div>
                            </div>
                        );
                    }
                }

                if (((this.state.dotInfo.dot_extension.difficulty !== null) && (this.state.dotInfo.dot_extension.difficulty > 0.0)) ||
                    ((this.state.dotInfo.difficultied_user_count >= this.numThresholdDifficulties) && (this.state.dotInfo.user_difficulty != null))) {
                    difficultyTitle = (this.state.dotInfo.difficultied_user_count >= this.numThresholdDifficulties)?
                        "Difficulty" :
                        (
                            (this.props.browserWidth <= 6)?
                                "Difficulty" : "Editor Difficulty"
                        );

                    if ((this.state.dotInfo.difficultied_user_count >= this.numThresholdDifficulties) && (this.state.dotInfo.user_difficulty != null)) {
                        difficultyCount= formatNumbers(this.state.dotInfo.difficultied_user_count).toString() + " Users";
                        difficulty = (
                            <div className = "dot-preview-numbers-container">
                                <div id = {
                                        (this.props.browserWidth <= 4)?
                                            "dot-preview-numbers-difficulty-mobile" :
                                            "dot-preview-numbers-difficulty"
                                    }
                                    className = {(this.props.colorMode === "day")?
                                        "font-cabin light-red" : "font-cabin red"}
                                >
                                    {Number(this.state.dotInfo.user_difficulty).toFixed(1)}
                                </div>
                                <div id = {
                                        (this.props.browserWidth <= 4)?
                                            "dot-preview-numbers-difficulty-count" :
                                            "dot-preview-numbers-difficulty-count"
                                    }
                                    className = {(this.props.colorMode === "day")? "lr4" : "r4"}
                                >
                                    {difficultyCount}
                                </div>
                            </div>
                        );
                    }
                    else {
                        difficulty = (
                            <div className = "dot-preview-numbers-container">
                                <div id = {
                                        (this.props.browserWidth <= 4)?
                                            "dot-preview-numbers-difficulty-mobile" :
                                            "dot-preview-numbers-difficulty"
                                    }
                                    className = {(this.props.colorMode === "day")?
                                        "font-cabin light-red" : "font-cabin red"}
                                >
                                    {Math.ceil(this.state.dotInfo.dot_extension.difficulty).toFixed(1)}
                                </div>
                            </div>
                        );
                    }
                }
            }

            /*
            // Completed users
            let completedUsers = null;
            let completedUsersTitle = null;
            if (this.state.dotInfo.completed_user_count > 0) {
                const completedUserProfilePicListProps = {
                    numProfilePics : this.numCompletedUserProfilePics,
                    usersInfo : this.state.dotInfo.completed_users_recent,
                    userCount : this.state.dotInfo.completed_user_count,
                    userTypeLabel : "Recently Visited",
                    userTypeLabelOn: false,
                    moreBlankOn: false,
                    classNamePrefix: "dot-preview",
                    classNameUserType: "completed-user",
                    index: 0
                };

                completedUsersTitle = (
                    <div className = "dot-preview-completed-users-title"
                        data-tip = "Test tooltip"
                        data-for = "dot-preview-completed-users-tooltip">
                        Recently Visited
                    </div>
                );

                completedUsers = (
                    <UserProfilePicList {...completedUserProfilePicListProps} />
                );
            }
            */


            /*
            ============================================================================================
                Like and save buttons
            ============================================================================================
            */

            let likeButton = null;
            let likeButtonTooltip = null;
            let saveButton = null;
            let saveButtonText = null;
            let saveButtonTooltip = null;
            let shareButton = null;
            let shareButtonTooltip = null;
            let moreButton = null;
            let moreButtonTooltip = null;

            if (loggedIn) {
                const likeButtonTooltipText = this.state.likedByMe? "Unlike" : "Like";
                const likeButtonTooltipID = "dot-like-button-tooltip";
                likeButtonTooltip = (window.touchOn)? null : (
                    <ReactTooltip
                        id = {likeButtonTooltipID}
                        className = "tooltip-s2"
                        type = "dark"
                        place = "bottom"
                        html={true}
                    />
                );

                const likeButtonClickProps = {
                    setState: this.setState,
                    likedByMe: this.state.likedByMe,
                    userInfo: this.props.userInfo,
                    dotInfo: this.state.dotInfo,
                    storeNewLike: this.props.storeNewLike,
                    storeUser: this.props.storeUser
                };

                const likeButtonImage = this.state.likedByMe?
                    (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/like-on-red.png") :
                            getStaticPath("/images/common/like-on-red.png")
                    ) : (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/like-off-black.png") :
                            getStaticPath("/images/common/like-off-white.png")
                    );

                const likeButtonID = this.state.likedByMe?
                    "dot-like-button-on" : "dot-like-button-off";

                likeButton = (isAuthor)? null : (
                    <div id = {likeButtonID}
                        className = "image-button-weak-s3"
                        style = {{ backgroundImage: likeButtonImage }}
                        onClick = {likeButtonClick.bind(this, likeButtonClickProps)}
                        data-tip = {likeButtonTooltipText}
                        data-for = {likeButtonTooltipID}
                    >
                    </div>
                )

                // Save button
                const saveButtonTooltipText = this.state.savedByMe? "Unbucket" : "Bucket This Dot";
                const saveButtonTooltipID = "dot-save-button-tooltip";
                saveButtonTooltip = (window.touchOn)? null : (
                    <ReactTooltip
                        id = {saveButtonTooltipID}
                        className = "tooltip-s2"
                        type = "dark"
                        place = "bottom"
                        html = {true}
                    />
                );

                const saveButtonClickProps = {
                    setState: this.setState,
                    savedByMe: this.state.savedByMe,
                    userInfo: this.props.userInfo,
                    dotInfo: this.state.dotInfo,
                    storeNewSave: this.props.storeNewSave,
                    storeUser: this.props.storeUser
                };

                const saveButtonImage = this.state.savedByMe?
                    (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/bucket-on-black.png") :
                            getStaticPath("/images/common/bucket-on-white.png")
                    ) : (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/bucket-off-black.png") :
                            getStaticPath("/images/common/bucket-off-white.png")
                    );

                const saveButtonID = this.state.savedByMe?
                    "dot-save-button-on" : "dot-save-button-off";

                saveButton = (isAuthor || (!this.state.savedByMe && bucketedOut))? null : (
                    <div id = {saveButtonID}
                        className = "image-button-weak-s2"
                        style = {{ backgroundImage: saveButtonImage }}
                        onClick = {this.saveButtonClick.bind(this, saveButtonClickProps)}
                        data-tip = {saveButtonTooltipText}
                        data-for = {saveButtonTooltipID}
                    >
                    </div>
                );

                if (!isAuthor && (this.state.savedByMe || !bucketedOut)) {
                    saveButtonText = this.state.savedByMe? (
                        <div id = "dot-save-button-text"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        >
                            {(smallLayout)? "Saved" : "Bucketed"}
                        </div>
                    ) : null;
                }
            }


            /*
            ============================================================================================
                Like and save counts
            ============================================================================================
            */

            // Counts
            const likeText = (this.state.likeCount === 1)? "Like" : "Likes";
            const likeCount = (this.state.likeCount > 0)?
            (
                <div id = "dot-like-count"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    {formatNumbers(this.state.likeCount).toString()} {likeText}
                </div>
            ) : null;

            /*
            const saveText = (this.state.saveCount === 1)? "Save" : "Saves";
            const saveCount = (this.state.saveCount > 0)?
            (
                <div id = "dot-save-count"
                    className = {(this.props.colorMode === "day")? "k4" : "w4"}
                >
                    {formatNumbers(this.state.saveCount).toString()} {saveText}
                </div>
            ) : null;
            */


            /*
            ============================================================================================
                Share Button
            ============================================================================================
            */

            const shareButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/share-off-black.png") :
                getStaticPath("/images/common/share-off-white.png");
            const shareButtonTooltipText = "Share";
            const shareButtonTooltipID = "dot-share-button-tooltip";

            shareButtonTooltip = (window.touchOn)? null : (
                <ReactTooltip
                    id = {shareButtonTooltipID}
                    className = "tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html = {true}
                />
            );

            const shareButtonID = "dot-share-button";

            shareButton = (isAuthor)? null : (
                <div id = {shareButtonID}
                    className = "image-button-weak-s2"
                    onClick = {this.shareButtonClick}
                    style = {{ backgroundImage:  shareButtonImage }}
                    data-tip = {shareButtonTooltipText}
                    data-for = {shareButtonTooltipID}
                >
                </div>
            );


            /*
            ============================================================================================
                More Button
            ============================================================================================
            */

            const moreButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/more-black.png") :
                getStaticPath("/images/common/more-white.png");

            const moreButtonTooltipText = "Options";
            const moreButtonTooltipID = "dot-more-button-tooltip";

            moreButtonTooltip = (window.touchOn)? null : (
                <ReactTooltip
                    id = {moreButtonTooltipID}
                    className = "tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html = {true}
                />
            );

            moreButton = (isAuthor)?
            (
                <div id = "dot-more-button"
                    className = "image-button-base"
                    onClick = {() => { this.moreButtonClick(isAuthor); }}
                    style = {{ backgroundImage:  moreButtonImage }}
                    data-tip = {moreButtonTooltipText}
                    data-for = {moreButtonTooltipID}
                >
                </div>
            ) : null;


            /*
            ============================================================================================
                Save bucket
            ============================================================================================
            */
            const saveBucket = (loggedIn)?
            (
                <SaveBucket
                    userInfo = {this.props.userInfo}
                    setState = {this.setState}
                    history={this.props.history}
                />
            ) : null;


            /*
            ============================================================================================
                Static Map Drag Button
            ============================================================================================
            */

            // Drag image
            const dragImage = (this.state.dragOn)?
                getStaticPath("/images/common/unlock-white.png") :
                getStaticPath("/images/common/lock-white.png");

            const dragButton = (window.touchOn)?
                (
                    <div className = "map-static-drag-button image-button-s1"
                        style = {{ backgroundImage: dragImage }}
                        onClick = {this.staticMapDragClick}
                    >
                    </div>
                ) : null;


            /*
            ============================================================================================
                Map
            ============================================================================================
            */

            let map = null;

            if (this.state.savedByMe || !bucketedOut) {
                // Using Google maps
                if (this.mapSource === "google") {
                    // Only when google object and locations are ready
                    if ((this.props.google) && (this.state.dotInfo)) {
                        // Set the props for Map component
                        const mapProps = {
                            // Google
                            google: this.props.google,

                            // Map mode
                            mapMode: this.mapMode,

                            // DOM node IDs
                            mapNodeID: this.mapNodeID,
                            mapContainerNodeID : this.mapContainerNodeID,
                            buttonsNodeID: this.mapButtonsNodeID,
                            startInputNodeID: null,
                            endInputNodeID: null,

                            // Map height
                            mapHeight: this.mapHeight,
                            mapMinHeight: this.mapMinHeight,
                            mapMaxHeight: this.mapMaxHeight,
                            mapHeightIncrement: this.mapHeightIncrement,

                            // Dots
                            dotsInfo: [ this.state.dotInfo ],

                            // Itinerary
                            itinerary: [ 0 ],

                            // Selected / hovered / selectedChild / hoveredChild / displayChildren
                            selected: 0,
                            hovered: null,
                            selectedChild: null,
                            hoveredChild: null,
                            displayChildren: null,

                            // Parking / start / end locations
                            parkingLocation: this.state.dotInfo.parking_location,
                            startLocation: null,
                            endLocation: null,

                            // Map zoom / center / type
                            mapZoom: this.state.dotInfo.map_zoom,
                            mapCenter: this.state.dotInfo.map_center,
                            mapType: (this.state.dotInfo.map_type === null)?
                                "hybrid" : this.state.dotInfo.map_type
                        }
                        //console.log("Dot / render - mapProps = ", mapProps);

                        // Get the Map component
                        map = (<GoogleMap {...mapProps}/>);
                    }
                }
                // Using Open Street Maps
                else if (this.mapSource === "open" || (this.mapSource === "static" && this.state.dotInfo.map_media === null)) {
                    if (this.state.dotInfo != null) {
                        // Set the props for Map component
                        const mapProps = {
                            // Map mode
                            mapMode: this.mapMode,

                            // DOM node IDs
                            mapNodeID: this.mapNodeID,

                            // Map height
                            mapHeight: this.mapHeight,
                            mapMinHeight: this.mapMinHeight,
                            mapMaxHeight: this.mapMaxHeight,
                            mapHeightIncrement: this.mapHeightIncrement,

                            // Dots
                            dotsInfo: [ this.state.dotInfo ],

                            // Itinerary
                            itinerary: [ 0 ],

                            // Selected / hovered / selectedChild / hoveredChild / displayChildren
                            selected: 0,
                            hovered: null,
                            selectedChild: null,
                            hoveredChild: null,
                            displayChildren: null,

                            // Parking / start / end locations
                            parkingLocation: this.state.dotInfo.parking_location,
                            startLocation: null,
                            endLocation: null,

                            // Map zoom / center / type
                            mapZoom: this.state.dotInfo.map_zoom,
                            mapCenter: this.state.dotInfo.map_center,
                            mapType: (this.state.dotInfo.map_type === null)?
                                "hybrid" : this.state.dotInfo.map_type,

                            // SetState
                            setState: this.setState
                        }
                        //console.log("Dot / render - mapProps = ", mapProps);

                        // Get the Map component
                        map = (<OpenMap {...mapProps}/>);
                    }
                }
                else if (this.mapSource === "static" && this.state.dotInfo.map_media !== null) {
                    // Media URL
                    const mapMediaURL = getMediaProperty(this.state.dotInfo.map_media, 'o', "url", true);
                    const mapMarkerImage = getStaticPath("/images/map/dot-marker-black.png");

                    // Translation
                    const dx = this.displacementRatio * Number(this.state.dotInfo.map_displacement[0]);
                    const dy = this.displacementRatio * Number(this.state.dotInfo.map_displacement[1]);
                    const transform = "translate(" + dx.toString() + "px, " + dy.toString() + "px)";

                    map = (
                        <Draggable
                            dragOn = {this.state.dragOn}
                            translateXLimit = {10}
                            translateYLimit = {210}
                            labelHeight = {30}
                        >
                            <div id = "dot-map-static"
                                className = "map-static"
                                style = {{
                                    backgroundImage: mapMediaURL,
                                    width: 720,
                                    height: 720
                                }}
                            >
                                <div id = "dot-map-marker"
                                    className = "map-static-marker map-static-marker-pulse"
                                    style = {{
                                        backgroundImage: mapMarkerImage,
                                        transform: transform
                                    }}
                                >
                                </div>
                            </div>
                        </Draggable>
                    );
                }
            }


            /*
            ============================================================================================
                Effective browser width
            ============================================================================================
            */

            const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.marginWidth;


            /*
            ============================================================================================
                Overview gallery
            ============================================================================================
            */

            let overviewGallery = null;
            let overviewGalleryDimensions = null;
            if (this.state.mediaOverview.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaOverview[this.state.overviewSelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaOverview[this.state.overviewSelectedMediaIndex].type === "video");

                // Gallery dimensions
                overviewGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo,
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth,
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: this.maxContentWidth
                });

                // Gallery props
                const overviewGalleryProps = Object.assign(
                    overviewGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "dot-overview",
                        classPrefix: "dot-overview",
                        selectedMediaIndex: this.state.overviewSelectedMediaIndex,
                        media: this.state.mediaOverview,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.overviewNextMediaClick,
                        prevMediaClick: this.overviewPrevMediaClick,
                        navDotClick: this.overviewNavDotClick,
                        index: null,
                        square: false,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.dotInfo,
                        tagMode: "save"
                    }
                );

                overviewGallery = (
                    <Gallery {...overviewGalleryProps} />
                );
            }


            /*
            ============================================================================================
                Todos gallery
            ============================================================================================
            */

            let todosGallery = null;
            if (this.state.mediaTodos.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaTodos[this.state.todosSelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaTodos[this.state.todosSelectedMediaIndex].type === "video");

                // Gallery dimensions
                const todosGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo,
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth,
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: null
                });

                // Gallery props
                const todosGalleryProps = Object.assign(
                    todosGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "dot-todos",
                        classPrefix: "dot-todos",
                        selectedMediaIndex: this.state.todosSelectedMediaIndex,
                        media: this.state.mediaTodos,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.todosNextMediaClick,
                        prevMediaClick: this.todosPrevMediaClick,
                        navDotClick: this.todosNavDotClick,
                        index: null,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.dotInfo,
                        tagMode: "save"
                    }
                );

                todosGallery = (
                    <Gallery {...todosGalleryProps} />
                );
            }


            /*
            ============================================================================================
                History gallery
            ============================================================================================
            */

            let historyGallery = null;
            if (this.state.mediaHistory.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaHistory[this.state.historySelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaHistory[this.state.historySelectedMediaIndex].type === "video");

                // Gallery dimensions
                const historyGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo,
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth,
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: null
                });

                // Gallery props
                const historyGalleryProps = Object.assign(
                    historyGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "dot-history",
                        classPrefix: "dot-history",
                        selectedMediaIndex: this.state.historySelectedMediaIndex,
                        media: this.state.mediaHistory,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.historyNextMediaClick,
                        prevMediaClick: this.historyPrevMediaClick,
                        navDotClick: this.historyNavDotClick,
                        index: null,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.dotInfo,
                        tagMode: "save"
                    }
                );

                historyGallery = (
                    <Gallery {...historyGalleryProps} />
                );
            }


            /*
            ============================================================================================
                Stories gallery
            ============================================================================================
            */

            let storiesGallery = null;
            if (this.state.mediaStories.length > 0) {
                // Get the image dimensions for the right size (small size)
                const mediaInfo = this.state.mediaStories[this.state.storiesSelectedMediaIndex];
                const mediaWidth = getMediaProperty(mediaInfo, "s", 'width',false);
                const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

                // Is video
                const isVideo = (this.state.mediaStories[this.state.storiesSelectedMediaIndex].type === "video");

                // Gallery dimensions
                const storiesGalleryDimensions = mediaDimensions({
                    colorMode: this.props.colorMode,
                    effectiveBrowserWidth: effectiveBrowserWidth,
                    isVideo: isVideo,
                    mediaArea: this.mediaArea,
                    mediaWidth: mediaWidth,
                    mediaHeight: mediaHeight,
                    minAspectRatio: this.minAspectRatio,
                    maxAspectRatio: this.maxAspectRatio,
                    minContentWidth: this.minContentWidth,
                    scaleContentWidth: this.scaleContentWidth,
                    maxContentWidth: null
                });

                // Gallery props
                const storiesGalleryProps = Object.assign(
                    storiesGalleryDimensions,
                    {
                        // General
                        id: "",
                        idPrefix: "dot-stories",
                        classPrefix: "dot-stories",
                        selectedMediaIndex: this.state.storiesSelectedMediaIndex,
                        media: this.state.mediaStories,
                        size: "s",
                        startPlaying: true,
                        checkScroll: this.state.checkScroll,
                        nextMediaClick: this.storiesNextMediaClick,
                        prevMediaClick: this.storiesPrevMediaClick,
                        navDotClick: this.storiesNavDotClick,
                        index: null,
                        muted: false,

                        // Interaction buttons
                        userTagOn: true,
                        saved: this.state.savedByMe,
                        dotTagOn: false,
                        unsaveOn: false,
                        enlargeOn: true,
                        dotInfo: this.state.dotInfo,
                        tagMode: "save"
                    }
                );

                storiesGallery = (
                    <Gallery {...storiesGalleryProps} />
                );
            }


            /*
            ============================================================================================
                Layout
            ============================================================================================
            */

            const narrowLayout = (this.props.browserWidthPixels < overviewGalleryDimensions.finalMediaWidth + this.editorColumnWidth + this.statsColumnWidth + 2 * this.marginWidth);


            /*
            ============================================================================================
                Editor
            ============================================================================================
            */

            // Editor information
            const editorImage = (this.state.dotInfo.editor.profile_pic)?
                (
                    (this.state.dotInfo.editor.profile_pic.external_url === null)?
                        getMediaProperty(this.state.dotInfo.editor.profile_pic, "t", "url", true) :
                        url(this.state.dotInfo.editor.profile_pic.external_url)
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/no-profile-picture-day.png") :
                        getStaticPath("/images/common/no-profile-picture-night.png")
                );

            const chatButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/user-chat-black.png") :
                getStaticPath("/images/common/user-chat-white.png");

            const calendarButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/user-calendar-black.png") :
                getStaticPath("/images/common/user-calendar-white.png");

            const infoButtonImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/user-info-black.png") :
                getStaticPath("/images/common/user-info-white.png");

            // Follow button / editor buttons
            let followButton = null;
            let editorButtons = null;

            if (loggedIn) {
                // Logged in user is same as the editor
                //console.log("token = ", localStorage.token);

                if (this.props.userInfo.id === this.state.dotInfo.editor.id) {
                    //console.log("Logged in user is the same as the editor");
                }
                // Logged in user is different than the editor
                else {
                    const editorFollowButtonID = this.state.followedByMe?
                        "dot-preview-editor-follow-button-on" :
                        "dot-preview-editor-follow-button-off";

                    const editorFollowButtonText = this.state.followedByMe?
                        "Following" : "Follow";

                    const editorFollowButtonClick = this.state.followedByMe?
                        this.unfollowRequest : this.followRequest;

                    followButton = (
                        <div id = {editorFollowButtonID}
                            className = {(this.props.colorMode === "day")?
                                "button-light-blue-s3" : "button-blue-s3"}
                            onClick = {editorFollowButtonClick}>
                            {editorFollowButtonText}
                        </div>
                    );


                    /*
                    ============================================================================================
                        Editor"s Buttons
                    ============================================================================================
                    */

                    const editorInfoTriangleImage = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/user-info-triangle-day.png") :
                        getStaticPath("/images/common/user-info-triangle-night.png");

                    const editorChatButtonID = this.state.editorChatButtonOn?
                        "dot-preview-editor-chat-button-on" :
                        "dot-preview-editor-chat-button-off";

                    const editorCalendarButtonID = this.state.editorCalendarButtonOn?
                        "dot-preview-editor-calendar-button-on" :
                        "dot-preview-editor-calendar-button-off";

                    const editorInfoButtonID = this.state.editorInfoButtonOn?
                        "dot-preview-editor-info-button-on" :
                        "dot-preview-editor-info-button-off";

                    editorButtons = (
                        <div id = {(narrowLayout)?
                            "dot-preview-editor-buttons-container-mobile" :
                            "dot-preview-editor-buttons-container"}
                        >
                            <div id = "dot-preview-editor-info-triangle"
                                style = {{
                                    display: this.state.editorInfoOn? "inline-block" : "none",
                                    backgroundImage: editorInfoTriangleImage
                                }}>
                            </div>
                            <div id = "dot-preview-editor-buttons">
                                <div id = {editorChatButtonID}
                                    className = "image-button-weak-base"
                                    style = {{ backgroundImage:  chatButtonImage }}
                                    data-tip = "Send a Message"
                                    data-for = "dot-preview-editor-chat-button-tooltip"
                                >
                                </div>
                                {
                                    (window.touchOn)? null :
                                    (
                                        <ReactTooltip
                                            id = "dot-preview-editor-chat-button-tooltip"
                                            className = "tooltip-s2"
                                            type = "dark"
                                            place = "bottom"
                                        />
                                    )
                                }
                                <div id = {editorCalendarButtonID}
                                    className = "image-button-weak-base"
                                    style = {{ backgroundImage:  calendarButtonImage }}
                                    data-tip = "Book a Tour"
                                    data-for = "dot-preview-editor-calendar-button-tooltip"
                                >
                                </div>
                                {
                                    (window.touchOn)? null :
                                    (
                                        <ReactTooltip
                                            id = "dot-preview-editor-calendar-button-tooltip"
                                            className = "tooltip-s2"
                                            type = "dark"
                                            place = "bottom"
                                        />
                                    )
                                }
                                <div id = {editorInfoButtonID}
                                    className = "image-button-weak-base"
                                    style = {{ backgroundImage:  infoButtonImage }}
                                    data-tip = "Curator Information"
                                    data-for = "dot-preview-editor-info-button-tooltip"
                                    onClick = {this.clickEditorInfo}
                                >
                                </div>
                                {
                                    (window.touchOn)? null :
                                    (
                                        <ReactTooltip
                                            id = "dot-preview-editor-info-button-tooltip tooltip-s2"
                                            className = "tooltip-s2"
                                            type = "dark"
                                            place = "bottom"
                                        />
                                    )
                                }
                            </div>
                        </div>
                    );
                }
            }


            /*
            ============================================================================================
                Editor Information
            ============================================================================================
            */

            const editorInfo = (this.state.editorInfoOn)?
            (
                <div id = "dot-preview-editor-info"
                    className = {(this.props.colorMode === "day")?
                        "dot-preview-editor-info-day" :
                        "dot-preview-editor-info-night"}
                >
                    <div id = "dot-preview-editor-info-published"
                        className = {(this.props.colorMode === "day")?
                            "dot-preview-editor-info-published-day k4" :
                            "dot-preview-editor-info-published-night w4"}
                    >
                        Published
                    </div>
                    <div id = "dot-preview-editor-info-published-dots">
                        <span className = {(this.props.colorMode === "day")?
                            "dot-preview-editor-info-published-number number-lb0" :
                            "dot-preview-editor-info-published-number number-b0"}
                        >
                            {this.state.dotInfo.editor.editing_dot_count}
                        </span>
                        <span className = {(this.props.colorMode === "day")?
                            "dot-preview-editor-info-published-title dg4" :
                            "dot-preview-editor-info-published-title g4"}
                        >
                            {" dots"}
                         </span>
                    </div>
                    <div id = "dot-preview-editor-info-published"
                        className = {(this.props.colorMode === "day")?
                            "dot-preview-editor-info-published-day k4" :
                            "dot-preview-editor-info-published-night w4"}
                    >
                        Guided
                    </div>
                </div>
            ) : null;


            /*
            ============================================================================================
                Contributors and Helpers
            ============================================================================================
            */

            let staff = null;
            if (this.props.browserWidth > 4) {
                if ((this.state.dotInfo.contributor_count - 1) > 0) {
                    const contributorProfilePicListProps = {
                        colorMode: this.props.colorMode,
                        numProfilePics : this.numStaffProfilePics,
                        usersInfo : this.state.dotInfo.contributors_best,
                        userCount : this.state.dotInfo.contributor_count - 1,
                        userTypeLabel : "Contributor",
                        userTypeLabelOn: true,
                        moreBlankOn: false,
                        classNamePrefix: "dot-preview",
                        classNameUserType: "contributor",
                        index: 0
                    };

                    if (this.state.dotInfo.helper_count > 0 && !narrowLayout) {
                        const helperListProps = {
                            colorMode: this.props.colorMode,
                            numProfilePics : this.numStaffProfilePics,
                            usersInfo : this.state.dotInfo.helpers_best,
                            userCount : this.state.dotInfo.helper_count,
                            userTypeLabel : "Helper",
                            andOn: true,
                            classNamePrefix: "dot-preview",
                            classNameUserType: "helper",
                            index: 0
                        };

                        staff = (
                            <div id = "dot-preview-staff">
                                <UserProfilePicList {...contributorProfilePicListProps} />
                                <div id = "dot-preview-staff-spacer"></div>
                                <UserSimpleList {...helperListProps} />
                            </div>
                        );
                    }
                    else {
                        staff = (
                            <div id = "dot-preview-staff">
                                <UserProfilePicList {...contributorProfilePicListProps} />
                            </div>
                        );
                    }
                }
                else {
                    if (this.state.dotInfo.helper_count > 0) {
                        const helperProfilePicListProps = {
                            colorMode: this.props.colorMode,
                            numProfilePics : this.numStaffProfilePics,
                            usersInfo : this.state.dotInfo.helpers_best,
                            userCount : this.state.dotInfo.helper_count,
                            userTypeLabel : "Helper",
                            userTypeLabelOn: true,
                            moreBlankOn: false,
                            classNamePrefix: "dot-preview",
                            classNameUserType: "helper",
                            index: 0
                        };

                        staff = (
                            <div id = "dot-preview-staff">
                                <UserProfilePicList {...helperProfilePicListProps} />
                            </div>
                        );
                    }
                }
            }


            /*
            ============================================================================================
                Curation Layouts
            ============================================================================================
            */

            const overviewClassName = (this.state.overviewVariableHeight)?
            (
                (this.state.overviewExpanded)?
                    "dot-preview-overview-container-expanded" :
                    "dot-preview-overview-container-folded"
            ) : (
                "dot-preview-overview-container"
            );

            const overviewStyle = (this.state.overviewVariableHeight && !this.state.overviewExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}

            const todosClassName = (this.state.todosVariableHeight)?
            (
                (this.state.todosExpanded)?
                    "dot-preview-todos-container-expanded" :
                    "dot-preview-todos-container-folded"
            ) : (
                "dot-preview-todos-container"
            );

            const todosStyle = (this.state.todosVariableHeight && !this.state.todosExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}

            const historyClassName = (this.state.historyVariableHeight)?
            (
                (this.state.historyExpanded)?
                    "dot-preview-history-container-expanded" :
                    "dot-preview-history-container-folded"
            ) : (
                "dot-preview-history-container"
            );

            const historyStyle = (this.state.historyVariableHeight && !this.state.historyExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}

            const storiesClassName = (this.state.storiesVariableHeight)?
            (
                (this.state.storiesExpanded)?
                    "dot-preview-stories-container-expanded" :
                    "dot-preview-stories-container-folded"
            ) : (
                "dot-preview-stories-container"
            );

            const storiesStyle = (this.state.storiesVariableHeight && !this.state.storiesExpanded)?
            {
                height: this.curationHeight + this.curationMargin
            } : {}


            /*
            ============================================================================================
                Curation Expand Buttons
            ============================================================================================
            */

            const overviewExpandButtonImage = (this.state.overviewExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const overviewExpandButton = (this.state.overviewVariableHeight)? (
                <div className = {(this.state.overviewExpanded)?
                        "dot-preview-overview-container-more image-button-base" : 
                        "dot-preview-overview-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: overviewExpandButtonImage
                    }}
                    onClick = {this.toggleOverviewExpanded}
                >
                </div>
            ) : null;

            const todosExpandButtonImage = (this.state.todosExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const todosExpandButton = (this.state.todosVariableHeight)? (
                <div className = {(this.state.todosExpanded)?
                        "dot-preview-todos-container-more image-button-base" : 
                        "dot-preview-todos-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: todosExpandButtonImage
                    }}
                    onClick = {this.toggleTodosExpanded}
                >
                </div>
            ) : null;


            const historyExpandButtonImage = (this.state.historyExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const historyExpandButton = (this.state.historyVariableHeight)? (
                <div className = {(this.state.historyExpanded)?
                        "dot-preview-history-container-more image-button-base" : 
                        "dot-preview-history-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: historyExpandButtonImage
                    }}
                    onClick = {this.toggleHistoryExpanded}
                >
                </div>
            ) : null;

            const storiesExpandButtonImage = (this.state.storiesExpanded)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/less-info-black.png") :
                        getStaticPath("/images/common/less-info-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/more-info-black.png") :
                        getStaticPath("/images/common/more-info-white.png")
                );

            const storiesExpandButton = (this.state.storiesVariableHeight)? (
                <div className = {(this.state.storiesExpanded)?
                        "dot-preview-stories-container-more image-button-base" : 
                        "dot-preview-stories-container-less image-button-base"
                    }
                    style = {{
                        backgroundImage: storiesExpandButtonImage
                    }}
                    onClick = {this.toggleStoriesExpanded}
                >
                </div>
            ) : null;


            /*
            ============================================================================================
                Curation
            ============================================================================================
            */

            // Title
            const titleContent = (this.state.dotInfo.title == null)?
                null : this.state.dotInfo.title.replace(/[*]/g, "");

            // Curation flags
            const overviewOn = (this.state.dotInfo.overview !== null);

            const todosOn = (commonDot)? false : (
                (this.state.dotInfo.dot_extension.todos === null)?
                    false : true
            );
            const historyOn = (commonDot)? false : (
                (this.state.dotInfo.dot_extension.history === null)?
                    false : true
            );
            const storiesOn = (commonDot)? false : (
                (this.state.dotInfo.dot_extension.stories === null)?
                    false : true
            );

            const overviewContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.dotInfo,
                keyHeader: "dot-overview",
                type: "overview",
                classPrefix: "dot"
            }
            const todosContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.dotInfo,
                keyHeader: "dot-todos",
                type: "todos",
                classPrefix: "dot"
            }
            const historyContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.dotInfo,
                keyHeader: "dot-history",
                type: "history",
                classPrefix: "dot"
            }
            const storiesContentProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.state.dotInfo,
                keyHeader: "dot-stories",
                type: "stories",
                classPrefix: "dot"
            }

            const overviewContent = (overviewOn)?
                this.curationPartition(overviewContentProps) : null;

            const todosContent = (todosOn)?
                this.curationPartition(todosContentProps) : null;

            const historyContent = (historyOn)?
                this.curationPartition(historyContentProps) : null;

            const storiesContent = (storiesOn)?
                this.curationPartition(storiesContentProps) : null;

            let todos = null;
            if (!todosOn) {
                todos = (this.state.mediaTodos.length > 0)? (
                    <div className = "body-wide"
                        style = {{ paddingTop: this.gallerySpacing }}
                    >
                        {todosGallery}
                    </div>
                ) : null;
            }
            else {
                todos = (
                    <div className = "body-wide">
                        <div id = "dot-todos-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Todos
                        </div>
                        <div id = "dot-todos"
                            ref = {this.todosRef}
                            className = {todosClassName}
                            style = {todosStyle}
                        >
                            {todosContent}
                        </div>
                        {todosExpandButton}
                        {todosGallery}
                    </div>
                );
            }

            let history = null;
            if (!historyOn) {
                history = (this.state.mediaHistory.length > 0)? (
                    <div className = "body-wide"
                        style = {{ paddingTop: this.gallerySpacing }}
                    >
                        {historyGallery}
                    </div>
                ) : null;
            }
            else {
                history = (
                    <div className = "body-wide">
                        <div id = "dot-history-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            History
                        </div>
                        <div id = "dot-history"
                            ref = {this.historyRef}
                            className = {historyClassName}
                            style = {historyStyle}
                        >
                            {historyContent}
                        </div>
                        {historyExpandButton}
                        {historyGallery}
                    </div>
                );
            }

            let stories = null;
            if (!storiesOn) {
                stories = (this.state.mediaStories.length > 0)? (
                    <div className = "body-wide"
                        style = {{ paddingTop: this.gallerySpacing }}
                    >
                        {storiesGallery}
                    </div>
                ) : null;
            }
            else {
                stories = (
                    <div className = "body-wide">
                        <div id = "dot-stories-title"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            Stories
                        </div>
                        <div id = "dot-stories"
                            ref = {this.storiesRef}
                            className = {storiesClassName}
                            style = {storiesStyle}
                        >
                            {storiesContent}
                        </div>
                        {storiesExpandButton}
                        {storiesGallery}
                    </div>
                );
            }


            /*
            ============================================================================================
                Bottle
            ============================================================================================
            */
            const bottleProps = {
                colorMode: this.props.colorMode,
                dotID: this.state.dotInfo.id,
                usersInfo: this.state.dotInfo.noted_users_recent,
                usersPublic: this.state.dotInfo.noted_users_recent_public,
                userCount: this.state.dotInfo.noted_user_count,
                minBottleSize: this.minBottleSize,
                maxBottleSize: this.maxBottleSize,
                classNamePrefix: "dot"
            };

            const bottle = (this.state.dotInfo.noted_user_count > 0)? (
                <BottleProfilePicList {...bottleProps} />
            ) : null;


            /*
            ============================================================================================
                Board
            ============================================================================================
            */

            const board = (this.state.dotInfo !== null && this.state.commentsInfo !== null)?
            (
                <div id = "dot-board-container">
                    {
                        ((this.state.dotInfo.board.comment_count > 0) || (this.props.userInfo !== null))?
                        (
                            <div id = "dot-board-title"
                                className = {(this.props.colorMode === "day")? "k2" : "w2"}
                            >
                                Comments
                            </div>
                        ) : null
                    }
                    <Board
                        key = {"dot-" + this.state.dotInfo.id + "-board-" + this.state.dotInfo.board.id}
                        typePrefix = {"dot"}
                        boardID = {this.state.dotInfo.board.id}
                        userInfo = {this.props.userInfo}
                        loggedIn = {loggedIn}
                        commentCount = {this.state.dotInfo.board.comment_count}
                        commentsInfo = {this.state.commentsInfo}
                        webSocketGroupPrefix = {this.props.webSocketGroupPrefix}
                    />
                </div>
            ) : null;


            /*
            ============================================================================================
                Loader image
            ============================================================================================
            */

            // Loader image
            const loaderImage = (this.props.colorMode === "day")?
                getStaticPath("/images/loader/loader-white.gif") :
                getStaticPath("/images/loader/loader-black.gif");


            /*
            ============================================================================================
                Editor column
            ============================================================================================
            */

            const editorColumn = (narrowLayout)?
            (
                <div id = "dot-preview-left-mobile">
                    {editorInfo}
                    <div className = "dot-preview-left-mobile-column">
                        <Link key = {"dot-editor-profile-pic"}
                            to = {`/user/${this.state.dotInfo.editor.username}`}
                        >
                            <div id = {(this.props.browserWidth <= 4)?
                                    "dot-preview-editor-profile-pic-loader-small" :
                                    "dot-preview-editor-profile-pic-loader-medium"}
                                className = "image-loader-s4"
                                style = {{ backgroundImage: loaderImage }}
                            >
                                <div id = "dot-preview-editor-profile-pic"
                                    className = {(this.props.colorMode === "day")?
                                        "profile-image-base border-day" :
                                        "profile-image-base border-night"}
                                    style = {{ backgroundImage:  editorImage }}>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className = "dot-preview-left-mobile-column">
                        <div id = "dot-preview-editor-name"
                            className = {
                                (this.props.browserWidth <= 4)?
                                    (
                                        (this.props.colorMode === "day")? "k5" : "w5"
                                    ) : (
                                        (this.props.colorMode === "day")? "k4" : "w4"
                                    )
                            }
                        >
                            {
                                ((this.state.dotInfo.editor.name.length > this.nameMobileMaxLength) &&
                                (this.props.browserWidth <= 4))?
                                    this.state.dotInfo.editor.first_name :
                                    this.state.dotInfo.editor.name
                            }
                        </div>
                        <div id = "dot-preview-editor-follow-button-container">
                            {followButton}
                        </div>
                        {editorButtons}
                    </div>
                    <div className = "dot-preview-left-mobile-column">
                        {staff}
                    </div>
                </div>
            ) : (
                <div id = "dot-preview-left">
                    {editorInfo}
                    <div className = "dot-preview-editor">
                        <div id = "dot-preview-editor-title"
                            className = {(this.props.colorMode === "day")? "k3" : "w3"}
                        >
                            Editor
                        </div>
                        <Link key = {"dot-editor-profile-pic"}
                            to = {`/user/${this.state.dotInfo.editor.username}`}
                        >
                            <div id = "dot-preview-editor-profile-pic-loader"
                                className = "image-loader-s4"
                                style = {{ backgroundImage: loaderImage }}
                            >
                                <div id = "dot-preview-editor-profile-pic"
                                    className = {(this.props.colorMode === "day")?
                                        "profile-image-base border-day" :
                                        "profile-image-base border-night"}
                                    style = {{ backgroundImage:  editorImage }}>
                                </div>
                            </div>
                        </Link>
                        <div id = "dot-preview-editor-name"
                            className = {(this.props.colorMode === "day")? "k4" : "w4"}
                        >
                            {this.state.dotInfo.editor.name}
                        </div>
                        <div id = "dot-preview-editor-follow-button-container">
                            {followButton}
                        </div>
                        {editorButtons}
                    </div>
                    {staff}
                </div>
            );


            /*
            ============================================================================================
                Bucketed out
            ============================================================================================
            */

            let bucketedOutSign = null;

            if (!this.state.savedByMe && bucketedOut) {
                const bucketedOutImage = (smallLayout)?
                    (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/saved-out-day.png") :
                            getStaticPath("/images/common/saved-out-night.png")
                    ) : (
                        (this.props.colorMode === "day")?
                            getStaticPath("/images/common/bucketed-out-day.png") :
                            getStaticPath("/images/common/bucketed-out-night.png")
                    );

                bucketedOutSign = (
                    <div className = {(smallLayout)?
                            "dot-bucketed-out-small image-contain" :
                            "dot-bucketed-out image-contain"}
                        style = {{ backgroundImage: bucketedOutImage }}
                    >
                    </div>
                );
            }


            /*
            ============================================================================================
                Stats column
            ============================================================================================
            */

            const statsColumn = (narrowLayout)?
                (
                    (this.state.dotInfo.rating === null)?
                        null : (
                            <div id = "dot-preview-right-mobile">
                                <div className = "dot-preview-right-mobile-column">
                                    <div className = {
                                        (this.props.browserWidth <= 4)?
                                            (
                                                (this.props.colorMode === "day")?
                                                    "dot-preview-numbers-title k4" :
                                                    "dot-preview-numbers-title w4"
                                            ) : (
                                                (this.props.colorMode === "day")?
                                                    "dot-preview-numbers-title k3" :
                                                    "dot-preview-numbers-title w3"

                                            )
                                        }
                                        data-tip = "Rating"
                                        data-for = "dot-preview-numbers-rating-tooltip"
                                    >
                                        {ratingTitle}
                                    </div>
                                    {rating}
                                </div>
                                <div className = "dot-preview-right-mobile-column">
                                    <div className = {
                                        (this.props.browserWidth <= 4)?
                                            (
                                                (this.props.colorMode === "day")?
                                                    "dot-preview-numbers-title k4" :
                                                    "dot-preview-numbers-title w4"
                                            ) : (
                                                (this.props.colorMode === "day")?
                                                    "dot-preview-numbers-title k3" :
                                                    "dot-preview-numbers-title w3"

                                            )
                                        }
                                        data-tip = "Difficulty"
                                        data-for = "dot-preview-numbers-difficulty-tooltip"
                                    >
                                        {difficultyTitle}
                                    </div>
                                    {difficulty}
                                </div>
                            </div>
                        )
                ) : (
                    <div id = "dot-preview-right">
                        <div className = {(this.props.colorMode === "day")?
                                "dot-preview-numbers-title k3" :
                                "dot-preview-numbers-title w3"}
                            data-tip = "Rating"
                            data-for = "dot-preview-numbers-rating-tooltip"
                        >
                            {ratingTitle}
                        </div>
                        {rating}
                        <div className = {(this.props.colorMode === "day")?
                                "dot-preview-numbers-title k3" :
                                "dot-preview-numbers-title w3"}
                            data-tip = "Difficulty"
                            data-for = "dot-preview-numbers-difficulty-tooltip"
                        >
                            {difficultyTitle}
                        </div>
                        {difficulty}
                    </div>
                );


            /*
            ============================================================================================
                Interaction Box
            ============================================================================================
            */

            const interactionBox = (likeCount || likeButton || shareButton || saveButton || bucketedOutSign)? (
                <div id = "dot-interaction-box" className = "clear-fix">
                    <div id = "dot-like-share-container">
                        {likeCount}
                        {likeButton}
                        {likeButtonTooltip}
                        {shareButton}
                        {shareButtonTooltip}
                    </div>
                    <div id = "dot-save-container">
                        {saveButtonText}
                        {saveButton}
                        {saveButtonTooltip}
                        {bucketedOutSign}
                    </div>
                </div>
            ) : null;


            /*
            ============================================================================================
                Body
            ============================================================================================
            */

            return (
                <div id = "dot-container"
                    className = {(this.props.browserWidth <= window.browserWidthSmall)?
                        "dot-container-small" : "dot-container"}
                >
                    <MetaTags>
                        <title>{this.state.dotInfo.title}</title>
                        <meta name="title" content={this.state.dotInfo.title} />
                        <meta name="description" content={this.state.dotInfo.overview} />
                        <meta property="fb:app_id" content="1387370438053401" />
                        <meta property="og:title" content={this.state.dotInfo.title} />
                        <meta property="og:type" content="place" />
                        <meta property="og:description" content={this.state.dotInfo.overview} />
                        <meta property="og:image" content={getMediaProperty(this.state.dotInfo.media[0], 'xs', 'url', true)} />
                        <meta property="og:image:width" content={getMediaProperty(this.state.dotInfo.media[0], 'xs', 'width', false)} />
                        <meta property="og:image:height" content={getMediaProperty(this.state.dotInfo.media[0], 'xs', 'height', false)} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="twitter:title" content={this.state.dotInfo.title} />
                        <meta property="twitter:description" content={this.state.dotInfo.overview} />
                        <meta property="twitter:image:src" content={getMediaProperty(this.state.dotInfo.media[0], 'xs', 'url', true)} />
                    </MetaTags>
                    <div className = "body-wide" style = {{ overflow: "visible" }}>
                        {saveBucket}

                        <div id = "dot-preview">
                            {(narrowLayout)? null : editorColumn}

                            <div id = "dot-preview-middle"
                                style = {{ width: overviewGalleryDimensions.finalMediaWidth }}
                            >
                                <div id = "dot-preview-intro">
                                    <div id = "dot-preview-title-container">
                                        <div id = "dot-preview-title"
                                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                                        >
                                            {titleContent}
                                        </div>

                                        <div id ="dot-more-cotainer">
                                            {moreButton}
                                            {moreButtonTooltip}
                                        </div>

                                        <div id = "dot-preview-name"
                                            className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                                        >
                                            {this.state.dotInfo.name}
                                        </div>
                                    </div>
                                    <div id = "dot-preview-overview"
                                        ref = {this.overviewRef}
                                        className = {overviewClassName}
                                        style = {overviewStyle}
                                    >
                                        {overviewContent}
                                    </div>
                                    {overviewExpandButton}
                                </div>

                                {interactionBox}
                                {bottle}
                                {overviewGallery}
                            </div>
                            {
                                (narrowLayout)? (
                                    <div id = "dot-preview-narrow">
                                        {editorColumn}
                                        {statsColumn}
                                    </div>
                                    ) : statsColumn
                            }
                        </div>
                    </div>
                    {todos}
                    {history}
                    {stories}
                    {
                        (!this.state.savedByMe && bucketedOut)? null : (
                            <div className = "body-wide">
                                <div id = "dot-map-container">
                                    <div id = "dot-map-title"
                                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                                    >
                                        Map
                                    </div>
                                    {
                                        (this.mapSource === "static" && this.state.dotInfo.map_media !== null)?
                                        (
                                            <div id = "dot-map-static-wrapper"
                                                className = {(this.props.colorMode === "black")?
                                                    "border-day" : "border-night"}
                                            >
                                                {dragButton}
                                                {map}
                                            </div>
                                        ) : (
                                            <div id = "dot-map-wrapper"
                                                className = {(this.props.colorMode === "black")?
                                                    "border-day" : "border-night"}
                                            >
                                                {map}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                    <div className = "body-narrow">
                        {board}
                    </div>
                </div>
            )
        }
        else{
            return null
        }
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentWillUnmount() {
        // Remove event listeners
        window.removeEventListener("resize", this.updateWindowDimensions);
        window.removeEventListener("scroll", this.scrollListener);

        // Remove relevant webSocket group
        removeWebSocketGroups(this.state.webSocketGroup);
    }

    componentDidMount() {
        //console.log("Dot / componentDidMount - dotSlug = ", this.props.dotSlug);

        // Get dot info from the server
        this.loadDotInfo(this.props.dotSlug);

        // Update window dimensions
        this.updateWindowDimensions();

        // Add event listeners
        window.addEventListener("resize", this.updateWindowDimensions);
        window.addEventListener("scroll", this.scrollListener);
    }

    componentDidUpdate(prevProps, prevState) {
        //if ((this.props.dotSlug !== prevProps.dotSlug) || (JSON.stringify(this.props.userInfo) !== JSON.stringify(prevProps.userInfo))) {
        //console.log("Dot / componentDidUpdate - update required");

        // If need an update
        if (this.props.dotSlug !== prevProps.dotSlug) {
            // Remove relevant webSocket group
            removeWebSocketGroups(this.state.webSocketGroup);

            // Load new dot
            this.loadDotInfo(this.props.dotSlug);
        }

        // Update curation style when window resized
        if ((this.props.browserWidthPixels !== prevProps.browserWidthPixels) && (this.state.dotInfo !== null)) {
            this.updateCurationStyle(this.state.dotInfo, this.updateCurationLayout);
        }

        // Update save
        if ((this.props.newSave.id !== prevProps.newSave.id) ||
            ((this.props.newSave.id === prevProps.newSave.id) && (this.props.newSave.saved !== prevProps.newSave.saved))) {
            if (this.state.dotInfo.id === this.props.newSave.id) {
                this.setState({
                    savedByMe: this.props.newSave.saved,
                    saveCount: this.props.newSave.count
                });
            }
        }
    }


    /*
    ============================================================================================
        Update curation layout
    ============================================================================================
    */

    updateCurationLayout() {
        // Curation
        if (this.overviewRef.current !== null) {
            const overviewBoundingBox = this.overviewRef.current.getBoundingClientRect();
            //console.log("Dot / updateCurationLayout - overviewBoundingBox.height = ", overviewBoundingBox.height);
            //console.log("Dot / updateCurationLayout - this.curationHeight = ", this.curationHeight);

            if (overviewBoundingBox.height > this.curationHeight) {
                this.setState({
                    overviewVariableHeight: true
                });
            }
        }

        if (this.todosRef.current !== null) {
            const todosBoundingBox = this.todosRef.current.getBoundingClientRect();

            if (todosBoundingBox.height > this.curationHeight) {
                this.setState({
                    todosVariableHeight: true
                });
            }
        }

        if (this.historyRef.current !== null) {
            const historyBoundingBox = this.historyRef.current.getBoundingClientRect();

            if (historyBoundingBox.height > this.curationHeight) {
                this.setState({
                    historyVariableHeight: true
                });
            }
        }

        if (this.storiesRef.current !== null) {
            const storiesBoundingBox = this.storiesRef.current.getBoundingClientRect();

            if (storiesBoundingBox.height > this.curationHeight) {
                this.setState({
                    storiesVariableHeight: true
                });
            }
        }        
    }


    /*
    ============================================================================================
        Toggle curation
    ============================================================================================
    */

    toggleOverviewExpanded() {
        this.setState({
            overviewExpanded: !this.state.overviewExpanded
        });
    }

    toggleTodosExpanded() {
        this.setState({
            todosExpanded: !this.state.todosExpanded
        });
    }

    toggleHistoryExpanded() {
        this.setState({
            historyExpanded: !this.state.historyExpanded
        });
    }

    toggleStoriesExpanded() {
        this.setState({
            storiesExpanded: !this.state.storiesExpanded
        });
    }


    /*
    ============================================================================================
        Share and More Button Click Functions
    ============================================================================================
    */

    shareButtonClick() {
        this.props.storeShare({
            modalOn: true,
            type: "dot",
            info: this.state.dotInfo
        });
    }

    moreButtonClick(isAuthor) {
        this.props.storeMore({
            modalOn: true,
            type: "dot",
            info: this.state.dotInfo,
            isAuthor: isAuthor
        });
    }


    /*
    ============================================================================================
        Overview Gallery Navigation Functions
    ============================================================================================
    */

    overviewNextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                overviewSelectedMediaIndex: (this.state.overviewSelectedMediaIndex + 1),
            }
        );
    }

    overviewPrevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                overviewSelectedMediaIndex: (this.state.overviewSelectedMediaIndex - 1),
            }
        );
    }

    overviewNavDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            overviewSelectedMediaIndex: mediaIndex,
        });
    }


    /*
    ============================================================================================
        Todos Gallery Navigation Functions
    ============================================================================================
    */

    todosNextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                todosSelectedMediaIndex: (this.state.todosSelectedMediaIndex + 1),
            }
        );
    }

    todosPrevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                todosSelectedMediaIndex: (this.state.todosSelectedMediaIndex - 1),
            }
        );
    }

    todosNavDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            todosSelectedMediaIndex: mediaIndex,
        });
    }


    /*
    ============================================================================================
        Todos Gallery Navigation Functions
    ============================================================================================
    */

    historyNextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                historySelectedMediaIndex: (this.state.historySelectedMediaIndex + 1),
            }
        );
    }

    historyPrevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                historySelectedMediaIndex: (this.state.historySelectedMediaIndex - 1),
            }
        );
    }

    historyNavDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            historySelectedMediaIndex: mediaIndex,
        });
    }


    /*
    ============================================================================================
        Stories Gallery Navigation Functions
    ============================================================================================
    */

    storiesNextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                storiesSelectedMediaIndex: (this.state.storiesSelectedMediaIndex + 1),
            }
        );
    }

    storiesPrevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                storiesSelectedMediaIndex: (this.state.storiesSelectedMediaIndex - 1),
            }
        );
    }

    storiesNavDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            storiesSelectedMediaIndex: mediaIndex,
        });
    }

    /*
    ============================================================================================
        Load Dot Info
    ============================================================================================
    */

    loadDotInfo(dotSlug) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Dot / loadDotInfo - response.data.content = ", response.data.content);

            // Check if the logged in user follows the editor
            const followedByMe = localStorage.token? response.data.content.editor.followed_by_me : null;
            //console.log("Dot / loadDotInfo - followedByMe = ", followedByMe);

            // Check if the the dot is liked or saved by the user
            const likedByMe = localStorage.token? response.data.content.liked_by_me : null;
            const savedByMe = localStorage.token? response.data.content.saved_by_me : null;

            // Get the like and save counts
            const likeCount = response.data.content.liked_user_count;
            const saveCount = response.data.content.saved_user_count;

            // Initial button opacities
            const likeButtonOpacity = likedByMe?
                this.likeButtonOnOpacity: this.likeButtonOffOpacity;
            const saveButtonOpacity = savedByMe?
                this.saveButtonOnOpacity: this.saveButtonOffOpacity;

            // Sort media
            const mediaSorted = sortMedia(response.data.content);

            // Callback
            const callback = () => {
                // Update curation
                this.updateCurationStyle(response.data.content, this.updateCurationLayout);
            };

            // Fetch board comments
            this.loadCommentsInfo(response.data.content.board.id);

            // Add relevant webSocket group
            let webSocketGroup = [];
            webSocketGroup.push(this.webSocketGroupPrefix + "_" + response.data.content.board.id);
            addWebSocketGroups(webSocketGroup);

            // Set states and check if the logged in user follows the editor
            this.setState(
                {
                    dotInfo: response.data.content,
                    followedByMe: followedByMe,
                    followerCount: response.data.content.editor.follower_count,
                    likedByMe: likedByMe,
                    savedByMe: savedByMe,
                    likeCount: likeCount,
                    saveCount: saveCount,
                    saveButtonOpacity: saveButtonOpacity,
                    likeButtonOpacity: likeButtonOpacity,
                    mediaOverview: mediaSorted.overview,
                    mediaTodos: mediaSorted.todos,
                    mediaHistory: mediaSorted.history,
                    mediaStories: mediaSorted.stories,
                    webSocketGroup: webSocketGroup
                },
                callback
            );
        };

        getDot(dotSlug)
        .then(axiosCallback)
        .catch((response) => { console.log("Dot / loadDotInfo - Axios error ", response); });
    }


    /*
    ============================================================================================
        loadCommentsInfo
    ============================================================================================
    */

    loadCommentsInfo(boardID) {
        // console.log("Dot / loadCommentsInfo - boardID = ", boardID);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (commentsResponse) => {
            //console.log("Dot / loadCommentsInfo - commentsResponse.data = ", commentsResponse.data);
            this.setState(
                {
                    commentsInfo: commentsResponse.data.content
                }
            );
        };

        // Send post request using axios with CSRF token
        getSingleBoardComments(boardID, 0)
        .then(axiosCallback)
        .catch((response) => {console.log("Dot / loadCommentsInfo - Axios error ", response);});
    }


    /*
    ============================================================================================
        Follow Request
    ============================================================================================
    */

    followRequest() {
        //console.log("Dot / followRequest - this.props = ", this.props);

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("Dot / followRequest - response.data.content = ", response.data.content);

            // Update local and global state
            this.setState(
                {
                    followedByMe: response.data.content.followed_by_me,
                    followerCount: response.data.content.follower_count
                },
                () => {
                    // Construct a shallow copy of the userInfo
                    const userInfo = Object.assign({}, this.props.userInfo);

                    // Update user info
                    userInfo.following_recent = response.data.content.following_recent;
                    userInfo.following_count = response.data.content.following_count;

                    // Dispatch to Redux store
                    this.props.storeUser(userInfo);
                }
            );
        };

        // Send request
        postFollowRequest("create", this.state.dotInfo.editor.id)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Dot / followRequest - error = ", response);})
    }


    /*
    ============================================================================================
        Unfollow Request
    ============================================================================================
    */

    unfollowRequest() {
        //console.log("Dot / unfollowRequest - this.props = ", this.props);

        // Get user info and update states
        const axiosCallback = (response) => {
            //console.log("Dot / unfollowRequest - response.data.content = ", response.data.content);

            // Update local and global state
            this.setState(
                {
                    followedByMe: response.data.content.followed_by_me,
                    followerCount: response.data.content.follower_count
                },
                () => {
                    // Construct a shallow copy of the userInfo
                    const userInfo = Object.assign({}, this.props.userInfo);

                    // Update user info
                    userInfo.following_recent = response.data.content.following_recent;
                    userInfo.following_count = response.data.content.following_count;

                    // Dispatch to Redux store
                    this.props.storeUser(userInfo);
                }
            );
        };

        // Send request
        postFollowRequest("destroy", this.state.dotInfo.editor.id)
        .then(axiosCallback)
        .catch((response) => {console.log("[WARNING] Dot / unfollowRequest - error = ", response);})
    }


    /*
    ============================================================================================
        Editor information window click callback
    ============================================================================================
    */

    clickEditorInfo() {
        if (this.state.editorInfoOn) {
            this.setState({
                editorInfoButtonOn: false,
                editorInfoOn: false
            });
        }
        else {
            this.setState({
                editorInfoButtonOn: true,
                editorInfoOn: true
            });
        }
    }


    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }


    /*
    ============================================================================================
        Static Map Drag Click
    ============================================================================================
    */

    staticMapDragClick() {
        this.setState({ dragOn: !this.state.dragOn });
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        google: state.map.google,
        userInfo: state.user.userInfo,
        newSave: state.interaction.newSave
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeMore,
            storeNewLike,
            storeNewSave,
            storeShare
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dot);
