/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
	DotGroup
--------------------------------------------------------------------------------------------
    Content
    - SelectedSmall
    - OptionalSmall
    - Selected
    - Optional
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
//import { connect } from "react-redux";

// Modules
//import DatePicker from "react-datepicker";
//import ReactTooltip from "thedots-tooltip";
//import InputRange from "thedots-input-range";
import moment from "moment-timezone";

// Components
import { Dot, DotList } from "js/DotFunctions";
import { GoogleMap } from "components/Map";
import { Gallery, mediaDimensions } from "components/Gallery";
import { DotStats } from "components/DotStats";

// Functions
import {
	pad,
	getMediaProperty,
    sortMedia,
	getStaticPath,
    getDotCategory,
    getTransitImage,
    freezeBody,
    unfreezeBody
} from "js/Functions";

import {
    regularCuration
} from "js/Curation";

// CSS
import "./DotGroup.scss";


/*
============================================================================================
    SelectedSmall
--------------------------------------------------------------------------------------------
    - Selected items for trip planning in small layout
============================================================================================
*/

// SelectedSmall component
class SelectedSmall extends Component {
    constructor(props) {
        super(props);

        // Initialize state
        this.state = {
            hoveredDotIndex: null
        };

        // Bind functions
        this.setState = this.setState.bind(this);
        this.dotTouch = this.dotTouch.bind(this);
    }

    render() {
        // Print out
        //console.log("DotGroup / SelectedSmall / render - this.props.hovered = ", this.props.hovered);
        //console.log("DotGroup / SelectedSmall / render - this.props.selected = ", this.props.selected);

    	// Line images
    	const topLineImage = (this.props.colorMode === "day")?
    		getStaticPath("/images/line/vertical/solid-top-light.png") :
    		getStaticPath("/images/line/vertical/solid-top-dark.png");

    	const bottomLineImage = (this.props.colorMode === "day")?
    		getStaticPath("/images/line/vertical/solid-bottom-light.png") :
    		getStaticPath("/images/line/vertical/solid-bottom-dark.png");

    	const middleLineImage = (this.props.colorMode === "day")?
    		getStaticPath("/images/line/vertical/solid-middle-light.png") :
    		getStaticPath("/images/line/vertical/solid-middle-dark.png");

        // Start and end images
        const startImage = (this.props.colorMode === "day")?
            getStaticPath("/images/number/double_white_S.png") :
            getStaticPath("/images/number/double_black_S.png");
            //getStaticPath("/images/number/double_red_day_S.png") :
            //getStaticPath("/images/number/double_red_night_S.png");

        const endImage = (this.props.colorMode === "day")?
            getStaticPath("/images/number/double_white_E.png") :
            getStaticPath("/images/number/double_black_E.png");
            //getStaticPath("/images/number/double_red_day_E.png") :
            //getStaticPath("/images/number/double_red_night_E.png");

    	// Start and end items
    	const startTime = (this.props.dotIndexOn && this.props.startMoment !== null)?
    		this.props.startMoment.format("h:mm a") : null;
    	const endTime = (this.props.dotIndexOn && this.props.actualEndMoment !== null)?
    		this.props.actualEndMoment.format("h:mm a") : null;

    	// Get start transit image
    	const startTransitImage = (this.props.dotIndexOn && this.props.transitModes !== null)?
    		getTransitImage(this.props.transitModes[0], this.props.colorMode, true) : null;
    	const startTransitTime = (this.props.dotIndexOn && this.props.transitModes !== null)?
    		this.props.transitTimes[0] : null;

    	const start = (
        	<div key = {"plan-selected-small-start-item"}
        		className = "plan-selected-small-start-item"
        		style = {{
                    display: (this.props.dotIndexOn)? "block" : "none",
        			opacity: this.props.dotIndexOpacity
        		}}
        	>
        		<div className = "plan-selected-small-start-time"
        			style = {{
        				backgroundImage: topLineImage
        			}}
        		>
        			<div className = "plan-selected-small-number"
        				style = {{ backgroundImage: startImage }}
        			>
    	    			<div className = "plan-selected-small-timestamp">
    	    				{startTime}
    	    			</div>
        			</div>

        			<div className = "plan-selected-small-transit-start-container">
    	    			<div className = "plan-selected-small-transit">
    		    			<div className = "plan-selected-small-transit-mode"
    		    				style = {{ backgroundImage: startTransitImage }}
    		    			>
    		    			</div>
    		    			<div className = {(this.props.colorMode === "day")?
    		    				"plan-selected-small-transit-time k4" :
    		    				"plan-selected-small-transit-time w4"}
    		    			>
    		    				{startTransitTime}
    		    			</div>
    	    			</div>
    	    		</div>
        		</div>

        		<div className = {(this.props.colorMode === "day")?
        			"plan-selected-small-start-name k4" :
        			"plan-selected-small-start-name w4"}
        		>
        			{this.props.startLocationName}
        		</div>
        	</div>
    	);

    	const end = (
        	<div key = {"plan-selected-small-end-item"}
        		className = "plan-selected-small-end-item"
        		style = {{
        			display: (this.props.dotIndexOn)? "block" : "none",
        			opacity: this.props.dotIndexOpacity
        		}}
        	>
        		<div className = "plan-selected-small-end-time"
        			style = {{
        				backgroundImage: bottomLineImage
        			}}
        		>
        			<div className = "plan-selected-small-number"
        				style = {{ backgroundImage: endImage }}
        			>
    	    			<div className = "plan-selected-small-timestamp">
    	    				{endTime}
    	    			</div>
        			</div>
        		</div>

        		<div className = {(this.props.colorMode === "day")?
        			"plan-selected-small-end-name k4" :
        			"plan-selected-small-end-name w4"}
        		>
        			{this.props.endLocationName}
        		</div>
        	</div>
    	);

        // For all dots in the list
        const dotList = this.props.itinerary.map((dotIndex, index) => {
            //console.log("DotGroup / SelectedSmall / render - dotIndex = ", dotIndex);

            // Get the dot object
            const dot = this.props.dotsInfo[dotIndex];
            //console.log("DotGroup / SelectedSmall / render - dot = ", dot);

            // Common dot
            const commonDot = (dot.type === "EV" || dot.type === "AU");

            // Estimate closure
            let estimatedClosure = false;
            if (!commonDot) {
                if (dot.dot_extension.closure_start_date !== null) {
                    // Closure start date
                    const closureStartMomentRaw = moment(dot.dot_extension.closure_start_date);
                    const closureStartMomentPreviousYear = moment(
                        String(this.props.startMoment.year() - 1) + "-"
                        + String(pad(closureStartMomentRaw.month(), 2, 0)) + "-"
                        + String(pad(closureStartMomentRaw.date(), 2, 0))
                    );

                    const closureStartMomentThisYear = moment(
                        String(this.props.startMoment.year()) + "-"
                        + String(pad(closureStartMomentRaw.month(), 2, 0)) + "-"
                        + String(pad(closureStartMomentRaw.date(), 2, 0))
                    );

                    let closureStartMoment;
                    if (closureStartMomentThisYear < this.props.startMoment) {
                        closureStartMoment = closureStartMomentThisYear;
                    }
                    else {
                        closureStartMoment = closureStartMomentPreviousYear;
                    }

                    // Closure end date
                    const closureEndMomentRaw = moment(dot.dot_extension.closure_end_date);
                    const closureEndMomentSameYear = moment(
                        String(closureStartMoment.year()) + "-"
                        + String(pad(closureEndMomentRaw.month(), 2, 0)) + "-"
                        + String(pad(closureEndMomentRaw.date(), 2, 0))
                    );
                    const closureEndMomentNextYear = moment(
                        String(closureStartMoment.year() + 1) + "-"
                        + String(pad(closureEndMomentRaw.month(), 2, 0)) + "-"
                        + String(pad(closureEndMomentRaw.date(), 2, 0))
                    );

                    let closureEndMoment;
                    if (closureEndMomentSameYear > closureStartMoment) {
                        closureEndMoment = closureEndMomentSameYear;
                    }
                    else {
                        closureEndMoment = closureEndMomentNextYear;
                    }

                    /*
                    console.log("==============================================================================");
                    console.log("DotGroup / SelectedSmall / render - dot.name = ", dot.name);
                    console.log("DotGroup / SelectedSmall / render - closureStartMomentPreviousYear = ", closureStartMomentPreviousYear);
                    console.log("DotGroup / SelectedSmall / render - closureStartMomentThisYear = ", closureStartMomentThisYear);
                    console.log("DotGroup / SelectedSmall / render - closureStartMoment = ", closureStartMoment);
                    console.log("DotGroup / SelectedSmall / render - closureEndMomentSameYear = ", closureEndMomentSameYear);
                    console.log("DotGroup / SelectedSmall / render - closureEndMomentNextYear = ", closureEndMomentNextYear);
                    console.log("DotGroup / SelectedSmall / render - closureEndMoment = ", closureEndMoment);
                    console.log("DotGroup / SelectedSmall / render - this.props.startMoment = ", this.props.startMoment);
                    console.log("DotGroup / SelectedSmall / render - this.props.startMoment >= closureStartMoment = ", this.props.startMoment >= closureStartMoment);
                    console.log("DotGroup / SelectedSmall / render - this.props.startMoment <= closureEndMoment = ", this.props.startMoment <= closureEndMoment);
                    */

                    // Closure status
                    if ((this.props.startMoment >= closureStartMoment) &&
                    	(this.props.startMoment <= closureEndMoment)) {
                        estimatedClosure = true;
                    }
                }
            }

            // Compile imageURLs for the right size (medium size: "xs")
            const mediaURL = getMediaProperty(dot.media[0], "xs", "url", true);

            // If the dot is in the selection or in the options window
            const options = (index === -1)? true : false;

            // If the dot list is for selection window and need find dot animation
            let dotOpacity;
            if (options) {
                dotOpacity = 1.0;
            }
            // For options window
            else {
                dotOpacity = this.props.dotOpacities[index];
            }

            // Define the animation reference
            const ref = (dotNode) => { this.props.setDotNodes(index, dotNode); };

            // Dot shifts
            let dotContainerStyle = null;
            if ((this.props.dotShiftTops) && (this.props.dotShiftLefts)) {
                //console.log("DotGroup / SelectedSmall / render - index / dotShiftLeft / dotShiftTop = ", index, this.props.dotShiftLefts[index], this.props.dotShiftTops[index]);

                // Figure out the shifts
                if ((this.props.dotShiftLefts[index] !== 0) || (this.props.dotShiftTops[index] !== 0)) {
                    //console.log("DotGroup / SelectedSmall / render - index / dotShiftLeft / dotShiftTop = ", index, this.props.dotShiftLefts[index], this.props.dotShiftTops[index]);
                    dotContainerStyle = {
                        top: this.props.dotShiftTops[index],
                        left: this.props.dotShiftLefts[index]
                    }
                }
                else {
                    dotContainerStyle = null;
                }
            }

            // Selected
            const selected = (this.props.selected === dotIndex)? true: false;

            // Hovered
            const hovered = (this.props.hovered === dotIndex)? true: false;

            //  Hover callbacks
            const dotHoverOn = () => {
                this.props.dotHoverOn(dotIndex, dot, selected, true);
            };
            const dotHoverOff = () => {
                this.setState(
                    {
                        hoveredDotIndex: null
                    },
                    () => {this.props.dotHoverOff(dotIndex, selected, true);}
                );
            };

            /*
            // Add button
            const addButtonImage = getStaticPath("/images/common/add-white-shadow.png");
            const addButton = (hovered && this.props.customizeOn && !this.props.inItinerary && dot.is_open === true)? (
                <div className = "plan-selected-small-item-add-button image-button-s4"
                    style = {{
                        display: (hovered)? "block" : "none",
                        backgroundImage: addButtonImage
                    }}
                    onMouseEnter = {dotHoverOn}
                    onMouseLeave = {dotHoverOff}
                    onClick = {
                        (this.props.dotAddClick)?
                            (event) => {
                                this.props.dotAddClick(event, dotIndex, null);
                                dotHoverOff();
                                this.setState({ hoveredDotIndex: null });
                            } : () => {}
                    }
                >
                </div>
            ) : null;
            */

            // Remove button
            const removeButtonImage = getStaticPath("/images/common/remove-white-shadow.png");
            const removeButton = (this.props.customizeOn)? (
                <div className = "plan-selected-small-item-remove-button image-button-s4"
                    style = {{
                        display: (hovered)? "block" : "none",
                        backgroundImage: removeButtonImage
                    }}
                    onMouseEnter = {dotHoverOn}
                    onMouseLeave = {dotHoverOff}
                    onClick = {
                        (this.props.dotRemoveClick)?
                            (event) => {
                                this.props.dotRemoveClick(event, dotIndex, null);
                                dotHoverOff();
                                this.setState({ hoveredDotIndex: null });
                            } : () => {}
                    }
                >
                </div>
            ) : null;

            // Up button
            const upButtonImage = getStaticPath("/images/common/triangle-narrow-up-white.png");
            const upButtonClassName = (index === (this.props.itinerary.length - 1))?
                "plan-selected-small-item-lower-button image-button-s5" :
                "plan-selected-small-item-upper-button image-button-s5";
            const upButton = (this.props.customizeOn && index !== 0)? (
                <div className = {upButtonClassName}
                    style = {{
                        display: (hovered)? "block" : "none",
                        backgroundImage: upButtonImage
                    }}
                    onMouseEnter = {dotHoverOn}
                    onMouseLeave = {dotHoverOff}
                    onClick = {
                        (this.props.dotLeftClick)?
                            (event) => {
                                this.props.dotLeftClick(event, dotIndex, null);
                                dotHoverOff();
                                this.setState({ hoveredDotIndex: null });
                            } : () => {}
                    }

                >
                </div>
            ) : null;

            // Down button
            const downButtonImage = getStaticPath("/images/common/triangle-narrow-down-white.png");
            const downButton = (this.props.customizeOn && index !== (this.props.itinerary.length - 1))? (
                <div className = "plan-selected-small-item-lower-button image-button-s5"
                    style = {{
                        display: (hovered)? "block" : "none",
                        backgroundImage: downButtonImage
                    }}
                    onMouseEnter = {dotHoverOn}
                    onMouseLeave = {dotHoverOff}
                    onClick = {
                        (this.props.dotRightClick)?
                            (event) => {
                                this.props.dotRightClick(event, dotIndex, null);
                                dotHoverOff();
                                this.setState({ hoveredDotIndex: null });
                            } : () => {}
                    }
                >
                </div>
            ) : null;

            // Number image
            const numberImage = (this.props.colorMode === "day")?
                getStaticPath("/images/number/double_red_day_" + (index + 1) + ".png") :
                getStaticPath("/images/number/double_red_night_" + (index + 1) + ".png");
            //console.log("DotGroup / SelectedSmall / render - dotTimes = ", this.props.dotTimes);

            // Dot time
    		const dotTime = (this.props.dotIndexOn && this.props.dotTimes !== null)?
    			this.props.dotTimes[index] : null;

    		// Get start transit image
    		const transitImage = (this.props.dotIndexOn && this.props.transitModes !== null)?
    			getTransitImage(this.props.transitModes[index + 1], this.props.colorMode, true) : null;
    		const transitTime = (this.props.dotIndexOn && this.props.transitModes !== null)?
    			this.props.transitTimes[index + 1] : null;

    		// Is bottom
    		const isBottom = (index === this.props.itinerary.length - 1);

            // Return an individual item
            return (
            	<div key = {"plan-selected-small-item-" + index.toString()}
            		className = "plan-selected-small-item"
            	>
            		<div className = "plan-selected-small-dot-time"
    	    			style = {{
    	    				backgroundImage: (this.props.dotIndexOn)? middleLineImage : null,
                            opacity: this.props.dotIndexOpacity
    	    			}}
    	    		>
    	    			<div className = "plan-selected-small-number"
    	    				style = {{
    	    					backgroundImage: (this.props.dotIndexOn)? numberImage : null

    	    				}}
    	    			>
    		    			<div className = "plan-selected-small-timestamp">
    		    				{dotTime}
    		    			</div>
    	    			</div>

    	    			<div className = {(isBottom)?
    	    				"plan-selected-small-transit-end-container" :
    	    				"plan-selected-small-transit-container"}
    					>
    		    			<div className = "plan-selected-small-transit">
    			    			<div className = "plan-selected-small-transit-mode"
    			    				style = {{ backgroundImage: transitImage }}
    			    			>
    			    			</div>
    			    			<div className = {(this.props.colorMode === "day")?
    			    				"plan-selected-small-transit-time k4" :
    			    				"plan-selected-small-transit-time w4"}
    			    			>
    			    				{transitTime}
    			    			</div>
    		    			</div>
    		    		</div>
    	    		</div>

    	            <div className = {"plan-selected-small-dot "
    	            		+ this.props.dotConnectAnimationClass}
    	                ref = {ref}
    	                style = {dotContainerStyle}
    	            >
    	                <Dot
    	                    colorMode = {this.props.colorMode}

    	                    //key = {"dot-object-" + index.toString()}
    	                    index = {index}
    	                    dotIndex = {dotIndex}
    	                    dot = {dot}
    	                    selected = {selected}
    	                    hovered = {hovered}
    	                    dotOpacity = {dotOpacity}
    	                    dotIndexOn = {false}
    	                    //dotIndexOn = {this.props.dotIndexOn}
    	                    //dotIndexOpacity = {this.props.dotIndexOpacity}
    	                    mediaURL = {mediaURL}
    	                    options = {options}
    	                    dotClick = {this.dotTouch}
    	                    dotHoverOn = {this.props.dotHoverOn}
    	                    dotHoverOff = {this.props.dotHoverOff}
                            inItinerary = {true}
    	                    estimatedClosure = {estimatedClosure}
    	                />
    	                {removeButton}
    	                {upButton}
    	                {downButton}
    	            </div>
                </div>
            );
        });

    	return (
    		<div className = "plan-selected-small">
    			{start}
    			{dotList}
    			{end}
    		</div>
    	);
    }

    dotTouch(dotIndex, childIndex) {
        //console.log("dotTouch - dotIndex = ", dotIndex);
        //console.log("dotTouch - this.state.hoveredDotIndex = ", this.state.hoveredDotIndex);

        // For touch devices
        if (window.touchOn) {
            if (this.state.hoveredDotIndex === dotIndex) {
                //console.log("=======================================");
                //console.log("   dotTouch - execute click (second)");
                //console.log("=======================================");

                this.setState(
                    {
                        hoveredDotIndex: null
                    },
                    () => {
                        this.props.dotClick(dotIndex, childIndex);
                    }
                );
            }
            else {
                //console.log("---------------------------------------");
                //console.log("   dotTouch - hover click (first)");
                //console.log("---------------------------------------");

                this.setState(
                    {
                        hoveredDotIndex: dotIndex
                    },
                    () => {
                        this.props.dotHoverOn(
                            dotIndex,
                            this.props.dotsInfo[dotIndex],
                            (this.props.selected === dotIndex),
                            true
                        );
                    }
                );
            }
        }
        // For regular devices
        else {
            this.props.dotClick(dotIndex, childIndex);
        }
    }
}


/*
============================================================================================
    Dot Preview
--------------------------------------------------------------------------------------------
    - Preview dot information
============================================================================================
*/

class DotPreview extends Component {
    constructor(props) {
        super(props);

        // Map Mode
        this.mapSource = "google";
        this.mapMode = "itinerary";

        // Map settings
        this.mapZoom = 10;
        this.mapHeight = 300;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // Map DOM IDs
        this.mapNodeID = "plan-dot-preview-map";
        this.mapContainerNodeID = "plan-dot-preview-map-container";
        this.mapButtonsNodeID = "plan-dot-preview-map-buttons";

        // Margin
        this.marginWidth = 10;

        // Gallery settings
        // Minimum / scale / maximum content widths
        this.minContentWidth = 300;
        this.scaleContentWidth = 400;
        this.maxContentWidth = 500;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media area
        this.mediaArea = 80000;
        this.mediaAreaVertical = 120000;

        // Stats widths
        this.statsWidth = 60;
        this.statsWidthSmall= 50;
        this.statsPaddingWidth = 1;
        this.statsHeight = 8;
        this.statsHeightSmall = 6;

        // Initialize state
        this.state = {
            media: null,
            selectedMediaIndex: null
        };

        // Bind functions
        this.closePreview = this.closePreview.bind(this);

        // Gallery navigation
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);
    }

    render() {
        //console.log("DotPreview / render - this.props = ", this.props);

        // If in itinerary
        const inItinerary = (this.props.selected === null)? false :
            (this.props.itinerary.indexOf(this.props.selected) >= 0);

        const cancelButton = (
            <div id = "plan-dot-preview-cancel"
                className = "image-button-weaker-s3"
                style = {{
                    backgroundImage:  (this.props.colorMode === "day")?
                        getStaticPath("/images/common/cancel-black.png") :
                        getStaticPath("/images/common/cancel-white.png")
                    }}
                onClick = {this.closePreview}
            >
            </div>
        );

        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        let map = null;

        // Using Google maps
        if (this.mapSource === "google") {
            // Only when google object and locations are ready
            if ((this.props.google) && (this.state.itinerary !== null)) {
                // Set the props for Map component
                const mapProps = {
                    // Google
                    google: this.props.google,

                    // Map mode
                    mapMode: this.mapMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,
                    mapContainerNodeID : this.mapContainerNodeID,
                    buttonsNodeID: this.mapButtonsNodeID,
                    startInputNodeID: null,
                    endInputNodeID: null,

                    // Map height
                    mapHeight: this.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,

                    // Dots
                    dotsInfo: this.props.tripInfo.trip_extension.children,

                    // Itinerary and selected settings
                    itinerary: this.props.itinerary,

                    // Selected / hovered / selectedChild / hoveredChild / displayChildren
                    selected: this.props.selected,
                    hovered: this.props.hovered,
                    selectedChild: this.props.selectedChild,
                    hoveredChild: this.props.hoveredChild,
                    displayChildren: this.props.displayChildren,

                    // Start and end locations
                    startLocation: this.props.startLocation,
                    endLocation: this.props.endLocation,

                    // Action triggers
                    mapZoomInAnimation: this.props.mapZoomInAnimation,
                    mapZoomOutAnimation: this.props.mapZoomOutAnimation,
                    mapZoomHikeAnimation: this.props.mapZoomHikeAnimation,
                    mapRefreshAnimation: this.props.mapRefreshAnimation,
                    resetAnimations: this.props.resetAnimations,

                    // Dot click and hover callback
                    dotClick: () => {},
                    dotHoverOn: this.props.dotHoverOn,
                    dotHoverOff: this.props.dotHoverOff,
                    endHoverOn: this.props.endHoverOn,
                    endHoverOff: this.props.endHoverOff,

                    // Parse callbacks (modify top states)
                    setStartLocation: null,
                    setEndLocation: null,

                    // Map type
                    mapType: this.props.tripInfo.map_type,

                    // Default zoom level
                    mapZoom: this.mapZoom
                }
                //console.log("Trip / render - mapProps = ", mapProps);

                // Get the Map component
                map = (<GoogleMap {...mapProps}/>);
            }
        }


        /*
        ============================================================================================
            Navigation Arrows
        ============================================================================================
        */

        let prevItem = null;
        let nextItem = null;

        if (inItinerary) {
            // Get the item index
            const index = this.props.itinerary.indexOf(this.props.selected);

            // Is first or last
            const isFirst = (this.props.selected === this.props.itinerary[0]);
            const isLast = (this.props.selected === this.props.itinerary[this.props.itinerary.length - 1]);

            const prevItemImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/arrow-left-black.png") :
                getStaticPath("/images/common/arrow-left-white.png");

            prevItem = (isFirst)? null : (
                <div id = "plan-dot-preview-prev-item"
                    className = {(this.props.browserWidth <= 2)?
                        "plan-dot-preview-prev-item-small image-button-s4" :
                        "plan-dot-preview-prev-item image-button-s3"
                    }
                    style = {{ backgroundImage: (isFirst)? null : prevItemImage }}
                    onClick = {
                        (isFirst)?
                            () => {} :
                            () => { this.props.dotClick(this.props.itinerary[index - 1]); }
                    }
                >
                </div>
            );

            const nextItemImage = (this.props.colorMode === "day")?
                getStaticPath("/images/common/arrow-right-black.png") :
                getStaticPath("/images/common/arrow-right-white.png");

            nextItem = (
                <div id = "plan-dot-preview-next-item"
                    className = {(this.props.browserWidth <= 2)?
                        "plan-dot-preview-next-item-small image-button-s4" :
                        "plan-dot-preview-next-item image-button-s3"
                    }
                    style = {{ backgroundImage: (isLast)? null : nextItemImage }}
                    onClick = {
                        (isLast)?
                            () => {} :
                            () => { this.props.dotClick(this.props.itinerary[index + 1]); }
                    }
                >
                </div>
            );
        }


        /*
        ============================================================================================
            Gallery
        ============================================================================================
        */
        const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.marginWidth;
        const layoutVertical = this.props.browserWidth < 4;

        let gallery = null;
        let galleryDimensions = null;
        if (this.state.media !== null) {
            // Get the image dimensions for the right size (small size)
            const mediaInfo = this.state.media[this.state.selectedMediaIndex];
            const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
            const mediaHeight = getMediaProperty(mediaInfo, "s", 'height',  false);

            // Is video
            const isVideo = (this.state.media[this.state.selectedMediaIndex].type === "video");

            // Gallery dimensions
            galleryDimensions = mediaDimensions({
                colorMode: this.props.colorMode,
                effectiveBrowserWidth: effectiveBrowserWidth,
                isVideo: isVideo,
                mediaArea: (layoutVertical)? this.mediaAreaVertical : this.mediaArea,
                mediaWidth: mediaWidth,
                mediaHeight: mediaHeight,
                minAspectRatio: this.minAspectRatio,
                maxAspectRatio: this.maxAspectRatio,
                minContentWidth: this.minContentWidth,
                scaleContentWidth: this.scaleContentWidth,
                maxContentWidth: this.maxContentWidth
            });
            //console.log("this.props.effectiveBrowserWidth = ", this.props.effectiveBrowserWidth);
            //console.log("galleryDimensions.finalMediaWidth = ", galleryDimensions.finalMediaWidth);

            // Gallery props
            const galleryProps = Object.assign(
                galleryDimensions,
                {
                    // General
                    id: "",
                    idPrefix: "plan-dot-preview",
                    classPrefix: "plan-dot-preview",
                    selectedMediaIndex: this.state.selectedMediaIndex,
                    media: this.state.media,
                    size: "s",
                    startPlaying: true,
                    checkScroll: false, //this.props.checkScroll,
                    nextMediaClick: this.nextMediaClick,
                    prevMediaClick: this.prevMediaClick,
                    navDotClick: this.navDotClick,
                    index: null,
                    square: (effectiveBrowserWidth <= galleryDimensions.finalMediaWidth)?
                        true : false,
                    muted: false,

                    // Interaction buttons
                    userTagOn: false,
                    dotTagOn: false,
                    unsaveOn: false,
                    enlargeOn: true,
                    dotInfo: this.props.tripInfo.trip_extension.children[this.props.selected],
                    tagMode: "save"
                }
            );

            gallery = (
                <Gallery {...galleryProps} />
            )
        }


        /*
        ============================================================================================
            Stats
        ============================================================================================
        */

        // Stats width and height
        const statsWidth = (this.props.browserWidth <= 4)?
            this.statsWidthSmall : this.statsWidth;
        const statsHeight = (this.props.browserWidth <= 4)?
            this.statsHeightSmall : this.statsHeight;

        // Descriptions
        const stats = (this.props.selected !== null)? (
            <DotStats
                classPrefix = {"plan-dot-preview"}
                browserWidth = {this.props.browserWidth}
                colorMode = {this.props.colorMode}
                dotInfo = {this.props.tripInfo.trip_extension.children[this.props.selected]}
                statsWidth = {statsWidth}
                statsHeight = {statsHeight}
                statsPaddingWidth = {this.statsPaddingWidth}
            />
        ) : null;


        /*
        ============================================================================================
            Add Button
        ============================================================================================
        */

        const addButton = (!inItinerary)? (
            <div className = {(this.props.colorMode === "day")?
                    "plan-dot-preview-add-button button-light-blue-gray-s1" :
                    "plan-dot-preview-add-button button-blue-gray-s1"}
                onClick = {
                    (event) => {
                        this.props.dotAddClick(event, this.props.selected, null);
                        this.closePreview();
                    }
                }
            >
                Add to Itinerary
            </div>
        ) : null;


        /*
        ============================================================================================
            Remove Button
        ============================================================================================
        */

        const removeButton = (inItinerary)? (
            <div className = {(this.props.colorMode === "day")?
                    "plan-dot-preview-remove-button button-light-blue-gray-s1" :
                    "plan-dot-preview-remove-button button-blue-gray-s1"}
                onClick = {
                    (event) => {
                        this.props.dotRemoveClick(event, this.props.selected, null);
                    }
                }
            >
                Remove from Itinerary
            </div>
        ) : null;


        /*
        ============================================================================================
            Curation
        ============================================================================================
        */

        // Stylize curation
        const overview = (this.props.selected !== null)? regularCuration(
            this.props.tripInfo.trip_extension.children[this.props.selected].overview,
            "plan-dot-preview-overview",
            "plan-dot-preview-overview",
            this.props.colorMode
        ) : null;

        const content = (this.props.selected !== null)?
        (
            <div id = "plan-dot-preview-content">
                <div id = {(this.props.browserWidth <= 2)?
                        "plan-dot-preview-header-small" :
                        "plan-dot-preview-header"}
                >
                    {prevItem}
                    <div id = {(this.props.browserWidth <= 2)?
                            "plan-dot-preview-header-content-small" :
                            "plan-dot-preview-header-content"}
                    >
                        <div id = "plan-dot-preview-name"
                            className = {(this.props.colorMode === "day")? "k2" : "w2"}
                        >
                            {this.props.tripInfo.trip_extension.children[this.props.selected].name}
                        </div>

                        <div id = "plan-dot-preview-title"
                            className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                        >
                            {this.props.tripInfo.trip_extension.children[this.props.selected].title}
                        </div>
                    </div>
                    {nextItem}
                </div>

                <div id = "plan-dot-preview-gallery">
                    {gallery}
                </div>

                <div id = {(this.props.browserWidth <= 2)?
                        "plan-dot-preview-overview-small" :
                        "plan-dot-preview-overview"}
                    className = {(this.props.colorMode === "day")? "dg4" : "lg4"}
                >
                    {overview}
                    {stats}
                </div>

                <div id = "plan-dot-preview-bottom-menu"
                    className = {(this.props.colorMode === "day")?
                        "plan-dot-preview-bottom-menu-day" :
                        "plan-dot-preview-bottom-menu-night"}
                >
                    {addButton}
                    {removeButton}
                    {cancelButton}
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Main JSX
        ============================================================================================
        */

        return (
            <div id = "plan-dot-preview-modal"
                style = {{ display: (this.props.selected === null)? "none" : "block" }}
            >
                <div id = "plan-dot-preview"
                    className = {(this.props.colorMode === "day")?
                        "modal-day" : "modal-night"}
                >
                    <div id = "plan-dot-preview-map-wrapper"
                        className = {(this.props.colorMode === "day")?
                            "plan-dot-preview-map-wrapper-day" :
                            "plan-dot-preview-map-wrapper-night"}
                    >
                        {map}
                    </div>

                    {content}
                </div>
            </div>
        );
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selected !== null && (prevProps.selected !== this.props.selected))
        {
            /*
            ============================================================================================
                Media
            ============================================================================================
            */

            // Get the media sorted
            const mediaSorted = sortMedia(this.props.tripInfo.trip_extension.children[this.props.selected]);
            const media = [
                ...mediaSorted.overview,
                ...mediaSorted.todos,
                ...mediaSorted.history,
                ...mediaSorted.stories
            ];

            // Update state
            this.setState({
                media: media,
                selectedMediaIndex: 0
            });

            // Freeze body
            freezeBody();
        }
        if (this.props.selected === null && prevProps.selected !== null) {
            // Unfreeze body
            unfreezeBody();
        }
    }

    closePreview() {
        //console.log("DotPreview / closePreview");

        this.props.dotClick(this.props.selected, null);
    }

    /*
    ============================================================================================
        Gallery Navigation Functions
    ============================================================================================
    */

    nextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex + 1)
            }
        );
    }

    prevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex - 1)
            }
        );
    }

    navDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            selectedMediaIndex: mediaIndex
        });
    }
}


/*
============================================================================================
    OptionalSmall
--------------------------------------------------------------------------------------------
    - Optional items for trip planning in small layout
============================================================================================
*/

class OptionalSmall extends Component {
	constructor(props) {
		super(props);

		// Dot settings
		this.numDotsCategoryDisplayed = 4;

		// Dot width
		this.dotWidth = 152;
		this.dotContainerWidth = 160;

		// Initialize state
		this.state = {
			category: "scenic",
			scenicPage: 0,
			dinePage: 0,
			experiencePage: 0,
			routePage: 0,
            customPage: 0
		};

		// Bind functions
		this.setState = this.setState.bind(this);
		this.scenicIconClick = this.scenicIconClick.bind(this);
	    this.dineIconClick = this.dineIconClick.bind(this);
	    this.experienceIconClick = this.experienceIconClick.bind(this);
	    this.routeIconClick = this.routeIconClick.bind(this);
        this.customIconClick = this.customIconClick.bind(this);
	    this.nextPage = this.nextPage.bind(this);
	    this.previousPage = this.previousPage.bind(this);
	}

	render() {
	    /*
	    ============================================================================================
	        Category Menu
	    ============================================================================================
	    */

	   	// Scenic category
	    const scenicIconImage = (this.props.colorMode === "day")?
	        getStaticPath("/images/common/category-scenic-black.png") :
	        getStaticPath("/images/common/category-scenic-white.png");
	    const scenicIconClass = (this.state.category === "scenic")?
            "plan-optional-small-scenic-icon plan-optional-small-menu-icon-on image-button-s3" :
            "plan-optional-small-scenic-icon plan-optional-small-menu-icon image-button-s3";
	    const scenicIcon = (
	        <div className = {scenicIconClass}
	            style = {{ backgroundImage: scenicIconImage }}
	            onClick = {this.scenicIconClick}
	        >
	        </div>
	    );

	    // Dine category
	    const dineIconImage = (this.props.colorMode === "day")?
	        getStaticPath("/images/common/category-dine-black.png") :
	        getStaticPath("/images/common/category-dine-white.png");
	    const dineIconClass = (this.state.category === "dine")?
            "plan-optional-small-dine-icon plan-optional-small-menu-icon-on image-button-s3" :
            "plan-optional-small-dine-icon plan-optional-small-menu-icon-off image-button-s3";
	    const dineIcon = (
	        <div className = {dineIconClass}
	            style = {{ backgroundImage: dineIconImage }}
	            onClick = {this.dineIconClick}
	        >
	        </div>
	    );

	    // Experience category
	    const experienceIconImage = (this.props.colorMode === "day")?
	        getStaticPath("/images/common/category-experience-black.png") :
	        getStaticPath("/images/common/category-experience-white.png");
	    const experienceIconClass = (this.state.category === "experience")?
            "plan-optional-small-experience-icon plan-optional-small-menu-icon-on image-button-s3" :
            "plan-optional-small-experience-icon plan-optional-small-menu-icon-off image-button-s3";
	    const experienceIcon = (
	        <div className = {experienceIconClass}
	            style = {{ backgroundImage: experienceIconImage }}
	            onClick = {this.experienceIconClick}
	        >
	        </div>
	    );

	    // Route category
	    const routeIconImage = (this.props.colorMode === "day")?
	        getStaticPath("/images/common/category-route-black.png") :
	        getStaticPath("/images/common/category-route-white.png");
	    const routeIconClass = (this.state.category === "route")?
            "plan-optional-small-route-icon plan-optional-small-menu-icon-on image-button-s3" :
            "plan-optional-small-route-icon plan-optional-small-menu-icon-off image-button-s3";
	    const routeIcon = (
	        <div className = {routeIconClass}
	            style = {{ backgroundImage: routeIconImage }}
	            onClick = {this.routeIconClick}
	        >
	        </div>
	    );


        // Route category
        const customIconImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/category-custom-black.png") :
            getStaticPath("/images/common/category-custom-white.png");
        const customIconClass = (this.state.category === "route")?
            "plan-optional-small-custom-icon plan-optional-small-menu-icon-on image-button-s3" :
            "plan-optional-small-custom-icon plan-optional-small-menu-icon-off image-button-s3";
        const customIcon = (
            <div className = {customIconClass}
                style = {{ backgroundImage: customIconImage }}
                onClick = {this.customIconClick}
            >
            </div>
        );


	    /*
	    ============================================================================================
	        Optional items
	    ============================================================================================
	    */

	    // Calculate how many dots in the selected category
	    const indicesCategory = [];
	    for (let i = 0; i < this.props.dotsInfo.length; i++) {
	        if (this.props.itinerary.indexOf(i) === -1) {
	            if (getDotCategory(this.props.dotsInfo[i].type) === this.state.category) {
	                indicesCategory.push(i);
	            }
	        }
	    }

	    // Determine category
	    let optionsPage;
	    switch (this.state.category) {
	        case "scenic":
	            optionsPage = this.state.scenicPage;
	            break;
	        case "dine":
	            optionsPage = this.state.dinePage;
	            break;
	        case "experience":
	            optionsPage = this.state.experiencePage;
	            break;
	        case "route":
	            optionsPage = this.state.routePage;
	            break;
            case "custom":
                optionsPage = this.state.customPage;
                break;
	        default:
	            break;
	    }

	    // Find out the IDs of dots in the selected page
	    const startIndex = this.numDotsCategoryDisplayed * optionsPage;
	    const endIndex = Math.min(
	        this.numDotsCategoryDisplayed * (optionsPage + 1) - 1,
	        indicesCategory.length - 1
	    );

	    const maxPage = Math.floor((indicesCategory.length - 1) /
	    	this.numDotsCategoryDisplayed);
	    //console.log("DotGroup / OptionalSmall / render - optionsPage = ", optionsPage);
	    //console.log("DotGroup / OptionalSmall / render - startIndex = ", startIndex);
	    //console.log("DotGroup / OptionalSmall / render - endIndex = ", endIndex);
	    //console.log("DotGroup / OptionalSmall / render - indicesCategory = ", indicesCategory);
	    //console.log("DotGroup / OptionalSmall / render - indicesCategory.length = ", indicesCategory.length);
	    //console.log("DotGroup / OptionalSmall / render - optionsNumDotsCategoryDisplayed = ", props.optionsNumDotsCategoryDisplayed);
	    //console.log("DotGroup / OptionalSmall / render - maxPage = ", maxPage);

	    // Find displayed indices
	    const indicesCategoryDisplayed = [];
	    for (let i = startIndex; i <= endIndex; i ++) {
	        indicesCategoryDisplayed.push(indicesCategory[i]);
	    }
	    //console.log("DotGroup / OptionalSmall / render - indicesCategoryDisplayed = ", indicesCategoryDisplayed);

	    // Build list of items
	    const dotList = (
	        <DotList
	        	dotWidth = {this.dotWidth}
	        	dotContainerWidth = {this.dotContainerWidth}

	            colorMode = {this.props.colorMode}

	            dots = {indicesCategoryDisplayed}
	            itinerary = {this.props.itinerary}
	            dotsInfo = {this.props.dotsInfo}
	            selected = {this.props.selected}
	            selectedChild = {this.props.selectedChild}
	            displayChildren = {this.props.displayChildren}
	            hovered = {this.props.hovered}
	            customizeOn = {true}

	            dotConnectAnimationClass = {this.props.dotConnectAnimationClass}

				dotClick = {this.props.dotClick}
	            dotAddClick = {this.props.dotAddClick}
	            dotRemoveClick = {this.props.dotRemoveClick}
				dotHoverOn = {this.props.dotHoverOn}
				dotHoverOff = {this.props.dotHoverOff}
	            dotLeftClick = {null}
	            dotRightClick = {null}

	            inItinerary = {false}

	            startMoment = {this.props.startMoment}
	            endMoment = {this.props.endMoment}
		    />
	    );
	    //console.log("DotGroup / OptionalSmall / render - indicesCategory / numDotsCategory = ", indicesCategory, numDotsCategory);
	    //console.log("DotGroup / OptionalSmall / render - startIndex / endIndex = ", startIndex, endIndex);

	    // Navigation buttons
	    const previousPageArrowImage = (this.props.colorMode === "day")?
	        getStaticPath("/images/common/arrow-left-black.png") :
	        getStaticPath("/images/common/arrow-left-white.png");

	    const previousPage = (optionsPage > 0)? (
	        <div className = "plan-optional-small-pagination-prev-page"
	            onClick = {this.previousPage}
	        >
	            <div className = "plan-optional-small-pagination-prev-page-arrow image-button-s8"
	                style = {{ backgroundImage: previousPageArrowImage }}
	            >
	            </div>
	            <div className = {(this.props.colorMode === "day")?
	            	"plan-optional-small-pagination-prev-page-text k4" :
	            	"plan-optional-small-pagination-prev-page-text w4"}
	            >
	                Previous
	            </div>
	        </div>
	    ) : null;

	    const nextPageArrowImage = (this.props.colorMode === "day")?
	        getStaticPath("/images/common/arrow-right-black.png") :
	        getStaticPath("/images/common/arrow-right-white.png");

	    const nextPage = (optionsPage < maxPage)? (
	        <div className = "plan-optional-small-pagination-next-page"
	            onClick = {this.nextPage}
	        >
	            <div className = "plan-optional-small-pagination-next-page-arrow image-button-s8"
	                style = {{ backgroundImage: nextPageArrowImage }}
	            >
	            </div>
	            <div className = {(this.props.colorMode === "day")?
	                "plan-optional-small-pagination-next-page-text k4" :
	                "plan-optional-small-pagination-next-page-text w4"}
	            >
	                Next
	            </div>
	        </div>
	    ) : null;

	    const pagination = ((previousPage != null) || (nextPage != null))?
	    (
	        <div className = "plan-optional-small-pagination clear-fix">
	            {previousPage}
	            {nextPage}
	        </div>
	    ) : null;


        // Close button
        const closeButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") :
            getStaticPath("/images/common/cancel-white.png");

	    // Open button
        const openButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/customize-light-blue.png") :
            getStaticPath("/images/common/customize-blue.png");

        const openButton = (
            <div id = "plan-optional-small-customize"
                onClick = {this.props.openOptionalSmall}
                style = {{ display: (this.props.optionalSmallOn)? "none" : "inline-block" }}
            >
                <div id = {(this.props.browserWidth <= 3)?
                        "plan-optional-small-customize-icon-small" :
                        "plan-optional-small-customize-icon"}
                    className = "image-button-strong-base"
                    style = {{ backgroundImage: openButtonImage }}
                >
                </div>
                <div id = "plan-optional-small-customize-text"
                    className = {
                        (this.props.browserWidth <= 3)?
                        (
                            (this.props.colorMode === "day")?
                               "k3" : "w3"
                        ) : (
                            (this.props.colorMode === "day")?
                               "k2" : "w2"
                        )
                    }
                >
                    Customize
                </div>
            </div>
        );

        // Finalize buttons
        const finalizeButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/print-light-blue.png") :
            getStaticPath("/images/common/print-blue.png");

        const finalizeButton = (this.props.loggedIn)?
        (
            <div id = "plan-optional-small-finalize"
                style = {{ display: (this.props.optionalSmallOn)? "none" : "inline-block" }}
            >
                <Link to = {"/itinerary/" + this.props.itineraryID}
                >
                    <div id = {(this.props.browserWidth <= 3)?
                            "plan-optional-small-finalize-icon-small" :
                            "plan-optional-small-finalize-icon"}
                        className = "image-contain"
                        style = {{ backgroundImage: finalizeButtonImage }}
                    >
                    </div>
                    <div id = "plan-optional-small-finalize-text"
                        className = {
                            (this.props.browserWidth <= 3)?
                            (
                                (this.props.colorMode === "day")?
                                   "k3" : "w3"
                            ) : (
                                (this.props.colorMode === "day")?
                                   "k2" : "w2"
                            )
                        }
                    >
                        Finalize
                    </div>
                </Link>
            </div>
        ) : (
            <div id = "plan-optional-small-finalize"
                style = {{ display: (this.props.optionalSmallOn)? "none" : "inline-block" }}
                onClick = {() => { this.props.setState({ finalizeModalOn: true }); }}
            >
                <div id = {(this.props.browserWidth <= 3)?
                        "plan-optional-small-finalize-icon-small" :
                        "plan-optional-small-finalize-icon"}
                    className = "image-contain"
                    style = {{ backgroundImage: finalizeButtonImage }}
                >
                </div>
                <div id = "plan-optional-small-finalize-text"
                    className = {
                        (this.props.browserWidth <= 3)?
                        (
                            (this.props.colorMode === "day")?
                               "k3" : "w3"
                        ) : (
                            (this.props.colorMode === "day")?
                               "k2" : "w2"
                        )
                    }
                >
                    Finalize
                </div>
            </div>
        );

	    // Render
	    return (
	        <div id = "plan-optional-small"
                className = {(this.props.colorMode === "day")?
		        	"plan-optional-small-day" :
		        	"plan-optional-small-night"}
	        	style = {{ display: (this.props.dotIndexOn)? "block" : "none" }}
	        >
                <div id = "plan-optional-small-buttons"
                    style = {{ display: (this.props.finalizeOn && !this.props.optionalSmallOn)?
                        "block" : "none" }}
                >
                    {openButton}
                    {finalizeButton}
                </div>

	        	<div className = "plan-optional-small-menu"
	        		style = {{ display: (this.props.optionalSmallOn)? "block" : "none" }}
	        	>
	                <div className = {(this.props.colorMode === "day")?
	                        "plan-optional-small-cancel image-button-weaker-s5" :
	                        "plan-optional-small-cancel image-button-weaker-s5"}
	                    style = {{ backgroundImage : closeButtonImage }}
	                    onClick = {this.props.closeOptionalSmall}
	                >
	                </div>
		        	<div className = "plan-optional-small-menu">
		        		{scenicIcon}
		        		{dineIcon}
		        		{experienceIcon}
		        		{routeIcon}
                        {customIcon}
		        	</div>
		        	<div className = "plan-optional-small-items">
			            {dotList}
		            </div>
		            {pagination}
		        </div>
	        </div>
	    );
	}

	scenicIconClick() {
		this.setState({
			category: "scenic"
		});
	}

	dineIconClick() {
		this.setState({
			category: "dine"
		});
	}

	experienceIconClick() {
		this.setState({
			category: "experience"
		});
	}

	routeIconClick() {
		this.setState({
			category: "route"
		});
	}

    customIconClick() {
        this.setState({
            category: "custom"
        });
    }

    nextPage() {
        // Change page
        switch (this.state.category) {
            case "scenic":
                this.setState({
                    scenicPage: this.state.scenicPage + 1
                });

                break;
            case "dine":
                this.setState({
                    dinePage: this.state.dinePage + 1
                });

                break;
            case "experience":
                this.setState({
                    experiencePage: this.state.experiencePage + 1
                });

                break;
            case "route":
                this.setState({
                    routePage: this.state.routePage + 1
                });

                break;
            case "custom":
                this.setState({
                    customPage: this.state.customPage + 1
                });

                break;
            default:
                break;
        }
    }

    previousPage() {
        // Change page
        switch (this.state.category) {
            case "scenic":
                this.setState({
                    scenicPage: this.state.scenicPage - 1
                });

                break;
            case "dine":
                this.setState({
                    dinePage: this.state.dinePage - 1
                });

                break;
            case "experience":
                this.setState({
                    experiencePage: this.state.experiencePage - 1
                });

                break;
            case "route":
                this.setState({
                    routePage: this.state.routePage - 1
                });

                break;
            case "custom":
                this.setState({
                    customPage: this.state.customPage - 1
                });

                break;
            default:
                break;
        }
    }

}


/*
============================================================================================
    Selected (stateless)
--------------------------------------------------------------------------------------------
    - Selected items for trip planning
============================================================================================
*/

function Selected(props) {
	return (
	    <DotList
	        colorMode = {props.colorMode}

	    	dots = {props.itinerary}
	        itinerary = {props.itinerary}
	        dotsInfo = {props.dotsInfo}
	        selected = {props.selected}
	        selectedChild = {props.selectedChild}
	        displayChildren = {props.displayChildren}
	        hovered = {props.hovered}
	        customizeOn = {props.customizeOn}

	        dotOpacities = {props.dotOpacities}
	        dotShiftTops = {props.dotShiftTops}
	        dotShiftLefts = {props.dotShiftLefts}
	        dotIndexOn = {props.dotIndexOn}
	        dotIndexOpacity = {props.dotIndexOpacity}

	        dotConnectAnimationClass = {props.dotConnectAnimationClass}
			setDotNodes = {props.setDotNodes}

			dotClick = {props.dotClick}
	        dotAddClick = {props.dotAddClick}
	        dotRemoveClick = {props.dotRemoveClick}
			dotHoverOn = {props.dotHoverOn}
			dotHoverOff = {props.dotHoverOff}
	        dotLeftClick = {props.dotLeftClick}
	        dotRightClick = {props.dotRightClick}

	        inItinerary = {true}

	        startMoment = {props.startMoment}
	        endMoment = {props.endMoment}
	    />
	);
}


/*
============================================================================================
    Optional (stateless)
--------------------------------------------------------------------------------------------
    - Optional items for trip planning
============================================================================================
*/

function Optional(props) {
    //console.log("DotGroup / Optional / render - props = ", props);

    // Calculate how many dots in the selected category
    const indicesCategory = [];
    for (let i = 0; i < props.dotsInfo.length; i++) {
        if (props.itinerary.indexOf(i) === -1) {
            if (getDotCategory(props.dotsInfo[i].type) === props.optionsCategory) {
                indicesCategory.push(i);
            }
        }
    }

    // Determine category
    let optionsPage;
    switch (props.optionsCategory) {
        case "scenic":
            optionsPage = props.optionsScenicPage;
            break;
        case "dine":
            optionsPage = props.optionsDinePage;
            break;
        case "experience":
            optionsPage = props.optionsExperiencePage;
            break;
        case "route":
            optionsPage = props.optionsRoutePage;
            break;
        default:
            break;
    }

    // Find out the IDs of dots in the selected page
    const startIndex = props.optionsNumDotsCategoryDisplayed * optionsPage;
    const endIndex = Math.min(
        props.optionsNumDotsCategoryDisplayed * (optionsPage + 1) - 1,
        indicesCategory.length - 1
    );

    const maxPage = Math.floor((indicesCategory.length - 1) / props.optionsNumDotsCategoryDisplayed);
    //console.log("DotGroup / Optional / render - optionsPage = ", optionsPage);
    //console.log("DotGroup / Optional / render - startIndex = ", startIndex);
    //console.log("DotGroup / Optional / render - endIndex = ", endIndex);
    //console.log("DotGroup / Optional / render - indicesCategory = ", indicesCategory);
    //console.log("DotGroup / Optional / render - indicesCategory.length = ", indicesCategory.length);
    //console.log("DotGroup / Optional / render - optionsNumDotsCategoryDisplayed = ", props.optionsNumDotsCategoryDisplayed);
    //console.log("DotGroup / Optional / render - maxPage = ", maxPage);

    // Find displayed indices
    const indicesCategoryDisplayed = [];
    for (let i = startIndex; i <= endIndex; i ++) {
        indicesCategoryDisplayed.push(indicesCategory[i]);
    }

    // Build list of items
    const dotList = (
        <DotList
            colorMode = {props.colorMode}

            dots = {indicesCategoryDisplayed}
            itinerary = {props.itinerary}
            dotsInfo = {props.dotsInfo}
            selected = {props.selected}
            selectedChild = {props.selectedChild}
            displayChildren = {props.displayChildren}
            hovered = {props.hovered}
            customizeOn = {props.customizeOn}

            dotConnectAnimationClass = {props.dotConnectAnimationClass}

			dotClick = {props.dotClick}
            dotAddClick = {props.dotAddClick}
            dotRemoveClick = {props.dotRemoveClick}
			dotHoverOn = {props.dotHoverOn}
			dotHoverOff = {props.dotHoverOff}
            dotLeftClick = {null}
            dotRightClick = {null}

            inItinerary = {false}

            startMoment = {props.startMoment}
            endMoment = {props.endMoment}
	    />
    );
    //console.log("DotGroup / Optional / render - indicesCategory / numDotsCategory = ", indicesCategory, numDotsCategory);
    //console.log("DotGroup / Optional / render - startIndex / endIndex = ", startIndex, endIndex);

    // Navigation buttons
    const previousPageArrowImage = (props.colorMode === "day")?
        getStaticPath("/images/common/arrow-left-black.png") :
        getStaticPath("/images/common/arrow-left-white.png");

    const previousPage = (optionsPage > 0)? (
        <div id = "plan-options-pagination-previous-page"
            onClick = {props.optionsPreviousPage}
        >
            <div id = "plan-options-pagination-previous-page-arrow"
                className = "image-button-s8"
                style = {{ backgroundImage: previousPageArrowImage }}
            >
            </div>
            <div id = "plan-options-pagination-previous-page-text"
                className = {(props.colorMode === "day")? "k4" : "w4"}
            >
                Previous
            </div>
        </div>
    ) : null;

    const nextPageArrowImage = (props.colorMode === "day")?
        getStaticPath("/images/common/arrow-right-black.png") :
        getStaticPath("/images/common/arrow-right-white.png");

    const nextPage = (optionsPage < maxPage)? (
        <div id = "plan-options-pagination-next-page"
            onClick = {props.optionsNextPage}
        >
            <div id = "plan-options-pagination-next-page-text"
                className = {(props.colorMode === "day")? "k4" : "w4"}
            >
                Next
            </div>
            <div id = "plan-options-pagination-next-page-arrow"
                className = "image-button-s8"
                style = {{ backgroundImage: nextPageArrowImage }}
            >
            </div>
        </div>
    ) : null;

    const pagination = ((previousPage != null) || (nextPage != null))?
    (
        <div id = "plan-options-pagination">
            {previousPage}
            {nextPage}
        </div>
    ) : null;

    // Render
    return (
        <div id = "plan-options-content">
            {dotList}
            {pagination}
        </div>
    );
}


export {
	SelectedSmall,
	OptionalSmall,
	Selected,
	Optional,
    DotPreview
};
