import {
	STORE_SCHEDULE
} from "actions";

const INITIAL_STATE = {
	schedule: 
	{
		modalOn: false,
		mode: null,
		//id: null,
		//info: null,
		//dot: null,
		size: null
	}
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
		case STORE_SCHEDULE:
			return { ...state, schedule: action.payload };
		default:
			return state;
	}
};