import { STORE_NEW_CHAT, STORE_NEW_CHAT_MESSAGE } from "actions"; 

const INITIAL_STATE = { 
    newChat: null,
    newChatMessage: null
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
        case STORE_NEW_CHAT:
            return { ...state, newChat: action.payload};
        case STORE_NEW_CHAT_MESSAGE:
            return { ...state, newChatMessage: action.payload};
		default:
			return state;
	}
};