/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    DotFunctions.js
    - React component for dot and list of dots
--------------------------------------------------------------------------------------------
    Content
    - Dot
    - DotList
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";

// Modules
import ReactTooltip from "thedots-tooltip";
import moment from "moment-timezone";

// Functions
import { pad, getStaticPath, getMediaProperty } from "js/Functions";

// CSS
import "css/DotFunctions.scss";


/*
============================================================================================
    Dot component (stateless)
--------------------------------------------------------------------------------------------
    - Individual dot item
============================================================================================
*/
function Dot(props) {
    //console.log("Dot / render - props.dot = ", props.dot);
    // Common dot
    const commonDot = (props.dot.type === "EV" || props.dot.type === "AU");

    // Get dot type icon
    let dotTypeIcon = null;
    /*
    if ((props.dot.type === "DI") || (props.dot.type === "DE")) {
        dotTypeIcon = getStaticPath("/images/common/dot-object-dine.png");
    }
    else if (props.dot.type === "EX") {
        dotTypeIcon = getStaticPath("/images/common/dot-object-experience.png");
    }
    */

    // Get dot image and index display
    const dotMedia = props.mediaURL;

    // Get dot index display and icon
    const dotIndexOn = (props.dotIndexOn)? "block" : "none";
    const dotIndexIcon =  (props.options)? null: 
        getStaticPath("/images/number/single_red_" + (props.index + 1) + ".png");

    // Get dot name and index
    let name, index;
    if ((props.options) || ((!props.options) && (!props.dotIndexOn))) {
        name = (props.colorMode === "day")? (
            <div className = "dot-object-name-options-day w4">
                {props.dot.name}
            </div>
        ) : (
            <div className = "dot-object-name-options-night w4">
                {props.dot.name}
            </div>
        );

        index = null;
    }
    else {
        name = (props.colorMode === "day")? (
            <div className = "dot-object-name-day w4">
                {props.dot.name}
            </div>
        ) : (
            <div className = "dot-object-name-night w4">
                {props.dot.name}
            </div>
        );

        //console.log("Dot - dotIndexOn / dotIndexOpacity = ", props.dotIndexOn, props.dotIndexOpacity);
        index = (
            <div className = "dot-object-index image-cover" 
                style = {{ 
                    backgroundImage: dotIndexIcon, 
                    display: dotIndexOn, 
                    opacity: props.dotIndexOpacity 
                }}
            >
            </div>
        );
    }

    // Get time
    const time = (commonDot)? null :
        (
            (props.dot.dot_extension.time !== null)? 
            (
                <div className = "dot-object-time number-w6">
                    {props.dot.dot_extension.time}
                </div>
            ) : null
        );

    // Get the rating color
    const ratingColor = (commonDot)? null :
        (props.dot.dot_extension.difficulty === null)? "#C5833B" : "#249CCE";

    // Get the single tooltip color
    const singleToolTipType = (commonDot)? "info" :
        (props.dot.dot_extension.difficulty === null)? "warning" : "info";

    // Get the factors
    let factor, factorTooltip;

    if (!commonDot) {
        if (props.dot.dot_extension.difficulty != null) {
            const leftToolTipID = "dot-object-factor-left-" + props.dotIndex;
            const rightToolTipID = "dot-object-factor-right-" + props.dotIndex;
            const scenicWidth = "" + Math.min(Math.round(props.dot.dot_extension.rating / 5 * 100), 100) + "%";
            const difficultyWidth = "" + Math.min(Math.round(Math.ceil(props.dot.dot_extension.difficulty) / 5 * 100), 100) + "%";
            factor = (
                <div className = "dot-object-factor">
                    <div className = "dot-object-factor-row">
                        <div className = "dot-object-factor-left" 
                            data-tip data-for = {leftToolTipID}
                        >
                            <div className = "dot-object-factor-background">
                                <div className = "dot-object-factor-scenic-rating"
                                    style = {{ width: scenicWidth }}
                                >
                                </div>
                            </div>
                        </div>
                        <div className = "dot-object-factor-right" 
                            data-tip data-for = {rightToolTipID}
                        >
                            <div className = "dot-object-factor-background">
                                <div className = "dot-object-factor-difficulty"
                                    style = {{ width: difficultyWidth }}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

            factorTooltip = (
                <div>
                    <ReactTooltip 
                        id = {leftToolTipID} 
                        className = "tooltip-s2"
                        type = "info" 
                        place = "left"
                    >
                        <span>Scenic Factor {props.dot.dot_extension.rating}</span>
                    </ReactTooltip>
                    <ReactTooltip 
                        id = {rightToolTipID} 
                        className = "tooltip-s2"
                        type = "error" 
                        place = "right"
                    >
                        <span>Difficulty {Math.ceil(props.dot.dot_extension.difficulty).toFixed(1)}</span>
                    </ReactTooltip>
                </div>
            );
        }
        else {
            const singleToolTipID = "dot-object-factor-single-" + props.dotIndex;
            const ratingWidth = "" + Math.round(props.dot.dot_extension.rating / 5 * 100) + "%"
            factor = (
                <div className = "dot-object-factor">
                    <div className = "dot-object-factor-row">
                        <div className = "dot-object-factor-single" data-tip data-for = {singleToolTipID}>
                            <div className = "dot-object-factor-background">
                                <div className = "dot-object-factor-rating" 
                                    style = {{ 
                                        width: ratingWidth,
                                        backgroundColor: ratingColor
                                    }}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

            factorTooltip = (
                <div>
                    <ReactTooltip 
                        id = {singleToolTipID} 
                        className = "tooltip-s2"
                        type = {singleToolTipType}
                        place = "bottom"
                    >
                        <span>Rating {props.dot.dot_extension.rating}</span>
                    </ReactTooltip>
                </div>
            );
        }
    }

    // Loader
    const loaderImage = (props.colorMode === "day")? 
        getStaticPath("/images/loader/loader-white.gif") :
        getStaticPath("/images/loader/loader-black.gif")

    // Dot style
    const dotStyle = ((props.selected) || (props.hovered))? {
        opacity: props.dotOpacity,
        border: ("1px solid " + ((props.colorMode === "day")?
            window.colorLightBlue : window.colorDarkBlue)),
        boxShadow: ("0px 0px 4px 0px " + ((props.colorMode === "day")?
            window.colorLightBlue : window.colorBlue))
    } : {
        opacity: props.dotOpacity,
        border: ("1px solid " + ((props.colorMode === "day")?
            window.colorLightGray : window.colorDarkestGray))
    };

    // Class name
    const dotClassMirrored = (commonDot)?
    "dot-object-image-mirrored" : (
        (props.estimatedClosure || props.dot.dot_extension.is_open === false)?
            "dot-object-image-mirrored grayscale" : "dot-object-image-mirrored"
    );

    const dotClass = (commonDot)? 
    "dot-object-image" : (
        (props.estimatedClosure || props.dot.dot_extension.is_open === false)?
            "dot-object-image grayscale" : "dot-object-image"
    );

    // Add shadow layer
    const dotShadow = (commonDot)? 
    null : (
        (props.estimatedClosure || props.dot.dot_extension.is_open === false)? (
            <div className = "dot-object-shadow"></div>
        ) : null
    );

    // Add closure layer
    let estimatedClosure = null;
    if (!commonDot) {
        estimatedClosure = (props.dot.dot_extension.is_open === false)? 
        (
            <div className = "dot-object-estimated-closure w5">
                Currently Closed
            </div>
        ) : (
            (props.estimatedClosure)? (
                <div className = "dot-object-estimated-closure w5">
                    Potential Closure
                </div>
            ) : null
        );
    }

    return (
        <div className = "dot-object-container"
            onClick = {
                props.dotClick != null? 
                    () => { props.dotClick(props.dotIndex); } : () => {}
                }
            onMouseEnter = {
                props.dotHoverOn != null?
                    () => { props.dotHoverOn(props.dotIndex, props.dot, props.selected, props.inItinerary); } : () => {}
                }
            onMouseLeave = {
                props.dotHoverOff != null?
                    () => { props.dotHoverOff(props.dotIndex, props.selected, props.inItinerary); } : () => {}
                }
        >
            <div className = "dot-object"
                style = {dotStyle}
            >
                <div className = "dot-object-gradient">
                    <div className = "dot-object-upper-row">
                        <div className = {dotClassMirrored}
                            style = {{ backgroundImage: dotMedia }}
                        >
                            <div className = "dot-object-image-mirrored-back">
                                {name}
                                {index}
                            </div>
                        </div>
                    </div>
                    <div className = "dot-object-middle-row">
                        <div className = "dot-object-image-loader"
                            style = {{ backgroundImage: loaderImage }}
                        >
                            <div className = {dotClass}
                                style = {{ backgroundImage: dotMedia }}
                            >
                                {factor}
                                {dotShadow}
                                {time}
                                {estimatedClosure}
                                <div className = "dot-object-type"
                                    style = {{ backgroundImage: dotTypeIcon }}
                                >
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {factorTooltip}
        </div>
    )
}


/*
============================================================================================
    DotList component (stateless)
--------------------------------------------------------------------------------------------
    - List of dots to render selection or options section
============================================================================================
*/

function DotList(props) {
    //console.log("DotList / render - props = ", props);

    // For all dots in the list
    const dotList = props.dots.map((dotIndex) => {
        //console.log("DotList / render - dotIndex = ", dotIndex);

        // Get the dot object
        const dot = props.dotsInfo[dotIndex];
        //console.log("DotList / render - dot = ", dot);

        // Common dot
        const commonDot = (dot.type === "EV" || dot.type === "AU");

        // Estimate closure
        let estimatedClosure = false;
        if (!commonDot) {
            if (dot.dot_extension.closure_start_date !== null) {
                // Closure start date
                const closureStartMomentRaw = moment(dot.dot_extension.closure_start_date);
                const closureStartMomentPreviousYear = moment(
                    String(props.startMoment.year() - 1) + "-" 
                    + String(pad(closureStartMomentRaw.month(), 2, 0)) + "-" 
                    + String(pad(closureStartMomentRaw.date(), 2, 0))
                );

                const closureStartMomentThisYear = moment(
                    String(props.startMoment.year()) + "-" 
                    + String(pad(closureStartMomentRaw.month(), 2, 0)) + "-" 
                    + String(pad(closureStartMomentRaw.date(), 2, 0))
                );

                let closureStartMoment;
                if (closureStartMomentThisYear < props.startMoment) {
                    closureStartMoment = closureStartMomentThisYear;
                }
                else {
                    closureStartMoment = closureStartMomentPreviousYear;
                }

                // Closure end date
                const closureEndMomentRaw = moment(dot.dot_extension.closure_end_date);
                const closureEndMomentSameYear = moment(
                    String(closureStartMoment.year()) + "-" 
                    + String(pad(closureEndMomentRaw.month(), 2, 0)) + "-" 
                    + String(pad(closureEndMomentRaw.date(), 2, 0))
                );
                const closureEndMomentNextYear = moment(
                    String(closureStartMoment.year() + 1) + "-" 
                    + String(pad(closureEndMomentRaw.month(), 2, 0)) + "-" 
                    + String(pad(closureEndMomentRaw.date(), 2, 0))
                );

                let closureEndMoment;
                if (closureEndMomentSameYear > closureStartMoment) {
                    closureEndMoment = closureEndMomentSameYear;
                }
                else {
                    closureEndMoment = closureEndMomentNextYear;
                }

                /*
                console.log("==============================================================================");
                console.log("DotList / render - dot.name = ", dot.name);
                console.log("DotList / render - closureStartMomentPreviousYear = ", closureStartMomentPreviousYear);
                console.log("DotList / render - closureStartMomentThisYear = ", closureStartMomentThisYear);
                console.log("DotList / render - closureStartMoment = ", closureStartMoment);
                console.log("DotList / render - closureEndMomentSameYear = ", closureEndMomentSameYear);
                console.log("DotList / render - closureEndMomentNextYear = ", closureEndMomentNextYear);
                console.log("DotList / render - closureEndMoment = ", closureEndMoment);
                console.log("DotList / render - props.startMoment = ", props.startMoment);
                console.log("DotList / render - props.startMoment >= closureStartMoment = ", props.startMoment >= closureStartMoment);
                console.log("DotList / render - props.startMoment <= closureEndMoment = ", props.startMoment <= closureEndMoment);
                */

                // Closure status
                if ((props.startMoment >= closureStartMoment) && (props.startMoment <= closureEndMoment)) {
                    estimatedClosure = true;
                }
            }
        }

        // Get media info
        const mediaInfo = dot.media[0];
        // const mediaURL = (Object.keys(mediaInfo.files).indexOf("xs") === -1)?
        //     null : getMediaProperty(mediaInfo, "xs", 'url', true);
        const mediaURL = ("xs" in mediaInfo.files)?
            getMediaProperty(mediaInfo, "xs", 'url', true) : 
            null;

        // Compile mediaURLs for the right size
        const mediaURLs = [];
        for (let i = 0; i < dot.media.length; i ++) {
            mediaURLs.push(
                getMediaProperty(dot.media[i], "xs", 'url', true)
            );
        }
        //console.log("DotList / render - mediaURLs = ", mediaURLs);

        // If the dot is in the selection or in the options window
        const index = (props.itinerary.indexOf(dotIndex));
        const options = (index === -1)? true : false;

        // If the dot list is for selection window and need find dot animation
        let dotOpacity;
        if (options) {
            dotOpacity = 1.0;
        }
        // For options window
        else {
            dotOpacity = props.dotOpacities[index];
        }

        // Define the animation reference
        const ref = (options)?
            null: ((dotNode) => {props.setDotNodes(index, dotNode);});

        // Dot shifts
        let dotContainerAnimationStyle = {};
        if ((props.dotShiftTops) && (props.dotShiftLefts)) {
            //console.log("DotList / render - index / dotShiftLeft / dotShiftTop = ", index, props.dotShiftLefts[index], props.dotShiftTops[index]);

            // Figure out the shifts
            if ((props.dotShiftLefts[index] !== 0) || (props.dotShiftTops[index] !== 0)) {
                //console.log("DotList / render - index / dotShiftLeft / dotShiftTop = ", index, props.dotShiftLefts[index], props.dotShiftTops[index]);
                dotContainerAnimationStyle = {
                    top: props.dotShiftTops[index],
                    left: props.dotShiftLefts[index]
                }
            }
        }

        // Selected
        const selected = (props.selected === dotIndex)? true: false;

        // Hovered
        const hovered = (props.hovered === dotIndex)? true: false;        


        // Padding
        let offset = 0;
        if ((props.dotWidth !== null && props.dotWidth !== undefined) &&
            (props.dotContainerWidth !== null && props.dotContainerWidth !== undefined)) {
            offset = (props.dotContainerWidth - props.dotWidth) / 2;            
        }

        // Add button
        const addButtonImage = getStaticPath("/images/common/add-white-shadow.png");
        const addButton = (props.customizeOn && !props.inItinerary)? (
            <div className = "dot-object-add-button image-button-s4"
                style = {{
                    backgroundImage: addButtonImage, 
                    margin: offset
                }}
                onClick = { (props.dotAddClick)? (event) => { props.dotAddClick(event, dotIndex, null); } : () => {} }
            >
            </div>
        ) : null;

        // Remove button
        const removeButtonImage = getStaticPath("/images/common/remove-white-shadow.png");
        const removeButton = (props.customizeOn && props.inItinerary)? (
            <div className = "dot-object-remove-button image-button-s4"
                style = {{
                    backgroundImage: removeButtonImage,
                    margin: offset
                }}
                onClick = { (props.dotRemoveClick)? (event) => { props.dotRemoveClick(event, dotIndex, null); } : () => {} }
            >
            </div>
        ) : null;

        // Left button
        const leftButtonImage = getStaticPath("/images/common/triangle-narrow-left-white.png");
        const leftButton = (props.customizeOn && props.inItinerary && index !== 0)? (
            <div className = "dot-object-left-button image-button-s5"
                style = {{
                    backgroundImage: leftButtonImage,
                    margin: offset
                }}
                onClick = { (props.dotLeftClick)? (event) => { props.dotLeftClick(event, dotIndex, null); } : () => {} }
            >
            </div>
        ) : null;

        // Right button
        const rightButtonImage = getStaticPath("/images/common/triangle-narrow-right-white.png");
        const rightButton = (props.customizeOn && props.inItinerary && index !== (props.itinerary.length - 1))? (
            <div className = "dot-object-right-button image-button-s5"
                style = {{
                    backgroundImage: rightButtonImage,
                    margin: offset
                }}
                onClick = { (props.dotRightClick)? (event) => { props.dotRightClick(event, dotIndex, null); } : () => {} }
            >
            </div>
        ) : null;

        // Information button
        const informationButtonImage = getStaticPath("/images/common/information-white.png");
        const informationButton = (props.informationOn)? (
            <div className = "dot-object-information-button image-button-s2"
                style = {{
                    backgroundImage: informationButtonImage
                }}
                onClick = {() => {props.dotClick(props.dotIndex); }}
            >
            </div>
        ) : null;

        // Custom style
        let dotContainerAddedStyle = {};
        if (offset !== null) {
            dotContainerAddedStyle = {
                width: props.dotContainerWidth,
                height: props.dotContainerWidth,
                padding: offset
            };
        }

        const dotContainerStyle = Object.assign({}, dotContainerAnimationStyle, dotContainerAddedStyle);
        //console.log("DotFunctions / DotList / render - dotContainerStyle = ", dotContainerStyle);

        // Return an individual item
        return (
            <div key = {"dot-object-container-" + dotIndex.toString()}
                className = {props.dotConnectAnimationClass}
                ref = {ref}
                style = {dotContainerStyle}
            >
                <Dot
                    colorMode = {props.colorMode}
                    
                    key = {"dot-object-" + dotIndex.toString()}                
                    index = {index}
                    dotIndex = {dotIndex}
                    dot = {dot}
                    selected = {selected}
                    hovered = {hovered}
                    dotOpacity = {dotOpacity}
                    dotIndexOn = {props.dotIndexOn}
                    dotIndexOpacity = {props.dotIndexOpacity}
                    mediaURL = {mediaURL}
                    options = {options}
                    dotClick = {props.dotClick}
                    dotHoverOn = {props.dotHoverOn}
                    dotHoverOff = {props.dotHoverOff}
                    inItinerary = {props.inItinerary}
                    estimatedClosure = {estimatedClosure}
                />
                {addButton}
                {removeButton}
                {leftButton}
                {rightButton}
                {informationButton}
            </div>
        )
    });

    return (
        <div className = "dot-object-list">
            {dotList}
        </div>
    )
}


export { Dot, DotList };