// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Functions
import {
    getStaticPath,
    getMediaProperty,
    freezeBody,
    unfreezeBody
} from "js/Functions";

// Redux
import {
    storeDotTag,
    storeNewDotTag,
    storeWarningAlert
} from "actions";

// Axios
import {
    postDotTagManage
} from "requests";

// CSS
import "./DotTag.scss";


class DotTag extends Component {
    constructor(props){
        super(props);

        // Max number of dot tags
        this.maxNumDotTags = 18;

        // Modal DOM node
        this.modalRef = React.createRef();

        // Input DOM node
        this.inputRef = React.createRef();

        // Placeholder for input
        this.inputPlaceholder = "Enter a New or Existing Tag";

        // Initialize state
        this.state = {
            expanded: false
        };

        // Bind functions
        this.removeDotTag = this.removeDotTag.bind(this);
        this.inputOnFocus = this.inputOnFocus.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.inputOnSubmit = this.inputOnSubmit.bind(this);
        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        //console.log("DotTag / componentDidUpdate - remount");

        // Freeze or unfreeze background
        if (this.props.dotTag.modalOn === true) {
            freezeBody();
        }
        if (this.props.dotTag.modalOn === false) {
            unfreezeBody();
        }

        // Add event listeners
        this.inputRef.current.addEventListener("keydown", this.inputOnSubmit);
        document.addEventListener("keydown", this.escClick, false);
        document.addEventListener("mousedown", this.clickOutside);
    }

    componentWillUnmount() {
        // Remove event listeners
        this.inputRef.current.removeEventListener("keydown", this.inputOnSubmit);
        document.removeEventListener("keydown", this.escClick, false);
        document.removeEventListener("mousedown", this.clickOutside);
    }

    inputOnFocus(event) {
        event.target.placeholder = "";
    }

    inputOnBlur(event) {
        event.target.placeholder = this.inputPlaceholder;
    }

    inputOnSubmit(event) {
        const keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            //console.log("DotTag / inputOnSubmit");

            if (this.props.dotTag.tags.length >= this.maxNumDotTags) {
                this.props.storeWarningAlert({
                    message: "Max Number of DotTags Reached. Remove Existing DotTags to Add New.",
                    on: true
                });
            }
            else {
                const axiosCallback = (response) => {
                    //console.log("DotTag / inputOnSubmit - response = ", response);

                    // Add new tag to the list of tags
                    const newDotTags = this.props.dotTag.tags.slice();
                    newDotTags.push(response.data.content);

                    // Update global tag object
                    this.props.storeDotTag(
                        {
                            modalOn: true,
                            type: this.props.dotTag.type,
                            mode: this.props.dotTag.mode,
                            id: this.props.dotTag.id,
                            dot: this.props.dotTag.dot,
                            tags: newDotTags
                        }
                    );

                    // Update global tag object
                    this.props.storeNewDotTag(
                        {
                            operation: "add",
                            type: this.props.dotTag.type,
                            mode: this.props.dotTag.mode,
                            id: this.props.dotTag.id,
                            tag: response.data.content
                        }
                    );

                    // Clear input
                    this.inputRef.current.value = "";
                };

                // Request type
                let requestType = null;
                if (this.props.dotTag.mode === "save") {
                    requestType = (this.props.dotTag.type === "filter")?
                        "user_save" : "dot_save";
                }
                else if (this.props.dotTag.mode === "contribute") {
                    requestType = (this.props.dotTag.type === "filter")?
                        "user_contribution" : "dot_contribution";
                }
                else if (this.props.dotTag.mode === "everyday") {
                    requestType = (this.props.dotTag.type === "filter")?
                        "user_everyday" : "dot_contribution";
                }
                else if (this.props.dotTag.mode === "complete") {
                    requestType = (this.props.dotTag.type === "filter")?
                        "user_completion" : "dot_completion";
                }
                else {
                    console.log("DotTag / removeDotTag - wrong tag mode");
                }

                // Data to send
                const dataJSON = (this.props.dotTag.type === "filter")?
                {
                    mode: "add",
                    tag_type: requestType,
                    tag_name: this.inputRef.current.value
                } : {
                    mode: "add",
                    id: this.props.dotTag.id,
                    tag_type: requestType,
                    tag_name: this.inputRef.current.value
                };

                // Send data to server to create a new tag
                postDotTagManage(this.props.userInfo.username, dataJSON)
                .then(axiosCallback)
                .catch(
                    (response) => {
                        console.log("[WARNING] DotTag / inputOnSubmit - ", response);
                    }
                );
            }
        }
    }

    removeDotTag(event, tag, index) {
        const axiosCallback = (response) => {
            //console.log("DotTag / removeDotTag - response = ", response);

            // Add new dotTag to the list of dotTags
            const newDotTags = this.props.dotTag.tags.slice();

            // Remove dotTag
            newDotTags.splice(index, 1);

            // Update global dotTag object
            this.props.storeDotTag(
                {
                    modalOn: true,
                    type: this.props.dotTag.type,
                    mode: this.props.dotTag.mode,
                    id: this.props.dotTag.id,
                    dot: this.props.dotTag.dot,
                    tags: newDotTags
                }
            );

            // Update global tag object
            this.props.storeNewDotTag(
                {
                    operation: "remove",
                    type: this.props.dotTag.type,
                    mode: this.props.dotTag.mode,
                    id: this.props.dotTag.id,
                    tag: response.data.content
                }
            );
        };

        // Request type
        let requestType = null;
        if (this.props.dotTag.mode === "save") {
            requestType = (this.props.dotTag.type === "filter")?
                "user_save" : "dot_save";
        }
        else if (this.props.dotTag.mode === "contribute") {
            requestType = (this.props.dotTag.type === "filter")?
                "user_contribution" : "dot_contribution";
        }
        else if (this.props.dotTag.mode === "everyday") {
            requestType = (this.props.dotTag.type === "filter")?
                "user_everyday" : "dot_contribution";
        }
        else if (this.props.dotTag.mode === "complete") {
            requestType = (this.props.dotTag.type === "filter")?
                "user_completion" : "dot_completion";
        }
        else {
            console.log("DotTag / removeDotTag - wrong tag mode");
        }

        // Data to send
        const dataJSON = (this.props.dotTag.type === "filter")?
        {
            mode: "remove",
            tag_type: requestType,
            tag_name: tag.tag_name
        } : {
            mode: "remove",
            id: this.props.dotTag.id,
            tag_type: requestType,
            tag_name: tag.tag_name
        };

        // Send data to server to create a new tag
        postDotTagManage(this.props.userInfo.username, dataJSON)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] DotTag / inputOnSubmit - ", response);
            }
        );
    }

    escClick(event) {
        // Disappear tag modal on pressing esc
        if (this.props.dotTag.modalOn === true && event.keyCode === 27) {
            this.closeModal();
        }
    }

    clickOutside(event) {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.closeModal();
        }
    }

    closeModal() {
        this.props.storeDotTag({ modalOn: false, type: null, mode: null, id: null, dot: null, tags: [] });

        this.setState({
            expanded: false
        });
    }

    render() {
        //console.log("DotTag / render - this.props = ", this.props);
        //console.log("DotTag / render - this.props.dotTag.tags = ", this.props.dotTag.tags);

        // Tags
        const tagItems = this.props.dotTag.tags.map(
            (tag, index) => {
                return(
                    <div key = {"dot-tag-item-" + index.toString()}
                        className = "dot-tag-modal-content__item"
                    >
                        <div className= "dot-tag-modal-content__item-image image-contain"
                            style = {{
                                backgroundImage: (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/tag-light-blue.png") :
                                    getStaticPath("/images/common/tag-blue.png")
                            }}
                        >
                        </div>
                        <span
                            className = {(this.props.colorMode === "day")?
                                    "dot-tag-modal-content__item-text k4" :
                                    "dot-tag-modal-content__item-text w4"}
                        >
                            {tag.tag_name[0].toUpperCase() + tag.tag_name.slice(1)}
                        </span>
                        <div className = "dot-tag-modal-content__item-remove image-contain"
                            style = {{
                                backgroundImage: (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/trash-can-light-blue.png") :
                                    getStaticPath("/images/common/trash-can-blue.png")
                            }}
                            onClick = { (event) => { this.removeDotTag(event, tag, index)} }
                        >
                        </div>
                    </div>
                );
            }
        );

        const tags = (this.props.dotTag.tags.length > 0)? (
            <div className = "dot-tag-modal-content__items">
                {tagItems}
            </div>
        ) : null;

        // Dot name
        const dotName = (this.props.dotTag.dot !== null)?
        (
            <div className = {(this.props.colorMode === "day")?
                    "dot-tag-modal-content__title k4" :
                    "dot-tag-modal-content__title w4"}
            >
                {this.props.dotTag.dot.name}
            </div>
        ) : null;

        // Dot media
        let mediaURL = null;
        let mediaClass = null;
        if (this.props.dotTag.dot !== null) {
            // Media info
            const mediaInfo = this.props.dotTag.dot.media[0];

            if (mediaInfo.type === "video") {
                if ("t" in mediaInfo.files) {
                    mediaURL = getMediaProperty(mediaInfo, "xs", "url", true);
                    mediaClass = (this.props.colorMode === "day")?
                        "dot-tag-modal-content__image image-cover" :
                        "dot-tag-modal-content__image image-cover";
                }
                else {
                    mediaURL = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/video-black.png", true) :
                        getStaticPath("/images/common/video-white.png", true);

                    mediaClass = (this.props.colorMode === "day")?
                        "dot-tag-modal-content__video image-actual" :
                        "dot-tag-modal-content__video image-actual";
                }
                // else {
                //     mediaURL = getMediaProperty(mediaInfo, "xs", 'url', true);
                //     mediaClass = (this.props.colorMode === "day")?
                //         "dot-tag-modal-content__image image-cover" :
                //         "dot-tag-modal-content__image image-cover";
                // }
            }
            else {
                mediaURL = getMediaProperty(mediaInfo, "s", "url", true);
                mediaClass = (this.props.colorMode === "day")?
                    "dot-tag-modal-content__image image-cover" :
                    "dot-tag-modal-content__image image-cover";
            }
        }

        // Shadow image
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette-weak.png");

        // Collection name
        let collectionName = null;
        if (this.props.dotTag.mode === "save") {
            collectionName = "Bucketed";
        }
        else if (this.props.dotTag.mode === "contribute") {
            collectionName = "Authored";
        }
        else if (this.props.dotTag.mode === "everyday") {
            collectionName = "Everyday";
        }
        else if (this.props.dotTag.mode === "complete") {
            collectionName = "Completed";
        }

        return (
            <div className = "dot-tag-modal"
                style = {{
                    display: (this.props.dotTag.modalOn)? "block" : "none"

                }}
            >
                <div className = {(this.props.colorMode === "day")?
                            "dot-tag-modal-content modal-day" :
                            "dot-tag-modal-content modal-night"}
                    ref = {this.modalRef}
                >
                    <div className = "dot-tag-modal-content__cancel image-button-weaker-s3"
                        style = {{
                            backgroundImage:  (this.props.colorMode === "day")?
                                getStaticPath("/images/common/cancel-black.png") :
                                getStaticPath("/images/common/cancel-white.png")
                        }}
                        onClick = {this.closeModal}
                    >
                    </div>

                    {dotName}

                    <div className = {mediaClass}
                        style = {{ backgroundImage: mediaURL }}
                    >
                        <div className = "dot-tag-modal-content__shadow"
                            style = {{ backgroundImage: shadowImage }}
                        >
                        </div>
                    </div>

                    <div className = {
                        (this.props.dotTag.type === "filter")?
                            (
                                (this.props.colorMode === "day")?
                                    "dot-tag-modal-content__title k3" :
                                    "dot-tag-modal-content__title w3"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "dot-tag-modal-content__sub-title k4" :
                                    "dot-tag-modal-content__sub-title w4"
                            )
                        }
                    >
                        {
                            (this.props.dotTag.type === "filter")?
                                "" + collectionName + " Collections" : (
                                    (this.props.dotTag.tags.length > 0)?
                                        "Collection Tags" : "Add a Collection Tag"
                                )
                        }
                    </div>

                    {tags}

                    <input ref = {this.inputRef}
                        id = "dot-tag-modal-input"
                        className = {(this.props.colorMode === "day")?
                            "dot-tag-modal-content__input input-s3 input-day" :
                            "dot-tag-modal-content__input input-s3 input-night"}
                        placeholder = {(this.props.dotTag.type === "filter")?
                            "Enter a New or Existing Tag" :
                            "Enter a New or Existing Tag"
                        }
                        onFocus = {this.inputOnFocus}
                        onBlur = {this.inputOnBlur}
                    />
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        dotTag: state.tag.dotTag,
        newDotTag: state.tag.newDotTag
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ storeDotTag, storeNewDotTag, storeWarningAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DotTag);
