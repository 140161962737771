const boardsAPI = "api/boards/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const getMultiBoardComments = (board_ids) => {
	let URL = rootURL + boardsAPI + "fetch/multiple-boards/?board_ids=" + board_ids;

    return window.axiosCSRF.get(URL);
};

export const getSingleBoardComments = (boardID, numCommentsLoaded) => {
	let URL = rootURL + boardsAPI + "fetch/single-board/?board_id=" + boardID + 
        "&num_comments_loaded=" + numCommentsLoaded;

    return window.axiosCSRF.get(URL);
};

export const getSingleCommentReplies = (commentID, numRepliesLoaded) =>  {
	let URL = rootURL + boardsAPI + "fetch/comment-replies/?comment_id=" + commentID +
	   "&num_replies_loaded=" + numRepliesLoaded;

    return window.axiosCSRF.get(URL);
};

export const postCreateComment = (dataJSON) => {
	let URL = rootURL + boardsAPI + "comments/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const patchEditComment = (dataJSON, commentID) => {
	let URL = rootURL + boardsAPI + "comments/" + commentID + "/";

    return window.axiosCSRF.patch(URL);
};

export const deleteComment = (commentID) => {
	let URL = rootURL + boardsAPI + "comments/" + commentID + "/";

    return window.axiosCSRF.delete(URL);
};
