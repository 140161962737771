// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

// Modules
import moment from "moment-timezone";

// Components
import { initTime } from "js/Time";
//import ItineraryOverview from "containers/ItineraryView";
import TimeBar from "components/TimeBar";
import { DayInfo } from "components/DayInfo";
import { OpenMap, GoogleMap, getAverageLocation } from "components/Map";
import { Gallery, mediaDimensions } from "components/Gallery";
//import Board from "components/Board";

// Websocket
import {
    //addWebSocketGroups,
    //removeWebSocketGroups
} from "js/WebSocketFunctions";

// Redux 
import {
    storeSchedule, 
    storeWarningAlert
} from "actions";

// Trip functions
import {
    dotIDsToIndices,
    updateTimesSetState, 
    updateTimes,
    updateDate,
    updateDayInfo,
    resetAnimations,    
    dotClick,
    dotHoverOn,
    dotHoverOff,
    endHoverOn,
    endHoverOff
} from "js/TripFunctions";

import { fetchWeather } from "components/DayInfo";

// Map functions
import {
    getDirection
} from "components/Map";

// Functions
import {
    getStaticPath,
    //getDateShortText,
    getMediaProperty,
    sortMedia,
    formatDuration,
    formatDistance,
    //formatNumbers,
    getTransitImage,
    getDirectionImageText,
    freezeBody,
    unfreezeBody,
    debounce
} from "js/Functions";

// Axios
import {
    //getItinerary,
} from "requests";

// CSS
import "./Schedule.scss";


class Schedule extends Component {
    // Constructor receives itineraryID as the prop
    constructor (props) {
        super (props);
        //console.log("Itinerary / constructor - props = ", props);

        // Modal DOM node
        this.modalRef = React.createRef();
        this.unscrollRef = React.createRef();

        // Margin width
        this.marginWidth = 10;
        this.footerHeight = 20;
        this.defaultUnscrollHeight = 150;
        this.modalMinWidth = 300;
        this.modalMaxWidth = 700;

        // Map Mode (google / open)
        this.mapSource = "open";
        this.mapMode = "itinerary";

        // Map settings
        this.mapZoom = 10;
        this.mapHeight = 280;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // User ratings / difficulties settings
        this.numThresholdRatings = 5;
        this.numThresholdDifficulties = 5;

        // Transit opacity
        this.transitOnOpacity = 0.9;
        this.transitOffOpacity = 0.4;

        // Map DOM IDs
        this.mapNodeID = "schedule-map";
        this.mapContainerNodeID = "schedule-map-container";
        this.mapButtonsNodeID = "schedule-map-buttons";

        // WebSocket Prefix
        //this.webSocketGroupPrefix = "itinerary_board";

        // Weather on
        this.showWeather = false;

        // Day info on
        const dayInfoOn = (this.props.browserWidth <= 5)? false : true;

        // Load from global
        const itineraryLoaded = (this.props.itineraryInfo !== null && this.props.itineraryInfo !== undefined)? (
            (this.props.schedule.modalOn)? true : false) : false;
        //console.log("Schedule / constructor - this.itineraryLoaded = ", this.itineraryLoaded);

        // Timezones and moments
        let timezone = null;
        let startTimezone = null;
        let endTimezone = null;
        let startMoment = null;
        let endMoment = null;
        let actualEndMoment = null;
        if (itineraryLoaded) {
            timezone = this.props.itineraryParams.timezone;
            startTimezone = this.props.itineraryParams.startTimezone;
            endTimezone = this.props.itineraryParams.endTimezone;
            startMoment = moment(this.props.itineraryInfo.start_time).tz(startTimezone);
            endMoment = moment(this.props.itineraryInfo.planned_end_time).tz(endTimezone);
            actualEndMoment = moment(this.props.itineraryInfo.end_time).tz(endTimezone);
        }

        // Initialize states
        this.state = {
            // Selected / hovered / selectedChild / hoveredChild / displayChildren
            selected: null,
            hovered: null,
            selectedChild: null,
            hoveredChild: null,
            displayChildren: null, 

            // Selected path
            //selectedPath: null,

            // Direction info
            directionInfo: null,

            // Direction on
            directionOn: false,

            // Next and prev on
            nextOn: false,
            prevOn: false,

            // Day info on
            dayInfoOn: dayInfoOn,

            // Itinerary information
            //itineraryInfo: null,
            itinerary: (itineraryLoaded)? dotIDsToIndices(
                this.props.itineraryInfo.route,
                this.props.itineraryInfo.dot.trip_extension.children
            ) : null,

            // Comments info
            //commentsInfo: null,

            // Locations and timezones
            location: (itineraryLoaded)? this.props.itineraryParams.location : null,
            timezone: timezone,
            startLocation: (itineraryLoaded)? this.props.itineraryParams.startLocation : null,
            endLocation: (itineraryLoaded)? this.props.itineraryParams.endLocation : null,
            startLocationName: (itineraryLoaded)? this.props.itineraryParams.startLocationName : null,
            endLocationName: (itineraryLoaded)? this.props.itineraryParams.endLocationName : null,
            startTimezone: startTimezone,
            endTimezone: endTimezone,

            // Highlight dots (indices of highlights in the dotsInfo list)
            highlightIndices: null,

            // Start and end times
            startMoment: startMoment,
            endMoment: endMoment,
            actualEndMoment: actualEndMoment,

            // Dot times
            dotTimes: (itineraryLoaded)? this.props.itineraryInfo.dot_start_times_local_formatted : null,

            // Transit times and modes
            transitTimes: (itineraryLoaded)? this.props.itineraryInfo.transit_time : null,
            transitTimeValues: (itineraryLoaded)? this.props.itineraryInfo.transit_time_value : null,
            transitModes: (itineraryLoaded)? this.props.itineraryInfo.transit_mode : null,

            // Day times
            sunTimes: null,
            moonTimes: null,
            dayTime: null,
            offsetTime: null,
            totalTime: null,
            startEndTimeValues: null,
            hour00Moment: null,
            hour24Moment: null,

            // Astronomy
            sunMoonOn: (itineraryLoaded)? this.props.itineraryParams.sunMoonOn : true,
            moonPhase: (itineraryLoaded)? this.props.itineraryParams.moonPhase : null,
            moonPhaseDigits: (itineraryLoaded)? this.props.itineraryParams.moonPhaseDigits : null,

            // Weather
            weather: (itineraryLoaded)? this.props.itineraryParams.weather : null,
            weatherOn: (itineraryLoaded)? this.props.itineraryParams.weatherOn : true,
            weatherIcon: (itineraryLoaded)? this.props.itineraryParams.weatherIcon : null,
            tempHigh: (itineraryLoaded)? this.props.itineraryParams.tempHigh : null,
            tempLow: (itineraryLoaded)? this.props.itineraryParams.tempLow : null,
            precipOn: (itineraryLoaded)? this.props.itineraryParams.precipOn : null,
            precipType: (itineraryLoaded)? this.props.itineraryParams.precipType : null,
            precipProb: (itineraryLoaded)? this.props.itineraryParams.precipProb : null,

            // Map switch 
            mapOn: true,

            // Map action triggers
            mapZoomInAnimation: false,
            mapZoomOutAnimation: false,
            mapZoomHikeAnimation: false,
            mapRefreshAnimation: false,

            // Window width
            windowWidth: window.innerWidth,

            // Websocket Group
            webSocketGroup: null,

            // Scroll
            checkScroll: false,

            // Re-render
            rerender: false
        };

        // Bind setState and onScroll functions
        this.setState = this.setState.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 100);

        // Bind load itinerary
        //this.loadItinerary = this.loadItinerary.bind(this);

        // Bind dot click and hover function
        this.dotClick = dotClick.bind(this);
        this.dotClickReset = this.dotClickReset.bind(this);
        this.dotHoverOn = dotHoverOn.bind(this);
        this.dotHoverOff = dotHoverOff.bind(this);
        this.endHoverOn = endHoverOn.bind(this);
        this.endHoverOff = endHoverOff.bind(this);

        // Bind prev and next click
        this.prevClick = this.prevClick.bind(this);
        this.nextClick = this.nextClick.bind(this);

        // Bind date and time change related functions
        this.updateTimes = updateTimes.bind(this);
        this.updateTimesSetState = updateTimesSetState.bind(this);
        this.updateDate = updateDate.bind(this);
        this.updateDayInfo = updateDayInfo.bind(this);
        this.fetchWeather = fetchWeather.bind(this);

        // Bind map animation function
        this.resetAnimations = resetAnimations.bind(this);

        // Bind like and save button functions
        //this.shareButtonClick = this.shareButtonClick.bind(this);

        // Time Bar Function
        this.timeBarClick = this.timeBarClick.bind(this);

        // Bind modal functions
        this.resetSchedule = this.resetSchedule.bind(this);
        this.escClick = this.escClick.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    // Main JSX
    render() {
        // Only when itinerary and dot info are ready
        if (this.props.itineraryInfo !== null && this.props.itineraryParams !== null && this.state.startMoment !== null) {
            // User logged in
            //const loggedIn = (!!localStorage.token && this.props.userInfo !== null);

            // Layouts
            const layoutSmall = (this.props.browserWidth <= 2);
            const layoutMedium = (this.props.browserWidth <= 6);
            const layoutVertical = (this.props.browserWidth <= 5);

            // Effective browser width
            const modalWidth = this.props.browserWidthPixels - 2 * this.marginWidth;
            const effectiveBrowserWidth = Math.max(Math.min(modalWidth, this.modalMaxWidth), this.modalMinWidth);
            //console.log("Schedule / render - modalWidth = ", modalWidth);
            //console.log("Schedule / render - effectiveBrowserWidth = ", effectiveBrowserWidth);

            // Scroll section height
            const scrollHeight = (this.modalRef.current !== null && this.unscrollRef.current !== null)?
                (window.innerHeight - this.unscrollRef.current.offsetHeight - this.footerHeight - 2 * this.marginWidth) : null;
            //console.log("scrollHeight = ", scrollHeight);


            /*
            ============================================================================================
                Map
            ============================================================================================
            */

            let scheduleMap = null;


            /*
            ============================================================================================
                Path
            ============================================================================================
            */

            let selectedPath = null;
            if (this.state.selected === null && this.state.nextOn) {
                selectedPath = 0;
            }
            else if (this.state.selected !== null && (this.state.nextOn || this.state.prevOn)) {
                selectedPath = (this.state.nextOn)?
                    this.state.itinerary.indexOf(this.state.selected) + 1 :
                    this.state.itinerary.indexOf(this.state.selected);
            }

            const direction = (
                (selectedPath !== null) &&
                (this.state.itinerary !== null) &&
                (this.props.itineraryInfo !== null) &&
                (this.state.directionInfo !== null)
            )?
            getDirection(
                selectedPath,
                this.state.itinerary,
                this.props.itineraryInfo.dots,
                this.state.directionInfo,
                (this.props.itineraryInfo.dot.type === "RT")? "route" : "trip"
            ) : null;

            //console.log("Schedule / render - direction = ", direction);
            //console.log("Schedule / render - selectedPath = ", selectedPath);
            //console.log("Schedule / render - this.state.itinerary = ", this.state.itinerary);
            //console.log("Schedule / render - this.props.itineraryInfo = ", this.props.itineraryInfo);
            //console.log("Schedule / render - this.state.directionInfo = ", this.state.directionInfo);

            // Using Google maps
            if (this.mapSource === "google") {
                // Only when google object and locations are ready
                if (this.props.google) {
                    // Set the props for Map component
                    const mapProps = {
                        // Google
                        google: this.props.google,

                        // Map mode
                        mapMode: this.mapMode,

                        // DOM node IDs
                        mapNodeID: this.mapNodeID,
                        mapContainerNodeID : this.mapContainerNodeID,
                        buttonsNodeID: this.mapButtonsNodeID,
                        startInputNodeID: this.startInputNodeID,
                        endInputNodeID: this.endInputNodeID,

                        // Map height
                        mapHeight: this.mapHeight,
                        mapMinHeight: this.mapMinHeight,
                        mapMaxHeight: this.mapMaxHeight,
                        mapHeightIncrement: this.mapHeightIncrement,

                        // Dots
                        dotsInfo: this.props.itineraryInfo.dot.trip_extension.children,

                        // Itinerary
                        itinerary: this.state.itinerary,

                        // Selected / hovered / selectedChild / hoveredChild / displayChildren
                        selected: this.state.selected, 
                        hovered: this.state.hovered,
                        selectedChild: this.state.selectedChild, 
                        hoveredChild: this.state.hoveredChild,
                        displayChildren: null, 

                        // Start and end locations
                        startLocation: this.state.startLocation,
                        endLocation: this.state.endLocation,

                        // Action triggers
                        mapZoomInAnimation: this.state.mapZoomInAnimation,
                        mapZoomOutAnimation: this.state.mapZoomOutAnimation,
                        mapZoomHikeAnimation: this.state.mapZoomHikeAnimation,
                        mapRefreshAnimation: this.state.mapRefreshAnimation,
                        resetAnimations: this.resetAnimations,

                        // Dot click and hover callback
                        dotClick: this.dotClickReset,
                        dotHoverOn: this.dotHoverOn,
                        dotHoverOff: this.dotHoverOff,
                        endHoverOn: this.endHoverOn,
                        endHoverOff: this.endHoverOff,

                        // Parse callbacks (modify top states)
                        setParkingLocation: null,
                        setStartLocation: null, //this.setStartLocation,
                        setEndLocation: null, //this.setEndLocation,

                        // Map type
                        mapType: (this.props.itineraryInfo.dot.map_type === null)?
                            "hybrid" : this.props.itineraryInfo.dot.map_type,

                        // Path polyline geometry
                        path: (direction === null)? null : direction.geometry,

                        // SetState
                        setState: this.setState
                    }
                    //console.log("Schedule / render - mapProps = ", mapProps);

                    // Get the Map component
                    scheduleMap = (<GoogleMap {...mapProps}/>);
                }
            }
            // Using Open Street Maps
            else if (this.mapSource === "open") {
                if ((this.props.itineraryInfo !== null) && (this.state.directionInfo !== null) && (this.state.itinerary !== null)) {
                    const averageLocation = getAverageLocation(
                        this.state.itinerary,
                        this.props.itineraryInfo.dot.trip_extension.children
                    );

                    // Set the props for Map component
                    const mapProps = {
                        // Map refresh
                        mapRefresh: false,

                        // Google
                        google: this.props.google,

                        // Map mode
                        mapMode: this.mapMode,

                        // DOM node IDs
                        mapNodeID: this.mapNodeID,
                        startInputNodeID: null, //this.startInputNodeID,
                        endInputNodeID: null, //this.endInputNodeID,

                        // Map height
                        mapHeight: this.mapHeight,
                        mapMinHeight: this.mapMinHeight,
                        mapMaxHeight: this.mapMaxHeight,
                        mapHeightIncrement: this.mapHeightIncrement,

                        // Dots
                        dotsInfo: this.props.itineraryInfo.dot.trip_extension.children,

                        // Itinerary
                        itinerary: this.state.itinerary,

                        // Selected / hovered / selectedChild / hoveredChild / displayChildren
                        selected: this.state.selected, 
                        hovered: this.state.hovered,
                        selectedChild: this.state.selectedChild, 
                        hoveredChild: this.state.hoveredChild,
                        displayChildren: this.state.displayChildren, 

                        // Start and end locations
                        startLocation: this.state.startLocation,
                        endLocation: this.state.endLocation,

                        // Dot click and hover callback
                        dotClick: this.dotClickReset,
                        //dotAddClick: this.dotAddClick,
                        //dotRemoveClick: this.dotRemoveClick,
                        //dotHoverOn: this.dotHoverOn,
                        //dotHoverOff: this.dotHoverOff,
                        //endHoverOn: this.endHoverOn,
                        //endHoverOff: this.endHoverOff,

                        // Parse callbacks (modify top states)
                        setParkingLocation: null,
                        setStartLocation: null, //this.setStartLocation,
                        setEndLocation: null, //this.setEndLocation,

                        // Map center / zoom / type
                        mapCenter: averageLocation,
                        mapZoom: this.mapZoom,
                        mapType: (this.props.itineraryInfo.dot.map_type === null)?
                            "hybrid" : this.props.itineraryInfo.dot.map_type,

                        // Path polyline geometry
                        path: (direction === null)? null : direction.geometry,

                        // SetState
                        setState: this.setState
                    }
                    //console.log("Schedule / render - mapProps = ", mapProps);

                    // Get the Map component
                    scheduleMap = (<OpenMap {...mapProps}/>);
                }
            }


            /*
            ============================================================================================
                Title and Area
            ============================================================================================
            */

            const scheduleTitle = null;
            /*
            const scheduleTitle = (
                <div id = "schedule-title-container">
                    <div id = "schedule-title"
                        className = {(this.props.colorMode === "day")? "k2" : "w2"}
                    >
                        {this.props.itineraryInfo.dot.title}
                    </div>
                    <div id = "schedule-area"
                        className = {(this.props.colorMode === "day")? "lb4" : "b4"}
                    >
                        {this.props.itineraryInfo.dot.area}
                    </div>
                </div>
            );
            */


            /*
            ============================================================================================
                DayInfo (weather / sun / moon)
            ============================================================================================
            */

            // Only when the sunTimes and moonPhase are ready ro render
            let dayInfo = null;
            if ((this.state.sunTimes) && (this.state.moonPhase)) {
                const dayInfoProps = {
                    colorMode: this.props.colorMode,
                    weatherOn: this.state.weatherOn,
                    weatherIcon: this.state.weatherIcon,
                    precipOn: this.state.precipOn,
                    precipType: this.state.precipType,
                    precipProb: this.state.precipProb,
                    tempHigh: this.state.tempHigh,
                    tempLow: this.state.tempLow,
                    sunTimes: this.state.sunTimes,
                    sunMoonOn: this.state.sunMoonOn,
                    moonIcon: this.state.moonIcon,
                    moonPhase: this.state.moonPhase,
                    moonPhaseDigits: this.state.moonPhaseDigits
                }

                dayInfo = DayInfo(dayInfoProps);
            }
            else{
                dayInfo = null;
            }

            const scheduleDayInfo = (
                <div id = "schedule-date-dayinfo-container">
                    <div id = "schedule-date-dayinfo"
                        className = "clear-fix"
                    >
                        <div id = {(this.props.browserWidth <= 2)?
                                "schedule-date-column-small" : "schedule-date-column"
                            }
                            className = {(this.props.colorMode === "day")?
                                "font-cabin light-blue" : "font-cabin blue"}
                            onClick = { () => { this.setState({ dayInfoOn: !this.state.dayInfoOn }); }}
                        >
                            {this.state.startMoment.format("MMM Do YYYY")}
                        </div>

                        {
                            (layoutVertical)? 
                                null : (
                                    <div id = "schedule-modal-spacer">
                                    </div>
                                )
                        }

                        {
                            (layoutVertical)?
                            null : (
                                <div id = "schedule-dayinfo-column">
                                    <div id = "schedule-dayinfo">
                                        {dayInfo}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            );


            /*
            ============================================================================================
                Time Bar
            ============================================================================================
            */

            // set the props for TimeBar component
            const timeBarProps = {
                classPrefix: "schedule",
                itinerary: this.state.itinerary,
                startMoment: this.state.startMoment,
                endMoment: this.state.endMoment,
                dotsInfo: this.props.itineraryInfo.dot.trip_extension.children,
                transitTimes: this.state.transitTimes,
                transitTimeValues: this.state.transitTimeValues,
                transitModes: this.state.transitModes,
                selected: this.state.selected,
                hovered: this.state.hovered,
                screenWidth: this.props.browserWidthPixels,
                //displayHike: this.state.displayHike
                dotClick: this.dotClick,
                dotHoverOn: this.dotHoverOn,
                dotHoverOff: this.dotHoverOff
            };
            //console.log("Schedule / render - timeBarProps = ", timeBarProps);
                
            const timeBar = (this.state.selected !== null)?
                (<TimeBar {...timeBarProps} />) : null;

            /*
            ============================================================================================
                Time Header
            ============================================================================================
            */

            let timeHeader = null;

            if (this.state.selected !== null || this.state.nextOn === true) {
                // Get the item index
                const isStart = (this.state.selected === null);
                
                // Is first or last
                const index = (isStart)?
                    null : this.state.itinerary.indexOf(this.state.selected);

                // Previous item click
                const prevItemImage = (isStart)? null: (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/arrow-left-black.png") :
                        getStaticPath("/images/common/arrow-left-white.png")
                );

                const prevItem = (
                    <div id = "schedule-time-header-prev-item"
                        className = {(this.props.browserWidth <= 2)?
                            "schedule-time-header-prev-item-small image-button-s4" :
                            "schedule-time-header-prev-item image-button-s3"
                        }
                        style = {{ backgroundImage: prevItemImage }}
                        onClick = {(isStart)? null : this.prevClick}
                    >
                    </div>
                );

                // Next item click
                const nextItemImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-right-black.png") :
                    getStaticPath("/images/common/arrow-right-white.png");

                const nextItem = (
                    <div id = "schedule-time-header-next-item"
                        className = {(this.props.browserWidth <= 2)?
                            "schedule-time-header-next-item-small image-button-s4" :
                            "schedule-time-header-next-item image-button-s3"
                        }
                        style = {{ backgroundImage: nextItemImage }}
                        onClick = {this.nextClick}
                    >
                    </div>
                );

                // Line images
                const leftLineImage = (isStart)? null :
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/line/horizontal/dotted-middle-lightest-gray.png") :
                        getStaticPath("/images/line/horizontal/dotted-middle-darkest-gray.png")
                );

                const rightLineImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/line/horizontal/dotted-middle-lightest-gray.png") :
                    getStaticPath("/images/line/horizontal/dotted-middle-darkest-gray.png");

                // Transit image
                const leftTransitImage = (this.state.prevOn)?
                    getTransitImage(this.state.transitModes[index], this.props.colorMode, true, "white") :
                    getTransitImage(this.state.transitModes[index], this.props.colorMode, true, "white");
                const rightTransitImage = (this.state.nextOn)?
                    getTransitImage(this.state.transitModes[index + 1], this.props.colorMode, true, "white") :
                    getTransitImage(this.state.transitModes[index + 1], this.props.colorMode, true, "white");

                // Number image
                const numberImage = (isStart)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/number/single_black_S.png") :
                        getStaticPath("/images/number/single_white_S.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/number/single_red_" + (index + 1) + ".png") :
                        getStaticPath("/images/number/single_red_" + (index + 1) + ".png")
                );

                // Time header
                timeHeader = (
                    <div id = "schedule-modal-time-header">
                        <div id = "schedule-time-header"
                            className = "schedule-time-header"
                        >
                            <div id = "schedule-time-header-left"
                                className = "schedule-time-header-left"
                                style = {{ backgroundImage: leftLineImage }}
                            >
                                <div id = "schedule-time-header-transit-mode-left"
                                    className = "schedule-time-header-transit-mode-left image-contain"
                                    style = {{
                                        opacity: (this.state.prevOn)? this.transitOnOpacity : this.transitOffOpacity,
                                        backgroundImage: leftTransitImage
                                    }}
                                    onClick = {
                                        (this.state.prevOn)?
                                            () => { this.setState({ directionOn: true }); } : null
                                    }
                                >
                                </div>
                                <div id = "schedule-time-header-transit-time-left"
                                    className = {
                                        (this.state.prevOn)?
                                        (
                                            (this.props.colorMode === "day")?
                                                "schedule-time-header-transit-time-left k4" :
                                                "schedule-time-header-transit-time-left w4"
                                        ) : (
                                            (this.props.colorMode === "day")?
                                                "schedule-time-header-transit-time-left k4" :
                                                "schedule-time-header-transit-time-left w4"
                                        )
                                    }
                                    style = {{
                                        opacity: (this.state.prevOn)? this.transitOnOpacity : this.transitOffOpacity
                                    }}
                                    onClick = {
                                        (this.state.prevOn)?
                                            () => { this.setState({ directionOn: true }); } : null
                                    }
                                >
                                    {this.state.transitTimes[index]}
                                </div>
                            </div>
                            <div id = "schedule-time-header-right"
                                className = "schedule-time-header-right"
                                style = {{ backgroundImage: rightLineImage }}
                            >
                                <div id = "schedule-time-header-transit-mode-right"
                                    className = "schedule-time-header-transit-mode-right image-contain"
                                    style = {{
                                        opacity: (this.state.nextOn)? this.transitOnOpacity : this.transitOffOpacity,
                                        backgroundImage: rightTransitImage
                                    }}
                                    onClick = {
                                        (this.state.nextOn)?
                                            () => { this.setState({ directionOn: true }); } : null
                                    }
                                >
                                </div>
                                <div id = "schedule-time-header-transit-time-right"
                                    className = {
                                        (this.state.nextOn)?
                                        (
                                            (this.props.colorMode === "day")?
                                                "schedule-time-header-transit-time-right k4" :
                                                "schedule-time-header-transit-time-right w4"
                                        ) : (
                                            (this.props.colorMode === "day")?
                                                "schedule-time-header-transit-time-right k4" :
                                                "schedule-time-header-transit-time-right w4"
                                        )
                                    }
                                    style = {{
                                        opacity: (this.state.nextOn)? this.transitOnOpacity : this.transitOffOpacity
                                    }}
                                    onClick = {
                                        (this.state.nextOn)?
                                            () => { this.setState({ directionOn: true }); } : null
                                    }                                    
                                >
                                    {(isStart)? this.state.transitTimes[0] : this.state.transitTimes[index + 1]}
                                </div>
                            </div>

                            <div id = "schedule-time-header-number"
                                className = "schedule-time-header-number image-contain"
                                style = {{ backgroundImage: numberImage }}
                                onClick = {() => {
                                    this.setState({
                                        prevOn: false,
                                        nextOn: false
                                    });
                                }}
                            >
                            </div>

                            <div id = "schedule-time-header-timestamp-container"
                                className = "schedule-time-header-timestamp-container"
                            >
                                <div id = "schedule-time-header-timestamp"
                                    className = {(this.props.colorMode === "day")? 
                                        "schedule-time-header-timestamp-day w5" : 
                                        "schedule-time-header-timestamp-night k5"
                                    }
                                >
                                    {(isStart)? this.state.startMoment.format("H:mm a") : 
                                        this.state.dotTimes[index]}
                                </div>
                            </div>

                            <div id = "schedule-time-header-duration"
                                className = {(this.props.colorMode === "day")? 
                                    "schedule-time-header-duration k4" : 
                                    "schedule-time-header-duration w4"
                                }
                            >
                                {(isStart)? null : this.props.itineraryInfo.dot.trip_extension.children[this.state.selected].dot_extension.time}
                            </div>

                            {prevItem}

                            {nextItem}
                        </div>
                    </div>
                );
            }


            /*
            ============================================================================================
                Schedule Summary
            ============================================================================================
            */
            const scheduleSummary = null;

            /*
            (this.state.selected === null)? (
                <div className = "schedule-summary">
                    <div className = "schedule-overview">
                        {this.props.itineraryInfo.dot.overview}
                    </div>
                    <div className = "schedule-stats">
                        {"" + this.props.itineraryInfo.dots.length + " Dots"}
                    </div>
                </div>
            ) : null;
            */


            /*
            ============================================================================================
                Selected Item
            ============================================================================================
            */

            //console.log("Schedule / render - this.state.selected = ", this.state.selected);
            //console.log("Schedule / render - this.state.selected = ", this.state.itinerary);
            //console.log("Schedule / render - this.props.itineraryInfo.dots = ", this.props.itineraryInfo.dots);
            //console.log("Schedule / render - this.props.itineraryInfo.dots[this.state.selected] = ", this.props.itineraryInfo.dots[this.state.selected]);
            const scheduleItem = (this.state.selected === null)? 
            (
                <ScheduleItem 
                    key = {"schedule-modal-item-summary"}
                    isSummary = {this.state.nextOn === false}
                    colorMode = {this.props.colorMode}
                    layoutSmall = {layoutSmall}
                    layoutMedium = {layoutMedium}
                    layoutVertical = {layoutVertical}
                    browserWidth = {this.props.browserWidth}
                    effectiveBrowserWidth = {effectiveBrowserWidth}
                    itineraryInfo = {this.props.itineraryInfo}
                    dotInfo = {this.props.itineraryInfo.dot}
                    checkScroll = {this.state.checkScroll}
                    nextClick = {this.nextClick}
                />
            ) : (
                <ScheduleItem 
                    key = {"schedule-modal-item-" + this.state.selected}
                    isSummary = {false}
                    colorMode = {this.props.colorMode}
                    layoutSmall = {layoutSmall}
                    layoutMedium = {layoutMedium}
                    layoutVertical = {layoutVertical}
                    browserWidth = {this.props.browserWidth}
                    effectiveBrowserWidth = {effectiveBrowserWidth}
                    itineraryInfo = {this.props.itineraryInfo}
                    dotInfo = {this.props.itineraryInfo.dot.trip_extension.children[this.state.selected]}
                    checkScroll = {this.state.checkScroll}
                    setState = {this.setState}
                    nextClick = {this.nextClick}
                    prevClick = {this.prevClick}
                />
            );


            /*
            ============================================================================================
                Render
            ============================================================================================
            */
            
            return (
                <div className = "schedule-modal" 
                    style = {{
                        display: (this.props.schedule.modalOn)? "block" : "none"

                    }}
                >
                    {
                        (direction !== null && this.state.directionOn)?
                        (

                            <Direction
                                colorMode = {this.props.colorMode}
                                direction = {direction}
                                setState = {this.setState}
                            />
                        ) : null
                    }                
                    <div ref = {this.modalRef}
                        className = {(this.props.colorMode === "day")? 
                            "schedule-modal-content modal-day" : 
                            "schedule-modal-content modal-night"} 
                    >
                        <div ref = {this.unscrollRef}
                            id = "schedule-modal-unscroll"
                        >
                            <div className = "schedule-modal-content__cancel image-button-weaker-s3"
                                style = {{
                                    backgroundImage:  (this.props.colorMode === "day")?
                                        getStaticPath("/images/common/cancel-black.png") :
                                        getStaticPath("/images/common/cancel-white.png")
                                }}
                                onClick = {this.closeModal}
                            >
                            </div>

                            <div id = "schedule-modal-dayinfo"
                                className = "schedule-modal-content__title"
                            >
                                {scheduleDayInfo}
                            </div>


                            <div id = "schedule-modal-title">
                                {scheduleTitle}
                            </div>

                            {
                                (layoutVertical && this.state.dayInfoOn)?
                                (

                                    <div id = "schedule-modal-dayinfo-small">
                                        <div id = "schedule-dayinfo">
                                            {dayInfo}
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>

                        <div id = "schedule-modal-scroll"
                            style = {{
                                display: (scrollHeight === null)? "none" : "block",
                                maxHeight: scrollHeight
                            }}
                        >
                            <div id = "schedule-modal-map">
                                {scheduleMap}
                            </div>

                            <div id = {(this.props.browserWidth <= 2)?
                                    "schedule-modal-time-bar-small" : 
                                    "schedule-modal-time-bar"}
                            >
                                {timeBar}
                            </div>

                            {timeHeader}

                            <div id = "schedule-modal-summary">
                                {scheduleSummary}
                            </div>

                            <div id = "schedule-modal-item">
                                {scheduleItem}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }

    componentDidMount() {
        //console.log("Schedule / componentDidMount - mount");

        // Freeze of unfreeze background
        if (this.props.schedule.modalOn === true) {
            freezeBody();
        }
        if (this.props.schedule.modalOn === false) {
            unfreezeBody();
        }

        // Fetch itinerary and direction
        if (this.props.schedule.modalOn && this.props.itineraryInfo !== null && this.props.itineraryParams !== null) {
            //console.log("Itinerary / componentDidMount - this.props.itineraryInfo = ", this.props.itineraryInfo);

            // Initialize Time object
            initTime(this.props.itineraryInfo.dot.dot_extension.time_value);

            this.updateDayInfo(
                false,
                false,
                null
            );

            // Load directions
            this.loadDirection(
                this.props.itineraryInfo.dot.id,
                this.props.itineraryInfo.dots + 2,
                (this.props.itineraryInfo.dot.type === "RT")? "route" : "trip"
            );
        }

        // Add event listeners
        document.addEventListener("keydown", this.escClick, false);
        //document.addEventListener("mousedown", this.clickOutside);
        window.addEventListener("scroll", this.scrollListener);
    }
    
    componentWillUnmount() {
        // Remove event listeners
        document.removeEventListener("keydown", this.escClick, false);
        //document.removeEventListener("mousedown", this.clickOutside);
        window.removeEventListener("scroll", this.scrollListener);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.browserWidth !== this.props.browserWidth && this.props.browserWidth <= 5) {
            this.setState({ dayInfoOn: false });
        }

        if (prevState.dayInfoOn !== this.state.dayInfoOn) {
            this.setState({ rerender: !this.state.rerender });
        }        
    }


    /*
    ============================================================================================
        Load Itinerary
    ============================================================================================
    */

    /*
    loadItinerary(itineraryID) {
        //console.log("Schedule / loadItinerary - itineraryID = ", itineraryID);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Schedule / loadItinerary - response.data.content = ", response.data.content);

            // Initialize time object
            initTime(response.data.content.dot.dot_extension.time_value);

            // Get timezones
            const timezone = response.data.content.dot.timezone;
            const startTimezone = response.data.content.start_timezone;
            const endTimezone = response.data.content.end_timezone;

            // Get start and end moments
            const startMoment = moment(response.data.content.start_time).tz(startTimezone);
            const endMoment = moment(response.data.content.end_time).tz(endTimezone);
            //const plannedEndMoment = moment(response.data.content.planned_end_time).tz(endTimezone);
            //console.log("Schedule / loadItinerary - startMoment = ", startMoment);
            //console.log("Schedule / loadItinerary - endMoment = ", endMoment);
            //console.log("Schedule / loadItinerary - plannedEndMoment = ", plannedEndMoment);

            // Locations
            const location = response.data.content.dot.location;
            const startLocation = response.data.content.start_location;
            const endLocation = response.data.content.end_location;
            const startLocationName = response.data.content.start_location_name;
            const endLocationName = response.data.content.end_location_name;

            // Itinerary dot indices
            const itinerary = dotIDsToIndices(
                response.data.content.route,
                response.data.content.dot.trip_extension.children
            );
            //console.log("Schedule / loadItinerary - itinerary = ", itinerary);

            // Reserve for itinerary-specific board
            // Fetch board comments 
            //this.loadCommentsInfo(response.data.content.board.id);

            // Add relevant webSocket group
            //let webSocketGroup = [];
            //webSocketGroup.push(this.webSocketGroupPrefix + "_" + response.data.content.board.id);
            //addWebSocketGroups(webSocketGroup, window.webSocket);

            // Callback after retrieving the itinerary info
            const afterLoadingCallback = () => {
                //console.log("Schedule / loadItinerary - this.state = ", this.state);

                // Update day info
                this.updateTimes(
                    this.updateDayInfo.bind(this, null),
                    false
                );
            };

            // Set states and check if the logged in user follows the editor
            this.setState(
                {
                    // Itinerary information
                    itineraryInfo: response.data.content,
                    itinerary: itinerary,

                    // Locations and timezones
                    location: location,
                    timezone: timezone,
                    startLocation:  startLocation,
                    endLocation:  endLocation,
                    startLocationName: startLocationName,
                    endLocationName: endLocationName,
                    startTimezone: startTimezone,
                    endTimezone: endTimezone,

                    // Start and end times
                    startMoment: startMoment,
                    endMoment: endMoment,

                    // Dot and transit times
                    dotTimes: response.data.content.dot_start_times_local_formatted,
                    transitTimes: response.data.content.transit_time,
                    transitTimeValues: response.data.content.transit_time_value,
                    transitModes: response.data.content.transit_mode,

                    // Selected / hovered / selectedChild / hoveredChild
                    selected: null, //itinerary[0],
                    hovered: null,
                    selectedChild: null,
                    hoveredChild: null,
                    displayChildren: null

                    // More times
                    sunTimes : null,
                    moonTimes : null,
                    dayTime : null,
                    offsetTime: null,
                    totalTime: null,
                    startEndTimeValues: null,
                    hour00Moment: null,
                    hour24Moment: null,

                    // Astronomy
                    sunMoonOn: false,
                    moonPhase: null,
                    moonIcon: null,

                    // Weather
                    weather: null,
                    weatherOn: false,
                    weatherIcon: null,
                    tempHigh: null,
                    tempLow: null,
                    precipOn: null,
                    precipType: null,
                    precipProb: null,

                    // Websocket
                    webSocketGroup: webSocketGroup
                },
                afterLoadingCallback
            );
        };

        // Send get itinerary request
        getItinerary(itineraryID)
        .then(axiosCallback)
        .catch((response) => { console.log("Schedule / loadItinerary - Axios error ", response); });
    }
    */


    /*
    ============================================================================================
        Load Direction
    ============================================================================================
    */

    /*
    loadDirection(directionID) {
        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Schedule / loadDirection - response.data.content = ", response.data.content);

            // Update state
            this.setState(
                {
                    directionInfo: response.data.content
                }
            );
        };

        // Send get direction request
        getDirection(directionID)
        .then(axiosCallback)
        .catch((response) => { console.log("Schedule / loadDirection - Axios error ", response); });
    }
    */


    loadDirection(dotID, dimension, type) {
        const directionInfo = [];
        for (let i = 0; i < dimension; i++) {
            directionInfo.push(null);
        }
        //console.log("Schedule / loadDirection - dotID = ", dotID);
        //console.log("Schedule / loadDirection - direction = ", direction);
        
        // Get the doc ref
        const docRef = window.firestore.collection("directions").doc(String(type) + "-" + String(dotID));

        // Get all docs in the rows sub-collection
        docRef.collection("rows").get()
        .then(
            (queryset) => {
                queryset.forEach(
                    (doc) => {
                        //console.log("Direction / loadDirection - doc.id = ", doc.id);
                        //console.log("Direction / loadDirection - doc = ", doc.data());
                        directionInfo[Number(doc.id)] = doc.data()["columns"];
                    }
                );
                //console.log("Schedule / loadDirection - directionInfo = ", directionInfo);

                // Update state
                this.setState({ directionInfo: directionInfo });
            }
        ).catch(
            (error) => { console.log("Error getting document:", error); }
        );
    }


    /*
    ============================================================================================
        Dot Click and Reset Switches
    ============================================================================================
    */

    dotClickReset(dotIndex, childDotIndex) {
        //console.log("Schedule / dotClickReset - dotIndex = ", dotIndex);

        // Reset switches
        this.setState({
            prevOn: false,
            nextOn: false
        });

        // Click dot and execute callback
        this.dotClick(dotIndex, childDotIndex);
    }


    /*
    ============================================================================================
        Prev Click
    ============================================================================================
    */

    prevClick() {
        // Next switch is not on
        if (this.state.prevOn === false) {
            // Turn on next switch
            this.setState({
                prevOn: true,
                nextOn: false
            });
        }        
        // Move to prev dot
        else {
            // Get dot index to click
            let dotIndexToClick = null;

            // First dot
            if (this.state.itinerary.indexOf(this.state.selected) === 0) {
                dotIndexToClick = this.state.itinerary[0];
            }
            else{
                dotIndexToClick = this.state.itinerary[this.state.itinerary.indexOf(this.state.selected) - 1];
            }
    
            // Click dot and reset switches
            this.dotClickReset(dotIndexToClick, null);
        }
    }


    /*
    ============================================================================================
        Next Click
    ============================================================================================
    */

    nextClick() {
        // Next switch is not on
        if (this.state.nextOn === false) {
            // Turn on next switch
            this.setState({
                prevOn: false,
                nextOn: true
            });
        }
        // Move to next dot
        else {
            // Get dot index to click
            let dotIndexToClick = null;

            // First dot
            if (this.state.selected === null) {
                dotIndexToClick = this.state.itinerary[0];
            }
            else {
                // Last dot
                if (this.state.itinerary.indexOf(this.state.selected) === (this.state.itinerary.length - 1)) {
                    dotIndexToClick = this.state.itinerary[this.state.itinerary.length - 1];
                }
                else{
                    dotIndexToClick = this.state.itinerary[this.state.itinerary.indexOf(this.state.selected) + 1];
                }
            }
    
            // Click dot and reset switches
            this.dotClickReset(dotIndexToClick, null);
        }
    }


    /*
    ============================================================================================
        Time Bar Click
    ============================================================================================
    */

    timeBarClick(event, dotIndex) {
        this.setState({
            selected: this.state.itinerary[dotIndex]
        });
    }


    /*
    ============================================================================================
        Modal Functions
    ============================================================================================
    */

    resetSchedule() {
        //console.log("Schedule / resetSchedule");
        this.props.storeSchedule(
            { 
                modalOn: false,
                mode: null,
                id: null,
                info: null,
                dot: null,
                size: null
            }
        );
    }

    escClick(event) {
        if (this.props.schedule.modalOn === true && event.keyCode === 27) {
            //console.log("Schedule / escClick");

            this.closeModal();
        }
    }

    clickOutside(event) {
        // If clicked outside
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            //console.log("Schedule / clickOutside");
    
            this.closeModal();
        }
    }

    closeModal(event) {
        //console.log("Schedule / closeModal");

        // Prevent event actions
        event.stopPropagation();
        event.preventDefault();

        // Reset schedule
        this.resetSchedule();
    }

    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        // Send out check scroll signal
        this.setState(
            {
                checkScroll: true
            },
            () => { this.setState({ checkScroll: false });}
        );
    }
}


class ScheduleItem extends Component {
    constructor(props) {
        super(props);

        // Layout settings
        //this.paddingWidth = 20;
        //this.borderWidth = 2;

        // Gallery settings
        // Minimum / scale / maximum content widths
        this.minContentWidth = 300;
        this.scaleContentWidth = 400;
        this.maxContentWidth = 500;

        // Min and max aspect ratio for media
        this.minAspectRatio = 0.5; // Vertical 1:2
        this.maxAspectRatio = 2.0; // Horizontal 2:1

        // Media area
        this.mediaArea = 80000;
        this.mediaAreaVertical = 120000;

        // Get the media sorted
        const mediaSorted = sortMedia(this.props.dotInfo);
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Media start indices
        this.mediaOverviewIndex = 0;
        this.mediaTodosIndex = this.mediaOverviewIndex + mediaSorted.overview.length;
        this.mediaHistoryIndex = this.mediaTodosIndex + mediaSorted.todos.length;
        this.mediaStoriesIndex = this.mediaHistoryIndex + mediaSorted.history.length;

        // Initialize state
        this.state = {            
            // Curation
            curationMode: "overview",

            // Media
            media: media,
            selectedMediaIndex: 0
        };

        // Bind functions
        this.setState = this.setState.bind(this);
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);
    }

    render() {

        // Common dot
        const commonDot = (this.props.dotInfo.type === "EV" || this.props.dotInfo.type === "AU");

        /*
        ============================================================================================
            Gallery
        ============================================================================================
        */

        let gallery = null;
        let galleryDimensions = null;
        if (this.state.media.length > 0) {
            // Get the image dimensions for the right size (small size)
            const mediaInfo = this.state.media[this.state.selectedMediaIndex];
            const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
            const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

            // Is video
            const isVideo = (this.state.media[this.state.selectedMediaIndex].type === "video");

            // Gallery dimensions
            galleryDimensions = mediaDimensions({
                colorMode: this.props.colorMode,
                effectiveBrowserWidth: this.props.effectiveBrowserWidth,
                isVideo: isVideo,
                mediaArea: (this.props.layoutVertical)? this.mediaAreaVertical : this.mediaArea,
                mediaWidth: mediaWidth,
                mediaHeight: mediaHeight,
                minAspectRatio: this.minAspectRatio,
                maxAspectRatio: this.maxAspectRatio,
                minContentWidth: this.minContentWidth,
                scaleContentWidth: this.scaleContentWidth,
                maxContentWidth: this.maxContentWidth
            });
            //console.log("this.props.effectiveBrowserWidth = ", this.props.effectiveBrowserWidth);
            //console.log("galleryDimensions.finalMediaWidth = ", galleryDimensions.finalMediaWidth);

            // Gallery props
            const galleryProps = Object.assign(
                galleryDimensions,
                {
                    // General
                    id: "",
                    idPrefix: "schedule-item",
                    classPrefix: "schedule-item",
                    selectedMediaIndex: this.state.selectedMediaIndex,
                    media: this.state.media,
                    size: "s",
                    startPlaying: true,
                    checkScroll: this.props.checkScroll,
                    nextMediaClick: this.nextMediaClick,
                    prevMediaClick: this.prevMediaClick,
                    navDotClick: this.navDotClick,
                    index: null,
                    square: (this.props.effectiveBrowserWidth <= galleryDimensions.finalMediaWidth)?
                        true : false,
                    muted: false,

                    // Interaction buttons
                    userTagOn: false,
                    dotTagOn: false,
                    unsaveOn: false,
                    enlargeOn: true,
                    dotInfo: this.props.dotInfo,
                    tagMode: "save"
                }
            );

            gallery = (
                <Gallery {...galleryProps} />
            )
        }


        /*
        ============================================================================================
            Start Button
        ============================================================================================
        */

        const startButton = (this.props.isSummary)? (
            <div id = "schedule-start-button-container">
                <div className = {(this.props.colorMode === "day")?
                        "schedule-start-button button-light-blue-gray-s1" : 
                        "schedule-start-button button-blue-gray-s1"}
                    onClick = {this.props.nextClick}
                >
                    Start Virtual Tour
                </div>
            </div>
        ) : null;


        // Header width
        //console.log("galleryDimensions.finalMediaWidth = ", galleryDimensions.finalMediaWidth);
        //const headerWidth = (this.props.layoutMedium)?
        //    "100%" : (this.props.effectiveBrowserWidth - galleryDimensions.finalMediaWidth- this.paddingWidth - 2 * this.borderWidth);
        const headerClassName = (this.props.layoutVertical)?
            "schedule-item-header-small" : "schedule-item-header";
        //console.log("ScheduleItem / render - headerWidth = ", headerWidth);

        // Dots image
        const dotsImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/main-mode-dots-light-blue.png") :
            getStaticPath("/images/common/main-mode-dots-blue.png");

        // Time image
        const timeImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/time-light-blue.png") :
            getStaticPath("/images/common/time-blue.png");

        // Line image
        const lineImage = (this.props.colorMode === "day")?
            getStaticPath("/images/line/horizontal/solid-middle-black.png") :
            getStaticPath("/images/line/horizontal/solid-middle-white.png");

        // Header
        const header = (this.props.isSummary)?
        (
            <div className = {headerClassName}>
                <div className = {(this.props.layoutVertical)?
                    (
                        (this.props.colorMode === "day")?
                            "schedule-item-name-small k2" : "schedule-item-name-small w2"

                    ) : (
                        (this.props.colorMode === "day")?
                            "schedule-item-name k2" : "schedule-item-name w2"
                    )}
                    style = {{
                        display: (this.props.layoutVertical)? "inline-block" : "block",
                        marginRight: (this.props.layoutVertical)? "8px" : "0px"
                    }}
                >
                    {this.props.dotInfo.area}
                </div>
                <div className = {(this.props.layoutVertical)?
                    (
                        (this.props.colorMode === "day")?
                            "schedule-item-title-small lb4" : "schedule-item-title-small b4"

                    ) : (
                        (this.props.colorMode === "day")?
                            "schedule-item-title lb4" : "schedule-item-title b4"
                    )}
                    style = {{
                        display: (this.props.layoutVertical)? "inline-block" : "block"
                    }}
                >
                    {this.props.dotInfo.title}
                </div>

                {startButton}

                <div className = "schedule-item-dots">
                    <div className = "schedule-item-dots-icon image-contain"
                        style = {{ backgroundImage: dotsImage }}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                        "schedule-item-dots-text k3" :
                        "schedule-item-dots-text w3"}
                    >
                        {"" + this.props.itineraryInfo.route.length + " dots"}
                    </div>
                </div>

                <div className = "schedule-item-time">
                    <div className = "schedule-item-time-icon image-contain"
                        style = {{ backgroundImage: timeImage }}
                    >
                    </div>

                    <div className = {(this.props.colorMode === "day")?
                        "schedule-item-time-text k3" :
                        "schedule-item-time-text w3"}
                    >
                        {this.props.itineraryInfo.start_time_local_formatted}
                    </div>
                    <div className = {(this.props.colorMode === "day")?
                        "schedule-item-time-line" :
                        "schedule-item-time-line"}
                        style = {{ backgroundImage: lineImage }}
                    >
                    </div>
                    <div className = {(this.props.colorMode === "day")?
                        "schedule-item-time-text k3" :
                        "schedule-item-time-text w3"}
                    >
                        {this.props.itineraryInfo.end_time_local_formatted}
                    </div>
                </div>              
            </div>
        ) : (
            <div className = {headerClassName}>
                <div className = {(this.props.colorMode === "day")?
                        "schedule-item-name k2" : "schedule-item-name w2"}
                    style = {{
                        display: (this.props.layoutVertical)? "inline-block" : "block",
                        marginRight: (this.props.layoutVertical)? "8px" : "0px"
                    }}
                >
                    {this.props.dotInfo.name}
                </div>
                <div className = {(this.props.colorMode === "day")?
                        "schedule-item-title lb4" : "schedule-item-title b4"}
                    style = {{
                        display: (this.props.layoutVertical)? "inline-block" : "block"
                    }}
                >
                    {this.props.dotInfo.title}
                </div>
            </div>
        );
        //console.log("ScheduleItem / render - this.props.itineraryInfo = ", this.props.itineraryInfo);

        /*
        ============================================================================================
            Curation Tabs
        ============================================================================================
        */

        //console.log("ScheduleItem / render - this.props.dotInfo = ", this.props.dotInfo);
        let curationCount = 0;
        let curationOverviewTab = null;
        let curationTodosTab = null;
        let curationHistoryTab = null;
        let curationStoriesTab = null;

        if (this.props.dotInfo.overview !== null && this.props.dotInfo.overview !== undefined) {
            curationOverviewTab = (this.props.dotInfo.overview.length > 0)? (
                <div className = {(this.props.colorMode  === "day")?
                        //"schedule-item-curation-tab lg4" :
                        //"schedule-item-curation-tab dg4"}
                        "schedule-item-curation-tab text-button-light-gray-s2" :
                        "schedule-item-curation-tab text-button-gray-s2"}
                    style = {
                        (this.state.curationMode === "overview")?
                            {
                                color: (this.props.colorMode === "day")?
                                    window.colorLightBlue : window.colorBlue,
                                opacity: 1.0
                            } : {
                            }
                    }
                    onClick = {() => {
                        this.setState({
                            curationMode: "overview",
                            selectedMediaIndex: this.mediaOverviewIndex
                        });
                    }}
                >
                    Overview
                </div>
            ) : null;

            curationCount += 1;
        } 

        if (!commonDot) {
            if (this.props.dotInfo.dot_extension.todos !== null && this.props.dotInfo.dot_extension.todos !== undefined) {
                curationTodosTab = (this.props.dotInfo.dot_extension.todos.length > 0)? (
                    <div className = {(this.props.colorMode  === "day")?
                            //"schedule-item-curation-tab lg4" :
                            //"schedule-item-curation-tab dg4"}
                            "schedule-item-curation-tab text-button-light-gray-s2" :
                            "schedule-item-curation-tab text-button-gray-s2"}
                        style = {
                            (this.state.curationMode === "todos")?
                                {
                                    color: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                curationMode: "todos",
                                selectedMediaIndex: this.mediaTodosIndex                            
                            });
                        }}
                    >
                        Todos
                    </div>
                ) : null;

                curationCount += 1;
            }

            if (this.props.dotInfo.dot_extension.history !== null && this.props.dotInfo.dot_extension.history !== undefined) {
                curationHistoryTab = (this.props.dotInfo.dot_extension.history.length > 0)? (
                    <div className = {(this.props.colorMode  === "day")?
                            //"schedule-item-curation-tab lg4" :
                            //"schedule-item-curation-tab dg4"}
                            "schedule-item-curation-tab text-button-light-gray-s2" :
                            "schedule-item-curation-tab text-button-gray-s2"}
                        style = {
                            (this.state.curationMode === "history")?
                                {
                                    color: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                curationMode: "history",
                                selectedMediaIndex: this.mediaHistoryIndex
                            });
                        }}
                    >
                        History
                    </div>
                ) : null;

                curationCount += 1;
            }

            if (this.props.dotInfo.dot_extension.stories !== null && this.props.dotInfo.dot_extension.stories !== undefined) {
                curationStoriesTab = (this.props.dotInfo.dot_extension.stories.length > 0)? (
                    <div className = {(this.props.colorMode  === "day")?
                            //"schedule-item-curation-tab lg4" :
                            //"schedule-item-curation-tab dg4"}
                            "schedule-item-curation-tab text-button-light-gray-s2" :
                            "schedule-item-curation-tab text-button-gray-s2"}
                        style = {
                            (this.state.curationMode === "stories")?
                                {
                                    color: (this.props.colorMode === "day")?
                                        window.colorLightBlue : window.colorBlue,
                                    opacity: 1.0
                                } : {
                                }
                        }
                        onClick = {() => {
                            this.setState({
                                curationMode: "stories",
                                selectedMediaIndex: this.mediaStoriesIndex
                            });
                        }}
                    >
                        Stories
                    </div>
                ) : null;

                curationCount += 1;
            }
        }

        const curationTabs = (curationCount > 1)?
            (
                <div className = {(this.props.layoutVertical)?
                    "schedule-item-curation-tabs-small" : 
                    "schedule-item-curation-tabs"}
                >
                    {curationOverviewTab}
                    {curationTodosTab}
                    {curationHistoryTab}
                    {curationStoriesTab}
                </div>

            ) : null;
       

        /*
        ============================================================================================
            Curation
        ============================================================================================
        */

        let curationText = null;
        let curationRawText = null;
        if (this.state.curationMode === "overview") {
            curationText = this.curationPartition(this.props.dotInfo.overview);
            curationRawText = this.props.dotInfo.overview.replace(/[*]/g, "");
        }
        else if (this.state.curationMode === "todos") {
            curationText = this.curationPartition(this.props.dotInfo.dot_extension.todos);
            curationRawText = this.props.dotInfo.dot_extension.todos.replace(/[*]/g, "");
        }
        else if (this.state.curationMode === "history") {
            curationText = this.curationPartition(this.props.dotInfo.dot_extension.history);
            curationRawText = this.props.dotInfo.dot_extension.history.replace(/[*]/g, "");
        }
        else if (this.state.curationMode === "stories") {
            curationText = this.curationPartition(this.props.dotInfo.dot_extension.stories);
            curationRawText = this.props.dotInfo.dot_extension.stories.replace(/[*]/g, "");
        }
        //console.log("Schedule / render - curationRawText = ", curationRawText);

        const curation = (
            <div className = {(this.props.layoutSmall)?
                (
                    (this.props.colorMode === "day")?
                        "schedule-item-curation-small dg4" : 
                        "schedule-item-curation-small lg4"
                ) : (
                    (this.props.colorMode === "day")?
                        "schedule-item-curation dg4" : 
                        "schedule-item-curation lg4"
                )}
                onClick = {() => {
                    const msg = new SpeechSynthesisUtterance(curationRawText);
                    window.speechSynthesis.speak(msg);
                }}
            >
                {curationText}
            </div>
        );


        /*
        ============================================================================================
            Main Render Function
        ============================================================================================
        */

        return(
            <div className = "schedule-item-content">
                {(this.props.layoutVertical)? null : curationTabs}

                {(this.props.layoutVertical)? header : null}

                <div className = {(this.props.layoutVertical)?
                    "schedule-item-gallery-small" :
                    "schedule-item-gallery"}
                >
                    {gallery}
                </div>

                {(this.props.layoutVertical)? curationTabs : null}

                {(this.props.layoutVertical)? null : header}

                {curation}
            </div>
        );
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    /*
    ============================================================================================
        Gallery Navigation Functions
    ============================================================================================
    */

    nextMediaClick() {
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex + 1)
            }
        );
    }

    prevMediaClick() {
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex - 1)
            }
        );
    }

    navDotClick(mediaIndex) {
        this.setState({
            selectedMediaIndex: mediaIndex
        });
    }


    /*
    ============================================================================================
        Curation
    ============================================================================================
    */

    curationPartition(string, keyHeader) {
        // Find indices of *
        const indices = [];
        let index = -1;
        while ((index = string.indexOf("*", index + 1)) >= 0) {
            indices.push(index);
        }
        
        // Odd number function
        const isOdd = (num) => { return num % 2;};

        // Initialize JSX expression
        let jsx = [];

        // Get the number of highlighted sections
        let startIndex = 0;
        for (let i = 0; i < (indices.length + 1); i++) {
            let subString = (i === (indices.length))?
                string.slice(startIndex) : string.slice(startIndex, indices[i]);
            let component;

            // First part
            if (subString.length > 0) {
                if (isOdd(i)) {
                    component = (
                        <span key = {keyHeader + i.toString()} 
                            className = {(this.props.colorMode === "day")?
                                "schedule-item-overview-curation-highlight text light-blue" :
                                "schedule-item-overview-curation-highlight text blue"}
                        >
                            {subString}
                        </span>
                    );
                }
                else {
                    component = (
                        <span key = {keyHeader + i.toString()} 
                            className = {(this.props.colorMode === "day")?
                                "schedule-item-overview-curation-regular text dark-gray" :
                                "schedule-item-overview-curation-regular text gray"}
                        >
                            {subString}
                        </span>
                    );
                }
                
            }

            // Update index
            startIndex = indices[i] + 1;

            // Add JSX
            jsx.push(component);
        }

        // Return modified string
        return jsx;
    }
}


class Direction extends Component {
    constructor(props) {
        super(props);

        this.unscrollHeight = 100;
        this.paddingWidth = 20;
        this.marginWidth = 10;

        // Initialize state
        this.state = {
        };

        // Bind functions
    }

    render() {
        if (this.props.direction !== null) {
            //console.log("Direction / render - this.props.direction = ", this.props.direction);

            // Scroll section height
            const scrollHeight = (window.innerHeight - this.unscrollHeight
                - 2 * (this.paddingWidth + this.marginWidth));

            // Direction items
            const directionItems = this.props.direction.steps.map(
                (step, index) => {
                    //console.log("Direction / render - step = ", step);
                    //console.log("Direction / render - intersection = ", this.props.direction.intersections[index]);
                    //console.log("Direction / render - maneuver = ", this.props.direction.maneuvers[index]);
                    //console.log("Direction / render - maneuver.type = ", this.props.direction.maneuvers[index].type);
                    //console.log("Direction / render - maneuver.modifier = ", this.props.direction.maneuvers[index].modifier);

                    // Duration / distance / direction        
                    //const duration = formatDuration(step.duration);
                    const distance = formatDistance(step.distance);
                    const directionImageText = getDirectionImageText(
                        step,
                        this.props.direction.maneuvers[index],
                        this.props.colorMode,
                        "blue",
                        true
                    );

                    return(
                        <div key = {"direction-item-" + index}
                            className = "direction-item"
                        >
                            <div className = "direction-item-image image-cover"
                                style = {{ backgroundImage: directionImageText[0] }}
                            >
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                "direction-item-instruction k4" : 
                                "direction-item-instruction w4"}
                            >
                                {directionImageText[1]}
                            </div>
                            <div className = {(this.props.colorMode === "day")?
                                "direction-item-distance lb4" : 
                                "direction-item-distance b4"}
                            >
                                {distance}
                            </div>
                        </div>
                    );
                }
            );

            return(
                <div id = "direction-background">
                    <div id = "direction-container"
                        className = {(this.props.colorMode === "day")?
                            "modal-day" : "modal-night"}
                    >
                        <div id = "direction-cancel-button"
                            className = "image-button-weaker-s3"
                            style = {{
                                backgroundImage:  (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/cancel-black.png") :
                                    getStaticPath("/images/common/cancel-white.png")
                            }}
                            onClick = {() => { this.props.setState({ directionOn: false }); }}
                        >

                        </div>
                        <div id = "direction-title"
                            className = {(this.props.colorMode === "day")?
                                "k2" : "w2"}
                        >
                            Driving Directions
                        </div>
                        <div id = "direction-stats">
                            <div id = "direction-distance"
                                className = {(this.props.colorMode === "day")?
                                    "lb3" : "b3"}
                            >
                                {formatDistance(this.props.direction.distance)}
                            </div>
                            <div id = "direction-stats-divider"
                                className = {(this.props.colorMode === "day")?
                                    "k3" : "w"}
                            >
                            |
                            </div>
                            <div id = "direction-duration"
                                className = {(this.props.colorMode === "day")?
                                    "lb3" : "b3"}
                            >
                                {formatDuration(this.props.direction.duration)}
                            </div>
                        </div>

                        <div id = "direction-scroll"
                            style = {{
                                maxHeight: scrollHeight
                            }}
                        >
                            <div id = "direction-items">
                                {directionItems}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }
}

function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        itineraryInfo: state.itinerary.itineraryInfo,
        itineraryParams: state.itinerary.itineraryParams,
        schedule: state.schedule.schedule,
        google: state.map.google
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeSchedule, 
            storeWarningAlert
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
