const authsAPI = "api/auths/"
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const postLogIn = (dataJSON, hostURL) => {
	let URL = rootURL + authsAPI + hostURL;

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postEmailCheck = (dataJSON) => {
	let URL = rootURL + authsAPI + "check/email/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postUsernameCheck = (dataJSON) => {
	let URL = rootURL + authsAPI + "check/username/";

    return window.axiosCSRF.post(URL, dataJSON);
};
