/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    UploadGallery.js
    - Gallery to display uploaded media
--------------------------------------------------------------------------------------------
    Content
    - UploadGallery
============================================================================================
*/

// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 

// Modules
import ReactTooltip from "thedots-tooltip";
import ReactPlayer from "react-player";
import EXIF from "exif-js";

// Component
import { DropDownMenu } from "js/Common";
import { mediaDimensions } from "components/Gallery";
import UploadIndicator from "components/UploadIndicator";

// Functions
import {
    getMediaProperty,
    getStaticPath,
    url
} from "js/Functions";

import {
    convertToDigits    
} from "components/Map/MapFunc";

// Axios 
import {
    postImage,
    deleteImage,
    postVideo,
    deleteVideo,
    getSearch
} from "requests";

// CSS
import "./UploadGallery.scss";

// Response codes
//import { responseResultCodes } from "ResponseCodes";


// UploadGallery
class UnconnectedUploadGallery extends Component{
    constructor (props){
        super(props);

        // DOM Node
        this.searchRef = React.createRef();

        // Search placeholder
        this.searchPlaceholder = "Search for media";

        // Number of columns
        this.numColumns = 4;

        // Number of pages per group
        this.numPagesPerGroup = 10;

        // Browser minimum width
        this.browserMinimumWidth = 320;
        this.browserMaximumWidth = 530;

        // Item
        this.searchItemWidth = 130;
        this.searchItemMargin = 5;

        // Default media size
        this.defaultMediaWidth = 0;
        this.defaultMediaHeight = 0;

        // Thumbnail resize ratio
        this.thumbnailRatioMedium = 0.85;
        this.thumbnailRatioSmall = 0.7;

        // Initialize state
        this.state = {
            hoverMediaIndex: null,
            searchResults: null,
            searchBorderColor: (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray,
            type: "media",
            page: 1,
            maxPage: null
        };

        // Bind basic functions
        this.setState = this.setState.bind(this);

        // Bind main media action functions
        //this.mediaHoverOn = this.mediaHoverOn.bind(this);
        //this.mediaHoverOff = this.mediaHoverOff.bind(this);

        //this.thumbnailPrevPage = this.thumbnailPrevPage.bind(this);
        //this.thumbnailNextPage = this.thumbnailNextPage.bind(this);
        this.thumbnailOnMouseEnter = this.thumbnailOnMouseEnter.bind(this);
        this.thumbnailOnMouseLeave = this.thumbnailOnMouseLeave.bind(this);

        // Fetch search results
        this.fetchSearchResults = this.fetchSearchResults.bind(this);
        this.clearSearchResults = this.clearSearchResults.bind(this);

        // Bind search functions
        this.searchOnFocus = this.searchOnFocus.bind(this);
        this.searchOnBlur = this.searchOnBlur.bind(this);
        this.searchOnChange = this.searchOnChange.bind(this);
        this.searchBorder = this.searchBorder.bind(this);

        // Bind pagination functions
        this.goToPage = this.goToPage.bind(this);
        this.previousGroup = this.previousGroup.bind(this);
        this.nextGroup = this.nextGroup.bind(this);        
    }

    render () {
        //console.log("===============================================");
        //console.log(" UploadGallery / render - this.props = ", this.props);

        // Shadow image
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette-weak.png");

        // Loader image
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/loader-white.gif") :
            getStaticPath("/images/loader/loader-black.gif");

        // Get the media url based on the media ID
        let backgroundImage = null;
        if (this.props.selectedMediaIndex !== null) {
            if (this.props.media[this.props.mediaKeys[this.props.selectedMediaIndex]].mediaType === "video") {

            }
            else {
                backgroundImage = url(this.props.media[this.props.mediaKeys[this.props.selectedMediaIndex]].mediaURL);
            }
        }
        //console.log(" UploadGallery / render - backgroundImage = ", backgroundImage);

		// Get the add image button image
		let addImageButtonImage = null;
        if (this.props.mediaWarningOn) {
            addImageButtonImage = (this.props.colorMode === "day")? 
                getStaticPath("/images/upload/upload-image-red.png") :
                getStaticPath("/images/upload/upload-image-red.png");
        }
        else {
            addImageButtonImage = (this.props.colorMode === "day")? 
                getStaticPath("/images/upload/upload-image-black.png") :
                getStaticPath("/images/upload/upload-image-white.png");
        }

        // Get the add video button image
        let addVideoButtonImage = null;
        if (this.props.mediaWarningOn) {
            addVideoButtonImage = (this.props.colorMode === "day")? 
                getStaticPath("/images/upload/upload-video-red.png") :
                getStaticPath("/images/upload/upload-video-red.png");
        }
        else {
            addVideoButtonImage = (this.props.colorMode === "day")? 
                getStaticPath("/images/upload/upload-video-black.png") :
                getStaticPath("/images/upload/upload-video-white.png");
        }

        // Get the media container width
        let mediaContainerWidth = 0;
        let mediaContainerHeight = 0;

        // When no media
        if (this.props.mediaKeys.length === 0) {
            mediaContainerWidth = this.defaultMediaWidth;
            mediaContainerHeight = this.defaultMediaHeight;
        }
        else {
            // Media width and height
            const mediaWidth = this.props.media[this.props.mediaKeys[this.props.selectedMediaIndex]].mediaWidth;
            const mediaHeight = this.props.media[this.props.mediaKeys[this.props.selectedMediaIndex]].mediaHeight;

            // Is video
            const isVideo = (this.props.media[this.props.mediaKeys[this.props.selectedMediaIndex]].mediaType === "video");

            // Effective browser width
            const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.props.marginWidth;


            /*
            ============================================================================================
                Media dimensions
            ============================================================================================
            */

            const mediaDimensionsProps = {
                colorMode: this.props.colorMode,
                effectiveBrowserWidth: effectiveBrowserWidth,
                isVideo: isVideo,
                mediaArea: this.props.mediaArea,
                mediaWidth: mediaWidth, 
                mediaHeight: mediaHeight,
                minAspectRatio: this.props.minAspectRatio,
                maxAspectRatio: this.props.maxAspectRatio,
                minContentWidth: this.props.minContentWidth,
                scaleContentWidth: this.props.scaleContentWidth,
                maxContentWidth: this.props.maxContentWidth
            };

            const dimensions = mediaDimensions(mediaDimensionsProps);
            mediaContainerWidth = dimensions.finalMediaWidth;
            mediaContainerHeight = dimensions.finalMediaHeight;
        }
        
        let mediaContainerStyle = {
            width: mediaContainerWidth, 
            height: mediaContainerHeight
        };

        // Media loader style
        const mediaLoaderStyle = {
            backgroundImage: loaderImage
            //transform: mediaLoaderScale
        };

        // Media
        let media = null;
        if (this.props.selectedMediaIndex !== null) {
            if (this.props.media[this.props.mediaKeys[this.props.selectedMediaIndex]].mediaType === "video") {
                media = (
                    <ReactPlayer
                        id = "upload-gallery-media-video"
                        width = {mediaContainerWidth}
                        height = {mediaContainerHeight}
                        volume = {0.05}
                        url = {this.props.media[this.props.mediaKeys[this.props.selectedMediaIndex]].mediaURL}
                        playing
                        controls
                    />
                );
            }
            else {
                media = (
                    <div id = "upload-gallery-media-image"
                        className = {(this.props.colorMode === "day")?
                            "border-day" : "border-night"}
                        style = {{
                            backgroundImage: backgroundImage,
                            width: mediaContainerWidth,
                            height: mediaContainerHeight
                        }}
                    >
                    </div>
                );
            }
        }

        // Remove media button
        let removeMediaButtonImage = null;
        let leftButtonImage = null;
        let rightButtonImage = null;

        // Compile thumbnail list
        const thumbnailList = (this.props.mediaKeys.length === 0)? null : this.props.mediaKeys.map(
            (mediaKey, index) => {
                // Thumbnail image and class
                let thumbnailImage = null;
                let thumbnailClass = null;
                if (this.props.media[mediaKey].mediaType === "video") {
                    thumbnailImage = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/video-black.png", true) :
                        getStaticPath("/images/common/video-white.png", true);
                    
                    thumbnailClass = (this.props.browserWidth <= 2)?
                        (
                            (this.props.colorMode === "day")?
                                "upload-gallery-thumbnail upload-gallery-thumbnail-video-mobile border-day" :
                                "upload-gallery-thumbnail upload-gallery-thumbnail-video-mobile border-night"
                        ) : (
                            (this.props.colorMode === "day")?
                                "upload-gallery-thumbnail upload-gallery-thumbnail-video border-day" :
                                "upload-gallery-thumbnail upload-gallery-thumbnail-video border-night"
                        );

                    removeMediaButtonImage = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/remove-black.png") :
                        getStaticPath("/images/common/remove-white-shadow.png");

                    leftButtonImage = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/triangle-narrow-left-black.png") :
                        getStaticPath("/images/common/triangle-narrow-left-white.png");

                    rightButtonImage = (this.props.colorMode === "day")?
                        getStaticPath("/images/common/triangle-narrow-right-black.png") :
                        getStaticPath("/images/common/triangle-narrow-right-white.png");
                }
                else {
                    thumbnailImage = url(this.props.media[mediaKey].mediaURL);
                    thumbnailClass = (this.props.colorMode === "day")?
                        "upload-gallery-thumbnail image-cover border-day" :
                        "upload-gallery-thumbnail image-cover border-night";

                    removeMediaButtonImage = getStaticPath("/images/common/remove-white-shadow.png");

                    leftButtonImage = getStaticPath("/images/common/triangle-narrow-left-white.png");

                    rightButtonImage = getStaticPath("/images/common/triangle-narrow-right-white.png");
                }

                // Drop down menu
                let dropDownMenu = null;
                if (this.props.userIsContributor && this.props.contributorModeOn) {
                    // Construct drop down menu
                    const updateItems = (mediaCategories) => {
                        this.props.setState(
                            {
                                mediaCategories: mediaCategories
                            },
                            () => { 
                                //console.log("UploadGallery / render - newMediaCategories = ", newMediaCategories);
                            }
                        );
                    };

                    const dropDownMenuProps = {
                        colorMode: this.props.colorMode,
                        objectIndex: this.props.mediaKeys[index],
                        selectedItemIndex : this.props.mediaCategories[this.props.mediaKeys[index]],
                        items: this.props.mediaCategories,
                        itemLabels: (this.props.objectType === "dot")? 
                        [ "Overview", "Todos", "History", "Stories" ] : ["Overview", "Todos", "History", "Stories"],
                        updateItems: updateItems,
                        showSelectedLabel: true,
                        width: 66,
                        height: 18,
                        top: 0,
                        left: 0
                    };

                    dropDownMenu = (
                        <DropDownMenu {...dropDownMenuProps} />
                    );
                }


                // Move left button
                const leftButton = ((index !== 0) && (this.props.mediaKeys.length >= 2))? (
                    <div className = {
                            (this.props.browserWidth <= 2)?
                                "upload-gallery-thumbnail-left-button image-button-s6":
                                "upload-gallery-thumbnail-left-button image-button-s5"
                        }
                        style = {{ backgroundImage: leftButtonImage }}
                        onClick = {(e) => { this.props.moveMediaLeft(e, index); }}
                    >
                    </div>
                ) : null;


                // Move right button
                const rightButton = ((index !== (this.props.mediaKeys.length - 1)) && (this.props.mediaKeys.length >= 2))? (
                    <div className = {
                            (this.props.browserWidth <= 2)?
                                "upload-gallery-thumbnail-right-button image-button-s6":
                                "upload-gallery-thumbnail-right-button image-button-s5"
                        }
                        style = {{ backgroundImage: rightButtonImage }}
                        onClick = {(e) => { this.props.moveMediaRight(e, index); }}
                    >
                    </div>
                ) : null;


                // All the thumbnails
                if ((index >= this.props.startMediaIndex) && (index <= this.props.endMediaIndex)) {
                    const opacity = ((index === this.props.selectedMediaIndex) || (index === this.state.hoverMediaIndex))? 
                        this.props.focusOpacity : this.props.unfocusOpacity;
                    const thumbnailBorder = ((index === this.props.selectedMediaIndex) || (index === this.props.hoverMediaIndex))? 
                        "1px solid #666666" : "1px solid #464646";
                    //console.log("UploadGallery / render - thumbnail index = ", index);
                    //console.log("UploadGallery / render - this.props.selectedMediaIndex = ", this.props.selectedMediaIndex);

					const contentOpacity = (index === this.state.hoverMediaIndex)? 1.0 : 0.0;


                    // Thumbnail width and height
                    let thumbnailWidth = this.props.media[this.props.mediaKeys[index]].thumbnailWidth;
                    let thumbnailHeight = this.props.media[this.props.mediaKeys[index]].thumbnailHeight;
                    if (this.props.browserWidth <= 2) {
                        thumbnailWidth *= this.thumbnailRatioSmall;
                        thumbnailHeight *= this.thumbnailRatioSmall;
                    }
                    else if (this.props.browserWidth <= 6) {
                        thumbnailWidth *= this.thumbnailRatioMedium;
                        thumbnailHeight *= this.thumbnailRatioMedium;
                    }

                    return(
                        <div className = "upload-gallery-thumbnail-container"
                                key = {"upload-gallery-thumbnail-" + mediaKey.toString()}
                        >
                            <UploadIndicator
                                index = {index}
                                mediaKey = {mediaKey}
                                uploadPercentage = {this.props.uploadProgress[this.props.mediaKeys[index]].uploadPercentage}
                                uploadComplete = {this.props.uploadProgress[this.props.mediaKeys[index]].uploadComplete}
                                uploadProcessPercentage = {this.props.uploadProgress[this.props.mediaKeys[index]].uploadProcessPercentage}
                                uploadProcessComplete = {this.props.uploadProgress[this.props.mediaKeys[index]].uploadProcessComplete}
                                uploadError = {this.props.uploadProgress[this.props.mediaKeys[index]].uploadError}
                                uploadUpdate = {this.props.uploadUpdate}
                            />

                            <div className = {thumbnailClass}
                                onClick = {(e) => {this.props.selectMedia(e, index);}}
                                onMouseEnter={(e) => this.thumbnailOnMouseEnter(e, index)}
                                onMouseLeave={(e) => this.thumbnailOnMouseLeave(e, index)}                            
                                style = {{
                                    width: thumbnailWidth,
                                    height: thumbnailHeight,
                                    //width: this.props.media[this.props.mediaKeys[index]].thumbnailWidth,
                                    //height: this.props.media[this.props.mediaKeys[index]].thumbnailHeight,
                                    backgroundImage: thumbnailImage,
                                    opacity: opacity,
                                    border: thumbnailBorder
                                }}
                            >

                            	<div className = "upload-gallery-thumbnail-content"
                            		style = {{ opacity: contentOpacity }}
                            	>
    	                        	<div className = {
                                            (this.props.browserWidth <= 2)?
                                                "upload-gallery-remove-media-button image-button-s6":
                                                "upload-gallery-remove-media-button image-button-s5"
                                        }
    	                        		key = {"upload-gallery-thumbnail-remove-button-" + mediaKey.toString()}
    		                            onClick = {(e) => {this.props.removeMedia(e, index, this.props.mediaIDs[this.props.mediaKeys[index]]);}}
    		                            style = {{ 
                                            display: (this.props.uploadProgress[this.props.mediaKeys[index]].uploadProcessComplete || this.props.uploadProgress[this.props.mediaKeys[index]].uploadError)? "block" : "none",
                                            backgroundImage: removeMediaButtonImage 
                                        }}
                                        data-for = "upload-gallery-remove-media-button-tooltip"
                                        data-tip = "Remove Media File"
    	                        	>
    	                        	</div>

                                    {leftButton}

                                    {rightButton}

                                    <ReactTooltip 
                                        id = "upload-gallery-remove-image-button-tooltip"
                                        className = "tooltip-s2"
                                        type = "dark"
                                        place = "bottom"
                                    />
    	                        </div>
                            </div>
                            {dropDownMenu}
                        </div>
                    )
                }
                else {
                    return null;
                }
            }
        );

        // Get add media button
		// Only if it is the last page
		//console.log("UploadGallery / render - this.props.startMediaIndex = ", this.props.startMediaIndex);
		//console.log("UploadGallery / render - this.props.endMediaIndex = ", this.props.endMediaIndex);
		//console.log("UploadGallery / render - this.props.numThumbnails = ", this.props.numThumbnails);

        const addMediaButtonOn = (
            (((this.props.startMediaIndex + this.props.numThumbnails) > (this.props.mediaKeys.length - 1)) || (this.props.mediaKeys.length === 0))
        )? "inline-block" : "none";
        //console.log("UploadGallery / render - addMediaButtonOn = ", addMediaButtonOn);

        // Add media buttons
        const addImageButton = (
            <div id = {
                (this.props.browserWidth <= 2)?
                    "upload-gallery-add-image-container-small" :
                    (
                        (this.props.browserWidth <= 6)?
                            "upload-gallery-add-image-container-medium" :
                            "upload-gallery-add-image-container"
                    )
                }
                className = "image-button-base"
                data-for = "upload-gallery-add-image-container-tooltip"
                data-tip = "Upload an Image"
                style = {{
                    display: addMediaButtonOn,
                    backgroundImage : addImageButtonImage
                }}
            >
                <input 
                    id = {this.props.imageUploadID}
                    type = "file" 
                    name = "image"
                    accept = "image/*"
                />

                <ReactTooltip 
                    id = "upload-gallery-add-image-container-tooltip"
                    className = "tooltip-s2"
                    type = "dark"
                    place = "bottom"
                />                
            </div>
        );

        const addVideoButton = (
            <div id = {
                (this.props.browserWidth <= 2)?
                    "upload-gallery-add-video-container-small" : 
                    (
                        (this.props.browserWidth <= 6)?
                            "upload-gallery-add-video-container-medium" :
                            "upload-gallery-add-video-container"
                    )
                }
                className = "image-button-base"
                data-for = "upload-gallery-add-video-container-tooltip"
                data-tip = "Upload a Video"
                style = {{
                    display: addMediaButtonOn,
                    backgroundImage : addVideoButtonImage
                }}
            >
                <input 
                    id = {this.props.videoUploadID}
                    type = "file" 
                    name = "video"
                    accept = "video/*"
                />

                <ReactTooltip 
                    id = "upload-gallery-add-video-container-tooltip"
                    className = "tooltip-s2"
                    type = "dark"
                    place = "bottom"
                />                
            </div>
        );

        // Arrow images
        const prevPageButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/arrow-left-black.png") :
            getStaticPath("/images/common/arrow-left-white.png");

        const nextPageButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/arrow-right-black.png") :
            getStaticPath("/images/common/arrow-right-white.png");

        // Get the thumbnail page navigation buttons
        let prevPageButton, nextPageButton = null;
        if (this.props.startMediaIndex > 0) {
            prevPageButton = (
                <div className = "upload-gallery-thumbnail-prev image-button-s3"
                    onClick = {(e) => this.props.thumbnailPrevPage(e)}
                    style = {{ backgroundImage: prevPageButtonImage }}
                >
                </div>
            );
        }

        if (this.props.endMediaIndex < (this.props.mediaKeys.length - 1)) {
            nextPageButton = (
                <div className = "upload-gallery-thumbnail-next image-button-s3"
                    onClick = {(e) => this.props.thumbnailNextPage(e)}
                    style = {{ backgroundImage: nextPageButtonImage }}
                >
                </div>
            );
        }

        // Attach previous and next page navigation buttons
        const thumbnails = (
            <div id = "upload-gallery-thumbnails">
                {prevPageButton}
                {thumbnailList}
                <div id = "upload-gallery-add-buttons">
                    {addImageButton}
                    {addVideoButton}
                </div>
                {nextPageButton}
            </div>
        );

        // Gallery message (or warning)
        const message = (this.props.mediaWarningOn)?
            (
                <div id = "upload-gallery-warning"
                    className = {(this.props.colorMode === "day")?
                        "warning-day-s2" : "warning-night-s2"}
                >
                    {this.props.mediaWarningMessage}
                </div>
            ) : null;


        /*
        ============================================================================================
            Media Search
        ============================================================================================
        */

        const numColumns = Math.round((this.props.browserWidthPixels - 2 * this.searchItemMargin) / this.searchItemWidth);

        const searchResultsClass = (numColumns >= this.numColumns)?
            "upload-gallery-search-results" :
            (
                (this.props.browserWidth <= 1)?
                    "upload-gallery-search-results-small" :
                    "upload-gallery-search-results-medium"
            );

        // Search button image
        const searchImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/search-input-black.png") :
            getStaticPath("/images/common/search-input-white.png");

        // Search button image
        const clearImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cross-black.png") :
            getStaticPath("/images/common/cross-white.png");

        // Search input
        const search = (this.props.searchOn)? (
            <div id = "upload-gallery-search-container">
                <div id = "upload-gallery-search">
                    <input
                        ref = {this.searchRef}
                        type = "text"
                        id = "upload-gallery-search-input"
                        className = {(this.props.colorMode === "day")? 
                            "input-s3 input-day" : "input-s3 input-night"}
                        placeholder = {this.searchPlaceholder}
                        onFocus = {this.searchOnFocus}
                        onBlur = {this.searchOnBlur}
                        onChange = {this.searchOnChange}
                        style = {{ borderColor: this.state.searchBorderColor }}
                    />
                    <div id = "upload-gallery-search-fetch-button"
                        className = "image-button-s6"
                        style = {{ backgroundImage: searchImage }}
                        onClick = {(e) => {
                            this.fetchSearchResults(this.state.type, 1, true);
                        }}
                    >
                    </div> 
                    {
                        (this.state.searchResults !== null)?
                        (
                            <div id = "upload-gallery-search-clear-button"
                                className = "image-button-s6"
                                style = {{ backgroundImage: clearImage }}
                                onClick = {this.clearSearchResults}
                            >
                            </div>
                        ) : null
                    }
                </div>
            </div>
        ) : null;


        // Search results
        let searchResultItems = null;
        let searchResults = null;
        if (this.state.searchResults !== null) {
            // Get the currently uploaded media IDs
            const mediaIDs = [];
            if (this.props.mediaKeys.length > 0) {
                for (let i = 0; i < this.props.mediaKeys.length; i++) {
                    mediaIDs.push(this.props.mediaIDs[this.props.mediaKeys[i]]);
                }
            }
            //console.log("UploadGallery / render - mediaIDs = ", mediaIDs);

            if (this.state.searchResults[this.state.page.toString()] !== null) {
                searchResultItems = this.state.searchResults[this.state.page.toString()].map(
                    (mediaInfo, mediaIndex) => {
                        // If selected
                        const index = mediaIDs.indexOf(mediaInfo.id);
                        const selected = (index >= 0);

                        // Selected text
                        const selectedText = (selected)? (
                            <div className = "upload-gallery-search-result-item-selected w4">
                                Selected
                            </div>
                        ) : null;

                        // Remove image button
                        const removeImage = getStaticPath("/images/common/remove-white-shadow.png");

                        const removeButton = (selected)? (
                            <div className = "upload-gallery-search-result-remove-button image-button-s3"
                                style = {{ backgroundImage: removeImage }}
                                onClick = {(e) => { this.props.removeMedia(e, index, mediaInfo.id); }}
                            >
                            </div>
                        ) : null;

                        // Add image button
                        const addImage = getStaticPath("/images/common/add-white-shadow.png");

                        const addButton = (selected)? null : (
                            <div className = "upload-gallery-search-result-add-button image-button-s3"
                                style = {{ backgroundImage: addImage }}
                                onClick = {(e) => { this.props.addSearchedMedia(mediaInfo); }}
                            >
                            </div>
                        );

                        // Media
                        let mediaURL = null;
                        let searchResultItemClass = null;

                        if (mediaInfo.type === "video") {
                            if ("t" in mediaInfo.files) {
                                mediaURL = getMediaProperty(mediaInfo, "t", 'url', false);
                                searchResultItemClass  = (this.props.colorMode === "day")?
                                    "upload-gallery-search-result-item-image border-day" :
                                    "upload-gallery-search-result-item-image border-night";
                            }
                            else {
                                mediaURL = (this.props.colorMode === "day")?
                                    getStaticPath("/images/common/video-black.png", false) :
                                    getStaticPath("/images/common/video-white.png", false);

                                searchResultItemClass = (this.props.colorMode === "day")?
                                    "upload-gallery-search-result-item-video border-day" :
                                    "upload-gallery-search-result-item-video border-night";
                            }
                        // }
                        // else {
                        //     mediaURL = getMediaProperty(mediaInfo, "xs", 'url', false);
                        }
                        else {
                            mediaURL = getMediaProperty(mediaInfo, "t", 'url', false);
                            searchResultItemClass  = (this.props.colorMode === "day")?
                                "upload-gallery-search-result-item-image image-cover border-day" :
                                "upload-gallery-search-result-item-image image-cover border-night";
                        }

                        // JSX
                        return(
                            <div key = {"upload-gallery-search-result-item-" + mediaIndex.toString()}
                                className = {(this.props.browserWidth <= 1)?
                                    "upload-gallery-search-result-item-loader-small image-loader-s4" :
                                    "upload-gallery-search-result-item-loader image-loader-s4"}
                                style = {{ backgroundImage: loaderImage }}
                            >
                                <img className = {searchResultItemClass}
                                    src = {mediaURL}
                                    alt = {""}
                                    style = {(selected)? { filter: "grayscale(100%)" } : null}
                                />
                                <div className = "upload-gallery-search-result-item-shadow"
                                    style = {{ backgroundImage: shadowImage }}
                                >
                                    {selectedText}
                                    {removeButton}
                                    {addButton}
                                </div>
                            </div>
                        );
                    }
                );

                searchResults = (
                    <div id = "upload-gallery-search-results-container">
                        <div id = "upload-gallery-search-results"
                            className = {searchResultsClass}
                        >
                            {searchResultItems}
                        </div>
                        {
                            (this.state.maxPage > 1)?
                            (
                                <Pagination
                                    colorMode = {this.props.colorMode}
                                    browserWidth = {this.props.browserWidth}
                                    items = {this.state.searchResults[this.state.page.toString()]}
                                    maxPage = {this.state.maxPage}
                                    numPagesPerGroup = {this.numPagesPerGroup}
                                    page = {this.state.page}
                                    goToPage = {this.goToPage}
                                    previousGroup = {this.previousGroup}
                                    nextGroup = {this.nextGroup}
                                />
                            ) : null
                        }
                    </div>
                );
            }
        }


        // Render component
        return (
            <div id = "upload-gallery">
                <div id = "upload-gallery-media-container"
                    style = {mediaContainerStyle}
                >
                    <div id = "upload-gallery-media-loader" style = {mediaLoaderStyle}
                        onMouseEnter = {(e) => this.mediaHoverOn(e)}
                        onMouseLeave = {(e) => this.mediaHoverOff(e)}>
                        {media}
                    </div>
                </div>
                {message}

            	<div id = "upload-gallery-thumbnails-container">
                	{thumbnails}
            	</div>

                {search}

                {searchResults}
        	</div>
        )
    }

    componentDidMount () {

    }

    componentDidUpdate (prevProps, prevState) {
        //console.log("----------------------------------");
        //console.log(" componentDidUpdate");
        //console.log("----------------------------------");

        if (prevProps.searchOn === true && this.props.searchOn === false) {
            this.clearSearchResults();

            this.props.clearSearchedMedia();
        }
    }

    thumbnailOnMouseEnter (e, index) {
        // Stop propogation of the event
        e.stopPropagation();

        // Change hover index
        this.setState({
            hoverMediaIndex: index
        });
    }

    thumbnailOnMouseLeave (e, index) {
        // Stop propogation of the event
        e.stopPropagation();

        // Change hover index
        this.setState({
            hoverMediaIndex: null
        });
    }

    // Main media hover actions
    mediaHoverOn(e) {
		// Stop propogation of the event
		e.stopPropagation();

    	//console.log("UploadGallery / mediaHoverOn");
    }

    mediaHoverOff(e) {
		// Stop propogation of the event
		e.stopPropagation();

    	//console.log("UploadGallery / mediaHoverOff");
    }


    searchOnFocus(e) {
        this.searchFocused = true;

        e.target.placeholder = "";

        this.searchBorder(e);
    }

    searchOnBlur(e) {
        this.searchFocused = false;

        e.target.placeholder = this.searchPlaceholder;

        this.searchBorder(e);
    }

    searchOnChange(e) {
        this.setState({
            keywords: this.searchRef.current.value
        });
    }

    searchBorder(e) {
        const searchBorderColor = (this.searchFocused)? 
        (
            window.colorGray
        ) : (
            (this.props.colorMode === "day")?
                window.colorLightGray : window.colorDarkestGray
        );

        this.setState({
            searchBorderColor: searchBorderColor
        });        
    }

    clearSearchResults() {
        //console.log("UploadGallery / clearSearchResults - this.searchRef.current = ", this.searchRef.current);
        if (this.searchRef.current !== null) {
            this.searchRef.current.value = "";
        }

        this.setState({
            keywords: null,
            searchResults: null,
            maxPage: null,
            page: 1
        })
    }

    fetchSearchResults(type, page, resetData) {
        //console.log("UploadGallery / fetchSearchResults");
        const axiosCallback = (response) => {
            //console.log("UploadGallery / fetchSearchResults - response.data.content = ", response.data.content);

            // Initialize results
            let searchResults = (resetData)? {} : Object.assign({}, this.state.dotsInfo);
            let maxPage = null;

            if ((response.data.content.media === null) && (response.data.content.media_results_total_pages === null)) {
                searchResults = null;
                maxPage = null;
            }
            else {
                searchResults[page.toString()] = response.data.content.media;
                maxPage = response.data.content.media_results_total_pages;
            }

            this.setState(
                {
                    searchResults: searchResults,
                    maxPage: maxPage,
                    page: page
                }
            );
        };

        // Send request
        getSearch(type, this.state.keywords, page)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARNING] UploadGallery / fetchSearchResults error - ", response);
            }
        );
    }

    goToPage(page, resetData) {
        if (Object.keys(this.state.searchResults).indexOf(page) >= 0) {

            // Change page
            this.setState({ page: page });
        }
        else {
            // Execute search for given page
            this.fetchSearchResults(this.state.type, page, false);
        }
    }

    previousGroup() {
        const previousPage = (Math.floor((this.state.page - 1) / this.numPagesPerGroup) - 1) * this.numPagesPerGroup + 1;

        this.goToPage(previousPage, false);
    }

    nextGroup() {
        const nextPage = (Math.floor((this.state.page - 1) / this.numPagesPerGroup) + 1) * this.numPagesPerGroup + 1;

        this.goToPage(nextPage, false);
    }
}


function moveMediaRight(e, index) {
    //const mediaKey = this.state.mediaKeys[index];
    const mediaKeys = this.state.mediaKeys.slice();

    mediaKeys[index + 1] = this.state.mediaKeys[index];
    mediaKeys[index] = this.state.mediaKeys[index + 1];
    
    this.setState({
        mediaKeys: mediaKeys
    });
}


function moveMediaLeft(e, index) {
    const mediaKeys = this.state.mediaKeys.slice();

    mediaKeys[index - 1] = this.state.mediaKeys[index];
    mediaKeys[index] = this.state.mediaKeys[index - 1];
    
    this.setState({
        mediaKeys: mediaKeys
    });
}


function thumbnailPrevPage (e) {
    // Stop propogation of the event
    e.stopPropagation();

    // Determine the first and last media indices of the displayed thumbnails
    const startMediaIndex = this.state.startMediaIndex - this.state.numThumbnails;
    const endMediaIndex = this.state.startMediaIndex - 1;
    //console.log("UploadGallery / thumbnailPrevPage - this.state.startMediaIndex = ", this.state.startMediaIndex);
    //console.log("UploadGallery / thumbnailPrevPage - this.state.endMediaIndex = ", this.state.endMediaIndex);
    //console.log("UploadGallery / thumbnailPrevPage - startMediaIndex = ", startMediaIndex);
    //console.log("UploadGallery / thumbnailPrevPage - endMediaIndex = ", endMediaIndex);

    const stateToUpdate = {
        startMediaIndex: startMediaIndex,
        endMediaIndex: endMediaIndex,
    };

    // Update state
    this.setState(stateToUpdate);
}


function thumbnailNextPage (e) {
    // Stop propogation of the event
    e.stopPropagation();

    // Determine the first and last media indices of the displayed thumbnails
    const startMediaIndex = this.state.startMediaIndex + this.state.numThumbnails;
    const endMediaIndex = ((this.state.endMediaIndex + this.state.numThumbnails) > (this.state.mediaKeys.length - 1))? 
        (this.state.mediaKeys.length - 1) : (this.state.endMediaIndex + this.state.numThumbnails);
    //console.log("UploadGallery / thumbnailNextPage - this.state.startMediaIndex = ", this.state.startMediaIndex);
    //console.log("UploadGallery / thumbnailNextPage - this.state.endMediaIndex = ", this.state.endMediaIndex);
    //console.log("UploadGallery / thumbnailNextPage - startMediaIndex = ", startMediaIndex);
    //console.log("UploadGallery / thumbnailNextPage - endMediaIndex = ", endMediaIndex);

    const stateToUpdate = {
        startMediaIndex: startMediaIndex,
        endMediaIndex: endMediaIndex,
    };

    // Update state
    this.setState(stateToUpdate);
}


// Need to be bound with the parent component
function addImage() {
    //console.log("UploadGallery / addImage ");

    // Check out attached file
    const file = this.imageNode.files[0];
    //console.log("UploadGallery / addImage - file = ", file);

    // If attached file is a blob
    if (file instanceof Blob) {
        // Generate media key
        const mediaKey = this.mediaCount;

        // Increase media counter
        this.mediaCount += 1;
        //console.log("UploadGallery / addImage - this.mediaCount = ", this.mediaCount);

        // Initiate update interval
        if (this.uploadUpdateInterval === null || this.uploadUpdateInterval === 0) {
            this.uploadUpdateInterval = setInterval(
                () => {
                    //console.log("UploadGallery / addImage - UPDATE INTERVAL - ", mediaKey);

                    this.setState(
                        {
                            uploadUpdate: true
                        },
                        () => {
                            this.setState({
                                uploadUpdate: false
                            });
                        }
                    )
                },
                this.uploadUpdateIntervalTime
            );
        }

        // Get local URL and image properties to enable image previews 
        const reader = new FileReader();
        reader.onload = (event) => {
            const mediaURL = reader.result;
            //console.log("UploadGallery / addImage - mediaURL = ", mediaURL);

            // Create a new image element to get image properties
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
                // Access image size here 
                //console.log("UploadGallery / addImage - image width = ", image.width);
                //console.log("UploadGallery / addImage - image height = ", image.height);

                // Extract location from exif information 
                EXIF.getData(
                    image,
                    () => {
                        //console.log("UploadGallery / addImage - exif data = ", image.exifdata);

                        // If location data exist
                        if ((typeof image.exifdata.GPSLatitude === "object") && (typeof image.exifdata.GPSLongitude === "object")) {
                            // Get latitude and longitude
                            const latitude = convertToDigits(
                                image.exifdata.GPSLatitude[0],
                                image.exifdata.GPSLatitude[1],
                                image.exifdata.GPSLatitude[2],
                                image.exifdata.GPSLatitudeRef
                            );

                            const longitude = convertToDigits(
                                image.exifdata.GPSLongitude[0],
                                image.exifdata.GPSLongitude[1],
                                image.exifdata.GPSLongitude[2],
                                image.exifdata.GPSLongitudeRef
                            );
                            //console.log("UploadGallery / addImage - latitude = ", latitude);
                            //console.log("UploadGallery / addImage - longitude = ", longitude);

                            // Get location object
                            const location = { latitude: latitude, longitude: longitude };
                            //console.log("UploadGallery / addImage - location = ", location);

                            // Update state
                            this.setState({
                                locationDetectOn: true,
                                locationDetected: location
                            });
                        }
                    }
                );

                // Clone media state
                const mediaKeys = this.state.mediaKeys.slice();
                const mediaCategories = this.state.mediaCategories.slice();
                const mediaIDs = this.state.mediaIDs.slice();
                
                // Media dimensions
                const mediaWidth = image.width;
                const mediaHeight = image.height;

                // Calculate the resized width and height of media and thumbnail
                //const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                //const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                //const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                // Add to lists
                mediaKeys.push(mediaKey);
                mediaCategories.push(0);
                mediaIDs.push(null);
                
                // Initialize media object
                this.media[mediaKey] = {
                    mediaType: "image",
                    mediaURL: mediaURL,
                    mediaWidth: mediaWidth,
                    mediaHeight: mediaHeight,
                    //mediaWidth: resizedMediaWidth,
                    //mediaHeight: resizedMediaHeight,
                    mediaCategory: 0,
                    thumbnailWidth: thumbnailWidth,
                    thumbnailHeight: thumbnailHeight,
                    source: "upload"
                };

                // Initialize upload progress object
                this.uploadProgress[mediaKey] = {
                    uploadPercentage: 0,
                    uploadComplete: false,
                    uploadProcessPercentage: 0,
                    uploadProcessComplete: false,
                    uploadProcessInterval: null,
                    uploadError: false,
                    removed: false
                };

                // Update the selected media index
                let selectedMediaIndex, startMediaIndex, endMediaIndex;

                if (mediaKeys.length === 1) {
                    selectedMediaIndex = 0;
                    startMediaIndex = 0;
                    endMediaIndex = 0;
                }
                else {
                    selectedMediaIndex = (mediaKeys.length - 1);
                    startMediaIndex = Math.floor((mediaKeys.length - 1 ) / this.state.numThumbnails) * this.state.numThumbnails;
                    endMediaIndex = selectedMediaIndex;
                }
                // console.log("UploadGallery / addImage - selectedMediaIndex = ", selectedMediaIndex);
                // console.log("UploadGallery / addImage - startMediaIndex = ", startMediaIndex);
                // console.log("UploadGallery / addImage - endMediaIndex = ", endMediaIndex);

                // Update state
                this.setState(
                    {
                        mediaKeys: mediaKeys,
                        mediaCategories: mediaCategories,
                        mediaIDs: mediaIDs,
                        selectedMediaIndex: selectedMediaIndex, 
                        startMediaIndex: startMediaIndex,
                        endMediaIndex: endMediaIndex
                    },
                    () => {
                        // Axios callback
                        const axiosCallback = (response) => {
                            //console.log("UploadGallery / addImage - axios response.data = ", response.data);

                            // Get media IDs
                            const mediaIDs = this.state.mediaIDs.slice();

                            // Update media id
                            mediaIDs[mediaKey] = response.data.content.id;

                            // Set upload process complete flag
                            this.uploadProgress[mediaKey].uploadProcessComplete = true;

                            // Check if all other processes are complete
                            let allUploadComplete = true;
                            const keys = Object.keys(this.uploadProgress);
                            for (let i = 0; i < keys.length; i++) {
                                const key = Number(keys[i]);
                                if ((this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) && this.uploadProgress[key].uploadError === false) {
                                    allUploadComplete = false;
                                    //console.log("[WARNING] Create / addImage - STILL UPLOADING - ", key);
                               }
                            }

                            // Turn off update interval if all processes are complete
                            if (allUploadComplete) {
                                //console.log("UploadGallery / addImage / onUploadProgress - UPDATE COMPLETE");

                                // Clear process interval
                                clearInterval(this.uploadProgress[mediaKey].uploadProcessInterval);
                                this.uploadProgress[mediaKey].uploadProcessInterval = 0;

                                // Clear update interval
                                clearInterval(this.uploadUpdateInterval);
                                this.uploadUpdateInterval = 0;
                            }

                            // Update state
                            this.setState(
                                {
                                    mediaIDs: mediaIDs,
                                    updateUpload: true
                                },
                                () => { 
                                    this.setState({
                                        updateUpload: false,
                                        mediaWarningOn: false,
                                        mediaWarningMessage: null
                                    }); 
                                }
                            );
                        };


                        // Attach the file as a part of formdata
                        const formData = new FormData();
                        const uploadPath = "dot";
                        formData.append("uploader_id", this.props.userInfo.id);
                        formData.append("image_file", file);
                        formData.append("width", mediaWidth);
                        formData.append("height", mediaHeight);
                        formData.append("create_mode", "file");
                        formData.append("directory", uploadPath);
                        formData.append("min_required_size", 300);


                        // Upload progress
                        const onUploadProgress = (progressEvent) => {
                            const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            //console.log("UploadGallery / addImage - progressEvent = ", progressEvent);
                            //console.log("UploadGallery / addImage - uploadPercentage = ", uploadPercentage);

                            // If upload is complete
                            if (uploadPercentage >= 100) {
                                //console.log("UploadGallery / addImage - UPLOAD COMPLETE - ", mediaKey);

                                // Set upload complete flag
                                if (this.uploadProgress[mediaKey].uploadComplete !== true) {
                                    // Set upload complete flag
                                    this.uploadProgress[mediaKey].uploadComplete = true;

                                    // Set up upload process interval
                                    this.uploadProgress[mediaKey].uploadProcessInterval = setInterval(
                                        () => {
                                            // Update percentage
                                            this.uploadProgress[mediaKey].uploadProcessPercentage += Math.round((this.uploadProcessIntervalTime * 100) / this.uploadProcessTime);
                                            //console.log("UploadGallery / addImage - this.uploadProcessPercentage - ", mediaKey, " = ", this.uploadProgress[mediaKey].uploadProcessPercentage);

                                            // If reached 100%
                                            if (this.uploadProgress[mediaKey].uploadProcessPercentage >= 100) {
                                                //console.log("UploadGallery / addImage - PROCESS COMPLETE - ", mediaKey);
                                                //console.log("UploadGallery / addImage - this.uploadProgress[mediaKey].uploadProcessInterval - ", this.uploadProgress[mediaKey].uploadProcessInterval);

                                                // Clear interval
                                                clearInterval(this.uploadProgress[mediaKey].uploadProcessInterval);
                                                //console.log("UploadGallery / addImage - this.uploadProgress[mediaKey].uploadProcessInterval (after clear) - ", this.uploadProgress[mediaKey].uploadProcessInterval);
                                                this.uploadProgress[mediaKey].uploadProcessInterval = 0;
                                                //console.log("UploadGallery / addImage - this.uploadProgress[mediaKey].uploadProcessInterval (after setting to zero) - ", this.uploadProgress[mediaKey].uploadProcessInterval);
                                            }
                                        },
                                        this.uploadProcessIntervalTime
                                    );
                                }
                            }

                            // Update the percentage
                            this.uploadProgress[mediaKey].uploadPercentage = uploadPercentage;
                        };

                        const axiosConfig = {
                            onUploadProgress: onUploadProgress
                        };

                        // Send create image request using axios post with CSRF token
                        postImage(formData, axiosConfig)
                        .then(axiosCallback)
                        .catch(
                            (response) => {
                                console.log("[WARNING] Create / addImage - axios error = ", response);

                                // Error handling
                                this.uploadProgress[mediaKey].uploadError = true;

                                // Check if all other processes are complete
                                let allUploadCompleteExceptThis = true;
                                const keys = Object.keys(this.uploadProgress);
                                for (let i = 0; i < keys.length; i++) {
                                    const key = Number(keys[i]);
                                    if (key !== mediaKey && 
                                        ((this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) && this.uploadProgress[key].uploadError === false)
                                    ) {
                                        allUploadCompleteExceptThis = false;
                                        //console.log("[WARNING] Create / addImage - STILL UPLOADING - ", key);
                                    }
                                }

                                // Clear update interval
                                if (allUploadCompleteExceptThis) {
                                    clearInterval(this.uploadUpdateInterval);
                                    this.uploadUpdateInterval = 0;
                                }

                                // Update state
                                this.setState(
                                    { 
                                        mediaWarningOn: true,
                                        mediaWarningMessage: "Image upload error",
                                        uploadUpdate: true
                                    },
                                    () => {
                                        this.setState({
                                            uploadUpdate: false
                                        });
                                    }
                                );
                            }
                        );
                    }
                );
            }
        };

        // Read file
        reader.readAsDataURL(file);
    }    
}


// Need to be bound with the parent component
function addVideo() {
    //console.log("UploadGallery / addVideo ");

    // Check out attached file
    const file = this.videoNode.files[0];
    //console.log("UploadGallery / addVideo - file = ", file);

    // If attached file is a blob
    if (file instanceof Blob) {
        // Generate media key
        const mediaKey = this.mediaCount;

        // Increase media counter
        this.mediaCount += 1;
        //console.log("UploadGallery / addImage - this.mediaCount = ", this.mediaCount);

        // Initiate update interval
        if (this.uploadUpdateInterval === null || this.uploadUpdateInterval === 0) {
            this.uploadUpdateInterval = setInterval(
                () => {
                    //console.log("UploadGallery / addImage - UPDATE INTERVAL - ", mediaKey);

                    this.setState(
                        {
                            uploadUpdate: true
                        },
                        () => {
                            this.setState({
                                uploadUpdate: false
                            });
                        }
                    )
                },
                this.uploadUpdateIntervalTime
            );
        }

        // Get local URL and image properties to enable image previews 
        const reader = new FileReader();
        reader.onload = (event) => {
            const mediaURL = reader.result;
            //console.log("UploadGallery / addImage - mediaURL = ", mediaURL);

            let video = document.createElement("video");
            video.setAttribute("src", mediaURL);

            video.onloadeddata = (event) => {
                const {
                    videoHeight,
                    videoWidth,
                    videoDuration
                } = event.srcElement;
                //console.log("UploadGallery / addVideo - videoHeight = ", videoHeight);
                //console.log("UploadGallery / addVideo - videoWidth = ", videoWidth);
                //console.log("UploadGallery / addVideo - videoDuration = ", videoDuration);

                // Media dimensions
                const mediaWidth = videoWidth;
                const mediaHeight = videoHeight;
                const thumbnailWidth = this.videoThumbnailWidth;
                const thumbnailHeight = this.videoThumbnailHeight;

                // Calculate the resized width and height of media and thumbnail
                const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
                //const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
                const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
                const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
                //const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
                //const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

                // Clone media state
                const mediaKeys = this.state.mediaKeys.slice();
                const mediaCategories = this.state.mediaCategories.slice();
                const mediaIDs = this.state.mediaIDs.slice();
                
                // Add to lists
                mediaKeys.push(mediaKey);
                mediaCategories.push(0);
                mediaIDs.push(null);
                
                // Initialize media object
                this.media[mediaKey] = {
                    mediaType: "video",
                    mediaURL: mediaURL,
                    mediaWidth: resizedMediaWidth,
                    mediaHeight: resizedMediaHeight,
                    mediaCategory: 0,
                    mediaDuration: videoDuration,
                    thumbnailWidth: thumbnailWidth,
                    thumbnailHeight: thumbnailHeight,
                    source: "upload"
                };

                // Initialize upload progress object
                this.uploadProgress[mediaKey] = {
                    uploadPercentage: 0,
                    uploadComplete: false,
                    uploadProcessPercentage: 0,
                    uploadProcessComplete: false,
                    uploadProcessInterval: null,
                    uploadError: false,
                    removed: false
                };

                // Update the selected media index
                let selectedMediaIndex, startMediaIndex, endMediaIndex;

                if (mediaKeys.length === 1) {
                    selectedMediaIndex = 0;
                    startMediaIndex = 0;
                    endMediaIndex = 0;
                }
                else {
                    selectedMediaIndex = (mediaKeys.length - 1);
                    startMediaIndex = Math.floor((mediaKeys.length - 1 ) / this.state.numThumbnails) * this.state.numThumbnails;
                    endMediaIndex = selectedMediaIndex;
                }
                // console.log("UploadGallery / addImage - selectedMediaIndex = ", selectedMediaIndex);
                // console.log("UploadGallery / addImage - startMediaIndex = ", startMediaIndex);
                // console.log("UploadGallery / addImage - endMediaIndex = ", endMediaIndex);

                // Update state
                this.setState(
                    {
                        mediaKeys: mediaKeys,
                        mediaCategories: mediaCategories,
                        mediaIDs: mediaIDs,
                        selectedMediaIndex: selectedMediaIndex, 
                        startMediaIndex: startMediaIndex,
                        endMediaIndex: endMediaIndex
                    },

                    () => {
                        // Axios callback
                        const axiosCallback = (response) => {
                            //console.log("UploadGallery / addImage - axios response.data = ", response.data);

                            // Get media IDs
                            const mediaIDs = this.state.mediaIDs.slice();

                            // Update media id
                            mediaIDs[mediaKey] = response.data.content.id;

                            // Set upload process complete flag
                            this.uploadProgress[mediaKey].uploadProcessComplete = true;

                            // Check if all other processes are complete
                            let allUploadComplete = true;
                            const keys = Object.keys(this.uploadProgress);
                            for (let i = 0; i < keys.length; i++) {
                                const key = Number(keys[i]);
                                if ((this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) && this.uploadProgress[key].uploadError === false) {
                                    allUploadComplete = false;
                                    //console.log("[WARNING] Create / addImage - STILL UPLOADING - ", key);
                               }
                            }

                            // Turn off update interval if all processes are complete
                            if (allUploadComplete) {
                                //console.log("UploadGallery / addImage / onUploadProgress - UPDATE COMPLETE");

                                // Clear process interval
                                clearInterval(this.uploadProgress[mediaKey].uploadProcessInterval);
                                this.uploadProgress[mediaKey].uploadProcessInterval = 0;

                                // Clear update interval
                                clearInterval(this.uploadUpdateInterval);
                                this.uploadUpdateInterval = 0;
                            }

                            // Update state
                            this.setState(
                                {
                                    mediaIDs: mediaIDs,
                                    updateUpload: true
                                },
                                () => { 
                                    this.setState({
                                        updateUpload: false,
                                        mediaWarningOn: false,
                                        mediaWarningMessage: null
                                    }); 
                                }
                            );
                        };


                        // Attach the file as a part of formdata
                        const formData = new FormData();
                        const uploadPath = "dot";
                        formData.append("uploader_id", this.props.userInfo.id);
                        formData.append("video_file", file);
                        formData.append("width", mediaWidth);
                        formData.append("height", mediaHeight);
                        formData.append("create_mode", "file");
                        formData.append("directory", uploadPath);
                        formData.append("min_required_size", 300);


                        // Upload progress
                        const onUploadProgress = (progressEvent) => {
                            const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            //console.log("UploadGallery / addImage - progressEvent = ", progressEvent);
                            //console.log("UploadGallery / addImage - uploadPercentage = ", uploadPercentage);

                            // If upload is complete
                            if (uploadPercentage >= 100) {
                                //console.log("UploadGallery / addImage - UPLOAD COMPLETE - ", mediaKey);

                                // Set upload complete flag
                                if (this.uploadProgress[mediaKey].uploadComplete !== true) {
                                    // Set upload complete flag
                                    this.uploadProgress[mediaKey].uploadComplete = true;

                                    // Set up upload process interval
                                    this.uploadProgress[mediaKey].uploadProcessInterval = setInterval(
                                        () => {
                                            // Update percentage
                                            this.uploadProgress[mediaKey].uploadProcessPercentage += Math.round((this.uploadProcessIntervalTime * 100) / this.uploadProcessTime);
                                            //console.log("UploadGallery / addImage - this.uploadProcessPercentage - ", mediaKey, " = ", this.uploadProgress[mediaKey].uploadProcessPercentage);

                                            // If reached 100%
                                            if (this.uploadProgress[mediaKey].uploadProcessPercentage >= 100) {
                                                //console.log("UploadGallery / addImage - PROCESS COMPLETE - ", mediaKey);
                                                //console.log("UploadGallery / addImage - this.uploadProgress[mediaKey].uploadProcessInterval - ", this.uploadProgress[mediaKey].uploadProcessInterval);

                                                // Clear interval
                                                clearInterval(this.uploadProgress[mediaKey].uploadProcessInterval);
                                                //console.log("UploadGallery / addImage - this.uploadProgress[mediaKey].uploadProcessInterval (after clear) - ", this.uploadProgress[mediaKey].uploadProcessInterval);
                                                this.uploadProgress[mediaKey].uploadProcessInterval = 0;
                                                //console.log("UploadGallery / addImage - this.uploadProgress[mediaKey].uploadProcessInterval (after setting to zero) - ", this.uploadProgress[mediaKey].uploadProcessInterval);
                                            }
                                        },
                                        this.uploadProcessIntervalTime
                                    );
                                }
                            }

                            // Update the percentage
                            this.uploadProgress[mediaKey].uploadPercentage = uploadPercentage;
                        };

                        const axiosConfig = {
                            onUploadProgress: onUploadProgress
                        };

                        // Send create image request using axios post with CSRF token
                        postVideo(formData, axiosConfig)
                        .then(axiosCallback)
                        .catch(
                            (response) => {
                                console.log("[WARNING] Create / addImage - axios error = ", response);

                                // Error handling
                                this.uploadProgress[mediaKey].uploadError = true;

                                // Check if all other processes are complete
                                let allUploadCompleteExceptThis = true;
                                const keys = Object.keys(this.uploadProgress);
                                for (let i = 0; i < keys.length; i++) {
                                    const key = Number(keys[i]);
                                    if (key !== mediaKey && 
                                        ((this.uploadProgress[key].uploadProcessComplete === false && this.uploadProgress[key].removed === false) && this.uploadProgress[key].uploadError === false)
                                    ) {
                                        allUploadCompleteExceptThis = false;
                                        //console.log("[WARNING] Create / addImage - STILL UPLOADING - ", key);
                                    }
                                }

                                // Clear update interval
                                if (allUploadCompleteExceptThis) {
                                    clearInterval(this.uploadUpdateInterval);
                                    this.uploadUpdateInterval = 0;
                                }

                                // Update state
                                this.setState(
                                    { 
                                        mediaWarningOn: true,
                                        mediaWarningMessage: "Video upload error",
                                        uploadUpdate: true
                                    },
                                    () => {
                                        this.setState({
                                            uploadUpdate: false
                                        });
                                    }
                                );
                            }
                        );
                    }
                );
            };
        };

        // Read file
        reader.readAsDataURL(file);
    }    
}


// Need to be bound with the parent component
function removeMedia(e, index, mediaID) {
	// Stop propogation of the event
	e.stopPropagation();

    // Delete a media file
    //console.log("Create / removeMedia - index = ", index);
    //console.log("Create / removeMedia - mediaID = ", mediaID);

    // Make copies of keys
    const mediaKeys = this.state.mediaKeys.slice();
    //console.log("Create / removeMedia - mediaKeys (before) = ", mediaKeys);

    // Store media key
    const mediaKey = mediaKeys[index];

	// Modify key list
    mediaKeys.splice(index, 1);
    //console.log("Create / removeMedia - mediaKeys (after) = ", mediaKeys);

    /*
    let overviewIncluded = false;
    for (let i = 0; i < mediaKeys.length; i++) {
        if (this.state.mediaCategories[mediaKeys[i]] === 0) {
            overviewIncluded = true;
        }
    }
    
    if (overviewIncluded === false) {
        this.setState({
            mediaWarningOn: true,
            mediaWarningMessage: "At least one media file must be for overview."
        })
    } 
    else {
    */

    // Update the selected media index and start and end indices of the thumbnails
    let selectedMediaIndex, startMediaIndex, endMediaIndex;
    if (mediaKeys.length === 0) {
        selectedMediaIndex = null;
        startMediaIndex = null;
        endMediaIndex = null;
    }
    else if (mediaKeys.length === 1) {
        selectedMediaIndex = 0;
        startMediaIndex = 0;
        endMediaIndex = 0;
    }
    else {
        // Get the selected media index
        if (index < this.state.selectedMediaIndex) {
            selectedMediaIndex = this.state.selectedMediaIndex - 1;
        }
        if (index === this.state.selectedMediaIndex) {
            // Select the previous one if the removed one is the last media of the list
            // Select the next media otherwise
            selectedMediaIndex = (index === this.state.mediaKeys.length - 1)? this.state.selectedMediaIndex - 1 : this.state.selectedMediaIndex;
        }
        else {
            selectedMediaIndex = this.state.selectedMediaIndex;
        }

        // Get the start and end indices of thumbnails
        startMediaIndex = this.state.startMediaIndex;
        endMediaIndex = this.state.endMediaIndex;

        // Adjust potential page adjustments
        if (this.state.endMediaIndex > (mediaKeys.length - 1)) {
            endMediaIndex = mediaKeys.length - 1;
        }
        if (this.state.startMediaIndex > (mediaKeys.length - 1)) {
            startMediaIndex = mediaKeys.length - this.state.numThumbnails;
        }
        if (selectedMediaIndex > (mediaKeys.length - 1)) {
            selectedMediaIndex = mediaKeys.length - 1;
        }
    }

    // Record removal
    this.uploadProgress[mediaKey].removed = true;

    // Check if any other errors
    let otherErrors = false;
    const keys = Object.keys(this.uploadProgress);
    for (let i = 0; i < keys.length; i++) {
        const key = Number(keys[i]);
        if (key !== mediaKey) {
            if (this.uploadProgress[key].uploadError === true && this.uploadProgress[key].removed === false) {
                otherErrors = true;
            }
        }
    }

    // Update state
    this.setState(
        {
            mediaKeys: mediaKeys,
            selectedMediaIndex: selectedMediaIndex,
            startMediaIndex: startMediaIndex,
            endMediaIndex: endMediaIndex, 
            mediaWarningOn: otherErrors? true : false,
            mediaWarningMessage: otherErrors? this.state.mediaWarningMessage : null                   
        }
    );

    // Send delete request
    if (mediaID !== null && this.media[this.state.mediaKeys[index]].source === "upload") {
        if (this.media[this.state.mediaKeys[index]].mediaType === "video") {
            deleteVideo(mediaID)
            .then((response) => { 
                //console.log("UploadGallery / removeMedia - axios response = ", response);
            })
            .catch((response) => {
                console.log("[WARNING] UploadGallery / removeMedia - axios error = ", response);
            });
        }
        else if (this.media[this.state.mediaKeys[index]].mediaType === "image") {
            deleteImage(mediaID)
            .then((response) => { 
                //console.log("UploadGallery / removeMedia - axios response = ", response);
            })
            .catch((response) => {
                console.log("[WARNING] UploadGallery / removeMedia - axios error = ", response);
            });
        }
    }
    //}
}


// Update start and end indices
function updateIndices() {
    // Number of thumbnails
    const numThumbnails = (this.props.browserWidth <= 4)?
        this.numThumbnailsSmall : 
        (
            (this.props.browserWidth <= 8)?
                this.numThumbnailsMedium : this.numThumbnailsLarge
        );

    // Start and end indices
    const startMediaIndex = 0;
    const endMediaIndex = Math.min(numThumbnails - 1, this.mediaCount - 1);

    // Update state
    this.setState({
        startMediaIndex: startMediaIndex,
        endMediaIndex: endMediaIndex,
        numThumbnails: numThumbnails
    });
}


/*
============================================================================================
    Pagination
============================================================================================
*/

const Pagination = (props) => {
    //console.log("Pagination - props = ", props);
    let previous = null;
    let next = null;
    //let pages = null;
    let pageItems = null;

    if (props.items != null && props.maxPage > 1) {
        const maxPageGroup = Math.floor((props.maxPage - 1) / props.numPagesPerGroup); // number group dotIndex
        const pageGroup = Math.floor((props.page - 1) / props.numPagesPerGroup); // current nunber group dotIndex

        if (pageGroup > 0) {
            previous = (
                <div className = "upload-gallery-search-results-previous"
                    style = {{
                        backgroundImage: (props.colorMode === "day")?
                            getStaticPath("/images/common/single-arrow-left-black.png") :
                            getStaticPath("/images/common/single-arrow-left-white.png")
                    }}
                    onClick = {() => { props.previousGroup(props.type); }}
                >
                </div>
            )
        }

        if (pageGroup < maxPageGroup) {
            next = (
                <div className = "upload-gallery-search-results-next"
                    style = {{
                        backgroundImage: (props.colorMode === "day")?
                            getStaticPath("/images/common/single-arrow-right-black.png") :
                            getStaticPath("/images/common/single-arrow-right-white.png")
                    }}
                    onClick = {() => { props.nextGroup(props.type); }}
            >
            </div>
            )
        }

        // Page groups
        const pageGroupStart = pageGroup * props.numPagesPerGroup + 1;
        const pageGroupEnd = (props.maxPage > (pageGroup + 1) * props.numPagesPerGroup)?
                            (pageGroup + 1) * props.numPagesPerGroup : props.maxPage;

        // Create an array of page numbers
        const pageNumbers = [];
        for (let pageNumber = pageGroupStart; pageNumber <= pageGroupEnd; pageNumber++) {
            pageNumbers.push(pageNumber);
        }

        // Page
        pageItems = pageNumbers.map(
            (pageNumber, index) => {
                const pageClassName = (pageNumber === props.page)?
                    (
                        (props.colorMode === "day")?
                            "upload-gallery-search-results-page page-selected-day-s2 " :
                            "upload-gallery-search-results-page page-selected-night-s2"
                    ) : (
                        (props.colorMode === "day")?
                            "upload-gallery-search-results-page page-day-s2 " :
                            "upload-gallery-search-results-page page-night-s2"
                    );

                return(
                    <div
                        key = {"upload-gallery-search-results-pagination-number-" + pageNumber.toString()}
                        className = {pageClassName}
                        onClick = {
                            () => { props.goToPage(pageNumber, false); }
                        }
                    >
                        {pageNumber}
                    </div>
                )
            }
        )
    }

    return(
        <div className = "upload-gallery-search-results-pages-container">
            {previous}
            <div className = "upload-gallery-search-results-pages">
                {pageItems}
            </div>
            {next}
        </div>
    )

}


// Need to be bound with the parent component
function selectMedia(e, index) {
	// Stop propogation of the event
	e.stopPropagation();

    // Select a media file
    //console.log("Create / selectMedia - index = ", index);
    this.setState({
        selectedMediaIndex: index
    });
}


// Need to be bound with the parent component
function addSearchedMedia(mediaInfo) {
    // Copy current state
    const mediaKeys = this.state.mediaKeys.slice();
    const mediaCategories = this.state.mediaCategories.slice();
    const mediaIDs = this.state.mediaIDs.slice();

    // Current media count
    let mediaCount = this.mediaCount;

    // Add new media
    mediaKeys.push(mediaCount);
    mediaCategories.push(0);
    mediaIDs.push(mediaInfo.id);

    // Calculate thumbnail width and height
    const mediaWidth = getMediaProperty(mediaInfo, "o", 'width',false);
    const mediaHeight = getMediaProperty(mediaInfo, "o", 'height', false);
    const mediaURL = getMediaProperty(mediaInfo, "o", 'url', false);
    // Calculate the resized width and height of media and thumbnail
    const mediaResizeRatio = Math.sqrt(this.mediaArea / (mediaWidth * mediaHeight));
    const thumbnailResizeRatio = Math.sqrt(this.thumbnailArea / (mediaWidth * mediaHeight));
    const resizedMediaWidth = Math.round(mediaResizeRatio * mediaWidth);
    const resizedMediaHeight = Math.round(mediaResizeRatio * mediaHeight);
    const thumbnailWidth = Math.round(thumbnailResizeRatio * mediaWidth);
    const thumbnailHeight = Math.round(thumbnailResizeRatio * mediaHeight);

    // Initialize media object
    this.media[mediaCount] = {
        mediaType: mediaInfo.type,
        mediaURL: mediaURL,
        mediaWidth: resizedMediaWidth,
        mediaHeight: resizedMediaHeight,
        mediaCategory: 0,
        thumbnailWidth: (mediaInfo.type === "video")? this.videoThumbnailWidth : thumbnailWidth,
        thumbnailHeight: (mediaInfo.type === "video")? this.videoThumbnailHeight : thumbnailHeight,
        source: "search"
    };

    // Initialize upload progress object
    this.uploadProgress[mediaCount] = {
        uploadPercentage: 100,
        uploadComplete: true,
        uploadProcessPercentage: 100,
        uploadProcessComplete: true,
        uploadProcessInterval: null,
        uploadError: false,
        removed: false
    };

    // Increase count
    this.mediaCount += 1;

    // Update the selected media index
    let selectedMediaIndex, startMediaIndex, endMediaIndex;

    if (mediaKeys.length === 1) {
        selectedMediaIndex = 0;
        startMediaIndex = 0;
        endMediaIndex = 0;
    }
    else {
        selectedMediaIndex = (mediaKeys.length - 1);
        startMediaIndex = Math.floor((mediaKeys.length - 1 ) / this.state.numThumbnails) * this.state.numThumbnails;
        endMediaIndex = selectedMediaIndex;
    }

    // Update state
    this.setState({
        selectedMediaIndex: selectedMediaIndex,
        startMediaIndex: startMediaIndex,
        endMediaIndex: endMediaIndex,
        mediaKeys: mediaKeys,
        mediaCategories: mediaCategories,
        mediaIDs: mediaIDs
    });
}


// Need to be bound with the parent component
function clearSearchedMedia(mediaInfo) {
    // Copy current state
    const mediaKeys = this.state.mediaKeys.slice();

    // For all media keys
    const newMediaKeys = [];
    for (let i = 0; i < mediaKeys.length; i++) {
        if (this.media[mediaKeys[i]].source !== "search") {
            newMediaKeys.push(mediaKeys[i]);
        }
    }

    // Update the selected media index
    let selectedMediaIndex, startMediaIndex, endMediaIndex;

    if (newMediaKeys.length === 0) {
        selectedMediaIndex = null;
        startMediaIndex = null;
        endMediaIndex = null;
    }
    else {
        selectedMediaIndex = 0;
        startMediaIndex = 0;
        endMediaIndex = Math.min(this.state.numThumbnails - 1, this.mediaCount - 1);
    }

    // Update state
    this.setState({
        selectedMediaIndex: selectedMediaIndex,
        startMediaIndex: startMediaIndex,
        endMediaIndex: endMediaIndex,
        mediaKeys: newMediaKeys
    });
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode
    };
}

const UploadGallery = connect(mapStateToProps, null)(UnconnectedUploadGallery);

// Export component and functions
export {
	UploadGallery, 
	addImage,
    addVideo,
	removeMedia,
    addSearchedMedia,
    clearSearchedMedia,
    selectMedia,
    moveMediaLeft,
    moveMediaRight,
    thumbnailPrevPage,
    thumbnailNextPage,
    updateIndices
};
