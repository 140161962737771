const findAPI = "api/dots/find/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const postFindDotsCheck = (dataJSON) => {
    let URL = rootURL + findAPI + "check/update/";

    return window.axiosCSRF.post(URL, dataJSON);
};

export const postFindDots = (dataJSON) => {
	let URL = rootURL + findAPI;

    return window.axiosCSRF.post(URL, dataJSON);
};
