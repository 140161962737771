/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    DotsHome.js
    - Main home feed that showcases dots and hike dots
--------------------------------------------------------------------------------------------
    Content
    - DotsHome
    - DotsHomeItem
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux"; 

import { storeUser } from "actions";

// Components
import SaveBucket from "components/SaveBucket";

// Modules
import DotsHomeItem from "./DotsHomeItem";

// Axios
import { 
    getHomeDots,
    postFetchDots,
    getMultiBoardComments,
    postCheckSave,
    postCheckLike
} from "requests";

// Websocket
import {
    addWebSocketGroups,
    removeWebSocketGroups
} from "js/WebSocketFunctions";

import {
    getStaticPath,
    sortMedia,
    debounce
} from "js/Functions";

// CSS
import "./DotsHome.scss";


class DotsHome extends Component {
    // Component constructor
    constructor(props) {
        //console.log("DotsHome / constructor - props = ", props);
        super(props);

        // Flag
        this.onScrollActive = false;

        // Map Mode (google / open / static)
        this.mapSource = "google";
        this.mapMode = "dots-home";

        // WebSocket Prefix
        this.webSocketGroupPrefix = "dot_board";

        // Number of dots per batch
        this.numDotsPerBatch = 20;

        // Initial state
        this.state = {
            feedMode: "contribute",

            contributedIDs: [],
            contributedStartID: null,
            contributedEndID: null,
            contributedDotsInfo: [],
            contributedDotsSaved: [],
            contributedDotsLiked: [],
            contributedFeedComplete: false,
            contributedLoaderOn: false,
            contributedGenesisOn: false,
            contributedCommentsInfo: [],
            contributedWebSocketGroups: [],

            everydayIDs: [],
            everydayStartID: null,
            everydayEndID: null,
            everydayDotsInfo: [],
            everydayDotsSaved: [],
            everydayDotsLiked: [],
            everydayFeedComplete: false,
            everydayLoaderOn: false,
            everydayGenesisOn: false,
            everydayCommentsInfo: [],
            everydayWebSocketGroups: [],

            checkScroll: false,
            contributeScrollPosition: 0,
            everydayScrollPosition: 0
        };

        // DOM Nodes
        this.contributedDotsContainerRef = React.createRef();
        this.everydayDotsContainerRef = React.createRef();

        // Bind functions
        this.setState = this.setState.bind(this);
        this.loadCommentsInfo = this.loadCommentsInfo.bind(this);

        // Feed mode
        this.menuModeClick = this.menuModeClick.bind(this);

        // Load dot ids and info
        this.loadDotIDs = this.loadDotIDs.bind(this);
        this.loadDotsInfo = this.loadDotsInfo.bind(this);

        // Scroll listener
        this.onScroll = this.onScroll.bind(this);
        this.scrollListener = debounce(this.onScroll, 50);
    }


    // Main render function
    render() {
        //console.log("DotsHome / render() - this.props = ", this.props);
        //console.log("DotsHome / render() - this.state.dotsLiked = ", this.state.dotsLiked);
        //console.log("DotsHome / render() - this.state.dotsSaved = ", this.state.dotsSaved);

        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && (this.props.userInfo !== null));

        // Everyday dots
        const everydayDots = this.state.everydayDotsInfo.map((dotInfo, index) => {
            //console.log("DotsHome / render() - dotInfo = ", dotInfo);

            // Comments
            const commentsInfoLoaded = (this.state.everydayDotsInfo.length === this.state.everydayCommentsInfo.length);
            const commentsInfo = (commentsInfoLoaded)?
                this.state.everydayCommentsInfo[index] : [];

            // Sort media
            const mediaSorted = sortMedia(dotInfo);

            // Merge all media categories
            const media = [
                ...mediaSorted.overview,
                ...mediaSorted.todos,
                ...mediaSorted.history,
                ...mediaSorted.stories
            ];

            // Construct dot item
            const dotsHomeItem = (
                <DotsHomeItem 
                    feedOn = {(this.props.dotsHomeOn && this.state.feedMode === "everyday")}
                    dotInfo = {dotInfo}
                    media = {media}
                    dotLiked = {this.state.everydayDotsLiked[index]}
                    dotSaved = {this.state.everydayDotsSaved[index]}
                    index = {index}
                    google = {this.props.google}
                    mapSource = {this.mapSource}
                    mapMode = {this.mapMode}
                    userInfo = {this.props.userInfo}
                    storeUser = {this.props.storeUser}
                    commentsInfo = {commentsInfo}
                    commentsInfoLoaded = {commentsInfoLoaded}
                    webSocketGroupPrefix = {this.webSocketGroupPrefix}
                    checkScroll = {this.state.checkScroll}
                />
            );

            // Return an individual item
            return(
                <div key = {"everyday-dots-home-item-" + index.toString()}
                    className = "dots-home-item"
                >
                    {dotsHomeItem}
                </div>
            );
        });

        // Authored dots
        const contributedDots = this.state.contributedDotsInfo.map((dotInfo, index) => {
            //console.log("DotsHome / render() - dotInfo = ", dotInfo);

            // Comments
            const commentsInfoLoaded = (this.state.contributedDotsInfo.length === this.state.contributedCommentsInfo.length);
            const commentsInfo = (commentsInfoLoaded)?
                this.state.contributedCommentsInfo[index] : [];

            // Sort media
            const mediaSorted = sortMedia(dotInfo);

            // Merge all media categories
            const media = [
                ...mediaSorted.overview,
                ...mediaSorted.todos,
                ...mediaSorted.history,
                ...mediaSorted.stories
            ];

            // Construct dot item
            const dotsHomeItem = (
                <DotsHomeItem 
                    feedOn = {(this.props.dotsHomeOn && this.state.feedMode === "contribute")}
                    dotInfo = {dotInfo}
                    media = {media}
                    dotLiked = {this.state.contributedDotsLiked[index]}
                    dotSaved = {this.state.contributedDotsSaved[index]}
                    index = {index}
                    google = {this.props.google}
                    mapSource = {this.mapSource}
                    mapMode = {this.mapMode}
                    userInfo = {this.props.userInfo}
                    storeUser = {this.props.storeUser}
                    commentsInfo = {commentsInfo}
                    commentsInfoLoaded = {commentsInfoLoaded}
                    webSocketGroupPrefix = {this.webSocketGroupPrefix}
                    checkScroll = {this.state.checkScroll}
                />
            );

            // Return an individual item
            return(
                <div key = {"everyday-dots-home-item-" + index.toString()}
                    className = "dots-home-item"
                >
                    {dotsHomeItem}
                </div>
            );
        });

        // Save bucket
        const saveBucket = loggedIn? 
        ( 
            <SaveBucket 
                userInfo = {this.props.userInfo} 
                setState = {this.setState}
            />
        ) : null;

        // Loader
        const loaderImage = (this.props.colorMode === "day")?
            getStaticPath("/images/loader/feed-loader-black.gif") :
            getStaticPath("/images/loader/feed-loader-white.gif");

        const everydayLoader = this.state.everydayLoaderOn? (
            <div className = "dots-home-loader"
                style = {{ 
                    backgroundImage:  loaderImage
                }}
            >
            </div>
        ) : null;

        const contributedLoader = this.state.contributedLoaderOn? (
            <div className = "dots-home-loader"
                style = {{ 
                    backgroundImage:  loaderImage
                }}
            >
            </div>
        ) : null;

        // Beginning
        const genesisImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/genesis-light-blue.png") :
            getStaticPath("/images/common/genesis-blue.png");

        const everydayGenesis = this.state.everydayGenesisOn? (
            <div className = "body-narrow">
                <div className = "dots-home-genesis image-contain"
                    style = {{ 
                        backgroundImage: genesisImage
                    }}
                >
                </div>
                <div className = {(this.props.colorMode === "day")? 
                    "dots-home-genesis-title k4" : "dots-home-genesis-title w4"}
                >
                    You've Reached the Beginning of Time.
                </div>
            </div>
        ) : null;

        const contributedGenesis = this.state.contributedGenesisOn? (
            <div className = "body-narrow">
                <div className = "dots-home-genesis image-contain"
                    style = {{ 
                        backgroundImage: genesisImage
                    }}
                >
                </div>
                <div className = {(this.props.colorMode === "day")? 
                    "dots-home-genesis-title k4" : "dots-home-genesis-title w4"}
                >
                    You've Reached the Beginning of Space.
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Bottom Menu
        ============================================================================================
        */

        /*
        const displayModeImage = (this.state.displayMode === "bucket")?
        (
            (this.props.colorMode === "day")?
                getStaticPath("/images/user/display-timeline-black.png") :
                getStaticPath("/images/user/display-timeline-white.png")
        ) : (
            (this.props.colorMode === "day")?
                getStaticPath("/images/user/display-bucket-black.png") :
                getStaticPath("/images/user/display-bucket-white.png")
        );
        */

        const textClassName = (this.props.browserWidth <= 2)?
            " font-century dots-home-bottom-menu-mode-small" :
            " font-century dots-home-bottom-menu-mode";

        const bottomMenu = (
            <div id = "dots-home-bottom-menu-container"
                className = {(this.props.colorMode === "day")?
                    "modal-day-no-shadow" : "modal-night"}
            >
                <div id = "dots-home-bottom-menu-modes">
                    <div id = "dots-home-bottom-menu-mode-contribute"
                        className = {
                            (this.state.feedMode === "contribute")?
                            (
                                (this.props.colorMode === "day")?
                                    "dots-home-bottom-menu-mode-selected-day light-blue" + textClassName :
                                    "dots-home-bottom-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName :
                                    "gray-blue" + textClassName
                            )
                        }
                        onClick = {(event) => { this.menuModeClick(event, "contribute"); }}
                    >
                        Authored
                    </div>

                    <div id = "dots-home-bottom-menu-mode-everyday"
                        className = {
                            (this.state.feedMode === "everyday")?
                            (
                                (this.props.colorMode === "day")?
                                    "dots-home-bottom-menu-mode-selected-day light-blue" + textClassName :
                                    "dots-home-bottom-menu-mode-selected-night blue" + textClassName
                            ) : (
                                (this.props.colorMode === "day")?
                                    "gray-light-blue" + textClassName :
                                    "gray-blue" + textClassName
                            )
                        }
                        onClick = {(event) => { this.menuModeClick(event, "everyday"); }}
                    >
                        Everyday
                    </div>
                </div>
            </div>
        );

        return (
            <div id =  "dots-home-container"
                className = {(this.props.browserWidth <= window.browserWidthSmall)? 
                    "dots-home-container-small" : "dots-home-container"}
                style = {{ display: (this.props.dotsHomeOn)? "block" : "none" }}
            >
                {saveBucket}

                <div ref = {this.everydayDotsContainerRef}
                    className =  "dots-home"
                    style = {{ display: (this.state.feedMode === "everyday")? "block" : "none" }}
                >
                    {everydayDots}
                    {everydayLoader}
                    {everydayGenesis}
                </div>
                <div ref = {this.contributedDotsContainerRef}
                    className =  "dots-home"
                    style = {{ display: (this.state.feedMode === "contribute")? "block" : "none" }}
                >
                    {contributedDots}
                    {contributedLoader}
                    {contributedGenesis}
                </div>

                {bottomMenu}

            </div>
        );
    }


    componentDidMount() {
        //this._isMounted = true;
        //console.log("DotsHome / componentDidMount");

        // Add event listeners
        window.addEventListener("scroll", this.scrollListener);

        // Load everyday dots
        this.loadDotIDs(
            "everyday",
            () => { this.loadDotsInfo("everyday"); }
        );

        // Load contributed dots
        this.loadDotIDs(
            "contribute",
            () => { this.loadDotsInfo("contribute"); }
        );
    }


    componentWillUnmount() {
        //this._isMounted = false;
        //console.log("DotsHome / componentWillUnmount");

        // Remove event listeners
        window.removeEventListener("scroll", this.scrollListener);

        // Remove WebSocket Groups
        removeWebSocketGroups(this.state.everydayWebSocketGroups);
        removeWebSocketGroups(this.state.contributedWebSocketGroups);
    }

    componentDidUpdate(prevProps, prevState) {
        // When logged in or out        
        if ((prevProps.userInfo === null) && (this.props.userInfo !== null)) {
            //console.log("DotsHome / componentDidUpdate - logged in");

            this.checkLike("everyday");
            this.checkLike("contribute");
            this.checkSave("everyday");
            this.checkSave("contribute");
        }
        else if ((prevProps.userInfo !== null) && (this.props.userInfo === null)) {
            //console.log("DotsHome / componentDidUpdate - logged out");

            this.resetLike();
            this.resetSave();
        }

        // Control event listeners
        if ((prevProps.dotsHomeOn === true) && (this.props.dotsHomeOn === false)) {
            // Remove event listeners
            window.removeEventListener("scroll", this.scrollListener);
        }
        else if ((prevProps.dotsHomeOn === false) && (this.props.dotsHomeOn === true)) {
            // Add event listeners
            window.addEventListener("scroll", this.scrollListener);

            // Restore scroll position
            if (this.state.feedMode === "everyday") {
                window.scrollTo(0, this.state.everydayScrollPosition);
            }
            else {
                window.scrollTo(0, this.state.contributeScrollPosition);
            }
        }
    }

    /*
    ============================================================================================
        loadDotIDs
    ============================================================================================
    */

    loadDotIDs(feedMode, callback) {
        //console.log("DotsHome / loadDotIDs");
        //console.log("DotsHome / loadDotIDs - feedMode = ", feedMode);

        // Determine start ID
        const startID = (feedMode === "everyday")?
            this.state.everydayEndID : this.state.contributedEndID;
        //console.log("DotsHome / loadDotIDs - startID = ", startID);

        // Determine if user is logged in
        //const loggedIn = !!localStorage.token;

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("DotsHome / loadDotIDs - response = ", response);
            //console.log("DotsHome / loadDotIDs - response.data.content.dot_ids = ", response.data.content.dot_ids);
            //console.log("DotsHome / loadDotIDs - response.data.content.feed_complete = ", response.data.content.feed_complete);

            // Patch up the dotIDs array
            const dotIDsPrevious = (feedMode === "everyday")?
                this.state.everydayIDs.slice() : this.state.contributedIDs.slice();
            const dotIDs = dotIDsPrevious.concat(response.data.content.dot_ids)

            // Update state
            if (feedMode === "everyday") {
                this.setState(
                    {
                        everydayIDs: dotIDs,
                        everydayFeedComplete: response.data.content.feed_complete,
                        everydayGenesisOn: response.data.content.feed_complete? true : false,
                    },
                    callback
                );
            }
            else {
                this.setState(
                    {
                        contributedIDs: dotIDs,
                        contributedFeedComplete: response.data.content.feed_complete,
                        contributedGenesisOn: response.data.content.feed_complete? true : false,
                    },
                    callback
                );
            }
        };

        getHomeDots(feedMode, startID, null)
        .then(axiosCallback)
        .catch((response) => {
            console.log("DotsHome / loadDotIDs - Axios error ", response)
        });
    }


    /*
    ============================================================================================
        loadDotsInfo
    ============================================================================================
    */

    loadDotsInfo(feedMode) {
        //console.log("DotsHome / loadDotsInfo");
        //console.log("DotsHome / loadDotsInfo - feedMode = ", feedMode);

        // Determine if user is logged in
        const loggedIn = !!localStorage.token;

        // Determine dot ids
        let dotIDs = null;
        let fetchComplete = false;
        if (feedMode === "everyday") {
            const startIndex = (this.state.everydayEndID === null)? 0 : 
                this.state.everydayIDs.indexOf(this.state.everydayEndID) + 1;
            const endIndex = startIndex + this.numDotsPerBatch;
            const maxIndex = this.state.everydayIDs.length;

            if (startIndex >= maxIndex && endIndex >= maxIndex) {
                fetchComplete = true;
                dotIDs = [];
            }
            else if (startIndex < maxIndex && endIndex >= maxIndex) {
                dotIDs = this.state.everydayIDs.slice(startIndex, maxIndex);
            }
            else {
                dotIDs = this.state.everydayIDs.slice(startIndex, endIndex);
            }
        }
        else {
            const startIndex = (this.state.contributedEndID === null)? 0 : 
                this.state.contributedIDs.indexOf(this.state.contributedEndID) + 1;
            const endIndex = startIndex + this.numDotsPerBatch;
            const maxIndex = this.state.contributedIDs.length;

            if (startIndex >= maxIndex && endIndex >= maxIndex) {
                fetchComplete = true;
                dotIDs = [];
            }
            else if (startIndex < maxIndex && endIndex >= maxIndex) {
                dotIDs = this.state.contributedIDs.slice(startIndex, maxIndex);
            }
            else {
                dotIDs = this.state.contributedIDs.slice(startIndex, endIndex);
            }
        }
        //console.log("DotsHome / loadDotsInfo - startIndex = ", startIndex);
        //console.log("DotsHome / loadDotsInfo - endIndex = ", endIndex);
        //console.log("DotsHome / loadDotsInfo - dotIDs = ", dotIDs);

        // Callback after retrieving the dots info
        const callback = () => {
            this.onScrollActive = false;
            //console.log("DotsHome / loadDotsInfo - this.state = ", this.state);
            //console.log("DotsHome / loadDotsInfo - this.onScrollActive = ", this.onScrollActive);
        };

        if (fetchComplete) {
            // Update state
            if (feedMode === "everyday") {
                this.setState(
                    {
                        everydayFeedLoaderOn: false
                    },
                    callback
                );
            }
            else {
                this.setState(
                    {
                        contributedFeedLoaderOn: false
                    },
                    callback
                );
            }
        }
        else {
            // Axios callback : execute when the server returns a response
            const axiosCallback = (response) => {
                //console.log("DotsHome / loadDotsInfo - response = ", response);
                //console.log("DotsHome / loadDotsInfo - response.data.content.dots = ", response.data.content.dots);
                //console.log("DotsHome / loadDotsInfo - response.data.content.feed_complete = ", response.data.content.feed_complete);

                // Patch up the dotsInfo array
                const dotsInfoPrevious = (feedMode === "everyday")?
                    this.state.everydayDotsInfo.slice() : this.state.contributedDotsInfo.slice();
                const dotsInfo = dotsInfoPrevious.concat(response.data.content.dots)

                // Fetch dotsSaved and dotsLiked arrays
                const dotsLikedPrevious = (feedMode === "everyday")?
                    this.state.everydayDotsLiked.slice() : this.state.contributedDotsLiked.slice();
     
                const dotsSavedPrevious = (feedMode === "everyday")?
                    this.state.everydayDotsSaved.slice() : this.state.contributedDotsSaved.slice();
     
                const dotsLikedNew = [];
                const dotsSavedNew = [];
                for (let i = 0; i < response.data.content.dots.length; i++) {
                    if (loggedIn) {
                        dotsLikedNew.push(response.data.content.dots[i].liked_by_me);
                        dotsSavedNew.push(response.data.content.dots[i].saved_by_me);
                    }
                    else {
                        dotsLikedNew.push(null);
                        dotsSavedNew.push(null);
                    }
                }
                const dotsLiked = dotsLikedPrevious.concat(dotsLikedNew);
                const dotsSaved = dotsSavedPrevious.concat(dotsSavedNew);

                // Create board id arrays
                const boardIDs = [];
                for (let i = 0; i < dotsInfo.length; i++) {
                    boardIDs.push(dotsInfo[i].board.id);
                }

                // Fetch board comments
                if (boardIDs.length > 0) {
                    this.loadCommentsInfo(boardIDs, feedMode);
                }

                // Add relevant webSocket group
                let webSocketGroups = [];
                for (let i = 0; i < response.data.content.dots.length; i++) {
                    webSocketGroups.push(this.webSocketGroupPrefix + "_" + response.data.content.dots[i].board.id);
                }
                if (webSocketGroups.length > 0) {
                    addWebSocketGroups(webSocketGroups);
                }

                // Update state
                const newWebSocketGroups = (feedMode === "everyday")?
                    this.state.everydayWebSocketGroups.concat(webSocketGroups) :
                    this.state.contributedWebSocketGroups.concat(webSocketGroups);

                // Update state
                if (feedMode === "everyday") {
                    this.setState(
                        {
                            everydayEndID: dotIDs[dotIDs.length - 1],
                            everydayDotsInfo: dotsInfo,
                            everydayDotsLiked: dotsLiked,
                            everydayDotsSaved: dotsSaved,
                            everydayFeedLoaderOn: false,
                            everydayWebSocketGroups: newWebSocketGroups
                        },
                        callback
                    );
                }
                else {
                    this.setState(
                        {
                            contributedEndID: dotIDs[dotIDs.length - 1],
                            contributedDotsInfo: dotsInfo,
                            contributedDotsLiked: dotsLiked,
                            contributedDotsSaved: dotsSaved,
                            contributedFeedLoaderOn: false,
                            contributedWebSocketGroups: newWebSocketGroups
                        },
                        callback
                    );
                }
            };

            const dataJSON = {
                dot_ids: dotIDs
            };

            postFetchDots(dataJSON)
            .then(axiosCallback)
            .catch((response) => {
                console.log("DotsHome / loadDotsInfo - Axios error ", response)
            });
        }
    }


    /*
    ============================================================================================
        Check Like
    ============================================================================================
    */
    checkLike(feedMode, callback) {
        // Construct request data
        const dotIDs = [];
        if (feedMode === "everyday") {
            for (let i = 0; i < this.state.everydayDotsInfo.length; i++) {
                dotIDs.push(this.state.everydayDotsInfo[i].id);
            }
        }
        else {
            for (let i = 0; i < this.state.contributedDotsInfo.length; i++) {
                dotIDs.push(this.state.contributedDotsInfo[i].id);
            }
        }

        const dataJSON = {
            "dot_ids" : dotIDs
        };
        //console.log("DotsHome / checkLike - dataJSON = ", dataJSON);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (checkLikeResponse) => {
            //console.log("DotsHome / checkLike - checkLikeResponse = ", checkLikeResponse);
            //console.log("DotsHome / checkLike - checkLikeResponse.data.content.dot_liked = ", checkLikeResponse.data.content.dot_liked);

            if (feedMode === "everyday") {
                // Set state
                this.setState(
                    {
                        everydayDotsLiked: checkLikeResponse.data.content.dot_liked
                    },
                    callback
                );
            }
            else {
                // Set state
                this.setState(
                    {
                        contributedDotsLiked: checkLikeResponse.data.content.dot_liked
                    },
                    callback
                );
            }
        };

        postCheckLike(dataJSON)
        .then(axiosCallback)
        .catch((response) => {
            console.log("DotsHome / checkLike - Axios error ", response)
        });
    }

    /*
    ============================================================================================
        Check Save
    ============================================================================================
    */
    checkSave(feedMode, callback) {
        // Construct request data
        const dotIDs = [];
        if (feedMode === "everyday") {
            for (let i = 0; i < this.state.everydayDotsInfo.length; i++) {
                dotIDs.push(this.state.everydayDotsInfo[i].id);
            }
        }
        else {
            for (let i = 0; i < this.state.contributedDotsInfo.length; i++) {
                dotIDs.push(this.state.contributedDotsInfo[i].id);
            }
        }

        const dataJSON = {
            "dot_ids" : dotIDs
        };
        //console.log("DotsHome / checkSave - dataJSON = ", dataJSON);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (checkSaveResponse) => {
            //console.log("DotsHome / checkSave - checkSaveResponse = ", checkSaveResponse);
            //console.log("DotsHome / checkSave - checkSaveResponse.data.content.dot_saved = ", checkSaveResponse.data.content.dot_saved);

            if (feedMode === "everyday") {
                // Set state
                this.setState(
                    {
                        everydayDotsSaved: checkSaveResponse.data.content.dot_saved
                    },
                    callback
                );
            }
            else {
                // Set state
                this.setState(
                    {
                        contributedDotsSaved: checkSaveResponse.data.content.dot_saved
                    },
                    callback
                );
            }
        };

        postCheckSave(dataJSON)
        .then(axiosCallback)
        .catch((response) => {
            console.log("DotsHome / checkSave - Axios error ", response)
        });
    }

    /*
    ============================================================================================
        Reset Like
    ============================================================================================
    */
    resetLike() {
        // Construct nullified like
        const everydayDotsLiked = [];
        for (let i = 0; i < this.state.everydayDotsInfo.length; i++) {
            everydayDotsLiked.push(null);
        }

        const contributedDotsLiked = [];
        for (let i = 0; i < this.state.contributedDotsInfo.length; i++) {
            contributedDotsLiked.push(null);
        }

        // Update state
        this.setState(
            {
                everydayDotsLiked: everydayDotsLiked,
                contributedDotsLiked: contributedDotsLiked
            }
        );
    }

    /*
    ============================================================================================
        Reset Save
    ============================================================================================
    */
    resetSave() {
        // Construct nullified save
        const everydayDotsSaved = [];
        for (let i = 0; i < this.state.everydayDotsInfo.length; i++) {
            everydayDotsSaved.push(null);
        }

        const contributedDotsSaved = [];
        for (let i = 0; i < this.state.contributedDotsInfo.length; i++) {
            contributedDotsSaved.push(null);
        }

        // Update state
        this.setState(
            {
                everydayDotsSaved: everydayDotsSaved,
                contributedDotsSaved: contributedDotsSaved
            }
        );
    }

    /*
    ============================================================================================
        loadCommentsInfo
    ============================================================================================
    */

    loadCommentsInfo(boardIDs, feedMode) {
        // console.log("DotsHome / loadCommentsInfo - boardIDs = ", boardIDs);

        // Axios callback : execute when the server returns a response
        const axiosCallback = (commentsResponse) => {
            // console.log("DotsHome / loadCommentsInfo - commentsResponse.data.content = ", commentsResponse.data.content);

            if (feedMode === "everyday") {
                this.setState(
                    {
                        everydayCommentsInfo: commentsResponse.data.content
                    }
                );
            }
            else if (feedMode === "contribute") {
                this.setState(
                    {
                        contributedCommentsInfo: commentsResponse.data.content
                    }
                );
            }
        };

        // Send post request using axios with CSRF token
        getMultiBoardComments(boardIDs)
        .then(axiosCallback)
        .catch((response) => {console.log("DotsHome / loadCommentsInfo - Axios error ", response);});
    }


    /*
    ============================================================================================
        menuModeClick
    ============================================================================================
    */

    menuModeClick(event, feedMode) {
        // Stop propagation
        event.stopPropagation();

        if (feedMode === "everyday") {
            // Save scroll position
            const scrollPosition = window.scrollY;

            // Update state
            this.setState(
                {
                    feedMode: "everyday",
                    contributeScrollPosition: scrollPosition
                },
                () => {
                    window.scrollTo(0, this.state.everydayScrollPosition);
                    //console.log("DotsHome / loadDotsInfo - this.state = ", this.state);
                }
            );
        }
        else {
            // Save scroll position
            const scrollPosition = window.scrollY;

            // Update state
            this.setState(
                {
                    feedMode: "contribute",
                    everydayScrollPosition: scrollPosition
                },
                () => {
                    window.scrollTo(0, this.state.contributeScrollPosition);
                    //console.log("DotsHome / loadDotsInfo - this.state = ", this.state);
                }
            );            
        }
    }


    /*
    ============================================================================================
        onScroll
    ============================================================================================
    */

    onScroll(e) {
        //console.log("DotsHome / onScroll - e = ", e);

        // When reached the bottom
        if (this.props.dotsHomeOn) {
            if (this.state.feedMode === "everyday" && this.everydayDotsContainerRef.current !== null) {
                // Send out check scroll signal
                this.setState(
                    {
                        checkScroll: true
                    },
                    () => {
                        this.setState({
                            checkScroll: false,
                            everydayScrollPosition: window.scrollY
                        });
                    }
                );

                if ((this.everydayDotsContainerRef.current.getBoundingClientRect().bottom <= window.innerHeight) && (!this.onScrollActive)) {
                    // Activate flag
                    this.onScrollActive = true;

                    // Update state
                    this.setState(
                        {
                            everydayLoaderOn: true,
                        },
                        () => {
                            // Fetch another page
                            if (!this.state.everydayFeedComplete) {
                                if (this.state.everydayIDs.length > this.state.everydayDotsInfo.length) {
                                    console.log("DotsHome / onScroll - fetching everyday dotsInfo");
                                    this.loadDotsInfo("everyday");
                                }
                                else {
                                    console.log("DotsHome / onScroll - fetching everyday dotIDs and dotsInfo");
                                    this.loadDotIDs(
                                        "everyday",
                                        () => { this.loadDotsInfo("everyday"); }
                                    );
                                }
                            }
                            else {
                                // De-activate flag
                                this.onScrollActive = false;

                                this.setState(
                                    {
                                        everydayLoaderOn: false
                                    }
                                )
                            }
                        }
                    )
                }
                else {
                    // De-activate flag
                    this.onScrollActive = false;
                }
            }
            else if (this.state.feedMode === "contribute" && this.contributedDotsContainerRef.current !== null) {
                // Send out check scroll signal
                this.setState(
                    {
                        checkScroll: true
                    },
                    () => {
                        this.setState({
                            checkScroll: false,
                            contributeScrollPosition: window.scrollY
                        });
                    }
                );

                if ((this.contributedDotsContainerRef.current.getBoundingClientRect().bottom <= window.innerHeight) && (!this.onScrollActive)) {
                    // Activate flag
                    this.onScrollActive = true;

                    // Update state
                    this.setState(
                        {
                            contributedLoaderOn: true,
                        },
                        () => {
                            // Fetch another page
                            if (!this.state.contributedFeedComplete) {

                                if (this.state.contributedIDs.length > this.state.contributedDotsInfo.length) {
                                    console.log("DotsHome / onScroll - fetching contributed dotsInfo");
                                    this.loadDotsInfo("contribute");
                                }
                                else {
                                    console.log("DotsHome / onScroll - fetching contributed dotIDs and dotsInfo");
                                    // Load contributed dots
                                    this.loadDotIDs(
                                        "contribute",
                                        () => { this.loadDotsInfo("contribute"); }
                                    );
                                }

                            }
                            else {
                                // De-activate flag
                                this.onScrollActive = false;

                                this.setState(
                                    {
                                        contributedLoaderOn: false
                                    }
                                );
                            }
                        }
                    )
                }
            }
        }
    }
}


function mapStateToProps(state) {
    return {
        dotsHomeOn: state.nav.dotsHomeOn,
        browserWidth: state.nav.browserWidth,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({storeUser}, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(DotsHome); 
