/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Notification.js
--------------------------------------------------------------------------------------------
    Content
    - Notification
============================================================================================
*/


// React / ReactDOM / React-router
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Modules
import ReactTooltip from "thedots-tooltip";

// Functions
import {
    url,
	getStaticPath,
    getMediaProperty,
    sortMedia,
    formatDatetime,
    formatTimeElapsed,
} from "js/Functions";

// CSS
import "./Notification.scss";


class Notification extends Component {
	constructor(props) {
		super(props);

        // Initialize state
        this.state = {
            scrollBarOn: false
        };

		// DOM nodes
		this.notificationsNode = null;

		// Bind functions
		this.notificationsOnScroll = this.notificationsOnScroll.bind(this);
        this.detectScrollBar = this.detectScrollBar.bind(this);
	}

	render() {
        // Triangle image
		const menuTriangleImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/top-menu-triangle-day.png") :
            getStaticPath("/images/common/top-menu-triangle-night.png");

        // Notifications list
		const notificationsList = (this.props.notificationsInfo.length === 0)?
		(
			<div id = "notification-item-blank"
                className = {(this.props.colorMode === "day")?
                    "notification-item-blank dg5" :
                    "notification-item-blank g5"}
            >
				No Notificaitons
			</div>
		) :
		this.props.notificationsInfo.map((notificationInfo, index) => {
	        // Return an individual item
	        return(
	        	<NotificationItem
                    setState = {this.props.setState}
                    storeNotificationMenuOn = {this.props.storeNotificationMenuOn}
                    scrollBarOn = {this.state.scrollBarOn}
                    colorMode = {this.props.colorMode}
		        	key = {"notification-item-" + index.toString()}
	        		index = {index}
	        		notificationInfo = {notificationInfo}
	        	/>
	        );
	    });

        const closeButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/cancel-black.png") :
            getStaticPath("/images/common/cancel-white.png");

		return (
            <div id = "notifications"
                className = {(this.props.buttonsOff)?
                    "notifications-small" : "notifications"}
            >
                {
                    (this.props.buttonsOff)?
                        null : (
                            <div id = "notifications-triangle"
                                style = {{ backgroundImage: menuTriangleImage }}
                            >
                            </div>
                        )
                }
				<div id = "notifications-shadow">
					<div id = "notifications-header"
                        className = {(this.props.colorMode === "day")?
                            "notifications-header-day k5" :
                            "notifications-header-night w5"}
                    >
						Notifications
                        {
                            (this.props.buttonsOff)?
                            (
                                <div id = "notifications-close-button"
                                    className = "image-button-s8"
                                    style = {{ backgroundImage: closeButtonImage }}
                                    onClick = {() => { this.props.storeNotificationMenuOn(false); }}
                                >
                                </div>
                            ) : null
                        }
					</div>
					<div id = "notifications-container"
                        className = {
                            (this.props.buttonsOff)?
                                (
                                    (this.props.colorMode === "day")?
                                    "notifications-container-long notifications-container-day" :
                                    "notifications-container-long notifications-container-night"
                                ) : (
                                    (this.props.colorMode === "day")?
                                    "notifications-container-short notifications-container-day" :
                                    "notifications-container-short notifications-container-night"
                                )
                        }
                    >
						{notificationsList}
					</div>
					<div id = "notifications-footer"
                        className = {(this.props.colorMode === "day")?
                            "notifications-footer-day" :
                            "notifications-footer-night"}
                    >
					</div>
				</div>
			</div>
		)
	}

	componentDidMount() {
        // DOM Node
        this.notificationsNode = document.getElementById("notifications-container");

        // Add event listeners
       	this.notificationsNode.addEventListener("scroll", this.notificationsOnScroll);
        //this.notificationsNode.addEventListener("resize", this.detectScrollBar);

        // Detect scroll bar
        this.detectScrollBar();
	}


    componentWillUnmount() {
        // Remove event listeners
        this.notificationsNode.removeEventListener("scroll", this.notificationsOnScroll);
        //this.notificationsNode.removeEventListener("resize", this.detectScrollBar);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.notificationsInfo !== null) &&
                (prevProps.notificationsInfo.length !== this.props.notificationsInfo.length)) {
            this.detectScrollBar();
        }
    }

    detectScrollBar() {
        const scrollBarOn = this.notificationsNode.scrollHeight > this.notificationsNode.clientHeight;

        // Update state
        this.setState({
            scrollBarOn: scrollBarOn
        });
    }

    notificationsOnScroll(e) {
        //console.log("Notification / notificationsOnScroll - e = ", e);

        // Stop propagation to parents
        //e.stopPropagation();

    	// If end of scroll is reached
		if (Math.floor(this.notificationsNode.scrollHeight - this.notificationsNode.scrollTop) === Math.floor(this.notificationsNode.clientHeight)) {
			//console.log("Notification - notificationsOnScroll - end of scroll");

			// Fetch more notifications
			if (!this.props.notificationsFetchComplete) {
				//console.log("Notification - notificationsOnScroll - fetching more notifications");
				this.props.getNotificationsInfo();
			}
		}
		/*
			console.log("--------------------------------------------------------------------------------");
			console.log("Notification - notificationsOnScroll - scrollHeight = ", this.notificationsNode.scrollHeight);
			console.log("Notification - notificationsOnScroll - scrollTop = ", this.notificationsNode.scrollTop);
			console.log("Notification - notificationsOnScroll - clientHeight = ", this.notificationsNode.clientHeight);
		*/
    }
}


function NotificationItem(props) {
    //console.log("NotificationItem / render - props.scrollBarOn = ", props.scrollBarOn);

	// Class name
	const notificationItemClassName = (props.notificationInfo.checked)?
        (
            (props.colorMode === "day")?
                "notification-item-checked-day" :
                "notification-item-checked-night"
        ) : (
            (props.colorMode === "day")?
                "notification-item-unchecked-day" :
                "notification-item-unchecked-night"
        );

	// Timestamp
    const timeElapsed = formatTimeElapsed(props.notificationInfo.timestamp);
    const timestampText = formatDatetime(props.notificationInfo.timestamp, true, false, " ");
    const timestampTooltipID = "notification-item-" + props.index + "-timestamp-tooltip";

    const timestamp = (
        <span className = "notification-item-timestamp b6"
            data-for = {timestampTooltipID}
            data-tip = {timestampText}
        >
            {timeElapsed}
        </span>
    );
    const timestampTooltip = (
        <ReactTooltip
            id = {timestampTooltipID}
            className = {"notification-item-timestamp-tooltip tooltip-s2"}
            type = "info"
            place = "right"
            html = {true}
        />
    );

	// Code #1 - followed by another user
	if (Number(props.notificationInfo.code) === 1) {
	    // User action image
		const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

		return (
			<div key = {"notification-item-content-" + props.index.toString()}
				className = {notificationItemClassName}
			>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

            	<div className = {(props.scrollBarOn)?
                    "notification-item-description-long-scroll" :
                    "notification-item-description-long"}
                >
                    <Link to = {`/user/${props.notificationInfo.user_action.username}`}
                        onClick = {() => { props.storeNotificationMenuOn(false);}}
                    >
                		<span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                			{props.notificationInfo.user_action.name}
                		</span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" started following you."}
                    </span>
            		{timestamp}
            		{timestampTooltip}
            	</div>
			</div>
		);
	}
	// Code #2 - comment on a dot
	else if (Number(props.notificationInfo.code) === 2) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if ("t" in mediaInfo.files) {
                dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
            }
            else {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

	    // User action image
 		const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

		return (
			<div key = {"notification-item-content-" + props.index.toString()}
				className = {notificationItemClassName}
			>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                		<span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                			{props.notificationInfo.user_action.name}
                		</span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                		{" left a comment on your " + dotType + " "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                    >
                		<span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                			{(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                		</span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                		{"."}
                    </span>
            		{timestamp}
            		{timestampTooltip}
            	</div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                >
                </Link>
			</div>
		);
    }
    // Code #3 - reply to a comment
    else if (Number(props.notificationInfo.code) === 3) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if ("t" in mediaInfo.files) {
                dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
            }
            else {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

	    // User action image
		const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

		return (
			<div key = {"notification-item-content-" + props.index.toString()}
				className = {notificationItemClassName}
			>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

            	<div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                		<span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                			{props.notificationInfo.user_action.name}
                		</span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                		{" replied to your comment on the " + dotType + " "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                    >
                		<span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                		</span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                		{"."}
                    </span>
            		{timestamp}
            		{timestampTooltip}
            	</div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
            		style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
            	>
                </Link>
			</div>
		);
    }
    // Code #4 - tag in a dot save
    else if (Number(props.notificationInfo.code) === 4) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if (mediaInfo.sizes.indexOf("t") === -1) {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
            else {
                dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

        // User action image
        const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        return (
            <div key = {"notification-item-content-" + props.index.toString()}
                className = {notificationItemClassName}
            >
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                            {props.notificationInfo.user_action.name}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" tagged you in the " + dotType + " "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {"."}
                    </span>
                    {timestamp}
                    {timestampTooltip}
                </div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                >
                </Link>
            </div>
        );
    }
    // Code #5 - added to an itinerary
    else if (Number(props.notificationInfo.code) === 5) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if (mediaInfo.sizes.indexOf("t") === -1) {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
            else {
                dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", "url", true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

        // User action image
        const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        return (
            <div key = {"notification-item-content-" + props.index.toString()}
                className = {notificationItemClassName}
            >
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                            {props.notificationInfo.user_action.name}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" invited you to a trip to "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {"."}
                    </span>
                    {timestamp}
                    {timestampTooltip}
                </div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                >
                </Link>
            </div>
        );
    }

    // Code #6 - requested to join an open itinerary
    else if (Number(props.notificationInfo.code) === 6) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if (mediaInfo.sizes.indexOf("t") === -1) {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
            else {
                dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

        // User action image
        const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        return (
            <div key = {"notification-item-content-" + props.index.toString()}
                className = {notificationItemClassName}
            >
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                            {props.notificationInfo.user_action.name}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" requested to join the trip to "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {"."}
                    </span>
                    {timestamp}
                    {timestampTooltip}
                </div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                >
                </Link>
            </div>
        );
    }

    // Code #7 - accepted the invite to join an itinerary
    else if (Number(props.notificationInfo.code) === 7) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if (mediaInfo.sizes.indexOf("t") === -1) {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
            else {
                dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

        // User action image
        const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        return (
            <div key = {"notification-item-content-" + props.index.toString()}
                className = {notificationItemClassName}
            >
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                            {props.notificationInfo.user_action.name}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" accepted your invitation to join the trip to "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {"."}
                    </span>
                    {timestamp}
                    {timestampTooltip}
                </div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                >
                </Link>
            </div>
        );
    }

    // Code #8 - request was approved to join an open itinerary
    else if (Number(props.notificationInfo.code) === 8) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if (mediaInfo.sizes.indexOf("t") === -1) {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
            else {
                dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

        // User action image
        const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        return (
            <div key = {"notification-item-content-" + props.index.toString()}
                className = {notificationItemClassName}
            >
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                            {props.notificationInfo.user_action.name}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" accepted your request to join the trip to "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {"."}
                    </span>
                    {timestamp}
                    {timestampTooltip}
                </div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/itinerary/${props.notificationInfo.itinerary.id}`}
                >
                </Link>
            </div>
        );
    }

    // Code #50 - media contributor
    else if (Number(props.notificationInfo.code) === 50) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if (mediaInfo.sizes.indexOf("t") === -1) {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
            else {
                dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

        // User action image
        const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        return (
            <div key = {"notification-item-content-" + props.index.toString()}
                className = {notificationItemClassName}
            >
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                            {props.notificationInfo.user_action.name}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" authored "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" using your media."}
                    </span>
                    {timestamp}
                    {timestampTooltip}
                </div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                >
                </Link>
            </div>
        );
    }


    // Code #51 - dot contributor
    else if (Number(props.notificationInfo.code) === 51) {
        // Sort media
        const mediaSorted = sortMedia(props.notificationInfo.dot);

        // Merge all media categories
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Pick the first item
        const mediaInfo = media[0];

        // Dot media
        let dotMedia = null;
        if (mediaInfo.type === "video") {
            if (mediaInfo.sizes.indexOf("t") === -1) {
                dotMedia = getStaticPath("/images/common/video-white.png", true);
            }
            else {
                dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
            }
        }
        else {
            dotMedia = getMediaProperty(mediaInfo, "t", 'url', true);
        }

        // Dot type
        let dotType = null;
        if (props.notificationInfo.dot.type === "TR") {
            dotType = "trip";
        }
        else if (props.notificationInfo.dot.type === "RT") {
            dotType = "route";
        }
        else {
            dotType = "dot";
        }

        // User action image
        const userActionImage  = (props.notificationInfo.user_action.profile_pic)?
            (
                (props.notificationInfo.user_action.profile_pic.external_url === null)?
                    getMediaProperty(props.notificationInfo.user_action.profile_pic, "t", "url", true) :
                    url(props.notificationInfo.user_action.profile_pic.external_url)
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        return (
            <div key = {"notification-item-content-" + props.index.toString()}
                className = {notificationItemClassName}
            >
                <Link className = {(props.colorMode === "day")?
                        "notification-item-user profile-image-s6 border-day" :
                        "notification-item-user profile-image-s6 border-night"}
                    style = {{ backgroundImage: userActionImage }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/user/${props.notificationInfo.user_action.username}`}
                >
                </Link>

                <div className = {(props.scrollBarOn)?
                    "notification-item-description-short-scroll" :
                    "notification-item-description-short"}
                >
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/user/${props.notificationInfo.user_action.username}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-user-name k5" :
                            "notification-item-user-name w5"}
                        >
                            {props.notificationInfo.user_action.name}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" authored " + dotType + " "}
                    </span>
                    <Link onClick = {() => { props.storeNotificationMenuOn(false);}}
                        to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                    >
                        <span className = {(props.colorMode === "day")?
                            "notification-item-dot-name k5" :
                            "notification-item-dot-name w5"}
                        >
                            {(dotType === "dot")?
                                props.notificationInfo.dot.name :
                                props.notificationInfo.dot.area}
                        </span>
                    </Link>
                    <span className = {(props.colorMode === "day")?
                        "notification-item-text comment-dg4" :
                        "notification-item-text comment-g4"}
                    >
                        {" using your dot."}
                    </span>
                    {timestamp}
                    {timestampTooltip}
                </div>
                <Link className = {(props.colorMode === "day")?
                        "notification-item-dot image-cover border-day" :
                        "notification-item-dot image-cover border-night"}
                    style = {{ backgroundImage: dotMedia }}
                    onClick = {() => { props.storeNotificationMenuOn(false);}}
                    to = {`/${dotType}/${props.notificationInfo.dot.slug}`}
                >
                </Link>
            </div>
        );
    }
}


// Fetch state as props from Redux store
function mapStateToProps(state) {
    return {
        colorMode: state.nav.colorMode
    };
}

// Export component
export default connect(mapStateToProps, null) (Notification);