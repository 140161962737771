/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    TripOverviewItem.js
--------------------------------------------------------------------------------------------
    Content
    - Individual item for TripOverview
============================================================================================
*/

// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

// Redux
import {
    storeUser,
    storeNewLike,
    storeNewSave,
    storeShare
} from "actions";

// Modules
import ReactTooltip from "thedots-tooltip";

// Components
import {
    UserProfilePicList,
    UserSimpleList
} from "js/Common";

import {
    Gallery,
    mediaDimensions
} from "components/Gallery";

// Functions
import {
    url,
    getStaticPath,
    getMediaProperty,
    sortMedia,
    formatNumbers
} from "js/Functions";

import {
    likeButtonClick,
    saveButtonClick
} from "js/Interaction";

import {
    regularCuration
} from "js/Curation";


// Individual items
class TripOverviewItem extends Component{
    constructor (props){
        super(props);

        // Media
        const mediaSorted = sortMedia(this.props.dotInfo);
        const media = [
            ...mediaSorted.overview,
            ...mediaSorted.todos,
            ...mediaSorted.history,
            ...mediaSorted.stories
        ];

        // Set the start and end media indices
        let startMediaIndex, endMediaIndex;

        // If more than one media --> thumbnails will be displayed
        if (media.length > 1) {
            // Index of the first thumbnail of the first page
            startMediaIndex = 0;

            // Index of the last thumbnail of the first page
            if (media.length > this.props.numThumbnails) {
                endMediaIndex = this.props.numThumbnails - 1;
            }
            else {
                endMediaIndex = media.length - 1;
            }
        }
        else {
            startMediaIndex = null;
            endMediaIndex = null;
        }

        // Stats widths
        this.statsWidth = 60;
        this.statsWidthMobile = 50;
        this.statsPaddingWidth = 1;
        this.statsHeight = 8;
        this.statsHeightMobile = 6;

        // Initialize states
        this.state = {
            // Media
            media: media,

            // Media indices
            selectedMediaIndex: 0,
            startMediaIndex: startMediaIndex,
            endMediaIndex: endMediaIndex,

            // Description switches
            secondDescriptionOn: false,
            thirdDescriptionOn: false,

            // Like / save
            likeCount: this.props.dotInfo.liked_user_count,
            likedByMe: this.props.dotInfo.liked_by_me,
            saveCount: this.props.dotInfo.saved_user_count,
            savedByMe: this.props.dotInfo.saved_by_me
        };
        //console.log("TripOverviewItem / constructor - this.props.dotInfo = ", this.props.dotInfo);

        // Bind functions
        this.setState = this.setState.bind(this);
        this.updateThumbnails = this.updateThumbnails.bind(this);
        this.thumbnailPrevPage = this.thumbnailPrevPage.bind(this);
        this.thumbnailNextPage = this.thumbnailNextPage.bind(this);
        this.toggleSecondDescription = this.toggleSecondDescription.bind(this);
        this.toggleThirdDescription = this.toggleThirdDescription.bind(this);
        this.likeButtonClick = likeButtonClick.bind(this);
        this.saveButtonClick = saveButtonClick.bind(this);
        this.helpButton = this.helpButton.bind(this);

        // Gallery functions
        this.selectMediaClick = this.selectMediaClick.bind(this);
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);
    }

    render () {
        //console.log("TripOverviewItem / render - this.props = ", this.props);
        //console.log("TripOverviewItem / render - this.props.thumbnailsNarrowLayout = ", this.props.thumbnailsNarrowLayout);

        // Determine if user is logged in
        const loggedIn = (!!localStorage.token && this.props.userInfo !== null);

        // Common dot
        const commonDot = (this.props.dotInfo.type === "EV" || this.props.dotInfo.type === "AU");

        /*
        ============================================================================================
            Effective Browser Width
        ============================================================================================
        */

        // Effective browser width
        const effectiveBrowserWidth = this.props.browserWidthPixels - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        const minContentWidth = this.props.minContentWidth - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        const scaleContentWidth = this.props.scaleContentWidth - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        const maxContentWidth = this.props.maxContentWidth - this.props.mediaMarginWidth - this.props.leftColumnWidth;
        //console.log("TripOverviewItem / render - effectiveBrowserWidth = ", effectiveBrowserWidth);


        /*
        ============================================================================================
            Shadow Image
        ============================================================================================
        */

        // Shadow image
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette.png");


        /*
        ============================================================================================
            Gallery
        ============================================================================================
        */

        let gallery = null;
        let galleryDimensions = null;
        if (this.state.media.length > 0) {
            // Get the image dimensions for the right size (small size)
            const mediaInfo = this.state.media[this.state.selectedMediaIndex];
            const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
            const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

            // Is video
            const isVideo = (this.state.media[this.state.selectedMediaIndex].type === "video");

            // Gallery dimensions
            galleryDimensions = mediaDimensions({
                colorMode: this.props.colorMode,
                effectiveBrowserWidth: effectiveBrowserWidth,
                isVideo: isVideo,
                mediaArea: this.props.mediaArea,
                mediaWidth: mediaWidth,
                mediaHeight: mediaHeight,
                minAspectRatio: this.props.minAspectRatio,
                maxAspectRatio: this.props.maxAspectRatio,
                minContentWidth: minContentWidth,
                scaleContentWidth: scaleContentWidth,
                maxContentWidth: maxContentWidth
            });
            //console.log("this.props.effectiveBrowserWidth = ", this.props.effectiveBrowserWidth);
            //console.log("galleryDimensions.finalMediaWidth = ", galleryDimensions.finalMediaWidth);

            // Gallery props
            const galleryProps = Object.assign(
                galleryDimensions,
                {
                    // General
                    id: "",
                    idPrefix: "trip-overview-item",
                    classPrefix: "trip-overview-item",
                    selectedMediaIndex: this.state.selectedMediaIndex,
                    media: this.state.media,
                    size: "s",
                    startPlaying: false,
                    checkScroll: this.props.checkScroll,
                    nextMediaClick: this.nextMediaClick,
                    prevMediaClick: this.prevMediaClick,
                    navDotClick: this.navDotClick,
                    index: null,
                    square: false,
                    muted: false,

                    // Interaction buttons
                    userTagOn: true,
                    dotTagOn: false,
                    unsaveOn: false,
                    enlargeOn: true,
                    dotInfo: this.props.dotInfo,
                    tagMode: "save"
                }
            );

            gallery = (
                <Gallery {...galleryProps} />
            )
        }


        /*
        ============================================================================================
            Thumbnails
        ============================================================================================
        */

        // Calculate thumbnail image sizes
        let maxThumbnailWidth = 0;
        const thumbnailWidths = [];
        const thumbnailHeights = [];
        if (this.state.media.length > 1) {
            for (let i = 0; i < this.state.media.length; i++) {
                // Get the image dimensions for the right size (small size: "s")
                const thumbnailMediaInfo = this.state.media[i];

                // Pick the properties of the right size
                const thumbnailMediaWidth = getMediaProperty(thumbnailMediaInfo, "s", 'width', false);
                const thumbnailMediaHeight = getMediaProperty(thumbnailMediaInfo, "s", 'height', false);
                const thumbnailResizeRatio = Math.sqrt(this.props.thumbnailArea / (thumbnailMediaWidth * thumbnailMediaHeight));
                const resizedThumbnailWidth = Math.round(thumbnailMediaWidth * thumbnailResizeRatio);
                const resizedThumbnailHeight = Math.round(thumbnailMediaHeight * thumbnailResizeRatio);

                if (resizedThumbnailWidth > maxThumbnailWidth) {
                    maxThumbnailWidth = resizedThumbnailWidth;
                }

                thumbnailWidths.push(resizedThumbnailWidth);
                thumbnailHeights.push(resizedThumbnailHeight);

                /*
                console.log("TripOverview / render - tthumbnailMediaWidth = ", thumbnailMediaWidth);
                console.log("TripOverview / render - resizedThumbnailHeight = ", resizedThumbnailHeight);
                console.log("TripOverview / render - resizedThumbnailWidth = ", resizedThumbnailWidth);
                console.log("TripOverview / render - resizedThumbnailHeight = ", resizedThumbnailHeight);
                */
           }
        }

        // Thumbnails layout
        const thumbnailsNarrowLayout = (this.props.browserWidthPixels <
            galleryDimensions.resizedMediaWidth + this.props.leftColumnWidth +
            maxThumbnailWidth + this.props.thumbnailMarginWidth);

        const imageConstrainedLayout = (this.props.browserWidthPixels <
            galleryDimensions.resizedMediaWidth + this.props.leftColumnWidth +
            this.props.thumbnailMarginWidth);
        /*
        console.log("TripOverview / render - this.props.browserWidthPixels = ", this.props.browserWidthPixels);
        console.log("TripOverview / render - galleryDimensions.resizedMediaWidth = ", galleryDimensions.resizedMediaWidth);
        console.log("TripOverview / render - this.props.leftColumnWidth = ", this.props.leftColumnWidth);
        console.log("TripOverview / render - maxThumbnailWidth = ", maxThumbnailWidth);
        console.log("TripOverview / render - this.props.thumbnailMarginWidth = ", this.props.thumbnailMarginWidth);
        console.log("TripOverview / render - galleryDimensions = ", galleryDimensions);
        */

        // Compile thumbnail list
        const thumbnailList = (this.state.media.length === 1)?
            null : this.state.media.map(
                (media, index) => {
                    if ((index >= this.state.startMediaIndex) && (index <= this.state.endMediaIndex)) {
                        // Thumbnail image
                        let thumbnailURL = null;

                        // For videos without gif animation
                        if (media.type === "video" && !("t" in media.files)) {
                            thumbnailURL = (this.props.colorMode === "day")?
                                getStaticPath("/images/common/video-black.png", true) :
                                getStaticPath("/images/common/video-white.png", true);
                        }
                        // For all else
                        else {
                            thumbnailURL = getMediaProperty(media, "xs", 'url', true);
                        }

                        // Thumbnail class
                        const thumbnailClass = (index === this.state.selectedMediaIndex)?
                            (
                                (this.props.colorMode === "day")?
                                    "trip-overview-thumbnail-on image-cover border-day" :
                                    "trip-overview-thumbnail-on image-cover border-night"
                            ) : (
                                (this.props.colorMode === "day")?
                                    "trip-overview-thumbnail-off image-cover border-day" :
                                    "trip-overview-thumbnail-off image-cover border-night"
                            );

                        return(
                            <div key = {"trip-overview-thumbnail-container-" + index.toString()}
                                className = {(thumbnailsNarrowLayout)?
                                    "trip-overview-thumbnail-column" : "trip-overview-thumbnail-row"
                                }
                            >
                                <div className = {thumbnailClass}
                                    key = {"trip-overview-thumbnail-" + index.toString()}
                                    onClick = {(e) => { this.selectMediaClick(e, index); }}
                                    style = {{
                                        width: thumbnailWidths[index],
                                        height: thumbnailHeights[index],
                                        backgroundImage: thumbnailURL
                                    }}
                                >
                                    <div className = "trip-overview-thumbnail-shadow image-cover"
                                        style = {{ backgroundImage: shadowImage }}
                                    >
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        return null;
                    }
                }
            );

        // Arrow images
        const prevPageButtonImage = (thumbnailsNarrowLayout)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-left-black.png") :
                    getStaticPath("/images/common/arrow-left-white.png")

            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-up-black.png") :
                    getStaticPath("/images/common/arrow-up-white.png")
            );

        const nextPageButtonImage = (thumbnailsNarrowLayout)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-right-black.png") :
                    getStaticPath("/images/common/arrow-right-white.png")

            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/arrow-down-black.png") :
                    getStaticPath("/images/common/arrow-down-white.png")
            );

        // Get the thumbnail page navigation buttons
        let prevPageButton, nextPageButton;
        if (this.state.startMediaIndex > 0) {
            prevPageButton = (
                <div className = {
                        (thumbnailsNarrowLayout)?
                            "trip-overview-thumbnail-prev-small image-button-base" :
                            "trip-overview-thumbnail-prev image-button-base"
                    }
                    onClick = {(e) => this.thumbnailPrevPage(e)}
                    style = {{ backgroundImage: prevPageButtonImage }}
                >
                </div>
            );
        }
        else {
            prevPageButton = null;
        }

        if (this.state.endMediaIndex < (this.state.media.length - 1)) {
            nextPageButton = (
                <div className = {
                        (thumbnailsNarrowLayout)?
                            "trip-overview-thumbnail-next-small image-button-base" :
                            "trip-overview-thumbnail-next image-button-base"
                    }
                    onClick = {(e) => this.thumbnailNextPage(e)}
                    style = {{ backgroundImage: nextPageButtonImage }}
                >
                </div>
            );
        }
        else {
            nextPageButton = null;
        }

        // Attach previous and next page navigation buttons
        const thumbnails = (
            <div className = {(thumbnailsNarrowLayout)?
                    "trip-overview-thumbnails-small" : "trip-overview-thumbnails"}
            >
                {prevPageButton}
                {thumbnailList}
                {nextPageButton}
            </div>
        );


        /*
        ============================================================================================
            Number Column
        ============================================================================================
        */

        // Line images
        let lineImage = null;
        let bottomLineImage = null;

        // First dot
        if (this.props.isTop) {
            lineImage = (this.props.colorMode === "day")?
                getStaticPath("/images/line/vertical/dotted-top-light.png") :
                getStaticPath("/images/line/vertical/dotted-top-dark.png");
            bottomLineImage = (this.props.colorMode === "day")?
                getStaticPath("/images/line/vertical/dotted-middle-light.png") :
                getStaticPath("/images/line/vertical/dotted-middle-dark.png");
        }
        else {
            // Last dot
            if (this.props.isBottom) {
                lineImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/dotted-bottom-light.png") :
                    getStaticPath("/images/line/vertical/dotted-bottom-dark.png");
                bottomLineImage = null;
            }
            // Middle dot
            else {
                lineImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/dotted-middle-light.png") :
                    getStaticPath("/images/line/vertical/dotted-middle-dark.png");

                bottomLineImage = (this.props.colorMode === "day")?
                    getStaticPath("/images/line/vertical/dotted-middle-light.png") :
                    getStaticPath("/images/line/vertical/dotted-middle-dark.png");
            }
        }

        // Number image
        const numberImage = (this.props.colorMode === "day")?
            getStaticPath("/images/number/double_white_" + this.props.number + ".png") :
            getStaticPath("/images/number/double_black_" + this.props.number + ".png");

        // Get number column
        const numberColumn = (
            <div className = "trip-overview-number-container">
                <div className = "trip-overview-number image-cover"
                    style = {{ backgroundImage: numberImage }}
                >
                </div>
            </div>
        );


        /*
        ============================================================================================
            Descriptions
        ============================================================================================
        */

        // Description style
        const descriptionStyle = {
            width: galleryDimensions.finalMediaWidth
        };

        // Stats width and height
        const statsWidth = (this.props.browserWidth <= 4)?
            this.statsWidthMobile : this.statsWidth;
        const statsHeight = (this.props.browserWidth <= 4)?
            this.statsHeightMobile : this.statsHeight;

        const secondDescription = this.state.secondDescriptionOn? (
            <SecondDescription
                thumbnailsNarrowLayout = {thumbnailsNarrowLayout}
                imageConstrainedLayout = {imageConstrainedLayout}
                browserWidth = {this.props.browserWidth}
                colorMode = {this.props.colorMode}
                commonDot = {commonDot}
                index = {this.props.index}
                dotInfo = {this.props.dotInfo}
                toggleThirdDescription = {this.toggleThirdDescription}
                secondDescriptionOpacity = {this.state.secondDescriptionOpacity}
                thirdDescriptionOn = {this.state.thirdDescriptionOn}
                editorWidth = {this.props.editorWidth}
                staffItemWidth = {this.props.staffItemWidth}
                staffMarginWidth = {this.props.staffMarginWidth}
                contributorsMinWidth = {this.props.contributorsMinWidth}
                helpersMinWidth = {this.props.helpersMinWidth}
                numStaffProfilePics = {this.props.numStaffProfilePics}
                statsWidth = {statsWidth}
                statsHeight = {statsHeight}
                statsPaddingWidth = {this.statsPaddingWidth}
            />
        ) : null;

        const thirdDescription = this.state.thirdDescriptionOn? (
            <ThirdDescription
                colorMode = {this.props.colorMode}
                index = {this.props.index}
                dotInfo = {this.props.dotInfo}
            />
        ) : null;


        /*
        ============================================================================================
            Main Render
        ============================================================================================
        */

        return (
            <div key = {"trip-overview-item-" + this.props.index.toString()}
                className = "trip-overview-item"
            >
                <div className = "trip-overview-item-row">
                    <div className = "trip-overview-media-left-column"
                        style = {{
                            minWidth: this.props.leftColumnWidth,
                            width: this.props.leftColumnWidth,
                            backgroundImage: lineImage
                        }}
                    >
                        {numberColumn}
                    </div>
                    <div className = "trip-overview-media-column">
                        {(thumbnailsNarrowLayout)? thumbnails : null}
                        {gallery}
                    </div>
                    <div className = "trip-overview-media-right-column"
                        style = {{ width: this.props.rightColumnWidth}}
                    >
                        {(thumbnailsNarrowLayout)? null : thumbnails}
                    </div>
                </div>
                <div className = "trip-overview-item-row">
                    <div className = "trip-overview-description-left-column"
                        style = {{ backgroundImage: bottomLineImage }}
                    >
                    </div>
                    <div className = "trip-overview-description-column">
                        <div className = "trip-overview-description"
                            style = {descriptionStyle}
                        >
                            <FirstDescription
                                colorMode =  {this.props.colorMode}
                                index = {this.props.index}
                                loggedIn = {loggedIn}
                                userInfo = {this.props.userInfo}
                                dotInfo = {this.props.dotInfo}
                                toggleSecondDescription = {this.toggleSecondDescription}
                                secondDescriptionOn = {this.state.secondDescriptionOn}
                                likeButtonClick = {this.likeButtonClick}
                                saveButtonClick = {this.saveButtonClick}
                                likedByMe = {this.state.likedByMe}
                                savedByMe = {this.state.savedByMe}
                                likeCount = {this.state.likeCount}
                                saveCount = {this.state.saveCount}
                                likeButtonOpacity = {this.state.likeButtonOpacity}
                                saveButtonOpacity = {this.state.saveButtonOpacity}
                                likeButtonOnOpacity = {this.likeButtonOnOpacity}
                                likeButtonOffOpacity = {this.likeButtonOffOpacity}
                                saveButtonOnOpacity = {this.saveButtonOnOpacity}
                                saveButtonOffOpacity = {this.saveButtonOffOpacity}
                                helpButton = {this.helpButton}
                                setState = {this.setState}
                                storeUser = {this.props.storeUser}
                                storeNewLike = {this.props.storeNewLike}
                                storeNewSave = {this.props.storeNewSave}
                                storeShare = {this.props.storeShare}
                            />
                            {secondDescription}
                            {thirdDescription}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    /*
    ============================================================================================
        Gallery Navigation Functions
    ============================================================================================
    */

    selectMediaClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: mediaIndex
            }
        );
    }

    nextMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex + 1)
            }
        );
    }

    prevMediaClick(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState(
            {
                selectedMediaIndex: (this.state.selectedMediaIndex - 1)
            }
        );
    }

    navDotClick(event, mediaIndex) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            selectedMediaIndex: mediaIndex
        });
    }

    thumbnailPrevPage(event) {
        // Determine the first and last image indices of the displayed thumbnails
        const startMediaIndex = this.state.startMediaIndex - this.props.numThumbnails;
        const endMediaIndex = this.state.startMediaIndex - 1;
        //console.log("TripOverviewItem / thumbnailPreviousPage - this.state.startMediaIndex = ", this.state.startMediaIndex);
        //console.log("TripOverviewItem / thumbnailPreviousPage - this.state.endMediaIndex = ", this.state.endMediaIndex);
        //console.log("TripOverviewItem / thumbnailPreviousPage - startMediaIndex = ", startMediaIndex);
        //console.log("TripOverviewItem / thumbnailPreviousPage - endMediaIndex = ", endMediaIndex);

        // Update thumbnail indices
        this.updateThumbnails(startMediaIndex, endMediaIndex);
    }

    thumbnailNextPage(event) {
        // Determine the first and last image indices of the displayed thumbnails
        const startMediaIndex = this.state.startMediaIndex + this.props.numThumbnails;
        const endMediaIndex = ((this.state.endMediaIndex + this.props.numThumbnails) > (this.state.media.length - 1))?
            (this.state.endMediaIndex + this.props.numThumbnails) :
            (this.state.media.length - 1);
        //console.log("TripOverviewItem / thumbnailNextPage - this.state.startMediaIndex = ", this.state.startMediaIndex);
        //console.log("TripOverviewItem / thumbnailNextPage - this.state.endMediaIndex = ", this.state.endMediaIndex);
        //console.log("TripOverviewItem / thumbnailNextPage - startMediaIndex = ", startMediaIndex);
        //console.log("TripOverviewItem / thumbnailNextPage - endMediaIndex = ", endMediaIndex);

        // Update thumbnail indices
        this.updateThumbnails(startMediaIndex, endMediaIndex);
    }

    updateThumbnails(startMediaIndex, endMediaIndex) {
        this.setState({
            startMediaIndex: startMediaIndex,
            endMediaIndex: endMediaIndex,
        });
    }


    /*
    ============================================================================================
        Description Functions
    ============================================================================================
    */

    toggleSecondDescription() {
        this.setState({
            secondDescriptionOn: !this.state.secondDescriptionOn
        });
    }

    toggleThirdDescription() {
        this.setState({
            thirdDescriptionOn: !this.state.thirdDescriptionOn
        });
    }

    helpButton() {
        console.log("TripOverviewItem / helpButton - clicked");
    }
}


function FirstDescription(props) {
    /*
    ============================================================================================
        Like / save / share buttons
    ============================================================================================
    */
    let likeButton = null;
    let likeButtonTooltip = null;
    let saveButton = null;
    let saveButtonText = null;
    let saveButtonTooltip = null;
    let shareButton = null;
    let shareButtonTooltip = null;

    if (props.loggedIn) {
        const likeButtonTooltipText = props.likedByMe? "Unlike" : "Like";
        const likeButtonTooltipID = "trip-overview-like-button-tooltip" + props.index;
        likeButtonTooltip = (
            <ReactTooltip
                id = {likeButtonTooltipID}
                className = "trip-overviewlike-button-tooltip tooltip-s2"
                type = "dark"
                place = "bottom"
                html={true}
            />
        );

        const likeButtonClickProps = {
            setState: props.setState,
            likedByMe: props.likedByMe,
            userInfo: props.userInfo,
            dotInfo: props.dotInfo,
            storeNewLike: props.storeNewLike,
            storeUser: props.storeUser
        };

        const likeButtonImage = props.likedByMe?
            (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/like-on-red.png") :
                    getStaticPath("/images/common/like-on-red.png")
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/like-off-black.png") :
                    getStaticPath("/images/common/like-off-white.png")
            );

        const likeButtonClass = props.likedByMe?
            "trip-overview-like-button-on image-button-weak-s3" :
            "trip-overview-like-button-off image-button-weak-s3";

        likeButton = (props.userInfo.id === props.dotInfo.editor.id)? null : (
            <div className = {likeButtonClass}
                style = {{ backgroundImage: likeButtonImage }}
                onClick = {(e) => {props.likeButtonClick(likeButtonClickProps, e)}}
                data-tip = {likeButtonTooltipText}
                data-for = {likeButtonTooltipID}
            >
            </div>
        );

        // Save button
        const saveButtonTooltipText = props.savedByMe? "Unbucket" : "Bucket This Trip";
        const saveButtonTooltipID = "trip-overview-save-button-tooltip-" + props.index;
        saveButtonTooltip = (
            <ReactTooltip
                id = {saveButtonTooltipID}
                className = "trip-overview-save-button-tooltip tooltip-s2"
                type = "dark"
                place = "bottom"
                html = {true}
            />
        );

        const saveButtonClickProps = {
            setState: props.setState,
            savedByMe: props.savedByMe,
            userInfo: props.userInfo,
            dotInfo: props.dotInfo,
            storeNewSave: props.storeNewSave,
            storeUser: props.storeUser
        };

        const saveButtonImage = props.savedByMe?
            (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/bucket-on-black.png") :
                    getStaticPath("/images/common/bucket-on-white.png")
            ) : (
                (props.colorMode === "day")?
                    getStaticPath("/images/common/bucket-off-black.png") :
                    getStaticPath("/images/common/bucket-off-white.png")
            );

        const saveButtonClass = props.savedByMe?
            "trip-overview-save-button-on image-button-weak-s2" :
            "trip-overview-save-button-off image-button-weak-s2";

        saveButton = (props.userInfo.id === props.dotInfo.editor.id)? null : (
            <div className = {saveButtonClass}
                style = {{ backgroundImage: saveButtonImage }}
                onClick = {(e) => {props.saveButtonClick(saveButtonClickProps, e);}}
                data-tip = {saveButtonTooltipText}
                data-for = {saveButtonTooltipID}
            >
            </div>
        );

        saveButtonText = (props.userInfo.id === props.dotInfo.editor.id)? null : (
            (props.savedByMe)? (
                <div className = {(props.colorMode === "day")?
                    "trip-overview-save-button-text k4" :
                    "trip-overview-save-button-text w4"}
                >
                    Bucketed
                </div>
            ) : null
        );
    }

    const shareButtonTooltipText = "Share";
    const shareButtonTooltipID = "trip-overview-share-button-tooltip" + props.index;

    shareButtonTooltip = (
        <ReactTooltip
            id = {shareButtonTooltipID}
            className = "trip-overview-share-button-tooltip tooltip-s2"
            type = "dark"
            place = "bottom"
            html = {true}
        />
    );

    const shareButtonImage = (
        (props.colorMode === "day")?
            getStaticPath("/images/common/share-off-black.png") :
            getStaticPath("/images/common/share-off-white.png")
    );

    const shareButtonClass = "trip-overview-share-button-off image-button-weak-s2";

    const shareButtonClick = (props) => {
        props.storeShare({
            modalOn: true,
            type: "dot",
            info: props.dotInfo
        });
    };

    shareButton = (
        <div className = {shareButtonClass}
            onClick = {() => { shareButtonClick(props); }}
            style = {{ backgroundImage:  shareButtonImage }}
            data-tip = {shareButtonTooltipText}
            data-for = {shareButtonTooltipID}
        >
        </div>
    );

    /*
    ============================================================================================
        Like and save counts
    ============================================================================================
    */

    // Counts
    const likeText = (props.likeCount === 1)? "Like" : "Likes";
    const likeCount = (props.likeCount > 0)?
    (
        <div className = {(props.colorMode === "day")?
            "trip-overview-like-count k4" :
            "trip-overview-like-count w4"}
        >
            {formatNumbers(props.likeCount).toString()} {likeText}
        </div>
    ) : null;

    /*
    const saveText = (props.saveCount === 1)? "Save" : "Saves";
    const saveCount = (props.saveCount > 0)?
    (
        <div className = "trip-overview-save-count">
            {formatNumbers(props.saveCount).toString()} {saveText}
        </div>
    ) : null;
    */

    /*
    ============================================================================================
        Open and seasonal status
    ============================================================================================
    */

    // More button
    const moreImage = props.secondDescriptionOn? (
            (props.colorMode === "day")?
                getStaticPath("/images/common/decrease-black.png") :
                getStaticPath("/images/common/decrease-white.png")

        ) : (
            (props.colorMode === "day")?
                getStaticPath("/images/common/increase-black.png") :
                getStaticPath("/images/common/increase-white.png")
        );

    const moreButton = (
        <div className = "trip-overview-first-description-footer-more image-button-weaker-s8"
            style = {{ backgroundImage: moreImage }}
            onClick = {(e) => props.toggleSecondDescription()}
        >
        </div>
    );

    // Open and seasonal status
    const statusOpen = (props.dotInfo.dot_extension.is_open)? (
            <span className = {(props.colorMode === "day")?
                "trip-overview-status-open-day font-century" :
                "trip-overview-status-open-night font-century"}
            >
                Open
            </span>
        ):(
            <span className = {(props.colorMode === "day")?
                "trip-overview-status-closed-day font-century" :
                "trip-overview-status-closed-night font-century"}
            >
                Closed
            </span>
        );

    const statusSeasonal = (props.dotInfo.dot_extension.closure_estimate !== null)? (
            <span className = {(props.colorMode === "day")?
                "trip-overview-status-seasonal-day font-century" :
                "trip-overview-status-seasonal-night font-century"}
            >
                Seasonal
            </span>
        ): null;


    /*
    ============================================================================================
        Curation
    ============================================================================================
    */

    // Stylize curation
    const curationOverview = regularCuration(
        props.dotInfo.overview,
        "trip-overview-first-description",
        "trip-overview-first-description",
        props.colorMode
    );

    return(
        <div className = "trip-overview-first-description">
            <div className = "trip-overview-first-description-header clear-fix">
                <Link to = {`/dot/${props.dotInfo.slug}`} target="_blank">
                    <div className = {(props.colorMode === "day")?
                        "trip-overview-first-description-title k4" :
                        "trip-overview-first-description-title w4"}
                    >
                        {props.dotInfo.title}
                    </div>
                </Link>
                <div className = "trip-overview-first-description-status clear-fix">
                    {moreButton}
                    {statusOpen}
                    {statusSeasonal}
                </div>
                <Link to = {`/dot/${props.dotInfo.slug}`} target="_blank">
                    <div className = {(props.colorMode === "day")?
                        "trip-overview-first-description-location lb4" :
                        "trip-overview-first-description-location b4"}
                    >
                        {props.dotInfo.name}
                    </div>
                </Link>
            </div>
            <div className = "trip-overview-first-description-curation">
                {curationOverview}
            </div>
            <div className = "trip-overview-first-description-footer clear-fix">
                <div className = "trip-overview-first-description-footer-left">
                    {likeCount}
                    {likeButton}
                    {likeButtonTooltip}
                    {shareButton}
                    {shareButtonTooltip}
                </div>
                <div className = "trip-overview-first-description-footer-right">
                    {saveButtonText}
                    {saveButton}
                    {saveButtonTooltip}
                </div>
            </div>
        </div>
    );
}


function SecondDescription(props) {
    //console.log("SecondDescription - props = ", props);

    /*
    ============================================================================================
        Editor
    ============================================================================================
    */
    const editorImage = (props.dotInfo.editor.profile_pic)?
        (
            (props.dotInfo.editor.profile_pic.external_url === null)?
                getMediaProperty(props.dotInfo.editor.profile_pic, "t", "url", true) :
                url(props.dotInfo.editor.profile_pic.external_url)
        ) : (
            (props.colorMode === "day")?
                getStaticPath("/images/common/no-profile-picture-day.png") :
                getStaticPath("/images/common/no-profile-picture-night.png")
        );

    /*
    ============================================================================================
        Contributors and Helpers
    ============================================================================================
    */

    let staff = null;
    if ((props.dotInfo.contributor_count - 1) > 0) {
        const contributorProfilePicListProps = {
            colorMode: props.colorMode,
            numProfilePics: props.numStaffProfilePics,
            usersInfo: props.dotInfo.contributors_best,
            userCount: props.dotInfo.contributor_count - 1,
            userTypeLabel: "Contributor",
            userTypeLabelOn: true,
            moreBlankOn: false,
            classNamePrefix: "trip-overview",
            classNameUserType: "contributor",
            index: props.index
        };

        if (props.dotInfo.helper_count > 0) {
            const helperListProps = {
                colorMode: props.colorMode,
                numProfilePics : props.numStaffProfilePics,
                usersInfo : props.dotInfo.helpers_best,
                userCount : props.dotInfo.helper_count,
                userTypeLabel : "Helper",
                andOn: true,
                classNamePrefix: "trip-overview",
                classNameUserType: "helper",
                index: props.index
            };

            staff = (
                <div className = "trip-overview-second-description-staff"
                    key = {"trip-overview-staff-" + props.index}
                >
                    <UserProfilePicList {...contributorProfilePicListProps} />
                    <UserSimpleList {...helperListProps} />
                </div>
            );
        }
        else {
            staff = (
                <div className = "trip-overview-second-description-staff"
                    key = {"trip-overview-staff-" + props.index}
                >
                    <UserProfilePicList {...contributorProfilePicListProps} />
                </div>
            );
        }
    }
    else {
        if (props.dotInfo.helper_count > 0) {
            const helperProfilePicListProps = {
                colorMode: props.colorMode,
                numProfilePics : props.numStaffProfilePics,
                usersInfo : props.dotInfo.helpers_best,
                userCount : props.dotInfo.helper_count,
                userTypeLabel : "Helper",
                userTypeLabelOn: true,
                moreBlankOn: false,
                classNamePrefix: "trip-overview",
                classNameUserType: "helper",
                index: props.index
            };

            staff = (
                <div className = "trip-overview-second-description-staff"
                    key = {"trip-overview-staff-" + props.index}
                >
                    <UserProfilePicList {...helperProfilePicListProps} />
                </div>
            );
        }
    }


    // Calculate the staff section width
    const staffWidth = ((props.dotInfo.contributor_count + props.dotInfo.helper_count) === 0)?
    0 : Math.max(
            Math.max(
                props.contributorsMinWidth,
                Math.min(props.dotInfo.contributor_count, props.numStaffProfilePics) * props.staffItemWidth,
            ),
            Math.max(
                props.helpersMinWidth,
                Math.min((props.dotInfo.helper_count), props.numStaffProfilePics) * props.staffItemWidth
            )
    );

    // Calculate the right column width
    const rightWidth = "" + (staffWidth + props.staffMarginWidth + props.editorWidth) + "px";
    //console.log("SecondDescription - staffWidth = ", staffWidth);
    //console.log("SecondDescription - rightWidth = ", rightWidth);


    /*
    ============================================================================================
        Difficulty and Rating
    ============================================================================================
    */

    let rating = null;
    let difficulty = null;
    let hours = null;
    let time = null;

    if (!props.commonDot) {
        if (props.dotInfo.dot_extension.rating != null) {
            const ratingBar = (props.dotInfo.dot_extension.rating < 5)?
            (
                <div className = {(props.colorMode === "day")?
                    "trip-overview-second-description-rating-bar-front-regular-day" :
                    "trip-overview-second-description-rating-bar-front-regular-night"}
                    style = {{ width: Math.round(props.dotInfo.dot_extension.rating * props.statsWidth / 5) }}
                >
                </div>
            ) : (
                <div className = {(props.colorMode === "day")?
                    "trip-overview-second-description-rating-bar-front-round-day" :
                    "trip-overview-second-description-rating-bar-front-round-night"}
                    style = {{ width: Math.round(props.dotInfo.dot_extension.rating * props.statsWidth / 5) }}
                >
                </div>
            );

            rating = (
                <div className = "trip-overview-second-description-rating">
                    <div className = {
                        (props.browserWidth <= 4)?
                            (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-rating-text dg5" :
                                    "trip-overview-second-description-rating-text g5"
                            ) : (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-rating-text dg4" :
                                    "trip-overview-second-description-rating-text g4"
                            )
                        }
                    >
                        Rating
                    </div>
                    <div className = {
                        (props.browserWidth <= 4)?
                            (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-rating-value-small font-cabin light-blue" :
                                    "trip-overview-second-description-rating-value-small font-cabin blue"
                            ) : (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-rating-value font-cabin light-blue" :
                                    "trip-overview-second-description-rating-value font-cabin blue"
                            )
                        }
                    >
                        {Number(props.dotInfo.dot_extension.rating).toFixed(1)}
                    </div>
                    {
                        (props.colorMode === "day")?
                        (
                            <div className = "trip-overview-second-description-rating-bar-day"
                                style = {{
                                    width: props.statsWidth + 2 * props.statsPaddingWidth,
                                    height: props.statsHeight + 2 * props.statsPaddingWidth
                                }}
                            >
                                <div className = "trip-overview-second-description-rating-bar-back-day">
                                    {ratingBar}
                                </div>
                            </div>
                        ) : (
                            <div className = "trip-overview-second-description-rating-bar-night"
                                style = {{
                                    width: props.statsWidth + 2 * props.statsPaddingWidth,
                                    height: props.statsHeight + 2 * props.statsPaddingWidth
                                }}
                            >
                                <div className = "trip-overview-second-description-rating-bar-back-night">
                                    {ratingBar}
                                </div>
                            </div>
                        )
                    }
                </div>
            );
        }

        if (props.dotInfo.dot_extension.difficulty != null) {
            const difficultyValue = Math.min(Math.ceil(props.dotInfo.dot_extension.difficulty), 5);
            const difficultyBar = (difficultyValue < 5)?
            (
                <div className = {(props.colorMode === "day")?
                    "trip-overview-second-description-difficulty-bar-front-regular-day" :
                    "trip-overview-second-description-difficulty-bar-front-regular-night"}
                    style = {{ width: Math.round(difficultyValue * props.statsWidth / 5) }}
                >
                </div>
            ) : (
                <div className = {(props.colorMode === "day")?
                    "trip-overview-second-description-difficulty-bar-front-round-day" :
                    "trip-overview-second-description-difficulty-bar-front-round-night"}
                     style = {{ width: Math.round(difficultyValue * props.statsWidth / 5) }}
                >
                </div>
            );
            difficulty = (
                <div className = "trip-overview-second-description-difficulty">
                    <div className = {
                        (props.browserWidth <= 4)?
                            (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-difficulty-text dg5" :
                                    "trip-overview-second-description-difficulty-text g5"
                            ) : (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-difficulty-text dg4" :
                                    "trip-overview-second-description-difficulty-text g4"
                            )
                        }
                    >
                        Difficulty
                    </div>
                    <div className = {
                        (props.browserWidth <= 4)?
                            (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-difficulty-value-small font-cabin light-red" :
                                    "trip-overview-second-description-difficulty-value-small font-cabin red"
                            ) : (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-difficulty-value font-cabin light-red" :
                                    "trip-overview-second-description-difficulty-value font-cabin red"
                            )
                        }
                    >
                        {Math.ceil(props.dotInfo.dot_extension.difficulty).toFixed(1)}
                    </div>
                    {
                        (props.colorMode === "day")?
                        (
                            <div className = "trip-overview-second-description-difficulty-bar-day"
                                style = {{
                                    width: props.statsWidth + 2 * props.statsPaddingWidth,
                                    height: props.statsHeight + 2 * props.statsPaddingWidth
                                }}
                            >
                                <div className = "trip-overview-second-description-difficulty-bar-back-day">
                                    {difficultyBar}
                                </div>
                            </div>
                        ) : (
                            <div className = "trip-overview-second-description-difficulty-bar-night"
                                style = {{
                                    width: props.statsWidth + 2 * props.statsPaddingWidth,
                                    height: props.statsHeight + 2 * props.statsPaddingWidth
                                }}
                            >
                                <div className = "trip-overview-second-description-difficulty-bar-back-night">
                                    {difficultyBar}
                                </div>
                            </div>
                        )
                    }
                </div>
            );
        }


        /*
        ============================================================================================
            Hours
        ============================================================================================
        */

        hours = ((props.dotInfo.dot_extension.start_hour) && (props.dotInfo.dot_extension.end_hour))? (
            <div className = "trip-overview-second-description-hours">
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-text dg5" :
                                "trip-overview-second-description-hours-text g5"
                        ) : (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-text dg4" :
                                "trip-overview-second-description-hours-text g4"
                        )
                    }
                >
                    Hours
                </div>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-value-small font-cabin black" :
                                "trip-overview-second-description-hours-value-small font-cabin white"
                        ) : (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-value font-cabin black" :
                                "trip-overview-second-description-hours-value font-cabin white"
                        )
                    }
                >
                    {props.dotInfo.dot_extension.start_hour}
                </div>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-line-small font-cabin black" :
                                "trip-overview-second-description-hours-line-small font-cabin white"
                        ) : (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-line font-cabin black" :
                                "trip-overview-second-description-hours-line font-cabin white"
                        )
                    }
                >
                    |
                </div>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-value-small font-cabin black" :
                                "trip-overview-second-description-hours-value-small font-cabin white"
                        ) : (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-hours-value font-cabin black" :
                                "trip-overview-second-description-hours-value font-cabin white"
                        )
                    }
                >
                    {props.dotInfo.dot_extension.end_hour}
                </div>
            </div>
        ) : null;


        /*
        ============================================================================================
            Time
        ============================================================================================
        */

        time = (props.dotInfo.dot_extension.time !== null)?
        (
            <div className = "trip-overview-second-description-time">
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-time-text dg5" :
                                "trip-overview-second-description-time-text g5"
                        ) : (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-time-text dg4" :
                                "trip-overview-second-description-time-text g4"
                        )
                    }
                >
                    Estimated Time
                </div>
                <div className = {
                    (props.browserWidth <= 4)?
                        (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-time-value-small font-cabin black" :
                                "trip-overview-second-description-time-value-small font-cabin white"
                        ) : (
                            (props.colorMode === "day")?
                                "trip-overview-second-description-time-value font-cabin black" :
                                "trip-overview-second-description-time-value font-cabin white"
                        )
                    }
                >
                    {props.dotInfo.dot_extension.time}
                </div>
            </div>
        ) : null;
    }


    /*
    ============================================================================================
        Editor Name
    ============================================================================================
    */

    const name = (props.imageConstrainedLayout)? null : (
        <div className = {
            (props.browserWidth <= 4)?
                (
                    (props.colorMode === "day")?
                        "trip-overview-second-description-editor-name k5" :
                        "trip-overview-second-description-editor-name w5"
                ) : (
                    (props.colorMode === "day")?
                        "trip-overview-second-description-editor-name k4" :
                        "trip-overview-second-description-editor-name w4"
                )
            }
        >
            {props.dotInfo.editor.name}
        </div>
    );


    // Render JSX
    return(
        <div className = "trip-overview-second-description clear-fix"
            style = {{ opacity: props.secondDescriptionOpacity }}>
            <div className = "trip-overview-second-description-left">
                {rating}
                {difficulty}
                {hours}
            </div>
            <div className = "trip-overview-second-description-right" style = {{ width: rightWidth }}>
                <div className = "trip-overview-second-description-editor">
                    <div className = {
                        (props.browserWidth <= 4)?
                            (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-editor-header dg5" :
                                    "trip-overview-second-description-editor-header g5"
                            ) : (
                                (props.colorMode === "day")?
                                    "trip-overview-second-description-editor-header dg4" :
                                    "trip-overview-second-description-editor-header g4"
                            )
                        }
                    >
                        Editor
                    </div>
                    <Link key = {"trip-overview-item-editor-" + props.index.toString() + "-profile-pic"}
                        to = {`/user/${props.dotInfo.editor.username}`}>
                        <div className = {
                            (props.browserWidth <= 4)?
                                (
                                    (props.colorMode === "day")?
                                        "trip-overview-second-description-editor-profile-pic-small profile-image-base border-day" :
                                        "trip-overview-second-description-editor-profile-pic-small profile-image-base border-night"
                                ) : (
                                    (props.colorMode === "day")?
                                        "trip-overview-second-description-editor-profile-pic profile-image-base border-day" :
                                        "trip-overview-second-description-editor-profile-pic profile-image-base border-night"
                                )
                            }
                            style = {{ backgroundImage: editorImage }}>
                        </div>
                    </Link>
                    {name}
                </div>
                {staff}
            </div>
            <div className = "trip-overview-second-description-middle">
                {time}
            </div>
        </div>
    );
}


class ThirdDescription extends Component{
    render() {
        return(
            <div className = "trip-overview-third-description"
                style = {{ opacity: this.props.thirdDescriptionOpacity }}>
                Third Description
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        userInfo: state.user.userInfo,
        newLike: state.interaction.newLike,
        newSave: state.interaction.newSave
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            storeUser,
            storeNewLike,
            storeNewSave,
            storeShare
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(TripOverviewItem);