// React / ReactDOM / React-router
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";

// Modules
import ReactTooltip from "thedots-tooltip";
import ReactPlayer from "react-player";

// Components
import {
    UserProfilePicList,
    BottleProfilePicList
} from "js/Common";

import { mediaDimensions } from "components/Gallery";
import { OpenMap, GoogleMap } from "components/Map";
import Board from "components/Board";
import Draggable from "js/Draggable";

// Functions
import {
    updateCurationStyle,
    curationCapitalize,
    curationPartition,
    removeFirstSpace
}
from "js/Curation";

import {
    likeButtonClick,
    saveButtonClick
} from "js/Interaction";

import {
    getMediaProperty,
    formatNumbers,
    getStaticPath,
    url
} from "js/Functions";

// Redux
import {
    storeNewLike,
    storeNewSave,
    storeShare,
    storeUserTag,
    storeGallery
} from "actions";

// CSS
import "./DotsHome.scss";


class DotsHomeItem extends Component {
    constructor(props) {
        super(props);
        //console.log("DotsHomeItem / constructor - props.dotInfo = ", props.dotInfo);

        // Displacement ratio
        this.displacementRatio = Number(720/640);

        // Margin
        this.marginWidth = 10;

        // Min and max aspect ratios for media
        this.minAspectRatio = 0.5;
        this.maxAspectRatio = 2.0;

        // Container widths
        this.minContentWidth = 320;
        this.scaleContentWidth = 514;
        this.mapContentWidth = 720;
        this.containContentWidth = 1000;

        // Layout dimensions
        this.headerRightWidth = 130;
        this.headerRightSmallWidth = 100;
        this.headerMargin = 40;
        this.minTitleWidth = 400;
        this.titleRatio = 0.55;
        this.mapRatio = 1.0 - this.titleRatio;
        this.likeContainerWidth = 160;

        // Curation settings
        this.curationMinLength = 100;
        this.curationMapLength = 200;
        this.curationLineHeight = 20;
        this.curationMargin = 2;

        // Contributors and helpers
        this.numStaffProfilePics = 3;

        // Bottle size
        this.minBottleSize = 3;
        this.maxBottleSize = 5;

        // Media area settings (conserve image area for all images)
        this.mediaArea = 400000;

        // Media container node
        this.mediaContainerBorderWidth = 1;
        this.mediaContainerRef = React.createRef();

        // Map height
        this.mapHeight = 280;
        this.mapMinHeight = 240;
        this.mapMaxHeight = 600;
        this.mapHeightIncrement = 40;

        // Map DOM IDs
        this.mapNodeID = "dots-home-item-map-" + props.index;
        this.mapContainerNodeID = "dots-home-item-map-container-" + props.index;
        this.mapButtonsNodeID = "dots-home-item-map-buttons-" + props.index;

        // Curation ref and height
        this.curationRef = React.createRef();
        this.curationHeight = this.mapHeight;

        // Initial state
        this.state = {
            // Media gallery
            selectedMediaIndex: 0,

            // Expanded
            expanded: false,

            // Variable height
            variableHeight: false,

            // Save / like / share
            likedByMe: props.dotInfo.liked_by_me,
            likeCount: props.dotInfo.liked_user_count,
            savedByMe: props.dotInfo.saved_by_me,
            saveCount: props.dotInfo.saved_user_count,

            // Video feed
            feedVideoOn: false,

            // Static map drag
            dragOn: (window.touchOn)? false : true,

            // Map height
            mapHeight: this.mapHeight,

            // Window width
            windowWidth: window.innerWidth,

            // Media in viewport
            inViewport: false
        };

        // Curation refs
        this.curationTitleRef = null;
        this.curationTitleLetterRef = null;
        this.curationOverviewRef = null;
        this.curationOverviewLetterRef = null;
        this.curationTodosRef = null;
        this.curationTodosLetterRef = null;
        this.curationHistoryRef = null;
        this.curationHistoryLetterRef = null;
        this.curationStoriesRef = null;
        this.curationStoriesLetterRef = null;

        // Bind setState
        this.setState = this.setState.bind(this);

        // Bind curation stylize functions
        this.updateCurationStyle = updateCurationStyle.bind(this);
        this.updateCurationLayout = this.updateCurationLayout.bind(this);
        this.curationCapitalize = curationCapitalize.bind(this);
        this.curationPartition = curationPartition.bind(this);

        // Bind functions
        this.setState = this.setState.bind(this);
        this.likeButtonClick = likeButtonClick.bind(this);
        this.saveButtonClick = saveButtonClick.bind(this);
        this.shareButtonClick = this.shareButtonClick.bind(this);
        this.nextMediaClick = this.nextMediaClick.bind(this);
        this.prevMediaClick = this.prevMediaClick.bind(this);
        this.navDotClick = this.navDotClick.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.staticMapDragClick = this.staticMapDragClick.bind(this);
    }


    render() {
        //console.log("DotsHomeItem / render - dotInfo = ", this.props.dotInfo);

        // Common dot flag
        const commonDot = this.props.dotInfo.type === "EV" || this.props.dotInfo.type === "AU";

        // Determine if user is logged in
        const loggedIn = !!localStorage.token;
        const isAuthor = (loggedIn)?
            (this.props.userInfo.id === this.props.dotInfo.editor.id) : false;

        // Small layout
        const smallLayout = this.props.browserWidth <= 3;

        // Save limit
        const bucketedOut = (this.props.dotInfo.save_limit === null)? false :
            (
                (this.props.dotInfo.saved_user_count >= this.props.dotInfo.save_limit)?
                    true : false
            );

        /*
        ============================================================================================
            Curation length
        ============================================================================================
        */
        let curationLength = 0;

        // If comment dot
        if (commonDot) {
            curationLength = this.props.dotInfo.overview.length;
        }
        // Contributor
        else {
            const overviewLength = this.props.dotInfo.overview.length;
            const todosLength = (this.props.dotInfo.dot_extension.todos == null)?
                0 : this.props.dotInfo.dot_extension.todos.length;
            const historyLength = (this.props.dotInfo.dot_extension.history == null)?
                0 : this.props.dotInfo.dot_extension.history.length;
            const storiesLength = (this.props.dotInfo.dot_extension.stories == null)?
                0 : this.props.dotInfo.dot_extension.stories.length;
            curationLength = overviewLength + todosLength + historyLength + storiesLength;
        }
        //console.log("curationLength = ", curationLength);


        /*
        ============================================================================================
            Curation switches
        ============================================================================================
        */

        let todosOn = false;
        let historyOn = false;
        let storiesOn = false;

        if (!commonDot) {
            if (this.props.dotInfo.dot_extension.todos === null) {
                todosOn = false;
            }
            else {
                todosOn = (this.props.dotInfo.dot_extension.todos.trim().length === 0)? false : true;
            }

            if (this.props.dotInfo.dot_extension.history === null) {
                historyOn = false;
            }
            else {
                historyOn = (this.props.dotInfo.dot_extension.history.trim().length === 0)? false : true;
            }

            if (this.props.dotInfo.dot_extension.stories === null) {
                storiesOn = false;
            }
            else {
                storiesOn = (this.props.dotInfo.dot_extension.stories.trim().length === 0)? false : true;
            }
        }


        /*
        ============================================================================================
            Overview divider on
        ============================================================================================
        */
        let overviewDividerOn = false;


        // General user
        if (commonDot) {
            overviewDividerOn = false;
        }
        // Contributor
        else {
            if ((!todosOn) && (!historyOn) && (!storiesOn)) {
                overviewDividerOn = false;
            }
        }


        /*
        ============================================================================================
            Header style
        ============================================================================================
        */
        //const headerColor = this.state.savedByMe? "#202020" : "#151515";


        /*
        ============================================================================================
            Staff (Editor / Contributors / Helpers)
        ============================================================================================
        */

        // Editor image
        const editorImage = (this.props.dotInfo.editor.profile_pic)?
            (
                (this.props.dotInfo.editor.profile_pic.external_url === null)?
                    getMediaProperty(this.props.dotInfo.editor.profile_pic, "t", "url", true) :
                    url(this.props.dotInfo.editor.profile_pic.external_url)
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/no-profile-picture-day.png") :
                    getStaticPath("/images/common/no-profile-picture-night.png")
            );

        // Contributors and helpers
        const contributorProfilePicListProps = {
            colorMode: this.props.colorMode,
            numProfilePics: this.numStaffProfilePics,
            usersInfo: this.props.dotInfo.contributors_best,
            userCount: (this.props.dotInfo.contributor_count - 1),
            userTypeLabel: "Contributor",
            userTypeLabelOn: false,
            moreBlankOn: false,
            classNamePrefix: "dots-home-item",
            classNameUserType: "contributor",
            index: this.props.index
        };

        const helperProfilePicListProps = {
            colorMode: this.props.colorMode,
            numProfilePics: this.numStaffProfilePics,
            usersInfo: this.props.dotInfo.helpers_best,
            userCount: this.props.dotInfo.helper_count,
            userTypeLabel: "Helper",
            userTypeLabelOn: true,
            moreBlankOn: false,
            classNamePrefix: "dots-home-item",
            classNameUserType: "helper",
            index: this.props.index
        };

        let staff = null;
        if (((this.props.dotInfo.contributor_count - 1) > 0) && (!smallLayout)) {
            if (this.props.dotInfo.helper_count > 0) {
                staff = (
                    <div className = "dots-home-item-staff">
                        <div className = {(this.props.colorMode === "day")?
                            "dots-home-item-staff-text dg4" :
                            "dots-home-item-staff-text g4"}
                        >
                            with
                        </div>
                        <div className = "dots-home-item-staff-item">
                            <UserProfilePicList {...contributorProfilePicListProps} />
                        </div>
                        <div className = "dots-home-item-staff-item">
                            <UserProfilePicList {...helperProfilePicListProps} />
                        </div>
                    </div>
                );
            }
            else {
                staff = (
                    <div className = "dots-home-item-staff">
                        <div className = {(this.props.colorMode === "day")?
                            "dots-home-item-staff-text dg4" :
                            "dots-home-item-staff-text g4"}
                        >
                            with
                        </div>
                        <div className = "dots-home-item-staff-item">
                            <UserProfilePicList {...contributorProfilePicListProps} />
                        </div>
                    </div>
                );
            }
        }
        else {
            if (this.props.dotInfo.helper_count > 0) {
                staff = (
                    <div className = "dots-home-item-staff">
                        <div className = {(this.props.colorMode === "day")?
                            "dots-home-item-staff-text dg4" :
                            "dots-home-item-staff-text g4"}
                        >
                            with
                        </div>
                        <div className = "dots-home-item-staff-item">
                            <UserProfilePicList {...helperProfilePicListProps} />
                        </div>
                    </div>
                );
            }
        }


        /*
        ============================================================================================
            Media
        ============================================================================================
        */

        // Get the image dimensions for the right size (small size)
        const mediaInfo = this.props.media[this.state.selectedMediaIndex];
        const mediaWidth = getMediaProperty(mediaInfo, "s", 'width', false);
        const mediaHeight = getMediaProperty(mediaInfo, "s", 'height', false);

        // Is video
        const isVideo = (this.props.media[this.state.selectedMediaIndex].type === "video");

        // Effective browser width
        const effectiveBrowserWidth = this.props.browserWidthPixels - 2 * this.marginWidth;


        /*
        ============================================================================================
            Media dimensions
        ============================================================================================
        */

        const mediaDimensionsProps = {
            colorMode: this.props.colorMode,
            effectiveBrowserWidth: effectiveBrowserWidth,
            isVideo: isVideo,
            mediaArea: this.mediaArea,
            mediaWidth: mediaWidth,
            mediaHeight: mediaHeight,
            minAspectRatio: this.minAspectRatio,
            maxAspectRatio: this.maxAspectRatio,
            minContentWidth: this.minContentWidth,
            scaleContentWidth: this.scaleContentWidth,
            maxContentWidth: null
        };

        const dimensions = mediaDimensions(mediaDimensionsProps);
        const finalMediaWidth = dimensions.finalMediaWidth;
        const finalMediaHeight = dimensions.finalMediaHeight;


        /*
        ============================================================================================
            Step 1 : Contrain Aspect Ratio
        ============================================================================================
        */

        /*
        // Aspect ratio adjusted width and height
        let aspectRatio = mediaWidth / mediaHeight;
        let adjustedMediaWidth = mediaWidth;
        let adjustedMediaHeight = mediaHeight;
        //console.log("===================================================================");
        //console.log("DotsHomeItem / render - browser width = ", this.props.browserWidthPixels);
        //console.log("DotsHomeItem / render - aspectRatio = ", aspectRatio);

        // If the media is vertically long
        if (aspectRatio < this.minAspectRatio) {
            //console.log("DotsHomeItem / render - too small aspect ratio");

            aspectRatio = this.minAspectRatio;
            adjustedMediaHeight = adjustedMediaWidth / aspectRatio;
        }
        else if (aspectRatio > this.maxAspectRatio) {
            //console.log("DotsHomeItem / render - too large aspect ratio");

            aspectRatio = this.maxAspectRatio;
            adjustedMediaWidth = adjustedMediaHeight * aspectRatio;
        }
        else if (aspectRatio >= this.minAspectRatio && aspectRatio <= this.maxAspectRatio) {
            //console.log("DotsHomeItem / render - normal aspect ratio");
        }
        else {
            console.log("[WARNING] DotsHomeItem / render - unexpected case");
        }
        */

        //console.log("DotsHomeItem / render - adjustedMediaWidth = ", adjustedMediaWidth);
        //console.log("DotsHomeItem / render - adjustedMediaHeight = ", adjustedMediaHeight);


        /*
        ============================================================================================
            Step 2 : Resize to keep constant area
        ============================================================================================
        */

        /*
        // Resize media
        const resizeRatio = Math.sqrt(this.mediaArea / (adjustedMediaWidth * adjustedMediaHeight));
        const resizedMediaWidth = Math.round(adjustedMediaWidth * resizeRatio);
        const resizedMediaHeight = Math.round(adjustedMediaHeight * resizeRatio);
        */

        //console.log("DotsHomeItem / render - resizeRatio = ", resizeRatio);
        //console.log("DotsHomeItem / render - resizedMediaWidth = ", resizedMediaWidth);
        //console.log("DotsHomeItem / render - resizedMediaHeight = ", resizedMediaHeight);


        /*
        ============================================================================================
            Step 3 : Constant height until the min content width
            Step 4 : Linear Scale as further constrained by the browser width
        ============================================================================================
        */

        /*

        // Compare the adjusted width with the min content width
        if (effectiveBrowserWidth < this.minContentWidth) {
            // Scale ratio
            const scaleRatio = this.minContentWidth / this.scaleContentWidth;

            finalMediaWidth = this.minContentWidth;
            finalMediaHeight = resizedMediaHeight * scaleRatio;

        }
        else if (effectiveBrowserWidth >= this.minContentWidth && effectiveBrowserWidth < this.scaleContentWidth) {
            // Scale ratio
            const scaleRatio = effectiveBrowserWidth / this.scaleContentWidth;

            finalMediaWidth = effectiveBrowserWidth;
            finalMediaHeight = resizedMediaHeight * scaleRatio;

            //console.log("DotsHomeItem / render - final width smaller than min content width");
            //console.log("DotsHomeItem / render - scaleRatio = ", scaleRatio);
        }

        else if (effectiveBrowserWidth >= this.scaleContentWidth && effectiveBrowserWidth <= resizedMediaWidth) {
            finalMediaWidth = effectiveBrowserWidth;
            finalMediaHeight = resizedMediaHeight;

            //console.log("DotsHomeItem / render - final width contrained by window");
        }
        else {
            finalMediaWidth = resizedMediaWidth;
            finalMediaHeight = resizedMediaHeight;

            //console.log("DotsHomeItem / render - final width unrestricted");
        }
        //console.log("DotsHomeItem / render - finalMediaWidth = ", finalMediaWidth);
        //console.log("DotsHomeItem / render - finalMediaHeight = ", finalMediaHeight);
        */


        /*
        ============================================================================================
            Media URL
        ============================================================================================
        */

        // Media URL
        const mediaURL = getMediaProperty(this.props.media[this.state.selectedMediaIndex], "s", "url", false);
        const shadowImage = (this.props.colorMode === "day")?
            null : getStaticPath("/images/shadow/vignette-medium.png");

        // Media style
        const mediaWrapperStyle = {
            width: dimensions.finalMediaWidth,
            height: finalMediaHeight,
            backgroundImage: (this.props.colorMode === "day")?
                getStaticPath("/images/loader/loader-white.gif") :
                getStaticPath("/images/loader/loader-black.gif")
        };


        /*
        ============================================================================================
            Media navigation
        ============================================================================================
        */

        const nextArrowImage = getStaticPath("/images/common/arrow-right-white.png");
        const nextArrow = this.state.selectedMediaIndex < (this.props.media.length - 1)?
        (
            <div className = "dots-home-item-media-next-arrow image-cover"
                style = {{ backgroundImage:  nextArrowImage }}
                onClick = {this.nextMediaClick}
            >
            </div>
        ) : null;

        const prevArrowImage = getStaticPath("/images/common/arrow-left-white.png");
        const prevArrow = this.state.selectedMediaIndex > 0?
        (
            <div className = "dots-home-item-media-prev-arrow image-cover"
                style = {{ backgroundImage: prevArrowImage }}
                onClick = {this.prevMediaClick}
            >
            </div>
        ) : null;

        // Navigation dots
        const navDotImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/image-dot-black.png") :
            getStaticPath("/images/common/image-dot-white.png");

        const navDots = (this.props.media.length > 1)?
        this.props.media.map((media, index) => {
            // If selected
            const navDotClass = (this.state.selectedMediaIndex === index)?
                "dots-home-item-nav-dot-on" : "dots-home-item-nav-dot-off";

            return(
                <div key = {"dots-home-item-nav-dot-" + index.toString()} className={navDotClass}
                    style = {{ backgroundImage: navDotImage }}
                    onClick = {this.navDotClick.bind(this, index)}
                >
                </div>
            );
        }) : null;


        /*
        ============================================================================================
            Tag User
        ============================================================================================
        */
        const tagUserButtonImage = getStaticPath("/images/common/user-tag-white.png");

        const tagUserButton = (loggedIn)? (
            <div className = {
                    (isVideo)?
                        "dots-home-item-user-tag-video image-button-s3" :
                        "dots-home-item-user-tag-image image-button-s3"
                }
                style = {{ backgroundImage: tagUserButtonImage }}
                onClick = {
                    () => {
                        this.props.storeUserTag(
                            {
                                modalOn: true,
                                mode: (isAuthor)? "contribute" : "save",
                                id: this.props.dotInfo.id,
                                dot: this.props.dotInfo,
                                saved: this.state.savedByMe
                            }
                        );
                    }
                }
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            Media enlarge button
        ============================================================================================
        */

        const enlargeButtonImage = getStaticPath("/images/common/enlarge-white.png");
        const enlargeButton = (
            <div className = {
                    (isVideo)?
                        "dots-home-item-enlarge-video image-button-s3" :
                        "dots-home-item-enlarge-image image-button-s3"
                }
                style = {{ backgroundImage: enlargeButtonImage }}
                onClick = {
                    (event) => {
                        // Stop propagation
                        event.stopPropagation();

                        // Open gallery modal
                        this.props.storeGallery({
                            modalOn: true,
                            info: this.props.dotInfo
                        });
                    }
                }
            >
            </div>
        );


        /*
        ============================================================================================
            Media
        ============================================================================================
        */

        const media = (isVideo)?
        (
            <div className = "dots-home-item-media-wrapper image-loader-s2"
                style = {mediaWrapperStyle}
            >
                <ReactPlayer className = "dots-home-item-video"
                    url = {mediaURL}
                    width = "100%"
                    height = "100%"
                    volume = {0.05}
                    playing = {this.state.inViewport && this.state.feedVideoOn}
                    controls
                    playsinline
                />
                {nextArrow}
                {prevArrow}
                {tagUserButton}
                {enlargeButton}
                <div className = "dots-home-item-media-shadow"
                    style = {{ backgroundImage: shadowImage }}
                >
                </div>
            </div>
        ) : (
            <div className = "dots-home-item-media-wrapper image-loader-s2"
                style = {mediaWrapperStyle}>
                <div className = "dots-home-item-image"
                    style = {{ backgroundImage: url(mediaURL) }}
                >
                </div>
                {nextArrow}
                {prevArrow}
                {tagUserButton}
                {enlargeButton}
                <div className = "dots-home-item-media-shadow"
                    style = {{ backgroundImage: shadowImage }}
                >
                </div>
            </div>
        );


        /*
        ============================================================================================
            Map Switch and Widths
        ============================================================================================
        */

        let mapOn = true;
        let containerWidth, headerLeftWidth, footerWidth, titleWidth, mapWidth;
        const headerRightWidth = (smallLayout)?
            this.headerRightSmallWidth : this.headerRightWidth;

        if (finalMediaWidth < this.mapContentWidth) {
            //console.log("DotHome / render - case 2");
            containerWidth = finalMediaWidth;
            headerLeftWidth = finalMediaWidth - headerRightWidth;
            footerWidth = finalMediaWidth;
            titleWidth = finalMediaWidth;
            mapWidth = finalMediaWidth;
            mapOn = (curationLength > this.curationMapLength)? true : false;
        }
        else if ((finalMediaWidth >= this.mapContentWidth) && (finalMediaWidth <= this.containContentWidth)) {
            //console.log("DotHome / render - case 3");
            containerWidth = finalMediaWidth;
            headerLeftWidth = finalMediaWidth - headerRightWidth;
            footerWidth = finalMediaWidth;
            if (curationLength > this.curationMapLength) {
                titleWidth = Math.max(Math.round(finalMediaWidth * this.titleRatio), this.minTitleWidth);
                mapWidth = finalMediaWidth - titleWidth;
                mapOn = true;
            }
            else {
                titleWidth = finalMediaWidth;
                mapWidth = 0;
                mapOn = false;
            }
        }
        else if (finalMediaWidth > this.containContentWidth) {
            //console.log("DotHome / render - case 4");
            containerWidth = finalMediaWidth;
            headerLeftWidth = this.containContentWidth - this.headerMargin - headerRightWidth;
            footerWidth = this.containContentWidth;
            if (curationLength > this.curationMapLength) {
                titleWidth = Math.max(Math.round(this.containContentWidth * this.titleRatio), this.minTitleWidth);
                mapWidth = this.containContentWidth - titleWidth;
                mapOn = true;
            }
            else {
                titleWidth = finalMediaWidth;
                mapWidth = 0;
                mapOn = false;
            }
        }

        /*
        console.log("DotHome / render - resizedMediaWidth = ", resizedMediaWidth);
        console.log("DotHome / render - containerWidth = ", containerWidth);
        console.log("DotHome / render - headerLeftWidth = ", headerLeftWidth);
        console.log("DotHome / render - footerWidth = ", footerWidth);
        console.log("DotHome / render - titleWidth = ", titleWidth);
        console.log("DotHome / render - mapWidth = ", mapWidth);
        */


        /*
        ============================================================================================
            Like / save / share buttons
        ============================================================================================
        */

        let likeButton = null;
        let likeButtonTooltip = null;
        let saveButton = null;
        let saveButtonText = null;
        let saveButtonTooltip = null;
        let shareButtonTooltip = null;
        let shareButton = null;

        if (loggedIn) {
            const likeButtonTooltipText = this.state.likedByMe? "Unlike" : "Like";
            const likeButtonTooltipID = "dots-home-item-like-button-tooltip" + this.props.index;
            likeButtonTooltip = (window.touchOn)? null : (
                <ReactTooltip
                    id = {likeButtonTooltipID}
                    className = "dots-home-item-like-button-tooltip tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html={true}
                />
            );

            const likeButtonClickProps = {
                setState: this.setState,
                likedByMe: this.state.likedByMe,
                userInfo: this.props.userInfo,
                dotInfo: this.props.dotInfo,
                storeUser: this.props.storeUser
            };

            const likeButtonImage = this.state.likedByMe?
                (
                    (this.props.colorMode === "day")?
                    getStaticPath("/images/common/like-on-red.png") :
                    getStaticPath("/images/common/like-on-red.png")
                    //getStaticPath("/images/common/like-on-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                    getStaticPath("/images/common/like-off-black.png") :
                    getStaticPath("/images/common/like-off-white.png")
                );

            const likeButtonClass = this.state.likedByMe?
                "dots-home-item-like-button-on image-button-weak-s3" :
                "dots-home-item-like-button-off image-button-weak-s3";

            likeButton = (isAuthor)? null : (
                <div className = {likeButtonClass}
                    style = {{ backgroundImage:  likeButtonImage }}
                    onClick = {likeButtonClick.bind(this, likeButtonClickProps)}
                    data-tip = {likeButtonTooltipText}
                    data-for = {likeButtonTooltipID}
                >
                </div>
            )

            // Save button
            const saveButtonTooltipText = this.state.savedByMe? "Unbucket" : "Bucket This Dot";
            const saveButtonTooltipID = "dots-home-item-save-button-tooltip" + this.props.index;
            saveButtonTooltip = (window.touchOn)? null : (
                <ReactTooltip
                    id = {saveButtonTooltipID}
                    className = "dots-home-item-save-button-tooltip tooltip-s2"
                    type = "dark"
                    place = "bottom"
                    html = {true}
                />
            );

            const saveButtonClickProps = {
                setState: this.setState,
                savedByMe: this.state.savedByMe,
                userInfo: this.props.userInfo,
                dotInfo: this.props.dotInfo,
                storeUser: this.props.storeUser
            };

            const saveButtonImage = this.state.savedByMe?
                (
                    (this.props.colorMode === "day")?
                    getStaticPath("/images/common/bucket-on-black.png") :
                    getStaticPath("/images/common/bucket-on-white.png")
                ) : (
                    (this.props.colorMode === "day")?
                    getStaticPath("/images/common/bucket-off-black.png") :
                    getStaticPath("/images/common/bucket-off-white.png")
                );

            const saveButtonClass = this.state.savedByMe?
                "dots-home-item-save-button-on image-button-weak-s2" :
                "dots-home-item-save-button-off image-button-weak-s2";

            saveButton = (isAuthor || (!this.state.savedByMe && bucketedOut))? null : (
                <div className = {saveButtonClass}
                    style = {{ backgroundImage: saveButtonImage }}
                    onClick = {this.saveButtonClick.bind(this, saveButtonClickProps)}
                    data-tip = {saveButtonTooltipText}
                    data-for = {saveButtonTooltipID}
                >
                </div>
            );

            if (!isAuthor && (this.state.savedByMe || !bucketedOut)) {
                saveButtonText = this.state.savedByMe? (
                    <div className = {(this.props.colorMode === "day")?
                        "dots-home-item-save-button-text k4" :
                        "dots-home-item-save-button-text w4"}
                    >
                        {(smallLayout)? "Saved" : "Bucketed"}
                    </div>
                ) : null;
            }
        }

        const shareButtonTooltipText = "Share";
        const shareButtonTooltipID = "dots-home-item-share-button-tooltip" + this.props.index;
        shareButtonTooltip = (window.touchOn)? null : (
            <ReactTooltip
                id = {shareButtonTooltipID}
                className = "dots-home-item-share-button-tooltip tooltip-s2"
                type = "dark"
                place = "bottom"
                html={true}
            />
        );

        const shareButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/share-off-black.png") :
            getStaticPath("/images/common/share-off-white.png");
        const shareButtonClass = "dots-home-item-share-button image-button-weak-s2";

        shareButton = (
            <div className = {shareButtonClass}
                onClick = {this.shareButtonClick}
                style = {{ backgroundImage:  shareButtonImage }}
                data-tip = {shareButtonTooltipText}
                data-for = {shareButtonTooltipID}
            >
            </div>
        )


        /*
        ============================================================================================
            Like and save counts
        ============================================================================================
        */

        // Counts
        const likeText = (this.state.likeCount === 1)? "Like" : "Likes";
        const likeCount = (this.state.likeCount > 0)?
        (
            <div className = {(this.props.colorMode === "day")?
                "dots-home-item-like-count k4" :
                "dots-home-item-like-count w4"}
            >
                {formatNumbers(this.state.likeCount).toString()} {likeText}
            </div>
        ) : null;

        // const saveText = (this.state.saveCount === 1)? "Save" : "Saves";
        /*
        const saveCount = (this.state.saveCount > 0)?
        (
            <div className = "dots-home-item-save-count">
                {formatNumbers(this.state.saveCount).toString()} {saveText}
            </div>
        ) : null;
        */


        /*
        ============================================================================================
            Area
        ============================================================================================
        */

        // Area
        let area = null;
        if (this.props.dotInfo.area == null) {
            area = (
                <div className = {(this.props.colorMode === "day")?
                    "dots-home-item-map-location k4" :
                    "dots-home-item-map-location w4"}
                >
                    {this.props.dotInfo.state_name}
                </div>
            );
        }
        else {
            if (this.props.dotInfo.area.trim().length > 0) {
                area = (
                    <div className = {(this.props.colorMode === "day")?
                        "dots-home-item-map-location k4" :
                        "dots-home-item-map-location w4"}
                    >
                        {this.props.dotInfo.area}
                    </div>
                );
            }
        }


        /*
        ============================================================================================
            Static Map Drag Button
        ============================================================================================
        */

        // Drag image
        const dragImage = (this.state.dragOn)?
            getStaticPath("/images/common/unlock-white.png") :
            getStaticPath("/images/common/lock-white.png");

        const dragButton = (window.touchOn)?
            (
                <div className = "map-static-drag-button image-button-s1"
                    style = {{ backgroundImage: dragImage }}
                    onClick = {this.staticMapDragClick}
                >
                </div>
            ) : null;


        /*
        ============================================================================================
            Map
        ============================================================================================
        */

        let map = null;
        let mapEmbedded = null;
        let mapBottom = null;
        let bucketedOutSign = null;

        if (this.state.savedByMe || !bucketedOut) {
            // Using Google maps
            if (this.props.mapSource === "google") {
                // Google Maps
                // Only when google object and locations are ready
                if (this.props.google) {
                    // Set the props for Map component
                    const mapProps = {
                        // Google
                        google: this.props.google,

                        // Map mode
                        mapMode: this.props.mapMode,

                        // DOM node IDs
                        mapNodeID: this.mapNodeID,
                        mapContainerNodeID : this.mapContainerNodeID + "-" + this.props.index,
                        buttonsNodeID: this.mapButtonsNodeID + "-" + this.props.index,
                        startInputNodeID: null,
                        endInputNodeID: null,

                        // Map height
                        mapWidth: mapWidth,
                        mapHeight: this.state.mapHeight,
                        mapMinHeight: this.mapMinHeight,
                        mapMaxHeight: this.mapMaxHeight,
                        mapHeightIncrement: this.mapHeightIncrement,
                        mapHeightUpdate: true,

                        // Dots
                        dotsInfo: [this.props.dotInfo],

                        // Itinerary and selected settings
                        itinerary: [0],
                        selected: 0,
                        hovered: null,
                        selectedChild: null,
                        hoveredChild: null,
                        displayChildren: null,

                        // Parking / start / end locations
                        parkingLocation: (this.props.dotInfo.parking_location)?
                            this.props.dotInfo.parking_location : null,
                        startLocation: null,
                        endLocation: null,

                        // Map zoom / center / type
                        mapZoom: this.props.dotInfo.map_zoom,
                        mapCenter: this.props.dotInfo.map_center,
                        mapType: (this.props.dotInfo.map_type === null)?
                            "hybrid" : this.props.dotInfo.map_type,

                        // Set state
                        setState: this.setState

                    };

                    // Get the Map component
                    map = (mapOn)? (
                        <GoogleMap {...mapProps}/>
                    ) : null;
                }
            }
            else if (this.props.mapSource === "open" ||
                (this.props.mapSource === "static" && this.props.dotInfo.map_media === null)) {
                // Set the props for Map component
                const openMapProps = {
                    // Customize on
                    mapRefresh: this.props.feedOn,

                    // Map mode
                    mapMode: this.props.mapMode,

                    // DOM node IDs
                    mapNodeID: this.mapNodeID,

                    // Map width and height
                    mapWidth: mapWidth,
                    mapHeight: this.state.mapHeight,
                    mapMinHeight: this.mapMinHeight,
                    mapMaxHeight: this.mapMaxHeight,
                    mapHeightIncrement: this.mapHeightIncrement,
                    mapHeightUpdate: true,

                    // Dots
                    dotsInfo: [ this.props.dotInfo ],

                    // Itinerary and selected settings
                    itinerary: [ 0 ],
                    selected: 0,
                    hovered: null,
                    selectedChild: null,
                    hoveredChild: null,
                    displayChildren: null,

                    // Parking / start / end locations
                    parkingLocation: (this.props.dotInfo.parking_location)?
                        this.props.dotInfo.parking_location : null,
                    startLocation: null,
                    endLocation: null,

                    // Map zoom / center / type
                    mapZoom: Math.min(13, this.props.dotInfo.map_zoom),
                    mapCenter: this.props.dotInfo.map_center,
                    mapType: (this.props.dotInfo.map_type === null)?
                        "hybrid" : this.props.dotInfo.map_type,

                    // SetState
                    setState: this.setState
                };

                // Get the Map component
                map = (mapOn)? (
                    <OpenMap {...openMapProps}/>
                ) : null;
            }
            else if (this.props.mapSource === "static" && this.props.dotInfo.map_media !== null) {
                // Media URL
                const mapMediaURL = getMediaProperty(this.props.dotInfo.map_media, 'o', "url", true);
                const mapMarkerImage = getStaticPath("/images/map/dot-marker-black.png");

                // Translation
                const dx = this.displacementRatio * Number(this.props.dotInfo.map_displacement[0]);
                const dy = this.displacementRatio * Number(this.props.dotInfo.map_displacement[1]);
                const transform = "translate(" + dx.toString() + "px, " + dy.toString() + "px)";

                map = (
                    <Draggable
                        dragOn = {this.state.dragOn}
                        translateXLimit = {(720 - mapWidth)/2}
                        translateYLimit = {220}
                        labelHeight = {30}
                    >
                        <div className = "dots-home-item-map-static map-static"
                            style = {{
                                backgroundImage: mapMediaURL,
                                width: 720,
                                height: 720
                            }}
                        >
                            <div className = "map-static-marker map-static-marker-pulse"
                                style = {{
                                    backgroundImage: mapMarkerImage,
                                    transform: transform
                                }}
                            >
                            </div>
                        </div>
                    </Draggable>
                );
            }
            else {
                console.log("DotsHomeItem / render - wrong map source type");
            }

            // Get the Map component
            const mapWrapped = (mapOn)? (
                <div className = "dots-home-item-map-container"
                    style = {{ width: mapWidth }}
                >
                    {area}
                    {
                        (this.props.mapSource === "static" && this.props.dotInfo.map_media !== null)?
                        (
                            <div className = {(this.props.colorMode === "black")?
                                    "dots-home-item-map-static-wrapper border-day" :
                                    "dots-home-item-map-static-wrapper border-night"}
                            >
                                {dragButton}
                                {map}
                            </div>
                        ) : (
                            <div className = {(this.props.colorMode === "black")?
                                    "dots-home-item-map-wrapper border-day" :
                                    "dots-home-item-map-wrapper border-night"}
                            >
                                {map}
                            </div>
                        )
                    }
                </div>
            ) : null;

            if (finalMediaWidth < this.mapContentWidth) {
                mapEmbedded = null;
                if (mapOn) {
                    mapBottom = (
                        <div className = "dots-home-item-footer-map">
                            {mapWrapped}
                        </div>
                    );
                }
                else {
                    mapBottom = (
                        <div className = "dots-home-item-footer-spacer">
                        </div>
                    );
                }
            }
            else{
                mapEmbedded = mapWrapped;
                mapBottom = (
                    <div className = "dots-home-item-footer-spacer">
                    </div>
                );
            }
        }
        else {
            const bucketedOutImage = (smallLayout)?
                (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/saved-out-day.png") :
                        getStaticPath("/images/common/saved-out-night.png")
                ) : (
                    (this.props.colorMode === "day")?
                        getStaticPath("/images/common/bucketed-out-day.png") :
                        getStaticPath("/images/common/bucketed-out-night.png")
                );

            mapEmbedded = null;

            mapBottom = (
                <div className = "dots-home-item-footer-spacer">
                </div>
            );

            bucketedOutSign = (
                <div className = {(smallLayout)?
                        "dots-home-item-bucketed-out-small image-contain" :
                        "dots-home-item-bucketed-out image-contain"}
                    style = {{ backgroundImage: bucketedOutImage }}
                >
                </div>
            );
        }


        /*
        ============================================================================================
            Location Name
        ============================================================================================
        */
        const locationName = (
            <Link to = {`/dot/${this.props.dotInfo.slug}`}>
                <div className = {(this.props.colorMode === "day")?
                    "dots-home-item-location-name lb4" :
                    "dots-home-item-location-name b4"}
                >
                    {this.props.dotInfo.name}
                </div>
            </Link>
        );
        // target="_blank"


        /*
        ============================================================================================
            Detail Button
        ============================================================================================
        */
        /*
        const detailButtonImage = (this.props.colorMode === "day")?
            getStaticPath("/images/common/curation-black.png") :
            getStaticPath("/images/common/curation-white.png");

        const detailButton = (
            <Link to = {`/dot/${this.props.dotInfo.slug}`} target="_blank">
                <div className = "dots-home-item-detail-button image-button-s0"
                    style = {{ backgroundImage: detailButtonImage }}
                >
                </div>
            </Link>
        );
        */


        /*
        ============================================================================================
            Expand button
        ============================================================================================
        */

        // Expand button
        const expandButtonImage = (this.state.expanded)?
            (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/less-info-black.png") :
                    getStaticPath("/images/common/less-info-white.png")
            ) : (
                (this.props.colorMode === "day")?
                    getStaticPath("/images/common/more-info-black.png") :
                    getStaticPath("/images/common/more-info-white.png")
            );

        const expandButton = (this.state.variableHeight)? (
            <div className = {(this.state.expanded)?
                    "dots-home-item-curation-more image-button-base" : 
                    "dots-home-item-curation-less image-button-base"
                }
                style = {{
                    backgroundImage: expandButtonImage
                }}
                onClick = {this.toggleExpanded}
            >
            </div>
        ) : null;


        /*
        ============================================================================================
            Curation
        ============================================================================================
        */

        let overview, todos, history, stories = null;

        // Overview divider
        const overviewDivider = (overviewDividerOn)? (
            <div className = "dots-home-item-curation-divider">
                <span className="dots-home-item-curation-divider-text b4">Overview</span>
            </div>
        ) : null;


        if (this.props.dotInfo.overview.length < this.curationMinLength) {
            overview = (
                <div className = "dots-home-item-overview">

                    {bucketedOutSign}

                    <span className = {(this.props.colorMode === "day")?
                        "dots-home-item-curation-text text dark-gray" :
                        "dots-home-item-curation-text text gray"}
                    >
                        {removeFirstSpace(this.props.dotInfo.overview).replace(/[*]/g, "")}
                    </span>
                </div>
            );
        }
        else {
            const overviewProps = {
                colorMode: this.props.colorMode,
                dotInfo: this.props.dotInfo,
                keyHeader: "dots-home-item-overview-" + this.props.index,
                type: "overview",
                classPrefix: "dots-home-item",
                truncate: !this.state.expanded,
                truncateLength: this.curationCutLength
            };
            //console.log("DotsHomeItem / render - overviewProps = ", overviewProps);
            const curationClassName = (this.state.variableHeight)?
            (
                (this.state.expanded)?
                    "dots-home-item-overview-expanded" : "dots-home-item-overview-folded"
            ) : (
                "dots-home-item-overview"
            );

            const curationStyle = (this.state.variableHeight && !this.state.expanded)?
            {
                height: Math.min(this.curationHeight, Math.ceil(this.state.mapHeight / this.curationLineHeight) * this.curationLineHeight) + this.curationMargin
            } : {}

            overview = (
                <div ref = {this.curationRef}
                    className = {curationClassName}
                    style = {curationStyle}
                >
                    {bucketedOutSign}

                    {
                        this.curationPartition(overviewProps)
                    }
                </div>
            );
        }

        if (false) {
            //if ((this.props.dotInfo.type == null) || (this.props.dotInfo.todos.length < this.curationMinLength)) {
            if (this.props.dotInfo.dot_extension.todos.length < this.curationMinLength) {
                todos = (
                    <div>
                        <div className = "dots-home-item-curation-divider">
                            <span className = {(this.props.colorMode === "day")?
                                "dots-home-item-curation-divider-text text lb4" :
                                "dots-home-item-curation-divider-text text b4"}
                            >
                                Todos
                            </span>
                        </div>
                        <div className = "dots-home-item-curation-todos">
                            <span className = {(this.props.colorMode === "day")?
                                "dots-home-item-curation-text text dark-gray" :
                                "dots-home-item-curation-text text gray"}
                            >
                                {removeFirstSpace(this.props.dotInfo.dot_extension.todos).replace(/[*]/g, "")}
                            </span>
                        </div>
                    </div>
                );
            }
            else {
                const todosProps = {
                    colorMode: this.props.colorMode,
                    dotInfo: this.props.dotInfo,
                    keyHeader: "dots-home-item-todos-" + this.props.index,
                    type: "todos",
                    classPrefix: "dots-home-item"
                };

                todos = (
                    <div>
                        <div className = "dots-home-item-curation-divider">
                            <span className = {(this.props.colorMode === "day")?
                                "dots-home-item-curation-divider-text text lb4" :
                                "dots-home-item-curation-divider-text text b4"}
                            >
                                Todos
                            </span>
                        </div>
                        <div className = "dots-home-item-curation-todos">
                            {
                                this.curationPartition(todosProps)
                            }
                        </div>
                    </div>
                );
            }
        }

        //if (historyOn) {
        if (false) {
            //if ((this.props.dotInfo.type == null) || (this.props.dotInfo.history.length < this.curationMinLength)) {
            if (this.props.dotInfo.dot_extension.history.length < this.curationMinLength) {
                history = (
                    <div>
                        <div className = "dots-home-item-curation-divider">
                            <span className = "dots-home-item-curation-divider-text b4">History</span>
                        </div>
                        <div className = "dots-home-item-curation-history">
                            <span className = {(this.props.colorMode === "day")?
                                "dots-home-item-curation-text text dark-gray" :
                                "dots-home-item-curation-text text gray"}
                            >
                                {removeFirstSpace(this.props.dotInfo.dot_extension.history).replace(/[*]/g, "")}
                            </span>
                        </div>
                    </div>
                );
            }
            else {
                const historyProps = {
                    colorMode: this.props.colorMode,
                    dotInfo: this.props.dotInfo,
                    keyHeader: "dots-home-item-history-" + this.props.index,
                    type: "history",
                    classPrefix: "dots-home-item"
                }

                history = (
                    <div>
                        <div className = "dots-home-item-curation-divider">
                            <span className = "dots-home-item-curation-divider-text b4">History</span>
                        </div>
                        <div className = "dots-home-item-curation-history">
                            {
                                this.curationPartition(historyProps)
                            }
                        </div>
                    </div>
                );
            }
        }

        //if (storiesOn) {
        if (false) {
            //if ((this.props.dotInfo.type == null) || (this.props.dotInfo.stories.length < this.curationMinLength)) {
            if (this.props.dotInfo.dot_extension.stories.length < this.curationMinLength) {
                stories = (
                    <div>
                        <div className = "dots-home-item-curation-divider">
                            <span className = "dots-home-item-curation-divider-text b4">Stories</span>
                        </div>
                        <div className = "dots-home-item-curation-stories">
                            <span className = {(this.props.colorMode === "day")?
                                "dots-home-item-curation-text text dark-gray" :
                                "dots-home-item-curation-text text gray"}
                            >
                                {removeFirstSpace(this.props.dotInfo.dot_extension.stories).replace(/[*]/g, "")}
                            </span>
                        </div>
                    </div>
                );
            }
            else {
                const storiesProps = {
                    colorMode: this.props.colorMode,
                    dotInfo: this.props.dotInfo,
                    keyHeader: "dots-home-item-stories-" + this.props.index,
                    type: "stories",
                    classPrefix: "dots-home-item"
                };

                stories = (
                    <div>
                        <div className = "dots-home-item-curation-divider">
                            <span className = "dots-home-item-curation-divider-text b4">Stories</span>
                        </div>
                        <div className = "dots-home-item-curation-stories">
                            {
                                this.curationPartition(storiesProps)
                            }
                        </div>
                    </div>
                );
            }
        }


        /*
        ============================================================================================
            Bottle
        ============================================================================================
        */
        const bottleProps = {
            colorMode: this.props.colorMode,
            dotID: this.props.dotInfo.slug,
            usersInfo: this.props.dotInfo.noted_users_recent,
            usersPublic: this.props.dotInfo.noted_users_recent_public,
            userCount: this.props.dotInfo.noted_user_count,
            minBottleSize: this.minBottleSize,
            maxBottleSize: this.maxBottleSize,
            classNamePrefix: "dots-home-item"
        };

        const bottle = (this.props.dotInfo.noted_user_count > 0)? (
            <BottleProfilePicList {...bottleProps} />
        ) : null;


        /*
        ============================================================================================
            Board
        ============================================================================================
        */

        const board = this.props.commentsInfoLoaded ?
        (
            <div className = "dots-home-item-board-container">
                <Board
                    key = {"dot-home-" + this.props.dotInfo.slug + "-board-" + this.props.dotInfo.board.id}
                    typePrefix = {"dot-home"}
                    boardID = {this.props.dotInfo.board.id}
                    userInfo = {this.props.userInfo}
                    loggedIn = {loggedIn}
                    commentCount = {this.props.dotInfo.board.comment_count}
                    commentsInfo = {this.props.commentsInfo}
                    webSocketGroupPrefix = {this.props.webSocketGroupPrefix}
                />
            </div>
        ) : null;


        // Render JSX
        return(
            <div className = "dots-home-item-wrapper">
                <div className = {(this.props.colorMode === "day")?
                    "dots-home-item-container-day" :
                    "dots-home-item-container-night"}
                >
                    <div className = "dots-home-item-header"
                        style = {{
                            width: containerWidth
                            //backgroundColor: headerColor,
                        }}
                    >
                        <div className = "dots-home-item-header-left" style = {{ width: headerLeftWidth }}>
                            <div className = "dots-home-item-editor-column">
                                <Link key = {"dots-home-item-editor-" + this.props.index.toString() + "-profile-pic"}
                                    to = {`/user/${this.props.dotInfo.editor.username}`}>
                                    <div className = {(this.props.colorMode === "day")?
                                        "dots-home-item-editor-profile-pic profile-image-s4 border-day image-button-strong-reverse-base" :
                                        "dots-home-item-editor-profile-pic profile-image-s4 border-night image-button-strong-base"}
                                        style = {{ backgroundImage: editorImage }}>
                                    </div>
                                    <div className = {(this.props.colorMode === "day")?
                                        "dots-home-item-editor-name k4" :
                                        "dots-home-item-editor-name w4"}
                                    >
                                        {this.props.dotInfo.editor.name}
                                    </div>
                                </Link>
                            </div>
                            <div className = "dots-home-item-staff-column">
                                {staff}
                            </div>
                        </div>
                        <div className = "dots-home-item-header-right"
                            style = {{ width: headerRightWidth }}
                        >
                            {saveButtonText}
                            {saveButton}
                            {saveButtonTooltip}
                        </div>
                    </div>


                    <div ref = {this.mediaContainerRef}
                        className = {(this.props.colorMode === "day")?
                            "dots-home-item-media-container-day" :
                            "dots-home-item-media-container-night"}
                        style = {{
                            width: finalMediaWidth,
                            height: finalMediaHeight //+ this.mediaContainerBorderWidth * 2
                        }}
                    >
                        {media}
                    </div>

                    <div className = "dots-home-item-footer"
                        style = {{ width: footerWidth }}
                    >
                        <div className = "dots-home-item-nav-dots">
                            {navDots}
                        </div>
                        {mapEmbedded}

                        <div className = "dots-home-item-curation-title"
                            style = {{ width: titleWidth }}
                        >
                            <Link to = {`/dot/${this.props.dotInfo.slug}`}>
                                <div className = {(this.props.colorMode === "day")?
                                    "dots-home-item-curation-title-text k2" :
                                    "dots-home-item-curation-title-text w2"}
                                >
                                    {this.props.dotInfo.title}
                                </div>
                            </Link>

                            {locationName}

                            <div className = "dots-home-item-like-container">
                                {likeCount}
                                {likeButton}
                                {likeButtonTooltip}
                                {shareButton}
                                {shareButtonTooltip}
                            </div>
                        </div>

                        {overviewDivider}
                        {overview}
                        {todos}
                        {history}
                        {stories}
                        {expandButton}
                        {bottle}
                        {mapBottom}
                    </div>
                </div>
                {board}
            </div>
        );
    }
    //<div className="dots-home-item-curation-divider">
    //    <span className="dots-home-item-curation-divider-text b4">Overview</span>
    //</div>

    shareButtonClick() {
        this.props.storeShare({
            modalOn: true,
            type: "dot",
            info: this.props.dotInfo
        });
    }

    nextMediaClick() {
        const newSelectedMediaIndex = this.state.selectedMediaIndex + 1;

        this.setState(
            {
                selectedMediaIndex: newSelectedMediaIndex
            }
        );
    }

    prevMediaClick() {
        const newSelectedMediaIndex = this.state.selectedMediaIndex - 1;

        this.setState(
            {
                selectedMediaIndex: newSelectedMediaIndex
            }
        );
    }

    navDotClick(mediaIndex) {
        this.setState({
            selectedMediaIndex: mediaIndex
        });
    }

    toggleExpanded(event) {
        // Stop propagation
        event.stopPropagation();

        // Update state
        this.setState({
            expanded: !this.state.expanded
        });
    }

    updateCurationLayout() {
        if (this.curationRef) {
            if (this.curationRef.current !== null) {
                // Judge if this is within viewport
                const curationBoundingBox = this.curationRef.current.getBoundingClientRect();
                //console.log("componentDidMount - curationBoundingBox = ", curationBoundingBox);

                // Save intial overview height
                this.curationHeight = curationBoundingBox.height;

                // If the container size is large
                if (curationBoundingBox.height > this.state.mapHeight) {
                    this.setState({
                        variableHeight: true
                    });
                }
            }
        }
    }

    staticMapDragClick() {
        this.setState({ dragOn: !this.state.dragOn });
    }

    componentDidMount() {
        // Stylize curations
        this.updateCurationStyle(this.props.dotInfo, this.updateCurationLayout);

        // Start playing videos if first dot
        if (this.props.index === 0) {
            this.setState({
                inViewport: true
            });
        }

        if (this.curationRef.current !== null) {
            // Judge if this is within viewport
            const curationBoundingBox = this.curationRef.current.getBoundingClientRect();
            //console.log("componentDidMount - curationBoundingBox = ", curationBoundingBox);

            // Save intial overview height
            this.curationHeight = curationBoundingBox.height;

            // If the container size is large
            if (curationBoundingBox.height > this.state.mapHeight) {
                this.setState({
                    variableHeight: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.checkScroll === false) && (this.props.checkScroll === true)) {
            if (this.mediaContainerRef.current !== null) {
                const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                // Judge if this is within viewport
                const mediaBoundingBox = this.mediaContainerRef.current.getBoundingClientRect();
                const inViewport = (
                    (mediaBoundingBox.top >= 0)
                    &&
                    (mediaBoundingBox.bottom <= viewportHeight)
                );

                if (inViewport !== this.state.inViewport) {
                    this.setState({
                        inViewport: inViewport
                    });
                }
            }
        }

        // Feed is on
        if ((prevProps.feedOn === false) && (this.props.feedOn === true)) {
            // Stylize curations
            this.updateCurationStyle(this.props.dotInfo, this.updateCurationLayout);

            //console.log("DotsHomeItem / componentDidUpdate - turning on video");
            setTimeout(
                () => {
                    this.setState({
                        feedVideoOn: true
                    });
                },
                250
            );
        }
        // Feed is off
        else if ((prevProps.feedOn === true) && (this.props.feedOn === false)) {
            this.setState({
                feedVideoOn: false
            });
        }

        // Update like
        if ((this.props.newLike.id !== prevProps.newLike.id) ||
            ((this.props.newLike.id === prevProps.newLike.id) && (this.props.newLike.liked !== prevProps.newLike.liked))) {
            if (this.props.dotInfo.id === this.props.newLike.id) {
                //console.log("DotsHomeItem / componentDidUpdate - updating new like");
                //console.log("DotsHomeItem / componentDidUpdate - this.props.newLike = ", this.props.newLike);

                this.setState({
                    likedByMe: this.props.newLike.liked,
                    likeCount: this.props.newLike.count
                });
            }
        }

        // Update save
        if ((this.props.newSave.id !== prevProps.newSave.id) ||
            ((this.props.newSave.id === prevProps.newSave.id) && (this.props.newSave.saved !== prevProps.newSave.saved))) {
            if (this.props.dotInfo.id === this.props.newSave.id) {
                //console.log("DotsHomeItem / componentDidUpdate - updating new save");
                //console.log("DotsHomeItem / componentDidUpdate - this.props.newSave = ", this.props.newSave);

                this.setState({
                    savedByMe: this.props.newSave.saved,
                    saveCount: this.props.newSave.count
                });
            }
        }


        // Map height changed
        if (this.state.mapHeight !== prevState.mapHeight) {

            if (this.curationRef.current !== null) {

                // If the container size is large
                if (this.state.mapHeight > this.curationHeight) {
                    this.setState({
                        variableHeight: false
                    });
                }
                else {
                    this.setState({
                        variableHeight: true
                    });
                }
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log("DotsHomeItem / getDeriveStateFromProps - nextProps = ", nextProps);
        //console.log("DotsHomeItem / getDeriveStateFromProps - prevState = ", prevState);

        let likedByMe, savedByMe;

        // User logged out
        if ((prevState.likedByMe !== null) && (nextProps.dotLiked === null)) {
            likedByMe = null;
        }
        // User logged in
        else if ((prevState.likedByMe === null) && (nextProps.dotLiked !== null)) {
            likedByMe = nextProps.dotLiked;
        }
        // Else
        else {
            likedByMe = prevState.likedByMe;
        }

        // User logged out
        if ((prevState.savedByMe !== null) && (nextProps.dotSaved === null)) {
            savedByMe = null;
        }
        // User logged in
        else if ((prevState.savedByMe === null) && (nextProps.dotSaved !== null)) {
            savedByMe = nextProps.dotSaved;
        }
        // Else
        else {
            savedByMe = prevState.savedByMe;
        }

        return {
            likedByMe: likedByMe,
            savedByMe: savedByMe
        }
    }
}

function mapStateToProps(state) {
    return {
        browserWidth: state.nav.browserWidth,
        browserWidthPixels: state.nav.browserWidthPixels,
        colorMode: state.nav.colorMode,
        google: state.map.google,
        newLike: state.interaction.newLike,
        newSave: state.interaction.newSave
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        storeNewLike,
        storeNewSave,
        storeShare,
        storeUserTag,
        storeGallery
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DotsHomeItem);
