/*
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    ItineraryOverviewStartEndItem.js
--------------------------------------------------------------------------------------------
    Content
    - Individual item for Start and End Items for ItineraryOverview
============================================================================================
*/


// React / ReactDOM / React-router
import React from "react";

// Modules
//import ReactTooltip from "thedots-tooltip";

// Functions
import {
    getStaticPath,
    getTransitImage
} from "js/Functions";


// Start and end items
function ItineraryOverviewStartEndItem (props) {
    //console.log("ItineraryOverviewStartEndItem / render - props = ", props);
    let lineImage = null;
    let bottomLineImage = null;

    // Determine if this is the first or the last dot
    if (props.isStart) {
        lineImage = (props.colorMode === "day")?
            getStaticPath("/images/line/vertical/solid-top-light.png") :
            getStaticPath("/images/line/vertical/solid-top-dark.png");

        bottomLineImage = (props.colorMode === "day")?
            getStaticPath("/images/line/vertical/solid-middle-light.png") :
            getStaticPath("/images/line/vertical/solid-middle-dark.png");
    }
    else {
        lineImage = (props.colorMode === "day")?
            getStaticPath("/images/line/vertical/solid-bottom-light.png") :
            getStaticPath("/images/line/vertical/solid-bottom-dark.png");
        bottomLineImage = null;
    }

    // End image
    const endImage = (props.isStart)?
        (
            (props.colorMode === "day")?
                getStaticPath("/images/number/double_white_S.png") :
                getStaticPath("/images/number/double_black_S.png")
                //getStaticPath("/images/number/double_red_day_S.png") :
                //getStaticPath("/images/number/double_red_night_S.png")
        ) : (
            (props.colorMode === "day")?
                getStaticPath("/images/number/double_white_E.png") :
                getStaticPath("/images/number/double_black_E.png")
                //getStaticPath("/images/number/double_red_day_E.png") :
                //getStaticPath("/images/number/double_red_night_E.png")
        );

    /*
    // Timestamp
    const timestamp = (props.isStart)? (
        <div id = "itinerary-overview-start-timestamp" 
            className = {(props.colorMode === "day")?
                "itinerary-overview-start-timestamp-day w4" :
                "itinerary-overview-start-timestamp-night k4"}
        >
            {props.endTimestamp}
        </div>
    ) : (
        <div id = "itinerary-overview-end-timestamp" 
            className = {(props.colorMode === "day")?
                "itinerary-overview-end-timestamp-day w4" :
                "itinerary-overview-end-timestamp-night k4"}
        >
            {props.endTimestamp}
        </div>
    );
    */
    
    // End column
    const endColumn = (
        <div className = "itinerary-overview-end-container">
            <div className = "itinerary-overview-end image-cover"
                style = {{ backgroundImage: endImage }}
            >
            </div>
        </div>
    );

    // Transit timestamp and mode
    const transitTimestamp = (props.isStart)? (
        <div className = {(props.colorMode === "day")?
            "itinerary-overview-transit-time k4" :
            "itinerary-overview-transit-time w4"}
        >
            {props.transitTimestamp}
        </div>
    ) : null;

    // Transit mode
    const transitImage = (props.isStart)? getTransitImage(props.transitMode, props.colorMode, true) : null;
    const transitMode = (props.isStart)? (
        <div className = "itinerary-overview-transit-mode image-contain"
            style = {{ backgroundImage: transitImage }}
        >
        </div>
    ) : null;

    // Transit circle
    const circleImage = (props.isStart)?
        (
            (props.colorMode === "day")?
                getStaticPath("/images/common/transit-down-day.png", true) :
                getStaticPath("/images/common/transit-down-night.png", true)
        ) : null;
        //getStaticPath("/images/common/user-time-bar-dot-light.png", true) :
        //getStaticPath("/images/common/user-time-bar-dot-dark.png", true);

    const transitCircle = (props.isStart)? (
        <div className = "itinerary-overview-transit-circle image-contain"
            style = {{ backgroundImage: circleImage }}
        >
        </div>
    ) : null;

    // Hotel
    const lodgingImage = (props.colorMode === "day")?
        getStaticPath("/images/common/lodging-light-blue.png") :
        getStaticPath("/images/common/lodging-blue.png");

    // Render component
    if (props.isStart) {
        return (
            <div key = {"itinerary-overview-item-start"}
                className = "itinerary-overview-item"
            >
                <div className = "itinerary-overview-item-row">
                    <div className = "itinerary-overview-media-left-column" 
                        style = {{ 
                            width: props.leftColumnWidth, 
                            backgroundImage: lineImage 
                        }}
                    >
                        {endColumn}
                    </div>
                    <div className = "itinerary-overview-media-column">
                        <div className = {(props.colorMode === "day")?
                            "itinerary-overview-end-time-day w4" :
                            "itinerary-overview-end-time-night k4"}
                        >
                            {props.endTimestamp}
                        </div>
                        <div className = {(props.colorMode === "day")?
                            "itinerary-overview-end-name k4" :
                            "itinerary-overview-end-name w4"}
                        >
                            {props.locationName}
                        </div>
                        <div className = "itinerary-overview-lodging-container">
                            <div className = "itinerary-overview-lodging-image image-cover"
                                style = {{ backgroundImage: lodgingImage }}
                            >
                            </div>
                            <div className = {(props.colorMode === "day")?
                                "itinerary-overview-lodging-text g4" :
                                "itinerary-overview-lodging-text g4"}
                            >
                                Lodging Options
                            </div>
                        </div>
                    </div>
                    <div className = "itinerary-overview-media-right-column">
                    </div>
                </div>
                <div className = "itinerary-overview-item-row">
                    <div className = "itinerary-overview-space-left-column" 
                        style = {{
                            width: props.leftColumnWidth,
                            backgroundImage: bottomLineImage
                        }}
                    >
                        <div className = "itinerary-overview-transit-container">
                            {transitCircle}
                            {transitMode}
                            {transitTimestamp}
                        </div>
                    </div>
                    <div className = "itinerary-overview-space-column">
                    </div>                    
                </div>
            </div>
        );
    }
    else {
        return (
            <div key = {"itinerary-overview-item-end"}
                className = "itinerary-overview-item"
            >
                <div className = "itinerary-overview-item-row">
                    <div className = "itinerary-overview-media-left-column" 
                        style = {{
                            width: props.leftColumnWidth,
                            backgroundImage: lineImage
                        }}
                    >
                        {endColumn}
                    </div>
                    <div className = "itinerary-overview-media-column">
                        <div className = {(props.colorMode === "day")?
                            "itinerary-overview-end-time-day w4" :
                            "itinerary-overview-end-time-night k4"}
                        >
                            {props.endTimestamp}
                        </div>
                        <div className = {(props.colorMode === "day")?
                            "itinerary-overview-end-name k4" :
                            "itinerary-overview-end-name w4"}
                        >
                            {props.locationName}
                        </div>
                        <div className = "itinerary-overview-lodging-container">
                            <div className = "itinerary-overview-lodging-image image-cover"
                                style = {{ backgroundImage: lodgingImage }}
                            >
                            </div>
                            <div className = {(props.colorMode === "day")?
                                "itinerary-overview-lodging-text g4" :
                                "itinerary-overview-lodging-text g4"}
                            >
                                Lodging Options
                            </div>
                        </div>
                    </div>
                    <div className = "itinerary-overview-media-right-column">
                    </div>
                </div>
            </div>
        );
    }
}


export default ItineraryOverviewStartEndItem;