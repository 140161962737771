/* 
============================================================================================
    Project Dots
--------------------------------------------------------------------------------------------
    Auth.js
--------------------------------------------------------------------------------------------
    - User authentication 
    - Centers around Django rest framework token authentication
    - Seperate access tokens abtained and sent to the server for Facebook / Google log in
      --> the server wil validate the access tokens once more via Facebook and Google
--------------------------------------------------------------------------------------------
    Content
    - logIn
    - logInFacebook
    - logInGoogle    
============================================================================================
*/

// Axios
import { postLogIn } from "requests/axios_auths";

// Response codes
import { responseResultCodes } from "ResponseCodes";

// Analytics
import { Mixpanel } from './Analytics';


/* 
============================================================================================
    log in
--------------------------------------------------------------------------------------------
  - Main log in function
============================================================================================
*/

// Login when the token exists
function logIn(email, password, token, authType, image_url, callback) {
    //console.log("Auth / logIn - email = ", email);
    //console.log("Auth / logIn - password = ", password);
    //console.log("Auth / logIn - token = ", token);

    // If token already exists
    if (!!localStorage.token) {
        if (callback) {
            console.log("[WARNING] Auth / logIn - log in reattempted while the auth token exists.");
            callback(true);
        }
        return
    }
    // If token does not exist
    else {
        let dataJSON, hostAPI;

        // Facebook authorization
        if (authType === "facebook") {
            //console.log("Auth / logIn - Facebook authentication");
            //console.log("Auth / logIn - Facebook email / token = ", email, token);

            // Axios request content : email / password
            dataJSON = {
                email: email,
                access_token: token,
                image_url: image_url
            };

            hostAPI = "login/facebook/";
        }
        // Google authorization
        else if (authType === "google") {
            //console.log("Auth / logIn - Google authentication");
            //console.log("Auth / logIn - Google email / token = ", email, token);

            // Axios request content : email / password
            dataJSON = {
                email: email,
                access_token: token,
                image_url: image_url
            };

            hostAPI = "login/google/";
        }
        // Regular authorization
        else {
            //console.log("Auth / logIn - regular authentication");
            // axios request content : email / password
            dataJSON = {
                email: email,
                password: password
            };

            hostAPI = "login/";
        }

        // Axios callback : execute when the server returns a response
        const axiosCallback = (response) => {
            //console.log("Auth / logIn - axios response = ", response);

            // If log in was unsuccessful
            if (response.data.status.code !== responseResultCodes.OK) {
                // Log out of Facebook or Google
                if (authType === "facebook") {
                    window.FB.logout();
                }
                else if (authType === "google") {
                    window.googleAuth.signOut().then(
                        () => {
                            console.log("Auth / logIn - Google signed out");
                        }
                    );
                }

                Mixpanel.track('login failed')

                // Return the values
                callback(
                    false,
                    null
                );
            }
            // If log in was successful
            else {
                // Get user info
                const userInfo = response.data.content.user;

                // Get token
                const token = response.data.content.token;

                // Save the token
                localStorage.token = token;
                
                // Print info
                //console.log("AuthFuctions / logIn - token = ", token);
                //console.log("AuthFuctions / logIn - userInfo = ", userInfo);

                // Set the axios headers with the access token
                window.axios.defaults.headers.common["Authorization"] = " Token " + token;
                window.axiosCSRF.defaults.headers.common["Authorization"] = " Token " + token;
                //console.log("Auth / logIn - window.axios.defaults = ", window.axios.defaults);
                //console.log("Auth / logIn - window.axiosCSRF.defaults = ", window.axiosCSRF.defaults);

                Mixpanel.identify(userInfo.id)
                Mixpanel.track('login')
                Mixpanel.people.set({
                    $first_name: userInfo.first_name,
                    $last_name: userInfo.last_name,
                    $email: userInfo.email
                })

                // Return the values
                callback(
                    true,
                    userInfo
                );
            }
        };

        // Send log in request using axios post with CSRF token
        //console.log("Auth / logIn - dataJSON = ", dataJSON);        
        postLogIn(dataJSON, hostAPI)
        .then(axiosCallback)
        .catch(
            (response) => {
                console.log("[WARING] Auth / logIn - axios error = ", response);

                // Return the values
                callback(
                    false,
                    null
                );
            }
        );
    }
}


/* 
============================================================================================
    logInGoogle
--------------------------------------------------------------------------------------------
  - log in through Google authentical tool
============================================================================================
*/

function logInGoogle(onSuccess, onFailure, userProfilePicSize) {
    // Stop propagation of click event to parents
    if (window.event) {
        window.event.stopPropagation();
    }

    const options = {
        response_type: "permission",
        redirect_uri: null,
        fetch_basic_profile: true,
        prompt: "",
        scope: "profile email"
    };

    const offline = false;

    if (offline) {
        window.googleAuth.grantOfflineAccess(options).then(
            response => onSuccess(response),
            error => onFailure(error)
        );
    }
    else {
        window.googleAuth.signIn(options).then(
            (response) => {
                //console.log("AuthFunctions / logInGoogle - response = ", response);
                const profile = response.getBasicProfile();
                const auth = response.getAuthResponse();

                const profileData = {
                    email: profile.getEmail(),
                    name: profile.getName(),
                    first_name: profile.getGivenName(),
                    last_name: profile.getFamilyName(),
                    google_id: profile.getId(),
                    profile_pic: profile.getImageUrl() + "?sz=" + userProfilePicSize,
                };
                //console.log("AuthFunctions / logInGoogle - profileData = ", profileData);

                const authResponse = {
                    idToken: auth.id_token,
                    accessToken: auth.access_token,
                };
                //console.log("AuthFunctions / logInGoogle - authResponse = ", authResponse);

                onSuccess(authResponse, profileData);
            },
            error => onFailure(error)
        );
    }
}


/* 
============================================================================================
    logInFacebook
--------------------------------------------------------------------------------------------
  - log in through Facebook authentical tool
============================================================================================
*/

function logInFacebook(onSuccess, onFailure, userProfilePicSize) {
    // stop propagation of click event
    if (window.event) {
        window.event.preventDefault();
    }

    // authentication scope
    const scope = "public_profile, email";
    const fields = "first_name, last_name, email, picture.width(" + userProfilePicSize + "), cover, locale";
    const language = "en_US";

    // facebook log in
    window.FB.login(
        function(response) {
            //console.log("AuthFunctions / logInFacebook - response = ", response);
            if (response.status === "connected") {
                // Logged into your app and Facebook.
                // api request for public user information
                window.FB.api(
                    "/me",
                    {
                        locale: language,
                        fields: fields
                    },
                    (apiResponse) => {
                        //Object.assign(response, authResponse);
                        onSuccess(response.authResponse, apiResponse);
                    }
                );
            }
            else {
                // The person is not logged into this app or we are unable to tell.
                onFailure(response);
            }
        },
        {
            scope: scope
        }
    );
}


// export functions
export {
    logIn,
    logInFacebook,
    logInGoogle
};