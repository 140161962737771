import { STORE_NEW_COMMENT, STORE_NEW_REPLY } from "actions"; 

const INITIAL_STATE = { 
    newComment: null,
    newReply: null
};

export default function(state=INITIAL_STATE, action) {
	switch(action.type) {
        case STORE_NEW_COMMENT:
            return { ...state, newComment: action.payload};
        case STORE_NEW_REPLY:
            return { ...state, newReply: action.payload};
		default:
			return state;
	}
};