const chatsAPI = "api/chats/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const postAddChat = (dataJSON) => {
	let URL = rootURL + chatsAPI;

    return window.axiosCSRF.post(URL, dataJSON);
};

export const getChats = (numChatsLoaded) => {
    let URL = rootURL + chatsAPI + "fetch/?num_chats_loaded=" + numChatsLoaded;

    return window.axiosCSRF.get(URL);
};

export const getChatMessages = (chatIDs) => {
    let URL = rootURL + chatsAPI + "fetch/multiple/?chat_ids=" + chatIDs;

    return window.axiosCSRF.get(URL);
};

export const getSingleChatMessage = (chatID, numMessagesLoaded) => {
    let URL = rootURL + chatsAPI + "fetch/single/?chat_id=" + chatID + "&num_messages_loaded=" + numMessagesLoaded;

    return window.axiosCSRF.get(URL);
};

export const deleteChatRoom = (chatID) => {
	let URL = rootURL + chatsAPI + chatID + "/";

    return window.axiosCSRF.delete(URL);
};