const contributionsAPI = "api/contributions/";
const rootURL = process.env.REACT_APP_API_ROOT_URL;

export const getContributions = (username, type, mode, page, tag) => {
	//console.log("requests / getContributions - username = ", username);
	//console.log("requests / getContributions - page = ", page);
	//console.log("requests / getContributions - downloaded = ", downloaded);

	let URL = null;

	if (mode === "not") {
		URL = (tag !== null)?
			(rootURL + contributionsAPI + "?user__username=" + username + "&dot__type_not=" + type + "&page=" + page + "&tag=" + tag) :
			(rootURL + contributionsAPI + "?user__username=" + username + "&dot__type_not=" + type + "&page=" + page);
	}
	else {
		URL = (tag !== null)?
			(rootURL + contributionsAPI + "?user__username=" + username + "&dot__type=" + type + "&page=" + page + "&tag=" + tag) :
			(rootURL + contributionsAPI + "?user__username=" + username + "&dot__type=" + type + "&page=" + page);
	}

	return window.axiosCSRF.get(URL);
};

export const patchContribution = (contributionID, dataJSON) => {

	const URL = rootURL + contributionsAPI + contributionID + "/";

	return window.axiosCSRF.patch(URL, dataJSON);
};

export const getContributors = (dotSlug, page) => {
	const URL = (page !== null)?
		(rootURL + contributionsAPI + "?dot__slug=" + dotSlug + "&page=" + page) :
		(rootURL + contributionsAPI + "?dot__slug=" + dotSlug);

	return window.axiosCSRF.get(URL);
};
